import { TimeframedMetricSubscriber } from '../models/time-frame/timeframed-metric-subscriber';
import { TypeOfValueEnum, ChannelTypes, ArcGISHistoryChannelAttributes, Metric } from '../models';
import { CanvasTab } from './canvas-tab.model';
import { CanvasTrendHeader } from './canvas-trend-header.model';
import { Subscription } from 'rxjs';
import { YAxisType } from '../enums/y-axis-type.enum';
import { TrendPoint } from 'interfaces/trend-point.interface';

/**
 * The model for the omni trend.
 */
export class CanvasTrend {

	get metric() {
		return this.canvasTab.metric;
	}
	/*
	get timeframe() {
		return this.metricSubscriber.timeframedMetric.timeframeFilter.timeFrame;
	}*/
	get dateField() {
		const field = this.canvasTab.metricSubscription?.timeframedMetric.timeframeFilter.timeframeField;
		if (!field) {
			if (this.metric.definition.source.type === ChannelTypes.History) {
				const historyChannelAttributes = this.metric.definition.historyChannel.attributes as ArcGISHistoryChannelAttributes;
				return historyChannelAttributes.completedDateFieldName;
			} else if (this.metric.definition.source.type === ChannelTypes.WorkOrder) {
				return 'startDate';
			} else {
				throw new Error('Asset trending is not supported.');
			}
		}
		return field;
	}

	get valueTypeField() {
		return this.metric.definition.displayValueSettings.valueTypeField.name;
	}

	private _label: string;
	get label() {
		if (!this._label) {
			if (this._isHistoryTrend) {
				this._label = this.canvasTab.trendDefinition.trendLabel;
			} else {
				const metricName = this.metric.definition.name;
				this._label = metricName;
				if (this.canvasTab.defaultMetric) {
					this._label = `${metricName} (Default Metric)`;
				} else {
					const timeframeText = this.canvasTab.tile?.timeFrameFilter?.timeFrame?.text;
					if (timeframeText) {
						this._label = `${metricName} (${timeframeText})`;
					}
				}
			}
		}
		return this._label;

	}

	get yAxisLabel() {
		if (!this._isHistoryTrend) {
			const typeOfValue = this.metric.definition.displayValueSettings.typeOfValue.enumValue;
			switch (typeOfValue) {
				case TypeOfValueEnum.recordCount:
				case TypeOfValueEnum.percetangeOfTotal:
					return 'Count (number)';
				case TypeOfValueEnum.sumOfAField:
				case TypeOfValueEnum.averageValue:
				case TypeOfValueEnum.mostRecentValue:
					return this.metric.definition.displayValueSettings.valueTypeField.name;
			}
		} else {
			return this.canvasTab.trendDefinition.trendLabel;
		}
	}

	get backgroundColor(): string {
		if (!this._isHistoryTrend) {
			return this.canvasTab.tile?.backgroundColor ?? this.canvasTab.metric.definition.backgroundColor.toHex();
		} else {
			return this.canvasTab.trendDefinition.trendBackgroundColor;
		}
	}

	canvasTab: CanvasTab;

	dataSet: any;
	data: TrendPoint[];
	header: CanvasTrendHeader;

	_yAxisId: string;
	// not sure if this is used or not.
	set yAxisId(value: string) {
		if (value != this._yAxisId) {
			this._yAxisId = value;
			if (this._yAxisId != 'left-axis') {
				this.yAxisType = YAxisType.Right;
			} else {
				this.yAxisType = YAxisType.Left;
			}
		}
	}
	get yAxisId(): string {
		return this._yAxisId;
	}

	yAxisType: YAxisType;
	dataSubscription: Subscription;

	_isHistoryTrend: boolean;
	get isHistoryTrend(): boolean {
		return this._isHistoryTrend;
	}

	constructor(canvasTab: CanvasTab) {
		this.canvasTab = canvasTab;
		if (this.canvasTab.trendDefinition) {
			this._isHistoryTrend = true;
		}
	}


}
