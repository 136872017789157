<ng-container>
	<mat-list-item class="asset-list-item">
		{{legend.title}}
	</mat-list-item>
	<mat-list style="margin-left:30px;" *ngFor="let info of legend.infos">
		<mat-list-item class="asset-list-item">
			<img [src]="info.src"
				[width]="info.width"
				[height]="info.height"
				[style.opacity]="info.opacity"/>
				<div class="list-item-text">{{info.label}}</div>
		</mat-list-item>
		<!-- <mat-divider class="divider-item"></mat-divider> -->
	</mat-list>
</ng-container>
