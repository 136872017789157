import { Injectable } from '@angular/core';
import { OmniSnackBarMessage } from './snackbar-message';
import { OmniSnackBarActionButton } from './snackbar-action-button';

@Injectable({
  providedIn: 'root'
})
export class OmniSnackBarService {
  snackBarMessage: OmniSnackBarMessage;
  snackBarButtons: OmniSnackBarActionButton[];
  constructor() { }
}
