import { Injectable } from '@angular/core';
import { OmniBootstrapService } from 'domain-service/omni-bootstrap.service';
import { NotificationObject } from 'sedaru-util';
import { ArcGISManager } from './data-sources/arc-gis-manager';
import { SubscriptionsManager } from './subscriptions/subscriptions-manager';
import { UIManager } from './ui/ui-manager';
import { AppComponent } from 'app/app.component';
import { MetricManager } from './metrics/metric-manager';
import { ConfigurationManager } from './configurations/configuration-manager';
import { TemplateManager } from 'app/ui-components/omni-list/list-item/templates/template-manager';
import { JobManager } from './jobs/job-manager';

@Injectable({
	providedIn: 'root'
})
export class OmniInteropService {
	private _arcGISManager: ArcGISManager;
	public get arcGISManager(): ArcGISManager {
		if (!this._arcGISManager) this._arcGISManager = new ArcGISManager(this);

		return this._arcGISManager;
	}

	private _templateManager: TemplateManager;
	public get templateManager(): TemplateManager {
		if (!this._templateManager) this._templateManager = new TemplateManager(this);

		return this._templateManager;
	}

	private _subscriptionsManager: SubscriptionsManager;
	public get subscriptionsManager(): SubscriptionsManager {
		if (!this._subscriptionsManager) this._subscriptionsManager = new SubscriptionsManager(this);

		return this._subscriptionsManager;
	}

	private _uiManager: UIManager;
	public get uiManager(): UIManager {
		if (!this._uiManager) this._uiManager = new UIManager(this);

		return this._uiManager;
	}

	private _metricManager: MetricManager;
	public get metricManager(): MetricManager {
		if (!this._metricManager) this._metricManager = new MetricManager(this);

		return this._metricManager;
	}

	private _configurationManager: ConfigurationManager;
	public get configurationManager(): ConfigurationManager {
		if (!this._configurationManager) this._configurationManager = new ConfigurationManager(this);

		return this._configurationManager;
	}

	private _jobManager: JobManager;
	public get jobManager(): JobManager {
		if (!this._jobManager) this._jobManager = new JobManager(this);

		return this._jobManager;
	}

	constructor(public omniDomain: OmniBootstrapService) {}

	async initialize(appComponent: AppComponent, notificationObject: NotificationObject<string>): Promise<boolean> {
		const domainResult = await this.omniDomain.initialize(notificationObject);

		if (domainResult) {
			this.uiManager.initialize(appComponent);
			this.metricManager.initialize();
			this.jobManager.initialize();
			this.arcGISManager.initialize();
			this.omniDomain.workOrderFactory.interopService = this;
			this.omniDomain.recurrenceProvider.interopService = this;
			await this.omniDomain.workOrderFactory.initialize();
		}

		return domainResult;
	}
}
