<div class="list-wrapper scrolling-content" (scroll)="onScroll()" #scrollingContainer infiniteScroll (scrolled)="onScrollStepReached()" [scrollWindow]="false">
	<ng-container *ngFor="let result of metricResults | slice: 0:displayedRecordCount">
		<app-list-item
			(mouseenter)="highlightFeatureOnHover(result)"
			(mouseleave)="closePopUp()"
			(itemClicked)="selectMetricRecord($event)"
			[resolver]="result.resolver"
			[item]="result"
		></app-list-item>
	</ng-container>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button mat-button class="omni-md-font" (click)="metric && metric.definition.isWorkOrderMetric && goToBulkEdit()" *ngIf="metric && metric.definition.isWorkOrderMetric">
			<img class="omni-sm-img" src="assets/workorder-white.png" />
			edit WOs
		</button>
		<button mat-button class="omni-md-font" (click)="goToBulkCreate()" *ngIf="metric && !metric.definition.isWorkOrderMetric">
			<img class="omni-sm-img" src="assets/workorder-white.png" />
			create WOs
		</button>
		<span style="flex:1"></span>
		<label class="omni-md-font">items: {{ metricResults.length }} &nbsp;</label>
	</mat-card-actions>
</mat-card-footer>
