import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ListItemResolver } from './templates/list-item.resolver';
import { ListItemComponentBase } from './templates/list-item.model';
import { LeftIconTwoLinesItemResolver } from './templates/left-icon-two-lines/left-icon-two-lines.resolver';

@Component({
	selector: 'app-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
	@ViewChild('listItem') listItem: ListItemComponentBase;
	private _item: any;
	@Input() set item(value: any) {
		this._item = value;
		this.resolver?.resetFlags();
	}
	get item(): any {
		return this._item;
	}

	@Input() resolver: ListItemResolver;
	@Input() isCurrentlySelected: boolean;
	@Input() connector: boolean;
	@Input() disable: boolean;
	@Output() leftIconClicked = new EventEmitter();
	@Output() rightIconClicked = new EventEmitter();
	@Output() itemClicked = new EventEmitter();
	@Output() itemRightClicked = new EventEmitter<{ event: MouseEvent; item: any; element: HTMLElement }>();

	constructor() {}

	ngOnInit() {}

	isSelected(): boolean {
		return this.listItem.selected;
	}

	deSelectItem() {
		this.listItem.selected = false;
	}

	showContextMenu(event: MouseEvent, item: any, element: HTMLElement) {
		this.itemRightClicked.emit({ event, item, element });
	}
}
