import { NavigationService, Pages } from './../../navigation/navigation.service';
import { WorkOrderFieldProvider } from './../workorder-filter/workorder-field.provider';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Task, TaskWrapper, WorkOrderFieldNames, WorkOrderWrapper } from 'models/work-order';
import { LeftIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-one-line/left-icon-one-line.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { ContextualSearchBase, ContextualSearch } from 'app/menu-panel/menu-panel-base/contextual-search';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-workorder-new-task',
	templateUrl: './workorder-new-task.component.html',
	styleUrls: ['./workorder-new-task.component.scss']
})
export class WorkorderNewTaskComponent extends MenuPanelBaseComponent {

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	workOrder: WorkOrderWrapper;
	workTask: TaskWrapper;
	get taskAssignedTo(): string {
		const employeeAssigned = this.workorderFactory.workOrderMetaData.employees.getByEmployeeId(this.workTask.assignedTo);
		return employeeAssigned ? employeeAssigned.name : '';
	}
	selectedItem = [];
	itemsKey = 'taskId';

	private resolvers = new Map();

	public get taskList(): Array<any> {
		return this.contextualSearch.getSearch<any>().results;
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		private workorderFactory: WorkOrderFactory,
		private interop: OmniInteropService,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.workOrder = args.parameter.workOrder;
		this.workTask = args.parameter.workTask;

		if (this.workTask.taskKey) {
			const selectedTask = this.taskList.find(task => task.taskId === this.workTask.taskKey);
			this.selectedItem.push(selectedTask);
		}
	}

	ngOnInit(): void {
		this.menuPanelComponent.updateView({ title: this.workOrder.workOrderKey, backgroundClass: 'orange-background' });
		const { workOrderMetaData } = this.workorderFactory;

		const workOrderStatuses = workOrderMetaData.statuses.length ? workOrderMetaData.statuses.filter(s => s.workOrderVisible) : [];
		const workOrderStatusList = workOrderStatuses.length ? workOrderStatuses : [{ statusCode: 'Open' }, { statusCode: 'Close' }];

		this.workTask.status = workOrderStatusList[0].statusCode;

		this.listComponent.getResolver = item => {
			return this.getListItemResolver(item);
		};
	}

	private getListItemResolver(item) {
		const listResolver = LeftIconOneLineComponent.createResolver();
		listResolver.getLabel = i => {
			return i.description;
		};
		listResolver.getIconPath = i => {
			return 'assets/workorder.png';
		};

		this.resolvers.set(item.name, listResolver);
		return listResolver;
	}

	goToWorkTaskAssignTo = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkTaskAssignTo;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [{ 'name' : this.taskAssignedTo }],
			selectedItemKey: 'name',
			workOrder: this.workOrder,
			workTask: this.workTask
		});
	};

	itemSelected(task: Task) {
		this.workTask.description = task.description;
		this.workTask.taskKey = task.taskId;
		this.workTask.customFields = task.taskCustomFields;
	}

	async save() {
		if (!this.workTask.taskKey) {
			this.alertDialog.mainMessage = { text: 'Need to select a task' };
			this.alertDialog.open = true;
			return;
		}
		if (!this.workTask.assignedTo) {
			this.alertDialog.mainMessage = { text: 'Need to set assigned to' };
			this.alertDialog.open = true;
			return;
		}
		await this.workorderFactory.updateWorkTask(this.workOrder, this.workTask);
		this.menuPanelComponent.goBack();
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (task: any, searchText) => {
			if (task.description.toLowerCase().includes(searchText.toLowerCase())) return true;

			return false;
		};
		const taskList = this.workorderFactory.workOrderMetaData.tasks.getTasksByWorkTypeId(this.workOrder.workTypeId);
		taskList.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : b.description.toLowerCase() > a.description.toLowerCase() ? -1 : 0));
		const contextualSearch = new ContextualSearch(taskList, searchFunction);
		contextualSearch.title = 'search task';
		return contextualSearch;
	}
}
