import { EquipmentCostContract, StandardEquipmentCostContract } from '../../contracts/work-order';
import { EquipmentCost } from './equipment-cost.model';
import { StandardEquipmentCost } from './standard-equipment-cost.model';

export class EquipmentCostWrapper {
	private _equipmentCost: EquipmentCost | StandardEquipmentCost;
	get equipmentCost(): EquipmentCost | StandardEquipmentCost {
		return this._equipmentCost;
	}

	constructor(equipmentCost: EquipmentCost | StandardEquipmentCost) {
		if (equipmentCost instanceof EquipmentCost) this._equipmentCost = equipmentCost as EquipmentCost;
		else this._equipmentCost = equipmentCost as StandardEquipmentCost;
	}

	get id(): string {
		return this._equipmentCost.id;
	}
	set id(value: string) {
		this._equipmentCost.id = value;
	}

	get equipmentId(): string {
		return this._equipmentCost.equipmentId;
	}
	set equipmentId(value: string) {
		this._equipmentCost.equipmentId = value;
	}

	get units(): number {
		return this._equipmentCost.units;
	}
	set units(value: number) {
		this._equipmentCost.units = value;
	}

	get unitCost(): number {
		return this._equipmentCost.unitCost;
	}
	set unitCost(value: number) {
		this._equipmentCost.unitCost = value;
	}

	get datestamp(): string {
		return this._equipmentCost.datestamp;
	}
	set datestamp(value: string) {
		this._equipmentCost.datestamp = value;
	}

	get workOrderKey(): string {
		return this._equipmentCost.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._equipmentCost.workOrderKey = value;
	}

	get totalCost(): number {
		return this._equipmentCost.totalCost;
	}
	set totalCost(value: number) {
		this._equipmentCost.totalCost = value;
	}

	get hours(): number {
		return this._equipmentCost.hours;
	}
	set hours(value: number) {
		this._equipmentCost.hours = value;
	}

	get workTaskId(): string {
		return this._equipmentCost.workTaskId;
	}
	set workTaskId(value: string) {
		this._equipmentCost.workTaskId = value;
	}

	get description(): string {
		return this._equipmentCost.description;
	}
	set description(value: string) {
		this._equipmentCost.description = value;
	}

	get createdBy(): string {
		return this._equipmentCost.createdBy;
	}
	set createdBy(value: string) {
		this._equipmentCost.createdBy = value;
	}

	get rateType(): string {
		if (this.equipmentCost instanceof EquipmentCost) return '';
		return (this.equipmentCost as StandardEquipmentCost).rateType;
	}
	set rateType(value: string) {
		if (this.equipmentCost instanceof EquipmentCost) return;
		(this.equipmentCost as StandardEquipmentCost).rateType = value;
	}

	get isDirty(): boolean {
		return this._equipmentCost.isDirty;
	}

	isNew = (): boolean => this._equipmentCost.isNew();

	calculateTotalCost = (): void => this._equipmentCost.calculateTotalCost();

	createTimeStamp = (): void => this._equipmentCost.createTimeStamp();

	clearDirty = (): void => this._equipmentCost.clearDirty();

	undoAll = (): boolean => this._equipmentCost.undoAll();

	onUpdateInformation = (copyModel): boolean => this._equipmentCost.onUpdateInformation(copyModel.equipmentCost);

	updateInformation = (copyModel): boolean => this._equipmentCost.updateInformation(copyModel.equipmentCost);

	getContract = (): EquipmentCostContract | StandardEquipmentCostContract => this._equipmentCost.getContract();
}
