<div class="list-wrapper">
	<app-omni-list
		[omniListItems]="selectionList"
		(itemClicked)="onItemSelected($event)"
		[selectedItems]="selectedItems"
		[itemsKey]="nameKey"
		[multipleSelection]="isMultipleSelection"
	></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button *ngIf="showClearButton || isMultipleSelection" mat-button class="omni-md-font" (click)="onClear()"><img class="omni-sm-img" src="assets/x-close.png" /> clear</button>
		<span style="flex:1"></span>
		<button *ngIf="isMultipleSelection" mat-button class="omni-md-font" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
