import { CloseWorkOrderHandler, CloseWorkOrderResult } from './../../../domain-service/subscriptions/handlers/close-work-order-handler';
import { NavigationService } from './../../navigation/navigation.service';
import { Pages } from './../../navigation/inavigation';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { WorkorderOutlineService } from './workorder-outline.service';
import { WorkorderStep } from './workorder-step.model';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { AdvancedWorkOrder } from 'models/work-order/advanced-work-order.model';
import { LEMService } from '../workorder-lem-list/lem.service';
import { NoIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-two-lines/no-icon-two-lines.component';
import { Equipment, Employee, LEMType, VendorCost, WorkOrderSummary, WorkOrderWrapper, StandardWorkOrder } from 'models/work-order';
import { Metric, SedaruWorkOrderChannelAttributes } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MethodHandlerResultBase } from 'sedaru-util/sedaru-subscriptions-library';
import { RecordContextGroup } from 'models/records/record-context-group';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { TwoIconsTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/two-icons-two-lines/two-icons-two-lines.component';
import { RecurrenceProvider } from 'providers/recurrence.provider';
import { RecurrenceTemplate } from 'models/recurrence';
import { AssetRecord } from 'models/records/asset-record.model';
import { HistoryRecord } from 'models/records/history-record.model';
import { Feature } from 'sedaru-util/esri-core';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';

@Component({
	selector: 'app-workorder-outline',
	templateUrl: './workorder-outline.component.html',
	styleUrls: ['./workorder-outline.component.scss']
})
export class WorkOrderOutlineComponent extends MenuPanelBaseComponent {
	disableCloseButton = false;
	/** A dictionary of workorder steps */
	workorderSteps: Array<any>;
	currentWorkOrder: WorkOrderWrapper;
	advancedMode: boolean;
	metric: Metric;
	workType: any;
	scrollPosition: number;
	relatedWorkorders: WorkOrderWrapper[] = [];
	relatedWorkorderCount = 0;
	tab: string;
	isReadOnly = false;
	private resolvers = new Map();
	private isLoadingWO = false;
	get pageIdentifier(): Pages {
		return Pages.workorderOutline;
	}
	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	constructor(
		private workorderOutlineService: WorkorderOutlineService,
		private workOrderFactory: WorkOrderFactory,
		private lemListService: LEMService,
		private interop: OmniInteropService,
		private recurrenceProvider: RecurrenceProvider,
		view: ElementRef<HTMLElement>,
	) {
		super(view);
	}

	async onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;
		this.menuPanelComponent.disableBackButton = true;

		const incompleteWorkOrder = this.completeWorkOrderRecord(args.parameter.incompleteRecord);
		const workOrder = incompleteWorkOrder ?? args.parameter.workOrder;
		if (workOrder instanceof AdvancedWorkOrder || workOrder instanceof StandardWorkOrder) this.currentWorkOrder = new WorkOrderWrapper(workOrder);
		else if (workOrder instanceof WorkOrderWrapper) this.currentWorkOrder = workOrder;
		else return;

		this.advancedMode = this.workOrderFactory.isAdvancedMode;
		this.metric = args.parameter.metric;
		this.tab = args.parameter.tab;

		this.listComponent.getResolver = item => {
			if (this.resolvers.has(item.name)) return this.resolvers.get(item.name);
			return this.getListResolver(item);
		};

		this.setReadOnly();
		this.setMenuPanelComponent();
		this.updateOutlineSteps();

		this.menuPanelComponent.updatePacifier(true);
		this.isLoadingWO = true;
		this.loadWO();
	}

	private completeWorkOrderRecord(incompleteRecord: any) {
		if (!incompleteRecord) return;
		const wo = incompleteRecord;
		const newWo = this.workOrderFactory.isAdvancedMode ? new AdvancedWorkOrder() : new StandardWorkOrder();
		newWo.workOrderKey = wo.workOrderKey || wo.WorkorderKey;
		newWo.workType = wo.workType || wo.WorkType;
		newWo.startDate = wo.startDate || wo.StartDate;
		newWo.completedDate = wo.completedDate || wo.CompletedDate;
		newWo.xCoordinate = wo.geometry ? wo.geometry.x : 0;
		newWo.yCoordinate = wo.geometry ? wo.geometry.y : 0;
		newWo.isNew = false;
		return newWo;
	}

	private setMenuPanelComponent(): void {
		this.menuPanelComponent.updateView({
			title: this.currentWorkOrder.workOrderKey || '',
			rightIcon: { url: 'assets/maplocation.png', toolTip: '', callBackFunction: this.goToWorkOrderAsset.bind(this) },
			backgroundClass: 'orange-background',
			badgeNumber: this.currentWorkOrder.workAssets ? this.currentWorkOrder.workAssets.length : 0,
			disableRightButton: this.isReadOnly && !this.currentWorkOrder.workAssets.length
		});
		this.workType = this.currentWorkOrder.workType;

		if (this.uiState) this.scrollPosition = this.uiState.scrollPosition;
	}

	onPageReload(args: NavigationArgs) {
		this.onPageNavigatedTo(args);
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
	}

	updateOutlineSteps() {
		this.workorderSteps = this.workorderOutlineService.getWorkorderSteps(this.currentWorkOrder, this.advancedMode, this.isReadOnly);
		this.resolvers.clear();
	}

	ngOnInit() {}

	private getListResolver(resolverItem) {
		const resolver = TwoIconsTwoLinesComponent.createResolver();
		resolver.getTopLabel = item => {
			return (item as WorkorderStep).name;
		};
		resolver.getLeftIconPath = item => {
			return this.getLeftIcon(item);
		};
		resolver.getRightIconPath = item => {
			return this.getRightIcon(item);
		};
		resolver.getBottomLeftLabel = item => {
			return (item as WorkorderStep).status;
		};
		resolver.isDisabled = item => {
			return (item as WorkorderStep).disabled;
		};
		resolver.getBottomRightLabel = item => {
			return '';
		};

		this.resolvers.set(resolverItem.name, resolver);
		return resolver;
	}

	/** Gets the proper icon for the workorder step according to its state */
	getLeftIcon(step: WorkorderStep) {
		return this.workorderOutlineService.getStepLeftIcon(step);
	}

	getRightIcon(step: WorkorderStep): string {
		return this.workorderOutlineService.getStepRightIcon(step);
	}

	async handleRightIconClick(event: WorkorderStep): Promise<void> {
		if (!this.currentWorkOrder || !this.currentWorkOrder.recurrenceTemplateId) return;

		let recurrenceTemplate: RecurrenceTemplate;

		try {
			this.menuPanelComponent.updatePacifier(true);
			recurrenceTemplate = await this.recurrenceProvider.getTemplateByTemplateId(this.currentWorkOrder.recurrenceTemplateId);
			if (!recurrenceTemplate) return;


			const assetDefinition = this.interop?.configurationManager?.customerCodeConfiguration?.assetDefinitions?.getByAssetType(recurrenceTemplate.assetType);
			if (!assetDefinition) return;

			const features = await this.interop?.arcGISManager?.getAssetLayerFeatures(recurrenceTemplate?.assetType, recurrenceTemplate?.associatedAssets?.map(aa => aa?.assetID));
			if (!features || !features.length) return;
			for (const f of features) {
				const workAssetModel = this.workOrderFactory.createWorkAssetModel(null, AssetRecord.create(assetDefinition, f));
				recurrenceTemplate.workAssets.push(workAssetModel);
			}
		} finally {
			this.menuPanelComponent.updatePacifier(false);
		}

		if (!recurrenceTemplate) return;
		NavigationService.navigateTo(Pages.workorderSummary, {
			workOrderSummary: new WorkOrderSummary(
				WorkOrderSummaryActions.EditRecurrenceTemplate,
				null,
				null,
				recurrenceTemplate
			)
		});
	}

	onScroll(position: number) {
		if (!this.uiState) this.uiState = {};
		this.uiState.scrollPosition = position;
	}

	async closeWorkOrderOperation() {
		this.disableCloseButton = true;
		const handler = this.interop.omniDomain.subscriptionService.hub.methodHandlers.getHandler('CloseWorkOrderHandler') as CloseWorkOrderHandler;
		const a = this.interop.omniDomain.userService.globalConfig.workOrderChannel.attributes as SedaruWorkOrderChannelAttributes;
		let customerCode = a.customerCode;
		if (!customerCode) {
			customerCode = this.interop.omniDomain.userService.currentUser.customerCode;
		}
		handler.sendCloseWorkOrderMessage(this.currentWorkOrder?.workOrder as AdvancedWorkOrder, customerCode); // fire and forget

		const listener = handler.createListener();

		const condition = (resultCandidate: MethodHandlerResultBase): Promise<boolean> => {
			return new Promise<boolean>((resolve, reject) => {
				// validate the incoming message
				// evaluate if its good, what you're looking for
				// return true if good

				const closeWorkOrderResult = resultCandidate as CloseWorkOrderResult;
				if (!closeWorkOrderResult) {
					resolve(false); // waitForMessage will continue to wait
					return;
				}

				resolve(true);
				return;

				// console.log(closeWorkOrderResult);
				// if (closeWorkOrderResult.workOrderKey === this.currentWorkorder.workOrderKey) {
				// 	resolve(true); // waitForMessage will resume execution
				// 	return;
				// }

				// resolve(false);
			});
		};

		const afterLongTimeFunction = () => {
			listener.cancel();
		};

		const result = await listener.waitForMessage(condition, 15, afterLongTimeFunction);

		console.log(result);
		// if you get this far, result guarantees that the condition was true. Success might be false... but the condition is true.

		if (result.cancelled) {
			// what ever need to happened after cancel
			this.disableCloseButton = false;
			return;
		}
		if (result.success) {
			this.disableCloseButton = false;
			this.menuPanelComponent.goBack();
			return; // youre done
		}

		this.disableCloseButton = false;
		this.alertDialog.mainMessage = { text: result.message };
		this.alertDialog.open = true;
		return;
	}

	goToWorkOrderAsset() {
		if (this.isLoadingWO) return;

		NavigationService.navigateTo(Pages.workorderAssets, {
			advancedMode: this.advancedMode,
			workOrder: this.currentWorkOrder,
			metric: this.metric
		});
	}

	async goTo(step: WorkorderStep) {
		if (this.isLoadingWO) return;

		if (step.name === 'summary') {
			return NavigationService.navigateTo(Pages.workorderSummary, {
				workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.EditSingleWO, [this.currentWorkOrder]),
				advancedMode: this.advancedMode
			});
		}
		if (step.name === 'tasklist' && !this.currentWorkOrder.workTasks.length) {
			const workTask = this.workOrderFactory.createWorkTaskModel(this.currentWorkOrder);
			return NavigationService.navigateTo(Pages.workorderNewTask, { workOrder: this.currentWorkOrder, workTask });
		}
		if (step.name === 'time') {
			this.lemListService.lemType = LEMType.labor;
			/**
			 * No creating LEM for now
			 */
			// if (!this.currentWorkorder.employeeCosts.length) {
			// 	this.workOrderProvider.createNewEmployeeCost();
			// 	const isAWO = this.dataChannelService.getWorkOrderMode() === WorkOrderModeType.Advanced;
			// 	isAWO ? NavigationService.navigateTo(Pages.workorderTime, { metric: this.metric }) : NavigationService.navigateTo(Pages.newLEMMenuPanel, { metric: this.metric });
			// 	return;
			// }
			this.lemListService.lemList = this.currentWorkOrder.employeeCosts;
			this.lemListService.lemHeader = 'employee time';
			this.lemListService.lemFooter = 'employees';
			const resolver = NoIconTwoLinesComponent.createResolver();
			resolver.getTopLabel = (item: Employee) => {
				if (!item.employeeId) return '';
				const employee = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(item.employeeId);
				return employee ? employee.name : '';
			};
			resolver.getBottomLeftLabel = (item: any) => {
				if (this.advancedMode) {
					const rateType = this.workOrderFactory.workOrderMetaData.employeeRates.getRateTypeDescription(item.rateType);
					return rateType ? rateType.description : '';
				}

				return item.rateType;
			};
			resolver.getBottomRightLabel = (item: any) => {
				return item.hours.toString() + ' hrs.';
			};
			this.lemListService.lemResolver = resolver;
			NavigationService.navigateTo(Pages.workorderLemList, { metric: this.metric, workOrder: this.currentWorkOrder });
			return;
		}
		if (step.name === 'materials') {
			this.lemListService.lemType = LEMType.material;
			/**
			 * No creating LEM for now
			 */
			// if (!this.currentWorkorder.materialCosts.length) {
			// 	this.workOrderProvider.createNewMaterialCost();
			// 	NavigationService.navigateTo(Pages.newLEMMenuPanel, { metric: this.metric });
			// 	return;
			// }
			this.lemListService.lemList = this.currentWorkOrder.materialCosts;
			this.lemListService.lemHeader = 'materials used';
			this.lemListService.lemFooter = 'total units';
			const resolver = NoIconTwoLinesComponent.createResolver();
			resolver.getTopLabel = (item: any) => {
				const material = this.workOrderFactory.workOrderMetaData.materials.getByMaterialId(item.materialId);
				return material ? material.description : item ? item.description : '';
			};
			resolver.getBottomLeftLabel = (item: any) => {
				return item ? '$' + item.totalCost.toString() : '-';
			};
			resolver.getBottomRightLabel = (item: any) => {
				return 'units: ' + item.units;
			};
			this.lemListService.lemResolver = resolver;
			NavigationService.navigateTo(Pages.workorderLemList, { metric: this.metric, workOrder: this.currentWorkOrder });
			return;
		}
		if (step.name === 'equipment') {
			this.lemListService.lemType = LEMType.equipment;
			/**
			 * No creating LEM for now
			 */
			// if (!this.currentWorkorder.equipmentCosts.length) {
			// 	this.workOrderProvider.createNewEquipmentCost();
			// 	NavigationService.navigateTo(Pages.newLEMMenuPanel, { metric: this.metric });
			// 	return;
			// }
			this.lemListService.lemList = this.currentWorkOrder.equipmentCosts;
			this.lemListService.lemHeader = 'equipment used';
			this.lemListService.lemFooter = 'total units';
			const resolver = NoIconTwoLinesComponent.createResolver();
			let equipmentItem: Equipment;
			resolver.getTopLabel = item => {
				equipmentItem = this.workOrderFactory.workOrderMetaData.equipments.find(e => e.equipmentId === (item.equipmentId ? item.equipmentId.toString() : item.rateType?.toString()));
				return equipmentItem ? equipmentItem.description : 'unavailable';
			};
			resolver.getBottomLeftLabel = item => {
				return equipmentItem ? equipmentItem.equipmentId.toString() : '';
			};
			resolver.getBottomRightLabel = item => {
				return item.hours + ' hrs.';
			};
			this.lemListService.lemResolver = resolver;
			NavigationService.navigateTo(Pages.workorderLemList, { metric: this.metric, workOrder: this.currentWorkOrder });
			return;
		}

		if (step.name === 'vendor') {
			this.lemListService.lemType = LEMType.vendor;
			/**
			 * No creating LEM for now
			 */
			// if (!this.currentWorkorder.equipmentCosts.length) {
			// 	this.workOrderProvider.createNewEquipmentCost();
			// 	NavigationService.navigateTo(Pages.newLEMMenuPanel, { metric: this.metric });
			// 	return;
			// }
			this.lemListService.lemList = this.currentWorkOrder.vendorCosts;
			this.lemListService.lemHeader = 'vendors used';
			this.lemListService.lemFooter = 'total units';
			const resolver = NoIconTwoLinesComponent.createResolver();
			resolver.getTopLabel = (item: VendorCost) => {
				return item ? item.description : '';
			};
			resolver.getBottomLeftLabel = (item: VendorCost) => {
				return item ? '$' + item.totalCost.toString() : '-';
			};
			resolver.getBottomRightLabel = item => {
				return 'units: ' + (item as VendorCost).units;
			};
			this.lemListService.lemResolver = resolver;
			NavigationService.navigateTo(Pages.workorderLemList, { metric: this.metric, workOrder: this.currentWorkOrder });
			return;
		}

		if (step.name === 'failure report') {
			if (!this.currentWorkOrder.failureReports || this.currentWorkOrder.failureReports.length === 0) return;
			NavigationService.navigateTo(Pages.workOrderFailureReport, { failureResults: this.currentWorkOrder.failureReports });
			return;
		}

		if (step.isHistoryRecord) {
			const { assetDefinition } = this.currentWorkOrder?.historyRecord;
			if (!this.currentWorkOrder.historyRecord || !assetDefinition) return;

			const historyFormDefinition = assetDefinition.forms.find(f => f.workType === this.currentWorkOrder.workType);
			if (!historyFormDefinition) return;

			const assetRecord = await this.getAssetRecord(this.currentWorkOrder?.historyRecord);
			NavigationService.navigateTo(Pages.historyRecordsForm, {
				context: new RecordContextGroup({ historyRecord: this.currentWorkOrder.historyRecord, assetRecord }),
				historyFormDefinition
			});
			return;
		}

		const pageKeyToGo = Object.keys(Pages).find(pageKey => pageKey.toLowerCase().includes(`workorder${step.name}`));
		NavigationService.navigateTo(Pages[pageKeyToGo], {
			metric: this.metric,
			workOrder: this.currentWorkOrder,
			advancedMode: this.advancedMode,
			isReadOnly: this.isReadOnly
		});
	}

	private async getAssetRecord(historyRecord: HistoryRecord): Promise<AssetRecord> {
		const historyFieldName = historyRecord?.assetDefinition?.historyChannel?.attributes['assetIdFieldName'];
		if (!historyFieldName) return null;

		const normalizedFieldName = Object.keys(historyRecord?.feature?.attributes).find(k => k.toLowerCase() === historyFieldName?.toLowerCase());
		if (!normalizedFieldName) return null;

		const assetKey = historyRecord?.feature?.attributes[normalizedFieldName];
		const features = await this.interop.arcGISManager.getAssetLayerFeatures(historyRecord?.assetType, [assetKey]);
		if (!features || !features.length) return null;

		return AssetRecord.create(historyRecord?.assetDefinition, Feature.fromEsriFeature(features[0]));
	}

	async getRelatedWorkorders() {
		// The getRelatedWorkOrder function will return a new instance of this.currentworkorder itself
		this.relatedWorkorders = await this.workOrderFactory.getRelatedWorkOrders(this.currentWorkOrder);
		// We filter out the duplicated instance
		this.relatedWorkorders = this.relatedWorkorders.filter(wo => wo.workOrderKey !== this.currentWorkOrder.workOrderKey);
		// And push in the orginal instance to keep a single instance in memory
		this.relatedWorkorders.push(this.currentWorkOrder);

		this.relatedWorkorders.sort((a, b) => (a.workOrderKey < b.workOrderKey ? 1 : b.workOrderKey < a.workOrderKey ? -1 : 0));
		const index = this.relatedWorkorders.findIndex(workorder => {
			return !workorder.parentWorkOrderKey;
		});
		this.relatedWorkorders.unshift(this.relatedWorkorders.splice(index, 1)[0]);
		this.relatedWorkorderCount = this.relatedWorkorders ? this.relatedWorkorders.length - 1 : 0;
	}

	goToRelatedWorkorder() {
		if (this.isLoadingWO) return;

		NavigationService.navigateTo(Pages.relatedWorkorders, {
			relatedWorkorders: this.relatedWorkorders,
			currentWorkOrder: this.currentWorkOrder,
			advancedMode: this.advancedMode,
			metric: this.metric
		});
	}

	async loadWO() {
		try {
			const detailedWorkOrder = await this.workOrderFactory.getWorkOrders([this.currentWorkOrder.workOrderKey], false);
			if (!detailedWorkOrder) return;
			// The workOrderFactory.getWorkOrders() returns all records that contain the workorderkey.
			// This code will find the work order that matches the workorderkey, instead of taking the first
			// item like it was doing previously, which was a bug showing the wrong work order.
			// If the api returns only a single record, then this code will not affect performance,
			// but if it returns say thousands, it might take sometime to filter, but atleast it will show the corrct
			// work order.
			const workOrder = detailedWorkOrder?.find(t => t.workOrderKey == this.currentWorkOrder.workOrderKey);
			if (this.currentWorkOrder.updateInformation && workOrder) this.currentWorkOrder.updateInformation(workOrder);
			this.setMenuPanelComponent();
			this.menuPanelComponent.disableBackButton = false;
			this.setReadOnly();
			this.getRelatedWorkorders();
			this.updateOutlineSteps();
		} finally {
			this.isLoadingWO = false;
			this.menuPanelComponent.updatePacifier(false);
		}
	}

	private setReadOnly(): void {
		this.isReadOnly = this.workOrderFactory.isWorkOrderReadOnly(this.currentWorkOrder);
	}
}
