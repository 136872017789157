import { RestServiceResponse } from './service-response';
import { ResultBase } from './result-base';
import { Feature } from './feature';
import { Server } from './server';
import { GeometryType, Geometry } from './geometry';

export class FindResultItem {
	public displayFieldName: string;
	public feature: Feature;
	public foundFieldName: string;
	public layerId: number;
	public layerName: string;
	public value: any;
	public geometryType: GeometryType;
	public geometry: any;

	public static fromJson(jsonData: any, parentServer: Server): FindResultItem {
		const item = new FindResultItem();

		Object.assign(item, jsonData);

		// Geometry Type
		if (jsonData.geometryType) {
			const casted = jsonData.geometryType as keyof GeometryType;
			item.geometryType = GeometryType[casted];
		}

		// Geometry
		if (jsonData.geometry) {
			item.geometry = Geometry.fromJson(jsonData);
		}

		item.feature = Feature.fromEsriFeature(jsonData);
		item.feature.geometry = item.geometry;

		return item;
	}
}

export class FindResult extends ResultBase {
	constructor(response: RestServiceResponse) {
		super(response);
	}

	private _results: Array<FindResultItem>;
	public get results(): Array<FindResultItem> {
		if (!this._results) this._results = new Array<FindResultItem>();

		return this._results;
	}

	static fromJson(serviceResult: RestServiceResponse, server: Server): FindResult {
		const result = new FindResult(serviceResult);
		const jObject = serviceResult.responseJsonToken;
		if (!jObject.results) return result;

		for (const jItem of jObject.results) {
			result.results.push(FindResultItem.fromJson(jItem, server));
		}
		return result;
	}
}
