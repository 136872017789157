import { FailureReport } from './failure-report.model';
import { FailureReportContract } from '../../contracts/work-order';

export class FailureReports extends Array<FailureReport> {
    static fromContracts(failureReportContracts: FailureReportContract[]): FailureReports {
        const failureReports = new FailureReports();
        for (const failureReportContract of failureReportContracts) failureReports.push(FailureReport.fromContract(failureReportContract));
        return failureReports;
    }

    static fromModels(failureReportModels: FailureReport[]): FailureReports {
		const failureReports = new FailureReports();
		for (const workComment of failureReportModels) failureReports.push(workComment);
		return failureReports;
	}

    getContracts = (): FailureReportContract[] => {
        const failureReportContracts = new Array<FailureReportContract>();
        for (const failureReport of this) failureReportContracts.push(failureReport.getContract());
        return failureReportContracts;
    }

    findByCodeType = (codeType: string): FailureReport => this.find(field => field.codeType === codeType);

    isDirty = (): boolean => !!this.filter(field => field.isDirty).length;
}
