<div class="asset-information">
	<app-three-component-navs [tabHeader1]="'information'" [tabHeader2]="'work orders'"
		[tabHeader2badge]="workOrders.length > 0 ? workOrders.length.toString() : ''" [tabHeader3]="'history'"
		[tabHeader3badge]="tabHeader3badge" [activeTab]="activeTab">
		<div class="component1">
			<app-info-form *ngIf="context && !context.isHierarchyRecord" [formDefinition]="assetRecord.assetDefinition.informationForm" [contextGroup]="context"
				[assetDefinition]="assetRecord.assetDefinition" [asset]="assetRecord.feature" [trendableRecordCount]="trendableRecordCount">
			</app-info-form>
			<app-hierarchy-asset-form *ngIf="context && context.isHierarchyRecord" [recordContext]="context">
			</app-hierarchy-asset-form>
		</div>
		<div class="component2">
			<div class="list-wrapper">
				<div *ngIf="workOrders.length == 0">
					<img class="workorder-list-thumbnail" src="assets/assets/no-workorders.png" />
					<div class="workorder-list-text">No active work orders</div>
				</div>
				<app-workorder-list *ngIf="workOrders.length > 0" class="component2" [workorders]="workOrders" [advancedMode]="isAdvancedWorkOrder"
					(workorderSelected)="workorderSelected($event)"> </app-workorder-list>
			</div>
		</div>
		<div class="component3">
			<div class="list-wrapper">
				<div *ngIf="(workOrderType === 'standard' && !historyRecords.length) || (workOrderType === 'advanced' && !completedWorkOrders.length)">
					<img class="workorder-list-thumbnail" src="assets/assets/dateselect.png" />
					<div class="workorder-list-text">No work history</div>
				</div>
				<app-omni-list [hidden]="!(workOrderType === 'standard')" class="component3"
					[omniListItems]="historyRecords" (itemClicked)="historyRecordSelected($event)">
				</app-omni-list>
				
				<app-workorder-list *ngIf="workOrderType === 'advanced'" class="component3"
				[workorders]="completedWorkOrders" (workorderSelected)="workorderSelected($event)"
				[advancedMode]="isAdvancedWorkOrder">
				</app-workorder-list>
			</div>
		</div>
	</app-three-component-navs>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end" *ngIf="isWorkAssetAdd">
		<button mat-button class="omni-md-font" (click)="apply()">add selected<img class="omni-sm-img add-selected-img" src="assets/done.png" /></button>
	</mat-card-actions>
</mat-card-footer>
