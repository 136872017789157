import { ContextType } from './value-context-type.enum';

export class ValueContext {
	fieldname: string;
	formula: any;
	type: ContextType;
	sourceProviderInfo: any;
	formValueProviderInfo: any;
	name: string;
	get isValid() {
		if (!this.type) return false;
		if (this.type === ContextType.Formula && !this.formula) return false;
		if (this.type === ContextType.FormValueProvider && !this.formValueProviderInfo) return false;
		if (this.type === ContextType.Asset && !this.fieldname) return false;
		if (this.type === ContextType.History && !this.fieldname) return false;
		if (this.type === ContextType.SourceProvider && !this.sourceProviderInfo) return false;
		return true;
	}
}
