import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { expandInShrinkOut } from 'app/app.animations';
import { Color } from 'models';
import { ToastController } from './toast-controller';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [expandInShrinkOut]
})
export class ToastComponent implements OnInit {
	controller: ToastController;
	private postition: 'bottom' | 'top' = 'bottom';
	@Output() toastDismissed = new EventEmitter();

	message() {
		if (!this.controller) return '';
		return this.controller.getMessageText();
	}
	buttonLabel() {
		if (!this.controller) return '';
		return this.controller.buttonLabel();
	}
	show() {
		if (!this.controller) return false;
		return this.controller.show();
	}
	onButtonClicked() {
		if (!this.controller) return;
		this.toastDismissed.emit();
		this.controller.buttonClicked();
	}


	getPosition() {
		if (this.postition === 'bottom') return { bottom: '5px' };
		return { top: '0' };
	}
	getButtonColor() {
		if (!this.controller) return ''
		return this.controller.getButtonColor().toString();
	}
	constructor() { }

	ngOnInit(): void { }
}
