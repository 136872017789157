<div class="workorder-task" #workorderTaskContainer>
	<label class="task-header">{{ taskDescription }}</label>
	<mat-divider class="task-divider"></mat-divider>
	<div #scrollingWrapper class="fields" (scroll)="onScroll()">
		<ng-container *ngIf="fieldsLoaded">
			<app-menu-panel-field
				*ngFor="let field of taskFields"
				[header]="field.header"
				[(value)]="field.value"
				[cssClass]="field.className"
				[type]="field.type"
				[goTo]="field.goTo"
				[buttonEnabled]="field.button"
				[disabled]="field.disabled"
				(valueChange)="field.onChange($event)"
			></app-menu-panel-field
		></ng-container>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" [class.omni-button-disabled]="isReadOnly" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
