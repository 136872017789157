import { EmployeeRate } from './employee-rate.model';
import { EmployeeRateContract } from '../../contracts/work-order';

export class EmployeeRates extends Array<EmployeeRate> {
	static fromContracts(employeeRatesContracts: EmployeeRateContract[]) {
		const employeeRates = new EmployeeRates();
		if (!employeeRatesContracts) return employeeRates;

		for (const employeeRatesContract of employeeRatesContracts) {
			const employeeRate = EmployeeRate.fromContract(employeeRatesContract);
			employeeRates.push(employeeRate);
		}
		return employeeRates;
	}

	getRateTypeDescription = (rateType: string) => this.find(rate => rate.rateCode === rateType);
}
