import { WorkCommentContract } from './../../contracts/work-order/work-comment-contract';
import { WorkComment } from './work-comment.model';
export class WorkComments extends Array<WorkComment> {
	static fromContracts(workCommentContracts: WorkCommentContract[]) {
		const workComments = new WorkComments();
		for (const workCommentContract of workCommentContracts) {
			const workComment = WorkComment.fromContract(workCommentContract);
			workComments.push(workComment);
		}
		return workComments;
	}

	static fromModels(workCommentModels: WorkComment[]): WorkComments {
		const workComments = new WorkComments();
		for (const workComment of workCommentModels) workComments.push(workComment);
		return workComments;
	}

	getContracts = () => {
		const workCommentContracts = new Array<WorkCommentContract>();
		for (const workComment of this) {
			const workCommentContract = workComment.getContract();
			workCommentContracts.push(workCommentContract);
		}
		return workCommentContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = () => this.forEach(workComment => (workComment.isDirty ? workComment.clearDirty() : null));
}
