import { HistoryRecord } from 'models/records/history-record.model';
import { Feature, Server } from 'sedaru-util/esri-core';
import { ArcGISHistoryChannelAttributes, AssetDefinition, Channel } from '../models';

export class HistoryProvider {
    private _server: Server;
    private _historyChannel: Channel;
    private _assetDefinition: AssetDefinition;

    get attributes(): ArcGISHistoryChannelAttributes {
        return this._historyChannel?.attributes as ArcGISHistoryChannelAttributes;
    }

    constructor(server: Server, historyChannel: Channel, assetDefinition: AssetDefinition) {
        this._server = server;
        this._historyChannel = historyChannel;
        this._assetDefinition = assetDefinition
    }

    private getQuery(assetId: number | string): string {
        let fieldName = 'assetkey';
        if (this.attributes) fieldName = this.attributes.assetIdFieldName;
        return `${fieldName}='` + assetId + "'";
    }

    async getHistoryRecordsByAssetId(assetId: number | string): Promise<HistoryRecord[]> {
        const historyRecords = new Array<HistoryRecord>();
        const historyLayer = this._server.layers.find(l => l.id === this.attributes.layerIndex);
        if (!assetId || !historyLayer) return historyRecords;

        const features = await historyLayer.query(this.getQuery(assetId), '*', true);
        for (const f of features) {
            const attributes = typeof f.attributes === 'string' ? JSON.parse(f.attributes) : f.attributes;
			const geometry = typeof f.geometry === 'string' ? JSON.parse(f.geometry) : f.geometry;
			const feature = Feature.fromEsriFeature({ attributes, geometry });
            const historyRecord = HistoryRecord.create(this._assetDefinition, feature);
            historyRecords.push(historyRecord);
        }

        return historyRecords;
    }

    async getHistoryRecordsByWorkOrderKeys(workOrderKeys: string[], idOnly = false): Promise<HistoryRecord[]> {
        const historyRecords = new Array<HistoryRecord>();
        const historyLayer = this._server.layers.find(l => l.id === this.attributes.layerIndex);
        if (!historyLayer) return historyRecords;

        let features: any[];
        features = await historyLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderkey' : '*');
        if (!features || !features.length) features = await historyLayer.query(`workorderid IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderid' : '*');
        if (!features || !features.length) return historyRecords;

        for (const f of features) {
            const attributes = typeof f.attributes === 'string' ? JSON.parse(f.attributes) : f.attributes;
            const geometry = typeof f.geometry === 'string' ? JSON.parse(f.geometry) : f.geometry;
            const feature = Feature.fromEsriFeature({ attributes, geometry });
            historyRecords.push(HistoryRecord.create(this._assetDefinition, feature));
        }

        return historyRecords;
    }
}
