import { Injectable, EventEmitter } from '@angular/core';
import { CanvasTrendHeader } from '../../../omni-model/canvas-trend-header.model';
import { CanvasTrend } from 'omni-model/canvas-trend.model';
import { ArcGISHistoryChannelAttributes, ChannelTypes, Metric, SedaruWorkOrderChannelAttributes, TypeOfValueEnum } from 'models';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ArcGisFeature } from 'app/metric/arc-gis-feature.model';

@Injectable({
	providedIn: 'root'
})
/**
 * Provides the methods to recieve and manipulate the data to be populated in the chart.
 */
export class CanvasTableService {
	private updateTableColorSubject = new Subject<any>();
	updateTableColorObservable = this.updateTableColorSubject.asObservable();

	constructor(private interopService: OmniInteropService) {}

	/**
	 * The method sets the trend header values calculated from the trend data passed.
	 * @param {any} trendData The data which will be populated on the trend
	 */

	updateTableBackgroundColor(metricName: string, color: string) {
		this.updateTableColorSubject.next({ metricName: metricName, color: color });
	}
}
