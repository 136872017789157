import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from '../../menu-panel/menu-panel-base/menu-panel-base.component';
import { Attachments } from 'models/work-order/attachments.model';
import { UploaderService } from '../attachment/uploader.service';
import { throwError, Subscription } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { IHttpResponse } from 'domain-service/http-response.model';
import { WorkOrderService } from 'domain-service/work-order.service';
import { Attachment, WorkOrderWrapper } from 'models/work-order';
import { AttachmentContract } from 'contracts/work-order';
import { FileResponseContract } from 'contracts/file-response-contract';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-workorder-attachments',
	templateUrl: './workorder-attachments.component.html',
	styleUrls: ['./workorder-attachments.component.scss']
})
export class WorkOrderAttachmentsComponent extends MenuPanelBaseComponent {
	currentWorkOrder: WorkOrderWrapper;

	attachments: any[] = [];

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	uploadedFile: File;

	attachmentModel: Attachment;

	isReadOnly = false;

	private uploadResponseSubscription: Subscription;

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		private workOrderFactory: WorkOrderFactory,
		private uploaderService: UploaderService,
		private workorderService: WorkOrderService,
		private interop: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.currentWorkOrder = args.parameter.workOrder;
		this.isReadOnly = this.workOrderFactory.isWorkOrderReadOnly(this.currentWorkOrder);

		this.menuPanelComponent.updateView({
			title: this.currentWorkOrder.workOrderKey,
			rightIcon: { url: 'assets/plus.png', toolTip: '', callBackFunction: this.toggleAttachmentType.bind(this) },
			backgroundClass: 'orange-background',
			disableRightButton: this.isReadOnly
		});

		this.uploadResponseSubscription = this.uploaderService.uploadResponse$.subscribe((attachmentUpload) => {
			try {
				if (!attachmentUpload || !attachmentUpload.isSuccess) {

					this.alertDialog.mainMessage = { text: `Unable to upload attachment ${attachmentUpload?.file?.name}` };
					this.alertDialog.open = true;
					return;
				}

				this.attachmentAppendHandler(attachmentUpload.attachmentModel, attachmentUpload.response.Result.Files[0]);
			} finally {
				this.loadingHeaderToggle(false);
			}
		});

		const attachments = this.currentWorkOrder?.attachments?.filter(a => a && a.url && a.url.startsWith('https://'));
		if (!attachments || !attachments.length) return;

		attachments.sort((a1: any, a2: any) => a2.attachedDate - a1.attachedDate);
		const attachmentFileIndexes = [];
		for (const attachment of attachments) {
			if (!attachment.fileIndexId) continue;
			attachmentFileIndexes.push(attachment.fileIndexId);
		}

		this.loadingHeaderToggle(true);

		this.uploaderService.getPublicFileIndexes(attachmentFileIndexes).subscribe(async result => {
			this.loadingHeaderToggle(false);
			const attachmentModels = new Array<any>();
			for (const att of attachments) {
				attachmentModels.push({
					thumbnailPath: this.getThumbnailPath(att.url),
					attachmentName: this.clipImageNameFromUrl(att.url),
					uploadTimeStamp: att.attachedDate ? att.attachedDate.toLocaleString() : '',
					imagePath: att.url,
					fileIndexId: att.fileIndexId
				});
			}

			try {
				if (!result || !result.Success) return;
				const files = result.Result.Files;
				if (!files || !files.length) return;

				for (const file of files) {
					const foundAttachment = attachmentModels.find(a => a.fileIndexId === Number(file.FileIndexId));
					if (!foundAttachment) continue;

					const thumbnailPath = (file && file.Success && file.UrlThumbnail && file.UrlThumbnail.length)
						? file.UrlThumbnail : foundAttachment.imagePath;

					foundAttachment.attachedName = file.FileName;
					foundAttachment.thumbnailPath = this.getThumbnailPath(thumbnailPath);
				}
			} finally {
				this.attachments.push(...attachmentModels);
			}
		});
	}

	ngOnDestroy() {
		if (this.uploadResponseSubscription) this.uploadResponseSubscription.unsubscribe();
	}

	private clipImageNameFromUrl(url: string): string {
		const urlChars = url.split('/');
		return urlChars[urlChars.length - 1];
	}

	fileChange(element) {
		if (this.isReadOnly) return;
		this.uploadedFile = element.target.files[0];
		this.uploadFile(this.uploadedFile);
	}

	uploadFile(uploadedFile) {
		if (!uploadedFile) return;
		this.loadingHeaderToggle(true);
		this.uploaderService.enqueueImage(this.workOrderFactory.getWorkOrderAttachmentUpload(uploadedFile, this.currentWorkOrder));
	}

	attachmentAppendHandler(attachmentModel: Attachment, fileDetails?: FileResponseContract) {

		const thumbnailPath = fileDetails && fileDetails.UrlThumbnail ? fileDetails.UrlThumbnail : attachmentModel.url;

		const attachmentData = {
			thumbnailPath: this.getThumbnailPath(thumbnailPath),
			attachmentName: fileDetails ? fileDetails.FileName : '',
			uploadTimeStamp: attachmentModel.attachedDate ? attachmentModel.attachedDate.toLocaleString() : '',
			imagePath: attachmentModel.url ? attachmentModel.url : '',
			fileIndexId: fileDetails.FileIndexId
		};
		this.attachments.unshift(attachmentData);
		this.currentWorkOrder.attachments.unshift(attachmentModel);
	}

	loadingHeaderToggle(isLoadingShow = false) {
		this.menuPanelComponent.updatePacifier(isLoadingShow);
	}

	deleteAttachment(fileIndex) {
		if (this.isReadOnly) return;

		const attachmentFileIndexes = [fileIndex];
		const attachmentContractList: AttachmentContract[] = [];
		const attachments: Attachments = this.currentWorkOrder.attachments;
		const attachmentContractIndex = attachments.findIndex(attachment => attachment.fileIndexId.toString() === fileIndex.toString());
		const selectedAttachmentContract = attachments[attachmentContractIndex];
		if (!selectedAttachmentContract) return;

		attachmentContractList.push(selectedAttachmentContract.getContract());
		this.loadingHeaderToggle(true);
		this.deleteAction(attachmentContractList, attachmentFileIndexes).subscribe(
			result => {
				this.loadingHeaderToggle(false);
				if (!result.Success) throwError(result.Message);

				const removedAttachmentIndex = this.attachments.findIndex(attachment => attachment.fileIndexId.toString() === fileIndex.toString());
				const newAttachments = [...this.attachments];
				newAttachments.splice(removedAttachmentIndex, 1);
				this.attachments = newAttachments;

				const newCurrentWorderAttachment: any = [...this.currentWorkOrder.attachments];
				newCurrentWorderAttachment.splice(attachmentContractIndex, 1);
				this.currentWorkOrder.attachments = newCurrentWorderAttachment;
			},
			catchError(err => this.uploaderService.handlerError(err))
		);
	}

	deleteAction(attachmentContractList, attachmentFileIndexes) {
		return this.workorderService.deleteAttachment(attachmentContractList).pipe(
			mergeMap((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				return this.uploaderService.deleteFileIndex(attachmentFileIndexes);
			}),
			catchError(err => this.uploaderService.handlerError(err))
		);
	}

	toggleAttachmentType() {
		this.fileInput.nativeElement.click();
	}

	getThumbnailPath(thumbnailPath) {
		if (!thumbnailPath) return;

		if (thumbnailPath.match(/.(jpg|jpeg|png|gif)$/i)) {
			thumbnailPath = thumbnailPath;
		} else if (thumbnailPath.match(/.(pdf)$/i)) {
			thumbnailPath = 'assets/attachment/att_pdf.png';
		} else if (thumbnailPath.match(/.(docx|doc)$/i)) {
			thumbnailPath = 'assets/attachment/att_doc.png';
		} else if (thumbnailPath.match(/.(xlsx|xlsm|xlsb|xltx)$/i)) {
			thumbnailPath = 'assets/attachment/att_xls.png';
		} else if (thumbnailPath.match(/.(mp4|mov|3gp|wmv|webm|flv|avi|mpeg|mpeg-4|vob|wav)$/i)) {
			thumbnailPath = 'assets/attachment/att_video.png';
		} else {
			thumbnailPath = 'assets/attachment/att_universal.png';
		}

		return thumbnailPath;
	}

}
