import { RootDataService } from 'domain-service/root-data.service';
import { environment } from './../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { User } from './user.model';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as SedaruUtil from 'sedaru-util';
import { OmniCookie } from './omni-cookie';
import { MembershipHttpService } from 'domain-service/http-services/membership-http.service';
import { IdentityController } from 'domain-service/http-services/identity-controller';
import { SedaruOAuthToken } from 'domain-service/http-services/sedaru-oauth-token';
import { OAuthService } from 'domain-service/http-services/oauth-service';
import { TokenPair } from 'domain-service/http-services/token-pair';
import { AppEnvironmentService } from 'domain-service/app-environment.service';
import { GlobalConfig } from 'models/global-config.model';
import { Transform } from 'models/sedaru-config/transform/transform';
import { ProfileService } from 'domain-service/http-services/profile-service';
import { ProfileGroups } from './profile-groups.model';

/**
 * The user service is used for handling communcation to user servers, user session
 * and keeping track of the current user.
 */
@Injectable({
	providedIn: 'root'
})
export class UserService {
	private _identityController: IdentityController;
	public get identityController(): IdentityController {
		return this._identityController;
	}
	private _oauthService: OAuthService;
	private _membershipService: MembershipHttpService;
	private _profileService: ProfileService;

	/**
	 * Alert when current user changes
	 */
	onCurrentUserChanges: EventEmitter<User> = new EventEmitter();

	private _globalConfig: GlobalConfig;
	public get globalConfig(): GlobalConfig {
		return this._globalConfig;
	}

	/**
	 * The current logged in user
	 */
	private _currentUser: User;
	public get currentUser(): User {
		return this._currentUser;
	}

	OMNICOOKIENAME = 'omnicookie';
	COOKIE_DOMAIN = environment.cookieSettings.domain;
	COOKIE_SAMESITE = environment.cookieSettings.sameSite as 'Lax'|'Strict'|'None';

	/**
	 * Loads the current user from the session or creates a new empty user if a session doesn't exist.
	 * @param {HttpClient} http - The Angular module that allows and facilitates server communication
	 */
	constructor(private http: HttpClient, private cookieService: CookieService, private appEnvironment: AppEnvironmentService) {
		// This will eventually come from session
		this._currentUser = new User();
	}

	async initialize(notificationObject: SedaruUtil.NotificationObject<string>): Promise<boolean> {
		const omniCookie = this.getCookie();

		if (!omniCookie) return false;

		this._oauthService = new OAuthService(environment.oauthUrl, this.http);
		this._identityController = new IdentityController(this._oauthService, new TokenPair(omniCookie.Token, omniCookie.SedaruRefreshToken));
		this._identityController.onTokenRefreshed.subscribe((sender, token) => {
			this.updateCookie(token as TokenPair);
		});
		this._membershipService = new MembershipHttpService(environment.infraApiUrl + 'MembershipProviderService.svc', this.http, this._identityController);
		this._profileService = new ProfileService(environment.infraApiUrl + 'ProfileService.svc', this.http, this._identityController);

		let user: User;
		/*if (omniCookie.isPortalEnabled) {
				user = await this.validateUser(omniCookie.CustomerCode, omniCookie.PortalToken);
				user.portalToken = omniCookie.PortalToken;
				user.portalRefreshToken = omniCookie.PortalRefreshToken;
			} else {
				user = await this.authenticateUser(omniCookie.CustomerCode, omniCookie.Token);
				user.sedaruRefreshToken = omniCookie.SedaruRefreshToken;
			}*/

		notificationObject.notify('authenticating user');
		user = await this.authenticateUser();

		if ((!user) || (user.status?.toLocaleLowerCase() !== 'active')) {
			this.cookieService.delete(this.OMNICOOKIENAME, '/', this.COOKIE_DOMAIN);
			notificationObject.notify('unable to authenticate user');
			return false;
		}

		user.customerCode = omniCookie.CustomerCode.toLowerCase();

		notificationObject.notify('fetching global profile');
		const globalProfile = await this.getProfile(user.customerCode, omniCookie.UserProfileGroup);
		user.isProfileGroupEnabled = omniCookie.UserProfileGroup ? true : false;
		user.profileGroup = omniCookie.UserProfileGroup;
		ProfileGroups.checkSelectedProfile(user.profileGroupList, user.profileGroup)

		if (!globalProfile) {
			notificationObject.notify('unable to get profile for ' + user.userName);
			return false;
		}

		this._currentUser = user;
		this._globalConfig = globalProfile;

		notificationObject.notify('success initializing');

		this.onCurrentUserChanges.emit(user);
		return true;
	}

	/**
	 * If cookie exist in session, it will validate by getting the user detials from token.
	 */
	private getCookie(): OmniCookie {
		const sessionCookieValue = this.cookieService.get(this.OMNICOOKIENAME);

		if (!sessionCookieValue) {
			return null;
		}

		const cookie = JSON.parse(sessionCookieValue);

		const omniCookie = new OmniCookie();
		Object.assign(omniCookie, cookie);

		if (!omniCookie.isValid) {
			this.cookieService.delete(this.OMNICOOKIENAME, '/', this.COOKIE_DOMAIN);
			return null;
		}

		return omniCookie;
	}

	private updateCookie(sedaruToken: TokenPair) {
		let existingCookie = this.getCookie();

		if (!existingCookie) {
			existingCookie = new OmniCookie();
		}

		if (sedaruToken) {
			existingCookie.SedaruRefreshToken = sedaruToken.refreshToken;
			existingCookie.Token = sedaruToken.accessToken;
		}

		this.cookieService.delete(this.OMNICOOKIENAME);
		this.cookieService.set(this.OMNICOOKIENAME, JSON.stringify(existingCookie), undefined, '/', this.COOKIE_DOMAIN, undefined, this.COOKIE_SAMESITE);
	}

	updateProfileGroupCookie(profileGroupName) {
		const existingCookie = this.getCookie();

		if (!existingCookie) {
			return;
		}

		existingCookie.UserProfileGroup = profileGroupName;

		this.cookieService.set(this.OMNICOOKIENAME, JSON.stringify(existingCookie), undefined, '/', this.COOKIE_DOMAIN, undefined, this.COOKIE_SAMESITE);
	}

	private async authenticateUser(): Promise<User> {
		const { result: user } = await this._membershipService.userDetailsFromToken();

		if (!user) return null;

		const { result: role } = await this._membershipService.getUserRoles(this.getCookie().CustomerCode.toLowerCase(), user.userName);
		user.isSSO = this.getCookie().isPortalEnabled;
		user.role = role
		const { result: profileGroups } = await this._profileService.getProfileGroups(this.getCookie().CustomerCode.toLowerCase(), user.userName);
		console.log(profileGroups);
		user.profileGroupList = profileGroups;
		return user
	}

	private async validateUser(customerCode: string, portalToken: string): Promise<User> {
		const response = await this._membershipService.validateUser(portalToken, customerCode);
		if (!response.success) {
			return null;
		}

		return response.result;
	}

	private async getProfile(customerCode: string, userProfileGroup: string): Promise<GlobalConfig> {
		let result;

		if (userProfileGroup) {
			result = await this._profileService.getUniversalProfileConfigWithProfileGroup(customerCode, userProfileGroup);
		} else {
			result = await this._membershipService.getUniversalProfileConfiguration();
		}

		if (!result.success) {
			return null;
		}

		const transform = new Transform(customerCode, result.result);
		const transFormedResult = transform.transform();

		if (!transFormedResult.success) return undefined;

		return transform.globalConfig;
	}

	/**
	 * Logs the current user out by notifing the user server and resetting the "current user" variable.
	 */
	logout() {
		// TODO: put to server a logout request
		this.cookieService.delete(this.OMNICOOKIENAME, '/', this.COOKIE_DOMAIN);
		const customerCode = this.currentUser.customerCode;
		this._currentUser = new User();
		this.onCurrentUserChanges.emit(this.currentUser);
		const currentEnvironment = this.appEnvironment.currentEnvironment;

		if (this.appEnvironment.isLocalhost) {
			window.location.href = `${this.appEnvironment.loginUrl}`;
		} else if (currentEnvironment === 3) {
			window.location.href = `${this.appEnvironment.loginUrl}/Home/Logout`;
		} else if (currentEnvironment === 0) { // testing environment
			const loginUrl = this.getProtocolResolvedUrl(this.appEnvironment.loginUrl);
			window.location.href = `${loginUrl}/Home/Logout?customerCode=${customerCode}`;
		} else {
			window.location.href = `${this.appEnvironment.loginUrl}/Home/Logout?customerCode=${customerCode}`;
		}
	}

	/** Method to get method with http protocol adjusted against the current window location */
	getProtocolResolvedUrl(url: string) {
		if (this.isServedSecure()) {
			url = url.replace(/^http:\/\//, 'https://');
		}

		return url;
	}

	/** Method to check the current location is secure or not */
	isServedSecure(): boolean {
		const url = window.location.href;
		if (!url) {
			return false;
		}
		return url.toLowerCase().startsWith('https');
	}

	/**
	 * Handles errors generated by the HTTP communcation.
	 * @param {HttpErrorResponse} error - The error object retured from a failed HTTP request.
	 * @returns {Observable<never>} - Returns a thrown error utilizing the angular "throwError" function.
	 */
	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			return throwError(error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ${error.statusText} \n` + `Body was: ${error.error}`);
			// return an observable with a user-facing error message
			return throwError(error.error);
		}
	}

	/**
	 * Get's the customer code from the current user after error handling.
	 * Throws an error if there is no current user or no customer code for the current user.
	 * @returns {string} - Returns the current customers code.
	 */
	getCurrentCustomerCode = () => {
		if (!this.currentUser || !this.currentUser.customerCode) {
			const errorMessage = 'A user must be logged in with a valid customer code';
			console.error(errorMessage);
			throw new Error(errorMessage);
		}
		return this.currentUser.customerCode;
	};

	getToken = () => {
		const omniCookie = this.getCookie();
		return omniCookie ? omniCookie.Token : null;
	};

	isPortalEnabled = () => {
		const omniCookie = this.getCookie();
		return omniCookie && omniCookie.isPortalEnabled ? { portalToken: omniCookie.PortalToken, refreshToken: omniCookie.PortalRefreshToken } : null;
	};

	updatePortalToken = (newSSOToken: string) => {
		const existingCookie = this.getCookie();
		existingCookie.PortalToken = newSSOToken;

		this.cookieService.set(this.OMNICOOKIENAME, JSON.stringify(existingCookie), undefined, '/', '.sedaru.com', undefined, 'None');
	};

	getCurrentProfileGroup = () => {
		if (!this.currentUser || !this.currentUser.customerCode) {
			const errorMessage = 'A user must be logged in with a valid customer code';
			console.error(errorMessage);
			throw new Error(errorMessage);
		}
		return this.currentUser.profileGroup;
	};

	async changePassword(password, newPassword) {
		const result = await this._membershipService.updatePassword(this.getCookie().CustomerCode.toLowerCase(), this.currentUser.userName, password, newPassword);
		return result.serviceResponse.defaultResponse.result;
	}

	async updateUser() {
		const result = await this._membershipService.updateUser(this.currentUser);
		return result.serviceResponse.defaultResponse.result;
	}
}
