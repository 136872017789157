import {gql, Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import { Metric, MetricInput } from 'models/metric.model';
import { MetricIcon } from '../models/metric-icon.model';


import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TypeOfValueEnum, QueryStatementList, QueryMode, ChannelTypes, Color, MetricTile, MetricTypes } from '../models';
import { UserService } from 'app/user/user.service';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HeadlessMetricServerResponse } from './headless-metric-server-response.model';
import { PNGManager } from 'app/ui-components/png-manager';
import { TileDateFrame } from 'models/tile-date-frame';
import { RefreshWorkOrderMetric } from './metrics/refresh-work-order-metric.model';
import { RefreshAssetMetric } from './metrics/refresh-asset-metric.model';
import { AGSQuery } from 'models/ags-query.model';
import { AWOQuery } from 'models/awo-query.model';
import { CustomQuery } from 'models/custom-query.model';
import { TimeframedMetric } from 'models/time-frame/timeframed-metric.model';
import { MetricDeltasRequest, MetricDeltasResponse, MetricLastChangedResponse } from './metrics/request-result.model';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { TimeframeDefinitionType } from 'models/time-frame/timeframe-definition-type.enum';

@Injectable({
	providedIn: 'root'
})
export class MetricService {
	/**
	 * Holds an array of all the metrices available.
	 */
	availableMetrics: Array<Metric> = [];

	/**
	 * Holds the currently selected OMNI metric object.
	 */
	// selectedMetric: Metric;

	constructor(private apollo: Apollo, private userService: UserService, private http: HttpClient) { }

	refreshWorkOrderMetrics(workOrderRefreshRecords: RefreshWorkOrderMetric[]) {
		const postBody = {
			CustomerCode: this.userService.getCurrentCustomerCode(),
			RecordsChanged: workOrderRefreshRecords
		};
		return this.postToHMS('/RefreshWorkOrderMetrics', postBody).toPromise();
	}

	refreshAssetMetrics(assetRefreshRecords: RefreshAssetMetric[]) {
		const postBody = {
			CustomerCode: this.userService.getCurrentCustomerCode(),
			RecordsChanged: assetRefreshRecords
		};
		return this.postToHMS('/RefreshAssetMetrics', postBody).toPromise();
	}

	private callHMS<T>(metricId: string, params: { [key: string]: string }) {
		let url = environment.alternateMetricServer + `/Metric/${metricId}` + '?Token=' + environment.headlessMetricToken + '&CustomerCode=' + this.userService.getCurrentCustomerCode();
		if (params) {
			for (const [key, value] of Object.entries(params)) {
				if (!value) continue;
				url += `&${key}=${value}`;
			}
		}

		return this.http.get<HeadlessMetricServerResponse<T>>(url).pipe(retry(1));
	}

	/**
	 * @param method the HMS method to hit
	 * @param metric
	 * @param body
	 */
	private postToHMS<T>(method: string, body: any, useAlternateMetricServer: boolean = false) {
		const url = (useAlternateMetricServer ? environment.alternateMetricServer : environment.headlessMetricServiceHeaderHost) + method;
		if (body) {
			if (!body.Token) {
				body.Token = environment.headlessMetricToken;
			}
			if (!body.CustomerCode) {
				body.CustomerCode = this.userService.getCurrentCustomerCode();
			}
		}
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
		return this.http.post<T>(url, body, httpOptions);
	}

	async getMetricResult<T>(timeframedMetric: TimeframedMetric, pageSize?: number, pageNumber?: number, requestId?: string) {
		const { metricid, outfields, timeframefield, startdate, enddate, rangetype } = timeframedMetric.toGetResultsRequest();
		const params = { outfields: outfields.toString(), timeframefield, startdate, rangetype, enddate, pagesize: pageSize?.toString(), pagenumber: pageNumber?.toString(), requestId };

		return await this.callHMS<T>(metricid, params).toPromise();
	}

	/**
	 * returns an array of metric results that conform a view or page of a table
	 * @param metric the metric to query
	 * @param dateFrame the date time range for the request
	 * @param pageSize how many results to return. Default is 100.
	 * @param pageNumber the index of the page to return. Default is 0;
	 * @param outFields the fields of the metric to return. Defautl is '*'.
	 * @param sorting an array of sorting rules. Sorts ascending or descending based on the field provided.
	 * @param filterByQuery a string to filter the results by.
	 */
	getMetricView(
		metric: Metric,
		timeFrameFilter: TimeframeFilter,
		pageSize?: number,
		pageNumber?: number,
		outFields?: Array<string>,
		sorting?: Array<{ field: string; order?: 'ASC' | 'DESC' }>,
		filterByQuery?: string
	) {
		if (!pageSize) pageSize = 100;
		if (!pageNumber) pageNumber = 0;
		if (!outFields || !outFields.length) outFields = ['*'];

		const postBody = {
			metrics: [
				{
					metricid: metric.id,
					timeframes: [
						{
							outfields: outFields,
							scalaronly: false,
							filter: {
								sorting,
								query: filterByQuery
							},
							paging: {
								pageNumber,
								pageSize
							}
						}
					]
				}
			]
		};
		if (timeFrameFilter && timeFrameFilter.timeFrameDefinition.type != TimeframeDefinitionType.Boundless) {
			postBody.metrics[0].timeframes[0]['rangeType'] = 'range';
			postBody.metrics[0].timeframes[0]['fromDateTime'] = timeFrameFilter.timeFrame.startDate;
			postBody.metrics[0].timeframes[0]['toDateTime'] = timeFrameFilter.timeFrame.endDate;
			postBody.metrics[0].timeframes[0]['dateTimeField'] = timeFrameFilter.timeframeField;
		} else {
			postBody.metrics[0].timeframes[0]['rangeType'] = 'all';
		}
		return this.postToHMS('/Metric/view', postBody, true).toPromise();
	}

	/** returns a list of scalars needed for when the tiles load */
	getMetricDeltas(metricTimeframes: MetricDeltasRequest[]) {
		const body = {};
		body['metrics'] = metricTimeframes;
		return this.postToHMS<MetricDeltasResponse>('/MetricResultDeltas', body);
	}

	async exportMetric(
		metric: Metric,
		options: {
			timeframeFilter?: TimeframeFilter;
			pageSize?: number;
			pageNumber?: number;
			outFields?: Array<string>;
			sorting?: Array<{ field: string; order?: string }>;
			filterByQuery?: string;
		},
		formattingHint: {
			datefieldnames?: Array<string>;
			integerfieldnames?: Array<string>;
			doublefieldnames?: Array<string>;
		}
	) {
		if (!options.pageSize) options.pageSize = 100;
		if (!options.pageNumber) options.pageNumber = 0;
		if (!options.outFields || !options.outFields.length) options.outFields = ['*'];

		const postBody = {
			metrics: [
				{
					metricid: metric.id,
					formattinghint: formattingHint ?? null,
					timeframes: [
						{
							startdate: options.timeframeFilter?.timeFrame?.startDate,
							enddate: options.timeframeFilter?.timeFrame?.endDate,
							datefield: options.timeframeFilter?.timeframeField,
							outfields: options.outFields,
							scalaronly: false,
							filter: {
								sorting: options.sorting,
								query: options.filterByQuery
							},
							paging: {
								pageNumber: options.pageNumber,
								pageSize: options.pageSize
							}
						}
					]
				}
			]
		};

		const results = await this.postToHMS('/Metric/view/excel', postBody, true).toPromise();
		const reportDownloadUrl = (results as any).Excels[0].reportDownloadUrl;

		return reportDownloadUrl;
	}

	async populateLatestValues(customerCode): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			const url = environment.headlessMetricServiceHeaderHost + '/MetricList?Token=2brlpH1BUcH-SwUk1&CustomerCode=' + customerCode.toLowerCase();
			const response = await this.http.get<any>(url).toPromise();

			if (!response.Success) {
				throwError('Unable to get metric values');
			}

			if (!response.Scalars) {
				resolve(false);
				return;
			}

			for (const metricId of Object.keys(response.Scalars)) {
				const metric = this.getMetricById(metricId);
				if (!metric) continue;

				const scalar = response.Scalars[metricId];
				if (scalar === null || scalar === undefined) {
					metric.result.value = '#';
				} else {
					metric.result.value = scalar;
				}
			}

			resolve(true);
			return;
		});
	}

	/**
	 * Metric values for the Tile types and it list the tile type values
	 */
	getTileSettingsTypeOfValueList(metric: Metric) {
		const valueTypeList = [
			{ name: 'record count', enumValue: TypeOfValueEnum.recordCount },
			{ name: 'sum of a field', enumValue: TypeOfValueEnum.sumOfAField },
			{ name: 'average value', enumValue: TypeOfValueEnum.averageValue },
			{ name: 'most recent value', enumValue: TypeOfValueEnum.mostRecentValue },
			{ name: 'percentage of total', enumValue: TypeOfValueEnum.percetangeOfTotal }
		];
		const { historyChannel, source } = metric.definition;

		if (source.workOrderSourceType === WorkOrderSourceType.workOrders || source.workOrderSourceType === WorkOrderSourceType.workOrderCosts) return valueTypeList;

		if (historyChannel) return valueTypeList;

		const valueTypeListWithOutMostRecentValue = valueTypeList.filter(valueType => valueType.enumValue !== TypeOfValueEnum.mostRecentValue);

		return valueTypeListWithOutMostRecentValue;
	}

	/**
	 * This method return a list value formatting methods to format metric values
	 */
	getValueFormatValueList() {
		const formatList = [
			{ name: 'none', enumValue: 0 },
			{ name: 'whole number', enumValue: 1 },
			{ name: 'decimal (tenths)', enumValue: 2 },
			{ name: 'decimal (hundredths)', enumValue: 3 },
			{ name: 'currency', enumValue: 4 }
		];

		return formatList;
	}

	/**
	 * This method returns a hardcoded list containing the default time frame values.
	 */
	getDefaultTimeFrameValueList() {
		const timeFrames = ['today', '12 hours', '24 hours', '48 hours', 'current week', 'current month', '30 days', 'current quarter', '90 days', 'current year', '365 days', '2 years', '3 years'];

		return timeFrames;
	}

	/**
	 * This method accepts a numberic metric ID and returns the corresponding metric from the list of available metrices.
	 * @param {string} id - Unique ID of a metric
	 * @return {Metric} - The method should return the metric object selected from all the available metrics.
	 */
	getMetricById(id: string): Metric {
		return this.availableMetrics.find(metric => metric.id === id);
	}

	getMetricListByProfileGroup() {
		if (!this.userService.currentUser.isProfileGroupEnabled) {
			return this.availableMetrics;
		}

		return this.availableMetrics.filter(metric => metric.profileGroup === this.userService.getCurrentProfileGroup());
	}


	validateMetricOnSave(metric: Metric): string {
		const { source: metricSource, assetChannel, historyChannel, workOrderChannel, query, displayValueSettings, menuPanelSettings, refreshFrequency, name } = metric.definition;
		if (!name) {
			// User should not be able to save a metric if no name is given for the metric
			return 'No description is given to this metric.';
		}

		if (!menuPanelSettings.displayField.name && (metricSource.type !== ChannelTypes.WorkOrder || !workOrderChannel.isAdvancedWorkOrder)) {
			return 'Need to set display field in default menu panel settings.';
		}

		if (query.mode === QueryMode.manualQuery) {
			if ((query as CustomQuery).mapLayerQueryString) {
				(query as CustomQuery).mapLayerQueryString = (query as CustomQuery).mapLayerQueryString.trim();
			}

			if ((query as CustomQuery).relatedTableQueryString) {
				(query as CustomQuery).relatedTableQueryString = (query as CustomQuery).relatedTableQueryString.trim();
			}
		}

		// .trim() is used to trim off the blank space in the name on the outter left and right side,
		// Example: '     ' will be trimed to '', or '   some metric name  ' to 'some metric name'
		metric.definition.name = metric.definition.name.trim();

		if (this.availableMetrics) {
			let duplicatedNameMessage = '';
			this.availableMetrics.forEach(existingMetric => {
				if (existingMetric.definition.name.trim() === metric.definition.name && existingMetric.id !== metric.id) {
					// If name already exist in the list of availableMetrics
					duplicatedNameMessage = 'Metric description already exists.';
				}
			});
			if (duplicatedNameMessage) return duplicatedNameMessage;
		}

		if (!metricSource) {
			// A metric must have a metric source
			return 'Must selected a metric source';
		}

		if (!assetChannel && !workOrderChannel && !historyChannel) {
			// If we only have asset channel, then we must need a map layer query
			return 'Need to select a source.';
		}

		if (query.mode === QueryMode.none) {
			if (!workOrderChannel) return 'Please enter create a query';
			const notLEM =
				metricSource.workOrderSourceType !== WorkOrderSourceType.employees &&
				metricSource.workOrderSourceType !== WorkOrderSourceType.equipment &&
				metricSource.workOrderSourceType !== WorkOrderSourceType.materials &&
				metricSource.workOrderSourceType !== WorkOrderSourceType.vendors;
			// stand LEM work order doesn't need a query
			if (notLEM) return 'Please enter create a query';
		}

		if (query.mode === QueryMode.manualQuery) {
			if (workOrderChannel) {
				if (!(query as CustomQuery).workOrderQueryString) return 'Need a manual query for the work order source';
			} else {
				if (!historyChannel && !(query as CustomQuery).mapLayerQueryString) return 'Need a manual query for the asset source';

				if (historyChannel && !(query as CustomQuery).relatedTableQueryString) return 'Need a manual query for the history source';
			}
		}

		if (query.mode === QueryMode.builderQuery) {
			if (!historyChannel && !this.includesAssetQuery(query as AGSQuery)) {
				return 'Need a built query for the asset source';
			}
			if (historyChannel && !this.includesHistoryQuery(query as AGSQuery)) {
				return 'Need a built query for the history source';
			}
		}

		const needValueTypeField =
			displayValueSettings.typeOfValue.enumValue === TypeOfValueEnum.averageValue ||
			displayValueSettings.typeOfValue.enumValue === TypeOfValueEnum.sumOfAField ||
			displayValueSettings.typeOfValue.enumValue === TypeOfValueEnum.mostRecentValue;

		if (needValueTypeField && !displayValueSettings.valueTypeField.name) {
			return 'Need to select a valueTypeField for display value settings.';
		}

		if (typeof refreshFrequency !== 'number') {
			return 'Refresh frequency must be a number.';
		}
	}

	private includesHistoryQuery(query: AGSQuery) {
		return query.queryStatementList.find(queryStatement => {
			const historyQueryFieldFound = queryStatement.queryFieldList.find(queryField => queryField.field.omniName.toLowerCase().includes('history'));
			if (historyQueryFieldFound) return true;
			return false;
		});
	}

	private includesAssetQuery(query: AGSQuery) {
		return query.queryStatementList.find(queryStatement => {
			const assetQueryFieldFound = queryStatement.queryFieldList.find(queryField => !queryField.field.omniName.toLowerCase().includes('history'));
			if (assetQueryFieldFound) return true;
			return false;
		});
	}

	/**
	 * This method save the metric in the database and return the metric.
	 * The gql query and variable used depends on creating or updating a metric
	 * @returns {Observable<Metric>} - Returns an observerable that returns a metric
	 */
	saveMetric(metric: Metric): Observable<Metric> {
		const { gqlMutationQuery, gqlMutationVariables } = metric.id ? this.getUpdateMetricGqlVariableAndQuery(metric) : this.getCreateMetricGqlVariableAndQuery(metric);
		// save new or update metric

		return this.apollo
			.mutate({
				mutation: gqlMutationQuery,
				variables: gqlMutationVariables
			})
			.pipe(
				map((result: any) => {
					if (!Object.entries(result.data).length) {
						throw new Error('saveMetric: Unable to fetch saved metric due to data returned was empty.');
					}
					// The return data could contain 'createMetric' or 'updateMetric'
					const metricFromServer = result.data.createMetric ? result.data.createMetric : result.data.updateMetric;
					metric.id = metricFromServer.id;
					metric.isNew = false;
					if (!this.availableMetrics.find(availableMetric => availableMetric.id === metric.id)) this.availableMetrics.unshift(metric);
					return metric;
				}),
				catchError(err => throwError(err))
			);
	}

	/**
	 * This function return the mutation query and variable for GQL that creates metric
	 * @returns {any} - Returns GraphQL mutation query and variable
	 */
	private getCreateMetricGqlVariableAndQuery(metric: Metric): any {
		const metricInput: MetricInput = this.mapMetricToGqlMetricInput(metric);
		metricInput.definition.createdDate = new Date().toUTCString();
		const gqlMutationQuery = gql`
			mutation createMetric($customerCode: String!, $metricInput: CreateMetricInput) {
				createMetric(customerCode: $customerCode, metricInput: $metricInput) {
					id
				}
			}
		`;
		const gqlMutationVariables = {
			customerCode: this.userService.getCurrentCustomerCode(),
			metricInput
		};

		return { gqlMutationVariables, gqlMutationQuery };
	}

	/**
	 * This function return the mutation query and variable for GQL that updates metric
	 * @returns {any} - Returns GraphQL mutation query and variable
	 */
	private getUpdateMetricGqlVariableAndQuery(metric: Metric): any {
		const metricInput: MetricInput = this.mapMetricToGqlMetricInput(metric);
		const gqlMutationQuery = gql`
			mutation updateMetric($customerCode: String!, $metricInput: UpdateMetricInput) {
				updateMetric(customerCode: $customerCode, metricInput: $metricInput) {
					id
				}
			}
		`;
		const gqlMutationVariables = {
			customerCode: this.userService.getCurrentCustomerCode(),
			metricInput
		};
		return { gqlMutationVariables, gqlMutationQuery };
	}

	/**
	 * This method will map selected metric Icon to Gql metricIconInput,
	 * and this process is to delete the '__typename' property given by gql
	 * @returns {MetricIcon} returns the metric icon to be inputed into the database
	 */
	private mapMetricIconToGqlMetricIconInput(metric: Metric): MetricIcon {
		const metricIconInput: MetricIcon = {
			name: '',
			foregroundColor: '',
			backgroundColor: '',
			pathList: [],
			base64: '',
			url: ''
		};

		return metricIconInput;
	}

	/**
	 * This method will map selected metric to Gql metricInput depends on adding or updating a metric
	 * @returns {Partial<Metric>} returns the metric to be inputed into the database
	 */
	private mapMetricToGqlMetricInput(metric: Metric): MetricInput {
		const {
			name,
			source,
			assetChannelId,
			historyChannelId,
			workOrderChannelId,
			displayValueSettings,
			menuPanelSettings,
			query,
			type,
			timeFrameFilter,
			defaultTrendSettings,
			refreshFrequency,
			createdDate,
		} = metric.definition;

		const metricQueryInput: any = { mode: query.mode };

		if ((query as AWOQuery).workOrderFilter) {
			metricQueryInput.workOrderFilter = {};
			for (const [key, value] of Object.entries((query as AWOQuery).workOrderFilter)) {
				if (key === 'customFields') continue;
				if (key === 'isDirty') continue;
				metricQueryInput.workOrderFilter[key] = value;
			}
			metricQueryInput.workOrderFilter.customFields = (query as AWOQuery).workOrderFilter.customFields.length ? (query as AWOQuery).workOrderFilter.customFields.getContracts() : null;
		}

		if ((query as AWOQuery).taskFilter) {
			metricQueryInput.taskFilter = {};
			for (const [key, value] of Object.entries((query as AWOQuery).taskFilter)) {
				if (key === 'customFields') continue;
				if (key === 'isDirty') continue;
				metricQueryInput.taskFilter[key] = value;
			}
			metricQueryInput.taskFilter.customFields = (query as AWOQuery).taskFilter.customFields ? (query as AWOQuery).taskFilter.customFields.getContracts() : null;
		}

		if (query.mode === QueryMode.manualQuery) {
			for (const [key, value] of Object.entries(query as CustomQuery)) {
				if (key === 'queryStatementList') metricQueryInput[key] = this.getQueryStatementListInput((query as AGSQuery).queryStatementList);
				else metricQueryInput[key] = value;
			}
		} else if (type !== MetricTypes.awo) {
			metricQueryInput.queryStatementList = this.getQueryStatementListInput((query as AGSQuery).queryStatementList);
		}

		const metricInput: MetricInput = {
			definition: {
				name,
				source,
				icon: this.mapMetricIconToGqlMetricIconInput(metric),
				query: metricQueryInput,
				type,
				displayValueSettings,
				menuPanelSettings,
				defaultTrendSettings,
				refreshFrequency,
				createdDate,
			}
		};

		if (timeFrameFilter && timeFrameFilter.isValid()) {
			metricInput.definition.timeFrameDefinition = timeFrameFilter.getDefinitionContract();
		}

		if (metric.id) {
			metricInput.id = metric.id;
		}

		if (assetChannelId) {
			metricInput.definition.assetChannelId = assetChannelId;
		}

		if (historyChannelId) {
			metricInput.definition.historyChannelId = historyChannelId;
		}

		if (workOrderChannelId) {
			metricInput.definition.workOrderChannelId = workOrderChannelId;
		}

		if (this.userService.currentUser.isProfileGroupEnabled) {
			metricInput.profileGroup = this.userService.getCurrentProfileGroup();
		}

		return metricInput;
	}

	getQueryStatementListInput(queryStatementList: QueryStatementList) {
		const queryStatementListInput = queryStatementList.map(queryStatement => {
			return {
				index: queryStatement.index,
				source: queryStatement.source,
				queryFieldList: queryStatement.queryFieldList,
				join: queryStatement.join
			};
		});
		return queryStatementListInput;
	}

	/**
	 * This function deletes the selected metric
	 * @returns {Observable<string>} - Returns an obversable that returns a deleted metric's Id
	 */
	deleteMetric(metric: Metric): Observable<string> {
		const { id } = metric;
		return this.apollo
			.mutate({
				mutation: gql`
					mutation deleteMetric($id: String!) {
						deleteMetric(id: $id)
					}
				`,
				variables: { id }
			})
			.pipe(
				map(() => {
					return id;
				}),
				catchError(err => throwError(err))
			);
	}

	async refreshMetricDefinition(metricId: string) {
		const refreshedMetric = await this.fetchMetricFromServer(metricId).toPromise();
		const metricInCache = this.getMetricById(metricId);
		metricInCache.updateDefinition(refreshedMetric);
	}

	/**
	 * This function gets a list of metric under a customer code
	 * @returns {Observable<{ [index: string]: Metric }>} - returns the availableMetrics in this metric service
	 */
	getMetricList(): Observable<Array<Metric>> {
		return this.apollo
			.query({
				query: this.getMetricListGqlQuery()
			})
			.pipe(
				map((result: ApolloQueryResult<{ getMetricList: Array<Metric> }>) => {
					const metricList = result.data && result.data.getMetricList ? result.data.getMetricList : [];

					metricList.forEach((metricData: Metric) => {
						const metric = new Metric();
						metric.initialize(metricData);
						this.availableMetrics.push(metric);
					});
					return this.availableMetrics;
				}),
				catchError(err => throwError(err))
			);
	}

	fetchMetricFromServer(metricId: string): Observable<Metric> {
		return this.apollo
			.query({
				query: this.getMetricGqlQuery(metricId),
				fetchPolicy: 'network-only'
			})
			.pipe(
				map((result: ApolloQueryResult<{ getMetric: Metric }>) => {
					const metric = result.data && result.data.getMetric ? result.data.getMetric : null;
					return metric;
				}),
				catchError(err => throwError(err))
			);
	}

	private getMetricGqlQuery(id: string): any {
		const getMetricGqlQuery = gql`{
			getMetric(id: "${id}") {
				id
				exportUrl
				profileGroup
				definition{
					name
					source{
						assetType
						type
						workOrderSourceType
					}
					icon{
						name
						backgroundColor
						foregroundColor
						pathList{
							path
							isForeground
						}
						base64
						url
					}
					type
					query{
						mode
						mapLayerQueryString
						relatedTableQueryString
						workOrderQueryString
						queryStatementList{
							index
							source{
								name
								value
							}
							queryFieldList{
								index
								field{
									name
									alias
									type
									omniName
								}
								operator{
									name
									value
								}
								value{
									name
									code
								}
								join{
									name
									value
								}
							}
							join{
								name
								value
							}
						}
						workOrderFilter{
							status
							assignedto
							teamid
							supervisor
							priority
							worktypeid
							customFields {
								index
								name
								customFieldId
								conditions{
									index
									operator{
										name
										value
									}
									value{
										name
										code
									}
									join{
										name
										value
									}
									type
								}
							}
						}
						taskFilter{
							status
							assignedto
							teamid
							taskkey
							customFields {
								index
								name
								customFieldId
								conditions{
									index
									operator{
										name
										value
									}
									value{
										name
										code
									}
									join{
										name
										value
									}
									type
								}
							}
						}
					}
					displayValueSettings{
						typeOfValue{
							name
							enumValue
						}
						valueTypeField{
							name
							type
							alias
							omniName
						}
						valueFormat{
							name
							enumValue
						}
					}
					menuPanelSettings{
						defaultSortField{
							name
							type
							alias
							omniName
						}
						defaultSortOrder
						displayField{
							name
							type
							alias
							omniName
						}
					}
					defaultTrendSettings{
						defaultTrendStyle{
							identifier
							label
						}
						xAxisField{
							name
							type
							alias
							omniName
						}
						yAxisField{
							name
							type
							alias
							omniName
						}
					}
					refreshFrequency
					assetChannelId
					historyChannelId
					workOrderChannelId
					createdDate
				}
			}
		}`;
		return getMetricGqlQuery;
	}

	/**
	 * creates a metric graph query language.
	 * @returns {any} - returns the metric gql object, in graphql-tag's index file, the return type was stated as 'any'.
	 */
	private getMetricListGqlQuery(): any {
		const customerCode = 'demo'; // this.userService.getCurrentCustomerCode();
		const getMetricListGqlQuery = gql`{
			metrics(customerCode: "${customerCode}") {
				id
				exportUrl
				profileGroup
				definition{
					name
					metricSource{
						assetType
						type
					}
					metricIcon{
						name
						backgroundColor
						foregroundColor
						pathList{
							path
							isForeground
						}
						url
						blob
						base64
					}
					assetChannelId
					historyChannelId
					workOrderChannelId
					mapLayerQuery
					relatedTableQuery
				}
			}
		}`;
		return getMetricListGqlQuery;
	}

	private getAssetIconUrl(metric: Metric): string {
		if (metric.definition.source.type === ChannelTypes.WorkOrder) {
			return 'assets/workorder-white.png';
		}
		const assetType = metric.definition.source.assetType;
		const assetDefinition = this.userService.globalConfig.assetDefinitions.getByAssetType(assetType);

		if (!assetDefinition) return;
		return assetDefinition.iconResource?.url;
	}

	async setIcon(metric: Metric) {
		metric.definition.iconUrl = this.getAssetIconUrl(metric) ? this.getAssetIconUrl(metric) : null;

		const backgroundColor = this.getMetricColor(metric);
		metric.definition.backgroundColor = backgroundColor;
		if (!metric.definition.iconUrl) return;
		const url = await PNGManager.editImage(metric.definition.iconUrl, { backgroundColor });
		metric.definition.symbolIconUrl = url;
	}

	async updateIcon(metricTile: MetricTile) {
		if (!metricTile.metric.definition.iconUrl) metricTile.metric.definition.iconUrl = this.getAssetIconUrl(metricTile.metric);

		const backgroundColor = new Color();
		if (metricTile.backgroundColor.includes('rgb')) backgroundColor.fromRGBA(metricTile.backgroundColor);
		else backgroundColor.fromHEX(metricTile.backgroundColor);

		metricTile.metric.definition.backgroundColor = backgroundColor;
	}

	getMetricColor(metric: Metric): Color {
		if (metric.definition.source.type === ChannelTypes.WorkOrder) {
			const color = new Color();
			color.fromRGBA('rgb(236, 137, 56)');
			return color;
		}

		const assetDefinition = this.userService.globalConfig.assetDefinitions.getByAssetType(metric.definition.source.assetType);
		if (!assetDefinition) return;
		return assetDefinition.style.layout.background.color;
	}

	exportMetricFeatures(metric: Metric) {
		return this.http.get(metric.exportUrl).pipe(
			map((response: any) => {
				if (!response.Success) {
					throwError('unable to download');
				}
				return response;
			}),
			catchError(err => {
				return throwError('unable to connect to server');
			})
		);
	}

	checkIfMetricsNeedRefresh(metrics: Metric[]): any {
		const metricIds = metrics.map(m => {
			return { metricId: m.id };
		});
		const body = { metrics: metricIds };
		return this.postToHMS<MetricLastChangedResponse[]>('/MetricStatus', body);
	}
}
