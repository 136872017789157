import { ContractBase } from '../contract-base';

export class MaterialContract extends ContractBase {
	public ObjectId: number;
	public MaterialId: string;
	public Description: string;
	public UnitCost: number;
	public PartNumber: string;
	public Units: number;
	public UnitType: string;
	public isActive: number;
}
