<div class="scrolling-content">
    <app-omni-list
        #workOrdersList
        [omniListItems]="workOrders"
        (itemClicked)="handleClick($event)">
    </app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<label class="omni-md-font">items: {{ workOrders.length }} &nbsp;</label>
	</mat-card-actions>
</mat-card-footer>
