import { MetricDefinition, MetricDefinitionInput } from './metric-definition.model';
import { MetricResult } from './metric-result.model';
import { DisplayValueSettings } from './display-value-settings.model';
import { ChannelAttributes } from './channel-attributes.model';
import { ChannelTypes } from './channel-type.enum.model';

/**
 * The model of an OMNI metric.
 */
export class Metric {
	/**
	 * Unique uuid identifier for each metric.
	 */
	public id?: string;
	/**
	 * Metric value to be displayed on the tile.
	 */
	public definition: MetricDefinition;

	/**
	 * Metric export url
	 */
	public exportUrl: string;

	/**
	 * Metric display values and features
	 */
	public result?: MetricResult;

	public isNew?: boolean;

	public lastTimeChanged: Date;

	public lastTimeScalarUpdated?: Date;

	/**
	 * The profile group of the customer
	 */
	 public profileGroup?: string;

	/**
	 * This is to constructor will construct the basic Omni Metric properties and assign empty values to them
	 */
	constructor() {
		this.id = null;
		this.definition = new MetricDefinition();
		this.result = new MetricResult();
		this.isNew = true;
	}

	initialize?(metric: Metric): void {
		this.id = metric.id;
		this.profileGroup = metric.profileGroup;
		this.definition.initialize(metric.definition);
		this.exportUrl = metric.exportUrl;
		this.isNew = false;
	}

	initializeResult?(): void {
		this.result = new MetricResult();
	}

	updateDefinition(metric: Metric) {
		this.definition.update(metric.definition);
		this.exportUrl = metric.exportUrl;
	}


	getIdFieldName(): string {
		const channelAttribute = this.definition.getChannel().attributes;
		if (!channelAttribute) {
			throw new Error('Channel attribute not defined.');
		}
		const idFieldName: string = channelAttribute.getIdFieldName();
		return idFieldName;
	}

	/**
     * Get the id value for the given record.
     * @param record The record to inspect.
     * @returns Id if available otherwise null.
     */
	 getRecordId(record: any): any {
		const {idFieldName, value} = this.getRecordIdField(record);
        return value;
    }


	/**
     * This method will return the id field name and value.
     * @param record The record to inspect.
     * @returns The id field name and value.
     */
	getRecordIdField(record: any): { idFieldName: string, value: any} {
		const channelAttribute = this.definition.getChannel().attributes;
		const { fieldName, value } = channelAttribute.getRecordId(record);
        return { idFieldName: fieldName, value };
    }


	/**
     * This only applies to history records and will return the id of the asset
	 * this history record is associated to.
     * @param record The record to inspect.
     * @returns Id if available otherwise null.
     */
	 getAssetIdFromHistoryRecord(record: any): any {
		 if (this.definition.source.type == ChannelTypes.History) {
			const channelAttribute = this.definition.getChannel(ChannelTypes.History).attributes;
			const { fieldName, value } = channelAttribute.getRecordId(record);
			return { idFieldName: fieldName, value };
		 }
		return null;
    }

}

export class MetricInput {
	/**
	 * Unique uuid identifier for each metric.
	 */
	public id?: string;
	/**
	 * Metric value to be displayed on the tile.
	 */
	public definition: MetricDefinitionInput;

	/**
	 * Metric display values and features
	 */
	public result?: MetricResult;

	/**
	 * The profile group of the customer
	 */
	 public profileGroup?: string;
}
