export class NodeModel {
	color = 'white';
	size: 'large' | 'medium' | 'small' = 'medium';
	position: { top: number; left: number };
	get width() {
		switch (this.size) {
			case 'large':
				return 40;
			case 'medium':
				return 25;
			case 'small':
				return 10;
		}
	}
	get center() {
		const x = this.position.left + this.width / 2;
		const y = this.position.top + this.width / 2;
		return { x, y };
	}
}
