import { Injectable } from '@angular/core';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderModeType } from 'models';

/**
 * The data channel service is used for curd operations upon DataChannels
 * for the metric data
 */
@Injectable({
	providedIn: 'root'
})
export class MetricSourceService {
	private _types = {
		asset: {
			fullName: 'Asset',
			shortName: 'asset',
			description: 'connect to map assets via ArcGIS service endpoints and webmaps',
			icon: 'assets/data-channel-types/arc-gis.png',
			enabled: true,
			order: 1
		},
		history: {
			fullName: 'History',
			shortName: 'history',
			description: 'access historical records for work history and time-series data',
			icon: 'assets/data-channel-types/history.png',
			enabled: true,
			order: 1
		},
		workorder: {
			fullName: 'WorkOrder',
			shortName: 'workorder',
			description: 'utilize Sedaru work orders, tasks, inspections and time and materials',
			icon: 'assets/data-channel-types/workorder.png',
			enabled: true,
			order: 2
		},
		scada: {
			fullName: 'SCADA',
			shortName: 'scada',
			description: 'link to a SCADA historian to stream real-time data and alarms',
			icon: 'assets/data-channel-types/scada.png',
			enabled: false,
			order: 4
		},
		sensors: {
			fullName: 'Sensors',
			shortName: 'sensors',
			description: 'connect to network sensors such as flow meters and pressure monitors',
			icon: 'assets/data-channel-types/sensors.png',
			enabled: false,
			order: 5
		}
	};

	get types() {
		if (this.interopService.configurationManager.customerCodeConfiguration.workOrderMode === WorkOrderModeType.Advanced) {
			this._types.history.enabled = false;
		} else {
			this._types.history.enabled = true;
		}
		return this._types;
	}

	assetTypes = [
		{ name: 'Customers', icon: '' },
		{ name: 'Hydrants', icon: '' },
		{ name: 'Tanks', icon: '' },
		{ name: 'Pipes', icon: '' },
		{ name: 'Valves', icon: '' },
		{ name: 'Pumps', icon: '' },
		{ name: 'Water Mains', icon: '' }
	];

	workOrderSourceTypes = [
		{ name: 'work orders', value: WorkOrderSourceType.workOrders, icon: '' },
		{ name: 'work order assets', value: WorkOrderSourceType.workOrderAssets, icon: '' },
		{ name: 'work order cost', value: WorkOrderSourceType.workOrderCosts, icon: '' },
		{ name: 'employees', value: WorkOrderSourceType.employees, icon: '' },
		{ name: 'materials', value: WorkOrderSourceType.materials, icon: '' },
		{ name: 'equipment', value: WorkOrderSourceType.equipment, icon: '' },
		{ name: 'vendors', value: WorkOrderSourceType.vendors, icon: '' }
	];

	constructor(private interopService: OmniInteropService) {}
}
