<div class="scrolling-content" #scrollingContainer (scroll)="onScroll()">
	<div class="source-types">
		<app-menu-panel-field
		*ngFor="let source of sources; index as i"
		[value]="getFilterFields(source.type)"
		[header]="source.name"
		[placeHolder]=""
		[cssClass]="getBackgroundColor(i)"
		[buttonEnabled]="enableButtonTemp(source.type)"
		(goToButtonClick)="goTo(source.type)"
		[disabled]="true"
	></app-menu-panel-field>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" [class.disabled]="!awoQuery.isDirty" (click)="awoQuery.isDirty && onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
