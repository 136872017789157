import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { CustomFields } from './custom-fields.model';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { CustomFieldContract } from '../../contracts/work-order/index';

export class WorkOrderTemplate extends OmniModel<WorkOrderTemplate, Contracts.WorkOrderTemplateContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _woTemplateId: SedaruUtils.Undoable<string>;
	get woTemplateId(): string {
		if (!this._woTemplateId) this._woTemplateId = new SedaruUtils.Undoable<string>();
		return this._woTemplateId.value;
	}
	set woTemplateId(value: string) {
		if (!this._woTemplateId) {
			this._woTemplateId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._woTemplateId.value = value;
	}

	private _woTemplateName: SedaruUtils.Undoable<string>;
	get woTemplateName(): string {
		if (!this._woTemplateName) this._woTemplateName = new SedaruUtils.Undoable<string>();
		return this._woTemplateName.value;
	}
	set woTemplateName(value: string) {
		if (!this._woTemplateName) {
			this._woTemplateName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._woTemplateName.value = value;
	}

	private _assetType: SedaruUtils.Undoable<string>;
	get assetType(): string {
		if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
		return this._assetType.value;
	}
	set assetType(value: string) {
		if (!this._assetType) {
			this._assetType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetType.value = value;
	}

	private _customFields: SedaruUtils.Undoable<CustomFieldWrappers>;
	get customFields(): CustomFieldWrappers {
		if (!this._customFields) this._customFields = new SedaruUtils.Undoable<CustomFieldWrappers>();
		return this._customFields.value;
	}
	set customFields(value: CustomFieldWrappers) {
		if (!this._customFields) {
			this._customFields = new SedaruUtils.Undoable<CustomFieldWrappers>(value);
			return;
		}
		this._customFields.value = value;
	}

	static fromContract(contract: Contracts.WorkOrderTemplateContract): WorkOrderTemplate {
		const model = new WorkOrderTemplate();
		model.id = contract.ObjectId.toString();
		model.woTemplateId = contract.WoTemplateId;
		model.woTemplateName = contract.WoTemplateName;
		model.assetType = contract.AssetType;
		if (contract.CustomFields) model.customFields = CustomFieldWrappers.fromContracts(contract.CustomFields);
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.WorkOrderTemplateContract {
		const contract = new Contracts.WorkOrderTemplateContract();
		contract.ObjectId = +this.id;
		contract.WoTemplateId = this.woTemplateId;
		contract.WoTemplateName = this.woTemplateName;
		contract.AssetType = this.assetType;
		contract.CustomFields = this.customFields.getContracts() as CustomFieldContract[];
		return contract;
	}

	onUpdateInformation(copyModel: WorkOrderTemplate): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._woTemplateId, () => {
				this.woTemplateId = copyModel.woTemplateId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._woTemplateName, () => {
				this.woTemplateName = copyModel.woTemplateName;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<CustomFieldWrappers>(this._customFields, () => {
				this.customFields = copyModel.customFields;
			}) || wasChanged;
		return wasChanged;
	}
}
