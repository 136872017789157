<div class="scrolling-content">
	<app-omni-list
		#selectionList
		[omniListItems]="listItems"
		[selectedItems]="selectedItems"
		[multipleSelection]="isMultipleSelection"
		[itemsKey]="'key'"
		(itemClicked)="itemSelected($event)"
	></app-omni-list>
</div>
<mat-card-footer *ngIf="isMultipleSelection" class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" (click)="onClear()">clear <img class="omni-sm-img" src="assets/x-close.png" /></button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" (click)="onSave()">done <img class="omni-sm-img" src="assets/done.png" /></button>
	</mat-card-actions>
</mat-card-footer>
