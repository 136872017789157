import { WorkOrderChannelAttributes } from './work-order-channel-attributes.model';
import { LEMConfig } from './sedaru-config/lem-config.model';
import { LEMMode } from './sedaru-config/lem-mode.enum';

/**
 * This defines the sedaru work order channel attributes
 */
export class SedaruWorkOrderChannelAttributes extends WorkOrderChannelAttributes {
	/**
	 * holds the sedaru unique customer code
	 */
	customerCode: string;

	canCloseWorkOrders: boolean;

	skipRealtimeValidation: boolean;

	activeTemplateId: number;

	completedTemplateId: number;

	get lemMode(): LEMMode {
		return this.lem.mode;
	}

	private _lem: LEMConfig;
	get lem(): LEMConfig {
		if (!this._lem) {
			this._lem = new LEMConfig();
		}
		return this._lem;
	}

	initialize?(attributes: SedaruWorkOrderChannelAttributes) {
		if (!attributes) return;
		this.workOrderMode = attributes.workOrderMode;
		this.workOrderSystem = attributes.workOrderSystem;
		Object.assign(this.activeWorkOrderValues, attributes.activeWorkOrderValues);
		Object.assign(this.completedWorkOrderValues, attributes.completedWorkOrderValues);
		Object.assign(this.readOnlyWorkOrderValues, attributes.readOnlyWorkOrderValues);
		Object.assign(this.hourlyRates, attributes.hourlyRates);
		this.customerCode = attributes.customerCode;
		this.canCloseWorkOrders = attributes.canCloseWorkOrders;
		this.skipRealtimeValidation = attributes.skipRealtimeValidation;
		this.activeTemplateId = attributes.activeTemplateId;
		this.completedTemplateId = attributes.completedTemplateId;
	}
}
