import { EventEmitter, Injectable } from '@angular/core';
import { OmniValueListItem } from 'app/omni-value-list/omni-value-list.model';

/**
 * Provides the servies required to handle the basic operations to be performed on a list which is display the list items and
 * emit an event when a list item is selected.
 */
@Injectable({
	providedIn: 'root'
})
export class OmniValueListService {
	/**
	 * Holds the list of items to be displayed in the list
	 */
	list: OmniValueListItem[] = [];

	/**
	 * holds the currently selected item and that will highlighted
	 */
	selectedItem: any;
	/**
	 * The header to be used when the panel loads
	 */
	header: string;
}
