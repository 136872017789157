import { StatusContract } from '../../contracts/work-order/status-contract';
import { Status } from './status.model';
export class Statuses extends Array<Status> {
	static fromContracts(statusContracts: StatusContract[]) {
		const statuses = new Statuses();
		if (!statusContracts) return statuses;

		for (const statusContract of statusContracts) {
			const status = Status.fromContract(statusContract);
			statuses.push(status);
		}
		return statuses;
	}

	getContracts = () => {
		const statusContracts = new Array<StatusContract>();
		for (const status of this) {
			const statusContract = status.getContract();
			statusContracts.push(statusContract);
		}
		return statusContracts;
	};

	getById = (id: string) => this.find(status => status.id === id);

	getByStatusCode = (statusCode: string) => this.find(status => status.statusCode?.toLowerCase() === statusCode?.toLowerCase());
}
