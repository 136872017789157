import { BulkUpdateChangedHandler, BulkUpdateChangedResult, WorkOrderUpdateError } from 'domain-service/subscriptions/handlers/bulk-update-changed-handler';
import { WorkOrderWrapper, WorkOrderWrappers } from 'models/work-order';
import { JobBase, JobState } from '../job-base';
import { v4 as uuid } from 'uuid';
import { EventEmitter } from '@angular/core';

export class BulkUpdateJobContract {
	jobId: string;
	workOrderTotalCount: number;
	workOrderUpdatedCount: number;
	workOrderFailedCount: number
	workOrderKeyFailedList: string[];
	workOrderKeySuccessList: string[];
	isActive: boolean;
	isCompleted: boolean;
}

export class Batch {
	batchId: string;
	customerCode: string;
	resolver: any;
	workOrders: WorkOrderWrapper[];
	result: BulkUpdateChangedResult;
	realTimeHandler: BulkUpdateChangedHandler;
}

export enum BulkJobMode {
	'None',
	'Create',
	'Update',
	'Delete'
}

export class BulkUpdateJob extends JobBase {
	private maxBatchCount = 25;
	private cancelRequested = false;
	private _currentBatch: Batch;
	private _cancelDeferrer: () => void
	private boundProgressHandler = this.onSedaruConnectProgress.bind(this);
	private boundCompletedHandler = this.onSedaruConnectCompleted.bind(this);
	private boundCancelledHandler = this.onSedaruConnectCancelled.bind(this);
	private isSedaruConnectCapable: boolean;
	private bulkJobMode: BulkJobMode;
	private runBatchDelegate: (batch: Batch) => Promise<boolean>;
	public jobFinished = new EventEmitter<string[]>();

	get workOrderTotalCount(): number {
		return this.workOrderList.length;
	}

	get customerCode(): string {
		return this.jobManager?.parentInteropService?.omniDomain?.workOrderFactory?.customerCode;
	}

	isDismissed = false;

	public get processedCount(): number {
		return this.successCount + this.failedCount;
	}
	public get successCount(): number {
		return this.workOrderKeySuccessList.length;
	}
	public get failedCount(): number {
		return this.workOrderKeyFailedList.length;
	}
	public get remainingCount(): number {
		return this.workOrderList.length - this.successCount - this.failedCount;
	}
	private _workOrderKeyFailedList = new Array<WorkOrderUpdateError>();
	public get workOrderKeyFailedList(): Array<WorkOrderUpdateError> {
		if (!this._workOrderKeyFailedList) this._workOrderKeyFailedList = new Array<WorkOrderUpdateError>();
		return this._workOrderKeyFailedList;
	}
	private _workOrderKeySuccessList = new Array<string>();
	public get workOrderKeySuccessList(): Array<string> {
		if (!this._workOrderKeySuccessList) this._workOrderKeySuccessList = new Array<string>();
		return this._workOrderKeySuccessList;
	}
	private _workOrderKeyPendingList = new Array<string>();
	public get workOrderKeyPendingList(): Array<string> {
		if (!this._workOrderKeyPendingList) this._workOrderKeyPendingList = new Array<string>();
		return this._workOrderKeyPendingList;
	}
	private _workOrderList: WorkOrderWrappers;
	get workOrderList(): WorkOrderWrappers {
		return this._workOrderList;
	}

	private _progressMessage: string;
	get progressMessage(): string {
		if (!this._progressMessage) {
			this._progressMessage = this.getProgressMessage();
		}
		return this._progressMessage;
	}

	private getProgressMessage(): string {

		if (this.state === JobState.Running) {
			let processedCount = this.processedCount;
			if (this._currentBatch && this._currentBatch.result) {
				processedCount += this._currentBatch.result.processedCount;
			}

			let modeMessage = '';
			switch (this.bulkJobMode) {
				case BulkJobMode.Create:
					modeMessage = 'Creating';
					break;
				case BulkJobMode.Update:
					modeMessage = 'Updating';
					break;
				case BulkJobMode.Delete:
					modeMessage = 'Deleting';
					break;
			}

			return `${modeMessage} ${processedCount} of ${this.workOrderTotalCount} records`;
		}

		if (this.state === JobState.Completed) {
			if (this.failedCount > 0) {
				return 'Completed with errors';
			}

			let modeMessage = '';
			switch (this.bulkJobMode) {
				case BulkJobMode.Create:
					modeMessage = 'created';
					break;
				case BulkJobMode.Update:
					modeMessage = 'updated';
					break;
				case BulkJobMode.Delete:
					modeMessage = 'deleted';
					break;
			}

			return `Successfully ${modeMessage} ${this.successCount} records`;
		}

		if (this.state === JobState.Cancelled) {
			if (this.failedCount > 0) {
				return 'Cancelled with errors';
			}

			return `Cancelled. Records processed: ${this.successCount}`;
		}

		return '';
	}

	private get realTimeHandler(): BulkUpdateChangedHandler {
		if (!this.jobManager) return undefined;
		return this.jobManager.parentInteropService.omniDomain.subscriptionService.hub.methodHandlers.getHandler('BulkUpdateChangedHandler') as BulkUpdateChangedHandler;
	}

	constructor(workOrders: WorkOrderWrapper[], runBatchDelegate: (batch: Batch) => Promise<boolean>, bulkJobMode: BulkJobMode, isSedaruConnectCapable: boolean) {
		super();
		this._workOrderList = WorkOrderWrappers.fromWrapperModels(workOrders);
		this.runBatchDelegate = runBatchDelegate;
		this.bulkJobMode = bulkJobMode;
		this.isSedaruConnectCapable = isSedaruConnectCapable;
	}

	async onRun() {
		this.hookOnToRealtimeEvents();

		let elapsed = 0;

		do {
			this._currentBatch = new Batch();
			this._currentBatch.realTimeHandler = this.realTimeHandler;
			this._currentBatch.customerCode = this.customerCode;
			this._currentBatch.batchId = uuid();

			const workOrders = this.workOrderList.slice(elapsed, elapsed + this.maxBatchCount);
			this._currentBatch.workOrders = workOrders;
			await this.runBatch(this._currentBatch);

			const result = this._currentBatch.result;
			if (result.workOrderKeysWithError && result.workOrderKeysWithError.length > 0) {
				for (const k of result.workOrderKeysWithError) {
					this.workOrderKeyFailedList.push(k);
				}
			}
			if (result.workOrderKeysWithSuccess && result.workOrderKeysWithSuccess.length > 0) {
				for (const k of result.workOrderKeysWithSuccess) {
					this.workOrderKeySuccessList.push(k);
				}
			}
			if (result.workOrderKeysPending && result.workOrderKeysPending.length > 0) {
				for (const k of result.workOrderKeysPending) {
					this._workOrderKeyPendingList.push(k);
				}
			}

			elapsed = this._currentBatch.workOrders.length + elapsed;

			this._progressMessage = '';

			if (this.cancelRequested) {
				this.addPendingWorkOrders(result, elapsed);
				this._cancelDeferrer();
				return;
			}
		} while (elapsed < this.workOrderList.length);

		this.markComplete(JobState.Completed);
	}

	private addPendingWorkOrders(result: BulkUpdateChangedResult, elapsed: number): void {
		if (!result) return;
		const remainingWorkOrders = this.workOrderList.slice(elapsed, this.workOrderList.length).map(wo => wo.workOrderKey);
		this.workOrderKeyPendingList.push(...remainingWorkOrders);
	}

	private runBatch(batch: Batch): Promise<boolean> {
		return this.runBatchDelegate(batch);
	}

	onCancel(deferrer: () => void) {
		this.cancelRequested = true;

		if (!this.realTimeHandler) {
			return;
		}

		this._cancelDeferrer = deferrer;
		this.realTimeHandler.sendCancelWorkOrderBulk(this._currentBatch.batchId, this.customerCode)
	}

	onDispose() {
		this._currentBatch = null;
		this.unhookRealtimeEvents();
		this.jobFinished?.emit(this.workOrderKeySuccessList);
	}

	onGetJobName() {
		return BulkUpdateJob.JobName;
	}

	public static get JobName(): string {
		return 'BulkUpdateJob';
	}

	private hookOnToRealtimeEvents() {
		if (!this.realTimeHandler || !this.isSedaruConnectCapable) {
			return;
		}

		this.realTimeHandler.onBulkUpdateProgress.subscribe(this.boundProgressHandler);
		this.realTimeHandler.onBulkUpdateCompleted.subscribe(this.boundCompletedHandler);
		this.realTimeHandler.onBulkUpdateCancelled.subscribe(this.boundCancelledHandler);
	}

	private unhookRealtimeEvents() {
		if (!this.realTimeHandler || !this.isSedaruConnectCapable) {
			return;
		}

		this.realTimeHandler.onBulkUpdateProgress.unsubscribe(this.boundProgressHandler);
		this.realTimeHandler.onBulkUpdateCompleted.unsubscribe(this.boundCompletedHandler);
		this.realTimeHandler.onBulkUpdateCancelled.unsubscribe(this.boundCancelledHandler);
	}

	private async onSedaruConnectProgress(handler, bulkPayload: BulkUpdateChangedResult) {
		if (!bulkPayload || !bulkPayload.batchId) return;

		if (bulkPayload.batchId !== this._currentBatch.batchId) return;

		this._currentBatch.result = bulkPayload;
		this._progressMessage = '';
	}

	private async onSedaruConnectCompleted(handler, bulkPayload: BulkUpdateChangedResult) {
		if (!bulkPayload || !bulkPayload.batchId) return;

		if (bulkPayload.batchId !== this._currentBatch.batchId) return;

		this._currentBatch.result = bulkPayload;

		if (this._currentBatch && this._currentBatch.resolver) {
			const batchResolver = this._currentBatch.resolver;
			batchResolver(true);
		}

		this._progressMessage = '';
	}

	private async onSedaruConnectCancelled(handler, bulkPayload: BulkUpdateChangedResult) {
		if (!bulkPayload || !bulkPayload.batchId) return;

		if (bulkPayload.batchId !== this._currentBatch.batchId) return;

		this._currentBatch.result = bulkPayload;
		// complete this job
		if (this._currentBatch && this._currentBatch.resolver) {
			const batchResolver = this._currentBatch.resolver;
			batchResolver(false);
		}

		this._progressMessage = '';
	}
}
