export class Queue<T> {
	private _items: Array<T>;
	get items() {
		return this._items;
	}

	constructor() {
		this._items = new Array<T>();
	}

	/** the maximum number of items allowed in the queue */
	max: number;

	/** returns the lenght of the queue */
	get count(): number {
		return this._items.length;
	}

	/** pushes a new item to the array */
	enqueue(item: T): void {
		if (!this._items) return;
		this._items.push(item);
		if (this.max && this.count > this.max) this.dequeue();
	}

	/** if the item exists in the queue it will be moved to the end of the array,
	 * otherwise it will be enqueued
	 */
	enqueueOrMove(item: T) {
		const indexOfItem = this._items.indexOf(item);
		if (indexOfItem < 0) this.enqueue(item);
		else {
			this._items.splice(indexOfItem, 1);
			this.enqueue(item);
		}
	}

	/** removes the first item of the array */
	dequeue(): T {
		if (!this._items || this.isEmpty()) return null;

		return this._items.shift();
	}

	/** returns the last item of the array */
	peek(): T {
		if (!this._items || this.isEmpty()) return null;

		return this._items[0];
	}

	/** returns true if the item is in the queue */
	contains(item: T): boolean {
		if (!this._items) return false;

		return this._items.includes(item);
	}

	isEmpty(): boolean {
		if (!this._items) return true;

		return this._items.length === 0;
	}

	/** drops all items from the array */
	dropAll() {
		this._items = [];
	}

	dropItem(item: T): boolean {
		const i = this._items.indexOf(item);
		if (i < 0) return false;
		this._items.splice(i, 1);
		return true;
	}
}
