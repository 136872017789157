import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';
import { FormsModule } from '@angular/forms';
import { AddressSearchResultsComponent } from './search-results/address-search-results/address-search-results.component';
import { WorkorderSearchResultsComponent } from './search-results/workorder-search-results/workorder-search-results.component';

@NgModule({
	declarations: [SearchBarComponent, SearchResultsComponent, AddressSearchResultsComponent, WorkorderSearchResultsComponent],
	imports: [CommonModule, UiComponentsModule, FormsModule],
	exports: [SearchBarComponent, SearchResultsComponent]
})
export class SearchModule {}
