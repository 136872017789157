import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class CustomFieldCode extends OmniModel<CustomFieldCode, Contracts.CustomFieldCodeContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _code: SedaruUtils.Undoable<string>;
	get code(): string {
		if (!this._code) this._code = new SedaruUtils.Undoable<string>();
		return this._code.value;
	}
	set code(value: string) {
		if (!this._code) {
			this._code = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._code.value = value;
	}

	private _codeType: SedaruUtils.Undoable<string>;
	get codeType(): string {
		if (!this._codeType) this._codeType = new SedaruUtils.Undoable<string>();
		return this._codeType.value;
	}
	set codeType(value: string) {
		if (!this._codeType) {
			this._codeType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._codeType.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _isActive: SedaruUtils.Undoable<boolean>;
	get isActive(): boolean {
		if (!this._isActive) this._isActive = new SedaruUtils.Undoable<boolean>();
		return this._isActive.value;
	}
	set isActive(value: boolean) {
		if (!this._isActive) {
			this._isActive = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._isActive.value = value;
	}

	static fromContract(contract: Contracts.CustomFieldCodeContract): CustomFieldCode {
		const model = new CustomFieldCode();
		model.id = contract.ObjectId.toString();
		model.code = contract.Code;
		model.codeType = contract.CodeType;
		model.description = contract.Description;
		model.isActive = contract.IsActive;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.CustomFieldCodeContract {
		const contract = new Contracts.CustomFieldCodeContract();
		contract.ObjectId = +this.id;
		contract.Code = this.code;
		contract.CodeType = this.codeType;
		contract.Description = this.description;
		contract.IsActive = this.isActive;
		return contract;
	}

	onUpdateInformation(copyModel: CustomFieldCode): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._code, () => {
				this.code = copyModel.code;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._codeType, () => {
				this.codeType = copyModel.codeType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._isActive, () => {
				this.isActive = copyModel.isActive;
			}) || wasChanged;
		return wasChanged;
	}
}
