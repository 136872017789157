<div class="scrolling-content">
	<span class="outline-header" *ngIf="workType">
		{{ workType || '&nbsp;' }}
		<mat-divider></mat-divider>
	</span>
	<div class="list-wrapper">
		<app-omni-list
			[omniListItems]="workorderSteps"
			[initialScroll]="scrollPosition"
			(scrolled)="onScroll($event)"
			(itemClicked)="goTo($event)"
			(rightIconClicked)="handleRightIconClick($event)"
		></app-omni-list>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button *ngIf='advancedMode' mat-button class="omni-md-font" [ngClass]="{ disabled: !relatedWorkorderCount }" (click)="relatedWorkorderCount && goToRelatedWorkorder()">
			<img class="omni-sm-img" src="assets/addlink.png" /> related WOs
		</button>
		<span style="flex:1"></span>
		<!-- <button [class.omni-button-disabled]="disableCloseButton" mat-button class="omni-md-font" (click)="closeWorkOrderOperation()">close <img class="omni-sm-img" src="assets/done.png" /></button> -->
		<div class="badge" *ngIf="relatedWorkorderCount">{{ relatedWorkorderCount }}</div>
	</mat-card-actions>
</mat-card-footer>
