<div class="scrolling-content" #scrollingContainer (scroll)="onScroll()">
	<div class="workorder-filter">
		<app-menu-panel-field
			*ngFor="let field of filterFields; index as i"
			[(value)]="field.value"
			[header]="field.name"
			[placeHolder]=""
			[type]="'text'"
			[cssClass]="getBackgroundColor(i)"
			[buttonEnabled]="field.name !== 'priority' || enablePriority"
			(goToButtonClick)="goTo(field)"
			[disabled]="isAdvancedWorkOrder"
		></app-menu-panel-field>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button [ngClass]="{ disabled: !enableCustomField }" class="omni-md-font" (click)="addCustomFieldFilter()"><img class="omni-sm-img" src="assets/plus.png" /> custom field</button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" [class.disabled]="!currentFilter.isDirty" (click)="currentFilter.isDirty && onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
