export class DateUtil {
	static isDefaultDate(date: Date): boolean {
		if (!date) return false;

		if (new Date(0).getTime() === date.getTime()) {
			return true;
		}

		return date.getFullYear() === 1;
	}

	static isValidDate(date: string | Date): boolean {
		if (!date) return false;

		let dateItem: Date;
		if (date instanceof Date) {
			dateItem = date as Date;
		} else if (typeof date === 'string' || typeof date === 'number') {
			dateItem = new Date(date);
		}

		if (dateItem.getTime() === NaN || dateItem.toString() === 'Invalid Date') {
			return false;
		}

		if (DateUtil.isDefaultDate(dateItem)) {
			return false;
		}

		return true;
	}

	static isInvalidDate(date: Date): boolean {
		if (!date) return true;

		if (date.getTime() === NaN || date.toString() === 'Invalid Date') {
			return true;
		}
		return false;
	}

	static convertLocaleString(date: string) {
		return DateUtil.isValidDate(date) ? new Date(date).toLocaleString() : '';
	}

	static convertLocaleDateString(date: string) {
		return DateUtil.isValidDate(date) ? new Date(date).toLocaleDateString() : '';
	}

	static convertLocaleTimeString(date: string) {
		return DateUtil.isValidDate(date) ? new Date(date).toLocaleTimeString() : '';
	}

	static convertLocaleUTCString(date: string): string {
		return DateUtil.isValidDate(date) ? new Date(date).toLocaleString('en-US', { timeZone: 'UTC' }) : '';
	}

	static convertLocaleUTCStringFormatted(date: string): string {
		return DateUtil.isValidDate(date) ? new Date(date).toLocaleString('en-US', {
			timeZone: 'UTC',
			day: 'numeric',
			month: 'numeric',
			year: '2-digit',
			hour: '2-digit',
			minute: '2-digit'
		}) : '';
	}

	static normalizeDate(isoDateString: string): string {
		if (!isoDateString) return '';
		const splitted = isoDateString.split('T');
		const day = splitted[0].trim();
		if (splitted.length === 1) return day;
		const time = splitted[1].trim();
		return `${day} ${time}`;
	}

	static get now(): Date {
		return new Date();
	}

	static get default(): Date {
		return new Date(0);
	}

	static getTimeZoneAgnosticString(dateValue: Date): string {
		if (!dateValue) return '';
		const date = new Date(Date.UTC(dateValue.getFullYear(),
			dateValue.getMonth(),
			dateValue.getDate(),
			dateValue.getHours(),
			dateValue.getMinutes(),
			dateValue.getSeconds(),
			dateValue.getMilliseconds()));
		const utcString = date.toISOString();
		return utcString.replace('Z', '');
	}

	static yyyyMMddHHmmss(dateValue: Date): string {
		if (!dateValue) return '';

		const year = dateValue.getFullYear();
		const month = dateValue.getMonth() + 1;
		const date = dateValue.getDate();
		const hours = dateValue.getHours();
		const minutes = dateValue.getMinutes();
		const seconds = dateValue.getSeconds();

		return year.toString() + DateUtil.pad(month) + DateUtil.pad(date) + DateUtil.pad(hours) + DateUtil.pad(minutes) + DateUtil.pad(seconds);
	}

	private static pad(num: number): string {
		return num < 10 ? '0' + num : num.toString();
	}

	static getDateComponent(value: Date): Date {
		if (!value) return null;
		return new Date(value.toDateString());
	}

	static daysInMonth(value: Date): number {
		return (new Date(value.getFullYear(), value.getMonth() + 1, 0)).getDate();
	}

	static getFirstDayOfMonth(value: Date): Date {
		const d = new Date(value);
		d.setDate(1);
		return d;
	}

	static getDayDifference(dateOne: Date, dateTwo: Date): number {
		dateOne = this.getDateComponent(dateOne);
		dateTwo = this.getDateComponent(dateTwo);

		if (dateOne.getTime() == dateTwo.getTime()) return 0;

		const second = 1000;
		const minute = second * 60;
		const hour = minute * 60;
		const day = hour * 24;

		let difference = 0;
		if (dateOne > dateTwo) {
			difference = dateOne.getTime() - dateTwo.getTime();
		} else {
			difference = dateTwo.getTime() - dateOne.getTime();
		}

		return Math.round(difference / day);
	}

	static getMonthDifference(date1: Date, date2: Date): number {
		const fromDate = this.getDateComponent(date1.getTime() > date2.getTime() ? date2 : date1);
		const toDate = this.getDateComponent(date1.getTime() < date2.getTime() ? date2 : date1);

		let monthDifference = toDate.getMonth() - fromDate.getMonth();
		let ignoreFirstYear = false;
		if (monthDifference < 0) {
			monthDifference = toDate.getMonth() + 12 - fromDate.getMonth();
			ignoreFirstYear = true;
		}

		let yearDifference = toDate.getFullYear() - fromDate.getFullYear();

		if (ignoreFirstYear) yearDifference -= 1;

		monthDifference += (yearDifference * 12);

		return monthDifference;
	}


	static getNextDateByDayOfWeek(date: Date, dayOfWeek: number): Date {
		const result = new Date(date);
		if (date.getDay() === dayOfWeek) {
			return result;
		}


		let dayDifference = dayOfWeek - date.getDay();
		if (date.getDay() > dayOfWeek) {
			dayDifference += 7;
		}

		result.setDate(result.getDate() + dayDifference);
		return result;
	}

	static getPreviousDateByDayOfWeek(date: Date, dayOfWeek: number): Date {
		const result = new Date(date);
		if (date.getDay() === dayOfWeek) {
			return result;
		}

		let dayDifference = dayOfWeek - date.getDay();
		if (date.getDay() < dayOfWeek) {
			dayDifference -= 7;
		}

		result.setDate(result.getDate() + dayDifference);
		return result;
	}

	static getFormattedDateTimeString(date: Date): string {
		if (!DateUtil.isValidDate(date)) return '';

		const timeZoneString = DateUtil.getTimeZoneAgnosticString(date);
		if (!timeZoneString) return '';

		return timeZoneString.split('.')[0]?.replace('T', ' ');
	}

	/**
	 *  Method to Parse numerical or string date into Date object with respect to a given format
	 * @param input input date string or number
	 * @param format input date format
	 * @returns Date object for a valid date input
	 */
	static parseDate(input: string | number, format: string): Date {
		if (typeof input === 'number') {
			return new Date(input);
		}

		if (DateUtil.isValidTimeStamp(input)) {
			return new Date(Number(input));
		}

		format = format || 'dd.MM.yyyy'; // some default format
		const parts = (input.match(/(\d+)/g) || []).map(p => Number(p));
		if (!parts || parts.length < 3) {
			throw new Error('Invalid date format');
		}
		let i = 0;
		const fmt = {};
		// extract date-part indexes from the format
		format.replace(/(yyyy|dd|MM)/g, (part) => { fmt[part] = i++; return ''; });
		return new Date(
			parts[fmt['yyyy']],
			parts[fmt['MM']] - 1,
			parts[fmt['dd']]
		);
	}

	/**
	 * Method to check for a valid timestamp in the given string input
	 * @param input input string timestamp
	 * @returns True if a valid timestamp, False otherwise
	 */
	static isValidTimeStamp(input: string): boolean {
		const reg = new RegExp(/^(\-)?(\d)+$/);
		return reg.test(input);
	}
}
