import { MaterialCost } from './material-cost.model';
import { MaterialCostContract } from '../../contracts/work-order';

export class MaterialCosts extends Array<MaterialCost> {
	static fromContracts(materialCostContracts: MaterialCostContract[]) {
		const materialCosts = new MaterialCosts();
		for (const materialCostContract of materialCostContracts) {
			const materialCost = MaterialCost.fromContract(materialCostContract);
			materialCosts.push(materialCost);
		}
		return materialCosts;
	}

	static fromModels(materialCostModels: MaterialCost[]): MaterialCosts {
		const materialCosts = new MaterialCosts();
		for (const materialCost of materialCostModels) materialCosts.push(materialCost);
		return materialCosts;
	}

	getContracts = () => {
		const materialCostContracts = new Array<MaterialCostContract>();
		for (const materialCost of this) {
			const materialCostContract = materialCost.getContract();
			materialCostContracts.push(materialCostContract);
		}
		return materialCostContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
