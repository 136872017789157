<div class="scrolling-content" #panel>
	<app-query-statement
		*ngFor="let queryStatement of queryStatementList; let i = index"
		[maxHeight]="panelHeight"
		[metric]="metric"
		[queryStatement]="queryStatement"
		[passModeToQueryBuilderComponent]="onModeChange"
		[statementIndex]="i"
		[deleteThisQueryStatement]="deleteQueryStatement"
		[passSourceToQueryBuilderComponent]="onQueryStatementSourceChange"
		[sendQueryStatementDoneSignal]="saveQueryStatementToMetric"
		(queryStatementLoading)="onQueryStatementLoding($event)"
	></app-query-statement>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions *ngIf="showFooter">
		<button mat-button class="omni-md-font" (click)="addNewQueryStatement()">add query <img class="omni-sm-img" src="assets/plus.png" /></button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" (click)="save()">done <img class="omni-sm-img" src="assets/done.png" /></button>
	</mat-card-actions>
</mat-card-footer>
