import { InvokableEvent } from './invokable-event';

export class DelayedAction {
	private _timerHandle: any;
	private _me: DelayedAction;

	private _onDelayedActionTriggered: InvokableEvent;
	public get onDelayedActionTriggered(): InvokableEvent {
		if (!this._onDelayedActionTriggered) {
			this._onDelayedActionTriggered = new InvokableEvent();
		}
		return this._onDelayedActionTriggered;
	}

	public get delayMilliseconds(): number {
		return this._delayMilliseconds;
	}

	constructor(private _delayMilliseconds: number) {}

	restart() {
		this.cancel();
		this._timerHandle = setTimeout(
			() => {
				this.executeTimer();
			},
			this.delayMilliseconds,
			this
		);
	}

	cancel() {
		clearTimeout(this._timerHandle);
	}

	private executeTimer() {
		this.onDelayedActionTriggered.invoke(this, undefined);
	}
}
