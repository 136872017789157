import { ErrorHandler, Injectable } from '@angular/core';
import { RaygunErrorHandler } from './raygun-analytics-channel';
import { RollbarAnalyticsChannel, RollbarErrorHandler } from './rollbar-analytics-channel';

@Injectable()
export class ErrorHandlerCluster implements ErrorHandler {
	private static _current: ErrorHandlerCluster;
	static get current(): ErrorHandlerCluster {
		return ErrorHandlerCluster._current;
	}

	private _errorHandlers: Array<ErrorHandler>;
	private get errorHandlers(): Array<ErrorHandler> {
		if (!this._errorHandlers) this._errorHandlers = new Array<ErrorHandler>();

		return this._errorHandlers;
	}

	consoleErrorEnabled = true;

	constructor() {
		ErrorHandlerCluster._current = this;
	}

	handleError(error: any): void {
		for (const errorHandler of this.errorHandlers) {
			errorHandler.handleError(error);
		}

		if (!this.consoleErrorEnabled) return;

		console.error(error);
	}

	registerErrorHandler(errorHandler: ErrorHandler) {
		this.errorHandlers.push(errorHandler);
	}
}
