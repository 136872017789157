export enum WorkOrderFieldNames {
	WorkOrderKey,
	WorkType,
	WorkOrderAssignTo,
	Supervisor,
	Team,
	WorkOrderStatus,
	CreatedDate,
	Priority,
	Description,
	StartDate,
	CompletedDate,
	CompletedBy,
	IsCorrective,
	Comments,
	CreatedBy,
	BudgetId,
	CustomerName,
	Location,
	SystemId,
	WorkTypeId,
	Xcoordinate,
	YCoordinate,
	IsRequest,
	ParentWorkOrderKey,
	WorkOrderCustomFields,
	TaskCustomFields,
	Employee,
	RateType,
	DateStamp,
	AddLEMTask,
	EmployeeTrade,
	WorkOrderBudget,
	WorkTaskAssignTo,
	WorkTaskStatus,
	WorkTaskCompletedDate,
	WorkOrderTeam,
	WorkTaskTeam
}
