<div class="wall" #wall *ngIf="active">
	<div class="background" #wrapper [ngStyle]="{ 'grid-template-columns': colTemplate, 'grid-template-rows': rowTemplate }">
		<div #gridItem *ngFor="let item of gridItems; index as i">
			<app-node-network [elementWrapper]="gridItem" [delay]="item.delay"></app-node-network>
		</div>
	</div>
	<div class="values" [style.width]="windowWidth" [style.height]="windowHeight" *ngIf="!error">
		<div class="top-section">
			<div class="left-top-section" *ngIf="mode !== 'phone'">
				<span #value class="value-item" style="grid-column-start:1; grid-column-end:5; font-size:2vw">Be Better Than Yesterday</span>
				<span #value class="value-item" style="writing-mode:vertical-rl; grid-row-start:2; grid-row-end:6; font-size:1.5vw; padding-right:20%">Let More Motivate</span>
				<span #value class="value-item" style="grid-column-start:2; grid-column-end:4; grid-row-start:2; grid-row-end:5; font-size:3.5vw">Build Tomorrow Passionately</span>
				<span #value class="value-item" style="text-align:right; font-size:1.4vw">Never Settle</span>
				<span #value class="value-item" style="text-align:right; font-size:2vw">Inspire Next</span>
				<span #value class="value-item" style="text-align:right; font-size:1.4vw">Drive Change</span>
				<span #value class="value-item" style="font-size:1.5vw">Invest Yourself</span>
				<span #value class="value-item" style="grid-column-start:3; grid-column-end:5; font-size:3.5vw">Do Awesome Things</span>
			</div>
			<div class="right-top-section" [ngStyle]="{ width: mode === 'phone' ? '100%' : '50%' }">
				<span
					#value
					class="value-item"
					[style.fontSize]="mode === 'phone' ? '4vw' : '2vw'"
					style="writing-mode:vertical-rl; grid-row-start:1; grid-row-end:4; text-align: center;line-height: 75%;"
					>Commit to Commitment</span
				>
				<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '6vw' : '4vw'" style="grid-column-start:2; grid-column-end:4; text-align:center">Care More, Always</span>
				<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '4.5vw' : '2.5vw'" style="text-align:left; ">&nbsp; Integrity is a Deliverable</span>
				<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '4vw' : '2vw'" style="text-align:right">Share Openly, Respectfully</span>
				<span
					#value
					class="value-item"
					[style.fontSize]="mode === 'phone' ? '4vw' : '2vw'"
					style="grid-column-start:3; grid-column-end:4; writing-mode:vertical-rl; transform:rotate(180deg); grid-row-start:2; grid-row-end:4; text-align:center"
					>Always Bring You</span
				>
			</div>
		</div>

		<div class="middle-section">
			<img src="assets/sedaru-header-light.png" [ngStyle]="{ width: imageWidth, height: imageHeight }" />
		</div>

		<div class="bottom-section">
			<div class="top-bottom-section">
				<div class="left-bottom-section" [ngStyle]="{ width: mode === 'phone' ? '100%' : '40%' }">
					<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '5vw' : '3vw'" style="grid-column-start:1; grid-column-end:3; ">Learn from Everyone</span>
					<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '3.5vw' : '1.5vw'" style="grid-row-start:2;grid-row-end:5; writing-mode:vertical-rl; ">Invest in Team</span>
					<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '4vw' : '2vw'" style="grid-column-start:2; text-align:left; ">Bring Joy</span>
					<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '5vw' : '3vw'" style="grid-column-start:2; text-align:center; ">Have Fun</span>
					<span #value class="value-item" [style.fontSize]="mode === 'phone' ? '3.5vw' : '1.5vw'" style="grid-column-start:2; text-align:center; "> Laugh at Yourself</span>
				</div>
				<div class="right-bottom-section" *ngIf="mode !== 'phone'">
					<span #value class="value-item" style="grid-row-start:1; grid-row-end:4; writing-mode:vertical-lr; transform:rotate(180deg); font-size:1.5vw; text-align: center"
						>Disrupt Norms</span
					>
					<span #value class="value-item" style="grid-column-start:2; font-size:3vw; padding-top:3%">Create and Live Forever</span>
					<span #value class="value-item" style="grid-column-start:2; font-size:2vw">Cherish, Value Family</span>
					<span #value class="value-item" style="grid-column-start:2; text-align:center; font-size:3vw">#BeMoreTogether</span>
					<span #value class="value-item" style="grid-column-start:1; grid-column-end:3; font-size:2vw">Value Different</span>
				</div>
			</div>
			<div #value [style.fontSize]="mode === 'phone' ? '4vw' : '2vw'" class="value-item footer-section">Change for Something You Believe In</div>
			<div class="loading" [style.fontSize]="mode === 'phone' ? '4vw' : '2vw'">loading ...</div>
		</div>
	</div>

	<div class="error" *ngIf="error">
		<div class="message">{{ errorMessage }}</div>
	</div>
</div>
