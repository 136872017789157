import { Component, Input, Output, EventEmitter, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { QueryFieldValueEditingMode } from 'app/metric/list-metric/edit-metric/metric-query/query-field/query-field-value-editing-mode.enum';

@Component({
	selector: 'app-drop-down',
	templateUrl: './drop-down.component.html',
	styleUrls: ['./drop-down.component.scss']
})
export class DropdownComponent {
	readonly editingModeEnum = QueryFieldValueEditingMode;
	/**
	 * the text shown as the main text of the dropdown
	 */
	header: string;
	/**
	 * the place holder for the header
	 */
	@Input() headerPlaceHolder: string;
	/**
	 * an array of strings that will show in the list dropped down
	 */
	@Input() itemList: any = [];
	/**
	 * The existed item that passed down to this component
	 */
	@Input() selectedItem: any;
	/**
	 * a hex or rgb(a) color. if not provided, the header will be transparent
	 */
	@Input() headerColor: string;
	/**
	 *  whether the dropdown is opened or not.
	 */
	@Input() opened: boolean;
	/**
	 *  whether the expansion panel is disabled or not
	 */
	@Input() expansionDisabled: boolean;

	/**
	 * The type of the html input tag
	 */
	@Input() htmlInputCriteria: any;

	/**
	 * indicates if we want to use the drop down in a selection mode or free text edition mode
	 */
	@Input() mode: string;
	/**
	 *  a custom call back function
	 */
	@Input() callBackFunction: () => {};

	@Input() inputValidationFunction: () => {};

	private _maxHeight: number;
	@Input() set maxHeight(h: number) {
		if (!h || h < 120) this._maxHeight = 120;
		else this._maxHeight = h;
	}
	get maxHeight() {
		return this._maxHeight;
	}
	/**
	 * the event watched by the parent component a item in the child panel is selected
	 */
	@Output() itemSelected = new EventEmitter();

	@Output() inputValueChanged = new EventEmitter<string>();

	@Output() inputKeyUp = new EventEmitter();
	/**
	 * the event watched by the parent when a child panel is being open
	 */
	@Output() expansionOpened = new EventEmitter();

	get showHighlight(): boolean {
		if (this.mode === this.editingModeEnum.DISABLED) return false;
		if (this.expansionDisabled) return false;
		return this.opened;
	}

	get showShadow(): boolean {
		if (this.mode === this.editingModeEnum.FREETEXT) return true;
		if (this.mode === this.editingModeEnum.DISABLED) return true;
		return !this.opened;
	}

	get showInput(): boolean {
		return this.mode === QueryFieldValueEditingMode.FREETEXT || this.mode === QueryFieldValueEditingMode.DISABLED || this.mode === QueryFieldValueEditingMode.FREETEXT_AND_SELECTION;
	}

	get disableInput(): boolean {
		return this.mode === QueryFieldValueEditingMode.DISABLED;
	}

	get showSpan(): boolean {
		return this.mode === QueryFieldValueEditingMode.SELECTION;
	}

	get showIcon(): boolean {
		return this.mode === QueryFieldValueEditingMode.SELECTION || this.mode === QueryFieldValueEditingMode.FREETEXT_AND_SELECTION || !this.expansionDisabled;
	}

	get showDropdown(): boolean {
		return this.mode === QueryFieldValueEditingMode.SELECTION || this.mode === QueryFieldValueEditingMode.FREETEXT_AND_SELECTION;
	}

	private _textInput = '';
	textInput: string;

	ngOnInit() {
		if (!this.callBackFunction) this.callBackFunction = () => null;
		if (!this.mode) this.mode = this.editingModeEnum.SELECTION;
		if (!this.htmlInputCriteria) this.htmlInputCriteria = { type: '' };
		this.setHeaderPlaceHolderForRange();
		if (this.selectedItem && this.selectedItem.name) {
			this.header = this.selectedItem.name;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.htmlInputCriteria) {
			this.setHeaderPlaceHolderForRange();
		}
		if (changes.selectedItem && changes.selectedItem.currentValue) {
			this.header = changes.selectedItem.currentValue.name;
		}
	}

	setHeaderPlaceHolderForRange() {
		if (this.headerPlaceHolder === '-') return;
		const { min, max } = this.htmlInputCriteria;
		if (min >= 0 && max >= 0) {
			this.headerPlaceHolder = `enter value between ${min} - ${max}`;
		}
	}

	/**
	 * The function opens the expansion
	 */
	openExpansion() {
		this.opened = true;
		this.callBackFunction();
	}

	/**
	 * The function closes the expansion
	 */
	closeExpansion() {
		this.opened = false;
	}

	/**
	 * The function will emit the selected item to the parent compoenet
	 * @param {any} item - The selected item
	 */
	onItemSelected(item: any) {
		this.itemSelected.emit(item);
		this.header = item.name;
		this.closeExpansion();
	}

	onInputSet() {
		this.inputValueChanged.emit(this.header);
	}

	buttonClicked() {
		if (this.opened) this.closeExpansion();
		else this.openExpansion();
	}
}
