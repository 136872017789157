import { AssetRecord } from './asset-record.model';
import { HistoryRecord } from './history-record.model';
import { HierarchyAssetRecord } from './hierarchy-asset-record.model';
import { AssetDefinition } from 'models/asset-definition.model';

export class RecordContextGroup {
	public constructor(init?: Partial<RecordContextGroup>) {
		Object.assign(this, init);
	}

	get assetDefinition(): AssetDefinition {
		if (this.assetRecord) return this.assetRecord.assetDefinition;
		if (this.historyRecord) return this.historyRecord.assetDefinition;
		if (this.hierarchyRecord) return this.hierarchyRecord.assetDefinition;
		return null;
	}

	assetRecord: AssetRecord;
	historyRecord: HistoryRecord;
	workOrderRecord: any;
	hierarchyRecord: HierarchyAssetRecord;
	isHierarchyRecord: boolean;
}
