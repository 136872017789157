<div class="scrolling-content" #scrollingContent (scroll)="onScroll()">
	<ng-container *ngFor="let group of groups; let i = index">
		<div #list class="header" [ngStyle]="{ 'background-color': group.header.background.toString() }" (click)="toggleLists(list, i)">
			<div class="header-icon-wrapper">
				<img
					class="header-icon"
					*ngIf="group.header.leftIcon?.url"
					[src]="group.header.leftIcon.url"
					(click)="!group.header.leftIcon.disabled && group.header.leftIcon.action(); $event.stopPropagation()"
					[title]="group.header.leftIcon.tooltip || ''"
					[ngClass]="{ disabled: group.header.leftIcon.disabled }"
					[ngStyle]="{ width: group.header.leftIcon.size + 'px' }"
				/>
			</div>

			<span>
				{{ group.header.label }}
			</span>

			<div class="header-icon-wrapper">
				<img
					class="header-icon"
					*ngIf="group.header.rightIcon"
					[src]="group.header.rightIcon.url"
					(click)="!group.header.rightIcon.disabled && group.header.rightIcon.action(); $event.stopPropagation()"
					[title]="group.header.rightIcon.tooltip || ''"
					[ngClass]="{ disabled: group.header.rightIcon.disabled }"
					[ngStyle]="{ width: group.header.rightIcon.size + 'px' }"
				/>
			</div>
		</div>
		<div class="collapsible-list" [@collapseOnLeave] *ngIf="!group.collapsed">
			<div [@expandOnEnter] *ngFor="let item of group.getShortList(maxRecords); let last = last">
				<app-list-item
					[resolver]="getResolver(item)"
					[item]="item"
					(rightIconClicked)="rightIconClicked.emit($event)"
					(leftIconClicked)="leftIconClicked.emit($event)"
					(itemRightClicked)="itemRightClicked.emit($event)"
					(itemClicked)="itemSelected.emit($event)"
					(mouseenter)="!collapsing && itemHovered.emit(item.value)"
					(mouseleave)="!collapsing && mouseLeft.emit(item.value)"
					[isCurrentlySelected]="item.selected"
				></app-list-item>
			</div>
			<div *ngIf="group.items.length > maxRecords" (click)="this.group.onShowMore && this.group.onShowMore()" class="more-button">more...</div>
		</div>
	</ng-container>
</div>
