import { SedaruConnectionType } from './sedaru-connection-type.enum';

export class SedaruNotificationConnection {
	protocolVersion: string;
	isRegistered: boolean;

	constructor(customerCode: string, username: string, app: string, agent?: string, connectionId?: string, sprotocol?: string) {
		this._customerCode = customerCode;
		this._username = username;
		this._app = app;

		this._connectionId = connectionId ? connectionId : '';
		this._agent = agent ? agent : '';
	}

	private _customerCode: string;
	get customerCode(): string {
		return this._customerCode;
	}

	private _username: string;
	get username(): string {
		return this._username;
	}

	private _app: string;
	get app(): string {
		return this._app;
	}

	private _agent: string;
	get agent(): string {
		return this._agent;
	}

	private _connectionId: string;
	get connectionId(): string {
		return this._connectionId;
	}

	private _ipAddress: string;
	get ipAddress(): string {
		return this._ipAddress;
	}

	get currentType(): SedaruConnectionType {
		if (this.connectionId) {
			return SedaruConnectionType.connectionId;
		}

		if (!this.customerCode) {
			return SedaruConnectionType.global;
		}

		if (!this.app && !this.username) {
			return SedaruConnectionType.customerCode;
		}

		if (!this.app) {
			return SedaruConnectionType.customerCodeUser;
		}

		if (!this.username) {
			return SedaruConnectionType.customerCodeApp
		}

		return SedaruConnectionType.customerCodeAppUser;
	}

	private _connectionType: SedaruConnectionType;
	get connectionType(): SedaruConnectionType {
		return this._connectionType;
	}

	static fromAny(jObject: any): SedaruNotificationConnection {
		if (!jObject) {
			return null;
		}

		const connection = new SedaruNotificationConnection(jObject.CustomerCode, jObject.Username, jObject.App, jObject.Agent, jObject.ConnectionID);

		connection._ipAddress = jObject.IPAddress;
		return connection;
	}

	public isMatch(connection: SedaruNotificationConnection) {
		if (!connection) return false;

		if (connection.currentType === SedaruConnectionType.none) return false;

		if (connection.currentType === SedaruConnectionType.global) return true;

		if (connection.currentType === SedaruConnectionType.connectionId) {
			return connection.connectionId.toLowerCase() === this.connectionId.toLowerCase()
		}

		if (connection.currentType === SedaruConnectionType.customerCode) {
			return connection.customerCode.toLowerCase() === this.customerCode.toLowerCase();
		}

		if (connection.currentType === SedaruConnectionType.customerCodeUser) {
			return connection.customerCode.toLowerCase() === this.customerCode.toLowerCase() &&
				connection.username.toLowerCase() === this.username.toLowerCase();
		}

		if (connection.currentType === SedaruConnectionType.customerCodeApp) {
			return connection.customerCode.toLowerCase() === this.customerCode.toLowerCase() &&
				connection.app.toLowerCase() === this.app.toLowerCase();
		}

		if (connection.currentType === SedaruConnectionType.customerCodeAppUser) {
			return connection.customerCode.toLowerCase() === this.customerCode.toLowerCase() &&
				connection.app.toLowerCase() === this.app.toLowerCase() &&
				connection.username.toLowerCase() === this.username.toLowerCase();
		}

		return false;
	}

	toAny(): any {
		let val: any;
		val = {};
		val.CustomerCode = this.customerCode;
		val.Username = this.username;
		val.App = this.app;
		val.Agent = this.agent;
		val.ConnectionID = this.connectionId;

		if (this.protocolVersion) {
			val.ProtocolVersion = this.protocolVersion;
		}

		if (this.ipAddress) {
			val.IPAddress = this.ipAddress;
		}

		return val;
	}
}
