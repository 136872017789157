import { ContractBase } from '../../contracts/contract-base';
import { TimeframeDefinitionType } from './timeframe-definition-type.enum';
import { TimeframeUnit } from './timeframe-unit.enum';
import { TimeframeRangeType as TimeframeBoundlessType } from './timeframe-range-type.enum';

export class TimeframeDefinitionContract extends ContractBase {
	public type: TimeframeDefinitionType;
	public unit: TimeframeUnit;
	public interval: number;
	public isInclusive: boolean;
	public date: string;
	public boundlessType: TimeframeBoundlessType;
	public timeframeField: string;
}
