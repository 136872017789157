<app-menu-panel-field
    [header]="'*every # of years'"
    [(value)]="pattern.interval"
    [cssClass]="'orange-background'"
    [width]="fieldFullSize"
    [height]="fieldHalfSize"
    [type]="'number'"
    [disabled]="isReadOnly"
    [buttonEnabled]="false"
></app-menu-panel-field>
