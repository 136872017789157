import { ManagerBase } from 'domain-service/manager-base';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { GlobalConfig } from '../../models/global-config.model';

export class ConfigurationManager extends ManagerBase {
	get customerCodeConfiguration(): GlobalConfig {
		return this.domain.userService.globalConfig;
	}

	get guiConfig(): GuiConfig[] {
		return this.domain.guiConfigService.availableConfigurations;
	}

	get userOmniConfiguration(): any {
		return undefined;
	}

	constructor(interopService: OmniInteropService) {
		super(interopService);
	}
}
