import { MetricTypes, QueryMode } from './metric-query.model';
import { MetricQuery } from './metric-query.model';
import { MetricSource } from './metric-source.model';
import {
	BasicDataFilterConditionJSON,
	BasicDataFilterJSON,
	BasicDataFiltersJSON,
	CustomFieldFilter,
	CustomFieldFilters,
	CustomFieldsFilterJSON,
	WorkOrderFilterField,
	WorkOrderFilterFields,
	WorkOrderFilterJSON,
	WorkOrderQueryJSON
} from './work-order';
import { WorkOrderSourceType } from './work-order-source-type.enum';

export class QueryFilter {
	workOrderFields: WorkOrderFilterFields;
	customFields: CustomFieldFilters;
	constructor() {
		this.workOrderFields = new WorkOrderFilterFields();
		this.customFields = new CustomFieldFilters();
	}
}

export class WorkOrderFilter {
	filterType = 'workOrderFilter';
	status: string;
	assignedto: string;
	teamid: string;
	supervisor: string;
	priority: string;
	worktypeid: string;
	customFields: CustomFieldFilters;
	isDirty?: boolean;
	constructor(contract?: any) {
		this.customFields = new CustomFieldFilters();

		if (!contract) return;

		this.status = contract.status;
		this.assignedto = contract.assignedto;
		this.teamid = contract.teamid;
		this.supervisor = contract.supervisor;
		this.priority = contract.priority;
		this.worktypeid = contract.worktypeid;
		this.isDirty = contract.isDirty;
		if (contract?.customFields?.length) {
			for (const customField of contract.customFields) {
				this.customFields.push(CustomFieldFilter.fromContract(customField));
			}
		}
	}
}

export class TaskFilter {
	filterType = 'taskFilter';
	status: string;
	assignedto: string;
	teamid: string;
	taskkey: string;
	customFields: CustomFieldFilters;
	isDirty?: boolean;
	constructor(contract?: any) {
		this.customFields = new CustomFieldFilters();

		if (!contract) return;

		this.status = contract.status;
		this.assignedto = contract.assignedto;
		this.teamid = contract.teamid;
		this.taskkey = contract.taskkey;
		this.isDirty = contract.isDirty;
		if (contract?.customFields?.length) {
			for (const customField of contract.customFields) {
				this.customFields.push(CustomFieldFilter.fromContract(customField));
			}
		}
	}
}
export class AWOQuery extends MetricQuery {
	workOrderFilter: WorkOrderFilter;
	taskFilter: TaskFilter;
	mode = QueryMode.workOrderFilter;
	isDirty?: boolean;
	constructor(contract?: AWOQuery, source?: MetricSource) {
		super();

		this.workOrderFilter = new WorkOrderFilter(contract?.workOrderFilter);
		this.taskFilter = new TaskFilter(contract?.taskFilter);

		// for older metrics
		if ((contract as any)?._workOrderFilter && source) {
			if (source.workOrderSourceType === WorkOrderSourceType.workOrders) {
				for (const fieldInContract of (contract as any)._workOrderFilter) {
					try {
						this.workOrderFilter[fieldInContract._key] = fieldInContract._value._initialValue;
					} catch (error) {
						console.error(error);
					}
				}
				if ((contract as any)._customFieldsFilter) {
					this.workOrderFilter.customFields.fromPojo((contract as any)._customFieldsFilter);
				}
			}
			if (source.workOrderSourceType === WorkOrderSourceType.task) {
				for (const fieldInContract of (contract as any)._workOrderFilter) {
					try {
						this.taskFilter[fieldInContract._key] = fieldInContract._value._initialValue;
					} catch (error) {
						console.error(error);
					}
				}
				if ((contract as any)._customFieldsFilter) {
					this.taskFilter.customFields.fromPojo((contract as any)._customFieldsFilter);
				}
			}
		}
	}

	getWorkOrderFilterJSON(): WorkOrderQueryJSON {
		const workOrderJson = new WorkOrderFilterJSON();
		workOrderJson.filters = new BasicDataFiltersJSON();
		if (this.workOrderFilter.customFields) workOrderJson.customFields = this.workOrderFilter.customFields.getJSON();
		for (const [key, value] of Object.entries(this.workOrderFilter)) {
			if (key === 'customFields' || key === 'filterType') continue;
			if (!value) continue;
			const filterJSON = new BasicDataFilterJSON();
			filterJSON.fieldIdentifier = key;
			filterJSON.logicalOperator = 'AND';
			const condition = new BasicDataFilterConditionJSON();
			condition.comparisonOperator = 'In';
			condition.values = value.split(',');
			condition.type = 'string';
			filterJSON.conditions = [condition];
			workOrderJson.filters.push(filterJSON);
		}
		if (workOrderJson.filters.length) workOrderJson.filters[0].logicalOperator = null;

		const tasksJson = new WorkOrderFilterJSON();
		tasksJson.filters = new BasicDataFiltersJSON();
		if (this.taskFilter.customFields) tasksJson.customFields = this.taskFilter.customFields.getJSON();
		for (const [key, value] of Object.entries(this.taskFilter)) {
			if (key === 'customFields' || key === 'filterType') continue;
			if (!value) continue;
			const filterJSON = new BasicDataFilterJSON();
			filterJSON.fieldIdentifier = key;
			filterJSON.logicalOperator = 'AND';
			const condition = new BasicDataFilterConditionJSON();
			condition.comparisonOperator = 'In';
			condition.values = value.split(',');
			condition.type = 'string';
			filterJSON.conditions = [condition];
			tasksJson.filters.push(filterJSON);
		}
		if (tasksJson.filters.length) tasksJson.filters[0].logicalOperator = null;

		return { workOrder: workOrderJson, tasks: tasksJson };
	}
}
