import { Component, OnInit, Input } from '@angular/core';
import { FormPageDefinition } from 'models';
import { BaseFormResolver } from '../form-resolvers/base-form.resolver';

@Component({
	selector: 'app-form-page',
	templateUrl: './form-page.component.html',
	styleUrls: ['./form-page.component.scss']
})
export class FormPageComponent {
	@Input() formPage: FormPageDefinition;
	@Input() formResolver: BaseFormResolver;
	/** active, hidden-left, or hidden-right */
	@Input() pageVisibility: string;
}
