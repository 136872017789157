import { ContractBase } from '../contract-base';
import { CustomFieldContract } from './custom-field-contract';

export class WorkOrderTemplateContract extends ContractBase {
	public ObjectId: number;
	public WoTemplateId: string;
	public WoTemplateName: string;
	public AssetType: string;
	public CustomFields: CustomFieldContract[];
}
