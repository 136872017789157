import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { UserService } from 'app/user/user.service';
import { Color } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { AssetRecord } from 'models/records/asset-record.model';
import { FindResultItem } from 'sedaru-util/esri-core/find-result';
import { RecordContextGroup } from 'models/records/record-context-group';
import { AdvancedWorkOrder } from 'models/work-order';
import { HeaderedList, HeaderedListButton } from 'app/ui-components/collapsible-list/headered-list.model';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { NoIconOneLineTemplateResolver } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/np-icon-one-line.resolver';
import { CollapsibleListComponent } from 'app/ui-components/collapsible-list/collapsible-list.component';
import { Subscription } from 'rxjs';
import { SearchService } from 'app/search/search.service';
import { LayerResultModel, LayerFeatureModel } from 'app/search/layer-result.model';
import { SearchResultItemResolver } from 'app/ui-components/search-result-item/search-result-item.resolver';

/**
 * This component is designed to be placed in the right side panel.
 * It displays a list of available map types.
 */
@Component({
	selector: 'app-map-asset-list',
	templateUrl: './map-asset-list.component.html',
	styleUrls: ['./map-asset-list.component.scss']
})
export class MapAssetListComponent extends MenuPanelBaseComponent implements OnInit, OnDestroy {
	@ViewChild(CollapsibleListComponent, { static: false }) list: CollapsibleListComponent;

	mode: string;

	context = this;

	resetWorkOrderIdentifiedResultList = true;

	assetGroups: HeaderedList[];

	private identifiedItems: FindResultItem[];

	private workOrder: AdvancedWorkOrder;

	private cachedListResolvers = new Map<any, ListItemResolver>();

	private searchUpdateHandler: Subscription;

	private searchResultsComplete: boolean;

	searchMessage: string;
	/**
	 * The constructly currently initialized a hard coded list of map types along with loading and injecting dependencies.
	 * @param {OmniMapService} mapService - Provides services to manage changes in the ESRI map added  to the canvas.
	 * appropriate component with the change in the current route.
	 */
	constructor(
		private mapService: CanvasMapService,
		private graphicsService: OmniMapGraphicsService,
		private userService: UserService,
		private searchService: SearchService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;

		this.assetGroups = [];

		this.mode = args.parameter.mode;
		if (args.isNavigatingBack) this.assetGroups = this.uiState?.assetGroups;
		this.identifiedItems = args.parameter.identifyResults;
		this.workOrder = args.parameter.workOrder;

		this.assetSearchQuery = args.searchTerm ? args.searchTerm : null;
	}

	onPageReload(args: NavigationArgs) {
		this.onPageNavigatedTo(args);
		this.ngOnInit();
	}

	ngOnInit() {
		this.graphicsService.closeMapToolTip();

		if (this.assetGroups?.length) {
			this.menuPanelComponent.updateView({ showPacifier: false });
			this.menuPanelComponent.title = this.mode === 'search-results' ? 'search results' : 'map selection';
			this.highlightAssetsOnMap();
			return;
		}

		this.menuPanelComponent.updateView({});
		this.menuPanelComponent.title = this.mode === 'search-results' ? 'searching' : 'map selection';
		this.menuPanelComponent.updatePacifier(true);

		if (this.mode === 'search-results') {
			this.searchMessage = 'searching...';
			this.searchUpdateHandler = this.searchService.searchUpdated$.subscribe((data: any) => {
				this.searchResultsComplete = this.searchService.searchComplete;
				this.searchMessage = 'searching for ' + this.searchService.searchQuery;

				if (!this.searchResultsComplete) return;

				this.searchUpdateHandler?.unsubscribe();

				if (data.tabId === this.config.id) {
					NavigationService.current.activeSidePanel.updateSearchTerm(this.searchService.searchQuery);
					if (this.searchResultsComplete) this.menuPanelComponent.title = 'search results';
					const isUpdatePacifier = this.searchResultsComplete ? false : true;
					this.menuPanelComponent.updatePacifier(isUpdatePacifier);
					const searchResultsMap = this.getAssetDictionaryFromSerchResults(this.searchService.searchResults);
					if (this.searchResultsComplete) this.searchMessage = this.searchService.searchQuery ? 'no results for ' + this.searchService.searchQuery : 'no results found';
					else this.searchMessage = 'searching for ' + this.searchService.searchQuery;
					this.filterAssetList(searchResultsMap);
				} else {
					this.searchResultsComplete = this.searchService.searchComplete;
					if (this.searchResultsComplete) this.searchMessage = this.searchService.searchQuery ? 'try again' : 'no results found';
					this.menuPanelComponent.updatePacifier(false);
					if (this.searchResultsComplete) this.menuPanelComponent.title = 'search results';
					NavigationService.current.activeSidePanel.updateSearchTerm('');
				}
			});
			return;
		}
		this.highlightAssetsOnMap();
		const assetMap = this.getAssetDictionaryFromMapResults(this.identifiedItems);
		this.filterAssetList(assetMap);
	}

	ngAfterViewInit() {
		if (this.uiState?.scrollPosition) this.scrollToPosition(this.uiState.scrollPosition);
	}

	ngOnDestroy() {
		this.searchUpdateHandler?.unsubscribe();
	}

	onScroll(position: number) {
		if (!this.uiState) this.uiState = { scrollPosition: 0 };
		this.uiState.scrollPosition = position;
	}

	scrollToPosition(position: number) {
		this.list.scrollTo(position);
	}

	filterAssetList(assetMap: Map<string, AssetRecord[]>) {
		this.assetGroups = [];
		if (assetMap.size === 1 && this.searchResultsComplete) {
			this.menuPanelComponent.updatePacifier(false);
			// NavigationService.removePageFromBackStack(Pages.mapAssetsList);
			return NavigationService.navigateTo(Pages.singleAssetList, { list: assetMap.values().next().value, 'asset-type': assetMap.keys().next().value, mode: this.mode });
		}
		assetMap.forEach((list, header) => {
			const assetGroup = new HeaderedList();
			assetGroup.header.label = header.toLowerCase();
			const definition = this.userService.globalConfig.assetDefinitions.getByAssetType(header);
			assetGroup.header.background = definition ? definition.style.layout.background.color : Color.createFromRGBA('rgba(102, 102, 102, 0.6)');
			assetGroup.header.leftIcon = new HeaderedListButton();
			assetGroup.header.leftIcon.url = definition?.iconResource?.url;

			if (this.mode !== 'add workasset') {
				assetGroup.header.rightIcon = new HeaderedListButton();
				assetGroup.header.rightIcon.url = 'assets/next-circled.png';
				assetGroup.header.rightIcon.action = () => NavigationService.navigateTo(Pages.singleAssetList, { list, 'asset-type': header, mode: this.mode });
			}

			assetGroup.onShowMore = () => NavigationService.navigateTo(Pages.singleAssetList, { list, 'asset-type': header, mode: this.mode });
			assetGroup.items.push(...list);
			this.assetGroups.push(assetGroup);
		});

		if (this.mode !== 'search-results' || (this.mode === 'search-results' && this.searchResultsComplete)) this.menuPanelComponent.updatePacifier(false);
		if (!this.uiState) this.uiState = {};
		this.uiState.assetGroups = this.assetGroups;
		setTimeout(() => {
			this.assetGroups.forEach(group => (group.collapsed = false));
		}, 1000);
	}

	private getAssetDictionaryFromMapResults(identifiedItems: FindResultItem[]) {
		const assetMap = new Map();
		identifiedItems.forEach(identifiedItem => {
			const layerName = identifiedItem.layerName;
			const definition = this.userService.globalConfig.assetDefinitions.getByAssetType(layerName);
			const assetRecord = AssetRecord.create(definition, identifiedItem.feature);
			if (!assetMap.has(layerName)) assetMap.set(layerName, [assetRecord]);
			else assetMap.get(layerName).push(assetRecord);
		});
		return assetMap;
	}

	private getAssetDictionaryFromSerchResults(layerResults: LayerResultModel[]) {
		const assetMap = new Map();
		layerResults.forEach(r => {
			assetMap.set(r.assetType, r.results);
		});
		return assetMap;
	}

	getItemResolver(item) {
		if (this.cachedListResolvers.has(item)) return this.cachedListResolvers.get(item);
		if (this.mode === 'search-results') return this.getSearchItemResolver(item);
		return this.getIdentifyItemResolver(item);
	}

	private getIdentifyItemResolver(item) {
		const resolver = new NoIconOneLineTemplateResolver();
		resolver.getLabel = asset => {
			return (asset as AssetRecord).assetId ? (asset as AssetRecord).assetId.toString() : (asset as AssetRecord).objectId ? (asset as AssetRecord).objectId.toString() : 'no id available';
		};
		resolver.getAlignment = asset => 'center';
		this.cachedListResolvers.set(item, resolver);
		return this.cachedListResolvers.get(item);
	}

	private getSearchItemResolver(item) {
		const resolver = new SearchResultItemResolver();
		resolver.getHeader = i => (i as LayerFeatureModel).foundField.fieldAlias;
		resolver.getMatchText = i => this.searchService.searchQuery;
		resolver.getValue = i => (i as LayerFeatureModel).feature.attributes[i.foundField.fieldName];
		this.cachedListResolvers.set(item, resolver);
		return this.cachedListResolvers.get(item);
	}

	highlightAssetsOnMap() {
		this.config.selectedCanvas.map.pacifier.show = true;
		this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'highlight-assets');
		const features = this.identifiedItems.map(item => item.feature);
		this.mapService.highlightAssets(this.config.selectedCanvas, features).then(() => {
			this.config.selectedCanvas.map.pacifier.show = false;
		});
	}

	async goToAssetAttributes(assetRecord: AssetRecord) {
		const context = new RecordContextGroup({ assetRecord: assetRecord });
		if (this.mode !== 'create workorder') {
			const geo = this.graphicsService.convertSP(this.config?.selectedCanvas, assetRecord.feature.geometry);
			if (geo) assetRecord.feature.geometry = geo;
			this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'highlight-assets');
			this.mapService.highlightAssets(this.config.selectedCanvas, [assetRecord.feature]).then(graphics => {
				if (!graphics) return;
				this.config.selectedCanvas.mapView.goTo({ target: graphics, scale: 1000 }, { duration: 1000 });
				this.graphicsService.showMapToolTip(graphics[0], context);
			});
		}

		const { assetDefinition } = assetRecord;
		switch (this.mode) {
			case 'add workasset':
				if (assetDefinition && assetDefinition.useHierarchy) {
					NavigationService.navigateTo(Pages.hierarchy, { recordContext: context, mode: this.mode, workOrder: this.workOrder });
					return;
				}

				if (assetDefinition) {
					NavigationService.navigateTo(Pages.assetInformation, { recordContext: context, mode: this.mode, workOrder: this.workOrder });
					return;
				}

				NavigationService.navigateTo(Pages.mapAssetAttributes, {
					mode: 'add workasset',
					feature: assetRecord.feature,
					metric: this.graphicsService.currentlySelectedMetric,
					assetType: assetRecord.assetType,
					workOrder: this.workOrder
				});
				break;
			case 'create workorder':
				this.graphicsService.startWorkOrderCreation(this.config.selectedCanvas, assetRecord as any);
				break;
			case 'search-results':
				this.searchResults(assetRecord as any);
				break;
			case 'asset selection':
			default:
				this.mapService.selectedIdentifyAsset = assetRecord.feature;
				if (!assetDefinition) {
					NavigationService.navigateTo(Pages.mapAssetAttributes, { mode: 'asset selection', feature: assetRecord.feature, assetType: assetRecord?.assetType });
					return;
				}

				let page = Pages.assetInformation;
				if (assetDefinition.useHierarchy) page = Pages.hierarchy;
				NavigationService.navigateTo(page, { recordContext: context });
				break;
		}
	}

	searchResults(assetRecord: AssetRecord) {
		const canvas = this.config.selectedCanvas;
		const geo = this.graphicsService.convertSP(canvas, assetRecord.feature.geometry);
		if (geo) assetRecord.feature.geometry = geo;
		const context = new RecordContextGroup({ assetRecord: assetRecord });
		this.mapService.highlightAssets(canvas, [assetRecord.feature]).then(graphics => {
			if (!graphics) return;
			canvas.mapView.goTo({ target: graphics, scale: 1000 }, { duration: 1000 });
			this.graphicsService.showMapToolTip(graphics[0], context);
			canvas.map.pacifier.show = false;
		});

		const { assetDefinition } = assetRecord;
		let page = Pages.assetInformation;
		if (assetDefinition.useHierarchy) page = Pages.hierarchy;
		NavigationService.navigateTo(page, { recordContext: context });
	}

	selectAssetRecord(item: AssetRecord | LayerFeatureModel) {
		if (item instanceof AssetRecord) return this.goToAssetAttributes(item);
		const { feature, assetType } = item as LayerFeatureModel;
		const definition = this.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		const record = AssetRecord.create(definition, feature);
		return this.goToAssetAttributes(record);
	}
}
