<div class="scrolling-content">
	<label class="comment-header">add work order comments</label>
	<mat-divider class="comment-divider"></mat-divider>
	<mat-card class="omni-panel-field orange-background">
		<mat-card-title class="omni-panel-field-header xs-font-size">
			<app-text-in-max-lines [maxLines]="2" [horizontalAlign]="'left'" [fontSize]="'12px'" [lineHeight]="'13px'" [text]="'comments'"></app-text-in-max-lines>
		</mat-card-title>
		<div>
			<textarea [(ngModel)]="workComment.description" cdkTextareaAutosize></textarea>
		</div>
	</mat-card>
</div>

<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" [class.omni-button-disabled]="!canSave" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
