import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { NoIconOneLineTemplateResolver } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/np-icon-one-line.resolver';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';

@Component({
  selector: 'app-address-search-results',
  templateUrl: './address-search-results.component.html',
  styleUrls: ['./address-search-results.component.scss']
})
export class AddressSearchResultsComponent implements OnInit, AfterViewInit {

  @ViewChild('addressList', { static: true }) listComponent: OmniListComponent;

  @Input() addressResults: Array<any>;

  @Output() itemClicked = new EventEmitter();

  constructor(private interop: OmniInteropService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listComponent.getResolver = item => {
      return this.getResolver();
    };
  }

  getResolver(selectedItem?: any): ListItemResolver {
    const resolver = NoIconOneLineComponent.createResolver();
    resolver.getAlignment = item => {
      return 'center';
    };
    resolver.getLabel = item => {
      if (!selectedItem) return item.value;
      return selectedItem.value;
    };
    resolver.onIsDisabled = item => {
      return false;
    };
    return resolver;
  }

  handleHover(event: any): void {
    const mapTooltip = this.interop?.uiManager?.activeCanvasComponent?.canvasMapComponent?.mapTooltip;
    if (!mapTooltip) return;
    mapTooltip.location = event.result.geometry;
    mapTooltip.resolver = this.getResolver(event);
    mapTooltip.open();
  }

  handleMouseLeave(event: any): void {
    const mapTooltip = this.interop?.uiManager?.activeCanvasComponent?.canvasMapComponent?.mapTooltip;
    if (!mapTooltip) return;
    mapTooltip.close();
  }
}
