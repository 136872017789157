import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StartingPacifierResolver } from './starting-pacifier-resolver';

@Component({
	selector: 'app-starting-pacifier',
	templateUrl: './starting-pacifier.component.html',
	styleUrls: ['./starting-pacifier.component.scss']
})
export class StartingPacifierComponent implements OnInit, OnDestroy {
	@Input() resolver: StartingPacifierResolver;

	get pulseSpeed() {
		return `${this.resolver.pulseSpeed}s`;
	}

	get spinningSpeed() {
		return `${this.spinningSpeed}s`;
	}

	get spinDirection() {
		return this.resolver.spinDirection === 'clockwise' ? 'normal' : 'reverse';
	}

	get spinnerWidth() {
		return `${this.resolver.spinnerWidth}px`;
	}

	get textSize() {
		return `${this.resolver.fontSize}px`;
	}
	textIntervalHandler;

	text: string;

	constructor() {}

	ngOnInit(): void {
		this.text = this.resolver.initialText;
		this.textIntervalHandler = setInterval(() => {
			const randomIndex = Math.floor(Math.random() * this.resolver.textArray.length);
			this.text = this.resolver.textArray[randomIndex];
		}, this.resolver.updateTextEvery * 1000);
	}

	ngOnDestroy() {
		if (this.textIntervalHandler) clearInterval(this.textIntervalHandler);
	}
}
