import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';

export class VendorCost extends OmniModel<VendorCost, Contracts.VendorCostContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<any>;
	get changeStatus(): any {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<any>();
		return this._changeStatus.value;
	}
	set changeStatus(value: any) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<any>(value);
			return;
		}
		this._changeStatus.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	private _datestamp: SedaruUtils.Undoable<string>;
	get datestamp(): string {
		if (!this._datestamp) this._datestamp = new SedaruUtils.Undoable<string>();
		return this._datestamp.value;
	}
	set datestamp(value: string) {
		if (!this._datestamp) {
			this._datestamp = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._datestamp.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	private _totalCost: SedaruUtils.Undoable<number>;
	get totalCost(): number {
		if (!this._totalCost) this._totalCost = new SedaruUtils.Undoable<number>();
		return this._totalCost.value;
	}
	set totalCost(value: number) {
		if (!this._totalCost) {
			this._totalCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._totalCost.value = value;
	}

	private _units: SedaruUtils.Undoable<number>;
	get units(): number {
		if (!this._units) this._units = new SedaruUtils.Undoable<number>();
		return this._units.value;
	}
	set units(value: number) {
		if (!this._units) {
			this._units = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._units.value = value;
	}

	private _vendorCostId: SedaruUtils.Undoable<number>;
	get vendorCostId(): number {
		if (!this._vendorCostId) this._vendorCostId = new SedaruUtils.Undoable<number>();
		return this._vendorCostId.value;
	}
	set vendorCostId(value: number) {
		if (!this._vendorCostId) {
			this._vendorCostId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._vendorCostId.value = value;
	}

	private _vendorId: SedaruUtils.Undoable<string>;
	get vendorId(): string {
		if (!this._vendorId) this._vendorId = new SedaruUtils.Undoable<string>();
		return this._vendorId.value;
	}
	set vendorId(value: string) {
		if (!this._vendorId) {
			this._vendorId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._vendorId.value = value;
	}

	private _workTaskId: SedaruUtils.Undoable<string>;
	get workTaskId(): string {
		if (!this._workTaskId) this._workTaskId = new SedaruUtils.Undoable<string>();
		return this._workTaskId.value;
	}
	set workTaskId(value: string) {
		if (!this._workTaskId) {
			this._workTaskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTaskId.value = value;
	}

	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	static fromContract(contract: Contracts.VendorCostContract): VendorCost {
		const model = new VendorCost();
		model.id = String(contract.ObjectId);
		model.changeStatus = contract.ChangeStatus;
		model.datestamp = DateUtil.isValidDate(contract.Datestamp) ? contract.Datestamp : '';
		model.description = contract.Description;
		model.systemId = contract.SystemId;
		model.totalCost = contract.TotalCost;
		model.units = contract.Units;
		model.vendorCostId = contract.VendorCostId;
		model.vendorId = contract.VendorID;
		model.workTaskId = contract.WorkTaskId;
		model.workOrderKey = contract.WorkorderKey;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.VendorCostContract => {
		const contract = new Contracts.VendorCostContract();
		contract.ObjectId = +this.id;
		contract.ChangeStatus = this.changeStatus;
		contract.CreatedBy = this.createdBy;
		contract.Datestamp = this.datestamp;
		contract.Description = this.description;
		contract.SystemId = this.systemId;
		contract.TotalCost = this.totalCost;
		contract.Units = this.units;
		contract.VendorCostId = this.vendorCostId;
		contract.VendorID = this.vendorId;
		contract.WorkTaskId = this.workTaskId;
		contract.WorkorderKey = this.workOrderKey;
		return contract;
	};

	onUpdateInformation(copyModel: VendorCost): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._units, () => {
				this.units = copyModel.units;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._changeStatus, () => {
				this.changeStatus = copyModel.changeStatus;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._datestamp, () => {
				this.datestamp = copyModel.datestamp;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._totalCost, () => {
				this.totalCost = copyModel.totalCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTaskId, () => {
				this.workTaskId = copyModel.workTaskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._vendorCostId, () => {
				this.vendorCostId = copyModel.vendorCostId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._vendorId, () => {
				this.vendorId = copyModel.vendorId;
			}) || wasChanged;
		return wasChanged;
	}
}
