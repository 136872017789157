import { Component, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { TileService } from 'app/tiles/tile.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Metric, MetricTile, Operator, UnitOperator } from 'models';
import { TimeframeDefinitionType } from 'models/time-frame/timeframe-definition-type.enum';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { TimeframeField } from 'models/time-frame/timeframe-query.model';
import { TimeframeDefinition } from 'models/time-frame/timeframe-definition.model';
import { TimeframeType } from 'models/time-frame/timeframe-type.enum';
import { TimeframeQueryValue } from 'models/time-frame/timeframe-query-value';
import { UserTimeframeOptionType } from 'models/time-frame/user-timeframe-option.enum';
import { UserTimeframeOption } from 'models/time-frame/user-timeframe-option.model';
import { TimeframeUnit } from 'models/time-frame/timeframe-unit.enum';

@Component({
	selector: 'app-timeframe-filter',
	templateUrl: './timeframe-filter.component.html',
	styleUrls: ['./timeframe-filter.component.scss']
})
export class TimeframeFilterComponent extends MenuPanelBaseComponent {
	private canNavigate = false;
	private _canSave = false;
	currentTimeframeFilter: TimeframeFilter;
	currentMetric: Metric;
	currentTile: MetricTile;
	timeFrameType: string;

	get currentTimeframe(): Timeframe {
		return this.currentTimeframeFilter?.timeFrame;
	}

	get dateField(): string {
		return this.currentTimeframeFilter && this.currentTimeframeFilter.timeframeField ? this.currentTimeframeFilter.timeframeField : '';
	}

	get canSave(): boolean {
		return this._canSave;
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private interop: OmniInteropService,
		private tileService: TileService
	) {
		super(view);
		this.currentTimeframeFilter = new TimeframeFilter();
	}

	ngOnInit(): void {
		let title = 'timeframe filter';
		if (this.timeFrameType === 'tile') title = 'tile ' + title;
		else title = 'metric ' + title;
		this.menuPanelComponent.updateView({ title });
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.canNavigate = false;
		this.timeFrameType = args.parameter.timeFrameType;
		this.currentMetric = args.parameter.metric;
		this.currentTile = args.parameter.tile;
		this.currentTimeframeFilter = args.parameter.timeframeFilter;
		if (!args.parameter.timeframeFilter) {
			if (this.timeFrameType === 'metric') this.currentTimeframeFilter = this.currentMetric.definition.timeFrameFilter;
			else if (this.timeFrameType === 'tile') this.currentTimeframeFilter = this.currentTile.uiTimeframeFilter;
		}
		if (this.currentTimeframeFilter.timeframeField) this._canSave = true;
	}

	onPageReload(args: NavigationArgs) {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
		this.onPageNavigatedTo(args);
		this.ngOnInit();
	}

	onPageNavigatingFrom(args: NavigationArgs): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (this.timeFrameType === 'metric' && args.isNavigatingBack) {
				args.parameter['timeFrameFilter'] = this.currentTimeframeFilter;
			}
			if (!this.canNavigate) this.currentTimeframeFilter?.undoAll();
			resolve(true);
		});
	}

	goToDateFilterField(): void {
		const metric = this.timeFrameType === 'metric' ? this.currentMetric : this.currentTile?.metric;
		if (!metric) return;

		const fields = this.interop?.arcGISManager?.getArcGisFields(metric);
		if (!fields) return;

		this.canNavigate = true;
		NavigationService.navigateTo(Pages.dateFieldSelection, {
			dateFields: fields.filter(f => f.type === 'esriFieldTypeDate' || f.type === 'DateTime'),
			timeframeFilter: this.currentTimeframeFilter,
			timeFrameType: this.timeFrameType,
			tile: this.currentTile,
			metric: this.currentMetric
		});
	}

	goToDefaultTimeFrame() {
		const moment = require('moment');
		const defaultDateFormat = 'M/D/YYYY';
		const timeframeDefinition = this.currentTimeframeFilter.timeFrameDefinition;
		const timeframeType: TimeframeType = timeframeDefinition.getTimeframeTypes();
		const timeframeBuilderField = new TimeframeField();
		const operator = new UnitOperator();
		operator.name = '';
		operator.value = null;

		const timeframeQueryValue = new TimeframeQueryValue();
		timeframeQueryValue.startDate = '';
		timeframeQueryValue.endDate = '';

		switch (timeframeType) {
			case TimeframeType.PeriodInterval:
			case TimeframeType.Period:
				operator.name = TimeframeDefinition.getUnitText(timeframeDefinition.unit);
				operator.value = timeframeDefinition.unit;
				timeframeQueryValue.name = timeframeDefinition.interval;
				timeframeQueryValue.value = timeframeDefinition.interval;
				timeframeQueryValue.code = timeframeDefinition.interval;
				break;
			case TimeframeType.SingleDate:
				timeframeQueryValue.name = moment(timeframeDefinition.date).format(defaultDateFormat);
				timeframeQueryValue.startDate =  timeframeDefinition.date.toDateString();
				timeframeQueryValue.date = timeframeDefinition.date;
				break;
			case TimeframeType.Range:
				timeframeQueryValue.startDate =  moment(timeframeDefinition.date).format(defaultDateFormat);
				timeframeQueryValue.name = moment(timeframeDefinition.date).format(defaultDateFormat);
				timeframeQueryValue.date = timeframeDefinition.date;
				if (timeframeDefinition.endDate) {
					timeframeQueryValue.endDate =  moment(timeframeDefinition.endDate).format(defaultDateFormat);
				} else {
					const start: Date = new Date(timeframeDefinition.date);
					const end: Date = new Date(timeframeDefinition.date);
					end.setDate(start.getDate() + (timeframeDefinition.interval - 1));
					timeframeQueryValue.endDate = moment(end).format(defaultDateFormat);
				}
				break;
		}

		const userOptionType = this.getTimeframeUserOptionFromType(timeframeDefinition);
		const UserOptionTimeframe = UserTimeframeOption.getUserOptionTimeframe(userOptionType);
		const timeframeUserOption = {
			type: timeframeDefinition.type,
			userOptionType,
			name: UserOptionTimeframe.text,
			text: timeframeDefinition.text,
			timeframeType: UserOptionTimeframe.type
		};
		timeframeBuilderField.timeframeOption = timeframeUserOption;
		timeframeBuilderField.operator = operator;
		timeframeBuilderField.value = timeframeQueryValue;

		const joinoperator = new Operator();
		joinoperator.name = 'done';
		joinoperator.value = 'DONE';
		timeframeBuilderField.join = joinoperator;
		timeframeBuilderField.timeframeDefinition = timeframeDefinition;

		this.canNavigate = true;
		return NavigationService.navigateTo(Pages.timeframeBuilder, {
			metric: this.currentMetric,
			tile: this.currentTile,
			timeframeFilter: this.currentTimeframeFilter,
			timeFrameType: this.timeFrameType,
			timeframeBuilderField
		});
	}

	getTimeframeUserOptionFromType(timeframeDefinition: TimeframeDefinition): UserTimeframeOptionType {
		const timeframeDefinitionType: TimeframeDefinitionType = timeframeDefinition.type;
		const isInclusive = timeframeDefinition.isInclusive;
		const unit = timeframeDefinition.unit;
		const interval = timeframeDefinition.interval;
		switch (timeframeDefinitionType) {
			case TimeframeDefinitionType.This:
				return this.getUserTimeframeOptionThis(unit);
			case TimeframeDefinitionType.RestOfX:
				return this.getUserTimeframeOptionRestOfX(unit);
			case TimeframeDefinitionType.XToNow:
				return this.getUserTimeframeOptionXToNow(unit);
			case TimeframeDefinitionType.NextX:
				return this.getUserTimeframeOptionNextX(unit, interval);
			case TimeframeDefinitionType.LastX:
				return this.getUserTimeframeOptionLastX(unit, interval);
			case TimeframeDefinitionType.LaterThanToday:
				return isInclusive ? UserTimeframeOptionType.TodayAndLater : UserTimeframeOptionType.LaterThanToday;
			case TimeframeDefinitionType.EarlierThanToday:
				return UserTimeframeOptionType.EarlierThanToday;
			case TimeframeDefinitionType.TodayAndEarlier:
				return UserTimeframeOptionType.TodayAndEarlier;
			case TimeframeDefinitionType.ExactDate:
				return UserTimeframeOptionType.ExactDate;
			case TimeframeDefinitionType.BeforeDate:
				return UserTimeframeOptionType.BeforeDate;
			case TimeframeDefinitionType.AfterDate:
				return UserTimeframeOptionType.AfterDate;
			case TimeframeDefinitionType.DateRange:
				return UserTimeframeOptionType.DateRange;
			case TimeframeDefinitionType.Boundless:
				return UserTimeframeOptionType.AllDates;
			default:
				return  UserTimeframeOptionType.AllDates;
		}
	}

	getUserTimeframeOptionThis(unit: TimeframeUnit) {
		switch (unit) {
			case TimeframeUnit.Days:
				return UserTimeframeOptionType.Today;
			case TimeframeUnit.Weeks:
				return UserTimeframeOptionType.ThisWeek;
			case TimeframeUnit.Months:
				return UserTimeframeOptionType.ThisMonth;
			case TimeframeUnit.Quarters:
				return UserTimeframeOptionType.ThisQuarter;
			case TimeframeUnit.Years:
				return UserTimeframeOptionType.ThisYear;
			default:
				return UserTimeframeOptionType.ThisX;
		}
	}

	getUserTimeframeOptionXToNow(unit: TimeframeUnit) {
		switch (unit) {
			case TimeframeUnit.Days:
				return UserTimeframeOptionType.MorningToNow;
			case TimeframeUnit.Weeks:
				return UserTimeframeOptionType.WeekToDate;
			case TimeframeUnit.Months:
				return UserTimeframeOptionType.MonthToDate;
			case TimeframeUnit.Quarters:
				return UserTimeframeOptionType.QuarterToDate;
			case TimeframeUnit.Years:
				return UserTimeframeOptionType.YearToDate;
			default:
				return UserTimeframeOptionType.XToNow;
		}
	}

	getUserTimeframeOptionRestOfX(unit: TimeframeUnit) {
	   	switch (unit) {
			case TimeframeUnit.Days:
				return UserTimeframeOptionType.RestOfDay;
			case TimeframeUnit.Weeks:
				return UserTimeframeOptionType.RestOfWeek;
			case TimeframeUnit.Months:
				return UserTimeframeOptionType.RestOfMonth;
			case TimeframeUnit.Quarters:
				return UserTimeframeOptionType.RestOfQuarter;
			case TimeframeUnit.Years:
				return UserTimeframeOptionType.RestOfYear;
			default:
				return UserTimeframeOptionType.RestOfX;
		}
   	}

	getUserTimeframeOptionNextX(unit: TimeframeUnit, interval: number) {
		if (interval !== 1) {
			return UserTimeframeOptionType.Next;
		}
		switch (unit) {
			case TimeframeUnit.Days:
				return UserTimeframeOptionType.Tomorrow;
			case TimeframeUnit.Weeks:
				return UserTimeframeOptionType.NextWeek;
			case TimeframeUnit.Months:
				return UserTimeframeOptionType.NextMonth;
			case TimeframeUnit.Quarters:
				return UserTimeframeOptionType.NextQuarter;
			case TimeframeUnit.Years:
				return UserTimeframeOptionType.Next;
			default:
				return UserTimeframeOptionType.Next;
		}
	}

	getUserTimeframeOptionLastX(unit: TimeframeUnit, interval: number) {
		if (interval !== 1) {
			return UserTimeframeOptionType.Last;
		}
		switch (unit) {
			case TimeframeUnit.Days:
				return UserTimeframeOptionType.Yesterday;
			case TimeframeUnit.Weeks:
				return UserTimeframeOptionType.LastWeek;
			case TimeframeUnit.Months:
				return UserTimeframeOptionType.LastMonth;
			case TimeframeUnit.Quarters:
				return UserTimeframeOptionType.LastQuarter;
			case TimeframeUnit.Years:
				return UserTimeframeOptionType.Last;
			default:
				return UserTimeframeOptionType.Last;
		}
	}

	onSave() {
		if (!this.isValidTimeframeFilter()) return;

		this.currentTimeframeFilter.clearDirty();

		this.currentTile.timeFrameFilter = this.currentTimeframeFilter;
		this.interop.uiManager.openTab.activeTile?.onTimeframeChanged();
		this.tileService.saveTimeframeDefinition(this.currentTile).subscribe(data => {
			NavigationService.navigateBackTo();
		});
	}

	private isValidTimeframeFilter(): boolean {
		const filterField = this.currentTimeframeFilter?.timeframeField;
		const type = this.currentTimeframeFilter?.timeFrame?.definition?.type;
		let errorMessage = '';

		if (!type) errorMessage = 'please select a time frame';
		else if (!filterField && type !== TimeframeDefinitionType.Boundless) errorMessage = 'please select a date filter field';
		else return true;
		this.alertDialog.mainMessage = { text: errorMessage };
		this.alertDialog.open = true;
		return false;
	}
}
