import { Injectable } from '@angular/core';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import { RollbarAnalyticsChannel, RollbarErrorHandler } from './rollbar-analytics-channel';
import { environment } from 'environments/environment';
import * as SedaruUtils from 'sedaru-util';
import { ErrorHandlerCluster } from './error-handler-cluster';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	private _rollbarChannel: RollbarAnalyticsChannel;

	constructor() {}

	initialize(notificationObject: SedaruUtils.NotificationObject<string>) {
		ErrorHandlerCluster.current.registerErrorHandler(new RollbarErrorHandler());
		this._rollbarChannel = new RollbarAnalyticsChannel();
		AnalyticsHub.current.registerChannel(this._rollbarChannel);
	}
}
