import { Injectable } from '@angular/core';
import { Template } from '../models/sedaru-config/template.model';
import { UserService } from '../app/user/user.service';
import { WorkOrderModeType, WorkOrderSystem, SedaruWorkOrderChannelAttributes, LEMMode, Metric, DataSource, ArcGISField, WorkOrderField } from '../models';
import { GlobalConfig } from '../models/global-config.model';
import { Employee, EmployeeCostWrapper, EmployeeCostWrappers, EquipmentCostWrapper, EquipmentCostWrappers, FailureReportWrapper, FailureReportWrappers, HierarchyNode, MaterialCostWrapper, MaterialCostWrappers, TaskWrapper, TaskWrappers, WorkAssetWrapper, WorkAssetWrappers, WorkCommentWrapper, WorkCommentWrappers, WorkOrderCapabilities, WorkOrderMetaData, WorkOrderWrapper, WorkOrderWrappers } from '../models/work-order';
import { BulkUpdateJob } from './jobs/bulk-update-job/bulk-update-job';
import { WorkOrderProviderBase, WorkOrderResult } from 'providers/work-order-provider-base';
import { GeometryService } from 'app/canvas-container/canvas-map/geometry.service';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { WorkOrderService } from './work-order.service';
import { DataChannelService } from './data-channel.service';
import { AssetLocationAttachmentUpload, UploaderService, WorkOrderAttachmentUpload } from 'app/workorder/attachment/uploader.service';
import { OmniInteropService } from './omni-interop.service';
import { StandardWorkOrderProvider } from 'providers/standard-workorder.provider';
import { WorkOrderProvider } from 'providers/workorder.provider';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { Asset } from 'models/work-order/asset.model';
import { AssetRecord } from 'models/records/asset-record.model';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { FeatureLayer } from 'sedaru-util/esri-core';
import { SedaruSubscriptionsService } from './subscriptions/sedaru-subscriptions.service';
import { WorkOrderUpdatedHandler } from './subscriptions/handlers/work-order-updated-handler';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { WorkOrderOutlineComponent } from 'app/workorder/workorder-outline/workorder-outline.component';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { ContractResponse } from 'contracts/work-order';

@Injectable({
	providedIn: 'root'
})
export class WorkOrderFactory {
	public interopService: OmniInteropService;

	private _provider: WorkOrderProviderBase;
	private get provider(): WorkOrderProviderBase {
		return this._provider;
	}

	private get globalConfig(): GlobalConfig {
		return this.userService?.globalConfig;
	}

	private get workOrderChannelAttributes(): SedaruWorkOrderChannelAttributes {
		return this.globalConfig?.workOrderChannel?.attributes as SedaruWorkOrderChannelAttributes;
	}

	public get isAdvancedMode(): boolean {
		return this.globalConfig?.workOrderMode === WorkOrderModeType.Advanced;
	}

    public get isSedaruConnectCapable(): boolean {
        return this.workOrderSystem === WorkOrderSystem.Maximo || this.workOrderSystem === WorkOrderSystem.CityWorks;
    }

    public get lemMode(): LEMMode {
		return this.workOrderChannelAttributes?.lemMode;
    }

	public get supportsVendors(): boolean {
		return this.lemMode === LEMMode.Advanced;
	}

	public get supportsAttachments(): boolean {
		return this.isAdvancedMode;
	}

	public get supportsFailureReports(): boolean {
		return this.workOrderSystem === WorkOrderSystem.Maximo;
	}

	public get supportsWorkOrderRecurrence(): boolean {
		if (this.workOrderSystem === WorkOrderSystem.Maximo || this.workOrderSystem === WorkOrderSystem.CityWorks) return false;
		return this.capabilities?.supportsWorkOrderRecurrence;
	}

	private _customerCode: string;
	public get customerCode(): string {
		return this._customerCode ?? (this._customerCode = this.getCustomerCode());
	}

	private _workOrderSystem: WorkOrderSystem;
	public get workOrderSystem(): WorkOrderSystem {
		return this._workOrderSystem ?? (this._workOrderSystem = this.getWorkOrderSystem());
	}

	private _activeWorkOrderTemplate: Template;
	public get activeWorkOrderTemplate(): Template {
		return this._activeWorkOrderTemplate ?? (this._activeWorkOrderTemplate = this.getWorkOrderTemplate(true));
	}

	private _completedWorkOrderTemplate: Template;
	public get completedWorkOrderTemplate(): Template {
		return this._completedWorkOrderTemplate ?? (this._completedWorkOrderTemplate = this.getWorkOrderTemplate(false));
	}

	private _activeWorkOrderValues: string[];
	public get activeWorkOrderValues(): string[] {
		return this._activeWorkOrderValues ?? (this._activeWorkOrderValues = this.getWorkOrderValues('active'));
	}

	private _completedWorkOrderValues: string[];
	public get completedWorkOrderValues(): string[] {
		return this._completedWorkOrderValues ?? (this._completedWorkOrderValues = this.getWorkOrderValues('completed'));
	}

	private _readOnlyWorkOrderValues: string[];
	public get readOnlyWorkOrderValues(): string[] {
		return this._readOnlyWorkOrderValues ?? (this._readOnlyWorkOrderValues = this.getWorkOrderValues('read-only'));
	}

	private _workOrderMetaData: WorkOrderMetaData;
	public get workOrderMetaData(): WorkOrderMetaData {
		return this._workOrderMetaData ?? (this._workOrderMetaData = new WorkOrderMetaData());
	}
	public set workOrderMetaData(value: WorkOrderMetaData) {
		this._workOrderMetaData = value;
	}

	private _capabilities: WorkOrderCapabilities;
	public get capabilities(): WorkOrderCapabilities {
		return this._capabilities ?? (this._capabilities = new WorkOrderCapabilities());
	}
	public set capabilities(value: WorkOrderCapabilities) {
		this._capabilities = value;
	}

	private _currentWorkOrder: WorkOrderWrapper;
	public get currentWorkOrder(): WorkOrderWrapper {
		return this._currentWorkOrder;
	}
	public set currentWorkOrder(value: WorkOrderWrapper) {
		this._currentWorkOrder = value;
	}

	public get workOrderLayer(): FeatureLayer {
		if (!this.provider) return null;

		return this.provider.workOrderLayer;
	}

	private _currentEmployee: Employee;
	public get currentEmployee(): Employee {
		return this._currentEmployee ?? (this._currentEmployee = this.getCurrentEmployee());
	}

	private _activeDataSource: DataSource;
	public get activeDataSource(): DataSource {
		return this._activeDataSource
	}
	public set activeDataSource(value: DataSource) {
		if (this._activeDataSource === value) return;
		this._activeDataSource = value;
	}

	constructor(
		public userService: UserService,
		public geometryService: GeometryService,
		public esriSdkService: EsriSdkService,
		public workOrderService: WorkOrderService,
		public dataChannelService: DataChannelService,
		public uploaderService: UploaderService,
		public subscriptionService: SedaruSubscriptionsService
	) { }

	public generateWorkOrderKey(keyCount = 1): Promise<string []> {
		if (!this.provider) return Promise.reject();

		return this.provider.generateWorkOrderKeys(keyCount)
			.then((contractResponse: ContractResponse<object>) => {
				return contractResponse.Result;
		});
	}

	public async initialize(): Promise<void> {
		const workOrderMode = this.globalConfig?.workOrderMode;
		let workOrderProvider: WorkOrderProviderBase;

		if (workOrderMode === WorkOrderModeType.Standard) {
			workOrderProvider = new StandardWorkOrderProvider(this.workOrderService);
		} else if (workOrderMode === WorkOrderModeType.Advanced) {
			workOrderProvider = new WorkOrderProvider(
				this.geometryService,
				this.esriSdkService,
				this.workOrderService,
				this.dataChannelService,
				this.userService,
				this.uploaderService
			);
		}

		this._provider = workOrderProvider;
		if (!this.provider) return;

		this.provider.interopService = this.interopService;

		let customerCode = this.customerCode;
		if (workOrderMode === WorkOrderModeType.Advanced) {
			const woAttributes = this.globalConfig.workOrderChannel.attributes as SedaruWorkOrderChannelAttributes;
			if (woAttributes.customerCode && woAttributes.customerCode.toLowerCase() != this.customerCode.toLowerCase()) {
				customerCode = woAttributes.customerCode.toLowerCase();
			}
		}

		this.provider.initialize(customerCode);
		this.handleSedaruConnectUpdates();
		await this.getInitialData();
		await this.getCapabilities();
	}

	private async getInitialData(): Promise<void> {
		if (!this.provider) return;

		this.workOrderMetaData = await this.provider.getInitialData();
	}

	private async getCapabilities(): Promise<void> {
		if (!this.provider) return;

		this.capabilities = await this.provider.getCapabilities();
	}

	private getCustomerCode(): string {
		const customerCode = this.globalConfig?.customerCode;
		if (!this.workOrderChannelAttributes || !this.workOrderChannelAttributes.customerCode) return customerCode;
		return this.workOrderChannelAttributes.customerCode;
	}

	private getWorkOrderTemplate(isActive: boolean): Template {
		if (!this.workOrderChannelAttributes) return null;
		return this.globalConfig?.templates?.find(t => t.id === (isActive ? this.workOrderChannelAttributes?.activeTemplateId
			: this.workOrderChannelAttributes?.completedTemplateId));
	}

	private getWorkOrderSystem(): WorkOrderSystem {
		if (!this.workOrderChannelAttributes) return WorkOrderSystem.None;

		return this.workOrderChannelAttributes.workOrderSystem;
	}

	private getWorkOrderValues(type: string): string[] {
		if (!this.workOrderChannelAttributes) return [];

		let workOrderValues: string[];
		switch (type) {
			case 'active':
				workOrderValues = this.workOrderChannelAttributes.activeWorkOrderValues;
				break;
			case 'completed':
				workOrderValues = this.workOrderChannelAttributes.completedWorkOrderValues;
				break;
			case 'read-only':
				workOrderValues = this.workOrderChannelAttributes.readOnlyWorkOrderValues;
				break;
			default:
				workOrderValues = new Array<string>();
				break;
		}

		return workOrderValues;
	}

	private getCurrentEmployee(): Employee {
		const foundEmployee = this.workOrderMetaData?.employees?.getByEmployeeId(this.userService?.currentUser?.userName);
		return foundEmployee ? foundEmployee : this.workOrderMetaData?.employees?.getByEmployeeUsername(this.userService?.currentUser?.userName);
	}

	//#region Work Orders CRUD
	public async createWorkOrder(workOrder: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		if (!this.provider) return new WorkOrderResult();

		return this.isSedaruConnectCapable ? await this.provider.createWorkOrderWithValidation(workOrder, timeoutDelegate)
			: await this.provider.createWorkOrder(workOrder);
	}

	public async getWorkOrders(workOrderKeys: string[], stubsOnly: boolean): Promise<WorkOrderWrappers> {
		if (!this.provider) return new WorkOrderWrappers();

		return await this.provider.getWorkOrders(workOrderKeys, stubsOnly);
	}

	public async getMatchingWorkOrders(query: string, isContains: boolean): Promise<WorkOrderWrappers> {
		if (!this.provider) return new WorkOrderWrappers();

		return await this.provider.getMatchingWorkOrders(query, isContains);
	}

	public async updateWorkOrder(workOrder: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		if (!this.provider) return new WorkOrderResult();

		return this.isSedaruConnectCapable ? await this.provider.updateWorkOrderWithValidation(workOrder, timeoutDelegate)
			: await this.provider.updateWorkOrder(workOrder);
	}

	public async deleteWorkOrder(workOrder: WorkOrderWrapper): Promise<WorkOrderResult> {
		if (!this.provider) return new WorkOrderResult();

		return await this.provider.deleteWorkOrder(workOrder);
	}
	//#endregion

	//#region Work Orders Bulk Create/Update
	public bulkCreate(workOrders: WorkOrderWrapper[]): BulkUpdateJob {
		if (!this.provider) return null;

		return this.provider.bulkCreate(workOrders);
	}

	public bulkEdit(workOrders: WorkOrderWrapper[]): BulkUpdateJob {
		if (!this.provider) return null;

		return this.provider.bulkEdit(workOrders);
	}

	public bulkDelete(workOrders: WorkOrderWrapper[]): BulkUpdateJob {
		if (!this.provider) return null;

		return this.provider.bulkDelete(workOrders);
	}
	//#endregion

	//#region Work Asset CRUD
	public async createWorkAsset(workOrder: WorkOrderWrapper, workAsset: WorkAssetWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createWorkAsset(workOrder, workAsset);
	}

	public async getWorkAssets(workOrderKey: string): Promise<WorkAssetWrappers> {
		if (!this.provider) return new WorkAssetWrappers();

		return await this.provider.getWorkAssets(workOrderKey);
	}

	public async updateWorkAsset(workOrder: WorkOrderWrapper, workAsset: WorkAssetWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateWorkAsset(workOrder, workAsset);
	}

	public async deleteWorkAsset(workOrder: WorkOrderWrapper, workAsset: WorkAssetWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteWorkAsset(workOrder, workAsset);
	}
	//#endregion

	//#region Work Tasks CRUD
	public async createWorkTask(workOrder: WorkOrderWrapper, workTask: TaskWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createWorkTask(workOrder, workTask);
	}

	public async getWorkTasks(workOrderKey: string): Promise<TaskWrappers> {
		if (!this.provider) return new TaskWrappers();

		return await this.provider.getWorkTasks(workOrderKey);
	}

	public async updateWorkTask(workOrder: WorkOrderWrapper, workTask: TaskWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateWorkTask(workOrder, workTask);
	}

	public async deleteWorkTask(workOrder: WorkOrderWrapper, workTask: TaskWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteWorkTask(workOrder, workTask);
	}
	//#endregion

	//#region Employee Costs CRUD
	public async createEmployeeCost(workOrder: WorkOrderWrapper, employeeCost: EmployeeCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createEmployeeCost(workOrder, employeeCost);
	}

	public async getEmployeeCosts(workOrderKey: string): Promise<EmployeeCostWrappers> {
		if (!this.provider) return new EmployeeCostWrappers();

		return await this.provider.getEmployeeCosts(workOrderKey);
	}

	public async updateEmployeeCost(workOrder: WorkOrderWrapper, employeeCost: EmployeeCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateEmployeeCost(workOrder, employeeCost);
	}

	public async deleteEmployeeCost(workOrder: WorkOrderWrapper, employeeCost: EmployeeCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteEmployeeCost(workOrder, employeeCost);
	}
	//#endregion

	//#region Equipment Costs CRUD
	public async createEquipmentCost(workOrder: WorkOrderWrapper, equipmentCost: EquipmentCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createEquipmentCost(workOrder, equipmentCost);
	}

	public async getEquipmentCosts(workOrderKey: string): Promise<EquipmentCostWrappers> {
		if (!this.provider) return new EquipmentCostWrappers();

		return await this.provider.getEquipmentCosts(workOrderKey);
	}

	public async updateEquipmentCost(workOrder: WorkOrderWrapper, equipmentCost: EquipmentCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateEquipmentCost(workOrder, equipmentCost);
	}

	public async deleteEquipmentCost(workOrder: WorkOrderWrapper, equipmentCost: EquipmentCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteEquipmentCost(workOrder, equipmentCost);
	}
	//#endregion

	//#region Material Costs CRUD
	public async createMaterialCost(workOrder: WorkOrderWrapper, materialCost: MaterialCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createMaterialCost(workOrder, materialCost);
	}

	public async getMaterialCosts(workOrderKey: string): Promise<MaterialCostWrappers> {
		if (!this.provider) return new MaterialCostWrappers();

		return await this.provider.getMaterialCosts(workOrderKey);
	}

	public async updateMaterialCost(workOrder: WorkOrderWrapper, materialCost: MaterialCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateMaterialCost(workOrder, materialCost);
	}

	public async deleteMaterialCost(workOrder: WorkOrderWrapper, materialCost: MaterialCostWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteMaterialCost(workOrder, materialCost);
	}
	//#endregion

	//#region Work Comments CRUD
	public async createWorkComment(workOrder: WorkOrderWrapper, workComment: WorkCommentWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createWorkComment(workOrder, workComment);
	}

	public async getWorkComments(workOrderKey: string): Promise<WorkCommentWrappers> {
		if (!this.provider) return new WorkCommentWrappers();

		return await this.provider.getWorkComments(workOrderKey);
	}

	public async updateWorkComment(workOrder: WorkOrderWrapper, workComment: WorkCommentWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateWorkComment(workOrder, workComment);
	}

	public async deleteWorkComment(workOrder: WorkOrderWrapper, workComment: WorkCommentWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteWorkComment(workOrder, workComment);
	}
	//#endregion

	//#region Failure Reports CRUD
	public async createFailureReport(workOrder: WorkOrderWrapper, failureReport: FailureReportWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.createFailureReport(workOrder, failureReport);
	}

	public async getFailureReports(workOrderKey: string): Promise<FailureReportWrappers> {
		if (!this.provider) return new FailureReportWrappers();

		return await this.provider.getFailureReports(workOrderKey);
	}

	public async updateFailureReport(workOrder: WorkOrderWrapper, failureReport: FailureReportWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.updateFailureReport(workOrder, failureReport);
	}

	public async deleteFailureReport(workOrder: WorkOrderWrapper, failureReport: FailureReportWrapper): Promise<boolean> {
		if (!this.provider) return false;

		return await this.provider.deleteFailureReport(workOrder, failureReport);
	}
	//#endregion

	//#region Creating new models
	createWorkOrderModel(workOrderKey: string, assetRecord?: AssetRecord | HierarchyAssetRecord): WorkOrderWrapper {
		if (!this.provider) return null;

		return this.provider.createWorkOrderModel(workOrderKey, assetRecord);
	}

	public createWorkAssetModel(workOrder: WorkOrderWrapper, assetRecord: AssetRecord): WorkAssetWrapper {
		if (!this.provider) return null;

		return this.provider.createWorkAssetModel(workOrder, assetRecord);
	}

	public createWorkAssetFromHierarchyRecordModel(workOrder: WorkOrderWrapper, hierarchyAssetRecord: HierarchyAssetRecord): WorkAssetWrapper {
		if (!this.provider) return null;

		return this.provider.createWorkAssetFromHierarchyRecordModel(workOrder, hierarchyAssetRecord);
	}

	public createWorkTaskModel(workOrder: WorkOrderWrapper): TaskWrapper {
		if (!this.provider) return null;

		return this.provider.createWorkTaskModel(workOrder);
	}

	public createEmployeeCostModel(workOrder: WorkOrderWrapper): EmployeeCostWrapper {
		if (!this.provider) return null;

		return this.provider.createEmployeeCostModel(workOrder);
	}

	public createMaterialCostModel(workOrder: WorkOrderWrapper): MaterialCostWrapper {
		if (!this.provider) return null;

		return this.provider.createMaterialCostModel(workOrder);
	}

	public createEquipmentCostModel(workOrder: WorkOrderWrapper): EquipmentCostWrapper {
		if (!this.provider) return null;

		return this.provider.createEquipmentCostModel(workOrder);
	}

	public createWorkCommentModel(workOrder: WorkOrderWrapper): WorkCommentWrapper {
		if (!this.provider) return null;

		return this.provider.createWorkCommentModel(workOrder);
	}
	//#endregion

	//#region Hierarchy
	public async getHierarchy(assetLocation: AssetLocationWithAssets): Promise<HierarchyNode<AssetLocationWithAssets>> {
		if (!this.provider) return null;

		return await this.provider.getHierarchy(assetLocation);
	}

	public async refreshHierarchyNodeValues(node: HierarchyNode<AssetLocationWithAssets>,
		updateAttachments: boolean = true, updateWorkOrderCount: boolean = true): Promise<void> {
		if (!this.provider) return;

		return await this.provider.refreshHierarchyNodes(node, updateAttachments, updateWorkOrderCount);
	}

	public async getAsset(locationId?: string, activeAsset?: string): Promise<Asset> {
		if (!this.provider) return null;

		return await this.provider.getAsset(locationId, activeAsset);
	}

	public getAssetLocationByGisId(gisId: string): AssetLocationWithAssets {
		if (!gisId || !gisId.length) return null;

		const assetLocations = this.workOrderMetaData?.assetLocationsWithAssets;
		if (!assetLocations) return null;

		return assetLocations.find(al => al.gisId === gisId);
	}
	//#endregion

	//#region Attachments
	public getWorkOrderAttachmentUpload(file: File, workOrderWrapper: WorkOrderWrapper): WorkOrderAttachmentUpload {
		if (!this.provider) return null;

		return this.provider.getWorkOrderAttachmentUpload(file, workOrderWrapper);
	}

	public getAssetLocationAttachmentUpload(file: File, assetLocation: AssetLocationWithAssets): AssetLocationAttachmentUpload {
		if (!this.provider) return null;

		return this.provider.getAssetLocationAttachmentUpload(file, assetLocation);
	}
	//#endregion

	//#region Sedaru Connect
	private handleSedaruConnectUpdates(): void {
		if (!this.isSedaruConnectCapable) return;

		const handler = this.subscriptionService.hub.methodHandlers.getHandler('WorkOrderUpdatedHandler') as WorkOrderUpdatedHandler;
		if (!handler) return;

        handler.onWorkOrderUpdated.subscribe((sender, args) => {
            this.handleOnWorkOrderUpdated(sender, args);
        });
	}

	private async handleOnWorkOrderUpdated(sender: any, args: any): Promise<void> {
		const selectedConfig = this.interopService?.configurationManager?.guiConfig.find(g => g.isSelected)
        if (!args || !args.workOrderKey || !selectedConfig) return;

        const selectedMetric = selectedConfig.selectedTile?.metric;
        if (!selectedMetric || !selectedMetric.definition.isWorkOrderMetric) return;

        const selectedMetricResults = selectedMetric.result;
        if (!selectedMetricResults || !selectedMetricResults.results) return;

        const localWorkOrder = selectedMetricResults.results.find(r => r.workOrderKey === args.workOrderKey);
        if (!localWorkOrder) return;

        const serverWO = await this.getWorkOrders([args.workOrderKey], false)[0];
        if (!serverWO) return;

        const wasChanged = localWorkOrder.updateInformation(serverWO);
        if (!wasChanged) return;

        if (NavigationService.current.activeSidePanel.currentPageIdentifier === Pages.workorderOutline) {
            (NavigationService.current.activeSidePanel.currentPage as WorkOrderOutlineComponent).updateOutlineSteps();
        }
	}

	public sendWorkOrderUpdateNotification(workOrderKey: string, isServiceRequest = false): void {
		if (!this.isSedaruConnectCapable) return;

        const handler = this.subscriptionService?.hub?.methodHandlers?.getHandler('WorkOrderUpdatedHandler') as WorkOrderUpdatedHandler;
		if (!handler) return;

        handler.sendUpdatedWorkOrderMessage(workOrderKey, this.customerCode, isServiceRequest);
    }
	//#endregion

	//#region Misc. methods
	public cancelRealTimeSaveOperation(): void {
		if (!this.provider) return;

		this.provider.cancelRealTimeSaveOperation();
	}
	public isWorkOrderReadOnly(workOrder: WorkOrderWrapper): boolean {
		if (!workOrder || !this.readOnlyWorkOrderValues) return false;

		return this.readOnlyWorkOrderValues.includes(workOrder.status);
	}

	public async getRelatedWorkOrders(workOrder: WorkOrderWrapper): Promise<WorkOrderWrappers> {
		if (!this.provider) return new WorkOrderWrappers();

		return await this.provider.getRelatedWorkOrders(workOrder);
	}

	public async getWorkOrdersByAsset(assetType: string, assetKey: string): Promise<WorkOrderWrappers> {
		if (!this.provider) return new WorkOrderWrappers();

		return await this.provider.getWorkOrdersByAsset(assetType, assetKey);
	}

	public getWorkOrdersXY(workOrders: WorkOrderWrapper[]): void {
		if (!this.provider) return;

		this.provider.getWorkOrdersXY(workOrders);
	}

	public async getWorkOrderByFilter(metric: Metric, timeFrame?: Timeframe): Promise<void> {
		if (!this.provider) return;

		await this.provider.getWorkOrderByFilter(metric, timeFrame);
	}

	public getLEMLengthFromMetaData(metric: Metric) {
		const { workOrderSourceType } = metric.definition.source;
		const { employees, equipment, materials, vendors } = WorkOrderSourceType;
		switch (workOrderSourceType) {
			case employees:
				metric.result.value = this.workOrderMetaData.employees.length.toString();
				break;
			case equipment:
				metric.result.value = this.workOrderMetaData.equipments.length.toString();
				break;
			case materials:
				metric.result.value = this.workOrderMetaData.materials.length.toString();
				break;
			case vendors:
				metric.result.value = this.workOrderMetaData.vendors.length.toString();
				break;
		}
	}

	getArcGISFields(): ArcGISField[] {
		if (!this.provider) return [];
		return this.provider.getArcGISFields();
	}

	getDefaultSummaryFields(recurrenceOnly: boolean): WorkOrderField[] {
		if (!this.provider) return [];
		return this.provider.getDefaultSummaryFields(recurrenceOnly);
	}
	//#endregion
}
