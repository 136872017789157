import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';
import { ComponentViewDirective } from './component-view.directive';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { GuiConfig } from 'omni-model/gui-config.model';
import { NavigationArgs } from '../navigation-args';
import { INavigation, Pages } from '../inavigation';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { MenuPanelComponent } from 'app/menu-panel/menu-panel.component';

class BackStackEntry {
	parameter: any;
	pageIdentifier: Pages;
	uiState: any;
	searchTerm: any;
}

// https://stackoverflow.com/questions/50417132/how-do-i-find-element-inside-templateref
@Component({
	selector: 'app-router-outlet',
	template: `
		<ng-template appComponentView></ng-template>
	`
})
export class RouterComponent implements OnInit {
	/** announces when a navigation has completed successfully */
	@Output() navigationComplete = new EventEmitter<MenuPanelBaseComponent>();
	/** emits false if it is going back, true if going forward */
	@Output() navigationHappening = new EventEmitter<{ currentComponent: MenuPanelBaseComponent; navigatingBack: boolean; goingOut: boolean }>();
	/** the id of the guiConfig */
	@Input() config: GuiConfig;
	/** the component view to display */
	@ViewChild(ComponentViewDirective, { static: true }) componentView: ComponentViewDirective;

	/** holds the navigation history */
	private _backStack: Array<BackStackEntry>;
	public get backStack(): Array<BackStackEntry> {
		if (!this._backStack) this._backStack = new Array<BackStackEntry>();

		return this._backStack;
	}
	public get currentBackStackEntry(): BackStackEntry {
		if (this.backStack.length === 0) return undefined;

		return this.backStack[this.backStack.length - 1];
	}
	public get currentPageIdentifier(): Pages {
		if (!this.currentBackStackEntry) return Pages.none;

		return this.currentBackStackEntry.pageIdentifier;
	}

	public get previousPageInBackStack(): BackStackEntry {
		if (this.backStack.length < 2) return undefined;

		return this.backStack[this.backStack.length - 2];
	}

	private parentMenuPanel: MenuPanelComponent;

	/** holds the active component */
	private _currentPage: MenuPanelBaseComponent;
	public get currentPage(): MenuPanelBaseComponent {
		return this._currentPage;
	}

	private _currentComponentDefinition: any;
	/** holds the ComponentRef of the previous page */
	private previousComponentRef;
	/** holds the current ComponentRef */
	private currentComponentRef;

	resolver: INavigation;

	constructor(private componentFactoryResolver: ComponentFactoryResolver, private dialogService: AlertDialogService) {}

	ngOnInit() {}

	ngOnDestroy() {}

	set(value: MenuPanelComponent) {
		this.parentMenuPanel = value;
	}

	/** loads the given component into view */
	private loadComponent(page: Pages, isNavigatingBack: boolean, parameter: any, uiState?: any, searchTerm?: any): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			try {
				const previousPage = this._currentPage as MenuPanelBaseComponent;
				const args = new NavigationArgs(isNavigatingBack, previousPage, page, parameter, searchTerm);

				if (this.currentPage && this.currentBackStackEntry && this.currentBackStackEntry.pageIdentifier === page) {
					if (this.currentPage.slideOnPageReload) this.navigationHappening.emit({ currentComponent: this._currentPage, navigatingBack: args.isNavigatingBack, goingOut: true });
					this.waitForAnimation(10).then(() => {
						this.currentPage.onPageReload(args);
						const reloadResult = args.cancel === false;
						if (reloadResult) {
							this.currentBackStackEntry.parameter = parameter;
							this.currentBackStackEntry.searchTerm = searchTerm;
						}
						if (this.currentPage.slideOnPageReload) this.navigationHappening.emit({ currentComponent: this._currentPage, navigatingBack: args.isNavigatingBack, goingOut: false });
						resolve(reloadResult);
					});
					return;
				}

				if (previousPage) {
					if (
						(await previousPage.isDirty(args)) &&
						!(await this.dialogService.openConfirmDialog(previousPage.dirtyMessageTitle(), previousPage.dirtyMessageBody(), 'yes, continue', 'cancel'))
					) {
						args.cancel = true;
						resolve(false);
						return;
					}

					const navigatingFromResult = await previousPage.onPageNavigatingFrom(args);
					if (args.cancel) {
						resolve(false);
						return;
					}
				}

				const previousComponentDefinition = this._currentComponentDefinition;

				const requestedComponentDefinition = this.getNavigationPanel(page);

				this.navigationHappening.emit({ currentComponent: this._currentPage, navigatingBack: args.isNavigatingBack, goingOut: true });

				const viewContainerRef = this.componentView.viewContainerRef;
				this.previousComponentRef = this.currentComponentRef;
				this.waitForAnimation().then(() => {
					try {
						if (!this.previousComponentRef) {
							return;
						}
						for (let i = 1; i < viewContainerRef.length; i++) {
							viewContainerRef.remove(i);
						}
					} finally {
						resolve(true);
					}
				});

				const componentFactory = this.componentFactoryResolver.resolveComponentFactory(requestedComponentDefinition);
				this.currentComponentRef = viewContainerRef.createComponent(componentFactory, 0) as any;

				this._currentComponentDefinition = requestedComponentDefinition;
				this._currentPage = <MenuPanelBaseComponent>this.currentComponentRef.instance;
				this.navigationComplete.emit(this._currentPage);
				this._currentPage.config = this.config;

				if (!args.isNavigatingBack) {
					if (this.backStack.length) this.backStack[this.backStack.length - 1].uiState = previousPage.uiState;
					const backStackEntry = new BackStackEntry();
					backStackEntry.pageIdentifier = page;
					backStackEntry.parameter = parameter;
					backStackEntry.searchTerm = searchTerm;
					this.backStack.push(backStackEntry);
				} else {
					this._currentPage.uiState = uiState;
				}

				this._currentPage.onPageNavigatedTo(args);

				this.navigationHappening.emit({ currentComponent: this._currentPage, navigatingBack: args.isNavigatingBack, goingOut: false });
				this.navigationComplete.emit(this._currentPage);

				if (this.parentMenuPanel) {
					this.parentMenuPanel.currentNavigationEntry = this._currentPage.menuPanelNavigationEntry;
				}

				AnalyticsHub.current.trackEvent('page navigated', { toPage: requestedComponentDefinition.name });
			} catch (e) {
				console.error(e);

				resolve(false);
			}
		});
	}

	updateSearchTerm(value) {
		if (!this.currentBackStackEntry) return;
		if (this.currentBackStackEntry.searchTerm === value) return;
		this.currentBackStackEntry.searchTerm = value;
	}

	/** if a parameter is provided, the back stak will be popped up to the page provided
	 */
	navigateBack(page?: Pages, parameter?: any): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			if (!this.canGoBack()) {
				resolve(false);
				return;
			}

			let result = false;
			let foundComponent: BackStackEntry;
			if (!page) {
				foundComponent = this.backStack[this.backStack.length - 2];
				if (parameter) foundComponent.parameter = parameter;
				result = await this.loadComponent(foundComponent.pageIdentifier, true, foundComponent.parameter, foundComponent.uiState, foundComponent.searchTerm);
				if (result) {
					this.backStack.pop();
				}
				resolve(result);
				return;
			}

			let backstackIndex = -1;
			for (let i = this.backStack.length - 1; i >= 0; i--) {
				if (this.backStack[i].pageIdentifier === page) {
					backstackIndex = i;
					break;
				}
			}

			if (backstackIndex < 0) {
				result = await this.navigateTo(page, parameter);
				resolve(result);
				return;
			}

			foundComponent = this.backStack[backstackIndex];
			if (parameter) foundComponent.parameter = parameter;
			result = await this.loadComponent(foundComponent.pageIdentifier, true, foundComponent.parameter, foundComponent.uiState);
			if (result) {
				this.backStack.splice(backstackIndex + 1);
			}
			resolve(result);
		});
	}

	async navigateTo(page: Pages, parameter: any): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			try {
				const result = await this.loadComponent(page, false, parameter);
				resolve(result);
			} catch {
				resolve(false);
			}
		});
	}

	canGoBack() {
		return this.backStack.length > 1;
	}

	waitForAnimation(milliseconds?: number): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, milliseconds || 400));
	}

	/** maps a page's path to a menu panel component */
	private getNavigationPanel(name: Pages) {
		return this.resolver?.resolve(name);
	}

	onTabActivated() {
		if (!this.currentPage) return;
		this.currentPage.onTabActivated();
	}

	onTabDeactivated() {
		if (!this.currentPage) return;
		this.currentPage.onTabDeactivated();
	}

	clearComponents() {
		this.componentView.viewContainerRef.clear();
		this.backStack.splice(0, this._backStack.length);
		this.currentComponentRef = undefined;
		this._currentPage = null;
	}

	containsPage(page: Pages): boolean {
		return this.backStack.find(b => b.pageIdentifier === page) !== undefined;
	}

	removePageFromBackStack(page: Pages): boolean {
		if (!this.containsPage(page)) return false;

		const lastPageOccurrence = this.backStack
			.slice()
			.reverse()
			.find(b => b.pageIdentifier === page);
		if (!lastPageOccurrence) return false;

		const index = this.backStack.lastIndexOf(lastPageOccurrence);
		if (index < 0) return false;

		this.backStack.splice(index, 1);
		return true;
	}
}
