import { AssetChannelAttributes } from './asset-channel-attributes.model';

/**
 * This defines the esri asset channel attributes
 */
export class ArcGISAssetChannelAttributes extends AssetChannelAttributes {

	mapServiceDataSourceLegacyId: number;
	/**
	 * holds the esri asset feature service layer index
	 */
	featureServiceLayerIndex: number;
	/**
	 * holds the esri map service layer index
	 */
	mapServiceLayerIndex: number;
	/**
	 * holds the esri asset unique name
	 */
	uniqueFieldName = '';

	getIdFieldName(): string {
		return this.uniqueFieldName;
	}

	initialize?(attributes: ArcGISAssetChannelAttributes) {
		if (!attributes) return;
		this.mapServiceDataSourceLegacyId = attributes.mapServiceDataSourceLegacyId;
		this.assetType = attributes.assetType;
		this.featureServiceLayerIndex = attributes.featureServiceLayerIndex;
		this.mapServiceLayerIndex = attributes.mapServiceLayerIndex;
		this.uniqueFieldName = attributes.uniqueFieldName;
	}
}
