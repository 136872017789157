import { SpatialReference } from './spatial-reference';
import { Extent } from 'esri/geometry';
import { GeometryType } from './geometry';
import * as Geometry from 'esri/geometry/Geometry';

export class IdentifyTask {
	returnGeometry = true;
	returnFieldName = true;
	geometry: Geometry;
	mapExtent: Extent;
	mapDPI = 96; // Resolution of the current map view in dots per inch.
	mapWidth: number;
	mapHeight: number;
	geometryType: string;
	layers = 'visible';
	tolerance = 10;
	spatialReference: SpatialReference;

	getQueryString(): string {
		let geometryString;
		if (this.geometryType === GeometryType.esriGeometryPolygon) {
			geometryString = JSON.stringify(this.geometry);
		} else {
			geometryString = `{"x":${this.geometry.toJSON().x},"y":${this.geometry.toJSON().y}}`;
		}
		const jsonExtent = this.mapExtent.toJSON();
		const stringExtent = `${jsonExtent.xmin},${jsonExtent.ymin},${jsonExtent.xmax},${jsonExtent.ymax}`;
		if (!this.spatialReference && jsonExtent.spatialReference) this.spatialReference = jsonExtent.spatialReference;

		let query = `geometry=${geometryString}`;
		query += `&geometryType=${this.geometryType}`;
		query += `&tolerance=${this.tolerance}`;
		query += `&layers=${this.layers}`;
		query += `&mapExtent=${stringExtent}`;
		query += `&imageDisplay=${this.mapWidth},${this.mapHeight},${this.mapDPI}`;
		query += `&returnGeometry=${this.returnGeometry}`;
		query += `&returnFieldName=${this.returnFieldName}`;

		if (this.spatialReference) query += `&sr=${this.spatialReference.wkid}`;

		return encodeURI(query);
	}
}
