<div mat-dialog-container>
	<div>
		<h1 mat-dialog-title>
			{{ header }}
		</h1>
		<div mat-dialog-content>
			{{ message }}
		</div>
	</div>
	<div mat-dialog-actions>
		<span style="flex:1"></span>
		<button mat-raised-button color="warn" (click)="onClose(true)">
			yes
		</button>
		<button mat-raised-button (click)="onClose(false)">
			cancel
		</button>
	</div>
</div>
