import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { Metric } from 'models';
import { RecordContextGroup } from './record-context-group';

export class MetricRecord {
	contextGroup: RecordContextGroup;
	resolver: ListItemResolver;
	metric: Metric;
	get feature() {
		if (this.contextGroup.historyRecord) return this.contextGroup.historyRecord.feature;
		if (this.contextGroup.workOrderRecord) return this.contextGroup.workOrderRecord;
		return this.contextGroup.assetRecord.feature;
	}
}
