import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class AlertDialogService {
    /**
	 * The current dialog refrence
	 */
    dialogRef: MatDialogRef<any, any>;

    constructor(private dialog: MatDialog) {}

	async openConfirmDialog(title: string, body: string, confirmButtonTitle?: string, cancelButtonTitle?: string): Promise<boolean> {
		this.dialogRef = this.dialog.open(DeleteDialogComponent, { width: '480px' });
		const dialogConfig = {
			title: title,
			content: body
		};

		this.dialogRef.componentInstance.config = dialogConfig;
		this.dialogRef.componentInstance.confirmMessage = confirmButtonTitle ? confirmButtonTitle : 'yes';
		this.dialogRef.componentInstance.cancelMessage = cancelButtonTitle ? cancelButtonTitle : 'no';
		return await this.dialogRef.afterClosed().toPromise();
	}
}
