import { OmniModel } from '../../models/omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class WorkComment extends OmniModel<WorkComment, Contracts.WorkCommentContract> {
	constructor(workOrderKey?: string) {
		super();
		if (workOrderKey) this.workOrderKey = workOrderKey;
	}
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _commentId: SedaruUtils.Undoable<string>;
	get commentId(): string {
		if (!this._commentId) this._commentId = new SedaruUtils.Undoable<string>();
		return this._commentId.value;
	}
	set commentId(value: string) {
		if (!this._commentId) {
			this._commentId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._commentId.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	private _createdDate: SedaruUtils.Undoable<string>;
	get createdDate(): string {
		if (!this._createdDate) this._createdDate = new SedaruUtils.Undoable<string>();
		return this._createdDate.value;
	}
	set createdDate(value: string) {
		if (!this._createdDate) {
			this._createdDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdDate.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	static fromContract(contract: Contracts.WorkCommentContract) {
		const model = new WorkComment();
		model.id = String(contract.ObjectId);
		model.description = contract.Description;
		model.createdBy = contract.CreatedBy;
		model.createdDate = contract.CreatedDate;
		model.workOrderKey = contract.WorkorderKey;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.WorkCommentContract {
		const contract = new Contracts.WorkCommentContract();
		contract.ObjectId = +this.id;
		contract.CommentId = this.commentId;
		contract.Description = this.description;
		contract.CreatedBy = this.createdBy;
		contract.CreatedDate = this.createdDate;
		contract.WorkorderKey = this.workOrderKey;
		return contract;
	}

	onUpdateInformation(copyModel: WorkComment): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._commentId, () => {
				this.commentId = copyModel.commentId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdDate, () => {
				this.createdDate = copyModel.createdDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		return wasChanged;
	}

	get isNew(): boolean {
		return this.id === '0';
	}
}
