import { Field } from './field';
import { Feature } from './feature';
import { ConnectionClient } from './connection-client';
import { Server } from './server';
import { ArcGISField, ArcGISFieldDomain } from '../../models/arc-gis-field.model';
import { Credentials } from './credentials';
import { TokenManager } from './token-manager';
import { CredentialsType } from '.';

// Correct Class
export class FeatureLayer {
	parentServer: Server;
	get url(): string {
		return this.parentServer.url + '/' + this.id;
	}

	currentVersion: number;

	objectIdField: string;
	displayField: string;
	globalIdField: string;

	id: number;
	name: string;
	type: string;
	parentLayer: null;
	defaultVisibility: boolean;
	minScale: number;
	maxScale: number;
	geometryType: string;
	description: string;
	copyrightText: string;
	editFieldsInfo: null;
	ownershipBasedAccessControlForFeatures: null;
	syncCanReturnChanges: boolean;
	relationships: any[];
	isDataVersioned: boolean;
	isDataArchived: boolean;
	isCoGoEnabled: boolean;
	supportsRollbackOnFailureParameter: boolean;

	supportsStatistics: boolean;
	supportsAdvancedQueries: boolean;
	supportsValidateSQL: boolean;
	supportsCoordinatesQuantization: boolean;
	supportsCalculate: boolean;
	advancedQueryCapabilities: { [key: string]: boolean };

	hasM: boolean;
	hasZ: boolean;
	allowGeometryUpdates: boolean;
	allowTrueCurvesUpdates: boolean;
	onlyAllowTrueCurveUpdatesByTrueCurveClients: boolean;
	hasAttachments: boolean;
	supportsApplyEditsWithGlobalIds: boolean;
	htmlPopupType: string;

	typeIdField: string;
	subtypeField: string;
	types: any[];

	maxRecordCount: number;
	supportedQueryFormats: string;
	capabilities: string;
	useStandardizedQueries: boolean;
	standardMaxRecordCount: number;
	tileMaxRecordCount: number;
	maxRecordCountFactor: number;

	private _fields: Field[];
	public get fields(): Field[] {
		if (!this._fields) this._fields = [];
		return this._fields;
	}
	public set fields(value: Field[]) {
		this._fields = value;
	}

	/*archivingInfo: ArchivingInfo;
	extent: Extent;
	sourceSpatialReference: SourceSpatialReference;
	drawingInfo: DrawingInfo;

	geometryField: Field;
	indexes: Index[];
	dateFieldsTimeReference: DateFieldsTimeReference;

	templates: Template[];*/

	public static fromJson(sourceFeatureLayer: any): FeatureLayer {
		const featureLayer = new FeatureLayer();

		Object.assign(featureLayer, sourceFeatureLayer);

		return featureLayer;
	}

	createFeature(): Feature {
		return new Feature();
	}

	createQuery() {
		return {};
	}

	async query(
		where: string = '1=1',
		outFields: string = '*',
		returnGeometry = true,
		resultRecordCount = 0,
		returnDistinctValues = false,
		orderByFields?: { fieldName: string; sortType: 'ASC' | 'DESC' }[]
	): Promise<Array<any>> {
		let data = '';
		data += 'where=' + where;
		data += `&outFields=${outFields}`;
		data += `&returnGeometry=${returnGeometry}`;
		data += `&returnDistinctValues=${returnDistinctValues}`;

		if (resultRecordCount > 0) data += `&resultRecordCount=${resultRecordCount}`;

		if (orderByFields) {
			const sortString = orderByFields.map(i => i.fieldName + ' ' + i.sortType).join(', ');
			data += `&orderByFields=${sortString}`;
		}

		const response = await ConnectionClient.current.postData(this.url + '/query', data, this.parentServer.credentials, this.parentServer.proxyUrl);

		return new Promise((resolve, reject) => {
			if (!response.success) {
				resolve(null);
				return;
			}

			resolve(response.responseJsonToken.features);
			return;
		});
	}

	async getAttachmentList(objectId) {
		const { credentials, proxyUrl } = this.parentServer;
		const response = await ConnectionClient.current.getJson(this.url + `/${objectId}/attachments`, credentials, proxyUrl);
		return new Promise(async (resolve, reject) => {
			if (!response.success) {
				resolve(null);
				return;
			}

			const attachmentInfos = response.responseJsonToken.attachmentInfos;
			if (!attachmentInfos) {
				resolve(null);
				return;
			}

			const attachmentList = new Array<any>();
			for (const attachment of attachmentInfos) {
				attachmentList.push({
					id: attachment.id,
					contentType: attachment.contentType,
					name: attachment.name,
					url: await this.buildAttachmentUrl(this.url + `/${objectId}/attachments/${attachment.id}`, credentials, proxyUrl)
				});
			}

			if (attachmentList && attachmentList.length) {
				resolve(attachmentList);
				return;
			}

			resolve(null);
			return;
		});
	}

	async validateQuery(where: string) {
		let data = where.replace(/\n/g, ' \\n ');
		data = data.replace(/"/g, '\\"');
		data = 'where=' + encodeURIComponent(data) + '&returnCountOnly=true';
		const response = await ConnectionClient.current.postData(this.url + '/query', data, this.parentServer.credentials, this.parentServer.proxyUrl);

		return new Promise((resolve, reject) => {
			resolve(response.success);
			return;
		});
	}

	getOmniFields(prefix: string) {
		const fieldsToReturn: ArcGISField[] = [];
		for (const f of this.fields) {
			const newField = new ArcGISField();
			newField.alias = f.alias;
			newField.type = f.type;
			if (f.domain) {
				newField.domain = new ArcGISFieldDomain();
				newField.domain.initialize(f.domain);
				if (newField.domain.codedValues) {
					for (const value of newField.domain.codedValues) {
						value.code = `${value.code}`;
					}
				}
			}
			newField.name = f.name;
			newField.omniName = `${prefix}-${f.name}`;
			fieldsToReturn.push(newField);
		}

		return fieldsToReturn;
	}

	private async buildAttachmentUrl(url: string, credentials?: Credentials, proxyUrl?: string): Promise<string> {
		let fullUrl = url;
		if (proxyUrl) fullUrl = `${proxyUrl}?${fullUrl}`;
		if (!credentials) return fullUrl;
		if (credentials.type === CredentialsType.Token) return `${fullUrl}?token=${credentials.token}`;
		if (!credentials.isAuthenticated) await TokenManager.current.authenticateCredentials(url, credentials);
		return `${fullUrl}?token=${credentials.token}`;
	}
}
