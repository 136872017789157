import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';
import { CustomFields } from './custom-fields.model';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { CustomFieldContract } from '../../contracts/work-order/index';

/**
 * This defines the sedaru work task
 */
export class WorkTask extends OmniModel<WorkTask, Contracts.WorkTaskContract> {
	constructor(workOrderKey?: string) {
		super();
		this.workOrderKey = workOrderKey;
	}
	/**
	 * uuid for work task
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the work task assigned to
	 */
	private _assignedTo: SedaruUtils.Undoable<string>;
	get assignedTo(): string {
		if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
		return this._assignedTo.value;
	}
	set assignedTo(value: string) {
		if (!this._assignedTo) {
			this._assignedTo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assignedTo.value = value;
	}

	/**
	 * holds the work task completed date
	 */
	private _completedDate: SedaruUtils.Undoable<string>;
	get completedDate(): string {
		if (!this._completedDate) this._completedDate = new SedaruUtils.Undoable<string>();
		return this._completedDate.value;
	}
	set completedDate(value: string) {
		if (!this._completedDate) {
			this._completedDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedDate.value = value;
	}

	/**
	 * holds the work task description
	 */
	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	/**
	 * holds the work task sequence
	 */
	private _sequence: SedaruUtils.Undoable<number>;
	get sequence(): number {
		if (!this._sequence) this._sequence = new SedaruUtils.Undoable<number>();
		return this._sequence.value;
	}
	set sequence(value: number) {
		if (!this._sequence) {
			this._sequence = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._sequence.value = value;
	}

	/**
	 * holds the work task status
	 */
	private _status: SedaruUtils.Undoable<string>;
	get status(): string {
		if (!this._status) this._status = new SedaruUtils.Undoable<string>();
		return this._status.value;
	}
	set status(value: string) {
		if (!this._status) {
			this._status = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._status.value = value;
	}

	/**
	 * holds the work task task key
	 */
	private _taskKey: SedaruUtils.Undoable<string>;
	get taskKey(): string {
		if (!this._taskKey) this._taskKey = new SedaruUtils.Undoable<string>();
		return this._taskKey.value;
	}
	set taskKey(value: string) {
		if (!this._taskKey) {
			this._taskKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._taskKey.value = value;
	}

	/**
	 * holds the work order key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the work task id
	 */
	private _workTaskId: SedaruUtils.Undoable<string>;
	get workTaskId(): string {
		if (!this._workTaskId) this._workTaskId = new SedaruUtils.Undoable<string>();
		return this._workTaskId.value;
	}
	set workTaskId(value: string) {
		if (!this._workTaskId) {
			this._workTaskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTaskId.value = value;
	}

	/**
	 * holds the work task comments
	 */
	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<string>;
	get changeStatus(): string {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<string>();
		return this._changeStatus.value;
	}
	set changeStatus(value: string) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeStatus.value = value;
	}


	private _changeBy: SedaruUtils.Undoable<string>;
	get changeBy(): string {
		if (!this._changeBy) this._changeBy = new SedaruUtils.Undoable<string>();
		return this._changeBy.value;
	}
	set changeBy(value: string) {
		if (!this._changeBy) {
			this._changeBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeBy.value = value;
	}

	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	private _teamId: SedaruUtils.Undoable<string>;
	get teamId(): string {
		if (!this._teamId) this._teamId = new SedaruUtils.Undoable<string>();
		return this._teamId.value;
	}
	set teamId(value: string) {
		if (!this._teamId) {
			this._teamId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._teamId.value = value;
	}

	private _extraFields: SedaruUtils.Undoable<any>;
	get extraFields(): any {
		if (!this._extraFields) this._extraFields = new SedaruUtils.Undoable<any>();
		return this._extraFields.value;
	}
	set extraFields(value: any) {
		if (!this._extraFields) {
			this._extraFields = new SedaruUtils.Undoable<any>(value);
			return;
		}
		this._extraFields.value = value;
	}

	private _customFields: CustomFieldWrappers;
	get customFields(): CustomFieldWrappers {
		return this._customFields ? this._customFields : (this._customFields = new CustomFieldWrappers());
	}
	set customFields(value) {
		if (this._customFields === value) return;
		this._customFields = value;
	}

	static fromContract(contract: Contracts.WorkTaskContract): WorkTask {
		const model = new WorkTask();
		model.id = contract.ObjectId.toString();
		model.assignedTo = contract.AssignedTo;
		model.completedDate = DateUtil.isValidDate(contract.CompletedDate) ? contract.CompletedDate : '';
		model.description = contract.Description;
		model.sequence = contract.Sequence;
		model.status = contract.Status;
		model.taskKey = contract.TaskKey;
		model.workOrderKey = contract.WorkOrderKey;
		model.workTaskId = contract.WorkTaskID;
		model.comments = contract.Comments;
		model.changeStatus = contract.ChangeStatus;
		model.changeBy = contract.ChangeBy;
		model.systemId = contract.SystemId;
		model.teamId = contract.TeamID;
		model.extraFields = contract.ExtraFields;
		if (contract.CustomFields) model.customFields = CustomFieldWrappers.fromContracts(contract.CustomFields);
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.WorkTaskContract => {
		const contract = new Contracts.WorkTaskContract();
		contract.ObjectId = +this.id;
		contract.AssignedTo = this.assignedTo;
		contract.CompletedDate = this.completedDate;
		contract.Description = this.description;
		contract.Sequence = this.sequence;
		contract.Status = this.status;
		contract.TaskKey = this.taskKey;
		contract.WorkOrderKey = this.workOrderKey;
		contract.WorkTaskID = this.workTaskId;
		contract.Comments = this.comments;
		contract.ChangeStatus = this.changeStatus;
		contract.ChangeBy = this.changeBy;
		contract.SystemId = this.systemId;
		contract.TeamID = this.teamId;
		contract.ExtraFields = this.extraFields;
		contract.CustomFields = this.customFields.getContracts() as CustomFieldContract[];
		return contract;
	};

	onUpdateInformation(copyModel: WorkTask): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assignedTo, () => {
				this.assignedTo = copyModel.assignedTo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._completedDate, () => {
				this.completedDate = copyModel.completedDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._sequence, () => {
				this.sequence = copyModel.sequence;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._status, () => {
				this.status = copyModel.status;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._taskKey, () => {
				this.taskKey = copyModel.taskKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTaskId, () => {
				this.workTaskId = copyModel.workTaskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._comments, () => {
				this.comments = copyModel.comments;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._changeStatus, () => {
				this.changeStatus = copyModel.changeStatus;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._teamId, () => {
				this.teamId = copyModel.teamId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<any>(this._extraFields, () => {
				this.extraFields = copyModel.extraFields;
			}) || wasChanged;

		wasChanged = this.refreshSubFields(this.customFields, copyModel.customFields);

		return wasChanged;
	}

	private refreshSubFields(currentFields: any[], newFields: any[]): boolean {
		let wasChanged = false;
		for (let i = 0; i < currentFields.length; i++) {
			// loop through old fields, find out if current field still exist in the new fields
			const stillExist = newFields.find(field => field.id === currentFields[i].id);
			// If exist, then we leave it along, no need to remove
			if (stillExist) continue;
			// else remove.
			const indexToRemove = currentFields.findIndex(field => field.id === currentFields[i].id);
			if (indexToRemove > -1) {
				currentFields.splice(indexToRemove, 1);
				i--;
				wasChanged = true;
			}
		}

		for (const newField of newFields) {
			// loop through new fields, find out if any of the old field need to be updated
			const currentFieldToUpdate = currentFields.find(field => field.id === newField.id);

			// If found, then old field need to be updated.
			if (currentFieldToUpdate) {
				wasChanged = currentFieldToUpdate.updateInformation(newField) || wasChanged;
			} else { // else, new field doesn't exist in the current state, so we push in the new field.
				currentFields.push(newField);
				wasChanged = true;
			}
		}

		return wasChanged;
	}

	get isNew() {
		return this.id === '0';
	}
}
