<div *ngIf="open" class="alert-dialog-overlay" (click)="close()">
    <div [style.width]="width" class="alert-dialog" (click)="$event.stopPropagation();">
        <div *ngIf="title">
            <div class="alert-title">
                <div *ngIf="title" class="title-text omni-md-font">{{ title }}</div>
                <div class="close-button" (click)="close()">
                    <img class="omni-md-img" src="assets/x-close.png" />
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="main-message" [ngStyle]="{ color: mainMessage && mainMessage.color ? getRGBAString(mainMessage.color) :  '#FFFFFF'}" [innerHTML]="mainMessage ? mainMessage.text : ''">
        </div>
        <div class="secondary-message" [ngStyle]="{ color: secondaryMessage && secondaryMessage.color ? getRGBAString(secondaryMessage.color) : '#FFFF00' }" [innerHTML]="secondaryMessage ? secondaryMessage.text : ''">
        </div>
        <div *ngIf="alertButtons" class="alert-buttons">
            <div *ngFor="let button of alertButtons" class="alert-button" (click)="button.onClick(); close()">
                <span class="button-icon">
                    <img class="omni-md-img" [src]="button.icon" />
                </span>
                <div class="button-text">{{ button.text }}</div>
            </div>
        </div>
        <div *ngIf="!alertButtons" class="alert-buttons">
            <div (click)="close()">
                <span class="button-icon">
                    <img class="omni-md-img" src="assets/tick-tranparent.png" />
                </span>
                <div class="button-text">OK</div>
            </div>
        </div>
      </div>
</div>
  