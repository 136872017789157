import { DailyPatternType } from '../../models/recurrence/daily-pattern-type.enum';
import { ContractBase } from '../contract-base';
import { MonthlyPatternContract } from './monthly-pattern-contract';
import { WeeklyPatternContract } from './weekly-pattern-contract';
import { YearlyPatternContract } from './yearly-pattern-contract';

export class PatternContract extends ContractBase {
    public interval: number;
    public dailyPattern: string;
    public weeklyPattern: WeeklyPatternContract;
    public monthlyPattern: MonthlyPatternContract;
    public yearlyPattern: YearlyPatternContract;
}
