import { ListItemResolver } from '../list-item.resolver';
import { MetricIcon } from 'models';
import { ListTemplates } from '../templates.enum';

export class TwoIconsOneLineItemResolver extends ListItemResolver {
	templateType = ListTemplates.twoIconsOneLine;
	getLabel: (item: any) => string;
	getLeftIcon: (item: any) => string | MetricIcon;
	getRightIcon: (item: any) => string;
	onShowRightIconOnHover: (item: any) => boolean;
	showRightIconOnHover(item: any): boolean {
		if (!this.onShowRightIconOnHover) return false;
		return this.onShowRightIconOnHover(item);
	}
}
