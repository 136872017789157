import * as Contracts from '../../contracts/work-order';
import { OmniModel } from '../../models/omni-model';
import * as SedaruUtils from '../../sedaru-util';

export class FailureCode extends OmniModel<FailureCode, Contracts.FailureCodeContract> {
    private _id: SedaruUtils.Undoable<string>;
    get id(): string {
        if (!this._id) this._id = new SedaruUtils.Undoable<string>();
        return this._id.value;
    }
    set id(value: string) {
        if (!this._id) {
            this._id = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._id.value = value;
    }

    private _type: SedaruUtils.Undoable<string>;
    get type(): string {
        if (!this._type) this._type = new SedaruUtils.Undoable<string>();
        return this._type.value;
    }
    set type(value: string) {
        if (!this._type) {
            this._type = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._type.value = value;
    }

    private _value: SedaruUtils.Undoable<string>;
    get value(): string {
        if (!this._value) this._value = new SedaruUtils.Undoable<string>();
        return this._value.value;
    }
    set value(value: string) {
        if (!this._value) {
            this._value = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._value.value = value;
    }

    private _description: SedaruUtils.Undoable<string>;
    get description(): string {
        if (!this._description) this._description = new SedaruUtils.Undoable<string>();
        return this._description.value;
    }
    set description(value: string) {
        if (!this._description) {
            this._description = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._description.value = value;
    }

    private _parentId: SedaruUtils.Undoable<string>;
    get parentId(): string {
        if (!this._parentId) this._parentId = new SedaruUtils.Undoable<string>();
        return this._parentId.value;
    }
    set parentId(value: string) {
        if (!this._parentId) {
            this._parentId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._parentId.value = value;
    }

    static fromContract(contract: Contracts.FailureCodeContract): FailureCode {
        const model = new FailureCode();
        model.id = contract.ObjectId.toString();
        model.type = contract.Type;
        model.value = contract.Value;
        model.description = contract.Description;
        model.parentId = contract.ParentId;
        return model;
    }

    getContract(): Contracts.FailureCodeContract {
        const contract = new Contracts.FailureCodeContract();
        contract.ObjectId = +this.id;
        contract.Type = this.type;
        contract.Value = this.value;
        contract.Description = this.description;
        contract.ParentId = this.parentId;
        return contract;
    }

    onUpdateInformation(copyModel: FailureCode): boolean {
        let wasChanged = false;
		wasChanged = this.updateIfNotDirty<string>(this._id, () => {
                this.id = copyModel.id;
            }) || wasChanged;
		wasChanged =
            this.updateIfNotDirty<string>(this._type, () => {
                this.type = copyModel.type;
            }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._value, () => {
				this.value = copyModel.value;
			}) || wasChanged;
		wasChanged =
            this.updateIfNotDirty<string>(this._description, () => {
                this.description = copyModel.description;
            }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._parentId, () => {
				this.parentId = copyModel.parentId;
			}) || wasChanged;
		return wasChanged;
    }
}
