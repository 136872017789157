import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';

export class ColorsTransform extends TransformBase {
	protected onTransform(): TransformationResult {
		if (!this.fieldforceConfig.Design || !this.fieldforceConfig.Design.DesignProperties || !this.fieldforceConfig.Design.DesignProperties.Colors) {
			return this.getResult(true);
		}

		for (const sourceColor of this.fieldforceConfig.Design.DesignProperties.Colors) {
			const color = new SedaruModels.Color();
			color.id = sourceColor.ID;
			color.r = sourceColor.R;
			color.b = sourceColor.B;
			color.g = sourceColor.G;
			color.alpha = sourceColor.Alpha;
			color.name = sourceColor.Name;
			color.isDefault = sourceColor.IsDefault;
			this.globalConfig.colors.push(color);
		}

		return this.getResult(true);
	}
}
