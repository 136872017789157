export class ServerInfo {
	signalRVersion: string;
	protocolVersion: string;

	static fromAny(jObject: any): ServerInfo {
		const serverInfo = new ServerInfo();

		if (!jObject) {
			return serverInfo;
		}

		serverInfo.protocolVersion = jObject.ProtocalVersion;
		serverInfo.signalRVersion = jObject.SignalRVersion;

		return serverInfo;
	}
}
