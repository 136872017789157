<div class="canvas-table-wrapper">
	<div class="table-header" *ngIf="tableDataSource?.length">
		<button mat-icon-button><img class="search-icon" src="assets/search.png" /></button>
		<input matInput (keyup)="omniTable?.applyFilter($event)" placeholder="Search Table" autocomplete="off" />
		<span style="flex:1"></span>
		<div class="download" *ngIf="!downloadInProgress && !saveFileActive">
			<label>Download</label>
			<button  mat-icon-button (click)="exportTable()"><img class="download-icon" src="assets/download.png" /></button>
		</div>
		<button *ngIf="downloadInProgress && !saveFileActive"  mat-button class="omni-md-font">
			<img class="omni-sm-img" [src]="pacifierIcon" />
			{{ downloadButtonText }}
		</button>
		<div class="download"  *ngIf="downloadUrl">
			<a *ngIf="!downloadInProgress && saveFileActive" (click)="downLoadLinkHandler()"
				 class="omni-md-font" [attr.href]="downloadUrl" [attr.download]="downloadName" target="_blank">
				<span>Download report </span>
				<img class="save-icon omni-sm-img" src="assets/save.png" />
			</a>
		</div>
	</div>
	<div class="omni-table-container" style="flex:1" *ngIf="tableDataSource?.length">
		<app-omni-table
			[config]="config"
			[data]="tableDataSource"
			[fields]="tableFields"
			[batchSize]="batchSize"
			[length]="tableLength"
			[uniqueKey]="uniqueKey"
			[selectedUniqueValue]="selectedUniqueValue"
			(rowSelected)="rowSelectedEvent($event)"
			[headerColor]="bgColor"
			[activeMetric]="activeMetric"
		></app-omni-table>
	</div>

	<div class="message" *ngIf="!tableDataSource?.length && !isTableLoading && !isEmptyResults">select a metric to see results</div>

	<div class="message" *ngIf="!tableDataSource?.length && !isTableLoading && isEmptyResults">empty results</div>

	<div class="message" *ngIf="isTableLoading">loading...</div>
</div>
