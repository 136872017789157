export class Feature {
	public get objectId(): number {
		for (const propertyName in this.attributes) {
			if (propertyName.toLowerCase() === 'objectid') {
				return this.attributes[propertyName];
			}
		}
	}

	attributes: any;
	geometry: any;
	esriFeature: any;
	displayFieldName: string;

	public static fromEsriFeature(feature: any) {
		const newFeature = new Feature();
		newFeature.attributes = {};
		for (const k of Object.keys(feature.attributes)) {
			newFeature.attributes[k.replace(' ', '')] = feature.attributes[k];
		}
		newFeature.geometry = feature.geometry;
		newFeature.esriFeature = feature;
		newFeature.displayFieldName = feature.displayFieldName;
		return newFeature;
	}

	getAttribute(fieldname: string): any {
		if (!this.attributes || !Object.keys(this.attributes).length || !fieldname) return;
		const normalizedKey = Object.keys(this.attributes).find(k => k.toLowerCase() === fieldname.toLowerCase());
		return this.attributes[normalizedKey];
	}

	getJSON(): any {
		const obj = {};
		if (this.attributes) obj['attributes'] = this.attributes;
		if (this.geometry) obj['geometry'] = this.geometry;
		return obj;
	}

	clearDirty() {}
}
