import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util/index';

export class WorkOrderBudget extends OmniModel<WorkOrderBudget, Contracts.WorkOrderBudgetContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _budgetId: SedaruUtils.Undoable<string>;
	get budgetId(): string {
		if (!this._budgetId) this._budgetId = new SedaruUtils.Undoable<string>();
		return this._budgetId.value;
	}
	set budgetId(value: string) {
		if (!this._budgetId) {
			this._budgetId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._budgetId.value = value;
	}

	private _name: SedaruUtils.Undoable<string>;
	get name(): string {
		if (!this._name) this._name = new SedaruUtils.Undoable<string>();
		return this._name.value;
	}
	set name(value: string) {
		if (!this._name) {
			this._name = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._name.value = value;
	}

	static fromContract(contract: Contracts.WorkOrderBudgetContract): WorkOrderBudget {
		const model = new WorkOrderBudget();
		model.id = contract.ObjectId.toString();
		model.budgetId = contract.BudgetId;
		model.name = contract.Name;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.WorkOrderBudgetContract => {
		const contract = new Contracts.WorkOrderBudgetContract();
		contract.ObjectId = +this.id;
		contract.BudgetId = this.budgetId;
		contract.Name = this.name;
		return contract;
	};

	onUpdateInformation(copyModel: WorkOrderBudget): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._budgetId, () => {
				this.budgetId = copyModel.budgetId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._name, () => {
				this.name = copyModel.name;
			}) || wasChanged;
		return wasChanged;
	}
}
