import { Employee, Equipment, Material, Employees, Equipments, Materials, EmployeeCost, EquipmentCost, MaterialCost, Vendor, Vendors } from './../../../models/work-order';
import { WorkOrderSourceType } from './../../../models/work-order-source-type.enum';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NoIconTwoLinesItemResolver } from 'app/ui-components/omni-list/list-item/templates/no-icon-two-lines/no-icon-two-lines.resolver';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { ListTemplates } from 'app/ui-components/omni-list/list-item/templates/templates.enum';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { Metric } from 'models';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-lem-cost-view',
	templateUrl: './workorder-lem-cost-view.component.html'
})
export class WorkOrderLEMCostViewComponent extends MenuPanelBaseComponent {
	resolver: NoIconTwoLinesItemResolver;

	list: Employees | Equipments | Materials | Vendors;

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	constructor(view: ElementRef<HTMLElement>, private workOrderFactory: WorkOrderFactory) {
		super(view);
	}

	/**
	 * Get the argument data from route.
	 * @param {NavigationArgs} args - arguments passed along with the route on init.
	 */
	onPageNavigatedTo(args: NavigationArgs) {
		this.loadView(args.parameter['workOrderSourceType'], args.parameter['metric']);
		this.menuPanelComponent.updateView({ title: 'workorder', backgroundClass: 'orange-background' });
	}

	/**
	 * Get the argument data from route.
	 * @param {NavigationArgs} args - arguement passed along with the route on reload.
	 */
	onPageReload(args: NavigationArgs) {
		this.loadView(args.parameter['workOrderSourceType'], args.parameter['metric']);
	}

	/**
	 * Loads the list to view depends on the workorder source type
	 * @param {WorkOrderSourceType} workOrderSourceType - the workorder source type
	 */
	private loadView(workOrderSourceType: WorkOrderSourceType, metric: Metric) {
		const resolver = new NoIconTwoLinesItemResolver();
		resolver.templateType = ListTemplates.noIconTwoLines;
		const { workOrderMetaData } = this.workOrderFactory;
		switch (true) {
			case workOrderSourceType === WorkOrderSourceType.employees:
				resolver.getTopLabel = (employee: Employee) => employee.name;
				resolver.getBottomLeftLabel = (employee: Employee) => employee.employeeId;
				resolver.getBottomRightLabel = (employee: Employee) => (employee.rate ? employee.rate.toString() : '');
				this.list = workOrderMetaData.employees;
				break;
			case workOrderSourceType === WorkOrderSourceType.equipment:
				resolver.getTopLabel = (equipment: Equipment) => equipment.description;
				resolver.getBottomLeftLabel = (equipment: Equipment) => equipment.equipmentId;
				resolver.getBottomRightLabel = (equipment: Equipment) => (equipment.hourlyRate ? equipment.hourlyRate.toString() : '');
				this.list = workOrderMetaData.equipments;
				break;
			case workOrderSourceType === WorkOrderSourceType.materials:
				resolver.getTopLabel = (material: Material) => material.description;
				resolver.getBottomLeftLabel = (material: Material) => material.materialId;
				resolver.getBottomRightLabel = (material: Material) => (material.unitCost ? material.unitCost.toString() : '');
				this.list = workOrderMetaData.materials;
				break;
			case workOrderSourceType === WorkOrderSourceType.workOrderCosts:
				resolver.getTopLabel = (cost: EmployeeCost | EquipmentCost | MaterialCost) => {
					if (cost instanceof EmployeeCost) return cost.employeeId;
					else if (cost instanceof EquipmentCost) return cost.equipmentId;
					else if (cost instanceof MaterialCost) return cost.materialId;
				};
				resolver.getBottomLeftLabel = (cost: EmployeeCost | EquipmentCost | MaterialCost) => {
					if (cost instanceof EmployeeCost) return cost.workOrderKey;
					else if (cost instanceof EquipmentCost) return cost.workOrderKey;
					else if (cost instanceof MaterialCost) return cost.workOrderKey;
				};
				resolver.getBottomRightLabel = (cost: EmployeeCost | EquipmentCost | MaterialCost) => {
					if (cost instanceof EmployeeCost) return cost.workTaskId;
					else if (cost instanceof EquipmentCost) return cost.workTaskId;
					else if (cost instanceof MaterialCost) return cost.workTaskId;
				};
				// const { workOrderFields: workOrderFilters } = (metric.definition.query as AWOQuery).workOrderFilter;
				// this.list = availableWorkOrders.getWorkOrderCostByType(workOrderFilters.getCostTypeCSVValue().split(','));
				break;
			case workOrderSourceType === WorkOrderSourceType.vendors:
				resolver.getTopLabel = (vendor: Vendor) => vendor.description;
				resolver.getBottomLeftLabel = (vendor: Vendor) => vendor.vendorId;
				resolver.getBottomRightLabel = (vendor: Vendor) => (vendor.unitCost ? String(vendor.unitCost) : '');
				this.list = workOrderMetaData.vendors;
				break;
		}
		this.listComponent.getResolver = () => resolver;
	}

	onItemClicked(item: Employee | Equipment | Material) {
		console.log(item);
	}
}
