import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';

export class DefaultExtent extends SedaruConfigBase {
	xmin?: number;

	xmax?: number;

	ymin?: number;

	ymax?: number;

	constructor(globalConfig: GlobalConfig) {
		super(globalConfig);
	}
}
