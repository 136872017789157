import { Component } from '@angular/core';
import { Color } from 'models';

export class Message {
  text: string;
  color?: Color;
}

export class AlertButton {
  text: string;
  icon: string;
  onClick: () => void;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  title: string;
  mainMessage: Message;
  secondaryMessage: Message;
  alertButtons: Array<AlertButton>;
  open: boolean;
  width = '480px';
  onDismiss: () => void;

  close() {
    this.title = '';
    this.mainMessage = new Message();
    this.secondaryMessage = new Message();
    this.alertButtons = [];
    this.open = false;
    if (this.onDismiss) this.onDismiss();
  }

  getRGBAString(color: Color) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.alpha / 100})`;
  }
}
