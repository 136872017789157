<div class="scrolling-content">
    <app-menu-panel-field
        [header]="'current password'"
        [placeHolder]="'enter current password'"
        [(value)]="currentPassword"
        [backgroundColor]="lightGrayColor"
        [type]="'password'"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'new password'"
        [placeHolder]="'enter new password'"
        [(value)]="newPassword"
        [backgroundColor]="darkGrayColor"
        [type]="'password'"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'confirm password'"
        [placeHolder]="'re-enter new password'"
        [(value)]="reEnterPassword"
        [backgroundColor]="lightGrayColor"
        [type]="'password'"
    ></app-menu-panel-field>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" [class.disabled]="!isSaveEnabled()" (click)="isSaveEnabled() && onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>