import esri = __esri;
import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { WorkAssetWrapper, WorkAssetWrappers, WorkOrderWrapper } from 'models/work-order';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { TwoIconsTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/two-icons-two-lines/two-icons-two-lines.component';
import AssetIcon from 'models/asset-icon.model';
import { Metric, WorkOrderSystem, ArcGISAssetChannelAttributes } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ContextMenuComponent } from 'app/ui-components/context-menu/context-menu.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { Feature } from 'sedaru-util/esri-core/feature';
import { AssetRecord } from 'models/records/asset-record.model';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { NoIconOneLineTemplateResolver } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/np-icon-one-line.resolver';
import { RecordContextGroup } from 'models/records/record-context-group';
import { StandardWorkOrder } from 'models/work-order/standard-work-order.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';

@Component({
	selector: 'app-workorder-asset-list',
	templateUrl: './workorder-asset-list.component.html',
	styleUrls: ['./workorder-asset-list.component.scss']
})
export class WorkorderAssetListComponent extends MenuPanelBaseComponent implements OnDestroy {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	workOrderMetricId: string;

	workAssetGraphics: esri.Graphic[] = [];

	workOrder: WorkOrderWrapper;

	assets: WorkAssetWrappers;

	addingAsset = false;

	metric: Metric;

	advancedMode: boolean;

	isReadOnly: boolean;

	isRecurrenceTemplate: boolean;

	readonly workOrderAssetTitle = 'work order assets';

	readonly addWorkOrderAssetTitle = 'add work order asset';

	private contextMenu: ContextMenuComponent;

	private contextMenuSubscription: Subscription;

	private resolvers = new Map<string, ListItemResolver>();

	resetWorkOrderIcons = true;

	public get pageIdentifier(): Pages {
		return Pages.workorderAssets;
	}

	get isMaximo() {
		return this.workOrderFactory.workOrderSystem === WorkOrderSystem.Maximo;
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	mode: string;
	constructor(
		private workOrderFactory: WorkOrderFactory,
		private graphicsService: OmniMapGraphicsService,
		private canvasMapService: CanvasMapService,
		private interop: OmniInteropService,
		private flashMessageService: FlashMessageService,
		view: ElementRef<HTMLElement>,
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.advancedMode = args.parameter.advancedMode;
		this.workOrder = args.parameter.workOrder;
		this.metric = args.parameter.metric;
		this.isRecurrenceTemplate = args.parameter.isRecurrenceTemplate;

		const paramAssets = args.parameter.workAssets;
		this.assets = paramAssets ? paramAssets : this.workOrder.workAssets;
		this.showWorkAssetOnMap();

		this.isReadOnly = this.workOrderFactory.isWorkOrderReadOnly(this.workOrder);

		this.menuPanelComponent.updateView({
			title: this.isRecurrenceTemplate ? 'recurrence template' : this.workOrder.workOrderKey,
			rightIcon: { url: 'assets/plus.png', callBackFunction: this.addAsset.bind(this), toolTip: 'add asset' },
			backgroundClass: 'orange-background',
			disableRightButton: this.isReadOnly || (this.isMaximo && this.assets.length > 0) || this.isRecurrenceTemplate
		});
	}

	onPageNavigatingFrom(navigationArgs: NavigationArgs): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (navigationArgs.parameter.mode !== 'add workasset') {
				const eventHandlers = this.config?.selectedCanvas?.map?.eventHandlers;
				if (!eventHandlers) return;
				if (eventHandlers['workAssetSelectionHandler']) {
					eventHandlers['workAssetSelectionHandler'].remove();
					delete eventHandlers['workAssetSelectionHandler'];
				}
				this.graphicsService.identifyTaskOnMapView(this.config.selectedCanvas);
			}
			resolve(true);
		});
	}

	ngOnInit(): void {
		this.listComponent.getResolver = (item: WorkAssetWrapper) => {
			if (this.resolvers.has(item.assetKey)) return this.resolvers.get(item.assetKey);
			return this.getItemResolver(item.assetKey);
		};
	}

	private getItemResolver(key: string): ListItemResolver {
		/** list resolver */
		const listResolver = TwoIconsTwoLinesComponent.createResolver();

		listResolver.getLeftIconPath = (item: WorkAssetWrapper) => AssetIcon.getAssetIconUrl(item.assetType);

		listResolver.getTopLabel = (item: WorkAssetWrapper) => item.assetKey;

		listResolver.getBottomLeftLabel = (item: WorkAssetWrapper) => item.assetType;

		listResolver.getBottomRightLabel = item => '';

		listResolver.getRightIconPath = item => 'assets/info.png';

		listResolver.isDisabled = item => {
			/** add logic to return true if the assets should be disabled */
			return false;
		};

		this.resolvers.set(key, listResolver);
		return listResolver;
	}

	ngOnDestroy() {
		if (this.resetWorkOrderIcons) this.hideWorkAssetOnMap();
	}

	showWorkAssetOnMap() {
		if (!this.metric) return;
		if (!this.config.selectedCanvas || !this.config.selectedCanvas.mapView) return;

		this.workOrderMetricId = this.metric.id;
		const workAssetGraphics = this.canvasMapService.getWorkAssetPointGraphic(this.workOrder.workAssets, false);
		this.workOrder.workAssetGraphics = workAssetGraphics;
		const workOrderGraphicLayer = this.canvasMapService.getLayerByLayerId(this.metric.id + '_work_asset', this.config.selectedCanvas.mapView.map);
		workOrderGraphicLayer.removeAll();
		workOrderGraphicLayer.addMany(workAssetGraphics);
		this.workAssetGraphics = [];
		if (!this.config.selectedCanvas.mapView) return;
		workOrderGraphicLayer.graphics.forEach(graphic => {
			// "isParent" equal true indicates that the graphic is a workorder icon, else its the graphic for the asset icon belows to the workOrder.
			const notParent = !graphic.attributes['isParent'];
			const matchingWorkOrder = graphic.attributes['workOrderKey'] === this.workOrder.workOrderKey;
			if (notParent && matchingWorkOrder) {
				graphic.visible = true;
				this.workAssetGraphics.push(graphic);
			} else {
				graphic.visible = false;
			}
		});
		const workOrderLayer = this.config.selectedCanvas.mapView.map.findLayerById(this.workOrderMetricId) as esri.GraphicsLayer;
		if (workOrderLayer) workOrderLayer.visible = false;
		this.config.selectedCanvas.mapView.goTo(this.workAssetGraphics, { animate: true, duration: 1 });
	}

	hideWorkAssetOnMap() {
		if (!this.config.selectedCanvas.mapView) return;
		const workAssetLayer = this.config.selectedCanvas.mapView.map.findLayerById(this.workOrderMetricId + '_work_asset') as esri.GraphicsLayer;
		if (workAssetLayer) this.config.selectedCanvas.mapView.map.remove(workAssetLayer);
		const workOrderLayer = this.config.selectedCanvas.mapView.map.findLayerById(this.workOrderMetricId) as esri.GraphicsLayer;
		if (workOrderLayer) workOrderLayer.visible = true;
	}

	assetSelected(workAsset: WorkAssetWrapper) {
		if (!this.config.selectedCanvas.mapView) return;
		const workAssetGraphicFound = this.workAssetGraphics.find(workAssetGraphic => workAssetGraphic.attributes['assetkey'] === workAsset.assetKey);
		if (!workAssetGraphicFound) return;
		this.config.selectedCanvas.mapView.goTo([workAssetGraphicFound.geometry['x'], workAssetGraphicFound.geometry['y']], { animate: true, duration: 1 });
	}

	/** when the user hits the (+) button in the header */
	addAsset = () => {
		if (this.isRecurrenceTemplate) return;

		if (!this.advancedMode) {
			this.alertDialog.mainMessage = { text: 'Feature coming soon!' };
			this.alertDialog.open = true;
			return;
		}

		if (!this.config.selectedCanvas || !this.config.selectedCanvas.map) return;
		this.menuPanelComponent.updateView({ backgroundClass: 'orange-background' });
		this.resetWorkOrderIcons = false;
		if (this.config.selectedCanvas.map.eventHandlers['identifyTaskHandler']) {
			this.config.selectedCanvas.map.eventHandlers['identifyTaskHandler'].remove();
			delete this.config.selectedCanvas.map.eventHandlers['identifyTaskHandler'];
		}

		this.addingAsset = true;
		this.workOrderFactory.currentWorkOrder = this.workOrder;
		this.graphicsService.startAssetSelectionForWorkAsset(this.config.selectedCanvas, this.metric, this.workOrder);
	};

	/** when the user clicks the right side icon */
	async goTo(workAsset: WorkAssetWrapper) {
		const assetDefinition = this.interop.configurationManager.customerCodeConfiguration.getAssetDefinition(workAsset.assetType);
		if (assetDefinition && assetDefinition.useHierarchy) {
			const assetLocation = this.workOrderFactory.workOrderMetaData.assetLocationsWithAssets.getAssetLocationByActiveAsset(workAsset.assetKey);
			const asset = await this.workOrderFactory.getAsset(null, assetLocation.activeAsset);
			return NavigationService.navigateTo(Pages.assetInformation, {
				recordContext: new RecordContextGroup({ hierarchyRecord: new HierarchyAssetRecord(asset, assetLocation, assetDefinition), isHierarchyRecord: true }),
				isWorkAssetAdd: this.mode === 'add workasset',
				workOrder: this.workOrder ? this.workOrder : this.workOrderFactory.currentWorkOrder
			});
		}
		if (assetDefinition.hierarchyEnabled) {
			this.flashMessageService.popMessage('Hierarchy not yet implemented');
			return;
		}

		const asstAttributes = assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
		let assetKey: string;
		if (this.workOrderFactory.workOrderSystem === WorkOrderSystem.Maximo) assetKey = this.workOrder.location;
		else assetKey = workAsset.assetKey;

		if (!assetKey) assetKey = workAsset.assetId?.toString();
		if (!assetKey) return;

		const layer = this.interop.arcGISManager.getAssetLayer(workAsset.assetType);
		if (!layer) return;

		const result = await layer.query(`${asstAttributes.uniqueFieldName} = '${assetKey}'`);
		if (result.length > 0) {
			const geometry = result[0].geometry;
			if (geometry.paths) {
				const centerOfPolyLine = Math.round(geometry.paths[0].length / 2);
				geometry.x = geometry.paths[0][centerOfPolyLine][0];
				geometry.y = geometry.paths[0][centerOfPolyLine][1];
			}
			const assetFeature = Feature.fromEsriFeature(result[0]);
			const assetRecord = AssetRecord.create(assetDefinition, assetFeature);
			NavigationService.navigateTo(Pages.assetInformation, { recordContext: new RecordContextGroup({ assetRecord: assetRecord }) } );
		}
	}

	showContextMenu(params: { event: MouseEvent; item: WorkAssetWrapper; element: HTMLElement }) {
		if (this.isRecurrenceTemplate) return;

		params.event.preventDefault();
		params.event.stopPropagation();

		// show context centered to the element clicked
		const x = params.element.getBoundingClientRect().x + params.element.getBoundingClientRect().width / 2 - 80;
		/** context menu */
		const contextMenuResolver = new NoIconOneLineTemplateResolver();
		contextMenuResolver.getLabel = item => {
			return item.label;
		};
		contextMenuResolver.onGetAlignment = item => {
			return 'center';
		};

		this.contextMenu = this.interop.uiManager.contextMenu;
		this.contextMenu.menuItems = [{ label: 'remove asset', value: 'remove' }];
		this.contextMenuSubscription = this.contextMenu.itemSelected.subscribe(option => {
			if (option.value === 'remove') this.removeAsset(params.item);
			this.contextMenu.close();
		});
		this.contextMenu.leftPosition = x;
		this.contextMenu.topPosition = params.event.clientY;
		this.contextMenu.getResolverForItem = item => contextMenuResolver;
		this.contextMenu.show();
	}

	removeAsset(workAsset: WorkAssetWrapper) {
		if (this.isRecurrenceTemplate) return;

		// TODO @Will: 16054 - Remove SWO check
		if (this.workOrder instanceof StandardWorkOrder) return;

		this.contextMenuSubscription.unsubscribe();
		this.workOrderFactory.deleteWorkAsset(this.workOrder, workAsset).then(() => {
			this.listComponent.omniListItems = this.workOrder.workAssets;
			this.assets = this.workOrder.workAssets;
			this.ngOnInit();
			this.menuPanelComponent.disableRightButton = this.isReadOnly || (this.isMaximo && this.assets.length > 0)
		});

	}
}
