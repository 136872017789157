<div class="list-wrapper">
	<app-collapsible-list
		*ngIf="assetGroups?.length"
		(scroll)="onScroll($event)"
		(itemSelected)="selectAssetRecord($event)"
		[groups]="assetGroups"
		[getResolver]="getItemResolver.bind(context)"
		[maxRecords]="10"
	></app-collapsible-list>
	<div *ngIf="this.mode === 'search-results' && !assetGroups?.length" class="omni-search-noresult">
		<img src="assets/search-noresults.png" />
		<label>{{ searchMessage }}</label>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start"> </mat-card-actions>
</mat-card-footer>
