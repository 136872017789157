import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ListTemplates } from 'app/ui-components/omni-list/list-item/templates/templates.enum';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { SearchController } from 'sedaru-util/generic-search/search-controller';
import { Material, Equipment, Employee, LEMType } from 'models/work-order';
import { LEMService } from '../workorder-lem-list/lem.service';
import { SearchListComponentComponent } from 'app/ui-components/search-list-component/search-list-component.component';

@Component({
	selector: 'app-workorder-new-lem',
	templateUrl: './workorder-new-lem.component.html',
	styleUrls: ['./workorder-new-lem.component.scss']
})
export class WorkorderNewLemComponent implements OnInit {
	@Input() lemMenuPanelHeader: string;
	@Input() searchFieldHeader: string;
	@Input() field1Header: string;
	@Input() field1Value: string;
	@Input() field2Header: string;
	@Input() field2Value: number;
	@Input() taskEnabled: boolean;
	@Input() lemList: any;
	@Input() itemKey: string;
	@Input() taskName: string;
	@Input() onSave: () => any;
	@Input() onDelete: () => any;
	@Input() goToTask: () => any;
	@Output() itemSelected = new EventEmitter();
	@Output() field2ValueChanged = new EventEmitter();
	@ViewChild(SearchListComponentComponent, { static: true }) searchList: SearchListComponentComponent;

	taskDisplayName: string;
	testController: SearchController<any>;
	listType = ListTemplates.noIconOneLine;
	private resolvers = new Map();

	constructor(private lemService: LEMService) {}

	ngOnInit() {
		this.testController = this.createSearchFunction();
		this.searchList.getListItemResolver = item => {
			if (this.resolvers.has(item[this.itemKey])) return this.resolvers.get(item[this.itemKey]);
			return this.getItemResolver(item);
		};
	}

	private getItemResolver(item) {
		const listResolver = NoIconOneLineComponent.createResolver();
		listResolver.getLabel = i => {
			return i[this.itemKey];
		};
		this.resolvers.set(item[this.itemKey], listResolver);
		return listResolver;
	}

	createSearchFunction() {
		if (this.lemService.lemType === LEMType.labor) return new SearchController<Employee>(this.lemList, this.castSearchFunction<Employee>());
		if (this.lemService.lemType === LEMType.equipment) return new SearchController<Equipment>(this.lemList, this.castSearchFunction<Equipment>());
		if (this.lemService.lemType === LEMType.material) return new SearchController<Material>(this.lemList, this.castSearchFunction<Material>());
	}

	castSearchFunction<T>() {
		const searchFunction = (c: T, text: string) => {
			if (
				c['description']
					.toString()
					.toLowerCase()
					.includes(text)
			) {
				return true;
			}
			return false;
		};
		return searchFunction;
	}

	valueChanged($event) {
		this.field2ValueChanged.emit($event);
	}
}
