import { TimeframeDefinition } from './timeframe-definition.model';
import { TimeframeDefinitionType } from './timeframe-definition-type.enum';
import { TimeframeUnit } from './timeframe-unit.enum';
import { UserTimeframeOptionType } from './user-timeframe-option.enum';
import { TimeframeRangeType } from './timeframe-range-type.enum';
import { TimeframeType } from './timeframe-type.enum';

export class UserTimeframeOption {
    get type(): UserTimeframeOptionType {
        return this._type;
    }
    get text(): string {
        return this._text;
    }

    get timeframeType(): TimeframeType {
        return this._timeframeType;
    }

    constructor(private _type: UserTimeframeOptionType, private _text: string, private _timeframeType?: TimeframeType) {

    }

    private static _options: UserTimeframeOption[];
    public static get userOptions(): UserTimeframeOption[] {
        if (!UserTimeframeOption._options) {
            UserTimeframeOption._options = [];

            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.AllDates, 'all time', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.Today, 'all of today', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.Yesterday, 'yesterday', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.Tomorrow, 'tomorrow', TimeframeType.Simple));

            // week related selections
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.LastWeek, 'last week', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.ThisWeek, 'this entire week', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.NextWeek, 'next week', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.WeekToDate, 'week to date', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.RestOfWeek, 'rest of this week', TimeframeType.Simple));

            // month related selections
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.LastMonth, 'last month', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.ThisMonth, 'this entire month', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.NextMonth, 'next month', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.MonthToDate, 'month to date', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.RestOfMonth, 'rest of this month', TimeframeType.Simple));

            // quarter related selections
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.LastQuarter, 'last quarter', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.ThisQuarter, 'this entire quarter', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.NextQuarter, 'next quarter', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.QuarterToDate, 'quarter to date', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.RestOfQuarter, 'rest of this quarter', TimeframeType.Simple));

            // year related selections
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.ThisYear, 'this entire year', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.YearToDate, 'year to date', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.RestOfYear, 'rest of this year', TimeframeType.Simple));


            // today related selections
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.MorningToNow, 'today to now', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.RestOfDay, 'rest of today', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.TodayAndEarlier, 'today & earlier', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.TodayAndLater, 'today & later', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.EarlierThanToday, 'earlier than today', TimeframeType.Simple));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.LaterThanToday, 'later than today', TimeframeType.Simple));

            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.DateRange, 'date range', TimeframeType.Range));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.BeforeDate, 'before date', TimeframeType.SingleDate));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.AfterDate, 'after date', TimeframeType.SingleDate));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.ExactDate, 'specific date', TimeframeType.SingleDate));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.Last, 'last X period', TimeframeType.PeriodInterval));
            UserTimeframeOption._options.push(new UserTimeframeOption(UserTimeframeOptionType.Next, 'next X period', TimeframeType.PeriodInterval));

        }

        return UserTimeframeOption._options;
    }

    static getTimeFrameDefinition(userOption: UserTimeframeOptionType, timeFrameUnit?: TimeframeUnit, interval?: number, inclusive?: boolean, date?: Date): TimeframeDefinition {

        if (userOption === UserTimeframeOptionType.Today) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Days);
        } else if (userOption === UserTimeframeOptionType.Yesterday) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Days, 1, false);
        } else if (userOption === UserTimeframeOptionType.Tomorrow) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Days, 1, false);
        } else if (userOption === UserTimeframeOptionType.Last7Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Days, 7);
        } else if (userOption === UserTimeframeOptionType.Next7Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Days, 7);
        } else if (userOption === UserTimeframeOptionType.LastWeek) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Weeks, 1, false);
        } else if (userOption === UserTimeframeOptionType.ThisWeek) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Weeks);
        } else if (userOption === UserTimeframeOptionType.NextWeek) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Weeks, 1, false);
        } else if (userOption === UserTimeframeOptionType.WeekToDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Weeks);
        } else if (userOption === UserTimeframeOptionType.RestOfWeek) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Weeks);
        } else if (userOption === UserTimeframeOptionType.LastMonth) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Months, 1, false);
        } else if (userOption === UserTimeframeOptionType.ThisMonth) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Months);
        } else if (userOption === UserTimeframeOptionType.NextMonth) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Months, 1, false);
        } else if (userOption === UserTimeframeOptionType.MonthToDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Months);
        } else if (userOption === UserTimeframeOptionType.RestOfMonth) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Months);
        } else if (userOption === UserTimeframeOptionType.LastQuarter) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Quarters, 1, false);
        } else if (userOption === UserTimeframeOptionType.ThisQuarter) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Quarters);
        } else if (userOption === UserTimeframeOptionType.NextQuarter) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Quarters, 1, false);
        } else if (userOption === UserTimeframeOptionType.QuarterToDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Quarters);
        } else if (userOption === UserTimeframeOptionType.RestOfQuarter) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Quarters);
        } else if (userOption === UserTimeframeOptionType.LastYear) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Years, 1, false);
        } else if (userOption === UserTimeframeOptionType.ThisYear) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Years);
        } else if (userOption === UserTimeframeOptionType.NextYear) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Years, 1, false);
        } else if (userOption === UserTimeframeOptionType.YearToDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Years);
        } else if (userOption === UserTimeframeOptionType.RestOfYear) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Years);
        } else if (userOption === UserTimeframeOptionType.LastHour) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Hours, 1, false);
        } else if (userOption === UserTimeframeOptionType.ThisHour) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, TimeframeUnit.Hours);
        } else if (userOption === UserTimeframeOptionType.NextHour) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Hours, 1, false);
        } else if (userOption === UserTimeframeOptionType.HourToNow) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Hours);
        } else if (userOption === UserTimeframeOptionType.RestOfHour) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Hours);
        } else if (userOption === UserTimeframeOptionType.MorningToNow) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, TimeframeUnit.Days);
        } else if (userOption === UserTimeframeOptionType.RestOfDay) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, TimeframeUnit.Days);
        } else if (userOption === UserTimeframeOptionType.Last12Hours) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Hours, 12, false);
        } else if (userOption === UserTimeframeOptionType.Next12Hours) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Hours, 12, false);
        } else if (userOption === UserTimeframeOptionType.Last24Hours) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Hours, 24, false);
        } else if (userOption === UserTimeframeOptionType.Next24Hours) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Hours, 24, false);
        } else if (userOption === UserTimeframeOptionType.Last30Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Days, 30);
        } else if (userOption === UserTimeframeOptionType.Next30Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Days, 30);
        } else if (userOption === UserTimeframeOptionType.Last90Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Days, 90);
        } else if (userOption === UserTimeframeOptionType.Next90Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Days, 90);
        } else if (userOption === UserTimeframeOptionType.Last365Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Days, 365);
        } else if (userOption === UserTimeframeOptionType.Next365Days) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Days, 365);
        } else if (userOption === UserTimeframeOptionType.Last2Years) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Years, 2, false);
        } else if (userOption === UserTimeframeOptionType.Next2Years) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Years, 2, false);
        } else if (userOption === UserTimeframeOptionType.Last3Years) {
            return new TimeframeDefinition(TimeframeDefinitionType.LastX, TimeframeUnit.Years, 3, false);
        } else if (userOption === UserTimeframeOptionType.Next3Years) {
            return new TimeframeDefinition(TimeframeDefinitionType.NextX, TimeframeUnit.Years, 3, false);
        } else if (userOption === UserTimeframeOptionType.Last) {
            if (!timeFrameUnit) return undefined;

            if (!interval || interval === 0) return undefined;

            return new TimeframeDefinition(TimeframeDefinitionType.LastX, timeFrameUnit, interval, inclusive);
        } else if (userOption === UserTimeframeOptionType.Next) {
            if (!timeFrameUnit) return undefined;

            if (!interval || interval === 0) return undefined;

            return new TimeframeDefinition(TimeframeDefinitionType.NextX, timeFrameUnit, interval, inclusive);
        } else if (userOption === UserTimeframeOptionType.EarlierThanToday) {
            return new TimeframeDefinition(TimeframeDefinitionType.EarlierThanToday, TimeframeUnit.Days, undefined);
        } else if (userOption === UserTimeframeOptionType.TodayAndEarlier) {
            return new TimeframeDefinition(TimeframeDefinitionType.TodayAndEarlier, TimeframeUnit.Days, undefined, true);
        } else if (userOption === UserTimeframeOptionType.TodayAndLater) {
            return new TimeframeDefinition(TimeframeDefinitionType.LaterThanToday, TimeframeUnit.Days, undefined, true);
        } else if (userOption === UserTimeframeOptionType.LaterThanToday) {
            return new TimeframeDefinition(TimeframeDefinitionType.LaterThanToday, TimeframeUnit.Days, undefined, false);
        } else if (userOption === UserTimeframeOptionType.BeforeDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.BeforeDate, timeFrameUnit, undefined, inclusive, date);
        } else if (userOption === UserTimeframeOptionType.ExactDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.ExactDate, timeFrameUnit, undefined, undefined, date);
        } else if (userOption === UserTimeframeOptionType.AfterDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.AfterDate, timeFrameUnit, undefined, inclusive, date);
        } else if (userOption === UserTimeframeOptionType.DateRange) {
            return new TimeframeDefinition(TimeframeDefinitionType.DateRange, timeFrameUnit, interval, inclusive, date);
        } else if (userOption === UserTimeframeOptionType.AllDates) {
            return new TimeframeDefinition(TimeframeDefinitionType.Boundless, undefined, undefined, undefined, undefined, TimeframeRangeType.All);
        } else if (userOption === UserTimeframeOptionType.NoDate) {
            return new TimeframeDefinition(TimeframeDefinitionType.Boundless, undefined, undefined, undefined, undefined, TimeframeRangeType.NoDate);
        } else if (userOption === UserTimeframeOptionType.ThisX) {
            return new TimeframeDefinition(TimeframeDefinitionType.This, timeFrameUnit);
        } else if (userOption === UserTimeframeOptionType.RestOfX) {
            return new TimeframeDefinition(TimeframeDefinitionType.RestOfX, timeFrameUnit);
        } else if (userOption === UserTimeframeOptionType.XToNow) {
            return new TimeframeDefinition(TimeframeDefinitionType.XToNow, timeFrameUnit);
        }

        return undefined;
    }

    static getFixedTimeFrameDefinition(userOption: UserTimeframeOptionType, startDate: Date, endDate?: Date): TimeframeDefinition {

        const validFixedTypes: UserTimeframeOptionType[] = [
            UserTimeframeOptionType.ExactDate,
            UserTimeframeOptionType.BeforeDate,
            UserTimeframeOptionType.AfterDate,
            UserTimeframeOptionType.DateRange
        ];

        if (!validFixedTypes.includes(userOption)) return undefined;

        if (!endDate) endDate = new Date(startDate);

        if (startDate.getTime() > endDate.getTime()) {
            const switchDate = new Date(startDate);
            startDate = endDate;
            endDate = switchDate;
        }

        startDate = new Date(startDate.toDateString());
        endDate = new Date(endDate.toDateString());

        let interval = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24; // We get all the difference in days.
        interval += 1; // Fixed timeframe is always inclusive
        return UserTimeframeOption.getTimeFrameDefinition(userOption, TimeframeUnit.Days, interval, true, startDate);
    }

    static getUserOptionTimeframe(userOptionType: UserTimeframeOptionType) {
        switch (userOptionType) {
            case UserTimeframeOptionType.AllDates:
                return {text: 'all time', type: TimeframeType.Simple};
            case UserTimeframeOptionType.Today:
                return {text: 'all of today', type: TimeframeType.Simple};
            case UserTimeframeOptionType.Yesterday:
                return {text: 'yesterday', type: TimeframeType.Simple};
            case UserTimeframeOptionType.Tomorrow:
                return {text: 'tomorrow', type: TimeframeType.Simple};
            case UserTimeframeOptionType.LastWeek:
                return {text: 'last week', type: TimeframeType.Simple};
            case UserTimeframeOptionType.ThisWeek:
                return {text: 'this entire week', type: TimeframeType.Simple};
            case UserTimeframeOptionType.NextWeek:
                return {text: 'next week', type: TimeframeType.Simple};
            case UserTimeframeOptionType.WeekToDate:
                return {text: 'week to date', type: TimeframeType.Simple};
            case UserTimeframeOptionType.RestOfWeek:
                return {text: 'rest of this week', type: TimeframeType.Simple};
             case UserTimeframeOptionType.LastMonth:
                return {text: 'last month', type: TimeframeType.Simple};
            case UserTimeframeOptionType.ThisMonth:
                return {text: 'this entire month', type: TimeframeType.Simple};
            case UserTimeframeOptionType.NextMonth:
                return {text: 'next month', type: TimeframeType.Simple};
            case UserTimeframeOptionType.MonthToDate:
                return {text: 'month to date', type: TimeframeType.Simple};
            case UserTimeframeOptionType.RestOfMonth:
                return {text: 'rest of this month', type: TimeframeType.Simple};
            case UserTimeframeOptionType.LastQuarter:
                return {text: 'last quarter', type: TimeframeType.Simple};
            case UserTimeframeOptionType.ThisQuarter:
                return {text: 'this entire quarter', type: TimeframeType.Simple};
            case UserTimeframeOptionType.NextQuarter:
                return {text: 'next quarter', type: TimeframeType.Simple};
            case UserTimeframeOptionType.QuarterToDate:
                return {text: 'quarter to date', type: TimeframeType.Simple};
            case UserTimeframeOptionType.RestOfQuarter:
                return {text: 'rest of this quarter', type: TimeframeType.Simple};
            case UserTimeframeOptionType.ThisYear:
                return {text: 'this entire year', type: TimeframeType.Simple};
            case UserTimeframeOptionType.YearToDate:
                return {text: 'year to date', type: TimeframeType.Simple};
            case UserTimeframeOptionType.RestOfYear:
                return {text: 'rest of this year', type: TimeframeType.Simple};
            case UserTimeframeOptionType.MorningToNow:
                return {text: 'today to now', type: TimeframeType.Simple};
            case UserTimeframeOptionType.RestOfDay:
                return {text: 'rest of today', type: TimeframeType.Simple};
            case UserTimeframeOptionType.TodayAndEarlier:
                return {text: 'today & earlier', type: TimeframeType.Simple};
            case UserTimeframeOptionType.TodayAndLater:
                return {text: 'today & later', type: TimeframeType.Simple};
            case UserTimeframeOptionType.EarlierThanToday:
                return {text: 'earlier than today', type: TimeframeType.Simple};
            case UserTimeframeOptionType.LaterThanToday:
                return {text: 'later than today', type: TimeframeType.Simple};
            case UserTimeframeOptionType.DateRange:
                return {text: 'date range', type: TimeframeType.Range};
            case UserTimeframeOptionType.BeforeDate:
                return {text: 'before date', type: TimeframeType.SingleDate};
            case UserTimeframeOptionType.AfterDate:
                return {text: 'after date', type: TimeframeType.SingleDate};
            case UserTimeframeOptionType.ExactDate:
                return {text: 'specific date', type: TimeframeType.SingleDate};
            case UserTimeframeOptionType.Last:
                return {text: 'last X period', type: TimeframeType.PeriodInterval};
            case UserTimeframeOptionType.Next:
                return {text: 'next X period', type: TimeframeType.PeriodInterval};
            default:
                return {text: '-', type: TimeframeType.Simple};
        }
    }
}
