import { DataChannelService } from './../../../domain-service/data-channel.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { LeftIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import * as moment from 'moment';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Metric, WorkOrderSystem } from 'models';
import { TaskWrapper, TaskWrappers, WorkOrderWrapper } from 'models/work-order';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { LeftIconTwoLinesItemResolver } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.resolver';

@Component({
	selector: 'app-workorder-task-list',
	templateUrl: './workorder-task-list.component.html',
	styleUrls: ['./workorder-task-list.component.scss']
})
export class WorkorderTaskListComponent extends MenuPanelBaseComponent implements OnInit {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	tasks: TaskWrappers;

	currentWorkOrder: WorkOrderWrapper;

	advancedMode: boolean;

	metric: Metric;

	scrollingPosition = 0;

	private resolvers = new Map();

	isReadOnly = false;

	constructor(
		private workOrderFactory: WorkOrderFactory,
		private dataChannelService: DataChannelService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	private getItemResolver(item) {
		const completedCodes = this.dataChannelService
								    .getCompletedWorkOrderValues()
									.join(',')
									.toLocaleLowerCase();
		const itemResolver = LeftIconTwoLinesComponent.createResolver();
		itemResolver.getIconPath = workTask => {
			return new Promise(resolve => {
				if (completedCodes.includes(workTask.status.toLocaleLowerCase())) {
					resolve('assets/task-completed.png');
				} else {
					resolve('assets/task-to-do.png');
				}
			});
		};
		itemResolver.getTopLabel = (workTask: TaskWrapper) => {
			if (workTask.description) return workTask.description;
			const taskFound = this.workOrderFactory.workOrderMetaData.tasks.find(task => task.taskId === workTask.taskKey);

			if (!taskFound || !taskFound.description) {
				return 'unknown task type';
			}

			return taskFound.description;
		};
		itemResolver.getBottomLeftLabel = (workTask: TaskWrapper) => {
			if (!workTask.assignedTo) {
				return 'unassigned';
			}

			const employeeFound = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(workTask.assignedTo);

			if (!employeeFound) {
				return workTask.assignedTo;
			}
			return employeeFound.name;
		};
		itemResolver.getBottomRightLabel = (workTask: TaskWrapper) => (workTask.completedDate ? moment(DateUtil.convertLocaleString(workTask.completedDate)).format('MM/DD/YYYY') : '');

		this.resolvers.set(item.id, itemResolver);
		return itemResolver;
	}

	onPageReload() {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
	}

	async onPageNavigatedTo(args: NavigationArgs) {
		if (!args && !args.parameter) return;

		this.currentWorkOrder = args.parameter.workOrder;
		this.advancedMode = args.parameter.advancedMode;
		this.metric = args.parameter.metric;
		this.isReadOnly = this.workOrderFactory.isWorkOrderReadOnly(this.currentWorkOrder);

		this.menuPanelComponent.updateView({
			title: this.currentWorkOrder.workOrderKey,
			rightIcon: { url: 'assets/plus.png', toolTip: 'add a task', callBackFunction: this.addTask },
			backgroundClass: 'orange-background',
			disableRightButton: this.isReadOnly
		});

		this.tasks = this.currentWorkOrder.workTasks;
		this.listComponent.getResolver = item => {
			if (this.resolvers.has(item.id)) return this.resolvers.get(item.id);
			return this.getItemResolver(item);
		};

		if (this.uiState && this.uiState.scrollPosition) this.scrollingPosition = this.uiState.scrollPosition;
	}

	onScroll(scrollPosition: number) {
		if (!this.uiState) this.uiState = { scrollPosition: 0 };
		this.uiState.scrollPosition = scrollPosition;
	}

	/** when the user clicks the (+) button in the header */
	addTask = () => {
		if (this.isReadOnly) return;

		const workTask = this.workOrderFactory.createWorkTaskModel(this.currentWorkOrder);
		NavigationService.navigateTo(Pages.workorderNewTask, { workOrder: this.currentWorkOrder, workTask });
	};

	/** when a user clicks the work task icon */
	async checkMarkTask(workTask: TaskWrapper) {
		if (this.isReadOnly) return;

		if (this.workOrderFactory.workOrderSystem === WorkOrderSystem.Maximo) {
			this.goTo(workTask);
			return;
		}

		const completedCodes = this.dataChannelService.getCompletedWorkOrderValues();
		if (completedCodes.includes(workTask.status)) {
			workTask.status = 'Open';
			workTask.completedDate = '';
		} else {
			workTask.status = completedCodes[0];
			workTask.completedDate = DateUtil.getTimeZoneAgnosticString(new Date());
		}

		await this.workOrderFactory.updateWorkTask(this.currentWorkOrder, workTask);
		const resolver: LeftIconTwoLinesItemResolver = <LeftIconTwoLinesItemResolver>this.resolvers.get(workTask.id);
		resolver.resetFlags();
	}

	goTo(workTask: TaskWrapper) {
		NavigationService.navigateTo(Pages.workorderTask, { metric: this.metric, workOrder: this.currentWorkOrder, advancedMode: this.advancedMode, workTask });
	}
}
