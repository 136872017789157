import { AssetDefinition } from './asset-definition.model';

/**
 * This defines the asset definition list
 */
export class AssetDefinitions extends Array<AssetDefinition> {
	getById = (id: number): AssetDefinition => {
		for (const assetDefinition of this) {
			if (assetDefinition.legacyId !== id) {
				continue;
			}
			return assetDefinition;
		}
	};

	/**
	 * This function returns the asset details.
	 * @param {string} assetType - asset type parameter.
	 */
	getByAssetType = (assetType: string): AssetDefinition => {
		if (!assetType) {
			return null;
		}

		for (const assetDefinition of this) {
			if (!assetDefinition.assetType || assetDefinition.assetType.toLowerCase() !== assetType.toLowerCase()) {
				continue;
			}
			return assetDefinition;
		}

		return null;
	};
}
