import { Material } from './material.model';
import { MaterialContract } from '../../contracts/work-order';

export class Materials extends Array<Material> {
	static fromContracts(materialContracts: MaterialContract[]) {
		const materials = new Materials();
		if (!materialContracts) return materials;

		for (const materialContract of materialContracts) {
			const material = Material.fromContract(materialContract);
			materials.push(material);
		}
		return materials;
	}
	getById = (id: string) => this.find(material => material.id === id);

	getByMaterialId = (materialId: string) => this.find(material => parseInt(material.materialId) === parseInt(materialId));
}
