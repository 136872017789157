import { MenuPanelNavigationEntry } from './menu-panel-navigation-entry';
import { NavigationButtonEntry } from './navigation-button-entry';

enum BackgroundClasses {
	grey = 'card-title-gray',
}

export class NavigationBarEntry {
	constructor(private menuPanelNavigationEntry: MenuPanelNavigationEntry) {}

	private _type: string;
	public get type(): string {
		return this._type;
	}
	public set type(value: string) {
		this._type = value;
	}

	private _title: string;
	public get title(): string {
		return this._title;
	}
	public set title(value: string) {
		this._title = value;
	}

	private _description: string;
	public get description(): string {
		return this._description;
	}
	public set description(value: string) {
		this._description = value;
	}

	private _isVisible: boolean;
	public get isVisible(): boolean {
		return this._isVisible;
	}
	public set isVisible(value: boolean) {
		this._isVisible = value;
	}

	public get backgroundClasses() {
		return BackgroundClasses;
	}

	private _backgroundColor: any;
	public get backgroundColor() {
		return this._backgroundColor;
	}
	public set backgroundColor(color: any) {
		this._backgroundColor = color;
	}

	private _foregroundColor: any;
	public get foregroundColor() {
		return this._foregroundColor;
	}
	public set foregroundColor(color: any) {
		this._foregroundColor = color;
	}

	private _selectedAction: () => void;
	public get selectedAction() {
		return this._selectedAction;
	}
	public set selectedAction(value: () => void) {
		this._selectedAction = value;
	}

	private _cancelBusyAction: () => void;
	public get cancelBusyAction() {
		return this._cancelBusyAction;
	}
	public set cancelBusyAction(value: () => void) {
		this._cancelBusyAction = value;
	}

	private _isBusy = false;
	public get isBusy(): boolean {
		return this._isBusy;
	}
	public set isBusy(value: boolean) {
		this._isBusy = value;
	}

	private _busyMessage: string;
	public get busyMessage(): string {
		return this._busyMessage;
	}
	public set busyMessage(value: string) {
		this._busyMessage = value;
	}

	private _leftButton: NavigationButtonEntry;
	public get leftButton(): NavigationButtonEntry {
		if (!this._leftButton) this._leftButton = new NavigationButtonEntry(this.menuPanelNavigationEntry);
		return this._leftButton;
	}

	private _rightButton: NavigationButtonEntry;
	public get rightButton(): NavigationButtonEntry {
		if (!this._rightButton) this._rightButton = new NavigationButtonEntry(this.menuPanelNavigationEntry);
		return this._rightButton;
	}
}
