export enum ResponseServiceErrorCode {
	None = 0,
	Success = 1,
	InvalidUrl = 2,
	InvalidResponse = 3,
	CannotReachServer = 4,
	ArcGISError = 5,
	HttpError = 6,
	Timeout = 7,
	CustomError = 8,
	Unknown = 9
}

export class RestServiceResponseError {
	code: number;
	message: string;
	private _details: Array<string>;
	get details(): Array<string> {
		if (!this._details) this._details = new Array<string>();
		return this._details;
	}

	static fromJson(errorData: any): RestServiceResponseError {
		if (!errorData) return null;

		const error = new RestServiceResponseError();
		error.code = errorData.code;
		error.message = errorData.message;

		if (errorData.details) {
			if (errorData.details.length) {
				error._details = errorData.details;
			} else {
				error.details.push(errorData.details);
			}
		}

		return error;
	}
}

export class RestServiceResponse {
	get success(): boolean {
		return !this.serviceException && !this.arcGISError && this.errorCode === ResponseServiceErrorCode.Success;
	}
	get isTokenExpired(): boolean {
		if (this.errorCode !== ResponseServiceErrorCode.ArcGISError) return false;

		if (!this.arcGISError) return false;

		if (this.arcGISError.code === 498) return true;

		return false;
	}
	responseString: string;
	responseStream: any;
	responseJsonToken: any;
	httpCode: number;
	errorCode = ResponseServiceErrorCode.None;
	arcGISError: RestServiceResponseError;
	serviceException: Error;
	customError: string;

	getResponseJson() {
		return JSON.stringify(this.responseJsonToken);
	}
}
