import { FormControlDefinition, ControlType, FormControlDefinitions } from 'models';
class FormGridPatternRow {
	controls: Array<FormControlDefinition>;
	get isFull(): boolean {
		if (this.controls.length > 1) return true;
		if (this.controls[0].controlType === ControlType.Control1x2) return true;
		return false;
	}
	constructor(control: FormControlDefinition) {
		this.controls = [];
		this.controls.push(control);
	}
}

interface FormGridPatternIndex {
	rowNumber: number;
	controlIndex: number;
	columnNumber: number;
}

export class FormGridPattern {
	rows: Array<FormGridPatternRow>;
	private searchIndex: Array<FormGridPatternIndex>;
	constructor(controls: FormControlDefinitions) {
		this.rows = [];
		this.searchIndex = [];
		let rowIndex = 0;
		for (let i = 0; i < controls.length; i++) {
			if (!this.rows[rowIndex]) {
				this.rows[rowIndex] = new FormGridPatternRow(controls[i]);
				this.searchIndex.push({ rowNumber: rowIndex, controlIndex: controls[i].index, columnNumber: this.rows[rowIndex].controls.length - 1 });
				continue;
			}
			if (!this.rows[rowIndex].isFull && controls[i].controlType === ControlType.Control1x1) {
				this.rows[rowIndex].controls.push(controls[i]);
				this.searchIndex.push({ rowNumber: rowIndex, controlIndex: controls[i].index, columnNumber: this.rows[rowIndex].controls.length - 1 });
				continue;
			}

			rowIndex++;
			this.rows[rowIndex] = new FormGridPatternRow(controls[i]);
			this.searchIndex.push({ rowNumber: rowIndex, controlIndex: controls[i].index, columnNumber: this.rows[rowIndex].controls.length - 1 });
		}
	}

	getControlCoordinates(control: FormControlDefinition): { rowNumber: number; colNumber: number } {
		const index = this.searchIndex.find(i => i.controlIndex === control.index);
		return { rowNumber: index.rowNumber, colNumber: index.columnNumber };
	}
}
