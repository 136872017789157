import { CustomFieldContract, StandardCustomFieldContract } from '../../contracts/work-order';
import { CustomField } from './custom-field.model';
import { StandardCustomField, SummaryFieldInputType } from './standard-custom-field.model';

export class CustomFieldWrapper {
	private _customField: CustomField | StandardCustomField;
	get customField(): CustomField | StandardCustomField {
		return this._customField;
	}

	constructor(customField: CustomField | StandardCustomField) {
		this._customField = customField;
	}

	get id(): string {
		return this._customField.id;
	}
	set id(value: string) {
		this._customField.id = value;
	}

	get customFieldId(): string {
		if (this._customField instanceof StandardCustomField) return '';
		return this._customField.customFieldId;
	}
	set customFieldId(value: string) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.customFieldId = value;
	}

	get customFieldValue(): string {
		return this._customField.customFieldValue;
	}
	set customFieldValue(value: string) {
		this._customField.customFieldValue = value;
	}

	get workOrderKey(): string {
		if (this._customField instanceof StandardCustomField) return '';
		return this._customField.workOrderKey;
	}
	set workOrderKey(value: string) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.workOrderKey = value;
	}

	get customFieldType(): string {
		if (this._customField instanceof StandardCustomField) return '';
		return this._customField.customFieldType;
	}
	set customFieldType(value: string) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.customFieldType = value;
	}

	get customFieldName(): string {
		return this._customField.customFieldName;
	}
	set customFieldName(value: string) {
		this._customField.customFieldName = value;
	}

	get fieldDescription(): string {
		if (this._customField instanceof StandardCustomField) return this._customField.customFieldDescription;
		return '';
	}
	set fieldDescription(value: string) {
		if (this._customField instanceof StandardCustomField) {
			this._customField.customFieldDescription = value;
		}
	}

	get inputType(): SummaryFieldInputType | undefined {
		if (this._customField instanceof StandardCustomField) {
			return this._customField.inputType;
		}
	}
	set inputType(value: SummaryFieldInputType) {
		if (this._customField instanceof StandardCustomField) {
			this._customField.inputType = value;
		}
	}

	get isVisible(): boolean {
		if (this._customField instanceof StandardCustomField) {
			return this._customField.isVisible;
		}
		return false;
	}
	set isVisible(value: boolean) {
		if (this._customField instanceof StandardCustomField) {
			this._customField.isVisible = value;
		}
	}

	get codeType(): string {
		if (this._customField instanceof StandardCustomField) return '';
		return this._customField.codeType;
	}
	set codeType(value: string) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.codeType = value;
	}

	get isRequired(): boolean {
		return this._customField.isRequired;
	}
	set isRequired(value: boolean) {
		this._customField.isRequired = value;
	}

	get isDefault(): boolean {
		if (this._customField instanceof StandardCustomField) return false;
		return this._customField.isDefault;
	}
	set isDefault(value: boolean) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.isDefault = value;
	}

	get isTaskDefault(): boolean {
		if (this._customField instanceof StandardCustomField) return false;
		return this._customField.isTaskDefault;
	}
	set isTaskDefault(value: boolean) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.isTaskDefault = value;
	}

	get customFieldGroup(): string {
		if (this._customField instanceof StandardCustomField) return '';
		return this._customField.customFieldGroup;
	}
	set customFieldGroup(value: string) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.customFieldGroup = value;
	}

	get sequence(): number {
		if (this._customField instanceof StandardCustomField) return Number.NaN;
		return this._customField.sequence;
	}
	set sequence(value: number) {
		if (this._customField instanceof StandardCustomField) return;
		this._customField.sequence = value;
	}

	get isDirty(): boolean {
		return this._customField.isDirty;
	}

	clearDirty = (): void => this._customField.clearDirty();

	undoAll = (): boolean => this._customField.undoAll();

	onUpdateInformation = (copyModel): boolean => this._customField.onUpdateInformation(copyModel);

	updateInformation = (copyModel): boolean => this._customField.updateInformation(copyModel);

	getContract = (): CustomFieldContract | StandardCustomFieldContract => this._customField.getContract();
}
