import { RestServiceResponse, ResponseServiceErrorCode } from './service-response';

export class ResultBase {
	constructor(private _serviceResponseDetails: RestServiceResponse) {}

	public get serviceResponseDetails(): RestServiceResponse {
		if (!this._serviceResponseDetails) {
			this._serviceResponseDetails = new RestServiceResponse();
		}
		return this._serviceResponseDetails;
	}
	public set serviceResponseDetails(value: RestServiceResponse) {
		this._serviceResponseDetails = value;
	}
	private _success: boolean = undefined;
	public get success(): boolean {
		if (this._success !== undefined) return false;

		if (!this.serviceResponseDetails) return false;

		return this.serviceResponseDetails.success;
	}
	public set success(value: boolean) {
		this._success = value;
	}

	public get errorMessage(): string {
		if (this.serviceResponseDetails.errorCode === ResponseServiceErrorCode.CustomError) {
			return this.serviceResponseDetails.customError;
		}

		let message = '';
		if (this.serviceResponseDetails.errorCode !== ResponseServiceErrorCode.ArcGISError) {
			// It is not an ArcGISError
			if (this.serviceResponseDetails.serviceException) {
				message += `${this.serviceResponseDetails.serviceException.message}\n`;
			}
		} else if (this.serviceResponseDetails.arcGISError) {
			// ArcGIS Error was declared;
			message += `${this.serviceResponseDetails.arcGISError.message}.\n`;
			if (this.serviceResponseDetails.arcGISError.details.length > 0) {
				message += `Details: ${this.serviceResponseDetails.arcGISError.details.join('\n')}\n`;
			}
			message += `ArcGIS Code: ${this.serviceResponseDetails.arcGISError.code}.\n`;
		}

		message += `HTTP CODE: ${this.serviceResponseDetails.httpCode.toString()} (${this.serviceResponseDetails.httpCode}) `;

		return message;
	}
}
