import * as SedaruModels from '../../index';
import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import { GlobalConfig } from '../../../models/global-config.model';

export class SearchTransform extends TransformBase {
	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig) {
		super(fieldforceConfig, globalConfiguration);
	}

	onTransform(): TransformationResult {
		if (!this.fieldforceConfig) {
			return this.getResult(true, 'source config is missing');
		}

		if (!this.fieldforceConfig.Search) {
			return this.getResult(true, 'source config is missing');
		}

		if (!this.fieldforceConfig.Search.SearchProviders) {
			return this.getResult(true, 'source config is missing');
		}

		if (this.fieldforceConfig.Search.SearchProviders.length === 0) {
			return this.getResult(true, 'no search providers found');
		}

		for (const searchProvider of this.fieldforceConfig.Search.SearchProviders) {
			// We ignore search providers with no search layers
			if (!searchProvider.SearchLayers || searchProvider.SearchLayers.length === 0) {
				continue;
			}

			for (const assetDefinition of this.globalConfig.assetDefinitions) {
				const arcGISAssetChannelAttributes = assetDefinition.assetChannel.attributes as SedaruModels.ArcGISAssetChannelAttributes;

				if (!arcGISAssetChannelAttributes) {
					continue;
				}

				if (arcGISAssetChannelAttributes.mapServiceDataSourceLegacyId !== searchProvider.ServiceID) {
					continue;
				}

				for (const searchLayer of searchProvider.SearchLayers) {
					if (searchLayer.AssetLayerIndex !== arcGISAssetChannelAttributes.mapServiceLayerIndex) {
						continue;
					}

					if (!searchLayer.SearchFields || searchLayer.SearchFields.length === 0) {
						continue;
					}

					if (!searchLayer.SearchFields.includes(',')) {
						assetDefinition.searchableFieldNames.push(searchLayer.SearchFields);
						continue;
					}

					const fields = searchLayer.SearchFields.split(',');
					fields.forEach(f => assetDefinition.searchableFieldNames.push(f));
				}
			}
		}

		return this.getResult(true);
	}
}
