import { ContractBase } from '../contract-base';
import { RecurrencePatternContract } from './recurrence-pattern-contract';
import { AssociatedAssetContract } from './associated-asset-contract';

export class RecurrenceTemplateContract extends ContractBase {
    public id: string;
    public name: string;
    public workType: string;
    public status: string;
    public assignedTo: string;
    public team: string;
    public owner: string;
    public priority: number | string;
    public recurrencePattern: RecurrencePatternContract;
    public associatedAssets: AssociatedAssetContract[];
}
