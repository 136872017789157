import { CustomFieldContract } from '../../contracts/work-order';
import { CustomField } from './custom-field.model';

export class CustomFields extends Array<CustomField> {
	private dict: Map<string, CustomField>;
	static fromContracts(customFieldContacts: CustomFieldContract[]) {
		const customFields = new CustomFields();

		if (!customFieldContacts) return customFields;
		// If custome fields isDefault = true, then come first(sorted by sequence), if isDefault = false, then come next (sorted by sequence)
		const defaultFields = customFieldContacts
			.filter(field => field.IsDefault)
			.sort((a, b) => (a.Sequence > b.Sequence ? 1 : a.Sequence < b.Sequence ? -1 : a.CustomFieldId > b.CustomFieldId ? 1 : a.CustomFieldId < b.CustomFieldId ? -1 : 0));
		const nonDefaultFields = customFieldContacts
			.filter(field => !field.IsDefault)
			.sort((a, b) => (a.Sequence > b.Sequence ? 1 : a.Sequence < b.Sequence ? -1 : a.CustomFieldId > b.CustomFieldId ? 1 : a.CustomFieldId < b.CustomFieldId ? -1 : 0));
		customFieldContacts = defaultFields.concat(nonDefaultFields);
		for (const customFieldContract of customFieldContacts) {
			const customField = CustomField.fromContract(customFieldContract);
			customFields.push(customField);
		}
		return customFields;
	}

	static fromModels(customFieldModels: CustomField[]): CustomFields {
		const customFields = new CustomFields();
		const defaultFields = customFieldModels
			.filter(field => field.isDefault)
			.sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : a.customFieldId > b.customFieldId ? 1 : a.customFieldId < b.customFieldId ? -1 : 0));
		const nonDefaultFields = customFieldModels
			.filter(field => !field.isDefault)
			.sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : a.customFieldId > b.customFieldId ? 1 : a.customFieldId < b.customFieldId ? -1 : 0));

		customFieldModels = defaultFields.concat(nonDefaultFields);
		for (const customField of customFieldModels) customFields.push(customField);
		return customFields;
	}

	getContracts = () => {
		const customFieldContracts = new Array<CustomFieldContract>();
		for (const customField of this) {
			const customFieldContract = customField.getContract();
			customFieldContracts.push(customFieldContract);
		}
		return customFieldContracts;
	};

	getDefaultFields = () => CustomFields.fromContracts(this.getContracts().filter(field => field.IsDefault));

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = () => this.forEach(field => (field.isDirty ? field.clearDirty() : null));

	undoAll = () => this.forEach(field => (field.isDirty ? field.undoAll() : null));

	getFieldsToUpdate = (): CustomFieldContract[] => this.filter(field => field.isDirty).map(field => field.getContract());

	getByCustomFieldId = (customFieldId: string) => {
		if (!this.dict) this.dict = new Map();
		if (!this.dict.has(customFieldId)) {
			const found = this.find(customField => customField.customFieldId === customFieldId);
			this.dict.set(customFieldId, found);
		}
		return this.dict.get(customFieldId);
	};
}
