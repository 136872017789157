import { ContractBase } from '../contract-base';

export class StandardEmployeeCostContract extends ContractBase {
	public objectid: number;
	public Hours: number;
	public RateType: string;
	public HourlyRate: number;
	public DateStamp: string;
	public WorkOrderKey: string;
	public EmployeeID: string;
	public TotalCost: number;
	public ChangeStatus: string;
	public WorkTaskId: string;
	public TradeId: string;
	public BudgetId: string;
	public CreatedBy: string;
}
