import { Color } from 'models';

export class HeaderedList {
	private _header: HeaderedListHeader;
	get header() {
		if (!this._header) this._header = new HeaderedListHeader();
		return this._header;
	}
	private _items: Array<any>;
	get items() {
		if (!this._items) this._items = [];
		return this._items;
	}
	collapsed = true;
	onShowMore: () => void;
	getShortList(length: number) {
		if (length === undefined || length === null || length >= this.items.length) return this.items;
		return this.items.slice(0, length);
	}
}

class HeaderedListHeader {
	label: string;
	background: Color;
	leftIcon: HeaderedListButton;
	rightIcon: HeaderedListButton;
}

export class HeaderedListButton {
	url: string;
	/** the string to show when the mouse hovers over the icon */
	tooltip: string;
	/** if left undefined, the button will not do anything on click */
	action: () => void;
	/** size in pixels. Default is 30 */
	size = 35;
	/** if true, the icon is grayed out and the action will be ignored */
	disabled: boolean;
}
