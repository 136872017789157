<label class="task-list-header">work order comments</label>
<mat-divider class="task-list-divider"></mat-divider>
<div class="list-wrapper">
	<app-omni-list [omniListItems]="workComments"></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<label class="omni-md-font">comments: {{ workComments.length }} &nbsp;</label>
	</mat-card-actions>
</mat-card-footer>
