import { DataSource } from '../models/data-source.model';
import { Injectable } from '@angular/core';
import { IdentityManagerService } from 'app/identity-manager/identity-manager.service';
import { DataSourceType } from '../models/data-source-type.enum';

@Injectable({
	providedIn: 'root'
})
export class DataSourceService {
	public availableDataSources: { [key: string]: DataSource } = {};

	constructor(private identityManager: IdentityManagerService) {}

	/**
	 * Verifies if the data source is a map server or a feature server
	 * @param {DataSource[]} dataSourceList - List of data source to be registered with esri identity manager
	 * @param {string} customerCode - The customer code
	 */
	/*registerDataSourcesWithEsriIdentityManager(customerCode: string): void {
		for (const dataSource of Object.values(this.availableDataSources)) {
			if (this.checkIsMapServer(dataSource)) {
				this.identityManager.registerToken(dataSource, customerCode);
			}
		}
	}*/

	checkIsMapServer(dataSource: DataSource): boolean {
		switch (dataSource.type) {
			case DataSourceType.FeatureService:
			case DataSourceType.MapService:
			case DataSourceType.WebMap:
				return true;
			default:
				return false;
		}
	}

	getFeatureServiceDataSource() {
		return Object.values(this.availableDataSources).find(dataSource => dataSource.type === DataSourceType.FeatureService);
	}
}
