import { ContractBase } from './contract-base';

export class FileIndexContract extends ContractBase {
    public FileIndexId: string;
    public CustomerCode: string;
    public UserName: string;
    public FileType: string;
    public FileName: string;
    public FileSize: string;
    public Description: string;
	public SecurityType: string;
	public OnConflict: string;
}
