import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { ArcGisField } from 'app/metric/queried-field-list.interface';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { Metric, MetricTile } from 'models';

@Component({
  selector: 'app-date-field-selection',
  templateUrl: './date-field-selection.component.html',
  styleUrls: ['./date-field-selection.component.scss']
})
export class DateFieldSelectionComponent extends MenuPanelBaseComponent {
  @ViewChild('dateFieldsList', { static: true }) dateFieldsList: OmniListComponent;

  private resolvers = new Map<string, ListItemResolver>();

  dateFields: ArcGisField[] = [];

  timeframeFilter: TimeframeFilter;

  selectedItem = [];

  itemsKey = 'name';

  timeFrameType: string;
	currentMetric: Metric;
	currentTile: MetricTile;

  constructor(
    view: ElementRef<HTMLElement>
  ) {
    super(view);
  }

  ngOnInit(): void {
    this.dateFieldsList.getResolver = (item: ArcGisField) => {
      if (this.resolvers.has(item.name)) return this.resolvers.get(item.name);
      return this.getResolver(item);
    };
    this.selectedItem.push(this.dateFields.find(dateField => dateField.name === this.timeframeFilter.timeframeField));
  }

  onPageNavigatedTo(args: NavigationArgs): void {
    if (!args || !args.parameter) return;

    this.timeFrameType = args.parameter.timeFrameType ? args.parameter.timeFrameType : 'tile';
		this.currentMetric = args.parameter.metric;
		this.currentTile = args.parameter.tile;

    this.dateFields = args.parameter.dateFields;
    this.timeframeFilter = args.parameter.timeframeFilter;
  }

  private getResolver(keyItem: ArcGisField): ListItemResolver {
    const itemResolver = NoIconOneLineComponent.createResolver();
    itemResolver.getAlignment = (item: ArcGisField) => 'center';
    itemResolver.showDivider = (item: ArcGisField) => true;
    itemResolver.onIsDisabled = (item: ArcGisField) => false;
    itemResolver.getLabel = (item: ArcGisField) => item.alias ? item.alias : item.name;
    this.resolvers.set(keyItem.name, itemResolver);
    return itemResolver;
  }

  handleItemClicked(event: ArcGisField): void {
    if (!this.timeframeFilter || !event) return;
    const newFilter = new TimeframeFilter();
    newFilter.timeframeField = event.name;
    newFilter.timeFrame = this.timeframeFilter.timeFrame;
    NavigationService.navigateBackTo(Pages.timeframefilter, { timeFrameType: this.timeFrameType, timeframeFilter: newFilter, tile: this.currentTile, metric: this.currentMetric });
  }
}
