import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { VerticalAlign } from './vertical-align-options.enum';
import { HorizontalAlign } from './horizontal-align-options.enum';

@Component({
	selector: 'app-text-in-max-lines',
	templateUrl: './text-in-max-lines.component.html',
	styleUrls: ['./text-in-max-lines.component.scss']
})
export class TextInMaxLinesComponent implements OnInit, AfterViewInit {
	private _text: string;
	get text() {
		return this._text;
	}
	@Input() set text(newText: string) {
		this._text = newText;
		this.updateText(newText);
	}
	@Input() maxLines: number;
	@Input() verticalAlign = VerticalAlign.middle;
	@Input() horizontalAlign = HorizontalAlign.center;
	@Input() fontSize = '20px';
	@Input() lineHeight = '20px';
	@Input() sufix: string;
	@Output() textFormatted = new EventEmitter();
	@ViewChildren('singleChar') spanElements: QueryList<ElementRef>;
	@ViewChild('wrapper', { static: true }) textWrapper: ElementRef;
	chars = [];
	paddingTop = '0px';
	showElipsis = false;
	toolTip: string;
	timerHandler;

	constructor() { }

	ngOnInit() { }

	ngAfterViewInit() { }

	onResize() {
		if (this.timerHandler) {
			clearTimeout(this.timerHandler);
		}

		this.timerHandler = setTimeout(() => {
			this.showAllSpans();
			this.runLayout();
			this.timerHandler = undefined;
		}, 200);
	}

	private updateText(text: string) {
		if (!text) text = '';
		this.showElipsis = false;
		this.chars = [];
		this.toolTip = text;
		for (let i = 0; i < text.length; i++) {
			this.chars.push(text.charAt(i));
		}
		setTimeout(() => {
			this.showAllSpans();
			this.runLayout();
			this.textFormatted.emit();
		}, 100);
	}

	private runLayout() {
		let lines = 1;
		let lineHeight = 0;
		for (let i = 1; i < this.spanElements.length; i++) {
			const currentElement = this.spanElements.toArray()[i].nativeElement as HTMLElement;
			if (currentElement.textContent === ' ') continue;

			if (currentElement.offsetHeight > lineHeight) lineHeight = currentElement.offsetHeight;
			const currentTop = currentElement.getBoundingClientRect().top;
			const previousElement = this.spanElements.toArray()[i - 1].nativeElement as HTMLElement;
			let previousTop;
			if (previousElement.textContent === ' ') {
				previousTop = (this.spanElements.toArray()[i - 2].nativeElement as HTMLElement).getBoundingClientRect().top;
			} else previousTop = previousElement.getBoundingClientRect().top;
			if (currentTop > previousTop) {
				if (lines + 1 > this.maxLines) {
					const ellipsisWidth = this.sufix ? 4 + this.sufix.length : 3;
					if (i > ellipsisWidth) {
						for (let c = i - ellipsisWidth; c < this.spanElements.length; c++) {
							(this.spanElements.toArray()[c].nativeElement as HTMLElement).style.display = 'none';
						}
					}
					this.showElipsis = true;
					break;
				}
				lines++;
			}
		}

		if (this.verticalAlign === VerticalAlign.middle) {
			const maxHeight = (this.textWrapper.nativeElement as HTMLElement).offsetHeight;
			const padding = (maxHeight - lines * lineHeight) / 2;
			this.paddingTop = padding ? padding + 'px' : '2px';
		}
	}

	private showAllSpans() {
		this.spanElements.forEach(s => {
			(s.nativeElement as HTMLElement).style.display = 'inline';
		});
	}
}
