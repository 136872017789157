import { SedaruClientMethodHandlerBase } from './sedaru-client-method-handler-manager';
import { MethodHandlerResult, MethodHandlerResultBase } from './method-handler-result';

export class MethodHandlerListener {
	private _isWaiting: boolean;
	get isWaiting(): boolean {
		return this._isWaiting;
	}
	private _isResolved = false;
	get isResolved(): boolean {
		return this._isResolved;
	}
	private _hasCancelled: boolean;
	get hasCancelled(): boolean {
		return this._hasCancelled;
	}
	hasTimedOut: boolean;
	get hasFinished(): boolean {
		return this.isResolved || this.hasTimedOut || this.hasCancelled;
	}

	private _timeoutHandler: any;

	private _sedaruClientMethodHandlerBase: SedaruClientMethodHandlerBase;
	public get sedaruClientMethodHandlerBase(): SedaruClientMethodHandlerBase {
		return this._sedaruClientMethodHandlerBase;
	}

	constructor(sedaruClientMethodHandler: SedaruClientMethodHandlerBase) {
		this._sedaruClientMethodHandlerBase = sedaruClientMethodHandler;
	}

	private _meetsRequirementsCondition: (result: MethodHandlerResultBase) => Promise<boolean>;
	get meetsRequirementsCondition(): (result: MethodHandlerResultBase) => Promise<boolean> {
		return this._meetsRequirementsCondition;
	}
	private _handler: (result: MethodHandlerResultBase) => void;
	private _timeout: number;
	public waitForMessage(meetsRequirementsCondition: (result?: MethodHandlerResultBase) => Promise<boolean>, timeoutSeconds?: number, timeoutFunction?: () => void): Promise<MethodHandlerResultBase> {
		const promise = new Promise<MethodHandlerResultBase>((resolve, reject) => {
			this._handler = resolve;
		});
		this._meetsRequirementsCondition = meetsRequirementsCondition;
		this._isWaiting = true;
		this._timeout = timeoutSeconds;
		this.startTimeout();

		this._timeoutHandler = setTimeout(() => {
			if (!timeoutFunction) {
				this.cancelListener(true);
				return;
			}

			timeoutFunction();
		}, timeoutSeconds * 1000);

		return promise;
	}

	private async startTimeout() {
		if (this._timeout <= 0) return;

		await new Promise(resolve => setTimeout(resolve, this._timeout * 4000));

		if (this.hasFinished) return;

		this._isWaiting = false;
		this.hasTimedOut = true;
	}

	private canResolve(methodHandleResult: MethodHandlerResultBase): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			if (!this.isWaiting) {
				resolve(false);
				return;
			}

			if (!this.meetsRequirementsCondition) {
				resolve(true);
				return;
			}

			const result = await this.meetsRequirementsCondition(methodHandleResult);
			resolve(result);
			return;
		});
	}

	public resolve(methodHandleResult: MethodHandlerResultBase): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			const result = await this.canResolve(methodHandleResult);

			if (!result) {
				resolve(false);
				return;
			}

			if (this._timeoutHandler) {
				clearTimeout(this._timeoutHandler);
			}

			this._isResolved = true;
			this._isWaiting = false;
			methodHandleResult.wasHandled = true;

			this._handler(methodHandleResult);

			resolve(true);
			return;
		});
	}

	public cancel = () => {
		this.cancelListener(false);
	};
	private cancelListener(wasTimedOut: boolean) {
		this._hasCancelled = true;
		this._isWaiting = false;
		const result = new MethodHandlerResultBase();
		result.hasTimedOut = true;
		result.cancelled = true;
		this._handler(result);
	}
}
