import { Template } from './template.model';

export class Templates extends Array<Template> {
    getById = (id: number): Template => {
        for (const template of this) {
            if (template.id !== id) {
                continue;
            }
            return template;
        }
    };
}
