import { ManagerBase } from 'domain-service/manager-base';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MetricChangedHandler, MetricChangedResult } from 'domain-service/subscriptions/handlers/metric-changed-handler';
import { ChannelTypes, Metric, WorkOrderModeType } from 'models';
import { AdvancedWorkOrders } from 'models/work-order';
import { RefreshWorkOrderMetric, WorkOrderMetricType } from './refresh-work-order-metric.model';
import { AssetMetricType, RefreshAssetMetric } from './refresh-asset-metric.model';
import { TimeframedMetric, MetricTimeframes } from '../../models/time-frame/timeframed-metric.model';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { TimeframedMetricSubscriber } from 'models/time-frame/timeframed-metric-subscriber';
import { UserTimeframeOption } from 'models/time-frame/user-timeframe-option.model';
import { UserTimeframeOptionType } from 'models/time-frame/user-timeframe-option.enum';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import { HMSRefreshOperation } from './hms-refresh-operation.enum';
import { NavigationService } from 'app/navigation/navigation.service';
import { DateUtil } from 'sedaru-util';

export class MetricManager extends ManagerBase {
	tableViewCurrentMetricScalarValue: number;

	private getMetricDeltasTimer;

	private getMetricResultsTimer;

	private timeFramedMetricsInQueue: TimeframedMetric[] = [];

	private metricResultsQueue: TimeframedMetric[] = [];

	private _timeframedMetricsInUse: Set<TimeframedMetric>;
	get timeframedMetricsInUse() {
		if (!this._timeframedMetricsInUse) this._timeframedMetricsInUse = new Set();
		return this._timeframedMetricsInUse;
	}

	constructor(interopService: OmniInteropService) {
		super(interopService);
	}

	initialize() {
		this.hookOnToRealtimeEvents();
	}

	hookOnToRealtimeEvents() {
		if (!this.domain.subscriptionService) {
			return;
		}

		const metricHandler = this.domain.subscriptionService.hub.methodHandlers.getHandler('MetricChangedHandler') as MetricChangedHandler;
		if (!metricHandler) {
			return;
		}
		metricHandler.onMetricChanged.subscribe(async (handler, metricPayload: MetricChangedResult) => {
			const openTab = this.interopService.uiManager.openTab;
			if (!openTab) return;

			if (!metricPayload || !metricPayload.metricId) return;
		});

		metricHandler.onMetricContentChanged.subscribe(async (handler, metricPayload: MetricChangedResult) => {
			if (!metricPayload || !metricPayload.metricId) return;

			const metric = this.domain.metricService.getMetricById(metricPayload.metricId);
			if (!metric) return;

			const openTab = NavigationService.current.activeTab?.mainContent;
			const currentTabMetricIds = Object.values(NavigationService.current.activeTab?.config?.tileList)?.map(t => t?.metric?.id);
			if (!currentTabMetricIds || !currentTabMetricIds.includes(metric?.id)) return;

			this.getMetricDeltasByMetricId(metricPayload.metricId);
			this.getMetricResultsByMetricId(metricPayload.metricId);
			await this.domain.metricService.refreshMetricDefinition(metric.id);
			openTab?.metricValueUpdated(metric);
		});
	}

	async getMetricTableView(
		metric: Metric,
		timeFrame: TimeframeFilter,
		pageSize?: number,
		pageNumber?: number,
		outFields?: Array<string>,
		sorting?: Array<{ field: string; order?: 'ASC' | 'DESC' }>
	) {
		const hmsReturn = await this.interopService.omniDomain.metricService.getMetricView(metric, timeFrame, pageSize, pageNumber, outFields, sorting);
		if (!(hmsReturn as any).MetricResults || !(hmsReturn as any).MetricResults.length) return null;

		const timeFrames = (hmsReturn as any).MetricResults[0].timeframes;
		this.tableViewCurrentMetricScalarValue = timeFrames[0].numberOfRecords;
		return timeFrames[0]?.results?.features
			? timeFrames[0].results.features.map(f => {
				return f.attributes ? f.attributes : f;
			})
			: timeFrames[0]?.results[0]?.WorkOrder
				? timeFrames[0].results.map(r => r.WorkOrder)
				: timeFrames[0]?.results;
	}

	private awaitForMetricResultsToBeReady(timeframedMetric: TimeframedMetric) {
		return new Promise(async (resolve, reject) => {
			const pageSize = 3000;
			let hmsResponse = await this.interopService.omniDomain.metricService.getMetricResult(timeframedMetric, pageSize);
			if (!hmsResponse.Success) {
				reject(hmsResponse);
				return;
			}

			if (hmsResponse.ResultsPending === false) {
				resolve(hmsResponse);
				return;
			}
			let numberOfTries = 1;
			const intervalHandler = setInterval(async () => {
				if (numberOfTries > 60) {
					reject('Waited for 5 minutes and metric did not return values.');
					clearInterval(intervalHandler);
					return;
				}

				hmsResponse = await this.interopService.omniDomain.metricService.getMetricResult(timeframedMetric, pageSize);

				if (hmsResponse.ResultsPending === false || hmsResponse.TotalNumberOfResults > 0) {
					resolve(hmsResponse);
					clearInterval(intervalHandler);
					return;
				}

				numberOfTries++;
			}, 5000);
		});
	}

	private async callHmsPages(timeframedMetric: TimeframedMetric, pageSize: number, lastPage: number, requestId: string): Promise<Array<any>> {
		const totalResults = [];
		for (let page = 1; page <= lastPage; page++) {
			totalResults.push(await this.interopService.omniDomain.metricService.getMetricResult(timeframedMetric, pageSize, page, requestId));
		}

		return totalResults;
	}

	async callTableViewRange(
		metric: Metric,
		timeFrame: TimeframeFilter,
		pageSize?: number,
		batchRange?: number[],
		outFields?: Array<string>,
		sorting?: Array<{ field: string; order?: 'ASC' | 'DESC' }>
	): Promise<Array<any>> {
		const totalResults = [];
		for (const batchNo of batchRange) {
			totalResults.push(await this.interopService.omniDomain.metricService.getMetricView(metric, timeFrame, pageSize, batchNo, outFields, sorting));
		}

		return totalResults;
	}

	refreshWorkOrderMetrics(workOrderKeyList: string[], operationType: HMSRefreshOperation) {
		const refreshWorkOrderMetricList: RefreshWorkOrderMetric[] = [];
		for (const workOrderKey of workOrderKeyList) {
			const refreshWorkOrderMetric = new RefreshWorkOrderMetric();
			refreshWorkOrderMetric.metricType = this.interopService.omniDomain.workOrderFactory.isAdvancedMode ? WorkOrderMetricType.AWO : WorkOrderMetricType.SWO;
			refreshWorkOrderMetric.workOrderKey = workOrderKey;
			refreshWorkOrderMetric.operation = operationType;

			refreshWorkOrderMetricList.push(refreshWorkOrderMetric);
		}

		this.interopService.omniDomain.metricService.refreshWorkOrderMetrics(refreshWorkOrderMetricList);
	}

	refreshAssetMetrics(assetList, operationType) {
		const refreshAssetMetricList: RefreshAssetMetric[] = [];
		for (const asset of assetList) {
			const refreshAssetMetric = new RefreshAssetMetric();
			refreshAssetMetric.metricType = AssetMetricType.Asset;
			refreshAssetMetric.assetType = asset.assetType;
			refreshAssetMetric.assetId = asset.assetId;
			refreshAssetMetric.assetIdField = asset.assetIdField;
			refreshAssetMetric.operation = operationType;

			refreshAssetMetricList.push(refreshAssetMetric);
		}

		this.interopService.omniDomain.metricService.refreshAssetMetrics(refreshAssetMetricList);
	}

	getResultsForTimeframedMetric(timeframedMetric: TimeframedMetric) {
		// add the timeframedMetric to the queue
		if (!this.metricResultsQueue.includes(timeframedMetric)) this.metricResultsQueue.push(timeframedMetric);
		// cancel the previous timer
		if (this.getMetricResultsTimer) {
			clearTimeout(this.getMetricResultsTimer);
			this.getMetricResultsTimer = null;
		}

		this.getMetricResultsTimer = setTimeout(async () => {
			const metricQueue = [...this.metricResultsQueue];
			this.metricResultsQueue = [];
			metricQueue.forEach(async tfm => {
				try {
					const hmsResponse = (await this.awaitForMetricResultsToBeReady(tfm)) as any;
					if (!hmsResponse.Success) {
						console.error(hmsResponse);
						return;
					}

					const pageSize = hmsResponse.ResultPageSize;
					const requestId = hmsResponse.ResultSetId;
					const lastPage = Math.ceil(hmsResponse.TotalNumberOfResults / pageSize) - 1;
					const totalResults = hmsResponse.Result.features ? hmsResponse.Result.features : hmsResponse.Result;

					if (hmsResponse.TotalNumberOfResults > hmsResponse.ResultPageSize) {
						const pagesResponse = await this.callHmsPages(tfm, pageSize, lastPage, requestId);
						pagesResponse.forEach(response => {
							const restOfResults = response.Result.features ? response.Result.features : response.Result;
							totalResults.push(...restOfResults);
						});
					}
					const metric = tfm.metric;
					if (metric.definition.source.type === ChannelTypes.WorkOrder && this.interopService.configurationManager.customerCodeConfiguration.workOrderMode === WorkOrderModeType.Advanced) {
						tfm.update({ newResults: AdvancedWorkOrders.fromBasicDataContracts(totalResults) });
					} else {
						tfm.update({
							newResults: totalResults.map(f => {
								return { attributes: f, geometry: f.geometry };
							})
						});
					}

					tfm.lastOutfieldsRequested = tfm.currentOutfieldsRequested;
				} catch (error) {
					AnalyticsHub.current.trackError(error);
				}
			});
		}, 200);
	}

	/** finds a timeframedMetric and returns a suscriber of it.
	 */
	getMetricSubscriber(metric: Metric, timeframeFilter?: TimeframeFilter): TimeframedMetricSubscriber {
		if (!timeframeFilter) {
			const allDatesDef = UserTimeframeOption.getTimeFrameDefinition(UserTimeframeOptionType.AllDates);
			const timeframe = new Timeframe(allDatesDef);
			timeframeFilter = new TimeframeFilter();
			timeframeFilter.timeFrame = timeframe;
		}

		const subscriber = new TimeframedMetricSubscriber();
		let timeframedMetric: TimeframedMetric;
		// does the timeframedMetric already exist
		timeframedMetric = [...this.timeframedMetricsInUse].find(tfm => {
			if (tfm.metric !== metric) return;
			if (timeframeFilter.timeframeField && tfm.timeframeFilter.timeframeField && timeframeFilter.timeframeField !== tfm.timeframeFilter.timeframeField) {
				return;
			}
			if (timeframeFilter.timeFrame.isEqualsTo(tfm.timeframeFilter.timeFrame)) {
				return tfm;
			}
			console.log('Timeframes are different:', timeframeFilter.timeFrame, tfm.timeframeFilter.timeFrame);
		});
		if (!timeframedMetric) {
			timeframedMetric = new TimeframedMetric(metric, timeframeFilter);
			this.timeframedMetricsInUse.add(timeframedMetric);
		}

		timeframedMetric.subscribe(subscriber);
		timeframedMetric.onResultsRequested = tfm => {
			this.getResultsForTimeframedMetric(tfm);
		};
		timeframedMetric.onScalarRequested = tfm => {
			this.getMetricDeltasByTimeframedMetric(tfm);
		};
		return subscriber;
	}

	removeTimeframedMetricsNotInUse() {
		for (const timeframedMetric of this.timeframedMetricsInUse) {
			if (!timeframedMetric.subscribers.length) this.timeframedMetricsInUse.delete(timeframedMetric);
		}
	}

	getTimeframesByMetricId(metricid: string, arrayOfTimeframedMetrics: TimeframedMetric[]): MetricTimeframes {
		const timeframedMetrics = arrayOfTimeframedMetrics.filter(tfm => tfm.metric.id === metricid);

		const metricTimeframes = new MetricTimeframes(timeframedMetrics);

		return metricTimeframes;
	}

	/** gets the new metric results for metric timeframes */
	getMetricResultsByMetricId(metricId: string) {
		const metricTimeframes = this.getTimeframesByMetricId(metricId, [...this.timeframedMetricsInUse]);
		metricTimeframes.timeframedMetrics.forEach(tfm => this.getResultsForTimeframedMetric(tfm));
	}

	/** gets the new scalar and delta results for metric timeframes */
	getMetricDeltasByMetricId(metricId: string) {
		const metricTimeframes = this.getTimeframesByMetricId(metricId, [...this.timeframedMetricsInUse]);
		metricTimeframes.timeframedMetrics.forEach(tfm => this.getMetricDeltasByTimeframedMetric(tfm));
	}

	getMetricDeltasByTimeframedMetric(timeframedMetric: TimeframedMetric) {
		if (this.timeFramedMetricsInQueue.includes(timeframedMetric)) return;
		// add the timeframedMetric to the queue
		this.timeFramedMetricsInQueue.push(timeframedMetric);
		// cancel the previous timer
		if (this.getMetricDeltasTimer) {
			clearTimeout(this.getMetricDeltasTimer);
			this.getMetricDeltasTimer = null;
		}

		return new Promise((resolve, reject) => {
			// wait for another request otherwise hit the service
			this.getMetricDeltasTimer = setTimeout(() => {
				clearTimeout(this.getMetricDeltasTimer);
				this.getMetricDeltasTimer = null;
				const timeFramedMetrics = [...this.timeFramedMetricsInQueue];
				this.timeFramedMetricsInQueue = [];
				const uniqueMetricIds = [];
				timeFramedMetrics.forEach(tfm => {
					if (uniqueMetricIds.includes(tfm.metric.id)) return;
					uniqueMetricIds.push(tfm.metric.id);
				});
				const metricTimeframes = uniqueMetricIds.map(metricId => this.getTimeframesByMetricId(metricId, timeFramedMetrics));
				const requests = metricTimeframes.map(metricTimeframe => metricTimeframe.toDeltaRequest());
				this.interopService.omniDomain.metricService
					.getMetricDeltas(requests)
					.toPromise()
					.then(response => {
						if (!response.Success) {
							reject('Could not get deltas');
							return;
						}

						for (let i = 0; i < requests.length; i++) {
							const responseTimeframes = response.Results[i]?.timeframes;
							const {
								metricid,
								metricStatus
							} = response.Results[i];
							let changeDateKey: string = null;
							if (metricStatus) changeDateKey = Object.keys(metricStatus).find(k => k?.toLowerCase() === 'lasttimechanged');
							const metric = this.interopService.omniDomain.metricService.availableMetrics.find(m => m.id === metricid);
							if (metric) metric.lastTimeChanged = changeDateKey ? new Date(metricStatus[changeDateKey] + ' UTC') : DateUtil.now;
							/** when the service returns error for single tile, the response from the service does
							 * not give enough information to know what timeframed metric failed. We need to find it
							 * through the request that was sent.
							 */
							if (!responseTimeframes || !responseTimeframes.length) {
								requests[i].timeframes.forEach(requestedTimeframedMetric => {
									const tfmFound = timeFramedMetrics.find(tfm => {
										const r = tfm.toDeltaRequest();
										if (r.timeframes[0].timeframefield !== requestedTimeframedMetric.timeframefield) return false;
										if (r.timeframes[0].enddate !== requestedTimeframedMetric.enddate) return false;
										if (r.timeframes[0].startdate !== requestedTimeframedMetric.startdate) return false;
										return true;
									});
									if (tfmFound) tfmFound.update({ newScalar: null });
								});
							}

							responseTimeframes.forEach(resTfm => {
								const newScalar = resTfm.scalar ?? null;
								const tfmFound = timeFramedMetrics.find(tfm => {
									const r = tfm.toDeltaRequest();
									if (r.metricid !== response.Results[i].metricid) return false;
									if (r.timeframes[0].timeframefield !== resTfm.timeframe.timeframefield) return false;
									if (r.timeframes[0].enddate !== resTfm.timeframe.enddate) return false;
									if (r.timeframes[0].startdate !== resTfm.timeframe.startdate) return false;
									return true;
								});
								if (tfmFound) tfmFound.update({ newScalar });
							});
						}
						resolve(true);
					})
					.catch((error: any) => {
						AnalyticsHub.current.trackError(error);
						for (const metric of timeFramedMetrics) metric.onFetchScalarError(error);
					});
			}, 200);
		});
	}

	getHotMetrics() {
		return Object.values(NavigationService.current.activeTab?.config?.tileList)?.filter(t => t && t.metric)?.map(t => t.metric);
	}

	async getMetricsThatNeedRefresh() {
		const metrics = this.getHotMetrics();
		const metricsThatNeedRefresh: Metric[] = [];
		if (!metrics || !metrics.length) return metricsThatNeedRefresh;

		const statusOfMetrics = await this.interopService.omniDomain.metricService.checkIfMetricsNeedRefresh(metrics).toPromise();
		if (!statusOfMetrics || !statusOfMetrics.metrics) return metricsThatNeedRefresh;

		for (const metric of metrics) {
			const metricStatus = statusOfMetrics.metrics.find(s => s.metricId === metric.id);
			if (!metricStatus) continue;

			const lastTimeChanged = new Date(metricStatus.lastTimeChanged + ' UTC');
			if (metric.lastTimeChanged < lastTimeChanged) metricsThatNeedRefresh.push(metric);

			if (metric.definition.timeFrameFilter.timeframeField && !this.isToday(metric.lastTimeScalarUpdated)) metricsThatNeedRefresh.push(metric);
		}
		return metricsThatNeedRefresh;
	}

	isToday(dateToCheck) {
        const today = new Date();
        return today.toDateString() === dateToCheck.toDateString();
    }

	/**
	 * @param checkForChange if set to true, will check if metrics have changed before refreshing. If false (default) it will refresh all hot metrics
	 */
	async refreshMetrics(checkForChange: boolean) {
		let metrics: Metric[];
		if (checkForChange) metrics = await this.getMetricsThatNeedRefresh();
		else metrics = this.getHotMetrics();
		/** each metric subscriber will be refreshed when the response returns */
		for (const metric of metrics) {
			this.getMetricDeltasByMetricId(metric.id);
			this.getMetricResultsByMetricId(metric.id);
		}
	}
}
