import { UserService } from '../app/user/user.service';
import { WorkComment } from '../models/work-order/work-comment.model';
import { GeometryService } from '../app/canvas-container/canvas-map/geometry.service';
import { ArcGISAssetChannelAttributes } from '../models/arc-gis-asset-channel-attributes.model';
import { DataChannelService } from '../domain-service/data-channel.service';
import { Metric } from '../models/metric.model';
import { Injectable } from '@angular/core';
import { WorkOrderMetaData, AdvancedWorkOrder, AdvancedWorkOrders, EmployeeCost, MaterialCost, EquipmentCost, WorkTask, WorkAsset, Employee, Attachment, WorkAssetWrapper, WorkOrderWrapper, WorkOrderWrappers, WorkCommentWrapper, EmployeeCostWrapper, EquipmentCostWrapper, MaterialCostWrapper, TaskWrapper, WorkOrderCapabilities } from 'models/work-order';
import { WorkOrderService } from 'domain-service/work-order.service';
import { WorkOrderMetaDataContract, WorkOrderRawContract, WorkOrderFilterContract, AssetContract, ContractResponse } from 'contracts/work-order';
import { v4 as uuid } from 'uuid';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { WorkOrderSystem } from 'models/sedaru-config/work-order-system.enum';
import { FieldType } from 'sedaru-util/esri-core/field-type';
import { Server } from 'sedaru-util/esri-core/server';
import { AssetRecord } from 'models/records/asset-record.model';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { HierarchyNode } from 'models/work-order/hierarchy-node.model';
import { AssetLocationsWithAssets } from 'models/work-order/asset-locations-with-assets.model';
import { Attachments } from 'models/work-order/attachments.model';
import { AssetLocationAttachmentUpload, AttachmentUpload, UploaderService, WorkOrderAttachmentUpload } from '../app/workorder/attachment/uploader.service';
import { Asset } from 'models/work-order/asset.model';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { ArcGISField, ChannelTypes, WorkOrderChannelAttributes, WorkOrderField } from 'models';
import { AWOQuery } from 'models/awo-query.model';
import { WorkOrderProviderBase, WorkOrderResult } from './work-order-provider-base';
import { Batch, BulkJobMode, BulkUpdateJob } from 'domain-service/jobs/bulk-update-job/bulk-update-job';
import { BulkUpdateChangedResult } from 'domain-service/subscriptions/handlers/bulk-update-changed-handler';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { MethodHandlerListener } from 'sedaru-util/sedaru-subscriptions-library/method-handler-listener';
import { CreateWorkOrderHandler, CreateWorkOrderResult } from 'domain-service/subscriptions/handlers/create-work-order-handler';
import { MethodHandlerResultBase } from 'sedaru-util/sedaru-subscriptions-library';
import { WorkOrderValidationHandler, WorkOrderValidationResult } from 'domain-service/subscriptions/handlers/work-order-validation-handler';
import { take } from 'rxjs/operators';
import { HMSRefreshOperation } from 'domain-service/metrics/hms-refresh-operation.enum';
import { SummaryFieldInputType } from 'models/work-order/standard-custom-field.model';

@Injectable({
	providedIn: 'root'
})
export class WorkOrderProvider extends WorkOrderProviderBase {
	private _allWorkOrders: AdvancedWorkOrders;
	private _lastTimeStamp: Date;
	private _selectedAssetLocations: Array<AssetLocationWithAssets>;
	availableWorkOrders: AdvancedWorkOrders;
	workOrderMetaData: WorkOrderMetaData;
	currentEmployee: Employee;
	workOrderListener: MethodHandlerListener;

	constructor(
		private geometryService: GeometryService,
		private esriSdkService: EsriSdkService,
		private workorderService: WorkOrderService,
		private dataChannelService: DataChannelService,
		private userService: UserService,
		private uploaderService: UploaderService
	) {
		super();
	}

	private get isMaximoSystem(): boolean {
		return this.interopService?.omniDomain?.workOrderFactory?.workOrderSystem === WorkOrderSystem.Maximo;
	}

	async getWorkOrders(workOrderKeys: string[], stubsOnly: boolean): Promise<WorkOrderWrappers> {
		const workOrders = await this.getWorkOrdersByWorkOrderKeys(workOrderKeys, stubsOnly);
		if (!workOrders || !workOrders.length) return new WorkOrderWrappers();

		return WorkOrderWrappers.fromModels(workOrders);
	}

	cancelRealTimeSaveOperation(): void {
		this.workOrderListener.cancel();
	}

	async createWorkOrderWithValidation(workOrderWrapper: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		const woResult = new WorkOrderResult();
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const realTimeHub = this.interopService?.omniDomain?.subscriptionService?.hub;
		const isSedaruConnectAvailable = await realTimeHub?.isSedaruConnectAvailable(this.workorderService.customerCode, 5);

		if (!realTimeHub || !isSedaruConnectAvailable) {
			woResult.isSuccess = false;
			woResult.errorMessage = 'Unable to establish a connection with Sedaru Connect';
			return woResult;
		}

		try {
			const handler = realTimeHub.methodHandlers.getHandler('CreateWorkOrderHandler') as CreateWorkOrderHandler;

			const condition = (resultCandidate: MethodHandlerResultBase): Promise<boolean> => {
				return new Promise<boolean>((resolve, reject) => {
					const createWorkOrderResult = resultCandidate as CreateWorkOrderResult;
					if (!createWorkOrderResult) {
						resolve(false);
						return;
					}

					resolve(true);
					return;
				});
			};

			handler.sendAdvancedWorkOrderMessage(workOrder, this.workorderService.customerCode);
			this.workOrderListener = handler.createListener();
			const result = (await this.workOrderListener.waitForMessage(condition, 15, timeoutDelegate)) as CreateWorkOrderResult;
			if (!result) return woResult;
			woResult.isSuccess = result.success;
			console.log(result);

			if (result.cancelled) {
				woResult.errorMessage = 'Operation has been cancelled';
				return woResult;
			}

			if (!result.success) {
				woResult.errorMessage = result.message;
				return woResult;
			}

			workOrder.isNew = false;
			workOrder.workOrderKey = result.newWorkOrderKey;
			workOrder.systemId = result.systemId;
			workOrder.clearDirty();
			return woResult;
		} finally {
			this.workOrderListener = undefined;
		}
	}

	async createWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		const woResult = new WorkOrderResult();
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		if (!workOrder) return woResult;

		const { isNew } = workOrder;
		if (!isNew) return this.updateWorkOrder(workOrderWrapper);

		workOrder.changeBy = this.userService.currentUser.userName;
		if (workOrder.isDirty) {
			const woResponse = await this.workorderService.postWorkOrder(workOrder).toPromise();
			workOrder.clearDirty();
		}

		if (isNew || workOrder.customFields.isDirty()) {
			if (isNew && workOrder.customFields.length) workOrder.customFields.forEach(customField => (customField.workOrderKey = workOrder.workOrderKey));
			console.log(workOrder.customFields.getFieldsToUpdate());
			const customFieldsResponse = await this.workorderService.postCustomFields(workOrder.customFields.getFieldsToUpdate()).toPromise();
			workOrder.customFields.clearDirty();
		}

		if (isNew && workOrder.workAssets.length) workOrder.workAssets.forEach(workAsset => (workAsset.workOrderKey = workOrder.workOrderKey));
		const workTaskResponse = await this.workorderService.postWorkAsset(workOrder.workAssets).toPromise();
		workOrder.workAssets.clearDirty();

		woResult.isSuccess = true;
		return woResult;
	}

	async updateWorkOrderWithValidation(workOrderWrapper: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		const woResult = new WorkOrderResult();
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const realTimeHub = this.interopService?.omniDomain?.subscriptionService?.hub;
		const isSedaruConnectAvailable = await realTimeHub?.isSedaruConnectAvailable(this.workorderService.customerCode, 5);

		if (!realTimeHub || !isSedaruConnectAvailable) {
			woResult.isSuccess = false;
			woResult.errorMessage = 'Unable to establish a connection with Sedaru Connect';
			return woResult;
		}

		try {
			const handler = realTimeHub.methodHandlers.getHandler('WorkOrderValidationHandler') as WorkOrderValidationHandler;

			const condition = (resultCandidate: MethodHandlerResultBase): Promise<boolean> => {
				return new Promise<boolean>((resolve, reject) => {
					const validationResult = resultCandidate as WorkOrderValidationResult;
					if (!validationResult) {
						resolve(false);
						return;
					}

					resolve(true);
					return;
				});
			};

			handler.sendSaveAdvancedWorkOrderMessage(workOrder, this.workorderService.customerCode);
			this.workOrderListener = handler.createListener();
			const result = (await this.workOrderListener.waitForMessage(condition, 15, timeoutDelegate)) as WorkOrderValidationResult;
			if (!result) return woResult;
			woResult.isSuccess = result.success;
			console.log(result);

			if (result.cancelled) {
				woResult.errorMessage = 'Operation has been cancelled';
				return woResult;
			}

			if (!result.success) {
				woResult.errorMessage = result.message;
				return woResult;
			}

			workOrder.isNew = false;
			workOrder.workOrderKey = result.workOrderKey;
			workOrder.systemId = result.systemId;
			workOrder.clearDirty();
			return woResult;
		} finally {
			this.workOrderListener = undefined;
		}
	}

	async updateWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		const woResult = new WorkOrderResult();
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		if (!workOrder) return woResult;

		const { isNew } = workOrder;
		workOrder.changeBy = this.userService.currentUser.userName;
		if (workOrder.isDirty) {
			const woResponse = isNew ? await this.workorderService.postWorkOrder(workOrder).toPromise() : await this.workorderService.updateWorkOrder(workOrder).toPromise();
			workOrder.clearDirty();
		}
		if (isNew || workOrder.customFields.isDirty()) {
			if (isNew && workOrder.customFields.length) workOrder.customFields.forEach(customField => (customField.workOrderKey = workOrder.workOrderKey));
			console.log(workOrder.customFields.getFieldsToUpdate());
			const customFieldsResponse = await this.workorderService.postCustomFields(workOrder.customFields.getFieldsToUpdate()).toPromise();
			workOrder.customFields.clearDirty();
		}

		if (isNew && workOrder.workAssets.length) workOrder.workAssets.forEach(workAsset => (workAsset.workOrderKey = workOrder.workOrderKey));
		const workTaskResponse = await this.workorderService.postWorkAsset(workOrder.workAssets).toPromise();
		workOrder.workAssets.clearDirty();

		woResult.isSuccess = true;
		return woResult;
	}

	async deleteWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		const woResult = new WorkOrderResult();
		woResult.isSuccess = false;

		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		if (!workOrder) return woResult;

		const result = await this.workorderService.deleteWorkOrders([workOrder.workOrderKey]).toPromise();
		woResult.isSuccess = result.Success;
		if (!result || !result.Success) {
			woResult.errorMessage = result.Message;
			return woResult;
		}

		const index = this.availableWorkOrders?.findIndex(wo => wo.workOrderKey === workOrder.workOrderKey);
		this.availableWorkOrders?.splice(index, 1);
		return woResult;
	}

	private async deleteWorkOrders(workOrderWrappers: WorkOrderWrapper[]): Promise<WorkOrderResult> {
		const result = new WorkOrderResult();
		const response = await this.workorderService.deleteWorkOrders(workOrderWrappers.map(wo => wo.workOrderKey)).toPromise();
		result.isSuccess = response.Success;
		if (!response.Success) result.errorMessage = response.Message;

		return result;
	}

	bulkEdit(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		const job = new BulkUpdateJob(workOrderWrappers, this.runBatchDelegateEdit, BulkJobMode.Update, true);
		job.jobFinished?.pipe(take(1)).subscribe((processedWorkOrderKeys: string[]) => {
			this.interopService?.metricManager?.refreshWorkOrderMetrics(processedWorkOrderKeys, HMSRefreshOperation.Update);
		});

		return job;
	}

	bulkDelete(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		const job = new BulkUpdateJob(workOrderWrappers, this.runBatchDelegateDelete.bind(this), BulkJobMode.Delete, false);
		job.jobFinished?.pipe(take(1)).subscribe((processedWorkOrderKeys: string[]) => {
			this.interopService?.metricManager?.refreshWorkOrderMetrics(processedWorkOrderKeys, HMSRefreshOperation.Remove);
		});

		return job;
	}

	private runBatchDelegateEdit(batch: Batch): Promise<boolean> {
		return new Promise<boolean>((resolver) => {
			if (!batch.realTimeHandler) resolver(false);

			batch.realTimeHandler.sendWorkOrderBulkUpdate(batch.workOrders.map(wo => (wo.workOrder as AdvancedWorkOrder).getContractWithoutNestedFields()),
				batch.batchId, batch.customerCode);

			batch.resolver = resolver;
		});
	}

	private async runBatchDelegateDelete(batch: Batch): Promise<boolean> {
		const bulkResult = new BulkUpdateChangedResult(null);
		const response = await this.deleteWorkOrders(batch.workOrders);
		if (response.isSuccess) bulkResult.workOrderKeysWithSuccess.push(...batch.workOrders.map(wo => wo.workOrderKey));
		batch.result = bulkResult;
		return response.isSuccess;
	}

	async createWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workComment = workCommentWrapper?.workComment as WorkComment;
		if (!workOrder || !workComment) return false;
		if (!workComment.isNew) return await this.updateWorkComment(workOrderWrapper, workCommentWrapper);

		workComment.createdBy = this.userService.currentUser.userName;
		const response = await this.workorderService.postWorkComment(workComment).toPromise();
		if (!response || !response.Success) return false;

		workComment.clearDirty();
		workOrder.workComments.push(workComment);
		return true;
	}

	async updateWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workComment = workCommentWrapper?.workComment as WorkComment;
		if (!workOrder || !workComment) return false;
		if (workComment.isNew) return await this.createWorkComment(workOrderWrapper, workCommentWrapper);

		workComment.createdBy = this.userService.currentUser.userName;
		const response = await this.workorderService.updateWorkComment(workComment).toPromise();
		if (!response || !response.Success) return false;

		workComment.clearDirty();
		return true;
	}

	async deleteWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workComment = workCommentWrapper?.workComment as WorkComment;

		if (!workOrder || !workComment) return false;

		const response = await this.workorderService.deleteWorkComment(workComment).toPromise();
		if (!response || !response.Success) return false;

		const indexFound = workOrder.workComments.findIndex(wc => wc === workComment);
		if (indexFound >= 0) workOrder.workComments.splice(indexFound, 1);
		return true;
	}

	async createEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const employeeCost = employeeCostWrapper?.employeeCost as EmployeeCost;
		if (!workOrder || !employeeCost) return false;
		if (!employeeCost.isNew) return await this.updateEmployeeCost(workOrderWrapper, employeeCostWrapper);

		employeeCost.calculateTotalCost(this.workOrderMetaData.employeeRates);
		employeeCost.createdBy = this.userService.currentUser.userName;
		const response = await this.workorderService.postEmployeeCost(employeeCost).toPromise();
		if (!response || !response.Success) return false;

		employeeCost.clearDirty();
		workOrder.employeeCosts.push(employeeCost);
		return true;
	}

	async updateEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const employeeCost = employeeCostWrapper?.employeeCost as EmployeeCost;
		if (!workOrder || !employeeCost) return false;
		if (employeeCost.isNew) return await this.createEmployeeCost(workOrderWrapper, employeeCostWrapper);

		employeeCost.calculateTotalCost(this.workOrderMetaData.employeeRates);
		const response = await this.workorderService.updateEmployeeCost(employeeCost).toPromise();
		if (!response || !response.Success) return false;

		employeeCost.clearDirty();
		return true;
	}

	async deleteEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const employeeCost = employeeCostWrapper?.employeeCost as EmployeeCost;
		if (!workOrder || !employeeCost) return false;

		const response = await this.workorderService.deleteEmployeeCost(employeeCost).toPromise();
		if (!response || !response.Success) return false;

		const index = workOrder.employeeCosts.findIndex(eCost => eCost === employeeCost);
		workOrder.employeeCosts.splice(index, 1);
		return true;
	}

	async createEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const equipmentCost = equipmentCostWrapper?.equipmentCost as EquipmentCost;
		if (!workOrder || !equipmentCost) return false;
		if (!equipmentCost.isNew) return await this.updateEquipmentCost(workOrderWrapper, equipmentCostWrapper);

		equipmentCost.calculateTotalCost();
		equipmentCost.createTimeStamp();
		equipmentCost.createdBy = this.userService.currentUser.userName;
		const response = await this.workorderService.postEquipmentCost(equipmentCost).toPromise();
		if (!response || !response.Success) return false;

		equipmentCost.clearDirty();
		workOrder.equipmentCosts.push(equipmentCost);
		return true;
	}

	async updateEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const equipmentCost = equipmentCostWrapper?.equipmentCost as EquipmentCost;
		if (!workOrder || !equipmentCost) return false;
		if (equipmentCost.isNew) return await this.createEquipmentCost(workOrderWrapper, equipmentCostWrapper);

		equipmentCost.calculateTotalCost();
		equipmentCost.createTimeStamp();
		const response = await this.workorderService.updateEquipmentCost(equipmentCost).toPromise();
		if (!response || !response.Success) return false;

		equipmentCost.clearDirty();
		return true;
	}

	async deleteEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const equipmentCost = equipmentCostWrapper?.equipmentCost as EquipmentCost;
		if (!workOrder || !equipmentCost) return false;

		const response = await this.workorderService.deleteEquipmentCost(equipmentCost).toPromise();
		if (!response || !response.Success) return false;

		const index = workOrder.equipmentCosts.findIndex(eCost => eCost === equipmentCost);
		workOrder.equipmentCosts.splice(index, 1);
		return true;
	}

	async createMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const materialCost = materialCostWrapper?.materialCost as MaterialCost;
		if (!workOrder || !materialCost) return false;
		if (!materialCost.isNew) return await this.updateMaterialCost(workOrderWrapper, materialCostWrapper);

		materialCost.calculateTotalCost();
		materialCost.createTimeStamp();
		materialCost.createdBy = this.userService.currentUser.userName;
		const response = await this.workorderService.postMaterialCost(materialCost).toPromise();
		if (!response || !response.Success) return false;

		materialCost.clearDirty();
		workOrder.materialCosts.push(materialCost);
		return true;
	}

	async updateMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const materialCost = materialCostWrapper?.materialCost as MaterialCost;
		if (!workOrder || !materialCost) return false;
		if (materialCost.isNew) return await this.createMaterialCost(workOrderWrapper, materialCostWrapper);

		materialCost.calculateTotalCost();
		materialCost.createTimeStamp();
		const response = await this.workorderService.updateMaterialCost(materialCost).toPromise();
		if (!response || !response.Success) return false;

		materialCost.clearDirty();
		return true;
	}

	async deleteMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const materialCost = materialCostWrapper?.materialCost as MaterialCost;
		if (!workOrder || !materialCost) return false;

		const response = await this.workorderService.deleteMaterialCost(materialCost).toPromise();
		if (!response || !response.Success) return false;

		const index = workOrder.materialCosts.findIndex(mCost => mCost === materialCost);
		workOrder.materialCosts.splice(index, 1);
		return true;
	}

	async createWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workTask = workTaskWrapper?.task as WorkTask;
		if (!workOrder || !workTask) return false;
		if (!workTask.isNew) return await this.updateWorkTask(workOrderWrapper, workTaskWrapper);

		workTask.changeBy = this.userService.currentUser.userName;
		const response = await this.workorderService.postWorkTask(workTask).toPromise();
		if (!response || !response.Success) return false;

		workTask.clearDirty();
		workTask.customFields.clearDirty();
		workOrder.workTasks.push(workTask);
		return true;
	}

	async updateWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workTask = workTaskWrapper?.task as WorkTask;
		if (!workOrder || !workTask) return false;
		if (workTask.isNew) return await this.createWorkTask(workOrderWrapper, workTaskWrapper);

		workTask.changeBy = this.currentEmployee.employeeId;
		const response = await this.workorderService.updateWorkTask(workTask).toPromise();
		if (!response || !response.Success) return false;

		workTask.clearDirty();
		workTask.customFields.clearDirty();
		return true;
	}

	async deleteWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workTask = workTaskWrapper?.task as WorkTask;
		if (!workOrder || !workTask) return false;

		const response = await this.workorderService.deleteWorkTask(workTask).toPromise();
		if (!response || !response.Success) return false;

		const index = workOrder.workTasks.findIndex(woTask => woTask === workTask);
		workOrder.workTasks.splice(index, 1);
		return true;
	}

	async updateWorkAsset(workOrderWrapper: WorkOrderWrapper, workAssetWrapper: WorkAssetWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		if (!workOrder) return false;

		const newAssets = workOrder.workAssets.getNewAssets();
		const response = await this.workorderService.postWorkAsset(newAssets).toPromise();
		if (!response || !response.Success) return false;

		const rawContract = new WorkOrderRawContract();
		rawContract.WorkOrder = workOrder.getContract();
		rawContract.WorkAssets = newAssets.getContracts();
		const assetsByLayers = await this.getWorkOrderAssetGeometry([rawContract], false);

		for (const newAsset of newAssets) {
			if (!assetsByLayers[newAsset.assetType]) continue;
			const { geometryType, spatialReference, features } = assetsByLayers[newAsset.assetType];
			let assetKey = newAsset.assetKey;
			if (this.dataChannelService.getWorkOrderSystem() === WorkOrderSystem.Maximo) {
				const assetLocation = this.workOrderMetaData.assetLocationsWithAssets.getAssetLocationByActiveAsset(assetKey);
				assetKey = assetLocation && assetLocation.gisId ? assetLocation.gisId : assetKey;
			}
			const featureFound = features.find(feature => Object.values(feature.attributes).includes(assetKey));
			if (!featureFound) continue;
			newAsset.geometry = featureFound.geometry;
			newAsset.geometry.geometryType = geometryType;
			newAsset.geometry.spatialReference = spatialReference;
			newAsset.geometry.hasChecked = true;
		}

		workOrder.workAssets.clearDirty();
		return true;
	}

	async deleteWorkAsset(workOrderWrapper: WorkOrderWrapper, workAssetWrapper: WorkAssetWrapper): Promise<boolean> {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		const workAsset = workAssetWrapper?.workAsset as WorkAsset;

		if (!workOrder || !workAsset) return false;

		const response = await this.workorderService.deleteWorkAsset(workAsset).toPromise();
		if (!response || !response.Success) return false;

		const index = workOrder.workAssets.indexOf(workAsset);
		if (index >= 0) workOrder.workAssets.splice(index, 1);
		return true;
	}

	initialize(customerCode?: string) {
		this.workorderService.customerCode = customerCode;
	}

	/**
	 * This function get the initial data for wo
	 */
	getInitialData(): Promise<WorkOrderMetaData> {
		return new Promise<WorkOrderMetaData>(async (resolve, reject) => {
			const { Result } = await this.workorderService.getInitialData().toPromise();
			this.workOrderMetaData = this.hydrateWorkOrderMetaData(Result);
			console.log(this.workOrderMetaData);
			this.currentEmployee = this.getCurrentEmployee();
			resolve(this.workOrderMetaData);
		});
	}

	/** Method to get current employee */
	private getCurrentEmployee(): Employee {
		let employee = this.workOrderMetaData.employees.getByEmployeeId(this.userService.currentUser.userName);
		if (!employee) {
			employee = this.workOrderMetaData.employees.getByEmployeeUsername(this.userService.currentUser.userName);
		}
		return employee;
	}

	async getCapabilities(): Promise<WorkOrderCapabilities> {
		const contract = await this.workorderService.getCapabilities().toPromise();
		if (!contract) return null;

		return WorkOrderCapabilities.fromContract(contract);
	}

	getReadOnlyWorkOrderValues(): string[] {
		const workOrderChannel = this.interopService?.configurationManager?.customerCodeConfiguration?.channels?.find(channel => channel.channelType === ChannelTypes.WorkOrder);
		if (!workOrderChannel) return [];

		return (workOrderChannel.attributes as WorkOrderChannelAttributes).readOnlyWorkOrderValues;
	}

	async getAllWorkOrders(): Promise<AdvancedWorkOrders> {
		return new Promise<AdvancedWorkOrders>(async (resolve, reject) => {
			const now = new Date();

			if (!this._lastTimeStamp || (now.getTime() - this._lastTimeStamp.getTime()) / 1000 > 120) {
				console.log('reloading work orders');
				await this.loadAllWorkOrders();
				this._lastTimeStamp = new Date();
				console.log('work orders loaded');
			}

			if (!this._allWorkOrders) {
				this._allWorkOrders = new AdvancedWorkOrders();
			}

			resolve(this._allWorkOrders);
		});
	}

	async getWorkOrdersByAsset(assetType: string, assetKey: string): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>(async (resolve, reject) => {
			const { Result } = await this.workorderService.getWorkOrdersByAsset(assetType, assetKey).toPromise();
			if (!Result) resolve(new WorkOrderWrappers());

			const workOrders = AdvancedWorkOrders.fromContracts(Result, null, null);
			resolve(WorkOrderWrappers.fromModels(workOrders));
		});
	}

	async getAttachments(referenceKey: string, referenceValue: string): Promise<Attachments> {
		return new Promise<Attachments>(async (resolve, reject) => {
			const { Success, Result } = await this.workorderService.getAttachments(referenceKey, referenceValue).toPromise();
			if (!Success || !Result) {
				resolve(new Attachments());
				return;
			}

			resolve(Attachments.fromContracts(Result));
		});
	}

	async getRelatedWorkOrders(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>(async (resolve, reject) => {
			try {
				const filter = new WorkOrderFilterContract();
				filter.includeRelatedWO = true;
				filter.workorderkey = workOrderWrapper.workOrderKey;
				filter.basicdata = true;
				const { Result } = await this.workorderService.getWorkOrders(filter).toPromise();
				const relatedWorkOrders = AdvancedWorkOrders.fromContracts(Result, null, null);
				resolve(WorkOrderWrappers.fromModels(relatedWorkOrders));
			} catch (e) {
				resolve(new WorkOrderWrappers());
			}
		});
	}

	private async loadAllWorkOrders(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			const filterBody = new WorkOrderFilterContract();
			filterBody.basicdata = true;
			const { Result } = await this.workorderService.getWorkOrders(filterBody).toPromise();

			this._allWorkOrders = AdvancedWorkOrders.fromContracts(Result, null, null);
			resolve(true);
		});
	}

	/**
	 * This function get the workorder by filter
	 * @param {Metric} metric - the metric
	 */
	async getWorkOrderByFilter(metric: Metric, timeFrame?: Timeframe): Promise<void> {
		const { workOrderSourceType } = metric.definition.source;
		if (workOrderSourceType === WorkOrderSourceType.workOrderCosts) {
			metric.result.value = (await metric.result.getWorkorders()).getWorkOrderCostByType((metric.definition.query as AWOQuery).taskFilter.taskkey.split(',')).length.toString();
		} else if (workOrderSourceType === WorkOrderSourceType.workOrderAssets) {
		} else {
			metric.result.value = (await metric.result.getWorkorders()).length.toString();
		}
		metric.result.hasBeenQueried = true;
	}

	async getWorkOrderAssetGeometry(woRawContracts: WorkOrderRawContract[], isInitializing: boolean) {
		const assetsBylayer = {};
		const layerDefs = [];
		const promiseArray = [];
		const layerIdToAssetType = new Object();
		const serversToQuery = this.getServersToQuery(woRawContracts, isInitializing);
		for (const [server, assetsToQuery] of serversToQuery) {
			for (const [layerId, layerAsset] of Object.entries(assetsToQuery)) {
				if (!layerIdToAssetType.hasOwnProperty(layerId)) layerIdToAssetType[layerId] = layerAsset.assetType;
				if (!layerAsset.assetKeys.length) continue;
				const layerQuery = { layerId: Number(layerId), where: `${layerAsset.uniqueFieldName} IN (${layerAsset.assetKeys.join(',')})`, outfields: layerAsset.outfields };
				layerDefs.push(layerQuery);
			}
			const queryBody = `&layerDefs=${JSON.stringify(layerDefs)}&outSR=4326`;
			promiseArray.push(server.queryTransaction(queryBody));
		}
		const all = await Promise.all(promiseArray);
		for (const res of all) {
			for (const result of Object.values(res)) {
				if (result['id'] == null || result['id'] == undefined) continue;
				assetsBylayer[layerIdToAssetType[result['id']]] = result;
			}
		}
		return assetsBylayer;
	}

	private getServersToQuery(woRawContracts: WorkOrderRawContract[], isInitializing: boolean): Map<Server, Object> {
		const serversToQuery = new Map<Server, Object>();
		for (const woRawContract of woRawContracts) {
			if (isInitializing) continue;
			for (const workAsset of woRawContract.WorkAssets) {
				// assetkey could be 'undefined' in string type....
				if (workAsset.AssetKey === 'undefined') continue;
				if (!workAsset.AssetType) continue;
				const assetChannel = this.userService.globalConfig.channels.getAssetChannelByAssetType(workAsset.AssetType);
				if (!workAsset.AssetType || !assetChannel) continue;
				const { uniqueFieldName, assetType, featureServiceLayerIndex } = assetChannel.attributes as ArcGISAssetChannelAttributes;
				const service = this.interopService.arcGISManager.getArcGISService(Number(assetChannel.dataSourceLegacyId));
				const assetLayer = this.interopService.arcGISManager.getAssetLayer(assetType);
				if (!assetLayer) continue;
				if (!uniqueFieldName) continue;
				const uniqueField = assetLayer.fields.find(field => field.name.toLowerCase() === uniqueFieldName.toLowerCase());
				if (!uniqueField) continue;

				const objectidField = assetLayer.fields.find(field => field.name.toLowerCase().includes('objectid'));
				if (!objectidField) continue;

				let assetKey = workAsset.AssetKey;
				if (this.dataChannelService.getWorkOrderSystem() === WorkOrderSystem.Maximo) {
					const assetLocation = this.workOrderMetaData.assetLocationsWithAssets.getAssetLocationByActiveAsset(workAsset.AssetKey);
					assetKey = assetLocation && assetLocation.gisId ? assetLocation.gisId : assetKey;
				}
				if (!assetKey) continue;

				const fieldValueIsString = uniqueField.type === FieldType.esriFieldTypeString;

				// /^\d+$/ to test if assetkey can be parsed to integer, /^\d+\.\d+$/ to test if assetkey can be parsed to float
				if (fieldValueIsString) assetKey = `'${assetKey}'`;
				else if (!/^\d+$/.test(assetKey) && !/^\d+\.\d+$/.test(assetKey)) continue;
				if (!serversToQuery.has(service)) serversToQuery.set(service, new Object());
				if (!serversToQuery.get(service).hasOwnProperty(featureServiceLayerIndex)) {
					serversToQuery.get(service)[featureServiceLayerIndex] = { uniqueFieldName: uniqueField.name, assetKeys: [], outfields: `${objectidField.name},${uniqueField.name}`, assetType };
				}
				serversToQuery.get(service)[featureServiceLayerIndex].assetKeys.push(assetKey);
			}
		}

		return serversToQuery;
	}

	async getSingleWorkOrder(workorderKey: string) {
		const filterBody = new WorkOrderFilterContract();
		filterBody.workorderkey = workorderKey;
		const { Result } = await this.workorderService.getWorkOrders(filterBody).toPromise();
		console.log(Result);
		if (!Result || !Result.length) return null;
		return AdvancedWorkOrder.fromContract(Result[0], null, null);
	}

	async getWorkOrdersByWorkOrderKeys(workOrderKeys: string[], stubsOnly: boolean): Promise<AdvancedWorkOrders> {
		const filterBody = new WorkOrderFilterContract();
		filterBody.workorderkey = workOrderKeys.join(',');
		filterBody.basicdata = stubsOnly;
		const { Result } = await this.workorderService.getWorkOrders(filterBody).toPromise();
		if (!Result || !Result.length) return new AdvancedWorkOrders();
		return AdvancedWorkOrders.fromContracts(Result, null, null);
	}

	/**
	 * This function gets the length of the LEM record from metadata.
	 * @param {Metric} metric - selected metric
	 */
	getLEMLengthFromMetaData(metric: Metric) {
		const { workOrderSourceType } = metric.definition.source;
		const { employees, equipment, materials, vendors } = WorkOrderSourceType;
		switch (workOrderSourceType) {
			case employees:
				metric.result.value = this.workOrderMetaData.employees.length.toString();
				break;
			case equipment:
				metric.result.value = this.workOrderMetaData.equipments.length.toString();
				break;
			case materials:
				metric.result.value = this.workOrderMetaData.materials.length.toString();
				break;
			case vendors:
				metric.result.value = this.workOrderMetaData.vendors.length.toString();
				break;
		}
	}

	/**
	 * This function gets the workorder's x and y coordinates
	 * @param {AdvancedWorkOrders} workOrders - the work order
	 */
	getWorkOrdersXY(workOrders: WorkOrderWrapper[]): void {
		for (const workOrder of workOrders) {
			this.getWorkOrderXY(workOrder);
		}
	}

	getWorkOrderXY(workOrder: WorkOrderWrapper): void {
		const geometries = [];
		for (const { geometry } of workOrder.workAssets) {
			if (!geometry) continue;
			const { x, y } = this.geometryService.getPointGeometry(geometry);
			const point = { x, y, spatialReference: geometry.spatialReference };
			geometries.push(point);
		}
		if (!geometries.length) return;
		const union = this.esriSdkService.esriMapSdk.GeometryEngine.union(geometries);
		workOrder.xCoordinate = union.extent ? union.extent.center.x : union['x'];
		workOrder.yCoordinate = union.extent ? union.extent.center.y : union['y'];
	}

	/**
	 * This function hydrates the work order model
	 * @param {WorkOrderRawContract} workOrderRawContracts - List of workorder raw contracts
	 */
	hydrateWorkOrder(workOrderRawContracts: WorkOrderRawContract[]) {
		const workOrders = AdvancedWorkOrders.fromContracts(workOrderRawContracts, null, null);
		workOrders.sort((a, b) => Number(b.systemId) - Number(a.systemId));
		return workOrders;
	}

	/**
	 * This function will hydrate the workorder meta data model
	 * @param {WorkOrderMetaDataContract} workOrderMetaDataContract
	 */
	hydrateWorkOrderMetaData(workOrderMetaDataContract: WorkOrderMetaDataContract) {
		const workOrderMetaData = WorkOrderMetaData.fromContract(workOrderMetaDataContract);
		return workOrderMetaData;
	}

	generateWorkOrderKeys(keyCount: number): Promise<ContractResponse<object>> {
		return this.workorderService.generteWorkOrderKeys(keyCount).toPromise();
	}

	createWorkOrderModel(workOrderKey: string, assetRecord?: AssetRecord | HierarchyAssetRecord): WorkOrderWrapper {
		const workOrderWrapper = new WorkOrderWrapper(new AdvancedWorkOrder());
		workOrderWrapper.workOrderKey = workOrderKey;
		if (assetRecord instanceof AssetRecord) this.createWorkAssetModel(workOrderWrapper, assetRecord);
		if (assetRecord instanceof HierarchyAssetRecord) this.createWorkAssetFromHierarchyRecordModel(workOrderWrapper, assetRecord);

		return workOrderWrapper;
	}

	createEmployeeCostModel(workOrderWrapper: WorkOrderWrapper): EmployeeCostWrapper {
		const employeeCost = new EmployeeCost();
		if (!workOrderWrapper.isNew) employeeCost.workOrderKey = workOrderWrapper.workOrderKey;
		return new EmployeeCostWrapper(employeeCost);
	}

	createMaterialCostModel(workOrderWrapper: WorkOrderWrapper): MaterialCostWrapper {
		const materialCost = new MaterialCost();
		if (!workOrderWrapper.isNew) materialCost.workOrderKey = workOrderWrapper.workOrderKey;
		return new MaterialCostWrapper(materialCost);
	}

	createEquipmentCostModel(workOrderWrapper: WorkOrderWrapper): EquipmentCostWrapper {
		const employeeCost = new EquipmentCost();
		if (!workOrderWrapper.isNew) employeeCost.workOrderKey = workOrderWrapper.workOrderKey;
		return new EquipmentCostWrapper(employeeCost);
	}

	createWorkTaskModel(workOrderWrapper: WorkOrderWrapper): TaskWrapper {
		const workTask = new WorkTask(workOrderWrapper.workOrderKey);
		workTask.workTaskId = uuid();
		return new TaskWrapper(workTask);
	}

	createWorkCommentModel(workOrderWrapper: WorkOrderWrapper): WorkCommentWrapper {
		return new WorkCommentWrapper(new WorkComment(workOrderWrapper?.workOrderKey));
	}

	createWorkAssetModel(workOrderWrapper: WorkOrderWrapper, asset: AssetRecord): WorkAssetWrapper {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;

		const workAsset = new WorkAsset();
		const { assetType } = asset.assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
		workAsset.assetType = assetType;
		workAsset.assetId = +asset.feature.objectId;
		let assetKey = asset.assetId;
		const assetLocation = this.workOrderMetaData.assetLocationsWithAssets.getAssetLocationByGisId(String(assetKey));
		assetKey = assetLocation && assetLocation.gisId ? assetLocation.gisId : assetKey;
		workAsset.assetKey = String(assetKey);
		workAsset.workOrderKey = workOrder ? workOrder.workOrderKey : '';
		workAsset.systemId = workOrder ? workOrder.systemId : '';
		if (assetLocation && assetLocation.locationId) {
			workOrder.location = assetLocation.locationId;
			workAsset.assetKey = String(assetLocation.activeAsset || assetKey);
		}

		if (workOrder) workOrder.workAssets.push(workAsset);
		return new WorkAssetWrapper(workAsset);
	}

	createWorkAssetFromHierarchyRecordModel(workOrderWrapper: WorkOrderWrapper, hierarchyAssetRecord: HierarchyAssetRecord): WorkAssetWrapper {
		const workOrder = workOrderWrapper?.workOrder as AdvancedWorkOrder;
		if (!workOrder) return null;

		if (!hierarchyAssetRecord || !hierarchyAssetRecord.asset || !hierarchyAssetRecord.assetLocation) return;
		const { assetLocation, asset } = hierarchyAssetRecord;
		const workAsset = new WorkAsset();
		workAsset.assetType = asset.assetType;
		workAsset.assetKey = asset.assetId;
		workAsset.workOrderKey = workOrderWrapper.workOrderKey;
		workAsset.systemId = workOrderWrapper.systemId;
		workOrderWrapper.location = assetLocation.locationId;

		workOrder.workAssets.push(workAsset);
		return new WorkAssetWrapper(workAsset);
	}

	/**
	 * This function gets the asset location where the AssetLocation.gisId matches assetId
	 * @param {string} assetId - AssetRecord.assetId
	 */
	getAssetLocationByGisId(assetId: string): AssetLocationWithAssets {
		if (!assetId || !assetId.length) return null;

		const assetLocations = this.workOrderMetaData?.assetLocationsWithAssets;
		if (!assetLocations) return null;

		return assetLocations.find(assetLocation => assetLocation.gisId === assetId);
	}

	/**
	 * This function gets the asset location where the AssetLocation.activeAsset matches assetKey
	 * @param {string} assetKey - Asset.assetKey
	 */
	getAssetLocationByAssetKey(assetKey: string): AssetLocationWithAssets {
		if (!assetKey || !assetKey.length) return null;

		const assetLocations = this.workOrderMetaData?.assetLocationsWithAssets;
		if (!assetLocations) return null;

		return assetLocations.find(assetLocation => assetLocation.activeAsset === assetKey);
	}

	/**
	 * This function gets the hierarchy of a vertical asset and returns a single hierarchy node of the selected asset location
	 * @param {AssetLocationWithAssets} assetLocation - selected asset location of a vertical asset
	 */
	async getHierarchy(assetLocation: AssetLocationWithAssets): Promise<HierarchyNode<AssetLocationWithAssets>> {
		const assetLocations = this.workOrderMetaData?.assetLocationsWithAssets;
		if (!assetLocation || !assetLocations) return null;

		let topMostParent = this.getTopMostHierarchyKey(assetLocation);
		if (!topMostParent) topMostParent = assetLocation;

		return await this.buildHierarchy(assetLocations, topMostParent, assetLocation, a => a.locationId);
	}

	/**
	 * This function gets the top most parent asset location in a vertical asset
	 * @param {AssetLocationWithAssets} assetLocation - starting asset location in a vertical asset
	 */
	private getTopMostHierarchyKey(assetLocation: AssetLocationWithAssets): AssetLocationWithAssets {
		if (!assetLocation) return null;

		this._selectedAssetLocations = new Array();
		return this.getTopMostHierarchyKeyRecursive(assetLocation);
	}

	/**
	 * This function recursively gets the top most parent asset location in a vertical asset
	 * @param {AssetLocationWithAssets} assetLocation - current asset location in a vertical asset
	 */
	private getTopMostHierarchyKeyRecursive(assetLocation: AssetLocationWithAssets): AssetLocationWithAssets {
		if (this._selectedAssetLocations.includes(assetLocation)) return null;

		this._selectedAssetLocations.push(assetLocation);
		if (assetLocation.gisId && assetLocation.gisId.length) return assetLocation;

		if (!assetLocation.parentId || !assetLocation.parentId.length) return null;

		const parent = this.workOrderMetaData.assetLocationsWithAssets.find(parentLocation => assetLocation.parentId === parentLocation.locationId);
		if (!parent) return null;

		return this.getTopMostHierarchyKeyRecursive(parent);
	}

	/**
	 * This function builds relationships of a vertical asset and returns single hierarchy node of the selected asset location
	 * @param {AssetLocationsWithAssets} assetLocations - array of all asset locations from initialData
	 * @param {AssetLocationWithAssets} topMostParent - top most parent asset location in a vertical asset
	 * @param {AssetLocationWithAssets} selectedLocation - selected asset location in a vertical asset
	 * @param {(AssetLocationWithAssets) => string} keySelector - asset location key selector
	 */
	async buildHierarchy(assetLocations: AssetLocationsWithAssets, topMostParent: AssetLocationWithAssets,
		selectedLocation: AssetLocationWithAssets, keySelector: (_: AssetLocationWithAssets) => string): Promise<HierarchyNode<AssetLocationWithAssets>> {
		if (!assetLocations || !assetLocations.length) return null;

		let foundNode: HierarchyNode<AssetLocationWithAssets>;
		const hierarchyAssetLocations = new Array<AssetLocationWithAssets>();
		const childrenFetcher = (parentId: string, parentNode: HierarchyNode<AssetLocationWithAssets>): Array<HierarchyNode<AssetLocationWithAssets>> => {
			if (parentNode) {
				const childrenNodes = new Array<HierarchyNode<AssetLocationWithAssets>>();
				if (parentNode.value.locationId === selectedLocation.locationId) foundNode = parentNode;
				hierarchyAssetLocations.push(parentNode.value);

				const separatedAssetKeys = parentNode.value.activeAsset ? parentNode.value.activeAsset.split(',') : [];
				for (const assetKey of separatedAssetKeys) {
					if (!assetKey || !assetKey.length) continue;

					const newNode = new HierarchyNode<AssetLocationWithAssets>(new AssetLocationWithAssets(assetKey, true), [...parentNode.ancestors, parentNode]);
					hierarchyAssetLocations.push(newNode.value);
					childrenNodes.push(newNode);
				}

				childrenNodes.push(...assetLocations.filter(a => parentId === a.parentId).map(a => {
					const newNode = new HierarchyNode<AssetLocationWithAssets>(a, [...parentNode.ancestors, parentNode]);
					newNode.children = childrenFetcher(keySelector(a), newNode);
					return newNode;
				}));

				return childrenNodes;
			}

			const nodeList = new Array<HierarchyNode<AssetLocationWithAssets>>();
			const assetLocation = assetLocations.find(a => a.locationId === topMostParent.locationId);
			if (!assetLocation) return nodeList;

			const topMostParentNode = new HierarchyNode<AssetLocationWithAssets>(assetLocation);
			topMostParentNode.children = childrenFetcher(keySelector(assetLocation), topMostParentNode);
			nodeList.push(topMostParentNode);
			return nodeList;
		}

		const topMostNode = childrenFetcher(topMostParent.parentId, null)[0];
		await this.getHierarchyAttachments(hierarchyAssetLocations);
		await this.getHierarchyWorkOrders(hierarchyAssetLocations);
		await this.refreshHierarchyNodes(topMostNode, false, false);
		return foundNode ? foundNode : topMostNode;
	}

	async refreshHierarchyNodes(initialNode: HierarchyNode<AssetLocationWithAssets>,
		updateAttachments: boolean = true, updateWorkOrderCount: boolean = true): Promise<void> {

		if (!initialNode || !initialNode.value) return;
		const assetLocations = new Array<AssetLocationWithAssets>();
		const topMostNode = initialNode.ancestors.length ? initialNode.ancestors[0] : initialNode;
		const func = (parentNode: HierarchyNode<AssetLocationWithAssets>): void => {
			if (!parentNode || !parentNode.value) return;

			assetLocations.push(parentNode.value);
			parentNode.value.childrenWorkOrdersSum = 0;
			for (const child of parentNode.children) {
				func(child);
				parentNode.value.childrenWorkOrdersSum += child.value.locationWorkOrderKeys.length;
				parentNode.value.childrenWorkOrdersSum += child.value.assetWorkOrderCount;
				parentNode.value.childrenWorkOrdersSum += child.value.childrenWorkOrdersSum;
			}
		}

		func(topMostNode);
		if (updateAttachments) await this.getHierarchyAttachments(assetLocations);
		if (!updateWorkOrderCount) return;
		await this.getHierarchyWorkOrders(assetLocations);
		await this.refreshHierarchyNodes(initialNode, false, false);
	}

	private async getHierarchyWorkOrders(assetLocations: AssetLocationWithAssets[]): Promise<void> {
		const locationsWithAsset = assetLocations.filter(al => al.isAssetLoaded);
		await this.getAssets(locationsWithAsset);
		await this.getWorkOrderCountsByAssets(locationsWithAsset);
		await this.getLocationWorkOrders(assetLocations.filter(al => !al.isAssetLoaded), true);
	}

	private async getHierarchyAttachments(assetLocations: AssetLocationWithAssets[]): Promise<void> {
		await this.getAssetLocationAttachments(assetLocations.filter(al => !al.isAssetLoaded), (al) => al.locationId,
			(al, a) => al.locationId === a.referenceValue, 'ASSETLOCATION');
		await this.getAssetLocationAttachments(assetLocations.filter(al => al.isAssetLoaded), (al) => al.activeAsset,
			(al, a) => al.activeAsset === a.referenceValue, 'HIERARCHYASSET');
	}

	/**
	 * This function gets asset location attachments
	 * @param {Array<AssetLocationWithAssets>} assetLocations - array of all asset locations that need their attachments updated
	 */
	private async getAssetLocationAttachments(assetLocations: Array<AssetLocationWithAssets>, referenceValueSelector: (_: AssetLocationWithAssets) => string,
		attachmentSelector: (_: AssetLocationWithAssets, __: Attachment) => boolean, referenceKey: string): Promise<void> {
		const attachments = await this.getAttachments(referenceKey, assetLocations.map(referenceValueSelector).join(','));
		if (!attachments || !attachments.length) return;

		const sortedAttachments = attachments.sort((a, b) => b.attachedDate?.getTime() - a.attachedDate?.getTime());
		for (const assetLocation of assetLocations) {
			const foundAttachment = sortedAttachments.find(a => attachmentSelector(assetLocation, a));
			if (!foundAttachment) continue;

			assetLocation.attachment.updateInformation(foundAttachment);
		}

		const { Success, Result } = await this.uploaderService.getPublicFileIndexes(assetLocations.map(al => al.attachment.fileIndexId)).toPromise();
		if (!Success) return;

		for (const fileResult of Result.Files) {
			if (!fileResult.Success) continue;
			const assetLocation = assetLocations.find(al => al.attachment && al.attachment.fileIndexId === fileResult.FileIndexId);
			if (!assetLocation) continue;
			assetLocation.attachment.thumbnailUrl = fileResult.UrlThumbnail;
		}
	}

	/**
	 * Get work orders for each asset location id
	 * @param {Array<AssetLocationWithAssets>} assetLocations - array of all asset locations that need their work orders updated
	 */
	private async getLocationWorkOrders(assetLocations: Array<AssetLocationWithAssets>, locationOnly = false): Promise<void> {
		const locationWorkOrderKeysResult = await this.getWorkOrderKeysByLocation(assetLocations.map(al => al.locationId), locationOnly);
		if (!locationWorkOrderKeysResult) return;

		for (const locationId of Object.keys(locationWorkOrderKeysResult)) {
			const assetLocation = assetLocations.find(al => al.locationId === locationId);
			if (!assetLocation) continue;
			const workOrderKeysString = locationWorkOrderKeysResult[locationId] as string;
			if (!workOrderKeysString) continue;
			assetLocation.locationWorkOrderKeys = workOrderKeysString.split(',');
		}
	}

	/**
	 * This function creates and returns WorkOrderAttachmentUpload for an attachment upload
	 * @param {File} file - file to upload
	 * @param {AdvancedWorkOrder} workOrder - associated work order
	 */
	getWorkOrderAttachmentUpload(file: File, workOrder: WorkOrderWrapper): WorkOrderAttachmentUpload {
		return new WorkOrderAttachmentUpload(workOrder, new File([file], file.name.split(' ').join('_'), { type: file.type } ), this.saveWorkOrderAttachment);
	}

	/**
	 * This function creates and return AssetLocationAttachmentUpload for an attachment upload
	 * @param {File} file - file to upload
	 * @param {AssetLocationWithAssets} assetLocation - associated asset location
	 */
	getAssetLocationAttachmentUpload(file: File, assetLocation: AssetLocationWithAssets): AssetLocationAttachmentUpload {
		return new AssetLocationAttachmentUpload(assetLocation, new File([file], file.name.split(' ').join('_'), { type: file.type } ), this.saveAssetLocationAttachment);
	}

	/**
	 * This function saves work order attachments
	 * @param {AttachmentUpload} attachmentUpload - attachment upload
	 */
	private saveWorkOrderAttachment = async (attachmentUpload: AttachmentUpload): Promise<AttachmentUpload> => {
		return await this.postAttachment(attachmentUpload as WorkOrderAttachmentUpload, true);
	}

	/**
	 * This function saves asset location attachments
	 * @param {AttachmentUpload} attachmentUpload - attachment upload
	 */
	private saveAssetLocationAttachment = async (attachmentUpload: AttachmentUpload): Promise<AttachmentUpload> => {
		return await this.postAttachment(attachmentUpload as AssetLocationAttachmentUpload, false);
	}

	/**
	 * This function posts attachments that are associated with work orders or asset locations
	 * @param {AttachmentUpload} attachmentUpload - attachment upload
	 * @param {boolean} isWorkOrderAttachment - is WorkOrderAttachmentUpload
	 */
	private async postAttachment(attachmentUpload: AttachmentUpload, isWorkOrderAttachment: boolean): Promise<AttachmentUpload> {
		const workOrderAttachmentUpload = attachmentUpload as WorkOrderAttachmentUpload;
		const assetLocationAttachmentUpload = attachmentUpload as AssetLocationAttachmentUpload;
		const assetLocation = assetLocationAttachmentUpload?.assetLocation;
		const { response } = attachmentUpload;
		const file = response.Result.Files[0];
		const fileIndexIdResponse = file && file.FileIndexId ? Number(file.FileIndexId) : null;

		const attachment = new Attachment();
		attachment.id = '0';
		attachment.attachmentId = 0;
		attachment.fileIndexId = fileIndexIdResponse;
		attachment.referenceKey = isWorkOrderAttachment ? 'WORKORDER' :
			assetLocation.isAssetLoaded ? 'HIERARCHYASSET' : 'ASSETLOCATION';
		attachment.referenceValue = isWorkOrderAttachment ? workOrderAttachmentUpload.workOrder.workOrderKey :
			assetLocation.isAssetLoaded ? assetLocation.activeAsset : assetLocation.locationId;
		attachment.url = file.Url;
		attachment.thumbnailUrl = file.UrlThumbnail;
		attachment.attachedBy = this.userService.currentUser.userName;
		attachment.attachedDate = new Date();
		attachment.comments = null;
		if (isWorkOrderAttachment) attachment.systemId = workOrderAttachmentUpload.workOrder.systemId;
		attachmentUpload.attachmentModel = attachment;
		attachmentUpload.isSuccess = await this.postAttachments([attachmentUpload.attachmentModel]);
		return attachmentUpload;
	}

	/**
	 * This function saves attachments
	 * @param {Array<Attachment>} attachments - array of attachments to save
	 */
	private async postAttachments(attachments: Attachment[]): Promise<boolean> {
		const { Success, Result } = await this.workorderService.postAttachments(attachments.map(a => a.getContract())).toPromise();
		if (!Success) return false;
		let index = 0;
		for (const attachment of attachments) {
			attachment.id = Result[index].toString();
			attachment.clearDirty();
			index++;
		}

		return true;
	}

	/**
	 * Get hierarchy asset info and asset specs
	 * @param {string} locationId - AssetLocation.locationId
	 * @param {string} activeAsset - AssetLocation.activeAsset
	 */
	async getAsset(locationId?: string, activeAsset?: string): Promise<Asset> {
		if (!locationId && !activeAsset) return null;

		const result = await this.workorderService.getAsset(locationId, activeAsset).toPromise();
		if (!result.Success) return null;

		return Asset.fromContract(result.Result[0]);
	}

	/**
	 * Get work order keys for each AssetLocation.locationId
	 * @param {Array<string>} locationIds - Array of AssetLocation.locationId to get work order keys for
	 */
	async getWorkOrderKeysByLocation(locationIds: string[], locationOnly = false): Promise<object> {
		if (!locationIds || !locationIds.length) return {};
		const { Success, Result } = await this.workorderService.getWorkOrderKeysByLocation(locationIds.join(','), locationOnly).toPromise();
		if (!Success || !Result) return {};

		return Result;
	}

	/**
	 * Get work order stubs for each of the work order keys
	 * @param {Array<string>} workOrderKeys - array of AdvancedWorkOrder.workOrderKey to fetch
	 */
	async getWorkOrderStubs(workOrderKeys: string[]): Promise<AdvancedWorkOrder[]> {
		const { Success, Result } = await this.workorderService.getWorkOrderStubsByWorkOrderKeys(workOrderKeys).toPromise();
		if (!Success) return [];

		return AdvancedWorkOrders.fromStubs(Result);
	}

	async getWorkOrderCountsByAssets(assetLocations: AssetLocationWithAssets[]): Promise<void> {
		if (!assetLocations || !assetLocations.length) return;

		const tupleList = new Array<[string, string]>();
		for (const assetLocation of assetLocations) tupleList.push([assetLocation.assetType, assetLocation.activeAsset]);

		const response = await this.workorderService.getWorkOrderCountsByAssets(tupleList).toPromise();
		if (!response || !response.Success || !response.Result) return;

		for (const dict of response.Result) {
			const foundAssetLocation = assetLocations.find(al => al.assetType === dict['assetType'] && al.activeAsset === dict['assetKey']);
			if (!foundAssetLocation) continue;

			foundAssetLocation.assetWorkOrderCount = Number.parseInt(dict['workOrderCount']);
		}
	}

	async getAssets(assetLocations: AssetLocationWithAssets[]): Promise<void> {
		if (!assetLocations || !assetLocations.length) return;

		const response = await this.workorderService.getAsset(assetLocations.map(al => al.locationId).join(','), assetLocations.map(al => al.activeAsset).join(',')).toPromise();
		if (!response || !response.Success || !response.Result) return;

		for (const asset of response.Result) {
			const foundAssetLocation = assetLocations.find(al => al.activeAsset === asset.AssetId);
			if (!foundAssetLocation) continue;

			foundAssetLocation.updateInformation(this.createAssetLocationFromAsset(asset));
		}
	}

	private createAssetLocationFromAsset(asset: AssetContract): AssetLocationWithAssets {
		const assetLocation = new AssetLocationWithAssets();
		assetLocation.activeAsset = asset.AssetId;
		assetLocation.locationId = asset.LocationId;
		assetLocation.assetType = asset.AssetType;
		assetLocation.description = asset.Description;
		return assetLocation;
	}

	getArcGISFields(): ArcGISField[] {
		const fields = this.workorderService?.getArcGISFields();
		const arcGISFields = new Array<ArcGISField>();
		if (!fields) return arcGISFields;

		for (const field of fields) {
			const gisField = new ArcGISField();
			gisField.alias = field.alias;
			gisField.type = field.type;
			gisField.name = field.columnName;
			arcGISFields.push(gisField);
		}

		return arcGISFields;
	}

	private createSummaryField(name: string, text: string, isRequired: boolean, isReadOnly: boolean, inputType: SummaryFieldInputType): WorkOrderField {
		const fieldConfig = new WorkOrderField(null);
		fieldConfig.isVisible = true;
		fieldConfig.name = name;
		fieldConfig.text = text;
		fieldConfig.isRequired = isRequired;
		fieldConfig.isReadOnly = isReadOnly;
		fieldConfig.inputType = inputType;
		return fieldConfig;
	}

	getDefaultSummaryFields(recurrenceOnly: boolean): WorkOrderField[] {
		const fields = new Array<WorkOrderField>();
		fields.push(this.createSummaryField('worktype', 'work type', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('status', 'status', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('assignedto', 'assigned to', false, false, SummaryFieldInputType.LIST));
		if (recurrenceOnly) return fields;
		fields.push(this.createSummaryField('teamid', 'team', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('supervisor', 'owner', false, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('priority', 'priority', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('location', 'location', false, true, SummaryFieldInputType.TEXT));
		if (!this.isMaximoSystem) fields.push(this.createSummaryField('startdate', 'start date', true, false, SummaryFieldInputType.DATETIME_PICKER));
		fields.push(this.createSummaryField('comments', 'comments', false, false, SummaryFieldInputType.LIST));
		return fields;
	}
}
