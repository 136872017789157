import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';
import { OmniModel } from '../omni-model';

export class EmployeeTrade extends OmniModel<EmployeeTrade, Contracts.EmployeeTradeContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _tradeId: SedaruUtils.Undoable<string>;
	get tradeId(): string {
		if (!this._tradeId) this._tradeId = new SedaruUtils.Undoable<string>();
		return this._tradeId.value;
	}
	set tradeId(value: string) {
		if (!this._tradeId) {
			this._tradeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._tradeId.value = value;
	}

	private _name: SedaruUtils.Undoable<string>;
	get name(): string {
		if (!this._name) this._name = new SedaruUtils.Undoable<string>();
		return this._name.value;
	}
	set name(value: string) {
		if (!this._name) {
			this._name = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._name.value = value;
	}

	private _hourlyRate: SedaruUtils.Undoable<number>;
	get hourlyRate(): number {
		if (!this._hourlyRate) this._hourlyRate = new SedaruUtils.Undoable<number>();
		return this._hourlyRate.value;
	}
	set hourlyRate(value: number) {
		if (!this._hourlyRate) {
			this._hourlyRate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._hourlyRate.value = value;
	}

	static fromContract(contract: Contracts.EmployeeTradeContract): EmployeeTrade {
		const model = new EmployeeTrade();
		model.id = contract.ObjectId.toString();
		model.tradeId = contract.TradeId;
		model.name = contract.Name;
		model.hourlyRate = contract.HourlyRate;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.EmployeeTradeContract => {
		const contract = new Contracts.EmployeeTradeContract();
		contract.ObjectId = +this.id;
		contract.HourlyRate = +this.hourlyRate;
		contract.TradeId = this.tradeId;
		contract.Name = this.name;
		contract.HourlyRate = this.hourlyRate;
		return contract;
	};

	onUpdateInformation(copyModel: EmployeeTrade): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._hourlyRate, () => {
				this.hourlyRate = copyModel.hourlyRate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._name, () => {
				this.name = copyModel.name;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._tradeId, () => {
				this.tradeId = copyModel.tradeId;
			}) || wasChanged;

		return wasChanged;
	}
}
