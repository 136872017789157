<div class="list-wrapper">
	<ng-container *ngFor="let metricRecord of metricRecords | slice: 0:displayedRecordCount">
		<app-list-item
			[resolver]="metricRecord.resolver"
			[item]="metricRecord"
			(itemClicked)="selectRecord(metricRecord)"
			(mouseenter)="highLightWorkOrder(metricRecord.contextGroup)"
			(mouseleave)="clearHighlight()"
		></app-list-item>
	</ng-container>
</div>
