import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { Template, TemplateType } from '../template.model';
import { Binding, BindingPlaceholders } from '../binding.model';
import { FormTransform } from './form-transform';

export class TemplateTransform extends TransformBase {

    protected onTransform(): TransformationResult {

        if (this.fieldforceConfig.Design.Templates.length === 0) return this.getResult(true);

        for (const ffTemplate of this.fieldforceConfig.Design.Templates) {
            const template = new Template();
            template.id = ffTemplate.ID;
            template.description = ffTemplate.Description;
            template.metricId =  ffTemplate.MetricID ? ffTemplate.MetricID : null;
            template.type = this.getTemplateType(ffTemplate.Type);
            this.fillBindings(template, ffTemplate);
            this.globalConfig.templates.push(template);
        }

        return this.getResult(true);
    }

    private getTemplateType(templateName: string): TemplateType {
        if (!templateName) return TemplateType.None;

        if (templateName === 'ListThreeFieldTemplate') return TemplateType.ListThreeFieldTemplate;

        if (templateName === 'SingleTextTemplate') return TemplateType.SingleTextTemplate;

        return TemplateType.None;
    }

    private fillBindings(template: Template, ffTemplate: any) {
        for (const ffBinding of ffTemplate.Bindings) {
            const binding = this.getBinding(ffBinding);
            template.bindings.push(binding);
        }
    }

    private getBinding(ffBinding: any): Binding {
        const binding = new Binding();

        if (ffBinding.Placeholder.toLowerCase() === 'header') binding.placeholder = BindingPlaceholders.Header;
        if (ffBinding.Placeholder.toLowerCase() === 'details') binding.placeholder = BindingPlaceholders.Details;
        if (ffBinding.Placeholder.toLowerCase() === 'extra') binding.placeholder = BindingPlaceholders.Extra;
        if (ffBinding.Placeholder.toLowerCase() === 'text') binding.placeholder = BindingPlaceholders.Text;

        const formTransform = FormTransform.getFormControlValue(ffBinding.Value);
        binding.value = formTransform;

        return binding;
    }
}
