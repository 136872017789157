import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, ElementRef } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Pages } from 'app/navigation/inavigation';

@Component({
	selector: 'app-workorder-filter-date-picker',
	templateUrl: './workorder-filter-date-picker.component.html'
})
export class WorkOrderFilterDatePickerComponent extends MenuPanelBaseComponent {
	static dateResolver: (date: Date) => any;

	public get pageIdentifier(): Pages {
		return Pages.workOrderFilterDatePicker;
	}

	ngOnDestroy() {
		WorkOrderFilterDatePickerComponent.dateResolver = null;
	}

	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	dateSelected(event: MatDatepickerInputEvent<Date>) {
		WorkOrderFilterDatePickerComponent.dateResolver(new Date(event.value));
	}
}
