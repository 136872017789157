import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { HistoryProvidersTransform } from './history-providers-transform';
import { AssetProvidersTransform } from './asset-providers-transform';
import { FormTransform } from './form-transform';

export class AssetLayersTransform extends TransformBase {
	onTransform(): TransformationResult {
		for (const assetLayerConfig of this.fieldforceConfig.AssetLayers) {
			if (!assetLayerConfig.AssetType) {
				// we ignore asset layers that don't have assetType defined
				continue;
			}

			if (!assetLayerConfig.DataAssetProvider) {
				// we ignore asset layers that don't have an asset provider
				continue;
			}

			const assetDefinition = new SedaruModels.AssetDefinition(this.globalConfig);
			assetDefinition.assetType = assetLayerConfig.AssetType;
			assetDefinition.legacyId = assetLayerConfig.ID;
			assetDefinition.title = assetLayerConfig.Title;
			assetDefinition.enabled = assetLayerConfig.Enabled;
			assetDefinition.useHierarchy = assetLayerConfig.UseHierarchy;
			assetDefinition.styleId = assetLayerConfig.StyleID;
			assetDefinition.iconResourceId = assetLayerConfig.IconResourceID;
			this.globalConfig.assetDefinitions.push(assetDefinition);

			if (assetLayerConfig.DataHistoryProvider) {
				const historyProviderTransform = new HistoryProvidersTransform(this.fieldforceConfig, this.globalConfig, assetLayerConfig, assetDefinition);
				historyProviderTransform.transform();
			}

			if (assetLayerConfig.DataAssetProvider) {
				const assetProviderTransform = new AssetProvidersTransform(this.fieldforceConfig, this.globalConfig, assetLayerConfig, assetDefinition);
				assetProviderTransform.transform();
			}

			if (assetLayerConfig.InformationForm || (assetLayerConfig.Forms && assetLayerConfig.Forms.length > 0)) {
				const formTransform = new FormTransform(this.fieldforceConfig, this.globalConfig, assetLayerConfig, assetDefinition);
				formTransform.transform();
			}

			if (assetLayerConfig.Templates) {
				assetDefinition.assetMetricTemplateId = assetLayerConfig.Templates.AssetTooltipTemplateID;
				assetDefinition.historyMetricTemplateId = assetLayerConfig.Templates.AssetHistoryListTemplateID;
				assetDefinition.workOrderMetricTemplateId = assetLayerConfig.Templates.ActiveListTemplateID;
			}
		}

		return this.getResult(true);
	}
}
