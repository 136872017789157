import { MenuPanelNavigationEntry } from './menu-panel-navigation-entry';

enum ImageSrcs {
	defaultSrc = 'assets/previous-circled.png',
	deleteSrc = 'assets/delete.png',
	saveSrc = 'assets/save.png',
	pacifier = 'gif'
}

export class NavigationButtonEntry {
	constructor(private _menuPanelNavigationEntry: MenuPanelNavigationEntry) {}

	private _type: string;
	public get type(): string {
		return this._type;
	}
	public set type(value) {
		this._type = value;
	}

	private _alignment: string;
	public get alignment(): string {
		return this._alignment;
	}
    public set alignment(value: string) {
        this._alignment = value;
    }

	private _selectedAction: () => void;
	public get selectedAction() {
		return this._selectedAction;
	}
	public set selectedAction(value: () => void) {
		this._selectedAction = value;
	}

	private _text: string;
	public get text(): string {
		return this._text;
	}
	public set text(value: string) {
		this._text = value;
	}

	private _toolTip: string;
	public get toolTip(): string {
		return this._toolTip;
	}
	public set toolTip(value: string) {
		this._toolTip = value;
	}

	private _isVisible: boolean;
	public get isVisible(): boolean {
		return this._isVisible;
	}
	public set isVisible(value: boolean) {
		this._isVisible = value;
	}

	private _isEnabled: boolean;
	public get isEnabled(): boolean {
		return this._isEnabled;
	}
	public set isEnabled(value: boolean) {
		this._isEnabled = value;
	}

	private _icon: string;
	public get icon(): string {
		return this._icon;
	}
	public set icon(value: string) {
		this._icon = value;
	}
	public get iconImgs() {
		return ImageSrcs;
	}

	private _isBusy: boolean;
	public get isBusy(): boolean {
		return this._isBusy;
	}
	public set isBusy(value: boolean) {
		this._isBusy = value;
	}

	private _selected: () => {};

	private _badgeText: string;
	public get badgeText(): string {
		return this._badgeText;
	}
	public set badgeText(value: string) {
		this._badgeText = value;
	}
}
