import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { GlobalConfig } from '../../../models/global-config.model';

export class AssetProvidersTransform extends TransformBase {
	private _assetLayerConfig: any;
	private _assetDefinition: SedaruModels.AssetDefinition;

	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig, assetLayerConfig: any, assetDefinition: SedaruModels.AssetDefinition) {
		super(fieldforceConfig, globalConfiguration);
		this._assetDefinition = assetDefinition;
		this._assetLayerConfig = assetLayerConfig;
	}

	onTransform(): TransformationResult {
		if (!this._assetLayerConfig.DataAssetProvider) {
			return this.getResult(true, 'no asset provider');
		}

		const assetChannel = this.getAssetChannel(this._assetLayerConfig.DataAssetProvider);

		if (!assetChannel) {
			return this.getResult(false, `unable to generate asset channel for ${this._assetDefinition.assetType}`);
		}

		this._assetDefinition.assetChannelId = assetChannel.id;
		this._assetDefinition.assetChannel = assetChannel;
		this.globalConfig.channels.push(assetChannel);

		return this.getResult(true);
	}

	getAssetChannel(dataAssetProvider: any): SedaruModels.Channel {
		if (!dataAssetProvider) {
			return null;
		}

		if (dataAssetProvider.AssetProviderID === 0) {
			return;
		}

		const provider = this.getAssetProvider(dataAssetProvider.AssetProviderID);
		if (!provider) {
			console.log(`Unable to find asset provider`);
			return null;
		}

		const attributes = this.getAssetChannelAttributes(dataAssetProvider);
		if (!attributes) {
			console.log(`Unable to generate asset channel`);
			return null;
		}

		const assetChannel = new SedaruModels.Channel(this.globalConfig);
		assetChannel.name = `${this._assetLayerConfig.AssetType.toLowerCase()} asset channel`;
		attributes.assetType = this._assetLayerConfig.AssetType;
		assetChannel.channelType = SedaruModels.ChannelTypes.Asset;
		let serviceConfig = this.getServiceConfig(provider.MapService.ServiceID);
		attributes.mapServiceDataSourceLegacyId = serviceConfig.ID;
		serviceConfig = this.getServiceConfig(provider.FeatureService.ServiceID);
		assetChannel.dataSourceLegacyId = serviceConfig.ID;
		assetChannel.attributes = attributes;

		return assetChannel;
	}

	getAssetProvider(id: number): any {
		const hasAssetProviders =
			this.fieldforceConfig && this.fieldforceConfig.DataProviders && this.fieldforceConfig.DataProviders.AssetProviders && this.fieldforceConfig.DataProviders.AssetProviders.length > 0;
		if (!hasAssetProviders) {
			return;
		}

		for (const assetProvider of this.fieldforceConfig.DataProviders.AssetProviders) {
			if (assetProvider.ID !== id) {
				continue;
			}

			return assetProvider;
		}

		return null;
	}

	getAssetChannelAttributes(dataAssetProvider: any): SedaruModels.ArcGISAssetChannelAttributes {
		if (!dataAssetProvider) {
			return null;
		}

		const attributes = new SedaruModels.ArcGISAssetChannelAttributes();
		attributes.featureServiceLayerIndex = dataAssetProvider.FeatureLayerIndex;
		attributes.mapServiceLayerIndex = dataAssetProvider.MapLayerIndex;

		if (!dataAssetProvider.ProviderFields) {
			return attributes;
		}

		if (!dataAssetProvider.ProviderFields.UniqueIDField) {
			return attributes;
		}

		attributes.uniqueFieldName = dataAssetProvider.ProviderFields.UniqueIDField;
		return attributes;
	}
}
