<div class="list-wrapper">
	<app-omni-list
		[omniListItems]="metrics"
		(rightIconClicked)="onEditMetricItem($event)"
		(itemClicked)="onMetricSelected($event)"
		[selectedItems]="[currentlySelectedMetric]"
		(scrolled)="onScroll($event)"
		[itemsKey]="'id'"
		[initialScroll]="initialScrollPosition"
	></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button *ngIf="showRemoveOption" [class.disabled]="!disableRemoveOption" mat-button class="omni-md-font" (click)="disableRemoveOption && onNoneClick()">remove <img class="omni-sm-img" src="assets/delete.png"/></button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" (click)="onAddMetricItem()">add metric <img class="omni-sm-img" src="assets/plus.png" /></button>
	</mat-card-actions>
</mat-card-footer>
