import { SedaruClientMethodHandlerBase } from './sedaru-client-method-handler-manager';

export class MethodHandlerResultBase {
	private static _empty: MethodHandlerResultBase;
	public static get empty(): MethodHandlerResultBase {
		if (!MethodHandlerResultBase._empty) MethodHandlerResultBase._empty = new MethodHandlerResultBase();

		return MethodHandlerResultBase._empty;
	}

	public hasTimedOut: boolean;
	public wasHandled: boolean;
	public cancelled: boolean;
	public message: string;
	public success: boolean;
}

export class MethodHandlerResult<T extends SedaruClientMethodHandlerBase> extends MethodHandlerResultBase {
	private _methodHandler: T;
	public get methodHandler(): T {
		return this._methodHandler;
	}

	protected constructor(methodHandler: T) {
		super();
		this._methodHandler = methodHandler;
	}
}
