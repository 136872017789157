import { DataChannelService } from 'domain-service/data-channel.service';
import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { WorkOrderMetaData, WorkOrderWrapper, WorkOrderWrappers } from 'models/work-order';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { Metric, MetricTile } from 'models';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { MetricRecord } from 'models/records/metric-record.model';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';

@Component({
	selector: 'app-workorder-list',
	templateUrl: './workorder-list.component.html',
	styleUrls: ['./workorder-list.component.scss']
})
export class WorkOrderListComponent extends MenuPanelBaseComponent {
	/** alerts when a workorder has been clicked */
	@Output() workorderSelected = new EventEmitter();
	@Output() scrolled = new EventEmitter<number>();
	/** the workorder data to be displayed */
	private _workOrders;
	@Input() set workorders(workOrders: WorkOrderWrappers | Array<WorkOrderWrapper>) {
		this._workOrders = workOrders;
		this.createMetricRecords();
	}
	get workorders() {
		return this._workOrders;
	}
	@Input() workorderMetaData: WorkOrderMetaData;
	@Input() advancedMode: boolean;
	@Input() initialScroll = 0;
	@Input() metric: Metric;
	@Input() activeTile: MetricTile;
	@Input() config: GuiConfig;

	metricRecords: MetricRecord[];

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	get channelType() {
		return this.metric.definition.source.type;
	}

	private initialRecordCount = 50;
	@Input() displayedRecordCount: number;

	private highlightPromise: Promise<any>;
	private dontClearHighlight = false;

	constructor(
		private dataChannelService: DataChannelService,
		private graphicsService: OmniMapGraphicsService,
		private interopService: OmniInteropService,
		private esriSdkService: EsriSdkService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	ngOnInit() {
		this.displayedRecordCount = this.initialRecordCount;
	}

	createMetricRecords() {
		this.metricRecords = [];
		this.workorders.forEach(workorder => {
			const context = new RecordContextGroup();
			context.workOrderRecord = workorder;
			const metricRecord = new MetricRecord();
			metricRecord.contextGroup = context;
			const metricManagerResolver = this.interopService.templateManager.getTemplateResolver(context);
			metricManagerResolver.showDivider = item => true;
			metricManagerResolver.onIsDisabled = item => false;
			metricRecord.resolver = metricManagerResolver;
			this.metricRecords.push(metricRecord);
		});
	}

	highLightWorkOrder(context: RecordContextGroup, zoom = false) {
		if (!this.config) return;
		const workorderkey = context.workOrderRecord.workOrderKey || context.workOrderRecord.attributes.workorderkey;
		this.highlightPromise = this.graphicsService.highlightWorkOrder(workorderkey, this.activeTile.id, this.config.selectedCanvas).then(graphic => {
			if (!graphic) return;
			graphic.geometry = this.esriSdkService.esriMapSdk.Projection.project(graphic.geometry, this.config.selectedCanvas.mapView.spatialReference) as __esri.Geometry;

			if (zoom) this.config.selectedCanvas.mapView.goTo({ target: graphic, scale: 1000 }, { duration: 1000, easing: 'ease-out' });
			this.showPopUpOnHover(graphic, context);
		});
	}

	clearHighlight() {
		if (!this.highlightPromise || this.dontClearHighlight) return;
		this.highlightPromise.then(g => {
			this.graphicsService.clearHighlightedFeature(this.config.selectedCanvas);
			this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip.close();
		});
	}

	/**
	 * returns the workorder's start date if the status is active,
	 * otherwise it returns the completed date
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getDate(workOrder: WorkOrderWrapper): string {
		const completeCodes = this.dataChannelService.getCompletedWorkOrderValues();
		const date = completeCodes.includes(workOrder.status) ? workOrder.completedDate : workOrder.startDate;
		if (!date) return '';
		const woDate = date.split(',');
		return woDate[0];
	}

	/**
	 * Returns the path of an orange icon if the workorder is active,
	 * otherwise it returns the path of a green icon
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getWorkOrderIcon(workOrder: WorkOrderWrapper): string {
		const completeCodes = this.dataChannelService.getCompletedWorkOrderValues();
		return completeCodes.includes(workOrder.status) ? 'assets/workorder-completed.png' : 'assets/workorder.png';
	}

	/**
	 * This function gets the employee name for the workorder
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getEmployeeName(workorder: WorkOrderWrapper): string {
		if (!workorder || !this.workorderMetaData || !this.workorderMetaData.employees) return '';

		const employee = this.workorderMetaData.employees.getByEmployeeId(workorder.assignedTo);

		if (!employee || !employee.name) return '';

		return employee.name;
	}

	/**
	 * This function gets the system id for the workorder
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getSystemId(workorder: WorkOrderWrapper): string {
		if (workorder.systemId) return workorder.systemId;

		return '';
	}

	/**
	 * This function gets the workorder key for the workorder
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getWorkorderKey(workorder: WorkOrderWrapper): string {
		if (workorder.workOrderKey) return workorder.workOrderKey;

		return '';
	}

	/**
	 * This function gets the work type for the workorder
	 * @param {AdvancedWorkOrder} workorder - The workorder
	 */
	getWorkType(workorder: WorkOrderWrapper): string {
		if (workorder.workType) return workorder.workType;

		return '';
	}

	selectRecord(metricRecord: MetricRecord) {
		this.dontClearHighlight = true;
		this.highLightWorkOrder(metricRecord.contextGroup, true);
		this.workorderSelected.emit(metricRecord.contextGroup.workOrderRecord);
	}

	private showPopUpOnHover(graphic, context) {
		if (!graphic) return;
		if (!this.interopService.uiManager.activeCanvasComponent.canvasMapComponent) return;
		const mapComponent = this.interopService.uiManager.activeCanvasComponent.canvasMapComponent;
		mapComponent.mapTooltip.location = this.esriSdkService.esriMapSdk.Projection.project(graphic.geometry, this.config.selectedCanvas.mapView.spatialReference);
		mapComponent.mapTooltip.location = graphic.geometry;
		mapComponent.mapTooltip.resolver = this.interopService.templateManager.getTemplateResolver(context);
		mapComponent.mapTooltip.open();
	}
}
