import { Role } from './../../../../models/role.enum';
import { TabScopeType } from './../../../../omni-model/tab-scope.enum';
import { Component, ElementRef } from '@angular/core';
import { GuiConfigService } from '../../../../domain-service/gui-config.service';
import { GuiConfig } from '../../../../omni-model/gui-config.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'app/ui-components/delete-dialog/delete-dialog.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { TileService } from 'app/tiles/tile.service';
import { MetricTile } from 'models';
import { RemoveCanvasAndTilesDialogComponent } from 'app/canvas-container/remove-canvas-and-tiles-dialog/remove-canvas-and-tiles-dialog.component';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { Canvas } from 'omni-model/canvas.model';
import { TabChangedWarningDialogComponent } from './tab-changed-warning-dialog/tab-changed-warning-dialog.component';
import { WarningMessageService } from './tab-changed-warning-dialog/warning-message.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { TabChangedHandler } from 'domain-service/subscriptions/handlers/tab-changed-handler';
import { PageGroup, PageGroupName } from 'app/navigation/page-group';
import { UserService } from 'app/user/user.service';

/**
 * This component is designed to be placed in the left side panel.
 * This is the component that will be initially loaded in the left panel when the app loads for the first time.
 * The component is also loaded when the settings (gear) icon on the tab is clicked.
 */
@Component({
	selector: 'app-tab-settings-main-menu',
	templateUrl: './tab-settings-main-menu.component.html',
	styleUrls: ['./tab-settings-main-menu.component.scss']
})
export class TabSettingsMainMenuComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the currently selected tab.
	 */
	tab: GuiConfig;

	dialogHandler: MatDialogRef<any, any>;

	pageGroup = PageGroup.get(PageGroupName.tab);

	get isSettingDirty(): 'name' | 'content' | 'canvasLayout' | 'tilesLayout' | 'theme' | 'scope' | null {
		if (this.tab.canvasLayout !== this.config.canvasLayout) return 'canvasLayout';
		if (this.tab.tilesLayout !== this.config.tilesLayout) return 'tilesLayout';
		if (this.tab.name !== this.config.name) return 'name';
		if (this.tab.content !== this.config.content) return 'content';
		if (this.tab.theme.displayName !== this.config.theme.displayName) return 'theme';
		if (this.tab.scope !== this.config.scope) return 'scope';
		return null;
	}

	isSaving = false;

	get hasDeletePermission() {
		if (this.config.scope == TabScopeType[TabScopeType.Myself]) return true;

		if (!this.interopService.omniDomain || !this.interopService.omniDomain.userService || !this.interopService.omniDomain.userService.currentUser) return false;

		const { userName, role } = this.interopService.omniDomain.userService.currentUser;

		if (role.toLowerCase() === Role.Admin) return true;

		if (role.toLowerCase() === Role.Manager) {
			if (this.config.createdBy.toLowerCase() === userName.toLowerCase()) return true;
		}

		return false;
	}

	public get pageIdentifier(): Pages {
		return Pages.tabSettings;
	}

	/**
	 * Timeout handler that waits 1 sec before updating the name of the tab
	 * to avoid multiple calls to the updateConfig api as the user types the tab's name
	 */
	waitForUserInputTimeoutHandler;

	/**
	 * The time in milliseconds that the component will wait before hitting the api
	 */
	waitTimeForUserInput = 1000;
	/**
	 * The current dialog refrence
	 */
	dialogRef: MatDialogRef<any, any>;

	get visibility() {
		if (!this.tab) return '';
		switch (this.tab.scope) {
			case TabScopeType[TabScopeType.Myself]:
				return 'just me';
			case TabScopeType[TabScopeType.Team]:
				return 'team';
			case TabScopeType[TabScopeType.Public]:
				return 'all users';
		}
	}

	get layout() {
		switch (this.tab.canvasLayout) {
			case 1:
				return this.tab.tilesLayout + ' tiles, ' + this.tab.canvasLayout + ' canvas';
			case 2:
			case 3:
			case 4:
				return this.tab.tilesLayout + ' tiles, ' + this.tab.canvasLayout + ' canvases';

			case 5:
				return this.tab.tilesLayout + ' tiles, 2 canvases';

			case 6:
				return this.tab.tilesLayout + ' tiles, 3 canvases';
		}
	}

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {GuiConfigService} guiConfigService - Provides all the services to manipulate the OMNI tab.
	 */
	constructor(
		private guiConfigService: GuiConfigService,
		private tileService: TileService,
		private canvasService: CanvasService,
		private dialog: MatDialog,
		private warningService: WarningMessageService,
		private interopService: OmniInteropService,
		private userService: UserService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args.previousPage && this.pageGroup.has(args.previousPage.pageIdentifier)) {
			this.tab = args.previousPage['configChanges'];
		} else {
			this.tab = Object.assign({}, this.config);
		}
	}

	/**
	 * On init, currently selected tab is initialized and the current route is emitted.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'tab settings', backgroundClass: 'cyan-background' });
	}

	/**
	 * This method is invoked when you try to enter or edit the tab name from the tab settings panel.
	 * It updates the name of the currently selected tab.
	 */
	changeTabNameAction(): void {}

	goTo(address: string) {
		switch (address) {
			case 'content':
				NavigationService.navigateTo(Pages.tabContentType, { configChanges: this.tab });
				break;
			case 'visibility':
				NavigationService.navigateTo(Pages.tabVisibility, { configChanges: this.tab });
				break;
			case 'theme':
				NavigationService.navigateTo(Pages.tabTheme, { configChanges: this.tab });
				break;
			case 'layout':
				NavigationService.navigateTo(Pages.layout, { configChanges: this.tab });
				break;
		}
	}

	/**
	 * This method will open up a dialog to ask user to confirm upon deleting a tile
	 */
	openDeletePrompt(): void {
		this.dialogRef = this.dialog.open(DeleteDialogComponent, { width: '480px' });
		const dialogConfig = {
			title: 'Remove Tab',
			content: 'Removing this tab will remove its related tiles & canvas and cannot be undone. Other users sharing this tab might be impacted.'
		};
		this.dialogRef.componentInstance.config = dialogConfig;
		this.dialogRef.componentInstance.confirmMessage = 'Remove';
		this.dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
			if (isConfirmed) {
				this.deleteTab();
			}
		});
	}

	async deleteTab() {
		const configIdToDelete = this.config.id;
		await this.guiConfigService.deleteGuiConfiguration(configIdToDelete).toPromise();
		const otherAvailableConfigs = this.guiConfigService.availableConfigurations.filter(config => config.id !== configIdToDelete && config.isEnabled);
		let newActiveConfig: GuiConfig;
		if (otherAvailableConfigs.length) {
			newActiveConfig = otherAvailableConfigs[0];
			this.guiConfigService.setSelectedConfig(newActiveConfig.id);
		} else {
			const partialConfig = GuiConfig.generateNewConfig(this.userService.getCurrentProfileGroup());
			const configFromDb = await this.guiConfigService.addConfigToDb(partialConfig).toPromise();
			newActiveConfig = this.guiConfigService.addConfigToAvailableConfigs(configFromDb.id, partialConfig);
		}
		const indexToDelete = this.guiConfigService.availableConfigurations.findIndex(config => config.id === configIdToDelete);
		const configRemoved = this.guiConfigService.availableConfigurations.splice(indexToDelete, 1);
		this.guiConfigService
			.updateTabOrder()
			.toPromise()
			.then(() => {
				if (this.config.scope !== TabScopeType[TabScopeType.Myself]) {
					const tabChangeHandler = this.interopService.omniDomain.subscriptionService.hub.methodHandlers.getHandler('TabChangedHandler') as TabChangedHandler;
					tabChangeHandler.sendTabNotification(this.tab.id, 'delete', null);
				}
				this.interopService.uiManager.appComponent.layoutComponent.tabPanelComponent.tabListWrapper.nativeElement.scrollTo(0, 0);
				this.interopService.uiManager.appComponent.layoutComponent.tabPanelComponent.tabSelected.emit(newActiveConfig);
				this.interopService.uiManager.appComponent.layoutComponent.activatedConfigs.dropItem(configRemoved[0]);
			});
	}

	async save() {
		this.isSaving = true;
		this.menuPanelComponent.updatePacifier(true);

		const canSave = await this.canSave();
		if (!canSave) {
			this.isSaving = false;
			this.menuPanelComponent.updatePacifier(false);
			return;
		}

		const tabChangeHandler = this.interopService.omniDomain.subscriptionService.hub.methodHandlers.getHandler('TabChangedHandler') as TabChangedHandler;
		const changesMade = [];
		while (this.isSettingDirty) {
			try {
				switch (this.isSettingDirty) {
					case 'tilesLayout':
						const tilesUpdateSuccess = await this.setTileCount(this.tab.tilesLayout);
						if (!tilesUpdateSuccess) {
							this.cancel();
							break;
						}
						changesMade.push('tile-layout');
						break;
					case 'canvasLayout':
						const canvasUpdateSuccess = await this.setCanvasLayout(this.tab.canvasLayout);
						if (!canvasUpdateSuccess) {
							this.cancel();
							break;
						}
						changesMade.push('canvas-layout');
						break;
					case 'name':
						await this.guiConfigService.updateConfigurationProperty(this.config, 'name', this.tab.name);
						this.config.name = this.tab.name;
						changesMade.push('name');
						break;
					case 'content':
						await this.guiConfigService.updateConfigurationProperty(this.config, 'content', this.tab.content);
						this.config.content = this.tab.content;
						changesMade.push('content');
						break;
					case 'scope':
						await this.guiConfigService.updateTabScope(this.config.id, TabScopeType[TabScopeType[this.tab.scope]]).toPromise();
						this.config.scope = this.tab.scope;
						changesMade.push('scope');
						break;
					case 'theme':
						await this.guiConfigService.updateConfigurationProperty(this.config, 'theme', this.tab.theme);
						this.config.theme = this.tab.theme;
						changesMade.push('theme');
				}
			} catch (error) {
				console.error(error);
				this.isSaving = false;
				this.menuPanelComponent.updatePacifier(false);
			}
		}
		if (changesMade.length && this.config.scope !== TabScopeType[TabScopeType.Myself]) tabChangeHandler.sendTabNotification(this.config.id, changesMade, this.tab.tilesLayout);
		this.isSaving = false;
		this.menuPanelComponent.updatePacifier(false);
	}

	cancel() {
		this.tab = Object.assign({}, this.config);
		this.menuPanelComponent.navigationRouter.currentBackStackEntry.parameter = this.tab;
	}

	private canSave(): Promise<boolean> {
		return new Promise(solve => {
			if (this.tab.scope === TabScopeType[TabScopeType.Myself] && this.tab.scope === this.config.scope) {
				solve(true);
				return;
			}
			if (this.tab.scope === TabScopeType[TabScopeType.Myself] && this.tab.scope !== this.config.scope) this.warningService.type = 'private-to-public';

			if (this.tab.scope !== TabScopeType[TabScopeType.Myself]) this.warningService.type = 'public-changes';

			this.dialogHandler = this.dialog.open(TabChangedWarningDialogComponent, { width: '480px' });
			this.dialogHandler.afterClosed().subscribe(confirmed => {
				if (confirmed) solve(true);
				else solve(false);
			});
		});
	}

	/**
	 * This method is invoked when the different tile layouts are selected from the layouts side panel.
	 * @param {number} count - It will be either of the following values,
	 * 0 - when the tab is set to have no tiles
	 * 6 - when the tab is set to have 6 tiles
	 * 12 - when the tab is set to have 12 tiles.
	 */
	private setTileCount(count: number): Promise<boolean> {
		return new Promise(async solve => {
			const metricTilesAtRisk: MetricTile[] = this.tileService.getMetricTilesAtRisk(count, this.config);

			if (metricTilesAtRisk && metricTilesAtRisk.length) {
				this.dialogHandler = this.dialog.open(RemoveCanvasAndTilesDialogComponent, {
					width: '480px'
				});
				this.dialogHandler.afterClosed().subscribe(confirmed => {
					if (!confirmed) {
						this.tileService.tilesAtRisk = [];
						solve(false);
						return;
					}
					const idList: string[] = metricTilesAtRisk.map((metricTile: MetricTile) => metricTile.id);
					// Need to pass in the id as a list of ids, even if its one
					this.tileService.deleteMetricTile(idList).subscribe(async () => {
						this.config.canvasList.forEach(canvas => {
							canvas.tabs.forEach(tab => {
								metricTilesAtRisk.forEach(tileAtRisk => {
									if (tab.tile && tab.tile.id === tileAtRisk.id) {
										this.canvasService.unlinkTileFromCanvas(tileAtRisk.id, tileAtRisk.metric.id, canvas, tileAtRisk.metric.definition.name);
									}
								});
							});
						});
						await this.guiConfigService.updateConfigurationProperty(this.config, 'tilesLayout', count);
						this.config.tilesLayout = count;
						// Check and see if tiles at risk includes the selected tile
						const selectedTileWasAtRisk = metricTilesAtRisk.find(tile => tile === this.config.selectedTile);
						// If there the selected tile was also at risk, set the selected tile to the first tile in the list after deleted
						if (selectedTileWasAtRisk) {
							this.config.selectedTile = this.config.tileList[0];
						}
						for (const metricTile of metricTilesAtRisk) {
							metricTile.clear();
						}
						solve(true);
					});
					this.tileService.tilesAtRisk = [];
				});
			} else {
				await this.guiConfigService.updateConfigurationProperty(this.config, 'tilesLayout', count);
				this.config.tilesLayout = count;
				solve(true);
			}
		});
	}

	/**
	 * This method is invoked when the different canvas layouts are selected from the layouts side panel.
	 * @param {number} layout - It will be a value from 1 to 6 ,
	 * which represents the 6 different layouts which are currently available.
	 */
	private setCanvasLayout(layout: number): Promise<boolean> {
		return new Promise(async solve => {
			if (
				this.canvasService.numberOfCanvasPerLayout(layout) < this.canvasService.numberOfCanvasPerLayout(this.config.canvasLayout) &&
				this.canvasService.getCanvasesWithContent(this.config, layout).length > 0
			) {
				this.dialogHandler = this.dialog.open(RemoveCanvasAndTilesDialogComponent, {
					width: '480px'
				});
				this.dialogHandler.afterClosed().subscribe(confirmed => {
					if (!confirmed) {
						this.canvasService.canvasesAtRisk = [];
						solve(false);
						return;
					}

					// Check and see if canvas at risk includes the selected canavs
					const selectedCanvasWasAtRisk = this.canvasService.canvasesAtRisk.find(canvas => canvas.position === this.config.selectedCanvas.position);

					console.log('here is canvas at risk', this.canvasService.canvasesAtRisk);
					const idList: string[] = this.canvasService.canvasesAtRisk.map((canvas: Canvas) => canvas.id);

					this.canvasService.deleteListOfCanvas(idList).subscribe(() => {
						this.guiConfigService.updateConfigurationProperty(this.config, 'canvasLayout', layout);
						// If there the selected canvas was also at risk, set the selected canvas to the first canvas in the list after deleted
						if (selectedCanvasWasAtRisk) {
							this.config.selectedCanvas = this.config.canvasList[0];
						}
						this.config.canvasLayout = layout;
						solve(true);
					});
					this.canvasService.canvasesAtRisk = [];
				});
			} else {
				await this.guiConfigService.updateConfigurationProperty(this.config, 'canvasLayout', layout);
				this.config.canvasLayout = layout;
				solve(true);
			}
		});
	}
}
