import { Injectable } from '@angular/core';
import { UserService } from 'app/user/user.service';
import { AnalyticsService } from './analytics/analytics.service';
import { GuiConfigService } from './gui-config.service';
import { RootDataService } from './root-data.service';
import { SedaruSubscriptionsService } from './subscriptions/sedaru-subscriptions.service';
import * as SedaruUtils from '../sedaru-util';
import { environment } from 'environments/environment';
import { AppEnvironmentService, EnvironmentType } from './app-environment.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { ArcGISDataSourceService } from 'domain-service/data-sources/arc-gis-data-source.service';
import { MetricService } from './metric.service';
import { TileService } from 'app/tiles/tile.service';
import { GlobalConfig } from 'models/global-config.model';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { IdentityManagerService } from 'app/identity-manager/identity-manager.service';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { TokenManager } from 'sedaru-util/esri-core/token-manager';
import { WorkOrderFactory } from './work-order-factory';
import { RecurrenceProvider } from 'providers/recurrence.provider';

/**
 * This service bootstraps the omni app
 */
@Injectable({
	providedIn: 'root'
})
export class OmniBootstrapService {
	constructor(
		private analyticsService: AnalyticsService,
		public guiConfigService: GuiConfigService,
		public userService: UserService,
		private rootDataService: RootDataService,
		private appEnvironment: AppEnvironmentService,
		public subscriptionService: SedaruSubscriptionsService,
		public arcGISDataSourceService: ArcGISDataSourceService,
		public metricService: MetricService,
		public tileService: TileService,
		public esriSdkService: EsriSdkService,
		private identityManagerService: IdentityManagerService,
		public snackBarService: FlashMessageService,
		public workOrderFactory: WorkOrderFactory,
		public recurrenceProvider: RecurrenceProvider
	) {}

	async initialize(notificationObject: SedaruUtils.NotificationObject<string>): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			let result = false;

			if (!this.appEnvironment.isLocalhost) {
				notificationObject.notify('Bootstrap: analytics initialization');
				this.initializeAnalytics(notificationObject);
				notificationObject.notify('Bootstrap: analytics initialization... success.');
			} else {
				notificationObject.notify('Bootstrap: analytics is disabled for local development.');
			}

			notificationObject.notify('Bootstrap: verifying credentials');
			result = await this.authenticateUser(notificationObject);

			if (!result) {
				notificationObject.notify('Bootstrap: verifing credentials FAILED');
				resolve(false);
				return;
			}

			notificationObject.notify('Bootstrap: connecting to real-time services');
			result = await this.initializeSubscriptionService(this.userService.currentUser.userName, this.userService.currentUser.customerCode);
			if (!result) {
				notificationObject.notify('Bootstrap: real-time services FAILED');
				resolve(false);
				return;
			}

			await this.esriSdkService.initialize();
			TokenManager.initialize(this.esriSdkService.esriMapSdk.IdentityManager);

			this.arcGISDataSourceService.declare(this.userService.globalConfig);

			await this.initializeCredentials(this.userService.globalConfig);

			await this.arcGISDataSourceService.initialize();

			notificationObject.notify('Bootstrap: Initialize Root Data Service');

			const profileGroupName: string = (this.userService.currentUser.isProfileGroupEnabled) ? this.userService.getCurrentProfileGroup() : '';

			result = await this.initializeRootDataService(this.userService.currentUser.customerCode, this.userService.currentUser.userName, profileGroupName);
			if (!result) {
				notificationObject.notify('Bootstrap: Initialize Root Data Service FAILED');
				resolve(false);
				return;
			}

			setTimeout(() => {
				// allow some time for the configurations to be served to the child components
				NavigationService.parseUrl();
			}, 100);

			resolve(true);
		});
	}

	initializeAnalytics(notificationObject: SedaruUtils.NotificationObject<string>) {
		this.analyticsService.initialize(notificationObject);
	}

	authenticateUser(notificationObject: SedaruUtils.NotificationObject<string>): Promise<boolean> {
		return this.userService.initialize(notificationObject);
	}

	initializeSubscriptionService(username: string, customerCode: string) {
		this.subscriptionService.url = environment.subscriptionsUrl;
		return this.subscriptionService.initialize(customerCode, username);
	}

	initializeCustomerProfile() { }

	initializeRootDataService(customerCode: string, userName: string, profileGroupName: string): Promise<boolean> {
		return this.rootDataService.initialize(customerCode, userName, profileGroupName);
	}

	initializeCredentials(globalConfig: GlobalConfig): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			this.identityManagerService.onPortalAccessTokenChanged.subscribe((sender, args) => {
				this.arcGISDataSourceService.updatePortalToken(args);
			});
			for (const dataSource of globalConfig.dataSources) {
				await this.identityManagerService.registerToken(dataSource, globalConfig.customerCode);
			}

			resolve(true);
		});
	}

	initializeMetrics() { }
}
