import { Component, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { QueryStatement, QueryStatementMode, Metric } from 'models';

@Component({
	selector: 'app-query-statement',
	templateUrl: './query-statement.component.html',
	styleUrls: ['./query-statement.component.scss']
})
export class QueryStatementComponent {
	/**
	 * This mode indicates if the query builder is in editing mode or view mode
	 */
	mode: QueryStatementMode = QueryStatementMode.view;

	/**
	 * The index of the statement in the list
	 */
	@Input() statementIndex: number;

	/**
	 * The query statement passed in from the parent
	 */
	@Input() queryStatement: QueryStatement;

	@Input() metric: Metric;

	/**
	 * Pass the query statement mode to the parent component
	 */
	@Input() passModeToQueryBuilderComponent: () => any;

	/**
	 * Passed in function that will send the signel to delte this query statement
	 */
	@Input() deleteThisQueryStatement: () => any;

	/**
	 * Pass the source to the parent component
	 */
	@Input() passSourceToQueryBuilderComponent: (queryStatement: QueryStatement) => any;

	/**
	 * Send a signal when query statement is done editing
	 */
	@Input() sendQueryStatementDoneSignal: () => any;

	@Input() maxHeight: number;

	@Output() queryStatementLoading = new EventEmitter<boolean>();

	/**
	 * On init, if this statement is the first on in the list, we delete the join property
	 */
	ngOnInit() {
		if (this.statementIndex === 0) {
			delete this.queryStatement.join;
		}
		this.queryStatement.index = this.statementIndex;

		if (this.queryStatement.isNew) {
			this.mode = QueryStatementMode.edit;
		}
	}

	/**
	 * This function will change the mode of the query statement and emit an signal to the parent component
	 * @param {QueryStatementMode} mode - The mode of the query statement, view or edit
	 */
	onModeChange(mode: QueryStatementMode): void {
		this.mode = mode;
		this.passModeToQueryBuilderComponent();
	}

	/**
	 * when query statment join changes, save it to the query statement
	 * @param {string} value - the value from the button toggle component
	 */
	onQueryStatementJoinChange(value: string) {
		this.queryStatement.join = {
			name: value.toLowerCase(),
			value: value.toUpperCase()
		};
	}

	/**
	 * When source change, pass the querystatement to parent component
	 * @param {QueryStatement} queryStatement - this query statement
	 */
	onSourceChange(queryStatement: QueryStatement) {
		this.passSourceToQueryBuilderComponent(queryStatement);
	}

	/**
	 * When this query statement is done editing, its no longer new.
	 * Also, the function sends a signal to the parent.
	 */
	onQueryFieldEditDone = () => {
		this.queryStatement.isNew = false;
		this.sendQueryStatementDoneSignal();
	};

	onStatementLoading(loading: boolean) {
		this.queryStatementLoading.emit(loading);
	}
}
