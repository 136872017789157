export class SearchableItemBase {
}

export class SearchableItem<T> extends SearchableItemBase {
	/**
	 * Each individual item from the array on which search is to be performed.
	 */
	private _originalItem: T;

	get originalItem(): T {
		return this._originalItem;
	}
	constructor(item: T) {
		super();
		this._originalItem = item;
	}
}
