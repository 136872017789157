<div class="scrolling-content" #scrollingContainer (scroll)="onScroll()">
	<app-menu-panel-field
		*ngFor="let field of fields; index as i"
		[header]="field.header"
		[(value)]="field.showButton && !field.isDateField ? getValueDescription(field) : field.value"
		[type]="field.inputType"
		[cssClass]="getBackgroundClass(i)"
		[disabled]="isReadOnly || field.isDisabled"
		[buttonEnabled]="!isReadOnly && field.showButton"
		(goToButtonClick)="fieldSelected(field)"
	></app-menu-panel-field>
	<div class="clear-both"></div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button *ngIf="showDeleteButton" [class.omni-button-disabled]="!isManagerOrAdmin" mat-button class="omni-md-font" (click)="onDeleteRequested()" ><img class="omni-sm-img" src="assets/delete.png" /> delete</button>
		<span style="flex:1"></span>
		<button *ngIf="!showCancelButton" [class.omni-button-disabled]="disableSaveButton || isReadOnly" mat-button class="omni-md-font" (click)="onSave()">
			{{ saveButtonLabel || 'save' }} <img class="omni-sm-img" src="assets/save.png" />
		</button>
		<button *ngIf="showCancelButton" mat-button class="omni-md-font" (click)="onCancel()">cancel</button>
	</mat-card-actions>
</mat-card-footer>
