<div>
	<div class="omni-slide-toggle" *ngIf="statementIndex !== 0">
		<app-toggle-button
			[textWhenUnchecked]="'AND'"
			[textWhenChecked]="'OR'"
			(toggled)="onQueryStatementJoinChange($event)"
			[disabled]="queryStatement.disableJoin"
			[checked]="queryStatement.join && queryStatement.join.value === 'OR' ? true : false"
		>
		</app-toggle-button>
	</div>
	<app-edit-query-statement
		*ngIf="mode === 'edit'"	
		[maxHeight]="maxHeight"
		[metric]="metric"
		[queryStatement]="queryStatement"
		[sendQueryFieldEditDoneSignal]="onQueryFieldEditDone"
		(passModeToQueryStatementComponent)="onModeChange($event)"
		(passQueryStatementToQueryStatementComponent)="onSourceChange($event)"
		(loading)="onStatementLoading($event)"
	></app-edit-query-statement>
	<app-view-query-statement
		*ngIf="mode === 'view'"
		[queryStatement]="queryStatement"
		(passModeToQueryStatementComponent)="onModeChange($event)"
		[deleteThisQueryStatement]="deleteThisQueryStatement"
	></app-view-query-statement>
</div>
