import { CustomFieldCode } from './custom-field-code.model';
import { CustomFieldCodeContract } from '../../contracts/work-order';

export class CustomFieldCodes extends Array<CustomFieldCode> {
	static fromContracts(customFieldCodeContracts: CustomFieldCodeContract[]) {
		const customFieldCodes = new CustomFieldCodes();
		if (!customFieldCodeContracts) return customFieldCodes;

		for (const customFieldCodeContract of customFieldCodeContracts) {
			const customFieldCode = CustomFieldCode.fromContract(customFieldCodeContract);
			customFieldCodes.push(customFieldCode);
		}
		return customFieldCodes;
	}

	getById(id: string) {
		return this.find(customerFieldCode => customerFieldCode.id === id);
	}
}
