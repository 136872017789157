import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { EndBoundary } from './end-boundary.model';
import { RecurrencePatternType } from './recurrence-pattern-type.enum';
import { TriggerType } from './trigger-type.enum';
import { StatusType } from './status-type.enum';
import { Pattern } from './pattern.model';
import { DateUtil } from '../../sedaru-util/index';

export class RecurrenceDefinition extends OmniModel<RecurrenceDefinition, Contracts.RecurrencePatternContract> {
    recurrenceTypeList = [
        { key: RecurrencePatternType.Daily, name: 'daily' },
        { key: RecurrencePatternType.Weekly, name: 'weekly' },
        { key: RecurrencePatternType.Monthly, name: 'monthly' },
        { key: RecurrencePatternType.Yearly, name: 'yearly' },
        { key: RecurrencePatternType.None, name: 'none' }
    ];

    constructor() {
        super();
        this.trigger = TriggerType.OnSchedule;
    }

    private _startDate: SedaruUtils.Undoable<Date>;
    get startDate(): Date {
        if (!this._startDate) this._startDate = new SedaruUtils.Undoable<Date>();
        return new Date(this._startDate.value);
    }
    set startDate(value: Date) {
        if (!this._startDate) {
            this._startDate = new SedaruUtils.Undoable<Date>(SedaruUtils.DateUtil.getDateComponent(value));
            return;
        }
        this._startDate.value = SedaruUtils.DateUtil.getDateComponent(value);
    }

    private _endBoundary: EndBoundary;
    get endBoundary(): EndBoundary {
        return this._endBoundary ? this._endBoundary : (this._endBoundary = new EndBoundary());
    }
    set endBoundary(value: EndBoundary) {
        if (this._endBoundary === value) return;
        this._endBoundary = value;
    }

    private _type: SedaruUtils.Undoable<RecurrencePatternType>;
    get type(): RecurrencePatternType {
        if (!this._type) this._type = new SedaruUtils.Undoable<RecurrencePatternType>();
        return this._type.value;
    }
    set type(value: RecurrencePatternType) {
        if (!this._type) {
            this._type = new SedaruUtils.Undoable<RecurrencePatternType>(value);
            return;
        }
        this._type.value = value;
    }

    private _pattern: Pattern;
    get pattern(): Pattern {
        return this._pattern ? this._pattern : (this._pattern = new Pattern());
    }
    set pattern(value: Pattern) {
        if (this._pattern === value) return;
        this._pattern = value;
    }

    private _trigger: SedaruUtils.Undoable<TriggerType>;
    get trigger(): TriggerType {
        if (!this._trigger) this._trigger = new SedaruUtils.Undoable<TriggerType>();
        return this._trigger.value;
    }
    set trigger(value: TriggerType) {
        if (!this._trigger) {
            this._trigger = new SedaruUtils.Undoable<TriggerType>(value);
            return;
        }
        this._trigger.value = value;
    }

    private _triggerDateField: SedaruUtils.Undoable<string>;
    get triggerDateField(): string {
        if (!this._triggerDateField) this._triggerDateField = new SedaruUtils.Undoable<string>();
        return this._triggerDateField.value;
    }
    set triggerDateField(value: string) {
        if (!this._triggerDateField) {
            this._triggerDateField = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._triggerDateField.value = value;
    }

    private _status: SedaruUtils.Undoable<StatusType>;
    get status(): StatusType {
        if (!this._status) this._status = new SedaruUtils.Undoable<StatusType>();
        return this._status.value;
    }
    set status(value: StatusType) {
        if (!this._status) {
            this._status = new SedaruUtils.Undoable<StatusType>(value);
            return;
        }
        this._status.value = value;
    }

    static fromContract(contract: Contracts.RecurrencePatternContract): RecurrenceDefinition {
        const model = new RecurrenceDefinition();
        model.type = contract.type as RecurrencePatternType;
        model.startDate = new Date(contract.startDate);
        model.trigger = contract.trigger as TriggerType;
        model.triggerDateField = contract.triggerDateField;
        model.status = contract.status as StatusType;
        if (contract.endBoundary) model.endBoundary = EndBoundary.fromContract(contract.endBoundary);
        if (contract.pattern) model.pattern = Pattern.fromContract(contract.pattern);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.RecurrencePatternContract {
        const contract = new Contracts.RecurrencePatternContract();
        contract.type = this.type;
        contract.startDate = DateUtil.getFormattedDateTimeString(this.startDate);
        contract.trigger = this.trigger;
        contract.triggerDateField = this.triggerDateField ? this.triggerDateField : 'startdate';
        contract.status = this.status;
        contract.endBoundary = this.endBoundary.getContract();
        contract.pattern = this.pattern.getContract();
        return contract;
    }

    onUpdateInformation(copyModel: RecurrenceDefinition): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<RecurrencePatternType>(this._type, () => {
                this.type = copyModel.type;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<Date>(this._startDate, () => {
                this.startDate = copyModel.startDate;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<TriggerType>(this._trigger, () => {
                this.trigger = copyModel.trigger;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._triggerDateField, () => {
                this.triggerDateField = copyModel.triggerDateField;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<StatusType>(this._status, () => {
                this.status = copyModel.status;
            }) || wasChanged;

        wasChanged = this.endBoundary.updateInformation(copyModel.endBoundary) || wasChanged;
        wasChanged = this.pattern.updateInformation(copyModel.pattern) || wasChanged;

        return wasChanged;
    }
}
