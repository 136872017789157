<div class="scrolling-content">
	<div>
		<ng-container *ngIf="formResolver && formDefinition">
			<app-dynamic-form [formResolver]="formResolver" [formDefinition]="formDefinition"> </app-dynamic-form>
		</ng-container>
	</div>	
	<div *ngIf="attachments.length" class="asset-info-attachments" >
		<span>attachments</span>
		<app-attachments-collection [attachments]="attachments" [isReadOnly]="true"></app-attachments-collection>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end"> </mat-card-actions>
</mat-card-footer>
