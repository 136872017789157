import { ListItemResolver } from '../list-item.resolver';
import { ListTemplates } from '../templates.enum';

export class RightIconOneLineItemResolver extends ListItemResolver {
	templateType = ListTemplates.rightIconOneLine;
	getLabel: (item: any) => string;
	private _label: string;
	private _labelRequested = false;

	getRightIconPath: (item: any) => string;
	private _iconPath: string;
	private _iconRequested = false;

	onShowIconOnHover: (item: any) => boolean;
	showIconOnHover(item: any): boolean {
		if (!this.onShowIconOnHover) return false;
		return this.onShowIconOnHover(item);
	}

	_getLabel(item: any) {
		if (!this._label && !this._labelRequested) {
			this._label = this.getLabel(item);
			this._labelRequested = true;
		}
		return this._label;
	}

	_getRightIconPath(item: any) {
		if (!this._iconPath && !this._iconRequested) {
			this._iconPath = this.getRightIconPath(item);
			this._iconRequested = true;
		}
		return this._iconPath;
	}
}
