import { FieldType } from './field-type';

export class Field {
	name: string;
	type: FieldType;
	alias: string;
	domain: any;
	editable: boolean;
	nullable: boolean;
	defaultValue: null;
	modelName: string;
	length?: number;

	constructor() {
		this.name = '';
		this.type = FieldType.none;
		this.alias = '';
	}
}
