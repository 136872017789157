import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { collapseOnLeave, expandOnEnter } from 'app/app.animations';
import { CheckBoxListResolver } from './check-box-list.resolver';

@Component({
	selector: 'app-check-box-accordion',
	templateUrl: './check-box-accordion.component.html',
	styleUrls: ['./check-box-accordion.component.scss'],
	animations: [collapseOnLeave, expandOnEnter]
})
export class CheckBoxAccordionComponent implements OnInit {
	@Input() headerText: string;
	@Input() identifier: string;
	@Input() selected: boolean;
	@Input() list: Array<any>;
	@Input() resolver: CheckBoxListResolver;
	@Input() expanded = true;
	@Output() accordionSelected = new EventEmitter<{ identifier: string; selected: boolean }>();
	@Output() itemSelected = new EventEmitter<{ identifier: string; selected: boolean }>();

	constructor() {}

	ngOnInit(): void {}

	accordionChange() {
		this.selected = !this.selected;
		this.accordionSelected.emit({ identifier: this.identifier, selected: this.selected });
	}
}
