export enum MetricScalarStatus {
	TextOverride = 'TextOverride',
	Success = 'Success'
}

export interface MetricScalar {
	scalarValue: number;
	scalarText: string;
	scalarStatus: MetricScalarStatus;
}
