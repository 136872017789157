import { Channel } from './channel.model';
import { Style } from './sedaru-config/style.model';
import { FormDefinitions } from './form-definitions.model';
import { FormDefinition } from './form-definition.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';
import { Template } from './sedaru-config/template.model';

/**
 * This defines the asset definition details
 */
export class AssetDefinition extends SedaruConfigBase {

	hierarchyEnabled = false;

	id: string;
	/**
	 * holds the asset type details
	 */
	assetType: string;
	/**
	 * holds the asset title
	 */
	title: string;
	/**
	 * determines if the asset definition is enabled
	 */
	enabled: boolean;
	/**
	 * holds the information form
	 */
	useHierarchy: boolean;
	informationForm: FormDefinition;

	private _sedaruWorkTypes: Array<string>;
	public get sedaruWorkTypes(): Array<string> {
		if (!this._sedaruWorkTypes) {
			this._sedaruWorkTypes = new Array<string>();

			for (const form of this.forms) {
				this._sedaruWorkTypes.push(form.workType);
			}
		}

		return this._sedaruWorkTypes;
	}

	/**
	 * holds the asset form details
	 */
	private _forms: FormDefinitions;
	get forms(): FormDefinitions {
		if (!this._forms) {
			this._forms = new FormDefinitions();
		}

		return this._forms;
	}
	/**
	 * holds the legacy id
	 */
	legacyId: number;
	/**
	 * holds the asset history channel id
	 */
	historyChannelId: string;
	/**
	 * holds the asset history channel details
	 */
	historyChannel: Channel;
	/**
	 * holds the asset channel id
	 */
	assetChannelId: string;
	/**
	 * holds the asset channel details
	 */
	assetChannel: Channel;

	assetMetricTemplateId: number;
	get assetMetricTemplate(): Template {
		const template = this.globalConfig.templates.find(t => t.id === this.assetMetricTemplateId);
		return template;
	}

	historyMetricTemplateId: number;
	get historyMetricTemplate(): Template {
		const template = this.globalConfig.templates.find(t => t.id === this.historyMetricTemplateId);
		return template;
	}

	workOrderMetricTemplateId: number;
	get workOrderMetricTemplate(): Template {
		const template = this.globalConfig.templates.find(t => t.id === this.workOrderMetricTemplateId);
		return template;
	}

	styleId: number;
	/**
	 * holds the style properties
	 */
	public get style(): Style {
		if (this.styleId === 0 || !this.globalConfig) {
			return null;
		}

		return this.globalConfig.styles.getById(this.styleId);
	}

	iconResourceId: number;
	get iconResource() {
		if (this.iconResourceId === 0 || !this.globalConfig) {
			return null;
		}

		return this.globalConfig.resources.getById(this.iconResourceId);
	}

	private _searchableFieldNames: string[];
	public get searchableFieldNames(): string[] {
		if (!this._searchableFieldNames) this._searchableFieldNames = [];

		return this._searchableFieldNames;
	}

	constructor(globalConfig: GlobalConfig) {
		super(globalConfig);
	}
}
