import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

/**
 * This defines the employee details
 */
export class Employee extends OmniModel<Employee, Contracts.EmployeeContract> {
	/**
	 * object id for employee
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}
	/**
	 * holds the employee username
	 */
	private _username: SedaruUtils.Undoable<string>;
	get username(): string {
		if (!this._username) this._username = new SedaruUtils.Undoable<string>();
		return this._username.value;
	}
	set username(value: string) {
		if (!this._username) {
			this._username = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._username.value = value;
	}

	/**
	 * holds the employee name
	 */
	private _name: SedaruUtils.Undoable<string>;
	get name(): string {
		if (!this._name) this._name = new SedaruUtils.Undoable<string>();
		return this._name.value;
	}
	set name(value: string) {
		if (!this._name) {
			this._name = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._name.value = value;
	}

	/**
	 * holds the employee id
	 */
	private _employeeId: SedaruUtils.Undoable<string>;
	get employeeId(): string {
		if (!this._employeeId) this._employeeId = new SedaruUtils.Undoable<string>();
		return this._employeeId.value;
	}
	set employeeId(value: string) {
		if (!this._employeeId) {
			this._employeeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._employeeId.value = value;
	}

	/**
	 * holds the employee rate per hour
	 */
	private _rate: SedaruUtils.Undoable<number>;
	get rate(): number {
		if (!this._rate) this._rate = new SedaruUtils.Undoable<number>();
		return this._rate.value;
	}
	set rate(value: number) {
		if (!this._rate) {
			this._rate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._rate.value = value;
	}

	static fromContract(contract: Contracts.EmployeeContract): Employee {
		const model = new Employee();
		model.id = contract.ObjectId.toString();
		model.username = contract.Username;
		model.name = contract.EmployeeName;
		model.employeeId = contract.EmployeeID;
		model.rate = contract.HourlyRate;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.EmployeeContract {
		const contract = new Contracts.EmployeeContract();
		contract.ObjectId = +this.id;
		contract.Username = this.username;
		contract.EmployeeName = this.name;
		contract.EmployeeID = this.employeeId;
		contract.HourlyRate = this.rate;
		return contract;
	}

	onUpdateInformation(copyModel: Employee): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._username, () => {
				this.username = copyModel.username;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._name, () => {
				this.name = copyModel.name;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._employeeId, () => {
				this.employeeId = copyModel.employeeId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._rate, () => {
				this.rate = copyModel.rate;
			}) || wasChanged;
		return wasChanged;
	}
}
