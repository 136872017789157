<div class="scrolling-content">
	<div class="layout-settings-container">
		<span> tiles </span>
		<hr />
		<div class="tile-layout-button" matRipple (click)="setTileCount(0)" [class.cyan-border]="configChanges.tilesLayout === 0">
			<img src="assets/0-tiles-icon.png" />
		</div>
		<div class="tile-layout-button" matRipple (click)="setTileCount(6)" [class.cyan-border]="configChanges.tilesLayout === 6">
			<img src="assets/6-tiles-icon.png" />
		</div>
		<div class="tile-layout-button" matRipple (click)="setTileCount(12)" [class.cyan-border]="configChanges.tilesLayout === 12">
			<img src="assets/12-tiles-icon.png" />
		</div>
	</div>

	<div class="layout-settings-container">
		<span>canvas</span>
		<hr />
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(1)" [class.cyan-border]="configChanges.canvasLayout === 1">
			<img src="assets/1-canvas.png" />
		</div>
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(2)" [class.cyan-border]="configChanges.canvasLayout === 2">
			<img src="assets/2-canvas-horizontal.png" />
		</div>
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(3)" [class.cyan-border]="configChanges.canvasLayout === 3">
			<img src="assets/3-canvas-down.png" />
		</div>
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(4)" [class.cyan-border]="configChanges.canvasLayout === 4">
			<img src="assets/4-canvas.png" />
		</div>
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(5)" [class.cyan-border]="configChanges.canvasLayout === 5">
			<img src="assets/2-canvas-vertical.png" />
		</div>
		<div class="canvas-layout-button" matRipple (click)="setCanvasLayout(6)" [class.cyan-border]="configChanges.canvasLayout === 6">
			<img src="assets/3-canvas-up.png" />
		</div>
	</div>

	<p style="clear:both"></p>
	<div class="layout-settings-container">
		<hr />
	</div>
</div>
