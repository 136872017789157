import { Role } from './../../../../models/role.enum';
import { Component, ElementRef } from '@angular/core';
import { UserService } from 'app/user/user.service';
import { GuiConfigService } from '../../../../domain-service/gui-config.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { TabScopeType } from 'omni-model/tab-scope.enum';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { GuiConfig } from 'omni-model/gui-config.model';
import { Pages } from 'app/navigation/inavigation';

/**
 * This is a child component to the tab settings component. This is designed to be placed in the left side panel.
 * The component is loaded when one tries to change a tab's visibilty settings by slecting the 'visibility' field
 * form the tab settings panel.
 * The component lists the 3 different visibility settings currently available.
 */
@Component({
	selector: 'app-tab-visibility',
	templateUrl: './tab-visibility.component.html',
	styleUrls: ['./tab-visibility.component.scss']
})
export class TabVisibilityComponent extends MenuPanelBaseComponent {
	get isMyTab() {
		return this.configChanges.scope == TabScopeType[TabScopeType.Myself];
	}

	get isTeamTab() {
		return this.configChanges.scope == TabScopeType[TabScopeType.Team];
	}

	get isAllUserTab() {
		return this.configChanges.scope == TabScopeType[TabScopeType.Public];
	}

	get disableMyTabOption() {
		return this.isAllUserTab;
	}

	get disableAllUserTabOption() {
		if (!this.userService || !this.userService.currentUser || !this.userService.currentUser.role) {
			return false
		}
		if (this.userService.currentUser.role.toLowerCase() == Role.User) {
			return true
		}
		return false;
	}

	configChanges: GuiConfig;

	public get pageIdentifier(): Pages {
		return Pages.tabVisibility;
	}

	/**
	 * The constructor aside from loading and injecting dependencies, inokes the method to set the visibilty of the 'all users'
	 * visibilty settings and initializes the visibility settings of the currently selected tab.
	 * @param {UserService} userService - Provides services to track the current user.
	 * @param {GuiConfigService} guiConfigService - Provides all the services to manipulate the OMNI tab.
	 */
	constructor(private userService: UserService, private guiConfigService: GuiConfigService, view: ElementRef<HTMLElement>) {
		super(view);
		this.updateAllUsersVisibility(userService.currentUser.role);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args.parameter.configChanges) this.configChanges = args.parameter.configChanges;
	}

	/**
	 * On init a subscription is made to the event thown when a new user is logged in and also emits the current route.
	 */
	ngOnInit() {
		this.userService.onCurrentUserChanges.subscribe(() => {
			this.updateAllUsersVisibility(this.userService.currentUser.role);
		});

		this.menuPanelComponent.updateView({ title: 'select visibility', backgroundClass: 'cyan-background' });
	}

	/**
	 * The method is used to enable or disable the 'all users' visibilty settings from the list of available visibilty settings.
	 * The 'all users' visibilty settings is enabled only for 'Advanced' users.
	 * @param {string} userRole - Holds the role of the currently logged in user.
	 */
	updateAllUsersVisibility(userRole: string) {

	}

	/**
	 * The method is invoked when one of the visibilty settings is selcted from the visibilty settings side panel.
	 * It sets the visibilty settings of the currently selected tab to the settings selected.
	 * @param {string} scope - It can be either of the three values: just me, team or all users.
	 */
	async setVisibility(scope: string) {
		if (scope == TabScopeType[TabScopeType.Team]) {
			// don't do anything for team for now.
			return;
		}

		if (scope == TabScopeType[TabScopeType.Public] && this.disableAllUserTabOption) {
			return;
		}

		if (scope == TabScopeType[TabScopeType.Myself] && this.disableMyTabOption) {
			// If the selected scope is myself option, and "disableMyTabOption" is true, then exit
			return;
		}

		this.configChanges.scope = TabScopeType[TabScopeType[scope]];

		/**
		 * when user selects an option we delay 400ms before redirecting back to tab settings
		 * that way we give the user time to notice the ripple animation and the
		 * newly highlighted item
		 */
		setTimeout(() => {
			this.menuPanelComponent.navigationRouter.navigateBack(Pages.tabSettings, { configChanges: this.configChanges });
		}, 400);
	}
}
