<div #mapViewNode class="map-canvas" (touchmove)="onTouchMove($event)">

	<app-map-selection-toolbar (selectionModeChanged)="onMapSelectionModeChanged($event)" class="map-selection-modes"></app-map-selection-toolbar>
	
	<a class="add-workorder-button" (click)="addingWorkorderToMap()" *ngIf="false">
		<span>create workorder</span>
		<img class="omni-md-img" src="assets/workorder-white.png" />
	</a>
	<a class="map-layers-button" (click)="listLayers()">
		<span>map layers</span>
		<img class="omni-md-img" src="assets/canvas-icons/map-layers.png" />
	</a>
	<app-loading-spinner #loadingPacifier></app-loading-spinner>

	<app-map-tooltip [mapView]="canvas.mapView" [mapWrapperElement]="mapViewNode"></app-map-tooltip>
</div>
