import { Component, Output, EventEmitter } from '@angular/core';
/**
 * This component is designed to be placed on top left corner of the OMNI app.
 * It displays the Sedaru logo and the menu icon to toggle the tab panel on a smaller screen.
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	/**
	 * alerts the layout component when the menu icon has been clicked
	 */
	@Output() toggleTabPanel = new EventEmitter();
}
