<div class="trend-wrapper">
	<div class="trend-empty"></div>
	<div class="trend-header" [class.omni-button-disabled]="header.disableHeader">
		<mat-card [class.phone-margins]="displayMode === 'phone'" class="current-value-container">
			<label class="value" [class.current-value]="!header.disableHeader">{{ header.currentValue != undefined ? header.currentValue : '-' }}</label>
			<label class="name">current value</label>
		</mat-card>

		<span style="flex:1"></span>
		<mat-card [class.phone-margins]="displayMode === 'phone'">
			<label class="value" [class.count-value]="!header.disableHeader">{{ header.count || '-' }}</label>
			<label class="name">count</label>
		</mat-card>
		<mat-card [class.phone-margins]="displayMode === 'phone'">
			<label class="value" [class.max-change]="!header.disableHeader">{{ header.maxChange != undefined ? header.maxChange : '-' }}</label>
			<label class="name">max change</label>
		</mat-card>
		<mat-card [class.phone-margins]="displayMode === 'phone'">
			<label class="value" [class.min-value]="!header.disableHeader">{{ header.minValue != undefined ? header.minValue : '-' }}</label>
			<label class="name">min value</label>
		</mat-card>
		<mat-card [class.phone-margins]="displayMode === 'phone'">
			<label class="value" [class.max-value]="!header.disableHeader">{{ header.maxValue != undefined ? header.maxValue : '-' }}</label>
			<label class="name">max value</label>
		</mat-card>
		<mat-card [class.phone-margins]="displayMode === 'phone'">
			<label class="value" [class.avg-value]="!header.disableHeader">{{ header.avgValue != undefined ? header.avgValue : '-' }}</label>
			<label class="name">avg value</label>
		</mat-card>
	</div>
	<div class="trend-calendar">
		<label> DATE </label>
		<span class="trend-date-range-picker">
			<img src="assets/calendar.png" class="omni-sm-img" (click)="openCalendar()" />
			<input #calendarInput type="text" readonly />
		</span>
		<span style="flex:1"></span>
		<span class="trend-style" (click)="goToStyleList()">
			<div *ngIf="displayMode !== 'phone'">{{ chartStyle.label || 'line chart' }}</div>
			<img [src]="chartStyle.iconURL" class="omni-sm-img" />
		</span>
	</div>
	<div class="trend-canvas">
		<canvas #chartWrapper style="width: 100%;height:100%;"></canvas>
	</div>
</div>
