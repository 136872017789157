<div class="navs-wrapper">
	<div [class.active]="tab1.active" (click)="navigateToTab(1)" class="nav s-font-size">
		{{ tab1.header }}
	</div>
	<div
		[class.active]="tab2.active"
		(click)="navigateToTab(2)"
		class="nav s-font-size"
		matBadge="{{ tabHeader2badge }}"
		matBadgeColor="warn"
		matBadgeOverlap="true"
	>
		{{ tab2.header }}
	</div>
	<div
		[class.active]="tab3.active"
		(click)="navigateToTab(3)"
		class="nav s-font-size history-tab"
		matBadge="{{ tabHeader3badge }}"
		matBadgeColor="accent"
		matBadgeOverlap="true"
	>
		{{ tab3.header }}
	</div>
</div>
<mat-divider></mat-divider>
<div class="component-wrapper">
	<div
		class="component-left"
		[class.when-middle-is-active]="tab2.active"
		[class.when-right-is-active]="tab3.active"
	>
		<ng-content select=".component1"></ng-content>
	</div>
	<div
		*ngIf="loaded"
		class="component-middle"
		[class.when-left-is-active]="tab1.active"
		[class.when-middle-is-active]="tab2.active"
		[class.when-right-is-active]="tab3.active"
	>
		<ng-content select=".component2"></ng-content>
	</div>
	<div
		*ngIf="loaded"
		class="component-right"
		[class.when-left-is-active]="tab1.active"
		[class.when-middle-is-active]="tab2.active"
		[class.when-right-is-active]="tab3.active"
	>
		<ng-content select=".component3"></ng-content>
	</div>
</div>
