import { Component, OnInit, Input } from '@angular/core';
import { Color } from 'models/sedaru-config/style.model';
import { FingersDrummingResolver } from './fingers-drumming.resolver';

@Component({
	selector: 'app-fingers-drumming',
	templateUrl: './fingers-drumming.component.html',
	styleUrls: ['./fingers-drumming.component.scss']
})
export class FingersDrummingComponent implements OnInit {
	get scaleSize() {
		return `scale(${this.resolver.scale})`;
	}

	@Input() resolver: FingersDrummingResolver;

	get duration() {
		return `${this.resolver.animationDuration}s`;
	}

	get delay() {
		return this.resolver.animationDuration * 100;
	}

	constructor() {}

	ngOnInit(): void {}
}
