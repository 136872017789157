import { ListItemResolver } from '../omni-list/list-item/templates/list-item.resolver';
import { ListTemplates } from '../omni-list/list-item/templates/templates.enum';

export class SearchResultItemResolver extends ListItemResolver {
	getHeader: (item: any) => string;
	private _header: string;
	private _headerRequested = false;

	getValue: (item: any) => string;
	private _value: string;
	private _valueRequested = false;

	getMatchText: (item: any) => string;
	private _matchText: string;
	private _matchTextRequested = false;

	getRightIconPath: (item: any) => string;
	private _iconPath: string;
	private _iconRequested = false;

	templateType = ListTemplates.searchResult;

	onShowIconOnHover: (item: any) => boolean;
	showIconOnHover(item: any): boolean {
		if (!this.onShowIconOnHover) return false;
		return this.onShowIconOnHover(item);
	}

	_getHeader(item: any) {
		if (!this._header && !this._headerRequested) {
			this._header = this.getHeader(item);
			this._headerRequested = true;
		}
		return this._header;
	}

	_getValue(item: any) {
		if (!this._value && !this._valueRequested) {
			this._value = this.getValue(item);
			this._valueRequested = true;
		}
		return this._value;
	}

	_getMatchText(item: any) {
		if (!this._matchText && !this._matchTextRequested) {
			this._matchText = this.getMatchText(item);
			this._matchTextRequested = true;
		}
		return this._matchText;
	}

	_getRightIconPath(item: any) {
		if (!this._iconPath && !this._iconRequested) {
			this._iconPath = this.getRightIconPath(item);
			this._iconRequested = true;
		}
		return this._iconPath;
	}
}
