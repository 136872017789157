<mat-card (click)="onStatementClicked()" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" class="query-card">
	<img [class.hidden]="notHovered" class="close-button" src="assets/x-close.png" (click)="onDeleteStatement($event, queryStatement.index)" />
	<div *ngFor="let queryField of queryStatement.queryFieldList; let i = index">
		<div class="query-statement">
			<label class="query-statement-title s-font-size">
				{{ queryField.field.omniName }}
			</label>
			<label class="query-statement-subtitle"> {{ queryField.operator.name }} {{ queryField.value ? queryField.value.name : '' }} </label>
		</div>
		<div class="query-divider" *ngIf="queryField.join.value !== 'DONE'">
			<hr class="left-divider" />
			<label class="text-divider s-font-size">
				{{ queryField.join.name }}
			</label>
			<hr class="right-divider" />
		</div>
	</div>
</mat-card>
