import * as SedaruModels from '../../index';
import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';

export class ServicesTransform extends TransformBase {
	onTransform(): TransformationResult {
		for (const jService of this.fieldforceConfig.Services) {
			const dataSource = new SedaruModels.DataSource(this.globalConfig);
			dataSource.legacyId = jService.ID;
			dataSource.url = jService.URL;
			dataSource.name = jService.Name;
			dataSource.credentialsLegacyId = jService.CredentialsID;

			// if (dataSource.credentialsLegacyId > 0) {
			// 	const credential = this.globalConfig.credentials.getByLegacyId(dataSource.credentialsLegacyId);
			// 	dataSource.credentials = credential;
			// }

			dataSource.type = this.getDataSourceType2(jService);
			dataSource.proxyLegacyId = jService.ProxyID;
			if (dataSource.proxyLegacyId > 0) {
				const proxy = this.globalConfig.proxies.getByLegacyId(dataSource.proxyLegacyId);
				dataSource.proxy = proxy;
			}
			dataSource.category = SedaruModels.DataSourceCategory.ArcGIS;
			this.globalConfig.dataSources.push(dataSource);
		}

		return this.getResult(true);
	}

	getDataSourceType2(dataSource: any): SedaruModels.DataSourceType {
		if (dataSource.ServiceType.toLowerCase() === 'service' && dataSource.URL.toLowerCase().includes('feature')) {
			return SedaruModels.DataSourceType.FeatureService;
		} else if (dataSource.ServiceType.toLowerCase() === 'service' && dataSource.URL.toLowerCase().includes('map')) {
			return SedaruModels.DataSourceType.MapService;
		} else if (dataSource.ServiceType.toLowerCase() === 'webmap') {
			return SedaruModels.DataSourceType.WebMap;
		} else if (dataSource.ServiceType.toLowerCase() === 'basemap' || dataSource.ServiceType.toLowerCase() === 'maplayer') {
			return SedaruModels.DataSourceType.BaseMap;
		}
		return SedaruModels.DataSourceType.Other;
	}

	getDataSourceType(dataSourceType: string): SedaruModels.DataSourceType {
		if (dataSourceType.toLowerCase() === 'featureservice') {
			return SedaruModels.DataSourceType.FeatureService;
		} else if (dataSourceType.toLowerCase() === 'mapservice') {
			return SedaruModels.DataSourceType.MapService;
		} else if (dataSourceType.toLowerCase() === 'webmap') {
			return SedaruModels.DataSourceType.WebMap;
		}

		return SedaruModels.DataSourceType.BaseMap;
	}
}
