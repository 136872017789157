/**
 * This defines a model for the OMNI theme element.
 */
export class Theme {
	id?: string;
	/**
	 * The path of the background theme image.
	 */
	backgroundURL?: string;
	/**
	 * The unique name used to identify each theme.
	 */
	displayName?: string;

	backgroundColor?: string;

	/**
	 * The constructor creates the theme object using the display name and url of the theme.
	 * @param {string} name - The unique name used to identify each theme.
	 * @param {string} backgroundURL - The path of the background theme image.
	 */
	constructor(name?: string, backgroundURL?: string) {
		if (name) {
			this.displayName = name;
			if (name === 'custom') {
				this.backgroundURL = backgroundURL;
			} else {
				switch (name) {
					case 'pavement':
						this.backgroundURL = 'assets/backgrounds/pavement.jpg';
						break;
					case 'blue burst':
						this.backgroundURL = 'assets/backgrounds/blue_burst.png';
						break;
					case 'digital':
						this.backgroundURL = 'assets/backgrounds/digital.jpg';
						break;
					case 'blue hex':
						this.backgroundURL = 'assets/backgrounds/blue_hex.jpg';
						break;
					case 'texture':
						this.backgroundURL = 'assets/backgrounds/texture.jpg';
						break;
					case 'OMNI default':
					default:
						this.backgroundColor = '#282828';
				}
			}
		}
	}
}
