import { Undoable, UndoableBase } from './undoable';

export class UndoableObject {
	getProperty<T, K extends keyof T>(obj: T, key: K) {
		return obj[key]; // Inferred type is T[K]
	}

	setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
		obj[key] = value;
	}

	get isDirty(): boolean {
		return this.getUndoableFields(true).length > 0;
	}

	private getUndoableFields(justDirtyFields: boolean = false): Array<UndoableBase> {
		const undoables = new Array<UndoableBase>();

		const allKeys = Object.keys(this);

		for (const k of allKeys) {
			if (!k) continue;

			const value = Reflect.get(this, k);
			if (!(value instanceof UndoableBase)) continue;

			if (!value) continue;

			if (justDirtyFields && !value.isDirty) continue;

			undoables.push(value);
		}

		return undoables;
	}

	clearDirty() {
		const allDirtyFields = this.getUndoableFields(true);

		for (const field of allDirtyFields) {
			field.clearUndoHistory();
		}

		this.onDirtyCleared();
	}

	undoAll(): boolean {
		const allDirtyFields = this.getUndoableFields(true);

        let wasChanged = false;
		for (const field of allDirtyFields) {
			wasChanged = field.undoAll() || wasChanged;
        }

        return wasChanged;
	}

    protected onUndoAll() {}
	protected onDirtyCleared() {}
}
