import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService } from 'app/navigation/navigation.service';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { NoIconOneLineTemplateResolver } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/np-icon-one-line.resolver';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { RecurrenceDefinition } from 'models/recurrence';

@Component({
  selector: 'app-recurrence-field-selection',
  templateUrl: './recurrence-field-selection.component.html',
  styleUrls: ['./recurrence-field-selection.component.scss']
})
export class RecurrenceFieldSelectionComponent extends MenuPanelBaseComponent {
  @ViewChild('selectionList', { static: true}) selectionList: OmniListComponent;

  private resolvers = new Map<string, ListItemResolver>();

  private recurrencePattern: RecurrenceDefinition;

  private recurrenceFieldType: RecurrenceFieldType;

  get isMultipleSelection(): boolean {
    return this.recurrenceFieldType === RecurrenceFieldType.DaysOfWeek;
  }

  listItems: any[];

  selectedItems: any[];

  constructor(view: ElementRef<HTMLElement>) {
    super(view);
  }

  ngOnInit(): void {
    this.selectionList.getResolver = (item: any) => {
      if (this.resolvers.has(item)) return this.resolvers.get(item);
      return this.getResolver(item);
    };
  }

  onPageNavigatedTo(args: NavigationArgs): void {
    if (!args || !args.parameter) return;

    this.listItems = args.parameter.listItems;
    this.selectedItems = args.parameter.selectedItems;
    this.recurrencePattern = args.parameter.recurrencePattern;
    this.recurrenceFieldType = args.parameter.recurrenceFieldType;

    let title = 'work order recurrence';
    if (this.recurrenceFieldType === RecurrenceFieldType.Frequency) title = 'frequency';
    else if (this.recurrenceFieldType === RecurrenceFieldType.Month) title = 'month selection';
    else if (this.recurrenceFieldType === RecurrenceFieldType.DaysOfWeek) title = 'days of the week';

    this.menuPanelComponent.updateView({
      title: title,
      backgroundClass: 'orange-background'
    });
  }

  getResolver(resolverItem: any): NoIconOneLineTemplateResolver {
    const resolver = NoIconOneLineComponent.createResolver();
    resolver.getAlignment = (item: any) => 'center';
    resolver.showDivider = (item: any) => true;
    resolver.onIsDisabled = (item: any) => false;
    resolver.getLabel = (item: any) => item.name;
    this.resolvers.set(resolverItem.key, resolver);
    return resolver;
  }

  onSave(): void {
    if (!this.recurrencePattern) return;

    if (this.recurrenceFieldType !== RecurrenceFieldType.DaysOfWeek) return;
    this.recurrencePattern.pattern.weeklyPattern.daysOfWeek = this.selectionList.selectedItems.map(item => item.key);
    NavigationService.navigateBackTo();
  }

  onClear(): void {
    if (!this.recurrencePattern) return;

    if (this.recurrenceFieldType !== RecurrenceFieldType.DaysOfWeek) return;
    this.recurrencePattern.pattern.weeklyPattern.daysOfWeek = [];
    NavigationService.navigateBackTo();
  }

  itemSelected = (event: any): void => {
    if (!this.recurrencePattern) return;

    if (this.recurrenceFieldType === RecurrenceFieldType.Frequency) this.recurrencePattern.type = event.key;
    else if (this.recurrenceFieldType === RecurrenceFieldType.Month) this.recurrencePattern.pattern.yearlyPattern.month = event.key;
    else return;

    NavigationService.navigateBackTo();
  }
}

export enum RecurrenceFieldType {
  None,
  Frequency,
  Month,
  DaysOfWeek
}
