<mat-card class="omni-panel-field" [ngClass]="getStyles()" [ngStyle]="{ 'background-color': bgColor, 'margin-left': leftMargin }">
	<mat-card-title [ngClass]="{ 'header-when-next-button': buttonEnabled }" class="omni-panel-field-header xs-font-size" (click)="launchUri()" [ngStyle]="{ color: color }">
		<app-text-in-max-lines [maxLines]="2" [horizontalAlign]="'left'" [fontSize]="'12px'" [lineHeight]="'14px'" [text]="header"></app-text-in-max-lines>
	</mat-card-title>
	<div
		[class.field-content-short]="height === 'half'"
		[class.field-content-tall]="height === 'full'"
		[title]="titleEnabled ? displayValue : ''"
		[matTooltip]="displayValue"
		[matTooltipPosition]="'above'"
		[matTooltipDisabled]="isTooltipDisabled"
	>
		<!-- <textarea
			*ngIf="type === 'text' || type === 'date'"
			[(ngModel)]="displayValue"
			(change)="invokeValueChange()"
			(keyup)="invokeValueChange()"
			[ngClass]="getFontSize()"
			[placeholder]="placeHolder || ''"
			[disabled]="disabled"
			
			[maxlength]="maxLength"
		></textarea> -->
		<input
			#input
			(focus)="onInputFocus($event)"
			[type]="type === 'date' ? 'text' : type"
			class="omni-restrict-overflow"
			[(ngModel)]="displayValue"
			(change)="invokeValueChange()"
			(keyup)="updateValue()"
			[ngClass]="'s-font-size'"
			[disabled]="disabled"
			[placeholder]="placeHolder || ''"
			[ngStyle]="{ color: color }"
			*ngIf="valueType === FieldValueType.STRING"
		/>
		<a
			*ngIf="valueType === FieldValueType.HYPERLINK"
			[href]="displayValue"
			class="hyperlink"
			target="_blank"
		>{{ displayValue }}</a>
		<span #hiddenspan class="hidden-div"></span>
		<div *ngIf="thumbnailEnabled" class="omni-thumbnail-div">
			<img [src]="thumbnailIconPath" />
		</div>
		<div *ngIf="buttonEnabled" class="omni-icon-div">
			<a class="omni-icon-button" mat-icon-button (click)="onInputFocus($event); goToButtonClick.emit(); goTo()">
				<img [src]="rightIconPath" />
			</a>
		</div>
		<a
			*ngIf="showTrendIcon"
			[class.disabled]="!enableTrendIcon"
			mat-icon-button
			[matTooltip]="!enableTrendIcon ? 'Not enough data to trend' : null"
			class="trend-icon"
			(click)="enableTrendIcon && trendIconSelected.emit()"
		>
			<img src="assets/trend.png" />
		</a>
	</div>
</mat-card>
