<div class="list-item-wrapper" [class.disabled]="disable" #elementWrapper (contextmenu)="showContextMenu($event, item, elementWrapper)">
	<app-left-icon-one-line
		#listItem
		*ngIf="resolver.templateType === 'leftIconOneLine'"
		[resolver]="resolver"
		[item]="item"
		(leftIconClicked)="leftIconClicked.emit($event)"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-left-icon-one-line>

	<app-left-icon-two-lines
		#listItem
		*ngIf="resolver.templateType === 'leftIconTwoLines'"
		[resolver]="resolver"
		[item]="item"
		(leftIconClicked)="leftIconClicked.emit($event)"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
		[connector]="connector"
	></app-left-icon-two-lines>

	<app-two-icons-two-lines
		#listItem
		*ngIf="resolver.templateType === 'twoIconsTwoLines'"
		[resolver]="resolver"
		[item]="item"
		(leftIconClicked)="leftIconClicked.emit($event)"
		(rightIconClicked)="rightIconClicked.emit($event)"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-two-icons-two-lines>

	<app-no-icon-two-lines
		#listItem
		*ngIf="resolver.templateType === 'noIconTwoLines'"
		[resolver]="resolver"
		[item]="item"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-no-icon-two-lines>

	<app-no-icon-one-line
		#listItem
		*ngIf="resolver.templateType === 'noIconOneLine'"
		[resolver]="resolver"
		[item]="item"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-no-icon-one-line>

	<app-search-result-item
		#listItem
		*ngIf="resolver.templateType === 'searchResult'"
		[resolver]="resolver"
		[item]="item"
		(itemSelected)="itemClicked.emit($event)"
		(rightIconClicked)="rightIconClicked.emit($event)"
	></app-search-result-item>

	<app-right-icon-one-line
		#listItem
		*ngIf="resolver.templateType === 'rightIconOneLine'"
		[resolver]="resolver"
		[item]="item"
		(rightIconClicked)="rightIconClicked.emit($event)"
		(itemSelected)="itemClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-right-icon-one-line>

	<app-two-icons-one-line
		#listItem
		*ngIf="resolver.templateType === 'twoIconsOneLine'"
		[resolver]="resolver"
		[item]="item"
		(itemSelected)="itemClicked.emit($event)"
		(leftIconClicked)="leftIconClicked.emit($event)"
		(rightIconClicked)="rightIconClicked.emit($event)"
		[selected]="isCurrentlySelected"
	></app-two-icons-one-line>
</div>
