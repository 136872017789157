<div class="list-wrapper">
	<app-omni-list 
		#list
		(scrolled)="onScroll($event)" 
		[omniListItems]="assetList" 
		(rightIconClicked)="removeItem($event)" 
		(itemClicked)="selectAssetRecord($event)" 
		(mouseLeft)="removeHighlightFromAsset()" 
		(itemHovered)="highlightAsset($event)"
	></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start"> </mat-card-actions>
</mat-card-footer>
