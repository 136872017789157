import { UserService } from 'app/user/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';

/**
 * The http client service is used for curd operations upon WorkOrder
 */
@Injectable({
	providedIn: 'root'
})
export class WorkOrderHttpService {
	/**
	 * AWO base url which will be provided from env variable
	 */
	baseUrl: string;
	/**
	 * Smart Uploader base URL which will be provided from env variable
	 */
	smartUploaderBaseUrl: string;
	/**
	 * holds the token string for the AWO api
	 */

	get token(): string {
		if (!this.userService || !this.userService.identityController || !this.userService.identityController.sedaruOAuthToken) {
			return '';
		}

		return this.userService.identityController.sedaruOAuthToken.accessToken;
	}
	/**
	 * Allows the HttpService and dependencies to be injected into the class
	 * @param {HttpService} http - HttpService object
	 */
	constructor(private http: HttpClient, private userService: UserService) {
		this.baseUrl = environment.awoBaseUrl;
		this.smartUploaderBaseUrl = environment.smartUploaderBaseUrl;
	}

	/**
	 * GET request for fetching data form AWO api server
	 * @param url - AWO url
	 * @param paramsObj - query string definition
	 */
	getService(url, paramsObj = null) {
		const params = paramsObj ? this.getQueryString(paramsObj) : '';
		return this.http.get(this.baseUrl + url + '?' + params + '&Token=' + this.token).pipe(retry(1));
	}

	/**
	 * POST request for AWO
	 * @param url - AWO api url
	 * @param postData - body data for post request
	 */
	postService(url, postData, option = {}) {
		const body = {
			Data: postData,
			Token: this.token
		};
		return this.http.post(this.baseUrl + url, body, option).pipe(retry(1));
	}

	woKeypostService(url, postData, option = {}) {
		const body = {
			Data: postData,
			Token: this.token
		};
		return this.http.post(this.smartUploaderBaseUrl + url, body, option).pipe(retry(1));
	}

	/**
	 * Error handling for api request
	 */
	handlerError(error) {
		console.error(error);
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		AnalyticsHub.current.trackError(error);

		return throwError(errorMessage);
	}

	private getQueryString(params) {
		return Object.keys(params)
			.map(key => key + '=' + params[key])
			.join('&');
	}
}
