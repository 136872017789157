import { Component, ElementRef } from '@angular/core';
import { KeyValue } from '@angular/common';
import { OmniConfigContentModel } from './omni-config-content.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Pages } from 'app/navigation/inavigation';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { GuiConfig } from 'omni-model/gui-config.model';

/**
 * This is a child component to the tab settings component. This is designed to be placed in the left side panel.
 * The component is loaded when one tries to change a tab's content by slecting the 'content' field form the tab settings panel.
 * The component lists the different OMNI tab contents.
 */
@Component({
	selector: 'app-tab-content-panel',
	templateUrl: './tab-content-panel.component.html',
	styleUrls: ['./tab-content-panel.component.scss']
})
export class TabContentPanelComponent extends MenuPanelBaseComponent {
	/**
	 * Holds a list of all the available contents which is being hardcorded from the constructor.
	 */
	contentList: { [index: string]: OmniConfigContentModel };
	/**
	 * Holds the selected item from the list of contents.
	 */
	currentContent: OmniConfigContentModel;

	configChanges: GuiConfig;

	public get pageIdentifier(): Pages {
		return Pages.tabContentType;
	}

	constructor(view: ElementRef<HTMLElement>) {
		super(view);
		this.contentList = {
			dashboard: {
				fullName: 'OMNI Dashboard',
				shortName: 'dashboard',
				description: 'create user-defined views to track, trend, and monitor performance',
				icon: 'assets/tab-content/omni-dashboard.png',
				enabled: true,
				order: 1
			},
			modeling: {
				fullName: 'Sedaru Modeling',
				shortName: 'modeling',
				description: 'perform what-if scenarios, run simulations, and determine impact',
				icon: 'assets/tab-content/modeling.png',
				enabled: false,
				order: 2
			},
			outage: {
				fullName: 'Sedaru Outage',
				shortName: 'outage',
				description: 'manage and respond to leaks, breaks and planned shutdowns',
				icon: 'assets/tab-content/outage.png',
				enabled: false,
				order: 3
			},
			ops: {
				fullName: 'Sedaru Ops',
				shortName: 'ops',
				description: 'use SCADA to monitor and simulate operations',
				icon: 'assets/tab-content/ops.png',
				enabled: false,
				order: 4
			},
			cip: {
				fullName: 'Sedaru CIP',
				shortName: 'cip',
				description: 'create and prioritize defensible capital improvement projects',
				icon: 'assets/tab-content/cip.png',
				enabled: false,
				order: 5
			}
		};
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.configChanges = args.parameter.configChanges;
	}

	/**
	 * On init, the currently selected content is initialized and the current route is emitted.
	 */
	ngOnInit() {
		this.currentContent = this.contentList[this.configChanges.content];
		this.menuPanelComponent.updateView({ title: 'select content type', backgroundClass: 'cyan-background' });
	}

	/**
	 * The method is invoked when the dashboard content is selected from the list. The method sets a timeout to display
	 * the ripple animation and also redirects user to the parent panel.
	 * @param {OmniConfigContentModel} content - The config of the content that was clicked.
	 */
	selectDashboard(content: OmniConfigContentModel) {
		if (content.enabled && this.currentContent.shortName !== content.shortName) {
			this.configChanges.content = content.shortName;
			/**
			 * when user selects an option we delay 400ms before redirecting back to tab settings
			 * that way we give the user time to notice the ripple animation and the
			 * newly highlighted item
			 */
			setTimeout(() => {
				this.menuPanelComponent.navigationRouter.navigateBack(Pages.tabSettings, { configChanges: this.configChanges });
			}, 400);
		}
	}

	/**
	 * Sorts the list of contents in ascending order based on the order provided before displaying it.
	 */
	sort = (a: KeyValue<string, OmniConfigContentModel>, b: KeyValue<string, OmniConfigContentModel>): number => {
		return a.value.order > b.value.order ? 1 : b.value.order > a.value.order ? -1 : 0;
	};
}
