import { ContractBase } from '../contract-base';

export class FailureReportContract extends ContractBase {
    public ObjectId: number;
    public CodeType: string;
    public CodeValue: string;
    public SystemId: string;
    public WorkOrderKey: string;
    public CreatedDate: string;
    public CreatedBy: string;
}
