import { SedaruClientMethodHandlerBase } from './sedaru-client-method-handler-manager';
import { NotificationPacket } from './notification-packet';
import { MethodHandlerArgs } from './method-handler-args';
import { MethodHandlerResultBase } from './method-handler-result';
import { PacketRecipients } from './packet-recipients';
import { SedaruNotificationConnection } from './sedaru-notification-connection';

export class ConnectionPingHandler extends SedaruClientMethodHandlerBase {
    private _requestMethodIdentifier = 'sedaru.globalpingrequest';
    private _responseMethodIdentifier = 'sedaru.globalpingresponse';

    private _isPinging = false;
    private _connectionLimit = 0;
    private _pingResolver: any;
    private _pingTaskCancellation: any;

    private _currentList: Array<SedaruNotificationConnection>;
    private get currentList(): Array<SedaruNotificationConnection> {
        if (!this._currentList) this._currentList = new Array<SedaruNotificationConnection>();

        return this._currentList;
    }

    public get canPing(): boolean {
        if (!this.hub.isConnected) return false;

        return !this._isPinging;
    }
    protected onGetUniqueId(): string {
        return 'ConnectionPingHandler';
    }
    shouldHandleMethod(methodIdentifier: string): boolean {
        return methodIdentifier.toLowerCase() === this._requestMethodIdentifier ||
            methodIdentifier.toLowerCase() === this._responseMethodIdentifier;
    }
    onHandleMethod(packet: NotificationPacket, args: MethodHandlerArgs): Promise<MethodHandlerResultBase> {
        return new Promise<MethodHandlerResultBase>((resolve, reject) => {
            if (packet.methodIdentifier.toLowerCase() === this._requestMethodIdentifier) {
                this.handleGlobalConnectionPingRequest(packet.recipients, args);
            } else if (packet.methodIdentifier.toLowerCase() === this._responseMethodIdentifier) {
                this.handleGlobalConnectionPingResponse(packet.recipients, args);
            }

            resolve(MethodHandlerResultBase.empty);
        });
    }

    private handleGlobalConnectionPingRequest(msg: PacketRecipients, args: MethodHandlerArgs) {
        if (!this.hub.clientConnection.isMatch(msg.to)) return;

        this.respondToGlobalPing(msg);
        args.isHandled = true;
    }

    private async respondToGlobalPing(incomingMsg: PacketRecipients) {
        await this.hub.sendNotification(this._responseMethodIdentifier, '', undefined, undefined, undefined, incomingMsg.from.connectionId);
        this.hub.invokeOnConnectionWasPinged(incomingMsg);
    }

    private handleGlobalConnectionPingResponse(incomingMessage: PacketRecipients, args: MethodHandlerArgs) {
        if (!this._isPinging) return;

        if (this.currentList.length >= this._connectionLimit) return;

        this.currentList.push(incomingMessage.from);

        args.isHandled = true;

        if (this.currentList.length >= this._connectionLimit && this._pingTaskCancellation) {
            clearTimeout(this._pingTaskCancellation);
            this.resolvePing();
        }
    }

    pingConnections(toCustomerCode: string = '', toUsername: string = '', toApp: string = '',
        toConnectionId: string = '', secondsToWait = 5, connectionsLimit = 2): Promise<Array<SedaruNotificationConnection>> {
        if (this._isPinging) return Promise.resolve(new Array<SedaruNotificationConnection>());

        return new Promise<Array<SedaruNotificationConnection>>(async (resolve, reject) => {

            try {
                this._isPinging = true;
                this.currentList.length = 0;

                if (secondsToWait <= 0) secondsToWait = 5;

                this._connectionLimit = connectionsLimit;

                await this.hub.sendNotification(this._requestMethodIdentifier, '', toCustomerCode, toUsername, toApp, toConnectionId);

                this._pingResolver = resolve;

                this._pingTaskCancellation = setTimeout(() => {
                    this.resolvePing();
                }, secondsToWait * 1000);
                return;
            } catch {
                this.resolvePing();
                return;
            }
        });
    }

    private resolvePing() {
        this._isPinging = false;
        if (!this._pingResolver) return;

        this._pingResolver(this.currentList);
    }
}
