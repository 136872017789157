<div class="check-list-item header">
	<div class="check-box" (click)="accordionChange()">
		<img class="to-do" src="assets/task-to-do.png" *ngIf="!selected" />
		<img class="done" src="assets/done.png" *ngIf="selected" />
	</div>

	<div class="expand" (click)="expanded = !expanded">
		<div class="text">
			{{ headerText }}
		</div>
		<img src="assets/ascending-circled.png" *ngIf="list && list.length && expanded" />
		<img src="assets/descending-circled.png" *ngIf="list && list.length && !expanded" />
	</div>
</div>
<div class="body" [@collapseOnLeave] [ngClass]="{ disabled: !selected }" *ngIf="expanded">
	<div class="check-list-item" [@expandOnEnter] *ngFor="let item of list">
		<div class="check-box" (click)="selected && resolver.onItemSelected(item)">
			<img class="to-do" src="assets/task-to-do.png" *ngIf="!resolver.getSelected(item)" />
			<img class="done" src="assets/done.png" *ngIf="resolver.getSelected(item)" />
		</div>
		<div class="text">{{ resolver.getLabel(item) }}</div>
	</div>
</div>
