import { WorkorderCommentComponent } from './../workorder/workorder-comment-list/workorder-comment/workorder-comment.component';
import { WorkorderCommentListComponent } from './../workorder/workorder-comment-list/workorder-comment-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterComponent } from './router/router.component';
import { ComponentViewDirective } from './router/component-view.directive';
import { LayoutConfigurationComponent } from 'app/menu-panel/tab-settings/layout-configuration/layout-configuration.component';
import { TabContentPanelComponent } from 'app/menu-panel/tab-settings/tab-content-panel/tab-content-panel.component';
import { TabSettingsMainMenuComponent } from 'app/menu-panel/tab-settings/tab-settings-main-menu/tab-settings-main-menu.component';
import { TabVisibilityComponent } from 'app/menu-panel/tab-settings/tab-visibility/tab-visibility.component';
import { ThemeComponent } from 'app/menu-panel/tab-settings/theme/theme.component';
import { TileSettingsComponent } from 'app/menu-panel/tile-settings/tile-settings.component';
import { ListMetricComponent } from 'app/metric/list-metric/list-metric.component';
import { MapSettingsComponent } from 'app/menu-panel/map-settings/map-settings.component';
import { MapListComponent } from 'app/menu-panel/map-list/map-list.component';
import { TrendSettingsComponent } from 'app/menu-panel/trend-settings/trend-settings.component';
import { TrendStyleComponent } from 'app/menu-panel/trend-settings/trend-style/trend-style.component';
import { MapLayersComponent } from 'app/menu-panel/map-layers/map-layers.component';
import { MapAssetListComponent } from 'app/menu-panel/map-assets/map-asset-list/map-asset-list.component';
import { MapAssetAttributesComponent } from 'app/menu-panel/map-assets/map-asset-attributes/map-asset-attributes.component';
import { EditMetricComponent } from 'app/metric/list-metric/edit-metric/edit-metric.component';
import { MetricSourceTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/types/metric-source-types.component';
import { QueryBuilderComponent } from 'app/metric/list-metric/edit-metric/metric-query/query-builder/query-builder.component';
import { DisplayValueSettingsComponent } from 'app/metric/list-metric/edit-metric/display-value-settings/display-value-settings.component';
import { MenuPanelSettingsComponent } from 'app/metric/list-metric/edit-metric/menu-panel-settings/menu-panel-settings.component';
import { DefaultTrendSettingsComponent } from 'app/metric/list-metric/edit-metric/default-trend-settings/default-trend-settings.component';
import { AssetTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/asset-types/metric-source-asset-types.component';
import { MetricIconListComponent } from 'app/metric/list-metric/edit-metric/metric-icon/metric-icon-list.component';
import { OmniValueListComponent } from 'app/omni-value-list/omni-value-list.component';
import { DefaultTrendStyleComponent } from 'app/metric/list-metric/edit-metric/default-trend-settings/default-trend-style/default-trend-style.component';
import { QueryValidatorComponent } from 'app/metric/list-metric/edit-metric/metric-query/query-validator/query-validator.component';
import { WorkOrderOutlineComponent } from 'app/workorder/workorder-outline/workorder-outline.component';
import { WorkOrderSummaryComponent } from 'app/workorder/workorder-summary/workorder-summary.component';
import { WorkOrderSourceTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/work-order-source-types/work-order-source-types.component';
import { WorkOrderFilterComponent } from 'app/workorder/workorder-filter/workorder-filter.component';
import { WorkOrderFilterSelectionComponent } from 'app/workorder/workorder-filter/workorder-filter-selection/workorder-filter-selection.component';
import { WorkOrderFilterDatePickerComponent } from 'app/workorder/workorder-filter/workorder-filter-date-picker/workorder-filter-date-picker.component';
import { SearchResultsComponent } from 'app/search/search-results/search-results.component';
import { WorkOrderAttachmentsComponent } from 'app/workorder/workorder-attachments/workorder-attachments.component';
import { UITestComponent } from 'app/ui-components/ui-components-test-panel';
import { WorkorderTaskListComponent } from 'app/workorder/workorder-task-list/workorder-task-list.component';
import { WorkorderTaskComponent } from 'app/workorder/workorder-task/workorder-task.component';
import { WorkorderAssetListComponent } from 'app/workorder/workorder-asset-list/workorder-asset-list.component';
import { AdvancedLaborComponent } from 'app/workorder/advanced-labor/advanced-labor.component';
import { WorkorderRelatedWorkordersComponent } from 'app/workorder/workorder-related-workorders/workorder-related-workorders.component';
import { WorkorderNewTaskComponent } from 'app/workorder/workorder-new-task/workorder-new-task.component';
import { WorkOrderFieldComponent } from 'app/workorder/work-order-field/workorder-field.component';
import { WorkorderLEMListComponent } from 'app/workorder/workorder-lem-list/workorder-lem-list.component';
import { WorkorderNewLemComponent } from 'app/workorder/workorder-new-lem/workorder-new-lem.component';
import { WorkOrderNewLEMMenuPanelComponent } from 'app/workorder/workorder-new-lem-menu-panel/workorder-new-lem-menu-panel.component';
import { AssetInformationComponent } from 'app/menu-panel/map-assets/asset-information/asset-information.component';
import { HistoryRecordsFormComponent } from 'app/dynamic-forms/history-records-form/history-records-form.component';
import { WorkOrderLEMCostViewComponent } from 'app/workorder/workorder-lem-cost-view/workorder-lem-cost-view.component';
import { TeamViewComponent } from 'app/team-viewer/team-view/team-view.component';
import { TimeFrameSettingsComponent } from 'app/metric/list-metric/edit-metric/time-frame-settings/time-frame-settings.component';
import { WorkorderFailureReportMenuPanelComponent } from 'app/workorder/workorder-failure-report-menu-panel/workorder-failure-report-menu-panel.component';
import { BulkEditErrorsListComponent } from 'app/workorder/bulk-edit-errors-list/bulk-edit-errors-list.component';
import { HierarchyMenuPanelComponent } from 'app/workorder/hierarchy-menu-panel/hierarchy-menu-panel.component';
import { HierarchyWorkorderListComponent } from 'app/workorder/hierarchy-workorder-list/hierarchy-workorder-list.component';
import { TableSettingsComponent } from 'app/menu-panel/table-settings/table-settings.component'
import { DateFieldSelectionComponent } from 'app/menu-panel/timeframe/date-field-selection/date-field-selection.component';
import { WorkOrderRecurrenceComponent } from 'app/workorder/workorder-recurrence/workorder-recurrence.component';
import { RecurrenceFieldSelectionComponent } from 'app/workorder/workorder-recurrence/recurrence-field-selection/recurrence-field-selection.component';
@NgModule({
	declarations: [RouterComponent, ComponentViewDirective],
	imports: [CommonModule],
	exports: [RouterComponent],
	entryComponents: [
		LayoutConfigurationComponent,
		TabContentPanelComponent,
		TabSettingsMainMenuComponent,
		TabVisibilityComponent,
		ThemeComponent,
		TileSettingsComponent,
		ListMetricComponent,
		MapListComponent,
		MapSettingsComponent,
		TrendSettingsComponent,
		TrendStyleComponent,
		MapLayersComponent,
		MapAssetListComponent,
		MapAssetAttributesComponent,
		EditMetricComponent,
		MetricSourceTypesComponent,
		MetricIconListComponent,
		QueryBuilderComponent,
		DisplayValueSettingsComponent,
		MenuPanelSettingsComponent,
		DefaultTrendSettingsComponent,
		AssetTypesComponent,
		OmniValueListComponent,
		DefaultTrendStyleComponent,
		QueryValidatorComponent,
		WorkOrderOutlineComponent,
		WorkOrderSummaryComponent,
		WorkorderTaskListComponent,
		WorkorderTaskComponent,
		WorkorderNewTaskComponent,
		WorkOrderSourceTypesComponent,
		WorkOrderFieldComponent,
		WorkOrderFilterComponent,
		WorkOrderAttachmentsComponent,
		WorkorderAssetListComponent,
		WorkOrderFilterSelectionComponent,
		WorkorderLEMListComponent,
		WorkorderNewLemComponent,
		WorkOrderFilterDatePickerComponent,
		SearchResultsComponent,
		AssetInformationComponent,
		WorkOrderAttachmentsComponent,
		AdvancedLaborComponent,
		WorkorderRelatedWorkordersComponent,
		WorkOrderNewLEMMenuPanelComponent,
		AssetInformationComponent,
		HistoryRecordsFormComponent,
		UITestComponent,
		WorkOrderLEMCostViewComponent,
		WorkorderCommentListComponent,
		WorkorderCommentComponent,
		TeamViewComponent,
		WorkorderFailureReportMenuPanelComponent,
		TimeFrameSettingsComponent,
		BulkEditErrorsListComponent,
		HierarchyMenuPanelComponent,
		HierarchyWorkorderListComponent,
		DateFieldSelectionComponent,
		WorkOrderRecurrenceComponent,
		RecurrenceFieldSelectionComponent,
	]
})
export class NavigationModule {}
