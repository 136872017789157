import { FormControlDefinition, FormDefinition, FormControlGroup, Color } from 'models';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { FormGridPattern } from './form-alternating-pattern';
import { ControlGroupHeaderIcon } from '../control-group/control-group-header-icon.model';
import { MenuPanelFieldValueType } from 'app/ui-components/menu-panel-field/menu-panel-field-value-type.enum';

export class BaseFormResolver {
	getControlHeader: (formControl: FormControlDefinition) => string;
	getControlWidth: (formControl: FormControlDefinition) => MenuPanelFieldSize;
	getControlBackground: (formControl: FormControlDefinition, grid?: FormGridPattern) => Color;
	getControlTextColor: (formControl: FormControlDefinition, grid?: FormGridPattern) => Color;
	getControlHeight: (formControl: FormControlDefinition) => MenuPanelFieldSize;
	getControlValue: (formControl: FormControlDefinition) => any;
	getControlValueType: (formControl: FormControlDefinition) => MenuPanelFieldValueType;
	getControlPlaceholder: (formControl: FormControlDefinition) => string;
	getControlGroupHeader: (formGroup: FormControlGroup) => string;
	getControlGroupHeaderIcon: () => ControlGroupHeaderIcon;
	getFooterFieldBackground: (footerControl: FormControlDefinition) => Color;
	getFooterTextColor: (footerControl: FormControlDefinition) => Color;
	getFormTitle: (formDef: FormDefinition) => string;
	showFormTitle: (formDef: FormDefinition) => boolean;
	disableFormControls: () => boolean;
	showTrendIcon: (formControl: FormControlDefinition) => boolean;
	trendIconSelected: (formControl: FormControlDefinition) => any;
	enableTrendIcon: (formControl: FormControlDefinition) => boolean;
	getMarginLeft: (formControl: FormControlDefinition, grid: FormGridPattern) => string;
}
