import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { TwoIconsTwoLinesItemResolver } from './two-icons-two-lines.resolver';
import { ListItemComponentBase } from '../list-item.model';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-two-icons-two-lines',
	templateUrl: './two-icons-two-lines.component.html',
	styleUrls: ['./two-icons-two-lines.component.scss']
})
export class TwoIconsTwoLinesComponent extends ListItemComponentBase {
	@Input() resolver: TwoIconsTwoLinesItemResolver;
	@Input() item: any;
	@Output() leftIconClicked = new EventEmitter();
	@Output() rightIconClicked = new EventEmitter();
	@Output() itemSelected = new EventEmitter();

	itemComponent = this;

	@Input() selected = false;

	hovered = false;

	static createResolver(): TwoIconsTwoLinesItemResolver {
		const resolver = new TwoIconsTwoLinesItemResolver();
		resolver.templateType = ListTemplates.twoIconsTwoLines;
		return resolver;
	}

	onRightIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.rightIconClicked.emit(this.item);
	}

	onLeftIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.leftIconClicked.emit(this.item);
	}

	getTypeOfIcon(item) {
		return typeof item;
	}
}
