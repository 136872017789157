import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';

export class MapTransform extends TransformBase {
    protected onTransform(): TransformationResult {
        if (!this.fieldforceConfig.Map || !this.fieldforceConfig.Map.DefaultExtent) return this.getResult(true, 'no map settings found');

        const mapConfig = this.fieldforceConfig.Map;
        const mapSetting = new SedaruModels.Map(this.globalConfig);
        mapSetting.spatialReference = mapConfig.SpatialReference;
        mapSetting.zoomFactor = mapConfig.ZoomFactor;

        if (mapConfig.BaseLayers) {
            for (const layer of mapConfig.BaseLayers) {
                const baseLayer = new SedaruModels.BaseLayer(this.globalConfig);
                baseLayer.legacyId = layer.ID;
                baseLayer.name = layer.Name;
                baseLayer.transparency = layer.Transparency;
                baseLayer.layerType = layer.LayerType;
                baseLayer.dataSourceLegacyId = layer.ServiceID;
                mapSetting.baseLayers.push(baseLayer);
            }
        }
        const defaultExtentConfig = mapConfig.DefaultExtent;
        mapSetting.defaultExtent = new SedaruModels.DefaultExtent(this.globalConfig);
        mapSetting.defaultExtent.xmin = defaultExtentConfig.XMin;
        mapSetting.defaultExtent.xmax = defaultExtentConfig.XMax;
        mapSetting.defaultExtent.ymin = defaultExtentConfig.YMin;
        mapSetting.defaultExtent.ymax = defaultExtentConfig.YMax;

        this.globalConfig.map = mapSetting;
        return this.getResult(true);
    }

}
