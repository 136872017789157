import { Component, Input, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { Canvas } from 'omni-model/canvas.model';

@Component({
	selector: 'app-map-legend',
	templateUrl: './map-legend.component.html',
	styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit, OnChanges {
	@Input() selectedCanvas: Canvas;
	/**
	 * legend info of the esri map
	 */
	public legendElements;

	/**
	 *  The constructor doesn't do anything aside from loading and injecting dependencies.
	 */
	constructor(private mapService: CanvasMapService) {}

	ngOnInit() {
		this.legendElements = this.mapService.getLegends(this.selectedCanvas);
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if (changes.selectedCanvas) {
			this.ngOnInit();
		}
	}
}
