/**
 * The model class for search mode.
 */
export class SearchMode {
    /**
     * The title of the mode which will be displayed in the mode selection dropdown.
     */
    title: string;

    /**
     * unique udentifier for each mode. eg., asset, address, workorder etc.
     */
    identifier: string;

    /**
     * URL fot the modes icon to be displayed in the drop down and the search bar.
     */
    iconUrl: string;

    /**
     * Sets the initial mode.
     */
    constructor () {
        this.title = 'search assets';
		this.identifier = 'assets';
		this.iconUrl = 'assets/assets.png';
    }
}
