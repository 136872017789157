<div class="scrolling-content" #scrollingContainer>
	<mat-grid-list  cols="2" gutterSize="25" rowHeight="125"  class="base-map-panel">
		<mat-grid-tile  *ngFor="let baseMap of baseMapList">
			<div class="thumbnail-wrapper" (click)="updateMap(baseMap.baseMapIdentifier)">
				<span class="base-map-thumbnail" [class.active]="baseMap.baseMapIdentifier == selectedBaseMap">
					<img *ngIf="baseMap.thumbnailImg" src="assets/map-thumbnails/{{ baseMap.thumbnailImg }}" />
				</span>
				<label class="omni-xs-font">{{ baseMap.baseMapLabel }}</label>
			</div>
		</mat-grid-tile>
	</mat-grid-list>
</div>
