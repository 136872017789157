import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { ElementRef, ViewChild, Component } from '@angular/core';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Metric } from 'models/metric.model';
import { WorkOrderSource, WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { AWOQuery } from 'models/awo-query.model';
import { AdvancedWorkOrderFilterTemplate } from 'app/workorder/workorder-filter/workorder-filter-templates';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';

@Component({
	selector: 'app-work-order-source-types',
	templateUrl: './work-order-source-types.component.html',
	styleUrls: ['./work-order-source-types.component.scss']
})
export class WorkOrderSourceTypesComponent extends MenuPanelBaseComponent {
	@ViewChild('scrollingContainer', { static: true }) scrollingContainer: ElementRef;
	sources = WorkOrderSource;
	metric: Metric;
	awoQuery: AWOQuery;
	private _scrollPosition: number;

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private interop: OmniInteropService
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
		if (!this.metric?.definition?.query && !args.parameter.query) {
			this.awoQuery = new AWOQuery();
			this.awoQuery.isDirty = false;
			return;
		}
		if (this.metric?.definition?.query && !args.parameter.query?.isDirty) {
			(this.metric?.definition?.query as AWOQuery).isDirty = false;
			this.awoQuery = JSON.parse(JSON.stringify(this.metric?.definition?.query as AWOQuery));
		} else this.awoQuery = args.parameter.query;
	}

	onPageReload() {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
	}

	ngOnInit() {
		if (this.uiState) this._scrollPosition = this.uiState.scrollPosition;
		this.menuPanelComponent.updateView({ title: 'work order filter' });
	}

	onScroll() {
		if (!this.uiState) this.uiState = {};
		this.uiState.scrollPosition = this.scrollingContainer.nativeElement.scrollTop;
	}

	ngAfterViewInit() {
		if (this._scrollPosition) this.scrollingContainer.nativeElement.scroll(0, this._scrollPosition);
	}

	getBackgroundColor(index: number) {
		if (index % 2) return 'light-gray-background';
		return 'orange-background';
	}

	isDirty(args: NavigationArgs) {
		return new Promise<boolean>(resolve => {
			if (args.isNavigatingBack) resolve(this.awoQuery.isDirty);
			else resolve(false);
		});
	}

	dirtyMessageTitle() {
		return 'WARNING';
	}

	dirtyMessageBody() {
		return 'Your changes have not been saved and will be lost if you proceed. Are you sure you want to continue?';
	}

	goTo(filterType: WorkOrderSourceType) {
		let canLeave = true;
		if (filterType === WorkOrderSourceType.task) canLeave = this.canGoToTaskFilter();

		if (!canLeave) {
			this.alertDialog.mainMessage = { text: 'A work order WorkType must be set to use the task filter' };
			this.alertDialog.open = true;
			return;
		}

		return NavigationService.navigateTo(Pages.workOrderFilter, { metric: this.metric, filterType, query: this.awoQuery });
	}

	onSave() {
		this.awoQuery.isDirty = false;
		this.metric.definition.query = new AWOQuery(this.awoQuery);
		this.menuPanelComponent.goBack();
	}

	private canGoToTaskFilter(): boolean {
		const workOrderFilter = this.awoQuery.workOrderFilter;
		if (!workOrderFilter) return false;

		return workOrderFilter.worktypeid && workOrderFilter.worktypeid.length > 0;
	}

	/**
	 * This is temporary until we get awo filter feature on everything else
	 * @param type
	 */
	enableButtonTemp(type: WorkOrderSourceType) {
		switch (type) {
			case WorkOrderSourceType.workOrders:
				return true;
			case WorkOrderSourceType.task:
				return true;
			default:
				return false;
		}
	}

	getFilterFields(sourceType: WorkOrderSourceType) {
		const filterTemplate = AdvancedWorkOrderFilterTemplate.get(sourceType);
		const awoQuery = this.awoQuery;
		switch (sourceType) {
			case WorkOrderSourceType.workOrders:
				const workOrderFilter = awoQuery?.workOrderFilter;
				return this.getActiveFilterFields(workOrderFilter, filterTemplate);
			case WorkOrderSourceType.task:
				const taskFilter = awoQuery?.taskFilter;
				return this.getActiveFilterFields(taskFilter, filterTemplate);
			default:
				return '';
		}
	}

	getActiveFilterFields(queryFilter, filterTemplate) {
		let filterFields = '';
		if (queryFilter) {
			filterTemplate.forEach(filter => {
				if (queryFilter[filter.key]) filterFields = filterFields + filter.name + ', ';
			});
			if (queryFilter.customFields?.length) {
				queryFilter.customFields.forEach(field => {
					filterFields = filterFields + field.name + ', ';
				});
			}
		}
		return filterFields.slice(0, -2);
	}
}
