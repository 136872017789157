import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class Status extends OmniModel<Status, Contracts.StatusContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _statusCode: SedaruUtils.Undoable<string>;
	get statusCode(): string {
		if (!this._statusCode) this._statusCode = new SedaruUtils.Undoable<string>();
		return this._statusCode.value;
	}
	set statusCode(value: string) {
		if (!this._statusCode) {
			this._statusCode = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._statusCode.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _workOrderVisible: SedaruUtils.Undoable<boolean>;
	get workOrderVisible(): boolean {
		if (!this._workOrderVisible) this._workOrderVisible = new SedaruUtils.Undoable<boolean>();

		return this._workOrderVisible.value;
	}
	set workOrderVisible(value: boolean) {
		if (!this._workOrderVisible) {
			this._workOrderVisible = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._workOrderVisible.value = value;
	}

	private _taskVisible: SedaruUtils.Undoable<boolean>;
	get taskVisible(): boolean {
		if (!this._taskVisible) this._taskVisible = new SedaruUtils.Undoable<boolean>();

		return this._taskVisible.value;
	}
	set taskVisible(value: boolean) {
		if (!this._taskVisible) {
			this._taskVisible = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._taskVisible.value = value;
	}

	static fromContract(contract: Contracts.StatusContract): Status {
		const model = new Status();
		model.id = contract.ObjectId.toString();
		model.statusCode = contract.StatusCode;
		model.description = contract.Description;
		model.workOrderVisible = contract.WoVisible;
		model.taskVisible = contract.TaskVisible;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.StatusContract {
		const contract = new Contracts.StatusContract();
		contract.ObjectId = +this.id;
		contract.StatusCode = this.statusCode;
		contract.Description = this.description;
		contract.TaskVisible = this.taskVisible;
		contract.WoVisible = this.workOrderVisible;
		return contract;
	}

	onUpdateInformation(copyModel: Status): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._statusCode, () => {
				this.statusCode = copyModel.statusCode;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._taskVisible, () => {
				this.taskVisible = copyModel.taskVisible;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._workOrderVisible, () => {
				this.workOrderVisible = copyModel.workOrderVisible;
			}) || wasChanged;

		return wasChanged;
	}
}
