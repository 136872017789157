<div class="scrolling-content">
	<mat-action-list class="omni-list-panel">
		<div class="omni-list-item-wrapper">
			<div *ngIf="feature.geometry">
				<mat-list-item class="omni-list-panel-item">
					<div class="attribute-wrapper">
						<span class="attribute-header">location</span>
						<span class="attribute-value">
							<img mat-ripple class="omni-sm-img" (click)="goToLocation()" src="assets/locate.png" />
						</span>
					</div>
				</mat-list-item>
				<mat-divider class="divider-item"></mat-divider>
			</div>
			<div *ngFor="let attribute of attributes">
				<mat-list-item class="omni-list-panel-item">
					<div class="attribute-wrapper">
						<span class="attribute-header">{{ attribute.key }}</span>
						<span #attributevalue class="attribute-value" *ngIf="attribute.value !== 'Null' && attribute.value !== ''"  [matTooltip]="attribute.value" [matTooltipPosition]="'above'" [matTooltipDisabled]="(attributevalue.offsetWidth >= attributevalue.scrollWidth)">
							{{ attribute.value }}
						</span>
						<span class="attribute-value" *ngIf="attribute.value === 'Null' || attribute.value === ''">-</span>
					</div>
				</mat-list-item>
				<mat-divider class="divider-item"></mat-divider>
			</div>
		</div>
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end" *ngIf="mode === 'add workasset'">
		<button mat-button class="omni-md-font" (click)="apply()">add selected<img class="omni-sm-img add-selected-img" src="assets/done.png" /></button>
	</mat-card-actions>
</mat-card-footer>
