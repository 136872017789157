import { Attachment } from './attachment.model';
import { AttachmentContract } from '../../contracts/work-order';

export class Attachments extends Array<Attachment> {
	static fromContracts(attachmentContracts: AttachmentContract[]) {
		return Attachments.fromModels(attachmentContracts.map(c => Attachment.fromContract(c)));
	}

	static fromModels(models: Attachment[]): Attachments {
		const wrappersList = new Attachments();
		for (const model of models) wrappersList.push(model);
		return wrappersList;
	}

	getContracts = () => {
		const attachmentContracts = new Array<AttachmentContract>();
		for (const attachment of this) {
			const attachmentContract = attachment.getContract();
			attachmentContracts.push(attachmentContract);
		}
		return attachmentContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
