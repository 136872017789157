import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { GlobalConfig } from '../../../models/global-config.model';
import { FormControlDefinition, ControlType, FormControlValue, ValueContext, ContextType } from '../../index';

export class FormTransform extends TransformBase {
	private _assetLayerConfig: any;
	private _assetDefinition: SedaruModels.AssetDefinition;

	static getFormControlValue(valueConfig: any): SedaruModels.FormControlValue {
		const value = new SedaruModels.FormControlValue();
		value.text = valueConfig.Text;
		value.textFormatId = valueConfig.TextFormatID;
		value.valueFormatId = valueConfig.ValueFormatID;
		if (valueConfig.Context) {
			value.context = FormTransform.getValueContext(valueConfig.Context);
		}
		return value;
	}

	private static getValueContext(contextConfig: any): SedaruModels.ValueContext {
		const context = new SedaruModels.ValueContext();
		context.fieldname = contextConfig.FieldName;
		context.type = FormTransform.getContextType(contextConfig.Type);
		return context;
	}

	private static getContextType(contextType: string): SedaruModels.ContextType {
		if (!contextType) return SedaruModels.ContextType.None;

		if (contextType.toLowerCase() === 'asset') return SedaruModels.ContextType.Asset;
		if (contextType.toLowerCase() === 'workorder') return SedaruModels.ContextType.WorkOrder;
		if (contextType.toLowerCase() === 'history') return SedaruModels.ContextType.History;
		if (contextType.toLowerCase() === 'formula') return SedaruModels.ContextType.Formula;
		if (contextType.toLowerCase() === 'sourceprovider') return SedaruModels.ContextType.SourceProvider;
		if (contextType.toLowerCase() === 'formvalueprovider') return SedaruModels.ContextType.FormValueProvider;

		return SedaruModels.ContextType.None;
	}

	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig, assetLayerConfig: any, assetDefinition: SedaruModels.AssetDefinition) {
		super(fieldforceConfig, globalConfiguration);
		this._assetLayerConfig = assetLayerConfig;
		this._assetDefinition = assetDefinition;
	}

	protected onTransform(): TransformationResult {
		if (this._assetLayerConfig.InformationForm) {
			this._assetDefinition.informationForm = this.getFormDefinition(this._assetLayerConfig.InformationForm, this._assetDefinition);
		}

		if (this._assetLayerConfig.Forms && this._assetLayerConfig.Forms.length > 0) {
			for (const formConfig of this._assetLayerConfig.Forms) {
				const formDefinition = this.getFormDefinition(formConfig, this._assetDefinition);
				this._assetDefinition.forms.push(formDefinition);
			}
		}

		return this.getResult(true);
	}

	private getFormDefinition(formConfig: any, assetDefinition: SedaruModels.AssetDefinition): SedaruModels.FormDefinition {
		const formDefinition = new SedaruModels.FormDefinition(this.globalConfig, assetDefinition);

		formDefinition.legacyId = formConfig.ID;
		formDefinition.workType = formConfig.WorkType;
		formDefinition.title = formConfig.Title;

		if (formConfig.Pages && formConfig.Pages.length > 0) {
			this.transformPages(formConfig, formDefinition);
		}

		return formDefinition;
	}

	private transformPages(formConfig: any, formDefinition: SedaruModels.FormDefinition) {
		let index = 0;
		for (const pageConfig of formConfig.Pages) {
			const page = new SedaruModels.FormPageDefinition(formDefinition);
			page.legacyId = pageConfig.ID;
			page.index = index;

			if (pageConfig.ControlGroups && pageConfig.ControlGroups.length > 0) {
				this.transformControlGroups(pageConfig, page);
			}

			if (pageConfig.Footer && pageConfig.Footer.Controls && pageConfig.Footer.Controls.length > 0) {
				const transformedControls = this.getTransformedControls(pageConfig.Footer.Controls, page);
				page.footer = transformedControls;
			}

			formDefinition.pages.push(page);
			index++;
		}
	}

	private transformControlGroups(pageConfig: any, pageDefinition: SedaruModels.FormPageDefinition) {
		let index = 0;
		for (const controlGroupConfig of pageConfig.ControlGroups) {
			const controlGroup = new SedaruModels.FormControlGroup(pageDefinition);
			controlGroup.title = controlGroupConfig.Title;
			controlGroup.legacyId = controlGroupConfig.ID;
			controlGroup.index = index;

			if (controlGroupConfig.Controls && controlGroupConfig.Controls.length > 0) {
				const transformedControls = this.getTransformedControls(controlGroupConfig.Controls, controlGroup);
				controlGroup.controls = transformedControls;
			}

			pageDefinition.controlGroups.push(controlGroup);
			index++;
		}
	}

	private getTransformedControls(controlConfigs: any[], controlGroup: SedaruModels.FormControlGroup | SedaruModels.FormPageDefinition): SedaruModels.FormControlDefinitions {
		const controls = new SedaruModels.FormControlDefinitions();

		if (!controlConfigs || controlConfigs.length === 0) {
			return controls;
		}

		let index = 0;
		for (const controlConfig of controlConfigs) {
			const control = new SedaruModels.FormControlDefinition(this.globalConfig, controlGroup);
			control.legacyId = controlConfig.ID;
			control.index = index;
			control.readOnly = controlConfig.ReadOnly;
			control.required = controlConfig.Required;
			control.controlType = this.parseControlType(controlConfig.ControlType);
			control.skipOnEdit = controlConfig.SkipOnEdit;
			control.defaultFieldName = controlConfig.DefaultFieldName;
			control.styleId = controlConfig.StyleID;

			if (controlConfig.Labels) {
				control.title = controlConfig.Labels.Title;
				control.description = controlConfig.Labels.Description;
			}

			if (controlConfig.Input) {
				control.input = this.getFormControlInput(controlConfig.Input);
			}

			if (controlConfig.Value) {
				control.value = FormTransform.getFormControlValue(controlConfig.Value);
			}
			controls.push(control);

			index++;
		}

		return controls;
	}

	private getFormControlInput(inputConfig: any): SedaruModels.FormControlInput {
		const input = new SedaruModels.FormControlInput();
		input.allowDecimals = inputConfig.AllowDecimals;
		input.type = this.parseInputType(inputConfig.Type);

		if (inputConfig.List) {
			input.list = this.getList(inputConfig.List);
		}

		return input;
	}

	private getList(listConfig: any): SedaruModels.FormControlInputList {
		const list = new SedaruModels.FormControlInputList();
		list.dataSourceId = listConfig.ServiceID;
		list.layerIndex = listConfig.LayerIndex;
		list.fieldName = listConfig.FieldName;

		if (listConfig.ListItems && listConfig.ListItems.length > 0) {
			for (const listItemConfig of listConfig.ListItems) {
				const listItem = new SedaruModels.FormControlInputListItem();
				listItem.text = listItemConfig.Text;
				listItem.value = listItemConfig.Value;
				list.listItems.push(listItem);
			}
		}

		return list;
	}

	private parseControlType(controlType: string): SedaruModels.ControlType {
		if (!controlType) {
			return SedaruModels.ControlType.Control1x1;
		}

		if (controlType.toLowerCase() === 'control1x2') {
			return SedaruModels.ControlType.Control1x2;
		} else if (controlType.toLowerCase() === 'footer') {
			return SedaruModels.ControlType.Footer;
		}

		return SedaruModels.ControlType.Control1x1;
	}

	private parseInputType(inputType: string): SedaruModels.FormControlInputType {
		if (!inputType) {
			return SedaruModels.FormControlInputType.None;
		}

		if (inputType.toLowerCase() === 'list' || inputType.toLowerCase() === 'picklist') {
			return SedaruModels.FormControlInputType.List;
		} else if (inputType.toLowerCase() === 'picture' || inputType.toLowerCase() === 'image') {
			return SedaruModels.FormControlInputType.Picture;
		} else if (inputType.toLowerCase() === 'keypad' || inputType.toLowerCase() === 'numberpad') {
			return SedaruModels.FormControlInputType.Keypad;
		} else if (inputType.toLowerCase() === 'text' || inputType.toLowerCase() === 'string') {
			return SedaruModels.FormControlInputType.Text;
		} else if (inputType.toLowerCase() === 'datepicker' || inputType.toLowerCase() === 'date') {
			return SedaruModels.FormControlInputType.DatePicker;
		} else if (inputType.toLowerCase() === 'timepicker' || inputType.toLowerCase() === 'time') {
			return SedaruModels.FormControlInputType.TimePicker;
		} else if (inputType.toLowerCase() === 'datetimepicker' || inputType.toLowerCase() === 'datetime') {
			return SedaruModels.FormControlInputType.DateTimePicker;
		} else if (inputType.toLowerCase() === 'formvalueprovider') {
			return SedaruModels.FormControlInputType.FormValueProvider;
		}

		return SedaruModels.FormControlInputType.None;
	}
}
