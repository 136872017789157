import { Component, OnInit, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';

@Component({
	selector: 'app-team-view',
	templateUrl: './team-view.component.html',
	styleUrls: ['./team-view.component.scss']
})
export class TeamViewComponent extends MenuPanelBaseComponent implements OnInit {
	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	ngOnInit(): void {}
}
