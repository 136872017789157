import { Component, OnInit, Input } from '@angular/core';
import { PacifierType } from './pacifier-types.enum';
import { Color } from 'models';
import { PacifierResolver } from './pacifier-resolver.base';

@Component({
	selector: 'app-pacifiers',
	templateUrl: './pacifiers.component.html',
	styleUrls: ['./pacifiers.component.scss']
})
export class PacifiersComponent implements OnInit {

	@Input() resolver: PacifierResolver;

	constructor() {}

	ngOnInit(): void {}
}
