import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Search } from 'sedaru-util/generic-search/search';
import { OmniListComponent } from '../omni-list/omni-list.component';
import { SearchController } from 'sedaru-util/generic-search/search-controller';
import { ListItemResolver } from '../omni-list/list-item/templates/list-item.resolver';

@Component({
	selector: 'app-search-list-component',
	templateUrl: './search-list-component.component.html',
	styleUrls: ['./search-list-component.component.scss']
})
export class SearchListComponentComponent implements OnInit {
	/** the name of the  */
	@Input() backgroundColor = 'dark-gray-background';
	/** the header of the search input field */
	@Input() header = 'search query';
	/** the placeholder to display in the input */
	@Input() inputPlaceHolder = 'type to search...';
	/** list to loop through */
	@Input() searchController: SearchController<any>;

	/** the height of the space that the search list will occupy */
	@Input() height: string;

	@Output() itemSelected = new EventEmitter();

	search: Search<any>;

	@Input() selectedItem: any;

	@Input() itemKey: string;

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	selectedItems: Array<any> = [];

	getListItemResolver: (item: any) => ListItemResolver;

	ngOnInit() {
		this.search = new Search<any>(this.searchController);
		this.listComponent.getResolver = item => {
			return this.getListItemResolver(item);
		};

		const selectedItemObj = {};
		if (this.itemKey && this.selectedItem) {
			selectedItemObj['taskId'] = this.selectedItem[this.itemKey];
			this.selectedItems.push(selectedItemObj);
		}
	}

	searchList(searchText: string) {
		this.search.searchText = searchText.toLowerCase();
	}
}
