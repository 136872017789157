import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SnackbarMessageTypes } from './snackbar-message-types.model';
import { OmniSnackBarMessage } from './omni-snack-bar/snackbar-message';
import { OmniSnackBarActionButton } from './omni-snack-bar/snackbar-action-button';
import { OmniSnackBarComponent } from './omni-snack-bar/omni-snack-bar.component';
import { OmniSnackBarService } from './omni-snack-bar/omni-snack-bar.service.ts.service';
/**
 * The Omni flash message service is used for poping the user messages on bottom screen
 */
@Injectable({
	providedIn: 'root'
})
export class FlashMessageService {
	/**
	 * This constructor loads and injects dependencies
	 * @param {MatSnackBar} snackBar - Used for displaying error messages in the UI
	 */
	constructor(private snackBar: MatSnackBar, private omniSnackBarService: OmniSnackBarService) {}

	/**
	 * Method is used to display messages in snackbar.
	 * @param {string} errorText - the snack bar message to be displayed.
	 * @param {SnackbarMessageTypes} messageType - the snack bar message type
	 */
	popMessage(errorText: string, messageType: SnackbarMessageTypes = SnackbarMessageTypes.INFO): void {
		let buttonText = 'Ok';
		const snackBarOptions: MatSnackBarConfig = {
			verticalPosition: 'bottom',
			horizontalPosition: 'right'
		};
		switch (messageType) {
			case SnackbarMessageTypes.ERROR:
				buttonText = 'Ok';
				snackBarOptions.panelClass = ['red-snackbar'];
				break;
			case SnackbarMessageTypes.WARNING:
				buttonText = 'Ok';
				snackBarOptions.panelClass = ['orange-snackbar'];
				break;
			case SnackbarMessageTypes.SUCCESS:
				buttonText = 'success';
				snackBarOptions.panelClass = ['green-snackbar'];
				snackBarOptions.duration = 3000;
				break;
			case SnackbarMessageTypes.INFO:
				break;
			default:
				break;
		}
		this.snackBar.open(errorText, buttonText, snackBarOptions);
	}

	openOmniSnackBar(snackBarMessage: OmniSnackBarMessage, buttons?: OmniSnackBarActionButton[], options?: MatSnackBarConfig) {
		this.omniSnackBarService.snackBarMessage = snackBarMessage;
		this.omniSnackBarService.snackBarButtons = buttons;
		if (!options) options = new MatSnackBarConfig();
		options.panelClass = 'omni-snack-bar-container';
		this.snackBar.openFromComponent(OmniSnackBarComponent, options);
	}

	closeSnackBar() {
		this.snackBar.dismiss();
	}

	showToast(message: string, buttonText: string, options?: MatSnackBarConfig, onButtonClicked?: () => void, onDismissed?: () => void) {
		const snackBarRef = this.snackBar.open(message, buttonText, options);
		if (onButtonClicked) {
			const actionSubscription = snackBarRef.onAction().subscribe(() => {
				actionSubscription.unsubscribe();
				onButtonClicked();
			});
		}
		if (onDismissed) {
			const dismissSubscription = snackBarRef.afterDismissed().subscribe(() => {
				dismissSubscription.unsubscribe();
				onDismissed();
			});
		}
	}
}
