import { ListItemResolver } from '../list-item.resolver';
import { ListTemplates } from '../templates.enum';

export class LeftIconOneLineItemResolver extends ListItemResolver {
	templateType = ListTemplates.leftIconOneLine;

	getIconPath: (item: any) => string;
	private _iconPath: string;
	private _iconPathRequested = false;

	getLabel: (item: any) => string;
	private _label: string;
	private _labelRequested = false;

	_getIconPath(item: any) {
		if (!this._iconPath && !this._iconPathRequested) {
			this._iconPath = this.getIconPath(item);
			this._iconPathRequested = true;
		}
		return this._iconPath;
	}

	_getLabel(item: any) {
		if (!this._label && !this._labelRequested) {
			this._label = this.getLabel(item);
			this._labelRequested = true;
		}
		return this._label;
	}

	resetFlags(): void {
		this._iconPath = this._label = null;
		this._iconPathRequested = this._labelRequested = false;
	}
}
