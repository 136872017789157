import { InvokableEvent } from './invokable-event';

export class NotificationObject<T> {
	private _onNotify: InvokableEvent;
	public get onNotify(): InvokableEvent {
		if (!this._onNotify) this._onNotify = new InvokableEvent();

		return this._onNotify;
	}

	notify(message: T) {
		this.onNotify.invoke(this, message);
	}
}
