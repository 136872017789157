import { StandardCustomFieldContract } from '../../contracts/work-order/standard-custom-field-contract';
import { OmniModel } from '../omni-model';
import { FieldType } from '../../sedaru-util/esri-core';
import { Undoable } from '../../sedaru-util/undoable';

/**
 * @enum SummaryFieldInputType, enumarate the input types for SWO custom field
 */
export enum SummaryFieldInputType {
	TEXT = 'Text',
	DATE_PICKER = 'DatePicker',
	DATETIME_PICKER = 'DateTimePicker',
	KEYPAD = 'Keypad',
	LIST = 'List'
}

export class StandardCustomField extends OmniModel<StandardCustomField, StandardCustomFieldContract> {

	/**
	 * id for custom field
	 */
	private _id: Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the custom field value
	 */
	private _customFieldValue: Undoable<any>;
	get customFieldValue(): any {
		if (!this._customFieldValue) this._customFieldValue = new Undoable<any>();
		return this._customFieldValue.value;
	}
	set customFieldValue(value: any) {
		if (!this._customFieldValue) {
			this._customFieldValue = new Undoable<string>(value);
			return;
		}
		this._customFieldValue.value = value;
	}

	/**
	 * holds the field name (Field in ArcGIS/configured in Studio)
	 */
	private _customFieldName: Undoable<string>;
	get customFieldName(): string {
		if (!this._customFieldName) this._customFieldName = new Undoable<string>();
		return this._customFieldName.value;
	}
	set customFieldName(value: string) {
		if (!this._customFieldName) {
			this._customFieldName = new Undoable<string>(value);
			return;
		}
		this._customFieldName.value = value;
	}

	/**
	 * holds field description to show in UI
	 */
	private _customFieldDescription: Undoable<string>;
	get customFieldDescription(): string {
		if (!this._customFieldDescription) this._customFieldDescription = new Undoable<string>();
		return this._customFieldDescription.value;
	}
	set customFieldDescription(value: string) {
		if (!this._customFieldDescription) {
			this._customFieldDescription = new Undoable<string>(value);
			return;
		}
		this._customFieldDescription.value = value;
	}

	/**
	 * holds the custom field type
	 */
	private _inputType: Undoable<SummaryFieldInputType>;
	get inputType(): SummaryFieldInputType {
		if (!this._inputType) this._inputType = new Undoable<SummaryFieldInputType>();
		return this._inputType.value;
	}
	set inputType(value: SummaryFieldInputType) {
		if (!this._inputType) {
			this._inputType = new Undoable<SummaryFieldInputType>(value);
			return;
		}
		this._inputType.value = value;
	}

	/**
	 * holds the flag to control field visibility in UI
	 */
	private _isVisible: Undoable<boolean>;
	get isVisible(): boolean {
		if (!this._isVisible) this._isVisible = new Undoable<boolean>();
		return this._isVisible.value;
	}
	set isVisible(value: boolean) {
		if (!this._isVisible) {
			this._isVisible = new Undoable<boolean>(value);
			return;
		}
		this._isVisible.value = value;
	}

	/**
	 * hold the flag to validate the required fields
	 */
	private _isRequired: Undoable<boolean>;
	get isRequired(): boolean {
		if (!this._isRequired) this._isRequired = new Undoable<boolean>();
		return this._isRequired.value;
	}
	set isRequired(value: boolean) {
		if (!this._isRequired) {
			this._isRequired = new Undoable<boolean>(value);
			return;
		}
		this._isRequired.value = value;
	}

	static fromContract(contract: StandardCustomFieldContract): StandardCustomField {
		const model = new StandardCustomField();
		model.id = contract.ObjectId?.toString();
		model.customFieldValue = contract.fieldValue === 'null' || contract.fieldValue === null || contract.fieldValue === undefined ? '' : contract.fieldValue;
		model.customFieldName = contract.fieldName;
		model.customFieldDescription = contract.fieldDescription;
		model.inputType = contract.inputType;
		model.isVisible = contract.isVisible;
		model.isRequired = contract.isRequired;
		model.clearDirty();
		return model;
	}

	getContract(): StandardCustomFieldContract {
		const contract = new StandardCustomFieldContract();
		contract.ObjectId = +this.id;
		contract.fieldValue = this.customFieldValue;
		contract.fieldName = this.customFieldName;
		contract.fieldDescription = this.customFieldDescription;
		contract.inputType = this.inputType;
		contract.isVisible = this.isVisible;
		contract.isRequired = this.isRequired;
		return contract;
	}

	onUpdateInformation(copyModel: StandardCustomField): boolean {
		let wasChanged = false;
		wasChanged = this.updateIfNotDirty<string>(this._id, () => {
			this.id = copyModel.id;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<string>(this._customFieldValue, () => {
			this.customFieldValue = copyModel.customFieldValue;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<string>(this._customFieldName, () => {
			this.customFieldName = copyModel.customFieldName;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<string>(this._customFieldDescription, () => {
			this.customFieldDescription = copyModel.customFieldDescription;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<SummaryFieldInputType>(this._inputType, () => {
			this.inputType = copyModel.inputType;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<boolean>(this._isVisible, () => {
			this.isVisible = copyModel.isVisible;
		}) || wasChanged;
		wasChanged = this.updateIfNotDirty<boolean>(this._isRequired, () => {
			this.isRequired = copyModel.isRequired;
		}) || wasChanged;
		return wasChanged;
	}

	public getArcGisFieldType(): FieldType {
		const {inputType, customFieldValue: fieldValue} = this;
		switch (inputType) {
			case SummaryFieldInputType.DATETIME_PICKER:
				return FieldType.DateTime;
			case SummaryFieldInputType.DATE_PICKER:
				return FieldType.esriFieldTypeDate;
			case SummaryFieldInputType.TEXT:
				return FieldType.esriFieldTypeString;
			case SummaryFieldInputType.KEYPAD:
				return this.getNumericalFieldType(fieldValue);
			case SummaryFieldInputType.LIST:
				return FieldType.string;
			default:
				return FieldType.none;
		}
	}

	private getNumericalFieldType(value: any): FieldType {
		if (isNaN(value)) {
			return FieldType.esriFieldTypeString;
		}
		if (value % 1 === 0) {
			return (-3.4E38 <= value && value <= 1.2E38) ? FieldType.esriFieldTypeSingle : FieldType.esriFieldTypeDouble;
		}
		return (-32.768 <= value && value <= 32.767) ? FieldType.esriFieldTypeSmallInteger : FieldType.esriFieldTypeInteger;
	}
}

