import { Component, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OmniListComponent } from '../omni-list/omni-list.component';
import { NoIconOneLineTemplateResolver } from '../omni-list/list-item/templates/no-icon-one-line/np-icon-one-line.resolver';
import { NoIconOneLineComponent } from '../omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { ListItemResolver } from '../omni-list/list-item/templates/list-item.resolver';

@Component({
	selector: 'app-context-menu',
	templateUrl: './context-menu.component.html',
	styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
	@ViewChild(OmniListComponent, { static: false }) listComponent: OmniListComponent;

	menuItems: Array<any>;
	topPosition: number;
	leftPosition: number;
	height: number;
	open = false;
	getResolverForItem: (item: any) => ListItemResolver;

	@Output() itemSelected = new EventEmitter<any>();

	constructor(private changeDetector: ChangeDetectorRef) {}

	show() {
		this.open = true;
		this.changeDetector.detectChanges();
		this.initializeList();
	}

	close(event?: MouseEvent) {
		if (event) event.stopPropagation();
		this.open = false;
	}

	private initializeList() {
		this.listComponent.getResolver = item => {
			return this.getResolverForItem(item);
		};
		this.listComponent.omniListItems = this.menuItems;
		this.height = this.menuItems.length * 41;
	}
}
