import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Metric, ArcGISAssetChannelAttributes } from 'models';
import { Feature, FieldType } from 'sedaru-util/esri-core';
import { Canvas } from 'omni-model/canvas.model';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { DataChannelService } from 'domain-service/data-channel.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { AssetRecord } from 'models/records/asset-record.model';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { WorkOrderWrapper } from 'models/work-order';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

/**
 * This component is designed to be placed in the right side panel.
 * It displays a list of available map types.
 */
@Component({
	selector: 'app-map-asset-attributes',
	templateUrl: './map-asset-attributes.component.html',
	styleUrls: ['./map-asset-attributes.component.scss']
})
export class MapAssetAttributesComponent extends MenuPanelBaseComponent implements OnInit, OnDestroy {
	mode: string;

	metric: Metric;

	feature: Feature;

	highlightParams: { objectid: number; canvas: Canvas; layerId: string; assetAttribute: string; historyAttribute: string };
	/**
	 * Holds the currently selected asset attributes.
	 */
	attributes: any[];

	hyperLink: string;

	assetType: string;

	tab: string;

	workOrder: WorkOrderWrapper;

	/**
	 * The constructly currently initialized a hard coded list of map types along with loading and injecting dependencies.
	 * @param {OmniMapService} mapService - Provides services to manage changes in the ESRI map added  to the canvas.
	 * appropriate component with the change in the current route.
	 */
	constructor(
		private mapService: CanvasMapService,
		private workOrderFactory: WorkOrderFactory,
		private grapicsService: OmniMapGraphicsService,
		private dataChannelService: DataChannelService,
		private interopService: OmniInteropService,
		private esriSdkService: EsriSdkService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.mode = args.parameter.mode;
		this.metric = args.parameter.metric;
		this.feature = args.parameter.feature;
		this.assetType = args.parameter.assetType;
		this.tab = args.parameter.tab;
		this.workOrder = args.parameter.workOrder;
		this.highlightParams = args.parameter.highlightParameters;

		this.attributes = this.aliasFieldNames(this.feature.attributes);
		this.menuPanelComponent.updateView({ title: this.getFacilityId() });

		const selectedMap = this.config?.selectedCanvas?.map;
		if (selectedMap.eventHandlers['workAssetSelectionHandler']) this.mode = 'add workasset';
		if (!this.workOrder) this.workOrder = this.workOrderFactory.currentWorkOrder;
	}

	onPageReload(args: NavigationArgs) {
		this.onPageNavigatedTo(args);
	}

	goToLocation() {
		let geometry;
		if (!this.feature.geometry.spatialReference) {
			geometry = this.getGeomertyFromWO(this.feature.geometry.x, this.feature.geometry.y);
			const graphic = new this.esriSdkService.esriMapSdk.Graphic({ geometry: geometry });
			if (geometry.spatialReference !== this.config.selectedCanvas.mapView.spatialReference) {
				// @ts-ignore
				geometry = this.convertSP(this.config.selectedCanvas, graphic.geometry);
			}
		} else if (this.feature.geometry.paths) {
			geometry = this.grapicsService.convertSP(this.config?.selectedCanvas, this.feature.geometry);
		} else {
			geometry = this.feature.geometry;
		}
		this.config.selectedCanvas.mapView.goTo({ target: geometry, scale: 1000 }, { duration: 1000, easing: 'ease-out' });
	}

	getGeomertyFromWO(xCoordinate, yCoordinate) {
		let spatialReference;
		if (this.interopService.arcGISManager.defaultAssetWkText) {
			spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkt: this.interopService.arcGISManager.defaultAssetWkText });
		} else spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkid: this.interopService.arcGISManager.defaultAssetWkid });
		const geometry = new this.esriSdkService.esriMapSdk.Point({ x: xCoordinate, y: yCoordinate, spatialReference });
		return geometry;
	}

	convertSP(canvas, geometry) {
		return this.esriSdkService.esriMapSdk.Projection.project(geometry, canvas.mapView.spatialReference);
	}

	async apply() {
		try {
			if (!this.workOrder) return;

			this.menuPanelComponent.updatePacifier(true);
			const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(this.assetType);
			const assetRecord = AssetRecord.create(assetDefinition, this.feature);
			this.workOrderFactory.createWorkAssetModel(this.workOrder, assetRecord);
			await this.workOrderFactory.updateWorkAsset(this.workOrder, null);
			const newAssetGraphics = this.mapService.getWorkAssetPointGraphic(this.workOrder.workAssets, true);
			newAssetGraphics.forEach(graphic => this.workOrder.workAssetGraphics.push(graphic));
			if (this.metric) {
				const workOrderGraphicLayer = this.mapService.getLayerByLayerId(this.metric.id + '_work_asset', this.config.selectedCanvas.mapView.map);
				workOrderGraphicLayer.addMany(newAssetGraphics);
				this.grapicsService.updateWorkOrderGraphic(this.metric, this.workOrder, this.config.selectedCanvas);
			}

			const eventHandlers = this.config?.selectedCanvas?.map?.eventHandlers;
			if (!eventHandlers) return;
			if (eventHandlers['workAssetSelectionHandler']) {
				eventHandlers['workAssetSelectionHandler'].remove();
				delete eventHandlers['workAssetSelectionHandler'];
			}

			this.grapicsService.identifyTaskOnMapView(this.config.selectedCanvas);
		} finally {
			this.menuPanelComponent.updatePacifier(false);
			NavigationService.navigateBackTo(Pages.workorderAssets, { workOrder: this.workOrder, advancedMode: true });
		}
	}

	getFacilityId() {
		let assetKey;
		const asset = this.mapService.selectedIdentifyAsset;
		const assetChannel = this.dataChannelService.getAssetChannelByAssetType(this.assetType);
		if (!assetChannel) return;
		const { uniqueFieldName } = assetChannel.attributes as ArcGISAssetChannelAttributes;
		for (const key of Object.keys(this.attributes)) {
			if (key.toLowerCase() === uniqueFieldName.toLowerCase()) {
				assetKey = this.attributes[key];
				break;
			}
		}
		const objectId = this.attributes['objectid'] || this.attributes['OBJECTID'] || this.attributes['ObjectId'] || this.attributes['objectId'];
		if (!assetKey) return objectId;
		return assetKey;
	}

	aliasFieldNames(attributes: Object) {
		const newAttributes = [];
		const assetLayer = this.interopService.arcGISManager.getAssetLayer(this.assetType);

		if (assetLayer != null) {
			for (const [key, value] of Object.entries(attributes)) {
				const field = assetLayer.fields.find(layerField => layerField.name.toLowerCase() === key.toLowerCase());
				if (!value || !field) continue;
				if (field.type === FieldType.DateTime || field.type === FieldType.esriFieldTypeDate && typeof (value) !== 'string') {
					attributes[key] = new Date(value).toLocaleDateString('en-US');
				}
			}

			for (const key in attributes) {
				if (Object.prototype.hasOwnProperty.call(attributes, key)) {
					const newAttr = { key: null, value: null };
					if (assetLayer && assetLayer.fields) {
						for (let i = 0; i < assetLayer.fields.length; i++) {
							if (key !== assetLayer.fields[i].name) continue;
							newAttr.key = assetLayer.fields[i].alias;
							break;
						}
					}
					newAttr.value = attributes[key];
					if (!newAttr.key) newAttr.key = key;

					newAttributes.push(newAttr);
				}
			}
		} else {
			for (const [key, value] of Object.entries(attributes)) {
				const newAttr = { key: key, value: value };
				newAttributes.push(newAttr);
			}
		}

		return newAttributes;
	}
}
