import { InspectionAttribute } from './inspection-attributes.model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

/**
 * This defines the inspection
 */
export class Inspection extends OmniModel<Inspection, Contracts.InspectionContract> {
	/**
	 * uuid for inspection
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the inspection type
	 */
	private _inspectionType: SedaruUtils.Undoable<string>;
	get inspectionType(): string {
		if (!this._inspectionType) this._inspectionType = new SedaruUtils.Undoable<string>();
		return this._inspectionType.value;
	}
	set inspectionType(value: string) {
		if (!this._inspectionType) {
			this._inspectionType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._inspectionType.value = value;
	}

	/**
	 * holds the inspection type id
	 */
	private _inspectionTypeId: SedaruUtils.Undoable<number>;
	get inspectionTypeId(): number {
		if (!this._inspectionTypeId) this._inspectionTypeId = new SedaruUtils.Undoable<number>();
		return this._inspectionTypeId.value;
	}
	set inspectionTypeId(value: number) {
		if (!this._inspectionTypeId) {
			this._inspectionTypeId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._inspectionTypeId.value = value;
	}

	/**
	 * holds the inspection asset type
	 */
	private _assetType: SedaruUtils.Undoable<string>;
	get assetType(): string {
		if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
		return this._assetType.value;
	}
	set assetType(value: string) {
		if (!this._assetType) {
			this._assetType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetType.value = value;
	}

	/**
	 * holds the inspection attribute list
	 */
	private _attributes: Array<InspectionAttribute>;
	get attributes(): InspectionAttribute[] {
		return this._attributes ? this._attributes : (this._attributes = new Array<InspectionAttribute>());
	}

	static fromContract(contract: Contracts.InspectionContract): Inspection {
		const model = new Inspection();
		// model.id = contract.objectid.toString();
		model.inspectionType = contract.InspectionType;
		model.inspectionTypeId = contract.InspectionTypeID;
		model.assetType = contract.AssetType;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.InspectionContract {
		const contract = new Contracts.InspectionContract();
		// contract.objectid = +this.id;
		contract.InspectionType = this.inspectionType;
		contract.InspectionTypeID = this.inspectionTypeId;
		contract.AssetType = this.assetType;
		return contract;
	}

	onUpdateInformation(copyModel: Inspection): boolean {
		let wasChanged = false;
		// wasChanged = this.updateIfNotDirty<string>(this._id, () => { this.id = copyModel.id }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._inspectionType, () => {
				this._inspectionType = copyModel._inspectionType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._inspectionTypeId, () => {
				this._inspectionTypeId = copyModel._inspectionTypeId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this._assetType = copyModel._assetType;
			}) || wasChanged;
		return wasChanged;
	}
}
