export enum TimeframeDefinitionType {
    None,
    This,
    NextX,
    LastX,
    RestOfX,
    XToNow,
    LaterThanToday,
    EarlierThanToday,
    ExactDate,
    BeforeDate,
    AfterDate,
    DateRange,
    Boundless,
    TodayAndEarlier
}
