import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { loadModules } from 'esri-loader';
import { IdentityManagerService } from 'app/identity-manager/identity-manager.service';
import esri = __esri; // Esri TypeScript Types

export class EsriMapSdk {
	Map: esri.Map;
	MapView: esri.View;
	BaseMap: esri.Basemap;
	FeatureLayer: any;
	MapImageLayer: esri.MapImageLayer;
	TileLayer: esri.TileLayer;
	MapGeometryExtent: esri.ExtentConstructor;
	MapGeometrySpatialReference: esri.SpatialReferenceConstructor;
	Projection: esri.projection;
	Point: esri.PointConstructor;
	Graphic: esri.GraphicConstructor;
	GraphicsLayer: esri.GraphicsLayer;
	GeometryService: esri.GeometryService;
	ProjectParameters: esri.ProjectParameters;
	IdentityManager: esri.IdentityManager;
	GeometryEngine: esri.geometryEngine;
	WatchUtils: esri.watchUtils;
	Polygon: esri.Polygon;
	IdentifyTask: esri.IdentifyTask;
	IdentifyParameters: esri.IdentifyParameters;
	SimpleMarkerSymbol: esri.SimpleMarkerSymbol;
	SimpleLineSymbol: esri.SimpleLineSymbol;
	Color: esri.Color;
	Sketch: esri.Sketch;
	SketchViewModel: esri.SketchViewModel;
	Draw: esri.Draw;
	Legend: esri.Legend;
	PictureMarkerSymbol: esri.PictureMarkerSymbol;
	SimpleRenderer: esri.SimpleRenderer;
	Geometry: esri.GeometryConstructor;
	webMercatorUtils: esri.webMercatorUtils;
	urlUtils: esri.urlUtils;
}

@Injectable({
	providedIn: 'root'
})
export class EsriSdkService implements OnDestroy {
	/**
	 * The esri sdk
	 */
	esriMapSdk: EsriMapSdk;

	/**
	 * An event emitter that will emit an event when sdk is loaded
	 */
	esriSdkReadyEvent = new EventEmitter<void>();

	constructor() { }

	initialize(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.loadEsriSdk().then(mapSdk => {
				this.esriMapSdk = mapSdk;
				this.esriSdkReadyEvent.emit();
				console.log('esri sdk loaded');
				resolve(true);
			});
		});
	}

	/**
	 * Loads the ESRI SDK modules OMNI needs for its maps via the ArcGIS API for JavaScript.
	 * @returns {Promise<EsriMapSdk>} - Returns an ESRI Map SDK namespace.  All properties in the namespace are ESRI classes.
	 */
	private async loadEsriSdk(): Promise<EsriMapSdk> {
		try {
			const [
				esriMap,
				esriMapView,
				esriBaseMap,
				esriFeatureLayer,
				esriMapImageLayer,
				esriTileLayer,
				esriMapGeoExtent,
				esriMapGeoSR,
				esriProjection,
				esriPoint,
				esriGraphic,
				esriGraphicLayer,
				esriGeometryService,
				esriProjectParameters,
				esriIdentityManager,
				esriGeometryEngine,
				esriWatchUtils,
				esriPolygon,
				esriIdentifyTask,
				esriIdentifyParameters,
				esriSimpleMarkerSymbol,
				esriSimpleLineSymbol,
				esriColor,
				esriSketch,
				esriSketchViewModel,
				esriDraw,
				esriLegend,
				esriPictureMarkerSymbol,
				esriSimpleRenderer,
				esriGeometry,
				webMercatorUtils,
				urlUtils
			] = await loadModules([
				'esri/Map',
				'esri/views/MapView',
				'esri/Basemap',
				'esri/layers/FeatureLayer',
				'esri/layers/MapImageLayer',
				'esri/layers/BaseDynamicLayer',
				'esri/geometry/Extent',
				'esri/geometry/SpatialReference',
				'esri/geometry/projection',
				'esri/geometry/Point',
				'esri/Graphic',
				'esri/layers/GraphicsLayer',
				'esri/tasks/GeometryService',
				'esri/rest/support/ProjectParameters',
				'esri/identity/IdentityManager',
				'esri/geometry/geometryEngine',
				'esri/core/watchUtils',
				'esri/geometry/Polygon',
				'esri/tasks/IdentifyTask',
				'esri/rest/support/IdentifyParameters',
				'esri/symbols/SimpleMarkerSymbol',
				'esri/symbols/SimpleLineSymbol',
				'esri/Color',
				'esri/widgets/Sketch',
				'esri/widgets/Sketch/SketchViewModel',
				'esri/views/draw/Draw',
				'esri/widgets/Legend',
				'esri/symbols/PictureMarkerSymbol',
				'esri/renderers/SimpleRenderer',
				'esri/geometry/Geometry',
				'esri/geometry/support/webMercatorUtils',
				'esri/core/urlUtils'
			]);

			return {
				Map: esriMap,
				MapView: esriMapView,
				BaseMap: esriBaseMap,
				FeatureLayer: esriFeatureLayer,
				MapImageLayer: esriMapImageLayer,
				TileLayer: esriTileLayer,
				MapGeometryExtent: esriMapGeoExtent,
				MapGeometrySpatialReference: esriMapGeoSR,
				Projection: esriProjection,
				Point: esriPoint,
				Graphic: esriGraphic,
				GraphicsLayer: esriGraphicLayer,
				GeometryService: esriGeometryService,
				ProjectParameters: esriProjectParameters,
				IdentityManager: esriIdentityManager,
				GeometryEngine: esriGeometryEngine,
				WatchUtils: esriWatchUtils,
				Polygon: esriPolygon,
				IdentifyTask: esriIdentifyTask,
				IdentifyParameters: esriIdentifyParameters,
				SimpleMarkerSymbol: esriSimpleMarkerSymbol,
				SimpleLineSymbol: esriSimpleLineSymbol,
				Color: esriColor,
				Sketch: esriSketch,
				SketchViewModel: esriSketchViewModel,
				Draw: esriDraw,
				Legend: esriLegend,
				PictureMarkerSymbol: esriPictureMarkerSymbol,
				SimpleRenderer: esriSimpleRenderer,
				Geometry: esriGeometry,
				webMercatorUtils: webMercatorUtils,
				urlUtils: urlUtils
			};
		} catch (error) {
			console.error('EsriLoader: ', error);
		}
	}

	ngOnDestroy() {
		this.esriMapSdk = null;
		this.esriSdkReadyEvent.complete();
	}
}
