import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

/**
 * This defines the equipment
 */
export class Equipment extends OmniModel<Equipment, Contracts.EquipmentContract> {
	/**
	 * object id for equipment
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}
	/**
	 * holds the equipment id
	 */
	private _equipmentId: SedaruUtils.Undoable<string>;
	get equipmentId(): string {
		if (!this._equipmentId) this._equipmentId = new SedaruUtils.Undoable<string>();
		return this._equipmentId.value;
	}
	set equipmentId(value: string) {
		if (!this._equipmentId) {
			this._equipmentId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._equipmentId.value = value;
	}

	/**
	 * holds the equipment description
	 */
	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	/**
	 * holds the equipment hours
	 */
	private _hourlyRate: SedaruUtils.Undoable<number>;
	get hourlyRate(): number {
		if (!this._hourlyRate) this._hourlyRate = new SedaruUtils.Undoable<number>();
		return this._hourlyRate.value;
	}
	set hourlyRate(value: number) {
		if (!this._hourlyRate) {
			this._hourlyRate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._hourlyRate.value = value;
	}

	/**
	 * holds the equipment serial number
	 */
	private _serialNumber: SedaruUtils.Undoable<string>;
	get serialNumber(): string {
		if (!this._serialNumber) this._serialNumber = new SedaruUtils.Undoable<string>();
		return this._serialNumber.value;
	}
	set serialNumber(value: string) {
		if (!this._serialNumber) {
			this._serialNumber = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._serialNumber.value = value;
	}

	/**
	 * holds the equipment type
	 */
	private _equipmentType: SedaruUtils.Undoable<string>;
	get equipmentType(): string {
		if (!this._equipmentType) this._equipmentType = new SedaruUtils.Undoable<string>();
		return this._equipmentType.value;
	}
	set equipmentType(value: string) {
		if (!this._equipmentType) {
			this._equipmentType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._equipmentType.value = value;
	}

	static fromContract(contract: Contracts.EquipmentContract): Equipment {
		const model = new Equipment();
		model.id = contract.ObjectId.toString();
		model.equipmentId = contract.EquipmentId ? contract.EquipmentId : contract['equipmentid'];
		model.description = contract.Description ? contract.Description : contract['description'];
		model.hourlyRate = contract.HourlyRate ? contract.HourlyRate : contract['hourlyrate'];
		model.serialNumber = contract.SerialNumber ? contract.SerialNumber : contract['serialnumber'];
		model.equipmentType = contract.EquipmentType ? contract.EquipmentType : contract['equipmenttype'];

		model.clearDirty();
		return model;
	}

	getContract(): Contracts.EquipmentContract {
		const contract = new Contracts.EquipmentContract();
		contract.ObjectId = +this.id;
		contract.EquipmentId = this.equipmentId.toString();
		contract.Description = this.description;
		contract.HourlyRate = +this.hourlyRate;
		contract.SerialNumber = this.serialNumber;
		contract.EquipmentType = this.equipmentType;
		return contract;
	}

	onUpdateInformation(copyModel: Equipment): boolean {
		let wasChanged = false;
		// wasChanged = this.updateIfNotDirty<string>(this._id, () => { this.id = copyModel.id }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._equipmentId, () => {
				this.equipmentId = copyModel.equipmentId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._hourlyRate, () => {
				this.hourlyRate = copyModel.hourlyRate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._serialNumber, () => {
				this.serialNumber = copyModel.serialNumber;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._equipmentType, () => {
				this.equipmentType = copyModel.equipmentType;
			}) || wasChanged;
		return wasChanged;
	}
}
