import {Apollo, gql} from 'apollo-angular';
import { MetricTile, Metric, TypeOfValueEnum, ValueFormatEnum } from './../../models';
import { GuiConfigService } from '../../domain-service/gui-config.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { map, catchError } from 'rxjs/operators';
import { GuiConfig } from 'omni-model/gui-config.model';
import { MetricService } from 'domain-service/metric.service';
import { TimeframeDefinition } from 'models/time-frame/timeframe-definition.model';
import { TimeframeDefinitionType } from 'models/time-frame/timeframe-definition-type.enum';
import { TimeframeUnit } from 'models/time-frame/timeframe-unit.enum';
import { TileComponent } from './tile/tile.component';

@Injectable({
	providedIn: 'root'
})
export class TileService {
	/**
	 * Keeps track of the last dragged tile
	 */
	tileDragged: TileComponent;
	tilesAtRisk: MetricTile[] = [];

	/**
	 * @param {MetricService} metricService - MetricSerivce needed to get the list of features
	 * @param {GuiConfigService} guiConfig - guiConfigService need to update the tile's display value
	 */
	constructor(private guiConfigService: GuiConfigService, private apollo: Apollo) {}

	/**
	 * returns an array of tiles that contain a given metric
	 */
	findTilesWithMetricInConfig(metric: Metric, selectedConfig: GuiConfig): MetricTile[] {
		const tilesWithMetric = [];
		Object.values(selectedConfig.tileList).forEach(tile => {
			if (tile.metric && tile.metric.id === metric.id) {
				tilesWithMetric.push(tile);
			}
		});
		return tilesWithMetric;
	}

	/**
	 * This method will delete the selected metric from the tile.
	 * @param {string} metricId - Selected metric's id
	 */
	deleteMetricFromAllTiles(metricId: string): void {
		// First loop through all avaiable gui configurations in cache.
		for (const config of Object.values(this.guiConfigService.availableConfigurations)) {
			// Next, loop through all the tiles in each config to check for matching metric
			for (const tile of Object.values(config.tileList)) {
				// When matching metric found
				if (tile.metric && tile.metric.id === metricId) {
					this.deleteMetricTile(tile.id).subscribe();
					tile.clear()
				}
			}
		}
	}

	/**
	 * Assigns a given metric to the active tile
	 */
	assignMetricToTile(metric: Metric, tile: MetricTile, config: GuiConfig) {
		tile.metric = metric;
		tile.metricId = metric.id;
		config.tileList[tile.tilePosition] = tile;
		return this.saveMetricTile(config.id, tile);
	}

	/**
	 * The method will format the metric value with selected formatting method and save it to displayValue property in the metric
	 * 	object
	 * @param {MetricTile} tile the tile object received from guiConfig
	 */
	applyValueFormatToTile(tile: MetricTile): void {
		if (!tile.metric || !tile.metric.definition) {
			return;
		}

		switch (tile.metric.definition.displayValueSettings.valueFormat.enumValue) {
			case ValueFormatEnum.wholeNumber:
				this.formatThousandSeparatorAndDecimal(tile, 0);
				break;
			case ValueFormatEnum.decimalTenths:
				this.formatThousandSeparatorAndDecimal(tile, 1);
				break;
			case ValueFormatEnum.decimalHundredths:
				this.formatThousandSeparatorAndDecimal(tile, 2);
				break;
			case ValueFormatEnum.currency:
				this.formatThousandSeparatorAndDecimal(tile, 2, true);
				break;
			case ValueFormatEnum.none:
			default:
				// default to no formatting but need to turn number into string
				this.formatThousandSeparatorAndDecimal(tile, 0);
				break;
		}

		const { typeOfValue } = tile.metric.definition.displayValueSettings;
		if (typeOfValue.enumValue === TypeOfValueEnum.percetangeOfTotal) {
			// Add a percentage sign is typeOfValue is 'percentage of total'
			tile.displayValue += '%';
		}
	}

	/**
	 * The method will format the value with thousand separators and decimal places
	 * @param {OmniTile} tile the tile object received from guiConfig
	 * @param {number} decimalPlaces the decimal places to be formated
	 * @param {boolean} isCurrency if true, will add dollar to format to currency
	 */
	private formatThousandSeparatorAndDecimal(tile: MetricTile, decimalPlaces: number, isCurrency: boolean = false): void {
		const valueParsedToFloat = parseFloat(tile.metric.result.value);
		if (valueParsedToFloat !== 0 && !valueParsedToFloat) {
			tile.displayValue = '-';
			return;
		}

		const decimalized = valueParsedToFloat.toFixed(decimalPlaces);
		if (decimalPlaces) {
			const splitByDecimal = decimalized.split('.');
			const withThousandSeparator = Number(splitByDecimal[0]).toLocaleString();
			tile.displayValue = `${withThousandSeparator}.${splitByDecimal[1]}`;
			if (isCurrency) {
				tile.displayValue = `$${tile.displayValue}`;
			}
		} else {
			tile.displayValue = Number(decimalized).toLocaleString();
		}
	}

	saveTimeframeDefinition(tile: MetricTile): Observable<MetricTile> {
		const { gqlMutationQuery, gqlMutationVariables } = this.createOrUpdateTimeframeGqlVariableAndQuery(tile);

		return this.apollo
			.mutate({
				mutation: gqlMutationQuery,
				variables: gqlMutationVariables
			})
			.pipe(
				map((result: any) => {
					// Advance Error handler
					const timeFrame = result.data.createTimeframeDefinition;
					return tile;
				}),
				catchError(err => this.guiConfigService.handlerError(err))
			);
	}

	/**
	 * The method return graphQL mutation query and variables for creating a tile
	 */
	 createOrUpdateTimeframeGqlVariableAndQuery(tile: MetricTile): any {
		const gqlMutationQuery = gql`
			mutation createOrUpdateTimeframeDefinition($tileId: String!, $timeframeDefinitionInput: TimeframeDefinitionInput) {
				createOrUpdateTimeframeDefinition(tileId: $tileId, timeframeDefinitionInput: $timeframeDefinitionInput) {
					timeframeField
				}
			}
		`;

		const gqlMutationVariables = { tileId: tile.id, timeframeDefinitionInput: tile.timeFrameFilter.getDefinitionContract() };
		return { gqlMutationQuery, gqlMutationVariables };
	}


	/**
	 * Update omni tile details to Tinkerpop via GraphQL.
	 * It uses the Apollo framework to create the mutation and hit the API server.
	 * @return {Observable<string>} the function should return an observable that returns a tile object
	 */
	saveMetricTile(configId: string, tile: MetricTile): Observable<MetricTile> {
		const { gqlMutationQuery, gqlMutationVariables } = tile.isNew ? this.getCreateTileGqlVariableAndQuery(configId, tile) : this.getUpdateTileGqlVaribleAndQuery(tile);

		return this.apollo
			.mutate({
				mutation: gqlMutationQuery,
				variables: gqlMutationVariables
			})
			.pipe(
				map((result: any) => {
					// Advance Error handler
					const newTile = result.data.createMetricTile ? result.data.createMetricTile : result.data.updateMetricTile;
					tile.id = newTile.id;
					tile.isNew = false;
					return tile;
				}),
				catchError(err => this.guiConfigService.handlerError(err))
			);
	}

	/**
	 * The method return graphQL mutation query and variables for creating a tile
	 */
	getCreateTileGqlVariableAndQuery(guiConfigId: string, tile: MetricTile): any {
		const gqlMutationQuery = gql`
			mutation createMetricTile($guiConfigId: String!, $metricTileInput: CreateMetricTileInput) {
				createMetricTile(guiConfigId: $guiConfigId, metricTileInput: $metricTileInput) {
					id
				}
			}
		`;
		const metricTileInput = this.getMetricTileInput(tile);
		const gqlMutationVariables = { guiConfigId, metricTileInput };
		return { gqlMutationQuery, gqlMutationVariables };
	}

	/**
	 * The method return graphQL mutation query and variables for updating a tile
	 */
	getUpdateTileGqlVaribleAndQuery(tile: MetricTile): any {
		const metricId = tile.metric.id;
		const gqlMutationQuery = gql`
			mutation updateMetricTile($metricTileInput: UpdateMetricTileInput) {
				updateMetricTile(metricTileInput: $metricTileInput) {
					id
				}
			}
		`;
		const metricTileInput = this.getMetricTileInput(tile);
		const gqlMutationVariables = { metricId, metricTileInput };
		return { gqlMutationQuery, gqlMutationVariables };
	}

	/**
	 * This function gets the metric tile input for save
	 * @returns {Partial<MetricTile>} - Returns a metric tile input object
	 */
	private getMetricTileInput(tile: MetricTile) {
		const { id, metricId, tilePosition, isSelected, backgroundColor, foregroundColor, isNew } = tile;
		const metricTileInput: Partial<MetricTile> = {
			tilePosition,
			metricId,
			isSelected,
			backgroundColor,
			foregroundColor
		};
		if (!isNew) {
			metricTileInput.id = id;
		}
		return metricTileInput;
	}

	/**
	 * This method will delete the selected tile when user click 'remove tile' in the gui.
	 * @param {string[] | string}  idToDelete - Can be passed in as a list of Ids or just one Id to be deleted
	 * @returns {Observable<string[]>} - Returns a promise that returns the Ids delted
	 */
	deleteMetricTile(idToDelete: string[] | string): Observable<string[]> {
		const idList = Array.isArray(idToDelete) ? idToDelete : [idToDelete];
		return this.apollo
			.mutate({
				mutation: gql`
					mutation deleteListOfMetricTile($idList: [String]) {
						deleteListOfMetricTile(idList: $idList)
					}
				`,
				variables: { idList }
			})
			.pipe(
				map(result => {
					console.log('sucess');
					return idList;
				}),
				catchError(err => this.guiConfigService.handlerError(err))
			);
	}

	/**
	 * Returns an array of the IDs of the canvases that are at risk
	 * of being removed if the current layout changes to the given number
	 * @param {number} count - A number between 0 and 12
	 * @returns {MetricTile[]} - The metricTiles at risk of being erased from data base
	 */
	getMetricTilesAtRisk(count: number, config: GuiConfig): MetricTile[] {
		const { tileList, tilesLayout } = config;
		const tilesAffected = [];
		this.tilesAtRisk = [];
		if (count < tilesLayout) {
			for (const tilePosition of Object.keys(tileList)) {
				if (parseInt(tilePosition) >= count) {
					tilesAffected.push(tileList[parseInt(tilePosition)]);
				}
			}
			for (const affectedTile of Object.values(tilesAffected)) {
				if (!affectedTile.isNew) {
					this.tilesAtRisk.push(affectedTile);
				}
			}
		}
		return this.tilesAtRisk;
	}
}
