<mat-card
	class="omni-templated-list-item-no-icon"
	[class.selected]="selected"
	(click)="!resolver.isDisabled(item) && (selected = !selected) && itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
>
	<div class="content">
		<mat-card-title class="omni-templated-list-item-no-icon-title s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
			{{ resolver.getLabel(item) }}
		</mat-card-title>
		<img
			mat-card-avatar
			[src]="resolver.getRightIconPath(item)"
			(click)="onRightIconClicked($event)"
			[class.invisible]="hovered || !resolver.showIconOnHover(item) ? false : true"
		/>
	</div>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-no-icon-divider"></mat-divider>
