import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BulkUpdateJob } from 'domain-service/jobs/bulk-update-job/bulk-update-job';
import { WorkOrderUpdateError } from 'domain-service/subscriptions/handlers/bulk-update-changed-handler';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { MenuPanelComponent } from 'app/menu-panel/menu-panel.component';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { LeftIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { AdvancedWorkOrder } from 'models/work-order';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { LeftIconTwoLinesItemResolver } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.resolver';
import { LeftIconOneLineItemResolver } from 'app/ui-components/omni-list/list-item/templates/left-icon-one-line/left-icon-one-line.resolver';
import { LeftIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-one-line/left-icon-one-line.component';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MetricRecord } from 'models/records/metric-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { CanvasMode } from 'omni-model/canvas.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
  selector: 'app-bulk-edit-errors-list',
  templateUrl: './bulk-edit-errors-list.component.html',
  styleUrls: ['./bulk-edit-errors-list.component.scss']
})
export class BulkEditErrorsListComponent extends MenuPanelBaseComponent {

  private activeJob: BulkUpdateJob;

  private isErrorsList: boolean;

  private resolvers = new Map();

  workOrderList: Array<any> = new Array<any>();

  highlightPromise: Promise<any>;

  title: string;

  private selectedFeature: any;

  @ViewChild('updateErrorsList', { static: true }) listComponent: OmniListComponent;

  constructor(
    view: ElementRef<HTMLElement>,
    private graphicsService: OmniMapGraphicsService,
    private interopService: OmniInteropService,
    private workOrderFactory: WorkOrderFactory
  ) {
    super(view);
  }

  ngOnInit(): void { }

  onPageNavigatedTo(args: NavigationArgs): void {
    this.menuPanelComponent.updateView({
      title: 'view results',
      backgroundClass: 'orange-background'
    });

    this.selectedFeature = undefined;

    this.activeJob = MenuPanelComponent.toastController.activeJob;
    if (!this.activeJob) return;

    if (this.activeJob.failedCount > 0) this.isErrorsList = true;
    else this.isErrorsList = false;

    if (this.isErrorsList) this.workOrderList = this.activeJob.workOrderKeyFailedList;
    else this.workOrderList = this.activeJob.workOrderKeyPendingList;

    this.title = this.isErrorsList ? 'failed work orders' : 'unprocessed work orders';
    this.listComponent.getResolver = item => {
      let key: string;
      if (this.isErrorsList) key = item.workOrderKey;
      else key = item;

      if (this.resolvers.has(key)) return this.resolvers.get(key);
      return this.getResolver(key);
    };
  }

  onPageReload(args: NavigationArgs): void {
    this.onPageNavigatedTo(args);
  }

  private getResolver(key: string): ListItemResolver {
    let itemResolver: ListItemResolver;
    if (this.isErrorsList) itemResolver = this.getLeftIconTwoLinesResolver();
    else itemResolver = this.getLeftIconOneLineResolver();

    this.resolvers.set(key, itemResolver);
    return itemResolver;
  }

  private getLeftIconTwoLinesResolver(): LeftIconTwoLinesItemResolver {
    const resolver = LeftIconTwoLinesComponent.createResolver();
    resolver.getAlignment = item => 'left';
    resolver.getIconPath = item => new Promise(solve => solve('assets/workorder.png'));
    resolver.getTopLabel = item => item && item.workOrderKey ? item.workOrderKey : 'work order key unavailble';
    resolver.getBottomLeftLabel = item => item && item.validationError ? item.validationError : 'validation error unavailable';
    resolver.getBottomRightLabel = item => '';
    return resolver;
  };

  private getLeftIconOneLineResolver(): LeftIconOneLineItemResolver {
    const resolver = LeftIconOneLineComponent.createResolver();
    resolver.getAlignment = item => 'center';
    resolver.getIconPath = item => 'assets/workorder.png';
    resolver.getLabel = item => item;
    resolver.showDivider = item => true;
    return resolver;
  }

  async handleClick(event: any): Promise<void> {
    if (!event) return;

    const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey();
    const workOrder = this.workOrderFactory.createWorkOrderModel(workOrderKeys[0]);
    if (!this.isErrorsList) workOrder.workOrderKey = event;
    else workOrder.workOrderKey = event.workOrderKey;

    this.selectedFeature = workOrder;

    this.hightlightAndZoom(workOrder.workOrderKey, true);

    NavigationService.navigateTo(Pages.workorderOutline, { workOrder });
  }

  handleHover(event: any) {
    this.hightlightAndZoom(this.isErrorsList ? event.workOrderKey : event);
  }

  hightlightAndZoom(workOrderKey: string, zoom = false) {
    const workOrder = this.activeJob.workOrderList.getByWorkOrderKey(workOrderKey);
    const context = new RecordContextGroup();
    context.workOrderRecord = workOrder;
    const metricRecord = new MetricRecord();
    metricRecord.contextGroup = context;
    const metricManagerResolver = this.interopService.templateManager.getTemplateResolver(context);
    metricManagerResolver.showDivider = item => true;
    metricManagerResolver.onIsDisabled = item => false;
    metricRecord.resolver = metricManagerResolver;
    this.highlightPromise = this.graphicsService.highlightFeatureByAttribute(workOrderKey, 'workOrderKey', this.config.selectedTile.id, this.config.selectedCanvas).then(graphic => {
      if (!graphic) return;
			metricRecord.feature.geometry = graphic.geometry;
			const mapComponent = this.interopService.uiManager.activeCanvasComponent.canvasMapComponent;
			const point = this.interopService.omniDomain.esriSdkService.esriMapSdk.Projection.project(graphic.geometry, this.config.selectedCanvas.mapView.spatialReference);
			metricRecord.feature.geometry = point;
      mapComponent.mapTooltip.location = point;
      if (zoom) {
        try {
          this.config.selectedCanvas.mapView.goTo({ target: metricRecord.feature.geometry, scale: 1000 }, { duration: 1000, easing: 'ease-out' });
        } catch (error) {
          console.error(error);
        }
      }
			const cloneDeep = require('clone');
			const resolver = cloneDeep(metricRecord.resolver);
			mapComponent.mapTooltip.resolver = resolver;
			mapComponent.mapTooltip.resolver.showDivider = item => false;
			mapComponent.mapTooltip.resolver.onIsDisabled = item => true;
      mapComponent.mapTooltip.open();
		});
  }

  closePopUp() {
    if (this.selectedFeature) return;
		if (this.highlightPromise) {
			this.highlightPromise.then(() => {
				if (this.config.selectedCanvas.mode !== CanvasMode.map) return;
				this.graphicsService.clearHighlightedFeature(this.config.selectedCanvas);
				if (this.interopService.uiManager.activeCanvasComponent) this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip.close();
			});
		} else {
			if (this.interopService.uiManager.activeCanvasComponent) this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip.close();
		}
	}
}
