import { ChannelTypes, ArcGISHistoryChannelAttributes, ArcGISAssetChannelAttributes, ArcGISField, Metric, DataSourceType, Channel } from 'models';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ManagerBase } from 'domain-service/manager-base';
import { GlobalConfig } from 'models/global-config.model';
import { FeatureLayer } from 'sedaru-util/esri-core/feature-layer';
import { Server } from 'sedaru-util/esri-core/server';
import { SpatialReference } from 'sedaru-util/esri-core/spatial-reference';
import { HistoryProvider } from 'providers/history.provider';
import { AssetRecord } from 'models/records/asset-record.model';
import { HistoryRecord } from 'models/records/history-record.model';
import { Feature } from 'sedaru-util/esri-core';

export class ArcGISManager extends ManagerBase {
	private _historyProviders: Map<string, HistoryProvider>;
	private get historyProviders(): Map<string, HistoryProvider> {
		return this._historyProviders ?? (this._historyProviders = new Map<string, HistoryProvider>());
	}

	private get globalConfig(): GlobalConfig {
		return this.interopService.omniDomain.userService.globalConfig;
	}

	constructor(interopService: OmniInteropService) {
		super(interopService);
	}

	initialize(): void {
		const { assetDefinitions } = this.interopService?.configurationManager?.customerCodeConfiguration;
		if (!assetDefinitions) return;

		for (const assetDefinition of assetDefinitions) {
			const { historyChannel } = assetDefinition;
			if (!historyChannel || historyChannel.channelType !== ChannelTypes.History) continue;

			const server = this.getArcGISService(historyChannel.dataSourceLegacyId);
			if (!server) continue;

			this.historyProviders.set(assetDefinition.assetType,
				new HistoryProvider(server, historyChannel, assetDefinition));
		}
	}

	async getHistoryRecords(assetRecord: AssetRecord): Promise<HistoryRecord[]> {
		if (!assetRecord) return [];
		const { assetType, assetId } = assetRecord;
		if (!assetType || !assetId) return [];

		const provider = this.historyProviders.get(assetType);
		if (!provider) return [];

		return provider.getHistoryRecordsByAssetId(assetId);
	}

	async getHistoryRecordsByWorkOrderKeys(workOrderKeys: string[], assetType: string, idOnly = false): Promise<HistoryRecord[]> {
		const provider = this.historyProviders.get(assetType);
		if (!provider) return null;

		return provider.getHistoryRecordsByWorkOrderKeys(workOrderKeys, idOnly);
	}

	public get defaultAssetDataSource(): Server {
		const validDefinitions = this.globalConfig.assetDefinitions.filter(ad => ad.assetChannel && ad.assetChannel.attributes);
		if (validDefinitions.length === 0) return undefined;
		const firstDefinition = validDefinitions[0];
		const attributes = firstDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
		return this.interopService.omniDomain.arcGISDataSourceService.services.find(s => s.id === attributes.mapServiceDataSourceLegacyId);
	}

	public get defaultAssetWkid(): number {
		const ds = this.defaultAssetDataSource;

		if (!ds || !ds.spatialReference) return SpatialReference.webMercator;

		return ds.spatialReference.wkid;
	}

	public get defaultAssetWkText(): string {
		const ds = this.defaultAssetDataSource;
		if (!ds || !ds.spatialReference) return '';
		return ds.spatialReference.wkText;
	}

	getAssetLayer(assetType: string): FeatureLayer {
		if (!this.globalConfig) {
			// TODO Analytics: requesting history layer for asset type, not found
			return undefined;
		}

		const assetDefinition = this.globalConfig.getAssetDefinition(assetType);
		if (!assetDefinition || !assetDefinition.assetChannel) {
			// TODO Analytics: requesting asset layer for asset type, not found
			return undefined;
		}

		const attributes = assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;

		if (!attributes) {
			// TODO Analytics: channel is not an arcgis asset channel

			return undefined;
		}

		const arcGISService = this.getArcGISService(assetDefinition.assetChannel.dataSourceLegacyId);
		if (!arcGISService) {
			return undefined;
		}

		const arcGISAssetLayer = arcGISService.layers.find(l => l.id === attributes.featureServiceLayerIndex);
		return arcGISAssetLayer;
	}

	getMapAssetLayer(assetType: string): FeatureLayer {
		if (!this.globalConfig) {
			// TODO Analytics: requesting history layer for asset type, not found
			return undefined;
		}

		const assetDefinition = this.globalConfig.getAssetDefinition(assetType);
		if (!assetDefinition || !assetDefinition.historyChannel) {
			// TODO Analytics: requesting history layer for asset type, not found
			return undefined;
		}

		const attributes = assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;

		if (!attributes) {
			// TODO Analytics: channel is not an arcgis history channel

			return undefined;
		}

		const arcGISService = this.getArcGISService(attributes.mapServiceDataSourceLegacyId);
		if (!arcGISService) {
			return undefined;
		}

		const arcGISAssetLayer = arcGISService.layers.find(l => l.id === attributes.mapServiceLayerIndex);
		return arcGISAssetLayer;
	}

	getHistoryLayer(assetType: string): FeatureLayer {
		if (!this.globalConfig) {
			// TODO Analytics: requesting history layer for asset type, not found
			return undefined;
		}

		const assetDefinition = this.globalConfig.getAssetDefinition(assetType);
		if (!assetDefinition || !assetDefinition.historyChannel) {
			// TODO Analytics: requesting history layer for asset type, not found
			return undefined;
		}

		const attributes = assetDefinition.historyChannel.attributes as ArcGISHistoryChannelAttributes;

		if (!attributes) {
			// TODO Analytics: channel is not an arcgis history channel

			return undefined;
		}

		const arcGISService = this.getArcGISService(assetDefinition.historyChannel.dataSourceLegacyId);
		if (!arcGISService) {
			return undefined;
		}

		const arcGISHistoryLayer = arcGISService.layers.find(l => l.id === attributes.layerIndex);
		return arcGISHistoryLayer;
	}

	getArcGISService(dataSourceId: number) {
		const arcGISService = this.interopService.omniDomain.arcGISDataSourceService.services.find(s => s.id === dataSourceId);

		if (!arcGISService) {
			return undefined;
		}

		return arcGISService;
	}

	getArcGisFields(metric: Metric, histoyFieldOnly?: boolean): ArcGISField[] {
		if (metric.definition.source.type === ChannelTypes.Asset) {
			const assetChannelAttributes = metric.definition.assetChannel.attributes as ArcGISAssetChannelAttributes;
			const assetLayer = this.interopService.arcGISManager.getMapAssetLayer(assetChannelAttributes.assetType);
			if (!assetLayer) return null;
			return assetLayer.getOmniFields(assetChannelAttributes.assetType);
		}
		if (metric.definition.source.type === ChannelTypes.History) {
			const historyChannelAttributes = metric.definition.historyChannel.attributes as ArcGISHistoryChannelAttributes;
			const historyLayer = this.interopService.arcGISManager.getHistoryLayer(historyChannelAttributes.assetType);
			if (histoyFieldOnly || !metric.definition.assetChannel) {
				return historyLayer.getOmniFields(`${historyChannelAttributes.assetType}History`);
			}
			const assetChannelAttributes = metric.definition.assetChannel.attributes as ArcGISAssetChannelAttributes;
			const assetLayer = this.interopService.arcGISManager.getAssetLayer(assetChannelAttributes.assetType);
			return historyLayer.getOmniFields(`${historyChannelAttributes.assetType}History`).concat(assetLayer.getOmniFields(assetChannelAttributes.assetType));
		}
		if (metric.definition.source.type === ChannelTypes.WorkOrder) {
			return this.interopService.omniDomain.workOrderFactory.getArcGISFields();
		}

		return [];
	}

	async getAssetLayerFeatures(assetType: string, assetIds: string[]): Promise<Feature[]> {
		const attributes = this.globalConfig?.assetDefinitions?.getByAssetType(assetType)?.assetChannel?.attributes as ArcGISAssetChannelAttributes;
		if (!attributes) return [];

		const assetLayer = this.getAssetLayer(assetType);
		if (!assetLayer) return [];

		return await assetLayer.query(`${attributes.uniqueFieldName} IN (${"'" + assetIds.join("','") + "'"})`);
	}
}
