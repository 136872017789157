import { EmployeeCost } from './employee-cost.model';
import { EmployeeCostContract } from '../../contracts/work-order';

export class EmployeeCosts extends Array<EmployeeCost> {
	static fromContracts(employeeCostContracts: EmployeeCostContract[]) {
		const employeeCosts = new EmployeeCosts();
		for (const employeeCostContract of employeeCostContracts) {
			const employeeCost = EmployeeCost.fromContract(employeeCostContract);
			employeeCosts.push(employeeCost);
		}
		return employeeCosts;
	}

	static fromModels(employeeCostModels: EmployeeCost[]): EmployeeCosts {
		const employeeCosts = new EmployeeCosts();
		for (const employeeCost of employeeCostModels) employeeCosts.push(employeeCost);
		return employeeCosts;
	}

	getContracts = () => {
		const employeeCostContracts = new Array<EmployeeCostContract>();
		for (const employee of this) {
			const employeeCostContract = employee.getContract();
			employeeCostContracts.push(employeeCostContract);
		}
		return employeeCostContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
