/**
 * The OMNI snack bar messages types.
 * ERROR: type error with background color red of snack bar
 * WARNING: type warning with background color orange of snack bar
 * SUCCESS: type success with background color green of snack bar
 * INFO: type info with background color black of snack bar
 */
export enum SnackbarMessageTypes {
	ERROR = 'error',
	WARNING = 'warning',
	SUCCESS = 'success',
	INFO = 'info'
}
