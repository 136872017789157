import { EsriSdkService, EsriMapSdk } from '../canvas-container/canvas-map/esri-sdk.service';
import { UserService } from '../user/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { DataSourceType } from 'models';
import { Injectable } from '@angular/core';
import * as Point from 'esri/geometry/Point';
import { Extent } from 'sedaru-util/esri-core/extent';
import { SpatialReference } from 'esri/geometry';
import { Server } from 'sedaru-util/esri-core';

@Injectable({ providedIn: 'root' })
export class BingSearchService {
	EsriMapSdk: EsriMapSdk;
	fullExtent: Extent;
	isInitialized: boolean;
	customerCode: string;
	userName: string;
	sedaruToken: string;
	searchServiceUrl: string;
	searchAgent: string;
	userLocation: string;

	private _mapConfigExtent;
	private get mapConfigExtent(): any {
		if (!this._mapConfigExtent) {
			this._mapConfigExtent = new this.EsriMapSdk.MapGeometryExtent({
				xmin: this.fullExtent?.xmin,
				ymin: this.fullExtent?.ymin,
				xmax: this.fullExtent?.xmax,
				ymax: this.fullExtent?.ymax,
				spatialReference: this.spatialReference
			});
		}

		return this._mapConfigExtent;
	}

	private _spatialReference;
	private get spatialReference(): SpatialReference {
		if (!this._spatialReference) {
			this._spatialReference = new this.EsriMapSdk.MapGeometrySpatialReference({
				wkid: this.fullExtent.spatialReference.wkid,
				wkt: this.fullExtent.spatialReference.wkText
			});
		}

		return this._spatialReference;
	}

	constructor(private http: HttpClient, private esriSdkService: EsriSdkService, private userService: UserService, private interop: OmniInteropService) {}

	convertToLatLong(x: number, y: number): Point {
		return this.EsriMapSdk.Projection.project(
			new this.EsriMapSdk.Point({
				x: x,
				y: y,
				spatialReference: this.spatialReference
			}),
			this.EsriMapSdk.MapGeometrySpatialReference.WGS84
		) as Point;
	}

	convertFromLatLong(lat: number, long: number): any {
		const xy = this.EsriMapSdk.webMercatorUtils.lngLatToXY(long, lat);
		return { x: xy[0], y: xy[1] };
	}

	createSearchResult(result: any) {
		const places = [];

		if (!result || result.length == 0) return places;

		try {
			for (const addressResult of result) {
				const xy = this.convertFromLatLong(addressResult.Lat, addressResult.Lon);
				const geographicPoint = this.EsriMapSdk.webMercatorUtils.webMercatorToGeographic(
					new this.EsriMapSdk.Point({
						x: xy.x,
						y: xy.y
					})
				) as Point;

				const projectedPoint = this.EsriMapSdk.Projection.project(geographicPoint, this.spatialReference);

				if (!this.mapConfigExtent.contains(projectedPoint)) continue;
				const address = addressResult.Address.FormattedAddress;
				const wholeAddress = addressResult.Address;
				places.push({ value: address, result: { geometry: geographicPoint }, address: wholeAddress });
			}
		} catch (exception) {
			console.log('error formatting address search result ' + exception);
		} finally {
			return places;
		}
	}

	search(searchQuery: string): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			if (!this.isInitialized) {
				reject('bing search service not initialized');
				return null;
			}

			const request = {
				Agents: this.searchAgent,
				SearchString: encodeURIComponent(searchQuery),
				CustomerCode: this.customerCode,
				UserName: this.userName,
				IsUserLocation: true,
				UserLocation: this.userLocation
			};

			const postData = {
				Data: JSON.stringify(request),
				Token: this.sedaruToken
			};

			const searchResponse = await this.http.post(this.searchServiceUrl, postData).toPromise();
			const result = JSON.parse(searchResponse['Result']);
			const searchResult = this.createSearchResult(result);
			resolve(searchResult);
			return searchResult;
		});
	}

	initialize() {
		if (this.isInitialized) return;
		let hasError = true;

		try {
			this.userName = this.userService?.currentUser?.userName;
			this.customerCode = this.userService?.getCurrentCustomerCode();
			this.searchServiceUrl = environment?.infraApiUrl + 'SearchService.svc/SearchAdvanced';
			this.sedaruToken = this.userService?.getToken();
			this.EsriMapSdk = this.esriSdkService?.esriMapSdk;
			this.searchAgent = 'bing';

			const dataSources = this.interop?.configurationManager?.customerCodeConfiguration?.dataSources?.filter(ds => ds.type === DataSourceType.MapService);

			let foundService: Server;
			for (const source of dataSources) {
				if (foundService) break;

				const service = this.interop?.arcGISManager?.getArcGISService(source.legacyId);
				if (!service || !service.fullExtent) continue;
				foundService = service;
			}

			if (!foundService) return;

			this.fullExtent = foundService.fullExtent;

			const minPoint = this.convertToLatLong(this.mapConfigExtent.xmin, this.mapConfigExtent.ymin);
			const maxPoint = this.convertToLatLong(this.mapConfigExtent.xmax, this.mapConfigExtent.ymax);
			const extent = new this.EsriMapSdk.MapGeometryExtent({
				xmin: minPoint.x,
				ymin: minPoint.y,
				xmax: maxPoint.x,
				ymax: maxPoint.y,
				spatialReference: this.EsriMapSdk.MapGeometrySpatialReference.WGS84
			});

			this.userLocation = `${extent.center.y},${extent.center.x}`;
			hasError = false;
		} catch (exception) {
			hasError = true;
		} finally {
			this.isInitialized = !hasError;
		}
	}
}
