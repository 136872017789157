import { AssetSpec } from './asset-spec.model';
import { AssetSpecContract } from 'contracts/work-order';
import { Asset } from './asset.model';

export class AssetSpecs extends Array<AssetSpec> {
    static fromContracts(assetSpecContracts: AssetSpecContract[]): AssetSpecs {
        const assetSpecs = new AssetSpecs();
        for (const specContract of assetSpecContracts) assetSpecs.push(AssetSpec.fromContract(specContract));
        return assetSpecs;
    }

    getContracts = (): Array<AssetSpecContract> =>  {
        const assetSpecContracts = new Array<AssetSpecContract>();
        for (const assetSpec of this) assetSpecContracts.push(assetSpec.getContract());

        return assetSpecContracts;
    }

    getRequiredFields = (): AssetSpecs => AssetSpecs.fromContracts(this.getContracts().filter(field => field.IsRequired));

    isDirty = (): boolean => !!this.filter(field => field.isDirty).length;

    clearDirty = (): void => this.forEach(field => (field.isDirty ? field.clearDirty() : null));

    undoAll = (): void => this.forEach(field => (field.isDirty ? field.clearDirty() : null));

    getFieldsToUpdate = (): AssetSpecContract[] => this.filter(field => field.isDirty).map(field => field.getContract());
}
