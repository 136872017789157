import { TileType } from '../omni-model/tile-type.enum';
import { Metric } from './metric.model';
import { TimeframeFilterContract } from './time-frame/timeframe-filter-contract';
import { TimeframeFilter } from './time-frame/timeframe-filter.model';
import { Timeframe } from './time-frame/timeframe.model';
import { TimeframeDefinition } from './time-frame/timeframe-definition.model';

/**
 * The model of an OMNI metric.
 */
export class MetricTile {
	/**
	 * Unique uuid id for a tile
	 */
	public id: string;
	/**
	 * Formated metric value to be displayed on the tile.
	 */
	private _displayValue: string;
	public get displayValue(): string {
		if (!this.metric.result.value || this.metric.result.value !== this._displayValue) return this._displayValue;

		return this.metric.result.value;
	}
	public set displayValue(value: string) {
		this._displayValue = value;
	}
	/**
	 * Holds the position of the tile within a tab. The number could range from 0 to 11.
	 */
	public tilePosition: number;

	/**
	 * Tile type could be Metric or bot. It could even be set to have no type.
	 */
	public type?: TileType;
	/**
	 * Check if tile value is checked or not
	 */
	public isTileValueSet?: boolean;
	/**
	 * Holds the metrics's Id thats assigend to the tile
	 */
	public metricId?: string;
	/**
	 * Holds the metric that has been added to the tile
	 */
	public metric?: Metric;
	/**
	 * Holds the background color of tile
	 */
	private _backgroundColor: string;
	get backgroundColor(): string {
		if (!this._backgroundColor) {
			if (!this.metric || !this.metric.definition || !this.metric.definition.backgroundColor) return '';
			return this.metric.definition.backgroundColor.toString();
		}
		return this._backgroundColor;
	}

	set backgroundColor(color: string) {
		this._backgroundColor = color;
	}

	/**
	 * Holds the foreground text color of tile
	 */
	public foregroundColor?: string;
	/**
	 * Indicates if this tile is selected
	 */
	public isSelected?: boolean;
	/**
	 * Indicate if this tile is a new tile that has not been saved into the data base,
	 * or is it a tile that is already in the data base.
	 */
	public isNew?: boolean;

	public isTrendable?: boolean;

	public isDoubleClick?: boolean;

	/** this timeframe filter is the one saved to the DB */
	private _timeFrameFilter: TimeframeFilter;
	public get timeFrameFilter(): TimeframeFilter {
		if (!this._timeFrameFilter) return;
		this._timeFrameFilter.timeframeField = this.metric?.definition.timeFrameFilter?.timeframeField;
		return this._timeFrameFilter;
	}
	public set timeFrameFilter(value: TimeframeFilter) {
		this._timeFrameFilter = value;
	}

	private _metricsTimeframeFilter: TimeframeFilter;
	get metricsTimeframeFilter() {
		if (!this._metricsTimeframeFilter || !this._metricsTimeframeFilter.isEqualsTo(this.metric?.definition?.timeFrameFilter)) {
			this._metricsTimeframeFilter = TimeframeFilter.fromContract(this.metric?.definition?.timeFrameFilter?.getContract());
		}

		return this._metricsTimeframeFilter;
	}

	get uiTimeframeFilter() {
		if (!this.timeFrameFilter || !this.timeFrameFilter.isValid()) return this.metricsTimeframeFilter;
		return this.timeFrameFilter;
	}

	/**
	 * The constructor initilze deafult values to this class
	 */
	constructor() {
		this.displayValue = '';
		this.isTileValueSet = false;
		this._backgroundColor = null;
		this.foregroundColor = '#fff';
		this.type = TileType.Metric;
		this.isNew = true;
		this.isTrendable = true;
		this._timeFrameFilter = null;
	}

	initialize?(tileProperties: MetricTile): void {
		this.clear();
		this.id = tileProperties.id;
		this.tilePosition = tileProperties.tilePosition;
		this.isTileValueSet = true;
		this.metricId = tileProperties.metricId;
		this.metric = new Metric();
		this.displayValue = tileProperties.displayValue;
		this.isNew = false;
		this._backgroundColor = tileProperties.backgroundColor;
		this.foregroundColor = tileProperties.foregroundColor;
		const contractObject: any = tileProperties.timeFrameFilter;
		this.initializeTimeFrame(contractObject);
	}

	clear?(): void {
		delete this.id;
		// tile position should stay the same
		this.isTileValueSet = false;
		this._backgroundColor = null;
		this.foregroundColor = '#fff';
		this.type = TileType.Metric;
		this.isNew = true;
		this.isTrendable = true;
		delete this.metricId;
		delete this.metric;
		this.displayValue = '';
		this._timeFrameFilter = null;
	}

	initializeTimeFrame(timeframeFilterContract: TimeframeFilterContract) {
		if (!timeframeFilterContract || !timeframeFilterContract.timeFrameDefinition) return;
		const contractTimeframe = TimeframeDefinition.fromContract(timeframeFilterContract.timeFrameDefinition);
		if (contractTimeframe.isEqualsTo(this.metric.definition.timeFrameFilter.timeFrameDefinition)) return;
		this._timeFrameFilter = TimeframeFilter.fromContract(timeframeFilterContract);
	}
}
