import { OmniInteropService } from '../omni-interop.service';
import { OmniBootstrapService } from '../omni-bootstrap.service';
import { InvokableEvent } from 'sedaru-util';
import { JobManager } from './job-manager';

export enum JobState {
	New,
	Running,
	Cancelled,
	Completed,
}


export abstract class JobBase {
	private _resolve: any;
	private _onCompleted: InvokableEvent;
	get onCompleted(): InvokableEvent {
		if (!this._onCompleted) this._onCompleted = new InvokableEvent();

		return this._onCompleted;
	}

	jobId: string;
	public get name(): string {
		return this.onGetJobName();
	}

	private _state: JobState = JobState.New;
	public get state(): JobState {
		return this._state;
	}
	private _onProgress: InvokableEvent;
	private _onCancelled: InvokableEvent;

	public jobManager: JobManager;

	constructor() {
	}

	dispose() {
		if (!this.jobManager) return;

		this.jobManager.removeJobById(this.jobId);
	}

	run(): Promise<JobState> {
		return new Promise<JobState>((resolve, reject) => {
			this._resolve = resolve;
			this._state = JobState.Running;
			this.onRun();
		});
	}

	cancel() {
		const deferrer = () => {
			this.markComplete(JobState.Cancelled);
		};
		this.onCancel(deferrer);
	}

	protected abstract onGetJobName(): string;
	protected abstract onRun();

	/// The deferrer must be called after the job has successfully cancelled.
	protected abstract onCancel(deferrer: () => void);
	protected abstract onDispose();

	protected markComplete(state: JobState) {

		if (state !== JobState.Cancelled && state !== JobState.Completed) {
			state = JobState.Completed;
		}

		this._state = state;

		this.onDispose();
		if (!this._resolve) return;

		this._resolve(state);
	}


}
