import { Input } from './input.model';
import { CustomFieldWrapper } from './work-order';

export class CustomFieldInput extends Input {
    constructor(customField: CustomFieldWrapper, customFieldTemplate?: CustomFieldWrapper) {
        super(customField?.customFieldValue);
        this.customField = customField;
        this.template = customFieldTemplate;
    }

    customField: CustomFieldWrapper;

    template: CustomFieldWrapper;

    get header(): string {
        return this.getHeader();
    }

    get inputType(): string {
        return this.getInputType();
    }

    get value(): any {
        return this.customField?.customFieldValue;
    }
    set value(value: any) {
        if (!this.customField) return;
        this.customField.customFieldValue = value;
    }

    get isReadOnly(): boolean {
        let type = this.customField?.customFieldType;
        if (this.template) type = this.template.customFieldType;
        return type?.toLowerCase() === 'readonly';
    }

    get isRequired(): boolean {
        if (this.template) return this.template.isRequired;
        return this.customField?.isRequired;
    }

    get isDisabled(): boolean {
        return this.getDisabled();
    }

    get showButton(): boolean {
        return this.getShowButton();
    }

    canBePicklist(type: string): boolean {
        if (!type) return false;
        if (type.toLowerCase() === 'varchar') return true;
        if (type.toLowerCase() === 'boolean') return true;
    }

    private getHeader(): string {
        if (!this.customField) return '';
        const header = this.template?.customFieldName ?? this.customField.customFieldName;
		return this.template?.isRequired ? `*${header}` : header;
    }

    private getInputType(): string {
        if (!this.customField || !this.customField.customFieldType) return 'text';
        if (this.customField.customFieldType === 'NUMERIC') return 'number';
		if (this.customField.customFieldType && this.customField.customFieldType.toLowerCase().includes('date')) return 'date';
		return 'text';
    }

    private getShowButton(): boolean {
        if (!this.template) return false;
		if (this.canBePicklist(this.template.customFieldType) && this.template.codeType) return true;
		if (this.template.customFieldType.toLowerCase().includes('date')) return false;
		return false;
    }

    private getDisabled(): boolean {
        if (!this.template) return false;
		return this.isReadOnly || this.showButton;
    }
}
