import { AssetLocationsWithAssets } from './asset-locations-with-assets.model';
import { WorkAsset } from './work-asset.model';
import { WorkAssetContract } from '../../contracts/work-order';

export class WorkAssets extends Array<WorkAsset> {
	static fromContracts(workAssetContracts: WorkAssetContract[], assetsByLayers: any, assetLocationsWithAssets: AssetLocationsWithAssets) {
		const workAssets = new WorkAssets();
		for (const workAssetContract of workAssetContracts) {
			const workAsset = WorkAsset.fromContract(workAssetContract, assetsByLayers, assetLocationsWithAssets);
			workAssets.push(workAsset);
		}
		return workAssets;
	}

	static fromModels(workAssetModels: WorkAsset[]): WorkAssets {
		const workAssets = new WorkAssets();
		for (const workAsset of workAssetModels) workAssets.push(workAsset);
		return workAssets;
	}

	getContracts = () => {
		const workAssetContracts = new Array<WorkAssetContract>();
		for (const workAsset of this) {
			const workAssetContract = workAsset.getContract();
			workAssetContracts.push(workAssetContract);
		}
		return workAssetContracts;
	};

	getNewAssets = () => {
		const newWorkAssets = new WorkAssets();
		for (const workAsset of this) {
			if (workAsset.isNew()) newWorkAssets.push(workAsset);
		}
		return newWorkAssets;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = () => this.forEach(workAsset => (workAsset.isDirty ? workAsset.clearDirty() : null));
}
