import { VendorContract } from '../../contracts/work-order';
import { Vendor } from './vendor.model';

export class Vendors extends Array<Vendor> {
	static fromContracts(vendorContracts: VendorContract[]) {
		const vendors = new Vendors();
		if (!vendorContracts) return vendors;

		for (const vendorContract of vendorContracts) {
			const vendor = Vendor.fromContract(vendorContract);
			vendors.push(vendor);
		}
		return vendors;
	}

	getByVendorId = (vendorId: string) => this.find(vendor => vendor.vendorId === vendorId);
}
