export enum FormControlInputType {
	None,
	List,
	Picture,
	Keypad,
	DatePicker,
	TimePicker,
	DateTimePicker,
	Text,
	FormValueProvider
}
