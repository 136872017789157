import { VendorCostContract } from './../../contracts/work-order/vendor-cost-contract';
import { VendorCost } from './vendor-cost.model';
export class VendorCosts extends Array<VendorCost> {
	static fromContracts(vendorCostContracts: VendorCostContract[]) {
		const vendorCosts = new VendorCosts();
		for (const vendorCostContract of vendorCostContracts) {
			const vendorCost = VendorCost.fromContract(vendorCostContract);
			vendorCosts.push(vendorCost);
		}
		return vendorCosts;
	}

	static fromModels(vendorCostModels: VendorCost[]): VendorCosts {
		const vendorCosts = new VendorCosts();
		for (const vendorCost of vendorCostModels) vendorCosts.push(vendorCost);
		return vendorCosts;
	}

	getContracts = (): VendorCostContract[] => this.map(vc => vc.getContract());
}
