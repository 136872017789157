// import { DailyPatternDefinition, DailyPatternDefinitionType, MonthlyPatternDefinition, MonthlyPatternDefinitionType, MonthlyVariableDayDefinition, MonthlyVariableDayDefinitionType, RecurrenceDefinition, RecurrenceDefinitionEndType, RecurrencePatternType, WeeklyPatternDefinition, YearlyPatternDefinition } from "./recurrence-definition";
import * as Sedaru from '../../sedaru-util';
import { DateUtil } from '../../sedaru-util';
import { DailyPatternType } from './daily-pattern-type.enum';
import { EndBoundaryType } from './end-boundary-type.enum';
import { MonthlyPatternType } from './monthly-pattern-type.enum';
import { MonthlyPattern } from './monthly-pattern.model';
import { Pattern } from './pattern.model';
import { RecurrenceDefinition } from './recurrence-definition.model';
import { RecurrencePatternType } from './recurrence-pattern-type.enum';
import { VariableDayType } from './variable-day-type.enum';
import { WeeklyPattern } from './weekly-pattern.model';
import { YearlyPattern } from './yearly-pattern.model';

export class Recurrence {
    get definition(): RecurrenceDefinition {
        return this._definition;
    }
    constructor(private _definition: RecurrenceDefinition) {
    }


    public conformsToDate(fromDate: Date, toDate: Date, targetDate: Date): boolean {
        if (!fromDate) {
            fromDate = this.definition.startDate;
        }

        if (!toDate) {
            toDate = this.definition.endBoundary.endDate;
        }

        if (!toDate) {
            toDate = targetDate;
        }

        if (fromDate) {
            if (fromDate < this.definition.startDate) {
                fromDate = Sedaru.DateUtil.getDateComponent(this.definition.startDate);
            } else {
                fromDate = Sedaru.DateUtil.getDateComponent(fromDate);
            }
        }

        if (toDate) {
            if (toDate > this.definition.endBoundary.endDate) {
                toDate = Sedaru.DateUtil.getDateComponent(this.definition.endBoundary.endDate);
            } else {
                toDate = Sedaru.DateUtil.getDateComponent(toDate);
            }
        }

        if (this.definition.type === RecurrencePatternType.Daily) {
            return this.conformsToDateDaily(this.definition.pattern.dailyPattern, this.definition.pattern.interval, targetDate);
        } else if (this.definition.type === RecurrencePatternType.Weekly) {
            return this.conformsToDateWeekly(this.definition.pattern.weeklyPattern, this.definition.pattern.interval, targetDate);
        } else if (this.definition.type === RecurrencePatternType.Monthly) {
            return this.conformsToDateMonthly(this.definition.pattern.monthlyPattern, this.definition.pattern.interval, targetDate);
        } else if (this.definition.type === RecurrencePatternType.Yearly) {
            return this.conformsToDateYearly(this.definition.pattern.yearlyPattern, this.definition.pattern.interval, targetDate);
        }

        return false;
    }

    public dateConformsToPattern(targetDate: Date): boolean {
        return this.conformsToDate(this.definition.startDate, undefined, targetDate);
    }

    public getNumberOfInstances(fromDate?: Date, toDate?: Date): number {
        if (!fromDate) {
            fromDate = new Date(this.definition.startDate);
        }
        if (!toDate && this.definition.endBoundary.endType === EndBoundaryType.Infinite) {
            return Infinity;
        }

        if (!toDate) {
            toDate = new Date(this.definition.endBoundary.endDate);
        }

        if (this.definition.type === RecurrencePatternType.Daily) {
            return this.getDailyInstanceCount(this.definition.pattern.dailyPattern, this.definition.pattern.interval, fromDate, toDate);
        } else if (this.definition.type === RecurrencePatternType.Weekly) {
            return this.getWeeklyInstanceCount(this.definition.pattern.weeklyPattern, this.definition.pattern.interval, fromDate, toDate);
        } else if (this.definition.type === RecurrencePatternType.Monthly) {
            return this.getMonthlyInstanceCount(this.definition.pattern.monthlyPattern, this.definition.pattern.interval, fromDate, toDate);
        } else if (this.definition.type === RecurrencePatternType.Yearly) {
            return this.getYearlyInstanceCount(this.definition.pattern.yearlyPattern, this.definition.pattern.interval, fromDate, toDate);
        }

        return 0;
    }


    getAllInstances(startDate?: Date, endDate?: Date): Array<Date> {
        if (!startDate) {
            startDate = this.definition.startDate;
        }

        if (!endDate) {
            endDate = this.definition.endBoundary.endDate;
        }

        if (startDate) {
            if (startDate < this.definition.startDate) {
                startDate = Sedaru.DateUtil.getDateComponent(this.definition.startDate);
            } else {
                startDate = Sedaru.DateUtil.getDateComponent(startDate);
            }
        }

        if (endDate) {
            if (endDate > this.definition.endBoundary.endDate) {
                endDate = Sedaru.DateUtil.getDateComponent(this.definition.endBoundary.endDate);
            } else {
                endDate = Sedaru.DateUtil.getDateComponent(endDate);
            }
        }

        if (!endDate && this.definition.endBoundary.endType === EndBoundaryType.Infinite) return undefined;

        let results = new Array<Date>();
        if (this.definition.type === RecurrencePatternType.Daily) {
            results = this.handleDaily(startDate, endDate);
        } else if (this.definition.type === RecurrencePatternType.Weekly) {
            results = this.handleWeekly(startDate, endDate);
        } else if (this.definition.type === RecurrencePatternType.Monthly) {
            results = this.handleMonthly(this.definition.pattern.monthlyPattern, startDate, endDate, this.definition.pattern.interval);
        } else if (this.definition.type === RecurrencePatternType.Yearly) {
            results = this.handleYearly(startDate, endDate);
        }

        return results;
    }

    // Daily Pattern Functions
    private conformsToDateDaily(pattern: DailyPatternType, interval: number, targetDate: Date): boolean {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = targetDate;

        if (startDate > targetDate) return false;

        if (endDate < targetDate) return false;

        // return this.getDailyInstanceCount(pattern, interval, targetDate, targetDate) === 1;
        const dayDifference = Sedaru.DateUtil.getDayDifference(startDate, targetDate);

        if (pattern === DailyPatternType.Interval) {
            if (this.definition.endBoundary.endType === EndBoundaryType.Occurrences) {
                const def = new Pattern();
                const def2 = new RecurrenceDefinition();
                def2.startDate = startDate;
                def.interval = this.definition.pattern.interval;
                def2.type = RecurrencePatternType.Daily;
                def2.endBoundary.endDate = targetDate;
                def2.endBoundary.endType = EndBoundaryType.Finite;
                def2.pattern = def;
                const d = new Recurrence(def2);
                if (d.getNumberOfInstances() > this.definition.endBoundary.occurrences) return false;
            }
            const remainder = dayDifference % interval;
            return remainder === 0;
        } else if (pattern === DailyPatternType.Weekdays) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [1, 2, 3, 4, 5];
            return this.conformsToDateWeekly(weeklyPattern, 1, targetDate);
        } else if (pattern === DailyPatternType.Weekends) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [0, 6];
            return this.conformsToDateWeekly(weeklyPattern, 1, targetDate);
        }

        return false;
    }
    private getDailyInstanceCount(pattern: DailyPatternType, interval: number, fromDate: Date, toDate: Date): number {

        if (this.definition.endBoundary.endType === EndBoundaryType.Occurrences) {
            return this.getDailyInstanceCountByOccurrence(pattern, interval, this.definition.startDate, fromDate, this.definition.endBoundary.occurrences, toDate);
        }

        if (!Sedaru.DateUtil.isValidDate(toDate)) {
            return NaN;
        }

        return this.getDailyInstanceCountByFinite(pattern, interval, this.definition.startDate, fromDate, toDate);
    }
    private getDailyInstanceCountByOccurrence(pattern: DailyPatternType, interval: number, startDate: Date, fromDate: Date, maxOccurrences: number, toDate?: Date): number {
        if (pattern === DailyPatternType.Weekdays) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [1, 2, 3, 4, 5];

            return this.getWeeklyInstanceCountByOccurrence(weeklyPattern, 1, this.definition.startDate, fromDate, toDate, maxOccurrences);
        } else if (pattern === DailyPatternType.Weekends) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [0, 6];
            return this.getWeeklyInstanceCountByOccurrence(weeklyPattern, 1, this.definition.startDate, fromDate, toDate, maxOccurrences);
        }

        if (startDate.getTime() > fromDate.getTime()) {
            fromDate = new Date(startDate);
        }

        const fromDayDifference = Sedaru.DateUtil.getDayDifference(startDate, fromDate);
        const instancesToIgnore = Math.floor(fromDayDifference / interval);

        if (instancesToIgnore >= maxOccurrences) {
            return 0;
        }

        if (Sedaru.DateUtil.isValidDate(toDate)) {
            if (toDate.getTime() < startDate.getTime()) {
                return 0;
            }

            const toDayDifference = Sedaru.DateUtil.getDayDifference(startDate, toDate);
            const totalInstances = Math.floor(toDayDifference / interval);

            let result = totalInstances - instancesToIgnore;

            if (fromDayDifference % interval === 0) {
                result += 1;
            } else if (toDayDifference % interval === 0) {
                result += 1;
            }

            if (maxOccurrences <= result) {
                return maxOccurrences;
            }
            return result;
        }

        return maxOccurrences - instancesToIgnore;
    }

    private getDailyInstanceCountByFinite(pattern: DailyPatternType, interval: number, startDate: Date, fromDate: Date, toDate: Date): number {
        if (pattern === DailyPatternType.Weekdays) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [1, 2, 3, 4, 5];
            return this.getWeeklyInstanceCount(weeklyPattern, 1, fromDate, toDate);
        }
        if (pattern === DailyPatternType.Weekends) {
            const weeklyPattern = new WeeklyPattern();
            weeklyPattern.daysOfWeek = [0, 6];
            return this.getWeeklyInstanceCount(weeklyPattern, 1, fromDate, toDate);
        }

        if (startDate.getTime() > fromDate.getTime()) {
            fromDate = new Date(startDate);
        }

        const fromDayDifference = Sedaru.DateUtil.getDayDifference(startDate, fromDate);
        const instancesToIgnore = Math.floor(fromDayDifference / interval);
        const toDayDifference = Sedaru.DateUtil.getDayDifference(startDate, toDate);
        const totalInstances = Math.floor(toDayDifference / interval);
        let result = totalInstances - instancesToIgnore;

        if (fromDayDifference % interval === 0) {
            result += 1;
        } else if (fromDayDifference % interval !== 0 && toDayDifference % interval === 0) {
            result += 1;
        }

        return result;
    }



    // Weekly Pattern Functions
    private conformsToDateWeekly(pattern: WeeklyPattern, interval: number, targetDate: Date): boolean {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = targetDate;

        if (!pattern.daysOfWeek.includes(targetDate.getDay())) return false;

        let daysApart = 0;
        if (startDate.getDay() <= targetDate.getDay()) {
            daysApart = targetDate.getDay() - startDate.getDay();
            startDate.setDate(startDate.getDate() + daysApart);
        } else {
            daysApart = startDate.getDay() - targetDate.getDay();
            startDate.setDate(startDate.getDate() + 7 - daysApart);
        }

        if (startDate > endDate) return false;

        const dayDifference = Sedaru.DateUtil.getDayDifference(startDate, targetDate);
        const remainder = dayDifference % (interval * 7);
        return remainder === 0;
    }
    private getWeeklyInstanceCount(pattern: WeeklyPattern, interval: number, fromDate: Date, toDate: Date): number {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = toDate;

        if (startDate > toDate) return 0;

        if (endDate < fromDate) return 0;

        if (toDate > endDate) toDate = endDate;

        if (fromDate < startDate) fromDate = startDate;

        let totalCount = 0;
        for (const dayNumber of pattern.daysOfWeek) {
            const currentFrom = new Date(fromDate);

            let needToAdd = true;
            while (currentFrom.getDay() !== dayNumber) {
                if (currentFrom.getDay() === 6) needToAdd = false;
                currentFrom.setDate(currentFrom.getDate() + 1);
            }

            if (currentFrom.getTime() > toDate.getTime()) continue;

            const recurrence = new RecurrenceDefinition();
            recurrence.pattern = new Pattern();
            recurrence.type = RecurrencePatternType.Daily;
            recurrence.startDate = currentFrom;
            recurrence.endBoundary.endDate = toDate;
            recurrence.pattern.interval = interval * 7;
            recurrence.pattern.dailyPattern = DailyPatternType.Interval;
            totalCount += (new Recurrence(recurrence)).getNumberOfInstances();
        }

        return totalCount;
    }

    private getWeeklyInstanceCountByOccurrence(pattern: WeeklyPattern, interval: number, startDate: Date, fromDate: Date, toDate: Date, maxOccurrences: number) {

        const sundayOfCurrentWeek = Sedaru.DateUtil.getPreviousDateByDayOfWeek(startDate, 0);
        const nextSunday = Sedaru.DateUtil.getNextDateByDayOfWeek(startDate, 0);
        let totalInstances = 0;
        let validInstances = 0;

        let isLastWeek = false;
        let lastWeekNumber = 0;
        do {
            if (Sedaru.DateUtil.isValidDate(toDate)) {
                if (toDate.getTime() >= sundayOfCurrentWeek.getTime() && toDate.getTime() < nextSunday.getTime()) {
                    isLastWeek = true;
                    lastWeekNumber = pattern.daysOfWeek.filter(d => d <= toDate.getDay()).length;
                }
            }

            if (sundayOfCurrentWeek.getTime() < startDate.getTime()) {
                // First week
                totalInstances += pattern.daysOfWeek.filter(d => d >= startDate.getDay()).length;
            } else if (isLastWeek) {
                totalInstances += lastWeekNumber;
            } else {
                totalInstances += pattern.daysOfWeek.length;
            }

            if (nextSunday.getTime() > fromDate.getTime()) {
                if (sundayOfCurrentWeek.getTime() < fromDate.getTime()) {
                    validInstances += pattern.daysOfWeek.filter(d => d >= fromDate.getDay()).length;
                } else if (isLastWeek) {
                    validInstances += lastWeekNumber;
                } else {
                    validInstances += pattern.daysOfWeek.length;
                }
            }

            if (isLastWeek || totalInstances >= maxOccurrences) {
                if (validInstances > maxOccurrences) {
                    return maxOccurrences;
                }

                return validInstances - (totalInstances - maxOccurrences);
            }

            sundayOfCurrentWeek.setDate(sundayOfCurrentWeek.getDate() + (interval * 7));
            nextSunday.setDate(nextSunday.getDate() + (interval * 7));
        } while (true);
    }

    // Monthly Pattern Functions
    private conformsToDateMonthly(pattern: MonthlyPattern, interval: number, targetDate: Date): boolean {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = targetDate;

        if (targetDate < startDate) return false;

        if (targetDate > endDate) return false;

        const r = this.getMonthlyInstanceCount(pattern, interval, targetDate, targetDate);
        return r === 1;
    }
    private getMonthlyInstanceCount(pattern: MonthlyPattern, interval: number, fromDate: Date, toDate: Date): number {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = toDate;

        if (startDate > toDate) return 0;

        if (endDate < fromDate) return 0;

        if (toDate > endDate) {
            toDate = endDate;
        }
        if (fromDate < startDate) {
            fromDate = startDate;
        }

        const monthCountToIgnore = Math.floor(Sedaru.DateUtil.getMonthDifference(startDate, fromDate) / interval);
        const totalMonthCount = Math.floor(Sedaru.DateUtil.getMonthDifference(startDate, toDate) / interval);

        let result = totalMonthCount - monthCountToIgnore;

        if (pattern.type === MonthlyPatternType.ExactDay) {
            let daysInMonth = Sedaru.DateUtil.daysInMonth(fromDate);
            let dayToEvaluate = pattern.exactDay;
            if (dayToEvaluate > daysInMonth) {
                dayToEvaluate = daysInMonth;
            }

            let monthFitsPattern = Sedaru.DateUtil.getMonthDifference(startDate, fromDate) % interval === 0;
            if (fromDate.getDate() <= dayToEvaluate && monthFitsPattern) {
                result += 1;
            }

            daysInMonth = Sedaru.DateUtil.daysInMonth(toDate);
            dayToEvaluate = pattern.exactDay;
            if (dayToEvaluate > daysInMonth) {
                dayToEvaluate = daysInMonth;
            }

            monthFitsPattern = Sedaru.DateUtil.getMonthDifference(startDate, toDate) % interval === 0;
            if (toDate.getDate() < dayToEvaluate && monthFitsPattern) {
                result -= 1;
            }
        } else if (pattern.type === MonthlyPatternType.VariableDay) {
            let matchDate = this.getVariableDate(pattern.variableDay.dayOfWeek, pattern.variableDay.type, fromDate);
            let monthFitsPattern = Sedaru.DateUtil.getMonthDifference(startDate, fromDate) % interval === 0;
            if (fromDate.getTime() <= matchDate.getTime() && monthFitsPattern) {
                result += 1;
            }

            matchDate = this.getVariableDate(pattern.variableDay.dayOfWeek, pattern.variableDay.type, toDate);
            monthFitsPattern = Sedaru.DateUtil.getMonthDifference(startDate, toDate) % interval === 0;
            if (toDate.getTime() < matchDate.getTime() && monthFitsPattern) {
                result -= 1;
            }
        }

        return result;
    }

    // Yearly Pattern Functions
    private conformsToDateYearly(pattern: YearlyPattern, interval: number, targetDate: Date): boolean {
        return this.getYearlyInstanceCount(pattern, interval, targetDate, targetDate) === 1;
    }

    private getYearlyInstanceCount(pattern: YearlyPattern, interval: number, fromDate: Date, toDate: Date): number {
        const startDate = this.definition.startDate;
        let endDate = this.definition.endBoundary.endDate;

        if (!endDate) endDate = toDate;

        if (startDate > toDate) return 0;

        if (endDate < fromDate) return 0;

        if (toDate > endDate) {
            toDate = endDate;
        }
        if (fromDate < startDate) {
            fromDate = startDate;
        }


        const monthlyPattern = pattern.monthlyPattern;
        let firstPossibleDay: Date;
        if (monthlyPattern.type === MonthlyPatternType.ExactDay) {
            firstPossibleDay = new Date(this.definition.startDate.getFullYear(), pattern.month - 1, pattern.monthlyPattern.exactDay);
        } else if (monthlyPattern.type === MonthlyPatternType.VariableDay) {
            firstPossibleDay = this.getVariableDate(monthlyPattern.variableDay.dayOfWeek, monthlyPattern.variableDay.type, new Date(this.definition.startDate.getFullYear(), pattern.month - 1, 1));
        }

        if (this.definition.startDate.getTime() > firstPossibleDay.getTime()) {
            firstPossibleDay.setFullYear(firstPossibleDay.getFullYear() + 1);
            firstPossibleDay = this.getVariableDate(monthlyPattern.variableDay.dayOfWeek, monthlyPattern.variableDay.type, Sedaru.DateUtil.getFirstDayOfMonth(firstPossibleDay));
        }

        if (firstPossibleDay.getTime() > this.definition.endBoundary.endDate.getTime()) {
            return 0;
        }

        const recurrenceDefinition = new RecurrenceDefinition();
        recurrenceDefinition.startDate = firstPossibleDay;
        recurrenceDefinition.endBoundary.endDate = this.definition.endBoundary.endDate;
        recurrenceDefinition.endBoundary.endType = this.definition.endBoundary.endType;
        recurrenceDefinition.endBoundary.occurrences = this.definition.endBoundary.occurrences;
        recurrenceDefinition.pattern.interval = 12 * interval;
        recurrenceDefinition.type = RecurrencePatternType.Monthly;
        recurrenceDefinition.pattern.monthlyPattern.type = pattern.monthlyPattern.type;
        recurrenceDefinition.pattern.monthlyPattern.exactDay = pattern.monthlyPattern.exactDay;
        recurrenceDefinition.pattern.monthlyPattern.variableDay.type = pattern.monthlyPattern.variableDay.type;
        recurrenceDefinition.pattern.monthlyPattern.variableDay.dayOfWeek = pattern.monthlyPattern.variableDay.dayOfWeek;

        const recurrence = new Recurrence(recurrenceDefinition);
        return recurrence.getMonthlyInstanceCount(recurrence.definition.pattern.monthlyPattern, 12 * interval, fromDate, toDate);
    }

    private handleDaily(startDate: Date, endDate: Date): Array<Date> {
        const results = new Array<Date>();
        const dailyPattern = this.definition.pattern.dailyPattern;

        if (dailyPattern === DailyPatternType.Interval) {
            let evaluatingDate: Date = startDate;
            do {
                evaluatingDate = Sedaru.DateUtil.getDateComponent(evaluatingDate);

                results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
                evaluatingDate.setDate(evaluatingDate.getDate() + this.definition.pattern.interval);
            } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));
        } else if (dailyPattern === DailyPatternType.Weekdays) {
            let evaluatingDate: Date = startDate;
            do {
                try {
                    evaluatingDate = Sedaru.DateUtil.getDateComponent(evaluatingDate);
                    if (evaluatingDate.getDay() === 0 || evaluatingDate.getDay() === 6) {
                        continue;
                    }

                    results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
                } finally {
                    evaluatingDate.setDate(evaluatingDate.getDate() + 1);
                }
            } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));
        } else if (dailyPattern === DailyPatternType.Weekends) {
            let evaluatingDate: Date = startDate;
            do {
                try {
                    evaluatingDate = Sedaru.DateUtil.getDateComponent(evaluatingDate);
                    if (evaluatingDate.getDay() === 0 || evaluatingDate.getDay() === 6) {

                        results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
                    }
                } finally {
                    evaluatingDate.setDate(evaluatingDate.getDate() + 1);
                }
            } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));
        }

        return results;
    }

    private handleWeekly(startDate: Date, endDate: Date): Array<Date> {
        const results = new Array<Date>();
        const weeklyPattern = this.definition.pattern.weeklyPattern;

        const weekNumber = 1;
        const evaluatingDate: Date = startDate;
        do {
            if (weeklyPattern.daysOfWeek.includes(evaluatingDate.getDay())) {
                results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
            }

            if (evaluatingDate.getDay() === 6) {
                const daysToSkip = (this.definition.pattern.interval - 1) * 7;
                evaluatingDate.setDate(evaluatingDate.getDate() + daysToSkip);

                // weekNumber + this.definition.pattern.interval;
            }

            evaluatingDate.setDate(evaluatingDate.getDate() + 1);

        } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));

        return results;
    }

    private handleMonthly(monthlyPattern: MonthlyPattern, startDate: Date, endDate: Date, interval: number): Array<Date> {
        const results = new Array<Date>();
        let monthNumber = 1;
        if (monthlyPattern.type === MonthlyPatternType.ExactDay) {
            const evaluatingDate: Date = new Date(startDate);
            do {
                let exactDay = monthlyPattern.exactDay;
                const daysInMonth = Sedaru.DateUtil.daysInMonth(evaluatingDate);
                if (exactDay > daysInMonth) exactDay = daysInMonth;
                evaluatingDate.setDate(exactDay);
                try {
                    if (evaluatingDate < startDate) continue;
                    if (evaluatingDate > endDate) continue;

                    results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
                }
                finally {
                    evaluatingDate.setMonth(evaluatingDate.getMonth() + interval, 1);
                    monthNumber += interval;
                }

            } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));
        } else if (monthlyPattern.type === MonthlyPatternType.VariableDay) {
            let evaluatingDate: Date = new Date(startDate);
            do {

                try {

                    evaluatingDate = this.getVariableDate(monthlyPattern.variableDay.dayOfWeek, monthlyPattern.variableDay.type, evaluatingDate);
                    if (evaluatingDate < startDate) continue;
                    if (evaluatingDate > endDate) continue;
                    results.push(Sedaru.DateUtil.getDateComponent(evaluatingDate));
                }
                finally {
                    evaluatingDate.setMonth(evaluatingDate.getMonth() + interval, 1);
                    monthNumber += interval;
                }

            } while (this.handleWhileLoopEnd(evaluatingDate, endDate, results.length));
        }

        return results;
    }
    private handleYearly(startDate: Date, endDate: Date): Array<Date> {
        let results = new Array<Date>();
        const yearlyPattern = this.definition.pattern.yearlyPattern;

        let firstValidDate: Date;
        if (yearlyPattern.month - 1 < startDate.getMonth()) {
            firstValidDate = new Date(startDate.getFullYear() + 1, yearlyPattern.month - 1, 1);
        } else {
            firstValidDate = new Date(startDate);
            firstValidDate.setMonth(yearlyPattern.month - 1);
        }

        if (firstValidDate > endDate) return results; // invalid range

        results = this.handleMonthly(yearlyPattern.monthlyPattern, firstValidDate, endDate, 12 * this.definition.pattern.interval);

        return results;
    }

    private handleWhileLoopEnd(evaluatingDate: Date, endDate: Date, occurrences: number) {
        const endType = this.definition.endBoundary.endType;
        if (endType === EndBoundaryType.Infinite) {
            if (isNaN(endDate.getTime())) {
                return false; // break the loop
            }
            return evaluatingDate <= endDate;
        }
        if (endType === EndBoundaryType.Finite) {
            return evaluatingDate <= endDate;
        }
        if (endType === EndBoundaryType.Occurrences) {
            return occurrences < this.definition.endBoundary.occurrences;
        }

        return false;
    }

    private getVariableDate(dayOfWeek: number, variableMonthlyDay: VariableDayType, date: Date): Date {
        let currentDate = Sedaru.DateUtil.getDateComponent(Sedaru.DateUtil.getFirstDayOfMonth(date));
        currentDate = Sedaru.DateUtil.getNextDateByDayOfWeek(currentDate, dayOfWeek);
        let counter = 0;
        let lastInstance: Date;
        do {
            if (currentDate.getDay() === dayOfWeek) {
                counter++;
                lastInstance = new Date(currentDate);
            }
            if (variableMonthlyDay === VariableDayType.First) {
                if (counter === 1) return currentDate;
            } else if (variableMonthlyDay === VariableDayType.Second) {
                if (counter === 2) return currentDate;
            } else if (variableMonthlyDay === VariableDayType.Third) {
                if (counter === 3) return currentDate;
            } else if (variableMonthlyDay === VariableDayType.Fourth) {
                if (counter === 4) return currentDate;
            }
            currentDate.setDate(currentDate.getDate() + 7);
        } while (currentDate.getMonth() === date.getMonth());

        return lastInstance;
    }
}
