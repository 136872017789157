import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IdentityController } from './identity-controller';

/*export class DefaultRequestObject {
	token: string;
	data: any;
	constructor(token: string, data: any) {
		this.token = token;
		this.data = data;
	}
}*/

export class DefaultResponseObject {
	success: boolean;
	message: string;
	result: any;
	authenticationKey: string;
	private _isUnauthorized = false;
	get isUnauthorized(): boolean {
		return this._isUnauthorized;
	}
	error: any;

	constructor() {}

	setError(error: any) {
		const responseError = error as HttpErrorResponse;
		this.error = error;
		if (responseError) {
			if (responseError.status === 401) {
				this._isUnauthorized = true;
			}
		}

		this.success = false;
	}

	setResponse(response: any) {
		if (response.ErrorCode && response.ErrorCode === '401') {
			this.success = false;
			this.message = response.ErrorMessage;
			this._isUnauthorized = true;
			this.result = response;
			return;
		}

		if (!response.Result) {
			// If it is not the standard format
			this.success = true;
			this.message = '';
			this.result = response;
			return;
		}

		this.authenticationKey = response.AuthenticationKey;
		this.message = response.Message;
		this.success = response.Success;

		const result = response.Result;
		if (!result) return;

		if (typeof result === 'string') this.result = JSON.parse(result);
		else this.result = result;
	}
}

export class ServiceResponse {
	private _defaultResponse: DefaultResponseObject;
	public get defaultResponse(): DefaultResponseObject {
		return this._defaultResponse;
	}
	public get success(): boolean {
		if (this.isUnauthorized) return false;

		if (this.error) return false;

		return true;
	}

	private _error;
	public get error(): any {
		if (this._error) return this._error;

		if (!this._defaultResponse) return null;

		return this._defaultResponse.error;
	}
	public set error(value: any) {
		this.error = value;
	}

	get isUnauthorized(): boolean {
		return this._defaultResponse.isUnauthorized;
	}

	constructor(response: DefaultResponseObject) {
		this._defaultResponse = response;
	}
}

export class ServiceEndpointResultBase {
	public get success(): boolean {
		if (this.error) return false;

		return true;
	}

	get error(): any {
		if (this.serviceResponse) return this.serviceResponse.error;

		return null;
	}

	private _serviceResponse: ServiceResponse;
	public get serviceResponse(): ServiceResponse {
		return this._serviceResponse;
	}

	constructor(serviceResponse: ServiceResponse) {
		this._serviceResponse = serviceResponse;
	}
}

export class ServiceEndpointResult<TResult> extends ServiceEndpointResultBase {
	result: TResult;

	constructor(serviceResponse: ServiceResponse, result?: TResult) {
		super(serviceResponse);
		this.result = result;
	}
}

export class HttpServiceBase {
	private _baseUrl: string;
	private httpClient: HttpClient;
	private _identityController: IdentityController;
	public get identityController(): IdentityController {
		return this._identityController;
	}

	private get token() {
		if (!this.identityController) {
			return '';
		}

		if (!this.identityController.sedaruOAuthToken) {
			return '';
		}
		return this.identityController.sedaruOAuthToken.accessToken;
	}

	constructor(baseUrl: string, httpClient: HttpClient, identityController?: IdentityController) {
		this._baseUrl = baseUrl;
		this.httpClient = httpClient;
		this._identityController = identityController;
	}

	/**
	 * GET request for fetching data form AWO api server
	 * @param url - AWO url
	 * @param paramsObj - query string definition
	 */
	protected get(endpoint: string, queryString?: string): Promise<ServiceResponse> {
		const url = this.createEndpointURL(endpoint);

		return this.connectMethod(url);
	}

	protected async getData(endpoint: string): Promise<ServiceResponse> {
		const retryCount = 1;
		let attempts = 0;

		let response: ServiceResponse;

		do {
			let url = endpoint;
			if (endpoint.includes('?')) {
				url = `${endpoint}&${this.onGetTokenParameterName(endpoint)}=${this.token}`;
			} else {
				url = `${endpoint}?${this.onGetTokenParameterName(endpoint)}=${this.token}`;
			}

			response = await this.get(url);

			if (response.isUnauthorized && this.identityController != null) {
				// token expired
				const tokenRenewed = await this.identityController.handleExpiredTokenAsync();
				if (!tokenRenewed) return response;
			}

			attempts++;
		} while (response.isUnauthorized && attempts <= retryCount);

		return response;
	}

	protected post(endpoint: string, content: any): Promise<ServiceResponse> {
		const url = this.createEndpointURL(endpoint);
		return this.connectMethod(url, content);
	}
	protected async postData(endpoint: string, data: any): Promise<ServiceResponse> {
		const request = this.createRequestBody(endpoint, data);

		const retryCount = 1;
		let attempts = 0;

		let response: ServiceResponse;

		do {
			response = await this.post(endpoint, request);

			if (response.isUnauthorized && this.identityController != null) {
				// token expired
				const tokenRenewed = await this.identityController.handleExpiredTokenAsync();
				if (!tokenRenewed) return response;
			}

			attempts++;
		} while (response.isUnauthorized && attempts <= retryCount);

		return response;
	}

	private connectMethod(url: string, data?: any): Promise<ServiceResponse> {
		return new Promise<ServiceResponse>(resolve => {
			const result = new DefaultResponseObject();

			let promise: Promise<any>;
			if (data) {
				promise = this.httpClient.post(url, data, this.onCreateOptions()).toPromise();
			} else {
				promise = this.httpClient.get(url, this.onCreateOptions()).toPromise();
			}

			promise
				.then(response => {
					result.setResponse(response);
					resolve(new ServiceResponse(result));
				})
				.catch(e => {
					result.setError(e);
					resolve(new ServiceResponse(result));
				});
		});
	}

	private createRequestBody(endpoint: string, data: any): any {
		const stringifyData = this.onStringifyData(endpoint);

		let parsedData = data;
		if (stringifyData) {
			parsedData = JSON.stringify(data);
		}

		const currentToken = this.token;

		if (!currentToken) console.log('Request was created with a null token.');

		const request = {};

		if (currentToken) {
			request[this.onGetTokenParameterName(endpoint)] = currentToken;
		}

		request[this.onGetDataParameterName(endpoint)] = parsedData;

		return request;
	}

	private getResponseResult(response: any): DefaultResponseObject {
		const r = new DefaultResponseObject();
		r.setResponse(response);
		return r;
	}

	private createEndpointURL(endpoint: string): string {
		const url = this.onCreateEndpointURL(endpoint);

		if (url && url.length > 0) return url;

		if (!endpoint) throw new Error('Invalid endpoint.');

		if (endpoint.startsWith('/')) endpoint = endpoint.substr(1);

		return this._baseUrl + '/' + endpoint;
	}

	protected onCreateEndpointURL(endpoint: string): string {
		return '';
	}

	protected onCreateOptions(): any {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};

		return httpOptions;
	}

	protected onGetTokenParameterName(endpoint: string): string {
		return 'token';
	}

	protected onGetDataParameterName(endpoint: string): string {
		return 'data';
	}

	protected onStringifyData(endpoint: string): boolean {
		return false;
	}
}
