import { MetricIcon } from 'models';
import { Component, Input } from '@angular/core';

/**
 * This component wraps an SVG so that a generic SVG can be imported and a circle can be added as well as forground and
 * background colors.
 */
@Component({
	selector: 'app-asset-icon',
	templateUrl: './asset-icon.component.html'
})
export class AssetIconComponent {
	/**
	 * The SVG icon to be displayed
	 */
	@Input() icon: MetricIcon;

	constructor() {}
}
