import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util/index';

export class WorkOrderCapabilities extends OmniModel<WorkOrderCapabilities, Contracts.WorkOrderCapabilitiesContract> {
    private _supportsWorkOrderRecurrence: SedaruUtils.Undoable<boolean>;
    get supportsWorkOrderRecurrence(): boolean {
        if (!this._supportsWorkOrderRecurrence) this._supportsWorkOrderRecurrence = new SedaruUtils.Undoable<boolean>();
        return this._supportsWorkOrderRecurrence.value;
    }
    set supportsWorkOrderRecurrence(value: boolean) {
        if (!this._supportsWorkOrderRecurrence) {
            this._supportsWorkOrderRecurrence = new SedaruUtils.Undoable<boolean>(value);
            return;
        }
        this._supportsWorkOrderRecurrence.value = value;
    }

    static fromContract(contract: Contracts.WorkOrderCapabilitiesContract): WorkOrderCapabilities {
        const model = new WorkOrderCapabilities();
        model.supportsWorkOrderRecurrence = contract.supportsWorkOrderRecurrence;
        return model;
    }

    getContract(): Contracts.WorkOrderCapabilitiesContract {
        const contract = new Contracts.WorkOrderCapabilitiesContract();
        contract.supportsWorkOrderRecurrence = this.supportsWorkOrderRecurrence;
        return contract;
    }

    onUpdateInformation(copyModel: WorkOrderCapabilities): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<boolean>(this._supportsWorkOrderRecurrence, () => {
                this.supportsWorkOrderRecurrence = copyModel.supportsWorkOrderRecurrence;
            }) || wasChanged;

        return wasChanged;
    }
}
