<mat-grid-list cols="2" rowHeight="fit" class="canvas-container" [ngStyle]="gridHeight" [gutterSize]="gutterSize" #canvascontainer>
	<mat-grid-tile *ngFor="let canvas of canvases" [colspan]="displayMode === 'phone' ? 2 : canvas.colspan" class="canvas">
		<app-canvas
			[config]="config"
			[canvas]="canvas"
			[mode]="canvas.mode"
			(goToMapList)="onGoToMapList($event)"
			(goToTrendList)="onGoToTrendList($event)"
			(activateTrend)="activateTrend($event)"
			(goToTable)="onGoToTable($event)"
			[displayMode]="displayMode"
		></app-canvas>
	</mat-grid-tile>
</mat-grid-list>
