import { Component, ElementRef } from '@angular/core';
import { OmniValueListService } from 'app/omni-value-list/omni-value-list.service';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';
import { MenuPanelSettings, SortOrderType, ArcGISField, Metric, ChannelTypes, ArcGISAssetChannelAttributes, ArcGISHistoryChannelAttributes } from '../../../../../models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniInteropService } from 'domain-service/omni-interop.service';

/**
 * This component is designed to be loaded when the user selects the default menu panel settings field from
 * the add or edit metric side panel.
 */
@Component({
	selector: 'app-menu-panel-settings',
	templateUrl: './menu-panel-settings.component.html',
	styleUrls: ['./menu-panel-settings.component.scss']
})
export class MenuPanelSettingsComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the metric sort settings object obtained from service.
	 */
	settings: MenuPanelSettings;
	/**
	 * Boolean variable to handle toggling the 'sort order' field.
	 */
	isAscending: boolean;
	/**
	 * Holds the display text for the 'sort order' field. It is either 'ascending' or 'descending'
	 */
	sortOrder: string;
	/**
	 * Holds the icon URL for the 'default sort order' field. It will either be a path to the up-arrow icon for ascending
	 * or the down-arrow icon for descending.
	 */
	defaultSortOrderIcon: string;

	metric: Metric;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {MetricService} metricService - Provides all the services required to manipulate a metric
	 * @param {OmniValueListService} listService - Provides services to create OMNI's generic list.
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(
		private interopService: OmniInteropService,
		private listService: OmniValueListService,
		private flashMessageService: FlashMessageService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	/**
	 * On init, defaultMenuPanelSettings is obtained from the service, the display text and icon for the 'default sort order'
	 * field is updated appropriately.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'panel settings' });
		this.settings = this.metric.definition.menuPanelSettings;

		if (this.settings.defaultSortOrder >= 0) {
			this.isAscending = this.settings.defaultSortOrder === Number(SortOrderType.Ascending);
			this.defaultSortOrderIcon = this.isAscending ? 'assets/ascending-circled.png' : 'assets/descending-circled.png';
			this.sortOrder = this.settings.defaultSortOrder === Number(SortOrderType.Ascending) ? 'ascending' : 'descending';
		} else {
			this.isAscending = true;
			this.defaultSortOrderIcon = 'assets/ascending-circled.png';
			this.sortOrder = 'ascending';
		}
	}

	/**
	 * This method is invoked when we select the 'default sort field' and it will fetch the array of fields and populate it
	 * in the omni list component.
	 */
	onGotoDefaultSortField(): void {
		const fields = this.interopService.arcGISManager.getArcGisFields(this.metric);

		this.listService.list = [];
		for (const defaultSortField of fields) {
			this.listService.list.push({
				name: defaultSortField.omniName,
				callBackFunction: () => {
					this.metric.definition.menuPanelSettings.defaultSortField = defaultSortField;
				},
				shouldGoBack: true
			});
		}
		this.listService.header = 'select sort field';
		this.listService.selectedItem = this.metric.definition.menuPanelSettings.defaultSortField.omniName;
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.defaultSortField.omniName }, selectedItemKey: 'name' });
	}

	/**
	 * This method is invoked when we select the 'table display fields' and it will fetch the array of fields and populate it
	 * in the omni list component.
	 */
	onGotoDisplayFields(): void {
		const fields = this.interopService.arcGISManager.getArcGisFields(this.metric);
		this.listService.list = [];
		for (const displayField of fields) {
			this.listService.list.push({
				name: displayField.omniName,
				callBackFunction: () => {
					this.metric.definition.menuPanelSettings.displayField = displayField;
				},
				shouldGoBack: true
			});
		}
		this.listService.selectedItem = this.metric.definition.menuPanelSettings.displayField.omniName;
		this.listService.header = 'select display field';
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.displayField.omniName }, selectedItemKey: 'name' });
	}

	/**
	 * This method is invoked when we toggle between 'ascending' and 'descending' in the 'default sort order' field
	 */
	toggleDefaultSortOrder() {
		this.isAscending = !this.isAscending;
		const { menuPanelSettings } = this.metric.definition;
		if (this.isAscending) {
			this.sortOrder = 'ascending';
			this.defaultSortOrderIcon = 'assets/ascending-circled.png';
			menuPanelSettings.defaultSortOrder = SortOrderType.Ascending;
		} else {
			this.sortOrder = 'descending';
			this.defaultSortOrderIcon = 'assets/descending-circled.png';
			menuPanelSettings.defaultSortOrder = SortOrderType.Descending;
		}
	}
}
