<div class="scrolling-content">
	<span *ngFor="let sourceType of metricSourceTypes | keyvalue: sort">
		<mat-card class="data-channel-type-option" (click)="sourceType.value.enabled && addMetricSource(sourceType.value)" [class.disabled]="!sourceType.value.enabled">
			<mat-card-content>{{ sourceType.value.fullName }}</mat-card-content>
			<mat-card-header>
				<img [src]="sourceType.value.icon" mat-card-avatar />
				<mat-card-subtitle>
					{{ sourceType.value.description }}
				</mat-card-subtitle>
			</mat-card-header>
		</mat-card>
		<mat-divider class="data-channel-type-divider"></mat-divider>
	</span>
</div>
