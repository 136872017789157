import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { HierarchyNode } from 'models/work-order/hierarchy-node.model';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { AssetRecord } from 'models/records/asset-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { AdvancedWorkOrder, WorkOrderSummary, WorkOrderWrapper } from 'models/work-order';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { UploaderService } from '../attachment/uploader.service';
import { Subscription } from 'rxjs';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';

@Component({
	selector: 'app-hierarchy-menu-panel',
	templateUrl: './hierarchy-menu-panel.component.html',
	styleUrls: ['./hierarchy-menu-panel.component.scss']
})
export class HierarchyMenuPanelComponent extends MenuPanelBaseComponent {
	private recordContext: RecordContextGroup;
	private nodeParameter: HierarchyNode<AssetLocationWithAssets>;
	private assetLocationToAddAttachment: AssetLocationWithAssets;
	private uploadResponseSubscription: Subscription;
	hierarchyNode: HierarchyNode<AssetLocationWithAssets>;
	ancestors: Array<HierarchyNode<AssetLocationWithAssets>> = new Array<HierarchyNode<AssetLocationWithAssets>>();
	children: Array<HierarchyNode<AssetLocationWithAssets>> = new Array<HierarchyNode<AssetLocationWithAssets>>();
	assetRecord: AssetRecord;
	workOrder: WorkOrderWrapper;
	instructionText = 'select an asset from the map';
	loading = true;
	slideOnPageReload = true;
	mode: string;
	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	@ViewChild('imageInput', { static: true }) imageInput: ElementRef;

	constructor(
		private workOrderFactory: WorkOrderFactory,
		private interop: OmniInteropService,
		private uploaderService: UploaderService,
		private graphicsService: OmniMapGraphicsService,
		private flashMessageService: FlashMessageService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	async ngAfterViewInit() {
		if (!this.nodeParameter) {
			this.loadAsset(this.assetRecord);
			return;
		}

		this.refreshHierarchyNodes(this.nodeParameter);
	}

	async refreshHierarchyNodes(node: HierarchyNode<AssetLocationWithAssets>): Promise<void> {
		if (!node || !node.value) return;

		try {
			this.loading = true;
			this.menuPanelComponent.updatePacifier(true);
			await this.workOrderFactory?.refreshHierarchyNodeValues(node);
			this.updateTree(node);
		} finally {
			this.loading = false;
			this.menuPanelComponent.updatePacifier(false);
		}
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.recordContext = args.parameter.recordContext;
		this.workOrder = args.parameter.workOrder;
		this.assetRecord = this.recordContext?.assetRecord;
		this.nodeParameter = args.parameter.node;

		this.uploadResponseSubscription = this.uploaderService.uploadResponse$.subscribe((attachmentUpload) => {
			try {
				if (!attachmentUpload || !attachmentUpload.isSuccess) {
					this.alertDialog.mainMessage = { text: `Unable to upload attachment ${attachmentUpload?.file?.name}` };
					this.alertDialog.open = true;
					return;
				}

				attachmentUpload.refreshAttachmentModel();
				this.updateTree(this.hierarchyNode);
			} finally {
				this.menuPanelComponent.updatePacifier(false);
			}
		});

		if (!this.recordContext || !this.assetRecord) return;
		const currentConfig = this.interop?.configurationManager?.guiConfig?.find(config => config.isSelected);
		if (currentConfig && currentConfig.selectedCanvas?.map) this.mode = currentConfig.selectedCanvas.map.eventHandlers['workAssetSelectionHandler'] ? 'add workasset' : '';

		this.menuPanelComponent.updateView({
			title: this.assetRecord.definitionTitle,
			subtitle: this.mode === 'add workasset' ? 'add work asset' : '',
			backgroundColor: this.assetRecord.assetDefinition.style.layout.background.color,
			rightIcon: { url: 'assets/workorder-white.png', toolTip: '', callBackFunction: this.getCallBackFunction.bind(this) },
			disableRightButton: this.mode === 'add workasset'
		});
	}

	ngOnDestroy() {
		if (this.uploadResponseSubscription) this.uploadResponseSubscription.unsubscribe();
	}

	onPageReload(args: NavigationArgs) {
		const node = args?.parameter?.node;
		if (!node) return;

		this.loading = true;
		this.updateTree(node);
		this.loading = false;
	}

	private getCallBackFunction = async () => {
		if (!this.hierarchyNode) return;
		this.menuPanelComponent.updatePacifier(true);
		try {
			const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey();
			if (!workOrderKeys) {
				throw new Error();
			}
			const workOrder = this.workOrderFactory.createWorkOrderModel(workOrderKeys[0], this.assetRecord);
			this.graphicsService.startWorkOrderCreation(this.config.selectedCanvas, this.assetRecord);
			workOrder.location = this.hierarchyNode.value.locationId;
			NavigationService.navigateTo(Pages.workorderSummary, { workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.CreateSingleWO, [workOrder]) } );
		} catch (e) {
			this.flashMessageService.popMessage('Cannot create workorder. No valid workorder key. Please try again.');
		} finally {
			this.menuPanelComponent.updatePacifier(false);
		}
	};

	private async loadAsset(assetRecord: AssetRecord): Promise<void> {
		if (!assetRecord) {
			this.instructionText = 'select an asset from the map';
			return;
		}

		if (!assetRecord.assetId) {
			this.instructionText = 'unable to find asset id';
			return;
		}

		try {
			this.menuPanelComponent.updatePacifier(true);
			this.menuPanelComponent.title = assetRecord.definitionTitle;
			this.instructionText = 'loading hierarchy';
			this.updateTree(await this.workOrderFactory.getHierarchy(this.workOrderFactory.getAssetLocationByGisId(assetRecord.assetId.toString())));
		} finally {
			this.instructionText = !this.hierarchyNode ? 'unable to load hierarchy' : null;
			this.menuPanelComponent.updatePacifier(false);
			this.loading = false;
		}
	}

	updateTree(node: HierarchyNode<AssetLocationWithAssets>): void {
		try {
			if (!node || !node.value) {
				this.clearTree();
				return;
			}

			this.updateNodes(node);
			this.hierarchyNode = node;
			this.adjustCurrentLists();
		} finally {
			this.menuPanelComponent.disableRightButton = this.hierarchyNode == null || this.mode === 'add workasset';
		}
	}

	updateNodes(node: HierarchyNode<AssetLocationWithAssets>): void {
		node.ancestors.forEach(a => {
			this.updateNode(a, false);
		});
		node.children.forEach(c => {
			this.updateNode(c, true);
		});
		this.updateNode(node, false);
	}

	updateNode(node: HierarchyNode<AssetLocationWithAssets>, isChild: boolean): void {
		node.isChild = isChild;
	}

	adjustCurrentLists() {
		this.ancestors = this.ancestors.filter(a => this.hierarchyNode.ancestors.includes(a));
		this.hierarchyNode.ancestors
			.sort(a => a.position)
			.filter(a => !this.ancestors.includes(a))
			.forEach(a => {
				this.ancestors.push(a);
			});
		this.ancestors.push(this.hierarchyNode);
		this.children = this.children.filter(c => this.hierarchyNode.children.includes(c));
		this.hierarchyNode.children
			.filter(c => !this.children.includes(c))
			.forEach(c => {
				this.children.push(c);
			});
	}

	clearTree(): void {
		this.hierarchyNode = null;
		this.ancestors = [];
		this.children = [];
	}

	async handleLeftIconClick(node: HierarchyNode<AssetLocationWithAssets>) {
		if (!node || !node.value) return;

		const attachmentUrl = node?.value?.attachment?.url;
		if (!attachmentUrl) {
			this.assetLocationToAddAttachment = node.value;
			this.imageInput.nativeElement.click();
			return;
		}

		const image = new Image();
		image.onload = () => this.interop.uiManager.photoViewer.openGallery([{ src: attachmentUrl, w: image.width, h: image.height }]);
		image.src = attachmentUrl;
	}

	async handleRightIconClick(node: HierarchyNode<AssetLocationWithAssets>) {
		if (!node || !node.value) return;

		if (!node.isChild) {
			if (node.value.hasAsset) {
				this.goToAssetInfo(node.value);
				return;
			}

			if (node.value.locationWorkOrderKeys && node.value.locationWorkOrderKeys.length) {
				this.goToWorkOrders(node.value);
				return;
			}

			return;
		}

		if (node.hasChildren) {
			this.nodeSelected(node);
			return;
		}

		if (!node.value.hasAsset) return;
		this.goToAssetInfo(node.value);
	}

	private nodeSelected(node: HierarchyNode<AssetLocationWithAssets>): void {
		const param = {
			node: node,
			recordContext: this.recordContext
		};

		if (node.ancestors.length < this.ancestors.length) NavigationService.navigateBackTo(Pages.hierarchy, param);
		NavigationService.navigateTo(Pages.hierarchy, param);
	}

	private goToWorkOrders(assetLocation: AssetLocationWithAssets): void {
		if (!assetLocation) return;

		if (assetLocation.locationWorkOrderKeys.length !== 1) {
			NavigationService.navigateTo(Pages.hierarchyWorkOrderList, { locationId: assetLocation.locationId, workOrderKeys: assetLocation.locationWorkOrderKeys });
			return;
		}

		const workOrder = new AdvancedWorkOrder();
		workOrder.workOrderKey = assetLocation.locationWorkOrderKeys[0];
		NavigationService.navigateTo(Pages.workorderOutline, { workOrder: workOrder, advancedMode: true });
		return;
	}

	private async goToAssetInfo(assetLocation: AssetLocationWithAssets): Promise<void> {
		if (!assetLocation) return;

		const asset = await this.workOrderFactory.getAsset(null, assetLocation.activeAsset);
		if (!asset) return;

		let assetDefinition = this.assetRecord.assetDefinition;
		const locationAssetDefinition = this.interop?.omniDomain?.userService?.globalConfig?.assetDefinitions?.getByAssetType(asset.assetType);
		if (locationAssetDefinition) assetDefinition = locationAssetDefinition;

		NavigationService.navigateTo(Pages.assetInformation, {
			recordContext: new RecordContextGroup({ hierarchyRecord: new HierarchyAssetRecord(asset, assetLocation, assetDefinition), isHierarchyRecord: true }),
			isWorkAssetAdd: this.mode === 'add workasset',
			workOrder: this.workOrder ? this.workOrder : this.workOrderFactory.currentWorkOrder
		});
	}

	async handleItemClick(node: HierarchyNode<AssetLocationWithAssets>) {
		const param = {
			node: node,
			recordContext: this.recordContext
		};

		if (!node.isChild) NavigationService.navigateBackTo(Pages.hierarchy, param);
		else NavigationService.navigateTo(Pages.hierarchy, param);
	}

	fileChange(element) {
		this.uploadFile(element.target.files[0]);
	}

	private uploadFile(uploadedFile: File): void {
		if (!uploadedFile) return;
		this.menuPanelComponent.updatePacifier(true);
		this.uploaderService.enqueueImage(this.workOrderFactory.getAssetLocationAttachmentUpload(uploadedFile, this.assetLocationToAddAttachment));
	}
}
