import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LayerResultModel, LayerFeatureModel } from './layer-result.model';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ArcGISAssetChannelAttributes } from 'models';
import { FindOperation } from 'sedaru-util/esri-core/find-operation';
import { FindResultItem, FindResult } from 'sedaru-util/esri-core/find-result';

import { debounceTime } from 'rxjs/operators';
import { switchMap, map } from 'rxjs/operators';
import { from, forkJoin } from 'rxjs';
import { BingSearchService } from './bing-search-service';
import { SearchMode } from './search-bar/search-mode.model';
import { GuiConfigService } from 'domain-service/gui-config.service';
import { AWOModel } from 'models/work-order/awo-model';
import { StandardWorkOrder } from 'models/work-order';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderService } from 'domain-service/work-order.service';

@Injectable({ providedIn: 'root' })
export class SearchService {
	/** alerts the subscriber when a new search has been requested */
	private searchUpdated = new Subject();
	searchUpdated$ = this.searchUpdated.asObservable();
	/** false if the service is in the middle of a search */
	searchComplete = false;
	/** the string that is being searched for */
	searchQuery = '';
	/** holds the results of the current search */
	searchResults: Array<any>;
	workOrderSearchResults: Array<AWOModel | StandardWorkOrder> = [];
	totalNumberOfRecords: number;

	selectedMode: SearchMode;

	scrollPosition = 0;
	currentPage: number;
	displayedRecordCount: number;

	constructor(
		private interop: OmniInteropService,
		private bingSearchService: BingSearchService,
		public guiConfigService: GuiConfigService,
		private flashMessageService: FlashMessageService,
		private workorderFactory: WorkOrderFactory,
		private workOrderService: WorkOrderService
	) {}

	initialize() {
		this.bingSearchService.initialize();
	}

	/**
	 * The methods returns the list of available search modes.
	 */
	getSearchModes() {
		const searchModes = [
			{
				title: 'search assets',
				identifier: 'assets',
				iconUrl: 'assets/assets.png'
			},
			{
				title: 'search street addresses',
				identifier: 'address',
				iconUrl: 'assets/address.png'
			},
			{
				title: 'search work orders',
				identifier: 'workorder',
				iconUrl: 'assets/workorder-white.png'
			},
			{
				title: 'contextual search',
				identifier: 'contextual',
				iconUrl: 'assets/search-context.png'
			}
		];
		searchModes.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
		return searchModes;
	}

	/**
	 * The method is called when the search is being made in 'asset' mode.
	 * @param {string} searchQuery - The value typed in the search component's text box.
	 */
	onSearchAssets$(searchTerms: Observable<string>) {
		const currentSelcted = this.guiConfigService.availableConfigurations.find(c => c.isSelected);
		const tabId = currentSelcted.id;
		return searchTerms.pipe(
			debounceTime(400),
			switchMap(searchTerm => {
				this.searchQuery = searchTerm;
				this.searchComplete = false;
				this.searchResults = [];
				const newSelctedTab = this.guiConfigService.availableConfigurations.find(c => c.isSelected);
				this.searchUpdated.next();
				return this.searchEntries(searchTerm);
			}),
			map(findResultResponseList => {
				for (const assetType of Object.keys(findResultResponseList)) {
					const findResult: FindResult = findResultResponseList[assetType];
					// We skip a layer with no results or if we encounter an issue
					if (!findResult.success || findResult.results.length === 0) continue;

					const layerFeatures: LayerFeatureModel[] = [];
					const findResults: FindResultItem[] = findResult.results;
					findResults.forEach((resultItem: FindResultItem) => {
						const layerFeature: LayerFeatureModel = {
							assetType,
							feature: resultItem.feature,
							layerId: resultItem.layerId,
							foundField: { fieldName: resultItem.foundFieldName, fieldAlias: resultItem.foundFieldName }
						};
						// layerFeature.feature.geometry = resultItem.geometry;
						layerFeatures.push(layerFeature);
					});

					const parsedResult: LayerResultModel = {
						assetType,
						results: layerFeatures
					};
					this.searchResults.push(parsedResult);
				}

				this.searchComplete = true;
				this.scrollPosition = 0;
				const newSelctedTab = this.guiConfigService.availableConfigurations.find(c => c.isSelected);

				this.searchUpdated.next({tabId: newSelctedTab.id});
				return this.searchResults;
			})
		);
	}

	searchEntries(searchTerm) {
		this.searchQuery = searchTerm;
		this.searchResults = [];
		this.searchComplete = false;
		const findResultList$ = {};
		for (const assetDefinition of this.interop.configurationManager.customerCodeConfiguration.assetDefinitions) {
			// We don't search if we can't find a valid channel
			if (!assetDefinition.assetChannel || !assetDefinition.assetChannel.attributes) continue;

			// We don't search if there are no fields to search.
			if (assetDefinition.searchableFieldNames.length === 0) continue;

			const arcGISAttributes = assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;

			// We don't search if the channel is not an ArcGIS channel
			if (!arcGISAttributes) continue;

			const service = this.interop.arcGISManager.getArcGISService(arcGISAttributes.mapServiceDataSourceLegacyId);
			const findOperation = new FindOperation();
			findOperation.layerIds.push(arcGISAttributes.mapServiceLayerIndex);
			findOperation.returnGeometry = true;
			assetDefinition.searchableFieldNames.forEach(f => findOperation.searchFields.push(f));
			// findOperation.searchFields.push('facilityid'); // debug;
			findOperation.searchText = this.searchQuery;
			const assetType = arcGISAttributes.assetType;
			findResultList$[assetType] = from(service.find(findOperation));
		}

		return forkJoin(findResultList$);
	}

	/**
	 * The method is called when the search is being made in 'address' mode.
	 * @param {string} searchQuery - The value typed in the search component's text box.
	 */
	async onSearchAddress(searchQuery: string) {
		this.searchQuery = searchQuery;
		this.searchComplete = false;
		this.searchResults = [];
		this.searchUpdated.next();

		try {
			const searchResults = await this.bingSearchService.search(searchQuery);
			if (!searchResults) return;

			for (const result of searchResults) this.searchResults.push(result);
		} catch (exception) {
			return;
		} finally {
			this.scrollPosition = 0;
			this.searchComplete = true;
			this.searchUpdated.next();
		}
	}

	/**
	 * The method is called when the search is being made in 'workorder' mode.
	 * @param {string} searchQuery - The value typed in the search component's text box.
	 */
	async onSearchWorkorder(searchQuery: string, page = 1, isContains?: boolean): Promise<void> {
		if (!searchQuery) return;
		if (page === 1) {
			this.workOrderSearchResults = [];
			this.currentPage = 0;
			this.displayedRecordCount = 50;
		}
		this.searchQuery = searchQuery;
		this.searchComplete = false;
		this.searchUpdated.next();
		await new Promise<void>(async(resolve) => {
				try {
					if (this.workorderFactory.isAdvancedMode) {
						const postData = {
							workorderkey: searchQuery,
							basicdata: true,
							iscontains: true,
							pagination: true,
							numberOfRecordsPerPage: 100,
							page: page,
							sorting: true
						};
						const searchResults = await this.workOrderService.getAdvancedWorkOrderSearchData(postData).toPromise()
						if (!searchResults || !searchResults.Result || !searchResults.Result.list) return;
						this.totalNumberOfRecords = searchResults.Result.totalNumberOfRecords;
						for (const workorderStub of searchResults.Result.list) {
							this.workOrderSearchResults.push(workorderStub as AWOModel);
						}
					} else {
						const searchResults = await this.workorderFactory.getMatchingWorkOrders(searchQuery, isContains);
						if (!searchResults || !searchResults) return;
						for (const workorderStub of searchResults) {
							this.workOrderSearchResults.push(workorderStub.workOrder as StandardWorkOrder);
						}
					}
				} catch (exception) {
					this.flashMessageService.popMessage(exception.ErrorMessage);
					console.error(exception);
				} finally {
					if (isContains) this.searchComplete = true;
					this.searchUpdated.next();
					resolve();
				}
		});

	};
};
