import { Component, Input, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { QuerySource } from 'models';

@Component({
	selector: 'app-query-source',
	templateUrl: './query-source.component.html'
})
export class QuerySourceComponent extends MenuPanelBaseComponent {
	/**
	 * The default sedaru grey color code
	 */
	readonly defaultSedaruGrey = 'rgba(128, 128, 128, 0.6)';

	/**
	 * The list of selection for source
	 */
	@Input() sourceSelection: any;

	/**
	 * The selected source that will populate the ui
	 */
	@Input() querySource: QuerySource;

	/**
	 * If the source selection should be disable
	 */
	@Input() disableSource: boolean;

	/**
	 * The call back function to pass the source from this component to its parent compoenent
	 */
	@Input() passSourceToEditQueryStatementComponent: (source: QuerySource) => any;

	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * Pass the selected source to the parent 'query-statement component'
	 * @param {QuerySource} source
	 */
	onSourceSelected(source: QuerySource): void {
		this.passSourceToEditQueryStatementComponent(source);
	}
}
