export enum BackButtonSource {
	menuPanel,
	browser,
	programatic
}
export class BackNavigationArgs {
	public get source(): BackButtonSource {
		return this._source;
	}
	handled = false;

	constructor(private _source: BackButtonSource) {}
}
