<div class="scrolling-content">
	<div class="query-block" *ngIf="showHistoryQueryInput">
		<span class="control-title">history table query string</span>
		<textarea
			placeholder="enter related table query string here"
			[(ngModel)]="relatedTableQueryString"
			(ngModelChange)="onQueryStringEdited()"
			rows="3"
			cols="50"
			name="metricTitle"
			id="metricTitle"
		></textarea>
	</div>
	<div class="query-block" *ngIf="showAssetQueryInput" [style.opacity]="showHistoryQueryInput && !relatedTableQueryString ? 0.5 : 1">
		<span class="control-title">map layer query string</span>
		<textarea
			placeholder="enter map layer query string here"
			[(ngModel)]="mapLayerQueryString"
			(ngModelChange)="onQueryStringEdited()"
			rows="3"
			cols="50"
			name="metricTitle"
			id="metricTitle"
			[disabled]="showHistoryQueryInput && !relatedTableQueryString"
		></textarea>
	</div>
	<div class="query-block" *ngIf="showWorkOrderQueryInput">
		<span class="control-title">work order query string</span>
		<textarea
			placeholder="enter work order query string here"
			[(ngModel)]="workOrderQueryString"
			(ngModelChange)="onQueryStringEdited()"
			rows="3"
			cols="50"
			name="metricTitle"
			id="metricTitle"
		></textarea>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button [class.omni-button-disabled]="disableValidateButton" (click)="disableValidateButton || onValidate()" mat-button class="omni-md-font" [matTooltip]="validateButtonTooltip">
			<img class="omni-sm-img" [src]="validateButtonIcon" />
			{{ validateButtonText }}
		</button>
		<span style="flex:1"></span>
		<button [class.omni-button-disabled]="disableSaveButton" (click)="onSave()" mat-button class="omni-md-font">
			{{ saveButtonText }}
			<img class="omni-sm-img" src="assets/save.png" />
		</button>
	</mat-card-actions>
</mat-card-footer>
