import { Feature } from './feature';
import { FeatureLayer } from './feature-layer';

export class LayerApplyEditsOperation {
	private _layer: FeatureLayer;
	get layerId(): number {
		return this._layer?.id;
	}

	private _adds: Feature[];
	get adds(): Feature[] {
		return this._adds ? this._adds : (this._adds = new Array<Feature>());
	}

	private _updates: Feature[];
	get updates(): Feature[] {
		return this._updates ? this._updates : (this._updates = new Array<Feature>());
	}

	private _deletes: number[];
	get deletes(): number[] {
		return this._deletes ? this._deletes : (this._deletes = new Array<number>());
	}

	get hasAnyEdits(): boolean {
		return !!(this.adds.length || this.updates.length || this.deletes.length);
	}

	constructor(layer: FeatureLayer, adds: Feature[] = null, updates: Feature[] = null, deletes: number[] = null) {
		this._layer = layer;
		this._adds = adds;
		this._updates = updates;
		this._deletes = deletes;
	}

	getJSON(): any {
		if (!this._layer || !this.hasAnyEdits) return null;

		const obj = {};
		obj['id'] = this.layerId;
		if (this.adds) {
			const addsArray = [];
			for (const feature of this.adds) addsArray.push(feature.getJSON());

			obj['adds'] = addsArray;
		}

		if (this.updates) {
			const updatesArray = [];
			for (const feature of this.updates) updatesArray.push(feature.getJSON());

			obj['updates'] = updatesArray;
		}

		if (this.deletes) {
			const deletesArray = [];
			for (const id of this.deletes) deletesArray.push(id);

			obj['deletes'] = deletesArray;
		}

		return obj;
	}
}
