import { DataSourceType } from './../../models/data-source-type.enum';
import { FindResult, FindResultItem } from './find-result';
import { RestServiceResponse } from './service-response';
import { FindOperation } from './find-operation';
import { FeatureLayer } from './feature-layer';
import { ConnectionClient } from './connection-client';
import { Credentials } from './credentials';
import { ResultBase } from './result-base';
import { FeatureLayers } from './feature-layers';
import { IdentifyTask } from './identify-task';
import { SpatialReference } from './spatial-reference';
import { Extent } from './extent';
import { ApplyEditsTransaction } from './apply-edits-transaction';

// Correct Class
export class Server {
	id: number;

	type: DataSourceType;

	get url(): string {
		return this._url;
	}
	private _layers: FeatureLayers;
	public get layers(): FeatureLayers {
		if (!this._layers) this._layers = new FeatureLayers();

		return this._layers;
	}

	fullExtent: Extent;

	spatialReference: SpatialReference;

	credentials: Credentials;

	proxyUrl: string;

	async queryTransaction(data: string): Promise<Array<any>> {
		const response = await ConnectionClient.current.postData(this.url + '/query', data, this.credentials, this.proxyUrl);
		return new Promise((resolve, reject) => {
			if (!response.success) {
				resolve(null);
				return;
			}

			resolve(response.responseJsonToken.layers as Array<any>);
			return;
		});
	}

	find(findOperation: FindOperation): Promise<FindResult> {
		return new Promise<FindResult>(async (resolve, reject) => {
			const result = new FindResult(new RestServiceResponse());

			if (!findOperation.isValid) {
				resolve(result);
				return;
			}

			const findUrl = `${this.url}/find?${FindOperation.getFindQueryString(findOperation)}`;
			const serviceResult = await ConnectionClient.current.getJson(findUrl, this.credentials, this.proxyUrl);

			resolve(FindResult.fromJson(serviceResult, this));
			return;
		});
	}

	identify(identifyTask: IdentifyTask): Promise<FindResult> {
		return new Promise<FindResult>(async resolve => {
			const result = new FindResult(new RestServiceResponse());
			const url = `${this.url}/identify?${identifyTask.getQueryString()}`;
			const serviceResults = await ConnectionClient.current.getJson(url, this.credentials, this.proxyUrl);
			resolve(FindResult.fromJson(serviceResults, this));
		});
	}

	constructor(private _url: string) {}

	async initialize() {
		const response = await ConnectionClient.current.getJson(this.url, this.credentials, this.proxyUrl);
		const result = new ResultBase(response);

		if (!result.success) return result;

		this.getInitialValues(result?.serviceResponseDetails?.responseJsonToken);

		await this.initializeLayers();

		return result;
	}

	private getInitialValues(response: any): void {
		if (!response) return;

		if (response.spatialReference) {
			this.spatialReference = new SpatialReference();
			this.spatialReference.wkid = response.spatialReference.wkid;
			this.spatialReference.latestWkid = response.spatialReference.latestWkid;
			this.spatialReference.wkText = response.spatialReference.wkt;
		}

		if (response.fullExtent) {
			this.fullExtent = new Extent();
			this.fullExtent.xmin = response.fullExtent.xmin;
			this.fullExtent.xmax = response.fullExtent.xmax;
			this.fullExtent.ymin = response.fullExtent.ymin;
			this.fullExtent.ymax = response.fullExtent.ymax;
			this.fullExtent.spatialReference = this.spatialReference;
		}
	}

	async initializeLayers() {
		const response = await ConnectionClient.current.getJson(this.url + '/layers', this.credentials, this.proxyUrl);
		const result = new ResultBase(response);

		if (!result.success) return result;

		for (const jLayer of result.serviceResponseDetails.responseJsonToken.layers) {
			const l = new FeatureLayer();
			l.parentServer = this;
			Object.assign(l, jLayer);
			this.layers.push(l);
		}

		for (const jLayer of result.serviceResponseDetails.responseJsonToken.tables) {
			const l = new FeatureLayer();
			l.parentServer = this;
			Object.assign(l, jLayer);
			this.layers.push(l);
		}
	}

	async applyEdits(transaction: ApplyEditsTransaction): Promise<RestServiceResponse> {
		const data = transaction?.getPostBody();
		if (!transaction || !data) return null;

		return await ConnectionClient.current.postData(this.url + '/applyEdits', data, this.credentials, this.proxyUrl);
	}
}

export interface DocumentInfo {
	Title: string;
	Author: string;
	Comments: string;
	Subject: string;
	Category: string;
	AntialiasingMode: string;
	TextAntialiasingMode: string;
	Keywords: string;
}

export interface Layer {
	id: number;
	name: string;
	parentLayerId: number;
	defaultVisibility: boolean;
	subLayerIds: number[] | null;
	minScale: number;
	maxScale: number;
	type?: Type;
	geometryType?: GeometryType;
}

export enum GeometryType {
	EsriGeometryPoint = 'esriGeometryPoint',
	EsriGeometryPolygon = 'esriGeometryPolygon',
	EsriGeometryPolyline = 'esriGeometryPolyline'
}

export enum Type {
	AnnotationLayer = 'Annotation Layer',
	DimensionLayer = 'Dimension Layer',
	FeatureLayer = 'Feature Layer'
}

export interface Table {
	id: number;
	name: string;
	type: string;
}
