import { Field } from '../sedaru-util/esri-core/field';

export class CodedValue {
	/**
	 * The name of the coded value
	 */
	name: string;

	/**
	 * The code reprsentation of the coded value
	 */
	code: string;
}

export class ArcGISFieldDomain {
	/**
	 * type of the domain
	 */
	type: string;

	/**
	 * name of the domain
	 */
	name: string;

	/**
	 * A list of coded values
	 */
	codedValues?: CodedValue[];

	/**
	 * THe range of
	 */
	range?: number[];

	initialize?(arcGISFieldDomain: ArcGISFieldDomain): void {
		this.name = arcGISFieldDomain.name;
		this.type = arcGISFieldDomain.type;
		if (arcGISFieldDomain.codedValues) {
			this.codedValues = arcGISFieldDomain.codedValues;
		}
		if (arcGISFieldDomain.range) {
			this.range = arcGISFieldDomain.range;
		}
	}
}

export class ArcGISField extends Field {
	omniName: string;
	constructor() {
		super();
		this.omniName = '';
	}
	initialize?(arcGISField: ArcGISField): void {
		this.name = arcGISField.name;
		this.type = arcGISField.type;
		this.alias = arcGISField.alias;
		this.omniName = arcGISField.omniName;
		if (arcGISField.domain) {
			this.domain = new ArcGISFieldDomain();
			this.domain.initialize(arcGISField.domain);
		}
	}

	clear?(): void {
		this.name = '';
		this.type = null;
		this.alias = '';
		this.omniName = '';
		this.domain = undefined;
	}
}
