<mat-list>
	<mat-list-item class="asset-list-item">
		Asset layers
	</mat-list-item>
</mat-list>

<ng-container *ngFor="let layer of layers">
	<app-check-box-accordion
		[headerText]="layer.title"
		[identifier]="layer.id"
		(accordionSelected)="onVisiblityLayerChange(layer)"
		[list]="layer.sublayers? layer.sublayers.toArray(): []"
		[resolver]="layerResolver"
		[selected]="layer.visible"
	></app-check-box-accordion>
</ng-container>

<mat-list>
	<mat-list-item class="asset-list-item">
		Base layers
	</mat-list-item>
</mat-list>

<ng-container *ngFor="let layer of baseMapLayers">
	<app-check-box-accordion
		[headerText]="layer.title"
		[identifier]="layer.id"
		(accordionSelected)="onVisiblityLayerChange(layer)"
		[list]="layer.sublayers? layer.sublayers.toArray(): []"
		[resolver]="layerResolver"
		[selected]="layer.visible"
	></app-check-box-accordion>
</ng-container>
