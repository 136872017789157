export class OmniCookie {
	CustomerCode: string;
	UserProfileGroup: string;

	Token: string;
	SedaruRefreshToken: string;

	PortalToken: string;
	PortalRefreshToken: string;

	public get isPortalEnabled(): boolean {
		if (this.PortalToken) return true;

		return false;
	}

	public get isValid(): boolean {
		if (!this.CustomerCode) return false;

		if (!this.PortalToken) {
			return this.Token && this.SedaruRefreshToken ? true : false;
		}

		return this.PortalToken && this.PortalRefreshToken ? true : false;
	}
}
