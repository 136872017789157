import { Task } from './task.model';
import { TaskContract } from '../../contracts/work-order';

export class Tasks extends Array<Task> {
	static fromContracts(taskContracts: TaskContract[]) {
		const tasks = new Tasks();
		if (!taskContracts) return tasks;

		for (const taskContract of taskContracts) {
			if (!taskContract.Name || !taskContract.Description) continue;
			const task = Task.fromContract(taskContract);
			tasks.push(task);
		}
		return tasks;
	}
	getByTaskId = (taskId: string) => this.find(task => task.taskId === taskId);

	getTasksByWorkTypeId = (woWorkTypeId: string) => this.filter(task => task.woTemplateId === woWorkTypeId);
}
