import { Component, ElementRef } from '@angular/core';
import { MetricService } from '../../../../../domain-service/metric.service';
import { OmniValueListService } from 'app/omni-value-list/omni-value-list.service';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { DisplayValueSettings, ArcGISField, TypeOfValueEnum, ValueFormatEnum, Metric, ChannelTypes, ArcGISAssetChannelAttributes, ArcGISHistoryChannelAttributes } from '../../../../../models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { FieldType } from 'sedaru-util/esri-core';

/**
 * This component is designed to be loaded when the user selects the default tile settings field from
 * the add or edit metric side panel.
 */
@Component({
	selector: 'app-display-value-settings',
	templateUrl: './display-value-settings.component.html',
	styleUrls: ['./display-value-settings.component.scss']
})
export class DisplayValueSettingsComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the model object for the default tile settings of a metric.
	 */
	settings: DisplayValueSettings;

	metric: Metric;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {MetricService} metricService - Provides all the services required to manipulate a metric
	 * @param {OmniValueListService} listService - Provides services to create OMNI's generic list.
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(
		private metricService: MetricService,
		private listService: OmniValueListService,
		private flashMessageService: FlashMessageService,
		private interopService: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	/**
	 * On init, the currently selected metric and the default settings of the metric is initialized.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'display value settings' });
		this.settings = this.metric.definition.displayValueSettings;
	}

	/**
	 * This method is invoked when the type of value field in the tile display settings side panel is clicked.
	 * It will load a list of values in a new side panel.
	 */
	onGotoTypeOfValue(): void {
		this.listService.list = [];
		const typeOfValueList = this.metricService.getTileSettingsTypeOfValueList(this.metric);
		for (const typeOfValue of typeOfValueList) {
			this.listService.list.push({
				name: typeOfValue.name,
				callBackFunction: () => {
					this.metric.definition.displayValueSettings.typeOfValue = {
						name: typeOfValue.name,
						enumValue: typeOfValue.enumValue
					};
					this.metric.definition.displayValueSettings.valueTypeField = new ArcGISField();
					if (typeOfValue.enumValue == TypeOfValueEnum.recordCount || typeOfValue.enumValue == TypeOfValueEnum.percetangeOfTotal) {
						this.metric.definition.displayValueSettings.valueFormat = {
							name: 'none',
							enumValue: ValueFormatEnum.none
						};
					}
				},
				shouldGoBack: true
			});
		}
		this.listService.selectedItem = this.metric.definition.displayValueSettings.typeOfValue;
		this.listService.header = 'select type of value';
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.typeOfValue.name }, selectedItemKey: 'name' });
	}

	/**
	 * The method is called when the 'value type field' in the 'display value settings' is clicked.
	 * The user is then navigated to omni list panel populated with the list of arcGis fields.
	 */
	onGotoValueTypeField(): void {
		if (!this.settings.typeOfValue.enumValue) {
			return this.flashMessageService.popMessage('Please select type of value to display');
		}
		const isCountOrPercentageOfTotal =
			this.settings.typeOfValue.enumValue === Number(TypeOfValueEnum.recordCount) || this.settings.typeOfValue.enumValue === Number(TypeOfValueEnum.percetangeOfTotal);

		if (isCountOrPercentageOfTotal) {
			return this.flashMessageService.popMessage("Value type field doesn't apply to record count and percentage of total");
		}

		let fields = this.interopService.arcGISManager.getArcGisFields(this.metric);

		this.listService.list = [];
		const isSumOfAFieldOrAverageValue = this.settings.typeOfValue.enumValue === Number(TypeOfValueEnum.sumOfAField) || this.settings.typeOfValue.enumValue === Number(TypeOfValueEnum.averageValue);

		if (isSumOfAFieldOrAverageValue) {
			const { esriFieldTypeDouble, esriFieldTypeInteger, esriFieldTypeSmallInteger } = FieldType;
			fields = fields.filter(valueTypeField => valueTypeField.type === esriFieldTypeDouble || valueTypeField.type === esriFieldTypeInteger || valueTypeField.type === esriFieldTypeSmallInteger);
		}

		for (const valueTypeField of fields) {
			this.listService.list.push({
				name: valueTypeField.omniName,
				callBackFunction: () => {
					this.metric.definition.displayValueSettings.valueTypeField = valueTypeField;
					this.metric.definition.displayValueSettings.valueFormat = {
						name: 'none',
						enumValue: ValueFormatEnum.none
					};
				},
				shouldGoBack: true
			});
		}
		this.listService.selectedItem = this.metric.definition.displayValueSettings.valueTypeField.omniName;
		this.listService.header = 'select value type field';
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.valueTypeField.omniName }, selectedItemKey: 'name' });
	}

	/**
	 * This method is invoked when the 'value format' in the tile display settings side panel is clicked.
	 * It will then naviagte to default tile settings's value list panel and populate with a list of value format choices
	 */
	onGotoValueFormat(): void {
		if (!this.settings.valueTypeField.name) {
			return this.flashMessageService.popMessage('Please select a value type field');
		}

		const { esriFieldTypeOID, esriFieldTypeString, esriFieldTypeDate } = FieldType;

		const isNotNumberType =
			this.settings.valueTypeField.type === esriFieldTypeOID || this.settings.valueTypeField.type === esriFieldTypeString || this.settings.valueTypeField.type === esriFieldTypeDate;

		if (isNotNumberType) {
			return this.flashMessageService.popMessage("Can't format value of a non number type");
		}

		this.listService.list = [];
		const valueFormatList = this.metricService.getValueFormatValueList();
		for (const valueFormat of valueFormatList) {
			this.listService.list.push({
				name: valueFormat.name,
				callBackFunction: () => {
					this.metric.definition.displayValueSettings.valueFormat = {
						name: valueFormat.name,
						enumValue: valueFormat.enumValue
					};
				},
				shouldGoBack: true
			});
		}
		this.listService.header = 'select value format';
		this.listService.selectedItem = this.metric.definition.displayValueSettings.valueFormat;
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.valueFormat.name }, selectedItemKey: 'name' });
	}
}
