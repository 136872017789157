import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import { Injectable, ErrorHandler, Inject, InjectionToken } from '@angular/core';
import { IAnalyticsChannel, AnalyticsError, AnalyticsEvent } from 'sedaru-util/analytics-hub';
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

export class RollbarAnalyticsChannel implements IAnalyticsChannel {
	private static _rollbarInstance: Rollbar;
	static get rollbarInstance(): Rollbar {
		const options = {
			accessToken: '9c26c34257414c04872b11707df29479',
			captureUncaught: true,
			captureUnhandledRejections: true,
			payload: {
				environment: 'development'
			}
		};

		if (!RollbarAnalyticsChannel._rollbarInstance) {
			RollbarAnalyticsChannel._rollbarInstance = new Rollbar(options);
		}

		return RollbarAnalyticsChannel._rollbarInstance;
	}

	isEnabled = true;

	private _userIdentifier: string;
	get userIdentifier(): string {
		return this._userIdentifier;
	}
	set userIdentifier(value: string) {
		this._userIdentifier = value;
		RollbarAnalyticsChannel.rollbarInstance.configure({
			payload: {
				person: {
					id: value
				}
			}
		});
	}
	uniqueIdentifier: string;

	constructor() {}
	trackEvent(analyticsEvent: AnalyticsEvent) {
		RollbarAnalyticsChannel.rollbarInstance.info(analyticsEvent.name, {
			description: analyticsEvent.description,
			payload: analyticsEvent.payload
		});
	}
	trackError(analyticsError: AnalyticsError) {
		RollbarAnalyticsChannel.rollbarInstance.error(analyticsError.exception, analyticsError.payload);
	}
}

export class RollbarErrorHandler implements ErrorHandler {
	constructor() {}
	static isEnabled = false;

	handleError(err: any): void {
		if (!RollbarErrorHandler.isEnabled) return;
		RollbarAnalyticsChannel.rollbarInstance.error(err.originalError || err);
	}
}
