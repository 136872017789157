// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestServiceResponse, ResponseServiceErrorCode, RestServiceResponseError } from './service-response';
import { Credentials, CredentialsType } from './credentials';
import { TokenManager } from './token-manager';
import { ArcGISOptions } from './arc-gis-options';
import * as Sedaru from '../../sedaru-util';

export class UrlInfo {
	constructor(public url: string) {}
}

export class AuthenticationException {
	response;
}

export class ConnectionClient {
	private static _current: ConnectionClient;
	// public static httpClient: HttpClient;
	private responseFormat = 'json';

	constructor() {}

	private async runRetryAsync(functionMethod: () => Promise<RestServiceResponse>, url: string, credentials?: Credentials): Promise<RestServiceResponse> {
		let retryCount = 0;

		let result: RestServiceResponse = null;
		do {
			retryCount++;

			try {
				result = await functionMethod();
			} catch (ex) {
				if (ex instanceof AuthenticationException) return (ex as AuthenticationException).response;
			}

			if (result.isTokenExpired && credentials) {
				credentials.isAuthenticated = false;
				await TokenManager.current.authenticateCredentials(url, credentials);
			}
		} while (result && result.isTokenExpired && retryCount < ArcGISOptions.maxRetryCountExpiredToken); // Only retry two times if token expired

		return result;
	}

	private getServiceResponse(responseMessage: any): RestServiceResponse {
		const response = new RestServiceResponse();

		response.httpCode = responseMessage.status;
		if (response.httpCode < 200 || response.httpCode > 299) response.errorCode = ResponseServiceErrorCode.HttpError;

		return response;
	}
	private async getJsonServiceResponse(responseMessage: any): Promise<RestServiceResponse> {
		const response = this.getServiceResponse(responseMessage);

		if (response.errorCode === ResponseServiceErrorCode.HttpError) return response;

		// response.responseString = ;

		const jResponse = responseMessage; // JSON.parse(response.responseString);
		const errorResponse = jResponse?.error || jResponse?.data?.error;

		if (errorResponse) {
			response.errorCode = ResponseServiceErrorCode.ArcGISError;
			response.arcGISError = RestServiceResponseError.fromJson(errorResponse);
		} else response.errorCode = ResponseServiceErrorCode.Success;

		response.responseJsonToken = jResponse.data;

		return response;
	}

	private handleException(exception: any, response: RestServiceResponse) {
		response.serviceException = exception;
		response.errorCode = ResponseServiceErrorCode.Unknown;
		/*if (exception is JsonReaderException)
				response.ErrorCode = ResponseServiceErrorCode.InvalidResponse;
			else if (exception is InvalidOperationException)
				response.ErrorCode = ResponseServiceErrorCode.InvalidUrl;
			else if (exception is System.Net.Http.HttpRequestException)
				response.ErrorCode = ResponseServiceErrorCode.CannotReachServer;
			else if (exception is TimeoutException)
				response.ErrorCode = ResponseServiceErrorCode.Timeout;
			else
				response.ErrorCode = ResponseServiceErrorCode.Unknown;*/
	}

	getJson(url: string, credentials?: Credentials, proxyUrl?: string): Promise<RestServiceResponse> {
		return this.runRetryAsync(
			() => {
				return new Promise<RestServiceResponse>(async (resolve, reject) => {
					let response = new RestServiceResponse();

					try {
						const urlInfo = await this.getFullUrl(url, credentials, proxyUrl);
						const responseMessage = await Sedaru.Http.HttpCommon.get(urlInfo.url); // ConnectionClient.httpClient.get(urlInfo.url).toPromise();
						response = await this.getJsonServiceResponse(responseMessage);
					} catch (e) {
						this.handleException(e, response);
					}

					resolve(response);
				});
			},
			url,
			credentials
		);
	}

	public async postData(url: string, data: string, credentials?: Credentials, proxyUrl = ''): Promise<RestServiceResponse> {
		return new Promise<RestServiceResponse>(async resolve => {
			if (!data) {
				data = `f=${this.responseFormat}`;
			} else {
				data = `${data}&f=${this.responseFormat}`;
			}

			const urlInfo = await this.getFullUrl(url, credentials, proxyUrl);
			const fullUrl = urlInfo.url;

			const headers = {
				'Content-Type': 'application/x-www-form-urlencoded'
			};
			// const angularHeaders = new HttpHeaders();
			// const options = { headers: angularHeaders };

			// const promise = ConnectionClient.httpClient.post(fullUrl, data, options).toPromise();
			const promise = Sedaru.Http.HttpCommon.post(fullUrl, data, headers); /// ConnectionClient.httpClient.post(fullUrl, data, options).toPromise();

			promise
				.then(async response => {
					const result = await this.getJsonServiceResponse(response);
					resolve(result);
				})
				.catch(e => {
					const result = new RestServiceResponse();
					result.customError = e;
					resolve(result);
				});
		});
	}

	private async getFullUrl(url: string, credentials?: Credentials, proxyUrl?: string): Promise<UrlInfo> {
		return new Promise<UrlInfo>(async (resolve, reject) => {
			let fullUrl = '';

			if (url.includes('?')) {
				fullUrl = `${url}&f=${this.responseFormat}`;
			} else {
				fullUrl = `${url}?f=${this.responseFormat}`;
			}

			if (proxyUrl) fullUrl = `${proxyUrl}?${fullUrl}`;

			if (!credentials) {
				resolve(new UrlInfo(fullUrl));
				return;
			}

			if (credentials.type === CredentialsType.Token) {
				resolve(new UrlInfo(`${fullUrl}&token=${credentials.token}`));
				return;
			}

			if (!credentials.isAuthenticated) {
				await TokenManager.current.authenticateCredentials(url, credentials);
			}

			resolve(new UrlInfo(`${fullUrl}&token=${credentials.token}`));
		});
	}

	static get current(): ConnectionClient {
		if (!ConnectionClient._current) ConnectionClient._current = new ConnectionClient();

		return ConnectionClient._current;
	}
}
