import * as SedaruModels from '../../index';
import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';

export class CredentialsTransform extends TransformBase {
	onTransform(): TransformationResult {
		if (!this.fieldforceConfig.Credentials || this.fieldforceConfig.Credentials.length === 0) {
			return this.getResult(true, 'no credentials found');
		}

		for (const jCredential of this.fieldforceConfig.Credentials) {
			const credential = new SedaruModels.Credentials(this.globalConfig);
			credential.legacyId = jCredential.ID;
			credential.name = jCredential.Name;
			credential.username = jCredential.Username;
			credential.password = jCredential.Password;
			credential.token = jCredential.Token;
			credential.type = this.getCredentialsType(jCredential.Type);
			this.globalConfig.credentials.push(credential);
		}

		return this.getResult(true);
	}

	getCredentialsType(credentialsType: string): SedaruModels.CredentialsType {
		if (credentialsType.toLowerCase() === 'password') {
			return SedaruModels.CredentialsType.Password;
		} else if (credentialsType.toLowerCase() === 'arcgisportalssotoken') {
			return SedaruModels.CredentialsType.ArcGISPortalSSOToken;
		} else if (credentialsType.toLowerCase() === 'arcgis') {
			return SedaruModels.CredentialsType.Arcgis;
		} else if (credentialsType.toLowerCase() === 'token') {
			return SedaruModels.CredentialsType.Arcgis;
		}
	}
}
