export enum TrendStyleIdentifier {
	none = 0,
	line = 1,
	bar = 2,
	area = 3,
	pie = 4
}

/**
 * The model for the trend style property.
 */
export class TrendStyle {
	/**
	 * Unique identiifier for the trend style.
	 */
	identifier: TrendStyleIdentifier;

	/**
	 * The label for the trend style which will be displayed in the UI.
	 */
	label: string;

	constructor() {
		this.identifier = TrendStyleIdentifier.none;
		this.label = '';
	}
}
