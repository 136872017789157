import { ContractBase } from '../contract-base';

export class AssetSpecContract extends ContractBase {
    public ObjectId: number;
    public AssetId: string;
    public SpecId: string;
    public Description: string;
    public Value: string;
    public IsRequired: number;
    public ChangeBy: string;
    public ChangeDate: string;
    public DataType: string;
    public CodeType: string;
}
