import { TimeframeDefinitionContract } from './timeframe-definition-contract';
import { TimeframeDefinitionType } from './timeframe-definition-type.enum';
import { TimeframeUnit } from './timeframe-unit.enum';
import { TimeframeRangeType as TimeframeBoundlessType, TimeframeRangeType } from './timeframe-range-type.enum';
import { Timeframe } from './timeframe.model';
import { TimeframeType } from './timeframe-type.enum';

export class TimeframeDefinition {
	private _parentTimeFrame: Timeframe;

	id: string;
	isNew: boolean;
	endDate: Date;
	constructor(
		private _type: TimeframeDefinitionType,
		private _unit?: TimeframeUnit,
		private _interval?: number,
		private _inclusive?: boolean,
		private _date?: Date,
		private _boundlessType?: TimeframeBoundlessType
	) {
		if (!this._interval) this._interval = 0;

		if (this._inclusive === undefined) this._inclusive = true;
		this.isNew = true;
	}
	get type(): TimeframeDefinitionType {
		return this._type;
	}
	get unit(): TimeframeUnit {
		return this._unit;
	}
	get interval(): number {
		return this._interval;
	}
	get isInclusive(): boolean {
		return this._inclusive;
	}
	get date(): Date {
		return this._date;
	}
	get boundlessType(): TimeframeBoundlessType {
		if (this.type !== TimeframeDefinitionType.Boundless) {
			return TimeframeBoundlessType.Range;
		}

		if (!this._boundlessType) return TimeframeBoundlessType.All;

		return this._boundlessType;
	}

	get boundlessTypeString(): string {
		switch (this.boundlessType) {
			case TimeframeRangeType.ValidDates:
				return 'validdates';
			case TimeframeRangeType.NoDate:
				return 'nodate';
			case TimeframeRangeType.All:
				return 'all';
			case TimeframeRangeType.Range:
			default:
				return 'range';
		}
	}

	get text() {
		return this.getText(this.type, this.unit, this.interval);
	}

	static newAllDatesDefinition(): TimeframeDefinition {
		return new TimeframeDefinition(TimeframeDefinitionType.Boundless, undefined, undefined, undefined, undefined, TimeframeRangeType.All);
	}

	static updateParentTimeFrame(timeFrameDefinition: TimeframeDefinition, newParent: Timeframe) {
		timeFrameDefinition._parentTimeFrame = newParent;
	}

    static fromContract(contract: TimeframeDefinitionContract): TimeframeDefinition {
        const timeframeDefinitionModel = new TimeframeDefinition(
            contract.type,
            (typeof(contract.unit) === 'undefined' || contract.unit == null) ? TimeframeUnit.None : contract.unit,
            contract.interval,
            contract.isInclusive,
            new Date(contract.date),
            contract.boundlessType
        );

		timeframeDefinitionModel.isNew = false;

		return timeframeDefinitionModel;
	}

	static getUnitText(unit: TimeframeUnit): string {
        switch (unit) {
            case TimeframeUnit.Hours:
                return 'hour';
            case TimeframeUnit.Days:
                return 'day';
            case TimeframeUnit.Weeks:
                return 'week';
            case TimeframeUnit.Months:
                return 'month';
            case TimeframeUnit.Quarters:
                return 'quarter';
            case TimeframeUnit.Years:
                return 'year';
            default:
                return '';
        }
    }

	getTimeframeTypes(): TimeframeType {
		switch (this.type) {
			case TimeframeDefinitionType.LaterThanToday:
			case TimeframeDefinitionType.EarlierThanToday:
			case TimeframeDefinitionType.TodayAndEarlier:
			case TimeframeDefinitionType.Boundless:
				return TimeframeType.Simple;
			case TimeframeDefinitionType.This:
			case TimeframeDefinitionType.RestOfX:
			case TimeframeDefinitionType.XToNow:
				return TimeframeType.Period;
			case TimeframeDefinitionType.NextX:
			case TimeframeDefinitionType.LastX:
				return TimeframeType.PeriodInterval;
			case TimeframeDefinitionType.ExactDate:
			case TimeframeDefinitionType.BeforeDate:
			case TimeframeDefinitionType.AfterDate:
				return TimeframeType.SingleDate;
			case TimeframeDefinitionType.DateRange:
				return TimeframeType.Range;
			default:
				return  TimeframeType.Simple;
		}
	}

	getContract = (): TimeframeDefinitionContract => {
		const timeframeContract = new TimeframeDefinitionContract();
		timeframeContract.type = this.type;
		timeframeContract.unit = typeof this.unit === 'undefined' || this.unit == null ? TimeframeUnit.None : this.unit;
		timeframeContract.interval = this.interval;
		timeframeContract.isInclusive = this.isInclusive;
		timeframeContract.date =  this.isDefinitionTypeDate() ? String(this.date) : '';
		timeframeContract.boundlessType = this.boundlessType;

		return timeframeContract;
	};

	public update(definitionSource: TimeframeDefinition) {
		this.id = definitionSource.id;
		this._date = definitionSource.date;
		this._interval = definitionSource.interval;
		this._type = definitionSource.type;
		this._unit = definitionSource.unit;
		this._inclusive = definitionSource.isInclusive;
		this._boundlessType = definitionSource.boundlessType;
		Timeframe.updateDates(this._parentTimeFrame);
	}

    private getText(definitionType: TimeframeDefinitionType, unit: TimeframeUnit, interval: number): string {
		const moment = require('moment');
		const defaultDateFormat = 'M/D/YYYY';
        switch (definitionType) {
            case TimeframeDefinitionType.This:
                return this.getThisText(unit);
            case TimeframeDefinitionType.NextX:
                return this.getNextText(unit, interval);
            case TimeframeDefinitionType.LastX:
                return this.getLastText(unit, interval);
            case TimeframeDefinitionType.RestOfX:
                return this.getRestOfText(unit);
            case TimeframeDefinitionType.XToNow:
                return this.getToNowText(unit);
            case TimeframeDefinitionType.LaterThanToday:
                return this.isInclusive ? 'today & later' : 'Later than today';
            case TimeframeDefinitionType.TodayAndEarlier:
                return 'today & earlier';
            case TimeframeDefinitionType.EarlierThanToday:
                return 'Earlier than today';
            case TimeframeDefinitionType.ExactDate:
                return `On ${moment(this.date).format(defaultDateFormat)}`;
            case TimeframeDefinitionType.BeforeDate:
                return `Before ${moment(this.date).format(defaultDateFormat)}`;
            case TimeframeDefinitionType.AfterDate:
                return `After ${moment(this.date).format(defaultDateFormat)}`;
            case TimeframeDefinitionType.DateRange:
				if (this.endDate) {
					return 'On ' + moment(this.date).format(defaultDateFormat) + ' - ' + moment(this.endDate).format(defaultDateFormat);
				} else {
					return 'On ' + moment(this.date).format(defaultDateFormat) + ' - ' + moment(this.getEndDate()).format(defaultDateFormat);
				}
            case TimeframeDefinitionType.Boundless:
				if (this.boundlessType === TimeframeBoundlessType.All) {
					return 'All Time';
				}
				if (this.boundlessType === TimeframeBoundlessType.ValidDates) {
					return 'All Valid Dates';
				}
				if (this.boundlessType === TimeframeBoundlessType.NoDate) {
					return 'Unspecified Dates';
				}
				break;
		}
	}

    private getThisText(unit: TimeframeUnit): string {
        switch (unit) {
            case TimeframeUnit.Hours:
                return 'This Hour';
            case TimeframeUnit.Days:
                return 'All of Today';
            case TimeframeUnit.Weeks:
                return 'This Entire Week';
            case TimeframeUnit.Months:
                return 'This Entire Month';
            case TimeframeUnit.Quarters:
                return 'This Entire Quarter';
            case TimeframeUnit.Years:
                return 'This Entire Year';
            default:
                return '';
        }
    }

	private getNextText(unit: TimeframeUnit, interval: number): string {
		switch (unit) {
			case TimeframeUnit.Hours:
				if (interval <= 1) {
					return 'Next Hour';
				}

				return `Next ${interval} Hours`;
			case TimeframeUnit.Days:
				if (interval <= 1) {
					return 'Tomorrow';
				}

				return `Next ${interval} Days`;
			case TimeframeUnit.Weeks:
				if (interval <= 1) {
					return 'Next Week';
				}

				return `Next ${interval} Weeks`;
			case TimeframeUnit.Months:
				if (interval <= 1) {
					return 'Next Month';
				}

				return `Next ${interval} Months`;
			case TimeframeUnit.Quarters:
				if (interval <= 1) {
					return 'Next Quarter';
				}

				return `Next ${interval} Quarters`;
			case TimeframeUnit.Years:
				if (interval <= 1) {
					return 'Next Year';
				}

				return `Next ${interval} Years`;
		}
	}

	private getLastText(unit: TimeframeUnit, interval: number): string {
		switch (unit) {
			case TimeframeUnit.Hours:
				if (interval <= 1) {
					return 'Last Hour';
				}

				return `Last ${interval} Hours`;
			case TimeframeUnit.Days:
				if (interval <= 1) {
					return 'Yesterday';
				}

				return `Last ${interval} Days`;
			case TimeframeUnit.Weeks:
				if (interval <= 1) {
					return 'Last Week';
				}

				return `Last ${interval} Weeks`;
			case TimeframeUnit.Months:
				if (interval <= 1) {
					return 'Last Month';
				}

				return `Last ${interval} Months`;
			case TimeframeUnit.Quarters:
				if (interval <= 1) {
					return 'Last Quarter';
				}

				return `Last ${interval} Quarters`;
			case TimeframeUnit.Years:
				if (interval <= 1) {
					return 'Last Year';
				}

				return `Last ${interval} Years`;
		}
	}

	private getRestOfText(unit: TimeframeUnit): string {
		switch (unit) {
			case TimeframeUnit.Hours:
				return 'Rest of this Hour';
			case TimeframeUnit.Days:
				return 'Rest of Today';
			case TimeframeUnit.Weeks:
				return 'Rest of this Week';
			case TimeframeUnit.Months:
				return 'Rest of this Month';
			case TimeframeUnit.Quarters:
				return 'Rest of this Quarter';
			case TimeframeUnit.Years:
				return 'Rest of this Year';
			default:
				return '';
		}
	}

    private getToNowText(unit: TimeframeUnit): string {
        switch (unit) {
            case TimeframeUnit.Hours:
                return 'This Hour to now';
            case TimeframeUnit.Days:
                return 'Today to now';
            case TimeframeUnit.Weeks:
                return 'Week to date';
            case TimeframeUnit.Months:
                return 'Month to date';
            case TimeframeUnit.Quarters:
                return 'Quarter to date';
            case TimeframeUnit.Years:
                return 'Year to date';
            default:
                return '';
        }
    }

	isEqualsTo(timeframeDefinition: TimeframeDefinition) {
		if (!timeframeDefinition) {
			return false;
		}

		if (this.boundlessType !== timeframeDefinition.boundlessType) return false;
		if (this.date !== timeframeDefinition.date) return false;
		if (this.interval !== timeframeDefinition.interval) return false;
		if (this.isInclusive !== timeframeDefinition.isInclusive) return false;
		if (this.type !== timeframeDefinition.type) return false;
		if (this.unit !== timeframeDefinition.unit) return false;

        if (this.boundlessType !== timeframeDefinition.boundlessType) return false;
        const dateTime = this.date?.getTime();
        if (dateTime && !isNaN(dateTime) && dateTime !== timeframeDefinition.date?.getTime()) return false;
        if (this.interval !== timeframeDefinition.interval) return false;
        if (this.isInclusive !== timeframeDefinition.isInclusive) return false;
        if (this.type !== timeframeDefinition.type) return false;
        if (this.unit !== timeframeDefinition.unit) return false;

        return true;
    }

	isDefinitionTypeDate() {
		return (this.type == TimeframeDefinitionType.AfterDate ||
			this.type == TimeframeDefinitionType.BeforeDate ||
			this.type == TimeframeDefinitionType.ExactDate ||
			this.type == TimeframeDefinitionType.DateRange);
	}

	getEndDate() {
		const start: Date = new Date(this.date);
		const end: Date = new Date(this.date);
		end.setDate(start.getDate() + (this.interval - 1));
		this.endDate = end;

		return this.endDate;
	}
}
