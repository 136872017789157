import * as SedaruModels from '../../index';
import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import { GlobalConfig } from '../../../models/global-config.model';

export class ProxiesTransform extends TransformBase {
	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig) {
		super(fieldforceConfig, globalConfiguration);
	}

	onTransform(): TransformationResult {
		if (!this.fieldforceConfig.Proxies || this.fieldforceConfig.Proxies.length === 0) {
			return this.getResult(true, 'no proxies declared');
		}

		for (const jProxy of this.fieldforceConfig.Proxies) {
			const proxy = new SedaruModels.Proxy(this.globalConfig);
			proxy.legacyId = jProxy.ID;
			proxy.credentialsLegacyId = jProxy.CredentialsID;
			proxy.url = jProxy.Url;
			if (proxy.credentialsLegacyId > 0) {
				proxy.credentials = this.globalConfig.credentials.getByLegacyId(proxy.credentialsLegacyId);
			}
			this.globalConfig.proxies.push(proxy);
		}

		return this.getResult(true);
	}
}
