import { Resource } from './resource.model';

export class Resources extends Array<Resource> {
	getById = (id: number): Resource => {
		for (const r of this) {
			if (r.id !== id) {
				continue;
			}

			return r;
		}

		return null;
	};

	getUrlById = (id: number): string => {
		const resource = this.getById(id);
		if (!resource) return '';

		return resource.url;
	};
}
