import { Component, Input, OnInit, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CheckBoxListResolver } from 'app/ui-components/check-box-accordion/check-box-list.resolver';
import { Canvas } from 'omni-model/canvas.model';

@Component({
	selector: 'app-layer-visibility',
	templateUrl: './layer-visibility.component.html',
	styleUrls: ['./layer-visibility.component.scss']
})
export class LayerVisibilityComponent implements OnInit, OnChanges {
	@Input() selectedCanvas: Canvas;

	@Output() visiblityLayerChange = new EventEmitter();

	@Output() visiblitySubLayerChange = new EventEmitter();
	/**
	 * Holds an array of map sublayers available.
	 */
	layers: Array<__esri.MapImageLayer>;

	baseMapLayers = [];

	layerResolver: CheckBoxListResolver;

	ngOnInit() {
		this.layers = this.getImageLayers().sort((a, b) => a.title.localeCompare(b.title));
		this.layers.forEach(l => this.sortSubLayers(l));

		this.layerResolver = new CheckBoxListResolver();
		this.layerResolver.getLabel = item => {
			if (!item) return;
			return item.title;
		};

		this.layerResolver.getSelected = item => {
			if (!item) return;
			return item.visible;
		};

		this.layerResolver.onItemSelected = item => {
			if (!item) return;
			item.visible = !item.visible;
			const allSublayers = this.layers.reduce((arr: __esri.Sublayer[], layer) => {
				return arr.concat(...layer.sublayers.toArray());
			}, []);
			this.selectedCanvas.map.visibleLayers = allSublayers.filter(sl => sl.visible);
			this.visiblitySubLayerChange.emit();
		};

		this.baseMapLayers = this.getBaseLayers().sort((a, b) => a.title.localeCompare(b.title));
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if (changes.selectedCanvas) {
			this.ngOnInit();
		}
	}

	/**
	 * onChange event handler while toggeling parent layer checkbox
	 * @param layerId - parent layer id
	 */
	onVisiblityLayerChange(layer: any) {
		layer.visible = !layer.visible;
		this.visiblityLayerChange.emit();
	}

	sortSubLayers(layer: __esri.MapImageLayer) {
		return layer.sublayers?.sort((a, b) => a.title.localeCompare(b.title));
	}

	private getImageLayers(): Array<__esri.MapImageLayer> {
		const filteredArray = this.selectedCanvas.mapView.map.layers.filter(l => {
			return l.type.includes('map-image') && !(l as any).isBaseLayer;
		});

		return filteredArray.toArray() as Array<__esri.MapImageLayer>;
	}

	private getBaseLayers(): Array<__esri.MapImageLayer> {
		const filteredArray = this.selectedCanvas.mapView.map.layers.filter(l => l.type.includes('map-image') && (l as any).isBaseLayer);
		return filteredArray.toArray() as Array<__esri.MapImageLayer>;
	}
}
