import { EmployeeCostContract, StandardEmployeeCostContract } from '../../contracts/work-order';
import { EmployeeCostWrapper } from './employee-cost-wrapper.model';
import { EmployeeCost } from './employee-cost.model';
import { StandardEmployeeCost } from './standard-employee-cost.model';

export class EmployeeCostWrappers extends Array<EmployeeCostWrapper> {
    static fromContracts(contracts: EmployeeCostContract[] | StandardEmployeeCostContract[]): EmployeeCostWrappers {
		let model: EmployeeCost | StandardEmployeeCost;
		return EmployeeCostWrappers.fromModels(
			contracts.map((c: EmployeeCostContract | StandardEmployeeCostContract)  => {
				if (c instanceof EmployeeCostContract) model = EmployeeCost.fromContract(c);
				else if (c instanceof StandardEmployeeCostContract) model = StandardEmployeeCost.fromContract(c);
				return model;
			})
		);
    }

    static fromModels(models: (EmployeeCost | StandardEmployeeCost)[]): EmployeeCostWrappers {
        const wrappersList = new EmployeeCostWrappers();
        for (const model of models) wrappersList.push(new EmployeeCostWrapper(model));
        return wrappersList;
    }

    getContracts = (): EmployeeCostContract[] | StandardEmployeeCostContract[] => this.map(ec => ec.getContract());

    getModels = (): (EmployeeCost | StandardEmployeeCost)[] => this.map(ec => ec.employeeCost);

    isDirty = (): boolean => !!this.filter(ec => ec.isDirty).length;

    clearDirty = (): void => this.forEach(ec => ec.clearDirty());
}
