import { WorkCommentWrapper, WorkCommentWrappers, WorkOrderWrapper } from './../../../models/work-order';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { LeftIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-workorder-comment-list',
	templateUrl: './workorder-comment-list.component.html',
	styleUrls: ['./workorder-comment-list.component.scss']
})
export class WorkorderCommentListComponent extends MenuPanelBaseComponent implements OnInit {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	workComments: WorkCommentWrappers;

	workOrder: WorkOrderWrapper;

	private resolvers = new Map();

	public get pageIdentifier(): Pages {
		return Pages.workOrderCommentList;
	}

	constructor(private workOrderFactory: WorkOrderFactory, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.workOrder = args.parameter.workOrder;
		this.workComments = this.workOrder.workComments;
		this.workComments.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

		this.menuPanelComponent.updateView({
			title: this.workOrder.workOrderKey,
			rightIcon: { url: 'assets/plus.png', toolTip: 'add a task', callBackFunction: this.addComment },
			backgroundClass: 'orange-background'
		});

		this.listComponent.getResolver = item => {
			if (!this.resolvers.has(item.id)) this.resolvers.set(item.id, this.getListResolver());
			const resolver = this.resolvers.get(item.id);
			return resolver;
		};
	}

	private getListResolver() {
		const listResolver = LeftIconTwoLinesComponent.createResolver();
		listResolver.getIconPath = (item: WorkCommentWrapper) => new Promise(solve => solve('assets/me.png')); // This is temporary
		listResolver.getTopLabel = (workComment: WorkCommentWrapper) => workComment.description;
		listResolver.getBottomLeftLabel = (workComment: WorkCommentWrapper) => {
			const employeeFound = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(workComment.createdBy);
			return employeeFound ? employeeFound.name : '';
		};
		listResolver.getBottomRightLabel = (workComment: WorkCommentWrapper) => {
			const createdDate = workComment.createdDate ? DateUtil.convertLocaleDateString(workComment.createdDate) : '';
			if (createdDate === DateUtil.now.toLocaleDateString()) return DateUtil.convertLocaleTimeString(workComment.createdDate);

			return createdDate;
		};
		return listResolver;
	}

	addComment = () => {
		NavigationService.navigateTo(Pages.workOrderComment, { workOrder: this.workOrder });
	};
}
