import { WorkOrderChannelAttributes } from './../../../models/work-order-channel-attributes.model';
import { UserService } from './../../user/user.service';
import { WorkOrderTemplate } from './../../../models/work-order/work-order-template.model';
import { WorkOrderFieldProvider } from './../workorder-filter/workorder-field.provider';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { WorkOrderFieldNames, Employee, Status, Priority, WorkOrderSummary, CustomFieldWrappers } from 'models/work-order';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { WorkOrderFilterDatePickerComponent } from '../workorder-filter/workorder-filter-date-picker/workorder-filter-date-picker.component';
import { WorkOrderFieldComponent } from '../work-order-field/workorder-field.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ArcGISWorkOrderChannelAttributes, Color, Input, CustomFieldInput, FieldConfigInput, Metric, WorkOrderField, WorkOrderSystem, FormDefinition, ListItem } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { WorkOrderTeam } from 'models/work-order/work-order-team.model';
import { Subscription } from 'rxjs';
import { CalendarWidgetService } from 'app/ui-components/calendar-widget/calendar-widget.service';
import * as moment from 'moment';
import { CalendarTypes } from 'app/ui-components/calendar-widget/calendar-types.enum';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuPanelComponent } from 'app/menu-panel/menu-panel.component';
import { JobState } from 'domain-service/jobs/job-base';
import { PageGroup, PageGroupName } from 'app/navigation/page-group';
import { HMSRefreshOperation } from 'domain-service/metrics/hms-refresh-operation.enum';
import { CustomFieldWrapper } from 'models/work-order/custom-field-wrapper.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { RecurrenceTemplate } from 'models/recurrence';
import { RecurrenceProvider } from 'providers/recurrence.provider';
import { ContractResponseBase } from 'contracts/work-order';
import { Role } from 'models/role.enum';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { Message, AlertButton } from '../../ui-components/alert-dialog/alert-dialog.component';
import { WorkOrderResult } from 'providers/work-order-provider-base';
import { SummaryFieldInputType } from 'models/work-order/standard-custom-field.model';

@Component({
	selector: 'app-workorder-summary',
	templateUrl: './workorder-summary.component.html',
	styleUrls: ['./workorder-summary.component.scss']
})
export class WorkOrderSummaryComponent extends MenuPanelBaseComponent {
	metric: Metric;

	customFieldNames = {};

	customFieldValues = {};

	showCancelButton = false;

	showDeleteButton = false;

	disableDeleteButton = false;

	disableSaveButton = false;

	isReccurenceTemplateDirty = false;

	advancedMode: boolean;

	isSingleWorkOrder: boolean;

	workOrderSummary: WorkOrderSummary;

	assignedEmployees: Employee[];

	assignedToString: string;

	owner: Employee;

	status: Status;

	priority: Priority;

	dialogRef: MatDialogRef<any, any>;

	fields: Input[] = new Array<Input>();

	isReadOnly = false;

	recurrencePlaceholder: string;

	pageGroup = PageGroup.get(PageGroupName.workorderSummary);

	saveButtonLabel: string;

	public get isRecurrenceTemplate(): boolean {
		return this.workOrderSummary?.isRecurrenceTemplate;
	}

	public get pageIdentifier(): Pages {
		return Pages.workorderSummary;
	}

	public get showRecurrenceField(): boolean {
		return (this.workOrderFactory?.supportsWorkOrderRecurrence && this.workOrderSummary?.isCreationMode) || this.isRecurrenceTemplate;
	}

	get isManagerOrAdmin() {
		if (this.userService.currentUser.role.toLowerCase() === Role.Manager || this.userService.currentUser.role.toLowerCase() === Role.Admin) return true;
		return false;
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	private _canNavigateAway: boolean;
	private _statusCode: string;
	private _statusDescription = '';
	get statusDescription(): string {
		if (!this.workOrderSummary || !this.workOrderSummary.status) return '';

		if (this._statusCode === this.workOrderSummary.status) {
			return this._statusDescription;
		}

		this._statusDescription = '';
		this._statusCode = this.workOrderSummary.status;
		this.status = this.workOrderFactory.workOrderMetaData.statuses.getByStatusCode(this._statusCode);
		if (this.status) {
			this._statusDescription = this.status.description;
		}

		return this._statusDescription;
	}
	set statusDescription(value: string) {}

	private _priorityDescriptionCode: string;
	private _priorityDescription = '';
	get priorityDescription(): string {
		if (!this.workOrderSummary || !this.workOrderSummary.priority) return '';

		if (this._priorityDescriptionCode === this.workOrderSummary.priority) {
			return this._priorityDescription;
		}

		this._priorityDescription = '';
		this._priorityDescriptionCode = this.workOrderSummary.priority;
		this.priority = this.workOrderFactory.workOrderMetaData.priorities.getByPriorityCode(this._priorityDescriptionCode);
		if (this.priority) {
			this._priorityDescription = this.priority.description;
		}

		return this._priorityDescription;
	}
	set priorityDescription(value: string) {}

	get workOrderTeam(): WorkOrderTeam {
		if (!this.workOrderSummary || !this.workOrderSummary.teamId) return null;
		const team = this.workOrderFactory.workOrderMetaData.workorderTeams.getByTeamId(this.workOrderSummary.teamId);
		return team;
	}

	get startDate(): string {
		return this.workOrderSummary.startDate ? DateUtil.convertLocaleUTCString(this.workOrderSummary.startDate) : '';
	}

	get completedDate(): string {
		return this.workOrderSummary.completedDate ? DateUtil.convertLocaleUTCString(this.workOrderSummary.completedDate) : '';
	}

	get isMaximo() {
		return this.workOrderFactory?.workOrderSystem === WorkOrderSystem.Maximo;
	}

	woTemplate: WorkOrderTemplate;

	scrollPosition: number;

	@ViewChild('scrollingContainer', { static: true }) scrollingContainer: ElementRef;

	datesUpdatedSubscription: Subscription;

	selectedCustomField: CustomFieldWrapper;

	private customFieldMap: Map<string, CustomFieldWrapper> = new Map();

	constructor(
		private interop: OmniInteropService,
		private workOrderFactory: WorkOrderFactory,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		private calendarService: CalendarWidgetService,
		private alertDialogService: AlertDialogService,
		private flashMessageService: FlashMessageService,
		private dialog: MatDialog,
		private userService: UserService,
		private recurrenceProvider: RecurrenceProvider,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageReload(navigationArgs: NavigationArgs): void {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
		this.onPageNavigatedTo(navigationArgs);
	}

	goToWorkOrderAsset() {
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderAssets, {
			advancedMode: this.advancedMode,
			workOrder: this.workOrderSummary?.workOrderWrapper,
			metric: this.metric,
			isRecurrenceTemplate: this.isRecurrenceTemplate,
			workAssets: this.workOrderSummary.workAssets
		});
	}

	ngAfterViewInit() {
		if (this.scrollPosition) this.scrollingContainer.nativeElement.scroll(0, this.scrollPosition);
	}

	onScroll() {
		if (!this.uiState) this.uiState = {};
		this.uiState['scrollPosition'] = this.scrollingContainer.nativeElement.scrollTop;
	}

	isDirty(args: NavigationArgs): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (this._canNavigateAway || !this.workOrderSummary) {
				resolve(false);
				return;
			}

			if (!this.workOrderSummary.isDirty && !this.isReccurenceTemplateDirty && !(this.workOrderSummary.customFields && this.workOrderSummary.customFields.isDirty())) {
				resolve(false);
				return;
			}

			resolve(true);
			return;
		});
	}

	async onPageNavigatingFrom(args: NavigationArgs): Promise<boolean> {
		if (!this.pageGroup.has(args.requestedTargetPage)) {
			this.workOrderSummary.undoAll();
			this.workOrderSummary.customFields?.undoAll();
		}
		args.cancel = false;
		return true;
	}

	onBackPressed = async () => {
		const { mapView } = this.config.selectedCanvas;
		if (mapView) mapView.map.remove(mapView.map.findLayerById('workorder-cone'));
	};

	onPageNavigatedTo(args: NavigationArgs) {
		this._canNavigateAway = false;
		if (!args || !args.parameter) return;
		if (args.previousPage) this.checkIsReccurenceTemplateDirty(args.previousPage);
		this.workOrderSummary = args.parameter.workOrderSummary;
		this.hideDeleteButton();
		this.sortCustomFieldsOnSequence(this.workOrderSummary.customFields);
		this.isSingleWorkOrder = this.workOrderSummary?.isSingleWorkOrder;
		this.advancedMode = this.workOrderFactory.isAdvancedMode;
		this.recurrencePlaceholder = this.workOrderSummary?.workOrderTemplate?.name;
		this.woTemplate = this.workOrderFactory?.workOrderMetaData?.woTemplates.find(template => template.woTemplateId === this.workOrderSummary.workTypeId);
		if (this.isRecurrenceTemplate) this.workOrderSummary.customFields = new CustomFieldWrappers();

		this.loadInputFields();
		if (this.isSingleWorkOrder || this.isRecurrenceTemplate) {
			this.setReadOnlyValue();
			this.compareTemplateCustomFields();
		}

		this.menuPanelComponent.updateView({
			title: this.getTitle(),
			rightIcon:
				this.isSingleWorkOrder || this.isRecurrenceTemplate
					? { url: 'assets/maplocation.png', toolTip: '', callBackFunction: this.goToWorkOrderAsset.bind(this) }
					: { url: 'assets/workorder-white.png', toolTip: '', callBackFunction: null },
			backgroundClass: 'orange-background',
			badgeNumber: this.isSingleWorkOrder || this.isRecurrenceTemplate ? this.workOrderSummary.workAssets?.length : this.workOrderSummary.workOrderWrappers?.length,
			disableRightButton: (!this.isSingleWorkOrder && !this.isRecurrenceTemplate) || (this.isReadOnly && !this.workOrderSummary.workAssets.length)
		});

		this.scrollPosition = this.uiState ? this.uiState.scrollPosition : 0;
		this.setAssignedToValues();
		if (this.workOrderSummary.supervisor) this.owner = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(this.workOrderSummary.supervisor);

		this.datesUpdatedSubscription = this.calendarService.calendarDatesUpdatedObservable.subscribe(calendarResult => {
			if (calendarResult.element === 'completed-date') {
				this.workOrderSummary.completedDate = moment(calendarResult.range.startDate).format('M/D/YYYY hh:mm:ss A');
				return;
			}
			if (calendarResult.element === 'wo-custom-field') {
				this.workOrderSummary.customFields.find(customField => {
					if (customField === this.selectedCustomField) {
						customField.customFieldValue = moment(calendarResult.range.startDate).format('M/D/YYYY hh:mm:ss A');
					}
				});
				return;
			}
		});
	}

	private loadInputFields(): void {
		this.fields = new Array<Input>();
		let fieldsConfig = (this.interop?.configurationManager?.customerCodeConfiguration?.workOrderChannel?.attributes as ArcGISWorkOrderChannelAttributes)?.summaryFields;
		if (!fieldsConfig || !fieldsConfig.length || this.workOrderSummary?.isRecurrenceTemplate) fieldsConfig = this.workOrderFactory?.getDefaultSummaryFields(this.workOrderSummary?.isRecurrenceTemplate);
		if (!fieldsConfig) return;

		const keys = Object.getOwnPropertyNames(WorkOrderSummary.prototype);
		for (const fieldConfig of fieldsConfig) {
			if (!this.canShowField(fieldConfig)) continue;

			let input: Input;
			const foundProperty = keys?.find(key =>  key?.toLowerCase() === fieldConfig?.name?.toLowerCase());
			const foundCF = this.workOrderSummary?.customFields?.find(cf => cf.customFieldName?.toLowerCase() === fieldConfig?.name?.toLowerCase());
			if (foundProperty) input = new FieldConfigInput(fieldConfig, this.workOrderSummary, foundProperty);
			else if (foundCF) input = new FieldConfigInput(fieldConfig, foundCF, 'customFieldValue');
			else continue;

			input.forceReadOnly = fieldConfig?.name?.toLowerCase() === 'worktype' && !this.workOrderSummary.isCreationMode;
			this.fields.push(input);
		}

		if (this.showRecurrenceField) this.fields.push(new FieldConfigInput(this.getRecurrenceField(), this.workOrderSummary, 'workOrderTemplate'));

		if (!this.woTemplate || !this.workOrderSummary || !this.workOrderFactory.isAdvancedMode) return;
		for (const customField of this.workOrderSummary.customFields) {
			const templateField = this.woTemplate?.customFields?.find(cf => cf.customFieldId?.toLowerCase() === customField?.customFieldId?.toLowerCase());
			if (!templateField) continue;
			this.fields.push(new CustomFieldInput(customField, templateField));
		}
	}

	private getRecurrenceField(): WorkOrderField {
		const fieldConfig = new WorkOrderField(null);
		fieldConfig.isReadOnly = false;
		fieldConfig.isRequired = false;
		fieldConfig.isVisible = true;
		fieldConfig.name = 'workordertemplate'
		fieldConfig.text = 'recurrence';
		fieldConfig.inputType = SummaryFieldInputType.LIST;
		return fieldConfig;
	}

	private canShowField(fieldConfig: WorkOrderField): boolean {
		if (!fieldConfig || !fieldConfig.isVisible) return false;

		switch (fieldConfig.name) {
			case 'supervisor':
				return this.isMaximo;
			case 'teamid':
				return this.advancedMode && this.workOrderFactory?.workOrderMetaData?.workorderTeams?.length > 0;
			case 'location':
				return !this.isRecurrenceTemplate;
			case 'description':
				return !this.isRecurrenceTemplate && !this.advancedMode;
			case 'comments':
				return !this.isRecurrenceTemplate && (!this.advancedMode || (!this.workOrderSummary.isNew && this.isSingleWorkOrder));
			case 'workordertemplate':
				return this.workOrderFactory?.supportsWorkOrderRecurrence;
			default: return true;
		}
	}

	getValueDescription(input: Input): string {
		if (!input) return '';

		const { value } = input;
		if (input instanceof CustomFieldInput) return this.getCustomFieldDescription(input);
		if (!(input instanceof FieldConfigInput)) return '';

		if (input.config?.list) return input.listItemMatch ? input.listItemMatch.text : input.value;
		switch (input.config?.name?.toLowerCase()) {
			case 'status':
				return this.statusDescription;
			case 'assignedto':
				return this.assignedToString;
			case 'teamid':
				return this.workOrderTeam?.teamName ?? value;
			case 'supervisor':
				return this.owner?.name ?? value;
			case 'priority':
				return this.priorityDescription;
			case 'comments':
				return this.getCommentPlaceHolder();
			case 'workordertemplate':
				return this.recurrencePlaceholder;
		}

		return input.value;
	}

	fieldSelected(input: Input): void {
		if (!input) return;
		if (input instanceof CustomFieldInput) {
			this.customFieldSelected(input);
			return;
		}

		if (!(input instanceof FieldConfigInput)) return;
		if (input.config?.list) {
			this.goToConfiguredList(input);
			return;
		}

		switch (input?.config?.name) {
			case 'worktype':
				this.goToWorkType();
				return;
			case 'status':
				this.goToWorkOrderStatus();
				return;
			case 'assignedto':
				this.goToWorkOrderAssignTo();
				return;
			case 'teamid':
				this.goToWorkOrderTeam();
				return;
			case 'supervisor':
				this.goToWorkorderOwner();
				return;
			case 'priority':
				this.goToPriority();
				return;
			case 'workordertemplate':
				this.goToRecurrence();
				return;
			case 'comments':
				this.goToComment();
				return;
		}
	}

	private hideDeleteButton(): void {
		if (!this.isCreateAction() && !this.workOrderSummary.isRecurrenceTemplate && this.isSedaruWO()) {
			this.showDeleteButton = true;
		}
	}

	private isCreateAction(): boolean {
		return this.workOrderSummary.action === WorkOrderSummaryActions.CreateSingleWO || this.workOrderSummary.action === WorkOrderSummaryActions.CreateMultipleWO;
	}

	private isSedaruWO(): boolean {
		const _workOrderSystem = (this.interop.omniDomain.userService.globalConfig.workOrderChannel.attributes as WorkOrderChannelAttributes).workOrderSystem;
		return _workOrderSystem === WorkOrderSystem.SedaruAWO || _workOrderSystem === WorkOrderSystem.SedaruSWO;
	}

	/** Method to sort (self mutation) the customFields array, based on sequence number */
	private sortCustomFieldsOnSequence(customFields: CustomFieldWrappers) {
		customFields.sort((aField, bField) => (!aField.sequence || !bField.sequence ? 0 : aField.sequence - bField.sequence));
	}

	private checkIsReccurenceTemplateDirty(previousPage) {
		if (!previousPage.recurrenceTemplate) return;
		if (previousPage.recurrenceTemplate.isDirty) this.isReccurenceTemplateDirty = true;
	}

	private getTitle(): string {
		let title: string;
		switch (this.workOrderSummary.action) {
			case WorkOrderSummaryActions.CreateSingleWO:
				title = 'create new work order';
				break;
			case WorkOrderSummaryActions.EditSingleWO:
				title = 'summary';
				break;
			case WorkOrderSummaryActions.CreateMultipleWO:
				title = 'bulk create';
				break;
			case WorkOrderSummaryActions.EditMultipleWO:
				title = 'bulk edit';
				break;
		}

		if (this.isRecurrenceTemplate) title = 'work order template';

		return title;
	}

	private setAssignedToValues(): void {
		this.assignedEmployees = this.getAssignedEmployees();
		this.assignedToString = this.assignedEmployees?.map(e => e.name)?.join(', ');
	}

	private getAssignedEmployees(): Employee[] {
		const assignedEmployees = new Array<Employee>();
		const employeeIds = this.workOrderSummary?.assignedTo?.split(',');
		if (!employeeIds) return assignedEmployees;

		for (const id of employeeIds) {
			const foundEmployee = this.workOrderFactory?.workOrderMetaData?.employees?.getByEmployeeId(id);
			if (!foundEmployee) continue;
			assignedEmployees.push(foundEmployee);
		}

		return assignedEmployees;
	}

	private setReadOnlyValue(): void {
		this.isReadOnly = this.isRecurrenceTemplate ? this.workOrderSummary?.workOrderTemplate?.isReadOnly : this.workOrderFactory.isWorkOrderReadOnly(this.workOrderSummary.workOrderWrapper);
	}

	/**
	 * This function saves the workorder
	 */
	async onSave() {
		if (this.isReadOnly) return;

		if (!this.validateWorkOrder()) return;

		if (this.isReadOnlyStatus() && !await this.confirmReadOnlyStatus()) return;

		this.disableSaveButton = true;
		this.setworkOrderChangeValues();
		this.workOrderSummary.applyAllDirtyFields(this.userService.currentUser.fullName);

		this.saveButtonLabel = 'saving';
		this.menuPanelComponent.disableBackButton = true;
		if (this.isRecurrenceTemplate) {
			await this.saveRecurrenceTemplate();
			this.isReccurenceTemplateDirty = false;
			return;
		}

		this.menuPanelComponent.updatePacifier(true);
		let saveResult = false;

		try {
			switch (this.workOrderSummary.action) {
				case WorkOrderSummaryActions.CreateSingleWO:
					saveResult = await this.createSingleWorkOrder();
					break;
				case WorkOrderSummaryActions.EditSingleWO:
					saveResult = await this.updateSingleWorkOrder();
					break;
				case WorkOrderSummaryActions.CreateMultipleWO:
					saveResult = await this.bulkCreation();
					break;
				case WorkOrderSummaryActions.EditMultipleWO:
					saveResult = await this.bulkUpdate();
					break;
			}
		} finally {
			this.disableSaveButton = false;
			this.menuPanelComponent.updatePacifier(false);
			if (!this.menuPanelComponent.toast.controller.activeJob) {
				this.menuPanelComponent.disableBackButton = false;
			}
			this.showCancelButton = false;

			if (!saveResult) {
				this.saveButtonLabel = 'save';
				return;
			}
			this._canNavigateAway = true;
			this.workOrderSummary?.clearDirty();
			await this.refreshWorkOrderMetrics();
			if (this.advancedMode) this.workOrderFactory.sendWorkOrderUpdateNotification(this.workOrderSummary?.workOrderWrapper?.workOrderKey);

			if (!this.isSingleWorkOrder) {
				await NavigationService.navigateBackTo();
				NavigationService.removePageFromBackStack(Pages.workorderSummary);
				return;
			}

			await NavigationService.navigateBackTo(Pages.workorderOutline, {
				workOrder: this.workOrderSummary?.workOrderWrapper,
				advancedMode: this.advancedMode,
				metric: this.metric
			});

			NavigationService.removePageFromBackStack(Pages.workorderSummary);
		}
	}

	private async createSingleWorkOrder(): Promise<boolean> {
		const workOrder = this.workOrderSummary?.workOrderWrapper;
		if (!workOrder) return false;

		const result = await this.workOrderFactory.createWorkOrder(workOrder, this.timeoutDelegate.bind(this));
		if (result && result.isSuccess) return true;

		this.showErrorMessage(result?.errorMessage);
		return false;
	}

	private async updateSingleWorkOrder(): Promise<boolean> {
		const workOrder = this.workOrderSummary?.workOrderWrapper;
		if (!workOrder) return false;

		const result = await this.workOrderFactory.updateWorkOrder(workOrder, this.timeoutDelegate.bind(this));
		if (result && result.isSuccess) return true;

		this.showErrorMessage(result?.errorMessage);
		return false;
	}

	private async bulkUpdate(): Promise<boolean> {
		const { workOrderWrappers } = this.workOrderSummary;
		if (!workOrderWrappers || !workOrderWrappers.length) return false;

		const realtimeHub = this.interop.omniDomain.subscriptionService.hub;
		const customerCode = this.workOrderFactory.customerCode;
		if (this.advancedMode && !(await realtimeHub.isSedaruConnectAvailable(customerCode, 5))) {
			this.alertDialog.mainMessage = { text: 'Unable to establish a connection with Sedaru Connect' };
			this.alertDialog.open = true;
			return false;
		}

		if (MenuPanelComponent.toastController.activeJob && MenuPanelComponent.toastController.activeJob.state === JobState.Running) {
			this.alertDialog.mainMessage = { text: 'Cannot perform action. There is already a bulk edit in progress' };
			this.alertDialog.open = true;
			return false;
		}

		const bulkJob = this.workOrderFactory.bulkEdit(workOrderWrappers);
		if (!bulkJob) return false;

		MenuPanelComponent.toastController.setBulkJob(bulkJob);
		this.interop.jobManager.enqueueJob(bulkJob);
		bulkJob.run();
		return true;
	}

	private async bulkCreation(): Promise<boolean> {
		if (this.advancedMode) {
			this.alertDialog.mainMessage = { text: 'Feature coming soon!' };
			this.alertDialog.open = true;
			return false;
		}

		if (MenuPanelComponent.toastController.activeJob && MenuPanelComponent.toastController.activeJob.state === JobState.Running) {
			this.alertDialog.mainMessage = { text: 'Cannot perform action. There is already a bulk creation in progress' };
			this.alertDialog.open = true;
			return false;
		}

		const bulkJob = this.workOrderFactory.bulkCreate(this.workOrderSummary.workOrderWrappers);
		if (!bulkJob) return false;

		MenuPanelComponent.toastController.setBulkJob(bulkJob);
		this.interop.jobManager.enqueueJob(bulkJob);
		bulkJob.run();
		return true;
	}

	private async bulkDelete(): Promise<boolean> {
		const { workOrderWrappers } = this.workOrderSummary;
		if (!workOrderWrappers || !workOrderWrappers.length) return false;

		if (this.workOrderFactory.workOrderSystem === WorkOrderSystem.Maximo || this.workOrderFactory.workOrderSystem === WorkOrderSystem.CityWorks) {
			this.alertDialog.mainMessage = { text: 'Cannot perform action. Work order system does not allow the deletion of work orders' };
			this.alertDialog.open = true;
		}

		if (MenuPanelComponent.toastController.activeJob && MenuPanelComponent.toastController.activeJob.state === JobState.Running) {
			this.alertDialog.mainMessage = { text: 'Cannot perform action. There is already a bulk creation in progress' };
			this.alertDialog.open = true;
			return false;
		}

		const bulkJob = this.workOrderFactory.bulkDelete(workOrderWrappers);
		if (!bulkJob) return false;

		MenuPanelComponent.toastController.setBulkJob(bulkJob);
		this.interop.jobManager.enqueueJob(bulkJob);
		bulkJob.run();
		return true;
	}

	private async saveRecurrenceTemplate(): Promise<void> {
		this.workOrderSummary?.setWorkOrderTemplateValues();
		const { workOrderTemplate } = this.workOrderSummary;
		if (!workOrderTemplate) return;

		let successfulSave = false;
		try {
			this.menuPanelComponent.updatePacifier(true);
			let response: ContractResponseBase;
			if (this.workOrderSummary.isCreationMode) response = await this.recurrenceProvider.createRecurrenceTemplate(workOrderTemplate);
			else response = await this.recurrenceProvider.updateRecurrenceTemplate(workOrderTemplate);

			if (!response || !response.Success) {
				this.warnUnsuccessfulSave(response.Message);
				return;
			}

			successfulSave = true;
		} finally {
			if (!successfulSave) return;
			this.workOrderSummary?.clearDirty();
			this.menuPanelComponent.updatePacifier(false);
			NavigationService.navigateBackTo();
		}
	}

	private warnUnsuccessfulSave(errorMessage: string): void {
		let body = 'Unable to create recurring work order';
		if (errorMessage) body = errorMessage;
		this.alertDialog.mainMessage = { text: body };
		this.alertDialog.open = true;
	}

	private isReadOnlyStatus(): boolean {
		const readOnlyValues = this.workOrderFactory.readOnlyWorkOrderValues;
		if (!readOnlyValues) return false;

		return readOnlyValues.includes(this.workOrderSummary.status);
	}

	private async confirmReadOnlyStatus(): Promise<boolean> {
		return await this.alertDialogService.openConfirmDialog(
			'Status Change',
			`Changing the status to '${this.status.description}' will set this work order as read-only and cannot be undone`,
			'yes, continue',
			'no'
		);
	}

	private setworkOrderChangeValues(): void {
		const { workOrderWrappers } = this.workOrderSummary;
		if (!workOrderWrappers) return;

		const currentEmployeeId = this.workOrderFactory?.currentEmployee?.employeeId;
		for (const workOrder of workOrderWrappers) {
			if (this.workOrderSummary.isNew) {
				workOrder.createdBy = currentEmployeeId;
				workOrder.createdDate = DateUtil.now.toLocaleString();
			}

			if (this.workOrderFactory.completedWorkOrderValues.includes(this.workOrderSummary.status)) {
				workOrder.completedDate = DateUtil.now.toLocaleString();
				workOrder.completedBy = currentEmployeeId;
			}

			workOrder.changeBy = currentEmployeeId;
			workOrder.isServiceRequest = 0;
		}
	}

	private async refreshWorkOrderMetrics(): Promise<void> {
		// RefreshWorkOrderMetrics already being called after bulk job is complete
		if (!this.isSingleWorkOrder) return;

		const workOrders = this.workOrderSummary?.workOrderWrappers;
		if (!workOrders) return;

		let refreshOperation: HMSRefreshOperation;
		if (this.workOrderSummary.isCreationMode) refreshOperation = HMSRefreshOperation.Add;
		else refreshOperation = HMSRefreshOperation.Update;

		await this.interop?.metricManager?.refreshWorkOrderMetrics(
			workOrders.map(wo => wo.workOrderKey),
			refreshOperation
		);
	}

	onCancel() {
		this.workOrderFactory.cancelRealTimeSaveOperation();
		this.showCancelButton = false;
	}

	timeoutDelegate = (): void => {
		this.showCancelButton = true;
	};

	/**
	 * This function deletes onew workorder
	 */
	deleteSingleWO() {
		return this.workOrderFactory.deleteWorkOrder(this.workOrderSummary.workOrderWrapper);
	}

	async onDeleteRequested() {
		/** check if user has manager or admin roles */
		if (!this.isManagerOrAdmin) {
			this.flashMessageService.showToast('you do not have permission to delete records', 'Got it');
			return;
		}

		/** if reccurence exists for any of the work orders */
		const warningMessage = new Message();
		if (this.workOrderSummary.workOrderWrappers.some(wrapper => wrapper.recurrenceTemplateId)) {
			if (this.workOrderSummary.workOrderWrappers.length > 1) {
				warningMessage.text = 'WARNING: deleting does not impact recurrence. some selected work orders have active recurrence.';
			} else {
				warningMessage.text = 'WARNING: deleting does not impact recurrence. this work order has active recurrence.';
			}
			warningMessage.color = Color.fromHex('#f3ee4d');
		}

		const confirmationMessage = new Message();
		if (this.workOrderSummary.workOrderWrappers.length > 1) {
			confirmationMessage.text = `clicking yes will remove ${this.workOrderSummary.workOrderWrappers.length} selected work orders and their histories, are you sure?`;
		} else confirmationMessage.text = 'clicking yes will remove this work order and history. are you sure you want to delete this workorder?';

		const yesButton: AlertButton = {
			text: 'yes',
			icon: 'assets/done.png',
			onClick: this.onDeleteConfirmation.bind(this)
		};

		const noButton: AlertButton = {
			text: 'no',
			icon: 'assets/x-close.png',
			onClick: () => {}
		};

		/** open dialogue */
		this.interop.uiManager.alertDialog.title = !this.isSingleWorkOrder ? 'batch delete confirmation' : 'delete confirmation';
		this.interop.uiManager.alertDialog.mainMessage = confirmationMessage;
		this.interop.uiManager.alertDialog.secondaryMessage = warningMessage;
		this.interop.uiManager.alertDialog.alertButtons = [noButton, yesButton];
		this.interop.uiManager.alertDialog.open = true;
	}

	onDeleteConfirmation() {
		if (!this.isSingleWorkOrder) {
			this.bulkDelete().then(success => {
				if (success) this.menuPanelComponent.goBack();
				else this.flashMessageService.showToast('Error deleting batch', 'OK');
			});
		} else {
			this.deleteSingleWO().then((result: WorkOrderResult) => {
				if (result.isSuccess) {
					this.refreshWorkOrderMetrics();
					NavigationService.navigateBackTo(Pages.metricResults);
				} else this.flashMessageService.showToast(`Error deleting work order: ${result.errorMessage}`, 'OK');
			});
		}
	}

	private showErrorMessage(errorMessage: string): void {
		if (!errorMessage) return;
		this.alertDialog.mainMessage = { text: errorMessage };
		this.alertDialog.open = true;
	}

	/**
	 * This function validates the workorder
	 */
	validateWorkOrder(): boolean {
		const keys = Object.getOwnPropertyNames(WorkOrderSummary.prototype);
		const requiredFields = this.fields?.filter(f => f?.isRequired);
		if (!requiredFields) return true;

		for (const inputField of requiredFields) {
			if (!inputField) continue;
			if (inputField instanceof FieldConfigInput) {
				const { name, text } = inputField?.config;
				const normalizedKey = keys?.find(k => k?.toLowerCase() === name?.toLowerCase());
				if (!inputField.value && (!this.workOrderSummary?.isBulkEdit || this.workOrderSummary.isFieldDirty(normalizedKey))) {
					this.alertDialog.mainMessage = { text: `${text ?? name} is required` };
					this.alertDialog.open = true;
					return false;
				}
			}

			if (!(inputField instanceof CustomFieldInput)) continue;
			if (!inputField.value) {
				this.alertDialog.mainMessage = { text: `${inputField?.customField?.customFieldName} is required` };
				this.alertDialog.open = true;
				return false;
			}
		}

		return true;
	}

	getCommentPlaceHolder() {
		const commentLength = this.workOrderSummary.workOrderWrapper.workComments ? this.workOrderSummary.workOrderWrapper.workComments.length : null;
		if (commentLength) return commentLength + ' entries added';
		return 'no entries added';
	}

	/** alternates the orange-background and the light-gray-background classes */
	getBackgroundClass(index: number) {
		if (index % 2) return 'light-gray-background';
		return 'orange-background';
	}

	goToWorkType = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkType;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.woTemplate],
			selectedItemKey: 'woTemplateName',
			workTypeFilterEnabled: true,
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToWorkOrderAssignTo = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkOrderAssignTo;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			isMultipleSelection: true,
			selectedItems: this.assignedEmployees,
			selectedItemKey: 'name',
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToWorkorderOwner = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.Supervisor;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.owner],
			selectedItemKey: 'name',
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToWorkOrderStatus = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkOrderStatus;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.status],
			selectedItemKey: 'description',
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToPriority = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.Priority;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.priority],
			selectedItemKey: 'description',
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToStartDate = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.StartDate;
		WorkOrderFilterDatePickerComponent.dateResolver = (date: Date) => {
			this.workOrderSummary.startDate = date.toLocaleString();
			this.menuPanelComponent.goBack();
		};
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workOrderFilterDatePicker);
	};

	goToWorkOrderTeam = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkOrderTeam;
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.workOrderTeam],
			selectedItemKey: 'teamName',
			workOrderSummary: this.workOrderSummary,
			advancedMode: this.advancedMode
		});
	};

	goToConfiguredList = (input: FieldConfigInput): void => {
		this._canNavigateAway = true;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [input.listItemMatch],
			input: input
		});
	}

	goToCompletedDate = () => {
		let currentRange = { startDate: moment(), endDate: moment() };
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.CompletedDate;
		if (this.workOrderSummary.completedDate) {
			currentRange = { startDate: moment(this.workOrderSummary.completedDate), endDate: moment(this.workOrderSummary.completedDate) };
		}
		this.calendarService.openCalendar(CalendarTypes.DateTimePicker, this.scrollingContainer, currentRange, { top: 75, left: 10 }, 'completed-date');
	};

	goToComment = () => {
		const { length } = this.workOrderSummary.workOrderWrapper.workComments;
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.Comments;
		this._canNavigateAway = true;
		if (length) return NavigationService.navigateTo(Pages.workOrderCommentList, { workOrder: this.workOrderSummary.workOrderWrapper });
		else return NavigationService.navigateTo(Pages.workOrderComment, { workOrder: this.workOrderSummary.workOrderWrapper });
	};

	goToRecurrence = (): void => {
		this._canNavigateAway = true;
		const template = RecurrenceTemplate.fromContract(this.workOrderSummary?.workOrderTemplate?.getContract());
		template.workAssets.push(...this.workOrderSummary?.workAssets);
		NavigationService.navigateBackTo(Pages.workOrderRecurrence, {
			workOrderSummary: this.workOrderSummary,
			recurrenceTemplate: template
		});
	};

	/**
	 * This function assigns the custom field value to the customer field resolver
	 * @param {CustomFieldWrapper} field - a custome field of a wo
	 */
	customFieldSelected(input: CustomFieldInput): void {
		if (!input || !input.customField) return;
		const { customField, template } = input
		let currentRange = { startDate: moment(), endDate: moment() };
		this.selectedCustomField = input.customField;
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkOrderCustomFields;
		const customFieldCode = input.showButton ? this.getCustomFieldCode(customField) : customField.customFieldValue;
		WorkOrderFieldComponent.customFieldResolver = () => customField;
		if (!customField.customFieldType.toLowerCase().includes('date')) {
			this._canNavigateAway = true;
			NavigationService.navigateTo(Pages.workorderField, {
				selectedItems: [customFieldCode],
				selectedItemKey: 'description',
				workOrderSummary: this.workOrderSummary,
				advancedMode: this.advancedMode
			});
			return;
		}
		// If we need to select date
		if (customField.customFieldValue) {
			currentRange = { startDate: moment(customField.customFieldValue), endDate: moment(customField.customFieldValue) };
		}
		this.calendarService.openCalendar(CalendarTypes.DateTimePicker, this.scrollingContainer, currentRange, { top: 75, left: 10 }, 'wo-custom-field');
	};

	getCustomFieldDescription(input: CustomFieldInput) {
		if (!input) return '';

		const { template, customField } = input;
		if (!customField) return '';
		if (!input.template) return input.customField.customFieldValue;
		if (template.customFieldType.toLowerCase().includes('date')) return DateUtil.convertLocaleString(customField.customFieldValue);
		if (!input.canBePicklist(template.customFieldType) || !template.codeType) return customField.customFieldValue;

		if (!customField.customFieldValue) {
			return '';
		}

		// If its a selectable custom field, we get the customeFieldValue's description
		const customCodeFound = this.workOrderFactory.workOrderMetaData.customFieldCodes.find(
			cField => cField.code && cField.code.toLowerCase() === customField.customFieldValue.toLowerCase() && cField.codeType === template.codeType
		);
		return customCodeFound ? customCodeFound.description : customField.customFieldValue;
	}

	private getCustomFieldCode(customField: CustomFieldWrapper) {
		if (this.customFieldValues[customField.customFieldId]) {
			if (this.customFieldValues[customField.customFieldId][0] === customField.customFieldValue) {
				return this.customFieldValues[customField.customFieldId][1];
			}
		}

		if (!customField || !customField.customFieldValue || !this.woTemplate) return '';
		const customFieldFromWoTemplate = this.woTemplate.customFields.getByCustomFieldId(customField.customFieldId);
		if (!customFieldFromWoTemplate || !customFieldFromWoTemplate.codeType) return '';
		// If its a selectable custome field, we get the customeFieldValue's description
		const customCodeFound = this.workOrderFactory.workOrderMetaData.customFieldCodes.find(
			cField =>
				cField.code &&
				cField.code.toLowerCase() === customField.customFieldValue.toLowerCase() &&
				cField.codeType &&
				cField.codeType.toLowerCase() === customFieldFromWoTemplate.codeType.toLowerCase()
		);

		this.customFieldValues[customField.customFieldId] = [customField.customFieldValue, customCodeFound];
		return customCodeFound;
	}

	private compareTemplateCustomFields() {
		const { workOrderWrapper: workOrder } = this.workOrderSummary;
		if (!workOrder || !this.woTemplate) return;

		const customFieldsToRemove = Array<CustomFieldWrapper>();
		workOrder.customFields.forEach(workOrderCF => {
			if (!this.woTemplate.customFields.getByCustomFieldId(workOrderCF.customFieldId)) customFieldsToRemove.push(workOrderCF);
		});

		customFieldsToRemove.forEach(cf => {
			const index = workOrder.customFields.findIndex(workOrderCF => workOrderCF.customFieldId === cf.customFieldId);
			if (index < 0) return;

			workOrder.customFields.splice(index, 1);
		});
	}
}
