import { Injectable, ElementRef } from '@angular/core';
import { CalendarTypes } from './calendar-types.enum';
import { Subscription, Subject } from 'rxjs';
import { PositionOffset } from './position-offset.model';

@Injectable({
	providedIn: 'root'
})
export class CalendarWidgetService {
	private _openCalendarSubject = new Subject();
	openCalendarObservable = this._openCalendarSubject.asObservable();

	/** Observable to alert when a calendar date range has been updated  */
	private calendarDatesUpdatedSubject = new Subject<any>();
	calendarDatesUpdatedObservable = this.calendarDatesUpdatedSubject.asObservable();

	currentCalendarType: CalendarTypes;
	currentBoundToElement: ElementRef;
	currentRange: any;
	positioOffset: PositionOffset;
	elementName: string;

	openCalendar(
		calendarType: CalendarTypes,
		boundToElement: ElementRef,
		range?: any,
		positionOffset?: PositionOffset,
		elementName?: string
	) {
		this.currentBoundToElement = boundToElement;
		this.currentCalendarType = calendarType;
		this.currentRange = range || null;
		this.positioOffset = positionOffset || null;
		this.elementName = elementName || null;
		this._openCalendarSubject.next();
	}

	oncalendarDatesUpdated(selectedDateRange) {
		this.calendarDatesUpdatedSubject.next({ range: selectedDateRange, element: this.elementName });
	}
}
