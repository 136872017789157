import { ArcGISField } from './arc-gis-field.model';
import { SortOrderType } from './sort-order-type.enum';

/**
 * The model of the metric sort settings
 */
export class MenuPanelSettings {
	/**
	 * The name of the field, the value of which will be used to sort the metric values. Field name includes event_time,
	 * 	work_created_time etc.
	 */
	public defaultSortField: ArcGISField;
	/**
	 * The order in which the metric list items should be sorted. It can take either of the two values,
	 * 'ascending' or 'descending'
	 */
	public defaultSortOrder: SortOrderType;
	/**
	 * The name of the field, the value of which will be displayed in the metric panel. Field name includes event_time, description
	 * 	etc.
	 */
	public displayField: ArcGISField;

	/**
	 * The constructor is used to initialize the model values.
	 */
	constructor() {
		this.defaultSortField = new ArcGISField();
		this.defaultSortOrder = SortOrderType.Ascending;
		this.displayField = new ArcGISField();
	}

	/**
	 * This function used to initial this object
	 * @param {MenuPanelSettings} menuPanelSettings - input used to initialize the object
	 */
	initialize?(menuPanelSettings: MenuPanelSettings): void {
		if (!menuPanelSettings) return;
		this.defaultSortOrder = menuPanelSettings.defaultSortOrder;
		this.defaultSortField.initialize(menuPanelSettings.defaultSortField);
		this.displayField.initialize(menuPanelSettings.displayField);
	}

	/**
	 * This function reset this object with default properties
	 */
	clear?(): void {
		this.defaultSortOrder = SortOrderType.Ascending;
		this.defaultSortField = new ArcGISField();
		this.displayField = new ArcGISField();
	}
}
