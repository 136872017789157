<div class="omni-search-bar" #searchbar>
	<div class="search-bar-container">
		<a class="omni-search-bar-drop-icon" [matMenuTriggerFor]="menu">
			<img class="selected-option-icon" [src]="selectedMode.iconUrl" />
			<img class="drop-down-icon" src="assets/descending-circled.png" />
		</a>
		<input #searchQuery type="text" class="omni-search-bar-input" value="" (keyup)="onKeyup($event.target.value, $event)" (keyup.enter)="onSearch($event.target.value)" [placeholder]="title + '...'" />
		<a class="search-button">
			<img src="assets/search.png" (click)="onSearchAsset(searchQuery.value)" />
		</a>
	</div>

	<mat-menu #menu="matMenu" class="search-menu-panel custom">
		<span class="menu-option" *ngFor="let searchMode of availableSearchModes" [ngStyle]="getSearchBarWidth()">
			<div
				class="search-menu-item"
				(click)="onSearchModeSelected(searchMode)">
				<img [src]="searchMode.iconUrl" />
				<span>{{ searchMode.title }}</span>
			</div>
			<span *ngIf="availableSearchModes.indexOf(searchMode) < availableSearchModes.length - 1">
				<mat-divider class="divider-item"></mat-divider>
			</span>
		</span>
	</mat-menu>
</div>
