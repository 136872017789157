export class TokenPair {
	accessToken: string;
	refreshToken: string;
	expires: Date;

	constructor(accessToken?: string, refreshToken?: string) {
		this.accessToken = accessToken;
		this.refreshToken = refreshToken;
	}
}
