import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';

export class AssetSpec extends OmniModel<AssetSpec, Contracts.AssetSpecContract> {
    private _id: SedaruUtils.Undoable<string>;
    get id(): string {
        if (!this._id) this._id = new SedaruUtils.Undoable<string>();

        return this._id.value;
    }
    set id(value: string) {
        if (!this._id) {
            this._id = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._id.value = value;
    }

    private _assetId: SedaruUtils.Undoable<string>;
    get assetId(): string {
        if (!this._assetId) this._assetId = new SedaruUtils.Undoable<string>();

        return this._assetId.value;
    }
    set assetId(value: string) {
        if (!this._assetId) {
            this._assetId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._assetId.value = value;
    }

    private _specId: SedaruUtils.Undoable<string>;
    get specId(): string {
        if (!this._specId) this._specId = new SedaruUtils.Undoable<string>();

        return this._specId.value;
    }
    set specId(value: string) {
        if (!this._specId) {
            this._specId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._specId.value = value;
    }

    private _description: SedaruUtils.Undoable<string>;
    get description(): string {
        if (!this._description) this._description = new SedaruUtils.Undoable<string>();

        return this._description.value;
    }
    set description(value: string) {
        if (!this._description) {
            this._description = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._description.value = value;
    }

    private _value: SedaruUtils.Undoable<string>;
    get value(): string {
        if (!this._value) this._value = new SedaruUtils.Undoable<string>();

        return this._value.value;
    }
    set value(value: string) {
        if (!this._value) {
            this._value = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._value.value = value;
    }

    private _isRequired: SedaruUtils.Undoable<boolean>;
    get isRequired(): boolean {
        if (!this._isRequired) this._isRequired = new SedaruUtils.Undoable<boolean>();

        return this._isRequired.value;
    }
    set isRequired(value: boolean) {
        if (!this._isRequired) {
            this._isRequired = new SedaruUtils.Undoable<boolean>(value);
            return;
        }

        this._isRequired.value = value;
    }

    private _changeBy: SedaruUtils.Undoable<string>;
    get changeBy(): string {
        if (!this._changeBy) this._changeBy = new SedaruUtils.Undoable<string>();

        return this._changeBy.value;
    }
    set changeBy(value: string) {
        if (!this._changeBy) {
            this._changeBy = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._changeBy.value = value;
    }

    private _changeDate: SedaruUtils.Undoable<string>;
    get changeDate(): string {
        if (!this._changeDate) this._changeDate = new SedaruUtils.Undoable<string>();

        return this._changeDate.value;
    }
    set changeDate(value: string) {
        if (!this._changeDate) {
            this._changeDate = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._changeDate.value = value;
    }

    private _dataType: SedaruUtils.Undoable<string>;
    get dataType(): string {
        if (!this._dataType) this._dataType = new SedaruUtils.Undoable<string>();

        return this._dataType.value;
    }
    set dataType(value: string) {
        if (!this._dataType) {
            this._dataType = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._dataType.value = value;
    }

    private _codeType: SedaruUtils.Undoable<string>;
    get codeType(): string {
        if (!this._codeType) this._codeType = new SedaruUtils.Undoable<string>();

        return this._codeType.value;
    }
    set codeType(value: string) {
        if (!this._codeType) {
            this._codeType = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._codeType.value = value;
    }

    static fromContract(contract: Contracts.AssetSpecContract): AssetSpec {
        const model = new AssetSpec();
        model.id = contract.ObjectId.toString();
        model.assetId = contract.AssetId;
        model.specId = contract.SpecId;
        model.description = contract.Description;
        model.value = contract.Value;
        model.isRequired = contract.IsRequired == 1;
        model.changeBy = contract.ChangeBy;
        model.changeDate = contract.ChangeDate;
        model.dataType = contract.DataType;
        model.codeType = contract.CodeType;
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.AssetSpecContract {
        const contract = new Contracts.AssetSpecContract();
        contract.ObjectId = +this.id;
        contract.AssetId = this.assetId;
        contract.SpecId = this.specId;
        contract.Description = this.description;
        contract.Value = this.value;
        contract.IsRequired = this.isRequired ? 1 : 0;
        contract.ChangeBy = this.changeBy;
        contract.ChangeDate = this.changeDate;
        contract.DataType = this.dataType;
        contract.CodeType = this.codeType;
        return contract;
    }

    onUpdateInformation(copyModel: AssetSpec): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<string>(this._id, () => {
                this.id = copyModel.id;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._assetId, () => {
                this.assetId = copyModel.assetId;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._specId, () => {
                this.specId = copyModel.specId;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._description, () => {
                this.description = copyModel.description;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._value, () => {
                this.value = copyModel.value;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<boolean>(this._isRequired, () => {
                this.isRequired = copyModel.isRequired;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._changeBy, () => {
                this.changeBy = copyModel.changeBy;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._changeDate, () => {
                this.changeDate = copyModel.changeDate;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._dataType, () => {
                this.dataType = copyModel.dataType;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._codeType, () => {
                this.codeType = copyModel.codeType;
            }) || wasChanged;

        return wasChanged;
    }
}
