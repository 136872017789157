import { FailureReportContract } from '../../contracts/work-order';
import { FailureReportWrapper } from './failure-report-wrapper.model';
import { FailureReport } from './failure-report.model';

export class FailureReportWrappers extends Array<FailureReportWrapper> {
	static fromContracts(contracts: FailureReportContract[]): FailureReportWrappers {
		return FailureReportWrappers.fromModels(contracts.map(c => FailureReport.fromContract(c)));
	}

	static fromModels(models: FailureReport[]): FailureReportWrappers {
		const wrappersList = new FailureReportWrappers();
		for (const model of models) wrappersList.push(new FailureReportWrapper(model));
		return wrappersList;
	}

	getContracts = (): FailureReportContract[] => this.map(fr => fr.getContract());

	getModels = (): FailureReport[] => this.map(fr => fr.failureReport);

	isDirty = (): boolean => !!this.filter(fr => fr.isDirty).length;

	clearDirty = (): void => this.forEach(fr => fr.clearDirty());
}
