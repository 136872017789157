<div class="table-component">
	<div class="omni-table" style="flex:1">
		<span *ngIf="loading" class="loading">loading...</span>
		<table mat-table matSort [matSortDisabled]="!enableSorting" [dataSource]="dataSource" (matSortChange)="sortData($event)" *ngIf="!loading">
			<ng-container *ngFor="let field of fields" [matColumnDef]="field.key">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-sort-header-container-centered">{{ field.text }}</th>
				<td mat-cell *matCellDef="let element" [style.color]="field.key === 'color' && element ? element[field.key] : 'white'">
					{{ !element ? '' : field.type === 'number' ? element[field.key]?.toLocaleString() : element[field.key] }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="fieldKeys; sticky: true" [ngStyle]="{ 'background-color': headerColor }"></tr>
			<tr mat-row *matRowDef="let row; columns: fieldKeys" [ngClass]="{ selected: selection.isSelected(row), hovered: row?.hovered }" (click)="rowSelectHandler(row)"></tr>
		</table>
	</div>
	<mat-paginator
		class="paginator"
		[disabled]="loading"
		(page)="onPageChange($event)"
		[length]="length"
		[pageSize]="pageSize"
		[pageIndex]="0"
		[pageSizeOptions]="[10, 20, 25, 50, 100]"
		showFirstLastButtons
	></mat-paginator>
</div>
