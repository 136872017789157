<ng-container *ngIf="show">
	<div class="map-tooltip-container" [ngStyle]="getContainerPosition()">
		<ng-container *ngIf="!bottomTip">
			<div class="map-tooltip-tip top" [ngStyle]="getTipOffsetX()"></div>
		</ng-container>

		<div class="map-tooltip-content" #tooltipContent [ngStyle]="{ width: contentWidth }">
			<div class="content-margin">
				<app-list-item [resolver]="resolver"></app-list-item>
			</div>
		</div>
		<ng-container *ngIf="bottomTip">
			<div class="map-tooltip-tip bottom" [ngStyle]="getTipOffsetX()"></div>
		</ng-container>
	</div>
</ng-container>
