<div class="scrolling-content">
	<app-menu-panel-field
		[header]="'*frequency'"
		[placeHolder]="lowerCaseType"
		[cssClass]="'light-gray-background'"
		[width]="fieldFullSize"
		[height]="fieldHalfSize"
		[type]="'text'"
		[disabled]="true"
		[buttonEnabled]="!isReadOnly"
		[goTo]="goToFrequencySelection"
	></app-menu-panel-field>
	<app-daily-pattern *ngIf="isDailyPattern" [isReadOnly]="isReadOnly" [recurrencePattern]="recurrencePattern"></app-daily-pattern>
	<app-weekly-pattern *ngIf="isWeeklyPattern" (daysOfWeekClicked)="handleDaysOfWeekClicked($event)" [isReadOnly]="isReadOnly" [recurrencePattern]="recurrencePattern"></app-weekly-pattern>
	<app-monthly-pattern *ngIf="isMonthlyPattern" [isReadOnly]="isReadOnly" [recurrencePattern]="recurrencePattern"></app-monthly-pattern>
	<app-yearly-pattern *ngIf="isYearlyPattern" [isReadOnly]="isReadOnly" [recurrencePattern]="recurrencePattern"></app-yearly-pattern>
	<app-menu-panel-field
		*ngIf="showRecurrenceFields"
		[header]="'*start date'"
		[(value)]="recurrencePattern.startDate"
		[cssClass]="'light-gray-background'"
		[width]="fieldFullSize"
		[height]="fieldHalfSize"
		[type]="'date'"
		[isDateOnly]="true"
		[disabled]="isReadOnly"
		[buttonEnabled]="!isReadOnly"
	></app-menu-panel-field>
	<app-menu-panel-field
		*ngIf="showRecurrenceFields"
		[header]="'end date'"
		[(value)]="recurrencePattern.endBoundary.endDate"
		[cssClass]="'orange-background'"
		[width]="fieldFullSize"
		[height]="fieldHalfSize"
		[type]="'date'"
		[isDateOnly]="true"
		[disabled]="isReadOnly"
		[buttonEnabled]="!isReadOnly"
	></app-menu-panel-field>
	<app-menu-panel-field
		*ngIf="showRecurrenceFields"
		[header]="'*description'"
		[(value)]="recurrenceTemplate.name"
		[cssClass]="'light-gray-background'"
		[width]="fieldFullSize"
		[height]="fieldHalfSize"
		[type]="'text'"
		[disabled]="isReadOnly"
		[buttonEnabled]="false"
	></app-menu-panel-field>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button *ngIf="isEdit" [class.omni-button-disabled]="isReadOnly" mat-button class="omni-md-font" (click)="onDelete()"><img class="omni-sm-img" src="assets/delete.png" /> delete</button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" [class.omni-button-disabled]="isReadOnly" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
