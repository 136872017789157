import { SummaryFieldInputType } from './work-order/standard-custom-field.model';
import { WorkOrderField } from './work-order-field.model';
import { List } from './list.model';
import { ListItem } from './list-item.model';
import { Input } from './input.model';
import { WorkOrderSummary } from './work-order';

export class FieldConfigInput extends Input {
    constructor(config: WorkOrderField, model?: any, propertyName?: string) {
        super(model[propertyName]);
        this.model = model;
        this.propertyName = propertyName;
        this.config = config;
    }

    config: WorkOrderField;

    model: WorkOrderSummary;

    propertyName: string;

    get header(): string {
        return this.getHeader();
    }

    get value(): any {
        if (!this.model) return null;
        return this.model[this.propertyName];
    }
    set value(value: any) {
        if (!this.model) return;
        this.model[this.propertyName] = value;
    }

    get inputType(): string {
        return this.getInputType();
    }

    get isReadOnly(): boolean {
        return this.config?.isReadOnly || this.forceReadOnly;
    }

    get isRequired(): boolean {
        return this.config?.isRequired;
    }

    get showButton(): boolean {
        return !this.isReadOnly && (this.isDateField || this.isList);
    }

    get isDisabled(): boolean {
        return this.isReadOnly || this.showButton;
    }

    get isList(): boolean {
        return this.config?.inputType === SummaryFieldInputType.LIST;
    }

    get list(): List {
        return this.config?.list;
    }

    get listItemMatch(): ListItem {
        return this.config?.list?.items?.find(i => i.value?.toString() === this.value?.toString());
    }

    private getHeader(): string {
        if (!this.config) return '';
        const header = this.config.text ?? this.config.name;
        return this.isRequired ? `*${header}` : header;
    }

    private getInputType(): string {
		if (!this.config || !this.config?.inputType) return 'text';
		switch (this.config.inputType) {
			case SummaryFieldInputType.KEYPAD:
				return 'number';
			case SummaryFieldInputType.DATETIME_PICKER:
			case SummaryFieldInputType.DATE_PICKER:
				return 'date';
			case SummaryFieldInputType.LIST:
			case SummaryFieldInputType.TEXT:
			default:
				return 'text';
		}
	}
}
