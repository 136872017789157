<div class="calendar-widget" *ngIf="showCalendar" (click)="closeCalendar()">
	<!-- <ngx-daterangepicker-material
		*ngIf="calendarType === 0"
		[ngStyle]="getPosition()"
		(datesUpdated)="datePickerSelectedDate($event)"
		[startDate]="selected.startDate"
		[endDate]="selected.endDate"
	></ngx-daterangepicker-material> -->

	<mat-card class="date-picker" [ngStyle]="getPosition()">
		<mat-calendar
			(click)="$event.stopPropagation()"
			*ngIf="calendarType === 1"
			[selected]="selected"
			[minDate]="selected.minDate"
			(selectedChange)="selected = $event;
			datePickerSelectedDate($event)"
		></mat-calendar>
	</mat-card>

	<ngx-daterangepicker-material
		class="date-time-picker"
		*ngIf="calendarType === 3"
		[ngStyle]="getPosition()"
		(datesUpdated)="selected = $event; datePickerSelectedDate($event)"
		[startDate]="selected"
		[endDate]="selected"
		[timePicker]="true"
		[timePickerIncrement]="15"
		[singleDatePicker]="true"
		[showDropdowns]="true"
		[showClearButton]="true"
	></ngx-daterangepicker-material>

	<div [ngStyle]="getPosition()">
		<mat-date-range-input [rangePicker]="picker">
			<input matStartDate [value]="selected.startDate">
			<input matEndDate [value]="selected.endDate">
		</mat-date-range-input>
		<mat-date-range-picker #picker [startAt]="selected.startDate" [opened]="calendarType === 0" (closed)="onDateRangePickerClosed()">
			<mat-date-range-picker-actions>
			<button mat-raised-button (click)="onDateRangePickerClosed()" matDateRangePickerApply>Apply</button>
			</mat-date-range-picker-actions>
		</mat-date-range-picker>
	</div>
</div>
