export enum GeometryType {
	esriGeometryPoint = 0,
	esriGeometryPolyline = 1,
	esriGeometryPolygon = 2
}

export enum MapGeomertyType {
	POINT = 'point',
	MULTIPOINT = 'multipoint',
	POLYGON = 'polygon',
	POLYLINE = 'polyline',
}
