import { Component } from '@angular/core';
import { LoadingSpinnerBase } from './loading-spinner.base';

@Component({
	selector: 'app-loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent extends LoadingSpinnerBase {
	position() {
		let y: string, x: string;
		if (!this.top) y = 'calc(50% - 10px)';
		else y = `${this.top - 10}px`;
		if (!this.left) x = 'calc(50% - 10px)';
		else x = `${this.left - 10}px`;

		return { top: y, left: x };
	}
}
