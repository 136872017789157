import { GlobalConfig } from '../global-config.model';

export class SedaruConfigBase {
	private _globalConfig: GlobalConfig;
	protected get globalConfig() {
		return this._globalConfig;
	}

	constructor(globalConfig: GlobalConfig) {
		this._globalConfig = globalConfig;
	}
}
