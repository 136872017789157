<div class="middle-container" [class.width100]="mode === 'phone'">
	<div class="tile-bar-wrapper">
		<app-tile-list [activeGuiConfig]="config" [tilesLayout]="config.tilesLayout" [mode]="mode"></app-tile-list>
	</div>
	<div #rightPanel class="phone-right-panel-container" [style.height]="rightPanelHeight" *ngIf="mode === 'phone'">
		<app-search-bar></app-search-bar>
		<app-menu-panel style="flex:1" [config]="config" [mode]="mode"></app-menu-panel>
		<app-omni-team [hidden]="!showTeamTab" style="height: 200px;" [config]="config" [mode]="mode"></app-omni-team>
	</div>
	<div class="canvas-wrapper">
		<app-canvas-container [config]="config" [displayMode]="mode" [tilesLayout]="config.tilesLayout" [canvasLayout]="config.canvasLayout" [canvases]="config.canvasList"></app-canvas-container>
	</div>
</div>
<div #rightPanel class="right-panel-container" *ngIf="mode !== 'phone'">
	<app-search-bar></app-search-bar>
	<app-menu-panel style="flex:1" [config]="config" [mode]="mode"></app-menu-panel>
	<app-omni-team [hidden]="!showTeamTab" style="height: 200px;" [config]="config" [mode]="mode"></app-omni-team>
</div>
