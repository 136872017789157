import * as Contracts from '../../contracts/work-order';
import { OmniModel } from '../../models/omni-model';
import * as SedaruUtils from '../../sedaru-util';

export class FailureReport extends OmniModel<FailureReport, Contracts.FailureReportContract> {
    private _id: SedaruUtils.Undoable<string>;
    get id(): string {
        if (!this._id) this._id = new SedaruUtils.Undoable<string>();
        return this._id.value;
    }
    set id(value: string) {
        if (!this._id) {
            this._id = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._id.value = value;
    }

    private _codeType: SedaruUtils.Undoable<string>;
    get codeType(): string {
        if (!this._codeType) this._codeType = new SedaruUtils.Undoable<string>();
        return this._codeType.value;
    }
    set codeType(value: string) {
        if (!this._codeType) {
            this._codeType = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._codeType.value = value;
    }

    private _codeValue: SedaruUtils.Undoable<string>;
    get codeValue(): string {
        if (!this._codeValue) this._codeValue = new SedaruUtils.Undoable<string>();
        return this._codeValue.value;
    }
    set codeValue(value: string) {
        if (!this._codeValue) {
            this._codeValue = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._codeValue.value = value;
    }

    private _systemId: SedaruUtils.Undoable<string>;
    get systemId(): string {
        if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
        return this._systemId.value;
    }
    set systemId(value: string) {
        if (!this._systemId) {
            this._systemId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._systemId.value = value;
    }

    private _workOrderKey: SedaruUtils.Undoable<string>;
    get workOrderKey(): string {
        if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
        return this._workOrderKey.value;
    }
    set workOrderKey(value: string) {
        if (!this._workOrderKey) {
            this._workOrderKey = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._workOrderKey.value = value;
    }

    private _createdDate: SedaruUtils.Undoable<string>;
    get createdDate(): string {
        if (!this._createdDate) this._createdDate = new SedaruUtils.Undoable<string>();
        return this._createdDate.value;
    }
    set createdDate(value: string) {
        if (!this._createdDate) {
            this._createdDate = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._createdDate.value = value;
    }

    private _createdBy: SedaruUtils.Undoable<string>;
    get createdBy(): string {
        if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
        return this._createdBy.value;
    }
    set createdBy(value: string) {
        if (!this._createdBy) {
            this._createdBy = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._createdBy.value = value;
    }

    static fromContract(contract: Contracts.FailureReportContract): FailureReport {
        const model = new FailureReport();
        model.id = contract.ObjectId.toString();
        model.codeType = contract.CodeType;
        model.codeValue = contract.CodeValue;
        model.systemId = contract.SystemId;
        model.workOrderKey = contract.WorkOrderKey;
        model.createdDate = contract.CreatedDate;
        model.createdBy = contract.CreatedBy;
        return model;
    }

    getContract(): Contracts.FailureReportContract {
        const contract = new Contracts.FailureReportContract();
        contract.ObjectId = +this.id;
        contract.CodeType = this.codeType;
        contract.CodeValue = this.codeValue;
        contract.SystemId = this.systemId;
        contract.WorkOrderKey = this.workOrderKey;
        contract.CreatedDate = this.createdDate;
        contract.CreatedBy = this.createdBy;
        return contract;
    }

    onUpdateInformation(copyModel: FailureReport): boolean {
        let wasChanged = false;
		wasChanged = this.updateIfNotDirty<string>(this._id, () => {
                this.id = copyModel.id;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._codeType, () => {
                this.codeType = copyModel.codeType;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._codeValue, () => {
                this.codeValue = copyModel.codeValue;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._systemId, () => {
                this.systemId = copyModel.systemId;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._workOrderKey, () => {
                this.workOrderKey = copyModel.workOrderKey;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._createdDate, () => {
                this.createdDate = copyModel.createdDate;
            }) || wasChanged;
        wasChanged = this.updateIfNotDirty<string>(this._createdBy, () => {
                this.createdBy = copyModel.createdBy;
            }) || wasChanged;
        return wasChanged;
    }
}
