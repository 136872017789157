import * as Contracts from '../../contracts/work-order';
import { OmniModel } from '../../models/omni-model';
import * as SedaruUtils from '../../sedaru-util';
import { Attachment } from './attachment.model';
export class AssetLocationWithAssets extends OmniModel<AssetLocationWithAssets, Contracts.AssetLocationWithAssetsContract> {
	constructor(activeAsset?: string, isAssetLoaded?: boolean) {
		super();
		if (activeAsset) this.activeAsset = activeAsset;
		if (isAssetLoaded) this.isAssetLoaded = isAssetLoaded;
	}

	private _assetType: SedaruUtils.Undoable<string>;
	get assetType(): string {
		if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
		return this._assetType.value;
	}
	set assetType(value: string) {
		if (!this._assetType) {
			this._assetType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetType.value = value;
	}

	private _activeAsset: SedaruUtils.Undoable<string>;
	get activeAsset(): string {
		if (!this._activeAsset) this._activeAsset = new SedaruUtils.Undoable<string>();
		return this._activeAsset.value;
	}
	set activeAsset(value: string) {
		if (!this._activeAsset) {
			this._activeAsset = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._activeAsset.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _gisId: SedaruUtils.Undoable<string>;
	get gisId(): string {
		if (!this._gisId) this._gisId = new SedaruUtils.Undoable<string>();
		return this._gisId.value;
	}
	set gisId(value: string) {
		if (!this._gisId) {
			this._gisId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._gisId.value = value;
	}

	private _imageUrl: SedaruUtils.Undoable<string>;
	get imageUrl(): string {
		if (!this._imageUrl) this._imageUrl = new SedaruUtils.Undoable<string>();
		return this._imageUrl.value;
	}
	set imageUrl(value: string) {
		if (!this._imageUrl) {
			this._imageUrl = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._imageUrl.value = value;
	}

	private _locationId: SedaruUtils.Undoable<string>;
	get locationId(): string {
		if (!this._locationId) this._locationId = new SedaruUtils.Undoable<string>();
		return this._locationId.value;
	}
	set locationId(value: string) {
		if (!this._locationId) {
			this._locationId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._locationId.value = value;
	}

	private _parentId: SedaruUtils.Undoable<string>;
	get parentId(): string {
		if (!this._parentId) this._parentId = new SedaruUtils.Undoable<string>();
		return this._parentId.value;
	}
	set parentId(value: string) {
		if (!this._parentId) {
			this._parentId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._parentId.value = value;
	}

	private _inactiveAsset: SedaruUtils.Undoable<any>;
	get inactiveAsset(): any {
		if (!this._inactiveAsset) this._inactiveAsset = new SedaruUtils.Undoable<any>();
		return this._inactiveAsset.value;
	}
	set inactiveAsset(value: any) {
		if (!this._inactiveAsset) {
			this._inactiveAsset = new SedaruUtils.Undoable<any>(value);
			return;
		}
		this._inactiveAsset.value = value;
	}

	private _isAssetLoaded: SedaruUtils.Undoable<boolean>;
	get isAssetLoaded(): boolean {
		if (!this._isAssetLoaded) this._isAssetLoaded = new SedaruUtils.Undoable<boolean>();
		return this._isAssetLoaded.value;
	}
	set isAssetLoaded(value: boolean) {
		if (!this._isAssetLoaded) {
			this._isAssetLoaded = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._isAssetLoaded.value = value;
	}

	private _attachment: SedaruUtils.Undoable<Attachment>;
	get attachment(): Attachment {
		if (!this._attachment) this._attachment = new SedaruUtils.Undoable<Attachment>(new Attachment());
		return this._attachment.value;
	}
	set attachment(value: Attachment) {
		if (!this._attachment) {
			this._attachment = new SedaruUtils.Undoable<Attachment>(value);
			return;
		}
		this._attachment.value = value;
	}

	private _childrenWorkOrdersSum: SedaruUtils.Undoable<number>;
	get childrenWorkOrdersSum(): number {
		if (!this._childrenWorkOrdersSum) this._childrenWorkOrdersSum = new SedaruUtils.Undoable<number>(0);
		return this._childrenWorkOrdersSum.value;
	}
	set childrenWorkOrdersSum(value: number) {
		if (!this._childrenWorkOrdersSum) {
			this._childrenWorkOrdersSum = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._childrenWorkOrdersSum.value = value;
	}

	private _locationWorkOrderKeys: SedaruUtils.Undoable<Array<string>>;
	get locationWorkOrderKeys(): Array<string> {
		if (!this._locationWorkOrderKeys) this._locationWorkOrderKeys = new SedaruUtils.Undoable<Array<string>>(new Array<string>());
		return this._locationWorkOrderKeys.value;
	}
	set locationWorkOrderKeys(value: Array<string>) {
		if (!this._locationWorkOrderKeys) {
			this._locationWorkOrderKeys = new SedaruUtils.Undoable<Array<string>>(value);
			return;
		}
		this._locationWorkOrderKeys.value = value;
	}

	private _assetWorkOrderCount: SedaruUtils.Undoable<number>;
	get assetWorkOrderCount(): number {
		if (!this._assetWorkOrderCount) this._assetWorkOrderCount = new SedaruUtils.Undoable<number>(0);
		return this._assetWorkOrderCount.value;
	}
	set assetWorkOrderCount(value: number) {
		if (!this._assetWorkOrderCount) {
			this._assetWorkOrderCount = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._assetWorkOrderCount.value = value;
	}

	get childWorkOrderCountString(): string {
		const sum = this.locationWorkOrderKeys.length + this.assetWorkOrderCount + this.childrenWorkOrdersSum;
		if (sum === 0) return '';
		return sum < 100 ? sum.toString() : '99+';
	}

	get workOrdersCountString(): string {
		const sum = this.locationWorkOrderKeys.length + this.assetWorkOrderCount;
		if (sum === 0) return '';
		return sum < 100 ? sum.toString() : '99+';
	}

	get hasAsset(): boolean {
		return this.activeAsset && this.activeAsset.length && this.isAssetLoaded;
	}

	static fromContract(contract: Contracts.AssetLocationWithAssetsContract): AssetLocationWithAssets {
		const model = new AssetLocationWithAssets();
		model.activeAsset = contract.ActiveAsset;
		model.description = contract.Description;
		model.gisId = contract.GisId;
		model.imageUrl = contract.ImageUrl;
		model.inactiveAsset = contract.InactiveAsset;
		model.locationId = contract.LocationId;
		model.parentId = contract.ParentId;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.AssetLocationWithAssetsContract => {
		const contract = new Contracts.AssetLocationWithAssetsContract();
		contract.ActiveAsset = this.activeAsset;
		contract.Description = this.description;
		contract.GisId = this.gisId;
		contract.ImageUrl = this.imageUrl;
		contract.InactiveAsset = this.inactiveAsset;
		contract.LocationId = this.locationId;
		contract.ParentId = this.parentId;
		return contract;
	};

	onUpdateInformation(copyModel: AssetLocationWithAssets): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._activeAsset, () => {
				this.activeAsset = copyModel.activeAsset;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._gisId, () => {
				this.gisId = copyModel.gisId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._imageUrl, () => {
				this.imageUrl = copyModel.imageUrl;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._inactiveAsset, () => {
				this.inactiveAsset = copyModel.inactiveAsset;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._locationId, () => {
				this.locationId = copyModel.locationId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._parentId, () => {
				this.parentId = copyModel.parentId;
			}) || wasChanged;
		return wasChanged;
	}
}
