import { Component, Input, ElementRef, QueryList, ViewChildren, ViewChild, EventEmitter, Output } from '@angular/core';
import {
	QuerySelectionType,
	QueryOperator,
	Operator,
	Metric,
	UnitOperator
} from 'models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { TimeframeValueEditingMode } from './timeframe-value-editing-mode.enum';
import { UserService } from 'app/user/user.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { DropdownComponent } from 'app/ui-components/drop-down/drop-down.component';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { UserTimeframeOption } from 'models/time-frame/user-timeframe-option.model';
import { UserTimeframeOptionType } from 'models/time-frame/user-timeframe-option.enum';
import { CalendarTypes } from 'app/ui-components/calendar-widget/calendar-types.enum';
import * as moment from 'moment';
import { TimeframeField } from 'models/time-frame/timeframe-query.model';
import { TimeframeDefinition } from 'models/time-frame/timeframe-definition.model';
import { TimeframeType } from 'models/time-frame/timeframe-type.enum';
import { FlashMessageService } from 'app/flash-message/flash-message.service';

@Component({
	selector: 'app-timeframe-field',
	templateUrl: './timeframe-field.component.html',
	styleUrls: ['./timeframe-field.component.scss']
})
export class TimeframeFieldComponent extends MenuPanelBaseComponent {
	private _dropdowns: QueryList<DropdownComponent>;
	@ViewChildren(DropdownComponent) set dropdowns(dds: QueryList<DropdownComponent>) {
		if (!dds) {
			return;
		}
		this._dropdowns = dds;
		this.updateMaxHeight();
	}

	/**
	 * The sedaru grey color code
	 */
	readonly sedaruGrey = 'rgba(84, 84, 84, 0.6)';

	/**
	 * The default sedaru grey color code
	 */
	readonly defaultSedaruGrey = 'rgba(128, 128, 128, 0.6)';

	/**
	 * Item to edit at each sequence
	 */
	itemToEdit: QuerySelectionType = QuerySelectionType.field;

	/**
	 * A list of opertor to select from
	 */
	unitOperatorList: UnitOperator[] = [];

	/**
	 * A list join operators
	 */
	joinOperationList: Operator[] = Object.values(QueryOperator.forDone);

	/**
	 * The value list holds all the coded values
	 */
	valueList: { name: string; code: string; value: string }[];

	/**
	 * The editing mode when editing a value
	 */
	valueEditingMode = 'disabled';

	/**
	 * Input received from parent component the query field
	 */
	@Input() queryField: TimeframeField;

	private waitForDropDowns;
	private _initialHeight: number;
	@Input() set initialHeight(h: number) {
		if (!h) {
			return;
		}
		this._initialHeight = h;
		if (!this._dropdowns) {
			// wait until dropdowns are defined
			this.waitForDropDowns = setInterval(() => {
				if (this._dropdowns) {
					clearInterval(this.waitForDropDowns);
					this.updateMaxHeight();
				}
			}, 100);
		} else this.updateMaxHeight();
	}

	maxHeightOfField: number;

	readonly defaultDateFormat = 'M/D/YYYY';
	/**
	 * This function pass the field selected to parent
	 */
	@Output() passTimeframeToBuilderComponent = new EventEmitter();

	showTimeframeOperatorComponent = true;

	@ViewChild('calendarInput') calendarInput: ElementRef;
	@ViewChild('calendarRangeInput') calendarRangeInput: ElementRef;

	@Input() userTimeframeList: {name: string, type: UserTimeframeOptionType, timeframeType: TimeframeType}[] = [];

	constructor(private interopService: OmniInteropService, private flashMessageService: FlashMessageService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * should show field component in the editing sequence
	 */
	get showFieldComponent(): boolean {
		return this.itemToEdit > 1 || this.itemToEdit === 0;
	}

	/**
	 * should show operator component in the editing sequence
	 */
	get showOperatorComponent(): boolean {
		return this.itemToEdit > 2 || this.itemToEdit === 0;
	}

	/**
	 * should show value component in the editing sequence
	 */
	get showValueComponent(): boolean {
		return this.itemToEdit > 3 || this.itemToEdit === 0;
	}

	/**
	 * should show join component in the editing sequence
	 */
	get showJoinComponent(): boolean {
		return this.itemToEdit > 4 || this.itemToEdit === 0;
	}

	/**
	 * indicate if the expansion panel should open
	 */
	get expansionShouldOpen(): boolean {
		const operatorName = this.queryField.operator?.name.toLowerCase();
		const operatorIsNotNull: boolean = this.queryField.operator && !operatorName.includes('null');
		const operatorIsNotBlank: boolean = this.queryField.operator && !operatorName.includes('blank');
		const openValueExpansionPanel: boolean = this.itemToEdit === 4 && operatorIsNotNull && operatorIsNotBlank;
		return openValueExpansionPanel || !this.queryField.value;
	}

	/**
	 * get the header for the value selection
	 */
	get headerForValuePanel(): string {
		const operatorName = this.queryField.operator?.name.toLowerCase();
		const operatorIsNull: boolean = this.queryField.operator && operatorName.includes('null');
		const operatorIsBlank: boolean = this.queryField.operator && operatorName.includes('blank');

		if (operatorIsNull || operatorIsBlank) {
			return '-';
		}

		switch (this.valueEditingMode) {
			case TimeframeValueEditingMode.FREETEXT:
				return 'enter a value';
			case TimeframeValueEditingMode.SELECTION:
				return 'select a value';
			case TimeframeValueEditingMode.DATEPICKER:
			case TimeframeValueEditingMode.DATERANGEPICKER:
				return (this.queryField.timeframeOption.timeframeType === TimeframeType.Range) ?  'select a date range' : 'select a date';
			default:
				return 'select or enter a value';
		}
	}

	/**
	 * indicate if expansion panel should disable
	 */
	get disableExpansionPanel(): boolean {
		const isFreeText: boolean = this.valueEditingMode === TimeframeValueEditingMode.FREETEXT;
		const operatorName = this.queryField.operator?.name.toLowerCase();
		const operatorIsNull: boolean = this.queryField.operator && operatorName.includes('null');
		const operatorIsBlank: boolean = this.queryField.operator && operatorName.includes('blank');

		return isFreeText || operatorIsNull || operatorIsBlank;
	}

	/**
	 * On init, we get the user timeframe option list, and then set the sequence for editing.
	 * Also, assign the index to the query field object
	 */
	ngOnInit() {
		this.queryField.index = 0;
		this.setUpQueryField();
	}

	ngAfterViewInit() {
		if (!this.calendarInput) {
			return;
		}
		this.calendarInput.nativeElement.value = this.queryField.value.name;

		if (!this.calendarRangeInput) {
			return;
		}
		this.calendarRangeInput.nativeElement.value = this.queryField.value.endDate;
	}

	private updateMaxHeight() {
		if (!this._initialHeight || !this._dropdowns) {
			return;
		}
		const numberOfFields = this._dropdowns.length - 1;
		setTimeout(() => {
			// inside a timeout to change this property outside the angular check cycle
			this.maxHeightOfField = this._initialHeight - numberOfFields * 56;
		}, 0);
	}

	async setUpQueryField() {
		this.getUnitOperatorListBaseOnOption(this.queryField.timeframeOption); // operator (timeframe selection)
		this.getEditModeBaseOnTimeframeOption(this.queryField.timeframeOption); // value
	}

	openCalendar() {
		const calendar = this.getCalandar(this.calendarInput, null);
		calendar.onDateSelected = newDate => {
			const startDate = moment(newDate).format(this.defaultDateFormat);
			this.calendarInput.nativeElement.value = startDate;
			this.onDateChanged(newDate, startDate);
			calendar.showCalendar = false;
		};

		calendar.showCalendar = true;
	}

	openEndDateCalendar() {
		const calendar = this.getCalandar(this.calendarRangeInput, this.queryField.value.date);
		calendar.onDateSelected = newDate => {
			const endDate = moment(newDate).format(this.defaultDateFormat);
			this.calendarRangeInput.nativeElement.value = endDate;
			this.onEndDateChanged(endDate)
			calendar.showCalendar = false;
		};

		calendar.showCalendar = true;
	}

	getCalandar(calendarInput, minDate) {
		const calendar = this.interopService.uiManager.dateTimePicker;
		calendar.calendarType = CalendarTypes.DatePicker;
		calendar.selected = { startDate: new Date(), endDate: new Date(), minDate: minDate };
		calendar.boundToElement = calendarInput;

		return calendar;
	}

	/**
	 * On field selected from the expansion pannel
	 */
	onFieldSelected(selectedField) {
		this.fieldIsDirty();
		this.setSquence(QuerySelectionType.field);
		this.getUnitOperatorListBaseOnOption(selectedField);
		this.getEditModeOnchange(selectedField);

		this.queryField.timeframeOption.userOptionType = selectedField.type;
		this.queryField.timeframeOption.name = selectedField.text;
		this.queryField.timeframeOption.timeframeType = selectedField.timeframeType;
		this.queryField.timeframeOption.type = null;
	}

	/**
	 * On operator selected from the expansion pannel
	 * @param {UnitOperator} selectedOperator - The selected operator
	 */
	async onOperatorSelected(selectedOperator: UnitOperator) {
		this.operatorIsDirty();
		this.setSquence(QuerySelectionType.operator);
		this.queryField.operator = selectedOperator;
		this.getEditModeOnchange(this.queryField.timeframeOption);
		setTimeout(() => this.setSquence(QuerySelectionType.join));
	}


	/**
	 * On value typed from the expansion pannel
	 * @param {any} value - The selected value
	 */
	onValueChanged(value: any) {
		this.queryField.value.value = value;
		this.queryField.value.name = value;
		this.queryField.value.code = value;
		this.setSquence(QuerySelectionType.join);
	}

	onDateChanged(date, startDate) {
		if (date) this.queryField.value.date = date;
		if (startDate) this.queryField.value.startDate = startDate;
		if (this.valueEditingMode !== TimeframeValueEditingMode.DATERANGEPICKER) {
			this.setSquence(QuerySelectionType.join);
		}
	}

	onEndDateChanged(endDate) {
		this.queryField.value.endDate = endDate;
		this.setSquence(QuerySelectionType.join);
	}

	/**
	 * On join operator selected
	 * @param {Operator} selectedJoinStatement - The join operator
	 */
	onJoinOperatorSelected(selectedJoinStatement: Operator) {
		this.queryField.join = selectedJoinStatement;
		if (selectedJoinStatement.value === QueryOperator.forJoin.doneOperator.value) {
			const timeframeDefinition =  this.buildTimeframeDefinition();
			this.queryField.timeframeDefinition = timeframeDefinition;
		}
		if (this.queryField.value.value && isNaN(this.queryField.value.value)) {
			this.flashMessageService.popMessage('enter valid number');
			return;
		}
		this.passTimeframeToBuilderComponent.emit();
	}

	buildTimeframeDefinition(): TimeframeDefinition {
		const timeframeField = this.queryField;
		const timeframeUserOptionType: UserTimeframeOptionType = timeframeField.timeframeOption.userOptionType;
		const timeframeUnit = timeframeField.operator.value ? timeframeField.operator.value : null;
		const interval = timeframeField.value.value && !isNaN(timeframeField.value.value) ? parseInt(timeframeField.value.value) : 0;
		let timeframeDefinition;
		const inclusive = (timeframeUserOptionType == UserTimeframeOptionType.Next || timeframeUserOptionType == UserTimeframeOptionType.Last) ? false : true;
		switch (timeframeUserOptionType) {
			case UserTimeframeOptionType.BeforeDate:
			case UserTimeframeOptionType.AfterDate:
			case UserTimeframeOptionType.ExactDate:
				timeframeDefinition = UserTimeframeOption.getFixedTimeFrameDefinition(timeframeUserOptionType, timeframeField.value.date);
				break;
			case UserTimeframeOptionType.DateRange:
				timeframeDefinition =  UserTimeframeOption.getFixedTimeFrameDefinition(timeframeUserOptionType, new Date(timeframeField.value.startDate), new Date(timeframeField.value.endDate));
				timeframeDefinition.endDate = new Date(timeframeField.value.endDate);
				break;
			default:
				timeframeDefinition =  UserTimeframeOption.getTimeFrameDefinition(timeframeUserOptionType, timeframeUnit, interval, inclusive);
		}

		return timeframeDefinition;
	}

	/**
	 * get the editing mode from the user timeframe option
	 */
	 getEditModeBaseOnTimeframeOption(selectedTimeframeOption) {
		switch (selectedTimeframeOption.timeframeType) {
			case TimeframeType.PeriodInterval:
				this.valueEditingMode = TimeframeValueEditingMode.FREETEXT_AND_SELECTION;
				this.setSquence(QuerySelectionType.none);
				break;
			case TimeframeType.SingleDate:
				this.valueEditingMode = TimeframeValueEditingMode.DATEPICKER;
				this.setSquence(QuerySelectionType.none);
				break;
			case TimeframeType.Range:
				this.valueEditingMode = TimeframeValueEditingMode.DATERANGEPICKER;
				this.setSquence(QuerySelectionType.none);
				break;
			case TimeframeType.Period:
			case TimeframeType.Simple:
				this.valueEditingMode = TimeframeValueEditingMode.DISABLED;
				this.setSquence(QuerySelectionType.none);
				break;
			default:
				this.setSquence(QuerySelectionType.field);
		}
	}

	getEditModeOnchange(selectedTimeframeOption) {
		switch (selectedTimeframeOption.timeframeType) {
			case TimeframeType.PeriodInterval:
				this.valueEditingMode = TimeframeValueEditingMode.FREETEXT_AND_SELECTION;
				this.setSquence(QuerySelectionType.operator);
				break;
			case TimeframeType.SingleDate:
				this.valueEditingMode = TimeframeValueEditingMode.DATEPICKER;
				this.setSquence(QuerySelectionType.value);
				break;
			case TimeframeType.Range:
				this.valueEditingMode = TimeframeValueEditingMode.DATERANGEPICKER;
				this.setSquence(QuerySelectionType.value);
				break;
			case TimeframeType.Period:
				this.valueEditingMode = TimeframeValueEditingMode.DISABLED;
				this.setSquence(QuerySelectionType.operator);
				break;
			case TimeframeType.Simple:
				this.valueEditingMode = TimeframeValueEditingMode.DISABLED;
				this.setSquence(QuerySelectionType.join);
				break;
			default:
				this.setSquence(QuerySelectionType.field);
		}
	}

	/**
	 * get the list of avaiable operators base on timeframe option selected
	 */
	 getUnitOperatorListBaseOnOption(selectedTimeframeOption) {
		if (selectedTimeframeOption.timeframeType == TimeframeType.PeriodInterval || selectedTimeframeOption.timeframeType == TimeframeType.Period) {
			this.unitOperatorList = Object.values(QueryOperator.forTimeframeInterval);
			this.showTimeframeOperatorComponent = true;
		} else {
			this.unitOperatorList = [];
			this.showTimeframeOperatorComponent = false;
		}
	}

	/**
	 * when fieldSelection opened, check to see if proper sequence is set or if the field list is empty
	 */
	onFieldSelectionOpened = () => {
		if (!this.queryField.operator) {
			this.setSquence(QuerySelectionType.field);
		}
	};

	/**
	 * when operator selection opened, check if proper sequnce is set
	 */
	onOperatorSelectionOpened = () => {
		if (!this.queryField.value) {
			this.setSquence(QuerySelectionType.operator);
		}
	};

	/**
	 * when value selection opened, check if proper sequnce is set
	 */
	onValueSelectionOpened = () => {
		if (this.valueEditingMode === TimeframeValueEditingMode.SELECTION && !this.queryField.value && !this.queryField.join) {
			this.setSquence(QuerySelectionType.value);
		}
	};

	/**
	 * set the sequence of the selection
	 * @param {QuerySelectionType} type - The selection type on the current sequence
	 */
	setSquence(type: QuerySelectionType) {
		this.itemToEdit = type;
	}


	/**
	 * When field selection component is dirty, delete the rest of the property
	 */
	fieldIsDirty() {
		this.queryField.value.name = '';
		this.queryField.value.code = '';
		this.queryField.value.startDate = '';
		this.queryField.value.endDate = '';
		if (this.calendarInput) {
			this.calendarInput.nativeElement.value = '';
		}
	}

	/**
	 * When the operator selection component is dirty, delete the rest of the property
	 */
	operatorIsDirty() {
		if (!this.queryField.value) {
			return;
		}
		this.queryField.value.name = '';
		this.queryField.value.code = '';
		this.queryField.value.value = '';
		this.queryField.value.startDate = '';
		this.queryField.value.endDate = '';
	}

	ngOnDestroy() {
		if (this.waitForDropDowns) clearInterval(this.waitForDropDowns);
	}
}
