import { VendorCostContract } from '../../contracts/work-order';
import { VendorCostWrapper } from './vendor-cost-wrapper.model';
import { VendorCost } from './vendor-cost.model';

export class VendorCostWrappers extends Array<VendorCostWrapper> {
	static fromContracts(contracts: VendorCostContract[]): VendorCostWrappers {
		const wrappersList = new VendorCostWrappers();
		for (const contract of contracts) wrappersList.push(new VendorCostWrapper(VendorCost.fromContract(contract)));

		return wrappersList;
	}

	static fromModels(models: VendorCost[]): VendorCostWrappers {
		const wrappersList = new VendorCostWrappers();
		for (const model of models) wrappersList.push(new VendorCostWrapper(model));
		return wrappersList;
	}

	getContracts = (): VendorCostContract[] => this.map(vc => vc.getContract());

	getModels = (): VendorCost[] => this.map(vc => vc.vendorCost);

	isDirty = (): boolean => !!this.filter(vc => vc.isDirty).length;

	clearDirty = (): void => this.forEach(vc => vc.clearDirty());
}
