import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { GuiConfig } from 'omni-model/gui-config.model';
import { TileListComponent } from 'app/tiles/tile-list.component';
import { CanvasContainerComponent } from 'app/canvas-container/canvas-container.component';
import { SearchBarComponent } from 'app/search/search-bar/search-bar.component';
import { MenuPanelComponent } from 'app/menu-panel/menu-panel.component';
import { TeamViewerComponent } from 'app/team-viewer/team-viewer.component';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { Metric, Color, MetricTile } from 'models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { ModifierKeys } from 'domain-service/ui';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { TabChangedHandler, TabChangedResult } from 'domain-service/subscriptions/handlers/tab-changed-handler';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { CanvasTab } from 'omni-model/canvas-tab.model';
import { TrendDefinition } from 'app/canvas-container/canvas-trend/trend-defintion.model';
import { TrendStyle } from 'app/menu-panel/trend-settings/trend-style/trend-style.model';
import { OmniSnackBarActionButton } from 'app/flash-message/omni-snack-bar/snackbar-action-button';
import { OmniSnackBarMessage } from 'app/flash-message/omni-snack-bar/snackbar-message';
import { MetricViewService } from 'app/metric/metric-view.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';

@Component({
	selector: 'app-main-content',
	templateUrl: './main-content.component.html',
	styleUrls: ['./main-content.component.css'],
	providers: [MetricViewService, OmniMapGraphicsService]
})
export class MainContentComponent implements AfterViewInit, OnDestroy {
	@Input() config: GuiConfig;

	@ViewChild(TileListComponent, { static: true }) tileListComponent: TileListComponent;

	@ViewChild(CanvasContainerComponent, { static: true }) canvasContainerComponent: CanvasContainerComponent;

	@ViewChild('rightPanel', { static: false }) rightPanelElement: ElementRef;

	@ViewChild(SearchBarComponent, { static: false }) searchBarComponent: SearchBarComponent;

	@ViewChild(MenuPanelComponent, { static: false }) menuPanelComponent: MenuPanelComponent;

	@ViewChild(TeamViewerComponent, { static: false }) teamViewerComponent: TeamViewerComponent;

	private _mode;
	/** Phone, tablet or large screen */
	@Input() set mode (m: string) {
		this._mode = m;
		if (this._mode === 'phone') {
			this.rightPanelHeight = window.innerHeight - 42 + 'px';
		}
		NavigationService.destroyNavigationGroup(this);
	}

	get mode () {
		return this._mode;
	}

	get active() {
		return this.config.isSelected;
	}

	get activeTile() {
		return this.tileListComponent.tileComponents.find(t => t.tile.tilePosition === this.config.selectedTile.tilePosition);
	}

	get activeCanvas() {
		return this.canvasContainerComponent.canvasComponents.find(c => c.isActive) ?? this.canvasContainerComponent.canvasComponents[0];
	}

	id: string;
	showTeamTab = false;

	tabChangesSubscription;

	rightPanelHeight: string;

	constructor(private scrollingService: ScrollToViewService, private interopService: OmniInteropService) {
		console.log('Main-Content Created');
	}

	ngOnInit() {
		this.id = this.config.id;
		const snackBarOptions: MatSnackBarConfig = {
			verticalPosition: 'bottom',
			horizontalPosition: 'right',
			panelClass: 'snack-bar-orange-action'
		};

		if (!this.interopService.omniDomain.subscriptionService.hub) return;
		const tabChangesHandler = this.interopService.omniDomain.subscriptionService.hub.methodHandlers.getHandler('TabChangedHandler') as TabChangedHandler;

		tabChangesHandler.onTabChanged.subscribe((handler: TabChangedHandler, args: TabChangedResult) => {
			const tab = this.interopService.omniDomain.guiConfigService.availableConfigurations.find(config => config.id === args.tabId);
			if (!tab) return;
			const dismissButton = new OmniSnackBarActionButton();
			dismissButton.label = 'DISMISS';
			dismissButton.onClick = () => this.interopService.omniDomain.snackBarService.closeSnackBar();
			const refreshButton = new OmniSnackBarActionButton();
			refreshButton.label = 'REFRESH';
			refreshButton.textColor = Color.createFromRGBA('rgba(245,167,66,0.8)');
			refreshButton.onClick = () => location.reload();
			const refreshMessage = new OmniSnackBarMessage();
			if (args.changeType === 'delete') refreshMessage.text = `Tab '${tab.name}' has been removed`;
			else refreshMessage.text = `The layout for tab '${tab.name}' has been changed`;
			this.interopService.omniDomain.snackBarService.openOmniSnackBar(refreshMessage, [refreshButton, dismissButton], snackBarOptions);
		});

		this.tileListComponent.onTileSelected = tileComponent => {
			return this.onTileSelected(tileComponent);
		};

		this.tileListComponent.onTileTimeframeChanged = tileComponent => {
			this.onTileTimeframeChanged(tileComponent);
		};
		this.canvasContainerComponent.onTabSelected = (canvasTab, defaultMetricRemoved) => {
			return this.onTabSelected(canvasTab, defaultMetricRemoved);
		};

		this.tileListComponent.onTileClickedWhenActive = tileComponent => {
			this.onTileSelected(tileComponent);
		};
	}

	ngOnDestroy(): void {
		NavigationService.destroyNavigationGroup(this);
		console.log('Main-Content Destroyed');
	}

	ngAfterViewInit() {
		this.scrollingService.menuPanel = this.rightPanelElement;
		this.menuPanelComponent.onInfoFormTrendIconClicked = trendDefinition => {
			this.onInfoFormTrendIconClicked(trendDefinition);
		};

		this.menuPanelComponent.onTileBackgroundColorChanged = (tile: MetricTile) => {
			this.onTileBackgroundColorChanged(tile);
		};

		this.menuPanelComponent.onTrendStyleChanged = trendStyle => {
			this.onTrendStyleChanged(trendStyle);
		};
	}

	/** when a tile gets clicked */
	onTileSelected(tileComponent: TileComponent) {
		this.canvasContainerComponent.onTileSelected(tileComponent);
		// navigate to metric results// emit selection to parent component
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		return NavigationService.navigateTo(Pages.metricResults, {
			tile: tileComponent.tile
		});
	}

	onInfoFormTrendIconClicked(trendDefinition: TrendDefinition) {
		this.canvasContainerComponent.onInfoFormTrendIconClicked(trendDefinition);
	}

	/** when a canvas tab gets selected */
	onTabSelected(canvasTab: CanvasTab, defaultMetricRemoved?: boolean) {
		const tileComponent = this.tileListComponent.tileComponents.find(tc => tc.tile === canvasTab.tile);
		this.tileListComponent.highlightTile(tileComponent);
		if (canvasTab.defaultMetric) return NavigationService.navigateTo(Pages.metricResults, { metric: canvasTab.defaultMetric });
		if (canvasTab.tile && !defaultMetricRemoved) NavigationService.navigateTo(Pages.metricResults, { tile: canvasTab.tile });
	}

	onActivated() {
		this.menuPanelComponent.onTabActivated();
	}

	onDeactivated() {
		this.menuPanelComponent.onTabDeactivated();
	}

	onMenuPanelNavigated(page: MenuPanelBaseComponent) {
		this.searchBarComponent.onMenuPanelNavigated(page);
	}

	onModifierKeysChanged(modifierKeys: ModifierKeys) {
		// this.canvasContainerComponent.onModifierKeysChanged(modifierKeys);
	}

	metricValueUpdated(metric: Metric) {}

	onTileTimeframeChanged(tile: TileComponent) {
		this.canvasContainerComponent.canvasComponents.forEach(canvasComponent => canvasComponent.onTileTimeframeChanged(tile));
		this.menuPanelComponent.navigationRouter.currentPage.onTileTimeframeChanged(tile);
	}

	onTileBackgroundColorChanged(tile: MetricTile) {
		this.canvasContainerComponent.onTileBackgroundColorChanged(tile);
	}

	onTrendStyleChanged(style: TrendStyle) {
		this.canvasContainerComponent.onTrendStyleChanged(style);
	}

	onMetricDefinitionChanged(metricId) {
		this.tileListComponent.onMetricDefinitionChanged(metricId);
		this.canvasContainerComponent.onMetricDefinitionChanged(metricId);
		this.menuPanelComponent.metricUpdated(metricId);
	}
}
