export class VendorCostContract {
	ChangeStatus: any;
	CreatedBy: string;
	Datestamp: string;
	Description: string;
	ObjectId: number;
	SystemId: string;
	TotalCost: number;
	VendorCostId: number;
	VendorID: string;
	WorkTaskId: string;
	WorkorderKey: string;
	Units: number;
}
