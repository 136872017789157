import {Observable} from '@apollo/client/core';
import { Component, ViewChild, Input, Output, EventEmitter, ViewChildren, QueryList, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GuiConfig } from 'omni-model/gui-config.model';
import { MatSidenav } from '@angular/material/sidenav';

import { MainContentComponent } from 'app/main-content/main-content.component';
import { ModifierKeys } from 'domain-service/ui';
import { PhotoSwipeComponent } from 'app/ui-components/photo-swipe/photo-swipe.component';
import { CalendarWidgetComponent } from 'app/ui-components/calendar-widget/calendar-widget.component';
import { ContextMenuComponent } from 'app/ui-components/context-menu/context-menu.component';
import { Queue } from 'sedaru-util';
import { TabPanelComponent } from 'app/tab-panel/tab-panel.component';
import { StartingPacifierResolver } from 'app/ui-components/pacifiers/starting-pacifier/starting-pacifier-resolver';
import { SedaruWallComponent } from 'app/ui-components/sedaru-wall/sedaru-wall/sedaru-wall.component';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
	/**
	 * The tab panel view element is loaded.
	 */
	@ViewChild('tabpanel', { static: true }) public sideNavDrawer: MatSidenav;

	@ViewChild(TabPanelComponent) tabPanelComponent: TabPanelComponent;

	@ViewChild('photoViewer', { static: true }) public photoViewer: PhotoSwipeComponent;

	@ViewChild('dateTimePicker', { static: true }) public dateTimePicker: CalendarWidgetComponent;

	@ViewChild(AlertDialogComponent, { static: true }) public alertDialog: AlertDialogComponent;

	@ViewChild(ContextMenuComponent, { static: true }) contextMenu: ContextMenuComponent;

	@ViewChild(SedaruWallComponent) sedaruWall: SedaruWallComponent;

	/** the child component that holds the tiles, canvas and menu panel */
	@ViewChildren(MainContentComponent)
	private set acivatedTabsContent(contents: QueryList<MainContentComponent>) {
		this.activeTabContent = contents.find(c => c.config.isSelected);
	}

	public activeTabContent: MainContentComponent;

	get activeGuiConfig() {
		return this.availableConfigs.find(config => config.isSelected);
	}

	/** available configurations in the gui config service */
	private _availableConfigs: Array<GuiConfig>;
	@Input() set availableConfigs(configs: Array<GuiConfig>) {
		this._availableConfigs = configs;
		this.activatedConfigs = new Queue<GuiConfig>();
		this.activatedConfigs.max = 4;
		this.activatedConfigs.enqueueOrMove(this.activeGuiConfig);
	}
	get availableConfigs() {
		return this._availableConfigs;
	}
	/** when the user requests to add a new tab to the tab panel component */
	@Input() onNewTabRequested: () => Observable<GuiConfig>;

	private _loading: boolean;
	@Input() set loading(value: boolean) {
		this._loading = value;
		if (value === false) {
			this.sedaruWall.close();
		}
	}
	get loading() {
		return this._loading;
	}
	/** when the user selects a new tab from the tab panel component */
	@Output() tabSelected = new EventEmitter<GuiConfig>();
	/** Phone, tablet or large screen */
	mode: string;

	activatedConfigs: Queue<GuiConfig>;

	pacifierResolver = new StartingPacifierResolver();

	windowHeight: number;

	constructor(private breakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
			if (result.matches) {
				this.mode = 'phone';
				if (this.sideNavDrawer) this.sideNavDrawer.close();
			}
		});
		this.breakpointObserver.observe([Breakpoints.Medium]).subscribe(result => {
			if (result.matches) {
				this.mode = 'tablet';
				if (this.sideNavDrawer) this.sideNavDrawer.close();
			}
		});
		this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
			if (result.matches) {
				this.mode = 'large';
				if (this.sideNavDrawer) this.sideNavDrawer.open();
			}
		});

		this.windowHeight = window.innerHeight;
	}

	selectTab(tab: GuiConfig) {
		this.activatedConfigs.enqueueOrMove(tab);
		this.tabSelected.emit(tab);
	}

	toggleTabPanel() {
		if (this.sideNavDrawer.mode === 'side') return;
		this.sideNavDrawer.toggle();
	}

	getBackgroundUrl() {
		if (this.activeGuiConfig) {
			if (this.activeGuiConfig.theme.displayName === 'OMNI default') return { 'background-color': this.activeGuiConfig.theme.backgroundColor };
			return { 'background-image': 'url("' + this.activeGuiConfig.theme.backgroundURL + '")' };
		}
		return { 'background-color': '#282828' };
	}

	shouldWarnDataDirty(): boolean {
		return false;
	}

	onModifierKeysChanged(modifierKeys: ModifierKeys) {
		// this.contents.forEach(mc => {
		// 	if (mc.config.id !== this.activeGuiConfig.id) return;
		// 	mc.onModifierKeysChanged(modifierKeys);
		// });
	}
}
