import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import * as SedaruUtils from '../../../sedaru-util';
import { AdvancedWorkOrderContractWithoutNestedFields } from 'contracts/work-order';

export class WorkOrderUpdateError {
	workOrderKey: string;
	validationError: string;
}

export class BulkUpdateChangedResult extends Subscriptions.MethodHandlerResult<BulkUpdateChangedHandler> {
	batchId: string;
	exception: string;

	public get hasException(): boolean {
		if (this.exception) return true;
		return false;
	}

	public get processedCount(): number {
		let count = 0;
		if (this.workOrderKeysWithSuccess) count += this.workOrderKeysWithSuccess.length;
		if (this.workOrderKeysWithError) count += this.workOrderKeysWithError.length;

		return count;
	}

	private _workOrderKeysWithSuccess: Array<string>;
	public get workOrderKeysWithSuccess(): Array<string> {
		if (!this._workOrderKeysWithSuccess) this._workOrderKeysWithSuccess = new Array<string>();

		return this._workOrderKeysWithSuccess;
	}
	private _workOrderKeysWithError: Array<WorkOrderUpdateError>;
	public get workOrderKeysWithError(): Array<WorkOrderUpdateError> {
		if (!this._workOrderKeysWithError) this._workOrderKeysWithError = new Array<WorkOrderUpdateError>();

		return this._workOrderKeysWithError;
	}
	private _workOrderKeysPending: Array<string>;
	public get workOrderKeysPending(): Array<string> {
		if (!this._workOrderKeysPending) this._workOrderKeysPending = new Array<string>();

		return this._workOrderKeysPending;
	}

	constructor(handler: BulkUpdateChangedHandler) {
		super(handler);
	}
}

export class BulkUpdateChangedHandler extends Subscriptions.SedaruClientMethodHandlerBase {

	constructor() {
		super();
	}

	private _onBulkUpdateProgress: SedaruUtils.InvokableEvent;
	public get onBulkUpdateProgress(): SedaruUtils.InvokableEvent {
		if (!this._onBulkUpdateProgress) this._onBulkUpdateProgress = new SedaruUtils.InvokableEvent();

		return this._onBulkUpdateProgress;
	}

	private _onBulkUpdateCompleted: SedaruUtils.InvokableEvent;
	public get onBulkUpdateCompleted(): SedaruUtils.InvokableEvent {
		if (!this._onBulkUpdateCompleted) this._onBulkUpdateCompleted = new SedaruUtils.InvokableEvent();

		return this._onBulkUpdateCompleted;
	}

	private _onBulkUpdateCancelled: SedaruUtils.InvokableEvent;
	public get onBulkUpdateCancelled(): SedaruUtils.InvokableEvent {
		if (!this._onBulkUpdateCancelled) this._onBulkUpdateCancelled = new SedaruUtils.InvokableEvent();

		return this._onBulkUpdateCancelled;
	}

	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdateprogress' || methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdatecompleted'
			|| methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdatecancelled';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		console.log('packet.methodIdentifier', packet.methodIdentifier);
		return new Promise<Subscriptions.MethodHandlerResultBase>((resolve, reject) => {
			const data = JSON.parse(packet.data);

			const bulkUpdateResult = new BulkUpdateChangedResult(this);
			if (!data || !data.batchId || !data.results) {
				bulkUpdateResult.message = 'payload was invalid';
				resolve(bulkUpdateResult);
				return;
			}

			bulkUpdateResult.batchId = data.batchId;
			bulkUpdateResult.exception = data.exception;

			if (data.results.successful) {
				for (const successfulResult of data.results.successful) bulkUpdateResult.workOrderKeysWithSuccess.push(successfulResult)
			}
			if (data.results.failed) {
				for (const f of data.results.failed) {
					const u = new WorkOrderUpdateError();
					u.validationError = f.validationMessage;
					u.workOrderKey = f.workOrderKey;
					bulkUpdateResult.workOrderKeysWithError.push(u);
				}
			}
			if (data.results.unprocessed) {
				for (const unprocessedResult of data.results.unprocessed) bulkUpdateResult.workOrderKeysPending.push(unprocessedResult);
			}

			if (packet.methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdateprogress') {
				this.onBulkUpdateProgress.invoke(this, bulkUpdateResult);
			} else if (packet.methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdatecompleted') {
				this.onBulkUpdateCompleted.invoke(this, bulkUpdateResult);
			} else if (packet.methodIdentifier.toLowerCase() === 'sedaruconnect.bulkupdatecancelled') {
				this.onBulkUpdateCancelled.invoke(this, bulkUpdateResult);
			}

			args.isHandled = true;
			resolve(bulkUpdateResult);
			return;
		});
	}

	onGetUniqueId(): string {
		return 'BulkUpdateChangedHandler'; // do not user Class.name because it doesn't work with minified code.
	}

	async sendWorkOrderBulkUpdate(workOrderList: AdvancedWorkOrderContractWithoutNestedFields[], batchId: string, customerCode: string) {
		if (!workOrderList || !customerCode) return;

		const data = { batchId, workOrderList };
		console.log('sendWorkOrderBulkUpdate omni.bulkupdate');
		await this.hub.sendNotification('omni.bulkupdate', JSON.stringify(data), customerCode);
	}

	async sendCancelWorkOrderBulk(batchId: string, customerCode: string) {
		if (!batchId || !customerCode) return;

		const data = { batchId };
		console.log('sendWorkOrderBulkUpdate omni.cancelbulkupdate');
		await this.hub.sendNotification('omni.cancelbulkupdate', JSON.stringify(data), customerCode);
	}
}
