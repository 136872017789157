import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WarningMessageService {
	type: 'public-changes' | 'private-to-public';
	get message() {
		switch (this.type) {
			case 'public-changes':
				return 'Changes made to this tab will likely impact other users that share this tab. Are you sure you want to continue?';

			case 'private-to-public':
				return "This change will add a tab to other users' sessions which can be disruptive. Are you sure you want to continue?";
		}
	}

	get header() {
		switch (this.type) {
			case 'public-changes':
				return 'This tab is not private';
			case 'private-to-public':
				return 'Do you want to make this tab public?';
		}
	}
	constructor() {}
}
