import { Component, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { EndBoundaryType, Pattern, RecurrenceDefinition, RecurrencePatternType, RecurrenceTemplate, StatusType, TriggerType } from 'models/recurrence';
import { Recurrence } from 'models/recurrence/recurrence.model';
import { CustomFieldWrappers, Status, WorkOrderSummary } from 'models/work-order';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { RecurrenceProvider } from 'providers/recurrence.provider';
import { DateUtil } from 'sedaru-util';
import { RecurrenceFieldType } from './recurrence-field-selection/recurrence-field-selection.component';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-workorder-recurrence',
	templateUrl: './workorder-recurrence.component.html',
	styleUrls: ['./workorder-recurrence.component.scss']
})
export class WorkOrderRecurrenceComponent extends MenuPanelBaseComponent {
	fieldFullSize = MenuPanelFieldSize.full;
	fieldHalfSize = MenuPanelFieldSize.half;

	recurrenceTemplate: RecurrenceTemplate;

	workOrderSummary: WorkOrderSummary;

	get recurrencePattern(): RecurrenceDefinition {
		return this.recurrenceTemplate?.recurrencePattern;
	}

	get pattern(): Pattern {
		return this.recurrencePattern?.pattern;
	}

	get isDailyPattern(): boolean {
		return this.recurrencePattern?.type === RecurrencePatternType.Daily;
	}

	get isMonthlyPattern(): boolean {
		return this.recurrencePattern?.type === RecurrencePatternType.Monthly;
	}

	get isWeeklyPattern(): boolean {
		return this.recurrencePattern?.type === RecurrencePatternType.Weekly;
	}

	get isYearlyPattern(): boolean {
		return this.recurrencePattern?.type === RecurrencePatternType.Yearly;
	}

	get isEdit(): boolean {
		return this.workOrderSummary?.action === WorkOrderSummaryActions.EditRecurrenceTemplate;
	}

	get isReadOnly(): boolean {
		return this.recurrenceTemplate?.isReadOnly;
	}

	get lowerCaseType(): string {
		return this.recurrencePattern?.type?.toLowerCase();
	}

	get showRecurrenceFields(): boolean {
		return this.recurrencePattern?.type && this.recurrencePattern?.type !== RecurrencePatternType.None;
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private recurrenceProvider: RecurrenceProvider,
		private alertDialogService: AlertDialogService,
		private interop: OmniInteropService
	) {
		super(view);
	}

	ngOnInit(): void {}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.workOrderSummary = args.parameter.workOrderSummary;
		this.recurrenceTemplate = args.parameter.recurrenceTemplate;
		this.menuPanelComponent.updateView({
			title: 'work order recurrence',
			backgroundClass: 'orange-background'
		});
	}

	goToFrequencySelection = (): void => {
		if (!this.recurrencePattern) return;

		NavigationService.navigateTo(Pages.recurrenceFieldSelection, {
			listItems: this.isEdit ? this.recurrencePattern.recurrenceTypeList.filter(f => f.name !== 'none')
				:  this.recurrencePattern.recurrenceTypeList,
			selectedItems: this.recurrencePattern.recurrenceTypeList.filter(t => t.key === this.recurrencePattern.type),
			recurrencePattern: this.recurrencePattern,
			recurrenceFieldType: RecurrenceFieldType.Frequency
		});
	};

	handleDaysOfWeekClicked(event: any): void {
		const weeklyPattern = this.pattern?.weeklyPattern;
		if (!weeklyPattern) return;

		NavigationService.navigateTo(Pages.recurrenceFieldSelection, {
			listItems: weeklyPattern.daysList,
			selectedItems: weeklyPattern.daysList.filter(d => weeklyPattern.daysOfWeek.includes(d.key)),
			recurrencePattern: this.recurrencePattern,
			recurrenceFieldType: RecurrenceFieldType.DaysOfWeek
		});
	}

	async onDelete(): Promise<void> {
		if (this.isReadOnly) return;

		const isConfirmed = await this.alertDialogService.openConfirmDialog('Deleting Recurrence', 'This action will delete the work order recurrence and cannot be undone', 'yes, continue', 'cancel');
		if (!isConfirmed) return;

		this.recurrencePattern.status = StatusType.Disabled;
		this.menuPanelComponent.updatePacifier(true);
		const { Success } = await this.recurrenceProvider.updateRecurrenceTemplate(this.recurrenceTemplate);
		this.menuPanelComponent.updatePacifier(false);

		if (!Success) {
			this.recurrencePattern.undoAll();
			this.alertValidationErrors('Unable to delete recurrence');
			return;
		}

		this.workOrderSummary.workOrderTemplate = this.recurrenceTemplate;
		this.recurrencePattern.clearDirty();
		NavigationService.navigateBackTo();
	}

	isDirty(args: NavigationArgs): Promise<boolean> {
		return new Promise<boolean> (resolve => resolve(args.isNavigatingBack && this.recurrencePattern.isDirty));
	}

	onSave(): void {
		if (this.isReadOnly) return;

		if (!this.recurrencePattern.type || this.recurrencePattern.type === RecurrencePatternType.None) {
			this.workOrderSummary.workOrderTemplate = null;
			this.workOrderSummary.resetAction();
			this.recurrencePattern.clearDirty();
			NavigationService.navigateBackTo();
			return;
		}

		this.recurrencePattern.status = StatusType.Active;
		this.recurrencePattern.trigger = TriggerType.OnSchedule;

		const { endBoundary } = this.recurrencePattern;
		endBoundary.endType = endBoundary.endDate && DateUtil.isValidDate(endBoundary.endDate) ? EndBoundaryType.Finite : EndBoundaryType.Infinite;
		this.setYearlyPattern();

		if (!this.validateRecurrence()) return;

		this.workOrderSummary.workOrderTemplate = this.recurrenceTemplate;
		if (this.workOrderSummary.isCreationMode) this.workOrderSummary.action = WorkOrderSummaryActions.CreateRecurrenceTemplate;
		this.recurrencePattern.clearDirty();
		NavigationService.navigateBackTo();
	}

	private setYearlyPattern(): void {
		if (this.recurrencePattern.type !== RecurrencePatternType.Yearly) return;
		const yearlyPattern = this.pattern?.yearlyPattern;
		const month = this.recurrencePattern?.startDate?.getMonth() + 1;
		const day = this.recurrencePattern?.startDate?.getDate();
		if (!month || !day) return;

		yearlyPattern.month = month;
		yearlyPattern.monthlyPattern.exactDay = day;
		return;
	}

	private validateRecurrence(): boolean {
		const emptyRequiredFields = this.getEmptyRequiredFields();
		if (emptyRequiredFields.length) {
			let errorsString = '';
			for (const field of emptyRequiredFields) errorsString += `<br />- ${field}`;

			this.alertValidationErrors('Following fields are required: ' + errorsString);
			return false;
		}

		if (!this.isValidDateRange()) {
			this.alertValidationErrors('Invalid date range');
			return false;
		}

		if (!this.hasInstances()) {
			this.alertValidationErrors('Selected recurrence will not generate any work orders');
			return false;
		}

		return true;
	}

	private getEmptyRequiredFields(): string[] {
		const missingFields = new Array<string>();
		if (!this.recurrencePattern.type) {
			missingFields.push('frequency');
			return missingFields;
		}

		switch (this.recurrencePattern.type) {
			case RecurrencePatternType.Daily:
				if (!this.pattern.interval) missingFields.push('every # of days');
				break;
			case RecurrencePatternType.Weekly:
				if (!this.pattern.interval) missingFields.push('every # of weeks');
				break;
			case RecurrencePatternType.Monthly:
				if (!this.pattern.interval) missingFields.push('every # of months');
				break;
			case RecurrencePatternType.Yearly:
				if (!this.pattern.interval)	missingFields.push('every # of years');
				break;
		}
		if (!this.recurrenceTemplate.name) missingFields.push('description');
		if (!this.recurrencePattern.startDate || !DateUtil.isValidDate(this.recurrencePattern.startDate)) missingFields.push('start date');

		switch (this.recurrencePattern.type) {
			case RecurrencePatternType.Weekly:
				if (!this.pattern.weeklyPattern.daysOfWeek.length) missingFields.push('days of the week');
				break;
			case RecurrencePatternType.Monthly:
				if (!this.pattern.monthlyPattern.exactDay) missingFields.push('day of month');
				break;
		}

		return missingFields;
	}

	private isValidDateRange(): boolean {
		const { endBoundary } = this.recurrencePattern;
		if (endBoundary.endType === EndBoundaryType.Infinite) return true;
		if (this.recurrencePattern.startDate > endBoundary.endDate) return false;
		return true;
	}

	private hasInstances(): boolean {
		return new Recurrence(this.recurrencePattern)?.getNumberOfInstances() !== 0;
	}

	private alertValidationErrors(errorMessage: string): void {
		this.alertDialog.mainMessage = { text: errorMessage };
		this.alertDialog.open = true;
	}
}
