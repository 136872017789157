import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MapSelectionMode, MapSelectionModes } from './map-selection-mode.model';

@Component({
	selector: 'app-map-selection-toolbar',
	templateUrl: './map-selection-toolbar.component.html',
	styleUrls: ['./map-selection-toolbar.component.scss']
})
export class MapSelectionToolbarComponent implements OnInit {
	@Output() selectionModeChanged = new EventEmitter<MapSelectionModes>();
	isOpen = false;
	selectionModes: Array<MapSelectionMode>;
	activeMode: MapSelectionMode;

	constructor() {}

	ngOnInit(): void {
		this.selectionModes = [
			{
				name: 'pan',
				displayText: 'pan & select',
				icon: 'assets/selection-pan.png'
			},
			{
				name: 'assets',
				displayText: 'select assets',
				icon: 'assets/maplocation.png'
			},
			{
				name: 'metrics',
				displayText: 'select metrics',
				icon: 'assets/selection-metrics.png'
			}
		];
	}

	selectMode(selectedMode: MapSelectionModes) {
		this.activeMode = this.selectionModes.find(m => m.name === selectedMode);
		const index = this.selectionModes.findIndex(mode => mode.name === selectedMode);
		[this.selectionModes[0], this.selectionModes[index]] = [this.selectionModes[index], this.selectionModes[0]];
		this.selectionModeChanged.emit(selectedMode);

		this.isOpen = false;
	}
}
