import { OmniInteropService } from './omni-interop.service';
import { OmniBootstrapService } from './omni-bootstrap.service';

export class ManagerBase {
	protected get domain(): OmniBootstrapService {
		return this.interopService.omniDomain;
	}

	constructor(protected interopService: OmniInteropService) {}
}
