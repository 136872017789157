import { AnalyticsHub } from './analytics-hub';
import { IDisposable } from '../idisposable';

export abstract class AnalyticsItem implements IDisposable {
	payload: any;

	protected constructor() {}

	/**
	 * Submits to the hub
	 */
	public submit(): void {
		this.onSubmit();
	}
	protected abstract onSubmit(): void;

	dispose() {
		this.onDispose(true);
	}

	protected onDispose(disposing: boolean) {}
}
