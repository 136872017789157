import { ContractBase } from '../contract-base';
import { CustomFieldContract } from './custom-field-contract';

export class TaskContract extends ContractBase {
	public ObjectId: number;
	public TaskId: string;
	public Name: string;
	public Description: string;
	public WOTemplateId: string;
	public AssignedTo: string;
	public Comments: string;
	public CustomFieldGroup: string;
	public TaskCustomFields: CustomFieldContract[];
}
