import { Injectable } from '@angular/core';
import { AssociatedAssetContract, RecurrenceTemplateContract } from 'contracts/recurrence';
import { ContractResponse, ContractResponseBase } from 'contracts/work-order';
import { IHttpResponse } from 'domain-service/http-response.model';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RecurrenceWorkOrderHttpService } from './recurrence-work-order-http.service';

@Injectable({
    providedIn: 'root'
})
export class RecurrenceWorkOrderService {
    constructor(private rws: RecurrenceWorkOrderHttpService) { }

    //#region Recurrence Template CRUD
    postRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplateContract, customerCode: string, profileGroup: string = ''): Observable<ContractResponseBase> {
        recurrenceTemplate['customerCode'] = customerCode;
        if (profileGroup) recurrenceTemplate['profileGroup'] = profileGroup;

		return this.rws.post('/RecurrenceTemplate', recurrenceTemplate).pipe(
            map((response: IHttpResponse) => {
                const result = new ContractResponseBase();
                result.Success = response?.Success;
                result.Message = response?.ErrorMessage;

                return result;
            }),
            catchError(err => this.rws.handlerError(err))
        );
	}

    getRecurrenceTemplate(recurrenceTemplateId: string): Observable<ContractResponse<RecurrenceTemplateContract>> {
		return this.rws.get('/RecurrenceTemplate', { id : recurrenceTemplateId }).pipe(
            map((response: IHttpResponse) => {
                const result = new ContractResponse<RecurrenceTemplateContract>();
                result.Success = response?.Success;
                result.Message = response?.ErrorMessage;
                result.Result = response['RecurringWorkorderTemplate'] as RecurrenceTemplateContract;

                return result;
            }),
            catchError(err => this.rws.handlerError(err))
        );
	}

    updateRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplateContract, customerCode: string): Observable<ContractResponseBase> {
        recurrenceTemplate['customerCode'] = customerCode;

		return this.rws.put('/RecurrenceTemplate', recurrenceTemplate, { id : recurrenceTemplate.id }).pipe(
            map((response: IHttpResponse) => {
                const result = new ContractResponseBase();
                result.Success = response?.Success;
                result.Message = response?.ErrorMessage;

                return result;
            }),
            catchError(err => this.rws.handlerError(err))
        )
	}

    removeRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplateContract): Observable<ContractResponseBase> {
		return this.rws.delete('/RecurrenceTemplate', {id : recurrenceTemplate.id}).pipe(
            map((response: IHttpResponse) => {
                const result = new ContractResponseBase();
                result.Success = response?.Success;
                result.Message = response?.ErrorMessage;

                return result;
            }),
            catchError(err => this.rws.handlerError(err))
        )
	}
    //#endregion

    //#region Associated Assets CRUD
    updateRecurrenceTemplateAssets(templateId: string, assetsToAdd?: AssociatedAssetContract[],
        assetsToRemove?: AssociatedAssetContract[]): Observable<ContractResponseBase> {

        if (!templateId) return null;
        const associatedAssets = new Array<any>();
        if (assetsToAdd) for (const asset of assetsToAdd) associatedAssets.push(this.getAssociatedAssetObject(asset, true));
        if (assetsToRemove) for (const asset of assetsToRemove) associatedAssets.push(this.getAssociatedAssetObject(asset, false));

        const postData = {
            'recurrenceTemplateId': templateId,
            'associatedAssets': associatedAssets
        };

        return this.rws.post('/RecurrenceTemplate/Assets', postData).pipe(
            map((response: IHttpResponse) => {
                const result = new ContractResponseBase();
                result.Success = response?.Success;
                result.Message = response?.ErrorMessage;

                return result;
            }),
            catchError(err => this.rws.handlerError(err))
        );
    }

    private getAssociatedAssetObject(asset: AssociatedAssetContract, isAdd: boolean): any {
        return {
            'assetType': asset.assetType,
            'assetId': asset.assetId,
            'action': isAdd ? 'add' : 'remove'
        };
    }
    //#endregion
}
