<div mat-dialog-container>
	<div *ngIf="canvasToRemove.length">
		<h1 mat-dialog-title>
			Remove canvases?
		</h1>
		<div mat-dialog-content>
			Canvas
			<span *ngFor="let canvas of canvasToRemove; let i = index">
				<span *ngIf="i > 0 && i !== canvasToRemove.length - 1">, </span>
				<span *ngIf="i > 0 && i === canvasToRemove.length - 1"> and </span>
				{{ canvas.position }}
			</span>
			<span *ngIf="canvasToRemove.length > 1"> are </span>
			<span *ngIf="canvasToRemove.length === 1"> is </span>
			not empty and the content will be lost. Are you sure you want to do this?
		</div>
	</div>
	<div *ngIf="tilesToRemove.length">
		<h1 mat-dialog-title>
			Remove Tiles?
		</h1>
		<div mat-dialog-content>
			Tile
			<span *ngFor="let tile of tilesToRemove; let i = index">
				<span *ngIf="i > 0 && i !== tilesToRemove.length - 1">, </span>
				<span *ngIf="i > 0 && i === tilesToRemove.length - 1"> and </span>
				{{ tile.tilePosition }}
			</span>
			<span *ngIf="tilesToRemove.length > 1"> are </span>
			<span *ngIf="tilesToRemove.length === 1"> is </span>
			not empty and the content will be lost. Are you sure you want to do this?
		</div>
	</div>
	<div mat-dialog-actions>
		<span style="flex:1"></span>
		<button mat-raised-button color="warn" (click)="onClose(true)">
			yes
		</button>
		<button mat-raised-button (click)="onClose(false)">
			no
		</button>
	</div>
</div>
