import { Component, Input, OnInit } from '@angular/core';
import { LayerLegend } from 'omni-model/canvas-map.model';

@Component({
	selector: 'app-legend-info',
	templateUrl: './legend-info.component.html',
	styleUrls: ['./legend-info.component.scss']
})
export class LegendInfoComponent implements OnInit {
	/**
	 * legend info of the esri map
	 */
	@Input() public legend;

	/**
	 *  The constructor doesn't do anything aside from loading and injecting dependencies.
	 */
	constructor() {}

	ngOnInit() {}
}
