import { CredentialsList } from './credentials.model';
import { DataSources } from './data-source.model';
import { Proxies } from './proxy.model';
import { Channels, Channel } from './channel.model';
import { AssetDefinitions } from './asset-definitions.model';
import { Colors } from './sedaru-config/style.model';
import { WorkOrderModeType } from './work-order-mode-type.enum';
import { ChannelTypes } from './channel-type.enum.model';
import { WorkOrderChannelAttributes } from './work-order-channel-attributes.model';
import { AssetDefinition } from './asset-definition.model';
import { ArcGISAssetChannelAttributes } from './arc-gis-asset-channel-attributes.model';
import { DataSourceType } from './data-source-type.enum';
import { Styles } from './sedaru-config/styles.model';
import { Resources } from './sedaru-config/resources.model';
import { Templates } from './sedaru-config/templates.model';
import { Map } from './map.model';
import { FormatProperties } from './sedaru-config/format-properties.model';

export class GlobalConfig {
	customerCode: string;

	private _formatProperties: FormatProperties;
	get formatProperties(): FormatProperties {
		if (!this._formatProperties) {
			this._formatProperties = new FormatProperties();
		}

		return this._formatProperties;
	}

	private _colors: Colors;
	get colors(): Colors {
		if (!this._colors) {
			this._colors = new Colors();
		}

		return this._colors;
	}

	private _credentials: CredentialsList;
	get credentials(): CredentialsList {
		if (!this._credentials) {
			this._credentials = new CredentialsList();
		}

		return this._credentials;
	}

	private _dataSources: DataSources;
	get dataSources(): DataSources {
		if (!this._dataSources) {
			this._dataSources = new DataSources();
		}
		return this._dataSources;
	}

	private _proxies: Proxies;
	get proxies(): Proxies {
		if (!this._proxies) {
			this._proxies = new Proxies();
		}
		return this._proxies;
	}

	private _channels: Channels;
	get channels(): Channels {
		if (!this._channels) {
			this._channels = new Channels();
		}
		return this._channels;
	}

	private _assetDefinitions: AssetDefinitions;
	get assetDefinitions(): AssetDefinitions {
		if (!this._assetDefinitions) {
			this._assetDefinitions = new AssetDefinitions();
		}
		return this._assetDefinitions;
	}

	private _resources: Resources;
	get resources(): Resources {
		if (!this._resources) {
			this._resources = new Resources();
		}

		return this._resources;
	}

	private _templates: Templates;
	get templates(): Templates {
		if (!this._templates) this._templates = new Templates();

		return this._templates;
	}

	private _styles: Styles;
	public get styles(): Styles {
		if (!this._styles) this._styles = new Styles();

		return this._styles;
	}

	private _map: Map;
	public get map(): Map {
		return this._map;
	}
	public set map(value: Map) {
		if (this._map == value) return;

		this._map = value;
	}

	public get workOrderChannel(): Channel {
		return this.channels.find(c => c.channelType === ChannelTypes.WorkOrder);
	}

	public get workOrderMode(): WorkOrderModeType {
		const channel = this.workOrderChannel;

		if (!channel) return WorkOrderModeType.None;

		const attributes = channel.attributes as WorkOrderChannelAttributes;
		if (!attributes) return WorkOrderModeType.None;

		return attributes.workOrderMode;
	}

	private _sedaruWorkTypes: Array<string>;
	public get sedaruWorkTypes(): Array<string> {
		if (!this._sedaruWorkTypes) {
			let mArray = new Array<string>();
			for (const assetDefinition of this.assetDefinitions) {
				mArray = mArray.concat(assetDefinition.sedaruWorkTypes);
			}
			this._sedaruWorkTypes = mArray;
		}

		return this._sedaruWorkTypes;
	}

	static create(): GlobalConfig {
		return new GlobalConfig();
	}

	public getAssetDefinition(legacyDataSourceId: number, layerIndex: number): AssetDefinition;
	public getAssetDefinition(assetType: string): AssetDefinition;
	public getAssetDefinition(param1: number | string, param2?: number): AssetDefinition {
		if (typeof param1 === 'string') {
			return this.handleGetAssetDefinition2(param1);
		}

		return this.handleGetAssetDefinition1(param1, param2);
	}
	private handleGetAssetDefinition1(legacyDataSourceId: number, layerIndex: number): AssetDefinition {
		const assetChannels = this.channels.filter(c => c.channelType === ChannelTypes.Asset);

		const channel = assetChannels.find(c => {
			const cAttributes = c.attributes as ArcGISAssetChannelAttributes;
			if (!cAttributes) return false;

			if (cAttributes.mapServiceLayerIndex !== layerIndex && cAttributes.featureServiceLayerIndex !== layerIndex) return false;

			if (c.dataSourceLegacyId !== legacyDataSourceId && cAttributes.mapServiceDataSourceLegacyId !== legacyDataSourceId) return false;

			return true;
		});

		if (!channel) return null;

		const attributes = channel.attributes as ArcGISAssetChannelAttributes;
		if (!attributes) return null;

		const assetDef = this.assetDefinitions.find(a => a.assetType.toLowerCase() === attributes.assetType.toLowerCase());

		if (!assetDef) return null;

		return assetDef;
	}
	private handleGetAssetDefinition2(assetType: string): AssetDefinition {
		const assetDef = this.assetDefinitions.find(a => a.assetType.toLowerCase() === assetType.toLowerCase());

		if (!assetDef) return null;

		return assetDef;
	}
	private constructor() { }
}
