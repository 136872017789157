<div class="scrolling-content">
	<mat-action-list class="omni-list-panel">
		<div class="omni-list-item-wrapper" *ngFor="let assetDefinition of assetDefinitions">
			<mat-list-item class="omni-list-panel-item" (click)="selectAssetType(assetDefinition)">
				<img *ngIf="assetDefinition.iconResourceId > 0" [src]="getAssetIcon(assetDefinition)" width="30px" height="30px" class="left-icon" />
				<span>{{ assetDefinition.title }}</span>
			</mat-list-item>
			<mat-divider class="divider-item"></mat-divider>
		</div>
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<span class="s-font-size">* use Sedaru Studio to add and edit assets</span>
</mat-card-footer>
