<label class="task-list-header">{{ title }}</label>
<mat-divider class="task-list-divider"></mat-divider>
<div class="list-wrapper">
    <app-omni-list #updateErrorsList
        [omniListItems]="workOrderList"
        (itemClicked)="handleClick($event)"
        (itemHovered)="handleHover($event)"
        (mouseLeft)="closePopUp()">
    </app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
    <mat-divider class="footer-divider"></mat-divider>
    <mat-card-actions align="end">
        <label class="omni-md-font">items: {{ workOrderList.length }} &nbsp;</label>
    </mat-card-actions>
</mat-card-footer>