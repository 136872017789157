import { FormControlValue } from '../form-control-value.model';


export enum BindingPlaceholders {
    Header,
    Details,
    Extra,
    Text
}

export class Binding {
    placeholder: BindingPlaceholders;

    value: FormControlValue;
}
