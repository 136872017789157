import { AssetLocationsWithAssets } from './asset-locations-with-assets.model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';
import { CustomFields } from './custom-fields.model';
import { WorkAssets } from './work-assets.model';
import { EmployeeCosts } from './employee-costs.model';
import { MaterialCosts } from './material-costs.model';
import { EquipmentCosts } from './equipment-costs.model';
import { WorkTasks } from './work-tasks.model';
import { WorkInspections } from './work-inspections.model';
import { Attachments } from './attachments.model';
import { WorkComments } from './work-comments.model';
import { VendorCosts } from './vendor-costs.model';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';
import { FailureReports } from './failure-reports.model';

/**
 * This defines the sedaru work order
 */
export class AdvancedWorkOrder extends OmniModel<AdvancedWorkOrder, Contracts.WorkOrderContract> {
	hasOnlyBasicData = true;

	isNew = true;

	/**
	 * holds the work order unique key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the work order type
	 */
	private _workType: SedaruUtils.Undoable<string>;
	get workType(): string {
		if (!this._workType) this._workType = new SedaruUtils.Undoable<string>();
		return this._workType.value;
	}
	set workType(value: string) {
		if (!this._workType) {
			this._workType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workType.value = value;
	}

	/**
	 * holds the work type id
	 */
	private _workTypeId: SedaruUtils.Undoable<string>;
	get workTypeId(): string {
		if (!this._workTypeId) this._workTypeId = new SedaruUtils.Undoable<string>();
		return this._workTypeId.value;
	}
	set workTypeId(value: string) {
		if (!this._workTypeId) {
			this._workTypeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTypeId.value = value;
	}

	/**
	 * holds the work order status
	 */
	private _status: SedaruUtils.Undoable<string>;
	get status(): string {
		if (!this._status) this._status = new SedaruUtils.Undoable<string>();
		return this._status.value;
	}
	set status(value: string) {
		if (!this._status) {
			this._status = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._status.value = value;
	}

	/**
	 * holds the work order created date
	 */
	private _createdDate: SedaruUtils.Undoable<string>;
	get createdDate(): string {
		if (!this._createdDate) this._createdDate = new SedaruUtils.Undoable<string>();
		return this._createdDate.value;
	}
	set createdDate(value: string) {
		if (!this._createdDate) {
			this._createdDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdDate.value = value;
	}

	/**
	 * holds the work order created by
	 */
	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	/**
	 * holds the work order created by
	 */
	private _changeBy: SedaruUtils.Undoable<string>;
	get changeBy(): string {
		if (!this._changeBy) this._changeBy = new SedaruUtils.Undoable<string>();
		return this._changeBy.value;
	}
	set changeBy(value: string) {
		if (!this._changeBy) {
			this._changeBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeBy.value = value;
	}

	/**
	 * holds the work order start date
	 */
	private _startDate: SedaruUtils.Undoable<string>;
	get startDate(): string {
		if (!this._startDate) this._startDate = new SedaruUtils.Undoable<string>();
		return this._startDate.value;
	}
	set startDate(value: string) {
		if (!this._startDate) {
			this._startDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._startDate.value = value;
	}

	private _changeDate: SedaruUtils.Undoable<string>;
	get changeDate(): string {
		if (!this._changeDate) this._changeDate = new SedaruUtils.Undoable<string>();
		return this._changeDate.value;
	}
	set changeDate(value: string) {
		if (!this._changeDate) {
			this._changeDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeDate.value = value;
	}

	/**
	 * holds the work order completed date
	 */
	private _completedDate: SedaruUtils.Undoable<string>;
	get completedDate(): string {
		if (!this._completedDate) this._completedDate = new SedaruUtils.Undoable<string>();
		return this._completedDate.value;
	}
	set completedDate(value: string) {
		if (!this._completedDate) {
			this._completedDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedDate.value = value;
	}

	/**
	 * holds the work order completed by
	 */
	private _completedBy: SedaruUtils.Undoable<string>;
	get completedBy(): string {
		if (!this._completedBy) this._completedBy = new SedaruUtils.Undoable<string>();
		return this._completedBy.value;
	}
	set completedBy(value: string) {
		if (!this._completedBy) {
			this._completedBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedBy.value = value;
	}

	/**
	 * holds the work order completed by
	 */
	private _assignedTo: SedaruUtils.Undoable<string>;
	get assignedTo(): string {
		if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
		return this._assignedTo.value;
	}
	set assignedTo(value: string) {
		if (!this._assignedTo) {
			this._assignedTo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assignedTo.value = value;
	}

	private _supervisor: SedaruUtils.Undoable<string>;
	get supervisor(): string {
		if (!this._supervisor) this._supervisor = new SedaruUtils.Undoable<string>();
		return this._supervisor.value;
	}
	set supervisor(value: string) {
		if (!this._supervisor) {
			this._supervisor = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._supervisor.value = value;
	}

	/**
	 * holds the work order priority
	 */
	private _priority: SedaruUtils.Undoable<string>;
	get priority(): string {
		if (!this._priority) this._priority = new SedaruUtils.Undoable<string>();
		return this._priority.value;
	}
	set priority(value: string) {
		if (!this._priority) {
			this._priority = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._priority.value = value;
	}

	/**
	 * holds the work order location
	 */
	private _location: SedaruUtils.Undoable<string>;
	get location(): string {
		if (!this._location) this._location = new SedaruUtils.Undoable<string>();
		return this._location.value;
	}
	set location(value: string) {
		if (!this._location) {
			this._location = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._location.value = value;
	}

	/**
	 * holds the customer name
	 */
	private _customerName: SedaruUtils.Undoable<string>;
	get customerName(): string {
		if (!this._customerName) this._customerName = new SedaruUtils.Undoable<string>();
		return this._customerName.value;
	}
	set customerName(value: string) {
		if (!this._customerName) {
			this._customerName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customerName.value = value;
	}

	/**
	 * holds the contact info
	 */
	private _contactInfo: SedaruUtils.Undoable<string>;
	get contactInfo(): string {
		if (!this._contactInfo) this._contactInfo = new SedaruUtils.Undoable<string>();
		return this._contactInfo.value;
	}
	set contactInfo(value: string) {
		if (!this._contactInfo) {
			this._contactInfo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._contactInfo.value = value;
	}

	/**
	 * holds the work order description
	 */
	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	/**
	 * holds the work order comments
	 */
	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

	/**
	 * holds the work order is corrective
	 */
	private _isCorrective: SedaruUtils.Undoable<number>;
	get isCorrective(): number {
		if (!this._isCorrective) this._isCorrective = new SedaruUtils.Undoable<number>();
		return this._isCorrective.value;
	}
	set isCorrective(value: number) {
		if (!this._isCorrective) {
			this._isCorrective = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isCorrective.value = value;
	}

	/**
	 * holds the work order budget id
	 */
	private _budgetId: SedaruUtils.Undoable<string>;
	get budgetId(): string {
		if (!this._budgetId) this._budgetId = new SedaruUtils.Undoable<string>();
		return this._budgetId.value;
	}
	set budgetId(value: string) {
		if (!this._budgetId) {
			this._budgetId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._budgetId.value = value;
	}

	/**
	 * holds the work order system id
	 */
	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	/**
	 * holds the work order account number
	 */
	private _accountNumber: SedaruUtils.Undoable<string>;
	get accountNumber(): string {
		if (!this._accountNumber) this._accountNumber = new SedaruUtils.Undoable<string>();
		return this._accountNumber.value;
	}
	set accountNumber(value: string) {
		if (!this._accountNumber) {
			this._accountNumber = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._accountNumber.value = value;
	}

	/**
	 * holds the work order parent WorkOrder key
	 */
	private _parentWorkOrderKey: SedaruUtils.Undoable<string>;
	get parentWorkOrderKey(): string {
		if (!this._parentWorkOrderKey) {
			this._parentWorkOrderKey = new SedaruUtils.Undoable<string>();
		}
		return this._parentWorkOrderKey.value;
	}
	set parentWorkOrderKey(value: string) {
		if (!this._parentWorkOrderKey) {
			this._parentWorkOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._parentWorkOrderKey.value = value;
	}

	/**
	 * holds the work order service request enabled
	 */
	private _isServiceRequest: SedaruUtils.Undoable<number>;
	get isServiceRequest(): number {
		if (!this._isServiceRequest) this._isServiceRequest = new SedaruUtils.Undoable<number>();
		return this._isServiceRequest.value;
	}
	set isServiceRequest(value: number) {
		if (!this._isServiceRequest) {
			this._isServiceRequest = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isServiceRequest.value = value;
	}

	/**
	 * holds the work order location
	 */
	private _yCoordinate: SedaruUtils.Undoable<number>;
	get yCoordinate(): number {
		if (!this._yCoordinate) this._yCoordinate = new SedaruUtils.Undoable<number>();
		return this._yCoordinate.value;
	}
	set yCoordinate(value: number) {
		if (!this._yCoordinate) {
			this._yCoordinate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._yCoordinate.value = value;
	}

	/**
	 * holds the work order location
	 */
	private _xCoordinate: SedaruUtils.Undoable<number>;
	get xCoordinate(): number {
		if (!this._xCoordinate) this._xCoordinate = new SedaruUtils.Undoable<number>();
		return this._xCoordinate.value;
	}
	set xCoordinate(value: number) {
		if (!this._xCoordinate) {
			this._xCoordinate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._xCoordinate.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<string>;
	get changeStatus(): string {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<string>();
		return this._changeStatus.value;
	}
	set changeStatus(value: string) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeStatus.value = value;
	}

	private _teamId: SedaruUtils.Undoable<string>;
	get teamId(): string {
		if (!this._teamId) this._teamId = new SedaruUtils.Undoable<string>();
		return this._teamId.value;
	}
	set teamId(value: string) {
		if (!this._teamId) {
			this._teamId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._teamId.value = value;
	}

	/**
	 * holds the work order recurrence template id
	 */
	private _recurrenceTemplateId: SedaruUtils.Undoable<string>;
	get recurrenceTemplateId(): string {
		if (!this._recurrenceTemplateId) this._recurrenceTemplateId = new SedaruUtils.Undoable<string>();
		return this._recurrenceTemplateId.value;
	}
	set recurrenceTemplateId(value: string) {
		if (!this._recurrenceTemplateId) {
			this._recurrenceTemplateId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._recurrenceTemplateId.value = value;
	}

	get isRecurring(): boolean {
		return this.recurrenceTemplateId && this.recurrenceTemplateId.length > 0;
	}

	private _customFields: CustomFields;
	get customFields(): CustomFields {
		return this._customFields ? this._customFields : (this._customFields = new CustomFields());
	}
	set customFields(value) {
		if (this._customFields === value) return;
		this._customFields = value;
	}

	private _workAssets: WorkAssets;
	get workAssets(): WorkAssets {
		return this._workAssets ? this._workAssets : (this._workAssets = new WorkAssets());
	}
	set workAssets(value) {
		if (this._workAssets === value) return;
		this._workAssets = value;
	}

	private _workAssetGraphics: any;
	get workAssetGraphics(): any {
		if (!this._workAssetGraphics) this._workAssetGraphics = [];
		return this._workAssetGraphics;
	}
	set workAssetGraphics(workAssetGraphics: any) {
		this._workAssetGraphics = workAssetGraphics;
	}

	private _employeeCosts: EmployeeCosts;
	get employeeCosts(): EmployeeCosts {
		return this._employeeCosts ? this._employeeCosts : (this._employeeCosts = new EmployeeCosts());
	}
	set employeeCosts(value) {
		if (this._employeeCosts === value) return;
		this._employeeCosts = value;
	}

	private _materialCosts: MaterialCosts;
	get materialCosts(): MaterialCosts {
		return this._materialCosts ? this._materialCosts : (this._materialCosts = new MaterialCosts());
	}
	set materialCosts(value) {
		if (this._materialCosts === value) return;
		this._materialCosts = value;
	}

	private _equipmentCosts: EquipmentCosts;
	get equipmentCosts(): EquipmentCosts {
		return this._equipmentCosts ? this._equipmentCosts : (this._equipmentCosts = new EquipmentCosts());
	}
	set equipmentCosts(value) {
		if (this._equipmentCosts === value) return;
		this._equipmentCosts = value;
	}

	private _workTasks: WorkTasks;
	get workTasks(): WorkTasks {
		return this._workTasks ? this._workTasks : (this._workTasks = new WorkTasks());
	}
	set workTasks(value) {
		if (this._workTasks === value) return;
		this._workTasks = value;
	}

	private _workInspections: WorkInspections;
	get workInspections(): WorkInspections {
		return this._workInspections ? this._workInspections : (this._workInspections = new WorkInspections());
	}
	set workInspections(value) {
		if (this._workInspections === value) return;
		this._workInspections = value;
	}

	private _attachments: Attachments;
	get attachments(): Attachments {
		return this._attachments ? this._attachments : (this._attachments = new Attachments());
	}
	set attachments(value) {
		if (this._attachments === value) return;
		this._attachments = value;
	}

	private _workComments: WorkComments;
	get workComments(): WorkComments {
		return this._workComments ? this._workComments : (this._workComments = new WorkComments());
	}
	set workComments(value) {
		if (this._workComments === value) return;
		this._workComments = value;
	}

	private _vendorCosts: VendorCosts;
	get vendorCosts(): VendorCosts {
		return this._vendorCosts ? this._vendorCosts : (this._vendorCosts = new VendorCosts());
	}
	set vendorCosts(value) {
		if (this._vendorCosts === value) return;
		this._vendorCosts = value;
	}

	private _failureReports: FailureReports;
	get failureReports(): FailureReports {
		return this._failureReports ? this._failureReports : (this._failureReports = new FailureReports());
	}
	set failureReports(value: FailureReports) {
		if (this._failureReports === value) return;
		this._failureReports = value;
	}

	static fromContract(contract: Contracts.WorkOrderRawContract, assetsByLayers: any, assetLocationsWithAsset: AssetLocationsWithAssets): AdvancedWorkOrder {
		const model = new AdvancedWorkOrder();
		model.hasOnlyBasicData = contract.EmployeeCosts === null;
		model.isNew = false;
		model.workOrderKey = contract.WorkOrder.WorkorderKey;
		model.workType = contract.WorkOrder.WorkType;
		model.workTypeId = contract.WorkOrder.WorkTypeId;
		model.status = contract.WorkOrder.Status;
		model.createdDate = DateUtil.isValidDate(contract.WorkOrder.CreatedDate) ? DateUtil.convertLocaleString(contract.WorkOrder.CreatedDate) : '';
		model.createdBy = contract.WorkOrder.CreatedBy;
		model.startDate = DateUtil.isValidDate(contract.WorkOrder.StartDate) ? DateUtil.convertLocaleString(contract.WorkOrder.StartDate) : '';
		model.completedDate = DateUtil.isValidDate(contract.WorkOrder.CompletedDate) ? DateUtil.convertLocaleString(contract.WorkOrder.CompletedDate) : '';
		model.completedBy = contract.WorkOrder.CompletedBy;
		model.changeBy = contract.WorkOrder.ChangeBy;
		model.changeDate = contract.WorkOrder.ChangeDate;
		model.assignedTo = contract.WorkOrder.AssignedTo;
		model.supervisor = contract.WorkOrder.Supervisor;
		model.priority = contract.WorkOrder.Priority ? contract.WorkOrder.Priority.toString() : '';
		model.location = contract.WorkOrder.Location;
		model.customerName = contract.WorkOrder.CustomerName;
		model.contactInfo = contract.WorkOrder.ContactInfo;
		model.description = contract.WorkOrder.Description;
		model.comments = contract.WorkOrder.Comments;
		model.isCorrective = contract.WorkOrder.IsCorrective;
		model.budgetId = contract.WorkOrder.BudgetId;
		model.teamId = contract.WorkOrder.TeamID;
		model.systemId = contract.WorkOrder.SystemId;
		model.accountNumber = contract.WorkOrder.AccountNumber;
		model.parentWorkOrderKey = contract.WorkOrder.ParentWorkorderKey;
		model.isServiceRequest = contract.WorkOrder.IsRequest;
		model.yCoordinate = contract.WorkOrder.Ycoordinate ? contract.WorkOrder.Ycoordinate : null;
		model.xCoordinate = contract.WorkOrder.Xcoordinate ? contract.WorkOrder.Xcoordinate : null;
		model.recurrenceTemplateId = contract.WorkOrder.RecurrenceTemplateID;

		if (contract.CustomFields) model.customFields = CustomFields.fromContracts(contract.CustomFields);
		if (contract.WorkAssets) model.workAssets = WorkAssets.fromContracts(contract.WorkAssets, assetsByLayers, assetLocationsWithAsset);
		if (contract.EmployeeCosts) model.employeeCosts = EmployeeCosts.fromContracts(contract.EmployeeCosts);
		if (contract.MaterialCosts) model.materialCosts = MaterialCosts.fromContracts(contract.MaterialCosts);
		if (contract.EquipmentCosts) model.equipmentCosts = EquipmentCosts.fromContracts(contract.EquipmentCosts);
		if (contract.WorkTasks) model.workTasks = WorkTasks.fromContracts(contract.WorkTasks);
		if (contract.WorkInspections) model.workInspections = WorkInspections.fromContracts(contract.WorkInspections);
		if (contract.Attachments) model.attachments = Attachments.fromContracts(contract.Attachments);
		if (contract.WorkComments) model.workComments = WorkComments.fromContracts(contract.WorkComments);
		if (contract.VendorCosts) model.vendorCosts = VendorCosts.fromContracts(contract.VendorCosts);
		if (contract.FailureResults) model.failureReports = FailureReports.fromContracts(contract.FailureResults);
		model.clearDirty();
		return model;
	}

	static fromHmsContract(contract) {
		const model = new AdvancedWorkOrder();
		model.isNew = false;
		model.workOrderKey = contract.workOrderKey;
		model.assignedTo = contract.assignedTo;
		model.completedDate = DateUtil.isValidDate(contract.completedDate) ? DateUtil.convertLocaleString(contract.completedDate) : '';
		model.startDate = DateUtil.isValidDate(contract.startDate) ? DateUtil.convertLocaleString(contract.startDate) : '';
		model.status = contract.status;
		model.systemId = contract.systemId;
		model.workType = contract.workType;
		model.yCoordinate = contract.yCoordinate ? contract.yCoordinate : null;
		model.xCoordinate = contract.xCoordinate ? contract.xCoordinate : null;
		model.clearDirty();
		return model;
	}

	static fromStub(stub: any) {
		const model = new AdvancedWorkOrder();
		model.workOrderKey = stub.WorkOrderKey || stub.workOrderKey;
		model.workType = stub.WorkType || stub.workType;
		model.workTypeId = stub.WorkTypeId || stub.workTypeId;
		model.status = stub.Status || stub.status;
		model.createdDate = this.checkParseDate(stub, 'createddate');
		model.createdBy = stub.CreatedBy || stub.createdBy;
		model.startDate = this.checkParseDate(stub, 'startdate');
		model.completedDate = this.checkParseDate(stub, 'completeddate');
		model.completedBy = stub.CompletedBy || stub.completedBy;
		model.changeBy = stub.ChangeBy || stub.changeBy;
		model.changeDate = stub.ChangeDate || stub.changeDate;
		model.assignedTo = stub.AssignedTo || stub.assignedTo;
		model.supervisor = stub.Supervisor || stub.supervisor;
		const priority = stub.Priority || stub.priority;
		model.priority = priority ? priority.toString() : '';
		model.location = stub.Location || stub.location;
		model.customerName = stub.CustomerName || stub.customerName;
		model.contactInfo = stub.ContactInfo || stub.contactInfo;
		model.description = stub.Description || stub.description;
		model.comments = stub.Comments || stub.comments;
		model.isCorrective = stub.IsCorrective || stub.isCorrective;
		model.budgetId = stub.BudgetId || stub.budgetId;
		model.teamId = stub.TeamID || stub.teamID;
		model.systemId = stub.SystemId || stub.systemId;
		model.accountNumber = stub.AccountNumber || stub.accountNumber;
		model.parentWorkOrderKey = stub.ParentWorkorderKey || stub.parentWorkorderKey;
		model.isServiceRequest = stub.IsRequest || stub.isRequest;

		const yCoordinate = stub.Ycoordinate || stub.yCoordinate || stub.Y;
		model.yCoordinate = yCoordinate ? yCoordinate : null;

		const xCoordinate = stub.Xcoordinate || stub.xCoordinate || stub.X;
		model.xCoordinate = xCoordinate ? xCoordinate : null;

		model.recurrenceTemplateId = stub.RecurrenceTemplateId || stub.recurrenceTemplateId;
		return model;
	}

	static checkParseDate(obj, key) {
		const value = this.getValue(obj, key);
		return DateUtil.isValidDate(value) ? DateUtil.convertLocaleString(value) : '';
	}

	static getValue(obj, key: string) {
		if (!obj) {
			return null;
		}

		const lowerCaseKey = key.toLowerCase();
		const foundKey = Object.keys(obj).find(k => String(k).toLowerCase() === lowerCaseKey);

		if (!foundKey) {
			return null;
		}

		return obj[foundKey];
	}

	getCenter() {
		// check x,y greater than 0
		// if true, create a map point(spatial reference must be webmaractor) return point  DONE!
		// if false, //this.getWorkAssetGeometry
	}

	getWorkAssetGeometry() {
		// loop through this.workAsset(need to create geomery property), where geometry is !null, return a Array of geo for all aset
		// NOte: this one will need union in geo to get one point to show on map.
	}

	getContract = (): Contracts.WorkOrderContract => {
		const contractWithOutNestedFields = this.getContractWithoutNestedFields();
		const fullContract = Object.assign(new Contracts.WorkOrderContract(), contractWithOutNestedFields);
		fullContract.CustomFields = this.customFields.getContracts();
		fullContract.WorkAssets = this.workAssets.getContracts();
		fullContract.EmployeeCosts = this.employeeCosts.getContracts();
		fullContract.MaterialCosts = this.materialCosts.getContracts();
		fullContract.EquipmentCosts = this.equipmentCosts.getContracts();
		fullContract.WorkTasks = this.workTasks.getContracts();
		fullContract.WorkInspections = this.workInspections.getContracts();
		fullContract.Attachments = this.attachments.getContracts();
		fullContract.FailureResults = this.failureReports.getContracts();
		return fullContract;
	};

	getContractMaximo = (): Contracts.AdvancedWorkOrderContractMaximo => {
		const contractWithOutNestedFields = this.getContractWithoutNestedFields();
		const fullContract = Object.assign(new Contracts.AdvancedWorkOrderContractMaximo(), contractWithOutNestedFields);
		fullContract.WorkAssets = this.workAssets.getContracts();
		fullContract.CustomFields = this.customFields.getContracts();
		fullContract.CustomFields = fullContract.CustomFields.filter(field => field && field.CustomFieldType && field.CustomFieldType.toLowerCase() !== 'readonly');
		return fullContract;
	};

	getContractWithoutNestedFields = (): Contracts.AdvancedWorkOrderContractWithoutNestedFields => {
		const contract = new Contracts.AdvancedWorkOrderContractWithoutNestedFields();
		contract.WorkorderKey = this.workOrderKey;
		contract.WorkType = this.workType;
		contract.WorkTypeId = this.workTypeId;
		contract.Status = this.status;
		contract.AssignedTo = this.assignedTo;
		contract.Supervisor = this.supervisor;
		contract.StartDate = this.startDate;
		contract.CreatedDate = this.createdDate ? new Date(this.createdDate).toISOString() : new Date().toISOString();
		contract.Priority = this.priority;
		contract.CompletedDate = this.completedDate;
		contract.CreatedBy = this.createdBy;
		contract.CompletedBy = this.completedBy;
		contract.ChangeDate = this.changeDate;
		contract.ChangeBy = this.changeBy;
		contract.Location = this.location;
		contract.CustomerName = this.customerName;
		contract.ContactInfo = this.contactInfo;
		contract.Description = this.description;
		contract.Comments = this.comments;
		contract.IsCorrective = this.isCorrective ? +this.isCorrective : undefined;
		contract.BudgetId = this.budgetId;
		contract.SystemId = this.systemId;
		contract.TeamID = this.teamId;
		contract.AccountNumber = this.accountNumber;
		contract.ParentWorkorderKey = this.parentWorkOrderKey;
		contract.IsRequest = this.isServiceRequest;
		contract.Ycoordinate = this.yCoordinate ? this.yCoordinate : 0;
		contract.Xcoordinate = this.xCoordinate ? this.xCoordinate : 0;
		contract.TeamID = this.teamId;
		contract.RecurrenceTemplateID = this.recurrenceTemplateId;
		return contract;
	};

	passWorkOrderKeyToChild = () => {
		// Need to pass all the workOrderKeyToChild after creating the work order
		this.customFields.forEach(field => (field.workOrderKey = this.workOrderKey));
		this.workAssets.forEach(workAsset => (workAsset.workOrderKey = this.workOrderKey));
	};

	onUpdateInformation = (copyModel: AdvancedWorkOrder): boolean => {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workType, () => {
				this.workType = copyModel.workType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTypeId, () => {
				this.workTypeId = copyModel.workTypeId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._status, () => {
				this.status = copyModel.status;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdDate, () => {
				this.createdDate = copyModel.createdDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._startDate, () => {
				this.startDate = copyModel.startDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._completedDate, () => {
				this.completedDate = copyModel.completedDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._completedBy, () => {
				this.completedBy = copyModel.completedBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assignedTo, () => {
				this.assignedTo = copyModel.assignedTo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._supervisor, () => {
				this.supervisor = copyModel.supervisor;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._teamId, () => {
				this.teamId = copyModel.teamId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._priority, () => {
				this.priority = copyModel.priority;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._location, () => {
				this.location = copyModel.location;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customerName, () => {
				this.customerName = copyModel.customerName;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._contactInfo, () => {
				this.contactInfo = copyModel.contactInfo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._comments, () => {
				this.comments = copyModel.comments;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._isCorrective, () => {
				this.isCorrective = copyModel.isCorrective;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._budgetId, () => {
				this.budgetId = copyModel.budgetId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._accountNumber, () => {
				this.accountNumber = copyModel.accountNumber;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._parentWorkOrderKey, () => {
				this.parentWorkOrderKey = copyModel.parentWorkOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._isServiceRequest, () => {
				this.isServiceRequest = copyModel.isServiceRequest;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._yCoordinate, () => {
				this.yCoordinate = copyModel.yCoordinate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._xCoordinate, () => {
				this.xCoordinate = copyModel.xCoordinate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._recurrenceTemplateId, () => {
				this.recurrenceTemplateId = copyModel.recurrenceTemplateId;
			}) || wasChanged;

		wasChanged = this.refreshSubFields(this.workAssets, copyModel.workAssets, 'assetKey') || wasChanged;

		if (copyModel.hasOnlyBasicData) {
			return wasChanged;
		}

		this.hasOnlyBasicData = copyModel.hasOnlyBasicData;

		wasChanged = this.refreshSubFields(this.customFields, copyModel.customFields) || wasChanged;
		wasChanged = this.refreshSubFields(this.employeeCosts, copyModel.employeeCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.materialCosts, copyModel.materialCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.equipmentCosts, copyModel.equipmentCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.workTasks, copyModel.workTasks) || wasChanged;
		wasChanged = this.refreshSubFields(this.workInspections, copyModel.workInspections) || wasChanged;
		wasChanged = this.refreshSubFields(this.attachments, copyModel.attachments) || wasChanged;
		wasChanged = this.refreshSubFields(this.workComments, copyModel.workComments) || wasChanged;
		wasChanged = this.refreshSubFields(this.vendorCosts, copyModel.vendorCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.failureReports, copyModel.failureReports) || wasChanged;
		return wasChanged;
	};

	private refreshSubFields(currentFields: any[], newFields: any[], idField: string = 'id'): boolean {
		let wasChanged = false;
		for (let i = 0; i < currentFields.length; i++) {
			// loop through old fields, find out if current field still exist in the new fields
			const stillExist = newFields.find(field => field[idField] === currentFields[i][idField]);
			// If exist, then we leave it along, no need to remove
			if (stillExist) continue;
			// else remove.
			const indexToRemove = currentFields.findIndex(field => field[idField] === currentFields[i][idField]);
			if (indexToRemove > -1) {
				currentFields.splice(indexToRemove, 1);
				i--;
				wasChanged = true;
			}
		}

		for (const newField of newFields) {
			// loop through new fields, find out if any of the old field need to be updated
			const currentFieldToUpdate = currentFields.find(field => field[idField] === newField[idField]);

			// If found, then old field need to be updated.
			if (currentFieldToUpdate) {
				wasChanged = currentFieldToUpdate.updateInformation(newField) || wasChanged;
			} else {
				// else, new field doesn't exist in the current state, so we push in the new field.
				currentFields.push(newField);
				wasChanged = true;
			}
		}

		return wasChanged;
	}
}
