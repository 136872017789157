<mat-card class="omni-team" [class.phone]="mode === 'phone'">
	<mat-card-title class="omni-team-title card-title-gray omni-md-font">
		<button mat-icon-button class="back-button" matTooltip="back" (click)="goBack()" *ngIf="canGoBack()">
			<img src="assets/previous-circled.png" />
		</button>
		<div class="page-title">tab name - team</div>
		<button mat-icon-button class="omni-team-title-button">
			<img src="assets/settings1.png" /></button
	></mat-card-title>
	<mat-card-content>
		<app-router-outlet #navigationRouter (navigationHappening)="onNavigationHappening($event)" [config]="config" (navigationComplete)="onNavigationComplete($event)"></app-router-outlet>
	</mat-card-content>
</mat-card>
