import { WorkOrderSourceType } from './work-order-source-type.enum';
import { ChannelTypes } from './channel-type.enum.model';

/**
 * The model of an OMNI metric source.
 */
export class MetricSource {
	/**
	 * The type of the asset associated with the metric. eg., pipe, customer, hydrants etc.
	 */
	public assetType?: string;
	/**
	 * holds the metric type eg: WorkOrder, Asset, History
	 */
	public type: ChannelTypes;
	/**
	 * holds the source type for workOrder
	 */
	public workOrderSourceType?: WorkOrderSourceType;

	public initialize?(metricSource: MetricSource) {
		if (!metricSource) return;
		this.assetType = metricSource.assetType;
		this.type = metricSource.type;
		this.workOrderSourceType = metricSource.workOrderSourceType;
	}
}
