import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { GlobalConfig } from '../../../models/global-config.model';
import { WorkOrderField } from '../../../models/work-order-field.model';
import { ListItem } from '../../../models/list-item.model';
import { List } from '../../../models/list.model';
import { SummaryFieldInputType } from '../../../models/work-order/standard-custom-field.model';

export class WorkOrderChannelTransform extends TransformBase {
	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig) {
		super(fieldforceConfig, globalConfiguration);
	}

	protected onTransform(): TransformationResult {
		if (!this.fieldforceConfig.DataProviders.WorkOrderProviders || this.fieldforceConfig.DataProviders.WorkOrderProviders.length === 0) {
			return this.getResult(true);
		}

		const workOrderProvider = this.fieldforceConfig.DataProviders.WorkOrderProviders[0];

		const workOrderChannel = new SedaruModels.Channel(this.globalConfig);
		workOrderChannel.channelType = SedaruModels.ChannelTypes.WorkOrder;
		workOrderChannel.dataSourceLegacyId = workOrderProvider.ServiceID;

		if (workOrderProvider.Mode && workOrderProvider.Mode.toLowerCase() === 'advanced') {
			workOrderChannel.attributes = this.getAWOChannelAttributes(workOrderProvider);
		} else {
			workOrderChannel.attributes = this.getSWOChannelAttributes(workOrderProvider);
		}

		this.globalConfig.channels.push(workOrderChannel);
		return this.getResult(true);
	}

	private getAWOChannelAttributes(workOrderProvider: any): SedaruModels.SedaruWorkOrderChannelAttributes {
		const attributes = new SedaruModels.SedaruWorkOrderChannelAttributes();
		this.populateWOChannelAttributes(workOrderProvider, attributes);

		if (workOrderProvider.CustomerCode) {
			attributes.customerCode = workOrderProvider.CustomerCode;
		}

		if (typeof workOrderProvider.CanCloseWorkOrders === 'boolean') {
			attributes.canCloseWorkOrders = workOrderProvider.CanCloseWorkOrders;
		}

		if (typeof workOrderProvider.SkipRealtimeValidation === 'boolean') {
			attributes.skipRealtimeValidation = workOrderProvider.SkipRealtimeValidation;
		}

		if (typeof workOrderProvider.ActiveTemplateID === 'number') {
			attributes.activeTemplateId = workOrderProvider.ActiveTemplateID;
		}

		if (typeof workOrderProvider.CompletedTemplateID === 'number') {
			attributes.completedTemplateId = workOrderProvider.CompletedTemplateID;
		}

		if (workOrderProvider.LEM) {
			if (workOrderProvider.LEM.Mode && workOrderProvider.LEM.Mode.toLowerCase() === 'advanced') {
				attributes.lem.mode = SedaruModels.LEMMode.Advanced;
			} else {
				attributes.lem.mode = SedaruModels.LEMMode.Standard;
			}
		}

		return attributes;
	}

	private getSWOChannelAttributes(workOrderProvider: any): SedaruModels.ArcGISWorkOrderChannelAttributes {
		const attributes = new SedaruModels.ArcGISWorkOrderChannelAttributes();
		this.populateWOChannelAttributes(workOrderProvider, attributes);

		if (workOrderProvider.WorkOrder) {
			if (!isNaN(Number(workOrderProvider.WorkOrder.Index))) {
				attributes.workOrderLayerIndex = Number(workOrderProvider.WorkOrder.Index);
			}

			if (workOrderProvider.WorkOrder && workOrderProvider.WorkOrder.CustomFields) {
				attributes.summaryFields = this.getWorkOrderSummaryFields(workOrderProvider.WorkOrder.CustomFields);
			}

		}

		if (workOrderProvider.WorkAsset) {
			if (!isNaN(Number(workOrderProvider.WorkAsset.Index))) {
				attributes.workAssetLayerIndex = Number(workOrderProvider.WorkAsset.Index);
			}

			if (workOrderProvider.WorkAsset.MappedAssetUniqueField) {
				attributes.workAssetMappedAssetUniqueField = workOrderProvider.WorkAsset.MappedAssetUniqueField;
			}
		}

		if (workOrderProvider.WorkTask) {
			if (!isNaN(Number(workOrderProvider.WorkTask.Index))) {
				attributes.workTaskLayerIndex = Number(workOrderProvider.WorkTask.Index);
			}

			if (workOrderProvider.WorkTask.CompletedWorkTaskValue) {
				attributes.completedWorkTaskValue = workOrderProvider.WorkTask.CompletedWorkTaskValue;
			}
		}

		if (workOrderProvider.Employee) {
			if (!isNaN(Number(workOrderProvider.Employee.Index))) {
				attributes.employeeLayerIndex = Number(workOrderProvider.Employee.Index);
			}
		}

		if (workOrderProvider.Material) {
			if (!isNaN(Number(workOrderProvider.Material.Index))) {
				attributes.materialLayerIndex = Number(workOrderProvider.Material.Index);
			}
		}

		if (workOrderProvider.Equipment) {
			if (!isNaN(Number(workOrderProvider.Equipment.Index))) {
				attributes.equipmentLayerIndex = Number(workOrderProvider.Equipment.Index);
			}
		}

		if (workOrderProvider.EmployeeCost) {
			if (!isNaN(Number(workOrderProvider.EmployeeCost.Index))) {
				attributes.employeeCostLayerIndex = Number(workOrderProvider.EmployeeCost.Index);
			}
		}

		if (workOrderProvider.MaterialCost) {
			if (!isNaN(Number(workOrderProvider.MaterialCost.Index))) {
				attributes.materialCostLayerIndex = Number(workOrderProvider.MaterialCost.Index);
			}
		}

		if (workOrderProvider.EquipmentCost) {
			if (!isNaN(Number(workOrderProvider.EquipmentCost.Index))) {
				attributes.equipmentCostLayerIndex = Number(workOrderProvider.EquipmentCost.Index);
			}
		}

		return attributes;
	}

	private populateWOChannelAttributes(workOrderProvider: any, attributes: SedaruModels.WorkOrderChannelAttributes) {
		if (workOrderProvider.Mode && workOrderProvider.Mode.toLowerCase() === 'advanced') {
			attributes.workOrderMode = SedaruModels.WorkOrderModeType.Advanced;
			attributes.workOrderSystem = this.getAWOWorkOrderSystem(workOrderProvider);
		} else if (workOrderProvider.Mode && workOrderProvider.Mode.toLowerCase() === 'standard') {
			attributes.workOrderSystem = SedaruModels.WorkOrderSystem.SedaruSWO;
			attributes.workOrderMode = SedaruModels.WorkOrderModeType.Standard;
		} else {
			attributes.workOrderSystem = SedaruModels.WorkOrderSystem.None;
			attributes.workOrderMode = SedaruModels.WorkOrderModeType.None;
		}

		if (workOrderProvider.ActiveWorkOrderValues && workOrderProvider.ActiveWorkOrderValues.length > 0) {
			for (const val of workOrderProvider.ActiveWorkOrderValues) {
				attributes.activeWorkOrderValues.push(val);
			}
		}

		if (workOrderProvider.CompletedWorkOrderValues && workOrderProvider.CompletedWorkOrderValues.length > 0) {
			for (const val of workOrderProvider.CompletedWorkOrderValues) {
				attributes.completedWorkOrderValues.push(val);
			}
		}

		if (workOrderProvider.ReadOnlyWorkOrderValues && workOrderProvider.ReadOnlyWorkOrderValues.length) {
			for (const val of workOrderProvider.ReadOnlyWorkOrderValues) {
				attributes.readOnlyWorkOrderValues.push(val);
			}
		}

		if (workOrderProvider.HourlyRates && workOrderProvider.HourlyRates.length > 0) {
			for (const val of workOrderProvider.HourlyRates) {
				const hourlyRate = new SedaruModels.HourlyRate();
				hourlyRate.factor = val.Factor;
				hourlyRate.name = val.Name;
				hourlyRate.type = val.Name;
				attributes.hourlyRates.push(hourlyRate);
			}
		}
	}

	private getAWOWorkOrderSystem(workOrderProvider: any) {
		let workOrderSystem: string = workOrderProvider.WorkOrderSystem;
		if (workOrderSystem) {
			workOrderSystem = workOrderSystem.toLowerCase();
		}

		if (workOrderSystem === 'maximo') {
			return SedaruModels.WorkOrderSystem.Maximo;
		}

		if (workOrderSystem === 'cityworks') {
			return SedaruModels.WorkOrderSystem.CityWorks;
		}

		if (workOrderSystem === 'sedaru') {
			return SedaruModels.WorkOrderSystem.SedaruAWO;
		}

		return SedaruModels.WorkOrderSystem.CityWorks;
		// When we develop omni to support Sedaru AWO backing and front we will enable this mode
		// return SedaruModels.WorkOrderSystem.SedaruAWO;
	}

	getWorkOrderSummaryFields(customFieldsElement: any): WorkOrderField[] {
		const summaryFields = new Array<WorkOrderField>();
		for (const fieldConfig of customFieldsElement) {
			const newField = new WorkOrderField(this.globalConfig);
			newField.name = fieldConfig.Name;
			newField.text = fieldConfig.Text;
			newField.isVisible = fieldConfig.Visible;
			newField.isRequired = fieldConfig.Required;
			newField.isReadOnly = fieldConfig.ReadOnly;
			newField.inputType = this.isDefaultListField(fieldConfig.Name?.toLowerCase()) ?
				SummaryFieldInputType.LIST : fieldConfig.InputType as SummaryFieldInputType;

			if (newField.inputType != SummaryFieldInputType.LIST ||
				!fieldConfig.List || !fieldConfig.List.ListItems) {
				summaryFields.push(newField);
				continue;
			}

			newField.list = new List(this.globalConfig);
			for (const listItemConfig of fieldConfig.List.ListItems) {
				const newListItem = new ListItem(this.globalConfig);
				newListItem.text = listItemConfig.Text;
				newListItem.value = listItemConfig.Value;
				newField.list.items.push(newListItem);
			}

			summaryFields.push(newField);
		}

		return summaryFields;
	}

	private isDefaultListField(fieldName: string): boolean {
		if (!fieldName) return false;
		return fieldName === 'worktype' || fieldName === 'status' || fieldName === 'assignedto';
	}
}
