import { MetricQuery, QueryMode } from './metric-query.model';

/**
 * The custom query model, holds the custom map layer query and realted table query
 */
export class CustomQuery extends MetricQuery {
	mapLayerQueryString?: string;

	relatedTableQueryString?: string;

	workOrderQueryString?: string;

	mode = QueryMode.manualQuery;

	constructor(contract?: CustomQuery) {
		super();
		if (!contract) return;
		this.mapLayerQueryString = contract.mapLayerQueryString;
		this.relatedTableQueryString = contract.relatedTableQueryString;
		this.workOrderQueryString = contract.workOrderQueryString;
	}
}
