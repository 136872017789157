<div class="scrolling-content">
	<span *ngFor="let content of (contentList | keyvalue: sort)">
		<mat-card
			class="tab-content-option"
			matRipple
			[matRippleColor]="'rgba(0,254,254, 0.3)'"
			[class.disabled]="!content.value.enabled"
			(click)="selectDashboard(content.value)"
			[class.cyan]="currentContent.shortName === content.value.shortName"
		>
			<mat-card-content>{{ content.value.fullName }}</mat-card-content>
			<mat-card-header>
				<img [src]="content.value.icon" mat-card-avatar />
				<mat-card-subtitle>
					{{ content.value.description }}
				</mat-card-subtitle>
			</mat-card-header>
		</mat-card>
		<mat-divider class="tab-content-divider"></mat-divider>
	</span>
</div>
