import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class WorkInspection extends OmniModel<WorkInspection, Contracts.WorkInspectionContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _inspectionTypeID: SedaruUtils.Undoable<number>;
	get inspectionTypeID(): number {
		if (!this._inspectionTypeID) this._inspectionTypeID = new SedaruUtils.Undoable<number>();
		return this._inspectionTypeID.value;
	}
	set inspectionTypeID(value: number) {
		if (!this._inspectionTypeID) {
			this._inspectionTypeID = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._inspectionTypeID.value = value;
	}

	private _assetType: SedaruUtils.Undoable<string>;
	get assetType(): string {
		if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
		return this._assetType.value;
	}
	set assetType(value: string) {
		if (!this._assetType) {
			this._assetType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetType.value = value;
	}

	private _assetKey: SedaruUtils.Undoable<string>;
	get assetKey(): string {
		if (!this._assetKey) this._assetKey = new SedaruUtils.Undoable<string>();
		return this._assetKey.value;
	}
	set assetKey(value: string) {
		if (!this._assetKey) {
			this._assetKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetKey.value = value;
	}

	private _assetOID: SedaruUtils.Undoable<number>;
	get assetOID(): number {
		if (!this._assetOID) this._assetOID = new SedaruUtils.Undoable<number>();
		return this._assetOID.value;
	}
	set assetOID(value: number) {
		if (!this._assetOID) {
			this._assetOID = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._assetOID.value = value;
	}

	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	private _inspectionKey: SedaruUtils.Undoable<string>;
	get inspectionKey(): string {
		if (!this._inspectionKey) this._inspectionKey = new SedaruUtils.Undoable<string>();
		return this._inspectionKey.value;
	}
	set inspectionKey(value: string) {
		if (!this._inspectionKey) {
			this._inspectionKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._inspectionKey.value = value;
	}

	private _systemID: SedaruUtils.Undoable<string>;
	get systemID(): string {
		if (!this._systemID) this._systemID = new SedaruUtils.Undoable<string>();
		return this._systemID.value;
	}
	set systemID(value: string) {
		if (!this._systemID) {
			this._systemID = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemID.value = value;
	}

	private _inspectedBy: SedaruUtils.Undoable<string>;
	get inspectedBy(): string {
		if (!this._inspectedBy) this._inspectedBy = new SedaruUtils.Undoable<string>();
		return this._inspectedBy.value;
	}
	set inspectedBy(value: string) {
		if (!this._inspectedBy) {
			this._inspectedBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._inspectedBy.value = value;
	}

	private _inspectionDate: SedaruUtils.Undoable<Date>;
	get inspectionDate(): Date {
		if (!this._inspectionDate) this._inspectionDate = new SedaruUtils.Undoable<Date>();
		return this._inspectionDate.value;
	}
	set inspectionDate(value: Date) {
		if (!this._inspectionDate) {
			this._inspectionDate = new SedaruUtils.Undoable<Date>(value);
			return;
		}
		this._inspectionDate.value = value;
	}

	private _status: SedaruUtils.Undoable<string>;
	get status(): string {
		if (!this._status) this._status = new SedaruUtils.Undoable<string>();
		return this._status.value;
	}
	set status(value: string) {
		if (!this._status) {
			this._status = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._status.value = value;
	}

	static fromContract(contract: Contracts.WorkInspectionContract): WorkInspection {
		const model = new WorkInspection();
		model.id = contract.ObjectId.toString();
		model.inspectionTypeID = contract.InspectionTypeID;
		model.assetType = contract.AssetType;
		model.assetKey = contract.AssetKey;
		model.assetOID = contract.AssetOID;
		model.workOrderKey = contract.WorkOrderKey;
		model.inspectionKey = contract.InspectionKey;
		model.systemID = contract.SystemID;
		model.inspectedBy = contract.InspectedBy;
		model.inspectionDate = contract.InspectionDate;
		model.status = contract.Status;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.WorkInspectionContract => {
		const contract = new Contracts.WorkInspectionContract();
		contract.ObjectId = +this.id;
		contract.InspectionTypeID = this.inspectionTypeID;
		contract.AssetType = this.assetType;
		contract.AssetKey = this.assetKey;
		contract.AssetOID = this.assetOID;
		contract.WorkOrderKey = this.workOrderKey;
		contract.InspectionKey = this.inspectionKey;
		contract.SystemID = this.systemID;
		contract.InspectedBy = this.inspectedBy;
		contract.InspectionDate = this.inspectionDate;
		contract.Status = this.status;
		return contract;
	};

	onUpdateInformation(copyModel: WorkInspection): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._inspectionTypeID, () => {
				this.inspectionTypeID = copyModel.inspectionTypeID;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetKey, () => {
				this.assetKey = copyModel.assetKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._assetOID, () => {
				this.assetOID = copyModel.assetOID;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._inspectionKey, () => {
				this.inspectionKey = copyModel.inspectionKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemID, () => {
				this.systemID = copyModel.systemID;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._inspectedBy, () => {
				this.inspectedBy = copyModel.inspectedBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<Date>(this._inspectionDate, () => {
				this.inspectionDate = copyModel.inspectionDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._status, () => {
				this.status = copyModel.status;
			}) || wasChanged;
		return wasChanged;
	}
}
