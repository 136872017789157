import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import { SedaruClientHub } from '../../../sedaru-util/sedaru-subscriptions-library';
import { AdvancedWorkOrder } from 'models/work-order';

export class WorkOrderValidationResult extends Subscriptions.MethodHandlerResult<WorkOrderValidationHandler> {
    workOrderKey: string;
    systemId: string;
    customerCode: string;

    constructor(handler: WorkOrderValidationHandler) {
        super(handler);
    }
}

export class WorkOrderValidationHandler extends Subscriptions.SedaruClientMethodHandlerBase {
    shouldHandleMethod(methodIdentifier: string): boolean {
        return methodIdentifier.toLowerCase() === 'smartconnect.onsaveworkorderprocessed';
    }

    onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
        return new Promise<Subscriptions.MethodHandlerResultBase>((resolve, reject) => {

            const result1 = new WorkOrderValidationResult(this);
            const result: Subscriptions.MethodHandlerResultBase = result1;

            try {
                if (args.isHandled) {
                    return;
                }

                args.isHandled = true;

                const jsonString = packet.data;
                const jBody = JSON.parse(jsonString);

                result1.success = jBody.Success;

                if (!result1.success) {
                    result1.message = jBody.Message && jBody.Message.length ? jBody.Message :
                        'Something unexpected happened while communicating with the work order system.<br />Please wait a moment and try again.';
                    return;
                }

                const jResult = jBody.Result;

                if (!jResult) {
                    result1.success = false;
                    result1.message = `No result returned but required. Service message: ${jBody.Message}`;
                    return;
                }

                result1.workOrderKey = jResult.WorderKey;
                result1.systemId = jResult.SystemId;

                if (!result1.workOrderKey) {
                    result1.success = false;
                    result1.message = 'Work order key is empty.';
                    return;
                }

                if (!result1.systemId) {
                    result1.success = false;
                    result1.message = 'Work order system ID is empty.';
                    return;
                }
            } finally {
                resolve(result);
            }

        });
    }

    onGetUniqueId(): string {
        return 'WorkOrderValidationHandler'; // do not user Class.name because it doesn't work with minified code.
    }

    public async sendSaveAdvancedWorkOrderMessage(workOrder: AdvancedWorkOrder, customerCode: string): Promise<boolean> {
        const jObject: any = {};
        jObject.WorkOrder = workOrder.getContractMaximo();

        const data = JSON.stringify(jObject);
        console.log(data);
        await this.hub.sendNotification('SmartConnect.UpdateWorkOrder', data, customerCode, SedaruClientHub.sedaruConnectAppName);
        return true;
    }
}
