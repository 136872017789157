import { ProfileGroups } from './profile-groups.model';
/**
 * This is a user object used to handle authentication, authorization and user customization.
 */
export class User {
	fullName: string;
	displayName: string;
	email: string;
	department: string;
	status: string;
	customerCode: string;
	userName: string;
	thumbnail: string;
	profileGroup?: string;
	isProfileGroupEnabled?: boolean;
	profileGroupList?: ProfileGroups;

	/**
	 * The user's role.  IE: Manager, Standard, Admin, etc.
	 */
	role: string;
	isSSO: boolean;
}
