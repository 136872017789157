import { ContractBase } from '../contract-base';

export class VendorContract extends ContractBase {
	public ObjectId: number;
	public Description: string;
	public IsActive: number;
	public UnitCost: number;
	public VendorId: string;
	public VendorType: string;
}
