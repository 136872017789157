import { Component, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { IImage } from '../photo-swipe/iimage';

// Import PhotoSwipe
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { TextInMaxLinesComponent } from '../text-in-max-lines/text-in-max-lines.component';
import { fadeInAndOutOnHover } from 'app/app.animations';

@Component({
	selector: 'app-attachment',
	templateUrl: './attachment.component.html',
	styleUrls: ['./attachment.component.scss'],
	animations: [fadeInAndOutOnHover]
})
export class AttachmentComponent {
	@ViewChild('thumbnail', { static: true }) thumbnail: ElementRef;
	@Input() thumbnailPath: string;
	@Input() uploadTimeStamp: string;
	@Input() attachmentName: string;
	@Input() imagePath: string;
	@Input() imageSize: any;
	@Input() isReadOnly: boolean;
	showCloseButton = false;
	images: IImage[] = [];
	dialogRef: MatDialogRef<any, any>;
	fileExtension: string;
	thumbnailStyle: any;

	constructor(private interopService: OmniInteropService, private dialog: MatDialog) {}

	@Input() fileIndexId: any;
	@Output() removeItem = new EventEmitter<number>();

	imgRes: any = {};
	displayName: string;

	ngOnInit() {
		if (this.attachmentName.match(/.(jpg|jpeg|png|gif)$/i)) {
			const image = new Image();
			image.onload = () => {
				this.imgRes.height = image.height;
				this.imgRes.width = image.width;
			};
			image.onerror = () => console.error('not able to load image with path ' + this.imagePath);
			image.src = this.imagePath;
		}
		const nameParts = this.attachmentName.split('.');
		const ext = nameParts[nameParts.length - 1];
		if (ext.length && ext.length < 5) this.fileExtension = '.' + ext;
	}

	onClick() {
		if (!(this.imgRes.width || this.imgRes.height)) {

			if (this.imagePath.match(/.(pdf)$/i) || this.imagePath.match(/.(mp4|mov|3gp|wmv|webm|flv|avi|mpeg|mpeg-4|vob|wav)$/i)) {
				console.log('pdf');
				window.open(this.imagePath, '_blank');
			} else {
				console.log('other');
				window.location.assign(this.imagePath);
			}
			return;
		}

		this.images = [];
		this.images.push({
			src: this.imagePath,
			w: this.imgRes.width,
			h: this.imgRes.height
		});

		const options = {
			index: 0,
			shareButtons: [{ id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }]
		};

		// Initializes and opens PhotoSwipe
		const gallery = new PhotoSwipe(this.interopService.uiManager.photoViewer.photoSwipe.nativeElement, PhotoSwipeUI_Default, this.images, options);
		gallery.init();
	}

	/**
	 * This method will open up a dialog to ask user to confirm upon deleting attachments
	 */
	openDeletePrompt(fileIndexId): void {
		this.dialogRef = this.dialog.open(DeleteDialogComponent, { width: '480px' });
		const dialogConfig = {
			title: 'Delete attachments',
			content: 'Deleting attachments will remove it from workorders and cannot be undone.'
		};
		this.dialogRef.componentInstance.config = dialogConfig;
		this.dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
			if (isConfirmed) {
				this.removeItem.emit(fileIndexId);
			}
		});
	}

	onThumbNailLoad() {
		const originalHeight = this.thumbnail.nativeElement.offsetHeight;
		const originalWidth = this.thumbnail.nativeElement.offsetWidth;
		let newHeight = 130;
		let newWidth = 130;
		if (originalWidth > originalHeight) newHeight = (originalHeight / originalWidth) * 130;
		// 130px is the max width;
		else newWidth = (originalWidth / originalHeight) * 130; // 130px is the max height

		this.thumbnailStyle = { width: newWidth + 'px', height: newHeight + 'px', visibility: 'visible' };
	}
}
