export class ArcGISOptions {
	static useIPAuthentication: boolean;
	static defaultTokenMinuteDuration = 60 * 24;
	static maxRetryCountExpiredToken = 2;
	static timeoutDurationSeconds = 60 * 10;
	private static _referer = '';
	static get referer(): string {
		return ArcGISOptions._referer;
	}
	static set referer(value: string) {
		ArcGISOptions._referer = value;
	}
}
