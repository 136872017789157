import { EmployeeTrade } from './employee-trade.model';
import { EmployeeTradeContract } from '../../contracts/work-order';

export class EmployeeTrades extends Array<EmployeeTrade> {
	static fromContracts(employeeTradeContracts: EmployeeTradeContract[]) {
		const employeeTrades = new EmployeeTrades();
		if (!employeeTradeContracts) return employeeTrades;

		for (const employeeTradeContract of employeeTradeContracts) {
			const employeeTrade = EmployeeTrade.fromContract(employeeTradeContract);
			employeeTrades.push(employeeTrade);
		}
		return employeeTrades;
	}

	getByTradeId = (tradeId: string) => this.find(trade => trade.tradeId === tradeId);
}
