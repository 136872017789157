export enum TypeOfValueEnum {
	none = 0,
	recordCount = 1,
	sumOfAField = 2,
	averageValue = 3,
	mostRecentValue = 4,
	percetangeOfTotal = 5
}

export class TypeOfValue {
	name: string;
	enumValue: TypeOfValueEnum;

	constructor() {
		this.name = 'record count';
		this.enumValue = TypeOfValueEnum.recordCount;
	}
}
