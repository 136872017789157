import { Component, Input, OnInit } from '@angular/core';
import { FormControlGroup, ControlType, Style, AlternatingPattern, Color } from 'models';
import { isUndefined } from 'util';
import { BaseFormResolver } from '../form-resolvers/base-form.resolver';
import { FormGridPattern } from '../form-resolvers/form-alternating-pattern';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { FormControlDefinition } from 'models/form-control-definition.model';
import { FormControlInputType } from 'models/form-control-input-type.enum';

@Component({
	selector: 'app-control-group',
	templateUrl: './control-group.component.html',
	styleUrls: ['./control-group.component.scss']
})
export class ControlGroupComponent implements OnInit {
	@Input() controlGroup: FormControlGroup;
	@Input() formResolver: BaseFormResolver;
	formGrid: FormGridPattern;

	constructor() { }

	ngOnInit() {
		this.formGrid = new FormGridPattern(this.controlGroup.controls);
	}

	isAttachmentControl(control: FormControlDefinition) {
		if (!control.input) return;
		return control.input.type === FormControlInputType.Picture;
	}
}
