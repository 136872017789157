<div class="canvas-tab-bar" *ngIf="canvas.mode !== 'empty'" (click)="activateThisCanvas()">
	<span [class.selected]="canvas.mode === 'map'"><img src="assets/canvas-icons/map-location.png" class="omni-md-img" (click)="loadMap()"/></span>
	<span [class.selected]="canvas.mode === 'trend'"><img src="assets/canvas-icons/trend.png" class="omni-md-img" (click)="loadTrend()"/></span>
	<span [class.selected]="canvas.mode === 'table'"><img src="assets/canvas-icons/grid.png" class="omni-md-img" (click)="loadTable()"/></span>

	<!-- canvas tabs -->
	<div class="tab-wrapper">
		<div class="scrollable-content">
			<span *ngIf="!canvas.tabs.length && !showWorkorderInstructionText">
				click or drag in a tile to show
				<label *ngIf="canvas.mode === 'map'">on map</label>
				<label *ngIf="canvas.mode === 'trend'">in the chart</label>
				<label *ngIf="canvas.mode === 'table'">in the table</label>
			</span>
			<span *ngIf="!canvas.tabs.length && showWorkorderInstructionText">
				select an asset or a location on the map
			</span>
			<div
				class="tab"
				*ngFor="let tab of canvas.tabs; let i = index"
				(mouseenter)="tab.hovered = true"
				(mouseleave)="tab.hovered = false"
				[class.active]="tab.active"
				(click)="setCanvasTabActive(tab)"
				#tabElement
			>
				<img [class.hidden]="!tab.locked" class="locked-icon" src="assets/locked.png" />
				<div *ngIf="tab.tile" class="tab-title">
					<app-text-in-max-lines [text]="tab.tile.metric.definition.name" [fontSize]="'12px'" [lineHeight]="'14px'" [maxLines]="2"></app-text-in-max-lines>
				</div>
				<div *ngIf="tab.defaultMetric" class="tab-title">
					<app-text-in-max-lines [text]="tab.defaultMetric.definition.name" [fontSize]="'12px'" [lineHeight]="'14px'" [maxLines]="2"></app-text-in-max-lines>
				</div>
				<div *ngIf="tab.trendDefinition" class="tab-title">
					<app-text-in-max-lines [text]="tab.trendDefinition.trendLabel" [fontSize]="'12px'" [lineHeight]="'14px'" [maxLines]="2"></app-text-in-max-lines>
				</div>

				<div class="close-button-wrapper">
					<img *ngIf="tab.tile || tab.trendDefinition" [class.hidden]="!tab.hovered" class="close-button" src="assets/x-close.png" (click)="closeCanvasTab(i, $event)" />
					<img *ngIf="tab.defaultMetric && config.hasPermission" [class.hidden]="!tab.hovered" class="close-button" src="assets/settings1.png" (click)="goToSettings($event)" />
				</div>
			</div>
		</div>
	</div>
	<span>
		<img *ngIf="config.hasPermission" src="assets/settings1.png" class="omni-md-img" (click)="goToSettings($event)" />
	</span>
</div>
