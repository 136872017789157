import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '../../../app.animations';
import { ConnectorModel } from './connector.model';

@Component({
	selector: 'app-connector',
	templateUrl: './connector.component.html',
	styleUrls: ['./connector.component.scss'],
	animations: [fadeIn]
})
export class ConnectorComponent implements OnInit {
	@Input() definition: ConnectorModel;

	get height() {
		return Math.abs(this.definition.to.y - this.definition.from.y);
	}

	get width() {
		return Math.abs(this.definition.to.x - this.definition.from.x);
	}

	get left() {
		return this.definition.from.x < this.definition.to.x ? this.definition.from.x : this.definition.to.x;
	}

	get top() {
		return this.definition.from.y < this.definition.to.y ? this.definition.from.y : this.definition.to.y;
	}

	get x1() {
		if (this.definition.from.x < this.definition.to.x) return 0;
		return 100;
	}

	get x2() {
		if (this.x1 === 0) return 100;
		return 0;
	}

	get y1() {
		if (this.definition.from.y < this.definition.to.y) return 0;
		return 100;
	}

	get y2() {
		if (this.y1 === 0) return 100;
		return 0;
	}

	constructor() {}

	ngOnInit() {}
}
