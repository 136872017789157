import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileGroup } from '../profile-group.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { User } from '../user.model';

@Component({
	selector: 'app-user-context-menu',
	templateUrl: './user-context-menu.component.html',
	styleUrls: ['./user-context-menu.component.scss']
})
export class UserContextMenuComponent implements OnInit {
	@Input() user: User;
	@Input() onLogOut: () => void;
	@Output() profileGroupSelected = new EventEmitter<ProfileGroup>();
	constructor() {}

	ngOnInit(): void {}

	goToMyProfile() {
		NavigationService.navigateTo(Pages.userProfile, {user: this.user});
	}

	openFreshDeskLink() {
		window.open('https://aquaticinformatics.deskpro.com/', '_blank');
	}

	updateProfileGroup(profileGroup) {
		if (profileGroup.isSelected) return;

		this.profileGroupSelected.emit(profileGroup);
	}

	checkValidIcon(iconUrl) {
		if (!iconUrl.includes('base64')) {
			return 'assets/people.png';
		}

		return iconUrl;
	}
}
