import { AlternatingPattern } from './alternating-pattern.enum';
import { SedaruConfigBase } from './sedaru-config-base.model';
import { GlobalConfig } from '../global-config.model';

export class Color {
	id?: number;
	r: number;
	g: number;
	b: number;
	alpha: number;
	name?: string;
	isDefault?: boolean;

	static createFromRGBA(rgba: string) {
		const color = new Color();
		color.fromRGBA(rgba);
		return color;
	}

	static fromHex(hex: string) {
		const color = new Color();
		color.fromHEX(hex);
		return color;
	}

	fromRGBA(rgba: string) {
		const colorArray = rgba
			.replace('rgba(', '')
			.replace('rgb(', '')
			.replace(')', '')
			.split(',');
		this.r = parseInt(colorArray[0]);
		this.g = parseInt(colorArray[1]);
		this.b = parseInt(colorArray[2]);
		if (colorArray[3]) this.alpha = 100 * parseFloat(colorArray[3]);
	}

	fromHEX(hex: string) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (!result) return;
		this.r = parseInt(result[1], 16);
		this.g = parseInt(result[2], 16);
		this.b = parseInt(result[3], 16);
		this.alpha = 100;
	}

	toString(): string {
		if (this.alpha === null || this.alpha === undefined) return `rgb(${this.r}, ${this.g}, ${this.b})`;
		return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.alpha / 100})`;
	}

	private componentToHex(c: number) {
		const hex = c.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}
	toHex() {
		return '#' + this.componentToHex(this.r) + this.componentToHex(this.g) + this.componentToHex(this.b);
	}
}

export class Colors extends Array<Color> {
	getById = (colorId: number): Color => {
		const result = this.filter(c => c.id === colorId)[0];
		if (!result) return null;

		return result;
	};
}

export class ColorGroup extends SedaruConfigBase {
	constructor(globalConfig: GlobalConfig) {
		super(globalConfig);
	}

	colorId: number;
	get color(): Color {
		return this.globalConfig.colors.getById(this.colorId);
	}
	alternateColorId: number;
	get alternateColor(): Color {
		return this.globalConfig.colors.getById(this.alternateColorId);
	}
	errorColorId: number;
	get errorColor(): Color {
		return this.globalConfig.colors.getById(this.errorColorId);
	}
	warningColorId: number;
	get warningColor(): Color {
		return this.globalConfig.colors.getById(this.warningColorId);
	}
}

export class Layout {
	background: ColorGroup;
	foreground: ColorGroup;
}

export class Style {
	id: number;
	alternatingPattern: AlternatingPattern;
	layout: Layout;
}
