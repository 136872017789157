import { NavigationBarEntry } from './navigation-bar-entry';

export class MenuPanelNavigationEntry {
    private _isLoaded = false;
    private _header: NavigationBarEntry;
    private _footer: NavigationBarEntry;

    public get isLoaded(): boolean {
        return this._isLoaded;
    }
    public set isLoaded(value: boolean) {
        this._isLoaded = value;
    }

    public static createDefault(): MenuPanelNavigationEntry {
        const entry = new MenuPanelNavigationEntry();
        // do more here
        return entry;
    }

    public get header(): NavigationBarEntry {
        if (!this._header) this._header = new NavigationBarEntry(this);

        return this._header;
    }

    public get footer(): NavigationBarEntry {
        if (!this._footer) this._footer = new NavigationBarEntry(this);

        return this._footer;
    }
}
