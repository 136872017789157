<div class="scrolling-content">
	<mat-action-list>
		<div class="omni-list-item-wrapper" *ngFor="let availableMap of availableMapList | keyvalue">
			<mat-list-item class="asset-list-item" [class.selected]="availableMap.key === selectedItem" (click)="onMapTypeSelected(availableMap.key)">
				<span style="flex:1">{{ availableMap.key }}</span>
			</mat-list-item>
			<mat-divider class="divider-item"></mat-divider>
		</div>
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<a mat-button class="omni-xs-font">
			* use Sedaru Studio to add and edit maps
		</a>
	</mat-card-actions>
</mat-card-footer>
