import { Injectable } from '@angular/core';
import * as EsriCore from '../../sedaru-util/esri-core';
import { DataSourceType, CredentialsType } from 'models';
import { HttpClient } from '@angular/common/http';
import { ConnectionClient } from 'sedaru-util/esri-core/connection-client';
import { GlobalConfig } from 'models/global-config.model';

@Injectable({
	providedIn: 'root'
})
export class ArcGISDataSourceService {
	private _services: Array<EsriCore.Server>;
	public get services(): Array<EsriCore.Server> {
		if (!this._services) this._services = new Array<EsriCore.Server>();

		return this._services;
	}

	constructor() {
		// httpClient: HttpClient) {
		// ConnectionClient.httpClient = httpClient;
		EsriCore.ArcGISOptions.referer = window.location.host;
	}

	updatePortalToken(args: { dataSourceLegacyId; newAccessToken }) {
		const service = this.services.find(s => s.id === args.dataSourceLegacyId);
		if (!service) return;

		service.credentials.token = args.newAccessToken;
	}

	declare(globalConfig: GlobalConfig) {
		if (!globalConfig.dataSources || globalConfig.dataSources.length === 0) return;

		const arcGISDataSources = globalConfig.dataSources.filter(ds => {
			return ds.type === DataSourceType.MapService || ds.type === DataSourceType.FeatureService;
		});

		arcGISDataSources.forEach(ds => {
			const server = new EsriCore.Server(ds.url);
			server.id = ds.legacyId;
			server.type = ds.type;
			if (ds.proxy) server.proxyUrl = ds.proxy.url;

			if (!ds.dataSourceCredentials) {
				this.services.push(server);
				return;
			}

			if (ds.dataSourceCredentials.type === CredentialsType.Password) {
				server.credentials = new EsriCore.Credentials(ds.dataSourceCredentials.username, ds.dataSourceCredentials.password);
			} else if (ds.dataSourceCredentials.type && CredentialsType.ArcGISPortalSSOToken) {
				server.credentials = new EsriCore.Credentials('');
				server.credentials.type = EsriCore.CredentialsType.Portal;
			}

			this.services.push(server);
		});
	}

	initialize(): Promise<void> {
		return new Promise<void>(async resolve => {
			const promises = [];

			this.services.forEach(s => {
				promises.push(s.initialize());
			});

			await Promise.all(promises);
			resolve();
		});

		// const op = new FindOperation();
		// op.contains = true;
		// op.layerIds.push(0);
		// op.searchFields.push('incident_commander_name');
		// op.searchText = '123ew';
		// const r3 = await server.find(op);
	}
}
