import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { QueryStatement, QueryStatementMode } from 'models';

@Component({
	selector: 'app-view-query-statement',
	templateUrl: './view-query-statement.component.html',
	styleUrls: ['./view-query-statement.component.scss']
})
export class ViewQueryStatementComponent extends MenuPanelBaseComponent {
	/**
	 * if the view card is hovered on or not
	 */
	notHovered = true;

	/**
	 * The query statement inputed from
	 */
	@Input() queryStatement: QueryStatement;

	/**
	 * Send the query statement index to be deleted
	 */
	@Input() deleteThisQueryStatement: (queryStatementIndex: number) => any;

	/**
	 * Send the query statement mode to the parent component
	 */
	@Output() passModeToQueryStatementComponent = new EventEmitter();
	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * On hovering on
	 */
	onMouseEnter() {
		this.notHovered = false;
	}

	/**
	 * On hovering off
	 */
	onMouseLeave() {
		this.notHovered = true;
	}

	/**
	 * When the query statement is click from view mode, it then changes it to edit mode
	 */
	onStatementClicked() {
		this.passModeToQueryStatementComponent.emit(QueryStatementMode.edit);
	}

	/**
	 * Deletes the query statement
	 * @param {Event} event - click event
	 * @param {number} queryStatementIndex - The index of the query statement in the list
	 */
	onDeleteStatement(event: Event, queryStatementIndex: number) {
		event.stopPropagation();
		this.deleteThisQueryStatement(queryStatementIndex);
	}
}
