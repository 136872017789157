import { AdvancedWorkOrders } from './work-order';
import { ArcGISFeature } from './arc-gis-feature.model';

/**
 * The model of an OMNI metric result.
 */
export class MetricResult {
	/**
	 * Indicates if this metric has been queried or not
	 */
	public hasBeenQueried: boolean;
	/**
	 * Metric value timestamp to be displayed on the gui.
	 */
	public valueTimestamp: Date;
	/**
	 * Metric value to be displayed on the gui.
	 */
	public value: string;
	/**
	 * Metric features timestamp to be displayed on the gui.
	 */
	public featuresTimestamp: Date;
	/**
	 * Metric features to be displayed on the gui.
	 */
	private _features: ArcGISFeature[];
	set features(features: ArcGISFeature[]) {
		this._features = features;
	}

	public workOrderTimestamp: Date;

	private _workOrders: AdvancedWorkOrders;
	set workOrders(wos: AdvancedWorkOrders) {
		this._workOrders = wos;
	}

	get results(): Array<any> {
		return this._features || this._workOrders;
	}

	public graphicsOnMap: any;

	constructor() {}

	/**
	 * This function sets the result value
	 * @param {string} value - The result value
	 * @param {Date} valueTimestamp - The value timestamp
	 */
	setValue(value: string, valueTimestamp: Date): void {
		this.value = value;
		this.valueTimestamp = valueTimestamp;
	}

	getFeatures(): Promise<ArcGISFeature[]> {
		return new Promise(resolve => {
			if (this._features) resolve(this._features);
			const timer = setInterval(() => {
				if (this._features) {
					clearInterval(timer);
					resolve(this._features);
				}
			}, 1000);
		});
	}

	getWorkorders(): Promise<AdvancedWorkOrders> {
		return new Promise(resolve => {
			if (this._workOrders) resolve(this._workOrders);

			const timer = setInterval(() => {
				if (this._workOrders) {
					clearInterval(timer);
					resolve(this._workOrders);
				}
			}, 1000);
		});
	}

	getMetricArcGisFeatures() {
		return this._features;
	}
}
