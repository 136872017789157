import * as SedaruModels from '../../index';
import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import { GlobalConfig } from '../../../models/global-config.model';

export class ResourcesTransform extends TransformBase {
	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig) {
		super(fieldforceConfig, globalConfiguration);
	}

	onTransform(): TransformationResult {
		if (!this.fieldforceConfig.Design || !this.fieldforceConfig.Design.Resources) {
			return this.getResult(true);
		}

		for (const r of this.fieldforceConfig.Design.Resources) {
			const resource = new SedaruModels.Resource();
			resource.id = r.ID;
			resource.url = r.URL;
			this.globalConfig.resources.push(resource);
		}

		return this.getResult(true);
	}
}
