import { Color } from 'models/sedaru-config/style.model';

export class PNGManager {
	static cachedImages = new Map();
	static composeImageKey(imageSrc: string, options: { width?: number; height?: number; backgroundColor?: Color; borderColor?: Color; borderWidth?: number; foregroundColor?: Color }) {
		const key = imageSrc;
		imageSrc += options.width ? options.width : '30';
		imageSrc += options.height ? options.height : '30';
		if (options.backgroundColor && options.backgroundColor.r >= 0 && options.backgroundColor.g >= 0 && options.backgroundColor.b >= 0) {
			imageSrc += options.backgroundColor.r + options.backgroundColor.g + options.backgroundColor.b;
		}
		if (options.borderColor && options.borderColor.r >= 0 && options.borderColor.g >= 0 && options.borderColor.b >= 0) {
			imageSrc += options.borderColor.r + options.borderColor.g + options.borderColor.b;
		}
		imageSrc += options.borderWidth ? options.borderWidth : '2';
		if (options.foregroundColor && options.foregroundColor.r >= 0 && options.foregroundColor.g >= 0 && options.foregroundColor.b >= 0) {
			imageSrc += options.foregroundColor.r + options.foregroundColor.g + options.foregroundColor.b;
		}
		return imageSrc;
	}
	static editImage(imageSrc: string, options: { width?: number; height?: number; backgroundColor?: Color; borderColor?: Color; borderWidth?: number; foregroundColor?: Color }): Promise<string> {
		return new Promise((resolve, reject) => {
			if (!imageSrc) {
				resolve('');
				return;
			}
			const imageName = PNGManager.composeImageKey(imageSrc, options);
			if (PNGManager.cachedImages.has(imageName)) {
				resolve(PNGManager.cachedImages.get(imageName));
				return;
			}
			const newSrc = imageSrc + '?v=' + Math.random();
			const tempImage = new Image();
			tempImage.crossOrigin = 'Anonymous';
			tempImage.onload = () => {
				if (!options.width) options.width = 30;
				if (!options.height) options.height = 30;
				if (!options.borderWidth) options.borderWidth = 2;
				if (!options.borderColor) {
					options.borderColor = new Color();
					options.borderColor.r = 255;
					options.borderColor.g = 255;
					options.borderColor.b = 255;
					options.borderColor.alpha = 100;
				}

				const tempCanvas = document.createElement('canvas');
				const context = tempCanvas.getContext('2d');
				tempCanvas.width = options.width;
				tempCanvas.height = options.height;
				const imageData = context.getImageData(0, 0, options.width, options.height); // the pixels of the original image

				// apply border
				context.lineWidth = options.borderWidth;
				const radius = options.width > options.height ? options.height / 2 : options.width / 2;
				context.arc(radius, radius, radius - options.borderWidth, 0, 2 * Math.PI);
				context.strokeStyle = options.borderColor.toString();
				context.stroke();

				// apply background
				if (options.backgroundColor) {
					context.fillStyle = options.backgroundColor.toString();
					context.fill();
				}

				// set foreground
				const foregroundImage = new Image();
				if (options.foregroundColor) {
					const foreCanvas = document.createElement('canvas');
					const foreContext = foreCanvas.getContext('2d');
					foreCanvas.width = tempImage.width;
					foreCanvas.height = tempImage.height;

					const pixels = imageData.data;

					for (let i = 0, n = pixels.length; i < n; i += 4) {
						if (!pixels[i + 3]) continue; // transparent pixel
						pixels[i] = options.foregroundColor.r; // Red component
						pixels[i + 1] = options.foregroundColor.g; // Blue component
						pixels[i + 2] = options.foregroundColor.b; // Green component
					}
					foreContext.putImageData(imageData, 0, 0);
					foregroundImage.src = foreCanvas.toDataURL();
				}

				// draw image into html canvas
				const maxWidth = Math.sqrt(2 * Math.pow(radius, 2));
				context.drawImage(foregroundImage.src ? foregroundImage : tempImage, radius - maxWidth / 2, radius - maxWidth / 2, maxWidth, maxWidth);

				PNGManager.cachedImages.set(imageName, tempCanvas.toDataURL());
				resolve(tempCanvas.toDataURL());
			};
			tempImage.onerror = error => {
				reject(error);
			};
			tempImage.src = newSrc;
		});
	}
}
