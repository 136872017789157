export class Input {
    constructor(value?: any) {
        this.value = value;
    }

    private _header: string;
    public get header(): string {
        return this._header;
    }
    public set header(value: string) {
        this._header = value;
    }

    private _value: any;
    public get value(): any {
        return this._value;
    }
    public set value(value: any) {
        this._value = value;
    }

    private _inputType: string;
    public get inputType(): string {
        return this._inputType;
    }
    public set inputType(value: string) {
        this._inputType = value;
    }

    private _isReadOnly: boolean;
    public get isReadOnly(): boolean {
        return this._isReadOnly;
    }
    public set isReadOnly(value: boolean) {
        this._isReadOnly = value;
    }

    private _isRequired: boolean;
    public get isRequired(): boolean {
        return this._isRequired;
    }
    public set isRequired(value: boolean) {
        this._isRequired = value;
    }

    private _showButton: boolean;
    public get showButton(): boolean {
        return this._showButton;
    }
    public set showButton(value: boolean) {
        this._showButton = value;
    }

    private _isDisabled: boolean;
    public get isDisabled(): boolean {
        return this._isDisabled;
    }
    public set isDisabled(value: boolean) {
        this._isDisabled = value;
    }
    // set from page
    private _forceReadOnly: boolean;
    public get forceReadOnly(): boolean {
        return this._forceReadOnly;
    }
    public set forceReadOnly(value: boolean) {
        this._forceReadOnly = value;
    }

    public get isDateField(): boolean {
        return this.inputType?.toLowerCase() === 'date';
    }
}
