<mat-card
	class="omni-templated-list-item"
	[class.selected]="selected"
	(click)="!resolver.isDisabled(item) && (selected = !selected) && itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
>
	<img mat-card-avatar class="omni-templated-list-item-icon" [src]="resolver._getIconPath(item)"  (click)="onLeftIconClicked($event)" />
	<mat-card-content class="omni-templated-list-item-title s-font-size"  [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
		{{ resolver._getLabel(item) }}
	</mat-card-content>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-divider"></mat-divider>
