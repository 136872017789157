<div *ngIf="!arcGISFieldLoading" class="edit-query" #editQueryWrapper>
	<app-query-source
		[sourceSelection]="sourceSelection"
		[querySource]="queryStatement.source"
		[passSourceToEditQueryStatementComponent]="onSourceSelected"
		[disableSource]="queryStatement.disableSource"
	></app-query-source>
	<app-query-field
		*ngFor="let queryField of queryStatement.queryFieldList; let i = index"
		[metric]="metric"
		[fieldIndex]="i"
		[querySource]="queryStatement.source"
		[queryField]="queryField"
		[passQueryFieldToParent]="onJoinOperatorSelected"
		[arcGISFieldList]="arcGISFieldList"
		[queryFieldComponentList]="queryFieldComponentList"
		[initialHeight]="queryFieldHeight"
	></app-query-field>
</div>
