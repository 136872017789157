<div class="omni-dropdown" [class.box-shadow]="showShadow">
	<div class="omni-dropdown-header" [class.highlighted]="showHighlight" [ngStyle]="{ 'background-color': headerColor }">
		<input
			*ngIf="showInput"
			class="omni-dropdown-title-free-text"
			[type]="htmlInputCriteria.type"
			[(ngModel)]="header"
			[placeholder]="headerPlaceHolder"
			(change)="onInputSet()"
			(keyup)="inputKeyUp.emit($event)"
			[disabled]="disableInput"
			#dropDownInput
		/>
		<span *ngIf="showSpan" class="omni-dropdown-title-selection" style="flex:1">{{ header ? header : headerPlaceHolder }}</span>
		<img *ngIf="showIcon && !(showDropdown && opened)" src="assets/descending-circled.png" class="omni-dropdown-icon" (click)="buttonClicked()" />
		<img *ngIf="showIcon && showDropdown && opened" src="assets/ascending-circled.png" class="omni-dropdown-icon" (click)="buttonClicked()" />
	</div>
	<ul class="dropdown-list-item-wrapper" *ngIf="showDropdown && opened" [ngStyle]="{ 'max-height': maxHeight - 56 + 'px' }">
		<li *ngFor="let item of itemList" class="dropdown-list-panel-item" (click)="onItemSelected(item)">{{ item.name }}</li>
	</ul>
</div>
