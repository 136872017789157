import { StandardWorkOrderContract } from '../../contracts/work-order';
import { StandardWorkOrder } from './standard-work-order.model';

export class StandardWorkOrders extends Array<StandardWorkOrder> {
	static fromContracts(contracts: Array<any>) {
		const standardWorkOrders = new StandardWorkOrders();
		if (!contracts) return standardWorkOrders;
		for (const swo of contracts) {
			const standardWorkOrder = StandardWorkOrder.fromContract(swo);
			standardWorkOrders.push(standardWorkOrder);
		}
		return standardWorkOrders;
	}

	getContracts = () => {
		const workOrderContracts = new Array<StandardWorkOrderContract>();
		for (const standardWorkOrder of this) {
			const workOrderContract = standardWorkOrder.getContract();
			workOrderContracts.push(workOrderContract);
		}
		return workOrderContracts;
	};
}
