import { Inspection } from './inspection.model';
import { InspectionContract } from '../../contracts/work-order';

export class Inspections extends Array<Inspection> {
	static fromContracts(inspectionContracts: InspectionContract[]) {
		const inspections = new Inspections();
		if (!inspectionContracts) return inspections;

		for (const inspectionContract of inspectionContracts) {
			const inspection = Inspection.fromContract(inspectionContract);
			inspections.push(inspection);
		}
		return inspections;
	}
	getById(id: string) {
		return this.find(inspection => inspection.id === id);
	}
}
