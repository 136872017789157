import { Feature } from '../../sedaru-util/esri-core/feature';

export abstract class Record {
	public get feature(): Feature {
		return this._feature;
	}
	protected constructor(private _feature: Feature = null) {
		if (!_feature) this._feature = new Feature();
	}

	get objectId(): number {
		return this.feature.objectId;
	}
}
