import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { List } from './list.model';
import { SummaryFieldInputType } from './work-order/standard-custom-field.model';
import { GlobalConfig } from './global-config.model';

export class WorkOrderField extends SedaruConfigBase {
    public constructor(globalConfig: GlobalConfig, init?: Partial<WorkOrderField>) {
        super(globalConfig);
		Object.assign(this, init);
	}

    name: string;
    text: string;
    inputType: SummaryFieldInputType;
    isVisible: boolean;
    isRequired: boolean;
    isReadOnly: boolean;
    list?: List;
}
