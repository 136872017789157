import { FormatProperty } from './format-property.model';

export class FormatProperties extends Array<FormatProperty> {
	getById = (formatId: number): FormatProperty => {
		const result = this.find(f => f.id === formatId);
		if (!result) return null;

		return result;
	};
}
