import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { EndBoundaryType } from './end-boundary-type.enum';
import { DateUtil } from '../../sedaru-util/index';

export class EndBoundary extends OmniModel<EndBoundary, Contracts.EndBoundaryContract> {

    constructor() {
        super();
        this.endType = EndBoundaryType.Finite;
    }

    private _endType: SedaruUtils.Undoable<EndBoundaryType>;
    get endType(): EndBoundaryType {
        if (!this._endType) this._endType = new SedaruUtils.Undoable<EndBoundaryType>();
        return this._endType.value;
    }
    set endType(value: EndBoundaryType) {
        if (!this._endType) {
            this._endType = new SedaruUtils.Undoable<EndBoundaryType>(value);
            return;
        }
        this._endType.value = value;
    }

    private _endDate: SedaruUtils.Undoable<Date>;
    get endDate(): Date {
        if (!this._endDate) this._endDate = new SedaruUtils.Undoable<Date>();
        return new Date(this._endDate.value);
    }
    set endDate(value: Date) {
        if (!this._endDate) {
            this._endDate = new SedaruUtils.Undoable<Date>(SedaruUtils.DateUtil.getDateComponent(value));
            return;
        }
        this._endDate.value = SedaruUtils.DateUtil.getDateComponent(value);
    }

    private _occurrences: SedaruUtils.Undoable<number>;
    get occurrences(): number {
        if (!this._occurrences) this._occurrences = new SedaruUtils.Undoable<number>();

        return this._occurrences.value;
    }
    set occurrences(value: number) {
        if (!this._occurrences) {
            this._occurrences = new SedaruUtils.Undoable<number>(value);
            return;
        }
        this._occurrences.value = value;
    }

    static fromContract(contract: Contracts.EndBoundaryContract): EndBoundary {
        const model = new EndBoundary();
        model.endType = contract.endType as EndBoundaryType;
        model.endDate = contract.endDate ? new Date(contract.endDate) : null;
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.EndBoundaryContract {
        const contract = new Contracts.EndBoundaryContract();
        contract.endType = this.endType;
        contract.endDate = !this.endDate ? '' : DateUtil.getFormattedDateTimeString(this.endDate);
        return contract;
    }

    onUpdateInformation(copyModel: EndBoundary): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<EndBoundaryType>(this._endType, () => {
                this.endType = copyModel.endType;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<Date>(this._endDate, () => {
                this.endDate = copyModel.endDate;
            }) || wasChanged;

        return wasChanged;
    }
}
