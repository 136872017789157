import { MetricIconListService } from './metric-icon-list.service';
import { Component, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MetricIcon } from '../../../../../models/metric-icon.model';
import { AssetIconComponent } from './asset-icon.component';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Metric } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';

/**
 * This component is designed to be loaded when the tile & icon field from the add and edit metric page is selected.
 * It holds a collection of metric icons and also has the option to add custom icon.
 */
@Component({
	selector: 'app-metric-icon-list',
	templateUrl: './metric-icon-list.component.html',
	styleUrls: ['./metric-icon-list.component.scss']
})
export class MetricIconListComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the currently selected metric icon.
	 */
	selectedIcon: MetricIcon;

	/**
	 * A list of availalble icons.
	 */
	icons = [];

	/**
	 * holds the background color of metric icon
	 */
	backgroundColor: string;

	/**
	 * holds the foreground color of metric icon
	 */
	foregroundColor: string;

	/**
	 * holds the background color of metric icon in RGB format
	 */
	backgroundRGBCode: string;

	/**
	 * holds the foreground color of metric icon in RGB format
	 */
	foregroundRGBCode: string;

	/**
	 * list of default sedaru colors which shows in color picker
	 */
	sedaruColors = [];

	metric: Metric;

	/**
	 * We are wrapping the AssetIconComponent native element into the ElementRef class that
	 * is just a wrapper class for native elements.
	 */
	@ViewChildren(AssetIconComponent, { read: ElementRef }) assetIconSvgRef: QueryList<ElementRef>;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {MetricService} metricService - Provides all the services required to manipulate a metric
	 * @param {MetricIconListService} metricIconListService - service which provide avialable metric svg icons.
	 */
	constructor(private metricIconListService: MetricIconListService, private mapService: CanvasMapService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	/**
	 * On init, the ID of the currently selected metric and a list of available asset's names are initialized.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'metric icon' });
		this.selectedIcon = this.metricIconListService.availableSvgIcons.find(icon => icon.name === this.metric.definition.icon.name);
		this.icons = this.metricIconListService.availableSvgIcons;

		if (this.selectedIcon) {
			this.selectedIcon.backgroundColor = this.metric.definition.icon.backgroundColor;
			this.selectedIcon.foregroundColor = this.metric.definition.icon.foregroundColor;
		} else {
			this.selectedIcon = new MetricIcon();
		}

		this.backgroundColor = this.selectedIcon.backgroundColor;
		this.foregroundColor = this.selectedIcon.foregroundColor;
		this.backgroundRGBCode = this.hexToRgb(this.selectedIcon.backgroundColor);
		this.foregroundRGBCode = this.hexToRgb(this.selectedIcon.foregroundColor);
		this.sedaruColors = ['#17365D', '#880000', '#666666', '#403251', '#72AE2F', '#0070C0', '#FFFFFF', '#000000'];
	}

	/**
	 * on change event trigers when foreground color picker changes values
	 * @param {string} color - foreground color that get from color picker by on change event action
	 */
	onForegroundColorChange(color: string): void {
		this.foregroundColor = color;
		this.foregroundRGBCode = this.hexToRgb(color);
		this.selectedIcon.foregroundColor = color;
		this.setMetricIconOnChange();
	}

	/**
	 * on change event triigers when background color picker changes values
	 * @param {string} color - background color that get from color picker by on change event action
	 */
	onBackgroundColorChange(color: string): void {
		this.backgroundColor = color;
		this.backgroundRGBCode = this.hexToRgb(color);
		this.selectedIcon.backgroundColor = color;
		this.setMetricIconOnChange();
	}

	/**
	 * This function sets the select icon to the metric
	 * @param {MetricIcon} icon - The icon selected by the user from the list.
	 */
	onIconSelected(icon: MetricIcon): void {
		this.selectedIcon = icon;
		this.foregroundColor = this.selectedIcon.foregroundColor;
		this.backgroundColor = this.selectedIcon.backgroundColor;
		this.setMetricIconOnChange();
	}

	/**
	 * function to set metric icon and update map symbols
	 */
	private setMetricIconOnChange() {
		const newMetricIcon = new MetricIcon();
		newMetricIcon.initialize(this.selectedIcon);
		const getSvgHtml = this.assetIconSvgRef.find(elm => {
			return elm.nativeElement.id === this.selectedIcon.name;
		});

		if (!getSvgHtml) {
			return;
		}

		setTimeout(() => {
			newMetricIcon.generateUrlWithBase64(getSvgHtml.nativeElement.innerHTML);
			this.metric.definition.icon = newMetricIcon;
			// this.mapService.updateSymbols(this.metric, this.metric.definition.icon.url);
		}, 10);
	}

	/**
	 * helper function to convert hexcode color to RGB color
	 * @param {string} hex Color-hex gives information about colors including color models
	 * @return {string} returns RGB (red, green, and blue) color system code
	 */
	private hexToRgb(hex: string): string {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
	}
}
