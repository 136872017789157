import { WorkCommentContract } from '../../contracts/work-order';
import { WorkComment } from './work-comment.model';

export class WorkCommentWrapper {
	private _workComment: WorkComment;
	get workComment(): WorkComment {
		return this._workComment;
	}

	constructor(workComment: WorkComment) {
		this._workComment = workComment;
	}

	get id(): string {
		return this._workComment.id;
	}
	set id(value: string) {
		this._workComment.id = value;
	}

	get commentId(): string {
		return this._workComment.commentId;
	}
	set commentId(value: string) {
		this._workComment.commentId = value;
	}

	get createdBy(): string {
		return this._workComment.createdBy;
	}
	set createdBy(value: string) {
		this._workComment.createdBy = value;
	}

	get createdDate(): string {
		return this._workComment.createdDate;
	}
	set createdDate(value: string) {
		this._workComment.createdDate = value;
	}

	get description(): string {
		return this._workComment.description;
	}
	set description(value: string) {
		this._workComment.description = value;
	}

	get workOrderKey(): string {
		return this._workComment.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._workComment.workOrderKey = value;
	}

	get isDirty(): boolean {
		return this._workComment.isDirty;
	}

	get isNew(): boolean {
		return this._workComment.isNew;
	}

	clearDirty = (): void => this._workComment.clearDirty();

	undoAll = (): boolean => this._workComment.undoAll();

	onUpdateInformation = (copyModel): boolean => this._workComment.onUpdateInformation(copyModel);

	updateInformation = (copyModel): boolean => this._workComment.updateInformation(copyModel);

	getContract = (): WorkCommentContract => this._workComment.getContract();
}
