import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';

export class BaseLayer extends SedaruConfigBase {

    /**
     * The ID from xml config
     */
    legacyId?: number;

    name?: string;

    transparency?: number;

    layerType?: string;

    /**
     * The serviceID from xml config
     */
    dataSourceLegacyId: number;

    constructor(globalConfig: GlobalConfig) {
        super(globalConfig);
	}
}
