<div class="scrolling-content" #scrollingContainer infiniteScroll (scroll)="onScroll()" (scrolled)="!isAdvanced && onScrollStepReached()" [scrollWindow]="false">
	<mat-action-list class="omni-list-panel">
		<app-address-search-results 
			*ngIf="searchModeIdentifier === 'address' && layerResults.length" 
			[addressResults]="layerResults"
			(itemClicked)="handleClick($event)">
		</app-address-search-results>
		<app-workorder-search-results
			*ngIf="searchModeIdentifier === 'workorder' && workOrderSearchResults.length"
			[workorderResults]="workOrderSearchResults"
			[displayedRecordCount]="isAdvanced ? workOrderSearchResults.length : displayedRecordCount"
			(workorderClicked)="workOrderClick($event)">
		</app-workorder-search-results>
		<div *ngIf="isAdvanced &&
					searchModeIdentifier === 'workorder' && 
					scrollPosition && searchComplete && 
					totalNumberOfRecords !== workOrderSearchResults.length" class="more-button" (click)="onMoreClicked()">
			<p>more...</p>
		</div>
	</mat-action-list>
	<div *ngIf="(searchModeIdentifier === 'address' && !layerResults.length) || (searchModeIdentifier === 'workorder' && !workOrderSearchResults.length)" class="omni-logo">
		<img src="assets/search-noresults.png" />
		<label>{{ searchMessage }}</label>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start"> </mat-card-actions>
</mat-card-footer>
