import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-attachments-collection',
  templateUrl: './attachments-collection.component.html',
  styleUrls: ['./attachments-collection.component.scss']
})
export class AttachmentsCollectionComponent {

  @Input() attachments: any[];
  @Input() isReadOnly: boolean;
  @Output() itemToRemove = new EventEmitter<number>();



}
