import { Component, ElementRef } from '@angular/core';
import { fadeInAndOutOnHover } from 'app/app.animations';
import { FlashMessageService } from './../../flash-message/flash-message.service';
import { SnackbarMessageTypes } from '../../flash-message/snackbar-message-types.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MetricTile } from '../../../models/metric-tile.model';
import { MetricRequesterService } from '../../metric/metric-requester.service';
import { MenuPanelBaseComponent } from '../menu-panel-base/menu-panel-base.component';
import { Pages, NavigationService } from 'app/navigation/navigation.service';
import { TileService } from 'app/tiles/tile.service';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { Color } from 'models';
import { DeleteDialogComponent } from 'app/ui-components/delete-dialog/delete-dialog.component';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { CanvasTrendService } from 'app/canvas-container/canvas-trend/canvas-trend.service';
import { PNGManager } from 'app/ui-components/png-manager';
import { MenuPanelNavigationEntry } from 'app/navigation/menu-panel-navigation';
import { CanvasTableService } from 'app/canvas-container/canvas-table/canvas-table.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';

/**
 * This component is designed to be placed in the left side menu panel.
 * The component is loaded when the add icon on the tile is clicked.
 * The component provides us with options to set the metric for the corresponding tile, set tile and text color and also
 * to set a link to canvas.
 */
@Component({
	selector: 'app-tile-settings',
	templateUrl: './tile-settings.component.html',
	styleUrls: ['./tile-settings.component.scss'],
	animations: [fadeInAndOutOnHover]
})
export class TileSettingsComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the configuration of the tile that has been selected.
	 */
	currentTile: MetricTile;
	/**
	 * holds the background color of tile icon which will be in hexadecimal format that we obtained for color picker
	 */
	tileBackgroundColor: string;
	/**
	 * holds the foreground color of tile icon which will be in hexadecimal format that we obtained for color picker
	 */
	tileForegroundColor: string;
	/**
	 * holds the background color of tile in RGB code format for showing up in UI
	 */
	get backgroundRGBCode() {
		return this.convertHexToRGBColorCode(this.tileBackgroundColor);
	}
	/**
	 * holds the foreground color of tile in RGB code format for showing up in UI
	 */
	get foregroundRGBCode() {
		return this.convertHexToRGBColorCode(this.tileForegroundColor);
	}

	/**
	 * list of default sedaru colors which shows in color picker
	 */
	sedaruColors = [];
	/**
	 * The current dialog refrence
	 */
	dialogRef: MatDialogRef<any, any>;
	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {GuiConfigService} guiConfigService
	 */
	constructor(
		private metricRequesterService: MetricRequesterService,
		private interopService: OmniInteropService,
		private canvasService: CanvasService,
		private tileService: TileService,
		private dialog: MatDialog,
		private flashMessageService: FlashMessageService,
		private mapService: CanvasMapService,
		private tableService: CanvasTableService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.currentTile = args.parameter.currentTile;
		console.log('this.currentTile', this.currentTile);
	}

	/**
	 * On init, the currently selected tile and its id is initialized, the current route is emitted and a subscription is
	 * made to the event emitted when the config is changed.
	 */
	ngOnInit() {
		this.setDefaultTileBackground();
		this.initTileColor();
		this.menuPanelComponent.updateView({ title: 'edit tile' });
	}

	onMenuPanelEntryConstruction(nav: MenuPanelNavigationEntry) {
		// nav.header.type = 'header';
		// nav.header.backgroundColor = nav.header.backgroundClasses.grey;
		// nav.header.isVisible = true;
		// nav.header.title = 'edit tile';
		// nav.header.leftButton.type = nav.header.type;
		// nav.header.leftButton.isVisible = this.menuPanelComponent.canGoBack();
		// nav.header.leftButton.icon = nav.header.leftButton.iconImgs.defaultSrc;
		// nav.header.leftButton.toolTip = 'back';
		// nav.header.leftButton.selectedAction = () => {
		// 	this.menuPanelComponent.goBack();
		// };

		// nav.footer.isVisible = true;
		// nav.footer.type = 'footer';
		// nav.footer.leftButton.type = nav.footer.type;
		// nav.footer.leftButton.isVisible = true;
		// nav.footer.leftButton.icon = nav.footer.leftButton.iconImgs.deleteSrc;
		// nav.footer.leftButton.text = 'remove';
		// nav.footer.leftButton.selectedAction = () => {
		// 	this.openDeletePrompt();
		// };
	}

	onPageReload(args: NavigationArgs) {
		this.currentTile = args.parameter.currentTile;
		this.ngOnInit();
	}

	setDefaultTileBackground() {
		if (!this.currentTile.foregroundColor) this.currentTile.foregroundColor = '#ffffff';
		if (this.currentTile.backgroundColor.includes('#')) return;

		const color = new Color();
		color.fromRGBA(this.currentTile.backgroundColor);
		this.currentTile.backgroundColor = this.rgbToHex(color.r, color.g, color.b);

		const tileSavedInterval = setInterval(() => {
			if (this.currentTile.id) {
				this.currentTile.isTileValueSet = true;
				this.saveTile();
				clearInterval(tileSavedInterval);
			}
		}, 100);
	}

	/**
	 * This method is invoked when the 'metric' field in the tile settings panel is clicked.
	 * This method will navigate the side panel to the list of metrices.
	 */
	onGoToMetricList() {
		this.metricRequesterService.setRequester('MetricTile', this.currentTile);
		NavigationService.navigateTo(Pages.editMetric, { metric: this.currentTile.metric });
	}

	GoToTileTimeframeFilter() {
		NavigationService.navigateTo(Pages.timeframefilter, { tile: this.currentTile, timeFrameType: 'tile' });
	}

	/**
	 * This method will open up a dialog to ask user to confirm upon deleting a tile
	 */
	openDeletePrompt(): void {
		this.dialogRef = this.dialog.open(DeleteDialogComponent, { width: '480px' });
		const dialogConfig = {
			title: 'Remove Tile',
			content: 'Removing this will remove values from the tile and cannot be undone.'
		};
		this.dialogRef.componentInstance.config = dialogConfig;
		this.dialogRef.componentInstance.confirmMessage = 'Remove';
		this.dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
			if (isConfirmed) {
				this.onRemoveTile();
			}
		});
	}

	/**
	 * This method is invoked when the 'remove' button at the bottom of the tile settings panel is clicked.
	 * It is used for removing a tile. By removing a tile we mean to just make the tile empty.
	 */
	onRemoveTile() {
		// 'deleteMetricTile' function is set to delete a list of metricTiles
		const removeTile = this.currentTile;
		this.tileService.deleteMetricTile(this.currentTile.id).subscribe(() => {
			this.menuPanelComponent.updateView({ showPacifier: true });
			// remove from canvas tabs
			this.config.canvasList.forEach(canvas => {
				canvas.tabs.forEach(tab => {
					if (tab.tile && tab.tile.id === removeTile.id) {
						this.canvasService.unlinkTileFromCanvas(removeTile.id, removeTile.metric.id, canvas, removeTile.metric.definition.name);
					}
				});
			});

			removeTile.clear();
			if (removeTile.id === this.currentTile.id) {
				this.config.selectedTile = undefined;
			}
			this.menuPanelComponent.navigationRouter.clearComponents();
			this.menuPanelComponent.updateView({ title: 'Sedaru OMNI', showPacifier: false });
			// this.menuPanelNavigationEntry.header.title = "Sedaru OMNI";
		});
	}

	/**
	 * @param colorCode - background-color (in hexadecimal format) obtained from color picker by an on-change event action.
	 */
	onBackgroundColorChange(colorCode: string) {
		this.tileBackgroundColor = colorCode;
		this.currentTile.backgroundColor = colorCode;
		this.saveTile();
	}

	private saveTile() {
		this.tileService.saveMetricTile(this.config.id, this.currentTile).subscribe(
			async () => {
				this.onTileBackgroundColorChanged(this.currentTile);
			},
			error => this.flashMessageService.popMessage(error.message, SnackbarMessageTypes.ERROR)
		);
	}

	saveTimeframeDefinition() {
		this.tileService.saveTimeframeDefinition(this.currentTile).subscribe(data => console.log('data', data));
	}

	/**
	 * @param colorCode - foreground-color (in hexadecimal format) obtained from color picker by an on-change event action.
	 */
	onForegroundColorChange(colorCode) {
		this.tileForegroundColor = colorCode;
		this.currentTile.foregroundColor = colorCode;
		this.saveTile();
	}

	/**
	 * set the tile background color and foreground color that obtained from the selected current tile
	 * and convert Hexadecimal color code to RGB code
	 */
	private initTileColor() {
		if (!this.currentTile) {
			throw new Error('No tile selected!');
		}
		this.tileBackgroundColor = this.currentTile.backgroundColor;
		this.tileForegroundColor = this.currentTile.foregroundColor;
		if (this.tileForegroundColor && this.tileForegroundColor.toLowerCase() === '#fff') this.tileForegroundColor = '#FFFFFF';
		this.sedaruColors = ['#17365D', '#880000', '#666666', '#403251', '#72AE2F', '#0070C0', '#FFFFFF', '#000000'];
	}

	/**
	 * helper function to convert hexa color code to RGB color code
	 * @param hexaColorCode - hexa decimal color code that generated from color picker
	 */
	private convertHexToRGBColorCode(hexaColorCode: string) {
		if (hexaColorCode.toLowerCase() === '#fff') hexaColorCode = '#ffffff';
		if (hexaColorCode === '#000') hexaColorCode = '#000000';
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexaColorCode);
		return result ? parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) : null;
	}

	private componentToHex(c: number) {
		const hex = c.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}
	private rgbToHex(r: number, g: number, b: number) {
		return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
	}
}
