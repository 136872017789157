import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import * as SedaruUtils from 'sedaru-util';
import { MethodHandlerResult, SedaruClientHub } from '../../../sedaru-util/sedaru-subscriptions-library';

export class WorkOrderUpdatedHandler extends Subscriptions.SedaruClientMethodHandlerBase {

	private _onWorkOrderUpdated: SedaruUtils.InvokableEvent;
	public get onWorkOrderUpdated(): SedaruUtils.InvokableEvent {
		if (!this._onWorkOrderUpdated) this._onWorkOrderUpdated = new SedaruUtils.InvokableEvent();

		return this._onWorkOrderUpdated;
	}

	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLowerCase() === 'sedaruconnect.workordersyncedtosystem' ||
			methodIdentifier.toLowerCase() === 'fieldforce.workordersaved';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		args.isHandled = true;

		const jBody = JSON.parse(packet.data);
		const workOrderKey = jBody.workOrderKey;
		if (!workOrderKey) {
			return Promise.resolve(Subscriptions.MethodHandlerResultBase.empty);
		}

		this.onWorkOrderUpdated.invoke(this, { workOrderKey });

		return Promise.resolve(Subscriptions.MethodHandlerResultBase.empty);
	}

	onGetUniqueId(): string {
		return 'WorkOrderUpdatedHandler'; // do not user Class.name because it doesn't work with minified code.
	}

	async sendUpdatedWorkOrderMessage(workOrderKey: string, customerCode: string, isServiceRequest: boolean) {
		if (!workOrderKey || !customerCode) return;

		const data = { workOrderKey, isServiceRequest };

		await this.hub.sendNotification('Fieldforce.WorkOrderSaved', JSON.stringify(data), customerCode);
	}
}
