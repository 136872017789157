import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CanvasTab } from 'omni-model/canvas-tab.model';
import { CanvasSubViewBaseComponent } from '../canvas/canvas-sub-view-base.component';
import { ArcGISField, ChannelTypes, Metric, MetricTypes } from 'models';
import { FieldType } from 'sedaru-util/esri-core';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { OmniTableComponent } from 'app/ui-components/omni-table/omni-table.component';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { OmniTableField } from 'app/ui-components/omni-table/omni-table.field.model';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { DateUtil } from 'sedaru-util';
import { MetricViewService } from 'app/metric/metric-view.service';
import { MapActionType, MetricViewEventArg, TableActionType } from 'app/metric/metric-view-event-arg.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-canvas-table',
	templateUrl: './canvas-table.component.html',
	styleUrls: ['./canvas-table.component.scss']
})
export class CanvasTableComponent extends CanvasSubViewBaseComponent implements OnInit, OnDestroy {

	private _omniTable: OmniTableComponent;
	@ViewChild(OmniTableComponent) set omniTable(omniTable: OmniTableComponent) {
		if (!omniTable) return;
		this._omniTable = omniTable;
		this.omniTable.onMoreRecordsNeeded = batchNeeded => {
			return this.getTableViewRecordsConcurrently(batchNeeded);
		};
	}

	get omniTable() {
		return this._omniTable;
	}

	activeMetric: Metric;

	timeframeFilter: TimeframeFilter;

	isTableLoading = false;
	isEmptyResults = false;

	rowIndex: number;

	bgColor: string;

	tableDataSource: any[];

	tableFields: OmniTableField[];

	tableLength: number;

	batchSize = 500;

	selectedUniqueValue;
	uniqueKey;
	invisibleLink: HTMLAnchorElement;
	/**
	 * Holds the text to be displayed for downloading excel.
	*/
	downloadButtonText = '';
	/**
	  * Holds the path of the icon to be displayed for downloading excel.
	*/
	pacifierIcon = '';
	/**
	* Boolean variable which can be used to control the behaviour of downloading excel.
	*/
	downloadInProgress = false;

	saveFileActive = false;

	downloadUrl = '';

	downloadName = '';

	private currentSorting = { field: null, order: null };
	private subscription: Subscription;

	get isAWO() {
		if (!this.activeMetric) return false;
		if (this.activeMetric.definition.source.type !== ChannelTypes.WorkOrder) return false;
		return this.workorderFactory.isAdvancedMode;
	}

	constructor(
		private interopService: OmniInteropService,
		private workorderFactory: WorkOrderFactory,
		private metricViewService: MetricViewService
	) {
		super();
	}


	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	ngOnInit() {
		const convasTable: CanvasTableComponent = this;
		this.subscription = this.metricViewService.getMetricEventStream()
								.pipe(filter((e, i) => (this.isCanvasActiveWithMode()) && (e.metric == this.activeTabMetric))
								)
								.subscribe(e => this.onMetricEvent(convasTable, e));
		if (!this.canvas) return;
		const activeTab = this.canvas.tabs.find(t => t.active);
		if (!activeTab) return;
		this.activeMetric = activeTab.tile ? activeTab.tile.metric : activeTab.defaultMetric;
		this.bgColor = activeTab.tile?.backgroundColor ?? activeTab.metric.definition.backgroundColor.toString();
		this.setTableDataSource(activeTab);
		this.invisibleLink = document.createElement('a');
	}

	onTabsInitialized(canvasTabs: CanvasTab[]) {
		if (!canvasTabs || !canvasTabs.length) return;
		const activeTab = canvasTabs.find(t => t.active);
		this.activeMetric = activeTab.tile ? activeTab.tile.metric : activeTab.defaultMetric;
		this.bgColor = activeTab.tile.backgroundColor ?? activeTab.metric.definition.backgroundColor.toString();
	}

	onTabSelected(tab: CanvasTab) {
		if (!tab) return;
		this.activeMetric = tab.metric;
		this.bgColor = tab.tile?.backgroundColor ?? tab.metric.definition.backgroundColor.toString();
		this.setTableDataSource(tab);
		this.omniTable.setScrollToTop();
	}

	onTabAdded(tab: CanvasTab) {
		if (!tab) return;
		this.activeMetric = tab.metric;
		this.bgColor = tab.tile?.backgroundColor ?? tab.metric.definition.backgroundColor.toString();
		this.setTableDataSource(tab);
		this.omniTable.setScrollToTop();
	}

	onTabUpdated(tab: CanvasTab, oldId: string) {
		if (!tab) return;
		if (!oldId) return; // if oldId is not provided, the timeframed id is the same, no need to reset the table
		this.activeMetric = tab.metric;
		this.bgColor = tab.tile?.backgroundColor ?? tab.metric.definition.backgroundColor.toString();
	}

	onTabRemoved(metricID: any) {
		this.onTabClosed();
	}

	onTabClosed() {
		this.isEmptyResults = false;
		const activeTab = this.canvas.tabs.find(t => t.active);
		if (!activeTab) return (this.tableDataSource = null);
		this.activeMetric = activeTab.metric;
		this.onTabSelected(activeTab);
	}

	onCanvasActivated() {
		setTimeout(() => {
			this.omniTable.scrollToTargetAdjusted();
		}, 50);
	}

	onMetricRemoved() {
		this.onTabClosed();
	}

	// TODO: This method can be removed once omni-map-graphics uses MetricViewService.
	highlightRecord(uniqueRowItem) {
		this.selectedUniqueValue = typeof(uniqueRowItem.value) !== 'string' ? uniqueRowItem.value.toString() : uniqueRowItem.value;
		this.uniqueKey = uniqueRowItem.isAdvancedWO ? 'workOrderKey' : uniqueRowItem.key;
	}

	clearHighlightRecord() {
		this.selectedUniqueValue = null;
	}

	highlightHoveredItem(uniqueRowItem) {
		const uniqueRowKey = uniqueRowItem.isAdvancedWO ? 'workOrderKey' : uniqueRowItem.key;
		const uniqueRowKeyCaseUp = uniqueRowKey.toUpperCase();
		const uniqueTableRow = this.omniTable.dataSource.data.find(rows => {
			return rows[uniqueRowKey] == uniqueRowItem.value || rows[uniqueRowKeyCaseUp] == uniqueRowItem.value;
		});
		if (uniqueTableRow && !this.omniTable.selection.selected.length) uniqueTableRow['hovered'] = true;
	}

	clearHighlights() {
		if (!this.omniTable) return;
		this.omniTable.dataSource.data.forEach(row => (row['hovered'] = false));
	}

	async rowSelectedEvent(row: any) {
		const metricEventArg: MetricViewEventArg = new MetricViewEventArg();
		metricEventArg.showInSidePanel = true;
		metricEventArg.tableAction = TableActionType.Highlight;
		metricEventArg.mapAction = MapActionType.ZoomOnItem;

		await this.metricViewService
				.triggerMetricEvent(this.activeTabMetric, row, metricEventArg);
	}

	async getTableViewRecords(batch: number) {
		const outFeilds = this.tableFields.map(f => f.key);
		const templateFeilds = this.interopService.templateManager.getTemplateOutFields(this.activeMetric, true);
		outFeilds.push(...templateFeilds);
		const dataSource = (await this.interopService.metricManager.getMetricTableView(
			this.activeMetric,
			this.timeframeFilter,
			this.batchSize,
			batch,
			outFeilds
		)) as Array<any>;
		this.tableLength = this.interopService.metricManager.tableViewCurrentMetricScalarValue;

		return this.formatTableData(dataSource);
	}

	async getTableViewRecordsConcurrently(batchRange) {
		const outFeilds = this.tableFields.map(f => f.key);
		const templateFeilds = this.interopService.templateManager.getTemplateOutFields(this.activeMetric, true);
		outFeilds.push(...templateFeilds);
		const tableViewReturnList = (await this.interopService.metricManager.callTableViewRange(
			this.activeMetric,
			this.timeframeFilter,
			this.batchSize,
			batchRange,
			outFeilds
		)) as Array<any>;
		const tableData = [];
		tableViewReturnList.forEach((tableViewReturn) => {
			if (!(tableViewReturn as any).MetricResults || !(tableViewReturn as any).MetricResults.length) return;
			const timeFrames = (tableViewReturn as any).MetricResults[0].timeframes;
			const results = timeFrames[0].results.features
				? timeFrames[0].results.features.map(f => {
					return f.attributes ? f.attributes : f;
				})
				: timeFrames[0].results[0].WorkOrder
					? timeFrames[0].results.map(r => r.WorkOrder)
					: timeFrames[0].results;
			tableData.push(...results);
		});

		return this.formatTableData(tableData);
	}

	async setTableDataSource(tab: CanvasTab) {
		this.resetExportDownload();
		const metric = tab.metricSubscription.timeframedMetric.metric;
		this.timeframeFilter = tab.metricSubscription.timeframedMetric.timeframeFilter;
		const metricFields = this.getMetricFields(metric);
		this.tableFields = metricFields.map(f => {
			let type: string;
			switch (f.type) {
				case FieldType.esriFieldTypeDouble:
				case FieldType.esriFieldTypeInteger:
					type = 'number';
					break;
				case FieldType.DateTime:
				case FieldType.esriFieldTypeDate:
					type = 'date';
					break;
				default:
					type = 'string';
			}
			return { key: f.name, text: f.alias, type };
		});

		this.isTableLoading = true;
		const dataSource = await this.getTableViewRecords(0);
		this.isTableLoading = false;
		this.tableDataSource = [...dataSource];
		this.tableLength = this.interopService.metricManager.tableViewCurrentMetricScalarValue;
		this.isEmptyResults = this.canvas.tabs.length ? true : false;
		const record = this.tableDataSource[0];
		const {idFieldName, value} = metric.getRecordIdField(record);
		this.uniqueKey = idFieldName
	}

	protected onMetricUpdated(canvasTab: CanvasTab) {
		const activeTab = this.canvas.tabs.find(t => t.active && canvasTab.metric.id == t.metric.id);
		if (!activeTab) return;
		this.setTableDataSource(activeTab);
	}


	/**
	 * Get the id field for the metric, checks the given object
	 * for the property. If value is null, id field should not be null,
	 * we loop through all the properties to find the property with correc casing.
	 * @param metric
	 * @param data
	 * @returns
	 */
	private getIdField(metric: Metric, data: any[]): string {
		if (!metric) {
			return null;
		}
		let idField = metric.definition.getChannel().attributes?.getIdFieldName();
		if (!(data?.length > 0)) {
			return idField;
		}

		const record = data[0];
		const value = record[idField];
		if (!value) {
			const idFieldLowerCase = idField.toLowerCase();
			const normalizedField = Object.keys(record)
										  .find(prop => prop.toLowerCase() == idFieldLowerCase);
			if (normalizedField) {
				idField = normalizedField;
			}
		}
		return idField;
	}

	private onMetricEvent(canvasTable: CanvasTableComponent, eventArg: MetricViewEventArg): void {
		try {
			switch (eventArg.tableAction) {
				case TableActionType.Highlight:
					const {idFieldName, value} = eventArg.metric.getRecordIdField(eventArg.feature);
					const isAdvancedWO = eventArg.metric.definition.source.type == ChannelTypes.WorkOrder;
					const uniqueRowItem = { key: idFieldName, value: value, isAdvancedWO };
					canvasTable.highlightRecord(uniqueRowItem)
					break;

				case TableActionType.ClearHighlight:
					canvasTable.clearHighlightRecord();
					break;

				default:
					break;
			}
		} catch (ex) {
			console.error(ex);
		}

	}

	private getMetricFields(metric: Metric) {
		switch (metric.definition.type) {
			case MetricTypes.awo:
			case MetricTypes.swo:
				return this.workorderFactory.getArcGISFields();
			case MetricTypes.asset:
				return this.interopService.arcGISManager.getArcGisFields(metric, false);
			case MetricTypes.history:
				return this.interopService.arcGISManager.getArcGisFields(metric, true);
		}
	}

	private setDateFormat(dateValue) {
		return DateUtil.convertLocaleUTCStringFormatted(dateValue);
	}

	updateTableBGColor(color: string) {
		this.bgColor = color;
	}

	formatTableData(dataSource) {
		const metricFields = this.getMetricFields(this.activeMetric);
		dataSource.forEach(item => {
			item['hovered'] = false;
			for (const field in item) {
				if (!(field && item[field])) continue;

				const arcGisFeildType = metricFields.find(arcField => arcField.name.toLowerCase() === field.toLowerCase());

				if (!arcGisFeildType) continue;

				if (this.activeMetric.definition.type === MetricTypes.awo && arcGisFeildType.name == 'status') {
					const status = this.workorderFactory.workOrderMetaData.statuses.getByStatusCode(item[field]);
					if (status) item[field] = status.description;
				}
				if (arcGisFeildType.type === FieldType.esriFieldTypeDate) {
					item[field] = this.setDateFormat(item[field]);
				}
			}
		});

		return dataSource;
	}

	async exportTable() {
		this.downloadInProgress = true;
		this.downloadButtonText = 'generating report...';
		this.pacifierIcon = 'assets/circles_pacifier.gif';
		const metric: Metric = this.activeMetric;
		const metricName = metric.definition.name ? metric.definition.name + '.xlsx' : 'metricExport.xlsx';
		const fieldsList = this.interopService?.arcGISManager?.getArcGisFields(metric);
		const reportDownloadUrl = await this.interopService.omniDomain.metricService.exportMetric(
			metric,
			{ timeframeFilter: this.timeframeFilter, sorting: [this.currentSorting] },
			this.getTableFormatting(fieldsList, metric?.definition?.isWorkOrderMetric)
		);
		this.downloadUrl = reportDownloadUrl;
		this.downloadInProgress = false;
		this.saveFileActive = true;
		this.downloadName = metricName;
	}

	downLoadLinkHandler() {
		this.saveFileActive = false;
	}

	resetExportDownload() {
		this.downloadInProgress = false;
		this.saveFileActive = false;
	}

	private getTableFormatting(fieldsList: ArcGISField[], isWorkOrderMetric: boolean = false): any {
		if (!fieldsList || !fieldsList.length) return null;

		const formattingHint = {
			datefieldnames: new Array<string>(),
			integerfieldnames: new Array<string>(),
			doublefieldnames: new Array<string>()
		};

		for (const field of fieldsList) {
			if (!field) continue;
			const fieldName = !isWorkOrderMetric && field.omniName ? field.omniName : field.name;
			switch (field.type) {
				case FieldType.DateTime:
				case FieldType.esriFieldTypeDate:
					formattingHint.datefieldnames.push(fieldName);
					break;
				case FieldType.esriFieldTypeSmallInteger:
				case FieldType.esriFieldTypeInteger:
					formattingHint.integerfieldnames.push(fieldName);
					break;
				case FieldType.esriFieldTypeDouble:
					formattingHint.doublefieldnames.push(fieldName);
					break;
			}
		}

		return formattingHint;
	}

	getTabLayerId(tab?: CanvasTab) {
		if (!tab) tab = this.canvas.tabs.find(t => t.active);
		if (!tab) return;
		if (tab.defaultMetric) return 'default-metric-graphic-layer';
		return tab.tile.id;
	}
}
