import { LayerApplyEditsOperation } from './layer-apply-edits-operation';

export class ApplyEditsTransaction {
	private _operations: LayerApplyEditsOperation[];
	private get operations(): LayerApplyEditsOperation[] {
		return this._operations ? this._operations : (this._operations = new Array<LayerApplyEditsOperation>());
	}

	addOperation(operation: LayerApplyEditsOperation): void {
		this.operations.push(operation);
	}

	addOperations(operations: LayerApplyEditsOperation[]): void {
		this.operations.push(...operations);
	}

	getOperationByLayerId(layerId: number): LayerApplyEditsOperation {
		return this.operations.find(op => op.layerId === layerId);
	}

	getPostBody(): string {
		const operationsArray = [];
		for (const operation of this.operations) {
			if (!operation.hasAnyEdits) continue;

			operationsArray.push(operation.getJSON());
		}

        if (!operationsArray.length) return null;
        return 'edits=' + encodeURIComponent(JSON.stringify(operationsArray));
	}
}
