<div class="scrolling-content">
	<mat-grid-list cols="2" gutterSize="25" rowHeight="100" class="theme-panel">
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('OMNI default')">
				<div class="theme-thumbnail default" [class.cyan-border]="configChanges.theme.displayName == 'OMNI default'"></div>
				<label class="omni-xs-font">OMNI default</label>
			</div>
		</mat-grid-tile>
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('blue burst')">
				<img src="assets/backgrounds/blue_burst.png" class="theme-thumbnail" [class.cyan-border]="configChanges.theme.displayName == 'blue burst'" /><label class="omni-xs-font">blue burst</label>
			</div>
		</mat-grid-tile>
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('pavement')">
				<img src="assets/backgrounds/pavement.jpg" class="theme-thumbnail" [class.cyan-border]="configChanges.theme.displayName == 'pavement'" /><label class="omni-xs-font">pavement</label>
			</div>
		</mat-grid-tile>
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('digital')">
				<img src="assets/backgrounds/digital.jpg" class="theme-thumbnail" [class.cyan-border]="configChanges.theme.displayName == 'digital'" /><label class="omni-xs-font">digital</label>
			</div>
		</mat-grid-tile>
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('blue hex')">
				<img src="assets/backgrounds/blue_hex.jpg" class="theme-thumbnail" [class.cyan-border]="configChanges.theme.displayName == 'blue hex'" /><label class="omni-xs-font">blue hex</label>
			</div>
		</mat-grid-tile>
		<mat-grid-tile>
			<div class="thumbnail-wrapper" (click)="updateTheme('texture')">
				<img src="assets/backgrounds/texture.jpg" class="theme-thumbnail" [class.cyan-border]="configChanges.theme.displayName == 'texture'" /><label class="omni-xs-font">texture</label>
			</div>
		</mat-grid-tile>
	</mat-grid-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font">add custom <img class="omni-sm-img" src="assets/add_custom.png" /></button>
	</mat-card-actions>
</mat-card-footer>
