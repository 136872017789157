import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OmniValueListService } from 'app/omni-value-list/omni-value-list.service';
import { OmniValueListItem } from 'app/omni-value-list/omni-value-list.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { SearchController } from 'sedaru-util/generic-search/search-controller';
import { Search } from 'sedaru-util/generic-search/search';
import { ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { NavigationArgs } from 'app/navigation/navigation-args';

/**
 * This Component is designed to be used whenever one wishes to display a list of value in the menu panel.
 */
@Component({
	selector: 'app-omni-value-list',
	templateUrl: './omni-value-list.component.html',
	styleUrls: ['./omni-value-list.component.scss']
})
export class OmniValueListComponent extends MenuPanelBaseComponent implements OnInit {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	/**
	 * Holds an array of list items which is the content that is to be displayed in the list.
	 */
	public get itemsList(): Array<any> {
		return this.contextualSearch.getSearch<any>().results;
	}

	/**
	 * holds the currently selected item and that will highlighted
	 */
	selectedItem: any;
	selectedItemKey: string;

	private resolvers = new Map();

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {OmniValueListService} listService - Provides services to create OMNI's generic list.
	 * is selected.
	 */
	constructor(private listService: OmniValueListService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.selectedItem = args.parameter.selectedItem;
		this.selectedItemKey = args.parameter.selectedItemKey;
	}

	/**
	 * On init, the list items are initialized and the current route is emitted to manage navigation.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: this.listService.header });
		this.listComponent.getResolver = item => {
			if (this.resolvers.has(item.name)) return this.resolvers.get(item.name);
			return this.getItemResolver(item);
		};
	}

	private getItemResolver(item) {
		const listResolver = NoIconOneLineComponent.createResolver();
		listResolver.getLabel = i => {
			return (i as OmniValueListItem).name;
		};
		listResolver.getAlignment = i => {
			return 'center';
		};
		this.resolvers.set(item.name, listResolver);
		return listResolver;
	}

	/**
	 * This method is invoked when an individual item on the list is selected.
	 * This defines a callback method to load the previous view when after an item is selected. This is the
	 * standard behaviour of an OMNI list.
	 * @param {OmniValueListItem} item - The list of items to be displayed in the list.
	 */
	onValueSelected(item: OmniValueListItem) {
		item.callBackFunction();
		if (item.shouldGoBack) {
			setTimeout(() => this.menuPanelComponent.goBack(), 400);
		}
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (itemsList: any, searchText) => {
			if (itemsList['name'].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) return true;
			return false;
		};

		const search = new Search<any>(new SearchController<any>(this.listService.list, searchFunction));
		return new ContextualSearchBase(search);
	}
}
