import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';

/**
 * This defines the material cost details
 */
export class StandardMaterialCost extends OmniModel<StandardMaterialCost, Contracts.StandardMaterialCostContract> {
	/**
	 * uuid for material cost
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the material id
	 */
	private _materialId: SedaruUtils.Undoable<string>;
	get materialId(): string {
		if (!this._materialId) this._materialId = new SedaruUtils.Undoable<string>();
		return this._materialId.value;
	}
	set materialId(value: string) {
		if (!this._materialId) {
			this._materialId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._materialId.value = value;
	}

	/**
	 * holds the material units
	 */
	private _units: SedaruUtils.Undoable<number>;
	get units(): number {
		if (!this._units) this._units = new SedaruUtils.Undoable<number>();
		return this._units.value;
	}
	set units(value: number) {
		if (!this._units) {
			this._units = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._units.value = value;
	}

	/**
	 * holds the material unit cost
	 */
	private _unitCost: SedaruUtils.Undoable<number>;
	get unitCost(): number {
		if (!this._unitCost) this._unitCost = new SedaruUtils.Undoable<number>();
		return this._unitCost.value;
	}
	set unitCost(value: number) {
		if (!this._unitCost) {
			this._unitCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._unitCost.value = value;
	}

	/**
	 * holds the material date
	 */
	private _datestamp: SedaruUtils.Undoable<string>;
	get datestamp(): string {
		if (!this._datestamp) this._datestamp = new SedaruUtils.Undoable<string>();
		return this._datestamp.value;
	}
	set datestamp(value: string) {
		if (!this._datestamp) {
			this._datestamp = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._datestamp.value = value;
	}

	/**
	 * holds the material work order key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the material total cost
	 */
	private _totalCost: SedaruUtils.Undoable<number>;
	get totalCost(): number {
		if (!this._totalCost) this._totalCost = new SedaruUtils.Undoable<number>();
		return this._totalCost.value;
	}
	set totalCost(value: number) {
		if (!this._totalCost) {
			this._totalCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._totalCost.value = value;
	}

	/**
	 * holds the material work task id
	 */
	private _workTaskId: SedaruUtils.Undoable<string>;
	get workTaskId(): string {
		if (!this._workTaskId) this._workTaskId = new SedaruUtils.Undoable<string>();
		return this._workTaskId.value;
	}
	set workTaskId(value: string) {
		if (!this._workTaskId) {
			this._workTaskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTaskId.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	static fromContract(contract: Contracts.StandardMaterialCostContract): StandardMaterialCost {
		const model = new StandardMaterialCost();
		model.id = contract.objectid ? contract.objectid.toString() : '';
		model.materialId = contract.MaterialId;
		model.units = contract.Units;
		model.unitCost = contract.UnitCost;
		model.datestamp = DateUtil.isValidDate(contract.DateStamp) ? contract.DateStamp : '';
		model.workOrderKey = contract.WorkOrderKey;
		model.totalCost = contract.TotalCost;
		model.workTaskId = contract.WorkTaskId;
		model.createdBy = contract.CreatedBy;
		model.description = contract.Description;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.StandardMaterialCostContract => {
		const contract = new Contracts.StandardMaterialCostContract();
		contract.objectid = +this.id;
		contract.MaterialId = this.materialId;
		contract.Units = +this.units;
		contract.UnitCost = +this.unitCost;
		contract.DateStamp = this.datestamp;
		contract.WorkOrderKey = this.workOrderKey;
		contract.TotalCost = this.totalCost;
		contract.WorkTaskId = this.workTaskId;
		contract.CreatedBy = this.createdBy;
		contract.Description = this.description;
		return contract;
	};

	calculateTotalCost() {
		this.totalCost = this.units * this.unitCost;
	}

	createTimeStamp() {
		this.datestamp = new Date().toLocaleString();
	}

	onUpdateInformation(copyModel: StandardMaterialCost): boolean {
		let wasChanged = false;
		// wasChanged = this.updateIfNotDirty<string>(this._id, () => { this.id = copyModel.id }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._materialId, () => {
				this.materialId = copyModel.materialId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._units, () => {
				this.units = copyModel.units;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._unitCost, () => {
				this.unitCost = copyModel.unitCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._datestamp, () => {
				this.datestamp = copyModel.datestamp;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._totalCost, () => {
				this.totalCost = copyModel.totalCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTaskId, () => {
				this.workTaskId = copyModel.workTaskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		return wasChanged;
	}

	isNew = () => this.id === '0';
}
