<div [ngClass]="{ 'phone-layout': mode === 'phone', 'full-layout': mode !== 'phone' }" [ngStyle]="getBackgroundUrl()">
	<app-alert-dialog></app-alert-dialog>
	<app-calendar-widget #dateTimePicker></app-calendar-widget>
	<app-context-menu></app-context-menu>

	<div class="margin-wrapper">
		<mat-toolbar class="header">
			<app-header class="omni-header" *ngIf="!loading" (toggleTabPanel)="toggleTabPanel()"></app-header>
		</mat-toolbar>

		<mat-sidenav-container class="container-wrapper">
			<mat-sidenav #tabpanel [mode]="mode === 'large' ? 'side' : 'push'" class="left-panel-container">
				<app-tab-panel [availableConfigs]="availableConfigs" [onNewTabRequested]="onNewTabRequested" (tabSelected)="selectTab($event)" (toggleTabPanel)="toggleTabPanel()"></app-tab-panel>
			</mat-sidenav>

			<mat-sidenav-content class="full-layout-content" [ngClass]="{ 'flex-column': mode === 'phone' }">
				<app-photo-swipe #photoViewer></app-photo-swipe>

				<ng-container *ngFor="let config of activatedConfigs.items; last as last">
					<app-main-content [ngClass]="{'active': last }" [hidden]="!last" [mode]="mode" [config]="config"></app-main-content>
				</ng-container>
			</mat-sidenav-content>
		</mat-sidenav-container>
		<mat-toolbar class="footer" *ngIf="mode !== 'phone'"></mat-toolbar>
	</div>

	<app-sedaru-wall [mode]="mode"></app-sedaru-wall>
</div>
