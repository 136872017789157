import { Component, Output, EventEmitter, Input, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import {
	ArcGISAssetChannelAttributes,
	QuerySelectionType,
	Channel,
	ArcGISField,
	QueryStatement,
	QueryOperator,
	QueryField,
	QuerySource,
	QueryStatementMode,
	ChannelTypes,
	Metric,
	ArcGISHistoryChannelAttributes
} from '../../../../../../../models';
import { QueryFieldComponent } from '../../query-field/query-field.component';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-edit-query-statement',
	templateUrl: './edit-query-statement.component.html',
	styleUrls: ['./edit-query-statement.component.scss']
})
export class EditQueryStatementComponent {
	arcGISFieldLoading = false;
	/**
	 * For arcGISField list
	 */
	arcGISFieldList: ArcGISField[] = [];
	/**
	 * For source selection
	 */
	sourceSelection = {};

	queryFieldHeight: number;

	/**
	 * The input from the parent, the queryStatement
	 */
	@Input() queryStatement: QueryStatement;

	@Input() maxHeight: number;
	/**
	 * Send a buble up and signal to the parent when query field id done editing
	 */
	@Input() sendQueryFieldEditDoneSignal: () => any;

	@Input() metric: Metric;

	/**
	 * Pass the queryStatment up to the parent component on execute
	 */
	@Output() passQueryStatementToQueryStatementComponent = new EventEmitter();

	/**
	 * pass the mode of the query statement compoenent to the parent component
	 */
	@Output() passModeToQueryStatementComponent = new EventEmitter();

	@Output() loading = new EventEmitter<boolean>();

	/**
	 * Able to interact with the children components (the query field components)
	 */
	@ViewChildren(QueryFieldComponent) queryFieldComponentList: QueryList<QueryFieldComponent>;

	constructor(private interopService: OmniInteropService, private flashMessageService: FlashMessageService) {}

	/**
	 * On init, we get avaiable source selection base on the history and asset channel
	 */
	ngOnInit() {
		const { historyChannel, assetChannel, workOrderChannel } = this.metric.definition;
		let assetType = '';

		if (!workOrderChannel) {
			if (assetChannel) assetType = (assetChannel.attributes as ArcGISAssetChannelAttributes)?.assetType;
			else if (historyChannel) assetType = (historyChannel.attributes as ArcGISHistoryChannelAttributes)?.assetType;

			this.sourceSelection = this.getSourceSelection(assetType, historyChannel);
		}
		this.getArcGISFields();
		if (this.maxHeight) this.queryFieldHeight = this.maxHeight - 82;
	}

	getArcGISFields() {
		this.loading.emit(true);
		const arcgisField: ArcGISField[] = this.interopService.arcGISManager.getArcGisFields(this.metric);
		if (arcgisField && arcgisField.length > 0) arcgisField.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
		this.arcGISFieldList = arcgisField;
		this.loading.emit(false);
	}

	/**
	 * If this query field component is filled out, meanning it has a field, operator, value ,join, and index,
	 * then prompt to have the join selection open
	 */
	ngAfterViewInit() {
		if (this.queryFieldComponentList.length && Object.keys(this.queryFieldComponentList.last.queryField).length === 5) {
			setTimeout(() => this.queryFieldComponentList.last.setSquence(QuerySelectionType.join), 50);
		}
	}

	/**
	 * When join operator is selected, if it is done, erase all the field after the current queryField
	 */
	onJoinOperatorSelected = (queryField: QueryField) => {
		if (queryField.join.value !== QueryOperator.forJoin.doneOperator.value) {
			this.queryStatement.queryFieldList.push(new QueryField());
		} else {
			this.removeQueryFieldAtRisk(queryField.index + 1);
			const validated = this.validateAllQueryField();
			this.showValidateMessage(validated);
			if (!validated) return;
			this.passModeToQueryStatementComponent.emit(QueryStatementMode.view);
			this.sendQueryFieldEditDoneSignal();
		}
	};

	/**
	 * This function will validate all the query field are filled out before switch to 'view' mode
	 */
	validateAllQueryField(): boolean {
		let isValidated = false;
		this.queryFieldComponentList.forEach(({ queryField }) => {
			if (!queryField.field || !queryField.join || !queryField.operator) return;
			if (queryField.field.name && queryField.join.name && queryField.operator.name) {
				const queryOperatorIsNullOrBlank = queryField.operator.name.toLowerCase().includes('null') || queryField.operator.name.toLowerCase().includes('blank');
				if (queryOperatorIsNullOrBlank) isValidated = true;
				else if (queryField.value.name !== '') isValidated = true;
			}
		});
		return isValidated;
	}

	/**
	 * This function will show the invalid message if apply.
	 * @param {boolean} validated - boolean value indicated if a query field is validated
	 */
	showValidateMessage(validated: boolean) {
		if (validated) return;
		this.flashMessageService.popMessage('Not all query field are filled out.');
	}

	/**
	 * Remove the query field at risk
	 * @param {number} index - the index of the query field to be removed
	 */
	removeQueryFieldAtRisk(index: number) {
		this.queryStatement.queryFieldList.splice(index);
	}

	/**
	 * On source selected, set the source to the queryStatement, and push a query field instance into the queryFieldList
	 */
	onSourceSelected = (source: QuerySource) => {
		if (this.queryStatement.source && this.queryStatement.source.name === source.name) return;

		this.queryStatement.clearQueryFieldList();
		this.queryStatement.source = source;
		this.queryStatement.queryFieldList.push(new QueryField());

		if (!this.queryStatement.index) return;

		this.passQueryStatementToQueryStatementComponent.emit(this.queryStatement);
	};

	/**
	 * Get the avaiable source selectio base off the asset type and if or not there is a history channel
	 * @param {string} assetType - The assetType for the asset
	 * @param {Channel} historyChannel - The historyChannel
	 */
	getSourceSelection(assetType: string, historyChannel: Channel) {
		const assetSourceSelection = {
			name: `${assetType}`,
			value: ChannelTypes.Asset
		};

		const sourceComponent: { opened?: boolean; list: QuerySource[] } = {
			list: [assetSourceSelection]
		};

		if (historyChannel) {
			const historySourceSelection = {
				name: `${assetType}History`,
				value: ChannelTypes.History
			};
			sourceComponent.list.push(historySourceSelection);
			sourceComponent.opened = true;
		}

		// If a source in the query statement already existed, it means we don't open the selection, just populate the header
		if (this.queryStatement.source.name) {
			sourceComponent.opened = false;
		}

		return sourceComponent;
	}
}
