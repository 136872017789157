import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { UserService } from './user.service';
import { Component, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { User } from './user.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GuiConfigService } from 'domain-service/gui-config.service';
import { environment } from 'environments/environment';
import { PNGManager } from 'app/ui-components/png-manager';
import { Color } from 'models';

/**
 * This component was created to be placed in the header of the OMNI app.
 * Although, it could be used elsewhere.  It simply displays the user's full name and their avatar in a single line.
 * It also allows the user to login and logout.
 */
@Component({
	selector: 'app-omni-user',
	templateUrl: './omni-user.component.html',
	styleUrls: ['./omni-user.component.css']
})
export class OmniUserComponent implements OnInit {
	/**
	 * The current user.  This is set to the user service "current user" on init.
	 */
	user: User;

	thumbnail: string;

	showLogOutMenu = false;
	/**
	 * If an error occurs during login then this variable can be used by the view to display an error message to the user.
	 */
	loginErrorMessage: string;
	/**
	 * This will tell the view to show the loading icon or not.
	 */
	get isLoginLoading(): boolean {
		if (this.user) return false;

		return true;
	}

	/**
	 * The current dialog refrence
	 */
	dialogRef: MatDialogRef<any, any>;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {UserService} userService - Used to communicate to the user server and user session.
	 * @param {MatDialog} dialog - The Angular Material dialog component.  Used for login input.
	 * @param {MatSnackBar} snackBar - The Angular Material snack bar component.  Used for login error notifications.
	 */
	constructor(private userService: UserService) {}

	async ngOnInit() {
		this.user = this.userService.currentUser;
		if (this.user.thumbnail) this.thumbnail = this.user.thumbnail;
		else {
			PNGManager.editImage('assets/me.png', {}).then(imageUrl => {
				const urlParts = imageUrl.split(',');
				this.thumbnail = urlParts[1];
			});
		}
	}

	onProfileGroupSelected(profileGroup) {
		this.userService.updateProfileGroupCookie(profileGroup.profileName);
		window.location.reload();
	}
	/**
	 * This function will logout the current user and notify them of the action via snack bar.
	 */
	logout() {
		this.userService.logout();
	}
}
