<app-drop-down
	mode="selection"
	[itemList]="sourceSelection.list"
	[selectedItem]="querySource"
	headerPlaceHolder="select a table"
	[headerColor]="defaultSedaruGrey"
	[opened]="sourceSelection.opened"
	(itemSelected)="onSourceSelected($event)"
	[expansionDisabled]="disableSource"
	[maxHeight]="200"
></app-drop-down>
