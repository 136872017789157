import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { DefaultExtent } from './default-extent.model';
import { GlobalConfig } from './global-config.model';
import { BaseLayers } from './base-layers.model';

export class Map extends SedaruConfigBase {
    zoomFactor?: number;

    spatialReference?: string;

    defaultExtent?: DefaultExtent;

    private _baseLayers?: BaseLayers;
    get baseLayers(): BaseLayers {
      if (!this._baseLayers) {
        this._baseLayers = new BaseLayers();
      }
      return this._baseLayers;
    }

    constructor(globalConfig: GlobalConfig) {
		super(globalConfig);
	}
}
