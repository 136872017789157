import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

export enum EnvironmentType {
	testing,
	staging,
	preview,
	production,
	unknown
}

/**
 * This service bootstraps the omni app
 */
@Injectable({
	providedIn: 'root'
})
export class AppEnvironmentService {
	constructor() {}

	public get isLocalhost(): boolean {
		if (window.location.href.toLowerCase().includes('localhost')) {
			return true;
		}

		return false;
	}

	public get currentEnvironment(): EnvironmentType {
		if (environment.name === 'testing') return EnvironmentType.testing;

		if (environment.name === 'staging') return EnvironmentType.staging;

		if (environment.name === 'preview') return EnvironmentType.preview;

		if (environment.name === 'production') return EnvironmentType.production;

		return EnvironmentType.unknown;
	}

	public get loginUrl(): string {
		if (this.isLocalhost) {
			return 'http://localhost:15132/dev-login.html';
		}

		return environment.loginPortalUrl;
	}
}
