import { WorkOrderSourceType } from '../../../models/work-order-source-type.enum';
import { WorkOrderFilterFieldContract } from '../../../contracts/work-order';
import { WorkOrderFilterField } from '../../../models/work-order/work-order-filter-field.model';
export enum WorkOrderFilterFieldKey {
	WorkTypeID = 'worktypeid',
	WorkType = 'worktype',
	Status = 'status',
	AssignedTo = 'assignedto',
	Priority = 'priority',
	FromDate = 'fromdate',
	ToDate = 'todate',
	StartDate = 'startdate',
	CompletedDate = 'completeddate',
	AssetType = 'assettype',
	CostCategory = 'CostCategory',
	WorkOrderKey = 'workorderkey',
	Team = 'teamid',
	Owner = 'supervisor',
	AssetStatus = 'assetstatus',
	TaskStatus = 'status',
	Task = 'taskkey'
}
class FilterField {
	readonly name: string;
	readonly key: string;
	readonly index: number;
	value: any;
}
export class AdvancedWorkOrderFilterTemplate {
	private static readonly [WorkOrderSourceType.workOrders]: Readonly<Array<FilterField>> = [
		{ name: 'status', key: WorkOrderFilterFieldKey.Status, index: 0, value: '' },
		{ name: 'assigned to', key: WorkOrderFilterFieldKey.AssignedTo, index: 1, value: '' },
		{ name: 'team', key: WorkOrderFilterFieldKey.Team, index: 2, value: '' },
		{ name: 'owner', key: WorkOrderFilterFieldKey.Owner, index: 3, value: '' },
		{ name: 'priority', key: WorkOrderFilterFieldKey.Priority, index: 4, value: '' },
		{ name: 'work type', key: WorkOrderFilterFieldKey.WorkTypeID, index: 5, value: '' }
	];

	private static readonly [WorkOrderSourceType.workOrderAssets]: Readonly<Array<FilterField>> = [
		{ name: 'asset type', key: WorkOrderFilterFieldKey.AssetType, index: 0, value: '' },
		{ name: 'status', key: WorkOrderFilterFieldKey.AssetStatus, index: 1, value: '' }
	];

	private static readonly [WorkOrderSourceType.task]: Readonly<Array<FilterField>> = [
		{ name: 'status', key: WorkOrderFilterFieldKey.TaskStatus, index: 0, value: '' },
		{ name: 'assigned to', key: WorkOrderFilterFieldKey.AssignedTo, index: 1, value: '' },
		{ name: 'team', key: WorkOrderFilterFieldKey.Team, index: 2, value: '' },
		{ name: 'task type', key: WorkOrderFilterFieldKey.Task, index: 5, value: '' }
	];

	static get(sourceType: WorkOrderSourceType): WorkOrderFilterField[] {
		return AdvancedWorkOrderFilterTemplate[sourceType];
	}
}

export class StandardWorkOrderFilterTemplate {
	// Take index key enum value 0
	static readonly [0]: Readonly<any>;

	static readonly [WorkOrderSourceType.workOrderCosts]: Readonly<Readonly<WorkOrderFilterFieldContract>[]> = [
		{ name: 'work type', key: WorkOrderFilterFieldKey.WorkType, value: '', index: 0 },
		{ name: 'status', key: WorkOrderFilterFieldKey.Status, value: '', index: 1 },
		{ name: 'assigned to', key: WorkOrderFilterFieldKey.AssignedTo, value: '', index: 2 },
		{ name: 'priority', key: WorkOrderFilterFieldKey.Priority, value: '', index: 3 },
		{ name: 'created date (from)', key: WorkOrderFilterFieldKey.FromDate, value: '', index: 4 },
		{ name: 'created date (to)', key: WorkOrderFilterFieldKey.ToDate, value: '', index: 5 },
		{ name: 'estimated start date', key: WorkOrderFilterFieldKey.StartDate, value: '', index: 6 },
		{ name: 'completed date', key: WorkOrderFilterFieldKey.CompletedDate, value: '', index: 7 },
		{ name: 'cost category', key: WorkOrderFilterFieldKey.CostCategory, value: '', index: 8 }
	];
}
