import { AssetLocationsWithAssets } from './asset-locations-with-assets.model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';
import { Geometry } from '../geometry.model';

/**
 * This defines the sedaru work asset
 */
export class WorkAsset extends OmniModel<WorkAsset, Contracts.WorkAssetContract> {
	/**
	 * uuid for work asset
	 */
	private _objectId: SedaruUtils.Undoable<number>;
	get objectId(): number {
		if (!this._objectId) this._objectId = new SedaruUtils.Undoable<number>(0);
		return this._objectId.value;
	}
	set objectId(value: number) {
		if (!this._objectId) {
			this._objectId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._objectId.value = value;
	}

	/**
	 * holds the work asset key
	 */
	private _assetKey: SedaruUtils.Undoable<string>;
	get assetKey(): string {
		if (!this._assetKey) this._assetKey = new SedaruUtils.Undoable<string>();
		return this._assetKey.value;
	}
	set assetKey(value: string) {
		if (!this._assetKey) {
			this._assetKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetKey.value = value;
	}

	/**
	 * holds the work asset asset type
	 */
	private _assetType: SedaruUtils.Undoable<string>;
	get assetType(): string {
		if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
		return this._assetType.value;
	}
	set assetType(value: string) {
		if (!this._assetType) {
			this._assetType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assetType.value = value;
	}

	/**
	 * holds the work order key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the work asset system id
	 */
	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	/**
	 * holds the work asset id
	 */
	private _assetId: SedaruUtils.Undoable<number>;
	get assetId(): number {
		if (!this._assetId) this._assetId = new SedaruUtils.Undoable<number>();
		return this._assetId.value;
	}
	set assetId(value: number) {
		if (!this._assetId) {
			this._assetId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._assetId.value = value;
	}

	/**
	 * Holds work asset geometry
	 */
	private _geometry: Geometry;
	get geometry(): Geometry {
		return this._geometry;
	}
	set geometry(geometry: Geometry) {
		this._geometry = geometry;
	}

	static fromContract(contract: Contracts.WorkAssetContract, assetsByLayers: any, assetLocationsWithAssets: AssetLocationsWithAssets): WorkAsset {
		const model = new WorkAsset();
		model.objectId = contract.ObjectId;
		model.assetKey = contract.AssetKey;
		model.assetType = contract.AssetType;
		model.workOrderKey = contract.WorkOrderKey;
		model.systemId = contract.SystemId;
		model.assetId = contract.AssetOID;
		if (assetsByLayers && contract.AssetType && assetsByLayers[contract.AssetType]) {
			const { geometryType, spatialReference, features } = assetsByLayers[contract.AssetType];
			const featureFound = features.find(feature => {
				const assetLocation = assetLocationsWithAssets.getAssetLocationByActiveAsset(contract.AssetKey);
				for (const value of Object.values(feature.attributes)) {
					if (value === null || value === undefined) continue;
					// Leave this double equal!!! NOT triple equal!!!
					if (assetLocation && value == assetLocation.gisId) return true;
					if (value == contract.AssetKey) return true;
				}
			});
			if (featureFound) {
				model.geometry = featureFound.geometry;
				model.geometry.geometryType = geometryType;
				model.geometry.spatialReference = spatialReference;
				model.geometry.hasChecked = true;
			}
		}
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.WorkAssetContract => {
		const contract = new Contracts.WorkAssetContract();
		contract.ObjectId = this.objectId;
		contract.AssetKey = this.assetKey;
		contract.AssetType = this.assetType;
		contract.WorkOrderKey = this.workOrderKey;
		contract.SystemId = this.systemId;
		contract.AssetOID = this.assetId;
		return contract;
	};

	onUpdateInformation(copyModel: WorkAsset): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<number>(this._objectId, () => {
				this.objectId = copyModel.objectId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetKey, () => {
				this.assetKey = copyModel.assetKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._assetId, () => {
				this.assetId = copyModel.assetId;
			}) || wasChanged;
		return wasChanged;
	}

	isNew = () => this.objectId === 0;
}
