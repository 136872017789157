import { CanvasMapService } from '../../canvas-container/canvas-map/canvas-map.service';
import { Component, ElementRef } from '@angular/core';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { Canvas, CanvasMode } from '../../../omni-model/canvas.model';
import { CanvasMap } from '../../../omni-model/canvas-map.model';
import { MenuPanelBaseComponent } from '../menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { DataSourceType } from 'models';
import { OmniInteropService } from 'domain-service/omni-interop.service';

/**
 * This component is designed to be placed in the right side panel.
 * It displays a list of available map types.
 */
@Component({
	selector: 'app-map-list',
	templateUrl: './map-list.component.html',
	styleUrls: ['./map-list.component.scss']
})
export class MapListComponent extends MenuPanelBaseComponent {
	/**
	 * Holds an array of map types available.
	 */
	availableMapList = {};
	/**
	 * Holds the currently selected map object loaded on the canvas.
	 */
	selectedMap: CanvasMap;

	selectedItem: any;

	selectedItemKey: string;

	/**
	 * The constructly currently initialized a hard coded list of map types along with loading and injecting dependencies.
	 * @param {CanvasMapService} mapService - Provides services to manage changes in the ESRI map added  to the canvas.
	 * @param {ScrollToViewService} scrollingService - This registers the view which is to be scrolled in when
	 * a user hits something, say a tile, on a mobile device.
	 */
	constructor(
		private mapService: CanvasMapService,
		private scrollingService: ScrollToViewService,
		private canvasService: CanvasService,
		view: ElementRef<HTMLElement>,
		private interopService: OmniInteropService) {
		super(view);
	}

	/**
	 * On init, the current route is emitted.
	 */
	ngOnInit() {
		this.availableMapList = [];
		for (const ds in this.mapService.availableDataSources) {
			if (this.mapService.availableDataSources[ds].type === DataSourceType.MapService) {
				this.availableMapList[ds] = this.mapService.availableDataSources[ds];
			}
		}
		this.menuPanelComponent.updateView({ title: 'select map*' });
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args && args.parameter && args.parameter.selectedItem) this.selectedItem = args.parameter.selectedItem;
	}

	/**
	 * This method is invoked when any one of the map type is selected from the list of map types.
	 * It will load the selected map on the selected canvas and also navigate to the map settings side panel.
	 * @param {string} mapName - Currently it could be either of the value from 'water map', 'sewer map', or 'county parcel map'.
	 */
	onMapTypeSelected(mapName: string): void {
		if (!this.config.selectedCanvas.map) {
			this.config.selectedCanvas.map = new CanvasMap();
		}
		if (this.config.selectedCanvas.map.name === mapName) return;

		this.config.selectedCanvas.map.name = mapName;
		const canvasMapComponent = this.interopService.uiManager?.activeCanvasComponent?.canvasMapComponent;
		canvasMapComponent?.init();
		this.selectedMap = this.config.selectedCanvas.map;
		const { selectedCanvas } = this.config;
		const isNewCanvas = selectedCanvas.mode === CanvasMode.empty;
		selectedCanvas.mode = CanvasMode.map;

		this.canvasService.saveOmniCanvas(this.config, selectedCanvas, isNewCanvas).subscribe((canvas: Canvas) => {
			if (canvas.map) {
				this.config.selectedCanvas.map.id = canvas.map.id;
			}
			this.config.selectedCanvas.id = selectedCanvas.id;
			this.config.selectedCanvas.mode = CanvasMode.map;
			this.scrollingService.canvasContainer.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
			NavigationService.navigateTo(Pages.mapSettings);
		});
	}
}
