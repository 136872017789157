import { TimeframeUnit } from './time-frame/timeframe-unit.enum';

export class Operator {
	name: string;
	value: string;
}

export class UnitOperator {
	name: string;
	value: TimeframeUnit;
}

class StringOperator {
	static readonly is: Readonly<Operator> = {
		name: 'is',
		value: '='
	};

	static readonly isNot: Readonly<Operator> = {
		name: 'is not',
		value: '<>'
	};

	static readonly contains: Readonly<Operator> = {
		name: 'contains',
		value: 'contains'
	};
	static readonly doesNotContain: Readonly<Operator> = {
		name: 'does not contain',
		value: 'doesnotcontain'
	};
	static readonly blank: Readonly<Operator> = {
		name: 'blank',
		value: '='
	};
	static readonly isNull: Readonly<Operator> = {
		name: 'is null',
		value: 'isnull'
	};
	static readonly isNotNull: Readonly<Operator> = {
		name: 'is not null',
		value: 'isnotnull'
	};
}

export class AlternativeOperatorKeywords {
	static readonly customFieldOperators = [
		{	operatorValue: 'LIKE',
			customFieldOperatorValue: 'CONTAINS'
		},
		{	operatorValue: 'NOT LIKE',
			customFieldOperatorValue: 'DOESNOTCONTAIN'
		},
		{
			operatorValue: 'IS NULL',
			customFieldOperatorValue: 'ISNULL'
		},
		{
			operatorValue: 'IS NOT NULL',
			customFieldOperatorValue: 'ISNOTNULL'
		}
	];
}
class NumericOperator {
	static readonly equals: Readonly<Operator> = {
		name: 'equals',
		value: '='
	};
	static readonly doesNotEqual: Readonly<Operator> = {
		name: 'not equal to',
		value: '<>'
	};
	static readonly greater: Readonly<Operator> = {
		name: 'greater than',
		value: '>'
	};
	static readonly greaterEqual: Readonly<Operator> = {
		name: 'greater than or =',
		value: '>='
	};
	static readonly less: Readonly<Operator> = {
		name: 'less than',
		value: '<'
	};
	static readonly lessEqual: Readonly<Operator> = {
		name: 'less than or =',
		value: '<='
	};
	static readonly isNull: Readonly<Operator> = {
		name: 'is null',
		value: 'isnull'
	};
	static readonly isNotNull: Readonly<Operator> = {
		name: 'is not null',
		value: 'isnotnull'
	};
}

class DateOperator {
	static readonly within: Readonly<Operator> = {
		name: 'within',
		value: '>='
	};
	static readonly before: Readonly<Operator> = {
		name: 'before',
		value: '<'
	};
	static readonly after: Readonly<Operator> = {
		name: 'after',
		value: '>'
	};
	static readonly isNull: Readonly<Operator> = {
		name: 'is null',
		value: 'isnull'
	};
	static readonly isNotNull: Readonly<Operator> = {
		name: 'is not null',
		value: 'isnotnull'
	};
}

class JoinOperator {
	static readonly orOperator: Readonly<Operator> = {
		name: 'or',
		value: 'OR'
	};
	static readonly andOperator: Readonly<Operator> = {
		name: 'and',
		value: 'AND'
	};

	static readonly doneOperator: Readonly<Operator> = {
		name: 'done',
		value: 'DONE'
	};
}

class TimeframeIntervalOperator {
	static readonly hour: Readonly<UnitOperator> = {
		name: 'hour',
		value: TimeframeUnit.Hours
	};
	static readonly day: Readonly<UnitOperator> = {
		name: 'day',
		value: TimeframeUnit.Days
	};
	static readonly week: Readonly<UnitOperator> = {
		name: 'week',
		value: TimeframeUnit.Weeks
	};
	static readonly month: Readonly<UnitOperator> = {
		name: 'month',
		value: TimeframeUnit.Months
	};
	static readonly quarter: Readonly<UnitOperator> = {
		name: 'quarter',
		value: TimeframeUnit.Quarters
	};
	static readonly year: Readonly<UnitOperator> = {
		name: 'year',
		value: TimeframeUnit.Years
	};
}

class TimeframePeroidOperator {
	static readonly week: Readonly<UnitOperator> = {
		name: 'week',
		value: TimeframeUnit.Weeks
	};
	static readonly month: Readonly<UnitOperator> = {
		name: 'month',
		value: TimeframeUnit.Months
	};
	static readonly quarter: Readonly<UnitOperator> = {
		name: 'quarter',
		value: TimeframeUnit.Quarters
	};
	static readonly year: Readonly<UnitOperator> = {
		name: 'year',
		value: TimeframeUnit.Years
	};
}

class DoneOperator {
	static readonly doneOperator: Readonly<Operator> = {
		name: 'done',
		value: 'DONE'
	};
}

export class QueryOperator {
	static readonly forString = StringOperator;
	static readonly forNumeric = NumericOperator;
	static readonly forDate = DateOperator;
	static readonly forJoin = JoinOperator;
	static readonly forTimeframeInterval = TimeframeIntervalOperator;
	static readonly forTimeframePeroid = TimeframePeroidOperator;
	static readonly forDone = DoneOperator;
}
