import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { OmniModel } from '../omni-model';

export class WeeklyPattern extends OmniModel<WeeklyPattern, Contracts.WeeklyPatternContract> {
    daysList = [
        { key: 0, name: 'Sunday' },
        { key: 1, name: 'Monday' },
        { key: 2, name: 'Tuesday' },
        { key: 3, name: 'Wednesday' },
        { key: 4, name: 'Thursday' },
        { key: 5, name: 'Friday' },
        { key: 6, name: 'Saturday' }
    ];

    get daysString(): string {
        return `${this.daysOfWeek.length} days`;
    }

    private _daysOfWeek: SedaruUtils.Undoable<number[]>;
    get daysOfWeek(): number[] {
        if (!this._daysOfWeek) this._daysOfWeek = new SedaruUtils.Undoable<number[]>(new Array<number>());
        return this._daysOfWeek.value;
    }
    set daysOfWeek(value: number[]) {

        if (value) {
            value = value.filter((v, i) => value.indexOf(v) === i);
            value.sort((a, b) => a - b);
        }

        if (!this._daysOfWeek) {
            this._daysOfWeek = new SedaruUtils.Undoable<number[]>(value);
            return;
        }
        this._daysOfWeek.value = value;
    }

    static fromContract(contract: Contracts.WeeklyPatternContract): WeeklyPattern {
        const model = new WeeklyPattern();
        model.daysOfWeek = contract.daysofweek;
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.WeeklyPatternContract {
        const contract = new Contracts.WeeklyPatternContract();
        contract.daysofweek = this.daysOfWeek;
        return contract;
    }

    onUpdateInformation(copyModel: WeeklyPattern): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<number[]>(this._daysOfWeek, () => {
                this.daysOfWeek = copyModel.daysOfWeek;
            }) || wasChanged;

        return wasChanged;
    }
}
