import * as JSZip from 'jszip';
import { Injectable } from '@angular/core';
// note the  outputType can be 'base64' | 'string' | 'text' | 'binarystring' | 'array' | 'uint8array' | 'arraybuffer' | 'blob' | 'stream';
type ZippedContentType = string | Blob | ArrayBuffer | Uint8Array | NodeJS.ReadableStream;
/**
 * The documentaion for JsZip is at : https://stuk.github.io/jszip/
 */
@Injectable({
	providedIn: 'root'
})
export class ZipReader {
	/**
	 * The instance varibale that holds the new instance of the JSZip library.
	 */
	private jsZip: JSZip;

	constructor() {
		this.jsZip = new JSZip();
	}
	/**
	 * This function unzips the zippedContent.
	 * @param {ZippedContentType} zippedContent - The type of the zipped content that needs to be unzipped
	 * @param {string} fileName - The name of the file that need to be extracted
	 */
	async unzip(zippedContent: ZippedContentType, fileName: string): Promise<any> {
		const unzippedContent = await this.jsZip.loadAsync(zippedContent);
		const file = await unzippedContent.file(fileName).async('string');

		// Returns the unzipped file in JSON strings.
		return file;
	}
}
