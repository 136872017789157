import { StandardWorkAssetContract, WorkAssetContract } from '../../contracts/work-order';
import { StandardWorkAsset } from './standard-work-asset.model';
import { WorkAsset } from './work-asset.model';
import { Geometry } from '../geometry.model';

export class WorkAssetWrapper {
	private _workAsset: WorkAsset | StandardWorkAsset;
	get workAsset(): WorkAsset | StandardWorkAsset {
		return this._workAsset;
	}

	constructor(workAsset: WorkAsset | StandardWorkAsset) {
		if (workAsset instanceof WorkAsset) this._workAsset = workAsset as WorkAsset;
		else this._workAsset = workAsset as StandardWorkAsset;
	}

	get id(): number {
		return this._workAsset.objectId;
	}
	set id(value: number) {
		this._workAsset.objectId = value;
	}

	get assetKey(): string {
		return this._workAsset.assetKey;
	}
	set assetKey(value: string) {
		this._workAsset.assetKey = value;
	}

	get assetType(): string {
		return this._workAsset.assetType;
	}
	set assetType(value: string) {
		this._workAsset.assetType = value;
	}

	get workOrderKey(): string {
		return this._workAsset.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._workAsset.workOrderKey = value;
	}

	get systemId(): string {
		return this._workAsset.systemId;
	}
	set systemId(value: string) {
		this._workAsset.systemId = value;
	}

	get assetId(): number {
		if (this._workAsset instanceof WorkAsset) return (this._workAsset as WorkAsset).assetId;
		return (this._workAsset as StandardWorkAsset).assetOID;
	}
	set assetId(value: number) {
		if (this._workAsset instanceof WorkAsset) {
			(this._workAsset as WorkAsset).assetId = value;
			return;
		}

		(this._workAsset as StandardWorkAsset).assetOID = value;
	}

	get isCompleted(): number {
		if (this._workAsset instanceof WorkAsset) return Number.NaN;
		return (this._workAsset as StandardWorkAsset).isCompleted;
	}
	set isCompleted(value: number) {
		if (this._workAsset instanceof WorkAsset) return;
		(this._workAsset as StandardWorkAsset).isCompleted = value;
	}

	get workInspectionKey(): string {
		if (this._workAsset instanceof WorkAsset) return '';
		return (this._workAsset as StandardWorkAsset).workInspectionKey;
	}
	set workInspectionKey(value: string) {
		if (this._workAsset instanceof WorkAsset) return;
		(this._workAsset as StandardWorkAsset).workInspectionKey = value;
	}

	get geometry(): Geometry {
		if (this._workAsset instanceof StandardWorkAsset) return null;
		return (this._workAsset as WorkAsset).geometry;
	}
	set geometry(value: Geometry) {
		if (this._workAsset instanceof StandardWorkAsset) return;
		(this._workAsset as WorkAsset).geometry = value;
	}

	get isDirty(): boolean {
		return this._workAsset.isDirty;
	}

	clearDirty = (): void => this._workAsset.clearDirty();

	undoAll = (): boolean => this._workAsset.undoAll();

	onUpdateInformation = (copyModel): boolean => this._workAsset.onUpdateInformation(copyModel.workAsset);

	updateInformation = (copyModel): boolean => this._workAsset.updateInformation(copyModel.workAsset);

	getContract = (): WorkAssetContract | StandardWorkAssetContract => this._workAsset.getContract();
}
