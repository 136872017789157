import { StandardWorkAssetContract } from '../../contracts/work-order';
import { StandardWorkAsset } from './standard-work-asset.model';

export class StandardWorkAssets extends Array<StandardWorkAsset> {
    static fromContracts(workAssetContracts: StandardWorkAssetContract[]): StandardWorkAssets {
        const workAssets = new StandardWorkAssets();
        for (const contract of workAssetContracts) workAssets.push(StandardWorkAsset.fromContract(contract));
        return workAssets;
    }

    static fromModels(workAssetModels: StandardWorkAsset[]): StandardWorkAssets {
        const workAssets = new StandardWorkAssets();
        for (const workAsset of workAssetModels) workAssets.push(workAsset);
        return workAssets;
    }

    getContracts = (): StandardWorkAssetContract[] => {
        const workAssetContracts = new Array<StandardWorkAssetContract>();
        for (const workAsset of this) workAssetContracts.push(workAsset.getContract());
        return workAssetContracts;
    };

    getNewAssets = () => {
		const newWorkAssets = new StandardWorkAssets();
		for (const workAsset of this) {
			if (workAsset.isNew()) newWorkAssets.push(workAsset);
		}
		return newWorkAssets;
	};

    isDirty = (): boolean => !!this.filter(wa => wa.isDirty).length;

    clearDirty = (): void => this.forEach(wa => wa.isDirty ? wa.clearDirty() : null);
}
