<div class="lem-list">
	<div class="list-header">
		<span>{{ LEMListHeader }}</span>
		<mat-divider class="divider-item"></mat-divider>
	</div>
	<div class="list-wrapper">
		<app-omni-list [omniListItems]="lemList" (itemClicked)="onItemClicked($event)"></app-omni-list>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<span>{{ footerText }}: {{ lemList.length }}</span
		>&nbsp;
	</mat-card-actions>
</mat-card-footer>
