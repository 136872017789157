<div class="scrolling-content">
	<mat-action-list class="map-settings">
		<mat-list-item class="dark-gray-background menu-panel-input-field">
			<div>
				<span class="control-title">map</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="canvasMap ? canvasMap.name : 'not set'" />
				<button mat-icon-button (click)="goTo('mapList')">
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div
				[matTooltip]="defaultMetric ? defaultMetric.definition.name : 'not set'"
				[matTooltipPosition]="'above'"
				[matTooltipDisabled]="(defaultMetricInput.offsetWidth >= defaultMetricInput.scrollWidth)"
			>
				<span class="control-title">default metric</span>
				<div class="control-body">
					<input
						#defaultMetricInput
						class="control-value omni-restrict-overflow"
						disabled
						[value]="defaultMetric ? defaultMetric.definition.name : 'not set'" />
					<div class="control-button">
						<button (click)="goTo('defaultMetric')" mat-icon-button>
							<img src="assets/next-circled.png" />
						</button>
					</div>
				</div>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
