import { HttpServiceBase, ServiceEndpointResult } from './http-service-base';
import { User } from 'app/user/user.model';
import { HttpClient } from '@angular/common/http';
import { IdentityController } from './identity-controller';
import { UserService } from 'app/user/user.service';

export class MembershipHttpService extends HttpServiceBase {
	constructor(baseUrl: string, httpClient: HttpClient, identityController: IdentityController) {
		super(baseUrl, httpClient, identityController);
	}

	private _userService: UserService;
	async validateUser(agsPortalToken: string, customerCode: string): Promise<ServiceEndpointResult<User>> {
		const data = { PortalToken: agsPortalToken };

		const response = await this.post(customerCode + '/users/ValidateUser', data);

		const result = new ServiceEndpointResult<User>(response);
		if (!response.success) {
			return result;
		}

		const jUser = response.defaultResponse.result[0];
		const user = new User();
		user.userName = jUser.UserName;
		user.fullName = jUser.FullName;
		user.displayName = jUser.DisplayName;
		user.email = jUser.Email;
		user.department = jUser.Department;
		user.status = jUser.Status;
		user.thumbnail = jUser.Thumbnail;

		user.customerCode = customerCode.toLowerCase();

		result.result = user;
		return result;
	}

	async getUserRoles(CustomerCode: string, UserName: string): Promise<ServiceEndpointResult<string>> {
		const data = { CustomerCode , UserName }
		const response = await this.postData('/GetUserRoles', data)
		const result = new ServiceEndpointResult<string>(response);
		if (!response.success) {
			return result;
		}
		result.result = response.defaultResponse.result.length ? response.defaultResponse.result[0]['UserRole'] : ''
		return result
	}

	async userDetailsFromToken(): Promise<ServiceEndpointResult<User>> {
		const response = await this.getData('/users/UserDetailsFromToken');
		const result = new ServiceEndpointResult<User>(response);
		if (!response.success) {
			return result;
		}
		const jUser = response.defaultResponse.result[0];
		if (!jUser) {
			this._userService.logout();
			return result;
		}
		const user = new User();
		user.userName = jUser.UserName;
		user.fullName = jUser.FullName;
		user.displayName = jUser.DisplayName;
		user.email = jUser.Email;
		user.department = jUser.Department;
		user.status = jUser.Status;
		user.thumbnail = jUser.Thumbnail;

		result.result = user;
		return result;
	}

	async getUniversalProfileConfiguration(): Promise<ServiceEndpointResult<any>> {
		const data = {
			Format: 'json'
		};
		const response = await this.postData('/GetUniversalProfileConfiguration', data);
		const result = new ServiceEndpointResult<any>(response);

		if (!response.success) {
			return result;
		}
		result.result = response.defaultResponse.result;
		return result;
	}

	async updatePassword(CustomerCode: string, UserName: string, Password: string, NewPassword: string): Promise<ServiceEndpointResult<string>> {
		const data = { CustomerCode , UserName, Password, NewPassword };
		const response = await this.postData('/ChangePassword', data)
		const result = new ServiceEndpointResult<string>(response);
		if (!response.success) {
			return result;
		}
		result.result = response.defaultResponse.result.length ? response.defaultResponse.result : '';
		return result
	}

	async updateUser(user: User): Promise<ServiceEndpointResult<string>> {
		const data = user;
		const response = await this.postData('/UpdateUser', data)
		const result = new ServiceEndpointResult<string>(response);
		if (!response.success) {
			return result;
		}
		result.result = response.defaultResponse.result.length ? response.defaultResponse.result : '';
		return result
	}

	onGetTokenParameterName(endpoint: string): string {
		return 'Token';
	}
	onGetDataParameterName(endpoint: string): string {
		return 'Data';
	}

	onStringifyData(endpoint: string): boolean {
		return true;
	}
}
