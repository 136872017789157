import { Credentials, CredentialsType } from './credentials';
import { ArcGISOptions } from './arc-gis-options';
import { ConnectionClient } from './connection-client';
import { Mutex } from '../core/semaphore';
import { PortalCredentials } from './portal-credentials';

export class TokenManager {
	static _renewTokenLock: Mutex = new Mutex();

	static portalAuthenticationHandler: (url: string) => Promise<PortalCredentials>;

	private static _current: TokenManager;
	static get current(): TokenManager {
		if (!TokenManager._current) TokenManager._current = new TokenManager();

		return TokenManager._current;
	}

	private static _identityManager: any;
	static initialize(identityManager: any) {
		TokenManager._identityManager = identityManager;
	}

	private static tokenUrl(tokenUrl: string): string {
		const includesGenerateToken = tokenUrl.includes('generateToken');
		return includesGenerateToken ? tokenUrl : tokenUrl + 'generateToken';
	}

	public static async getTokenUrl(url: string): Promise<string> {
		if (!TokenManager._identityManager) return await this.getDefaultTokenUrl(url);

		const serverUrl = TokenManager._identityManager.findServerInfo(url).server;
		const response = await ConnectionClient.current.getJson(serverUrl + '/rest/info');

		if (!response.success) {
			return '';
		}

		const serverInfo = response.responseJsonToken;

		if (serverInfo.authInfo) {
			return TokenManager.tokenUrl(serverInfo.authInfo.tokenServicesUrl);
		}

		return await TokenManager.getDefaultTokenUrl(url);
	}

	/**
	 * Return the base url from the data source url
	 */
	private static getBaseUrl(dataSourceUrl: string) {
		const urlArray = dataSourceUrl.split('/');
		return urlArray[0] + '//' + urlArray[2];
	}

	private static async getDefaultTokenUrl(url: string): Promise<string> {
		const baseUrl = this.getBaseUrl(url);
		const response = await ConnectionClient.current.getJson(baseUrl);
		if (response.success) {
			const serverInfo: any = response.responseJsonToken;

			if (serverInfo.authInfo) {
				const tokenUrl = serverInfo.authInfo.tokenServicesUrl;
				return TokenManager.tokenUrl(tokenUrl);
			}

			return baseUrl + '/arcgis/tokens/generateToken';
		}

		return baseUrl + '/arcgis/tokens/generateToken';
	}

	private static async invokeCredentialsHandlerAsync(url: string, expiredCredentials: PortalCredentials): Promise<PortalCredentials> {
		return await TokenManager._renewTokenLock.use(() => {
			return new Promise<PortalCredentials>(async (resolve, reject) => {
				if (!expiredCredentials.isExpired) {
					return expiredCredentials;
				}

				const credentials = await TokenManager.portalAuthenticationHandler(url);
				return credentials;
			});
		});
	}

	private constructor() {}

	public async authenticateCredentials(serviceUrl: string, credentials: Credentials): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			if (!credentials) {
				resolve(false);
				return;
			}

			let query = '';
			let tokenUrl = '';
			if (credentials.type === CredentialsType.Basic) {
				if (ArcGISOptions.useIPAuthentication) {
					query = `username=${credentials.username}&password=${credentials.password}&expiration=${ArcGISOptions.defaultTokenMinuteDuration}&client=requestip`;
				} else {
					query = `username=${credentials.username}&password=${credentials.password}&expiration=${ArcGISOptions.defaultTokenMinuteDuration}&client=referer&referer=${ArcGISOptions.referer}`;
				}

				tokenUrl = await TokenManager.getTokenUrl(serviceUrl);
			} else if (credentials.type === CredentialsType.ArcGISOnline) {
				query = `client_id=${credentials.username}&client_secret=${credentials.password}&expiration=${ArcGISOptions.defaultTokenMinuteDuration}&grant_type=client_credentials`;
				tokenUrl = this.getArcGISOnlineUrl();
			}

			const result = await ConnectionClient.current.postData(tokenUrl, query);

			if (result.success) {
				credentials.refreshCredentials(result.responseJsonToken);
				credentials.isAuthenticated = true;
				resolve(true);
				return;
			}

			resolve(false);
		});
	}

	private getArcGISOnlineUrl(): string {
		return 'https://www.arcgis.com/sharing/rest/oauth2/token';
	}
}
