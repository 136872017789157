import { GlobalConfig } from './global-config.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';

export class ListItem extends SedaruConfigBase {
    constructor(globalConfig: GlobalConfig, init?: Partial<ListItem>) {
        super(globalConfig);
        Object.assign(this, init);
    }

    text: string;
    value: string;
}
