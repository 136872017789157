import { Component, ElementRef } from '@angular/core';
import { TrendStyle, Metric } from '../../../../../../models';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';

/**
 * We will get rid of this component after trend setting component is complete so that we can reuse that component.
 * Although this component is repeated, but we use it to show for now.
 */
@Component({
	selector: 'app-default-trend-style',
	templateUrl: './default-trend-style.component.html',
	styleUrls: ['./default-trend-style.component.scss']
})
export class DefaultTrendStyleComponent extends MenuPanelBaseComponent {
	trendStyles = TrendStyle;

	metric: Metric;

	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'default trend style' });
	}

	onTrendStyleSelect(selectedStyle: TrendStyle) {
		this.metric.definition.defaultTrendSettings.defaultTrendStyle = selectedStyle;
		NavigationService.navigateBackTo(Pages.defaulttrendsettings, { metric: this.metric });
	}
}
