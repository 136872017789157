import { ContractBase } from '../contract-base';

export class WorkAssetContract extends ContractBase {
	public ObjectId: number;
	public AssetKey: string;
	public AssetType: string;
	public WorkOrderKey: string;
	public IsCompleted: number;
	public WorkInspectionkey: string;
	public SystemId: string;
	public AssetOID: number;
}
