import { ActivatedRoute } from '@angular/router';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from '../menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Canvas } from 'omni-model/canvas.model';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { ThreeComponentNavsComponent } from 'app/ui-components/three-component-navs/three-component-navs.component';
/**
 * This component shows a list of base maps that can be selected for the currently selected map.
 */
@Component({
	selector: 'app-map-layers',
	templateUrl: './map-layers.component.html',
	styleUrls: ['./map-layers.component.scss']
})
export class MapLayersComponent extends MenuPanelBaseComponent implements OnInit {
	@ViewChild(ThreeComponentNavsComponent) childComponents: ThreeComponentNavsComponent;
	canvas: Canvas;
	/**
	 * The constructor just loads dependencies.
	 * @param navigationService - Navigation service is used in conjuction with the activated route to enable the back button.
	 * @param route - The route is used in conjuction with the navigation service to enable the back button.
	 */
	constructor(private mapService: CanvasMapService, private canvasService: CanvasService, private view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageReload(args: NavigationArgs) {
		this.canvas = args.parameter.canvas;
		this.childComponents.navigateToTab(1);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.canvas = args.parameter.canvas;
	}

	/**
	 * Loads ESRI base maps and sets up the back button navigation.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'map layers' });
	}

	baseMapChangeHandler(baseMapId) {
		if (baseMapId || baseMapId === '') {
			this.config.selectedCanvas.map.baseMapId = baseMapId;
			this.canvasService.saveOmniCanvas(this.config, this.config.selectedCanvas).subscribe(() => {
				// @ts-ignore
				this.canvas.mapView.map.basemap = baseMapId;
			});
		} else {
			this.canvas.mapView.map.basemap = null;
		}
	}

	visiblityLayerHandler() {
		const canvas = this.config.selectedCanvas;
		this.canvasService.saveOmniCanvas(this.config, canvas).subscribe(() => {
			console.log(`visibile layer saved for canvas in position ${canvas.position}.`);
		});
	}

	visiblitySubLayerHandler() {
		const canvas = this.config.selectedCanvas;
		this.canvasService.saveOmniCanvas(this.config, canvas).subscribe(() => {
			console.log(`visibile layer saved for canvas in position ${canvas.position}.`);
		});
	}
}
