import {Observable} from '@apollo/client/core';
import { OmniInteropService } from './../../domain-service/omni-interop.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { fadeInAndOutOnHover, newTabAnimation } from 'app/app.animations';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { GuiConfig } from '../../omni-model/gui-config.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserService } from 'app/user/user.service';

/**
 * This component is designed to be placed on the left end of the OMNI app.
 * It displays as a list all the tabs available. It also provides user with the ability to add new tabs.
 */
@Component({
	selector: 'app-tab-panel',
	templateUrl: './tab-panel.component.html',
	styleUrls: ['./tab-panel.component.scss'],
	animations: [fadeInAndOutOnHover, newTabAnimation]
})
export class TabPanelComponent implements OnInit {
	@ViewChild('tabListWrapper', { static: true }) tabListWrapper: ElementRef;
	/**
	 * An array of all the tabs available.
	 */
	@Input() availableConfigs: Array<GuiConfig>;
	/** when the user clicks the + button to add a new tab */
	@Input() onNewTabRequested: () => Observable<GuiConfig>;
	/** when a tab gets clicked or tapped */
	@Output() tabSelected = new EventEmitter<GuiConfig>();

	@Output() toggleTabPanel = new EventEmitter();
	/**
	 * The current year as a 4 digit number.
	 */
	year = new Date().getFullYear();

	/**
	 * waiting for the last tab order saving response to comeback
	 */
	isWaiting = false;

	/**
	 * Keeps the state of the loading spinner when adding a new tab. If true, a loading spinner will show up in the UI.
	 */
	addingNewTab = false;

	/**
	 * Allows the Router, GuiConfigService, ScrollToViewService and TabpanelToggleService dependencies to be injected into
	 * 	the class
	 * @param {ScrollToViewService} scrollingService - This registers the view which is to be scrolled in when a user
	 * hits something, say a tile, on a mobile device.
	 * @param {TabpanelToggleService} tabToggle - provides methods to handle the toggling of the tab panel
	 */
	constructor(private scrollingService: ScrollToViewService, private interOpService: OmniInteropService, private userService: UserService) {}

	/**
	 * Defines the handler for the event emmitted when the GUI config is changed.
	 */
	ngOnInit() {}

	/**
	 * This method is called when the user hits the '+' in the GUI for adding a new tab.
	 */
	onNewTabButtonClick() {
		this.addingNewTab = true;

		this.onNewTabRequested().subscribe((newConfig: GuiConfig) => {
			this.tabSelected.emit(newConfig);
			this.addingNewTab = false;

			/**
			 * Scroll to menu panel after tab panel collapses
			 */
			setTimeout(() => {
				this.scrollingService.menuPanel.nativeElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}, 800);

			/**
			 * Navigate to tab settings after scrolling happens
			 */

			setTimeout(() => {
				NavigationService.navigateTo(Pages.tabSettings);
			}, 1200);
		});

		this.toggleTabPanel.emit();
	}

	/**
	 * This method is invoked when the gear icon within a tab is clicked to open the tab settings panel
	 * @param clickEvent - event fired when the gear icon on the tab is clicked
	 * @param {number} tabId - Currently selected tab
	 */
	goToTabSettings(clickEvent, tabId) {
		clickEvent.stopPropagation();
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		this.toggleTabPanel.emit();
		NavigationService.navigateTo(Pages.tabSettings);
	}

	/**
	 * This method is invoked when a tab is clicked and this will load up the GUI with the configuration of the selected tab
	 * @param {GuiConfig} newlySelectedTab - The currently selected tab.
	 */
	onTabSelected(newlySelectedTab: GuiConfig) {
		if (newlySelectedTab.isSelected || !newlySelectedTab.isEnabled) return; // does not allow to click a tab that is already selected
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		this.toggleTabPanel.emit();
		this.tabSelected.emit(newlySelectedTab);
	}

	async drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.availableConfigs, event.previousIndex, event.currentIndex);
		try {
			this.isWaiting = true;
			await this.interOpService.omniDomain.guiConfigService.updateTabOrder().toPromise();
		} finally {
			this.isWaiting = false;
		}
	}
}
