import { SpatialReference } from './spatial-reference';

export class FindOperation {
	contains = true;
	returnGeometry = true;
	returnFieldName = true;
	searchText: string;
	spatialReference: SpatialReference;
	private _layerIds: Array<number>;
	get layerIds(): Array<number> {
		if (!this._layerIds) this._layerIds = new Array<number>();

		return this._layerIds;
	}

	private _searchFields: Array<string>;
	get searchFields(): Array<string> {
		if (!this._searchFields) this._searchFields = new Array<string>();

		return this._searchFields;
	}

	get isValid() {
		if (!this.searchText) {
			return false;
		}

		if (this.layerIds.length === 0) {
			return false;
		}

		return true;
	}

	static getFindQueryString(operation: FindOperation): string {
		let query = `searchText=${operation.searchText}`;
		query += `&contains=${operation.contains}`;
		query += `&layers=${operation.layerIds.join(',')}`;
		query += `&returnGeometry=${operation.returnGeometry}`;
		query += `&searchFields=${operation.searchFields.join(',')}`;
		query += `&returnFieldName=${operation.returnFieldName}`;

		if (operation.spatialReference) query += `&sr=${operation.spatialReference.wkid}`;

		return query;
	}
}
