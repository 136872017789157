<div class="hierarchy-panel">
	<p *ngIf="instructionText && !loading && !hierarchyNode" class="omni-md-font center-text">{{ instructionText }}</p>
	<input #imageInput type="file" accept="image/*" (change)="fileChange($event)" style="display: none;" />
	<app-hierarchy-list
		class="hierarchy-list"
		*ngIf="hierarchyNode && !loading"
		[currentNode]="hierarchyNode"
		[ancestors]="ancestors"
		[children]="children"
		(leftIconClicked)="handleLeftIconClick($event)"
		(rightIconClicked)="handleRightIconClick($event)"
		(itemClicked)="handleItemClick($event)">
	</app-hierarchy-list>
</div>
