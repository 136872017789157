import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { CustomFields } from './custom-fields.model';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { CustomFieldContract } from '../../contracts/work-order/index';

export class Task extends OmniModel<Task, Contracts.TaskContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _taskId: SedaruUtils.Undoable<string>;
	get taskId(): string {
		if (!this._taskId) this._taskId = new SedaruUtils.Undoable<string>();
		return this._taskId.value;
	}
	set taskId(value: string) {
		if (!this._taskId) {
			this._taskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._taskId.value = value;
	}

	private _name: SedaruUtils.Undoable<string>;
	get name(): string {
		if (!this._name) this._name = new SedaruUtils.Undoable<string>();
		return this._name.value;
	}
	set name(value: string) {
		if (!this._name) {
			this._name = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._name.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _woTemplateId: SedaruUtils.Undoable<string>;
	get woTemplateId(): string {
		if (!this._woTemplateId) this._woTemplateId = new SedaruUtils.Undoable<string>();
		return this._woTemplateId.value;
	}
	set woTemplateId(value: string) {
		if (!this._woTemplateId) {
			this._woTemplateId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._woTemplateId.value = value;
	}

	private _assignedTo: SedaruUtils.Undoable<string>;
	get assignedTo(): string {
		if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
		return this._assignedTo.value;
	}
	set assignedTo(value: string) {
		if (!this._assignedTo) {
			this._assignedTo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assignedTo.value = value;
	}

	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

	private _customFieldGroup: SedaruUtils.Undoable<string>;
	get customFieldGroup(): string {
		if (!this._customFieldGroup) this._customFieldGroup = new SedaruUtils.Undoable<string>();
		return this._customFieldGroup.value;
	}
	set customFieldGroup(value: string) {
		if (!this._customFieldGroup) {
			this._customFieldGroup = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldGroup.value = value;
	}

	private _taskCustomFields: SedaruUtils.Undoable<CustomFieldWrappers>;
	get taskCustomFields(): CustomFieldWrappers {
		if (!this._taskCustomFields) this._taskCustomFields = new SedaruUtils.Undoable<CustomFieldWrappers>(new CustomFieldWrappers());
		return this._taskCustomFields.value;
	}
	set taskCustomFields(value: CustomFieldWrappers) {
		if (!this._taskCustomFields) {
			this._taskCustomFields = new SedaruUtils.Undoable<CustomFieldWrappers>(value);
			return;
		}
		this._taskCustomFields.value = value;
	}

	static fromContract(contract: Contracts.TaskContract): Task {
		const model = new Task();
		model.id = contract.ObjectId.toString();
		model.taskId = contract.TaskId;
		model.name = contract.Name;
		model.description = contract.Description;
		model.woTemplateId = contract.WOTemplateId;
		model.assignedTo = contract.AssignedTo;
		model.comments = contract.Comments;
		model.customFieldGroup = contract['customFieldGroup'];
		if (contract.TaskCustomFields) model.taskCustomFields = CustomFieldWrappers.fromContracts(contract.TaskCustomFields);
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.TaskContract {
		const contract = new Contracts.TaskContract();
		contract.ObjectId = +this.id;
		contract.TaskId = this.taskId;
		contract.Name = this.name;
		contract.Description = this.description;
		contract.WOTemplateId = this.woTemplateId;
		contract.AssignedTo = this.assignedTo;
		contract.Comments = this.comments;
		contract.CustomFieldGroup = this.customFieldGroup;
		contract.TaskCustomFields = this.taskCustomFields.getContracts() as CustomFieldContract[];
		return contract;
	}

	onUpdateInformation(copyModel: Task): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._taskId, () => {
				this.taskId = copyModel.taskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._name, () => {
				this.name = copyModel.name;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._woTemplateId, () => {
				this.woTemplateId = copyModel.woTemplateId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assignedTo, () => {
				this.assignedTo = copyModel.assignedTo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._comments, () => {
				this.comments = copyModel.comments;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldGroup, () => {
				this.customFieldGroup = copyModel.customFieldGroup;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<CustomFieldWrappers>(this._taskCustomFields, () => {
				this.taskCustomFields = copyModel.taskCustomFields;
			}) || wasChanged;
		return wasChanged;
	}
}
