export class Semaphore {
	private _runningCount = 0;
	private tasks: (() => void)[] = [];
	public get count(): number {
		return this._count;
	}
	get queueCount(): number {
		return this.tasks.length + this._runningCount;
	}
	constructor(private _count: number) {}

	private sched() {
		if (this.count > 0 && this.tasks.length > 0) {
			this._count--;
			const next = this.tasks.shift();
			if (next === undefined) {
				throw new Error('Unexpected undefined value in tasks list');
			}

			next();
		}
	}

	public acquire() {
		return new Promise<() => void>((res, rej) => {
			const task = () => {
				let released = false;
				res(() => {
					if (!released) {
						released = true;
						this._count++;
						this.sched();
					}
				});
			};
			this.tasks.push(task);

			if (process && process.nextTick) {
				process.nextTick(this.sched.bind(this));
			} else {
				setImmediate(this.sched.bind(this));
			}
		});
	}

	public use<T>(f: () => Promise<T>) {
		return this.acquire().then(release => {
			this._runningCount++;
			return f()
				.then(res => {
					this._runningCount--;
					release();
					return res;
				})
				.catch(err => {
					release();
					throw err;
				});
		});
	}
}

export class Mutex extends Semaphore {
	constructor() {
		super(1);
	}
}
