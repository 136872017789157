import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { MetricService } from './metric.service';
import { UserService } from 'app/user/user.service';
import { RootData } from '../omni-model/root-data.model';
import { Observable, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { DataSourceService } from './data-source.service';
import { EsriSdkService } from '../app/canvas-container/canvas-map/esri-sdk.service';
import { User } from '../app/user/user.model';
import { DataChannelService } from './data-channel.service';
import { Metric, Channel, DataSource, MetricTypes, ChannelTypes, ArcGISAssetChannelAttributes, ArcGISHistoryChannelAttributes } from '../models';
import { CanvasMapService } from '../app/canvas-container/canvas-map/canvas-map.service';
import { GuiConfigService } from './gui-config.service';
import { GuiConfigServerData } from '../omni-model/gui-config-server-data.model';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import { CustomQuery } from 'models/custom-query.model';

@Injectable({
	providedIn: 'root'
})
export class RootDataService {
	constructor(
		private apollo: Apollo,
		private esriSdkService: EsriSdkService,
		private canvasMapService: CanvasMapService,
		private dataSourceService: DataSourceService,
		private dataChannelService: DataChannelService,
		private metricService: MetricService,
		private guiConfigService: GuiConfigService
	) {}

	initialize(customerCode: string, userName: string, profileGroupName: string): Promise<boolean> {
		return this.getRootData(customerCode, userName, profileGroupName);
	}

	/**
	 * This function gets the root data to load the app
	 * @param {string} customerCode - The customer code
	 */
	private getRootData(customerCode: string, userName: string, profileGroupName: string): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			let rootData: RootData;
			await this.apollo
				.query({
					query: this.getRootDataGqlQuery(customerCode, userName, profileGroupName)
				})
				.pipe(
					map(async (result: ApolloQueryResult<{ getRootData: RootData }>) => {
						const clone = require('clone');
						rootData = clone(result.data?.getRootData);
					}),
					catchError(err => {
						resolve(false);
						window.alert(customerCode + ' data has not been imported');
						AnalyticsHub.current.trackError(err);
						return throwError(err);
					})
				)
				.toPromise();

			if (!rootData) {
				console.log('Get Root Data failed.');
				resolve(false);
				return;
			}

			this.assignDataSourceToDataSourceService(rootData.dataSourceList);

			this.assignDataSourceReferenceToChannel(rootData);

			await this.assignChannelReferenceToMetric(rootData);

			await this.assignGuiConfigToGuiConfigService(customerCode, rootData.guiConfigServerDataList);

			console.log('here is root data', rootData);

			resolve(true);
		});
	}

	private async assignGuiConfigToGuiConfigService(customerCode: string, guiConfigServerDataList: GuiConfigServerData[]) {
		await this.guiConfigService.loadConfigurations(customerCode, guiConfigServerDataList);
	}

	private async assignChannelReferenceToMetric(rootData: RootData) {
		return new Promise<boolean>(async (resolve, reject) => {
			const { availableDataChannels } = this.dataChannelService;
			const assetChannels = Object.values(availableDataChannels).filter(c => c.channelType === ChannelTypes.Asset);
			const historyChannels = Object.values(availableDataChannels).filter(c => c.channelType === ChannelTypes.History);
			const workOrderChannel = Object.values(availableDataChannels).find(c => c.channelType === ChannelTypes.WorkOrder);

			console.warn('*** ASSET CHANNELS: *** ');
			assetChannels.forEach(ch => console.log(ch.attributes['assetType']));

			console.warn('*** History channels: ***');
			historyChannels.forEach(ch => console.log(ch.attributes['assetType']));

			for (const metric of rootData.metricList) {
				if (metric.definition.type === MetricTypes.asset) {
					const c = assetChannels.find(i => (i.attributes as ArcGISAssetChannelAttributes).assetType.toLowerCase() === metric.definition.source.assetType.toLowerCase());
					if (!c) console.error(`Could not find asset channel for asset type ${metric.definition.source.assetType}`);
					metric.definition.assetChannel = c;
					if (c) metric.definition.assetChannelId = c.id;
				}

				if (metric.definition.type === MetricTypes.history) {
					const c = historyChannels.find(i => (i.attributes as ArcGISHistoryChannelAttributes).assetType.toLowerCase() === metric.definition.source.assetType.toLowerCase());
					if (!c) console.error(`Could not find history channel for asset type ${metric.definition.source.assetType}`);
					metric.definition.historyChannel = c;
					if (c) metric.definition.historyChannelId = c.id;

					const ac = assetChannels.find(i => (i.attributes as ArcGISAssetChannelAttributes).assetType.toLowerCase() === metric.definition.source.assetType.toLowerCase());
					if (!ac) console.error(`Could not find asset channel for asset type ${metric.definition.source.assetType}`);
					metric.definition.assetChannel = ac;

					if (ac) metric.definition.assetChannelId = ac.id;
				}

				if (metric.definition.type === MetricTypes.awo || metric.definition.type === MetricTypes.swo) {
					metric.definition.workOrderChannel = workOrderChannel;
					metric.definition.workOrderChannelId = workOrderChannel.id;
				}

				// save metric to metric service
				const newMetric = new Metric();
				try {
					newMetric.initialize(metric);
					await this.metricService.setIcon(newMetric);
					const metricIndexExists = this.metricService.availableMetrics.findIndex(existingMetric => existingMetric.id === metric.id);
					if (metricIndexExists < 0) this.metricService.availableMetrics.push(newMetric);
					else this.metricService.availableMetrics[metricIndexExists] = newMetric;
				} catch (error) {
					console.error('skipping this metric due to error in initialization', metric, error);
				}
			}
			this.metricService.availableMetrics.sort((a: Metric, b: Metric) => (new Date(a.definition.createdDate) < new Date(b.definition.createdDate) ? 1 : -1));
			resolve(true);
		});
	}

	private assignDataSourceReferenceToChannel(rootData: RootData) {
		const { availableDataSources } = this.dataSourceService;
		for (const channel of rootData.channelList) {
			channel.dataSource = Object.values(availableDataSources).find(dataSource => dataSource.id === channel.dataSourceId);

			// save channel to dataChannel service
			this.dataChannelService.availableDataChannels[channel.id] = new Channel(undefined);
			this.dataChannelService.availableDataChannels[channel.id].initialize(channel);
		}
	}

	private assignDataSourceToDataSourceService(dataSourceList: DataSource[]) {
		for (const dataSource of dataSourceList) {
			// save data source to dataSource service
			this.dataSourceService.availableDataSources[dataSource.id] = new DataSource(undefined);
			this.dataSourceService.availableDataSources[dataSource.id].initialize(dataSource);
			if (this.dataSourceService.checkIsMapServer(dataSource)) {
				this.canvasMapService.availableDataSources[dataSource.name] = dataSource;
			}
		}
	}

	/**
	 * This function returns a graphQL query that gets the root data
	 * @param {string} customerCode - The customer code
	 * @returns {any} - returns a graphQL query
	 */
	private getRootDataGqlQuery(customerCode: string, userName: string, profileGroupName: string): any {
		const referer = window.location.origin;
		const getRootDataGqlQuery = gql`{
			getRootData(customerCode: "${customerCode}",userName:"${userName}", profileGroupName:"${profileGroupName}", referer:"${referer}") {
				dataSourceList{
					id
					legacyId
					credentialsId
					credentialsLegacyId
					credentials{
						token
						expires
						type
					}
					proxyId
					proxyLegacyId
					proxy{
						url
						credentialsId
						credentialsLegacyId
						credentials{
							token
							expires
							type
						}
					}
					name
					url
					type
					category
				}
				channelList{
					id
					name
					dataSourceId
					dataSourceLegacyId
					channelType
					attributes{
						assetType

						# For asset channel attribtues
						uniqueFieldName
						featureServiceLayerIndex
						mapServiceLayerIndex

						# For history channel attributes
						completedDateFieldName
						workTypeFieldName
						assignedToFieldName
						assetIdFieldName
						layerIndex

						# For work order channel attributes
						workOrderMode
						workOrderSystem
						activeWorkOrderValues
						completedWorkOrderValues
						hourlyRates{
							factor
							name
							type
						}

						# For ArcGIS work order channel attributes
						materialCostLayerIndex
						completedWorkTaskValue
						employeeLayerIndex
						workTaskLayerIndex
						equipmentLayerIndex
						workOrderLayerIndex
						materialLayerIndex
						employeeCostLayerIndex
						workAssetLayerIndex
						equipmentCostLayerIndex

						# For Sedaru work order channel attributes
						customerCode
						canCloseWorkOrders
						skipRealtimeValidation
						lem{
							mode
						}
					}
				}
				metricList{
					id
					exportUrl
					profileGroup
					definition{
						name
						source{
							assetType
							type
							workOrderSourceType
						}
						icon{
							name
							backgroundColor
							foregroundColor
							pathList{
								path
								isForeground
							}
							base64
							url
						}
						type
						query{
							mode
							mapLayerQueryString
							relatedTableQueryString
							workOrderQueryString
							queryStatementList{
								index
								source{
									name
									value
								}
								queryFieldList{
									index
									field{
										name
										alias
										type
										omniName
									}
									operator{
										name
										value
									}
									value{
										name
										code
									}
									join{
										name
										value
									}
								}
								join{
									name
									value
								}
							}
							workOrderFilter{
								status
								assignedto
								teamid
								supervisor
								priority
								worktypeid
								customFields {
									index
									name
									customFieldId
									conditions{
										index
										operator{
											name
											value
										}
										value{
											name
											code
										}
										join{
											name
											value
										}
										type
									}
								}
							}
							taskFilter{
								status
								assignedto
								teamid
								taskkey
								customFields {
									index
									name
									customFieldId
									conditions{
										index
										operator{
											name
											value
										}
										value{
											name
											code
										}
										join{
											name
											value
										}
										type
									}
								}
							}
						}
						timeFrameSettings{
							field{
								name
								alias
								type
								omniName
							}
							operator{
								name
								value
							}
							value{
								name
								code
							}
						}
						timeFrameFilter {
							timeframeField
							timeFrameDefinition {
								type
								unit
								interval
								isInclusive
								date
								boundlessType
							}
						}
						displayValueSettings{
							typeOfValue{
								name
								enumValue
							}
							valueTypeField{
								name
								type
								alias
								omniName
							}
							valueFormat{
								name
								enumValue
							}
						}
						menuPanelSettings{
							defaultSortField{
								name
								type
								alias
								omniName
							}
							defaultSortOrder
							displayField{
								name
								type
								alias
								omniName
							}
						}
						defaultTrendSettings{
							defaultTrendStyle{
								identifier
								label
							}
							xAxisField{
								name
								type
								alias
								omniName
							}
							yAxisField{
								name
								type
								alias
								omniName
							}
						}
						refreshFrequency
						assetChannelId
						historyChannelId
						workOrderChannelId
						createdDate
					}
				}
				guiConfigServerDataList{
					id
					name
					content
					lastPathVisited
					isSelected
					canvasLayout
					tilesLayout
					scope
					teamId
					teamName
					teamOwner
					teamMembers
					createdBy
					profileGroupName
					theme{
						id
						backgroundURL
						displayName
						backgroundColor
					}
					canvasList{
						id
						position
						mode
						tabs{
							active
							tileId
							defaultMetricId
							locked
						}
						map{
							id
							name
							baseMapId
							initialExtent
							initialZoom
							subLayers
							baseLayers
						}
						trend{
							style {
								identifier
								label
								iconURL
							}
							minDate
							maxDate
						}
					}
					tileList{
						id
						tilePosition
						metricId
						foregroundColor
						backgroundColor
						timeFrameFilter {
							timeframeField
							timeFrameDefinition {
								type
								unit
								interval
								isInclusive
								date
								boundlessType
							}
						}
					}
				}
				assetDefinitionList{
					id
					legacyId
					assetType
					title
					enabled
					style
					historyChannelId
					assetChannelId
					searchableFieldNames
					informationForm{
						legacyId
						workType
						title
						pages{
							legacyId
							index
							footer{
								legacyId
								index
								defaultFieldName
								controlType
								readOnly
								required
								skipOnEdit
								title
								description
								input{
									type
									allowDecimals
									list{
										dataSourceId
										layerIndex
										fieldName
										listItems{
											text
											value
										}
									}
								}
								value{
									text
									textFormatId
									valueFormatId
								}
							}
							controlGroups{
								legacyId
								index
								title
								controls{
									legacyId
									index
									defaultFieldName
									controlType
									readOnly
									required
									skipOnEdit
									title
									description
									input{
										type
										allowDecimals
										list{
											dataSourceId
											layerIndex
											fieldName
											listItems{
												text
												value
											}
										}
									}
									value{
										text
										textFormatId
										valueFormatId
									}
								}
							}
						}
					}
					forms{
						legacyId
						workType
						title
						pages{
							legacyId
							index
							footer{
								legacyId
								index
								defaultFieldName
								controlType
								readOnly
								required
								skipOnEdit
								title
								description
								input{
									type
									allowDecimals
									list{
										dataSourceId
										layerIndex
										fieldName
										listItems{
											text
											value
										}
									}
								}
								value{
									text
									textFormatId
									valueFormatId
								}
							}
							controlGroups{
								legacyId
								index
								title
								controls{
									legacyId
									index
									defaultFieldName
									controlType
									readOnly
									required
									skipOnEdit
									title
									description
									input{
										type
										allowDecimals
										list{
											dataSourceId
											layerIndex
											fieldName
											listItems{
												text
												value
											}
										}
									}
									value{
										text
										textFormatId
										valueFormatId
									}
								}
							}
						}
					}
				}
			}
		}`;
		return getRootDataGqlQuery;
	}
}
