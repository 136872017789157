import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { Component, Input, OnChanges, SimpleChange, OnInit,  Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BaseMapTypes } from 'app/canvas-container/canvas-map/base-map-types.model';
import { Canvas } from 'omni-model/canvas.model';

/**
 * This component shows a list of base maps that can be selected for the currently selected map.
 */
@Component({
	selector: 'app-base-map',
	templateUrl: './base-map.component.html',
	styleUrls: ['./base-map.component.scss']
})
export class BaseMapComponent implements OnInit, OnChanges {
	@Input() selectedCanvas: Canvas;

	@Output() updateBasemap  = new EventEmitter();
	@ViewChild('scrollingContainer', { static: true }) scrollingContainer: ElementRef;
	/**
	 * ESRI basemap types list which will be used for ESRI SDK for selected map container
	 */
	baseMapList: BaseMapTypes[];

	selectedBaseMap: string;
	/**
	 * The constructor just loads dependencies.
	 * @param mapService - The map service is used to actually modify the map view with the new base map selection.
	 */
	constructor(private mapService: CanvasMapService) {}

	/**
	 * Loads ESRI base maps and sets up the back button navigation.
	 */
	ngOnInit() {
		this.getBaseMapList();
		this.selectedBaseMap = this.selectedCanvas.map.baseMapId;
		this.scrollingContainer.nativeElement.scrollTo(0, 0);
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if (changes.selectedCanvas) {
			this.ngOnInit();
		}
	}

	/**
	 * Updates the map with the selected basemap.
	 * @param baseMapId - Basemap ID that has been selected by the user
	 */
	updateMap(baseMapId: string) {
		this.selectedBaseMap = baseMapId;
		this.updateBasemap.emit(baseMapId);
	}

	/**
	 * List of ESRI base map types which were used for OMNI
	 */
	private getBaseMapList() {
		this.baseMapList = this.mapService.baseMapESRIList();
	}
}
