import { WorkorderCommentComponent } from './../workorder/workorder-comment-list/workorder-comment/workorder-comment.component';
import { WorkorderCommentListComponent } from './../workorder/workorder-comment-list/workorder-comment-list.component';
import { INavigation, Pages } from './inavigation';
import { TileSettingsComponent } from 'app/menu-panel/tile-settings/tile-settings.component';
import { TabContentPanelComponent } from 'app/menu-panel/tab-settings/tab-content-panel/tab-content-panel.component';
import { TabVisibilityComponent } from 'app/menu-panel/tab-settings/tab-visibility/tab-visibility.component';
import { ThemeComponent } from 'app/menu-panel/tab-settings/theme/theme.component';
import { LayoutConfigurationComponent } from 'app/menu-panel/tab-settings/layout-configuration/layout-configuration.component';
import { ListMetricComponent } from 'app/metric/list-metric/list-metric.component';
import { TabSettingsMainMenuComponent } from 'app/menu-panel/tab-settings/tab-settings-main-menu/tab-settings-main-menu.component';
import { MapSettingsComponent } from 'app/menu-panel/map-settings/map-settings.component';
import { MapListComponent } from 'app/menu-panel/map-list/map-list.component';
import { TrendSettingsComponent } from 'app/menu-panel/trend-settings/trend-settings.component';
import { TrendStyleComponent } from 'app/menu-panel/trend-settings/trend-style/trend-style.component';
import { MapLayersComponent } from 'app/menu-panel/map-layers/map-layers.component';
import { MapAssetListComponent } from 'app/menu-panel/map-assets/map-asset-list/map-asset-list.component';
import { MapAssetAttributesComponent } from 'app/menu-panel/map-assets/map-asset-attributes/map-asset-attributes.component';
import { EditMetricComponent } from 'app/metric/list-metric/edit-metric/edit-metric.component';
import { MetricSourceTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/types/metric-source-types.component';
import { QueryBuilderComponent } from 'app/metric/list-metric/edit-metric/metric-query/query-builder/query-builder.component';
import { DisplayValueSettingsComponent } from 'app/metric/list-metric/edit-metric/display-value-settings/display-value-settings.component';
import { MenuPanelSettingsComponent } from 'app/metric/list-metric/edit-metric/menu-panel-settings/menu-panel-settings.component';
import { DefaultTrendSettingsComponent } from 'app/metric/list-metric/edit-metric/default-trend-settings/default-trend-settings.component';
import { AssetTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/asset-types/metric-source-asset-types.component';
import { MetricIconListComponent } from 'app/metric/list-metric/edit-metric/metric-icon/metric-icon-list.component';
import { OmniValueListComponent } from 'app/omni-value-list/omni-value-list.component';
import { DefaultTrendStyleComponent } from 'app/metric/list-metric/edit-metric/default-trend-settings/default-trend-style/default-trend-style.component';
import { QueryValidatorComponent } from 'app/metric/list-metric/edit-metric/metric-query/query-validator/query-validator.component';
import { WorkOrderOutlineComponent } from 'app/workorder/workorder-outline/workorder-outline.component';
import { WorkOrderSummaryComponent } from 'app/workorder/workorder-summary/workorder-summary.component';
import { WorkOrderSourceTypesComponent } from 'app/metric/list-metric/edit-metric/metric-source/work-order-source-types/work-order-source-types.component';
import { WorkOrderFilterComponent } from 'app/workorder/workorder-filter/workorder-filter.component';
import { WorkOrderFilterSelectionComponent } from 'app/workorder/workorder-filter/workorder-filter-selection/workorder-filter-selection.component';
import { WorkOrderFilterDatePickerComponent } from 'app/workorder/workorder-filter/workorder-filter-date-picker/workorder-filter-date-picker.component';
import { SearchResultsComponent } from 'app/search/search-results/search-results.component';
import { WorkOrderAttachmentsComponent } from 'app/workorder/workorder-attachments/workorder-attachments.component';
import { UITestComponent } from 'app/ui-components/ui-components-test-panel';
import { AdvancedLaborComponent } from 'app/workorder/advanced-labor/advanced-labor.component';
import { WorkorderRelatedWorkordersComponent } from 'app/workorder/workorder-related-workorders/workorder-related-workorders.component';
import { WorkorderTaskListComponent } from 'app/workorder/workorder-task-list/workorder-task-list.component';
import { WorkorderTaskComponent } from 'app/workorder/workorder-task/workorder-task.component';
import { WorkorderAssetListComponent } from 'app/workorder/workorder-asset-list/workorder-asset-list.component';
import { WorkorderNewTaskComponent } from 'app/workorder/workorder-new-task/workorder-new-task.component';
import { WorkorderLEMListComponent } from 'app/workorder/workorder-lem-list/workorder-lem-list.component';
import { WorkOrderFieldComponent } from 'app/workorder/work-order-field/workorder-field.component';
import { WorkOrderNewLEMMenuPanelComponent } from './../workorder/workorder-new-lem-menu-panel/workorder-new-lem-menu-panel.component';
import { AssetInformationComponent } from 'app/menu-panel/map-assets/asset-information/asset-information.component';
import { HistoryRecordsFormComponent } from 'app/dynamic-forms/history-records-form/history-records-form.component';
import { WorkOrderLEMCostViewComponent } from 'app/workorder/workorder-lem-cost-view/workorder-lem-cost-view.component';
import { TeamViewComponent } from 'app/team-viewer/team-view/team-view.component';
import { WorkorderFailureReportMenuPanelComponent } from 'app/workorder/workorder-failure-report-menu-panel/workorder-failure-report-menu-panel.component';
import { TimeFrameSettingsComponent } from 'app/metric/list-metric/edit-metric/time-frame-settings/time-frame-settings.component';
import { WorkOrderFilterCustomFieldComponent } from 'app/workorder/workorder-filter/workorder-filter-custom-field/workorder-filter-custom-field.component';
import { BulkEditErrorsListComponent } from 'app/workorder/bulk-edit-errors-list/bulk-edit-errors-list.component';
import { MetricResultsComponent } from 'app/metric/metric-results/metric-results.component';
import { HierarchyMenuPanelComponent } from 'app/workorder/hierarchy-menu-panel/hierarchy-menu-panel.component';
import { HierarchyWorkorderListComponent } from 'app/workorder/hierarchy-workorder-list/hierarchy-workorder-list.component';
import { TimeframeFilterComponent } from 'app/menu-panel/timeframe/timeframe-filter/timeframe-filter.component';
import { TileTimeframeFormComponent } from 'app/menu-panel/tile-settings/tile-timeframe-form/tile-timeframe-form.component';
import { TableSettingsComponent } from 'app/menu-panel/table-settings/table-settings.component';
import { SingleAssetTypeListComponent } from 'app/menu-panel/map-assets/map-asset-list/single-asset-type-list/single-asset-type-list.component';
import { DateFieldSelectionComponent } from 'app/menu-panel/timeframe/date-field-selection/date-field-selection.component';
import { WorkOrderRecurrenceComponent } from 'app/workorder/workorder-recurrence/workorder-recurrence.component';
import { RecurrenceFieldSelectionComponent } from 'app/workorder/workorder-recurrence/recurrence-field-selection/recurrence-field-selection.component';
import { HistoryRecordAssetListComponent } from 'app/dynamic-forms/history-records-form/history-record-asset-list/history-record-asset-list.component';
import { UserProfileComponent } from 'app/user/user-profile/user-profile.component';
import { ChangePasswordComponent } from 'app/user/change-password/change-password.component';
import { TimeframeBuilderComponent } from 'app/menu-panel/timeframe/timeframe-builder/timeframe-builder.component';

export class PageResolver implements INavigation {
	resolve(name: Pages) {
		switch (name) {
			case Pages.tabContentType:
				return TabContentPanelComponent;
			case Pages.tabVisibility:
				return TabVisibilityComponent;
			case Pages.tabTheme:
				return ThemeComponent;
			case Pages.layout:
				return LayoutConfigurationComponent;
			case Pages.metrics:
				return ListMetricComponent;
			case Pages.tileSettings:
				return TileSettingsComponent;
			case Pages.mapList:
				return MapListComponent;
			case Pages.mapSettings:
				return MapSettingsComponent;
			case Pages.trendSettings:
				return TrendSettingsComponent;
			case Pages.trendStyle:
				return TrendStyleComponent;
			case Pages.tableSettings:
				return TableSettingsComponent;
			case Pages.metricResults:
				return MetricResultsComponent;
			case Pages.mapLayers:
				return MapLayersComponent;
			case Pages.mapAssetsList:
				return MapAssetListComponent;
			case Pages.singleAssetList:
				return SingleAssetTypeListComponent;
			case Pages.mapAssetAttributes:
				return MapAssetAttributesComponent;
			case Pages.editMetric:
				return EditMetricComponent;
			case Pages.metricSourceTypes:
				return MetricSourceTypesComponent;
			case Pages.metricIconList:
				return MetricIconListComponent;
			case Pages.queryBuilder:
				return QueryBuilderComponent;
			case Pages.timeframeBuilder:
				return TimeframeBuilderComponent;
			case Pages.displayValueSettings:
				return DisplayValueSettingsComponent;
			case Pages.timeFrameSettings:
				return TimeFrameSettingsComponent;
			case Pages.menupanelsettings:
				return MenuPanelSettingsComponent;
			case Pages.defaulttrendsettings:
				return DefaultTrendSettingsComponent;
			case Pages.assetTypes:
				return AssetTypesComponent;
			case Pages.valueList:
				return OmniValueListComponent;
			case Pages.defaulttrendstyle:
				return DefaultTrendStyleComponent;
			case Pages.manualQuery:
				return QueryValidatorComponent;
			case Pages.workorderOutline:
				return WorkOrderOutlineComponent;
			case Pages.workorderSummary:
				return WorkOrderSummaryComponent;
			case Pages.workorderTaskList:
				return WorkorderTaskListComponent;
			case Pages.workorderTask:
				return WorkorderTaskComponent;
			case Pages.workorderNewTask:
				return WorkorderNewTaskComponent;
			case Pages.workOrderSourceTypes:
				return WorkOrderSourceTypesComponent;
			case Pages.workorderField:
				return WorkOrderFieldComponent;
			case Pages.workOrderFilter:
				return WorkOrderFilterComponent;
			case Pages.workOrderFilterSelection:
				return WorkOrderFilterSelectionComponent;
			case Pages.workOrderFilterDatePicker:
				return WorkOrderFilterDatePickerComponent;
			case Pages.workOrderFilterCustomField:
				return WorkOrderFilterCustomFieldComponent;
			case Pages.workOrderAttachments:
				return WorkOrderAttachmentsComponent;
			case Pages.workorderAssets:
				return WorkorderAssetListComponent;
			case Pages.workorderLemList:
				return WorkorderLEMListComponent;
			case Pages.searchResults:
				return SearchResultsComponent;
			case Pages.workorderLEMCostView:
				return WorkOrderLEMCostViewComponent;
			case Pages.workOrderAttachments:
				return WorkOrderAttachmentsComponent;
			case Pages.workOrderCommentList:
				return WorkorderCommentListComponent;
			case Pages.workOrderComment:
				return WorkorderCommentComponent;
			case Pages.workorderTime:
				return AdvancedLaborComponent;
			case Pages.relatedWorkorders:
				return WorkorderRelatedWorkordersComponent;
			case Pages.newLEMMenuPanel:
				return WorkOrderNewLEMMenuPanelComponent;
			case Pages.assetInformation:
				return AssetInformationComponent;
			case Pages.historyRecordsForm:
				return HistoryRecordsFormComponent;
			case Pages.teamView:
				return TeamViewComponent;
			case Pages.test:
				return UITestComponent;
			case Pages.workOrderFailureReport:
				return WorkorderFailureReportMenuPanelComponent;
			case Pages.bulkEditErrorsList:
				return BulkEditErrorsListComponent;
			case Pages.hierarchy:
				return HierarchyMenuPanelComponent;
			case Pages.hierarchyWorkOrderList:
				return HierarchyWorkorderListComponent;
			case Pages.tileTimeframe:
				return TileTimeframeFormComponent;
			case Pages.timeframefilter:
				return TimeframeFilterComponent;
			case Pages.historyRecordAssetList:
				return HistoryRecordAssetListComponent;
			case Pages.dateFieldSelection:
				return DateFieldSelectionComponent;
			case Pages.workOrderRecurrence:
				return WorkOrderRecurrenceComponent;
			case Pages.recurrenceFieldSelection:
				return RecurrenceFieldSelectionComponent;
			case Pages.userProfile:
				return UserProfileComponent;
			case Pages.changePassword:
				return ChangePasswordComponent;
			case Pages.tabSettings:
			default:
				return TabSettingsMainMenuComponent;
		}
	}
}
