export class Node<T> {
	private _value: T;

	get value(): T {
		return this._value;
	}

	next: Node<T>;
	previous: Node<T>;

	constructor(value: T) {
		this._value = value;
	}
}

export class LinkedList<T> {
	private _first: Node<T>;
	get first(): Node<T> {
		return this._first;
	}

	private _last: Node<T>;
	get last(): Node<T> {
		return this._last;
	}
	get count(): number {
		let nextNode = this.first;
		let count = 0;
		while (nextNode) {
			count++;
			nextNode = nextNode.next;
		}
		return count;
	}

	clear() {
		this._first = this._last = null;
	}

	public append = (value: T): LinkedList<T> => {
		const node = this.forgeNode(value);

		if (this.isEmpty()) {
			this._first = node;
			this._last = node;
			return this;
		}

		this.appendToTheEndOfTheList(node);
		return this;
	};

	public isEmpty = () => !this.first;

	public toArray = (): T[] => {
		const result: T[] = [];
		let node = this.first;
		while (node) {
			result.push(node.value);
			node = node.next;
		}
		return result;
	};

	public fromArray = (values: T[]): LinkedList<T> => {
		values.forEach(v => this.append(v));
		return this;
	};

	public removeLast() {
		if (!this.last) return;

		if (!this.last.previous) {
			this.clear();
			return;
		}

		this._last = this.last.previous;
		this.last.next = null;
	}

	private appendToTheEndOfTheList = (node: Node<T>) => {
		if (!node) return;

		node.previous = this.last;
		this.last.next = node;
		this._last = node;
	};

	private forgeNode = (value: T): Node<T> => {
		return new Node<T>(value);
	};
}
