<app-drop-down
	*ngIf="showFieldComponent"
	mode="selection"
	[headerPlaceHolder]="'select a timeframe type'"
	[headerColor]="sedaruGrey"
	[opened]="itemToEdit === 2"
	[itemList]="userTimeframeList"
	[selectedItem]="queryField.timeframeOption"
	[callBackFunction]="onFieldSelectionOpened"
	(itemSelected)="onFieldSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<app-drop-down
	*ngIf="showOperatorComponent && showTimeframeOperatorComponent"
	mode="selection"
	headerPlaceHolder="select period type"
	[headerColor]="defaultSedaruGrey"
	[opened]="itemToEdit === 3"
	[itemList]="unitOperatorList"
	[selectedItem]="queryField.operator"
	[callBackFunction]="onOperatorSelectionOpened"
	(itemSelected)="onOperatorSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<app-drop-down
	*ngIf="showValueComponent && valueEditingMode === 'freeTextAndSelection'"
	[mode]="valueEditingMode"
	[headerPlaceHolder]="headerForValuePanel"
	[headerColor]="sedaruGrey"
	[opened]="expansionShouldOpen"
	[itemList]="valueList"
	[selectedItem]="queryField.value"
	(itemSelected)="onValueChanged($event)"
	(inputKeyUp)="onValueChanged($event.target.value)"
	[callBackFunction]="onValueSelectionOpened"
	[expansionDisabled]="disableExpansionPanel"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<div *ngIf="showValueComponent && valueEditingMode === 'freeText'" class="input-box" [ngStyle]="{ 'background-color': defaultSedaruGrey }">
	<input [value]="queryField.value?.name" (input)="onValueChanged($event.target.value, $event)" [placeholder]="headerForValuePanel" />
</div>
<ng-container *ngIf="showValueComponent && valueEditingMode === 'datepicker'">
	<div class="input-box" [ngStyle]="{ 'background-color': sedaruGrey }">
		<span class="date-picker">
			<input  #calendarInput type="text" readonly [value]="queryField.value?.name" [placeholder]="headerForValuePanel"/>
			<img src="assets/calendar.png" class="omni-md-img" (click)="openCalendar()" />
		</span>
	</div>
</ng-container>
<ng-container *ngIf="showValueComponent && valueEditingMode === 'daterangepicker'">
	<div class="input-box" [ngStyle]="{ 'background-color': sedaruGrey }">
		<span class="date-picker">
			<input  #calendarInput type="text" readonly [value]="queryField.value?.startDate" placeholder="select start date"/>
			<img src="assets/calendar.png" class="omni-md-img" (click)="openCalendar()" />
		</span>
	</div>
	<div class="input-box" [ngStyle]="{ 'background-color': sedaruGrey }" *ngIf="queryField.value.startDate">
		<span class="date-picker">
			<input  #calendarRangeInput type="text" readonly [value]="queryField.value?.endDate"  placeholder="select end date"/>
			<img src="assets/calendar.png" class="omni-md-img" (click)="openEndDateCalendar()" />
		</span>
	</div>
</ng-container>
<app-drop-down
	*ngIf="showJoinComponent"
	mode="selection"
	headerPlaceHolder="select an item"
	[headerColor]="defaultSedaruGrey"
	[opened]="itemToEdit === 5"
	[itemList]="joinOperationList"
	[selectedItem]="queryField.join"
	(itemSelected)="onJoinOperatorSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>

