import { ProfileGroup } from './profile-group.model';

export class ProfileGroups extends Array<ProfileGroup> {
	static fromContracts(profilegroupData: any) {
		const profileGroupList = new ProfileGroups();
		for (const pgData of profilegroupData) {
			const profileGroup = ProfileGroup.fromContract(pgData);
			profileGroupList.push(profileGroup);
		}

		return profileGroupList;
	}

	static checkSelectedProfile(profileGroups, pgName) {
		if (profileGroups) {
			for (const pg of profileGroups) {
				pg.isSelected =  (pg.profileName === pgName) ? true : false;
			}
		}
	}

}
