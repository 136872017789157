import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';

@Injectable({
    providedIn: 'root'
})
export class RecurrenceWorkOrderHttpService {
    baseUrl: string;

    get token(): string {
        if (!this.userService || !this.userService.identityController || !this.userService.identityController.sedaruOAuthToken) {
			return '';
		}

		return this.userService.identityController.sedaruOAuthToken.accessToken;
    }

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) {
        this.baseUrl = `${environment.unifiedOmniApiServer}/v1/rws`;
    }

    get(url: string, paramsObj = null) {
        const params = paramsObj ? this.getQueryString(paramsObj) : '';
        return this.http.get(this.baseUrl + url + '?' + params + '&token=' + this.token).pipe(retry(1));
    }

    post(url: string, postData: any, option = {}) {
        return this.http.post(this.baseUrl + url + '?token=' + this.token, postData, option).pipe(retry(1));
    }

    put(url: string, postData: any, paramsObj = null, option = {}) {
        const params = paramsObj ? this.getQueryString(paramsObj) : '';
        return this.http.put(this.baseUrl + url + '?' + params + '&token=' + this.token, postData, option).pipe(retry(1));
    }

    delete(url: string, paramsObj = null) {
        const params = paramsObj ? this.getQueryString(paramsObj) : '';
        return this.http.delete(this.baseUrl + url + '?' + params + '&token=' + this.token).pipe(retry(1));
    }

    private getQueryString(params) {
		return Object.keys(params)
			.map(key => key + '=' + params[key])
			.join('&');
	}

    handlerError(error) {
		console.error(error);
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		AnalyticsHub.current.trackError(error);
		return throwError(errorMessage);
	}
}
