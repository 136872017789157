import { VendorContract } from './vendor-contract';
import { PriortyContract } from './priority-contract';
import { EmployeeContract } from './employee-contract';
import { EquipmentContract } from './equipment-contract';
import { MaterialContract } from './material-contract';
import { TaskContract } from './task-contract';
import { InspectionContract } from './inspection-contract';
import { WorkOrderTemplateContract } from './work-order-template-contract';
import { CustomFieldCodeContract } from './custom-field-code-contract';
import { StatusContract } from './status-contract';
import { EmployeeRateContract } from './employee-rate-contract';
import { EmployeeTradeContract } from './employee-trade-contract';
import { WorkOrderBudgetContract } from './work-order-budget-contract';
import { AssetLocationWithAssetsContract } from './asset-location-with-assets-contract';
import { WorkOrderTeamContract } from './work-order-team-contract';
import { FailureCodeContract } from './failure-code-contract';

export class WorkOrderMetaDataContract {
	Employees: EmployeeContract[];
	Equipments: EquipmentContract[];
	Materials: MaterialContract[];
	Tasks: TaskContract[];
	Inspections: InspectionContract[];
	WOTemplates: WorkOrderTemplateContract[];
	CustomFieldCodes: CustomFieldCodeContract[];
	WorkOrderPriority: PriortyContract[];
	WorkOrderStatus: StatusContract[];
	EmployeeRates: EmployeeRateContract[];
	EmployeeTrades: EmployeeTradeContract[];
	WorkOrderBudget: WorkOrderBudgetContract[];
	Vendors: VendorContract[];
	AssetLocationsWithAssets: AssetLocationWithAssetsContract[];
	WorkOrderTeam: WorkOrderTeamContract[];
	FailureCodes: FailureCodeContract[];
}
