import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ListItemResolver } from './list-item/templates/list-item.resolver';
import { ListItemComponent } from './list-item/list-item.component';

@Component({
	selector: 'app-omni-list',
	templateUrl: './omni-list.component.html',
	styleUrls: ['./omni-list.component.scss']
})
export class OmniListComponent implements OnInit {
	@ViewChildren(ListItemComponent) listItems: QueryList<ListItemComponent>;

	@ViewChild('scrollingContainer', { static: false }) scrollingContainer: ElementRef;

	/** the array to be displayed in the list */
	private _inputListItems: Array<any>;
	@Input() set omniListItems(items: Array<any>) {
		if (items === null || items === undefined) return;
		this._inputListItems = items;
		this.updateItems();
	}
	get omniListItems() {
		return this._inputListItems;
	}

	@Input() initialRecordCount = 100;

	@Input() selectedItems: Array<any> = [];

	@Input() itemsKey: string;

	@Input() multipleSelection = false;

	@Input() initialScroll = 0;

	@Input() connector = false;

	@Input() isSelectSingleItem = false;

	@Output() leftIconClicked = new EventEmitter<any>();

	@Output() rightIconClicked = new EventEmitter<any>();

	@Output() itemClicked = new EventEmitter<any>();

	@Output() itemHovered = new EventEmitter<any>();

	@Output() mouseLeft = new EventEmitter<any>();

	@Output() scrolled = new EventEmitter<number>();

	@Output() itemRightClicked = new EventEmitter<any>();

	displayItems: Array<{ value: any; selected: boolean; disabled: boolean }>;

	/** the resolver gets set from the template */
	resolver: any;

	displayedRecordCount: number;

	private _viewLoaded = false;

	private _initialScrolled = false;

	private _componentInitialized = false;

	private _waitForOnInit;

	/** delegated to the parent to get the resolver from the parent*/
	getResolver: (item: any) => ListItemResolver;

	constructor() {}

	ngOnInit() {
		this.displayedRecordCount = this.initialRecordCount;
		this._componentInitialized = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const changeName in changes) {
			if (changeName === 'initialScroll') {
				if (this.initialScroll !== 0 && !this._initialScrolled) {
					this.scrollToInitialScroll();
				}
			} else if (changeName === 'initialRecordCount') {
				this.onScrollStepReached();
			}
		}
	}

	ngAfterViewInit(): void {
		this._viewLoaded = true;
		if (this.selectedItems && this.selectedItems.length) {
			const selectedIndex = this.omniListItems.indexOf(this.selectedItems[0]);
			if (selectedIndex >= 0) {
				const keepScrollingInterval = setInterval(() => {
					this.scrollingContainer.nativeElement.scroll(0, selectedIndex * 41.11);
					if (this.scrollingContainer.nativeElement.scrollHeight >= selectedIndex * 41.11) clearInterval(keepScrollingInterval);
				}, 500);
			}
		}
	}

	private updateItems() {
		if (this._waitForOnInit) clearInterval(this._waitForOnInit);
		this._waitForOnInit = setInterval(() => {
			if (this._componentInitialized) {
				clearInterval(this._waitForOnInit);
				this.displayItems = [];
				this.omniListItems.forEach(originalItem => {
					this.displayItems.push({ value: originalItem, selected: this.indexInItemsSelected(originalItem) >= 0 , disabled: !!originalItem.disabled});
				});
				if (this.initialScroll !== 0) this.scrollToInitialScroll();
			}
		}, 100);
	}

	private scrollToInitialScroll() {
		const waitForView = setInterval(() => {
			if (!this._viewLoaded) return;
			clearInterval(waitForView);
			this.scrollingContainer.nativeElement.scroll(0, this.initialScroll);
			if (this.scrollingContainer.nativeElement.scrollHeight < this.initialScroll) {
				const keepScrollingInterval = setInterval(() => {
					this.scrollingContainer.nativeElement.scroll(0, this.initialScroll);
					if (this.scrollingContainer.nativeElement.scrollHeight >= this.initialScroll) {
						clearInterval(keepScrollingInterval);
						this._initialScrolled = true;
					}
				}, 500);
			}
		}, 500);
	}

	onItemSelected(itemSelected) {
		const indexInItemsSelected = this.indexInItemsSelected(itemSelected);
		if (indexInItemsSelected >= 0) this.selectedItems.splice(indexInItemsSelected, 1);
		else if (this.isSelectSingleItem) this.selectedItems = [itemSelected];
		else this.selectedItems.push(itemSelected);
		this.updateItems();
		this.itemClicked.emit(itemSelected);
	}

	onScrollStepReached() {
		this.displayedRecordCount += this.initialRecordCount;
	}

	onScroll() {
		this.scrolled.emit(this.scrollingContainer.nativeElement.scrollTop);
	}

	clearSelection() {
		this.displayItems.forEach(item => (item.selected = false));
		this.selectedItems = [];
	}

	removeItem(item: any) {
		const i = this.omniListItems.indexOf(item);
		if (i >= 0) this.omniListItems.splice(i, 1);
		this.updateItems();
	}

	private indexInItemsSelected(itemInTheList): number {
		if (!this.selectedItems || !this.selectedItems.length || !this.itemsKey) return -1;

		let index = -1;
		for (let i = 0; i < this.selectedItems.length; i++) {
			const selectedItem = this.selectedItems[i];
			if (!selectedItem) continue;
			if (selectedItem[this.itemsKey] === itemInTheList[this.itemsKey]) {
				index = i;
				break;
			}
		}
		return index;
	}
}
