import { Component, ElementRef, OnInit } from '@angular/core';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Color } from 'models';
import { UserService } from '../user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends MenuPanelBaseComponent {
  currentPassword: string;
  newPassword: string;
  reEnterPassword: string;

  get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

  lightGrayColor = Color.createFromRGBA('rgba(128,128,128,0.6)');
  darkGrayColor = Color.createFromRGBA('rgba(84, 84, 84, 0.6)');

  constructor(
    private alertDialogService: AlertDialogService,
    private userService: UserService,
    private interop: OmniInteropService,
    private flashMessageService: FlashMessageService,
    view: ElementRef<HTMLElement>
  ) {
    super(view);
  }

  ngOnInit(): void {
    this.menuPanelComponent.updateView({
      title: 'change password'
    });
  }

  onPageNavigatedTo(args: NavigationArgs) {}

  isSaveEnabled() {
    if (!this.currentPassword || !this.newPassword || !this.reEnterPassword) return false;
    return true;
  }

  async onSave() {
    if (this.currentPassword === this.newPassword) {
      this.alertDialog.mainMessage = { text: 'New password same as current password'};
		  this.alertDialog.open = true;
      return;
    }
    if (this.newPassword !== this.reEnterPassword) {
      this.alertDialog.mainMessage = { text: 'Please make sure your new passwords match.'};
		  this.alertDialog.open = true;
      return;
    }
    const result = await this.userService.changePassword(this.currentPassword, this.newPassword);
    if (!result.Success) {
      this.alertDialog.mainMessage = { text: result.Message};
		  this.alertDialog.open = true;
      return;
    } else {
      this.flashMessageService.popMessage('Password Changed successfully.');
      this.menuPanelComponent.goBack();
    }
  }
}
