import { MaterialCostContract, StandardMaterialCostContract } from '../../contracts/work-order';
import { MaterialCostWrapper } from './material-cost-wrapper.model';
import { MaterialCost } from './material-cost.model';
import { StandardMaterialCost } from './standard-material-cost.model';

export class MaterialCostWrappers extends Array<MaterialCostWrapper> {
	static fromContracts(contracts: MaterialCostContract[] | StandardMaterialCostContract[]): MaterialCostWrappers {
		let model: MaterialCost | StandardMaterialCost;
		return MaterialCostWrappers.fromModels(
			contracts.map((c: MaterialCostContract | StandardMaterialCostContract) => {
				if (c instanceof MaterialCostContract) model = MaterialCost.fromContract(c);
				else if (c instanceof StandardMaterialCostContract) model = StandardMaterialCost.fromContract(c);
				return model;
			})
		);
	}

	static fromModels(models: (MaterialCost | StandardMaterialCost)[]): MaterialCostWrappers {
		const wrappersList = new MaterialCostWrappers();
		for (const model of models) wrappersList.push(new MaterialCostWrapper(model));
		return wrappersList;
	}

	getContracts = (): MaterialCostContract[] | StandardMaterialCostContract[] => this.map(mc => mc.getContract());

	getModels = (): (MaterialCost | StandardMaterialCost)[] => this.map(mc => mc.materialCost);

	isDirty = (): boolean => !!this.filter(ec => ec.isDirty).length;

	clearDirty = (): void => this.forEach(ec => ec.clearDirty());
}
