import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Component, ElementRef } from '@angular/core';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { QueryMode, Channel, Metric, ArcGISHistoryChannelAttributes, ArcGISAssetChannelAttributes, MetricTypes, ChannelTypes, QueryStatementList } from 'models';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { AGSQuery } from 'models/ags-query.model';
import { CustomQuery } from 'models/custom-query.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AssetQuery } from 'models/asset-query.model';
import { HistoryQuery } from 'models/history-query.model';
import { AWOQuery } from 'models/awo-query.model';
import { SWOQuery } from 'models/swo-query.model';

/**
 * This component will be used to validate the manual query for different data channels (say esri)
 */
@Component({
	selector: 'app-query-validator',
	templateUrl: './query-validator.component.html',
	styleUrls: ['./query-validator.component.scss']
})
export class QueryValidatorComponent extends MenuPanelBaseComponent {
	metric: Metric;

	showHistoryQueryInput = false;

	showAssetQueryInput = false;

	showWorkOrderQueryInput = false;
	/**
	 * Holds the map layer query which was entered through the UI.
	 */
	mapLayerQueryString = '';
	/**
	 * Holds the related table query which was entered through the UI.
	 */
	relatedTableQueryString = '';

	/**
	 * Holds the custom work order query;
	 */
	workOrderQueryString = '';
	/**
	 * Holds the text to be displayed for the validate button at the bottom of the component.
	 */
	validateButtonText = '';
	/**
	 * Holds the path of the icon to be displayed for the validate button at the bottom of the component.
	 */
	validateButtonIcon = '';
	/**
	 * Tooltip to be displayed when hovering over the validate button.
	 */
	validateButtonTooltip = '';
	/**
	 * Holds the text to be displayed for the apply button at the bottom of the component.
	 */
	saveButtonText = '';
	/**
	 * Boolean variable which can be used to enable and disable the apply button appropriately.
	 */
	disableSaveButton = true;
	/**
	 * Boolean variable which can be used to enable and disable the validate button appropriately.
	 */
	disableValidateButton = false;
	/**
	 * Boolean variable which can be used to control the behaviour of the validate button appropriately.
	 */
	validationInProgress = false;
	/**
	 * To determine weather the map layer query or the related table query is being validated.
	 * It can have 'maplayerquery' or 'relatedtablequery' as its value.
	 */
	queryStringType = '';

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {MetricService} metricService - Provides all the services required to manipulate a metric
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(private interopService: OmniInteropService, private flashMessageService: FlashMessageService, private workOrderFactory: WorkOrderFactory, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	/**
	 * Initially we set all the button values and images to the default ones
	 */
	ngOnInit() {
		let { query } = this.metric.definition;
		const { workOrderChannel, historyChannel, assetChannel } = this.metric.definition;
		const viewObj = { title: 'manual query' };

		if (!query || query.mode !== QueryMode.manualQuery) {
			query = new CustomQuery();
			query.mode = QueryMode.manualQuery;
			if (workOrderChannel) {
				(query as CustomQuery).workOrderQueryString = '';
			} else {
				(query as CustomQuery).mapLayerQueryString = '';
				if (historyChannel) (query as CustomQuery).relatedTableQueryString = '';
			}
		}

		if (workOrderChannel) {
			this.workOrderQueryString = (query as CustomQuery).workOrderQueryString;
			this.showWorkOrderQueryInput = true;
		}
		if (historyChannel) {
			this.relatedTableQueryString = (query as CustomQuery).relatedTableQueryString;
			this.showHistoryQueryInput = true;
		}
		if (assetChannel) {
			this.mapLayerQueryString = (query as CustomQuery).mapLayerQueryString;
			this.showAssetQueryInput = true;
		}

		if (this.relatedTableQueryString || this.mapLayerQueryString || this.workOrderQueryString) {
			viewObj['rightIcon'] = { url: 'assets/equalizer.png', toolTip: 'query builder', callBackFunction: this.goToQueryBuilder };
		}

		this.validateButtonText = 'validate';
		this.validateButtonIcon = 'assets/tick-tranparent.png';
		this.validateButtonTooltip = 'validate the query string';
		this.saveButtonText = 'save';
		this.menuPanelComponent.updateView(viewObj);
	}

	goToQueryBuilder = () => {
		switch (this.metric.definition.source.type) {
			case ChannelTypes.Asset:
				this.metric.definition.query = new AssetQuery();
				break;
			case ChannelTypes.History:
				this.metric.definition.query = new HistoryQuery();
				break;
			case ChannelTypes.WorkOrder:
				if (this.metric.definition.workOrderChannel.isAdvancedWorkOrder) {
					this.metric.definition.query = new AWOQuery();
					break;
				}
				this.metric.definition.query = new SWOQuery();
		}
		const queryStatementList = QueryStatementList.from((this.metric.definition.query as AGSQuery).queryStatementList);

		NavigationService.navigateTo(Pages.queryBuilder, { metric: this.metric, queryStatementList });
	};

	/**
	 * when user edits (model value changed) the text box we reset the button and images to default ones
	 */
	onQueryStringEdited(): void {
		this.disableValidateButton = false;
		// Need to change this back
		this.disableSaveButton = true;
		this.validateButtonText = 'validate';
		this.validateButtonIcon = 'assets/tick-tranparent.png';
		this.validateButtonTooltip = 'validate the query string';
		this.saveButtonText = 'save';

		if (this.showHistoryQueryInput && !this.relatedTableQueryString && this.mapLayerQueryString) {
			this.mapLayerQueryString = '';
		}

		if (!this.relatedTableQueryString && !this.mapLayerQueryString && !this.workOrderQueryString) {
			this.disableSaveButton = false;
			this.disableValidateButton = true;
			const { query } = this.metric.definition;
			if ((query as AGSQuery).queryStatementList.length) {
				query.mode = QueryMode.builderQuery;
			} else {
				query.mode = QueryMode.none;
			}
		}
	}

	/**
	 * On save, the query string will be saved into selectedMetric object,
	 * Depends on the route, we know if this.queryString is mapLayerQuery or relatedTableQuery
	 */
	onSave(): void {
		if (this.disableSaveButton) return;
		const { query, historyChannel, assetChannel, workOrderChannel } = this.metric.definition;

		if (historyChannel && !this.relatedTableQueryString) {
			return this.flashMessageService.popMessage('Need a related table query string for history source.');
		}

		if (assetChannel) (query as CustomQuery).mapLayerQueryString = this.mapLayerQueryString;

		if (historyChannel) (query as CustomQuery).relatedTableQueryString = this.relatedTableQueryString;

		if (workOrderChannel) (query as CustomQuery).workOrderQueryString = this.workOrderQueryString;

		if (this.relatedTableQueryString || this.mapLayerQueryString || this.workOrderQueryString) {
			query.mode = QueryMode.manualQuery;
		}

		NavigationService.navigateBackTo(Pages.editMetric);
	}

	/**
	 * on Validate will valiate the query string using the graphQl service validateQueryString
	 */
	async onValidate(): Promise<void> {
		if (this.validationInProgress) {
			return;
		}
		if (!this.mapLayerQueryString && !this.relatedTableQueryString && !this.workOrderQueryString) {
			return;
		}

		this.validationInProgress = true;
		this.validateButtonText = 'validating...';
		this.validateButtonIcon = 'assets/circles_pacifier.gif';
		const promiseList = this.getValidateQueryStringPromiseList();
		try {
			const statuses = await Promise.all(promiseList);
			console.log(statuses);
			for (const status of statuses) {
				this.validateResponse(status);
			}
			this.popSuccess('Validated');
			this.disableSaveButton = false;
		} catch (error) {
			this.popError(error.message);
		}
	}

	getValidateQueryStringPromiseList() {
		const validatePromiseAll = [];
		const { assetChannel, historyChannel } = this.metric.definition;
		if (this.mapLayerQueryString) {
			// Do not await for this function call
			const assetAttribtues = assetChannel.attributes as ArcGISAssetChannelAttributes;
			const assetLayer = this.interopService.arcGISManager.getAssetLayer(assetAttribtues.assetType);
			const promise = assetLayer.validateQuery(this.mapLayerQueryString);
			validatePromiseAll.push(promise);
		}

		if (this.relatedTableQueryString) {
			// Do not await for this function call
			const historyAttribtues = historyChannel.attributes as ArcGISHistoryChannelAttributes;
			const historyLayer = this.interopService.arcGISManager.getHistoryLayer(historyAttribtues.assetType);
			const promise = historyLayer.validateQuery(this.relatedTableQueryString);
			validatePromiseAll.push(promise);
		}

		if (this.workOrderQueryString) {
			// Do not await for this function call
			const wordOrderLayer = this.workOrderFactory.workOrderLayer;
			const promise = wordOrderLayer.validateQuery(this.workOrderQueryString);
			validatePromiseAll.push(promise);
		}

		return validatePromiseAll;
	}

	validateResponse(status: any): void {
		this.validationInProgress = false;
		if (!status) {
			throw new Error('invalid query');
		}
	}

	popSuccess(message: string): void {
		this.flashMessageService.popMessage(message, SnackbarMessageTypes.SUCCESS);
		this.validateButtonText = 'validated';
		this.validateButtonIcon = 'assets/tick-green.png';
	}

	popError(message: string): void {
		this.flashMessageService.popMessage(message, SnackbarMessageTypes.ERROR);
		this.saveButtonText = 'save';
		this.validateButtonIcon = 'assets/error.png';
		this.validateButtonText = 'validate';
		this.disableValidateButton = true;
	}
}
