import { Component, ViewChild, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { GuiConfigService } from '../domain-service/gui-config.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import { GuiConfig } from 'omni-model/gui-config.model';
import { Observable } from 'rxjs';
import * as SedaruUtils from '../sedaru-util';
import { OmniBootstrapService } from 'domain-service/omni-bootstrap.service';
import { NotificationObject } from '../sedaru-util';
import { LayoutComponent } from './layout/layout.component';
import { AppEnvironmentService } from 'domain-service/app-environment.service';
import { NavigationService, Pages } from './navigation/navigation.service';
import { BackNavigationArgs, BackButtonSource } from './navigation/back-navigation-args';
import { LocationStrategy } from '@angular/common';
import { PageResolver } from 'app/navigation/pageresolver';
import { RouterRegistry } from './navigation/router/router.registry';
import { ModifierKeys } from '../domain-service/ui';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { HttpClient } from '@angular/common/http';
import { HttpAngular } from '../omni/http-angular-wrapper';
import { UserService } from './user/user.service';

/**
 * This is the root component of the OMNI app.
 * It provides the placeholder for all the other components.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
	private static thisInstance: AppComponent;

	// @ViewChild(CanvasContainerComponent) canvasContainerComponent: CanvasContainerComponent;

	// @ViewChild(TileListComponent) tileListComponent: TileListComponent;

	@ViewChild(LayoutComponent) layoutComponent: LayoutComponent;

	public get guiConfigService(): GuiConfigService {
		return this._guiConfigService;
	}
	public get activeGuiConfig(): GuiConfig {
		return this.availableConfigs.find(existingConfig => existingConfig.isSelected);
	}

	private _availableConfigs;
	public get availableConfigs(): Array<GuiConfig> {
		return this._guiConfigService.availableConfigurations;
	}
	private _hasHistoryEntry = false;

	private _notificationObject: SedaruUtils.NotificationObject<string>;
	private _modifierKeys: ModifierKeys;
	public get modifierKeys(): ModifierKeys {
		if (!this._modifierKeys) this._modifierKeys = new ModifierKeys();

		return this._modifierKeys;
	}

	/** the number of milliseconds to refresh metrics */
	private metricRefreshInterval = 15 * 60 * 1000;

	private metricRefreshIntervalHandler;

	private refreshMetricsTimeout;

	loading = true;

	/**
	 * Holds the URL to the background image corresponding to the selected tab.
	 */
	tabBackground = '';

	/**
	 * The constructor aside from loading and injecting dependencies, registers all the images used in the OMNI home screen.
	 * @param {MatIconRegistry} _iconRegistry - Provides services to register and display icons used by the <mat-icon> component.
	 * @param {DomSanitizer} _domSanitizer - Provides services to sanitize values to be safe to use in the different DOM contexts.
	 * 		screens.
	 * @param {GuiConfigService} _guiConfigService - Provides all the services to manipulate the OMNI tab.
	 */
	constructor(
		private _iconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer,
		private appEnvironment: AppEnvironmentService,
		private omniAppInterop: OmniInteropService,
		private _guiConfigService: GuiConfigService,
		private _platformLocation: LocationStrategy,
		private userService: UserService,
		private _httpClient: HttpClient
	) {
		HttpAngular.initializeHttp(_httpClient);
		// Register Images
		this._iconRegistry.addSvgIcon('sedaru-header', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/sedaru-header.svg'));
		this._iconRegistry.addSvgIcon('sedaru', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/sedaru-logo.svg'));
		this._iconRegistry.addSvgIcon('omni', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/omni-logo.svg'));
		AppComponent.thisInstance = this;
		smoothscroll.polyfill();
	}

	@HostListener('document:keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		let hasChanged = false;
		if (this.modifierKeys.ctrlPressed !== event.ctrlKey) {
			this.modifierKeys.ctrlPressed = event.ctrlKey;
			hasChanged = true;
		}
		if (this.modifierKeys.shitftPressed !== event.shiftKey) {
			this.modifierKeys.shitftPressed = event.shiftKey;
			hasChanged = true;
		}
		if (this.modifierKeys.altPressed !== event.altKey) {
			this.modifierKeys.altPressed = event.altKey;
			hasChanged = true;
		}

		if (hasChanged) this.layoutComponent.onModifierKeysChanged(this.modifierKeys);
	}

	@HostListener('document:keyup', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		let hasChanged = false;
		if (this.modifierKeys.ctrlPressed !== event.ctrlKey) {
			this.modifierKeys.ctrlPressed = event.ctrlKey;
			hasChanged = true;
		}
		if (this.modifierKeys.shitftPressed !== event.shiftKey) {
			this.modifierKeys.shitftPressed = event.shiftKey;
			hasChanged = true;
		}
		if (this.modifierKeys.altPressed !== event.altKey) {
			this.modifierKeys.altPressed = event.altKey;
			hasChanged = true;
		}

		if (hasChanged) this.layoutComponent.onModifierKeysChanged(this.modifierKeys);
	}

	@HostListener('window:beforeunload', ['$event'])
	unloadHandler(event: any) {
		if (!this?.shouldWarnDataDirty()) {
			return;
		}

		const confirmationMessage = 'my message';
		// tslint:disable-next-line: deprecation
		(event || window.event).returnValue = confirmationMessage; // Gecko + IE
		return confirmationMessage; // Safari, Chrome, and other
	}

	onPopState(): boolean {
		if (
			NavigationService.current.activeTab.mainContent.menuPanelComponent.navigationRouter.currentPageIdentifier === 'workOrderAttachments' ||
			NavigationService.current.activeTab.mainContent.menuPanelComponent.navigationRouter.currentPageIdentifier === 'hierarchy' ||
			NavigationService.current.activeTab.mainContent.menuPanelComponent.navigationRouter.currentPageIdentifier === 'assetInformation' ||
			NavigationService.current.activeTab.mainContent.menuPanelComponent.navigationRouter.currentPageIdentifier === 'historyRecordsForm'
		) {
			return false;
		}

		const canGoBack = NavigationService.current.activeTab.mainContent.menuPanelComponent.canGoBack();
		console.log('can go back: ' + canGoBack + ' length: ' + window.history.length);
		if (!canGoBack) {
			return false;
		}

		const args = new BackNavigationArgs(BackButtonSource.browser);
		NavigationService.current.activeTab.mainContent.menuPanelComponent.goBack(args);

		return true;
	}

	private addMockEntry() {
		if (this._hasHistoryEntry) return;

		history.pushState(null, null, location.href);
		this._hasHistoryEntry = true;
		console.log('added entry');
	}

	updateBackButtonStatus() {
		const canGoBack = NavigationService.current.activeTab.mainContent.menuPanelComponent.canGoBack();

		if (canGoBack) {
			if (!this._hasHistoryEntry) {
				this.addMockEntry();
			}

			return;
		}
		if (this._hasHistoryEntry) {
			history.back();
			console.log('history back');
			this._hasHistoryEntry = false;
		}

		if (NavigationService.current.activeTab.mainContent.menuPanelComponent?.currentNavigationEntry?.header?.leftButton) {
			NavigationService.current.activeTab.mainContent.menuPanelComponent.currentNavigationEntry.header.leftButton.isVisible = canGoBack;
		}
	}

	async ngOnInit() {
		this._platformLocation.onPopState(() => {
			this._hasHistoryEntry = false;
			const handled = this.onPopState();
			if (handled) {
				this.addMockEntry();
			}
			return handled;
		});

		const notificationObject = new NotificationObject<string>();
		notificationObject.onNotify.subscribe((sender, args) => {
			console.log(args);
		});

		const result = await this.omniAppInterop.initialize(this, notificationObject);

		if (!result) {
			if (window.location.href.toLowerCase().includes('localhost') || window.location.href.toLowerCase().includes('developer.sedaru.com')) {
				const userResponse = confirm('Failed to start app. Want to be redirected to login?');
				if (!userResponse) return;
			}
			window.location.href = `${this.appEnvironment.loginUrl}?customerCode=`;
			return;
		}

		this.loading = false;
		setTimeout(() => {
			NavigationService.navigateTo(Pages.teamView, null, null, RouterRegistry.temViewerRouter);
		}, 0);

		this.setMetricRefreshInterval();
	}

	ngOnDestroy() {
		if (this.metricRefreshIntervalHandler) clearInterval(this.metricRefreshIntervalHandler);
	}

	setMetricRefreshInterval() {
		const omniInterop = this.omniAppInterop;
		this.metricRefreshIntervalHandler = setInterval(() => {
			const now = new Date();
			if (now.getHours() === 0 && now.getMinutes() < this.metricRefreshInterval / 1000) omniInterop.metricManager?.refreshMetrics(false);
			else omniInterop.metricManager?.refreshMetrics(true);
		}, this.metricRefreshInterval);
	}

	ngAfterViewInit(): void {
		NavigationService.current.initializeAppLayout(this, new PageResolver());
	}

	/** when the user clicks the + button to add a new tab from the tab panel component */
	onNewTabRequested(): Observable<GuiConfig> {
		return new Observable<GuiConfig>(subscriber => {
			const partialConfig = GuiConfig.generateNewConfig(this.userService.getCurrentProfileGroup());
			AppComponent.thisInstance._guiConfigService.addConfigToDb(partialConfig).subscribe(async configFromDb => {
				subscriber.next(AppComponent.thisInstance._guiConfigService.addConfigToAvailableConfigs(configFromDb.id, partialConfig));
				await AppComponent.thisInstance._guiConfigService.updateTabOrder().toPromise();
				subscriber.complete();
			});
		});
	}

	/** when the user selects a tab from the tab panel component */
	onTabSelected(selectedTab: GuiConfig) {
		this._guiConfigService.setSelectedConfig(selectedTab.id);
		setTimeout(() => {
			// give time for the newly selected tab to load
			const router = NavigationService.current.activeTab.router;
			this._hasHistoryEntry = router.canGoBack();
		}, 500);

		if (this.refreshMetricsTimeout) {
			clearTimeout(this.refreshMetricsTimeout);
			this.refreshMetricsTimeout = null;
		}

		this.refreshMetricsTimeout = setTimeout(() => this.omniAppInterop.metricManager?.refreshMetrics(true), 5000);
	}

	private shouldWarnDataDirty(): boolean {
		return this.layoutComponent.shouldWarnDataDirty();
	}
}
