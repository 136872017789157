import * as SedaruUtils from '../sedaru-util';

export abstract class ModelBase extends SedaruUtils.UndoableObject {}

export abstract class OmniModelBase<TModel> extends ModelBase {
	private _onModelUpdated: SedaruUtils.InvokableEvent;
	get onModelUpdatedEvent(): SedaruUtils.InvokableEvent {
		if (!this._onModelUpdated) this._onModelUpdated = new SedaruUtils.InvokableEvent();

		return this._onModelUpdated;
	}

	invokeDataUpdated() {
		this.onModelUpdatedEvent.invoke(this, null);
	}

	onDirtyCleared() {
		this.invokeDataUpdated();
	}
}

export abstract class OmniModel<TModel, TContract> extends OmniModelBase<TModel> {
	updateInformation(workOrderChild: TModel): boolean {
		const wasChanged = this.onUpdateInformation(workOrderChild);

		if (wasChanged) this.invokeDataUpdated();

		return wasChanged;
	}

	protected updateIfNotDirty<T>(field: SedaruUtils.Undoable<T>, assignFunction: () => void) {
		if (!field) {
			assignFunction();
			return true;
		}

		if (!field.isDirty) {
			assignFunction();
			if (field.isDirty) {
				// we clear the history because this change was made by the server data, not the user input
				field.clearUndoHistory();
				return true;
			}
		}

		return false;
	}

	getDirtyFieldsFeature(): any {
		return null;
	}

	abstract getContract(): TContract;
	abstract onUpdateInformation(copyModel: TModel): boolean;
}
