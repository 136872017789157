<app-menu-panel-field
    [header]="'*every # of months'"
    [(value)]="pattern.interval"
    [cssClass]="'orange-background'"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [type]="'number'"
    [disabled]="isReadOnly"
    [buttonEnabled]="false"
></app-menu-panel-field>
<app-menu-panel-field
    [header]="'*day of month'"
    [(value)]="pattern.monthlyPattern.exactDay"
    [cssClass]="'orange-background'"
    [maxNumber]="31"
    [minNumber]="1"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [leftMargin]="'4px'"
    [type]="'number'"
    [disabled]="isReadOnly"
    [buttonEnabled]="false"
></app-menu-panel-field>
