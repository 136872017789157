import { OmniBootstrapService } from 'domain-service/omni-bootstrap.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ManagerBase } from 'domain-service/manager-base';
import { JobBase } from './job-base';
import { BulkUpdateJob } from './bulk-update-job/bulk-update-job';
import { Template } from '@angular/compiler/src/render3/r3_ast';

export class JobManager extends ManagerBase {

	get parentInteropService(): OmniInteropService {
		return this.interopService;
	}

	private _jobs: Array<JobBase>;
	public get jobs(): Array<JobBase> {
		if (!this._jobs) this._jobs = new Array<JobBase>();

		return this._jobs;
	}

	constructor(protected interopService: OmniInteropService) {
		super(interopService);
	}

	getJobById(id: string): JobBase {
		return this.jobs.find((item) => item.jobId.toLowerCase() === id.toLowerCase())
	}

	getJobsActiveJobsByName(jobName: string): JobBase[] {
		return this.jobs.filter(item => item.name === jobName);
	}

	getLatestBulkUpdateJob(): BulkUpdateJob {
		const jobs = this.jobs.filter(item => item.name === BulkUpdateJob.JobName);
		if (!jobs || jobs.length === 0) return undefined;

		const bulkJobs = jobs.map(j => j as BulkUpdateJob);
		return bulkJobs.find(j => !j.isDismissed);
	}

	removeJobById(jobId: string): boolean {
		const job = this.getJobById(jobId);
		if (!job) return false;
		const index = this.jobs.indexOf(job);
		this.jobs.splice(index, 1);

		return true;
	}

	initialize() {
	}

	enqueueJob(job: JobBase): string {
		const newJobId = '';
		job.jobManager = this;
		job.jobId = newJobId;
		this.jobs.push(job);
		return newJobId;
	}
}
