import { ListItemResolver } from '../list-item.resolver';
import { ListTemplates } from '../templates.enum';

export class NoIconOneLineTemplateResolver extends ListItemResolver {
	getLabel: (item: any) => string;
	private _label: string;
	private _labelRequested = false;

	templateType = ListTemplates.noIconOneLine;

	_getLabel(item: any) {
		if (!this._label && !this._labelRequested) {
			this._label = this.getLabel(item);
			this._labelRequested = true;
		}
		return this._label;
	}

	resetFlags(): void {
		this._label = null;
		this._labelRequested = false;
	}
}
