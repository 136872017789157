import { CanvasMap } from './canvas-map.model';
import { CanvasTab } from './canvas-tab.model';
import { Metric } from '../models/metric.model';
import { TrendStyle } from '../app/menu-panel/trend-settings/trend-style/trend-style.model';

export enum CanvasMode {
	empty = 'empty',
	map = 'map',
	table = 'table',
	trend = 'trend'
}

export class CanvasBase {
	/**
	 * The vertex id of this canvas vertex in the data base
	 */
	public id?: string;
	/**
	 * Used to uniquely identify a canvas within a tab.
	 */
	public position: number;
	/**
	 * Holds the column size of the canvas
	 */
	public colspan?: number;
	/**
	 * A string that represents what view the canvas will show: empty, map, table or trend
	 */
	public mode?: CanvasMode;
	/**
	 * Holds a number from 1 to 4 which represents the size of the canvas based on the ratio of the area it occupies.
	 */
	public size?: CanvasSize;
	/**
	 * Holds all the necessary settings to load the map into a canvas
	 */
	public map?: CanvasMap;

	public table?: any;
}

export class Canvas extends CanvasBase {
	/**
	 * Holds the esri map view for this canvas
	 */
	public mapView?: __esri.MapView;

	private _trendStyle: TrendStyle;
	get trendStyle() {
		if (!this._trendStyle) this._trendStyle = new TrendStyle();
		return this._trendStyle;
	}
	set trendStyle(style: TrendStyle) {
		this._trendStyle = style;
	}
	/**
	 * Holds the array of tabs of a canvas. Each tab corresponds to a tile that has been dragged into the canvas
	 * or to a tile that has been linked to this canvas and is active (selected).
	 */
	public tabs?: Array<CanvasTab> = [];

	/**
	 * if true it will show a highlighting cyan border
	 */
	public highlighted?: boolean;

	/**
	 * if true it can draw polygon on map
	 */
	public isDrawPolygonActive?: boolean;

	/**
	 * This constructor sets the size of the canvas.
	 * @param {CanvasSize} canvasSize - a number from 1 to 4 which represents the size of the canvas
	 * based on the ratio of the area it occupies.
	 * @param {number} position - Uniquely identify a canvas for the current tab by its position
	 * @param {any} canvasParamaters - an array which holds the canvas layout and the array of canvas maps.
	 */
	constructor(canvasSize: CanvasSize, position: number) {
		super();
		if (canvasSize === 1 || canvasSize === 2) {
			this.colspan = 2;
			this.size = canvasSize;
		}
		if (canvasSize === 3 || canvasSize === 4) {
			this.colspan = 1;
			this.size = canvasSize;
		}

		this.position = position;

		this.mode = CanvasMode.empty;

		this.isDrawPolygonActive = false;
	}

	getTabsWithMetric(metric: Metric): Array<CanvasTab> {
		const tabsWithMetric = [];
		if (this.tabs && this.tabs.length) {
			this.tabs.forEach(tab => {
				if (tab.hasMetric(metric)) tabsWithMetric.push(tab);
			});
		}
		return tabsWithMetric;
	}
}

/**
 * The canvas size is a number that represents the following pattern:
 * 1: the canvas takes the whole available space;
 * 2: the canvas is half of the vertical space;
 * 3: the canvas is half of the horizontal space;
 * 4: the canvas is a quadrant of the available space;
 */
export enum CanvasSize {
	size1 = 1,
	size2 = 2,
	size3 = 3,
	size4 = 4
}

/**
 * Each size represents the different styles a canvas can take.
 * The style differs based on the number of canvases being displayed at the same time and the position of these canvases
 * when there are multiple canvases.
 * OMNI currently supports 6 different styles.
 * 1: one canvas
 * 2: two horizontal canvases
 * 3: two sqaure canvases on top, one horizontal canvas at the bottom
 * 4: four sqaure canvases
 * 5: two vertical canvases
 * 6: one horizontal canvas on top, two square canvases at the bottom
 */
export enum CanvasLayoutTypes {
	size1 = 1,
	size2 = 2,
	size3 = 3,
	size4 = 4,
	size5 = 5,
	size6 = 6
}
