import { NavigationService, Pages } from './../../navigation/navigation.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { LEMService } from './lem.service';
import { EmployeeCostWrapper, LEMType, WorkOrderWrapper } from 'models/work-order';
import * as SedaruModels from 'models';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderChannelAttributes, LEMMode } from 'models';
import { GlobalConfig } from '../../../models/global-config.model';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-workorder-lem-list',
	templateUrl: './workorder-lem-list.component.html',
	styleUrls: ['./workorder-lem-list.component.scss']
})
export class WorkorderLEMListComponent extends MenuPanelBaseComponent {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	lemList: Array<any> = new Array<any>();
	LEMListHeader: string;
	currentWorkOrder: WorkOrderWrapper;
	footerText: string;
	globalConfig: GlobalConfig;

	constructor(
		private workOrderFactory: WorkOrderFactory,
		private lemService: LEMService,
		private interOpService: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.currentWorkOrder = args.parameter.workOrder;
		if (!this.currentWorkOrder) return;

		this.menuPanelComponent.updateView({
			title: this.currentWorkOrder.workOrderKey,
			/**
			 * not going to add LEM for now
			 */
			// rightIcon: { url: 'assets/plus.png', callBackFunction: this.callBackFunction.bind(this), toolTip: '' },
			backgroundClass: 'orange-background'
		});

		this.LEMListHeader = this.lemService.lemHeader;
		this.lemList = this.lemService.lemList;
		this.listComponent.getResolver = () => this.lemService.lemResolver;
		this.footerText = this.lemService.lemFooter;
		this.globalConfig = this.interOpService.configurationManager.customerCodeConfiguration;
	}

	callBackFunction() {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				const employeeCost = this.workOrderFactory.createEmployeeCostModel(this.currentWorkOrder);
				NavigationService.navigateTo(Pages.workorderTime, { workOrder: this.currentWorkOrder, employeeCost });
				break;
			case LEMType.equipment:
				const equipmentCost = this.workOrderFactory.createEquipmentCostModel(this.currentWorkOrder);
				NavigationService.navigateTo(Pages.newLEMMenuPanel, { workOrder: this.currentWorkOrder, equipmentCost });
				break;
			case LEMType.material:
				const materialCost = this.workOrderFactory.createMaterialCostModel(this.currentWorkOrder);
				NavigationService.navigateTo(Pages.newLEMMenuPanel, { workOrder: this.currentWorkOrder, materialCost });
				break;
		}
	}

	onItemClicked(item: any) {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				if (this.globalConfig.workOrderMode !== SedaruModels.WorkOrderModeType.Advanced) return;
				const woAttributes = this.globalConfig.workOrderChannel.attributes as WorkOrderChannelAttributes;
				if (!woAttributes) return;
				if (woAttributes.lemMode !== LEMMode.Advanced) return;

				NavigationService.navigateTo(Pages.workorderTime, {
					readOnly: true,
					workOrder: this.currentWorkOrder,
					employeeCost: item as EmployeeCostWrapper
				});
				break;

			/**
			 * these cases are not being used for now, but don't delete
			 */
			// case LEMType.equipment:
			// 	this.workOrderProvider.currentEquipmentCost = item as EquipmentCost;
			// 	NavigationService.navigateTo(Pages.newLEMMenuPanel);
			// 	break;
			// case LEMType.material:
			// 	this.workOrderProvider.currentMaterialCost = item as MaterialCost;
			// 	NavigationService.navigateTo(Pages.newLEMMenuPanel);
			// 	break;
		}
	}
}
