import { Timeframe } from './timeframe.model';
import { TimeframeDefinitionContract } from './timeframe-definition-contract';
import { TimeframeDefinition } from './timeframe-definition.model';
import { TimeframeFilterContract } from './timeframe-filter-contract';
import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';

export class TimeframeFilter extends OmniModel<TimeframeFilter, TimeframeFilterContract> {
	private _timeframeField: SedaruUtils.Undoable<string>;
	public get timeframeField(): string {
		if (!this._timeframeField) this._timeframeField = new SedaruUtils.Undoable<string>();
		return this._timeframeField.value;
	}
	public set timeframeField(value: string) {
		if (!this._timeframeField) {
			this._timeframeField = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._timeframeField.value = value;
	}

	private _timeFrame: SedaruUtils.Undoable<Timeframe>;
	public get timeFrame(): Timeframe {
		if (!this._timeFrame) this._timeFrame = new SedaruUtils.Undoable<Timeframe>(new Timeframe(TimeframeDefinition.newAllDatesDefinition()));
		return this._timeFrame.value;
	}
	public set timeFrame(value: Timeframe) {
		if (!this._timeFrame) {
			this._timeFrame = new SedaruUtils.Undoable<Timeframe>(value);
			return;
		}
		this._timeFrame.value = value;
	}

	public get timeFrameDefinition(): TimeframeDefinition {
		if (!this.timeFrame) return null;
		return this.timeFrame.definition;
	}

	static fromContract(timeframeFilterContract: TimeframeFilterContract): TimeframeFilter {
		const model = new TimeframeFilter();
		model.timeFrame = new Timeframe(TimeframeDefinition.fromContract(timeframeFilterContract.timeFrameDefinition));
		model.timeframeField = timeframeFilterContract.timeframeField;
		model.clearDirty();
		return model;
	}

	onUpdateInformation(copyModel: TimeframeFilter): boolean {
		return false;
	}

	getContract = (): TimeframeFilterContract => {
		const contract = new TimeframeFilterContract();
		if (this.timeFrameDefinition) contract.timeFrameDefinition = this.timeFrameDefinition.getContract();
		contract.timeframeField = this.timeframeField;
		return contract;
	};

	getDefinitionContract = (): TimeframeDefinitionContract => {
		if (!this.timeFrameDefinition) return null;

		const timeFrameFilterContract = this.timeFrameDefinition.getContract();
		timeFrameFilterContract.timeframeField = this.timeframeField ? this.timeframeField.toString() : '';
		return timeFrameFilterContract;
	};

	isValid(): boolean {
		if (!this.timeFrameDefinition) return false;
		const type = this.timeFrameDefinition.type;
		return !!(this.timeframeField && this.timeframeField.length && type);
	}

	isEqualsTo(timeframeFilter: TimeframeFilter): boolean {
		if (!timeframeFilter) return false;
		if (!((timeframeFilter as any) instanceof TimeframeFilter)) return false;
		if (this.timeframeField?.toLowerCase() !== timeframeFilter.timeframeField?.toLowerCase()) return false;
		return this.timeFrame?.isEqualsTo(timeframeFilter.timeFrame);
	}
}
