<!-- if it is a tile with content-->
<div draggable="true" (dragstart)="onTileDrag(thisComponent)" (dragend)="onTileDrop(thisComponent)">
	<mat-card
		class="omni-tile"
		(click)="onSelect()"
		[style.background-color]="tile.backgroundColor || null"
		[style.color]="tile.foregroundColor || null"
		(mouseover)="hovered = true"
		(mouseleave)="hovered = false"
	>
		<div>
			<div class="omni-tile-header">
				<img [src]="tile.metric.definition.iconUrl" class="omni-tile-header-avatar visible" />

				<div class="omni-tile-title" [title]="tile.metric.definition.name">
					<app-text-in-max-lines [text]="tile.metric.definition.name" [fontSize]="'12px'" [lineHeight]="'14px'" [maxLines]="2"></app-text-in-max-lines>
				</div>
				<img
					*ngIf="settingsEnabled"
					mat-card-avatar
					class="omni-tile-header-settings-button"
					(click)="$event.stopPropagation(); onSettingsClick(thisComponent)"
					src="assets/settings1.png"
					alt="settings"
					[class.visible]="tile.metric && hovered"
				/>
			</div>

			<mat-card-content class="omni-tile-content">
				<app-pacifiers *ngIf="loading" [resolver]="pacifierResolver" style="display: flex; justify-content: center; margin-top: 10px;"></app-pacifiers>
				<span
					class="display-value"
					#tileDisplayValue
					*ngIf="!loading && !textOverride"
					[matTooltip]="displayValue"
					[matTooltipDisabled]="tileDisplayValue.offsetWidth >= tileDisplayValue.scrollWidth"
					>{{ displayValue }}</span
				>
				<div class="display-text" *ngIf="!loading && textOverride">{{ textOverride }}</div>
			</mat-card-content>
			<div class="omni-tile-timeframe" [class.hidden]="!isTimeframeFieldSet" [style.color]="tile.foregroundColor || null">
				<span (click)="onDateFrameClick(thisComponent); $event.stopPropagation()">{{ tile.uiTimeframeFilter.timeFrame?.text?.toLowerCase() }}</span>
			</div>

			<span class="indicator" *ngIf="showIndicator">•</span>
		</div>
		<div class="omni-tile-overlay" *ngIf="showWarning">
			<span>{{ showWarning }}</span>
		</div>
	</mat-card>
</div>
