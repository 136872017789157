import { Component, OnInit, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { FailureReportWrapper, FailureReportWrappers } from 'models/work-order';

@Component({
  selector: 'app-workorder-failure-report-menu-panel',
  templateUrl: './workorder-failure-report-menu-panel.component.html',
  styleUrls: ['./workorder-failure-report-menu-panel.component.scss']
})
export class WorkorderFailureReportMenuPanelComponent extends MenuPanelBaseComponent {
  failureResultHeight: MenuPanelFieldSize = MenuPanelFieldSize.full;
  failureResultClass: FailureReportWrapper;
  failureResultProblem: FailureReportWrapper;
  failureResultCause: FailureReportWrapper;
  failureResultRemedy: FailureReportWrapper;

  constructor(
    private workOrderFactory: WorkOrderFactory,
		view: ElementRef<HTMLElement>
	) {
		super(view);
  }

  ngOnInit(): void {
    this.menuPanelComponent.rightIcon = null;
  }

  onPageNavigatedTo(args: NavigationArgs): void {
    const failureResults = args.parameter.failureResults as FailureReportWrappers;
    if (!failureResults) return;

    failureResults.forEach(fr => this.setFailureResult(fr));
  }

  private setFailureResult(failureResult: FailureReportWrapper): void {
    if (!failureResult) return;

    switch (failureResult.codeType) {
      case 'CLASS':
        this.failureResultClass = failureResult;
        break;
      case 'PROBLEM':
        this.failureResultProblem = failureResult;
        break;
      case 'CAUSE':
        this.failureResultCause = failureResult;
        break;
      case 'REMEDY':
        this.failureResultRemedy = failureResult;
        break;
      default:
        break;
    }
  }

  getDescriptionFromCodedValue(failureResult: FailureReportWrapper): string {
    if (!failureResult || !this.workOrderFactory || !this.workOrderFactory.workOrderMetaData) return '';

    const failureCode = this.workOrderFactory.workOrderMetaData.failureCodes.findByCodedValue(failureResult.codeValue);
    if (!failureCode) return '';

    return failureCode.description;
  }
}
