import { ValueContext } from './value-context';

export class FormControlValue {
	text: '';
	textFormatId: number;
	valueFormatId: number;
	context: ValueContext;
	get isValid() {
		if (!this.text && !this.context) return false;
		if (this.context && !this.context.isValid) return false;
		return true;
	}
}
