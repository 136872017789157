import { TimeframeDefinition } from './timeframe-definition.model';
import { TimeframeDefinitionType } from './timeframe-definition-type.enum';
import { TimeframeUnit } from './timeframe-unit.enum';
import * as SedaruUtils from '../../sedaru-util';
import { TimeframeRangeType } from './timeframe-range-type.enum';

export class Timeframe {
	private _onTimeRangeChanged: SedaruUtils.InvokableEvent;
	public get onTimeRangeChanged(): SedaruUtils.InvokableEvent {
		if (!this._onTimeRangeChanged) this._onTimeRangeChanged = new SedaruUtils.InvokableEvent();

		return this._onTimeRangeChanged;
	}

	private _precisionTimestamp: number;
	private _precisionUnit: TimeframeUnit;
	private get isExpired(): boolean {
		let newTimeStamp = 0;
		if (this._precisionUnit === TimeframeUnit.Minute) {
			newTimeStamp = this.currentDateTimeStamp.getMinutes();
		} else if (this._precisionUnit === TimeframeUnit.Hours) {
			newTimeStamp = this.currentDateTimeStamp.getHours();
		} else if (this._precisionUnit === TimeframeUnit.Days || this.definition.unit === TimeframeUnit.Weeks) {
			newTimeStamp = this.currentDateTimeStamp.getDate();
		} else if (this._precisionUnit === TimeframeUnit.Months || this.definition.unit === TimeframeUnit.Quarters) {
			newTimeStamp = this.currentDateTimeStamp.getMonth();
		} else if (this._precisionUnit === TimeframeUnit.Years) {
			newTimeStamp = this.currentDateTimeStamp.getFullYear();
		}

		return this._precisionTimestamp !== newTimeStamp;
	}

	public get currentDateTimeStamp() {
		if (!this.dateTimeStampOverride) return new Date();

		return this.dateTimeStampOverride;
	}

	private _dateTimeStampOverride: Date;
	public get dateTimeStampOverride(): Date {
		return this._dateTimeStampOverride;
	}
	public set dateTimeStampOverride(value: Date) {
		this._dateTimeStampOverride = value;
		this.refresh();
	}

	private _startDate: Date;
	public get startDate(): Date {
		if (this.definition.boundlessType === TimeframeRangeType.All) {
			return null;
		}

		if (this.definition.boundlessType === TimeframeRangeType.ValidDates) {
			return null;
		}

		if (this.definition.boundlessType === TimeframeRangeType.NoDate) {
			return null;
		}

		if (this.isExpired) this.refresh();
		return this._startDate;
	}

	private _endDate: Date;
	public get endDate(): Date {
		if (this.definition.boundlessType === TimeframeRangeType.All) {
			return null;
		}

		if (this.definition.boundlessType === TimeframeRangeType.ValidDates) {
			return null;
		}

		if (this.definition.boundlessType === TimeframeRangeType.NoDate) {
			return null;
		}

		if (this.isExpired) this.refresh();
		return this._endDate;
	}

	public get definition(): TimeframeDefinition {
		return this._timeFrameDefinition;
	}

	public get text(): string {
		return this.definition.text;
	}

	constructor(private _timeFrameDefinition: TimeframeDefinition) {
		if (_timeFrameDefinition) TimeframeDefinition.updateParentTimeFrame(_timeFrameDefinition, this);
		this.refresh();
	}

	static updateDates(timeframe: Timeframe) {
		timeframe.refresh();
	}

	private getHourStart(value: Date): Date {
		return new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), 0, 0, 0);
	}
	private getHourEnd(value: Date): Date {
		return new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), 59, 59, 999);
	}
	private getDateStart(value: Date): Date {
		return new Date(value.toDateString());
	}
	private getDateEnd(value: Date): Date {
		return new Date(this.getDateStart(value).getTime() + (24 * 60 * 60 * 1000 - 1));
	}
	private getWeekStart(value: Date): Date {
		const startDate = new Date(value);
		startDate.setDate(value.getDate() - value.getDay());
		return this.getDateStart(startDate);
	}
	private getWeekEnd(value: Date): Date {
		const difference = 6 - value.getDay();
		const endDate = new Date(value);
		endDate.setDate(value.getDate() + difference);
		return this.getDateEnd(endDate);
	}
	private getMonthStart(value: Date): Date {
		return this.getDateStart(new Date(value.getFullYear(), value.getMonth(), 1));
	}
	private getMonthEnd(value: Date): Date {
		return this.getDateEnd(new Date(value.getFullYear(), value.getMonth() + 1, 0));
	}
	private getQuarterStart(value: Date): Date {
		if (value.getMonth() < 3) {
			return this.getDateStart(new Date(value.getFullYear(), 0, 1));
		}
		if (value.getMonth() < 6) {
			return this.getDateStart(new Date(value.getFullYear(), 3, 1));
		}
		if (value.getMonth() < 9) {
			return this.getDateStart(new Date(value.getFullYear(), 6, 1));
		}
		if (value.getMonth() < 12) {
			return this.getDateStart(new Date(value.getFullYear(), 9, 1));
		}
	}
	private getQuarterEnd(value: Date): Date {
		if (value.getMonth() < 3) {
			return this.getDateEnd(new Date(value.getFullYear(), 2, 31));
		}
		if (value.getMonth() < 6) {
			return this.getDateEnd(new Date(value.getFullYear(), 5, 30));
		}
		if (value.getMonth() < 9) {
			return this.getDateEnd(new Date(value.getFullYear(), 8, 30));
		}
		if (value.getMonth() < 12) {
			return this.getDateEnd(new Date(value.getFullYear(), 11, 31));
		}
	}
	private getYearStart(value: Date): Date {
		return new Date(value.getFullYear(), 0, 1);
	}
	private getYearEnd(value: Date): Date {
		return new Date(value.getFullYear(), 11, 31, 23, 59, 59);
	}
	private setPrecisionUnit(unit: TimeframeUnit) {
		this._precisionUnit = unit;
		if (unit === TimeframeUnit.Minute) {
			this._precisionTimestamp = this.currentDateTimeStamp.getMinutes();
		} else if (unit === TimeframeUnit.Hours) {
			this._precisionTimestamp = this.currentDateTimeStamp.getHours();
		} else if (unit === TimeframeUnit.Days) {
			this._precisionTimestamp = this.currentDateTimeStamp.getDate();
		} else if (unit === TimeframeUnit.Months) {
			this._precisionTimestamp = this.currentDateTimeStamp.getMonth();
		} else if (unit === TimeframeUnit.Years) {
			this._precisionTimestamp = this.currentDateTimeStamp.getFullYear();
		}
	}

	private refresh() {
		const currentStartDate = this._startDate;
		const currentEndDate = this._endDate;
		this._precisionUnit = undefined;
		this._precisionTimestamp = undefined;
		switch (this.definition.type) {
			case TimeframeDefinitionType.This:
				this.setThis(this.currentDateTimeStamp, this.definition.unit);
				break;
			case TimeframeDefinitionType.NextX:
				this.setNextX(this.currentDateTimeStamp, this.definition.unit, this.definition.interval, this.definition.isInclusive);
				break;
			case TimeframeDefinitionType.LastX:
				this.setLastX(this.currentDateTimeStamp, this.definition.unit, this.definition.interval);
				break;
			case TimeframeDefinitionType.RestOfX:
				this.setRestOfX(this.currentDateTimeStamp, this.definition.unit);
				break;
			case TimeframeDefinitionType.XToNow:
				this.setXtoNow(this.currentDateTimeStamp);
				break;
			case TimeframeDefinitionType.LaterThanToday:
				this.setAfterDate(this.currentDateTimeStamp, this.definition.isInclusive);
				break;
			case TimeframeDefinitionType.TodayAndEarlier:
				this.setBeforeDate(this.currentDateTimeStamp, this.definition.isInclusive);
				break;
			case TimeframeDefinitionType.EarlierThanToday:
				this.setBeforeDate(this.currentDateTimeStamp, this.definition.isInclusive);
				break;
			case TimeframeDefinitionType.ExactDate:
				this.setThis(this.definition.date, TimeframeUnit.Days);
				break;
			case TimeframeDefinitionType.DateRange:
				this.setNextX(this.definition.date, TimeframeUnit.Days, this.definition.interval, true);
				break;
			case TimeframeDefinitionType.BeforeDate:
				this.setBeforeDate(this.definition.date, true);
				break;
			case TimeframeDefinitionType.AfterDate:
				this.setAfterDate(this.definition.date, true);
				break;
			case TimeframeDefinitionType.Boundless:
				this.setPrecisionUnit(TimeframeUnit.None);
				break;
		}

		if (currentStartDate !== this._startDate || currentEndDate !== this._endDate) {
			this.onTimeRangeChanged.invoke(this, undefined);
		}

		if (!this._precisionUnit && !this._precisionTimestamp) {
			console.warn(this.definition.type + ' ' + this.definition.unit + ' invalid time frame');
		}
	}

	private setThis(anchorDate: Date, unit: TimeframeUnit) {
		let start: Date = new Date(anchorDate);
		let end: Date = new Date(anchorDate);

		switch (unit) {
			case TimeframeUnit.Hours:
				this._startDate = this.getHourStart(anchorDate);
				this._endDate = this.getHourEnd(anchorDate);
				this.setPrecisionUnit(TimeframeUnit.Hours);
				break;
			case TimeframeUnit.Days:
				this._startDate = this.getDateStart(anchorDate);
				this._endDate = this.getDateEnd(anchorDate);
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Weeks:
				this._endDate = this.getWeekEnd(this.getDateEnd(end));
				this._startDate = this.getWeekStart(this.getDateStart(start));
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Months:
				start = this.getMonthStart(start);
				this._startDate = this.getDateStart(start);

				end = this.getMonthEnd(end);
				this._endDate = this.getDateEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Quarters:
				start = this.getQuarterStart(start);
				this._startDate = this.getDateStart(start);

				end = this.getQuarterEnd(end);
				this._endDate = this.getDateEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Years:
				start = this.getYearStart(start);
				this._startDate = start;
				end = this.getYearEnd(end);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Years);
				break;
		}
	}

	private setLastX(anchorDate: Date, unit: TimeframeUnit, interval: number) {
		let start: Date = new Date(anchorDate);
		let end: Date = new Date(anchorDate);

		switch (unit) {
			case TimeframeUnit.Hours:
				if (!this.definition.isInclusive) {
					end.setHours(anchorDate.getHours() - 1);
				}
				this._endDate = this.getHourEnd(end);
				start = new Date(end);
				start.setHours(end.getHours() - interval + 1);
				this._startDate = this.getHourStart(start);
				this.setPrecisionUnit(TimeframeUnit.Hours);
				break;
			case TimeframeUnit.Days:
				if (!this.definition.isInclusive) {
					end.setDate(anchorDate.getDate() - 1);
					end = this.getDateEnd(end);
				}
				this._endDate = this.getDateEnd(end);
				start = new Date(end);
				start.setDate(end.getDate() - (interval - 1));
				this._startDate = this.getDateStart(start);
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Weeks:
				end = this.getWeekEnd(anchorDate);
				if (!this.definition.isInclusive) end.setDate(end.getDate() - 7);
				this._endDate = end;
				start = new Date(end);
				start.setDate(start.getDate() - 7 * interval + 1);
				this._startDate = this.getDateStart(start);
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Months:
				if (!this.definition.isInclusive) {
					end.setMonth(anchorDate.getMonth() - 1);
				}
				this._endDate = this.getMonthEnd(end);
				end.setMonth(end.getMonth() - (interval - 1));
				this._startDate = this.getMonthStart(end);
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Quarters:
				start = this.getQuarterStart(start);
				end = new Date(start);
				if (!this.definition.isInclusive) {
					end.setDate(end.getDate() - 1);
					this._endDate = this.getDateEnd(end);
				} else this._endDate = this.getQuarterEnd(start);
				if (this.definition.isInclusive) interval = interval - 1;
				for (let i = 0; i < interval; i++) {
					start.setDate(start.getDate() - 1);
					start = this.getQuarterStart(start);
				}
				this._startDate = start;
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Years:
				if (!this.definition.isInclusive) {
					end.setFullYear(anchorDate.getFullYear() - 1);
				}
				this._endDate = this.getDateEnd(this.getYearEnd(end));
				end.setFullYear(end.getFullYear() - (interval - 1));
				this._startDate = this.getDateStart(this.getYearStart(end));
				this.setPrecisionUnit(TimeframeUnit.Years);
				break;
		}
	}

	private setNextX(anchorDate: Date, unit: TimeframeUnit, interval: number, isInclusive?: boolean) {
		let start: Date = new Date(anchorDate);
		let end: Date = new Date(anchorDate);

		switch (unit) {
			case TimeframeUnit.Hours:
				if (!isInclusive) {
					start.setHours(anchorDate.getHours() + 1);
				}
				this._startDate = this.getHourStart(start);
				end = new Date(start);
				end.setHours(start.getHours() + interval - 1);
				this._endDate = this.getHourEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Hours);
				break;
			case TimeframeUnit.Days:
				if (!isInclusive) {
					start.setDate(anchorDate.getDate() + 1);
				}
				this._startDate = this.getDateStart(start);

				end.setDate(start.getDate() + (interval - 1));
				this._endDate = this.getDateEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Hours);
				break;
			case TimeframeUnit.Weeks:
				start = this.getWeekStart(anchorDate);
				if (!isInclusive) start.setDate(start.getDate() + 7);
				this._startDate = start;
				end = new Date(start);
				end.setDate(end.getDate() + 7 * interval - 1);
				this._endDate = this.getDateEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Months:
				if (!isInclusive) {
					start.setMonth(anchorDate.getMonth() + 1);
				}
				this._startDate = this.getMonthStart(start);
				start.setMonth(start.getMonth() + (interval - 1));
				this._endDate = this.getMonthEnd(start);
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Quarters:
				end = this.getQuarterEnd(end);
				start = new Date(end);
				if (!isInclusive) {
					start.setDate(start.getDate() + 1);
					this._startDate = this.getDateStart(start);
				} else this._startDate = this.getQuarterStart(end);
				if (isInclusive) interval = interval - 1;
				for (let i = 0; i < interval; i++) {
					end.setDate(end.getDate() + 1);
					end = this.getQuarterEnd(end);
				}
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Years:
				if (!isInclusive) {
					start.setFullYear(anchorDate.getFullYear() + 1);
				}
				this._startDate = this.getDateStart(this.getYearStart(start));

				end.setFullYear(start.getFullYear() + (interval - 1));
				this._endDate = this.getDateEnd(this.getYearEnd(end));
				this.setPrecisionUnit(TimeframeUnit.Hours);
				break;
		}
	}

	private setRestOfX(anchorDate: Date, unit: TimeframeUnit) {
		const start: Date = new Date(anchorDate);
		const end: Date = new Date(anchorDate);

		switch (this.definition.unit) {
			case TimeframeUnit.Hours:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getHourEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Minute);
				break;
			case TimeframeUnit.Days:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getDateEnd(end);
				this.setPrecisionUnit(TimeframeUnit.Minute);
				break;
			case TimeframeUnit.Weeks:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getWeekEnd(this.getDateEnd(end));
				this.setPrecisionUnit(TimeframeUnit.Minute);
				break;
			case TimeframeUnit.Months:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getDateEnd(this.getMonthEnd(end));
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Quarters:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getDateEnd(this.getQuarterEnd(end));
				this.setPrecisionUnit(TimeframeUnit.Quarters);
				break;
			case TimeframeUnit.Years:
				this._startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
				this._endDate = this.getDateEnd(this.getYearEnd(end));
				this.setPrecisionUnit(TimeframeUnit.Years);
				break;
		}
	}

	private setXtoNow(anchorDate: Date) {
		const start: Date = new Date(anchorDate);
		const end: Date = new Date(anchorDate);

		switch (this.definition.unit) {
			case TimeframeUnit.Hours:
				this._startDate = this.getHourStart(start);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Minute);
				break;
			case TimeframeUnit.Days:
				this._startDate = this.getDateStart(start);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Minute);
				break;
			case TimeframeUnit.Weeks:
				this._startDate = this.getWeekStart(this.getDateStart(start));
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Days);
				break;
			case TimeframeUnit.Months:
				this._startDate = this.getMonthStart(start);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Months);
				break;
			case TimeframeUnit.Quarters:
				this._startDate = this.getQuarterStart(start);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Quarters);
				break;
			case TimeframeUnit.Years:
				this._startDate = this.getYearStart(start);
				this._endDate = end;
				this.setPrecisionUnit(TimeframeUnit.Years);
				break;
		}
	}

	private setBeforeDate(anchorDate: Date, inclusive: boolean) {
		this._startDate = undefined;
		const endDate = anchorDate;
		if (!inclusive) endDate.setDate(anchorDate.getDate() - 1);
		else endDate.setDate(anchorDate.getDate());

		this._endDate = this.getDateEnd(new Date(endDate));
		this.setPrecisionUnit(TimeframeUnit.Days);
	}

	private setAfterDate(anchorDate: Date, inclusive: boolean) {
		this._endDate = undefined;
		const startDate = anchorDate;

		if (!inclusive) startDate.setDate(anchorDate.getDate() + 1);
		else startDate.setDate(anchorDate.getDate());

		this._startDate = this.getDateStart(new Date(startDate));
		this.setPrecisionUnit(TimeframeUnit.Days);
	}

	/**
	 * Compares this timeframe to another. Returns true if equals.
	 * @param timeframe the timeframe to compare to.
	 */
	isEqualsTo(timeframe: Timeframe) {
		if (!timeframe) return false;
		if (!((timeframe as any) instanceof Timeframe)) return false;

		return this.definition.isEqualsTo(timeframe.definition);
	}
}
