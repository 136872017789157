import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ConnectorModel } from '../connector/connector.model';
import { NodeModel } from '../node/node.model';

@Component({
	selector: 'app-node-network',
	templateUrl: './node-network.component.html',
	styleUrls: ['./node-network.component.scss']
})
export class NodeNetworkComponent implements OnInit {
	@Input() numberOfNodes = 10;

	@Input() speedOfNodes = 2000;

	@Input() speedOfConnectors = 500;

	private _elementWrapper: HTMLDivElement;
	@Input() set elementWrapper(e: HTMLDivElement) {
		if (!e) return;
		const x = e.offsetWidth / 2;
		const y = e.offsetHeight / 2;
		this.center = { x, y };
		setTimeout(() => {
			this._elementWrapper = e;
		}, 0);
	}
	get elementWrapper() {
		return this._elementWrapper;
	}

	@Input() delay = 100;

	get radius() {
		if (this.elementWrapper.offsetWidth < this.elementWrapper.offsetHeight) return this.elementWrapper.offsetWidth;
		return this.elementWrapper.offsetHeight;
	}

	center: { x: number; y: number };

	nodes: NodeModel[] = [];
	connectors: ConnectorModel[] = [];

	private intervalHandler;

	constructor() {}

	ngOnInit() {
		setTimeout(() => {
			if (!this.elementWrapper || this.elementWrapper.offsetHeight < 50 || this.elementWrapper.offsetWidth < 50) return;
			this.addCenterNode();
			this.addNodes();
		}, this.delay);
	}

	ngOnDestroy(): void {
		if (this.intervalHandler) clearInterval(this.intervalHandler);
	}

	addCenterNode() {
		const centerNode = new NodeModel();
		centerNode.position = { top: this.center.y, left: this.center.x };
		centerNode.size = 'medium';
		this.nodes.push(centerNode);
	}

	addNodes() {
		this.intervalHandler = setInterval(() => {
			const newNode = new NodeModel();
			newNode.size = this.getRandomSize();
			newNode.position = this.getRandomPosition();
			this.nodes.push(newNode);
			setTimeout(() => {
				this.addConnector(newNode);
			}, this.speedOfConnectors);
			if (this.nodes.length >= this.numberOfNodes) clearInterval(this.intervalHandler);
		}, this.speedOfNodes);
	}

	addConnector(nodeTo: NodeModel) {
		const connector = new ConnectorModel();
		const nodeFrom = this.nodes[0];
		connector.from = nodeFrom.center;
		connector.to = nodeTo.center;
		this.connectors.push(connector);
	}

	private getRandomSize() {
		const randomNumber = Math.floor(Math.random() * 3);
		switch (randomNumber) {
			case 0:
				return 'small';
			case 1:
				return 'medium';
			case 2:
				return 'large';
		}
	}

	private getRandomPosition() {
		const maxHeight = this.elementWrapper.offsetHeight;
		const maxWidth = this.elementWrapper.offsetWidth;
		let top: number, left: number;
		do {
			top = Math.floor(Math.random() * maxHeight);
			left = Math.floor(Math.random() * maxWidth);
		} while (this.isCoordinateCloseToOthers(left, top));

		return { top, left };
	}

	private isCoordinateCloseToOthers(x: number, y: number) {
		let borderLeft = this.center.x - 50;
		let borderRight = this.center.x + 50;
		let borderTop = this.center.y - 50;
		let borderBottom = this.center.y + 50;
		let xWithinBorders: boolean, yWithinBorders: boolean;
		if (x >= borderLeft && x <= borderRight) xWithinBorders = true;
		if (y >= borderTop && y <= borderBottom) yWithinBorders = true;
		if (xWithinBorders && yWithinBorders) return true;
		let result = false;
		this.nodes.forEach(node => {
			xWithinBorders = false;
			yWithinBorders = false;
			borderLeft = node.position.left - 40;
			borderRight = node.position.left + 40;
			borderTop = node.position.top - 40;
			borderBottom = node.position.top + 40;
			if (x >= borderLeft && x <= borderRight) xWithinBorders = true;
			if (y >= borderTop && y <= borderBottom) yWithinBorders = true;
			if (xWithinBorders && yWithinBorders) return (result = true);
		});
		return result;
	}
}
