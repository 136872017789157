<div class="scrolling-content">
	<div class="timeframe-filter">
		<app-menu-panel-field
			[value]="dateField"
			[header]="'date filter field'"
			[placeHolder]="'not set'"
			[buttonEnabled]="!this.currentTile"
			(goToButtonClick)="goToDateFilterField()"
			[class.disabled]="this.currentTile"
			[disabled]="true"
		></app-menu-panel-field>

		<app-menu-panel-field
			[value]="currentTimeframe ? currentTimeframe.text.toLowerCase() : 'all time'"
			[header]="'time frame'"
			[placeHolder]="'not set'"
			[cssClass]="'light-gray-background'"
			[buttonEnabled]="true"
			(goToButtonClick)="dateField && goToDefaultTimeFrame()"
			[class.disabled]="!dateField"
			[disabled]="true"
		></app-menu-panel-field>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<span flex></span>
		<button *ngIf="timeFrameType !== 'metric' && canSave" mat-button class="omni-md-font" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
