import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { WarningMessageService } from './warning-message.service';

@Component({
	selector: 'app-tab-changed-warning-dialog',
	templateUrl: './tab-changed-warning-dialog.component.html',
})
export class TabChangedWarningDialogComponent implements OnInit {

  header: string;
  message: string;

	constructor(public dialogRef: MatDialogRef<TabChangedWarningDialogComponent>, private warningService: WarningMessageService) {}

	/**
	 * This method will close the dialog and trigger an event.
	 * @param {boolean} isConfirmed - Boolean value that indicates if the user confirmed to remove the canvases
	 */
	onClose(isConfirmed: boolean) {
		this.dialogRef.close(isConfirmed);
	}

  ngOnInit() {
    this.header = this.warningService.header;
    this.message = this.warningService.message;
  }
}
