<app-drop-down
	*ngIf="showFieldComponent"
	mode="selection"
	[headerPlaceHolder]="'select a field'"
	[headerColor]="sedaruGrey"
	[opened]="itemToEdit === 2"
	[itemList]="filtedArcGISFieldList"
	[selectedItem]="queryField.field"
	[callBackFunction]="onFieldSelectionOpened"
	(itemSelected)="onFieldSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<app-drop-down
	*ngIf="showOperatorComponent"
	mode="selection"
	headerPlaceHolder="select an operator"
	[headerColor]="defaultSedaruGrey"
	[opened]="itemToEdit === 3"
	[itemList]="operatorList"
	[selectedItem]="queryField.operator"
	[callBackFunction]="onOperatorSelectionOpened"
	(itemSelected)="onOperatorSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<app-drop-down
	*ngIf="showValueComponent && valueEditingMode !== 'freeText'"
	[mode]="valueEditingMode"
	[headerPlaceHolder]="headerForValuePanel"
	[headerColor]="sedaruGrey"
	[opened]="expansionShouldOpen"
	[itemList]="valueList"
	[selectedItem]="queryField.value"
	(itemSelected)="onValueChanged($event)"
	(inputKeyUp)="onValueChanged($event.target.value)"
	[callBackFunction]="onValueSelectionOpened"
	[htmlInputCriteria]="htmlInputCriteria"
	[expansionDisabled]="disableExpansionPanel"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
<div *ngIf="showValueComponent && valueEditingMode === 'freeText'" class="input-box" [ngStyle]="{ 'background-color': sedaruGrey }">
	<input [value]="queryField.value?.code" (input)="onValueChanged($event.target.value)" [placeholder]="headerForValuePanel" />
</div>
<app-drop-down
	*ngIf="showJoinComponent"
	mode="selection"
	headerPlaceHolder="select an item"
	[headerColor]="defaultSedaruGrey"
	[opened]="itemToEdit === 5"
	[itemList]="joinOperationList"
	[selectedItem]="queryField.join"
	(itemSelected)="onJoinOperatorSelected($event)"
	[maxHeight]="maxHeightOfField"
></app-drop-down>
