import { Credentials } from './credentials.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';



export interface FlatProxy {
	id: string;
	legacyId: number;
	url: string;
	credentialsId: string;
	credentialsLegacyId: number;
}


/**
 * This defines the proxy details for source layers
 */
export class Proxy extends SedaruConfigBase {
	/**
	 * uuid for proxy
	 */
	id?: string;

	/**
	 * holds the frist generation id which will getting from studio.
	 */
	legacyId?: number;

	/**
	 * holds the url of the source layers
	 */
	url: string;

	/**
	 * holds the credentials id for the credential vertex
	 */
	credentialsId?: string;

	/**
	 * hold the legacyId of the credentials
	 */
	credentialsLegacyId?: number;

	/**
	 * holds the credentials details for the source layers
	 */
	credentials?: Credentials;

	static fromFlatProxy(flatProxy: FlatProxy) {
		const p = new Proxy();
		p.id = flatProxy.id;
		p.legacyId = flatProxy.legacyId;
		p.url = flatProxy.url;
		p.credentialsId = flatProxy.credentialsId;
		p.credentialsLegacyId = flatProxy.credentialsLegacyId;
		return p;
	}

	/**
	 * This constructor loads and injects dependencies and initializes a default empty value.
	 */
	constructor(globalConfig?: GlobalConfig) {
		super(globalConfig);
	}

	getFlatProxy(): FlatProxy {
		return {
			id: this.id,
			legacyId: this.legacyId,
			url: this.url,
			credentialsId: this.credentialsId,
			credentialsLegacyId: this.credentialsLegacyId
		};
	}
}

export class Proxies extends Array<Proxy> {
	getByLegacyId = (id: number): Proxy => {
		return this.find(proxy => proxy.legacyId === id);
	};
}
