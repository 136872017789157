import { Injectable } from '@angular/core';
import { ContractResponseBase } from 'contracts/work-order';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { RecurrenceWorkOrderService } from 'domain-service/recurrence/recurrence-work-order.service';
import { AssociatedAsset, RecurrenceTemplate } from 'models/recurrence';

@Injectable({
	providedIn: 'root'
})
export class RecurrenceProvider {
	interopService: OmniInteropService;

	private _customerCode: string;
	public get customerCode(): string {
		return this._customerCode ?? (this._customerCode = this.getCustomerCode());
	}

	private _profileGroup: string;
	public get profileGroup(): string {
		return this._profileGroup ?? (this._profileGroup = this.getProfileGroup());
	}

	constructor(private rws: RecurrenceWorkOrderService) {}

	private getCustomerCode(): string {
		return this.interopService?.omniDomain?.userService?.currentUser?.customerCode;
	}

	private getProfileGroup(): string {
		return this.interopService?.omniDomain?.userService?.currentUser?.profileGroup;
	}

	//#region Recurrence Template CRUD
	public async createRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplate): Promise<ContractResponseBase> {
		const response = await this.rws.postRecurrenceTemplate(recurrenceTemplate.getContract(), this.customerCode, this.profileGroup).toPromise();
		if (!response || !response.Success) return response;

		recurrenceTemplate.clearDirty();
		return response;
	}

	public async getTemplateByTemplateId(recurrenceTemplateId: string): Promise<RecurrenceTemplate> {
		const response = await this.rws.getRecurrenceTemplate(recurrenceTemplateId).toPromise();
		if (!response || !response.Success) return null;

		return RecurrenceTemplate.fromContract(response.Result);
	}

	public async updateRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplate): Promise<ContractResponseBase> {
		const response = await this.rws.updateRecurrenceTemplate(recurrenceTemplate.getContract(), this.customerCode).toPromise();
		if (!response || !response.Success) return response;

		recurrenceTemplate.clearDirty();
		return response;
	}

	public async removeRecurrenceTemplate(recurrenceTemplate: RecurrenceTemplate): Promise<ContractResponseBase> {
		const response = await this.rws.removeRecurrenceTemplate(recurrenceTemplate.getContract()).toPromise();
		if (!response || !response.Success) return response;

		recurrenceTemplate.clearDirty();
		return response;
	}
	//#endregion

	//#region Associated Assets CRUD
	public getAssociatedAssetsByTemplateId(recurrenceTemplateId: string): Promise<AssociatedAsset[]> {
		return new Promise<AssociatedAsset[]>((resolve, reject) => {
            resolve([]);
        });
	}

	public updateRecurrenceTemplateAssets(recurrenceTemplateId: string,
		assetsToAdd: AssociatedAsset[], assetsToRemove: AssociatedAsset[]): Promise<ContractResponseBase> {

		return this.rws
			.updateRecurrenceTemplateAssets(
				recurrenceTemplateId,
				assetsToAdd.map(a => a.getContract()),
				assetsToRemove.map(a => a.getContract())
			)
			.toPromise();
	}
	//#endregion
}
