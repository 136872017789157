<div class="scrolling-content">
	<mat-action-list class="menu-panel-settings">
		<mat-list-item class="dark-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.defaultSortField.omniName"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#defaultSortFieldTooltip="toggleTooltip"
				[matTooltipDisabled]="defaultSortFieldTooltip.isTooltipDisabled"
			>
				<span class="control-title ">default sort field</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="not set" [value]="settings.defaultSortField.omniName" />
				<button (click)="onGotoDefaultSortField()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div
				[matTooltip]="sortOrder"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#defaultSortOrderTooltip="toggleTooltip"
				[matTooltipDisabled]="defaultSortOrderTooltip.isTooltipDisabled"
			>
				<span class="control-title">default sort order</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="sortOrder" />
				<button (click)="toggleDefaultSortOrder()" mat-icon-button>
					<img [src]="defaultSortOrderIcon" />
				</button>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
