import { DataChannelService } from './../../../domain-service/data-channel.service';
import { NavigationService, Pages } from './../../navigation/navigation.service';
import { WorkOrderFieldProvider } from './../workorder-filter/workorder-field.provider';
import { MenuPanelBaseComponent } from './../../menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { WorkOrderFieldNames, Task, Employee, Status, CustomFieldCode, TaskWrapper, WorkOrderWrapper } from 'models/work-order';
import { WorkOrderFieldComponent } from '../work-order-field/workorder-field.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderSystem, Metric } from 'models';
import { UserService } from 'app/user/user.service';
import { WorkOrderTeam } from 'models/work-order/work-order-team.model';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { HMSRefreshOperation } from 'domain-service/metrics/hms-refresh-operation.enum';
import { CustomFieldWrapper } from 'models/work-order/custom-field-wrapper.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';

/** @type TaskFields, Array to hold Task-fields for UI render */
type TaskFields = Array<{
	header: string;
	value: any;
	className?: string;
	type: string;
	button: boolean;
	goTo: {};
	disabled: boolean;
	onChange: (newValue: any) => void;
	sequence?: number;
}>;

@Component({
	selector: 'app-workorder-task',
	templateUrl: './workorder-task.component.html',
	styleUrls: ['./workorder-task.component.scss']
})
export class WorkorderTaskComponent extends MenuPanelBaseComponent {
	@ViewChild('scrollingWrapper', { static: true }) scrollingWrapper: ElementRef;

	workOrderTask: TaskWrapper;

	assignedTo: Employee;

	status: Status;

	taskTemplate: Task;

	team: WorkOrderTeam;

	description: string;

	currentWorkOrder: WorkOrderWrapper;

	advancedMode: boolean;

	metric: Metric;

	selectedcustomField: CustomFieldWrapper;

	taskFields: TaskFields = [];

	fieldsLoaded = false;

	isReadOnly = false;

	@ViewChild('workorderTaskContainer', { static: true }) workorderTaskContainer: ElementRef;

	get isMaximo() {
		return this.workOrderFactory.workOrderSystem === WorkOrderSystem.Maximo;
	}

	get taskDescription() {
		if (!this.workOrderTask) return '';
		if (this.workOrderTask.description) return this.workOrderTask.description
		const taskTemplate = this.workOrderFactory.workOrderMetaData.tasks.find(task => task.taskId === this.workOrderTask.taskKey);
		if (!taskTemplate || !taskTemplate.description) return 'unknown task type';
		return taskTemplate.description
	}

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private workOrderFactory: WorkOrderFactory,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		private dataChannelService: DataChannelService,
		private interop: OmniInteropService,
		private userService: UserService
	) {
		super(view);
	}

	private setTaskFields() {
		const completedCodes = this.dataChannelService.getCompletedWorkOrderValues();
		this.taskFields.push(
			{ header: 'assigned to', value: this.assignedTo ? this.assignedTo.name : '', type: 'text', goTo: this.goToWorkTaskAssignTo, button: !this.isReadOnly, disabled: true, onChange: newValue => {} },
			{ header: 'team', value: this.team ? this.team.teamName : '', type: 'text', goTo: this.goToWorkTaskTeam, button: !this.isReadOnly, disabled: true, onChange: newValue => {} },
			{ header: 'status', value: this.status ? this.status.description : '', type: 'text', goTo: this.goToWorkTaskStatus, button: !this.isReadOnly, disabled: true, onChange: newValue => {} }
		);
		this.taskTemplate?.taskCustomFields.forEach(customField => {
			if (this.findCustomFieldInWorkTask(customField)) {
				this.taskFields.push({
					header: customField.customFieldName,
					value: this.findCustomFieldInWorkTask(customField).customFieldValue,
					type: this.customeFieldFreeTextType(customField),
					goTo: () => {
						this.customFieldSelected(customField);
					},
					disabled: this.isReadOnly || this.showGoToButton(customField),
					button: !this.isReadOnly && this.showGoToButton(customField),
					onChange: newValue => {
						const customFieldInTask = this.findCustomFieldInWorkTask(customField);
						if (this.customeFieldFreeTextType(customField) === 'date') customFieldInTask.customFieldValue = this.convertDate(newValue);
						else customFieldInTask.customFieldValue = newValue;
					},
					sequence: customField.sequence
				});
			}
		});
		this.taskFields.push({
			header: 'completed date',
			value: this.workOrderTask.completedDate,
			type: 'date',
			disabled: this.isReadOnly || !completedCodes.includes(this.status?.statusCode),
			button: false,
			goTo: null,
			onChange: newValue => {
				this.workOrderTask.completedDate = newValue ? DateUtil.getTimeZoneAgnosticString(new Date(newValue)) : '';
			}
		});

		this.taskFields.push({
			header: 'comments',
			value: this.workOrderTask.comments,
			type: 'text',
			disabled: false,
			button: false,
			goTo: null,
			onChange: newValue => {
				this.workOrderTask.comments = newValue;
			}
		});

		this.sortTaskFieldsOnSequence(this.taskFields);

		this.taskFields.forEach((field, i) => {
			field.className = this.getBackgroundColorForIndex(i);
		});

		this.menuPanelComponent.updateView({ title: this.currentWorkOrder.workOrderKey, backgroundClass: 'orange-background', showPacifier: false });
		this.fieldsLoaded = true;
	}

	/** Method to sort (self mutation) the taskFields array, based on sequence number */
	private sortTaskFieldsOnSequence(tasksFields: TaskFields) {
		tasksFields.sort((aField, bField) => (
			(!aField.sequence || !bField.sequence) ? 0 : aField.sequence - bField.sequence
		));
	}

	async onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;

		this.currentWorkOrder = args.parameter.workOrder;
		this.advancedMode = args.parameter.advancedMode;
		this.metric = args.parameter.metric;
		this.workOrderTask = args.parameter.workTask;

		this.isReadOnly = this.workOrderFactory.isWorkOrderReadOnly(this.currentWorkOrder);
		this.menuPanelComponent.updateView({
			title: this.currentWorkOrder.workOrderKey,
			backgroundClass: 'orange-background',
			showPacifier: true
		});

		this.taskTemplate = this.workOrderFactory.workOrderMetaData.tasks.find(task => task.taskId === this.workOrderTask.taskKey);
		const employeeFound = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(this.workOrderTask.assignedTo);
		const currentUser = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(this.userService.currentUser.userName);
		if (employeeFound) {
			this.assignedTo = employeeFound;
		} else if (!this.isReadOnly) {
			this.assignedTo = currentUser;
			setTimeout(() => (this.workOrderTask.assignedTo = this.userService.currentUser.userName));
		}

		const statusFound = this.workOrderFactory.workOrderMetaData.statuses.getByStatusCode(this.workOrderTask.status);
		this.status = statusFound ? statusFound : null;
		const team = this.workOrderFactory.workOrderMetaData.workorderTeams.getByTeamId(this.workOrderTask.teamId);
		this.team = team ? team : null;
		this.setTaskFields();
	}

	onScroll() {
		if (!this.uiState) this.uiState = { scrollPosition: 0 };
		this.uiState.scrollPosition = this.scrollingWrapper.nativeElement.scrollTop;
	}

	ngAfterViewInit() {
		if (this.uiState && this.uiState.scrollPosition) this.scrollingWrapper.nativeElement.scroll(0, this.uiState.scrollPosition);
	}

	convertDate(date: string) {
		return DateUtil.convertLocaleString(date);
	}

	getBackgroundColorForIndex(index: number) {
		return index % 2 === 0 ? 'orange-background' : 'light-gray-background';
	}

	onBackPressed = async () => {
		if (this.workOrderTask.isDirty) this.workOrderTask.undoAll();
		if (this.workOrderTask.customFields.isDirty()) this.workOrderTask.customFields.undoAll();
	};

	/**
	 * This function return true for disabling the input false other wise
	 */
	shouldDisableCustomerField = (customField: CustomFieldWrapper) => {
		// If its not VARCHAR, return true for disable
		if (customField.customFieldType !== 'VARCHAR') return true;
		// If its VARCHAR, but codeType is not NULL and not undefined, return true for disable
		if (customField.codeType !== null && customField.codeType !== undefined) return true;
		// IS VARCHAR and (null or undefined)
		return false;
	};

	goToWorkTaskAssignTo = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkTaskAssignTo;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.assignedTo],
			selectedItemKey: 'name',
			workOrder: this.currentWorkOrder,
			workTask: this.workOrderTask,
			advancedMode: true
		});
	};

	goToWorkTaskStatus = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkTaskStatus;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.status],
			selectedItemKey: 'description',
			workOrder: this.currentWorkOrder,
			workTask: this.workOrderTask,
			advancedMode: true
		});
	};

	goToWorkTaskTeam = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkTaskTeam;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.team],
			selectedItemKey: 'teamName',
			workOrder: this.currentWorkOrder,
			workTask: this.workOrderTask,
			advancedMode: true
		});
	};

	/**
	 * This function assigns the custom field value to the customer field resolver
	 * @param {CustomField} field - a custome field of a wo
	 */
	customFieldSelected = (field: CustomFieldWrapper) => {
		const customFieldFoundInWorkTask = this.findCustomFieldInWorkTask(field);
		const customFieldCode = this.showGoToButton(field) ? this.getCustomFieldCode(field) : field.customFieldValue;
		WorkOrderFieldComponent.customFieldResolver = () => customFieldFoundInWorkTask;
		if (!field.customFieldType.toLowerCase().includes('date')) {
			return NavigationService.navigateTo(Pages.workorderField, {
				selectedItems: [customFieldCode],
				selectedItemKey: 'description',
				workOrder: this.currentWorkOrder,
				workTask: this.workOrderTask,
				advancedMode: true
			});
		}
	};

	private findCustomFieldInWorkTask(field: CustomFieldWrapper): CustomFieldWrapper {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.TaskCustomFields;
		return this.workOrderTask.customFields.find(field1 => field1.customFieldId === field.customFieldId);
	}

	/**
	 * This function return true if custome field is selectable
	 */
	showGoToButton = (customField: CustomFieldWrapper) => {
		if (customField.customFieldType === 'VARCHAR' && customField.codeType) return true;
		return false;
	};

	customeFieldFreeTextType = (customField: CustomFieldWrapper) => {
		if (customField.customFieldType === 'NUMERIC') return 'number';
		if (customField.customFieldType.toLowerCase().includes('date')) return 'date';
		return 'text';
	};

	getCustomFieldCode(customField: CustomFieldWrapper): CustomFieldCode {
		const customFieldInWorktask = this.workOrderTask.customFields.find(field => field.customFieldId === customField.customFieldId);
		const customFieldFromWoTemplate = this.taskTemplate?.taskCustomFields.find(taskCustomField => taskCustomField.customFieldId === customField.customFieldId);
		if (!customFieldFromWoTemplate || !customFieldInWorktask) return null;
		const customFieldCodeFound = this.workOrderFactory.workOrderMetaData.customFieldCodes.find(
			cField => cField.code === customFieldInWorktask.customFieldValue && cField.codeType === customFieldFromWoTemplate.codeType
		);
		return customFieldCodeFound;
	}

	getCustomFieldCodeValue(customField: CustomFieldWrapper) {
		const customFieldCode = this.getCustomFieldCode(customField);
		return customFieldCode ? customFieldCode.description : '';
	}

	async onSave() {
		if (this.isReadOnly) return;

		const completedCodes = this.dataChannelService.getCompletedWorkOrderValues().join(',');
		if (this.workOrderTask.completedDate && !completedCodes.includes(this.workOrderTask.status)) {
			this.alertDialog.mainMessage = { text: 'Need completed status.' };
			this.alertDialog.open = true;
			return;
		}
		if (completedCodes.includes(this.workOrderTask.status) && !this.workOrderTask.completedDate) this.workOrderTask.completedDate = new Date().toISOString();
		const saveResult = await this.workOrderFactory.updateWorkTask(this.currentWorkOrder, this.workOrderTask);
		if (saveResult) {
			this.workOrderFactory.sendWorkOrderUpdateNotification(this.currentWorkOrder.workOrderKey);
			await this.interop.metricManager.refreshWorkOrderMetrics([this.currentWorkOrder.workOrderKey], HMSRefreshOperation.Update); // fire and forget workorder update
			await NavigationService.navigateBackTo();
		}
	}

	async onDelete() {
		if (this.isReadOnly) return;
		await this.workOrderFactory.deleteWorkTask(this.currentWorkOrder, this.workOrderTask);
		await NavigationService.navigateBackTo();
	}
}
