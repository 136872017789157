import { HMSRefreshOperation } from './hms-refresh-operation.enum';

export enum AssetMetricType {
	Asset = 'Asset',
	History = 'History',
}

/**
 * This defines the model for the OMNI OMNI hms to call refresh asset metric.
 */
export class RefreshAssetMetric {
	/**
	 * Metric type
	 */
	metricType: AssetMetricType;
	/**
	 * Name of the map
	 */
	assetType: string;
	/**
	 * asset id of asset
	 */
	assetId: string;
	/**
	 * asset id field
	 */
	assetIdField?: string;
	/**
	 * HMS operation for add, move and update
	 */
	operation: HMSRefreshOperation;
}
