import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import * as SedaruUtils from '../../../sedaru-util';
import { AdvancedWorkOrder } from 'models/work-order';
import { SedaruClientHub } from '../../../sedaru-util/sedaru-subscriptions-library';

export class CreateWorkOrderResult extends Subscriptions.MethodHandlerResult<CreateWorkOrderHandler> {
	workOrderKey: string;
	newWorkOrderKey: string;
	systemId: string;
	customerCode: string;

	constructor(handler: CreateWorkOrderHandler) {
		super(handler);
	}
}

export class CreateWorkOrderHandler extends Subscriptions.SedaruClientMethodHandlerBase {
	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLocaleLowerCase() === 'smartconnect.onnewworkorderprocessed';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		return new Promise<Subscriptions.MethodHandlerResultBase>((resolve, reject) => {
			const originalData = {
				Success: true,
				Message: '',
				OldWorderKey: '', // the one you sent up
				NewWorderKey: '', // the one provided by Maximo or CW
				SystemId: '' //
			};
			console.log(packet);
			const data = JSON.parse(packet.data);

			const result = new CreateWorkOrderResult(this);
			if (!data || data.Success === undefined) {
				result.message = 'payload was undefined';
				resolve(result);
				return;
			}

			if (data.Result) {
				result.workOrderKey = data.Result.OldWorderKey;
				result.newWorkOrderKey = data.Result.NewWorderKey;
				result.systemId = data.Result.SystemId ? data.Result.SystemId : result.newWorkOrderKey;
			}

			result.success = data.Success;

			if (!data.Success) {
				result.message = data.Message && data.Message.length ? data.Message :
					'Something unexpected happened while communicating with the work order system.<br />Please wait a moment and try again.';
				resolve(result);
				return;
			}

			// more code here

			args.isHandled = true;
			resolve(result);
			return;
		});
	}

	onGetUniqueId(): string {
		return 'CreateWorkOrderHandler'; // do not user Class.name because it doesn't work with minified code.
	}

	sendAdvancedWorkOrderMessage(advancedWorkOrder: AdvancedWorkOrder, customerCode: string) {
		const data = { WorkOrder: advancedWorkOrder.getContractMaximo() };
		// 'SedaruConnect.CloseWorkOrder'
		console.log(data);
		console.log(JSON.stringify(data));
		this.hub.sendNotification('SmartConnect.SaveWorkOrder', JSON.stringify(data), customerCode, SedaruClientHub.sedaruConnectAppName);
	}
}
