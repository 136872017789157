import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { MonthlyPattern } from './monthly-pattern.model';
import { OmniModel } from '../omni-model';

export class YearlyPattern extends OmniModel<YearlyPattern, Contracts.YearlyPatternContract> {
    monthsList = [
        { key: 1, name: 'January' },
        { key: 2, name: 'February' },
        { key: 3, name: 'March' },
        { key: 4, name: 'April' },
        { key: 5, name: 'May' },
        { key: 6, name: 'June' },
        { key: 7, name: 'July' },
        { key: 8, name: 'August' },
        { key: 9, name: 'September' },
        { key: 10, name: 'October' },
        { key: 11, name: 'November' },
        { key: 12, name: 'December' },
    ];

    get monthString(): string {
        return this.monthsList.find(m => m.key === this.month).name;
    }

    private _month: SedaruUtils.Undoable<number>;
    get month(): number {
        if (!this._month) this._month = new SedaruUtils.Undoable<number>();
        return this._month.value;
    }
    set month(value: number) {
        if (!this._month) {
            this._month = new SedaruUtils.Undoable<number>(value);
            return;
        }
        this._month.value = value;
    }

    private _monthlyPattern: MonthlyPattern;
    get monthlyPattern(): MonthlyPattern {
        return this._monthlyPattern ? this._monthlyPattern : (this._monthlyPattern = new MonthlyPattern());
    }
    set monthlyPattern(value: MonthlyPattern) {
        if (this._monthlyPattern === value) return;
        this._monthlyPattern = value;
    }

    static fromContract(contract: Contracts.YearlyPatternContract): YearlyPattern {
        const model = new YearlyPattern();
        model.month = contract.month;
        if (contract.monthlyPattern) model.monthlyPattern = MonthlyPattern.fromContract(contract.monthlyPattern);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.YearlyPatternContract {
        const contract = new Contracts.YearlyPatternContract();
        contract.month = this.month;
        contract.monthlyPattern = this.monthlyPattern.getContract();
        return contract;
    }

    onUpdateInformation(copyModel: YearlyPattern): boolean {
        let wasChanged = false;
        wasChanged =
			this.updateIfNotDirty<number>(this._month, () => {
				this.month = copyModel.month;
			}) || wasChanged;

        wasChanged = this.monthlyPattern.updateInformation(copyModel.monthlyPattern) || wasChanged;

        return wasChanged;
    }
}
