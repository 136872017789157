import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

export class WorkOrderTeam extends OmniModel<WorkOrderTeam, Contracts.WorkOrderTeamContract> {
	/**
	 * object id for employee
	 */
	private _id: SedaruUtils.Undoable<number>;
	get id(): number {
		if (!this._id) this._id = new SedaruUtils.Undoable<number>();
		return this._id.value;
	}
	set id(value: number) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._id.value = value;
	}

	private _teamId: SedaruUtils.Undoable<string>;
	get teamId(): string {
		if (!this._teamId) this._teamId = new SedaruUtils.Undoable<string>();
		return this._teamId.value;
	}
	set teamId(value: string) {
		if (!this._teamId) {
			this._teamId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._teamId.value = value;
	}

	private _teamName: SedaruUtils.Undoable<string>;
	get teamName(): string {
		if (!this._teamName) this._teamName = new SedaruUtils.Undoable<string>();
		return this._teamName.value;
	}
	set teamName(value: string) {
		if (!this._teamName) {
			this._teamName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._teamName.value = value;
	}

	private _employeeIds: string[];
	get employeeIds(): string[] {
		return this._employeeIds;
	}
	set employeeIds(employeeIds: string[]) {
		this._employeeIds = employeeIds;
	}

	static fromContract(contract: Contracts.WorkOrderTeamContract): WorkOrderTeam {
		const model = new WorkOrderTeam();
		model.id = contract.ObjectId;
		model.teamId = contract.TeamId;
		model.teamName = contract.TeamName;
		model.employeeIds = contract.employeeids;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.WorkOrderTeamContract {
		const contract = new Contracts.WorkOrderTeamContract();
		contract.ObjectId = this.id;
		contract.TeamId = this.teamId;
		contract.TeamName = this.teamName;
		contract.employeeids = this.employeeIds;
		return contract;
	}

	onUpdateInformation(copyModel: WorkOrderTeam): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<number>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._teamId, () => {
				this.teamId = copyModel.teamId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._teamName, () => {
				this.teamName = copyModel.teamName;
			}) || wasChanged;
		return wasChanged;
	}
}
