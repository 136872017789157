import { Component, ElementRef } from '@angular/core';
import { FormResolver } from '../form-resolvers/dynamic-form.resolver';
import { FormDefinition, AssetDefinition, ControlType, ContextType, ArcGISHistoryChannelAttributes, Metric, ArcGISAssetChannelAttributes, ChannelTypes, FormControlInputType } from 'models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { RecordContextGroup } from 'models/records/record-context-group';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Canvas } from 'omni-model/canvas.model';
import { FieldType, Feature } from 'sedaru-util/esri-core';
import * as moment from 'moment';
import { CanvasTrendService } from 'app/canvas-container/canvas-trend/canvas-trend.service';
import { HistoryRecord } from 'models/records/history-record.model';
import { AssetRecord } from 'models/records/asset-record.model';
import { ArcGisFeature } from 'app/metric/arc-gis-feature.model';
import { DatePipe } from '@angular/common';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { FormGridPattern } from '../form-resolvers/form-alternating-pattern';
import { MenuPanelFieldValueType } from 'app/ui-components/menu-panel-field/menu-panel-field-value-type.enum';
import { DateUtil } from 'sedaru-util';

@Component({
	selector: 'app-history-records-form',
	templateUrl: './history-records-form.component.html',
	styleUrls: ['./history-records-form.component.scss']
})
export class HistoryRecordsFormComponent extends MenuPanelBaseComponent {
	formResolver: FormResolver;
	formDefinition: FormDefinition;
	context: RecordContextGroup;
	tab: string;
	highlightParams: { objectid: number; canvas: Canvas; layerId: string; assetAttribute: string; historyAttribute: string };
	trendableRecordCount: number;
	metric: Metric;
	attachments: any[] = [];
	histroyFormHeight: string;
	activeControlsList: any[] = [];
	get pageIdentifier(): Pages {
		return Pages.historyRecordsForm;
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.loadArgs(args);
	}
	onPageReload(args: NavigationArgs) {
		this.loadArgs(args);
	}
	private async loadArgs(args: NavigationArgs) {
		this.metric = args.parameter['metric'];
		this.context = args.parameter['context'] ?? (await this.getContext(args.parameter.incompleteRecord));
		this.formDefinition = args.parameter['historyFormDefinition'] ?? this.getHistoryFormDefinition();
		if (!this.formDefinition) {
			console.error('No form definition found for ', this.context.historyRecord.workType);
			NavigationService.navigateTo(Pages.mapAssetAttributes, { feature: this.context.historyRecord.feature });
		}
		this.highlightParams = args.parameter['highlightParameters'];
		this.trendableRecordCount = args.parameter['trendableRecordCount'];
		this.tab = args.parameter['tab'];
		this.load();
	}

	constructor(private omniInterop: OmniInteropService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	ngOnInit() {}

	private async getContext(incompleteRecord: any) {
		if (!this.metric) return;
		const context = new RecordContextGroup();
		const { assetQuery, historyQuery } = this.getLayerQueries(incompleteRecord.feature);
		const assetLayer = this.omniInterop.arcGISManager.getMapAssetLayer(this.metric.definition.source.assetType);
		const geometry = incompleteRecord.feature.geometry;
		const historyLayer = this.omniInterop.arcGISManager.getHistoryLayer(this.metric.definition.source.assetType);
		const assetFeatureJSON = assetLayer ? await assetLayer.query(assetQuery, '*', true) : null;
		const historyFeatureJSON = historyQuery ? await historyLayer.query(historyQuery, '*', true) : null;
		const assetDefinition = this.omniInterop.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(this.metric.definition.source.assetType);
		const assetFeature = assetFeatureJSON && assetFeatureJSON.length ? Feature.fromEsriFeature(assetFeatureJSON[0]) : null;
		if (assetFeature) assetFeature.geometry = geometry;
		const historyFeature = historyFeatureJSON && historyFeatureJSON.length ? Feature.fromEsriFeature(historyFeatureJSON[0]) : null;
		if (this.metric.definition.source.type === ChannelTypes.History) context.historyRecord = HistoryRecord.create(assetDefinition, historyFeature);
		context.assetRecord = AssetRecord.create(assetDefinition, assetFeature);
		return context;
	}

	private getLayerQueries(featureSelected: ArcGisFeature): { assetQuery: string; historyQuery: string } {
		let assetQuery, historyQuery, id;
		const assetFieldName = this.metric.definition.assetChannel?.attributes['uniqueFieldName'];
		if (this.metric.definition.source.type === ChannelTypes.History) {
			const historyFieldName = this.metric.definition.historyChannel.attributes['assetIdFieldName'];
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLocaleLowerCase().includes(historyFieldName.toLowerCase()));
			id = featureSelected.attributes[normalizedFieldName];
			historyQuery = historyFieldName + ' = ' + "'" + id + "'";
		} else {
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLowerCase().includes(assetFieldName));
			id = featureSelected.attributes[normalizedFieldName];
		}
		assetQuery = assetFieldName ? `${assetFieldName} = '${id}'` : null;
		return { assetQuery, historyQuery };
	}

	private load() {
		let badgeNumber;
		let disableRightButton;
		if (this.context.historyRecord.assetId) {
			badgeNumber = 1
			disableRightButton = false;
		} else {
			badgeNumber = '';
			disableRightButton = true;
		}
		this.menuPanelComponent.updateView({
			title: this.metric?.definition?.name,
			rightIcon: {url: 'assets/maplocation.png', toolTip: '', callBackFunction: this.goToHistoryAsset.bind(this) },
			badgeNumber: badgeNumber,
			disableRightButton: disableRightButton
		});

		this.formResolver = this.createHistoryRecordsFormResolver(this.context.assetDefinition, this.context);
		this.getAttachments();
	}

	async getAttachments() {
		const assetLayer = this.omniInterop.arcGISManager.getMapAssetLayer(this.context?.assetDefinition?.assetType);
		const attachmentList: any = assetLayer && this.context?.assetRecord ? await assetLayer.getAttachmentList(this.context.assetRecord.objectId) : null;
		const controlGroups = this.formDefinition.pages[0].controlGroups;
		controlGroups.forEach((group: any) => {
			const activeControls = [];
			group.controls.forEach(control => {
				if (control.input && control.input.type === FormControlInputType.Picture) {
					const attachmentId = this.context.historyRecord.feature.getAttribute(control.defaultFieldName);
					this.hydrateAttachments(attachmentList, control, attachmentId);
				} else {
					activeControls.push(control);
				}
			});
			this.activeControlsList.push({title: group.title, controls: activeControls});
		})
		let countOfRows = 0;
		let countOfHeaders = 1;
		for (let i = 0; i < this.activeControlsList.length; i++) {
			const grid = new FormGridPattern(this.activeControlsList[i].controls);
			if (this.activeControlsList[i].title) countOfHeaders++;
			countOfRows += grid.rows.length;
		}
		this.histroyFormHeight = countOfHeaders * 20 + countOfRows * 55 + 30 + 'px';
	}

	hydrateAttachments(attachmentList, control, id) {
		if (!attachmentList) return;
		const attachmentObj = attachmentList.find((attachment) => {
			return attachment.id === id;
		});

		if (!attachmentObj) return;
		const extension = attachmentObj.name.split('.').pop();
		this.attachments.push({
			thumbnailPath: attachmentObj.url,
			uploadTimeStamp: null,
			attachmentName: control.title + `.${extension}`,
			imagePath: attachmentObj.url,
			fileIndexId: attachmentObj.id,
		});
	}

	goToHistoryAsset() {
		NavigationService.navigateTo(Pages.historyRecordAssetList, {context: this.context, metric: this.metric});
	}

	getHistoryFormDefinition() {
		const historyRecord = this.context.historyRecord;
		const assetDefinition = this.context.assetDefinition;
		const forms = assetDefinition.forms.map(form => form.title);
		return assetDefinition.forms.find(form => form.workType.toLowerCase() === historyRecord.workType.toLowerCase());
	}

	createHistoryRecordsFormResolver(assetDefinition: AssetDefinition, contextGroup: RecordContextGroup): FormResolver {
		const assetType = this.context?.assetDefinition?.assetType;
		const assetLayer = this.omniInterop.arcGISManager.getMapAssetLayer(assetType);
		const historyLayer = this.omniInterop.arcGISManager.getHistoryLayer(assetType);
		const historyChannelAttributes = assetDefinition.historyChannel.attributes as ArcGISHistoryChannelAttributes;
		const formResolver = new FormResolver(assetDefinition);
		formResolver.showFormTitle = () => {
			return true;
		};
		formResolver.getFormTitle = form => {
			return form.title;
		};
		formResolver.getControlGroupHeader = group => {
			return group.title;
		};
		formResolver.getControlHeader = control => {
			return control.title;
		};
		formResolver.getControlPlaceholder = control => {
			return '';
		};
		formResolver.getControlValue = control => {
			if (control.value.text) {
				return control.value.text;
			}

			let returnValue = '';
			let sourceLayer = null;
			const defaultControlFieldName = control.defaultFieldName;
			const historyRecord = contextGroup.historyRecord;
			if (control.input?.list?.fieldName) {
				const controlFieldName = control.input.list.fieldName.toLowerCase();
				const layerIndex = control.input.list.layerIndex;
				if (layerIndex == assetLayer.id) {
					sourceLayer = assetLayer;
				} else if (layerIndex == historyLayer.id) {
					sourceLayer = historyLayer;
				}
				const codedDomainValue = historyRecord.feature.getAttribute(defaultControlFieldName);
				if (sourceLayer && (codedDomainValue !== null || codedDomainValue !== undefined)) {
					const field = sourceLayer.fields.find(f => f.name.toLowerCase() === controlFieldName);
					const codedValues = field.domain.codedValues;
					const codedDomainDesc = codedValues.find(item => item.code === codedDomainValue);
					if (codedDomainDesc) return codedDomainDesc.name;
				}

				return returnValue;
			}

			if (historyRecord && defaultControlFieldName) {
				returnValue = historyRecord.feature.getAttribute(defaultControlFieldName);

				const defaultControlFieldLowerCase = defaultControlFieldName.toLowerCase();
				const field = historyLayer.fields.find(f => f.name.toLowerCase() === defaultControlFieldLowerCase);
				if (field?.type === FieldType.esriFieldTypeDate) {
					returnValue = DateUtil.convertLocaleUTCStringFormatted(returnValue);
				}
			}

			if (!returnValue) return '';

			return returnValue;
		};
		formResolver.getControlWidth = control => {
			if (control.controlType === ControlType.Control1x1) return MenuPanelFieldSize.half;
			return MenuPanelFieldSize.full;
		};
		formResolver.getControlHeight = () => {
			return MenuPanelFieldSize.half;
		};
		formResolver.getFooterFieldBackground = control => {
			if (control.styleId && control.style?.layout?.background.color) return control.style.layout.background.color;
			return null;
		};
		formResolver.getFooterTextColor = control => {
			return control.style.layout.foreground.color;
		};
		formResolver.disableFormControls = () => {
			return true;
		};
		formResolver.showTrendIcon = control => {
			if (!historyLayer || !control.defaultFieldName) {
				return false;
			}

			const field = historyLayer.fields.find(f => f.name.toLowerCase() === control.defaultFieldName);
			if (!field) {
				// TODO Analytics: invalid history field
				return false;
			}

			if (
				(!field.name.toLowerCase().includes('attachment') && field.type === FieldType.esriFieldTypeInteger) ||
				field.type === FieldType.esriFieldTypeDouble ||
				field.type === FieldType.esriFieldTypeSingle
			) {
				return true;
			}

			return false;
		};
		formResolver.enableTrendIcon = control => {
			return false;
			if (formResolver.showTrendIcon(control) && this.trendableRecordCount > 3) return true;
			return false;
		};
		formResolver.trendIconSelected = control => {
			if (!historyLayer || !control.defaultFieldName) {
				return;
			}

			const field = historyLayer.fields.find(f => f.name.toLowerCase() === control.defaultFieldName);
			if (!field) {
				// TODO Analytics: invalid history field
				return;
			}

			if (field.type === FieldType.esriFieldTypeInteger || field.type === FieldType.esriFieldTypeDouble || field.type === FieldType.esriFieldTypeSingle) {
				// execute trend

				const trendDefinition = {
					assetIdFieldName: historyChannelAttributes.assetIdFieldName,
					assetId: contextGroup.historyRecord.assetId,
					dateFieldName: historyChannelAttributes.completedDateFieldName,
					historyLayer: historyLayer,
					defaultFieldName: control.defaultFieldName,
					dateStartValue: moment()
						.startOf('day')
						.subtract(historyChannelAttributes.assetHistoryDays, 'days')
						.format('MM/DD/YYYY HH:mm:ss'),
					dateEndValue: moment()
						.endOf('day')
						.format('MM/DD/YYYY HH:mm:ss'),
					trendLabel:
						assetDefinition.title +
						contextGroup.historyRecord.assetId +
						' ' +
						control.title +
						' (' +
						moment()
							.subtract(historyChannelAttributes.assetHistoryDays, 'days')
							.format('MM/DD/YYYY') +
						' through today)',
					trendBackgroundColor: control.style.layout.background.color.toHex()
				};

				// this.trendService.trendHistoryField(trendDefinition);

				return;
			}

			return;
		};

		formResolver.getControlValueType = control => MenuPanelFieldValueType.STRING;

		return formResolver;
	}

	editForm() {}
}
