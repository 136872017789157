import { ChannelAttributes } from './channel-attributes.model';
import { WorkOrderModeType } from './work-order-mode-type.enum';
import { HourlyRates } from './sedaru-config/hourly-rate.model';
import { LEMMode } from './sedaru-config/lem-mode.enum';
import { WorkOrderSystem } from './sedaru-config/work-order-system.enum';

/**
 * This defines the work order attributes
 */
export class WorkOrderChannelAttributes extends ChannelAttributes {

	/**
	 * holds the work order mode
	 */
	workOrderMode: WorkOrderModeType;

	get lemMode(): LEMMode {
		return LEMMode.None;
	}

	workOrderSystem: WorkOrderSystem = WorkOrderSystem.None;

	private _activeWorkOrderValues: Array<string>;
	get activeWorkOrderValues(): Array<string> {
		if (!this._activeWorkOrderValues) this._activeWorkOrderValues = new Array<string>();

		return this._activeWorkOrderValues;
	}

	private _completedWorkOrderValues: Array<string>;
	get completedWorkOrderValues(): Array<string> {
		if (!this._completedWorkOrderValues) this._completedWorkOrderValues = new Array<string>();

		return this._completedWorkOrderValues;
	}

	private _readOnlyWorkOrderValues: Array<string>;
	get readOnlyWorkOrderValues(): Array<string> {
		if (!this._readOnlyWorkOrderValues) this._readOnlyWorkOrderValues = new Array<string>();

		return this._readOnlyWorkOrderValues;
	}

	private _hourlyRates: HourlyRates;
	get hourlyRates(): HourlyRates {
		if (!this._hourlyRates) this._hourlyRates = new HourlyRates();

		return this._hourlyRates;
	}

	getIdFieldName(): string {
		return 'workOrderKey';
	}

}
