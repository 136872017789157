import { WorkOrderContract } from './work-order-contract';
import { CustomFieldContract } from './custom-field-contract';
import { WorkAssetContract } from './work-asset-contract';
import { EmployeeCostContract } from './employee-cost-contract';
import { MaterialCostContract } from './material-cost-contract';
import { EquipmentCostContract } from './equipment-cost-contract';
import { WorkTaskContract } from './work-task-contract';
import { WorkInspectionContract } from './work-inspection-contract';
import { AttachmentContract } from './attachment-contract';
import { WorkCommentContract } from './work-comment-contract';
import { VendorCostContract } from './vendor-cost-contract';
import { FailureReportContract } from './failure-report-contract';

export class WorkOrderRawContract {
	WorkOrder: WorkOrderContract;
	CustomFields: CustomFieldContract[];
	WorkAssets: WorkAssetContract[];
	EmployeeCosts: EmployeeCostContract[];
	MaterialCosts: MaterialCostContract[];
	EquipmentCosts: EquipmentCostContract[];
	WorkTasks: WorkTaskContract[];
	WorkInspections: WorkInspectionContract[];
	Attachments: AttachmentContract[];
	WorkComments: WorkCommentContract[];
	VendorCosts: VendorCostContract[];
	FailureResults: FailureReportContract[];
}
