import { WorkOrderFilterField } from './work-order-filter-field.model';
import { WorkOrderFilterFieldContract, WorkOrderFilterContract } from '../../contracts/work-order';
import { WorkOrderFilterFieldKey } from '../../app/workorder/workorder-filter/workorder-filter-templates';
import { Operator } from '../../models/query-operator.enum';
import { CodedValue } from '../../models/arc-gis-field.model';

export class WorkOrderFilterJSON {
	filters: BasicDataFiltersJSON;
	customFields: CustomFieldsFilterJSON;
}

export class WorkOrderQueryJSON {
	workOrder?: WorkOrderFilterJSON;
	tasks?: any;
	labor?: any;
	equipment?: any;
	material?: any;
	vendors?: any;
}
export class CustomFieldFilterCondition {
	index: number;
	operator: Operator;
	value: CodedValue;
	join: Operator;
	type: string;
	constructor(index: number, type: string) {
		this.index = index;
		this.type = type;
	}
}

export class CustomFieldFilterContract {
	index: number;
	name: string;
	customFieldId: string;
	conditions: CustomFieldFilterCondition[];
}

export class CustomFieldFilter extends CustomFieldFilterContract {
	constructor(index: number) {
		super();
		this.index = index;
	}

	static fromContract(contract: CustomFieldFilter) {
		const filter = new CustomFieldFilter(contract.index);
		filter.name = contract.name;
		filter.customFieldId = contract.customFieldId;
		filter.conditions = contract.conditions;
		return filter;
	}

	deepCopy = (customFieldFilter: CustomFieldFilter) => {
		this.name = customFieldFilter.name;
		this.customFieldId = customFieldFilter.customFieldId;
		this.conditions = JSON.parse(JSON.stringify(customFieldFilter.conditions));
	};

	getContract = () => {
		const contract = new CustomFieldFilterContract();
		contract.index = this.index;
		contract.name = this.name;
		contract.customFieldId = this.customFieldId;
		contract.conditions = this.conditions;
		return contract;
	};
}

class FilterConditionJSON {
	comparisonOperator: string;
	type: string;
}

class CustomFieldFilterJSON {
	customFieldId: string;
	conditions: CustomeFieldFilterConditionJSON[] = [];
}

export class BasicDataFilterJSON {
	fieldIdentifier: string;
	logicalOperator: string = null;
	conditions: BasicDataFilterConditionJSON[] = [];
}

export class BasicDataFiltersJSON extends Array<BasicDataFilterJSON> {}

export class CustomFieldsFilterJSON extends Array<CustomFieldFilterJSON> {}

class CustomeFieldFilterConditionJSON extends FilterConditionJSON {
	logicalOperator: string = null;
	value: string;
}

export class BasicDataFilterConditionJSON extends FilterConditionJSON {
	values: string[];
}

export class CustomFieldFilters extends Array<CustomFieldFilter> {
	getJSON = () => {
		const filtersJSON = new CustomFieldsFilterJSON();
		for (const customeFieldFilter of this) {
			const filterJSON = new CustomFieldFilterJSON();
			filterJSON.customFieldId = customeFieldFilter.customFieldId;
			for (let i = 0; i < customeFieldFilter.conditions.length; i++) {
				const conditionJSON = new CustomeFieldFilterConditionJSON();
				const { operator, value, type } = customeFieldFilter.conditions[i];
				conditionJSON.comparisonOperator = operator.value;
				conditionJSON.value = value.code;
				// This is only for the case of calculated priortiy
				conditionJSON.type = type.toLowerCase() == 'readonly' ? 'NUMERIC' : type;
				if (i > 0) {
					conditionJSON.logicalOperator = customeFieldFilter.conditions[i - 1].join.value;
				}
				filterJSON.conditions.push(conditionJSON);
			}
			filtersJSON.push(filterJSON);
		}
		return filtersJSON;
	};

	getContracts = () => {
		const contract = new Array<CustomFieldFilterContract>();
		this.forEach(filter => contract.push(filter.getContract()));
		return contract;
	};

	fromPojo = (customFieldsFilterPojo: any) => {
		for (const customFieldFilterPojo of customFieldsFilterPojo) {
			const customFieldFilter = new CustomFieldFilter(customFieldFilterPojo._index);
			customFieldFilter.name = customFieldFilterPojo._name;
			customFieldFilter.conditions = customFieldFilterPojo._conditions;
			customFieldFilter.customFieldId = customFieldFilterPojo._customFieldId;
			this.push(customFieldFilterPojo);
		}
	}
}

export class WorkOrderFilterFields extends Array<WorkOrderFilterField> {
	static fromContracts(workOrderFilterFieldContracts: WorkOrderFilterFieldContract[]) {
		const workOrderFilter = new WorkOrderFilterFields();
		for (const workOrderFilterFieldContract of workOrderFilterFieldContracts) {
			const workOrderFilterField = WorkOrderFilterField.fromContract(workOrderFilterFieldContract);
			workOrderFilter.push(workOrderFilterField);
		}
		return workOrderFilter;
	}

	clearDirty = () => this.forEach(f => f.clearDirty());

	undoAll = () => this.forEach(f => f.undoAll());

	getContracts = () => {
		const workOrderFilterContracts = new Array<WorkOrderFilterFieldContract>();
		for (const workOrderFilterField of this) {
			const workOrderFilterContract = workOrderFilterField.getContract();
			workOrderFilterContracts.push(workOrderFilterContract);
		}
		return workOrderFilterContracts;
	};

	update = (templateFilter: WorkOrderFilterFields) => {
		const tempFilter = new WorkOrderFilterFields();
		for (const templateFiled of templateFilter) {
			const newField = new WorkOrderFilterField(templateFiled.index, templateFiled.name, templateFiled.key);
			const found = this.find(field => field.key == templateFiled.key);
			if (found) newField.value = found.value;
			tempFilter.push(newField);
		}
		this.length = 0;
		for (const tempField of tempFilter) {
			const newField = new WorkOrderFilterField(tempField.index, tempField.name, tempField.key);
			newField.value = tempField.value;
			this.push(newField);
		}
	};

	getField = (templateField: WorkOrderFilterField) => {
		const fieldFound = this.find(field => field.key === templateField.key);
		if (fieldFound) return fieldFound;
		const newField = new WorkOrderFilterField(templateField.index, templateField.name, templateField.key);
		this.push(newField);
		return newField;
	};

	getJSON = () => {
		const filtersJSON = new BasicDataFiltersJSON();
		for (const filter of this) {
			if (filter.value == '') continue;
			filtersJSON.push(filter.getJSON());
		}
		if (filtersJSON.length) filtersJSON[0].logicalOperator = null;
		return filtersJSON;
	};

	fromPojo = (workOrderFilterPojo: any) => {
		for (const workOrderFilterFieldPojo of workOrderFilterPojo) {
			const workOrderFilterField = new WorkOrderFilterField(
				workOrderFilterFieldPojo._index,
				workOrderFilterFieldPojo._name,
				workOrderFilterFieldPojo._key
			);
			workOrderFilterField.value = workOrderFilterFieldPojo._value._initialValue;
			this.push(workOrderFilterField);
		}
	};

	getKeyValue = (): WorkOrderFilterContract => {
		const filter: { [key: string]: string } = {};
		for (const workOrderFilterField of this) {
			filter[workOrderFilterField.key] = workOrderFilterField.value;
		}
		return filter;
	};

	getCostTypeCSVValue = () => this[8].value;

	getStandardWorkOrderQuery = () => {
		const workOrderQuerys: string[] = [];
		let hasFromDate = false;
		let hasToDate = false;
		for (let i = 0; i < this.length; i++) {
			if (this[i].value === '') continue;

			if (this[i].key === WorkOrderFilterFieldKey.CostCategory) continue;

			if (this[i].key === WorkOrderFilterFieldKey.FromDate) {
				hasFromDate = true;
				continue;
			}

			if (this[i].key === WorkOrderFilterFieldKey.ToDate) {
				hasToDate = true;
				continue;
			}

			// Leverage standard workorder to support multi select.
			const multiValue = this[i].value.split(',');

			workOrderQuerys.push(this.multiValueQuery(this[i].key, multiValue));
		}
		if (hasFromDate && hasToDate) {
			// fromDate is index 4
			// toDate is index 5
			const createDateQueryString = `createddate >= '${new Date(this[4].value).toUTCString()}' AND createddate <= '${new Date(this[5].value).toUTCString()}'`;
			workOrderQuerys.push(createDateQueryString);
		}
		return workOrderQuerys.join(' AND ');
	};

	private multiValueQuery = (key: string, multiValue: string[]) => {
		const queryList = [];
		const isStartOrCompletedDate: boolean = key === WorkOrderFilterFieldKey.StartDate || key === WorkOrderFilterFieldKey.CompletedDate;
		for (const value of multiValue) {
			if (key === WorkOrderFilterFieldKey.Priority) {
				queryList.push(`${key} = ${value.trim()}`);
			} else if (isStartOrCompletedDate) {
				queryList.push(`${key} = '${new Date(value.trim()).toUTCString()}'`);
			} else {
				queryList.push(encodeURIComponent(`LOWER(${key}) LIKE '%${value.trim().toLowerCase()}%'`));
			}
		}
		return `(${queryList.join(' OR ')})`;
	};
}
