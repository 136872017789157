import { PacifierResolver } from '../pacifier-resolver.base';
import { PacifierType } from '../pacifier-types.enum';
import { Color } from 'models';
export const sedaruValues = [
	'#BeMoreTogether',
	'Be Better Than Yesterday',
	'Never Settle',
	'Inspire Next',
	'Build Tomorrow Passionately',
	'Do Awesome Things',
	'Commit to Commitment',
	'Care More, Always',
	'Always Bring You',
	'Learn from Everyone',
	'Invest in Team',
	'Bring Joy',
	'Value Different',
	'Disrupt Norms',
	'Make it Right',
	'Believe. Pursue the Impossible',
	'Own it!',
	'Work Smart. Work Hard.'
];

export class StartingPacifierResolver extends PacifierResolver {
	type = PacifierType.starting;
	pulseSpeed = 3;
	spinningSpeed = 1;
	spinnerColor = Color.createFromRGBA('rgb(114, 174, 47)');
	spinnerWidth = 10;
	spinDirection: 'clockwise' | 'counterclockwise';
	pulseIconUrl = 'assets/sedaru-logo.png';
	textArray = sedaruValues;
	updateTextEvery = 4;
	initialText = 'OMNI is loading...';
	fontSize = 20;
}
