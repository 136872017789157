import { MetricTile, ChannelTypes, Metric } from '../../models';
import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChange, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { fadeInAndOutOnHover } from 'app/app.animations';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { KeyValue } from '@angular/common';
import { TileService } from './tile.service';
import { GuiConfig } from '../../omni-model/gui-config.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MetricRequesterService } from 'app/metric/metric-requester.service';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { TileComponent } from './tile/tile.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MetricResultsComponent } from 'app/metric/metric-results/metric-results.component';
import { CanvasMode } from 'omni-model/canvas.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { FlashMessageService } from 'app/flash-message/flash-message.service';

/**
 * This component was designed to be placed above the canvas in the OMNI app.
 * It can either have 6 tiles, 12 tiles or 0 tiles in it.
 */
@Component({
	selector: 'app-tile-list',
	templateUrl: './tile-list.component.html',
	styleUrls: ['./tile-list.component.scss'],
	animations: [fadeInAndOutOnHover]
})
export class TileListComponent implements OnInit, OnChanges {
	/**
	 * We are wrapping the #tiles native element into the ElementRef class that
	 * is just a wrapper class for native elements.
	 */
	@ViewChild('tiles', { static: true }) public tileListElement: ElementRef;

	private _tileComponents: QueryList<TileComponent>;
	get tileComponents() {
		return this._tileComponents;
	}
	@ViewChildren(TileComponent) set tileComponents(tiles: QueryList<TileComponent>) {
		if (!tiles) return;
		this._tileComponents = tiles;
		this.setTileFunctions();
	}
	/**
	 * the output event triggered when a tile is selected
	 */
	onTileSelected: (tile: TileComponent) => Promise<boolean>;

	onTileClickedWhenActive: (tile: TileComponent) => void;

	onTileTimeframeChanged: (tile: TileComponent) => void;

	/**
	 * Holds an array of the list of tiles present in the currently selected tab.
	 */
	tileList: MetricTile[];
	/** the layout number: 6, 12 or 0 */
	@Input() tilesLayout: number;

	@Input() activeGuiConfig: GuiConfig;

	@Input() mode: string;

	private _initialized = false;
	get initialized() {
		return this._initialized;
	}

	/**
	 * We need to show the add button on the tile only when hovered over the tile. This boolean helps to control this
	 * operation.
	 */
	hideAddButton = false;

	/**
	 * Holds the height of the tile list component which changes dynamically based on the number of tiles selected.
	 */
	rowHeight = '0px';

	/**
	 * Total number of columns to be displayed on the tile list grid. The number of columns is 2 when in mobile view and
	 * 6 when in normal desktop view.
	 */
	cols = 6;

	/**
	 * Holds the gutterSize for the tile list grid. This varies based on whether the component is loaded on a mobile device
	 * or desktop.
	 */
	gutterSize = '3px';

	/**
	 * Holds the margin height for the tile list for mobile device.
	 */
	tileListMargin = '0';

	isDoubleClickEvent: boolean;

	/**
	 * Allows the GuiConfigService, Router, BreakpointObserver and ScrollToViewService dependencied to be injected into the class
	 * @param {ScrollToViewService} scrollingService - This registers the view which is to be scrolled in when a user hits
	 * something, say a tile, on a mobile device.
	 */
	constructor(private scrollingService: ScrollToViewService, private tileService: TileService, private metricRequest: MetricRequesterService, private flashService: FlashMessageService) { }

	/**
	 * On init, the tiles are fetched from the config of the selected tab.
	 */
	ngOnInit() {
		this.scrollingService.tileList = this.tileListElement;
	}

	ngAfterViewInit() { }

	setTileFunctions(): void {
		this.tileComponents.forEach(tileComponent => {
			tileComponent.onTileClick = tile => {
				this.highlightTile(tile);
				return this.onTileSelected(tileComponent);
			};
			tileComponent.onTileClickWhenActive = tile => {
				this.highlightTile(tile);
				this.onTileClickedWhenActive(tile);
			};
			tileComponent.metricSubscriberChanged = tile => {
				this.onTileTimeframeChanged(tile);
			};
			tileComponent.onSettingsClick = t => this.showTileSettings(t.tile);
			tileComponent.onDateFrameClick = t => this.goToTimeframe(t.tile);
			tileComponent.onTileDrag = t => this.tileDragged(t);
			tileComponent.onTileDrop = t => this.tileDraggedEnd(t);
		});
	}

	/** when the config tilesLayout or tileList changes */
	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		for (const propertyName in changes) {
			if (propertyName === 'tilesLayout') {
				this.tileList = [];
				for (let i = 0; i < this.tilesLayout; i++) {
					if (this.activeGuiConfig.tileList[i]) {
						this.tileList[i] = this.activeGuiConfig.tileList[i];
					} else {
						const tile = new MetricTile();
						tile.tilePosition = i;
						this.tileList[i] = tile;
					}
				}
				this.setTileListHeight(this.tilesLayout);
			} else if (propertyName === 'mode') {
				if (this.mode === 'phone') {
					this.cols = 2;
					this.gutterSize = '5px';
					this.tileListMargin = '0 5px';
				} else {
					this.cols = 6;
					this.gutterSize = '3px';
					this.tileListMargin = '0 0 3px 0';
				}
			}
		}
	}

	/**
	 * This method sets the row height of the tile list component based the number of tiles selected.
	 * @param {number} count - Holds the total number of tiles in the currently selected tab.
	 */
	setTileListHeight(count: number): void {
		switch (count) {
			case 0:
				this.rowHeight = '0px';
				break;
			default:
				this.rowHeight = '91px';
		}
	}

	/**
	 * This method is called when the gear icon on a tile is clicked. This will take us to the tile settings side panel.
	 * @param {MetricTile} tile - Holds the currently selected tile.
	 * @param {Event} event - Holds the click event on the tile.
	 */
	showTileSettings(tile: MetricTile, event?: Event): void {
		if (event) {
			event.stopPropagation();
		}
		this.activeGuiConfig.selectedTile = tile;
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});

		NavigationService.navigateTo(Pages.tileSettings, { currentTile: tile });
	}

	/**
	 * This method is called when the '+' icon on a tile is clicked. This will take us to the list of available metrics.
	 * @param {MetricTile} tile - Holds the currently selected tile.
	 */
	onGoToMetricList(tile: MetricTile) {
		this.activeGuiConfig.selectedTile = tile;
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		this.metricRequest.setRequester('MetricTile', tile);
		NavigationService.navigateTo(Pages.metrics, { showRemoveOption: false });
	}

	/**
	 * This method is invoked when we click on a tile for which a metric has been populated.
	 * @param {MetricTile} tile - Holds the currently selected tile
	 */
	highlightTile(tileComponent: TileComponent) {
		this.tileComponents.forEach(t => (t.tile.isSelected = false));
		const tile = tileComponent?.tile;
		this.activeGuiConfig.selectedTile = tile;
		if (!tileComponent) return;
		tile.isSelected = true;
		if (
			this.activeGuiConfig.selectedCanvas.mode === CanvasMode.trend &&
			tile.metric.definition.source.type !== ChannelTypes.History &&
			tile.metric.definition.source.type !== ChannelTypes.WorkOrder
		) {
			tileComponent.showWarning = 'This metric is not trendable';
		}
	}

	/**
	 * ngFor sorts keyvalue pairs using the object keys as string values, and thus '10' and '11' go before '2'.
	 * To avoid that we pass a custom sort function to the ngFor loop parsing the keys as numbers.
	 */
	sort = (a: KeyValue<number, MetricTile>, b: KeyValue<number, MetricTile>): number => {
		return +a.key > +b.key ? 1 : +b.key > +a.key ? -1 : 0;
	};

	/**
	 * Returns true if the passed tile is the same selected tile in the selected config
	 */
	isSelectedTile(tile: MetricTile): boolean {
		if (!this.activeGuiConfig.selectedTile) return false;
		return this.activeGuiConfig.selectedTile.tilePosition === tile.tilePosition;
	}

	tileDragged(tileComponent: TileComponent) {
		const existingTab = this.activeGuiConfig.selectedCanvas.tabs.some(tab => tab.metric.id === tileComponent.tile.metric.id && !tab.defaultMetric);
		if (this.activeGuiConfig.selectedCanvas.tabs.length >= 5 && !existingTab) this.flashService.popMessage('maximum allowable canvas limit reached');
		if (
			this.activeGuiConfig.selectedCanvas.mode === CanvasMode.trend &&
			tileComponent.tile.metric.definition.source.type !== ChannelTypes.History &&
			tileComponent.tile.metric.definition.source.type !== ChannelTypes.WorkOrder
		) {
			tileComponent.showWarning = 'This metric is not trendable';
		}

		this.tileService.tileDragged = tileComponent;
	}

	tileDraggedEnd(tileComponent: TileComponent) {
		this.highlightTile(tileComponent);
	}

	getTileBorder(tile: MetricTile) {
		const color = tile.metric ? tile.backgroundColor : 'rgba(255, 255, 255, 0.1)';
		if (this.isSelectedTile(tile)) {
			return {
				border: '3px solid rgba(0, 254, 254, 0.7)',
				'border-radius': 0,
				'background-color': color
			};
		}
		if (!tile.metric) return { border: '1px dotted #999999' };

		return { border: '3px solid ' + color, 'background-color': color };
	}

	metricUpdated(metric: Metric) {
		this.tileComponents.forEach(tileComponent => { });
	}

	goToTimeframe(tile: MetricTile) {
		this.activeGuiConfig.selectedTile = tile;
		NavigationService.navigateTo(Pages.timeframefilter, { tile: tile, timeFrameType: 'tile' });
	}

	onMetricDefinitionChanged(metricId: string) {
		this.tileComponents.forEach(t => t.onMetricDefinitionChanged(metricId));
	}
}
