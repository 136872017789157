import { ContractBase } from '../contract-base';

export class StandardMaterialCostContract extends ContractBase {
	public objectid: number;
	public MaterialId: string;
	public Units: number;
	public UnitCost: number;
	public DateStamp: string;
	public WorkOrderKey: string;
	public TotalCost: number;
	public WorkTaskId: string;
	public CreatedBy: string;
	public Description: string;
}
