export class ProfileGroup {
	id: number;
	profileName: string;
	icon: string;
	modifiedBy: string;
	modifiedDate: string;
	parentId: number;
	description: string;
	status: string;
    isSelected: boolean;
    static fromContract(profilegroupData) {
        const profileGroup = new ProfileGroup();
		profileGroup.id = profilegroupData.Id;
		profileGroup.profileName = profilegroupData.ProfileName;
        profileGroup.icon = profilegroupData.Icon;
        profileGroup.modifiedBy = profilegroupData.ModifiedBy;
        profileGroup.modifiedDate = profilegroupData.ModifiedDate;
        profileGroup.parentId = profilegroupData.ParentId;
        profileGroup.description = profilegroupData.Description;
        profileGroup.status = profilegroupData.Status;
        return profileGroup;
    }
}
