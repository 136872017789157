import { FeatureLayer } from '../../../sedaru-util/esri-core';

export class TrendDefinition {
    assetIdFieldName: string;
    assetId: string | number;
    dateFieldName: string;
    historyLayer: FeatureLayer;
    defaultFieldName: string;
    dateStartValue: string;
    dateEndValue: string;
    trendLabel?: string;
    trendBackgroundColor?: string;
}
