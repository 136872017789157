export enum FieldType {
	esriFieldTypeInteger = 'esriFieldTypeInteger',
	esriFieldTypeSmallInteger = 'esriFieldTypeSmallInteger',
	esriFieldTypeDouble = 'esriFieldTypeDouble',
	esriFieldTypeSingle = 'esriFieldTypeSingle',
	esriFieldTypeString = 'esriFieldTypeString',
	esriFieldTypeDate = 'esriFieldTypeDate',
	esriFieldTypeGeometry = 'esriFieldTypeGeometry',
	esriFieldTypeOID = 'esriFieldTypeOID',
	esriFieldTypeBlob = 'esriFieldTypeBlob',
	esriFieldTypeGlobalID = 'esriFieldTypeGlobalID',
	esriFieldTypeRaster = 'esriFieldTypeRaster',
	esriFieldTypeGUID = 'esriFieldTypeGUID',
	esriFieldTypeXML = 'esriFieldTypeXML',
	DateTime = 'DateTime',
	string = 'string',
	none = ''
}
