export class TimeframeQueryValue {
	/**
	 * The name of the value
	 */
	name: any;

	/**
	 * The code reprsentation of the  value
	 */
	code?: any;

	value?: any;

	date?: Date;

	startDate?: string;

	endDate?: string
}

