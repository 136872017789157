<div class="scrolling-content">
	<mat-card class="tab-visibility-option" matRipple [matRippleColor]="'rgba(0,254,254, 0.3)'" (click)="setVisibility('Myself')" [class.disabled]="disableMyTabOption" [class.cyan]="isMyTab">
		<mat-card-content>just me</mat-card-content>
		<mat-card-header>
			<img src="assets/me.png" mat-card-avatar />
			<mat-card-subtitle>only you will be able to see this tab and any future updates to it</mat-card-subtitle>
		</mat-card-header>
	</mat-card>
	<mat-divider class="tab-visibility-divider"></mat-divider>

	<mat-card class="tab-visibility-option" matRipple [matRippleColor]="'rgba(0,254,254, 0.3)'" [class.disabled]="true" (click)="setVisibility('Team')" [class.cyan]="isTeamTab">
		<mat-card-content>team</mat-card-content>
		<mat-card-header>
			<img src="assets/team.png" mat-card-avatar />
			<mat-card-subtitle>
				users in this team view will see this tab and any future updates to it
			</mat-card-subtitle>
		</mat-card-header>
	</mat-card>
	<mat-divider class="tab-visibility-divider"></mat-divider>

	<mat-card
		class="tab-visibility-option"
		matRipple
		[matRippleColor]="'rgba(0,254,254, 0.3)'"
		[class.disabled]="disableAllUserTabOption"
		(click)="setVisibility('Public')"
		[class.cyan]="isAllUserTab"
	>
		<mat-card-content>all users</mat-card-content>
		<mat-card-header>
			<img src="assets/people.png" mat-card-avatar />
			<mat-card-subtitle>all Sedaru users will see this tab and any future updates to it</mat-card-subtitle>
		</mat-card-header>
	</mat-card>
	<mat-divider class="tab-visibility-divider"></mat-divider>
</div>
