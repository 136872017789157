import { GeometryService } from './geometry.service';
import { NavigationService, Pages } from './../../navigation/navigation.service';
import { Injectable, EventEmitter } from '@angular/core';
import esri = __esri; // Esri TypeScript Types
import { MapViewInput } from './map-view-input.model';
import { BaseMapTypes } from './base-map-types.model';
import { EsriSdkService } from '../canvas-map/esri-sdk.service';
import { Canvas } from '../../../omni-model/canvas.model';
import { MapGraphic } from './metric-map-graphic.model';
import { Subject } from 'rxjs';
import { MapGeomertyType } from './geometry-type.model';
import { DataSource } from 'models/data-source.model';
import { ArcGISFeature, BaseLayer, Color, Metric, MetricTile } from 'models';
import { GuiConfigService } from 'domain-service/gui-config.service';
import { IdentifyTaskResult } from './identify-task-result.model';
import { LayerLegend } from 'omni-model/canvas-map.model';
import AssetIcon from 'models/asset-icon.model';
import { Feature } from 'sedaru-util/esri-core';
import { PNGManager } from 'app/ui-components/png-manager';
import { RecordContextGroup } from 'models/records/record-context-group';
import { UserService } from 'app/user/user.service';
import { BasemapVisibleLayer } from 'omni-model/basemap-visible-layer.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkAssetWrappers, WorkOrderWrapper, WorkOrderWrappers } from 'models/work-order';
import { TimeframedMetricSubscriber } from 'models/time-frame/timeframed-metric-subscriber';

/**
 * The OMNI Map Service will be used to manage communication to the API server about the ESRI Map.
 */
@Injectable({
	providedIn: 'root'
})
export class CanvasMapService {
	/**
	 * The ESRI map that once loaded can be uitlized to update the view.
	 */
	availableDataSources: { [index: string]: DataSource } = {};

	/**
	 * ESRI Map SDK is a custom "Namespace".  It holds the different ESRI classes that require loading.
	 * Once load is complete the classes can be utilized to modify and visualize the map.
	 */
	EsriMapSdk;

	/**
	 * The extent of the map image layer, this will zoom the map back to proper extent after a graphic layer is deleted.
	 * Since each container could have a different map image layer,
	 * we can cache its extent as a one to one relationship to a related canvas
	 */
	mapImageLayerExtent: { [index: string]: esri.Extent } = {};

	/**
	 * This holds a list of graphic layer list
	 */
	mapGraphicLayerList: { [index: string]: MapGraphic[] } = {};

	/**
	 * This holds a list of identified assets from graphics layer
	 */
	identifyResultAssets: IdentifyTaskResult[] = [];
	/**
	 * selected identify asset
	 */
	selectedIdentifyAsset: any;

	/**
	 * The event subject lets the emit when the map extent changes
	 */
	mapExtentChange$ = new Subject();

	/**
	 * The event Observer lets the subscribe when the map extent changes
	 */
	mapExtentChangeObservable$ = this.mapExtentChange$.asObservable();

	/**
	 * the data source that was last clicked
	 *   */
	set activeDataSource(ds: DataSource) {
		this.workOrderFactory.activeDataSource = ds;
	}
	get activeDataSource() {
		return this.workOrderFactory.activeDataSource;
	}

	featureLayerClient = {};

	/**
	 * The constructor loads dependencies but also initilizes the map.  The promise is stored in the "ready" property.
	 * @param {Apollo} apollo - Used for connecting graphql with api-server
	 * @param {UserService} userService - Used to fetching user data
	 */
	constructor(
		private esriSdkService: EsriSdkService,
		private userService: UserService,
		private guiConfigService: GuiConfigService,
		private workOrderFactory: WorkOrderFactory,
		private geometryService: GeometryService
	) {
		if (this.esriSdkService.esriMapSdk) {
			this.EsriMapSdk = this.esriSdkService.esriMapSdk;
		} else {
			this.esriSdkService.esriSdkReadyEvent.subscribe(() => {
				this.EsriMapSdk = this.esriSdkService.esriMapSdk;
			});
		}
	}

	/**
	 * This method will load the map when data sources are fetched from our data base.
	 * @param {MapViewInput} mapViewInputProperties -holds the map view properties for creating new map instance. eg - baseMap and
	 * 		mapType
	 */
	async loadMapIntoView(mapViewInputProperties: MapViewInput) {
		const mapImageLayers = mapViewInputProperties.baseVisibleLayers.map((baseLayer: BasemapVisibleLayer) => {
			const mapPros: any = {
				url: baseLayer.url,
				opacity: baseLayer.transparency / 100,
				baseLayerLegacyId: 1,
				name: baseLayer.name,
				isBaseLayer: true,
				visible: baseLayer.visible
			};

			if (baseLayer.subLayers && baseLayer.subLayers.length) {
				mapPros.sublayers = baseLayer.subLayers;
			}

			if (baseLayer.layerType == 'TileLayer') return new this.EsriMapSdk.TileLayer(mapPros);

			return new this.EsriMapSdk.MapImageLayer(mapPros);
		});

		// We load the map with no base layer to have the map image layer be the first one we load
		const esriMap: esri.Map = new this.EsriMapSdk.Map();
		esriMap.addMany(mapImageLayers);

		mapImageLayers.forEach(async mapImageLayer => {
			await mapImageLayer.load();
		});

		const dsUrl = this.availableDataSources[mapViewInputProperties.mapName].url;

		const selectedMapImageLayer = new this.EsriMapSdk.MapImageLayer({
			url: dsUrl
		});

		selectedMapImageLayer.on('layerview-create-error', event => {
			console.error('LayerView failed to create for layer with the id: ', selectedMapImageLayer.id, ' in this view: ', event.view);
		});

		// We load the map with no base layer to have the map image layer be the first one we load
		// const esriMap: esri.Map = new this.EsriMapSdk.Map();

		esriMap.add(selectedMapImageLayer);

		const mapViewProperties: esri.MapViewProperties = {
			container: mapViewInputProperties.mapElement,
			map: esriMap,
			highlightOptions: {
				color: '#00FFFF',
				haloOpacity: 0.5,
				fillOpacity: 0.5
			}
		};

		const newMapView: esri.MapView = new this.EsriMapSdk.MapView(mapViewProperties);

		newMapView.constraints = {
			minZoom: 0
			// maxZoom: 19
		};

		// We load the base map here, once the map image layer has been loaded first
		const baseMap: esri.Basemap = (mapViewInputProperties.baseMapType)
			? this.EsriMapSdk.BaseMap.fromId(mapViewInputProperties.baseMapType)
			: this.EsriMapSdk.BaseMap.fromId('topo-vector');
		esriMap.basemap = baseMap;

		await selectedMapImageLayer.load();

		const subLayers = selectedMapImageLayer.sublayers.toArray();
		const visibleLayerIds = mapViewInputProperties.visibleLayers?.map(l => l.id);
		if (visibleLayerIds) {
			subLayers.forEach(element => {
				element.visible = visibleLayerIds.includes(element.id);
			});
		}

		newMapView.extent = await this.obtainMapViewFromProjection(selectedMapImageLayer);

		// Saving the mapImageLayer's extent so that if a graphic layer that was visible is deleted from cache,
		// then we can reset the zoom level to mapImageLayer's extent.
		this.mapImageLayerExtent[mapViewInputProperties.canvasId] = newMapView.extent;

		return newMapView;
	}

	/**
	 * Usese the projection to obtain the proper extent for the new map view.  It also pushes the new map view into
	 *  this.mapViews object.
	 * @param {esri.MapImageLayer} loadedMapImageLayer - Hash map of Map Image Layers
	 * @returns {esri.Extent} - The extent of the loadedMapImageLayer, used to correctly zoom and center in the view
	 */
	private obtainMapViewFromProjection(loadedMapImageLayer: esri.MapImageLayer): Promise<esri.Extent> {
		return new Promise<esri.Extent>(async resolve => {
			const projection = this.EsriMapSdk.Projection;
			await projection.load();
			const outerSpatialReference = new this.EsriMapSdk.MapGeometrySpatialReference({
				// Each projected and geographic coordinate system is defined by a well-known ID (WKID)
				// https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-SpatialReference.html#wkid
				wkid: 3857
			});
			const geogtrans = projection.getTransformation(loadedMapImageLayer.spatialReference, outerSpatialReference, loadedMapImageLayer.fullExtent);
			const project = projection.project(loadedMapImageLayer.fullExtent, outerSpatialReference, geogtrans);

			if (!project) {
				resolve(null);
				return;
			}

			resolve(project.extent);
		});
	}

	/**
	 * This method takes an array of map geometry and converts it to a set of point geometries.
	 * @param {any} arcGisFeatures - An array arcGisFeatures
	 * @param {string} iconUrl - the icon url
	 * @returns {esri.Graphic[]} - Returns a promise that returns a list of Esri graphic
	 */
	async getPointGraphics(featuresJSON: ArcGISFeature[], iconUrl: string, color?: Color): Promise<esri.Graphic[]> {
		if (color) {
			iconUrl = await PNGManager.editImage(iconUrl, { backgroundColor: color });
		}
		const symbol = { type: 'picture-marker', url: iconUrl, width: '30px', height: '30px' };
		const pointGraphicsArray: esri.Graphic[] = [];
		for (const feature of featuresJSON) {
			if (!feature.geometry) continue;
			const geometry = typeof feature.geometry === 'string' ? JSON.parse(feature.geometry) : feature.geometry;
			const { x, y } = this.geometryService.getPointGeometry(geometry);
			const attributes = typeof feature.attributes === 'string' ? JSON.parse(feature.attributes) : feature.attributes;
			pointGraphicsArray.push(this.createGraphic(x, y, symbol, attributes));
		}
		return pointGraphicsArray;
	}

	/**
	 * this function gets the poing graphic for work orders
	 * @param {AdvancedWorkOrders} workOrders - all the work orders
	 * @param {string} iconUrl - the icon url
	 * @returns {esri.Graphic[]} - Returns a promise that returns a list of Esri graphic
	 */
	async getWorkOrderPointGraphic(workOrders: WorkOrderWrappers, iconUrl: string, color?: Color) {
		if (color) {
			iconUrl = await PNGManager.editImage(iconUrl, { backgroundColor: color });
		}
		const symbol = { type: 'picture-marker', url: iconUrl, width: '30px', height: '30px' };
		const pointGraphicArray: esri.Graphic[] = [];
		for (const { workOrderKey, xCoordinate, yCoordinate } of workOrders) {
			if (!xCoordinate || !yCoordinate) continue;
			pointGraphicArray.push(this.createGraphic(xCoordinate, yCoordinate, symbol, { workOrderKey, isParent: true }));
		}

		return pointGraphicArray;
	}

	getWorkAssetPointGraphic(workAssets: WorkAssetWrappers, isVisibleGraphic: boolean) {
		const pointGraphics: esri.Graphic[] = [];
		for (const { geometry, assetKey: assetkey, assetId, assetType, workOrderKey } of workAssets) {
			if (!geometry) continue;
			const iconUrl = AssetIcon.getAssetIconUrl(assetType);
			const { x, y } = this.geometryService.getPointGeometry(geometry);
			const symbol = { type: 'picture-marker', url: iconUrl, width: '30px', height: '30px' };
			pointGraphics.push(this.createGraphic(x, y, symbol, { workOrderKey, assetkey, assetType, assetId, isParent: false }, isVisibleGraphic));
		}
		return pointGraphics;
	}

	/**
	 * This function creates a graphic
	 * @param {number} longitude - longitude, or x
	 * @param {number} latitude - latitude, or y
	 * @param {string} iconUrl - icon url
	 * @param {any} attributes - the attibutes that we can nest inside the graphic icon
	 */
	createGraphic(longitude: number, latitude: number, symbol: any, attributes: any, visible: boolean = true) {
		const geometry = { type: 'point', longitude, latitude };
		const graphic: esri.Graphic = new this.EsriMapSdk.Graphic({ geometry, symbol, attributes, visible });
		return graphic;
	}

	/**
	 * updates the map symbols
	 * @param {string} url - URL to changed in the symbol
	 */
	updateSymbols(layerId: string, url: string) {
		Object.values(this.guiConfigService.availableConfigurations).forEach(config => {
			if (!config.isSelected) return;
			for (const canvas of config.canvasList) {
				if (!canvas.mapView) continue;

				canvas.mapView.map.layers.forEach(layer => {
					if (layer.type !== 'feature') return;

					const featureLayer = layer as esri.FeatureLayer;
					if (featureLayer.id === layerId) {
						const markerSymbol = new this.EsriMapSdk.PictureMarkerSymbol();
						markerSymbol.url = url;
						markerSymbol.width = '30px';
						markerSymbol.height = '30px';
						const simpleRenderer = new this.EsriMapSdk.SimpleRenderer();
						simpleRenderer.symbol = markerSymbol;
						featureLayer.renderer = simpleRenderer;
					}
				});
			}
		});
	}

	getWOPointGeometry(x, y) {
		return new this.EsriMapSdk.Point({ latitude: y, longitude: x });
	}

	/**
	 * List of ESRI base map types which were used for OMNI
	 */
	baseMapESRIList(): BaseMapTypes[] {
		return [
			{
				baseMapIdentifier: 'streets-vector',
				baseMapLabel: 'streets vector',
				thumbnailImg: 'streets.png'
			},
			{
				baseMapIdentifier: 'streets-night-vector',
				baseMapLabel: 'streets night vector',
				thumbnailImg: 'streets-night-vector.png'
			},
			{
				baseMapIdentifier: 'streets-navigation-vector',
				baseMapLabel: 'streets navigation vector',
				thumbnailImg: 'street-navigation-vector.png'
			},
			{
				baseMapIdentifier: 'streets-relief-vector',
				baseMapLabel: 'streets relief vector',
				thumbnailImg: 'street-relief-vector.png'
			},
			{
				baseMapIdentifier: 'gray-vector',
				baseMapLabel: 'gray vector',
				thumbnailImg: 'gray-vector.png'
			},
			{
				baseMapIdentifier: 'dark-gray-vector',
				baseMapLabel: 'dark gray vector',
				thumbnailImg: 'dark-gray-vector.png'
			},
			{
				baseMapIdentifier: 'topo-vector',
				baseMapLabel: 'topo map v2',
				thumbnailImg: 'topo-map-v2.png'
			},
			{
				baseMapIdentifier: 'satellite',
				baseMapLabel: 'arcgis imagery',
				thumbnailImg: 'arcgis-imagery.png'
			},
			{
				baseMapIdentifier: 'streets',
				baseMapLabel: 'streets',
				thumbnailImg: 'streets.png'
			},
			{
				baseMapIdentifier: 'terrain',
				baseMapLabel: 'terrain',
				thumbnailImg: 'terrain.png'
			},
			{
				baseMapIdentifier: 'national-geographic',
				baseMapLabel: 'national-geographic',
				thumbnailImg: 'national-geographic.png'
			},
			{
				baseMapIdentifier: 'oceans',
				baseMapLabel: 'oceans',
				thumbnailImg: 'oceans.png'
			},
			{
				baseMapIdentifier: 'osm',
				baseMapLabel: 'osm',
				thumbnailImg: 'osm.png'
			},
			{
				baseMapIdentifier: 'hybrid',
				baseMapLabel: 'arcgis imagery labels',
				thumbnailImg: 'arcgis-imagery-labels.png'
			},
			{
				baseMapIdentifier: '',
				baseMapLabel: 'no base-map',
				thumbnailImg: null
			}
		];
	}

	highlightAssets(canvas: Canvas, identifyTaskResults: Feature[], layerId = 'highlight-assets', color?: Color): Promise<esri.Graphic[]> {
		return new Promise(solve => {
			if (!canvas.mapView) {
				solve(null);
				return;
			}
			const hexColor = color ? color.toHex() : '#00FFFF';
			const highlightGrahicList: esri.Graphic[] = identifyTaskResults.map(identifyResult => {
				return this.generateGraphic(identifyResult, hexColor);
			});

			if (!highlightGrahicList.length) {
				solve(null);
				return;
			}
			const highlightLayer: esri.GraphicsLayer = this.getLayerByLayerId(layerId, canvas.mapView.map);
			highlightLayer.opacity = 0.5;
			this.addGraphicsToGraphicLayer(
				highlightLayer,
				highlightGrahicList.filter(i => i !== undefined)
			);
			canvas.mapView.map.layers.reorder(highlightLayer, canvas.mapView.map.layers.length - 1);
			solve(highlightGrahicList);
		});
	}

	/**
	 * generates the grahic object for the given geometry
	 * @param {IdentifyTaskResult} identifyResult
	 * @param {string} color - hexa color code to be highlight the grahic object
	 * @returns {esri.Graphic} - Returns a the Esri graphic
	 */
	generateGraphic(identifyResult: Feature, color: string = '#00FFFF') {
		if (!identifyResult.attributes) return;

		if (!identifyResult.geometry) return;

		const geometry = identifyResult.geometry;

		let symbol;
		if (geometry.type === MapGeomertyType.POINT || geometry.type === MapGeomertyType.MULTIPOINT) {
			symbol = new this.EsriMapSdk.SimpleMarkerSymbol();
			symbol.outline = { style: 'none', width: 0 };
			symbol.color = new this.EsriMapSdk.Color(color);
			symbol.size = 20;
		} else if (geometry.type === MapGeomertyType.POLYGON || geometry.type === MapGeomertyType.POLYLINE) {
			symbol = new this.EsriMapSdk.SimpleLineSymbol();
			symbol.color = new this.EsriMapSdk.Color(color);
			symbol.width = 5;
		}

		const graphic: esri.Graphic = new this.EsriMapSdk.Graphic({
			geometry: geometry,
			symbol: symbol
		});

		graphic.setAttribute('id', identifyResult.objectId);

		return graphic;
	}

	generateGraphicByGeometry(geometry, id, color: string = '#00FFFF') {
		let symbol;
		if (geometry.type === MapGeomertyType.POINT || geometry.type === MapGeomertyType.MULTIPOINT) {
			symbol = new this.EsriMapSdk.SimpleMarkerSymbol();
			symbol.outline = { style: 'none', width: 0 };
			symbol.color = new this.EsriMapSdk.Color(color);
			symbol.size = 20;
		} else if (geometry.type === MapGeomertyType.POLYGON || geometry.type === MapGeomertyType.POLYLINE) {
			symbol = new this.EsriMapSdk.SimpleLineSymbol();
			symbol.color = new this.EsriMapSdk.Color(color);
			symbol.width = 5;
		}

		const graphic: esri.Graphic = new this.EsriMapSdk.Graphic({
			geometry: geometry,
			symbol: symbol
		});

		graphic.setAttribute('id', id);

		return graphic;
	}

	/**
	 * this function filters and returns the grahics layer by layer id
	 * @param {string} layerId - unique name for a graphic layer
	 * @param {esri.Map} canvasMap - esri map instance loaded on a particular canvas
	 * @returns {esri.GraphicsLayer} - returns a the esri graphic layer
	 */
	getLayerByLayerId(layerId: string, canvasMap: esri.Map) {
		if (!layerId) return;

		let graphicsLayer = canvasMap.findLayerById(layerId) as esri.GraphicsLayer;

		if (graphicsLayer) {
			graphicsLayer.visible = true;
			return graphicsLayer;
		}

		graphicsLayer = new this.EsriMapSdk.GraphicsLayer();
		graphicsLayer.set('id', layerId);
		graphicsLayer.set('title', layerId);
		graphicsLayer.set('visible', true);
		canvasMap.add(graphicsLayer);

		return graphicsLayer;
	}

	/**
	 * create new graphics object to corresponding graphics layer
	 * @param {esri.GraphicsLayer} graphicLayer - graphic layer
	 * @param {esri.Graphic[]} graphics - graphics list
	 */
	addGraphicsToGraphicLayer(graphicLayer: esri.GraphicsLayer, graphics: esri.Graphic[]) {
		if (this.mapGraphicLayerList[graphicLayer.id] && this.mapGraphicLayerList[graphicLayer.id].length > 0) {
			graphics.forEach(item => {
				this.mapGraphicLayerList[graphicLayer.id].push(item);
			});
		} else {
			this.mapGraphicLayerList[graphicLayer.id] = graphics;
		}
		graphicLayer.addMany(graphics);
	}

	/**
	 * removes graphic from the corresponding graphics layer
	 * @param {esri.GraphicsLayer} graphicLayer - graphic layer
	 * @param {string} graphicId - graphics unique id
	 */
	removeGraphicFromGraphicLayer(graphicLayer, graphicId) {
		const graphicsList = this.mapGraphicLayerList[graphicLayer.id];
		const graphicRemoveIndex = graphicsList.findIndex(graphic => graphic.attributes.id === graphicId);
		const graphicToRemove = graphicsList.splice(graphicRemoveIndex, 1);
		graphicLayer.removeMany(graphicToRemove);
	}

	/**
	 * removes all grahics from the corresponding graphics layer
	 * @param {esri.GraphicsLayer} graphicLayer - graphic layer
	 */
	removeAllGraphicFromGraphicLayer(canvas, layerId) {
		if (!canvas.mapView) return;
		const highlightLayer: esri.GraphicsLayer = this.getLayerByLayerId(layerId, canvas.mapView.map);
		highlightLayer.removeAll();
	}

	removePolygonGraphic(canvas) {
		this.removeAllGraphicFromGraphicLayer(canvas, 'polygonGraphicsLayer');
	}

	initLegends(canvas: Canvas) {
		const legend = new this.EsriMapSdk.Legend({
			view: canvas.mapView
		});

		return legend;
	}

	getLegends(canvas: Canvas) {
		const legend: esri.Legend = canvas.map.legends;
		const legendList: LayerLegend[] = [];
		const activeLayerInfoList: esri.Collection<esri.ActiveLayerInfo> = legend.activeLayerInfos;
		activeLayerInfoList.forEach(activeLayer => {
			if (activeLayer.layer.type === 'map-image') {
				legendList.push({
					id: activeLayer.layer.id,
					title: activeLayer.layer.title,
					legendElements: activeLayer.legendElements
				});
			}
		});

		return legendList;
	}
	getLayers(canvas: Canvas) {
		const allLayerViews = canvas.mapView.allLayerViews;
		const mapImageLayerView = allLayerViews.filter(layerView => {
			// @ts-ignore
			return layerView.layer.type === 'map-image' && !layerView.layer.isBaseLayer;
		});

		const layerDetails = [];
		// @ts-ignore
		for (const imageLayer of mapImageLayerView.items) {
			const mapImageLayer = imageLayer.layer;
			let subLayerItems = [];
			const url = mapImageLayer.url;

			const regex = new RegExp('featureserver', 'i');
			const isFeatureServer = regex.test(url);

			if (!isFeatureServer) {
				const subLayersVisible = mapImageLayer.sublayers;
				const subLayers = subLayersVisible.map(item => {
					return {
						id: item.id,
						title: item.title,
						visible: item.visible
					};
				});
				subLayerItems = subLayers.items;
			} else {
				subLayerItems = [];
			}

			const isAllSubDisabled = subLayerItems.every(item => item.visible === false);

			const layerObject = {
				id: mapImageLayer.id,
				title: mapImageLayer.title,
				visible: isAllSubDisabled ? false : mapImageLayer.visible,
				type: mapImageLayer.type,
				url: mapImageLayer.url,
				subLayers: subLayerItems
			};
			layerDetails.push(layerObject);
		}

		return layerDetails;
	}

	getBaseLayers(canvas: Canvas) {
		const allLayerViews = canvas.mapView.allLayerViews;
		const mapImageLayerView = allLayerViews.filter(layerView => {
			// @ts-ignore
			return layerView.layer.isBaseLayer;
		});

		const layerDetails = [];
		// @ts-ignore
		for (const imageLayer of mapImageLayerView.items) {
			const mapImageLayer = imageLayer.layer;
			let subLayerItems = [];
			const url = mapImageLayer.url;

			const regex = new RegExp('featureserver', 'i');
			const isFeatureServer = regex.test(url);

			if (!isFeatureServer && mapImageLayer.sublayers) {
				const subLayersVisible = mapImageLayer.sublayers;
				const subLayers = subLayersVisible.map(item => {
					return {
						id: item.id,
						title: item.title ? item.title : item.name,
						visible: item.visible
					};
				});
				subLayerItems = subLayers.items;
			} else {
				subLayerItems = [];
			}

			const isSublayerVisibility: boolean = mapImageLayer.capabilities && mapImageLayer.capabilities.exportMap ? mapImageLayer.capabilities.exportMap.supportsSublayerVisibility : false;

			const layerObject = {
				id: mapImageLayer.id,
				name: mapImageLayer.name,
				visible: mapImageLayer.visible,
				type: mapImageLayer.type,
				url: mapImageLayer.url,
				transparency: mapImageLayer.opacity * 100,
				isSubLayerLocked: !isSublayerVisibility,
				subLayers: subLayerItems
			};
			layerDetails.push(layerObject);
		}

		return layerDetails;
	}

	toggleSubLayersVisibility(canvas, layerId, sublayer) {
		const allLayerViews = canvas.mapView.allLayerViews;
		const mapImageLayer = allLayerViews.find(layerItem => {
			return layerItem.layer.id === layerId;
		});

		const mapImageSublayer = mapImageLayer.layer.findSublayerById(sublayer.id);
		mapImageSublayer.visible = sublayer.visible;
	}

	toggleLayerVisibility(canvas, layerId, isVisible) {
		const allLayerViews = canvas.mapView.allLayerViews;
		const mapLayerView = allLayerViews.find(layerItem => {
			return layerItem.layer.id === layerId;
		});

		const layer = mapLayerView.layer;
		layer.visible = isVisible;
	}

	getGlobalConfigBaseLayers() {
		const globalConfigBaseLayers = this.userService.globalConfig.map.baseLayers;
		const globalConfigDataSources = this.userService.globalConfig.dataSources;

		const canvasMapBaseLayer: BasemapVisibleLayer[] = [];
		globalConfigBaseLayers.map((baseLayer: BaseLayer) => {
			const dataSourceItem = globalConfigDataSources.find(source => source.legacyId === baseLayer.dataSourceLegacyId);
			if (!dataSourceItem) return;
			const visibleBaseLayer = new BasemapVisibleLayer();
			visibleBaseLayer.legacyId = baseLayer.legacyId;
			visibleBaseLayer.name = dataSourceItem.name;
			visibleBaseLayer.layerType = baseLayer.layerType;
			visibleBaseLayer.transparency = baseLayer.transparency;
			visibleBaseLayer.url = dataSourceItem.url;
			visibleBaseLayer.visible = true;

			canvasMapBaseLayer.push(visibleBaseLayer);
		});

		return canvasMapBaseLayer;
	}
}
