import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { VariableDay } from './variable-day.model';
import { OmniModel } from '../omni-model';
import { MonthlyPatternType } from './monthly-pattern-type.enum';

export class MonthlyPattern extends OmniModel<MonthlyPattern, Contracts.MonthlyPatternContract> {
    constructor() {
        super();
        this.type = MonthlyPatternType.ExactDay;
    }

    private _type: SedaruUtils.Undoable<MonthlyPatternType>;
    get type(): MonthlyPatternType {
        if (!this._type) {
            this._type = new SedaruUtils.Undoable<MonthlyPatternType>();
        }

        return this._type.value;
    }
    set type(value: MonthlyPatternType) {
        if (!this._type) {
            this._type = new SedaruUtils.Undoable<MonthlyPatternType>(value);
        }
        this._type.value = value;
    }

    private _variableDay: VariableDay;
    get variableDay(): VariableDay {
        return this._variableDay ? this._variableDay : (this._variableDay = new VariableDay());
    }
    set variableDay(value: VariableDay) {
        if (this._variableDay === value) return;
        this._variableDay = value;
    }

    private _exactDay: SedaruUtils.Undoable<number>;
    get exactDay(): number {
        if (!this._exactDay) this._exactDay = new SedaruUtils.Undoable<number>();
        return this._exactDay.value;
    }
    set exactDay(value: number) {
        if (!this._exactDay) {
            this._exactDay = new SedaruUtils.Undoable<number>(value);
            return;
        }
        this._exactDay.value = value;
    }

    static fromContract(contract: Contracts.MonthlyPatternContract): MonthlyPattern {
        const model = new MonthlyPattern();
        model.type = contract.type as MonthlyPatternType;
        model.exactDay = contract.exactDay;
        if (contract.variableDay) model.variableDay = VariableDay.fromContract(contract.variableDay);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.MonthlyPatternContract {
        const contract = new Contracts.MonthlyPatternContract();
        contract.type = this.type;
        contract.exactDay = this.exactDay;
        contract.variableDay = this.variableDay.getContract();
        return contract;
    }

    onUpdateInformation(copyModel: MonthlyPattern): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<number>(this._exactDay, () => {
                this.exactDay = copyModel.exactDay;
            }) || wasChanged;

        wasChanged = this.variableDay.updateInformation(copyModel.variableDay) || wasChanged;

        return wasChanged;
    }
}
