<div class="new-task-panel">
	<label class="new-task-header">add work task</label>
	<mat-divider class="new-task-divider"></mat-divider>

	<app-omni-list
		class="task-list"
		[omniListItems]="taskList"
		(itemClicked)="itemSelected($event)"
		[isSelectSingleItem]="true"
		[selectedItems]="selectedItem"
		[itemsKey]="itemsKey"
	></app-omni-list>

	<app-menu-panel-field
		[type]="'text'"
		[cssClass]="'light-gray-background'"
		[buttonEnabled]="true"
		[header]="'*assigned to'"
		[value]="taskAssignedTo"
		[goTo]="goToWorkTaskAssignTo"
	></app-menu-panel-field>
</div>

<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" (click)="save()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
