import { ServerInfo } from './server-info';

export class PacketMetadata {
	serverInformation: ServerInfo;
	private _protocolVersion: string;
	get protocolVersion(): string {
		if (!this._protocolVersion) {
			this._protocolVersion = '1.1';
		}
		return this._protocolVersion;
	}

	static fromAny(jObject: any): PacketMetadata {
		const metadata = new PacketMetadata();
		metadata._protocolVersion = jObject.ProtocolVersion;
		metadata.serverInformation = ServerInfo.fromAny(jObject.ServerInformation);

		return metadata;
	}
	toAny(): any {
		let val: any;
		val = {
			ProtocolVersion: this.protocolVersion
		};

		if (this.serverInformation) {
			val.ServerInformation = {
				SignalRVersion: this.serverInformation.signalRVersion,
				ProtocolVersion: this.serverInformation.protocolVersion
			};
		}

		return val;
	}
}
