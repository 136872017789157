import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { slideInLeftAnimation } from 'app/app.animations';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { MenuPanelBaseComponent } from './menu-panel-base/menu-panel-base.component';
import { RouterComponent } from 'app/navigation/router/router.component';
import { Color, Metric, MetricTile } from 'models';
import { BackNavigationArgs, BackButtonSource } from 'app/navigation/back-navigation-args';
import { NavigationService } from 'app/navigation/navigation.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ToastComponent } from 'app/ui-components/toast/toast.component';
import { ToastController } from 'app/ui-components/toast/toast-controller';
import { HeaderFooterComponent } from 'app/ui-components/header-footer/header-footer.component';
import * as MenuPanelNavigation from 'app/navigation/menu-panel-navigation';
import { TrendDefinition } from 'app/canvas-container/canvas-trend/trend-defintion.model';
import { TrendStyle } from './trend-settings/trend-style/trend-style.model';

/**
 * This component is the parent or wrapper for all omni contextual menus((aka side panel)), such as tab settings,
 * tile settings, and search.
 */
@Component({
	selector: 'app-menu-panel',
	templateUrl: './menu-panel.component.html',
	styleUrls: ['./menu-panel.component.scss'],
	animations: [slideInLeftAnimation]
})
export class MenuPanelComponent implements OnInit, OnDestroy {
	private static _toastController: ToastController;
	static get toastController(): ToastController {
		if (!MenuPanelComponent._toastController) {
			MenuPanelComponent._toastController = new ToastController();
		}
		return MenuPanelComponent._toastController;
	}

	@Input() config: GuiConfig;
	@Input() mode: string;
	/**
	 * We are wrapping the #menupanel native element into the ElementRef class that
	 * is just a wrapper class for native elements.
	 */
	@ViewChild('menupanel', { read: ElementRef, static: true }) public menupanel: ElementRef;
	/** the instance of the router component */
	@ViewChild('navigationRouter', { static: true }) navigationRouter: RouterComponent;

	@ViewChild('toast', { static: true }) toast: ToastComponent;

	@ViewChild('header', { static: true }) headerComponent: HeaderFooterComponent;

	@ViewChild('footer', { static: true }) footerComponent: HeaderFooterComponent;
	/**
	 * Holds the title to be displayed on the menu panel header.
	 */
	private _title = 'Sedaru OMNI';
	set title(t: string) {
		this._title = t;
	}
	get title() {
		return this._title;
	}
	/**
	 * Holds the sub-title to be displayed on the menu panel header.
	 */
	subtitle = '';
	/**
	 * Holds the background color of the menu panel header.
	 */
	titleBackground = 'card-title-gray';
	/**
	 * The icon that will displayed on the right side of the menu panel header.
	 */
	rightIcon: { url?: string; callBackFunction?: { () }; toolTip: string };
	/** if set, the header's background color will change to this rgba color */
	headerBackGroundColor: Color;
	/** shows or hides the loading pacifier */
	loading = false;
	disableBackButton = false;
	badgeNumber = 0;
	disableRightButton = false;

	onInfoFormTrendIconClicked: (trendDefinition: TrendDefinition) => void;
	onTileBackgroundColorChanged: (tile: MetricTile) => void;
	onTrendStyleChanged: (trendStyle: TrendStyle) => void;

	private _currentNavigationEntry: MenuPanelNavigation.MenuPanelNavigationEntry;
	public set currentNavigationEntry(value: MenuPanelNavigation.MenuPanelNavigationEntry) {
		// this._currentNavigationEntry = value;
		// if (!this._currentNavigationEntry) {
		// 	this.headerComponent.navigationBar = undefined;
		// 	this.footerComponent.navigationBar = undefined;
		// 	return;
		// }
		// this.headerComponent.navigationBar = this._currentNavigationEntry.header;
		// this.footerComponent.navigationBar = this._currentNavigationEntry.footer;
	}
	public get currentNavigationEntry(): MenuPanelNavigation.MenuPanelNavigationEntry {
		return this._currentNavigationEntry;
	}

	/**
	 * @param {ScrollToViewService} scollingService - This registers the view which is to be scrolled in when a user hits
	 * something, say a tile, on a mobile device.
	 */
	constructor(private scollingService: ScrollToViewService) {}

	/**
	 * On init, a subscription is made to the event emitted when components are loaded in the menu panel which will update
	 * the menu panel view accordingly and another subscription is made to the event to update the header of the menu panel.
	 */
	ngOnInit() {
		this.scollingService.menuPanel = this.menupanel;
		this.toast.controller = MenuPanelComponent.toastController;
		this.navigationRouter.set(this);
	}

	onNavigationComplete(activeComponent: MenuPanelBaseComponent) {
		activeComponent.menuPanelComponent = this;
		this.loading = false;
		this.badgeNumber = 0;
	}

	/**
	 * On destroy, the method simply unsubscribes to the navigation event handler
	 */
	ngOnDestroy() {}

	/**
	 * The method updates the title of the menu panel and the background color of the header
	 */
	updateView(updates: {
		title?: string;
		subtitle?: string;
		backgroundClass?: string;
		backgroundColor?: Color;
		rightIcon?: { url?: string; callBackFunction?: { () }; toolTip: string };
		showPacifier?: boolean;
		badgeNumber?: number;
		disableRightButton?: boolean;
	}) {
		// to avoid getting the error ExpressionChangedAfterItHasBeenCheckedError,
		// updates are made after the child component has been loaded
		setTimeout(() => {
			if (updates.title) this.title = updates.title;
			// if (updates.title) this.currentNavigationEntry.header.title = updates.title;
			this.subtitle = updates.subtitle ? updates.subtitle : '';
			if (updates.backgroundClass) this.titleBackground = updates.backgroundClass;
			else this.titleBackground = 'card-title-gray';
			if (updates.backgroundColor) this.headerBackGroundColor = updates.backgroundColor;
			else this.headerBackGroundColor = null;
			if (updates.rightIcon) this.rightIcon = updates.rightIcon;
			else this.rightIcon = undefined;
			if (!this.isNullOrUndefined(updates.showPacifier)) this.loading = updates.showPacifier;
			if (updates.badgeNumber) this.badgeNumber = updates.badgeNumber;
			if (!updates.disableRightButton) this.disableRightButton = false;
			else this.disableRightButton = true;
		}, 1);
	}

	updatePacifier(showPacifier: boolean) {
		setTimeout(() => {
			this.loading = showPacifier;
		}, 1);
	}

	updateRightIcon(params: { url?: string; callBackFunction?: { () }; toolTip?: string; disable?: boolean }) {
		setTimeout(() => {
			if (params.url) this.rightIcon.url = params.url;
			if (params.callBackFunction) this.rightIcon.callBackFunction = params.callBackFunction;
			if (params.toolTip) this.rightIcon.toolTip = params.toolTip;
			if (params.disable !== undefined) this.disableRightButton = params.disable;
		}, 400);
	}

	updateSubTitle(subtitle) {
		this.subtitle = subtitle ? subtitle : null;
	}

	private isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	}

	/**
	 * Enables navigation to the previous menu panel.
	 */
	async goBack(args?: BackNavigationArgs) {
		if (!this.navigationRouter.currentPage) {
			return;
		}
		// wait until the data get loaded before navigate back to previous page to resolve dislpaying wrong panel header name issue(Bug:18277)
		if (this.loading) {
			return;
		}

		if (!args) args = new BackNavigationArgs(BackButtonSource.menuPanel);
		await this.navigationRouter.currentPage.onBackPressed(args);
		if (args.handled) return;

		this.disableBackButton = true;
		await NavigationService.navigateBackTo();
		setTimeout(() => (this.disableBackButton = false), 500);
	}

	canGoBack() {
		return this.navigationRouter.canGoBack();
	}

	toastDismissed() {
		this.disableBackButton = false;
	}

	/**
	 * If the menu title contains a subtitle, the padding should shrink to give more space to the title
	 */
	calculateTitlePadding() {
		if (this.subtitle) {
			return '3px';
		}
		return '10px';
	}

	onNavigationHappening(navigationEvent) {
		const { currentComponent, navigatingBack, goingOut } = navigationEvent;
		if (!currentComponent) return;
		if (!navigatingBack) {
			if (goingOut) {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-left-out 400ms forwards';
				}
			} else {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-left-in 400ms forwards';
				}
			}
		} else {
			if (goingOut) {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-right-out 400ms forwards';
				}
			} else {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-right-in 400ms forwards';
				}
			}
		}
	}

	getBackgroundColor() {
		if (!this.headerBackGroundColor) return null;
		return `rgba(${this.headerBackGroundColor.r}, ${this.headerBackGroundColor.g}, ${this.headerBackGroundColor.b}, ${this.headerBackGroundColor.alpha})`;
	}

	onTabActivated() {
		if (!this.navigationRouter) return;
		this.navigationRouter.onTabActivated();
		this.scollingService.menuPanel = this.menupanel;
	}

	onTabDeactivated() {
		if (!this.navigationRouter) return;
		this.navigationRouter.onTabDeactivated();
	}

	showOmniLogo() {
		return !this.navigationRouter.currentPage;
	}

	async metricUpdated(metricId: string) {
		if (!this.navigationRouter.currentPage) {
			return;
		}

		this.navigationRouter.currentPage.metricUpdated(metricId);
	}

	onInfoFormTrendIconClick(trendDefinition: TrendDefinition) {
		this.onInfoFormTrendIconClicked(trendDefinition);
	}
}
