import { WorkOrderWrapper } from './work-order-wrapper.model';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModelBase } from '../omni-model';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { WorkCommentWrappers } from './work-comment-wrappers.model';
import { WorkAssetWrappers } from './work-asset-wrappers.model';
import { WorkOrderSummaryActions } from './work-order-summary-actions.enum';
import { AssetRecord } from '../records/asset-record.model';
import { RecurrenceTemplate } from '../recurrence';
import { DateUtil } from '../../sedaru-util/index';

export class WorkOrderSummary extends OmniModelBase<WorkOrderSummary> {

	constructor(workOrderSummaryAction: WorkOrderSummaryActions,
		workOrders?: WorkOrderWrapper[], assetRecords?: AssetRecord[], workOrderTemplate?: RecurrenceTemplate) {
		super();

		this.originalAction = this.action = workOrderSummaryAction;
		this.assetRecords = assetRecords;
		this.workOrderWrappers = workOrders;
		this.workOrderTemplate = workOrderTemplate;

		this.setInitialFields();
	}

	private _action: WorkOrderSummaryActions;
	get action(): WorkOrderSummaryActions {
		return this._action;
	}
	set action(value: WorkOrderSummaryActions) {
		if (this._action === value) return;
		this._action = value;
	}

	private _originalAction: WorkOrderSummaryActions;
	private get originalAction(): WorkOrderSummaryActions {
		return this._originalAction;
	}
	private set originalAction(value: WorkOrderSummaryActions) {
		if (this._originalAction === value) return;
		this._originalAction = value;
	}

	get workOrderWrapper(): WorkOrderWrapper {
		return this.workOrderWrappers[0];
	}

	private _workOrderWrappers: WorkOrderWrapper[];
	get workOrderWrappers(): WorkOrderWrapper[] {
		if (!this._workOrderWrappers) this._workOrderWrappers = new Array<WorkOrderWrapper>();
		return this._workOrderWrappers;
	}
	set workOrderWrappers(value: WorkOrderWrapper[]) {
		if (this._workOrderWrappers === value) return;
		this._workOrderWrappers = value;
	}

	private _workOrderTemplate: RecurrenceTemplate;
	get workOrderTemplate(): RecurrenceTemplate {
		return this._workOrderTemplate ? this._workOrderTemplate : (this._workOrderTemplate = new RecurrenceTemplate());
	}
	set workOrderTemplate(value: RecurrenceTemplate) {
		if (this._workOrderTemplate === value) return;
		this._workOrderTemplate = value;
	}

	private _assetRecords: AssetRecord[];
	get assetRecords(): AssetRecord[] {
		if (!this._assetRecords) this._assetRecords = new Array<AssetRecord>();
		return this._assetRecords;
	}
	set assetRecords(value: AssetRecord[]) {
		if (this._assetRecords === value) return;
		this._assetRecords = value;
	}

	private _objectId: SedaruUtils.Undoable<number>;
	get objectId(): number {
		if (!this._objectId) this._objectId = new SedaruUtils.Undoable<number>();
		return this._objectId.value;
	}
	set objectId(value: number) {
		if (!this._objectId) {
			this._objectId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._objectId.value = value;
	}

	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

    private _workType: SedaruUtils.Undoable<string>;
	get workType(): string {
		if (!this._workType) this._workType = new SedaruUtils.Undoable<string>();
		return this._workType.value;
	}
	set workType(value: string) {
		if (!this._workType) {
			this._workType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workType.value = value;
	}

	private _workTypeId: SedaruUtils.Undoable<string>;
	get workTypeId(): string {
		if (!this._workTypeId) this._workTypeId = new SedaruUtils.Undoable<string>();
		return this._workTypeId.value;
	}
	set workTypeId(value: string) {
		if (!this._workTypeId) {
			this._workTypeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTypeId.value = value;
	}

	private _status: SedaruUtils.Undoable<string>;
	get status(): string {
		if (!this._status) this._status = new SedaruUtils.Undoable<string>();
		return this._status.value;
	}
	set status(value: string) {
		if (!this._status) {
			this._status = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._status.value = value;
	}

	private _startDate: SedaruUtils.Undoable<string>;
	get startDate(): string {
		if (!this._startDate) this._startDate = new SedaruUtils.Undoable<string>();
		return this._startDate.value;
	}
	set startDate(value: string) {
		if (!this._startDate) {
			this._startDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._startDate.value = value;
	}

	private _changeBy: SedaruUtils.Undoable<string>;
	get changeBy(): string {
		if (!this._changeBy) this._changeBy = new SedaruUtils.Undoable<string>();
		return this._changeBy.value;
	}
	set changeBy(value: string) {
		if (!this._changeBy) {
			this._changeBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeBy.value = value;
	}

	private _changeDate: SedaruUtils.Undoable<string>;
	get changeDate(): string {
		if (!this._changeDate) this._changeDate = new SedaruUtils.Undoable<string>();
		return this._changeDate.value;
	}
	set changeDate(value: string) {
		if (!this._changeDate) {
			this._changeDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeDate.value = value;
	}

	private _completedDate: SedaruUtils.Undoable<string>;
	get completedDate(): string {
		if (!this._completedDate) this._completedDate = new SedaruUtils.Undoable<string>();
		return this._completedDate.value;
	}
	set completedDate(value: string) {
		if (!this._completedDate) {
			this._completedDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedDate.value = value;
	}

	private _completedBy: SedaruUtils.Undoable<string>;
	get completedBy(): string {
		if (!this._completedBy) this._completedBy = new SedaruUtils.Undoable<string>();
		return this._completedBy.value;
	}
	set completedBy(value: string) {
		if (!this._completedBy) {
			this._completedBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedBy.value = value;
	}

	private _assignedTo: SedaruUtils.Undoable<string>;
	get assignedTo(): string {
		if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
		return this._assignedTo.value;
	}
	set assignedTo(value: string) {
		if (!this._assignedTo) {
			this._assignedTo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assignedTo.value = value;
	}

	private _supervisor: SedaruUtils.Undoable<string>;
	get supervisor(): string {
		if (!this._supervisor) this._supervisor = new SedaruUtils.Undoable<string>();
		return this._supervisor.value;
	}
	set supervisor(value: string) {
		if (!this._supervisor) {
			this._supervisor = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._supervisor.value = value;
	}

	private _priority: SedaruUtils.Undoable<string>;
	get priority(): string {
		if (!this._priority) this._priority = new SedaruUtils.Undoable<string>();
		return this._priority.value;
	}
	set priority(value: string) {
		if (!this._priority) {
			this._priority = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._priority.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

    private _location: SedaruUtils.Undoable<string>;
	get location(): string {
		if (!this._location) this._location = new SedaruUtils.Undoable<string>();
		return this._location.value;
	}
	set location(value: string) {
		if (!this._location) {
			this._location = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._location.value = value;
	}

	private _teamId: SedaruUtils.Undoable<string>;
	get teamId(): string {
		if (!this._teamId) this._teamId = new SedaruUtils.Undoable<string>();
		return this._teamId.value;
	}
	set teamId(value: string) {
		if (!this._teamId) {
			this._teamId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._teamId.value = value;
	}

	private _createdDate: SedaruUtils.Undoable<string>;
	get createdDate(): string {
		if (!this._createdDate) this._createdDate = new SedaruUtils.Undoable<string>();
		return this._createdDate.value;
	}
	set createdDate(value: string) {
		if (!this._createdDate) {
			this._createdDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdDate.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	private _customerName: SedaruUtils.Undoable<string>;
	get customerName(): string {
		if (!this._customerName) this._customerName = new SedaruUtils.Undoable<string>();
		return this._customerName.value;
	}
	set customerName(value: string) {
		if (!this._customerName) {
			this._customerName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customerName.value = value;
	}

	private _contactInfo: SedaruUtils.Undoable<string>;
	get contactInfo(): string {
		if (!this._contactInfo) this._contactInfo = new SedaruUtils.Undoable<string>();
		return this._contactInfo.value;
	}
	set contactInfo(value: string) {
		if (!this._contactInfo) {
			this._contactInfo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._contactInfo.value = value;
	}

	private _accountNumber: SedaruUtils.Undoable<string>;
	get accountNumber(): string {
		if (!this._accountNumber) this._accountNumber = new SedaruUtils.Undoable<string>();
		return this._accountNumber.value;
	}
	set accountNumber(value: string) {
		if (!this._accountNumber) {
			this._accountNumber = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._accountNumber.value = value;
	}

	private _isCorrective: SedaruUtils.Undoable<number>;
	get isCorrective(): number {
		if (!this._isCorrective) this._isCorrective = new SedaruUtils.Undoable<number>();
		return this._isCorrective.value;
	}
	set isCorrective(value: number) {
		if (!this._isCorrective) {
			this._isCorrective = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isCorrective.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<string>;
	get changeStatus(): string {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<string>();
		return this._changeStatus.value;
	}
	set changeStatus(value: string) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeStatus.value = value;
	}

	private _budgetId: SedaruUtils.Undoable<string>;
	get budgetId(): string {
		if (!this._budgetId) this._budgetId = new SedaruUtils.Undoable<string>();
		return this._budgetId.value;
	}
	set budgetId(value: string) {
		if (!this._budgetId) {
			this._budgetId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._budgetId.value = value;
	}

	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	private _parentWorkOrderKey: SedaruUtils.Undoable<string>;
	get parentWorkOrderKey(): string {
		if (!this._parentWorkOrderKey) this._parentWorkOrderKey = new SedaruUtils.Undoable<string>();
		return this._parentWorkOrderKey.value;
	}
	set parentWorkOrderKey(value: string) {
		if (!this._parentWorkOrderKey) {
			this._parentWorkOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._parentWorkOrderKey.value = value;
	}

	private _customFields: CustomFieldWrappers;
	get customFields(): CustomFieldWrappers {
		if (!this._customFields) this._customFields = new CustomFieldWrappers();
		return this._customFields;
	}
	set customFields(value) {
		if (this._customFields === value) return;
		this._customFields = value;
	}

	get workComments(): WorkCommentWrappers {
		return this.workOrderWrapper?.workComments;
	}

	get workAssets(): WorkAssetWrappers {
		if (this.action === WorkOrderSummaryActions.EditRecurrenceTemplate) return this.workOrderTemplate?.workAssets;
		const workAssets = new WorkAssetWrappers();
		this.workOrderWrappers.forEach(wo => workAssets.push(...wo.workAssets));
		return workAssets;
	}

    private allSummaryFields: Array<string> = ['objectId', 'workType', 'workTypeId', 'workOrderKey', 'status', 'startDate', 'changeBy',
        'changeDate', 'completedDate', 'completedBy', 'assignedTo', 'supervisor', 'priority', 'location', 'description', 'comments', 'teamId', 'createdDate', 'createdBy',
		'customerName', 'contactInfo', 'accountNumber', 'isCorrective', 'changeStatus', 'budgetId', 'systemId', 'parentWorkOrderKey'];

	private setInitialFields(): void {
		for (const field of this.allSummaryFields) {
			this[field] = this.isSingleWorkOrder ? this.workOrderWrapper[field]
				: (this.isRecurrenceTemplate ? this.workOrderTemplate[field] : this.getCommonValue(field));
		}

		this.customFields = this.workOrderWrapper?.customFields;
		this.clearDirty();
	}

	private getCommonValue(propertyName: string): any {
		const defaultString = '';
		if (!this.workOrderWrappers || !propertyName) return defaultString;

		let valueToCompare: any;
		for (const workOrder of this.workOrderWrappers) {
			if (!valueToCompare) {
				valueToCompare = workOrder[propertyName];
				continue;
			}

			if (workOrder[propertyName] !== valueToCompare) return defaultString;
		}

		return valueToCompare;
	}

	resetAction(): void {
		this.action = this.originalAction;
	}

	applyAllDirtyFields(currentUserFullName?: string): void {
		for (const workOrder of this.workOrderWrappers) {
			this.setDirtyFields(workOrder, currentUserFullName);
			workOrder.customFields = this.customFields;
		}
	}

	private setDirtyFields(workOrder: WorkOrderWrapper, currentUserFullName?: string): void {
		if (this.isAnyFieldDirty()) {
			const changeByField = this.allSummaryFields.find(field => field.toLowerCase().includes('changeby'));
			const changeDateField = this.allSummaryFields.find(field => field.toLowerCase().includes('changedate'));
			workOrder[changeByField] = currentUserFullName;
			workOrder[changeDateField] = DateUtil.now.toLocaleString();
		}
		for (const field of this.allSummaryFields) {
			if (!this.isFieldDirty(field)) continue;
			workOrder[field] = this[field];
		}
	}

	isAnyFieldDirty() {
		const dirtyFieldExist = this.allSummaryFields.find(field => this.isFieldDirty(field));
		if (!dirtyFieldExist) return false;
		else return true;
	}

	getArrayValueUsingKey(array: any, key: any) {

	}

	isFieldDirty(fieldName: string): boolean {
		const undoablePropertyString = `_${fieldName}`;
		if (!(this[undoablePropertyString] instanceof SedaruUtils.UndoableBase)) return false;
		return (this[undoablePropertyString] as SedaruUtils.UndoableBase).isDirty;
	}

	setWorkOrderTemplateValues(): void {
		this.workOrderTemplate.setWorkOrderTemplateValues(this);
	}

	get isBulkEdit(): boolean {
		return this.action === WorkOrderSummaryActions.EditMultipleWO;
	}

	get isSingleWorkOrder(): boolean {
		return this.action === WorkOrderSummaryActions.CreateSingleWO
			|| this.action === WorkOrderSummaryActions.EditSingleWO;
	}

	get isCreationMode(): boolean {
		return this.action === WorkOrderSummaryActions.CreateSingleWO
			|| this.action === WorkOrderSummaryActions.CreateMultipleWO
			|| this.action === WorkOrderSummaryActions.CreateRecurrenceTemplate;
	}

	get isRecurrenceTemplate(): boolean {
		return this.action === WorkOrderSummaryActions.CreateRecurrenceTemplate
			|| this.action === WorkOrderSummaryActions.EditRecurrenceTemplate;
	}

	get isNew(): boolean {
		return this.action === WorkOrderSummaryActions.CreateSingleWO
			|| this.action === WorkOrderSummaryActions.CreateMultipleWO;
	}
}
