import { WorkOrderSystem } from './../models/sedaru-config/work-order-system.enum';
import { WorkOrderChannelAttributes } from './../models/work-order-channel-attributes.model';
import { Channel } from '../models/channel.model';
import { Injectable } from '@angular/core';
import { ArcGISHistoryChannelAttributes, ArcGISAssetChannelAttributes, ChannelTypes, WorkOrderModeType, ArcGISWorkOrderChannelAttributes, SedaruWorkOrderChannelAttributes, Metric, MetricTypes } from '../models';

/**
 * The data channel service is used for curd operations upon DataChannels
 * for the metric data
 */
@Injectable({
	providedIn: 'root'
})
export class DataChannelService {
	/**
	 * Holds the list of available data channels
	 */
	public availableDataChannels: { [key: string]: Channel } = {};

	constructor() {}

	assignDataChannelToMetric(metric: Metric) {
		// clear out the previous channels everytime we make a selection.
		// For example, if we want to change from history channel to asset channel only,
		// then we need to delete the history channel
		this.resetMetricSourceChannels(metric);

		const { type: sourceType, assetType: sourceAssetType } = metric.definition.source;

		const isHistorySource = sourceType === ChannelTypes.History;
		const isAssetSource = sourceType === ChannelTypes.Asset;

		if (isHistorySource) {
			this.handleHistorySource(metric, sourceType, sourceAssetType);
		} else if (isAssetSource) {
			this.handleAssetSource(metric, sourceType, sourceAssetType);
		} else {
			this.handleWorkOrderSource(metric, sourceType);
		}
	}

	/**
	 * This function deletes all the channel properties inside of metric's definition
	 */
	private resetMetricSourceChannels(metric: Metric): void {
		delete metric.definition.assetChannel;
		delete metric.definition.assetChannelId;
		delete metric.definition.historyChannel;
		delete metric.definition.historyChannelId;
		delete metric.definition.workOrderChannel;
		delete metric.definition.workOrderChannelId;
	}

	/**
	 * This function handles the logic for assigning history source
	 * @param {number} sourceType - The enum value for source type
	 * @param {string} sourceAssetType - The string value for source asset type
	 */
	private handleHistorySource(metric: Metric, sourceType: number, sourceAssetType: string): void {
		// If it is an history channel
		// We find the matching history channel with the same asset type as the metric source
		const matchingHistoryChannel = Object.values(this.availableDataChannels)
			.filter(channel => channel.channelType === ChannelTypes.History)
			.find(historyChannel => {
				const historyChannelAttributes = historyChannel.attributes as ArcGISHistoryChannelAttributes;
				if (!historyChannelAttributes) return false;

				return sourceAssetType.toLowerCase() === historyChannelAttributes.assetType.toLowerCase();
			});

		if (!matchingHistoryChannel) return console.error('no history channel in the database for this asset');

		const matchingHistoryChannelAttributes = matchingHistoryChannel.attributes as ArcGISHistoryChannelAttributes;

		// We find the matching asset channel by finding all the available asset channel that have the same assetType
		const matchingAssetChannel = Object.values(this.availableDataChannels)
			.filter(channel => channel.channelType === ChannelTypes.Asset)
			.find(assetChannel => {
				const assetChannelAttributes = assetChannel.attributes as ArcGISAssetChannelAttributes;
				if (!assetChannelAttributes) return false;

				return assetChannelAttributes.assetType.toLowerCase() === matchingHistoryChannelAttributes.assetType.toLowerCase();
			});

		if (!matchingAssetChannel) return console.error('no asset channel in the database for this asset');

		// Assign the channels
		metric.definition.historyChannel = matchingHistoryChannel;
		metric.definition.historyChannelId = matchingHistoryChannel.id;
		metric.definition.assetChannel = matchingAssetChannel;
		metric.definition.assetChannelId = matchingAssetChannel.id;
		metric.definition.type = MetricTypes.history;

		delete metric.definition.workOrderChannel;
		delete metric.definition.workOrderChannelId;
	}

	/**
	 * This function handles the logic for assigning asset source
	 * @param {number} sourceType - The enum value for source type
	 * @param {string} sourceAssetType - The string value for source asset type
	 */
	private handleAssetSource(metric: Metric, sourceType: number, sourceAssetType: string): void {
		// Else if it is a asset channel
		// Find the matching asset channel with the same asset type as the metric source
		const matchingAssetChannel = Object.values(this.availableDataChannels)
			.filter(channel => channel.channelType === ChannelTypes.Asset)
			.find(assetChannel => {
				const assetChannelAttributes = assetChannel.attributes as ArcGISAssetChannelAttributes;
				if (!assetChannelAttributes) return false;

				return assetChannelAttributes.assetType.toLowerCase() === sourceAssetType.toLowerCase();
			});

		if (!matchingAssetChannel) return console.error('no asset channel in the database for this asset');

		// Assign the channels
		metric.definition.assetChannel = matchingAssetChannel;
		metric.definition.assetChannelId = matchingAssetChannel.id;
		metric.definition.type = MetricTypes.asset;

		delete metric.definition.historyChannel;
		delete metric.definition.historyChannelId;
		delete metric.definition.workOrderChannel;
		delete metric.definition.workOrderChannelId;
	}

	/**
	 * This function handles the logic for assigning work order source
	 * @param {number} sourceType - The enum value for source type
	 */
	private handleWorkOrderSource(metric: Metric, sourceType: number): void {
		// Else work order channel
		const matchingWorkOrderChannel = Object.values(this.availableDataChannels).find(workOrderChannel => workOrderChannel.channelType === sourceType);

		// Assign the channels
		metric.definition.workOrderChannel = matchingWorkOrderChannel;
		metric.definition.workOrderChannelId = matchingWorkOrderChannel.id;
		if (matchingWorkOrderChannel.isAdvancedWorkOrder) metric.definition.type = MetricTypes.awo;
		else metric.definition.type = MetricTypes.swo;

		delete metric.definition.historyChannel;
		delete metric.definition.historyChannelId;
		delete metric.definition.assetChannel;
		delete metric.definition.assetChannelId;
	}

	/**
	 * This function takes in a history channel name, convert it to a history field prefix, and returns it
	 * @param {string} assetTypeName - The history channel name
	 */
	getHistoryFieldPrefix(assetTypeName: string) {
		const assetFieldPrefix = this.getAssetFieldPrefix(assetTypeName);
		const historyFieldPrefix = `${assetFieldPrefix}History`;
		return historyFieldPrefix;
	}

	/**
	 * This function takes in a asset channel name, convert it to a asset field prefix, and returns it
	 * @param {string} assetTypeName - The asset channel name
	 */
	getAssetFieldPrefix(assetTypeName: string) {
		const assetFieldPrefix = this.captializeName(assetTypeName);
		return assetFieldPrefix;
	}

	/**
	 * The function takes in a name and return a captialized name
	 * @param {string} name - The name to be capitalized
	 */
	private captializeName(name: string) {
		name = name.toLowerCase();
		return name.charAt(0).toUpperCase() + name.slice(1);
	}

	/**
	 * this function returns the work order mode
	 */
	getWorkOrderMode = (): WorkOrderModeType => {
		const workOrderChannel = Object.values(this.availableDataChannels).find(channel => channel.channelType === ChannelTypes.WorkOrder);
		if (!workOrderChannel) return;
		return (workOrderChannel.attributes as WorkOrderChannelAttributes).workOrderMode;
	};

	getWorkOrderSystem = (): WorkOrderSystem => {
		const workOrderChannel = Object.values(this.availableDataChannels).find(channel => channel.channelType === ChannelTypes.WorkOrder);
		if (!workOrderChannel || !workOrderChannel.attributes) return;
		return (workOrderChannel.attributes as WorkOrderChannelAttributes).workOrderSystem;
	};

	getWorkOrderChannel = (): Channel =>
		Object.values(this.availableDataChannels).find(channel => channel.attributes instanceof ArcGISWorkOrderChannelAttributes || channel.attributes instanceof SedaruWorkOrderChannelAttributes);

	/**
	 * This function returns a array of status code for "completed"
	 */
	getCompletedWorkOrderValues = (): string[] => {
		const workOrderChannel = Object.values(this.availableDataChannels).find(channel => channel.channelType === ChannelTypes.WorkOrder);
		if (!workOrderChannel) return;
		return (workOrderChannel.attributes as WorkOrderChannelAttributes).completedWorkOrderValues;
	};

	getAssetChannelByAssetType = (assetType: string) =>
		Object.values(this.availableDataChannels).find(({ channelType, attributes }) => channelType === ChannelTypes.Asset && attributes['assetType'] === assetType);

	getAssetChannelByChannelIndex = (index: number) =>
		Object.values(this.availableDataChannels).find(({ attributes }) => (attributes as ArcGISAssetChannelAttributes).featureServiceLayerIndex === index);
}
