import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ListItemComponentBase } from '../list-item.model';
import { LeftIconTwoLinesItemResolver } from './left-icon-two-lines.resolver';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-left-icon-two-lines',
	templateUrl: './left-icon-two-lines.component.html',
	styleUrls: ['./left-icon-two-lines.component.scss']
})
export class LeftIconTwoLinesComponent extends ListItemComponentBase {
	@Input() resolver: LeftIconTwoLinesItemResolver;
	@Input() item: any;
	// @Input() disableIcon: boolean;
	@Output() leftIconClicked = new EventEmitter();
	@Output() itemSelected = new EventEmitter();

	@Input() selected = false;

	hovered = false;

	itemComponent = this;

	@Input() connector: boolean;

	static createResolver(): LeftIconTwoLinesItemResolver {
		const resolver = new LeftIconTwoLinesItemResolver();
		resolver.templateType = ListTemplates.leftIconTwoLines;
		return resolver;
	}

	onLeftIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.leftIconClicked.emit(this.item);
	}

	onItemClicked() {
		if (!this.resolver.isDisabled(this.item)) {
			this.selected = !this.selected;
			this.itemSelected.emit(this.item);
		}
	}
}
