import { Pages } from './inavigation';

/**
 * Put the group name here
 */
export enum PageGroupName {
	workorderSummary = 'workorderSummay',
	tab = 'tab',
	workorderFilter = 'workorderFilter'
}

/**
 * Put the pages name within the page group array to form a group
 */
export class PageGroup {
	static get(groupName: PageGroupName): Set<string> {
		let pageGroup = [];
		switch (groupName) {
			case PageGroupName.workorderSummary:
				pageGroup = [
					Pages.workorderSummary,
					Pages.workorderAssets,
					Pages.workorderField,
					Pages.workOrderComment,
					Pages.workOrderCommentList,
					Pages.workOrderFilterDatePicker,
					Pages.workOrderRecurrence,
					Pages.recurrenceFieldSelection
				];
				break;
			case PageGroupName.tab:
				pageGroup = [Pages.tabContentType, Pages.layout, Pages.tabVisibility, Pages.tabTheme];
				break;
			case PageGroupName.workorderFilter:
				pageGroup = [Pages.workOrderFilter, Pages.workOrderFilterSelection, Pages.workOrderFilterCustomField, Pages.workOrderFilterDatePicker];
				break;
			default:
				break;
		}
		return new Set(pageGroup);
	}
}
