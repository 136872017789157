import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';
import { OmniModel } from '../omni-model';

/**
 * This defines the custom field
 */
export class CustomField extends OmniModel<CustomField, Contracts.CustomFieldContract> {
	/**
	 * id for custom field
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}
	/**
	 * holds the custom field id
	 */
	private _customFieldId: SedaruUtils.Undoable<string>;
	get customFieldId(): string {
		if (!this._customFieldId) this._customFieldId = new SedaruUtils.Undoable<string>();
		return this._customFieldId.value;
	}
	set customFieldId(value: string) {
		if (!this._customFieldId) {
			this._customFieldId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldId.value = value;
	}

	/**
	 * holds the custom field value
	 */
	private _customFieldValue: SedaruUtils.Undoable<string>;
	get customFieldValue(): string {
		if (!this._customFieldValue) this._customFieldValue = new SedaruUtils.Undoable<string>();
		return this._customFieldValue.value;
	}
	set customFieldValue(value: string) {
		if (!this._customFieldValue) {
			this._customFieldValue = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldValue.value = value;
	}

	/**
	 * holds the work order key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the custom field type
	 */
	private _customFieldType: SedaruUtils.Undoable<string>;
	get customFieldType(): string {
		if (!this._customFieldType) this._customFieldType = new SedaruUtils.Undoable<string>();
		return this._customFieldType.value;
	}
	set customFieldType(value: string) {
		if (!this._customFieldType) {
			this._customFieldType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldType.value = value;
	}

	/**
	 * holds the custom field name
	 */
	private _customFieldName: SedaruUtils.Undoable<string>;
	get customFieldName(): string {
		if (!this._customFieldName) this._customFieldName = new SedaruUtils.Undoable<string>();
		return this._customFieldName.value;
	}
	set customFieldName(value: string) {
		if (!this._customFieldName) {
			this._customFieldName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldName.value = value;
	}
	/**
	 * holds the code type
	 */
	private _codeType: SedaruUtils.Undoable<string>;
	get codeType(): string {
		if (!this._codeType) this._codeType = new SedaruUtils.Undoable<string>();
		return this._codeType.value;
	}
	set codeType(value: string) {
		if (!this._codeType) {
			this._codeType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._codeType.value = value;
	}

	/**
	 * holds the is custom field required
	 */
	private _isRequired: SedaruUtils.Undoable<boolean>;
	get isRequired(): boolean {
		if (!this._isRequired) this._isRequired = new SedaruUtils.Undoable<boolean>();
		return this._isRequired.value;
	}
	set isRequired(value) {
		if (!this._isRequired) {
			this._isRequired = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._isRequired.value = value;
	}

	/**
	 * holds the is custom field required
	 */
	private _isDefault: SedaruUtils.Undoable<boolean>;
	get isDefault(): boolean {
		if (!this._isDefault) this._isDefault = new SedaruUtils.Undoable<boolean>();
		return this._isDefault.value;
	}
	set isDefault(value) {
		if (!this._isDefault) {
			this._isDefault = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._isDefault.value = value;
	}

	private _isTaskDefault: SedaruUtils.Undoable<boolean>;
	get isTaskDefault(): boolean {
		if (!this._isTaskDefault) this._isTaskDefault = new SedaruUtils.Undoable<boolean>();
		return this._isTaskDefault.value;
	}
	set isTaskDefault(value) {
		if (!this._isTaskDefault) {
			this._isTaskDefault = new SedaruUtils.Undoable<boolean>(value);
			return;
		}
		this._isTaskDefault.value = value;
	}

	private _customFieldGroup: SedaruUtils.Undoable<string>;
	get customFieldGroup(): string {
		if (!this._customFieldGroup) this._customFieldGroup = new SedaruUtils.Undoable<string>();
		return this._customFieldGroup.value;
	}
	set customFieldGroup(value) {
		if (!this._customFieldGroup) {
			this._customFieldGroup = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customFieldGroup.value = value;
	}

	private _sequence: SedaruUtils.Undoable<number>;
	get sequence(): number {
		if (!this._sequence) this._sequence = new SedaruUtils.Undoable<number>();
		return this._sequence.value;
	}
	set sequence(value) {
		if (!this._sequence) {
			this._sequence = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._sequence.value = value;
	}

	static fromContract(contract: Contracts.CustomFieldContract): CustomField {
		const model = new CustomField();
		model.id = contract.ObjectId.toString();
		model.customFieldId = contract.CustomFieldId;
		model.customFieldValue = contract.CustomFieldValue === 'null' || contract.CustomFieldValue === null || contract.CustomFieldValue === undefined ? '' : contract.CustomFieldValue;
		model.workOrderKey = contract.WorkorderKey;
		model.customFieldType = contract.CustomFieldType;
		model.customFieldName = contract.CustomFieldName;
		model.codeType = contract.CodeType;
		model.isRequired = contract.IsRequired;
		model.isDefault = contract.IsDefault;
		model.isTaskDefault = contract.IsTaskDefault;
		model.customFieldGroup = contract.CustomFieldGroup;
		model.sequence = contract.Sequence;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.CustomFieldContract => {
		const contract = new Contracts.CustomFieldContract();
		contract.ObjectId = +this.id;
		contract.CustomFieldId = this.customFieldId;
		contract.CustomFieldValue = this.customFieldValue;
		contract.WorkorderKey = this.workOrderKey;
		contract.CustomFieldType = this.customFieldType;
		contract.CustomFieldName = this.customFieldName;
		contract.CodeType = this.codeType;
		contract.IsRequired = this.isRequired;
		contract.IsDefault = this.isDefault;
		contract.IsTaskDefault = this.isTaskDefault;
		contract.CustomFieldGroup = this.customFieldGroup;
		contract.Sequence = this.sequence;
		return contract;
	};

	onUpdateInformation(copyModel: CustomField): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldId, () => {
				this.customFieldId = copyModel.customFieldId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldValue, () => {
				this.customFieldValue = copyModel.customFieldValue;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldType, () => {
				this.customFieldType = copyModel.customFieldType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldName, () => {
				this.customFieldName = copyModel.customFieldName;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._codeType, () => {
				this.codeType = copyModel.codeType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._isRequired, () => {
				this.isRequired = copyModel.isRequired;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._isDefault, () => {
				this.isDefault = copyModel.isDefault;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._isTaskDefault, () => {
				this.isTaskDefault = copyModel.isTaskDefault;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customFieldGroup, () => {
				this.customFieldGroup = copyModel.customFieldGroup;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._sequence, () => {
				this.sequence = copyModel.sequence;
			}) || wasChanged;
		return wasChanged;
	}
}
