import { ContractBase } from '../contract-base';
import { AssetSpecContract } from './asset-spec-contract';

export class AssetContract extends ContractBase {
    public ObjectId: number;
    public AssetId: string;
    public LocationId: string;
    public Description: string;
    public Status: string;
    public StatusDate: string;
    public InService: number;
    public AssetType: string;
    public AssetSpecs: AssetSpecContract[];
}
