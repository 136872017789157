<mat-card
	class="omni-templated-list-item"
	[class.selected]="selected"
	(click)="onItemClicked()"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
	[ngClass]="{ disabled: resolver.isDisabled(item) }"
>
	<img *ngIf="resolver._getIconPath(item)" mat-card-avatar class="omni-templated-list-item-icon" [src]="resolver._getIconPath(item)" (click)="onLeftIconClicked($event)" />
	<div class="omni-templated-list-item-content">
		<mat-card-title class="omni-templated-list-item-title s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
			{{ resolver._getTopLabel(item) }}
		</mat-card-title>
		<mat-card-subtitle class="omni-templated-list-item-subtitle xs-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
			<span class="omni-templated-list-item-subtitle-1" style="flex:1">{{ resolver._getBottomLeftLabel(item) }}</span>
			<span class="omni-templated-list-item-subtitle-2">
				{{ resolver._getBottomRightLabel(item) }}
			</span>
		</mat-card-subtitle>
		<div *ngIf="connector" class="connection-line"></div>
	</div>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-divider"></mat-divider>
