import { WorkOrderChannelAttributes } from './work-order-channel-attributes.model';
import { LEMMode } from './sedaru-config/lem-mode.enum';
import { WorkOrderField } from './work-order-field.model';

/**
 * This defines the esri work order channel attributes
 */
export class ArcGISWorkOrderChannelAttributes extends WorkOrderChannelAttributes {
	get lemMode(): LEMMode {
		return LEMMode.Standard;
	}

	/**
	 * holds the esri work order layer index
	 */
	workOrderLayerIndex: number;
	/**
	 * holds the esri work asset layer index
	 */
	workAssetLayerIndex: number;
	/**
	 * Holds the asset unique field
	 */
	workAssetMappedAssetUniqueField: string;
	/**
	 * holds the esri work task layer index
	 */
	workTaskLayerIndex: number;
	/**
	 * holds the comleted work task value
	 */
	completedWorkTaskValue: string;
	/**
	 * holds the employee layer index
	 */
	employeeLayerIndex: number;
	/**
	 * holds the material layer index
	 */
	materialLayerIndex: number;
	/**
	 * holds the equipment layer index
	 */
	equipmentLayerIndex: number;
	/**
	 * holds the employee cost layer index
	 */
	employeeCostLayerIndex: number;
	/**
	 * holds the material cost layer index
	 */
	materialCostLayerIndex: number;
	/**
	 * holds the equipment cost layer index
	 */
	equipmentCostLayerIndex: number;

	summaryFields: WorkOrderField[];

	initialize?(attributes: ArcGISWorkOrderChannelAttributes) {
		if (!attributes) return;
		this.workOrderMode = attributes.workOrderMode;
		this.workOrderSystem = attributes.workOrderSystem;
		Object.assign(this.activeWorkOrderValues, attributes.activeWorkOrderValues);
		Object.assign(this.completedWorkOrderValues, attributes.completedWorkOrderValues);
		Object.assign(this.readOnlyWorkOrderValues, attributes.readOnlyWorkOrderValues);
		Object.assign(this.hourlyRates, attributes.hourlyRates);
		this.workOrderLayerIndex = attributes.workOrderLayerIndex;
		this.workAssetLayerIndex = attributes.workAssetLayerIndex;
		this.workAssetMappedAssetUniqueField = attributes.workAssetMappedAssetUniqueField;
		this.workTaskLayerIndex = attributes.workTaskLayerIndex;
		this.completedWorkTaskValue = attributes.completedWorkTaskValue;
		this.employeeLayerIndex = attributes.employeeLayerIndex;
		this.materialLayerIndex = attributes.materialLayerIndex;
		this.equipmentLayerIndex = attributes.equipmentLayerIndex;
		this.employeeCostLayerIndex = attributes.employeeCostLayerIndex;
		this.materialCostLayerIndex = attributes.materialCostLayerIndex;
		this.equipmentCostLayerIndex = attributes.equipmentCostLayerIndex;
		if (attributes.summaryFields) Object.assign(this.summaryFields, attributes.summaryFields);
	}
}
