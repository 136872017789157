import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { HierarchyNode } from 'models/work-order/hierarchy-node.model';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { TwoIconsTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/two-icons-two-lines/two-icons-two-lines.component';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { PNGManager } from 'app/ui-components/png-manager';

@Component({
	selector: 'app-hierarchy-list',
	templateUrl: './hierarchy-list.component.html',
	styleUrls: ['./hierarchy-list.component.scss']
})
export class HierarchyListComponent implements OnInit {
	@ViewChild('ancestorsList', { static: true }) listAncestorComponent: OmniListComponent;
	@ViewChild('childrenList', { static: true }) listChildrenComponent: OmniListComponent;

	@Input() ancestors: Array<HierarchyNode<AssetLocationWithAssets>>;
	@Input() children: Array<HierarchyNode<AssetLocationWithAssets>>;
	@Input() currentNode: HierarchyNode<AssetLocationWithAssets>;

	@Output() leftIconClicked = new EventEmitter();
	@Output() rightIconClicked = new EventEmitter();
	@Output() itemClicked = new EventEmitter();

	private resolvers = new Map<any, any>();

	private defaultImageUrl = 'assets/add-photo.png';

	constructor() {}

	ngOnInit(): void {
		PNGManager.editImage('assets/add-photo.png', { width: 30, height: 30, borderWidth: 1.75 }).then(url => (this.defaultImageUrl = url));
		this.listAncestorComponent.getResolver = node => {
			const key = node?.value?.locationId + node?.value?.description;
			if (!key) return this.getResolver(key);
			if (this.resolvers.has(key)) return this.resolvers.get(key);
			return this.getResolver(key);
		};
		this.listChildrenComponent.getResolver = node => {
			const key = node?.value?.locationId + node?.value?.description;
			if (!key) return this.getResolver(key);
			if (this.resolvers.has(key)) return this.resolvers.get(key);
			return this.getResolver(key);
		};
	}

	private getResolver(key: any): ListItemResolver {
		const listResolver = TwoIconsTwoLinesComponent.createResolver();
		listResolver.getLeftIconPath = node => this.getLeftIconPath(node);
		listResolver.getRightIconPath = node => this.getRightIcon(node);
		listResolver.getTopLabel = node => this.getDescription(node);
		listResolver.getBottomLeftLabel = node => this.getLocationId(node);
		listResolver.getBottomRightLabel = node => '';
		listResolver.getLeftBorderStyle = node => '1px solid #b4b4b4';
		listResolver.getBottomBorderStyle = node => '1px solid #b4b4b4';
        listResolver.getBadgeLabel = node => this.getBadgeLabel(node);
        listResolver.getBadgeBackgroundColor = node => '#ec8938';
		listResolver.onShowLeftBorder = node => node && node.isChild;
		listResolver.onShowBottomBorder = node => true;
		listResolver.onIsForceSelected = node => node && node.value && node.value === this.currentNode.value;
		listResolver.showDivider = node => false;
		listResolver.onShowLeftImageBorder = node => !this.isDefaultImage(node);
		listResolver.getLeftImageBorderStyle = node => '2px solid white';
		this.resolvers.set(key, listResolver);
		return listResolver;
	}

	getLeftIconPath(node: HierarchyNode<AssetLocationWithAssets>): string {
		const thumbnailUrl = node?.value?.attachment?.thumbnailUrl;
		const attachmentUrl = node?.value?.attachment?.url;
		if (thumbnailUrl) return thumbnailUrl;
		else if (attachmentUrl) return attachmentUrl;
		else return this.defaultImageUrl;
	}

	isDefaultImage(node: HierarchyNode<AssetLocationWithAssets>): boolean {
		const thumbnailUrl = node?.value?.attachment?.thumbnailUrl;
		const attachmentUrl = node?.value?.attachment?.url;
		return !thumbnailUrl && !attachmentUrl;
	}

	getRightIcon(node: HierarchyNode<AssetLocationWithAssets>): string {
		if (!node || !node.value) return null;
		const infoIcon = 'assets/information.png';
		const nextIcon = 'assets/next-circled.png';
		const pinIcon = 'assets/pin.png';

		if (!node.isChild) {
			if (node.value.hasAsset) return infoIcon;
			return pinIcon;
		}

		if (node.hasChildren) return nextIcon;
		else if (node.value.hasAsset) return infoIcon;
		else return pinIcon;
	}

	getDescription(node: HierarchyNode<AssetLocationWithAssets>): string {
		return node ? node.value.description : '';
	}

	getLocationId(node: HierarchyNode<AssetLocationWithAssets>): string {
		return node ? node.value.locationId : '';
	}

	getBadgeLabel(node: HierarchyNode<AssetLocationWithAssets>): string {
		if (!node || !node.value) return;
		return node.isChild ? node.value.childWorkOrderCountString : node.value.workOrdersCountString;
	}
}
