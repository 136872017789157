import { FormControlDefinitions } from './form-control-definitions.model';
import { FormPageDefinition } from './form-page-definition.model';

export class FormControlGroup {
	legacyId: number;
	index: number;
	title: string;

	constructor(parent: FormPageDefinition) {
		this._parent = parent;
	}

	private _parent: FormPageDefinition;
	public get parent(): FormPageDefinition {
		return this._parent;
	}

	private _controls: FormControlDefinitions;
	get controls(): FormControlDefinitions {
		if (!this._controls) {
			this._controls = new FormControlDefinitions();
		}

		return this._controls;
	}
	set controls(value: FormControlDefinitions) {
		this._controls = value;
	}
}
