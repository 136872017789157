<div class="workorder-lem">
	<div class="list-header">
		<span>{{ lemMenuPanelHeader }}</span>
		<mat-divider class="divider-item"></mat-divider>
	</div>

	<app-search-list-component
		[searchController]="testController"
		[header]="searchFieldHeader"
		[backgroundColor]="'orange-background'"
		(itemSelected)="itemSelected.emit($event)"
		[height]="'calc(100% - 158px)'"
	></app-search-list-component>

	<div class="lem-fields">
		<app-menu-panel-field [header]="field1Header" [width]="'half'" [cssClass]="'light-gray-background'" [value]="field1Value" [type]="'text'" [disabled]="true"></app-menu-panel-field>
		<app-menu-panel-field
			[header]="field2Header"
			[width]="'half'"
			[cssClass]="'light-gray-background'"
			[(value)]="field2Value"
			(valueChange)="valueChanged($event)"
			[type]="'number'"
			[maxNumber]="field2Header === 'hours' ? 1000 : ''"
			[minNumber]="field2Header === 'hours' ? -1000 : ''"
		></app-menu-panel-field>
		<app-menu-panel-field
			*ngIf="taskEnabled"
			[header]="'task'"
			[cssClass]="'orange-background'"
			[type]="'text'"
			[disabled]="true"
			[buttonEnabled]="true"
			[goTo]="goToTask"
			[value]="taskName"
		></app-menu-panel-field>
		<div class="clear-both"></div>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" (click)="onDelete()">delete <img class="omni-sm-img" src="assets/delete.png" /></button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
