import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { FileIndexContract } from 'contracts/file-index-contract';
import { throwError, Subject} from 'rxjs';
import { FileIndexResponseContract } from 'contracts/file-index-response-contract';
import { FileStatusContract } from 'contracts/file-status-contract';
import { IHttpResponse } from 'domain-service/http-response.model';
import { ContractResponse } from 'contracts/work-order';
import { FileResponseContract } from 'contracts/file-response-contract';
import { QueryFileIndexesContract } from 'contracts/query-file-indexes-contract';
import { MembershipProviderRequest } from '../../user/membership-provider-request.model';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import { UserService } from 'app/user/user.service';
import { Queue } from 'sedaru-util/queue';
import { AdvancedWorkOrder, Attachment, WorkOrderWrapper } from 'models/work-order';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';

@Injectable({
	providedIn: 'root'
})

/**
 * Allows to upload attachements to S3 bucket
 */
export class UploaderService {
	/**
	 * uploader base url which will be provided from env variable
	 */
	baseUrl: string;

	/**
	 * holds the token string for the AWO api
	 */
	token: string;

	private isRunning = false;

	uploadResponse$ = new Subject<AttachmentUpload>();

	private _uploadQueue: Queue<AttachmentUpload>;
	private get uploadQueue(): Queue<AttachmentUpload> {
		if (!this._uploadQueue) {
			this._uploadQueue = new Queue<AttachmentUpload>();
			return this._uploadQueue;
		}

		return this._uploadQueue;
	}

	/**
	 * This customer code is to be replaced by userService.currentUser.customerCode
	 */
	get customerCode(): string {
		return this.userService.currentUser.customerCode;
	}

	/**
	 * Allows the HttpService and dependencies to be injected into the class
	 * @param {HttpService} http - HttpService object
	 */
	constructor(
		private http: HttpClient,
		private userService: UserService
		) {
		this.baseUrl = environment.smartUploaderUrl;
		this.token = this.userService.getToken ? this.userService.getToken() : '';
	}

	enqueueImage(attachmentUpload: AttachmentUpload): void {
		if (!attachmentUpload) return;

		this.uploadQueue.enqueue(attachmentUpload);
		this.run();
	}

	private async run() {
		if (this.uploadQueue.isEmpty() || this.isRunning) return;

		this.isRunning = true;
		const attachmentUpload = this.uploadQueue.dequeue();

		try {
			attachmentUpload.uploadAttempts++;
			const uploadResponse = await this.uploadAttachement(attachmentUpload).toPromise();
			attachmentUpload.response = uploadResponse;
			if (!uploadResponse || !uploadResponse.Success) return;
			await attachmentUpload.saveAttachment(attachmentUpload);
		} finally {
			if (attachmentUpload.isSuccess || attachmentUpload.uploadAttempts > 3) this.uploadResponse$.next(attachmentUpload);
			else this.uploadQueue.enqueue(attachmentUpload);
			this.isRunning = false;
			this.run();
		}
	}

	uploadAttachement(attachmentUpload: AttachmentUpload) {
		const fileObject: any = attachmentUpload.file;
		const fileIndexObject = new FileIndexContract();
		fileIndexObject.CustomerCode = this.customerCode;
		fileIndexObject.UserName = 'testuser';
		fileIndexObject.FileType = 'public';
		fileIndexObject.FileName = fileObject.name;
		fileIndexObject.FileSize = fileObject.size;
		fileIndexObject.Description = '';

		return this.postFileIndex(attachmentUpload, fileIndexObject);
	}

	/**
	 * post file index
	 */
	private postFileIndex(attachmentUpload: AttachmentUpload, fileIndexObject: FileIndexContract) {
		const request = new MembershipProviderRequest();
		request.Data = fileIndexObject;
		request.Token = this.token;
		return this.http.post(this.baseUrl + '/PostFileIndex', request).pipe(
			switchMap((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const fileIndexResponse: FileIndexResponseContract = response.Result;
				return this.uploadToS3Bucket(attachmentUpload, fileIndexResponse);
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * upload file to s3
	 */
	private uploadToS3Bucket(attachmentUpload: AttachmentUpload, fileIndexResponse: FileIndexResponseContract) {
		const file: any = attachmentUpload.file;
		const contentType = file.type ? file.type : 'image/png';
		const headers = new HttpHeaders({ 'Content-Type': contentType });
		return this.http.put(fileIndexResponse.UploadUrl, file, { headers }).pipe(
			switchMap((response) => {
				const fileStatus = new FileStatusContract();
				fileStatus.CustomerCode = this.customerCode;
				fileStatus.FileIndexId = fileIndexResponse.FileIndexId;
				fileStatus.Compress = false;
				fileStatus.CreateThumbnail = this.isFileImage(file);
				fileStatus.FileType = 'public';

				return this.postSuccessFileStatus(fileStatus);
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * post success file status
	 */
	private postSuccessFileStatus(fileStatus: FileStatusContract) {
		const request = new MembershipProviderRequest();
		request.Data = fileStatus;
		request.Token = this.token;

		return this.http.post(this.baseUrl + '/PostSuccessFileStatus', request).pipe(
			mergeMap((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				const fileIndexList = [fileStatus.FileIndexId]

				return this.getPublicFileIndexes(fileIndexList);
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * Get file by file Index Id
	 */
	public getFileIndexes(fileIndexList: number[]) {
		const request = new MembershipProviderRequest();
		request.Data = {
			CustomerCode: this.customerCode,
			Ids: fileIndexList
		};
		request.Token = this.token;
		return this.http.post(this.baseUrl + '/GetPublicFileIndexes', request).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const fileStatusResponse: { Files: FileResponseContract[] } = response.Result;
				const result = new ContractResponse<{ Files: FileResponseContract[] }>();
				result.Success = response.Success;
				result.Result = fileStatusResponse;

				return result;
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * Get file by Query Parameters
	 */
	public queryFileIndexes(queryFileIndexes: QueryFileIndexesContract) {
		const body: any = {};
		body['Data'] = queryFileIndexes;
		body['Token'] = this.token;
		return this.http.post(this.baseUrl + '/QueryFileIndexes', body).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const fileStatusResponse: { Files: FileResponseContract[] } = response.Result;
				const result = new ContractResponse<{ Files: FileResponseContract[] }>();
				result.Success = response.Success;
				result.Result = fileStatusResponse;

				return result;
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * Get public file by file Index Id
	 */
	public getPublicFileIndexes(fileIndexList: number[]) {
		const body = new MembershipProviderRequest();
		body.Data = {
			CustomerCode: this.customerCode,
			Ids: fileIndexList
		};
		body.Token = this.token;
		return this.http.post(this.baseUrl + '/GetPublicFileIndexes', body).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const fileStatusResponse: { Files: FileResponseContract[] } = response.Result;
				const result = new ContractResponse<{ Files: FileResponseContract[] }>();
				result.Success = response.Success;
				result.Result = fileStatusResponse;

				return result;
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * Post file path
	 */
	public postFilePath(fileIndexContract: FileIndexContract) {
		const body: any = {};
		body['Data'] = fileIndexContract;
		body['Token'] = this.token;
		return this.http.post(this.baseUrl + '/PostFilePath', body).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const fileIndexResponse: FileIndexResponseContract = response.Result;
				const result = new ContractResponse<FileIndexResponseContract>();
				result.Success = response.Success;
				result.Result = fileIndexResponse;
				return result;
			}),
			catchError(err => this.handlerError(err))
		);
	}

	/**
	 * Delete from FileIndex
	 */
	public deleteFileIndex(fileIndexList: string[]) {
		const request = new MembershipProviderRequest();
		request.Data = {
			CustomerCode: this.customerCode,
			FileIndexId: fileIndexList
		};
		request.Token = this.token;

		return this.http.post(this.baseUrl + '/DeleteFileIndex', request).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse();
				result.Success = response.Success;
				result.Result = response.Result;

				return result;
			}),
			catchError(err => this.handlerError(err))
		);
	}

	handlerError(error) {
		console.error(error);
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		AnalyticsHub.current.trackError(error);

		return throwError(errorMessage);
	}

	private isFileImage = (file) => {
		return file && file['type'].split('/')[0] === 'image';
	}
}

export abstract class AttachmentUpload {
	constructor(file: File, saveAttachment: (attachmentUpload: AttachmentUpload) => Promise<AttachmentUpload>) {
		this.file = file;
		this.saveAttachment = saveAttachment;
		this.isSuccess = false;
		this.uploadAttempts = 0;
	}

	isSuccess: boolean;

	uploadAttempts: number;

	file: File;

	attachmentModel: Attachment;

	response: ContractResponse<{ Files: FileResponseContract[] }>;

	saveAttachment = (attachmentUpload: AttachmentUpload): Promise<AttachmentUpload> => {
		return new Promise<AttachmentUpload>((resolve, reject) => {
			resolve(null);
		});
	}

	refreshAttachmentModel(): void {

	}
}

export class WorkOrderAttachmentUpload extends AttachmentUpload {
	workOrder: WorkOrderWrapper;

	constructor(workOrder: WorkOrderWrapper, file: File, saveAttachment: (attachmentUpload: AttachmentUpload) => Promise<AttachmentUpload>) {
		super(file, saveAttachment);
		this.workOrder = workOrder;
	}
}

export class AssetLocationAttachmentUpload extends AttachmentUpload {
	assetLocation: AssetLocationWithAssets;

	constructor(assetLocation: AssetLocationWithAssets, file: File, saveAttachment: (attachmentUpload: AttachmentUpload) => Promise<AttachmentUpload>) {
		super(file, saveAttachment);
		this.assetLocation = assetLocation;
	}

	refreshAttachmentModel(): void {
		if (!this.assetLocation) return;
		else if (!this.assetLocation.attachment) this.assetLocation.attachment = this.attachmentModel;
		else this.assetLocation.attachment.updateInformation(this.attachmentModel);
	}
}
