import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormPageComponent } from './form-page/form-page.component';
import { ControlGroupComponent } from './control-group/control-group.component';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';
import { FormFooterFieldComponent } from './form-footer-field/form-footer-field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { InfoFormComponent } from './info-form/info-form.component';
import { HistoryRecordsFormComponent } from './history-records-form/history-records-form.component';
import { HistoryRecordAssetListComponent } from './history-records-form/history-record-asset-list/history-record-asset-list.component';

@NgModule({
  declarations: [FormPageComponent, ControlGroupComponent, FormFooterFieldComponent, DynamicFormComponent, InfoFormComponent, HistoryRecordsFormComponent, HistoryRecordAssetListComponent],
  imports: [
    CommonModule, UiComponentsModule
  ],
  exports: [DynamicFormComponent, InfoFormComponent, HistoryRecordsFormComponent]
})
export class DynamicFormsModule { }
