<div class="control-group">
	<div class="control-group-header">
		<p class="title">{{ formResolver.getControlGroupHeader(controlGroup) }}</p>
		<img
			class="omni-sm-img"
			*ngIf="formResolver.getControlGroupHeaderIcon"
			[src]="formResolver.getControlGroupHeaderIcon().url"
			(click)="formResolver.getControlGroupHeaderIcon().action(formResolver.getControlGroupHeaderIcon().asset)"
		/>
	</div>
	<ng-container *ngFor="let control of controlGroup.controls">
		<app-menu-panel-field *ngIf="!isAttachmentControl(control)"
			[header]="formResolver.getControlHeader(control)"
			[placeHolder]="formResolver.getControlPlaceholder(control)"
			[value]="formResolver.getControlValue(control)"
			[width]="formResolver.getControlWidth(control)"
			[height]="formResolver.getControlHeight(control)"
			[backgroundColor]="formResolver.getControlBackground(control, formGrid)"
			[textColor]="formResolver.getControlTextColor(control, formGrid)"
			[disabled]="formResolver.disableFormControls()"
			[showTrendIcon]="formResolver.showTrendIcon(control)"
			[enableTrendIcon]="formResolver.enableTrendIcon(control)"
			(trendIconSelected)="formResolver.trendIconSelected(control)"
			[leftMargin]="formResolver.getMarginLeft(control, formGrid)"
			[valueType]="formResolver.getControlValueType(control)"
		></app-menu-panel-field>
	</ng-container>
</div>
