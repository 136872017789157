import { AssetDefinition } from '../../models/asset-definition.model';
import { ArcGISAssetChannelAttributes } from '../../models';
import { Record } from './record.model';
import { Feature } from '../../sedaru-util/esri-core/feature';

export class AssetRecord extends Record {
	public get assetType(): string {
		if (!this._assetDefinition) return null;
		return this._assetDefinition.assetType;
	}

	public get definitionTitle(): string {
		return this._assetDefinition.title;
	}

	private _assetDefinition: AssetDefinition;
	private get assetChannelAttributes(): ArcGISAssetChannelAttributes {
		if (!this._assetDefinition) return null;
		return this._assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
	}

	public get layerId() {
		if (!this._assetDefinition) return null;
		return this.assetChannelAttributes['mapServiceLayerIndex'];
	}

	public get assetId(): number | string {
		if ((this.feature?.attributes) && (this._assetDefinition)) {
			const uniqueFieldName = this.assetChannelAttributes.uniqueFieldName.toLowerCase();
			for (const key of Object.keys(this.feature.attributes)) {
				// unique field name and feature name do not have the same casing
				if (key.toLowerCase() === uniqueFieldName) {
					return this.feature.attributes[key];
				}
			}
		}
		return null;
	}

	public get assetDefinition(): AssetDefinition {
		return this._assetDefinition;
	}

	private constructor(_feature: Feature = null) {
		super(_feature);
	}

	public static create(assetDefinition: AssetDefinition, feature: Feature = null): AssetRecord {
		const assetRecord = new AssetRecord(feature);
		assetRecord._assetDefinition = assetDefinition;
		return assetRecord;
	}
}
