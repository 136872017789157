import { Component, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import {  Metric, MetricTile } from 'models';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { TimeframeField } from 'models/time-frame/timeframe-query.model';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { ContextualSearch, ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { UserTimeframeOption } from 'models/time-frame/user-timeframe-option.model';

/**
 * This component is designed to loaded when the Query field in the add/edit metric panel is selected.
 * It can be used to create custom AGS queries.
 */
@Component({
	selector: 'app-timeframe-builder',
	templateUrl: './timeframe-builder.component.html',
	styleUrls: ['./timeframe-builder.component.scss']
})
export class TimeframeBuilderComponent extends MenuPanelBaseComponent {

	timeframeBuilderField: TimeframeField;

	panelHeight: number;

	timeframeFilter: TimeframeFilter;
	timeFrameType: string;
	currentMetric: Metric;
	currentTile: MetricTile;

	public get userTimeframeList(): Array<any> {
		return this.contextualSearch.getSearch<any>().results;
	}

	@ViewChild('panel', { static: true }) panel: ElementRef;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 */
	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.timeframeBuilderField = args.parameter.timeframeBuilderField;
		this.timeframeFilter = args.parameter.timeframeFilter;
		this.timeFrameType = args.parameter.timeFrameType ? args.parameter.timeFrameType : 'tile';
		this.currentMetric = args.parameter.metric;
		this.currentTile = args.parameter.tile;
	}

	/**
	 * On init, the current route/URL is emitted.
	 */
	ngOnInit() {
		if (this.timeframeBuilderField) {
			this.timeframeBuilderField = this.timeframeBuilderField;
		} else {
			this.timeframeBuilderField = this.createNewTimeframeField();
		}

		this.menuPanelComponent.updateView({ title: 'timeframe filter..'});

		this.panelHeight = this.panel.nativeElement.offsetHeight;
		console.log('this.panelHeight', this.panelHeight);
	}

	ngOnDestroy() {
	}

	/**
	 * create a new timeframe field
	 */
	createNewTimeframeField(): TimeframeField {
		return new TimeframeField();
	}

	save() {
		const timeframeField = this.timeframeBuilderField;
		const newfilter = new TimeframeFilter();
		newfilter.timeFrame = new Timeframe(timeframeField.timeframeDefinition);
		newfilter.timeframeField = this.timeframeFilter.timeframeField;
		NavigationService.navigateBackTo(Pages.timeframefilter, {
			timeFrameType: this.timeFrameType,
			timeframeFilter: newfilter,
			tile: this.currentTile,
			metric: this.currentMetric
		});
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (tileTimeFrame: any, searchText) => {
			if (tileTimeFrame.name.toLowerCase().includes(searchText.toLowerCase())) return true;

			return false;
		};
		const userTimeframeList = UserTimeframeOption.userOptions.map((option) => {
			return {name: option.text, type: option.type, timeframeType: option.timeframeType }
		});
		const contextualSearch = new ContextualSearch(userTimeframeList, searchFunction);
		contextualSearch.title = 'search timeframe';
		return contextualSearch;
	}
}
