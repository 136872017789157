import { Component, Input, OnInit } from '@angular/core';
import { FormDefinition } from 'models';
import { BaseFormResolver } from '../form-resolvers/base-form.resolver';

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
	@Input() formDefinition: FormDefinition;
	@Input() formResolver: BaseFormResolver;

	private visibilities = [];

	ngOnInit() {
		this.formDefinition.pages.forEach((p, i) => {
			if (i === 0) this.visibilities.push('active');
			else this.visibilities.push('hidden-right');
		});
	}

	getPageVisibility(i: number) {
		return this.visibilities[i];
	}

	setActivePage(activeIndex: number) {
		for (let i = 0; i < this.visibilities.length; i++) {
			if (i < activeIndex) {
				this.visibilities[i] = 'hidden-left';
				continue;
			}
			if (i === activeIndex) {
				this.visibilities[i] = 'active';
				continue;
			}
			this.visibilities[i] = 'hidden-right';
		}
	}
}
