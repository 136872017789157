import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { DailyPatternType } from './daily-pattern-type.enum';
import { WeeklyPattern } from './weekly-pattern.model';
import { MonthlyPattern } from './monthly-pattern.model';
import { YearlyPattern } from './yearly-pattern.model';
import { RecurrencePatternType } from './recurrence-pattern-type.enum';

export class Pattern extends OmniModel<Pattern, Contracts.PatternContract> {
    constructor() {
        super();
        this.dailyPattern = DailyPatternType.Interval;
    }

    private _interval: SedaruUtils.Undoable<number>;
    get interval(): number {
        if (!this._interval) this._interval = new SedaruUtils.Undoable<number>(1);
        return this._interval.value;
    }
    set interval(value: number) {
        if (!this._interval) {
            this._interval = new SedaruUtils.Undoable<number>(value);
            return;
        }
        this._interval.value = value;
    }

    private _dailyPattern: SedaruUtils.Undoable<DailyPatternType>;
    get dailyPattern(): DailyPatternType {
        if (!this._dailyPattern) this._dailyPattern = new SedaruUtils.Undoable<DailyPatternType>(DailyPatternType.Interval);
        return this._dailyPattern.value;
    }
    set dailyPattern(value: DailyPatternType) {
        if (!this._dailyPattern) {
            this._dailyPattern = new SedaruUtils.Undoable<DailyPatternType>(value);
            return;
        }
        this._dailyPattern.value = value;
    }

    private _weeklyPattern: WeeklyPattern;
    get weeklyPattern(): WeeklyPattern {
        return this._weeklyPattern ? this._weeklyPattern : (this._weeklyPattern = new WeeklyPattern());
    }
    set weeklyPattern(value: WeeklyPattern) {
        if (this._weeklyPattern === value) return;
        this._weeklyPattern = value;
    }

    private _monthlyPattern: MonthlyPattern;
    get monthlyPattern(): MonthlyPattern {
        return this._monthlyPattern ? this._monthlyPattern : (this._monthlyPattern = new MonthlyPattern());
    }
    set monthlyPattern(value: MonthlyPattern) {
        if (this._monthlyPattern === value) return;
        this._monthlyPattern = value;
    }

    private _yearlyPattern: YearlyPattern;
    get yearlyPattern(): YearlyPattern {
        return this._yearlyPattern ? this._yearlyPattern : (this._yearlyPattern = new YearlyPattern());
    }
    set yearlyPattern(value: YearlyPattern) {
        if (this._yearlyPattern === value) return;
        this._yearlyPattern = value;
    }

    static fromContract(contract: Contracts.PatternContract): Pattern {
        const model = new Pattern();
        model.interval = contract.interval;
        if (contract.dailyPattern) model.dailyPattern = contract.dailyPattern as DailyPatternType;
        if (contract.weeklyPattern) model.weeklyPattern = WeeklyPattern.fromContract(contract.weeklyPattern);
        if (contract.monthlyPattern) model.monthlyPattern = MonthlyPattern.fromContract(contract.monthlyPattern);
        if (contract.yearlyPattern) model.yearlyPattern = YearlyPattern.fromContract(contract.yearlyPattern);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.PatternContract {
        const contract = new Contracts.PatternContract();
        contract.interval = this.interval;
        contract.dailyPattern = this.dailyPattern;
        contract.weeklyPattern = this.weeklyPattern.getContract();
        contract.monthlyPattern = this.monthlyPattern.getContract();
        contract.yearlyPattern = this.yearlyPattern.getContract();

        return contract;
    }

    onUpdateInformation(copyModel: Pattern): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<number>(this._interval, () => {
                this.interval = copyModel.interval;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<DailyPatternType>(this._dailyPattern, () => {
                this.dailyPattern = copyModel.dailyPattern;
            }) || wasChanged;

        wasChanged = this.weeklyPattern.updateInformation(copyModel.weeklyPattern) || wasChanged;
        wasChanged = this.monthlyPattern.updateInformation(copyModel.monthlyPattern) || wasChanged;
        wasChanged = this.yearlyPattern.updateInformation(copyModel.yearlyPattern) || wasChanged;

        return wasChanged;
    }
}
