/**
 * This defines the chanel attributes details
 */
export abstract class ChannelAttributes {
    abstract getIdFieldName(): string;

        /**
     * Get the id value for the given record/channel combination.
     * @param record
     * @returns
     */
    public getRecordId(record: any) {
        return this.getRecordIdFieldAndValue(record);
    }

    /**
     * Get the id value for the given record/channel combination.
     * @param record
     * @returns
     */
    public getRecordIdFieldAndValue(record: any) {

		let idFieldName: string = this.getIdFieldName();
		if (!idFieldName) {
			throw new Error('Id field not defined for channel attribute');
		}
		let value;
		if (record) {
			let objToCheck;
			if (record.feature) {
				objToCheck = record.feature?.attributes;
			} else {
				objToCheck = record.attributes ?? record;
			}

			if (objToCheck) {
				if (idFieldName in objToCheck) {
					value = objToCheck[idFieldName];
				} else {
                    const lowerCaseIdFieldName = idFieldName.toLowerCase();
					for (const property in objToCheck) {
						if (property.toLowerCase() == lowerCaseIdFieldName) {
							value = objToCheck[property];
                            idFieldName = property;
						}
					}
				}
			}
		}

		return  {fieldName: idFieldName, value: value };
	}

}
