import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { ListTemplates } from 'app/ui-components/omni-list/list-item/templates/templates.enum';
@Component({
	selector: 'app-map-tooltip',
	templateUrl: './map-tooltip.component.html',
	styleUrls: ['./map-tooltip.component.scss']
})
export class MapTooltipComponent {
	@Input() mapView;

	@Input() mapWrapperElement: HTMLElement;

	@ViewChild('tooltipContent', { static: false }) tooltipContent: ElementRef;

	location;

	resolver: ListItemResolver;

	get contentWidth() {
		if (this.resolver && this.resolver.templateType === ListTemplates.leftIconTwoLines) return '300px';
		return 'auto';
	}

	private _containerWidth = 300;
	get containerWidth() {
		if (!this.tooltipContent || !this.tooltipContent.nativeElement) return this._containerWidth;
		return this.tooltipContent.nativeElement.offsetWidth;
	}
	private _containerHeight = 50;
	get containerHeight() {
		if (!this.tooltipContent || !this.tooltipContent.nativeElement) return this._containerHeight;
		return this.tooltipContent.nativeElement.offsetHeight;
	}

	private _containerX: number;

	private _containerY: number;

	private _tipOffsetX: number;

	private _bottomTip: boolean;
	get bottomTip() {
		return this._bottomTip;
	}

	private _show: boolean;
	get show() {
		return this.resolver && this.location && this._show;
	}

	getContainerPosition() {
		try {
			this.calculateLocation();
		} catch (error) {
			console.error(error);
			this.close();
		}
		return { left: this._containerX + 'px', top: this._containerY + 'px' };
	}
	getTipOffsetX() {
		return { left: this._tipOffsetX + 'px' };
	}

	close() {
		this.resolver = null;
		this.location = null;
		this._show = false;
	}

	open() {
		this._show = true;
	}

	private calculateLocation() {
		const { x, y } = this.mapView.toScreen(this.location);
		let topTipX, topTipY, bottomTipX, bottomTipY;
		// if location is on the top half of the map use the bottom tip
		if (y <= this.mapWrapperElement.offsetHeight / 2) {
			topTipX = x - 10;
			topTipY = y;
			this._bottomTip = false;
		} else {
			bottomTipX = x - 10;
			bottomTipY = y - 20;
			this._bottomTip = true;
		}
		// if location is too close to the left border move container to the right
		if (x - this.containerWidth / 2 <= this.containerWidth / 2) this._containerX = x - 30;
		// if location is too close to the right border, move container to the left
		else if (x >= this.mapWrapperElement.offsetWidth - this.containerWidth / 2) this._containerX = x - this.containerWidth + 30;
		else this._containerX = x - this.containerWidth / 2;
		this._containerY = this._bottomTip ? bottomTipY - this.containerHeight : topTipY + 10;

		// convert tip coordinates to relative to the container
		if (this._bottomTip) this._tipOffsetX = bottomTipX - this._containerX;
		else this._tipOffsetX = topTipX - this._containerX;
	}
}
