import { TransformationResult } from './transformation-result';
import { GlobalConfig } from '../../../models/global-config.model';

export abstract class TransformBase {
	private _fieldforceConfig: any;
	protected get fieldforceConfig(): any {
		return this._fieldforceConfig;
	}
	private _globalConfig: GlobalConfig;
	protected get globalConfig(): GlobalConfig {
		return this._globalConfig;
	}

	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig) {
		this._fieldforceConfig = fieldforceConfig;
		this._globalConfig = globalConfiguration;
	}

	transform(): TransformationResult {
		if (!this.fieldforceConfig) {
			return this.getResult(true, 'source config is missing');
		}

		return this.onTransform();
	}
	protected abstract onTransform(): TransformationResult;

	protected getResult(success: boolean, message: string = ''): TransformationResult {
		const result = new TransformationResult();

		result.success = success;
		result.message = message;

		return result;
	}

	protected logMessage(object: any) {
		console.log(object);
	}

	protected getServiceConfig(dataSourceId: number): any {
		if (!this.fieldforceConfig || !this.fieldforceConfig.Services) {
			return null;
		}

		for (const service of this.fieldforceConfig.Services) {
			if (service.ID !== dataSourceId) {
				continue;
			}

			return service;
		}

		return null;
	}
}
