import { Component, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { QueryStatementList, QueryStatement, QueryField, ArcGISAssetChannelAttributes, QueryMode, QueryStatementMode, QueryOperator, ChannelTypes, Metric } from 'models';
import { QueryStatementComponent } from '../query-statement/query-statement.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { AGSQuery } from 'models/ags-query.model';

/**
 * This component is designed to loaded when the Query field in the add/edit metric panel is selected.
 * It can be used to create custom AGS queries.
 */
@Component({
	selector: 'app-query-builder',
	templateUrl: './query-builder.component.html',
	styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the query statement list
	 */
	queryStatementList = [];

	tempStatementList = [];

	/**
	 * If or not we are going to show the footter of the query builder
	 */
	showFooter = true;

	metric: Metric;

	panelHeight: number;

	/**
	 * Able to view the children component (The query statement componenets)
	 */
	@ViewChildren(QueryStatementComponent) queryStatementComponentList: QueryList<QueryStatementComponent>;

	@ViewChild('panel', { static: true }) panel: ElementRef;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {MetricService} - The metric service holds all the avialable metric
	 */
	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
		this.tempStatementList = args.parameter.queryStatementList;
	}

	/**
	 * On init, the current route/URL is emitted.
	 */
	ngOnInit() {
		if (this.tempStatementList && this.tempStatementList.length) {
			this.queryStatementList = this.tempStatementList;
		} else {
			this.queryStatementList = this.createNewQueryStatementList();
		}

		this.menuPanelComponent.updateView({ title: 'query builder', rightIcon: { url: 'assets/edit.png', toolTip: 'manual query', callBackFunction: this.goToManualQuery } });

		this.panelHeight = this.panel.nativeElement.offsetHeight;
	}

	ngOnDestroy() {
		this.deleteIncompleteQueryFields();
	}

	deleteIncompleteQueryFields() {
		const { queryStatementList } = this.metric.definition.query as AGSQuery;
		for (const queryStatement of queryStatementList) {
			if (!queryStatement.queryFieldList || !queryStatement.queryFieldList.length) continue;
			const index = queryStatement.queryFieldList.findIndex(queryField => !queryField.join);
			if (index > -1) queryStatement.queryFieldList.splice(index, 1);
			const lastQueryField = queryStatement.queryFieldList[queryStatement.queryFieldList.length - 1];
			if (lastQueryField && lastQueryField.join.name !== QueryOperator.forJoin.doneOperator.name) lastQueryField.join = QueryOperator.forJoin.doneOperator;
		}
		const invalidQueryStatementList = queryStatementList.filter(queryStatement => !queryStatement.queryFieldList || !queryStatement.queryFieldList.length);
		if (!invalidQueryStatementList.length) return;
		for (const invalidQueryStatement of invalidQueryStatementList) {
			queryStatementList.splice(invalidQueryStatement.index, 1);
		}
	}

	/**
	 * create a new query statement list
	 */
	createNewQueryStatementList(): QueryStatementList {
		this.showFooter = false;
		const newQueryStatement = new QueryStatement();
		if (!this.metric.definition.historyChannel) {
			this.setQueryStatementNoHistory(newQueryStatement);
		}
		const queryStatementList = new QueryStatementList();
		queryStatementList.push(newQueryStatement);
		return queryStatementList;
	}

	/**
	 * On query statement view mode change, if not all query statement component is in view mode,
	 * still hide the footer
	 */
	onModeChange = () => {
		const notAllChildInViewMode = this.queryStatementComponentList.find(queryStatementComponent => queryStatementComponent.mode === QueryStatementMode.edit);
		this.showFooter = notAllChildInViewMode ? false : true;
	};

	/**
	 * On any query statement source change
	 */
	onQueryStatementSourceChange = (currentQueryStatement: QueryStatement) => {
		this.reinsertTheCurrentQueryStatementToSibling(currentQueryStatement);
		this.disableQueryStatementJoinIfApply(currentQueryStatement);
		this.enableQueryStatementJoinIfApply(currentQueryStatement);
	};

	/**
	 * reinserts the query statement to the end of its sibilg group
	 * @param {QueryStatement} currentQueryStatement - current query statement in interaction
	 */
	reinsertTheCurrentQueryStatementToSibling(currentQueryStatement: QueryStatement) {
		const sameSourceTypeQueryStatementList = this.queryStatementList.filter((queryStatement: QueryStatement) => queryStatement.source.name === currentQueryStatement.source.name);
		const { length } = sameSourceTypeQueryStatementList;

		if (length <= 1) return;

		const lastQueryStatementWithTheSameSource = sameSourceTypeQueryStatementList[length - 2];
		const { index } = lastQueryStatementWithTheSameSource;
		this.queryStatementList.splice(currentQueryStatement.index, 1);
		this.queryStatementList.splice(index + 1, 0, currentQueryStatement);
		this.reorderQueryStatementIndex();
	}

	/**
	 * disable the query statement join when apply
	 * @param {QueryStatement} currentQueryStatement - current query statement in interaction
	 */
	disableQueryStatementJoinIfApply(currentQueryStatement: QueryStatement) {
		const previousQueryStatement = this.queryStatementList[currentQueryStatement.index - 1];
		if (currentQueryStatement.source.value >= 0 && previousQueryStatement.source.value !== currentQueryStatement.source.value) {
			currentQueryStatement.disableJoin = true;
			delete currentQueryStatement.join;
		}
	}

	/**
	 * enable the query statement join when apply
	 * @param {QueryStatement} currentQueryStatement - current query statement in interaction
	 */
	enableQueryStatementJoinIfApply(currentQueryStatement: QueryStatement) {
		const previousQueryStatement = this.queryStatementList[currentQueryStatement.index - 1];
		if (currentQueryStatement.source.value >= 0 && previousQueryStatement.source.value === currentQueryStatement.source.value) {
			currentQueryStatement.disableJoin = false;
			currentQueryStatement.join = QueryOperator.forJoin.andOperator;
		}
	}

	/**
	 * add a new query statement instance to the query statement list
	 */
	addNewQueryStatement = () => {
		const { historyChannel } = this.metric.definition;

		const newQueryStatement = new QueryStatement();

		newQueryStatement.join = QueryOperator.forJoin.andOperator;

		if (!historyChannel) {
			this.setQueryStatementNoHistory(newQueryStatement);
		}

		this.queryStatementList.push(newQueryStatement);

		this.showFooter = false;
	};

	/**
	 * If thisis no history channel, then every query statement will only be prompt with asset source selected
	 */
	setQueryStatementNoHistory = (newQueryStatement: QueryStatement) => {
		const { assetChannel, workOrderChannel } = this.metric.definition;
		if (workOrderChannel) {
			newQueryStatement.source = {
				name: 'WorkOrder',
				value: ChannelTypes.WorkOrder
			};
		} else {
			const assetChannelAttributes = assetChannel.attributes as ArcGISAssetChannelAttributes;
			const { assetType } = assetChannelAttributes;
			const sourceName = assetType.charAt(0).toUpperCase() + assetType.slice(1);
			newQueryStatement.source = {
				name: sourceName,
				value: ChannelTypes.Asset
			};
		}
		newQueryStatement.disableSource = true;
		newQueryStatement.queryFieldList.push(new QueryField());
	};

	saveQueryStatementToMetric = () => {};

	/**
	 * delete the selected query statement
	 */
	deleteQueryStatement = (index: number) => {
		const currentStatement = this.queryStatementList[index];
		const nextStatement = this.queryStatementList[index + 1];
		if (currentStatement.disableJoin && nextStatement) {
			nextStatement.disableJoin = true;
			delete nextStatement.join;
		}
		this.queryStatementList.splice(index, 1);
		this.reorderQueryStatementIndex();

		if (this.queryStatementList.length) return;

		if (this.metric.definition.query.mode === QueryMode.manualQuery) {
			this.metric.definition.query.mode = QueryMode.manualQuery;
		} else {
			this.metric.definition.query.mode = QueryMode.none;
		}
		this.queryStatementList = this.createNewQueryStatementList();
	};

	/**
	 * reorder the query statement index
	 */
	reorderQueryStatementIndex() {
		this.queryStatementList.forEach((queryStatement: QueryStatement, index: number) => (queryStatement.index = index));
	}

	goToManualQuery = () => {
		NavigationService.navigateTo(Pages.manualQuery, { metric: this.metric });
	};

	save() {
		const { query } = this.metric.definition;
		query.mode = QueryMode.builderQuery;
		(query as AGSQuery).queryStatementList.length = 0;
		Object.assign((query as AGSQuery).queryStatementList, this.queryStatementList);
		NavigationService.navigateBackTo(Pages.editMetric, { metric: this.metric });
	}

	onQueryStatementLoding(loading: boolean) {
		this.menuPanelComponent.updateView({ title: 'query builder', rightIcon: { url: 'assets/edit.png', toolTip: 'manual query', callBackFunction: this.goToManualQuery }, showPacifier: loading });
	}
}
