import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { EmployeeCostWrapper, WorkOrderFieldNames, WorkOrderWrapper } from 'models/work-order';
import { WorkOrderFieldProvider } from '../workorder-filter/workorder-field.provider';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Subscription } from 'rxjs';
import { CalendarWidgetService } from 'app/ui-components/calendar-widget/calendar-widget.service';
import * as moment from 'moment';
import { CalendarTypes } from 'app/ui-components/calendar-widget/calendar-types.enum';
import { AlertDialogService } from 'app/ui-components/alert-dialog/alert-dialog.service';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-advanced-labor',
	templateUrl: './advanced-labor.component.html',
	styleUrls: ['./advanced-labor.component.scss']
})
export class AdvancedLaborComponent extends MenuPanelBaseComponent {
	workOrder: WorkOrderWrapper;

	employeeCost: EmployeeCostWrapper;

	employeeName: string;

	rateTypeDescription: string;

	dateStamp: string;

	workTaskDescription: string;

	employeeTradeName: string;

	budgetName: string;

	readOnly = false;

	@ViewChild('advancedLaborContainer', { static: true }) advancedLaborContainer: ElementRef;

	datesUpdatedSubscription: Subscription;

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		public workOrderFactory: WorkOrderFactory,
		private interop: OmniInteropService,
		private calendarService: CalendarWidgetService
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;

		this.readOnly = args.parameter.readOnly;
		this.workOrder = args.parameter.workOrder;
		this.employeeCost = args.parameter.employeeCost;

		this.menuPanelComponent.updateView({ title: 'advanced labor', backgroundClass: 'orange-background' });
		this.getFieldDisplayNames();

		this.datesUpdatedSubscription = this.calendarService.calendarDatesUpdatedObservable.subscribe(calendarResult => {
			if (calendarResult.element !== 'advanced-labor') return;
			this.employeeCost.dateStamp = moment(calendarResult.range.startDate).format('M/D/YYYY hh:mm:ss A');
		});
	}

	async onSave() {
		if (!this.employeeCost.isDirty) {
			this.alertDialog.mainMessage = { text: 'No changes made' };
			this.alertDialog.open = true;
			return;
		}
		const errorMessage = this.validateEmployeeCost();
		if (errorMessage) {
			this.alertDialog.mainMessage = { text: errorMessage };
			this.alertDialog.open = true;
			return;
		}
		await this.workOrderFactory.updateEmployeeCost(this.workOrder, this.employeeCost);
		this.menuPanelComponent.goBack();
	}

	async onDelete() {
		await this.workOrderFactory.deleteEmployeeCost(this.workOrder, this.employeeCost);
		this.menuPanelComponent.goBack();
	}

	validateEmployeeCost() {
		if (!this.employeeCost.employeeId) return 'Need to select employee.';
		if (!this.employeeCost.rateType) return 'Need rate type.';
		if (!this.employeeCost.hours) return 'Need hours.';
		if (!this.employeeCost.hourlyRate) return 'Need employee trade';
		if (!this.employeeCost.dateStamp) return 'Need work date';
		return '';
	}

	goToEmployee = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.Employee;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.employeeName],
			selectedItemKey: 'name',
			workOrder: this.workOrder,
			employeeCost: this.employeeCost,
			advancedMode: true
		});
	};

	goToRateType = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.RateType;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.rateTypeDescription],
			selectedItemKey: 'description',
			workOrder: this.workOrder,
			employeeCost: this.employeeCost,
			advancedMode: true
		});
	};

	goToStartDate = () => {
		let currentRange = { startDate: moment(), endDate: moment() };
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.StartDate;
		if (this.workOrderFieldProvider.selectedFilterField.value) {
			currentRange = { startDate: moment(this.employeeCost.dateStamp), endDate: moment(this.employeeCost.dateStamp) };
		}
		this.calendarService.openCalendar(
			CalendarTypes.DateTimePicker,
			this.advancedLaborContainer,
			currentRange,
			{ top: 75, left: 10 },
			'advanced-labor'
		);
	};

	goToAddLEMTask = () => {
		if (!this.hasTask()) return;
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.AddLEMTask;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.workTaskDescription],
			selectedItemKey: 'description',
			workOrder: this.workOrder,
			employeeCost: this.employeeCost,
			advancedMode: true
		});
	};

	goToEmployeeTrade = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.EmployeeTrade;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.employeeTradeName],
			selectedItemKey: 'name',
			workOrder: this.workOrder,
			employeeCost: this.employeeCost,
			advancedMode: true
		});
	};

	goToWorkOrderBudget = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.WorkOrderBudget;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.budgetName],
			selectedItemKey: 'name',
			workOrder: this.workOrder,
			employeeCost: this.employeeCost,
			advancedMode: true
		});
	};

	getFieldDisplayNames() {
		const employee = this.workOrderFactory.workOrderMetaData.employees.getByEmployeeId(this.employeeCost.employeeId);
		this.employeeName = employee ? employee.name : '';
		const rateType = this.workOrderFactory.workOrderMetaData.employeeRates.getRateTypeDescription(this.employeeCost.rateType);
		this.rateTypeDescription = rateType ? rateType.description : '';
		const { dateStamp } = this.employeeCost;
		this.dateStamp = dateStamp ? this.getDateStamp() : '';
		const workTask = this.workOrder.workTasks.getByWorkTaskId(this.employeeCost.workTaskId);
		this.workTaskDescription = workTask ? workTask.description : '';
		const trade = this.workOrderFactory.workOrderMetaData.employeeTrades.getByTradeId(this.employeeCost.tradeId);
		this.employeeTradeName = trade ? trade.name : '';
		const budget = this.workOrderFactory.workOrderMetaData.workOrderBudgets.getByBudgetId(this.employeeCost.budgetId);
		this.budgetName = budget ? budget.name : '';
	}

	getDateStamp() {
		const { dateStamp } = this.employeeCost;
		const splitted = dateStamp.split('T');
		const day = splitted[0];
		if (splitted.length === 1) return day;
		const time = splitted[1].substring(0, 8);
		return `${day} ${time}`;
	}

	hasTask = (): boolean => !!this.workOrder.workTasks.length;
}
