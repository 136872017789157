import { VendorCostContract } from '../../contracts/work-order';
import { VendorCost } from './vendor-cost.model';

export class VendorCostWrapper {
	private _vendorCost: VendorCost;
	get vendorCost(): VendorCost {
		return this._vendorCost;
	}

	constructor(vendorCost: VendorCost) {
		this._vendorCost = vendorCost;
	}

	get id(): string {
		return this._vendorCost.id;
	}
	set id(value: string) {
		this._vendorCost.id = value;
	}

	get changeStatus(): any {
		return this._vendorCost.changeStatus;
	}
	set changeStatus(value: any) {
		this._vendorCost.changeStatus = value;
	}

	get createdBy(): string {
		return this._vendorCost.createdBy;
	}
	set createdBy(value: string) {
		this._vendorCost.createdBy = value;
	}

	get datestamp(): string {
		return this._vendorCost.datestamp;
	}
	set datestamp(value: string) {
		this._vendorCost.datestamp = value;
	}

	get description(): string {
		return this._vendorCost.description;
	}
	set description(value: string) {
		this._vendorCost.description = value;
	}

	get systemId(): string {
		return this._vendorCost.systemId;
	}
	set systemId(value: string) {
		this._vendorCost.systemId = value;
	}

	get totalCost(): number {
		return this._vendorCost.totalCost;
	}
	set totalCost(value: number) {
		this._vendorCost.totalCost = value;
	}

	get units(): number {
		return this._vendorCost.units;
	}
	set units(value: number) {
		this._vendorCost.units = value;
	}

	get vendorCostId(): number {
		return this._vendorCost.vendorCostId;
	}
	set vendorCostId(value: number) {
		this._vendorCost.vendorCostId = value;
	}

	get vendorId(): string {
		return this._vendorCost.vendorId;
	}
	set vendorId(value: string) {
		this._vendorCost.vendorId = value;
	}

	get workTaskId(): string {
		return this._vendorCost.workTaskId;
	}
	set workTaskId(value: string) {
		this._vendorCost.workTaskId = value;
	}

	get workOrderKey(): string {
		return this._vendorCost.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._vendorCost.workOrderKey = value;
	}

	get isDirty(): boolean {
		return this._vendorCost.isDirty;
	}

	clearDirty = (): void => this._vendorCost.clearDirty();

	undoAll = (): boolean => this._vendorCost.undoAll();

	onUpdateInformation = (copyModel): boolean => this._vendorCost.onUpdateInformation(copyModel);

	updateInformation = (copyModel): boolean => this._vendorCost.updateInformation(copyModel);

	getContract = (): VendorCostContract => this._vendorCost.getContract();
}
