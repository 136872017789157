export class SedaruSignalRVersions {
	public static get v_1_0_text(): string {
		return '1.0';
	}

	public static get v_1_1_text(): string {
		return '1.1';
	}

	public static get currentVersion(): string {
		return SedaruSignalRVersions.v_1_1_text;
	}
}
