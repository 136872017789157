import { StandardCustomFieldContract } from '../../contracts/work-order/standard-custom-field-contract';
import { CustomFieldContract } from '../../contracts/work-order';
import { CustomFieldWrapper } from './custom-field-wrapper.model';
import { CustomField } from './custom-field.model';
import { StandardCustomField } from './standard-custom-field.model';

export class CustomFieldWrappers extends Array<CustomFieldWrapper> {
	private dict: Map<string, CustomFieldWrapper>;
	static fromContracts(contracts: (CustomFieldContract | StandardCustomFieldContract)[]): CustomFieldWrappers {
		return CustomFieldWrappers.fromModels(contracts.map(c => {
			if (c instanceof StandardCustomFieldContract) {
				return StandardCustomField.fromContract(c);
			}
			return CustomField.fromContract(c);
		}));
	}

	static fromModels(models: (CustomField | StandardCustomField)[]): CustomFieldWrappers {
		const wrappersList = new CustomFieldWrappers();
		for (const model of models) wrappersList.push(new CustomFieldWrapper(model));
		return wrappersList;
	}

	getContracts = (): (CustomFieldContract | StandardCustomFieldContract)[] => this.map(cf => cf.getContract());

	getModels = (): (CustomField | StandardCustomField)[] => this.map(cf => cf.customField);

	isDirty = (): boolean => !!this.filter(cf => cf.isDirty).length;

	clearDirty = (): void => this.forEach(cf => cf.clearDirty());

	undoAll = () => this.forEach(field => (field.isDirty ? field.undoAll() : null));

	getDefaultFields = () => CustomFieldWrappers.fromContracts(this.getContracts().filter(field => field instanceof CustomFieldContract && field.IsDefault));

	getFieldsToUpdate = (): (CustomFieldContract | StandardCustomFieldContract)[] => this.filter(field => field.isDirty).map(field => field.getContract());

	getByCustomFieldId = (customFieldId: string) => {
		if (!this.dict) this.dict = new Map();
		if (!this.dict.has(customFieldId)) {
			const found = this.find(customField => customField.customFieldId === customFieldId);
			this.dict.set(customFieldId, found);
		}
		return this.dict.get(customFieldId);
	};
}
