import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import { AdvancedWorkOrder } from 'models/work-order';
import { SedaruClientHub } from '../../../sedaru-util/sedaru-subscriptions-library';

export class CloseWorkOrderResult extends Subscriptions.MethodHandlerResult<CloseWorkOrderHandler> {
	exceptionMessage: string;
	constructor(handler: CloseWorkOrderHandler) {
		super(handler);
	}
}

export class CloseWorkOrderHandler extends Subscriptions.SedaruClientMethodHandlerBase {
	private _workOrdersToClose: Array<AdvancedWorkOrder>;

	constructor() {
		super();
		this._workOrdersToClose = new Array<AdvancedWorkOrder>();
	}

	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLocaleLowerCase() === 'sedaruconnect.closeworkorderresult';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		return new Promise<Subscriptions.MethodHandlerResultBase>((resolve, reject) => {
			console.log(packet);
			args.isHandled = true;
			const data = JSON.parse(packet.data);

			const closeResult = new CloseWorkOrderResult(this);
			if (!data || data.Success === undefined) {
				closeResult.message = 'payload was undefined';
				resolve(closeResult);
				return;
			}

			closeResult.success = data.Success;

			if (!closeResult.success) {
				const exceptionMessage = data.ExceptionMessage;
				const errorMessage = data.ErrorMessage;
				if (exceptionMessage) {
					closeResult.message = `Unable to establish a connection to Work Order Provider. Please try again`;
					closeResult.exceptionMessage = exceptionMessage;
				} else {
					closeResult.message = `Unable to close work order. Work Order Provider responded with the following error(s): ${errorMessage}`;
				}

				resolve(closeResult);
				return;
			}

			const workOrderSystemId = data.WorkOrderSystemId;
			if (!workOrderSystemId) {
				closeResult.success = false;
				closeResult.message = `Linking work order 'SystemId' is empty`;
				resolve(closeResult);
				return;
			}

			const matchingWorkOrder = this._workOrdersToClose.find(wo => wo.systemId.toLowerCase() === workOrderSystemId.toLowerCase());
			if (!matchingWorkOrder) {
				closeResult.success = false;
				closeResult.message = `No matching work order found for linking 'SystemId'`;
				resolve(closeResult);
				return;
			}

			this._workOrdersToClose.splice(this._workOrdersToClose.indexOf(matchingWorkOrder), 1);
			resolve(closeResult);
			return;
		});
	}

	onGetUniqueId(): string {
		return 'CloseWorkOrderHandler'; // do not user Class.name because it doesn't work with minified code.
	}

	sendCloseWorkOrderMessage(advancedWorkOrder: AdvancedWorkOrder, customerCode: string) {
		const data = {
			SystemId: advancedWorkOrder.systemId,
			IsServiceRequest: advancedWorkOrder.isServiceRequest
		};
		this._workOrdersToClose.push(advancedWorkOrder);
		this.hub.sendNotification('SedaruConnect.CloseWorkOrder', JSON.stringify(data), customerCode, SedaruClientHub.sedaruConnectAppName);
	}
}
