<div *ngIf="!isLoginLoading">
	<span class="omni-user" (click)="showLogOutMenu = !showLogOutMenu">
		<span hide-xs class="m-font-size">{{ user.fullName }}</span>
		<button mat-icon-button class="omni-avatar-button">
			<img *ngIf="thumbnail" [src]="'data:image/png;base64,' + thumbnail" class="omni-avatar" />
		</button>
	</span>
</div>
<mat-spinner *ngIf="isLoginLoading" diameter="40"></mat-spinner>

<div class="user-menu-background" *ngIf="showLogOutMenu" (click)="showLogOutMenu = false">
	<app-user-context-menu [user]="user" [onLogOut]="logout.bind(this)" (profileGroupSelected)="onProfileGroupSelected($event)"></app-user-context-menu>
</div>
