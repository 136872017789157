import { AdvancedWorkOrder } from './advanced-work-order.model';
import { StandardWorkOrder } from './standard-work-order.model';
import { WorkOrderWrapper } from './work-order-wrapper.model';

export class WorkOrderWrappers extends Array<WorkOrderWrapper> {
	static fromModels(workOrders: AdvancedWorkOrder[] | StandardWorkOrder[]): WorkOrderWrappers {
		const wrappers = new WorkOrderWrappers();
		if (!workOrders) return wrappers;
		for (const wo of workOrders) wrappers.push(new WorkOrderWrapper(wo));
		return wrappers;
	}

	static fromWrapperModels(workOrderWrappers: WorkOrderWrapper[]): WorkOrderWrappers {
		const wrappers = new WorkOrderWrappers();
		if (!workOrderWrappers) return wrappers;
		for (const wo of workOrderWrappers) wrappers.push(wo);
		return wrappers;
	}

	getByWorkOrderKey = (workOrderKey: string): WorkOrderWrapper => this.find(wo => wo.workOrderKey === workOrderKey);
}
