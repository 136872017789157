import { WorkOrderTemplate } from './work-order-template.model';
import { WorkOrderTemplateContract } from '../../contracts/work-order';

export class WorkOrderTemplates extends Array<WorkOrderTemplate> {
	static fromContracts(workOrderTemplateContracts: WorkOrderTemplateContract[]) {
		const workOrderTemplates = new WorkOrderTemplates();
		if (!workOrderTemplateContracts) return workOrderTemplates;

		for (const workOrderTemplateContract of workOrderTemplateContracts) {
			const workOrderTemplate = WorkOrderTemplate.fromContract(workOrderTemplateContract);
			workOrderTemplates.push(workOrderTemplate);
		}
		return workOrderTemplates;
	}
	getById = (id: string) => this.find(workorderTemplate => workorderTemplate.id === id);

	getByTemplateId = (templateId: string) => this.find(template => template.woTemplateId === templateId);
}
