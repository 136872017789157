import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamViewerComponent } from './team-viewer.component';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';
import { TeamViewComponent } from './team-view/team-view.component';
import { NavigationModule } from 'app/navigation/navigation.module';

@NgModule({
	declarations: [TeamViewerComponent, TeamViewComponent],
	imports: [CommonModule, UiComponentsModule, NavigationModule],
	exports: [TeamViewerComponent]
})
export class TeamViewerModule {}
