import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Metric } from 'models';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { OmniTableField } from './omni-table.field.model';
import { GuiConfig } from 'omni-model/gui-config.model';
import { FieldType } from 'sedaru-util/esri-core';

@Component({
	selector: 'app-omni-table',
	templateUrl: './omni-table.component.html',
	styleUrls: ['./omni-table.component.scss']
})
export class OmniTableComponent implements OnInit, OnChanges {
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	@Output() rowSelected = new EventEmitter();

	@Input() headerColor: string;

	@Input() config: GuiConfig;

	@Input() fields: OmniTableField[];

	@Input() activeMetric: Metric;

	@Input() data = [];
	/** max number of records given each time the table requests records */
	@Input() batchSize: number;

	@Input() pageSize = 20;
	private rowClicked = false;
	private _uniqueKey;
	@Input() set uniqueKey(value: string) {
		if (!value) {
			this._uniqueKey = null;
			return;
		}
		this._uniqueKey = value;
	}
	get uniqueKey() {
		return this._uniqueKey;
	}

	get numberOfBatchesNeeded() {
		if (!this.length || !this.batchSize) return;
		return Math.ceil(this.length / this.batchSize);
	}

	private _selectedUniqueValue: string;
	@Input() set selectedUniqueValue(value: string) {
		if (!value) {
			this._selectedUniqueValue = null;
			this.selection.select(null);
			this.selection.clear();
			return;
		}

		this._selectedUniqueValue = value;

		if (!this.dataSource) this.setDataSource();

		let tableDataSource = (this.dataSource.filteredData.length) ? this.dataSource.filteredData : this.dataSource.data;
		if (this.dataSource.sort?.active) {
			tableDataSource = this.dataSource.sortData(tableDataSource, this.dataSource.sort);
		}

		const uniqueTableRowIndex = tableDataSource.findIndex((rows, idx) => {
			if (!rows) {
				return null;
			}
			return rows[this.uniqueKey] == value;
		});

		if (uniqueTableRowIndex >= 0) {
			this.selection.clear();
			this.selection.select(tableDataSource[uniqueTableRowIndex]);
			setTimeout(() => {
				if (this.paginator) {
					console.log(Number(uniqueTableRowIndex / this.paginator.pageSize));
					const pageIndex = Math.trunc(uniqueTableRowIndex / this.paginator.pageSize);
					this.paginator.pageIndex = pageIndex; // number of the page you want to jump.
					this.paginator.page.next({
						pageIndex: pageIndex,
						pageSize: this.paginator.pageSize,
						length: this.paginator.length
					});
				}
			}, 50);

			setTimeout(() => {
				this.scrollToTargetAdjusted();
			}, 50);
		} else {
			this.selection.select(null);
			this.selection.clear();
		}
	}
	get selectedUniqueValue() {
		return this._selectedUniqueValue;
	}

	@Input() length: number;

	/** when the user reaches a page that requires values not in the current data source */
	onMoreRecordsNeeded: (batchRange: number[]) => Promise<any[]>;

	fieldKeys: string[];
	selection = new SelectionModel<any>(false, null);
	loading = false;
	dataSource: MatTableDataSource<[]>;

	get enableSorting() {
		if (!this.numberOfBatchesNeeded) return false;
		if (this.resultBatchesInMemory.length >= this.numberOfBatchesNeeded) return true;
		return false;
	}

	private fieldsWithDateType = [];

	private currentSorting = { field: null, order: null };

	private currentPage: number;
	/** the hms results are fetched in batches of 1000. Pages 0-9 are in batch 0, pages 10-19 in batch 2, etc */
	private resultBatchesInMemory = [];

	constructor(private interopService: OmniInteropService, private changeDetector: ChangeDetectorRef) {}

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.setDataSource();
		setTimeout(() => {
			this.scrollToTargetAdjusted();
		}, 50);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setDataSource();
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	sortData(sort: Sort) {
		this.currentSorting.field = sort.active;
		this.currentSorting.order = sort.direction.toLocaleUpperCase() as 'DESC ' | 'ASC';
	}

	async setDataSource() {
		this.data.forEach(item => {
			item['hovered'] = false;
		});
		this.fieldKeys = this.fields.map(f => f.key);
		this.resultBatchesInMemory.push(0);
		if (this.length) this.data.length = this.length;
		this.dataSource = new MatTableDataSource(this.data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.paginator.firstPage();
		this.setDateFields();
		this.dataSource.sortingDataAccessor = (item, property) => {
			const isDateTypeProperty = this.fieldsWithDateType.includes(property);
			if (isDateTypeProperty) {
				return new Date(item[property]).getTime();
			} else {
				return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.loading = false;
	}

	// The method to extract only date fieldnames
	private setDateFields() {
		const fieldsList = this.interopService?.arcGISManager?.getArcGisFields(this.activeMetric);
		this.fieldsWithDateType = [];
		for (const field of fieldsList) {
			if (field.type === FieldType.esriFieldTypeDate) {
				this.fieldsWithDateType.push(field.name);
			}
		}
	}


	async onPageChange(pageChangeEvent: PageEvent) {
		const table = document.querySelector('.omni-table');
		table.scrollTo(0, 0);
		if (isNaN(pageChangeEvent.pageIndex) || !pageChangeEvent.pageIndex) return;
		this.pageSize = pageChangeEvent.pageSize;
		this.currentPage = pageChangeEvent.pageIndex;
		const pagesPerBatch = Math.ceil(this.batchSize / this.pageSize);
		const batchNeeded = Math.trunc(this.currentPage / pagesPerBatch);
		if (this.resultBatchesInMemory.includes(batchNeeded)) return;
		this.loading = true;
		const rangeStart = this.resultBatchesInMemory[this.resultBatchesInMemory.length - 1]
		const batchRange = this.range(rangeStart + 1, batchNeeded);
		const newResultsBatch = await this.onMoreRecordsNeeded(batchRange);
		const newDataSource = [];
		// @ts-ignore
		newDataSource.push(...this.dataSource.data.filter(entry => entry !== undefined));
		newDataSource.push(...newResultsBatch);
		newDataSource.length = this.length;
		this.dataSource = new MatTableDataSource(newDataSource);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.loading = false;
		this.resultBatchesInMemory.push(...batchRange);
		this.changeDetector.detectChanges();
	}

	rowSelectHandler(row) {
		this.rowClicked = true;
		this.selection.select(row);
		this.rowSelected.emit(row);
	}

	setScrollToTop() {
		const table = document.querySelector('.omni-table');
		table.scrollTo(0, 0);
	}

	scrollToTargetAdjusted() {
		const element = document.querySelector('.omni-table .mat-row.selected');
		if (!element) return;
		if (this.rowClicked) return;
		const table = document.querySelector('.omni-table');
		table.scrollTo(0, 0);
		const offset = (this.config.tilesLayout === 12) ? 400 : 300;
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		table.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});
	}

	private range(start, end) {
		// @ts-ignore
		return Array(end - start + 1).fill().map((_, idx) => start + idx)
	}

}
