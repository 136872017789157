<div class="info-header">
	<span>specifications</span>
</div>
<div *ngIf="hierarchyAsset" class="scrolling-content">
  <app-menu-panel-field
    [header]="'DESCRIPTION'"
    [(value)]="hierarchyAsset.description"
    [backgroundColor]="assetDefinition.style.layout.background.color"
    [width]="fieldFullSize"
    [height]="fieldHalfSize"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
  <app-menu-panel-field
    [header]="'ASSET NUMBER'"
    [(value)]="hierarchyAsset.assetId"
    [backgroundColor]="defaultGrayColor"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
  <app-menu-panel-field
    [header]="'LOCATION'"
    [(value)]="hierarchyAsset.locationId"
    [backgroundColor]="assetDefinition.style.layout.background.color"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [leftMargin]="'4px'"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
  <app-menu-panel-field
    [header]="'IN SERVICE'"
    [value]="hierarchyAsset.inServiceString"
    [backgroundColor]="assetDefinition.style.layout.background.color"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
  <app-menu-panel-field
    [header]="'STATUS DATE'"
    [(value)]="hierarchyAsset.statusDate"
    [backgroundColor]="defaultGrayColor"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [leftMargin]="'4px'"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
  <app-menu-panel-field
    *ngFor="let spec of hierarchyAsset.assetSpecs; index as i"
    [header]="spec.description"
    [value]="spec.value"
    [backgroundColor]="getBackgroundColor(i)"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [leftMargin]="getLeftMargin(i)"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="false"
  ></app-menu-panel-field>
</div>