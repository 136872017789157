import { Injectable } from '@angular/core';
import { EmployeeContract, EquipmentContract, MaterialContract, StandardCustomFieldContract, StandardEmployeeCostContract, StandardEquipmentCostContract, StandardMaterialCostContract, StandardWorkAssetContract, StandardWorkOrderContract, WorkOrderMetaDataContract } from 'contracts/work-order';
import { ArcGISWorkOrderChannelAttributes } from 'models';
import { WorkOrderResult } from 'providers/work-order-provider-base';
import { FeatureLayer, Server, ApplyEditsTransaction } from 'sedaru-util/esri-core';
import { RestServiceResponseError } from 'sedaru-util/esri-core/service-response';

@Injectable({
	providedIn: 'root'
})
export class StandardWorkOrderService {
	private _server: Server;
	private _workOrderAttributes: ArcGISWorkOrderChannelAttributes;

	constructor(server: Server, workOrderAttributes: ArcGISWorkOrderChannelAttributes) {
		this._server = server;
		this._workOrderAttributes = workOrderAttributes;
	}

	private getLayer(layerId: number) {
		return this._server?.layers?.find(l => l.id === layerId);
	}

	get workOrderLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.workOrderLayerIndex);
	}

	get workAssetLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.workAssetLayerIndex);
	}

	get employeeLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.employeeLayerIndex);
	}

	get employeeCostLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.employeeCostLayerIndex);
	}

	get equipmentLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.equipmentLayerIndex);
	}

	get equipmentCostLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.equipmentCostLayerIndex);
	}

	get materialLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.materialLayerIndex);
	}

	get materialCostLayer(): FeatureLayer {
		return this.getLayer(this._workOrderAttributes.materialCostLayerIndex);
	}

	async fetchEmployees(): Promise<EmployeeContract[]> {
		const employeesList = new Array<EmployeeContract>();
		let employeeQuery = '1=1';
		if (!this.employeeLayer) return employeesList;
		const isActiveField = this.employeeLayer.fields.find(field => field.name.toLowerCase() === 'isactive');
		if (isActiveField) employeeQuery = `${isActiveField.name} = 1`;

		const features = await this.employeeLayer.query(employeeQuery);
		for (const { attributes } of features) {
			if (!attributes) continue;
			const employee = new EmployeeContract();
			employee.EmployeeID = attributes.employeeid;
			employee.HourlyRate = attributes.hourlyrate;
			employee.EmployeeName = attributes.name;
			employee.ObjectId = attributes.objectid;
			employee.Username = attributes.username;
			employeesList.push(employee);
		}

		return employeesList;
	}

	async fetchEquipment(): Promise<EquipmentContract[]> {
		const equipmentList = new Array<EquipmentContract>();
		if (!this.equipmentLayer) return equipmentList;

		const features = await this.equipmentLayer.query('1=1');
		for (const { attributes } of features) {
			if (!attributes) continue;
			const equipment = new EquipmentContract();
			equipment.ObjectId = attributes.objectid;
			equipment.Description = attributes.description;
			equipment.EquipmentId = attributes.equipmentid;
			equipment.EquipmentType = attributes.equipmenttype;
			equipment.HourlyRate = attributes.hourlyrate;
			equipment.SerialNumber = attributes.serialnumber;
			equipmentList.push(equipment);
		}

		return equipmentList;
	}

	async fetchMaterials(): Promise<MaterialContract[]> {
		const materialsList = new Array<MaterialContract>();
		if (!this.materialLayer) return materialsList;

		const features = await this.materialLayer.query('1=1');
		for (const { attributes } of features) {
			if (!attributes) continue;
			const material = new MaterialContract();
			material.ObjectId = attributes.objectid;
			material.Description = attributes.description;
			material.MaterialId = attributes.materialid;
			material.PartNumber = attributes.partnumber;
			material.UnitCost = attributes.unitcost;
			materialsList.push(material);
		}

		return materialsList;
	}

	async fetchEmployeeCost(workOrderKeys: string[], idOnly = false): Promise<StandardEmployeeCostContract[]> {
		const employeeCostList = new Array<StandardEmployeeCostContract>();
		if (!this.employeeCostLayer || !workOrderKeys || !workOrderKeys.length) return employeeCostList;

		const features = await this.employeeCostLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderkey' : '*');
		for (const { attributes } of features) {
			const empCost = new StandardEmployeeCostContract();
			empCost.objectid = attributes.objectid;
			empCost.Hours = attributes.hours;
			empCost.RateType = attributes.ratetype;
			empCost.WorkOrderKey = attributes.workorderkey;
			empCost.HourlyRate = attributes.hourlyrate;
			empCost.DateStamp = attributes.datestamp;
			empCost.EmployeeID = attributes.employeeid;
			empCost.TotalCost = attributes.totalcost;
			empCost.ChangeStatus = attributes.changestatus;
			empCost.WorkTaskId = attributes.worktaskid;
			empCost.TradeId = attributes.tradeid;
			empCost.BudgetId = attributes.budgetid;
			empCost.CreatedBy = attributes.createdby;
			employeeCostList.push(empCost);
		}

		return employeeCostList;
	}

	async fetchEquipmentCost(workOrderKeys: string[], idOnly = false): Promise<StandardEquipmentCostContract[]> {
		const equipmentCostList = new Array<StandardEquipmentCostContract>();
		if (!this.equipmentCostLayer || !workOrderKeys || !workOrderKeys.length) return equipmentCostList;

		const features = await this.equipmentCostLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderkey' : '*');
		for (const { attributes } of features) {
			const empCost = new StandardEquipmentCostContract();
			empCost.objectid = attributes.objectid;
			empCost.EquipmentId = attributes.equipmentid;
			empCost.Units = attributes.units;
			empCost.WorkOrderKey = attributes.workorderkey;
			empCost.Hours = attributes.hours;
			empCost.UnitCost = attributes.unitcost;
			empCost.Datestamp = attributes.datestamp;
			empCost.TotalCost = attributes.totalcost;
			empCost.RateType = attributes.ratetype;
			empCost.Description = attributes.description;
			empCost.WorkTaskId = attributes.worktaskid;
			empCost.CreatedBy = attributes.createdBy;
			equipmentCostList.push(empCost);
		}

		return equipmentCostList;
	}

	async fetchMaterialCost(workOrderKeys: string[], idOnly = false): Promise<StandardMaterialCostContract[]> {
		const materialCostList = new Array<StandardMaterialCostContract>();
		if (!this.materialCostLayer || !workOrderKeys || !workOrderKeys.length) return materialCostList;

		const features = await this.materialCostLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderkey' : '*');
		for (const { attributes } of features) {
			const matCost = new StandardMaterialCostContract();
			matCost.objectid = attributes.objectid;
			matCost.MaterialId = attributes.materialid;
			matCost.Units = attributes.units;
			matCost.WorkOrderKey = attributes.workorderkey;
			matCost.UnitCost = attributes.unitcost;
			matCost.DateStamp = attributes.datestamp;
			matCost.TotalCost = attributes.totalcost;
			matCost.Description = attributes.description;
			matCost.WorkTaskId = attributes.worktaskid;
			matCost.CreatedBy = attributes.createdby;
			materialCostList.push(matCost);
		}

		return materialCostList;
	}

	async getInitialData(): Promise<WorkOrderMetaDataContract> {
		const initialDataContract = new WorkOrderMetaDataContract();
		let employees: EmployeeContract[];
		let equipment: EquipmentContract[];
		let materials: MaterialContract[];

		try {
			employees = await this.fetchEmployees();
			equipment = await this.fetchEquipment();
			materials = await this.fetchMaterials();
		} catch (err) {
			return initialDataContract;
		} finally {
			initialDataContract.Employees = employees;
			initialDataContract.Equipments = equipment;
			initialDataContract.Materials = materials;
		}

		return initialDataContract;
	}

	async fetchWorkOrder(workOrderKeys: string[]): Promise<StandardWorkOrderContract[]> {
		const workOrderList = new Array<StandardWorkOrderContract>();
		if (!this.workOrderLayer || !workOrderKeys || !workOrderKeys.length) return workOrderList;

		const features = await this.workOrderLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`);
		for (const { attributes } of features) {
			const workOrder = new StandardWorkOrderContract();
			workOrder.objectid = attributes.objectid;
			workOrder.workorderkey = attributes.workorderkey;
			workOrder.worktype = attributes.worktype;
			workOrder.status = attributes.status;
			workOrder.createddate = attributes.createddate;
			workOrder.createdby = attributes.createdby;
			workOrder.startdate = attributes.startdate;
			workOrder.completeddate = attributes.completeddate;
			workOrder.completedby = attributes.completedby;
			workOrder.assignedto = attributes.assignedto;
			workOrder.priority = attributes.priority;
			workOrder.location = attributes.location;
			workOrder.customername = attributes.customername;
			workOrder.contactinfo = attributes.contactinfo;
			workOrder.description = attributes.description;
			workOrder.comments = attributes.comments;
			workOrder.iscorrective = attributes.iscorrective;
			workOrder.change_status = attributes.change_status;
			workOrder.budgetid = attributes.budgetid;
			workOrder.systemid = attributes.systemid;
			workOrder.accountnumber = attributes.accountnumber;
			workOrder.parentworkorderkey = attributes.parentworkorderkey;
			workOrder.recurrencetemplateid = attributes.recurrencetemplateid;
			workOrder.changeBy = attributes.changeby;
			workOrder.changeDate = attributes.changedate;
			workOrder.xCoordinate = attributes.xcoordinate;
			workOrder.yCoordinate = attributes.ycoordinate;


			for (const key in attributes) {
				if (key in workOrder) continue;

				const customField = new StandardCustomFieldContract();
				customField.fieldName = key;
				customField.fieldValue = attributes[key];
				workOrder.customFields.push(customField);
			}

			workOrderList.push(workOrder);
		}

		return workOrderList;
	}

	async getMatchingWorkOrder(query: string, isContains: boolean): Promise<StandardWorkOrderContract[]> {
		const workOrderList = new Array<StandardWorkOrderContract>();
		let where = '';
		const outFields = 'workorderkey';
		if (!this.workOrderLayer || !query) return workOrderList;
		if (isContains) {
			where = `LOWER(workorderkey) LIKE '%25${query.toLowerCase()}%25' AND LOWER(workorderkey) <> ${"'" + query.toLowerCase() + "'"}`;
		} else {
			where = `LOWER(workorderkey) = ${"'" + query.toLowerCase() + "'"}`;
		}

		const features = await this.workOrderLayer.query(where, outFields);
		for (const { attributes } of features) {
			const workOrder = new StandardWorkOrderContract();
			workOrder.objectid = attributes.objectid;
			workOrder.workorderkey = attributes.workorderkey;
			workOrderList.push(workOrder);
		}

		return workOrderList;
	}

	async fetchWorkAssets(workOrderKeys: string[], idOnly = false): Promise<StandardWorkAssetContract[]> {
		const workAssetsList = new Array<StandardWorkAssetContract>();
		if (!this.workAssetLayer || !workOrderKeys || !workOrderKeys.length) return workAssetsList;

		const features = await this.workAssetLayer.query(`workorderkey IN (${"'" + workOrderKeys.join("','") + "'"})`, idOnly ? 'objectid,workorderkey' : '*');
		for (const { attributes } of features) {
			const workAsset = new StandardWorkAssetContract();
			workAsset.ObjectId = attributes.objectid;
			workAsset.AssetKey = attributes.assetkey;
			workAsset.AssetType = attributes.assettype;
			workAsset.WorkOrderKey = attributes.workorderkey;
			workAsset.IsCompleted = attributes.iscompleted;
			workAsset.WorkInspectionkey = attributes.workinspectionkey;
			workAsset.SystemId = attributes.systemid;
			workAssetsList.push(workAsset);
		}

		return workAssetsList;
	}

	async fetchWorkOrderKeysByAsset(assetType: string, assetKey: string): Promise<string[]> {
		const workOrderKeys = new Array<string>();

		if (!this.workAssetLayer || !assetType || !assetKey) return workOrderKeys;
		const features = await this.workAssetLayer.query(`assettype='${assetType}' AND assetkey='${assetKey}'`, 'workorderkey');
		for (const { attributes } of features) {
			if (!attributes || !attributes.workorderkey) continue;
			workOrderKeys.push(attributes.workorderkey);
		}

		return workOrderKeys;
	}

	async applyEdits(transaction: ApplyEditsTransaction): Promise<WorkOrderResult> {
		const response = await this._server.applyEdits(transaction);
		const result = new WorkOrderResult();
		result.isSuccess = response?.success;
		result.errorMessage = this.buildErrorMessage(response?.arcGISError);
		if (!response || !response.success) return result;

		for (const layer of response.responseJsonToken) {
			if (!layer.id || !layer.addResults) continue;

			const operation = transaction.getOperationByLayerId(layer.id);
			if (!operation) continue;

			for (let i = 0; i < layer.addResults.length; i++) {
				const resultObjectId = layer.addResults[i]?.objectId;
				const feature = operation.adds[i];
				if (!feature || !resultObjectId) continue;

				feature.attributes.objectid = resultObjectId;
			}
		}

		return result;
	}

	private buildErrorMessage(error: RestServiceResponseError): string {
		if (!error) return '';
		let message = error?.message;

		if (!error.details) return message;
		for (const detail of error.details) message = message + ` ${detail}`;

		return message;
	}
}
