import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';
import { StandardWorkAssets } from './standard-work-assets.model';
import { StandardEmployeeCosts } from './standard-employee-costs.model';
import { StandardEquipmentCosts } from './standard-equipment-costs.model';
import { StandardMaterialCosts } from './standard-material-costs.model';
import { HistoryRecord } from '../records/history-record.model';
import { OmniModel } from '../../models/omni-model';
import { StandardCustomFields } from './standard-custom-fields.model';
import { FieldType } from '../../sedaru-util/esri-core';
import { ArcGISField } from '../../models/arc-gis-field.model';
import { StandardCustomField } from './standard-custom-field.model';

export class StandardWorkOrder extends OmniModel<StandardWorkOrder, Contracts.StandardWorkOrderContract> {
	isNew = true;
	/**
	 * holds the work order unique key
	 */
	private _objectId: SedaruUtils.Undoable<number>;
	get objectId(): number {
		if (!this._objectId) this._objectId = new SedaruUtils.Undoable<number>();
		return this._objectId.value;
	}
	set objectId(value: number) {
		if (!this._objectId) {
			this._objectId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._objectId.value = value;
	}

	private _workorderkey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workorderkey) this._workorderkey = new SedaruUtils.Undoable<string>();
		return this._workorderkey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workorderkey) {
			this._workorderkey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workorderkey.value = value;
	}

	private _workType: SedaruUtils.Undoable<string>;
	get workType(): string {
		if (!this._workType) this._workType = new SedaruUtils.Undoable<string>();
		return this._workType.value;
	}
	set workType(value: string) {
		if (!this._workType) {
			this._workType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workType.value = value;
	}

	private _status: SedaruUtils.Undoable<string>;
	get status(): string {
		if (!this._status) this._status = new SedaruUtils.Undoable<string>();
		return this._status.value;
	}
	set status(value: string) {
		if (!this._status) {
			this._status = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._status.value = value;
	}

	private _createdDate: SedaruUtils.Undoable<string>;
	get createdDate(): string {
		if (!this._createdDate) this._createdDate = new SedaruUtils.Undoable<string>();
		return this._createdDate.value;
	}
	set createdDate(value: string) {
		if (!this._createdDate) {
			this._createdDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdDate.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	private _startDate: SedaruUtils.Undoable<string>;
	get startDate(): string {
		if (!this._startDate) this._startDate = new SedaruUtils.Undoable<string>();
		return this._startDate.value;
	}
	set startDate(value: string) {
		if (!this._startDate) {
			this._startDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._startDate.value = value;
	}

	private _completedDate: SedaruUtils.Undoable<string>;
	get completedDate(): string {
		if (!this._completedDate) this._completedDate = new SedaruUtils.Undoable<string>();
		return this._completedDate.value;
	}
	set completedDate(value: string) {
		if (!this._completedDate) {
			this._completedDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedDate.value = value;
	}

	private _completedBy: SedaruUtils.Undoable<string>;
	get completedBy(): string {
		if (!this._completedBy) this._completedBy = new SedaruUtils.Undoable<string>();
		return this._completedBy.value;
	}
	set completedBy(value: string) {
		if (!this._completedBy) {
			this._completedBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._completedBy.value = value;
	}

	private _assignedTo: SedaruUtils.Undoable<string>;
	get assignedTo(): string {
		if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
		return this._assignedTo.value;
	}
	set assignedTo(value: string) {
		if (!this._assignedTo) {
			this._assignedTo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._assignedTo.value = value;
	}

	private _priority: SedaruUtils.Undoable<string>;
	get priority(): string {
		if (!this._priority) this._priority = new SedaruUtils.Undoable<string>();
		return this._priority.value;
	}
	set priority(value: string) {
		if (!this._priority) {
			this._priority = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._priority.value = value;
	}

	private _location: SedaruUtils.Undoable<string>;
	get location(): string {
		if (!this._location) this._location = new SedaruUtils.Undoable<string>();
		return this._location.value;
	}
	set location(value: string) {
		if (!this._location) {
			this._location = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._location.value = value;
	}

	private _customerName: SedaruUtils.Undoable<string>;
	get customerName(): string {
		if (!this._customerName) this._customerName = new SedaruUtils.Undoable<string>();
		return this._customerName.value;
	}
	set customerName(value: string) {
		if (!this._customerName) {
			this._customerName = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._customerName.value = value;
	}

	private _contactInfo: SedaruUtils.Undoable<string>;
	get contactInfo(): string {
		if (!this._contactInfo) this._contactInfo = new SedaruUtils.Undoable<string>();
		return this._contactInfo.value;
	}
	set contactInfo(value: string) {
		if (!this._contactInfo) {
			this._contactInfo = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._contactInfo.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

	private _isCorrective: SedaruUtils.Undoable<number>;
	get isCorrective(): number {
		if (!this._isCorrective) this._isCorrective = new SedaruUtils.Undoable<number>();
		return this._isCorrective.value;
	}
	set isCorrective(value: number) {
		if (!this._isCorrective) {
			this._isCorrective = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isCorrective.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<string>;
	get changeStatus(): string {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<string>();
		return this._changeStatus.value;
	}
	set changeStatus(value: string) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeStatus.value = value;
	}

	private _budgetId: SedaruUtils.Undoable<string>;
	get budgetId(): string {
		if (!this._budgetId) this._budgetId = new SedaruUtils.Undoable<string>();
		return this._budgetId.value;
	}
	set budgetId(value: string) {
		if (!this._budgetId) {
			this._budgetId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._budgetId.value = value;
	}

	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	private _accountNumber: SedaruUtils.Undoable<string>;
	get accountNumber(): string {
		if (!this._accountNumber) this._accountNumber = new SedaruUtils.Undoable<string>();
		return this._accountNumber.value;
	}
	set accountNumber(value: string) {
		if (!this._accountNumber) {
			this._accountNumber = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._accountNumber.value = value;
	}

	private _parentWorkOrderKey: SedaruUtils.Undoable<string>;
	get parentWorkOrderKey(): string {
		if (!this._parentWorkOrderKey) this._parentWorkOrderKey = new SedaruUtils.Undoable<string>();
		return this._parentWorkOrderKey.value;
	}
	set parentWorkOrderKey(value: string) {
		if (!this._parentWorkOrderKey) {
			this._parentWorkOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._parentWorkOrderKey.value = value;
	}

	private _historyRecord: HistoryRecord;
	get historyRecord(): HistoryRecord {
		return this._historyRecord;
	}
	set historyRecord(value: HistoryRecord) {
		if (this._historyRecord === value) return;
		this._historyRecord = value;
	}

	private _employeeCosts: StandardEmployeeCosts;
	get employeeCosts(): StandardEmployeeCosts {
		return this._employeeCosts ? this._employeeCosts : (this._employeeCosts = new StandardEmployeeCosts());
	}
	set employeeCosts(value) {
		if (this._employeeCosts === value) return;
		this._employeeCosts = value;
	}

	private _materialCosts: StandardMaterialCosts;
	get materialCosts(): StandardMaterialCosts {
		return this._materialCosts ? this._materialCosts : (this._materialCosts = new StandardMaterialCosts());
	}
	set materialCosts(value) {
		if (this._materialCosts === value) return;
		this._materialCosts = value;
	}

	private _equipmentCosts: StandardEquipmentCosts;
	get equipmentCosts(): StandardEquipmentCosts {
		return this._equipmentCosts ? this._equipmentCosts : (this._equipmentCosts = new StandardEquipmentCosts());
	}
	set equipmentCosts(value) {
		if (this._equipmentCosts === value) return;
		this._equipmentCosts = value;
	}

	private _workAssets: StandardWorkAssets;
	get workAssets(): StandardWorkAssets {
		return this._workAssets ? this._workAssets : (this._workAssets = new StandardWorkAssets());
	}
	set workAssets(value: StandardWorkAssets) {
		if (this._workAssets === value) return;
		this._workAssets = value;
	}

	/**
	 * holds the work order location
	 */
	private _yCoordinate: SedaruUtils.Undoable<number>;
	get yCoordinate(): number {
		if (!this._yCoordinate) this._yCoordinate = new SedaruUtils.Undoable<number>();
		return this._yCoordinate.value;
	}
	set yCoordinate(value: number) {
		if (!this._yCoordinate) {
			this._yCoordinate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._yCoordinate.value = value;
	}

	/**
	 * holds the work order location
	 */
	private _xCoordinate: SedaruUtils.Undoable<number>;
	get xCoordinate(): number {
		if (!this._xCoordinate) this._xCoordinate = new SedaruUtils.Undoable<number>();
		return this._xCoordinate.value;
	}
	set xCoordinate(value: number) {
		if (!this._xCoordinate) {
			this._xCoordinate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._xCoordinate.value = value;
	}

	private _recurrenceTemplateId: SedaruUtils.Undoable<string>;
	get recurrenceTemplateId(): string {
		if (!this._recurrenceTemplateId) this._recurrenceTemplateId = new SedaruUtils.Undoable<string>();
		return this._recurrenceTemplateId.value;
	}
	set recurrenceTemplateId(value: string) {
		if (!this._recurrenceTemplateId) {
			this._recurrenceTemplateId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._recurrenceTemplateId.value = value;
	}

	get isRecurring(): boolean {
		return this.recurrenceTemplateId && this.recurrenceTemplateId.length > 0;
	}

	private _changeBy: SedaruUtils.Undoable<string>;
	get changeBy(): string {
		if (!this._changeBy) this._changeBy = new SedaruUtils.Undoable<string>();
		return this._changeBy.value;
	}
	set changeBy(value: string) {
		if (!this._changeBy) {
			this._changeBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeBy.value = value;
	}

	private _changeDate: SedaruUtils.Undoable<string>;
	get changeDate(): string {
		if (!this._changeDate) this._changeDate = new SedaruUtils.Undoable<string>();
		return this._changeDate.value;
	}
	set changeDate(value: string) {
		if (!this._changeDate) {
			this._changeDate = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeDate.value = value;
	}

	/**
	 * holds the SWO custom fields
	 */
	private _customFields: StandardCustomFields;
	get customFields(): StandardCustomFields {
		return this._customFields ? this._customFields : (this._customFields = new StandardCustomFields());
	}
	set customFields(value) {
		if (this._customFields === value) return;
		this._customFields = value;
	}

	static fromFeature(feature: any, tableFields?: ArcGISField[]): StandardWorkOrder {
		const swo = new StandardWorkOrder();
		swo.isNew = false;
		swo.objectId = feature.objectId;
		swo.workOrderKey = feature.workOrderKey;
		swo.workType = feature.workType;
		swo.status = feature.status;
		swo.createdDate = feature.createdDate ? DateUtil.convertLocaleUTCString(feature.createdDate) : '';
		swo.createdBy = feature.createdBy;
		swo.startDate = feature.startDate ? DateUtil.convertLocaleUTCString(feature.startDate) : '';
		swo.completedDate = feature.completedDate ? DateUtil.convertLocaleUTCString(feature.completedDate) : '';
		swo.completedBy = feature.completedBy;
		swo.assignedTo = feature.assignedTo;
		swo.priority = feature.priority;
		swo.location = feature.location;
		swo.customerName = feature.customerName;
		swo.contactInfo = feature.contactInfo;
		swo.description = feature.description;
		swo.comments = feature.comments;
		swo.isCorrective = feature.isCorrective;
		swo.changeStatus = feature.changeStatus;
		swo.budgetId = feature.budgetId;
		swo.systemId = feature.systemId;
		swo.accountNumber = feature.accountNumber;
		swo.parentWorkOrderKey = feature.parentWorkOrderKey;
		swo.recurrenceTemplateId = feature.recurrenceTemplateId;
		swo.changeBy = feature.changeby;
		swo.changeDate = feature.changeDate;
		swo.customFields = feature.customFields;
		if (tableFields) this.compareFields(swo, tableFields);
		swo.clearDirty();
		return swo;
	}

	static compareFields(model: StandardWorkOrder, tableFields: ArcGISField[]): void {
		if (!tableFields || !tableFields.length) return;
		let objectId = 0;
		for (const field of tableFields.map(f => f.name)) {
			if (field in model || model.customFields.some(cf => cf.customFieldName?.toLowerCase() === field?.toLowerCase())) continue;
			const customField = new StandardCustomField();
			objectId++;
			customField.id = objectId?.toString();
			customField.customFieldName = field;
			customField.customFieldValue = model[field];
			model.customFields.push(customField);
		}
	}

	static fromContract(contract: Contracts.StandardWorkOrderContract): StandardWorkOrder {
		const model = new StandardWorkOrder();
		model.isNew = false;
		model.objectId = contract.objectid;
		model.workOrderKey = contract.workorderkey;
		model.workType = contract.worktype;
		model.status = contract.status;
		model.createdDate = contract.createddate ? DateUtil.convertLocaleUTCString(contract.createddate) : '';
		model.createdBy = contract.createdby;
		model.startDate = contract.startdate ? DateUtil.convertLocaleUTCString(contract.startdate) : '';
		model.completedDate = contract.completeddate ? DateUtil.convertLocaleUTCString(contract.completeddate) : '';
		model.completedBy = contract.completedby;
		model.assignedTo = contract.assignedto;
		model.priority = contract.priority;
		model.location = contract.location;
		model.customerName = contract.customername;
		model.contactInfo = contract.contactinfo;
		model.description = contract.description;
		model.comments = contract.comments;
		model.isCorrective = contract.iscorrective;
		model.changeStatus = contract.change_status;
		model.budgetId = contract.budgetid;
		model.systemId = contract.systemid;
		model.accountNumber = contract.accountnumber;
		model.parentWorkOrderKey = contract.parentworkorderkey;
		model.recurrenceTemplateId = contract.recurrencetemplateid;
		model.employeeCosts = contract.employeecosts ? StandardEmployeeCosts.fromContracts(contract.employeecosts) : null;
		model.equipmentCosts = contract.equipmentcosts ? StandardEquipmentCosts.fromContracts(contract.equipmentcosts) : null;
		model.materialCosts = contract.materialcosts ? StandardMaterialCosts.fromContracts(contract.materialcosts) : null;
		model.changeBy = contract.changeBy;
		model.changeDate = contract.changeDate;
		model.customFields = contract.customFields ? StandardCustomFields.fromContracts(contract.customFields) : null;
		model.xCoordinate = contract.xCoordinate;
		model.yCoordinate = contract.yCoordinate;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.StandardWorkOrderContract => {
		const contractWithOutNestedFields = this.getContractWithoutNestedFields();
		const fullContract = Object.assign(new Contracts.StandardWorkOrderContract(), contractWithOutNestedFields);
		fullContract.workAssets = this.workAssets.getContracts();
		fullContract.employeecosts = this.employeeCosts.getContracts();
		fullContract.equipmentcosts = this.equipmentCosts.getContracts();
		fullContract.materialcosts = this.materialCosts.getContracts();
		fullContract.customFields = this.customFields.getContracts();
		return fullContract;
	};

	getDirtyFieldsFeature(): any {
		const obj = {};
		for (const propName of Object.keys(this)) {
			if (!propName || !(this[propName] instanceof SedaruUtils.UndoableBase)) continue;

			const value = this[propName] as SedaruUtils.Undoable<any>;
			if (!value || !value.isDirty) continue;

			obj[propName.substring(1).toLowerCase()] = value.value;
		}

		for (const customField of this.customFields) {
			if (!customField || !customField.customFieldName || !customField.isDirty) continue;
			obj[customField.customFieldName] = customField.customFieldValue;
		}

		if (!obj['objectid'] && this.objectId) obj['objectid'] = this.objectId;
		return obj;
	}

	getDirtyCustomFields(customFields: StandardCustomFields) {
		const fields = [];
		for (const customField of customFields) {
			if (!customField.isDirty) {
				continue;
			}
			const type = customField.getArcGisFieldType();
			const obj = {
				fieldName: customField.customFieldName,
				fieldValue: this.getFieldValueAfterCasting(customField.customFieldValue, type)
			};
			fields.push(obj);
		}

		return fields;
	}

	getFieldValueAfterCasting(value: any, type: FieldType): string | number | Date  {
		switch (type) {
			case FieldType.esriFieldTypeInteger:
			case FieldType.esriFieldTypeSmallInteger:
				return Number.parseInt(value);
			case FieldType.esriFieldTypeDouble:
			case FieldType.esriFieldTypeSingle:
				return Number.parseFloat(value);
			case FieldType.esriFieldTypeDate:
				return Date.parse(value);
			default:
				return '' + value;
		}
	}

	getContractWithoutNestedFields = (): Contracts.StandardWorkOrderContractWithoutNestedFields => {
		const contract = new Contracts.StandardWorkOrderContract();
		contract.objectid = this.objectId;
		contract.workorderkey = this.workOrderKey;
		contract.worktype = this.workType;
		contract.status = this.status;
		contract.createddate = this.createdDate;
		contract.createdby = this.createdBy;
		contract.startdate = this.startDate;
		contract.completeddate = this.completedDate;
		contract.assignedto = this.assignedTo;
		contract.priority = this.priority;
		contract.location = this.location;
		contract.customername = this.customerName;
		contract.contactinfo = this.contactInfo;
		contract.description = this.description;
		contract.comments = this.comments;
		contract.iscorrective = this.isCorrective;
		contract.change_status = this.changeStatus;
		contract.budgetid = this.budgetId;
		contract.systemid = this.systemId;
		contract.accountnumber = this.accountNumber;
		contract.parentworkorderkey = this.parentWorkOrderKey;
		contract.recurrencetemplateid = this.recurrenceTemplateId;
		contract.changeBy = contract.changeBy;
		contract.changeDate = contract.changeDate;
		return contract;
	}

	onUpdateInformation = (copyModel: StandardWorkOrder): boolean => {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<number>(this._objectId, () => {
				this.objectId = copyModel.objectId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workorderkey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workType, () => {
				this.workType = copyModel.workType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._status, () => {
				this.status = copyModel.status;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdDate, () => {
				this.createdDate = copyModel.createdDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._startDate, () => {
				this.startDate = copyModel.startDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._completedDate, () => {
				this.completedDate = copyModel.completedDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._completedBy, () => {
				this.completedBy = copyModel.completedBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assignedTo, () => {
				this.assignedTo = copyModel.assignedTo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._priority, () => {
				this.priority = copyModel.priority;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._location, () => {
				this.location = copyModel.location;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._customerName, () => {
				this.customerName = copyModel.customerName;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._contactInfo, () => {
				this.contactInfo = copyModel.contactInfo;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._comments, () => {
				this.comments = copyModel.comments;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._isCorrective, () => {
				this.isCorrective = copyModel.isCorrective;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._changeStatus, () => {
				this.changeStatus = copyModel.changeStatus;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._budgetId, () => {
				this.budgetId = copyModel.budgetId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._accountNumber, () => {
				this.accountNumber = copyModel.accountNumber;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._parentWorkOrderKey, () => {
				this.parentWorkOrderKey = copyModel.parentWorkOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._recurrenceTemplateId, () => {
				this.recurrenceTemplateId = copyModel.recurrenceTemplateId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._changeBy, () => {
				this.changeBy = copyModel.changeBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._changeDate, () => {
				this.changeDate = copyModel.changeDate;
			}) || wasChanged;

		this.historyRecord = copyModel.historyRecord;
		wasChanged = this.refreshSubFields(this.workAssets, copyModel.workAssets) || wasChanged;
		wasChanged = this.refreshSubFields(this.employeeCosts, copyModel.employeeCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.materialCosts, copyModel.materialCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.equipmentCosts, copyModel.equipmentCosts) || wasChanged;
		wasChanged = this.refreshSubFields(this.customFields, copyModel.customFields) || wasChanged;
		return wasChanged;
	};

	private refreshSubFields(currentFields: any[], newFields: any[]): boolean {
		let wasChanged = false;
		for (let i = 0; i < currentFields.length; i++) {
			// loop through old fields, find out if current field still exist in the new fields
			const stillExist = newFields.find(field => field.id === currentFields[i].id);
			// If exist, then we leave it along, no need to remove
			if (stillExist) continue;
			// else remove.
			const indexToRemove = currentFields.findIndex(field => field.id === currentFields[i].id);
			if (indexToRemove > -1) {
				currentFields.splice(indexToRemove, 1);
				i--;
				wasChanged = true;
			}
		}

		for (const newField of newFields) {
			// loop through new fields, find out if any of the old field need to be updated
			const currentFieldToUpdate = currentFields.find(field => field.id === newField.id);

			// If found, then old field need to be updated.
			if (currentFieldToUpdate) {
				wasChanged = currentFieldToUpdate.updateInformation(newField) || wasChanged;
			} else {
				// else, new field doesn't exist in the current state, so we push in the new field.
				currentFields.push(newField);
				wasChanged = true;
			}
		}

		return wasChanged;
	}
}
