import { WorkTask } from './work-task.model';
import { WorkTaskContract } from '../../contracts/work-order';

export class WorkTasks extends Array<WorkTask> {
	static fromContracts(workTaskContracts: WorkTaskContract[]) {
		const workTasks = new WorkTasks();
		for (const workTaskContract of workTaskContracts) {
			const workTask = WorkTask.fromContract(workTaskContract);
			workTasks.push(workTask);
		}
		return workTasks;
	}

	static fromModels(workTaskModels: WorkTask[]): WorkTasks {
		const workTasks = new WorkTasks();
		for (const workTask of workTaskModels) workTasks.push(workTask);
		return workTasks;
	}

	getContracts = () => {
		const workTaskContracts = new Array<WorkTaskContract>();
		for (const workTask of this) {
			const workTaskContract = workTask.getContract();
			workTaskContracts.push(workTaskContract);
		}
		return workTaskContracts;
	};

	getByWorkTaskId = (workTaskId: string) => this.find(workTask => workTask.workTaskId === workTaskId);

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
