import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

/**
 * This defines the material
 */
export class Material extends OmniModel<Material, Contracts.MaterialContract> {
	/**
	 * object for material
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the material id
	 */
	private _materialId: SedaruUtils.Undoable<string>;
	get materialId(): string {
		if (!this._materialId) this._materialId = new SedaruUtils.Undoable<string>();
		return this._materialId.value;
	}
	set materialId(value: string) {
		if (!this._materialId) {
			this._materialId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._materialId.value = value;
	}

	/**
	 * holds the material description
	 */
	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	/**
	 * holds the material unit cost
	 */
	private _unitCost: SedaruUtils.Undoable<number>;
	get unitCost(): number {
		if (!this._unitCost) this._unitCost = new SedaruUtils.Undoable<number>();
		return this._unitCost.value;
	}
	set unitCost(value: number) {
		if (!this._unitCost) {
			this._unitCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._unitCost.value = value;
	}

	/**
	 * holds the material part number
	 */
	private _partNumber: SedaruUtils.Undoable<number>;
	get partNumber(): number {
		if (!this._partNumber) this._partNumber = new SedaruUtils.Undoable<number>();
		return this._partNumber.value;
	}
	set partNumber(value: number) {
		if (!this._partNumber) {
			this._partNumber = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._partNumber.value = value;
	}

	/**
	 * holds the material units
	 */
	private _units: SedaruUtils.Undoable<number>;
	get units(): number {
		if (!this._units) this._units = new SedaruUtils.Undoable<number>();
		return this._units.value;
	}
	set units(value: number) {
		if (!this._units) {
			this._units = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._units.value = value;
	}

	private _unitType: SedaruUtils.Undoable<string>;
	get unitType(): string {
		if (!this._unitType) this._unitType = new SedaruUtils.Undoable<string>();
		return this._unitType.value;
	}
	set unitType(value: string) {
		if (!this._unitType) {
			this._unitType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._unitType.value = value;
	}

	private _isActive: SedaruUtils.Undoable<number>;
	get isActive(): number {
		if (!this._isActive) this._isActive = new SedaruUtils.Undoable<number>();
		return this._isActive.value;
	}
	set isActive(value: number) {
		if (!this._isActive) {
			this._isActive = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isActive.value = value;
	}

	static fromContract(contract: Contracts.MaterialContract): Material {
		const model = new Material();
		model.id = contract.ObjectId.toString();
		model.materialId = contract.MaterialId;
		model.description = contract.Description;
		model.unitCost = contract.UnitCost;
		model.partNumber = +contract.PartNumber;
		model.unitType = contract.UnitType;
		model.isActive = contract.isActive;
		model.units = contract.Units;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.MaterialContract {
		const contract = new Contracts.MaterialContract();
		contract.ObjectId = +this.id;
		contract.MaterialId = this.materialId.toString();
		contract.Description = this.description;
		contract.UnitCost = +this.unitCost;
		contract.PartNumber = this.partNumber.toString();
		contract.Units = +this.units;
		contract.UnitType = this.unitType;
		contract.isActive = this.isActive;
		return contract;
	}

	calculateTotalCost() { }

	onUpdateInformation(copyModel: Material): boolean {
		let wasChanged = false;
		// wasChanged = this.updateIfNotDirty<string>(this._id, () => { this.id = copyModel.id }) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._materialId, () => {
				this.materialId = copyModel.materialId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._unitCost, () => {
				this.unitCost = copyModel.unitCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._partNumber, () => {
				this.partNumber = copyModel.partNumber;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._units, () => {
				this.units = copyModel.units;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._unitType, () => {
				this.unitType = copyModel.unitType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._isActive, () => {
				this.isActive = copyModel.isActive;
			}) || wasChanged;
		return wasChanged;
	}
}
