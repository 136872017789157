<app-menu-panel-field
    [header]="'*every # of weeks'"
    [(value)]="pattern.interval"
    [cssClass]="'orange-background'"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [type]="'number'"
    [disabled]="isReadOnly"
    [buttonEnabled]="false"
></app-menu-panel-field>
<app-menu-panel-field
    [header]="'*days of the week'"
    [placeHolder]="daysString"
    [cssClass]="'orange-background'"
    [width]="fieldHalfSize"
    [height]="fieldHalfSize"
    [leftMargin]="'4px'"
    [type]="'text'"
    [disabled]="true"
    [buttonEnabled]="!isReadOnly"
    (goToButtonClick)="daysOfWeekClicked.emit($event)"
></app-menu-panel-field>