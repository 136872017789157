import { Component, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { RightIconOneLineItemResolver } from 'app/ui-components/omni-list/list-item/templates/right-icon-one-line/right-icon-one-line.resolver';
import { AssetRecord } from 'models/records/asset-record.model';
import { Color } from 'models';
import { RecordContextGroup } from 'models/records/record-context-group';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { Pages } from 'app/navigation/inavigation';
import { AdvancedWorkOrder } from 'models/work-order/advanced-work-order.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummary, WorkOrderWrapper } from 'models/work-order';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { SearchResultItemResolver } from 'app/ui-components/search-result-item/search-result-item.resolver';
import { UserService } from 'app/user/user.service';
import { LayerFeatureModel } from 'app/search/layer-result.model';
import { SearchService } from 'app/search/search.service';
import { Feature } from 'sedaru-util/esri-core';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-single-asset-type-list',
	templateUrl: './single-asset-type-list.component.html',
	styleUrls: ['./single-asset-type-list.component.scss']
})
export class SingleAssetTypeListComponent extends MenuPanelBaseComponent {
	@ViewChild('list', { static: true }) list: OmniListComponent;

	assetList: AssetRecord[] | LayerFeatureModel[];

	private assetType: string;

	private cacheOfResolvers = new Map<any, ListItemResolver>();

	private mode: string;

	private workOrder: AdvancedWorkOrder;

	private highlightHandler: Promise<any>;

	private highlightTimeout;

	private get isSearchResult(): boolean {
		return this.mode === 'search-results';
	}

	get alertDialog(): AlertDialogComponent {
		return this.interopService.uiManager.alertDialog;
	}

	constructor(
		private mapService: CanvasMapService,
		private searchService: SearchService,
		private userService: UserService,
		private graphicsService: OmniMapGraphicsService,
		private workOrderFactory: WorkOrderFactory,
		private flashMessageService: FlashMessageService,
		private interopService: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;
		this.mode = args.parameter.mode;
		this.assetList = args.parameter['list'] || [];
		this.assetType = args.parameter['asset-type'] || '';
	}

	ngOnInit(): void {
		if (this.uiState && this.uiState.scrollPosition) this.list.initialScroll = this.uiState.scrollPosition;

		this.highlightAssetsOnMap();

		this.graphicsService.closeMapToolTip();

		this.list.getResolver = listItem => {
			if (this.cacheOfResolvers.has(listItem)) return this.cacheOfResolvers.get(listItem);
			if (this.mode === 'search-results') return this.getSearchResultResolver(listItem);
			return this.getIdentifyItemResolver(listItem);
		};

		const definition = this.userService.globalConfig.assetDefinitions.getByAssetType(this.assetType);
		const color = definition ? definition.style.layout.background.color : Color.createFromRGBA('rgba(102, 102, 102, 0.6)');

		const rightButton = { url: 'assets/workorder-white.png', callBackFunction: this.createWorkOrders.bind(this), toolTip: 'create work orders' };

		this.menuPanelComponent.updateView({ title: this.assetType, backgroundColor: color, rightIcon: rightButton });
	}

	getIdentifyItemResolver(listItem) {
		const resolver = new RightIconOneLineItemResolver();
		resolver.getLabel = asset => ((asset as AssetRecord).assetId ? (asset as AssetRecord).assetId.toString() : (asset as AssetRecord).objectId.toString());
		resolver.getAlignment = item => 'center';
		resolver.getRightIconPath = item => 'assets/x-close.png';
		resolver.onShowIconOnHover = item => true;
		this.cacheOfResolvers.set(listItem, resolver);
		return this.cacheOfResolvers.get(listItem);
	}

	getSearchResultResolver(listItem) {
		const resolver = new SearchResultItemResolver();
		resolver.getHeader = i => (i as LayerFeatureModel).foundField.fieldAlias;
		resolver.getMatchText = i => this.searchService.searchQuery;
		resolver.getValue = i => (i as LayerFeatureModel).feature.attributes[i.foundField.fieldName];
		resolver.getRightIconPath = item => 'assets/x-close.png';
		resolver.onShowIconOnHover = item => true;
		this.cacheOfResolvers.set(listItem, resolver);
		return this.cacheOfResolvers.get(listItem);
	}

	onScroll(position: number) {
		if (!this.uiState || !this.uiState.scrollPosition) this.uiState = { scrollPosition: 0 };
		this.uiState.scrollPosition = position;
	}

	highlightAssetsOnMap() {
		if (!this.interopService.uiManager.activeCanvasComponent.canvasMapComponent) {
			return;
		}
		this.config.selectedCanvas.map.pacifier.show = true;
		this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'highlight-assets');
		const features = new Array<Feature>();
		this.assetList.forEach(item => features.push(item.feature));
		this.mapService.highlightAssets(this.config.selectedCanvas, features).then(() => {
			this.config.selectedCanvas.map.pacifier.show = false;
		});
	}

	selectAssetRecord(item: AssetRecord | LayerFeatureModel) {
		if (item instanceof AssetRecord) return this.goToAssetAttributes(item);
		const { feature, assetType } = item as LayerFeatureModel;
		const definition = this.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		const record = AssetRecord.create(definition, feature);
		return this.goToAssetAttributes(record);
	}

	async goToAssetAttributes(assetRecord: AssetRecord) {
		const context = new RecordContextGroup({ assetRecord: assetRecord });
		if (this.mode !== 'create workorder') {
			const geo = this.graphicsService.convertSP(this.config?.selectedCanvas, assetRecord.feature.geometry);
			if (geo) assetRecord.feature.geometry = geo;
			this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'highlight-assets');
			this.mapService.highlightAssets(this.config.selectedCanvas, [assetRecord.feature]).then(graphics => {
				this.config.selectedCanvas.mapView.goTo({ target: graphics, scale: 1000 }, { duration: 1000 });
				this.graphicsService.showMapToolTip(graphics[0], context);
				this.config.selectedCanvas.map.pacifier.show = false;
			});
		}

		const { assetDefinition } = assetRecord;
		switch (this.mode) {
			case 'add workasset':
				if (assetDefinition && assetDefinition.useHierarchy) {
					NavigationService.navigateTo(Pages.hierarchy, { recordContext: context, mode: this.mode, workOrder: this.workOrder });
					return;
				}

				NavigationService.navigateTo(Pages.mapAssetAttributes, {
					mode: 'add workasset',
					feature: assetRecord.feature,
					metric: this.graphicsService.currentlySelectedMetric,
					assetType: assetRecord.assetType,
					workOrder: this.workOrder
				});
				break;
			case 'create workorder':
				this.graphicsService.startWorkOrderCreation(this.config.selectedCanvas, assetRecord as any);
				break;
			case 'asset selection':
			default:
				this.mapService.selectedIdentifyAsset = assetRecord.feature;
				if (!assetDefinition) {
					NavigationService.navigateTo(Pages.mapAssetAttributes, { mode: 'asset selection', feature: assetRecord.feature, assetType: assetRecord?.assetType });
					return;
				}

				let page = Pages.assetInformation;
				if (assetDefinition.useHierarchy) page = Pages.hierarchy;
				NavigationService.navigateTo(page, { recordContext: context });
				break;
		}
	}

	highlightAsset(assetRecord: AssetRecord & LayerFeatureModel) {
		if (this.highlightTimeout) clearTimeout(this.highlightTimeout);
		this.highlightTimeout = setTimeout(() => {
			const yellow = Color.createFromRGBA('rgb(255,215,255)');
			this.highlightHandler = this.mapService.highlightAssets(this.config.selectedCanvas, [assetRecord.feature], 'yellow-highlights', yellow);
		}, 500);
	}

	removeHighlightFromAsset() {
		this.highlightHandler?.then(() => {
			this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'yellow-highlights');
		});
	}

	removeItem(record: AssetRecord & LayerFeatureModel) {
		const indexOfRecord = this.assetList.indexOf(record);
		if (indexOfRecord >= 0) this.assetList.splice(indexOfRecord, 1);
		this.highlightAssetsOnMap();
		this.list.removeItem(record);
	}

	async createWorkOrders() {
		if (this.workOrderFactory.isAdvancedMode) {
			this.alertDialog.mainMessage = { text: 'Feature coming soon!' };
			this.alertDialog.open = true;
			return;
		}
		let assetRecords: AssetRecord[];
		if (!this.isSearchResult) assetRecords = this.assetList as AssetRecord[];
		else assetRecords = this.getAssetRecordsFromSearchResults();
		if (!assetRecords || !assetRecords.length) return;

		const workOrderWrappers = new Array<WorkOrderWrapper>();
		this.menuPanelComponent.updatePacifier(true);
		try {
			const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey(assetRecords.length);
			if (!workOrderKeys || workOrderKeys.length != assetRecords.length) {
				throw new Error();
			}
			for (let i = 0; i < assetRecords.length; i++) {
				if (!assetRecords[i]) continue;
				workOrderWrappers.push(this.workOrderFactory.createWorkOrderModel(workOrderKeys[i], assetRecords[i]));
			}

			NavigationService.navigateTo(Pages.workorderSummary, {
				workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.CreateMultipleWO, workOrderWrappers, assetRecords)
			});
		} catch (e) {
			this.flashMessageService.popMessage('Cannot create workorder. No valid workorder key. Please try again.');
		} finally {
			this.menuPanelComponent.updatePacifier(false);
		}
	}

	private getAssetRecordsFromSearchResults(): AssetRecord[] {
		const assetRecords = new Array<AssetRecord>();
		const searchResult = this.assetList as LayerFeatureModel[];
		if (!searchResult) return assetRecords;

		for (const result of searchResult) {
			const { feature, assetType } = result;
			if (!feature || !assetType) continue;

			const definition = this.userService?.globalConfig?.assetDefinitions?.getByAssetType(assetType);
			if (!definition) continue;

			assetRecords.push(AssetRecord.create(definition, feature));
		}

		return assetRecords;
	}
}
