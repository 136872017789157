
export enum TimeframeUnit {
    Minute,
    Hours,
    Days,
    Weeks,
    Months,
    Quarters,
    Years,
    None
}
