import {gql, Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import { GuiConfigService } from '../../domain-service/gui-config.service';


import { map, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError, of, Subscriber } from 'rxjs';
import { Canvas, CanvasMode } from '../../omni-model/canvas.model';
import { Metric, MetricTile } from 'models';
import { CanvasTab, CanvasTabOutput } from 'omni-model/canvas-tab.model';
import { GuiConfig } from 'omni-model/gui-config.model';

@Injectable({
	providedIn: 'root'
})

/**
 * Allows to persist information about the canvases for a given configuration
 */
export class CanvasService {
	/**
	 * It holds the IDs of the canvases that are at risk of being removed
	 * when changing to a lower number of canvases
	 */
	canvasesAtRisk: Canvas[] = [];
	/** timer handler to wait to save canvas only once within one minute */
	saveCanvasTimer;

	/** Observable to alert when a metric has been added to a canvas as a default metric  */
	private defaultMetricAdded = new Subject<any>();
	defaultMetricAdded$ = this.defaultMetricAdded.asObservable();

	/** Observable to alert when the default metric as been set to none */
	private removeDefaultMetric = new Subject();
	removeDefaultMetric$ = this.removeDefaultMetric.asObservable();

	/** Observable to alert when a tab needs to be removed from a canvas */
	removeTileFromCanvasSubject = new Subject<{
		canvasId: string;
		tabIndex: number;
		tileId?: string;
		metricId: string;
		metricName: string;
	}>();
	removeTileFromCanvas$ = this.removeTileFromCanvasSubject.asObservable();

	private removeMetricFromCanvasSubject = new Subject<{
		metricId: string;
		metricName: string;
		canvas: Canvas;
	}>();
	removeMetricFromCanvas$ = this.removeMetricFromCanvasSubject.asObservable();

	constructor(private guiConfigService: GuiConfigService, private apollo: Apollo) {}
	/**
	 * Returns an array of the IDs of the canvases that are at risk
	 * of being removed if the current layout changes to the given number
	 * @param layout - A number between 1 and 6
	 */
	getCanvasesWithContent(config: GuiConfig, layout: number): Canvas[] {
		const canvases = config.canvasList;
		this.canvasesAtRisk = [];
		canvases.forEach((canvas, index) => {
			if (canvas.mode !== CanvasMode.empty && index >= this.numberOfCanvasPerLayout(layout)) {
				this.canvasesAtRisk.push(canvas);
			}
		});
		return this.canvasesAtRisk;
	}

	/**
	 * @param layout - A number between 1 and 6
	 /*
	 * OMNI currently supports 6 different layouts.
	 * 1: one canvas
	 * 2: two horizontal canvases
	 * 3: two sqaure canvases on top, one horizontal canvas at the bottom
	 * 4: four sqaure canvases
	 * 5: two vertical canvases
	 * 6: one horizontal canvas on top, two square canvases at the bottom
	 */
	numberOfCanvasPerLayout(layout: number): number {
		let numberOfCanvas: number;
		switch (layout) {
			case 1:
				numberOfCanvas = 1;
				break;
			case 2:
				numberOfCanvas = 2;
				break;
			case 3:
				numberOfCanvas = 3;
				break;
			case 4:
				numberOfCanvas = 4;
				break;
			case 5:
				numberOfCanvas = 2;
				break;
			case 6:
				numberOfCanvas = 3;
				break;

			default:
				numberOfCanvas = 0;
				break;
		}
		return numberOfCanvas;
	}

	/**
	 * This method saves the canvas object into our database
	 * @param {number} canvasToSave - the canvas to save.
	 */
	saveOmniCanvas(config: GuiConfig, canvasToSave: Canvas, isNewCanvas?: boolean): Observable<Canvas> {
		console.log('saveOmniCanvas', canvasToSave);
		const { gqlMutationQuery, gqlMutationVariables } = isNewCanvas
			? this.getCreateCanvasGqlVariableWithQuery(config, canvasToSave)
			: this.getUpdateCanvasGqlVariableWithQuery(config, canvasToSave);
		return this.apollo
			.mutate({
				mutation: gqlMutationQuery,
				variables: gqlMutationVariables
			})
			.pipe(
				map((result: any) => {
					// Advance Error handler
					const canvas = result.data.createCanvas ? result.data.createCanvas : result.data.updateCanvas;
					if (canvas.id) {
						canvasToSave.id = canvas.id;
					}
					return canvas;
				}),
				catchError(err => this.guiConfigService.handlerError(err))
			);
	}

	/**
	 * This method return the graphQl varaible and query for creating the canvas object in our database
	 * @param {number} canvasMode - a number derived from the canvas-mode enum indicates the mode of the canvas.
	 */
	getCreateCanvasGqlVariableWithQuery(config: GuiConfig, canvasToSave?: Canvas) {
		let canvasInput;
		const guiConfigId = config.id;
		const { size, position, map: mapInput, mode } = canvasToSave ? canvasToSave : config.selectedCanvas;
		switch (mode) {
			case CanvasMode.map:
				const hasExtentAndZoom = mapInput.initialExtent && isFinite(mapInput.initialZoom);
				canvasInput = {
					size,
					position,
					mode,
					map: hasExtentAndZoom
						? {
								name: mapInput.name,
								baseMapId: mapInput.baseMapId,
								initialExtent: mapInput.initialExtent,
								initialZoom: mapInput.initialZoom
						  }
						: {
								name: mapInput.name,
								baseMapId: mapInput.baseMapId
						  }
				};
				break;
			case CanvasMode.trend:
				canvasInput = {
					position,
					size,
					mode
				};
				break;
			case CanvasMode.table:
				canvasInput = {
					position,
					size,
					mode
				};
				break;
		}
		const gqlMutationQuery = gql`
			mutation createCanvas($guiConfigId: String!, $canvasInput: CreateCanvasInput) {
				createCanvas(guiConfigId: $guiConfigId, canvasInput: $canvasInput) {
					id
					map {
						id
					}
					trend {
						id
					}
				}
			}
		`;
		const gqlMutationVariables = { guiConfigId, canvasInput };

		return { gqlMutationQuery, gqlMutationVariables };
	}

	private getTabOutput(tab: CanvasTab): CanvasTabOutput {
		return {
			active: tab.active,
			defaultMetricId: tab.defaultMetric ? tab.defaultMetric.id : undefined,
			tileId: tab.tile ? tab.tile.id : undefined,
			locked: tab.locked
		};
	}

	/**
	 * This method return the graphQl varaible and query for update the canvas object in our database
	 * @param {number} canvasMode - a number derived from the canvas-mode enum indicates the mode of the canvas.
	 */
	getUpdateCanvasGqlVariableWithQuery(config: GuiConfig, canvasToSave?: Canvas) {
		let canvasInput;
		const { id, position, size, map: canvasMap, tabs, mode, trendStyle: style } = canvasToSave ? canvasToSave : config.selectedCanvas;
		const lockedTabs: CanvasTabOutput[] = [];
		tabs.filter(tab => tab.locked).forEach(tabItem => {
			const lockedTabOutput = {} as CanvasTabOutput;
			lockedTabOutput.active = tabItem.active;
			lockedTabOutput.defaultMetricId = tabItem.defaultMetric ? tabItem.defaultMetric.id : null;
			lockedTabOutput.tileId = tabItem.tile ? tabItem.tile.id : null;
			lockedTabOutput.locked = true;

			lockedTabs.push(lockedTabOutput);
		});
		switch (mode) {
			case CanvasMode.map:
				const mapInput = {
					id: canvasMap.id,
					name: canvasMap.name,
					baseMapId: canvasMap.baseMapId,
					initialExtent: canvasMap.initialExtent,
					initialZoom: canvasMap.initialZoom,
					subLayers: canvasMap.subLayersDTO(),
					baseLayers: canvasMap.baseLayerDTO()
				};
				canvasInput = {
					id,
					position,
					size,
					map: mapInput,
					tabs: lockedTabs
				};
				break;
			case CanvasMode.trend:
				canvasInput = {
					id,
					position,
					size,
					tabs: lockedTabs,
					trend: { style }
				};
				break;
			case CanvasMode.table:
				canvasInput = {
					id,
					position,
					size,
					tabs: lockedTabs
				};
				break;
		}
		const gqlMutationQuery = gql`
			mutation updateCanvas($canvasInput: UpdateCanvasInput) {
				updateCanvas(canvasInput: $canvasInput) {
					id
					map {
						id
					}
				}
			}
		`;
		const gqlMutationVariables = { canvasInput };

		return { gqlMutationQuery, gqlMutationVariables };
	}

	/**
	 * Alerts when a metric has been added as default to a canvas
	 */
	defaultMetricSelected(metric: Metric, selectedCanvas: Canvas) {
		this.defaultMetricAdded.next({ defaultMetric: metric, selectedCanvas: selectedCanvas });
	}

	/** alerts when the default metric of a canvas has been set to none */
	defaultMetricRemoved() {
		this.removeDefaultMetric.next(null);
	}

	/**
	 * Alerts when a tab needs to be removed from a canvas
	 */
	unlinkTileFromCanvas(tileId: string, metricId: string, canvas: Canvas, metricName: string) {
		const indexOfTabToBeRemoved = canvas.tabs.findIndex(tab => tab.tile && tab.tile.id === tileId);
		if (indexOfTabToBeRemoved < 0) return;
		this.removeTileFromCanvasSubject.next({
			canvasId: canvas.id,
			tabIndex: indexOfTabToBeRemoved,
			tileId,
			metricId,
			metricName
		});
	}

	unlinkMetricFromCanvas(metricId: string, metricName: string, canvas: Canvas) {
		this.removeMetricFromCanvasSubject.next({ metricId, metricName, canvas });
	}

	deleteListOfCanvas(idToDelete: string[] | string): Observable<string[]> {
		const idList = Array.isArray(idToDelete) ? idToDelete : [idToDelete];
		return this.apollo
			.mutate({
				mutation: gql`
					mutation deleteListOfCanvas($idList: [String]) {
						deleteListOfCanvas(idList: $idList)
					}
				`,
				variables: { idList }
			})
			.pipe(
				map(result => {
					console.log('sucess');
					return idList;
				}),
				catchError(err => this.guiConfigService.handlerError(err))
			);
	}
}
