import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class Vendor extends OmniModel<Vendor, Contracts.VendorContract> {
	/**
	 * object for material
	 */
	private _id: SedaruUtils.Undoable<number>;
	get id(): number {
		if (!this._id) this._id = new SedaruUtils.Undoable<number>();
		return this._id.value;
	}
	set id(value: number) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._id.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _isActive: SedaruUtils.Undoable<number>;
	get isActive(): number {
		if (!this._isActive) this._isActive = new SedaruUtils.Undoable<number>();
		return this._isActive.value;
	}
	set isActive(value: number) {
		if (!this._isActive) {
			this._isActive = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._isActive.value = value;
	}

	private _unitCost: SedaruUtils.Undoable<number>;
	get unitCost(): number {
		if (!this._unitCost) this._unitCost = new SedaruUtils.Undoable<number>();
		return this._unitCost.value;
	}
	set unitCost(value: number) {
		if (!this._unitCost) {
			this._unitCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._unitCost.value = value;
	}

	private _vendorId: SedaruUtils.Undoable<string>;
	get vendorId(): string {
		if (!this._vendorId) this._vendorId = new SedaruUtils.Undoable<string>();
		return this._vendorId.value;
	}
	set vendorId(value: string) {
		if (!this._vendorId) {
			this._vendorId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._vendorId.value = value;
	}

	private _vendorType: SedaruUtils.Undoable<string>;
	get vendorType(): string {
		if (!this._vendorType) this._vendorType = new SedaruUtils.Undoable<string>();
		return this._vendorType.value;
	}
	set vendorType(value: string) {
		if (!this._vendorType) {
			this._vendorType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._vendorType.value = value;
	}

	static fromContract(contract: Contracts.VendorContract): Vendor {
		const model = new Vendor();
		model.id = contract.ObjectId;
		model.description = contract.Description;
		model.unitCost = contract.UnitCost;
		model.isActive = contract.IsActive;
		model.vendorId = contract.VendorId;
		model.vendorType = contract.VendorType;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.VendorContract {
		const contract = new Contracts.VendorContract();
		contract.ObjectId = this.id;
		contract.Description = this.description;
		contract.IsActive = this.isActive;
		contract.UnitCost = this.unitCost;
		contract.VendorId = this.vendorId;
		contract.VendorType = this.vendorType;
		return contract;
	}

	onUpdateInformation(copyModel: Vendor): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._isActive, () => {
				this.isActive = copyModel.isActive;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._unitCost, () => {
				this.unitCost = copyModel.unitCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._vendorId, () => {
				this.vendorId = copyModel.vendorId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._vendorType, () => {
				this.vendorType = copyModel.vendorType;
			}) || wasChanged;
		return wasChanged;
	}
}
