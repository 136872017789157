export class InvokableEventArgs {
	private static _empty: InvokableEventArgs;
	public static get empty(): InvokableEventArgs {
		if (!InvokableEventArgs.empty) InvokableEventArgs._empty = new InvokableEventArgs();
		return this._empty;
	}
}

export class InvokableEvent {
	private _targets: Array<any>;

	private get targets(): Array<(sender: any, args: any) => void> {
		if (!this._targets) this._targets = new Array<any>();

		return this._targets;
	}
	subscribe(target: (sender: any, args: any) => void) {
		this.targets.push(target);
	}
	unsubscribe(target: (sender: any, args: any) => void) {
		const index = this.targets.indexOf(target);
		if (index < 0) return;

		this.targets.splice(index, 1);
	}

	invoke(sender, args) {
		for (const t of this.targets) {
			t(sender, args);
		}
	}
}
