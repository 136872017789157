import { NavigationService, Pages } from './../../../navigation/navigation.service';
import { WorkCommentWrapper, WorkOrderWrapper } from './../../../../models/work-order';
import { Component, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-workorder-comment',
	templateUrl: './workorder-comment.component.html',
	styleUrls: ['./workorder-comment.component.scss']
})
export class WorkorderCommentComponent extends MenuPanelBaseComponent {
	workOrder: WorkOrderWrapper;
	workComment: WorkCommentWrapper;

	private _canSave = true;

	get canSave(): boolean {
		return this._canSave;
	}

	public get pageIdentifier(): Pages {
		return Pages.workOrderComment;
	}

	readonly backgroundColor: Readonly<any> = { r: 236, g: 137, b: 56, alpha: 100 };

	constructor(private workOrderFactory: WorkOrderFactory, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.workOrder = args.parameter.workOrder;
		this.workComment = args.parameter.workComment;

		if (!this.workComment) this.workComment = this.workOrderFactory.createWorkCommentModel(this.workOrder);

		this.menuPanelComponent.updateView({ title: this.workOrder.workOrderKey, backgroundClass: 'orange-background' });
	}

	ngOnDestroy() {
		this.workComment.undoAll();
	}

	async onSave() {
		if (!this.canSave) return;

		try {
			this._canSave = false;
			await this.workOrderFactory.updateWorkComment(this.workOrder, this.workComment);
			const previousPage = this.menuPanelComponent.navigationRouter.previousPageInBackStack;
			if (!previousPage || previousPage.pageIdentifier === Pages.workOrderCommentList) {
				this.menuPanelComponent.goBack();
				return;
			}

			NavigationService.navigateTo(Pages.workOrderCommentList, { workOrder: this.workOrder });
			NavigationService.removePageFromBackStack(Pages.workOrderComment)
		}
		finally {
			this._canSave = true;
		}
	}
}
