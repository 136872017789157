import { Component, Input, OnInit } from '@angular/core';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { AssetDefinition, Color, Colors } from 'models';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { Asset } from 'models/work-order/asset.model';

@Component({
  selector: 'app-hierarchy-asset-form',
  templateUrl: './hierarchy-asset-form.component.html',
  styleUrls: ['./hierarchy-asset-form.component.scss']
})
export class HierarchyAssetFormComponent implements OnInit {
  constructor() { }

  hierarchyAsset: Asset;
  assetDefinition: AssetDefinition;
  @Input() recordContext: RecordContextGroup;
  fieldFullSize = MenuPanelFieldSize.full;
  fieldHalfSize = MenuPanelFieldSize.half;
  defaultGrayColor = Color.createFromRGBA('rgba(128,128,128,1)');
  private isPreviousDefaultGray = true;

  ngOnInit(): void {
    if (!this.recordContext || !this.recordContext.hierarchyRecord) return;
    this.hierarchyAsset = this.recordContext.hierarchyRecord.asset;
    this.assetDefinition = this.recordContext.hierarchyRecord.assetDefinition;
  }


  getBackgroundColor(index: number): Color {
    if (index === 0) return this.defaultGrayColor;
    if (index % 2 === 0) return this.isPreviousDefaultGray ? this.defaultGrayColor : this.assetDefinition.style.layout.background.color;
    this.isPreviousDefaultGray = !this.isPreviousDefaultGray;
    return this.isPreviousDefaultGray ? this.defaultGrayColor : this.assetDefinition.style.layout.background.color;
  }

  getLeftMargin(index: number): string {
    if (index % 2 === 0) return '0';
    return '4px';
  }
}
