import { Injectable } from '@angular/core';
import { WorkOrderFilterField, WorkOrderFilterFields, WorkOrderFieldNames } from 'models/work-order';

@Injectable({
	providedIn: 'root'
})
export class WorkOrderFieldProvider {
	// This two is for editing work order filter.
	currentFilter: WorkOrderFilterFields;
	selectedFilterField: WorkOrderFilterField;

	// This is for work order field in the summary to edit a work order.
	selectedWorkOrderFieldName: WorkOrderFieldNames;
	constructor() {}
}
