export enum GeometryType {
	esriGeometryPoint = 'esriGeometryPoint',
	esriGeometryMultipoint = 'esriGeometryMultipoint',
	esriGeometryPolyline = 'esriGeometryPolyline',
	esriGeometryPolygon = 'esriGeometryPolygon',
	esriGeometryEnvelope = 'esriGeometryEnvelope',
	esriGeometry = 'esriGeometry'
}

export class Geometry {
	type: string;
	spatialReference: any;
	x?: number;
	y?: number;
	paths?: Array<any>;
	extent?: any;
	latitude?: any;
	longitude?: any;
	rings?: any;
	static fromJson(jsonData: any): Geometry {
		const geo = new Geometry();
		if (!jsonData || !jsonData.geometry) return geo;

		let geometryType: string;
		if (jsonData.geometryType) geometryType = jsonData.geometryType;
		else if (jsonData.geometry.geometryType) geometryType = jsonData.geometry.geometryType;
		else if (jsonData.geometry.type) geometryType = jsonData.geometry.type;
		if (geometryType) geo.type = geometryType.replace('esriGeometry', '').toLowerCase() || jsonData.geometry.type;
		if (jsonData.geometry.x) geo.x = jsonData.geometry.x;
		if (jsonData.geometry.y) geo.y = jsonData.geometry.y;
		if (jsonData.geometry.paths) geo.paths = jsonData.geometry.paths;
		if (jsonData.geometry.extent) geo.extent = jsonData.geometry.extent;
		if (jsonData.geometry.latitude) geo.latitude = jsonData.geometry.latitude;
		if (jsonData.geometry.longitude) geo.longitude = jsonData.geometry.longitude;
		if (jsonData.geometry.spatialReference) geo.spatialReference = jsonData.geometry.spatialReference;
		if (jsonData.geometry.rings) geo.rings = jsonData.geometry.rings;
		return geo;
	}
}

export class GeomteryA extends Geometry {
	rings: any;
}
