import { TrendPoint } from 'interfaces/trend-point.interface';

/**
 * The model for the omni trend's header.
 */
export class CanvasTrendHeader {
    /**
     * The highest data point on the trend.
     */
     maxValue: number;

	/**
     * The lowest data point on the trend.
     */
     minValue: number;

	/**
     * The mean value of the data plotted on the trend.
     */
     avgValue: number;

	/**
     * The latest value on the trend.
     */
     currentValue: number;

	/**
     * The greatest difference between two adjacent time steps
     */
     maxChange: number;

     /**
      * The sum of all the plot point values.
      */
     count: number;

     /**
	 * Boolean variable to enable and disable the trend header.
	 */
     disableHeader: Boolean;

     constructor() {
		 this.calculate(null);
	}



	calculate(data: TrendPoint[]) {
		if (!data || !data.length) {
			this.disableHeader = true;
			this.maxValue = undefined;
			this.minValue = undefined;
			this.avgValue = undefined;
			this.currentValue = undefined;
			this.maxChange = undefined;
			this.count = undefined;
		} else {
			this.disableHeader = false;
			const values = data.map(listItem => listItem.y);
			if (values.length === 1) {
				this.maxChange = 0;
			} else {
				this.maxChange = this.getMaxChangeValue(data);
			}
			this.maxValue = Math.max(...values);
			this.minValue = Math.min(...values);
			this.avgValue = parseFloat((values.reduce((a, b) => a + b, 0) / values.length).toFixed(3));
			this.currentValue = values[values.length - 1];
			this.count = values.reduce((a, b) => a + b);
		}

	}

	private getMaxChangeValue(data: TrendPoint[]) {
		if (data.length === 0) {
			return null;
		}
		let maxDelta = 0;
		for (let i = 0; i < data.length - 1; i++) {
			const delta = Math.abs(data[i + 1].y - data[i].y);
			if (delta > maxDelta) {
				maxDelta = delta;
			}
		}
		return maxDelta;
	}
}
