import { MaterialCostContract, StandardMaterialCostContract } from '../../contracts/work-order';
import { MaterialCost } from './material-cost.model';
import { StandardMaterialCost } from './standard-material-cost.model';

export class MaterialCostWrapper {
	private _materialCost: MaterialCost | StandardMaterialCost;
	get materialCost(): MaterialCost | StandardMaterialCost {
		return this._materialCost;
	}

	constructor(materialCost: MaterialCost | StandardMaterialCost) {
		if (materialCost instanceof MaterialCost) this._materialCost = materialCost as MaterialCost;
		else this._materialCost = materialCost as StandardMaterialCost;
    }

    get id(): string {
        return this._materialCost.id;
    }
    set id(value: string) {
        this._materialCost.id = value;
    }

    get materialId(): string {
        return this._materialCost.materialId;
    }
    set materialId(value: string) {
        this._materialCost.materialId = value;
    }

    get units(): number {
        return this._materialCost.units;
    }
    set units(value: number) {
        this._materialCost.units = value;
    }

    get unitCost(): number {
        return this._materialCost.unitCost;
    }
    set unitCost(value: number) {
        this._materialCost.unitCost = value;
    }

    get datestamp(): string {
        return this._materialCost.datestamp;
    }
    set datestamp(value: string) {
        this._materialCost.datestamp = value;
    }

    get workOrderKey(): string {
        return this._materialCost.workOrderKey;
    }
    set workOrderKey(value: string) {
        this._materialCost.workOrderKey = value;
    }

    get totalCost(): number {
        return this._materialCost.totalCost;
    }
    set totalCost(value: number) {
        this._materialCost.totalCost = value;
    }

    get workTaskId(): string {
        return this._materialCost.workTaskId;
    }
    set workTaskId(value: string) {
        this._materialCost.workTaskId = value;
    }

    get description(): string {
        return this._materialCost.description;
    }
    set description(value: string) {
        this._materialCost.description = value;
    }

    get createdBy(): string {
        return this._materialCost.createdBy;
    }
    set createdBy(value: string) {
        this._materialCost.createdBy = value;
    }

    get isDirty(): boolean {
        return this._materialCost.isDirty;
    }

    isNew = (): boolean => this._materialCost.isNew();

    calculateTotalCost = (): void => this._materialCost.calculateTotalCost();

    createTimeStamp = (): void => this._materialCost.createTimeStamp();

    clearDirty = (): void => this._materialCost.clearDirty();

    undoAll = (): boolean => this._materialCost.undoAll();

    onUpdateInformation = (copyModel): boolean => this._materialCost.onUpdateInformation(copyModel.materialCost);

    updateInformation = (copyModel): boolean => this._materialCost.updateInformation(copyModel.materialCost);

    getContract = (): MaterialCostContract | StandardMaterialCostContract => this._materialCost.getContract();
}
