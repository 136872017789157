import { PacketRecipients } from './packet-recipients';
import { PacketMetadata } from './packet-metadata';

export class NotificationPacket {
	data: string;
	private _methodIdentifier: string;
	get methodIdentifier(): string {
		return this._methodIdentifier;
	}

	private _metadata: PacketMetadata;
	get metadata(): PacketMetadata {
		if (!this._metadata) {
			this._metadata = new PacketMetadata();
		}
		return this._metadata;
	}

	private _recipients: PacketRecipients;
	get recipients(): PacketRecipients {
		return this._recipients;
	}
	set recipients(value: PacketRecipients) {
		this._recipients = value;
	}

	constructor(methodIdentifier: string) {
		this._methodIdentifier = methodIdentifier;
	}

	static fromAny(jObject: any): NotificationPacket {
		const packet = new NotificationPacket(jObject.MethodIdentifier);
		packet.data = jObject.Data;
		packet._metadata = PacketMetadata.fromAny(jObject.Metadata);
		packet._recipients = PacketRecipients.fromAny(jObject.Recipients);

		return packet;
	}

	jsonString(): string {
		let packet: any;
		packet = {
			MethodIdentifier: this.methodIdentifier,
			Data: this.data,
			Metadata: this.metadata.toAny(),
			Recipients: this.recipients.toAny()
		};

		return JSON.stringify(packet);
	}
}
