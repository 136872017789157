import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MetricIcon } from 'models';
import { TwoIconsOneLineItemResolver } from './two-icons-one-line.resolver';
import { ListItemComponentBase } from '../list-item.model';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-two-icons-one-line',
	templateUrl: './two-icons-one-line.component.html',
	styleUrls: ['./two-icons-one-line.component.scss']
})
export class TwoIconsOneLineComponent extends ListItemComponentBase {
	@Input() resolver: TwoIconsOneLineItemResolver;

	@Input() item: any;

	@Output() itemSelected = new EventEmitter();

	@Output() leftIconClicked = new EventEmitter();

	@Output() rightIconClicked = new EventEmitter();

	@Input() selected = false;

	hovered = false;

	itemComponent = this;

	static createResolver(): TwoIconsOneLineItemResolver {
		const resolver = new TwoIconsOneLineItemResolver();
		resolver.templateType = ListTemplates.twoIconsOneLine;
		return resolver;
	}

	getTypeOfIcon(icon) {
		return typeof icon;
	}

	onRightIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.rightIconClicked.emit(this.item);
	}

	onLeftIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.leftIconClicked.emit(this.item);
	}
}
