// Replace this file with ValueFormat and ValueFormatType

export enum ValueFormatEnum {
	none = 0,
	wholeNumber = 1,
	decimalTenths = 2,
	decimalHundredths = 3,
	currency = 4
}

export class ValueFormat {
	name: string;
	enumValue: ValueFormatEnum;

	constructor() {
		this.name = 'none';
		this.enumValue = ValueFormatEnum.none;
	}
}
