import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { Pattern, RecurrenceDefinition } from 'models/recurrence';

@Component({
  selector: 'app-weekly-pattern',
  templateUrl: './weekly-pattern.component.html',
  styleUrls: ['./weekly-pattern.component.scss']
})
export class WeeklyPatternComponent implements OnInit {
  fieldFullSize = MenuPanelFieldSize.full;
  fieldHalfSize = MenuPanelFieldSize.half;

  @Input() isReadOnly: boolean;

  @Input() recurrencePattern: RecurrenceDefinition;

  @Output() daysOfWeekClicked = new EventEmitter();

  get pattern(): Pattern {
    return this.recurrencePattern?.pattern;
  }

  get daysString(): string {
    return this.recurrencePattern?.pattern?.weeklyPattern?.daysString;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
