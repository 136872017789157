<mat-card class="user-context-menu">
	<mat-card-header>
		<mat-card-subtitle>Full name: {{ user.fullName }}</mat-card-subtitle>
		<mat-card-subtitle>Role: {{ user.role }}</mat-card-subtitle>
		<mat-card-subtitle>Department: {{ user.department }}</mat-card-subtitle>
	</mat-card-header>
	<mat-divider class="divider"></mat-divider>
	<mat-card-header class="profile" (click)="goToMyProfile()">
		<mat-card-subtitle>My Profile</mat-card-subtitle>
	</mat-card-header>
	<mat-divider class="divider"></mat-divider>
	<mat-card-header (click)="openFreshDeskLink()">
		<mat-card-subtitle><a href="https://sedaru.freshdesk.com/support/home">Sedaru Support Portal</a><img src="assets/new-tab-white.png"/></mat-card-subtitle>
	</mat-card-header>
	<mat-divider class="divider"></mat-divider>
    <mat-card-header class="profileGroup" *ngIf="user.isProfileGroupEnabled">
		<mat-card-subtitle *ngFor="let profileGroup of user.profileGroupList" 
			[class.selected]="profileGroup.isSelected"
			(click)="updateProfileGroup(profileGroup)">
			<img [src]="checkValidIcon(profileGroup.icon)"/> <span>{{profileGroup.profileName}} </span>
		</mat-card-subtitle>
	</mat-card-header>
	<mat-divider class="divider"></mat-divider>
    
	<mat-card-header class="log-out" (click)="onLogOut()">
		<mat-card-subtitle>Log out</mat-card-subtitle>
	</mat-card-header>
</mat-card>
