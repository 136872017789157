import { ContractBase } from '../contract-base';
import { EndBoundaryContract } from './end-boundary-contract';
import { PatternContract } from './pattern-contract';

export class RecurrencePatternContract extends ContractBase {
    public startDate: string;
    public endBoundary: EndBoundaryContract;
    public type: string;
    public pattern: PatternContract;
    public trigger: string;
    public triggerDateField: string;
    public status: string;
}
