import { Injectable } from '@angular/core';
import * as SedaruSubscriptions from '../../sedaru-util/sedaru-subscriptions-library/index';
import * as SedaruUtils from 'sedaru-util/index';
import { WorkOrderUpdatedHandler } from './handlers/work-order-updated-handler';
import { MetricChangedHandler } from './handlers/metric-changed-handler';
import { CreateWorkOrderHandler } from './handlers/create-work-order-handler';
import { CloseWorkOrderHandler } from './handlers/close-work-order-handler';
import { WorkOrderValidationHandler } from './handlers/work-order-validation-handler';
import { BulkUpdateChangedHandler } from './handlers/bulk-update-changed-handler';
import { TabChangedHandler } from './handlers/tab-changed-handler';

@Injectable({
	providedIn: 'root'
})
export class SedaruSubscriptionsService {
	private _onConnected: SedaruUtils.InvokableEvent;
	get onConnected(): SedaruUtils.InvokableEvent {
		if (!this._onConnected) {
			this._onConnected = new SedaruUtils.InvokableEvent();
		}
		return this._onConnected;
	}

	private _hub: SedaruSubscriptions.SedaruClientHub;
	get hub(): SedaruSubscriptions.SedaruClientHub {
		return this._hub;
	}
	url: string;
	private _customerCode: string;
	get customerCode(): string {
		return this._customerCode;
	}
	private _username: string;
	get username(): string {
		return this._username;
	}

	async initialize(customerCode: string, username: string): Promise<boolean> {
		this._customerCode = customerCode;
		this._username = username;
		this._hub = new SedaruSubscriptions.SedaruClientHub(this.url, this.customerCode, this.username, 'omni', '2.0');
		const connected = await this._hub.start();

		console.log('connected to ' + this.url);
		const workOrderUpdatedHandler = new WorkOrderUpdatedHandler();
		this._hub.methodHandlers.add(workOrderUpdatedHandler);
		const metricChangedHandler = new MetricChangedHandler();
		this._hub.methodHandlers.add(metricChangedHandler);
		const createWorkOrderHandler = new CreateWorkOrderHandler();
		this._hub.methodHandlers.add(createWorkOrderHandler);
		const closeWorkOrderHandler = new CloseWorkOrderHandler();
		this._hub.methodHandlers.add(closeWorkOrderHandler);
		const workOrderValidationHandler = new WorkOrderValidationHandler();
		this._hub.methodHandlers.add(workOrderValidationHandler);
		const tabChangedHandler = new TabChangedHandler();
		this._hub.methodHandlers.add(tabChangedHandler)

		const bulkUpdateChangedHandler = new BulkUpdateChangedHandler();
		this._hub.methodHandlers.add(bulkUpdateChangedHandler);

		if (connected) {
			this.onConnected.invoke(this, undefined);
		}

		return true;
	}
}
