import { WorkInspection } from './work-inspection.model';
import { WorkInspectionContract } from '../../contracts/work-order';

export class WorkInspections extends Array<WorkInspection> {
	static fromContracts(workInspectionContracts: WorkInspectionContract[]) {
		return WorkInspections.fromModels(workInspectionContracts.map(c => WorkInspection.fromContract(c)));
	}

	static fromModels(models: WorkInspection[]): WorkInspections {
		const wrappersList = new WorkInspections();
		for (const model of models) wrappersList.push(model);
		return wrappersList;
	}

	getContracts = () => {
		const workInspectionContracts = new Array<WorkInspectionContract>();
		for (const workInspection of this) {
			const workInspectionContract = workInspection.getContract();
			workInspectionContracts.push(workInspectionContract);
		}
		return workInspectionContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
