import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { UserService } from '../user/user.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { environment } from 'environments/environment';

@NgModule({
	exports: [HttpClientModule]
})
export class GraphQLModule {
	constructor(private apollo: Apollo, private httpLink: HttpLink, private userService: UserService) {
		const cache = new InMemoryCache({ addTypename: false });
		const uri = environment.omniApiServer + '/graphql';
		const auth = setContext((operation, context) => {
			return {
				headers: {
					sedarutoken: this.getCurrentToken()
				}
			};
		});

		const link = ApolloLink.from([auth, this.httpLink.create({ uri })]);

		const defaultOptions: DefaultOptions = {
			watchQuery: {
				errorPolicy: 'all'
			}
		};

		this.apollo.create({ link, cache, defaultOptions });
	}

	private getCurrentToken(): string {
		if (!this.userService || !this.userService.identityController || !this.userService.identityController.sedaruOAuthToken) {
			return '';
		}

		return this.userService.identityController.sedaruOAuthToken.accessToken;
	}
}
