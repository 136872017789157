export namespace URIParser {

	export enum LinkType {
		NONE,
		HTTP,
		HTTPS,
		FTP,
		FTPS,
		SERVER_RELATIVE_UNIX_STYLE, // example: //server/data
		WIN_PATH, // example: C:\dir\file
		WIN_RELATIVE_PATH_NO_DRIVE, // example: server\dir\file
		WIN_PATH_UNIX_STYLE_SEP, // example: C:/dir/file with Unix style separator
		BROWSER_WIN_FILE_PATH_UNIX_STYE // example: file://C:/dir/file with Unix style separator
	}

	const REGEXP_HTTP = /^http:\/\/[^\s$.?#].[^\s]*$/i;
	const REGEXP_HTTPS = /^https:\/\/[^\s$.?#].[^\s]*$/i;
	const REGEXP_FTP = /^ftp:\/\/[^\s$.?#].[^\s]*$/i;
	const REGEXP_FTPS = /^ftps:\/\/[^\s$.?#].[^\s]*$/i;
	const REGEXP_SERVER_SUB_NO_PROTOCOL = /^(\/(\/)?)([^:\?#\s]+)?$/i;
	const REGEXP_LOCAL_WIN_PATH = /^(([A-Z]|[a-z]):\\){1}((-|\w|\s|\.|\(|\))+\\)*([^:\\/?#\s]+)?$/i;
	const REGEXP_LOCAL_WIN_NO_PROTOCOL = /^((-|\w|\s|\.|\(|\))+\\)+([^:\\/?#\s]+)?$/i;
	const REGEXP_LOCAL_WIN_PATH_UNIX_STYLE = /^(([A-Z]|[a-z]):\/){1}((-|\w|\s|\.|\(|\))+\/)*([^:\\/?#\s]+)?$/i;
	const REGEXP_BROWSER_WIN_PATH_UNIX_STYLE = /^(file:\/\/\/)?(([A-Z]|[a-z]):\/){1}((-|\w|\s|\.|\(|\))+\/)*([^:\\/?#\s]+)?$/i;

	/** Method to detect whether the input is a valid link and to return the LinkType */
	export function getLinkType(input: string, isRetry = false): LinkType {
		if (REGEXP_HTTP.test(input)) {
			return LinkType.HTTP;
		} else if (REGEXP_HTTPS.test(input)) {
			return LinkType.HTTPS;
		} else if (REGEXP_FTP.test(input)) {
			return LinkType.FTP;
		} else if (REGEXP_FTPS.test(input)) {
			return LinkType.FTPS;
		} else if (REGEXP_SERVER_SUB_NO_PROTOCOL.test(input)) {
			return LinkType.SERVER_RELATIVE_UNIX_STYLE;
		} else if (REGEXP_LOCAL_WIN_PATH.test(input)) {
			return LinkType.WIN_PATH;
		} else if (REGEXP_LOCAL_WIN_NO_PROTOCOL.test(input)) {
			return LinkType.WIN_RELATIVE_PATH_NO_DRIVE;
		} else if (REGEXP_LOCAL_WIN_PATH_UNIX_STYLE.test(input)) {
			return LinkType.WIN_PATH_UNIX_STYLE_SEP;
		} else if (REGEXP_BROWSER_WIN_PATH_UNIX_STYLE.test(input)) {
			return LinkType.BROWSER_WIN_FILE_PATH_UNIX_STYE;
		} else {
			if (!isRetry) {
				return getLinkType(encodeURI(input), true);
			}
			return LinkType.NONE;
		}
	}
}
