import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GuiConfig } from 'omni-model/gui-config.model';
import { RouterComponent } from 'app/navigation/router/router.component';
import { BackNavigationArgs } from 'app/navigation/back-navigation-args';
import { NavigationService } from 'app/navigation/navigation.service';
import { RouterRegistry } from 'app/navigation/router/router.registry';

@Component({
	selector: 'app-omni-team',
	templateUrl: './team-viewer.component.html',
	styleUrls: ['./team-viewer.component.scss']
})
export class TeamViewerComponent implements OnInit {
	@Input() config: GuiConfig;
	@Input() mode: string;
	@ViewChild('navigationRouter', { static: true }) navigationRouter: RouterComponent;

	constructor() {}

	ngOnInit() {}

	onNavigationHappening(navigationEvent) {
		const { currentComponent, navigatingBack, goingOut } = navigationEvent;
		if (!currentComponent) return;
		if (!navigatingBack) {
			if (goingOut) {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-left-out 400ms forwards';
				}
			} else {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-left-in 400ms forwards';
				}
			}
		} else {
			if (goingOut) {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-right-out 400ms forwards';
				}
			} else {
				for (const element of currentComponent.panelTemplate) {
					element.style.animation = 'slide-to-right-in 400ms forwards';
				}
			}
		}
	}

	onNavigationComplete(activeComponent) {}

	async goBack(args?: BackNavigationArgs) {
		if (!this.navigationRouter.currentPage) {
			return;
		}

		NavigationService.navigateBackTo(null, null, null, RouterRegistry.temViewerRouter);
	}

	canGoBack() {
		return this.navigationRouter.canGoBack();
	}
}
