/**
 * This defines the model for the OMNI map layers loaded on the canvas.
 */
export class MapVisibleLayer {
	id?: string;
	/**
	 * Name of the map
	 */
	title: string;
	/**
	 * check whether visible layer are enabled or not.
	 */
	visible: boolean;

	constructor() {
		this.title = '';
		this.visible = true;
	}

	setVisibleLayer?(visibleLayer: MapVisibleLayer) {
		this.title = visibleLayer.title;
		this.visible = visibleLayer.visible;
	}
}
