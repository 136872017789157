import { StandardWorkOrder } from './standard-work-order.model';
import { AdvancedWorkOrder } from './advanced-work-order.model';
import { WorkInspections } from './work-inspections.model';
import { Attachments } from './attachments.model';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { WorkAssetWrappers } from './work-asset-wrappers.model';
import { MaterialCostWrappers } from './material-cost-wrappers.model';
import { EmployeeCostWrappers } from './employee-cost-wrappers.model';
import { EquipmentCostWrappers } from './equipment-cost-wrappers.model';
import { WorkCommentWrappers } from './work-comment-wrappers.model';
import { FailureReportWrappers } from './failure-report-wrappers.model';
import { TaskWrappers } from './task-wrappers.model';
import { VendorCostWrappers } from './vendor-cost-wrappers.model';
import { CustomFields } from './custom-fields.model';
import { VendorCosts } from './vendor-costs.model';
import { StandardWorkAssets } from './standard-work-assets.model';
import { StandardWorkAsset } from './standard-work-asset.model';
import { WorkAssets } from './work-assets.model';
import { WorkAsset } from './work-asset.model';
import { StandardEmployeeCosts } from './standard-employee-costs.model';
import { EmployeeCosts } from './employee-costs.model';
import { EmployeeCost } from './employee-cost.model';
import { StandardEmployeeCost } from './standard-employee-cost.model';
import { StandardMaterialCosts } from './standard-material-costs.model';
import { StandardMaterialCost } from './standard-material-cost.model';
import { MaterialCosts } from './material-costs.model';
import { MaterialCost } from './material-cost.model';
import { StandardEquipmentCosts } from './standard-equipment-costs.model';
import { StandardEquipmentCost } from './standard-equipment-cost.model';
import { EquipmentCosts } from './equipment-costs.model';
import { EquipmentCost } from './equipment-cost.model';
import { WorkTasks } from './work-tasks.model';
import { HistoryRecord } from '../records/history-record.model';
import { WorkComments } from './work-comments.model';
import { FailureReports } from './failure-reports.model';
import { StandardCustomFields } from './standard-custom-fields.model';
import { StandardCustomField } from './standard-custom-field.model';
import { CustomField } from './custom-field.model';

export class WorkOrderWrapper {
	private _workOrder: AdvancedWorkOrder | StandardWorkOrder;
	get workOrder(): AdvancedWorkOrder | StandardWorkOrder {
		return this._workOrder;
	}

	get isAdvanced(): boolean {
		return this.workOrder instanceof AdvancedWorkOrder;
	}

	get isNew(): boolean {
		return this.workOrder.isNew;
	}

	constructor(workOrder: AdvancedWorkOrder | StandardWorkOrder) {
		if (workOrder instanceof AdvancedWorkOrder) this._workOrder = workOrder as AdvancedWorkOrder;
		else this._workOrder = workOrder as StandardWorkOrder;
	}

	get objectId(): number {
		if (this.workOrder instanceof AdvancedWorkOrder) return 0;
		return (this.workOrder as StandardWorkOrder).objectId;
	}
	set objectId(value: number) {
		if (this.workOrder instanceof AdvancedWorkOrder) return;
		(this.workOrder as StandardWorkOrder).objectId = value;
	}

	get workType(): string {
		return this.workOrder.workType;
	}
	set workType(value: string) {
		this.workOrder.workType = value;
	}

	get workTypeId(): string {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).workTypeId;
	}
	set workTypeId(value: string) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).workTypeId = value;
	}

	get status(): string {
		return this.workOrder.status;
	}
	set status(value: string) {
		this.workOrder.status = value;
	}

	get createdDate(): string {
		return this.workOrder.createdDate;
	}
	set createdDate(value: string) {
		this.workOrder.createdDate = value;
	}

	get startDate(): string {
		return this.workOrder.startDate;
	}
	set startDate(value: string) {
		this.workOrder.startDate = value;
	}

	get completedDate(): string {
		return this.workOrder.completedDate;
	}
	set completedDate(value: string) {
		this.workOrder.completedDate = value;
	}

	get completedBy(): string {
		return this.workOrder.completedBy;
	}
	set completedBy(value: string) {
		this.workOrder.completedBy = value;
	}

	get supervisor(): string {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).supervisor;
	}
	set supervisor(value: string) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).supervisor = value;
	}

	get teamId(): string {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).teamId;
	}
	set teamId(value: string) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).teamId = value;
	}

	get assignedTo(): string {
		return this.workOrder.assignedTo;
	}
	set assignedTo(value: string) {
		this.workOrder.assignedTo = value;
	}

	get priority(): string {
		return this.workOrder.priority;
	}
	set priority(value: string) {
		this.workOrder.priority = value;
	}

	get location(): string {
		return this.workOrder.location;
	}
	set location(value: string) {
		this.workOrder.location = value;
	}

	get customerName(): string {
		return this.workOrder.customerName;
	}
	set customerName(value: string) {
		this.workOrder.customerName = value;
	}

	get contactInfo(): string {
		return this.workOrder.contactInfo;
	}
	set contactInfo(value: string) {
		this.workOrder.contactInfo = value;
	}

	get description(): string {
		return this.workOrder.description;
	}
	set description(value: string) {
		this.workOrder.description = value;
	}

	get comments(): string {
		return this.workOrder.comments;
	}
	set comments(value: string) {
		this.workOrder.comments = value;
	}

	get isCorrective(): number {
		return this.workOrder.isCorrective;
	}
	set isCorrective(value: number) {
		this.workOrder.isCorrective = value;
	}

	get changeStatus(): string {
		return this.workOrder.changeStatus;
	}
	set changeStatus(value: string) {
		this.workOrder.changeStatus = value;
	}

	get changeBy(): string {
		return this.workOrder.changeBy;
	}
	set changeBy(value: string) {
		this.workOrder.changeBy = value;
	}

	get createdBy(): string {
		return this.workOrder.createdBy;
	}
	set createdBy(value: string) {
		this.workOrder.createdBy = value;
	}

	get changeDate(): string {
		return this.workOrder.changeDate;
	}
	set changeDate(value: string) {
		this.workOrder.changeDate = value;
	}

	get budgetId(): string {
		return this.workOrder.budgetId;
	}
	set budgetId(value: string) {
		this.workOrder.budgetId = value;
	}

	get systemId(): string {
		return this.workOrder.systemId;
	}
	set systemId(value: string) {
		this.workOrder.systemId = value;
	}

	get accountNumber(): string {
		return this.workOrder.accountNumber;
	}
	set accountNumber(value: string) {
		this.workOrder.accountNumber = value;
	}

	get workOrderKey(): string {
		return this.workOrder.workOrderKey;
	}
	set workOrderKey(value: string) {
		this.workOrder.workOrderKey = value;
	}

	get parentWorkOrderKey(): string {
		return this.workOrder.parentWorkOrderKey;
	}
	set parentWorkOrderKey(value: string) {
		this.workOrder.parentWorkOrderKey = value;
	}

	get isServiceRequest(): number {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).isServiceRequest;
	}
	set isServiceRequest(value: number) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).isServiceRequest = value;
	}

	get xCoordinate(): number {
		if (this.workOrder instanceof StandardWorkOrder) {
			return (this.workOrder as StandardWorkOrder).xCoordinate;
		} else {
			return (this.workOrder as AdvancedWorkOrder).xCoordinate;
		}
	}
	set xCoordinate(value: number) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).xCoordinate = value;
		} else {
			(this.workOrder as AdvancedWorkOrder).xCoordinate = value;
		}
	}

	get yCoordinate(): number {
		if (this.workOrder instanceof StandardWorkOrder) {
			return (this.workOrder as StandardWorkOrder).yCoordinate;
		} else {
			return (this.workOrder as AdvancedWorkOrder).yCoordinate;
		}
	}
	set yCoordinate(value: number) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).yCoordinate = value;
		} else {
			(this.workOrder as AdvancedWorkOrder).yCoordinate = value;
		}
	}

	get recurrenceTemplateId(): string {
		return this.workOrder.recurrenceTemplateId;
	}
	set recurrenceTemplateId(value: string) {
		this.workOrder.recurrenceTemplateId = value;
	}

	get workAssetGraphics(): any {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		if (!(this.workOrder as AdvancedWorkOrder).workAssetGraphics) (this.workOrder as AdvancedWorkOrder).workAssetGraphics = [];
		return (this.workOrder as AdvancedWorkOrder).workAssetGraphics;
	}
	set workAssetGraphics(workAssetGraphics: any) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).workAssetGraphics = workAssetGraphics;
	}

	get customFields(): CustomFieldWrappers {
		return CustomFieldWrappers.fromModels(this.workOrder.customFields);
	}
	set customFields(value: CustomFieldWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).customFields = StandardCustomFields.fromModels(value.getModels() as StandardCustomField[]);
		}
		(this.workOrder as AdvancedWorkOrder).customFields = CustomFields.fromModels(value.getModels() as CustomField[]);
	}

	get workAssets(): WorkAssetWrappers {
		return WorkAssetWrappers.fromModels(this.workOrder.workAssets);
	}
	set workAssets(value: WorkAssetWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).workAssets = StandardWorkAssets.fromModels(value.getModels() as StandardWorkAsset[]);
			return;
		}

		(this.workOrder as AdvancedWorkOrder).workAssets = WorkAssets.fromModels(value.getModels() as WorkAsset[]);
	}

	get employeeCosts(): EmployeeCostWrappers {
		return EmployeeCostWrappers.fromModels(this.workOrder.employeeCosts);
	}
	set employeeCosts(value: EmployeeCostWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).employeeCosts = StandardEmployeeCosts.fromModels(value.getModels() as StandardEmployeeCost[]);
			return;
		}

		(this.workOrder as AdvancedWorkOrder).employeeCosts = EmployeeCosts.fromModels(value.getModels() as EmployeeCost[]);
	}

	get materialCosts(): MaterialCostWrappers {
		return MaterialCostWrappers.fromModels(this.workOrder.materialCosts);
	}
	set materialCosts(value: MaterialCostWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).materialCosts = StandardMaterialCosts.fromModels(value.getModels() as StandardMaterialCost[]);
			return;
		}

		(this.workOrder as AdvancedWorkOrder).materialCosts = MaterialCosts.fromModels(value.getModels() as MaterialCost[]);
	}

	get equipmentCosts(): EquipmentCostWrappers {
		return EquipmentCostWrappers.fromModels(this.workOrder.equipmentCosts);
	}
	set equipmentCosts(value: EquipmentCostWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) {
			(this.workOrder as StandardWorkOrder).equipmentCosts = StandardEquipmentCosts.fromModels(value.getModels() as StandardEquipmentCost[]);
			return;
		}

		(this.workOrder as AdvancedWorkOrder).equipmentCosts = EquipmentCosts.fromModels(value.getModels() as EquipmentCost[]);
	}

	get vendorCosts(): VendorCostWrappers {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return VendorCostWrappers.fromModels((this.workOrder as AdvancedWorkOrder).vendorCosts);
	}
	set vendorCosts(value: VendorCostWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).vendorCosts = VendorCosts.fromModels(value.getModels());
	}

	get workTasks(): TaskWrappers {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return TaskWrappers.fromModels((this.workOrder as AdvancedWorkOrder).workTasks);
	}
	set workTasks(value: TaskWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).workTasks = WorkTasks.fromModels(value.getModels());
	}

	get workComments(): WorkCommentWrappers {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return WorkCommentWrappers.fromModels((this.workOrder as AdvancedWorkOrder).workComments);
	}
	set workComments(value: WorkCommentWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).workComments = WorkComments.fromModels(value.getModels());
	}

	get failureReports(): FailureReportWrappers {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return FailureReportWrappers.fromModels((this.workOrder as AdvancedWorkOrder).failureReports);
	}
	set failureReports(value: FailureReportWrappers) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		(this.workOrder as AdvancedWorkOrder).failureReports = FailureReports.fromModels(value.getModels());
	}

	get workInspections(): WorkInspections {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).workInspections;
	}
	set workInspections(value) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		if ((this.workOrder as AdvancedWorkOrder).workInspections === value) return;
		(this.workOrder as AdvancedWorkOrder).workInspections = value;
	}

	get attachments(): Attachments {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return (this.workOrder as AdvancedWorkOrder).attachments;
	}
	set attachments(value) {
		if (this.workOrder instanceof StandardWorkOrder) return;
		if ((this.workOrder as AdvancedWorkOrder).attachments === value) return;
		(this.workOrder as AdvancedWorkOrder).attachments = value;
	}

	get historyRecord(): HistoryRecord {
		if (this.workOrder instanceof AdvancedWorkOrder) return null;
		return (this.workOrder as StandardWorkOrder).historyRecord;
	}
	set historyRecord(value: HistoryRecord) {
		if (this.workOrder instanceof AdvancedWorkOrder) return;
		(this.workOrder as StandardWorkOrder).historyRecord = value;
	}

	get isRecurring(): boolean {
		return this.workOrder.isRecurring;
	}

	get isDirty(): boolean {
		return this.workOrder.isDirty;
	}

	static normalizePropertyName(name: string) {
		if (name.trim().toLowerCase() === 'workorder') return 'workOrder';
		if (name.trim().toLowerCase() === 'isadvanced') return 'isAdvanced';
		if (name.trim().toLowerCase() === 'isnew') return 'isNew';
		if (name.trim().toLowerCase() === 'objectid') return 'objectId';
		if (name.trim().toLowerCase() === 'worktype') return 'workType';
		if (name.trim().toLowerCase() === 'worktypeid') return 'workTypeId';
		if (name.trim().toLowerCase() === 'status') return 'status';
		if (name.trim().toLowerCase() === 'createddate') return 'createdDate';
		if (name.trim().toLowerCase() === 'startdate') return 'startDate';
		if (name.trim().toLowerCase() === 'completeddate') return 'completedDate';
		if (name.trim().toLowerCase() === 'completedby') return 'completedBy';
		if (name.trim().toLowerCase() === 'supervisor') return 'supervisor';
		if (name.trim().toLowerCase() === 'teamid') return 'teamId';
		if (name.trim().toLowerCase() === 'assignedto') return 'assignedTo';
		if (name.trim().toLowerCase() === 'priority') return 'priority';
		if (name.trim().toLowerCase() === 'location') return 'location';
		if (name.trim().toLowerCase() === 'customername') return 'customerName';
		if (name.trim().toLowerCase() === 'contactinfo') return 'contactInfo';
		if (name.trim().toLowerCase() === 'description') return 'description';
		if (name.trim().toLowerCase() === 'comments') return 'comments';
		if (name.trim().toLowerCase() === 'iscorrective') return 'isCorrective';
		if (name.trim().toLowerCase() === 'changestatus') return 'changeStatus';
		if (name.trim().toLowerCase() === 'changeby') return 'changeBy';
		if (name.trim().toLowerCase() === 'createdby') return 'createdBy';
		if (name.trim().toLowerCase() === 'changedate') return 'changeDate';
		if (name.trim().toLowerCase() === 'budgetid') return 'budgetId';
		if (name.trim().toLowerCase() === 'systemid') return 'systemId';
		if (name.trim().toLowerCase() === 'accountnumber') return 'accountNumber';
		if (name.trim().toLowerCase() === 'workorderkey') return 'workOrderKey';
		if (name.trim().toLowerCase() === 'parentworkorderkey') return 'parentWorkOrderKey';
		if (name.trim().toLowerCase() === 'isservicerequest') return 'isServiceRequest';
		if (name.trim().toLowerCase() === 'xcoordinate') return 'xCoordinate';
		if (name.trim().toLowerCase() === 'ycoordinate') return 'yCoordinate';
		if (name.trim().toLowerCase() === 'recurrencetemplateid') return 'recurrenceTemplateId';
		if (name.trim().toLowerCase() === 'workassetgraphics') return 'workAssetGraphics';
		if (name.trim().toLowerCase() === 'customfields') return 'customFields';
		if (name.trim().toLowerCase() === 'workassets') return 'workAssets';
		if (name.trim().toLowerCase() === 'employeecosts') return 'employeeCosts';
		if (name.trim().toLowerCase() === 'materialcosts') return 'materialCosts';
		if (name.trim().toLowerCase() === 'equipmentcosts') return 'equipmentCosts';
		if (name.trim().toLowerCase() === 'vendorcosts') return 'vendorCosts';
		if (name.trim().toLowerCase() === 'worktasks') return 'workTasks';
		if (name.trim().toLowerCase() === 'workcomments') return 'workComments';
		if (name.trim().toLowerCase() === 'failurereports') return 'failureReports';
		if (name.trim().toLowerCase() === 'workinspections') return 'workInspections';
		if (name.trim().toLowerCase() === 'attachments') return 'attachments';
		if (name.trim().toLowerCase() === 'historyrecord') return 'historyRecord';
		if (name.trim().toLowerCase() === 'isrecurring') return 'isRecurring';
		if (name.trim().toLowerCase() === 'isdirty') return 'isDirty';
	}

	/** Method to extract all properties of WorkOrderWrapper class */
	public static getClassProperties(woWrapperObject?: WorkOrderWrapper): string[] {
		let properties = Object.keys(WorkOrderWrapper);
		if (!properties || !properties.length) {
			properties = Object.keys(WorkOrderWrapper.prototype);
		}
		if ((!properties || !properties.length) && woWrapperObject) {
			properties = Object.getOwnPropertyNames(woWrapperObject);
		}
		if (!properties || !properties.length) {
			throw new Error('Not able to extract the WorkOrder wrapper properties');
		}

		// cleanup property values by removing any leading underscore chars
		properties = properties.map((property) => property.replace(/^_/, ''));
		return properties;
	}

	clearDirty = () => this.workOrder.clearDirty();

	undoAll = () => this.workOrder.undoAll();

	onUpdateInformation = (copyModel): boolean => this.workOrder.onUpdateInformation(copyModel.workOrder);

	updateInformation = (copyModel): boolean => this.workOrder.updateInformation(copyModel.workOrder);

	getContract = () => this.workOrder.getContract();

	getAWOContractMaximo = () => {
		if (this.workOrder instanceof StandardWorkOrder) return null;
		return this.workOrder.getContractMaximo();
	};
}
