import { Style } from './style.model';

export class Styles extends Array<Style> {
	getById = (id: number): Style => {
		for (const style of this) {
			if (style.id !== id) {
				continue;
			}
			return style;
		}
	};
}
