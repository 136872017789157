import { ContractBase } from '../contract-base';
import { CustomFieldContract } from './custom-field-contract';

export class WorkTaskContract extends ContractBase {
	public ObjectId: number;
	public AssignedTo: string;
	public CompletedDate?: string;
	public Description: string;
	public Sequence: number;
	public Status: string;
	public TaskKey: string;
	public WorkOrderKey: string;
	public WorkTaskID: string;
	public Comments: string;
	public ChangeStatus: string;
	public ChangeBy: string;
	public SystemId: string;
	public TeamID: string;
	public ExtraFields: any;
	public CustomFields: CustomFieldContract[];
}
