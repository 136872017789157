import { HttpServiceBase, ServiceEndpointResult } from './http-service-base';
import { HttpClient } from '@angular/common/http';
import { IdentityController } from './identity-controller';
import { ProfileGroups } from 'app/user/profile-groups.model';

export class ProfileService extends HttpServiceBase {
	constructor(baseUrl: string, httpClient: HttpClient, identityController: IdentityController) {
		super(baseUrl, httpClient, identityController);
	}

	async getUniversalProfileConfigWithProfileGroup(customerCode: string, profileGroup: string): Promise<ServiceEndpointResult<any>> {
		const data = {
			Format: 'json',
			CustomerCode: customerCode,
            ProfileGroup: profileGroup
		};
		const response = await this.postData('/GetUniversalProfileConfigurationByProfileGroup', data);
		const result = new ServiceEndpointResult<any>(response);

		if (!response.success) {
			return result;
		}
		result.result = response.defaultResponse.result;
		return result;
	}

	async getProfileGroups(customerCode: string, username: string): Promise<ServiceEndpointResult<any>> {
		const data = {
			customercode: customerCode,
			username
		};
		const response = await this.postData('/GetProfileGroupsByUsername', data);
		const result = new ServiceEndpointResult<any>(response);

		if (!response.success) {
			return result;
		}

		const apiResult = response.defaultResponse.result;
		if (!Array.isArray(apiResult)) {
			result.result = [];
			return result;
		}

		const pgList = ProfileGroups.fromContracts(response.defaultResponse.result);
		result.result = pgList;
		return result;
	}
	onStringifyData(endpoint: string): boolean {
		return true;
	}
}
