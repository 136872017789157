import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { HeaderedList } from './headered-list.model';
import { ListItemResolver } from '../omni-list/list-item/templates/list-item.resolver';
import { collapseOnLeave, expandOnEnter, expandInShrinkOut } from 'app/app.animations';

@Component({
	selector: 'app-collapsible-list',
	templateUrl: './collapsible-list.component.html',
	styleUrls: ['./collapsible-list.component.scss'],
	animations: [collapseOnLeave, expandOnEnter, expandInShrinkOut]
})
export class CollapsibleListComponent implements OnInit {
	@Input() groups: Array<HeaderedList>;
	@Input() getResolver: (item: any) => ListItemResolver;
	/** the maximum numbers of records to show. If undefined, it returns all items */
	@Input() maxRecords: number;

	@Output() rightIconClicked = new EventEmitter();
	@Output() leftIconClicked = new EventEmitter();
	@Output() itemRightClicked = new EventEmitter();
	@Output() itemSelected = new EventEmitter();
	@Output() itemHovered = new EventEmitter();
	@Output() mouseLeft = new EventEmitter();
	@Output() showMoreClick = new EventEmitter();
	@Output() scroll = new EventEmitter<number>();

	@ViewChild('scrollingContent', { static: true }) wrapper: ElementRef;

	private animationDelay = 100;
	private animationDuration = 400; // the collapse/expand animation
	private scrollTimer;

	collapsing = false;

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.scrollTimer) clearInterval(this.scrollTimer);
	}

	onScroll() {
		this.scroll.emit(this.wrapper.nativeElement.scrollTop);
	}

	toggleLists(list: HTMLElement, i: number) {
		this.groups.forEach(group => (group.collapsed = !group.collapsed));
		this.scrollToList(list);
	}

	scrollTo(position: number) {
		this.scrollTimer = setInterval(() => {
			if (this.wrapper.nativeElement.scrollTop !== position) this.wrapper.nativeElement.scrollTo(0, position);
			else clearInterval(this.scrollTimer);
		}, this.animationDelay + this.animationDuration);
	}

	private scrollToList(list: HTMLElement) {
		setTimeout(() => {
			list.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}, this.animationDuration);
	}
}
