import { EmployeeCost } from './employee-cost.model';
import { EmployeeCostContract, StandardEmployeeCostContract } from '../../contracts/work-order';
import { StandardEmployeeCost } from './standard-employee-cost.model';

export class StandardEmployeeCosts extends Array<StandardEmployeeCost> {
	static fromContracts(employeeCostContracts: StandardEmployeeCostContract[]) {
		const employeeCosts = new StandardEmployeeCosts();
		for (const employeeCostContract of employeeCostContracts) {
			const employeeCost = StandardEmployeeCost.fromContract(employeeCostContract);
			employeeCosts.push(employeeCost);
		}
		return employeeCosts;
	}

	static fromModels(employeeCostModels: StandardEmployeeCost[]): StandardEmployeeCosts {
		const employeeCosts = new StandardEmployeeCosts();
		for (const employeeCost of employeeCostModels) employeeCosts.push(employeeCost);
		return employeeCosts;
	}

	getContracts = () => {
		const employeeCostContracts = new Array<StandardEmployeeCostContract>();
		for (const employee of this) {
			const employeeCostContract = employee.getContract();
			employeeCostContracts.push(employeeCostContract);
		}
		return employeeCostContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = (): void => this.forEach(e => e.clearDirty());
}
