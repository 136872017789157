import { AnalyticsItem } from './analytics-item';
import { AnalyticsException } from './analytics-exception';

export class AnalyticsError extends AnalyticsItem {
	static trackError: (event: AnalyticsError) => void;
	public exception;

	constructor(exception, message?: string, details: string = '') {
		super();
		if (exception) {
			this.exception = exception;
			return;
		}
	}

	public static create(exception, message?: string, details: string = ''): AnalyticsError {
		if (exception) return new AnalyticsError(exception);
		return new AnalyticsError(message, details);
	}

	protected onSubmit(): void {
		if (!AnalyticsError.trackError) return;

		AnalyticsError.trackError(this);
	}
}
