import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SedaruWallComponent } from './sedaru-wall/sedaru-wall.component';
import { NodeNetworkComponent } from './node-network/node-network.component';
import { NodeComponent } from './node/node.component';
import { ConnectorComponent } from './connector/connector.component';

@NgModule({
	declarations: [SedaruWallComponent, NodeNetworkComponent, NodeComponent, ConnectorComponent],
	imports: [CommonModule],
	exports: [SedaruWallComponent]
})
export class SedaruWallModule {}
