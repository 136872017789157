import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import * as SedaruUtils from '../../../sedaru-util';

export class MetricChangedResult extends Subscriptions.MethodHandlerResult<MetricChangedHandler> {
	metricId: string;
	timestamp: string;
	scalarValue: string;

	constructor(handler: MetricChangedHandler) {
		super(handler);
	}
}

export class MetricChangedHandler extends Subscriptions.SedaruClientMethodHandlerBase {
	private _onMetricChanged: SedaruUtils.InvokableEvent;
	public get onMetricChanged(): SedaruUtils.InvokableEvent {
		if (!this._onMetricChanged) this._onMetricChanged = new SedaruUtils.InvokableEvent();

		return this._onMetricChanged;
	}

	private _onMetricContentChanged: SedaruUtils.InvokableEvent;
	public get onMetricContentChanged(): SedaruUtils.InvokableEvent {
		if (!this._onMetricContentChanged) this._onMetricContentChanged = new SedaruUtils.InvokableEvent();

		return this._onMetricContentChanged;
	}

	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLowerCase() === 'metricengine.metricupdated' || methodIdentifier.toLowerCase() === 'metricengine.metriccontentschanged';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		console.log(packet.methodIdentifier);
		return new Promise<Subscriptions.MethodHandlerResultBase>((resolve, reject) => {
			const data = JSON.parse(packet.data);
			// metricid: data.id
			// metricid: data.scalarvalue
			// metricid: data.timestamp
			const result = new MetricChangedResult(this);
			if (!data || !data.metric) {
				result.message = 'payload was undefined';
				resolve(result);
				return;
			}
			result.metricId = data.metric.id;
			if (result.metricId && result.metricId.includes('metric:')) {
				result.metricId = result.metricId.replace('metric:', '');
			}

			result.timestamp = data.metric.timestamp;
			if (packet.methodIdentifier.toLocaleLowerCase() === 'metricengine.metricupdated') {
				result.scalarValue = data.metric.scalar;
				this.onMetricChanged.invoke(this, result);
			} else if (packet.methodIdentifier.toLocaleLowerCase() === 'metricengine.metriccontentschanged') {
				this.onMetricContentChanged.invoke(this, result);
			}

			args.isHandled = true;
			resolve(result);
			return;
		});
	}

	onGetUniqueId(): string {
		return 'MetricChangedHandler'; // do not user Class.name because it doesn't work with minified code.
	}
}
