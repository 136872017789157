import { Component, OnInit } from '@angular/core';
import { OmniSnackBarActionButton } from './snackbar-action-button';
import { OmniSnackBarMessage } from './snackbar-message';
import { OmniSnackBarService } from './omni-snack-bar.service.ts.service';

@Component({
	selector: 'app-omni-snack-bar',
	templateUrl: './omni-snack-bar.component.html',
	styleUrls: ['./omni-snack-bar.component.scss']
})
export class OmniSnackBarComponent implements OnInit {
	message: OmniSnackBarMessage;
	actionButtons: OmniSnackBarActionButton[];

	constructor(private service: OmniSnackBarService) {}

	ngOnInit(): void {
		this.message = this.service.snackBarMessage;
		this.actionButtons = this.service.snackBarButtons;
	}
}
