import { ChannelTypes, Metric } from 'models';
import { MetricRecord } from 'models/records/metric-record.model';
import { AdvancedWorkOrder, StandardWorkOrder } from 'models/work-order';
import { Feature } from 'sedaru-util/esri-core';

export enum MapActionType {
    None = 0,
    Highlight,
    ZoomOnItem,
    PanToItem,
    ClearHighlight,
}

export enum TableActionType {
    None,
    Highlight,
    ClearHighlight
}

export class MetricViewEventArg {
    mapAction?: MapActionType;
    tableAction?: TableActionType;
    showInSidePanel?: boolean;
    metricRecord?: MetricRecord;
    source?: string;

    get metric(): Metric {
        return this.metricRecord.metric;
    }

    get feature(): Feature | AdvancedWorkOrder | StandardWorkOrder | any {
        let feature;

        switch (this.metric.definition.source.type) {
            case ChannelTypes.Asset:
                feature = this.metricRecord.contextGroup.assetRecord.feature;
                break;

            case ChannelTypes.WorkOrder:
                feature = this.metricRecord.contextGroup.workOrderRecord;
                break;

            case ChannelTypes.History:
                feature = this.metricRecord.contextGroup.historyRecord?.feature;
                if (!feature) {
                    feature = this.metricRecord.contextGroup.assetRecord.feature;
                }

                break;

            default:
                throw new Error(`Source type: ${this.metric.definition.source.type} - not supported`);

        }
        return feature;
    }
}
