<div class="omni-snack-bar" [ngStyle]="{ 'background-color': message.backgroundColor.toString() }">
	<img mat-card-image *ngIf="message.iconPath" [src]="message.iconPath" />
	<div class="message" [ngStyle]="{ color: message.textColor }">{{ message.text }}</div>

	<ng-container *ngFor="let actionButton of actionButtons">
		<button mat-button (click)="actionButton.onClick()" [ngStyle]="{ color: actionButton.textColor.toString(), 'background-color': actionButton.backgroundColor.toString() }">
			{{ actionButton.label }}
		</button>
	</ng-container>
</div>
