import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftIconTwoLinesComponent } from './omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { MaterialWrapperModule } from 'app/ui-components/material-wrapper/material-wrapper.module';
import { MenuPanelFieldComponent } from './menu-panel-field/menu-panel-field.component';
import { FormsModule } from '@angular/forms';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { ThreeComponentNavsComponent } from './three-component-navs/three-component-navs.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { AttachmentsCollectionComponent } from './attachments-collection/attachments-collection.component';
import { ListItemComponent } from './omni-list/list-item/list-item.component';
import { UITestComponent } from './ui-components-test-panel';
import { NoIconTwoLinesComponent } from './omni-list/list-item/templates/no-icon-two-lines/no-icon-two-lines.component';
import { NoIconOneLineComponent } from './omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { TwoIconsTwoLinesComponent } from './omni-list/list-item/templates/two-icons-two-lines/two-icons-two-lines.component';
import { RightIconOneLineComponent } from './omni-list/list-item/templates/right-icon-one-line/right-icon-one-line.component';
import { OmniListComponent } from './omni-list/omni-list.component';
import { TwoIconsOneLineComponent } from './omni-list/list-item/templates/two-icons-one-line/two-icons-one-line.component';
import { MetricIconComponent } from 'app/metric/list-metric/edit-metric/metric-icon/metric-icon.component';
import { SearchListComponentComponent } from './search-list-component/search-list-component.component';
import { PhotoSwipeComponent } from './photo-swipe/photo-swipe.component';
import { LeftIconOneLineComponent } from './omni-list/list-item/templates/left-icon-one-line/left-icon-one-line.component';
import { CalendarWidgetComponent } from './calendar-widget/calendar-widget.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TextInMaxLinesComponent } from './text-in-max-lines/text-in-max-lines.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserThumbnailComponent } from './user-thumbnail/user-thumbnail.component';
import { OmniTableComponent } from './omni-table/omni-table.component';
import { DateSliderComponent } from './date-slider/date-slider.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { WebcamDialogComponent } from './webcam-dialog/webcam-dialog.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DropdownComponent } from './drop-down/drop-down.component';
import { ToastComponent } from './toast/toast.component';
import { MapSelectionToolbarComponent } from './map-selection-toolbar/map-selection-toolbar.component';
import { CheckBoxAccordionComponent } from './check-box-accordion/check-box-accordion.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PacifiersComponent } from './pacifiers/pacifiers.component';
import { CollapsibleListComponent } from './collapsible-list/collapsible-list.component';
import { SearchResultItemComponent } from './search-result-item/search-result-item.component';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import { MainHeaderFooterComponent } from './header-footer/main-header-footer/main-header-footer.component';
import { HeaderFooterButtonComponent } from './header-footer/header-footer-button/header-footer-button.component';
import { HeaderDescriptionComponent } from './header-footer/header-description/header-description.component';
import { PacifierHeaderFooterComponent } from './header-footer/pacifier-header-footer/pacifier-header-footer.component';
import { StartingPacifierComponent } from './pacifiers/starting-pacifier/starting-pacifier.component';
import { FingersDrummingComponent } from './pacifiers/fingers-drumming/fingers-drumming.component';
import { SedaruWallModule } from './sedaru-wall/sedaru-wall.module';
import { SedaruWallComponent } from './sedaru-wall/sedaru-wall/sedaru-wall.component';

@NgModule({
	entryComponents: [DeleteDialogComponent, AlertDialogComponent],
	declarations: [
		LeftIconTwoLinesComponent,
		MenuPanelFieldComponent,
		ToggleButtonComponent,
		ThreeComponentNavsComponent,
		ListItemComponent,
		UITestComponent,
		NoIconTwoLinesComponent,
		NoIconOneLineComponent,
		TwoIconsTwoLinesComponent,
		RightIconOneLineComponent,
		OmniListComponent,
		AttachmentComponent,
		AttachmentsCollectionComponent,
		TwoIconsOneLineComponent,
		MetricIconComponent,
		SearchListComponentComponent,
		PhotoSwipeComponent,
		LeftIconOneLineComponent,
		CalendarWidgetComponent,
		TextInMaxLinesComponent,
		UserThumbnailComponent,
		OmniTableComponent,
		DateSliderComponent,
		DeleteDialogComponent,
		LoadingSpinnerComponent,
		WebcamDialogComponent,
		ContextMenuComponent,
		AlertDialogComponent,
		DropdownComponent,
		ToastComponent,
		MapSelectionToolbarComponent,
		CheckBoxAccordionComponent,
		PacifiersComponent,
		CollapsibleListComponent,
		SearchResultItemComponent,
		HeaderFooterComponent,
		MainHeaderFooterComponent,
		HeaderFooterButtonComponent,
		HeaderDescriptionComponent,
		PacifierHeaderFooterComponent,
		StartingPacifierComponent,
		FingersDrummingComponent
	],

	imports: [BrowserModule, BrowserAnimationsModule, CommonModule, MaterialWrapperModule, FormsModule, NgxDaterangepickerMd.forRoot(), InfiniteScrollModule, SedaruWallModule],
	exports: [
		LeftIconTwoLinesComponent,
		MaterialWrapperModule,
		MenuPanelFieldComponent,
		ToggleButtonComponent,
		ThreeComponentNavsComponent,
		UITestComponent,
		ListItemComponent,
		OmniListComponent,
		AttachmentComponent,
		AttachmentsCollectionComponent,
		MetricIconComponent,
		PhotoSwipeComponent,
		SearchListComponentComponent,
		NgxDaterangepickerMd,
		CalendarWidgetComponent,
		TextInMaxLinesComponent,
		OmniTableComponent,
		DateSliderComponent,
		LoadingSpinnerComponent,
		ContextMenuComponent,
		DropdownComponent,
		ToastComponent,
		InfiniteScrollModule,
		MapSelectionToolbarComponent,
		CheckBoxAccordionComponent,
		PacifiersComponent,
		CollapsibleListComponent,
		SearchResultItemComponent,
		HeaderFooterComponent,
		SedaruWallComponent,
		AlertDialogComponent
	]
})
export class UiComponentsModule {}
