import { Binding, BindingPlaceholders } from './binding.model';

export enum TemplateType {
	None,
	ListThreeFieldTemplate,
	SingleTextTemplate
}

export class Template {
	id: number;
	description: string;
	type: TemplateType;
	metricId: string;

	private _bindings: Array<Binding>;
	public get bindings(): Array<Binding> {
		if (!this._bindings) this._bindings = new Array<Binding>();

		return this._bindings;
	}

	validateTemplate(): { isValid: boolean; reason?: string } {
		if (!this.type || this.type === (TemplateType.None as TemplateType)) return { isValid: false, reason: 'No template type provided.' };
		if (this.type === TemplateType.SingleTextTemplate) {
			if (this.bindings.length !== 1) return { isValid: false, reason: 'Template has no bindings.' };
			if (this.bindings[0].placeholder !== BindingPlaceholders.Text) return { isValid: false, reason: 'No biding was provided with Text placeholder' };
			if (!this.bindings[0].value || !this.bindings[0].value.isValid) return { isValid: false, reason: 'Binding has invalid value.' };
			return { isValid: true };
		}

		if (this.bindings.length !== 3) return { isValid: false, reason: `Expected 3 bindings but got ${this.bindings.length}.` };
		const headerBinding = this.bindings.find(b => b.placeholder === BindingPlaceholders.Header);
		if (!headerBinding) return { isValid: false, reason: 'No binding for header' };
		if (!headerBinding.value.isValid) return { isValid: false, reason: 'Biding for header is not valid' };
		const detailsBinding = this.bindings.find(b => b.placeholder === BindingPlaceholders.Details);
		if (!detailsBinding) return { isValid: false, reason: 'No binding for details' };
		if (!detailsBinding.value.isValid) return { isValid: false, reason: 'Biding for details is not valid' };
		return { isValid: true };
	}
}
