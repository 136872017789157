<div class="lem-list">
	<div class="list-header">
		<span>failure report</span>
		<mat-divider class="divider-item"></mat-divider>
	</div>
	<div class="list-wrapper">
		<!-- <app-menu-panel-field
			[titleEnabled]="true"
			[header]="'class'"
			[value]="getDescriptionFromCodedValue(failureResultClass)"
			[height]="failureResultHeight"
			[cssClass]="'light-gray-background'"
			[type]="'text'"
			[disabled]="true"
			[buttonEnabled]="false"
		></app-menu-panel-field> -->
		<app-menu-panel-field
			[titleEnabled]="true"
			[header]="'problem'"
			[value]="getDescriptionFromCodedValue(failureResultProblem)"
			[cssClass]="'orange-background'"
			[type]="'text'"
			[disabled]="true"
			[buttonEnabled]="false"
		></app-menu-panel-field>
		<app-menu-panel-field
			[titleEnabled]="true"
			[header]="'cause'"
			[value]="getDescriptionFromCodedValue(failureResultCause)"
			[cssClass]="'light-gray-background'"
			[type]="'text'"
			[disabled]="true"
			[buttonEnabled]="false"
		></app-menu-panel-field>
		<app-menu-panel-field
			[titleEnabled]="true"
			[header]="'remedy'"
			[value]="getDescriptionFromCodedValue(failureResultRemedy)"
			[cssClass]="'orange-background'"
			[type]="'text'"
			[disabled]="true"
			[buttonEnabled]="false"
		></app-menu-panel-field>
	</div>
</div>