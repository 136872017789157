import { AssetDefinition } from '../../models/asset-definition.model';
import { ArcGISHistoryChannelAttributes } from '../../models/arc-gis-history-channel-attributes.model';
import { Record } from './record.model';
import { Feature } from '../../sedaru-util/esri-core/feature';

export class HistoryRecord extends Record {
	public get assetType(): string {
		return this.assetDefinition.assetType;
	}

	public assetDefinition: AssetDefinition;
	private get assetChannelAttributes(): ArcGISHistoryChannelAttributes {
		return this.assetDefinition.historyChannel.attributes as ArcGISHistoryChannelAttributes;
	}

	public get assetId(): number | string {
		return this.feature.attributes[this.assetChannelAttributes.assetIdFieldName];
	}

	public get workType(): string {
		return this.feature.attributes[this.assetChannelAttributes.workTypeFieldName];
	}

	public get workDate(): Date {
		return this.feature.attributes[this.assetChannelAttributes.completedDateFieldName];
	}

	public get workBy(): string {
		return this.feature.attributes[this.assetChannelAttributes.assignedToFieldName];
	}

	public get workOrderKey(): string {
		return this.feature.attributes['workorderkey'];
	}

	public get workOrderId(): string {
		return this.feature.attributes['workorderid'];
	}

	private constructor(_feature: Feature = null) {
		super(_feature);
	}

	public static create(assetDefinition: AssetDefinition, feature: Feature = null): HistoryRecord {
		const assetRecord = new HistoryRecord(feature);
		assetRecord.assetDefinition = assetDefinition;
		return assetRecord;
	}
}
