import { ContractBase } from '../contract-base';
import { InspectionAttributeContract } from './inspection-attribute-contract';

export class InspectionContract extends ContractBase {
	public InspectionType: string;
	public InspectionTypeID: number;
	public AssetType: string;

	private _attributes: Array<InspectionAttributeContract>;

	get Attributes(): Array<InspectionAttributeContract> {
		return this._attributes ? this._attributes : (this._attributes = new Array<InspectionAttributeContract>());
	}
	set Attributes(value) {
		this._attributes = value;
	}
}
