import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { BulkUpdateJob } from 'domain-service/jobs/bulk-update-job/bulk-update-job';
import { JobState } from 'domain-service/jobs/job-base';
import { Color } from 'models/sedaru-config/style.model';

export class ToastController {

    private _activeJob: BulkUpdateJob;
    get activeJob(): BulkUpdateJob {
        return this._activeJob;
    }

    private _defaultColor: Color;
    private get defaultColor(): Color {
        if (!this._defaultColor) {
            this._defaultColor = new Color();
            this._defaultColor.fromRGBA('45,235,130');
        }

        return this._defaultColor;
    }
    private _redColor: Color;
    private get redColor(): Color {
        if (!this._redColor) {
            this._redColor = new Color();
            this._redColor.fromRGBA('250,0,0');
        }

        return this._redColor;
    }
    private _greenColor: Color;
    private get greenColor(): Color {
        if (!this._greenColor) {
            this._greenColor = new Color();
            this._greenColor.fromRGBA('0,128,0');
        }

        return this._greenColor;
    }

    private dismissActiveJob(): void {
        if (!this.activeJob) return;
        this.activeJob.isDismissed = true;
        this.activeJob.dispose();
        this._activeJob = undefined;
    }

    getMessageText(): string {
        if (!this.activeJob) return '';

        return this.activeJob.progressMessage;
    }
    getButtonColor(): Color {
        if (!this.activeJob) return this.defaultColor;

        return this.activeJob.state === JobState.Running ? this.redColor : this.greenColor;
    }
    show(): boolean {
        if (this.activeJob && this.activeJob.isDismissed === false) return true;

        return false;
    }
    buttonLabel(): string {
        if (!this.activeJob) return 'X';

        if (this.activeJob.state === JobState.Running) {
            return 'Cancel';
        }

        if (this.activeJob.failedCount > 0) {
            return 'View Errors';
        }

        if (this.activeJob.state === JobState.Cancelled) {
            return 'View Results';
        }

        return 'Got it';
    }
    buttonClicked() {
        if (this.activeJob.state === JobState.Running) {
            this.activeJob.cancel(); // request cancel
            return;
        }

        if (this.activeJob.failedCount > 0) {
            this.activeJob.isDismissed = true;
            NavigationService.navigateTo(Pages.bulkEditErrorsList);
            return;
        }

        if (this.activeJob.state === JobState.Cancelled) {
            this.activeJob.isDismissed = true;
            NavigationService.navigateTo(Pages.bulkEditErrorsList);
            return;
        }

        if (this.activeJob.state === JobState.Completed) {
            this.activeJob.isDismissed = true;
            this.activeJob.dispose();
            this._activeJob = undefined;
            return;
		}

		this.activeJob.isDismissed = true;
		this._activeJob = undefined;
		return;
    }

    setBulkJob(job: BulkUpdateJob) {
        this.dismissActiveJob();
        this._activeJob = job;
    }

    constructor() {
    }
}
