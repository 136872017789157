<div class="related-workorder-list">
	<div class="list-header">
		<span>related work orders</span>
		<mat-divider class="divider-item"></mat-divider>
	</div>

	<div class="list-wrapper">
		<app-omni-list [omniListItems]="relatedWorkorders" [connector]="true" [selectedItems]="[selectedItem]" [itemsKey]="'workOrderKey'" (itemClicked)="workorderSelected($event)"></app-omni-list>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<p>related work orders: {{ relatedWorkorders.length - 1 }}</p>
</mat-card-footer>
