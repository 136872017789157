import { HttpServiceBase, ServiceEndpointResult } from './http-service-base';
import { HttpClient } from '@angular/common/http';
import { TokenPair } from './token-pair';

export class OAuthService extends HttpServiceBase {
	constructor(baseUrl: string, httpClient: HttpClient) {
		super(baseUrl, httpClient);
	}

	async refreshTokenAsync(tokenPair: TokenPair): Promise<ServiceEndpointResult<TokenPair>> {
		const result = await this.post('refreshtoken', tokenPair);

		const response = new ServiceEndpointResult<TokenPair>(result);
		if (!result.success) return response;

		const returnToken = new TokenPair();
		Object.assign(returnToken, result.defaultResponse.result);
		response.result = returnToken;
		return response;
	}

	onGetTokenParameterName(): string {
		return 'SedaruToken';
	}
}
