import { AssetLocationsWithAssets } from './asset-locations-with-assets.model';
import { WorkOrderCostCategory } from '../work-order/workorder-cost-category.enum';
import { AdvancedWorkOrder } from './advanced-work-order.model';
import { WorkOrderRawContract } from '../../contracts/work-order';
import { WorkOrderContract } from '../../contracts/work-order';
import { Employees } from './employees.model';
import { Equipments } from './equipments.model';
import { Materials } from './materials.model';

export class AdvancedWorkOrders extends Array<AdvancedWorkOrder> {
	static fromContracts(workOrderRawContracts: WorkOrderRawContract[], assetsByLayers: any, assetLocationsWithAsset: AssetLocationsWithAssets) {
		const advancedWorkOrders = new AdvancedWorkOrders();
		for (const workOrderRawContract of workOrderRawContracts) {
			const advancedWorkOrder = AdvancedWorkOrder.fromContract(workOrderRawContract, assetsByLayers, assetLocationsWithAsset);
			advancedWorkOrders.push(advancedWorkOrder);
		}

		return advancedWorkOrders;
	}

	static fromBasicDataContracts(contracts: Array<any>) {
		const workOrders = new AdvancedWorkOrders();
		if (!contracts) return workOrders;
		for (const wo of contracts) {
			const newWo = AdvancedWorkOrder.fromStub(wo);
			newWo.isNew = false;
			workOrders.push(newWo);
		}
		return workOrders;
	}

	static fromStubs(contracts: Array<any>): AdvancedWorkOrders {
		const workOrders = new AdvancedWorkOrders();
		if (!contracts) return workOrders;
		for (const wo of contracts) {
			const newWo = new AdvancedWorkOrder();
			newWo.workOrderKey = wo.WorkOrderKey;
			newWo.workType = wo.WorkType;
			newWo.startDate = wo.StartDate;
			newWo.completedDate = wo.CompletedDate;
			newWo.xCoordinate = wo.X;
			newWo.yCoordinate = wo.Y;
			newWo.systemId = wo.SystemId;
			newWo.status = wo.Status;
			newWo.isNew = false;
			newWo.hasOnlyBasicData = true;
			workOrders.push(newWo);
		}
		return workOrders;
	}

	getContracts = () => {
		const workOrderContracts = new Array<WorkOrderContract>();
		for (const advanceWorkOrder of this) {
			const workOrderContract = advanceWorkOrder.getContract();
			workOrderContracts.push(workOrderContract);
		}
		return workOrderContracts;
	};

	getByWorkOrderKey = (workOrderKey: string) => this.find(workOrder => workOrder.workOrderKey === workOrderKey);

	getWorkOrderCostByType = (costTypes): Employees | Equipments | Materials => {
		const list: any = [];
		for (const workorder of this) {
			for (const costType of costTypes) {
				if (costType === WorkOrderCostCategory.employee) {
					workorder.employeeCosts.forEach(employeeCost => list.push(employeeCost));
				}
				if (costType === WorkOrderCostCategory.equipment) {
					workorder.equipmentCosts.forEach(equipmentCost => list.push(equipmentCost));
				}
				if (costType === WorkOrderCostCategory.material) {
					workorder.materialCosts.forEach(materialCost => list.push(materialCost));
				}
			}
		}
		return list;
	};

	removeByWorkOrderKey = (workOrderKey: string) => {
		const index = this.findIndex(wo => wo.workOrderKey === workOrderKey);
		if (index > -1) this.splice(index, 1);
	};
}
