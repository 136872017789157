import { EquipmentCostContract, StandardEquipmentCostContract } from '../../contracts/work-order';
import { EquipmentCostWrapper } from './equipment-cost-wrapper.model';
import { EquipmentCost } from './equipment-cost.model';
import { StandardEquipmentCost } from './standard-equipment-cost.model';

export class EquipmentCostWrappers extends Array<EquipmentCostWrapper> {
	static fromContracts(contracts: EquipmentCostContract[] | StandardEquipmentCostContract[]): EquipmentCostWrappers {
		let model: EquipmentCost | StandardEquipmentCost;
		return EquipmentCostWrappers.fromModels(
			contracts.map((c: EquipmentCostContract | StandardEquipmentCostContract) => {
				if (c instanceof EquipmentCostContract) model = EquipmentCost.fromContract(c);
				else if (c instanceof StandardEquipmentCostContract) model = StandardEquipmentCost.fromContract(c);
				return model;
			})
		);
	}

	static fromModels(models: (EquipmentCost | StandardEquipmentCost)[]): EquipmentCostWrappers {
		const wrappersList = new EquipmentCostWrappers();
		for (const model of models) wrappersList.push(new EquipmentCostWrapper(model));
		return wrappersList;
	}

	getContracts = (): EquipmentCostContract[] | StandardEquipmentCostContract[] => this.map(ec => ec.getContract());

	getModels = (): (EquipmentCost | StandardEquipmentCost)[] => this.map(ec => ec.equipmentCost);

	isDirty = (): boolean => !!this.filter(ec => ec.isDirty).length;

	clearDirty = (): void => this.forEach(ec => ec.clearDirty());
}
