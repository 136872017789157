import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel, OmniModelBase, ModelBase } from '../omni-model';

export class HierarchyNode<T> extends ModelBase {
    constructor(value: T, ancestors?: Array<HierarchyNode<T>>, callback?) {
        super();
        this.value = value;
        if (ancestors) this.ancestors = ancestors;
        if (callback) this.children = callback;
    }

    private _position: SedaruUtils.Undoable<number>;
    get position(): number {
        if (!this._position) this._position = new SedaruUtils.Undoable<number>();
        return this._position.value;
    }
    set position(value: number) {
        if (!this._position) {
            this._position = new SedaruUtils.Undoable<number>(value);
            return;
        }

        this._position.value = value;
    }

    private _value: SedaruUtils.Undoable<T>;
    get value(): T {
        if (!this._value) this._value = new SedaruUtils.Undoable<T>();
        return this._value.value;
    }
    set value(value: T) {
        if (!this._value) {
            this._value = new SedaruUtils.Undoable<T>(value);
            return;
        }

        this._value.value = value;
    }

    private _ancestors: SedaruUtils.Undoable<Array<HierarchyNode<T>>>;
    get ancestors(): Array<HierarchyNode<T>> {
        if (!this._ancestors) this._ancestors = new SedaruUtils.Undoable<Array<HierarchyNode<T>>>(new Array<HierarchyNode<T>>());
        return this._ancestors.value;
    }
    set ancestors(value: Array<HierarchyNode<T>>) {
        if (!this._ancestors) {
            this._ancestors = new SedaruUtils.Undoable<Array<HierarchyNode<T>>>(value);
            return;
        }

        this._ancestors.value = value;
    }

    private _children: SedaruUtils.Undoable<Array<HierarchyNode<T>>>;
    get children(): Array<HierarchyNode<T>> {
        if (!this._children) this._children = new SedaruUtils.Undoable<Array<HierarchyNode<T>>>(new Array<HierarchyNode<T>>());
        return this._children.value;
    }
    set children(value: Array<HierarchyNode<T>>) {
        if (!this._children) {
            this._children = new SedaruUtils.Undoable<Array<HierarchyNode<T>>>(value);
            return;
        }

        this._children.value = value;
    }

    private _isChild: SedaruUtils.Undoable<boolean>;
    get isChild(): boolean {
        if (!this._isChild) this._isChild = new SedaruUtils.Undoable<boolean>();
        return this._isChild.value;
    }
    set isChild(value: boolean) {
        if (!this._isChild) {
            this._isChild = new SedaruUtils.Undoable<boolean>(value);
            return;
        }

        this._isChild.value = value;
    }

    get hasChildren(): boolean {
        return this.children.length > 0;
    }

    get hasAncestors(): boolean {
        return this.ancestors.length > 0;
    }
}
