import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Color } from 'models';
import { MenuPanelFieldSize } from './menu-panel-field-size.enum';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { CalendarTypes } from '../calendar-widget/calendar-types.enum';
import { DateRange } from '../calendar-widget/date-range';
import { Subscription } from 'rxjs';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import * as moment from 'moment';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { MenuPanelFieldValueType } from './menu-panel-field-value-type.enum';

@Component({
	selector: 'app-menu-panel-field',
	templateUrl: './menu-panel-field.component.html',
	styleUrls: ['./menu-panel-field.component.scss']
})
export class MenuPanelFieldComponent implements OnInit {
	private _currentStyle: any;
	private _evaluatedValue: any;
	FieldValueType = MenuPanelFieldValueType;

	@ViewChild('hiddenspan', { static: false }) hiddenSpan: ElementRef;

	@ViewChild('input', { static: false }) inputElement: ElementRef;

	/** 'half' or 'full' - whether the field should occupy the full width or just half */
	@Input() width = MenuPanelFieldSize.full;
	/** 'half' or 'full' */
	@Input() height = MenuPanelFieldSize.half;
	/** a string to be set as the header or instruction of the field */
	@Input() header: string;
	/** the value to show in the field */
	@Input() value: any;
	/** 'text', 'date', 'tel', 'password' or 'number'. By default type is text */
	@Input() type = 'text';
	/** value type can have value hyperlink, string */
	@Input() valueType: MenuPanelFieldValueType = MenuPanelFieldValueType.STRING;
	/** the name of a css class or classes to be applied to the field */
	@Input() cssClass: string;
	/** the string to be displayed as a placeholder for the field */
	@Input() placeHolder: string;
	/** whether the field should be disabled or not. By default the field is enabled */
	@Input() disabled = false;
	/** the background color set in the assetDefinition */
	@Input() backgroundColor: Color;

	@Input() isDateOnly = false;
	/** the string representation of the backgroundColor input */
	bgColor: string;
	/** the foregroundColor set in the assetDefinition */
	@Input() textColor: Color;
	/** the maximum number that can be entered in an input field of type 'number' */
	@Input() maxNumber: number;
	/** the minimum number that can be entered in an input field of type 'number' */
	@Input() minNumber: number;
	/** max length for the text area */
	@Input() maxLength: number;
	/** if input is of type input, provide a DateRange for the date picker */
	@Input() dateRange: DateRange;
	/** the string representation of the textColor input */
	color: string;

	@Input() buttonEnabled = false;

	@Input() showTrendIcon = false;

	@Input() enableTrendIcon = false;

	@Input() titleEnabled = false;

	@Input() leftMargin = '0';

	@Input() thumbnailIconPath: string;

	@Input() thumbnailEnabled = false;

	get rightIconPath(): string {
		return this.type === 'date' ? 'assets/calendar.png' : 'assets/next-circled.png';
	}

	get displayValue() {
		if (this.type === 'date' && this.value) return DateUtil.convertLocaleString(this.value);
		return this.value;
	}

	set displayValue(value: any) {
		this.value = value;
	}

	get isTooltipDisabled() {
		return !this.inputElement || this.inputElement.nativeElement.offsetWidth >= this.inputElement.nativeElement.scrollWidth;
	}

	/** event triggered when the user changes the value of the field */
	@Output() valueChange = new EventEmitter();
	/**
	 * the output event triggered when the trend icon on a trendable field is clicked
	 */
	@Output() trendIconSelected = new EventEmitter();

	@Output() goToButtonClick = new EventEmitter();

	private timerHandler;

	@Input() goTo: () => any = () => {};

	constructor(private flashMessageService: FlashMessageService, private interOpService: OmniInteropService) {}

	/** determines if the value in the field is a url */
	get isUri(): boolean {
		if (!this.value) {
			return false;
		}
		const expression = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		const regex = new RegExp(expression);
		const valueString = this.value.toString();
		return regex.test(valueString);
	}

	/** launches the url in the value if the field is read only */
	launchUri() {
		if (this.disabled || !this.isUri) {
			return;
		}

		window.open(this.value.toString(), '_blank');
	}

	ngOnInit() {
		if (this.backgroundColor) {
			this.bgColor = `rgba(${this.backgroundColor.r}, ${this.backgroundColor.g}, ${this.backgroundColor.b}, ${this.backgroundColor.alpha / 100})`;
		}
		if (this.textColor) {
			this.color = `rgba(${this.textColor.r}, ${this.textColor.g}, ${this.textColor.b}, ${this.textColor.alpha / 100})`;
		}
	}

	/** returns the names of the css classes that will style the field */
	getStyles(): string {
		let style;
		switch (this.width) {
			case MenuPanelFieldSize.half:
				style = 'half-field';
				break;
			case MenuPanelFieldSize.full:
			default:
				style = 'full-field';
		}
		if (this.cssClass) style += ' ' + this.cssClass;
		return style;
	}

	/** returns the name of the css class that will set the font size depending on the length of the field value */
	getFontSize() {
		if (this._evaluatedValue === this.value && this._currentStyle) {
			return this._currentStyle;
		}

		let style;
		let valueWidth = 0;
		if (this.hiddenSpan && this.value) {
			for (let i = 0; i < this.value.length; i++) {
				this.hiddenSpan.nativeElement.innerText = this.value[i];
				valueWidth += this.hiddenSpan.nativeElement.offsetWidth;
			}
		}
		switch (this.type) {
			case 'tel':
			case 'password':
			case 'number': // using an <input>
				switch (this.width) {
					case MenuPanelFieldSize.half:
						if (!this.value || (valueWidth >= 0 && valueWidth < 100)) {
							style = 's-font-size';
						} else if (valueWidth >= 100 && valueWidth < 110) {
							style = 'xs-font-size';
						} else {
							style = 'xxs-font-size';
						}
						break;
					case MenuPanelFieldSize.full:
					default:
						if (!this.value || (valueWidth >= 0 && valueWidth < 250)) {
							style = 's-font-size';
						} else if (valueWidth >= 250 && valueWidth <= 310) {
							style = 'xs-font-size';
						} else {
							style = 'xxs-font-size';
						}
				}
				break;

			case 'text':
			case 'date':
			default:
				// using a <textarea>
				switch (this.width) {
					case MenuPanelFieldSize.half:
						if (!this.value || (valueWidth >= 0 && valueWidth < 100)) {
							style = 's-font-size';
						} else if (valueWidth >= 100 && valueWidth < 110) {
							style = 'xs-font-size';
						} else {
							style = 'xxs-font-size';
						}
						break;
					case MenuPanelFieldSize.full:
					default:
						if (!this.value || (valueWidth >= 0 && valueWidth < 250)) {
							style = 's-font-size';
						} else if (valueWidth >= 250 && valueWidth <= 310) {
							style = 'xs-font-size';
						} else {
							style = 'xxs-font-size';
						}
				}
				break;
		}

		this._evaluatedValue = this.value;
		this._currentStyle = style;
		return style;
	}

	updateValue() {
		if (this.type === 'number' && this.maxNumber && this.minNumber) {
			if (this.value > this.maxNumber || this.value < this.minNumber) {
				if (this.value === '') return;
				this.value = '';
				this.flashMessageService.popMessage('Please enter a value between ' + this.minNumber + ' and ' + this.maxNumber + '.');
			} else {
				let decimalLength = 0;
				for (let i = 0; i <= this.value.length; i++) {
					if (this.value[i] === '.') decimalLength = this.value.toString().substring(i + 1).length;
				}
				if (decimalLength > 2) this.value = this.value.slice(0, this.value.length - decimalLength + 2);
			}
		}
		if (!this.timerHandler) {
			clearTimeout(this.timerHandler);
			this.timerHandler = undefined;
		}
		this.timerHandler = setTimeout(() => {
			this.invokeValueChange();
		}, 1000);
	}

	invokeValueChange() {
		this.valueChange.emit(this.value);
		this._currentStyle = undefined;
	}

	onInputFocus(event: MouseEvent) {
		// show date time picker on focus
		if (this.type === 'date') {
			const calendar = this.interOpService.uiManager.dateTimePicker;
			calendar.calendarType = this.isDateOnly ? CalendarTypes.DatePicker : CalendarTypes.DateTimePicker;
			calendar.selected = this.displayValue ? moment(this.displayValue) : moment();
			calendar.boundToElement = this.inputElement;
			calendar.onDateSelected = newDate => {
				this.value = newDate;
				this.invokeValueChange();
				calendar.showCalendar = false;
			};
			calendar.showCalendar = true;
		}
	}
}
