import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { CanvasTrend } from '../../../omni-model/canvas-trend.model';
import { Canvas } from 'omni-model/canvas.model';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { CanvasTrendHeader } from 'omni-model/canvas-trend-header.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { CalendarTypes } from 'app/ui-components/calendar-widget/calendar-types.enum';
import { interval, Subscription } from 'rxjs';
import { GuiConfig } from 'omni-model/gui-config.model';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { TrendStyle } from 'app/menu-panel/trend-settings/trend-style/trend-style.model';
import { ChartingService } from './charting.service';
import { CanvasTrendDataService } from './canvas-trend-data.service';
import { Observable } from 'rxjs';
import { TrendData } from './trend-data';
import { filter, take, tap, timeout } from 'rxjs/operators';
import { CanvasSubViewBaseComponent } from '../canvas/canvas-sub-view-base.component';
import { TrendChartType } from 'enums/trend-chart-type.enum';



/**
 * This component will be loaded inside the canvas container when the trend icon is selected.
 * A chart, date range picker and time slider are some of the important aspects of the component.
 */
@Component({
	selector: 'app-canvas-trend',
	templateUrl: './canvas-trend.component.html',
	styleUrls: ['./canvas-trend.component.scss'],
	providers: [ChartingService, CanvasTrendDataService]
})
export class CanvasTrendComponent extends CanvasSubViewBaseComponent implements OnInit, OnDestroy, AfterViewInit {

	// TODO: there is no need to pass mode via input, it should
	//       be extracted to a service.

	/** Phone, tablet or large screen */
	@Input() displayMode: string;


	/** the trend style used by the chart.js */
	get chartStyle(): TrendStyle {
		if (!this.canvas?.trendStyle) return new TrendStyle();
		return this.canvas?.trendStyle;
	}

	trendsInView: CanvasTrend[] = [];
	header = new CanvasTrendHeader();

	@ViewChild('chartWrapper', { static: true }) chartWrapper: ElementRef;
	@ViewChild('calendarInput', { static: true }) calendarInput: ElementRef;

	@Input() canvas: Canvas;
	@Input() canvasTabsInitialized: CanvasTab[];
	@Input() metricRemoved: any;
	@Input() config: GuiConfig;
	@Output() tabClosed = new EventEmitter<CanvasTab>();

	private currentStartDate: Date;
	private currentEndDate: Date;

	private highlightedTrendChangeSub: Subscription;

	/**
	 * The constructor injects dependencies
	 */
	constructor(private interOpService: OmniInteropService
		, private flashMessageService: FlashMessageService
		, private chartingService: ChartingService
		, private canvasTrendDataService: CanvasTrendDataService) {
		super();
	}
	ngAfterViewInit(): void {
		// need this check so that the elements are sized before
		// chart js is initialized, otherwise the canvas size is set to 0
		// by the library.
		const parentNode = this.chartWrapper.nativeElement.parentNode;
		interval(50).pipe(
			filter(() => {
				console.log('Check interval');
				return parentNode.clientHeight && parentNode.clientWidth;
			})
			, take(1)
		)
			.subscribe(() => {
				this.setDateRange();
				this.chartingService.initialize(this.chartWrapper.nativeElement);
				this.loadTrends();
				this.activateTrend();
				this.onTrendStyleChanged(this.canvas.trendStyle);
				this.highlightedTrendChangeSub = this.chartingService
					.highlightedTrendDataChanged
					.subscribe(n => this.onHighlightedTrendChanged(n));
			});
	}
	ngOnDestroy(): void {
		if (this.highlightedTrendChangeSub) {
			this.highlightedTrendChangeSub.unsubscribe();
			this.highlightedTrendChangeSub = null;
		}
	}

	/**
	 * On init, the trend is populated with a basic empty chart and the trend headers are updated.
	 */
	ngOnInit() {

	}


	/**
	 *
	 * */
	activateTrend() {
		if (this.trendsInView.length !== this.canvas.tabs.length) {
			this.flashMessageService.popMessage('Some metrics could not be trended.')
		}
	}


	/**
	 * Loads the trends for the existing data.
	 * */
	private loadTrends() {
		this.canvas.tabs.forEach(tab => this.addTrend(tab));
		this.onTabSelected(this.canvas.tabs.find(t => t.active));
	}

	/**
	 * Add a new trend.
	 * @param canvasTab
	 */
	addTrend(canvasTab: CanvasTab) {
		let canvasTrend = null;
		if (!canvasTab.metric.definition.isAssetMetric) {
			canvasTrend = new CanvasTrend(canvasTab);

			const trendDataObs: Observable<TrendData> = this.canvasTrendDataService
				.getDataStream(canvasTrend)
				.pipe(
					tap(t => {
						t.canvasTrend.header = null;
						this.setDateRange(t.timeframeStartDate, t.timeframeEndDate);
					})
				);

			const addResult = this.chartingService.addTrend(canvasTrend, trendDataObs);
			if (!addResult.status) {
				canvasTrend = null;
			} else {
				this.trendsInView.push(canvasTrend);
			}
		}

		return canvasTrend;
	}


	/**
	 * Set the date ranges for the chart.
	 * @param startDate
	 * @param endDate
	 */
	private setDateRange(startDate?: Date, endDate?: Date) {
		if ((this.currentStartDate != startDate) || (this.currentEndDate != endDate)) {
			this.currentStartDate = startDate;
			this.currentEndDate = endDate;
			this.canvasTrendDataService.setDataTimeframe(startDate, endDate);
		}
		const mStartDate = moment(this.currentStartDate ?? new Date());
		const mEndDate = moment(this.currentEndDate ?? new Date());
		this.calendarInput.nativeElement.value = mStartDate.format('M/D/YYYY') + ' - ' + mEndDate.format('M/D/YYYY');
	}

	/**
	 * Executed when the charting service changes the highlighted trend.
	 * @param canvasTrend
	 */
	private onHighlightedTrendChanged(canvasTrend: CanvasTrend) {
		this.header = null;
		if (canvasTrend) {
			if (!canvasTrend.header) {
				canvasTrend.header = new CanvasTrendHeader();
				canvasTrend.header.calculate(canvasTrend.data);

			}
			this.header = canvasTrend.header;
		}
		if (!this.header) {
			this.header = new CanvasTrendHeader();
		}
	}


	/**
	 * Executed when the user changes the trend style.
	 * @param style
	 */
	onTrendStyleChanged(style: TrendStyle) {
		if (style.identifier == 'line') {
			this.chartingService.setChartType(TrendChartType.LineFilled);
		} else {
			this.chartingService.setChartType(TrendChartType.Bar);
		}
	}


	/**
	 * Executed when a tab is selected.
	 * @param canvasTabSelected
	 */
	onTabSelected(canvasTabSelected) {
		if (!canvasTabSelected) return;
		const activeTrend = this.trendsInView.find(trend => trend.canvasTab === canvasTabSelected);
		if (activeTrend) {
			this.chartingService.highlightTrend(activeTrend);
		}
	}



	onTabUpdated(canvasTab: CanvasTab, oldId: string) {
		// TODO: Do we really need to remove the chart and add it again?
		this.removeTrend(canvasTab);
		this.addTrend(canvasTab);
	}


	/**
	 * Executed when a new tab is added.
	 * @param canvasTab
	 */
	onTabAdded(canvasTab: CanvasTab) {
		if (!canvasTab || canvasTab.metric?.definition?.isAssetMetric) {
			return;
		}
		const trend: any = this.addTrend(canvasTab);
	}



	/**
	 * Executed when a tab is closed.
	 * @param canvasTab
	 */
	onTabClosed(canvasTab: CanvasTab) {
		if (!canvasTab) {
			return;
		}
		if (this.canvasTabsInitialized && this.canvasTabsInitialized.find(tab => tab.tile === canvasTab.tile)) {
			return;
		}
		this.removeTrend(canvasTab);
	}

	onTabRemoved(tab: CanvasTab) {
		this.onTabClosed(tab);
	}


	/**
	 * This method is called when the chart style icon is clicked.
	 */
	goToStyleList() {
		NavigationService.navigateTo(Pages.trendStyle, { currentlySelectedStyle: this.chartStyle });
	}

	/**
	 * This method is called when the tile background color is changed. This will update the background coloe of the trend
	 * to the tiles background color.
	 * @param {string} metricName the metric name which is the trend's label
	 * @param {string} newColor the newly updated hex color.
	 */
	updateTrendBackgroundColor(metricName: string, newColor: string) {
		const trend = this.trendsInView.find(t => t.metric?.definition.name == metricName);
		if (trend) {
			this.chartingService.updateTrendColor(trend, newColor);
		}
	}

	/**
	 * This method is called when the calendar icon on the trend component is clicked. It opens the calendar.
	 */
	openCalendar(): void {
		let selected;
		const calendar = this.interOpService.uiManager.dateTimePicker;
		calendar.calendarType = CalendarTypes.DateRange;
		if (this.currentStartDate && this.currentEndDate) {
			selected = { startDate: new Date(this.currentStartDate), endDate: new Date(this.currentEndDate) };
		} else {
			selected = { startDate: new Date(), endDate: new Date() };
		}
		calendar.selected = selected;
		calendar.boundToElement = this.calendarInput;

		calendar.onDateSelected = newDate => {
			this.datesUpdated(newDate);
			calendar.showCalendar = false;
		};

		calendar.showCalendar = true;
	}

	/**
	 * The method is called when a date range is selected on the date range picker.
	 * @param {any} range The selected date range.
	 */
	private datesUpdated(range): void {
		if (!(this.areDatesEqual(range.startDate, this.currentStartDate)) || !(this.areDatesEqual(range.endDate, this.currentEndDate))) {
			if (range.startDate && range.endDate) {
				for (let i = 0; i < this.trendsInView.length; i++) {
					this.trendsInView[i].header = null;
				}
				this.setDateRange(range.startDate, range.endDate);
			}
		}
	}


	/**
	 * Compare two dates to determine if they are equal.
	 * @param d1
	 * @param d2
	 */
	private areDatesEqual(d1: Date, d2: Date): boolean {
		return (d1 && d2 && d1?.getTime() == d2?.getTime())
	}


	/**
	 * This method is called when a tab is removed. It will remove a trend.
	 * @param {CanvasTab} canvasTabClosed the tab which is removed.
	 */
	private removeTrend(canvasTab: CanvasTab) {
		const trend: CanvasTrend = this.trendsInView.find(t => t.canvasTab == canvasTab);
		if (trend) {
			this.canvasTrendDataService.remove(trend);
			const idx = this.trendsInView.indexOf(trend);
			this.trendsInView.splice(idx, 1);
			if (this.trendsInView.length == 0) {
				this.setDateRange();
				this.header = new CanvasTrendHeader();
			}
		}
	}

}
