import { AssetChannelAttributes } from './asset-channel-attributes.model';

/**
 * This defines the esri asset history channel attributes
 */
export class ArcGISHistoryChannelAttributes extends AssetChannelAttributes {

	/**
	 * holds the esri asset history service layer index
	 */
	layerIndex: number;
	/**
	 * holds the esri asset history id field name
	 */
	assetIdFieldName = '';
	/**
	 * holds the esri asset history assigned to fieldName
	 */
	assignedToFieldName = '';
	/**
	 * holds the esri asset history completed date fieldName
	 */
	completedDateFieldName = '';
	/**
	 * holds the esri asset history work type fieldName
	 */
	workTypeFieldName = '';


	/**
	 * when loading history records for all assets,
	 * use this number for history days
	 */
	allAssetsHistoryDays: number;

	/**
	 * when loading history for 1 asset, use this
	 * number for history days
	 */
	assetHistoryDays: number;


	getIdFieldName(): string {
		return this.assetIdFieldName ?? 'objectid';
	}

	initialize?(attributes: ArcGISHistoryChannelAttributes) {
		if (!attributes) return;
		this.assetType = attributes.assetType;
		this.layerIndex = attributes.layerIndex;
		this.assetIdFieldName = attributes.assetIdFieldName;
		this.assignedToFieldName = attributes.assignedToFieldName;
		this.completedDateFieldName = attributes.completedDateFieldName;
		this.workTypeFieldName = attributes.workTypeFieldName;
		this.allAssetsHistoryDays = attributes.allAssetsHistoryDays;
		this.assetHistoryDays = attributes.assetHistoryDays;
	}
}
