import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListItemComponentBase } from '../list-item.model';
import { RightIconOneLineItemResolver } from './right-icon-one-line.resolver';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-right-icon-one-line',
	templateUrl: './right-icon-one-line.component.html',
	styleUrls: ['./right-icon-one-line.component.scss']
})
export class RightIconOneLineComponent extends ListItemComponentBase {
	@Input() resolver: RightIconOneLineItemResolver;

	@Input() item: any;

	@Output() rightIconClicked = new EventEmitter();

	@Output() itemSelected = new EventEmitter();

	@Input() selected = false;

	hovered = false;

	itemComponent = this;

	static createResolver(): RightIconOneLineItemResolver {
		const resolver = new RightIconOneLineItemResolver();
		resolver.templateType = ListTemplates.rightIconOneLine;
		return resolver;
	}

	onRightIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.rightIconClicked.emit(this.item);
	}
}
