import { Injectable, OnDestroy } from '@angular/core';
import { TrendStyle } from 'app/menu-panel/trend-settings/trend-style/trend-style.model';
import { CanvasTrend } from 'omni-model/canvas-trend.model';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TrendData } from './trend-data';
import { YAxisType } from 'enums/y-axis-type.enum';
import { TrendChartType } from 'enums/trend-chart-type.enum';
import moment from 'moment';

import {
	Chart,
	LineElement,
	BarElement,
	PointElement,
	LineController,
	BarController,
	LinearScale,
	TimeScale,
	Filler,
	Legend,
	Title,
	Tooltip,
	SubTitle,
	ChartType,
	ChartOptions,
	DefaultDataPoint,
	ChartData
} from 'chart.js';


Chart.register(
	LineElement,
	BarElement,
	PointElement,
	BarController,
	LineController,
	LinearScale,
	TimeScale,
	Filler,
	Legend,
	Title,
	Tooltip,
	SubTitle
);


Chart.defaults.color = 'white';
Chart.defaults.font.family = 'Century-Gothic W01';

@Injectable()
export class ChartingService implements OnDestroy {

	static leftYAxisId = 'yLeftAxis';
	static rightYAxisId = 'yRightAxis';
	static defaultColorStyle = 'rgba(255,255,255,0.7)';
	static secondaryColorStyle = 'rgba(255,255,255,0.1)';
	static transparent = 'rgba(255,255,255,0)';

	private _chartType: TrendChartType = TrendChartType.LineFilled;
	private highlightedTrend: CanvasTrend;
	private _highlightedTrendDataChangedSubject: Subject<CanvasTrend>;
	get highlightedTrendDataChanged(): Observable<CanvasTrend> {
		return this._highlightedTrendDataChangedSubject.asObservable();
	}

	private canvasTrends: CanvasTrend[];
	private chart: Chart;
	private updateChartSubject: Subject<void>;
	private updateSubscription: Subscription;

	constructor() {
		this.canvasTrends = [];
		this._highlightedTrendDataChangedSubject = new Subject<CanvasTrend>();
		const comp = this;
		this.updateChartSubject = new Subject<void>();
		this.updateSubscription = this.updateChartSubject
			.pipe(debounceTime(500))
			.subscribe(next => comp.onUpdateChart());
	}

	ngOnDestroy(): void {
		for (let i = 0; i < this.canvasTrends.length; i++) {
			this.remove(this.canvasTrends[i]);
		}
		this.canvasTrends = [];
		this.updateSubscription.unsubscribe();
		this.updateSubscription.unsubscribe();
	}



	/**
	 * Initialize the service and create a chart.
	 * Initial time range for the x-axis is time.
	 * @param nativeElement The element to use to render the chart.
	 */
	initialize(nativeElement: any): void {
		if (this.chart) {
			throw new Error('Chart already initialized');
		}

		require('chartjs-adapter-moment');
	 	const date = moment();
		const scales = {
			x: {
				type: 'time',
				time: {
					unit: 'hour'
				},
				grid: {
					color: ChartingService.defaultColorStyle
				},
				ticks: {
					minTicksLimit: 12,
					maxTicksLimit: 12
				},
				min: date.startOf('day').toString(),
				max: date.endOf('day').toString()
			}
		};
		scales[ChartingService.leftYAxisId] = {
			position: 'left',
			display: true,
			title: {
				display: true,
				text: 'value'
			},
			grid: {
				color: ChartingService.defaultColorStyle,
				tickColor: ChartingService.defaultColorStyle,
			},
			beginAtZero: true
		};
		scales[ChartingService.rightYAxisId] = {
			position: 'right',
			display: false,
			title: {
				display: true,
				text: 'value'
			},
			grid: {
				color: ChartingService.defaultColorStyle,
				tickColor: ChartingService.defaultColorStyle,
			},
			beginAtZero: true
		};

		this.chart = new Chart(nativeElement, {
			type: this.getChartTypeString(),
			data: null,
			options: {
				interaction: {
					intersect: true,
					mode: 'index'
				},
				scales: <any>scales,
				maintainAspectRatio: false,
				responsive: false,
				plugins: {
					tooltip: {
						usePointStyle: true,
					}
				}
			}

		});
	}


	/**
	 * Determine if the y-axis can be added to the chart.
	 * @param yAxisLabel The y-axis to be tested.
	 * @returns true if axis can be added.
	 */
	canAddYAxis(yAxisLabel: string): boolean {
		if (this.getYAccessToUse(yAxisLabel) != null) {
			return true;
		}
		return false;
	}


	/**
	 * Set the type of chart to be rendered.
	 * NOTE: This will cause an update to the chart when the current
	 *       chart type is different from the new type.
	 * @param chartType
	 */
	setChartType(chartType: TrendChartType) {
		if (this._chartType != chartType) {
			this._chartType = chartType;
			if (this.chart) {
				this.chart.config.type = this.getChartTypeString();
				this.updateChart();
			}
		}
	}


	/**
	 * Get the current chart type.
	 */
	getChartType(): TrendChartType {
		return this._chartType;
	}

	/**
	 * Determine the y-axis to use for this dataset.
	 * If the returned value is null, then this dataset
	 * cannot be added to the chart. If the requested axis
	 * is already in use the next available axis will be returned.
	 * @param yAxisLabel The label for the y-axis.
	 * @param yAxisType The requested y-axis, this is optional.
	 * @returns Type: YAxisType if dataset can be added, null otherwise.
	 */
	private getYAccessToUse(yAxisLabel: string, yAxisType?: YAxisType): YAxisType {
		if (!this.canvasTrends.length) {
			// there are no datasets in the chart, so we can
			// use requested or default to left.
			return yAxisType ?? YAxisType.Left;
		} else {
			let leftAxisInUse = false;
			let rightAxisInUser = false;
			for (let i = 0; i < this.canvasTrends.length; i++) {
				const item = this.canvasTrends[i];
				if (item.yAxisLabel == yAxisLabel) {
					// same y-axis label so we can chart this on the same
					// access as the existing axis type.
					return item.yAxisType;
				} else if (item.yAxisType != yAxisType) {
					if (item.yAxisType == YAxisType.Left) {
						leftAxisInUse = true;
					} else {
						rightAxisInUser = true;
					}

					if (leftAxisInUse && rightAxisInUser) {
						// we allow only two y-axis and both are in use.
						return null;
					}
				}
			}
			return leftAxisInUse ? YAxisType.Right : YAxisType.Left;
		}

		// should never reach here.
		return null;
	}

	/**
	 * Add the provided trend to the chart.
	 * @param canvasTrend The trend to be added.
	 */
	addTrend(canvasTrend: CanvasTrend, dataStream: Observable<TrendData>): { status: boolean, error?: string } {

		const result = { status: false, error: 'Unknown' };
		const yAxisToAdd = this.getYAccessToUse(canvasTrend.yAxisLabel)
		if ((yAxisToAdd != null) && (this.canvasTrends.indexOf(canvasTrend) < 0)) {
			this.setYAxisStyle(yAxisToAdd);
			this.setChartYAxis(yAxisToAdd, true, canvasTrend.yAxisLabel);
			canvasTrend.yAxisType = yAxisToAdd;
			this.adjustStackOrderAndRemoveHighlight();

			const chartDataSet = {
				data: [],
				label: canvasTrend.label,
				borderColor: '#fff',
				backgroundColor: canvasTrend.backgroundColor + 'BF',
				radius: 2,
				borderWidth: 2,
				barThickness: 'flex',
				fill: 'origin',
				order: 0,
				pointRadius: 3,
				pointHoverRadius: 5,
				tension: 0.35,
				yAxisID: this.getAxisId(yAxisToAdd)
			};

			this.chart.data.datasets.push(chartDataSet);
			this.canvasTrends.push(canvasTrend);
			this.highlightedTrend = canvasTrend;
			canvasTrend.dataSet = chartDataSet;

			canvasTrend.dataSubscription = dataStream.subscribe(
				next => this.onNewTrendData(next)
				, err => this.remove(canvasTrend) // not sure if the trend should be removed on an error.
				, () => this.remove(canvasTrend)
			);

			result.status = true;
			result.error = null;
		} else {
			// TODO: should this message indicate that the both y-axes are in use.
			result.error = 'Maximum allowable trend limit reached.'
		}

		return result;
	}


	/**
	 * Highlight the given trend.
	 * @param canvasTrend
	 */
	highlightTrend(canvasTrend: CanvasTrend) {
		if (canvasTrend.dataSet) {
			const idx = this.canvasTrends.indexOf(canvasTrend);
			if (idx >= 0) {
				this.adjustStackOrderAndRemoveHighlight();
				canvasTrend.dataSet.order = 0;
				canvasTrend.dataSet.borderColor = '#fff';
				canvasTrend.dataSet.radius = 2;
				canvasTrend.dataSet.pointRadius = 3;
				const yAxisType: YAxisType = canvasTrend.yAxisType;
				this.setYAxisStyle(yAxisType);
				this.updateChart();
			}
			this.highlightedTrend = canvasTrend;
			this._highlightedTrendDataChangedSubject.next(canvasTrend);
		}

	}


	/**
	 * Change the trend color.
	 * @param trend
	 */
	updateTrendColor(trend: CanvasTrend, newColor: string) {
		const canvasTrend = this.canvasTrends.find(t => t == trend);
		if (canvasTrend?.dataSet) {
			canvasTrend.dataSet.backgroundColor = newColor + 'BF';
			this.updateChart();
		}
	}


	updateChart(): void {
		this.updateChartSubject.next();
	}

	private onUpdateChart() {
		this.chart.update();
	}



	/**
	 * Executed when new data arrives for a trend.
	 * @param chartDataset
	 * @param canvasTrend
	 * @param data
	 */
	private onNewTrendData(trendData: TrendData) {
		console.log('ChartingService:onNewTrendData -> New Data', trendData);
		const canvasTrend = trendData.canvasTrend;
		canvasTrend.dataSet.data = trendData.points;
		canvasTrend.data = trendData.points;
		const xScale = this.chart.options.scales.x;
		xScale.min = trendData.timeframeStartDate.toUTCString();
		xScale.max = trendData.timeframeEndDate.toUTCString();
		if (trendData.timeframeIntervalDays == 0) {
			(<any>xScale).time.unit = 'hour';
		} else {
			(<any>xScale).time.unit = 'day';
		}

		this.updateChart();
		if (this.highlightedTrend === canvasTrend) {
			this._highlightedTrendDataChangedSubject.next(this.highlightedTrend);
		}
	}


	/**
	 * Removes a trend from the chart, the subscription is also removed.
	 * @param chartDataset
	 * @param canvasTrend
	 * @param dataSubscription
	 */
	private remove(canvasTrend: CanvasTrend) {

		const chartDataset = canvasTrend.dataSet;

		// remove trend from the trend collection
		const idx = this.canvasTrends.indexOf(canvasTrend);
		if (idx >= 0) {
			this.canvasTrends.splice(idx, 1);
		}

		// remove the trend from the chart, and update the y-axis if required
		const chartDatasets = this.chart.data.datasets;
		const datasetIdx = chartDatasets.indexOf(chartDataset);
		if (datasetIdx >= 0) {
			chartDatasets.splice(datasetIdx, 1);

			if (chartDatasets.length) {
				const yAxisInUse = this.canvasTrends.some(t => t.yAxisType == canvasTrend.yAxisType);
				if (!yAxisInUse) {
					this.setChartYAxis(canvasTrend.yAxisType, false);
				}

				// highlight the previously activated trend.
				let maxOrder = -1;
				let dataset = null;
				for (let i = 0; i < chartDatasets.length; i++) {
					if (chartDatasets[i].order > maxOrder) {
						maxOrder = chartDatasets[i].order;
						dataset = chartDatasets[i];
					}
				}
				const trendToHighlight = this.canvasTrends.find(t => t.dataSet === dataset);
				if (trendToHighlight) {
					this.highlightTrend(trendToHighlight);
				}
			}
			canvasTrend.dataSet = null;
			this.updateChart();
		}

		const dataSubscription = canvasTrend.dataSubscription;
		// unsubscribe to changes to the data for this trend.
		if (dataSubscription) {
			dataSubscription.unsubscribe();
			canvasTrend.dataSubscription = null;
		}

		if (this.canvasTrends.length == 0) {
			const xScale = this.chart.options.scales.x;

			(<any>xScale).time.unit = 'hour';
			const date = moment();
			xScale.min = date.startOf('day').toString();
			xScale.max = date.endOf('day').toString();
		}
	}




	/**
	 * Adjust the stack order of the exiting data, pushing the current
	 * dataset to the back of the list and updating the style.
	 * */
	private adjustStackOrderAndRemoveHighlight() {
		// we are changing the dataset stack order so the new dataset
		// is infront. There should only be one dataset with order = 0.
		for (let i = 0; i < this.chart.data.datasets?.length; i++) {
			const dataSet = <any>this.chart.data.datasets[i];
			if (dataSet.order === 0) {
				const maxOrder = Math.max(... this.chart.data.datasets.map(dataset => {
					return dataset.order;
				}));
				dataSet.order = maxOrder + 1;
				dataSet.radius = 0;
				dataSet.pointRadius = 0;
				dataSet.pointHoverRadius = 0;
				dataSet.borderColor = ChartingService.transparent;
			}
		}
	}


	/**
	 * Gets the chart type name as a string.
	 * */
	private getChartTypeString() {
		switch (this._chartType) {
			case TrendChartType.LineFilled:
				return 'line';

			case TrendChartType.Bar:
				return 'bar';

			default:
				throw new Error('Unsupported chart type: ' + this._chartType);

		}
	}


	/**
	 * Get the y-axis id for the given y-axis type.
	 * @param yAxisType
	 */
	private getAxisId(yAxisType: YAxisType): string {
		switch (yAxisType) {
			case YAxisType.Left:
				return ChartingService.leftYAxisId;
				break;

			case YAxisType.Right:
				return ChartingService.rightYAxisId;
				break;

			default:
				throw new Error(`Unknow y-axis type: ${yAxisType}.`);
				break;
		}
	}


	/**
	 * Set the colors for the primary and secondary axes.
	 * @param primaryAxe
	 * @param secondaryAxe
	 */
	private setYAxesColors(primaryAxe: any, secondaryAxe: any) {
		primaryAxe.gridLines = {
			color: ChartingService.defaultColorStyle,
			zeroLineColor: ChartingService.defaultColorStyle
		};
		primaryAxe.ticks.color = ChartingService.defaultColorStyle;
		primaryAxe.title.color = ChartingService.defaultColorStyle;
		secondaryAxe.ticks.color = ChartingService.secondaryColorStyle;
		secondaryAxe.title.color = ChartingService.secondaryColorStyle;
	}


	/**
	 * Set the styles for both axes.
	 * @param yAxisType The type of axis being configured.
	 */
	private setYAxisStyle(yAxisType: YAxisType) {
		const scales = this.chart.config.options.scales;
		if (yAxisType === YAxisType.Left) {
			this.setYAxesColors(scales.yLeftAxis, scales.yRightAxis);
		} else {
			this.setYAxesColors(scales.yRightAxis, scales.yLeftAxis);
		}
	}


	/**
	 * Sets the label and enables axis.
	 * @param yAxisType The axis to configure.
	 * @param label The label for the axis.
	 */
	private setChartYAxis(yAxisType: YAxisType, showAxis: boolean, label?: string): void {

		let yAxis = null;
		switch (yAxisType) {
			case YAxisType.Left:
				yAxis = ChartingService.leftYAxisId;
				break;

			case YAxisType.Right:
				yAxis = ChartingService.rightYAxisId;
				break;
		}

		const scale = <any>this.chart.options.scales[yAxis];
		scale.title.text = label;
		scale.display = showAxis;
	}


}
