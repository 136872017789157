import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderListComponent } from './workorder-list/workorder-list.component';
import { UiComponentsModule } from 'app/ui-components/ui-components.module';
import { WorkOrderOutlineComponent } from './workorder-outline/workorder-outline.component';
import { RouterModule } from '@angular/router';
import { WorkOrderFilterComponent } from './workorder-filter/workorder-filter.component';
import { WorkOrderSummaryComponent } from './workorder-summary/workorder-summary.component';
import { WorkOrderFilterSelectionComponent } from './workorder-filter/workorder-filter-selection/workorder-filter-selection.component';
import { WorkOrderFilterDatePickerComponent } from './workorder-filter/workorder-filter-date-picker/workorder-filter-date-picker.component';
import { AdvancedLaborComponent } from './advanced-labor/advanced-labor.component';
import { WorkorderRelatedWorkordersComponent } from './workorder-related-workorders/workorder-related-workorders.component';
import { WorkorderLEMListComponent } from './workorder-lem-list/workorder-lem-list.component';
import { WorkorderTaskListComponent } from './workorder-task-list/workorder-task-list.component';
import { WorkorderTaskComponent } from './workorder-task/workorder-task.component';
import { WorkorderAssetListComponent } from './workorder-asset-list/workorder-asset-list.component';
import { WorkorderNewLemComponent } from './workorder-new-lem/workorder-new-lem.component';
import { WorkorderNewTaskComponent } from './workorder-new-task/workorder-new-task.component';
import { WorkOrderFieldComponent } from './work-order-field/workorder-field.component';
import { WorkOrderNewLEMMenuPanelComponent } from './workorder-new-lem-menu-panel/workorder-new-lem-menu-panel.component';
import { WorkOrderAttachmentsComponent } from './workorder-attachments/workorder-attachments.component';
import { WorkOrderLEMCostViewComponent } from './workorder-lem-cost-view/workorder-lem-cost-view.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WorkorderCommentListComponent } from './workorder-comment-list/workorder-comment-list.component';
import { WorkorderCommentComponent } from './workorder-comment-list/workorder-comment/workorder-comment.component';
import { WorkorderFailureReportMenuPanelComponent } from './workorder-failure-report-menu-panel/workorder-failure-report-menu-panel.component';
import { WorkOrderFilterCustomFieldComponent } from './workorder-filter/workorder-filter-custom-field/workorder-filter-custom-field.component';
import { BulkEditErrorsListComponent } from './bulk-edit-errors-list/bulk-edit-errors-list.component';
import { HierarchyMenuPanelComponent } from './hierarchy-menu-panel/hierarchy-menu-panel.component';
import { HierarchyListComponent } from './hierarchy-menu-panel/hierarchy-list/hierarchy-list.component';
import { HierarchyWorkorderListComponent } from './hierarchy-workorder-list/hierarchy-workorder-list.component';
import { WorkOrderRecurrenceComponent } from './workorder-recurrence/workorder-recurrence.component';
import { MonthlyPatternComponent } from './workorder-recurrence/monthly-pattern/monthly-pattern.component';
import { YearlyPatternComponent } from './workorder-recurrence/yearly-pattern/yearly-pattern.component';
import { WeeklyPatternComponent } from './workorder-recurrence/weekly-pattern/weekly-pattern.component';
import { DailyPatternComponent } from './workorder-recurrence/daily-pattern/daily-pattern.component';
import { RecurrenceFieldSelectionComponent } from './workorder-recurrence/recurrence-field-selection/recurrence-field-selection.component';

@NgModule({
	declarations: [
		WorkOrderListComponent,
		WorkOrderOutlineComponent,
		WorkOrderSummaryComponent,
		WorkOrderFilterComponent,
		WorkOrderFilterSelectionComponent,
		WorkOrderFilterDatePickerComponent,
		WorkOrderFilterCustomFieldComponent,
		AdvancedLaborComponent,
		WorkorderRelatedWorkordersComponent,
		WorkorderLEMListComponent,
		WorkorderTaskListComponent,
		WorkorderTaskComponent,
		WorkorderAssetListComponent,
		WorkorderNewLemComponent,
		WorkorderNewTaskComponent,
		WorkOrderFieldComponent,
		WorkOrderNewLEMMenuPanelComponent,
		WorkOrderAttachmentsComponent,
		WorkOrderLEMCostViewComponent,
		WorkorderCommentListComponent,
		WorkorderCommentComponent,
		WorkorderFailureReportMenuPanelComponent,
		BulkEditErrorsListComponent,
		HierarchyMenuPanelComponent,
		HierarchyListComponent,
		HierarchyWorkorderListComponent,
		WorkOrderRecurrenceComponent,
		MonthlyPatternComponent,
		YearlyPatternComponent,
		WeeklyPatternComponent,
		DailyPatternComponent,
		RecurrenceFieldSelectionComponent
	],
	imports: [CommonModule, UiComponentsModule, RouterModule, FormsModule],
	exports: [
		WorkOrderOutlineComponent,
		WorkOrderListComponent,
		WorkOrderFilterComponent,
		WorkOrderFilterSelectionComponent,
		WorkOrderFilterDatePickerComponent,
		WorkOrderFilterCustomFieldComponent,
		AdvancedLaborComponent,
		WorkOrderFieldComponent,
		WorkOrderNewLEMMenuPanelComponent,
		WorkOrderAttachmentsComponent,
		WorkorderCommentListComponent,
		WorkorderCommentComponent
	]
})
export class WorkorderModule {}
