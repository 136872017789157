import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'app-toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
	/** text that shows to the left of the component */
	@Input() textWhenChecked: string;
	/** text that shows to the right of the component */
	@Input() textWhenUnchecked: string;
	/** event triggered when the toggle changes */
	@Input() disabled: boolean;
	/** whether the toggle is checked or not */
	@Input() checked: boolean;
	@Output() toggled = new EventEmitter();

	ngOnInit() {
		if (this.isNullOrUndefined(this.disabled)) this.disabled = false;
		if (!this.checked) this.checked = false;
	}
	/** triggerd when the event is clicked  */
	toggle(event: MouseEvent) {
		if (this.disabled) return;
		event.stopPropagation();
		this.checked = !this.checked;
		if (this.checked) {
			this.toggled.emit(this.textWhenChecked);
		} else {
			this.toggled.emit(this.textWhenUnchecked);
		}
	}

	private isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	}
}
