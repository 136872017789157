import { Component, Injectable, Input } from '@angular/core';
import { Metric } from 'models';
import { CanvasTab } from 'omni-model/canvas-tab.model';
import { Canvas, CanvasMode } from 'omni-model/canvas.model';
import { GuiConfig } from 'omni-model/gui-config.model';

@Component({
	template: 'canvas-base'
})
export abstract class CanvasSubViewBaseComponent {

	/**
	 * Holds the given config
	 */
	@Input() config: GuiConfig;

	/**
	 * Holds the canvas on which the view is loaded.
	 */
	@Input() canvas: Canvas;

	/**
	 * Get the tab that is active for this canvas.
	 */

	get activeTab(): CanvasTab {
		// NOTE:
		// This is not ideal, better for Canvas track the active
		// tab instead of searching for it, but this should't
		// affect peformance.
		return this.canvas?.tabs?.find(tab => tab.active);
	}

	/**
	 * Get the metric of the currently active tab.
	 */
	get activeTabMetric(): Metric {
		return this.activeTab?.defaultMetric ?? this.activeTab?.tile?.metric;
	}

	public metricUpdated(canvasTab: CanvasTab) {
		this.onMetricUpdated(canvasTab);
	}
	protected onMetricUpdated(canvasTab: CanvasTab) { }
	/** when tabs have been initially loaded*/
	onTabsInitialized(tabs: CanvasTab[]) { }

	onTabClosed(tab: CanvasTab) { }

	onTabAdded(tab: CanvasTab) { }

	onTabSelected(tab: CanvasTab) { }

	onTabUpdated(tab: CanvasTab, oldId: string) { }

	onTabRemoved(metricID: any) { }

	isCanvasActiveWithMode(mode?: CanvasMode): boolean {
		let result = false;
		if (this.config?.selectedCanvas == this.canvas) {
			if (mode) {
				result = this.canvas.mode === mode;
			} else {
				result = true;
			}
		}
		return result;
	}

	onCanvasActivated() { }
}
