import { ContractBase } from './contract-base';

export class FileStatusAssociation {
	public AssetId: string;
	public AssetType: string;
	public EventId: string;
	public EventType: string;
	constructor() {
		this.AssetId = '';
		this.AssetType = null;
		this.EventId = null;
		this.EventType = null;
	}
}

export class FileStatusContract extends ContractBase {
	public FileIndexId: number;
	public CustomerCode: string;
	public Compress: boolean;
	public CreateThumbnail: boolean;
	public FileType: string;
	public Association: FileStatusAssociation;

	constructor() {
		super();
		this.Association = new FileStatusAssociation();
	}
}
