import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';
import { OmniModel } from '../omni-model';

export class EmployeeRate extends OmniModel<EmployeeRate, Contracts.EmployeeRateContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _rateCode: SedaruUtils.Undoable<string>;
	get rateCode(): string {
		if (!this._rateCode) this._rateCode = new SedaruUtils.Undoable<string>();
		return this._rateCode.value;
	}
	set rateCode(value: string) {
		if (!this._rateCode) {
			this._rateCode = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._rateCode.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _multiplier: SedaruUtils.Undoable<number>;
	get multiplier(): number {
		if (!this._multiplier) this._multiplier = new SedaruUtils.Undoable<number>();
		return this._multiplier.value;
	}
	set multiplier(value: number) {
		if (!this._multiplier) {
			this._multiplier = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._multiplier.value = value;
	}

	static fromContract(contract: Contracts.EmployeeRateContract): EmployeeRate {
		const model = new EmployeeRate();
		model.id = contract.ObjectId.toString();
		model.rateCode = contract.RateCode;
		model.description = contract.Description;
		model.multiplier = contract.Multiplier;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.EmployeeRateContract => {
		const contract = new Contracts.EmployeeRateContract();
		contract.ObjectId = +this.id;
		contract.RateCode = this.rateCode;
		contract.Description = this.description;
		contract.Multiplier = this.multiplier;
		return contract;
	};

	onUpdateInformation(copyModel: EmployeeRate): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._rateCode, () => {
				this.rateCode = copyModel.rateCode;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._multiplier, () => {
				this.multiplier = copyModel.multiplier;
			}) || wasChanged;
		return wasChanged;
	}
}
