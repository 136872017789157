import { Directive, Input, ElementRef, HostListener } from '@angular/core';

/**
 * This directive enables and disables the tooltip based on weather the ellipsis are activated or not.
 */
@Directive({
	selector: '[appTooltip]',
	exportAs: 'toggleTooltip'
})
export class TooltipDirective {
	/**
	 * Boolean variable used to enable and disable tooltip
	 */
	@Input('appTooltip')
	isTooltipDisabled = true;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {ElementRef} element holds the reference to the DOM element
	 */
	constructor(private element: ElementRef) {}

	/**
	 * This method is invoked when mouse enters or is hovered over a DOM element.
	 * It checks whether the text within the element overflows or not and enables and disables tooltip accordingly.
	 */
	@HostListener('mouseover') onHover() {
		const offWidth = this.element.nativeElement.offsetWidth;
		const scrollWidth = this.element.nativeElement.scrollWidth;
		if (offWidth < scrollWidth) {
			this.isTooltipDisabled = false;
		} else {
			this.isTooltipDisabled = true;
		}
	}
}
