/**
 * This is a Pure Data Object that is required by the Membership Provider requests.
 */
export class MembershipProviderRequest {
	/**
	 * An object the holds to request data for the requested API
	 */
	Data: any;
	/**
	 * A security token.  It's currently hard coded, but will eventually be dynamic.
	 */
	Token =
		'GqwV3qxAjJHs4rMDKIRMthE47NWPNuoX5JB7Rx0y2KiGWREScqv2sh0EBkSekPFCiFCKFWcypnlx+GJwoVNUFA==';
}
