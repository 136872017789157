import { Component, ElementRef, OnInit } from '@angular/core';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { PNGManager } from 'app/ui-components/png-manager';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Color } from 'models';
import { User } from '../user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends MenuPanelBaseComponent {

  lightGrayColor = Color.createFromRGBA('rgba(128,128,128,0.6)');
  darkGrayColor = Color.createFromRGBA('rgba(84, 84, 84, 0.6)');
  thumbnail: string;
  user: User;
  email: string;

  constructor(
    private interop: OmniInteropService,
    private userService: UserService,
    private flashMessageService: FlashMessageService,
    view: ElementRef<HTMLElement>
  ) {
    super(view);
   }

  ngOnInit(): void {
    this.menuPanelComponent.updateView({
      title: 'my profile'
    });
  }

  onPageNavigatedTo(args: NavigationArgs) {
    this.user = args.parameter.user;
    this.email = this.user.email;
    if (this.user.thumbnail) this.thumbnail = this.user.thumbnail;
		else {
			PNGManager.editImage('assets/me.png', {}).then(imageUrl => {
				const urlParts = imageUrl.split(',');
				this.thumbnail = urlParts[1];
			});
		}
  }

  changePassword() {
    NavigationService.navigateTo(Pages.changePassword);
  }

  async onSave() {
    if (!(/^\S+@\S+\.\S+$/.test(this.email))) {
      this.interop.uiManager.alertDialog.mainMessage = { text: 'please enter a valid email' };
			this.interop.uiManager.alertDialog.open = true;
      return;
    }
    this.user.email = this.email;
    const result = await this.userService.updateUser();
    if (!result.Success) {
      this.interop.uiManager.alertDialog.mainMessage = { text: result.Message};
		  this.interop.uiManager.alertDialog.open = true;
      return;
    } else {
      this.flashMessageService.popMessage('User updated successfully.');
      this.menuPanelComponent.goBack();
    }
  }

  isSaveEnabled() {
    if (this.email && this.user.email !== this.email) return true;
    else return false;
  }

}
