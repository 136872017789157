import { TaskContract, WorkTaskContract} from '../../contracts/work-order';
import { TaskWrapper } from './task-wrapper.model';
import { Task } from './task.model';
import { WorkTask } from './work-task.model';

export class TaskWrappers extends Array<TaskWrapper> {
	static fromContracts(contracts: WorkTaskContract[]): TaskWrappers {
		return TaskWrappers.fromModels(contracts.map(c => WorkTask.fromContract(c)));
	}

	static fromModels(models: WorkTask[]): TaskWrappers {
		const wrappersList = new TaskWrappers();
		for (const model of models) wrappersList.push(new TaskWrapper(model));
		return wrappersList;
	}

	getByWorkTaskId = (workTaskId: string): TaskWrapper => this.find(workTask => workTask.workTaskId === workTaskId);

	getContracts = (): WorkTaskContract[]  => this.map(t => t.getContract());

	getModels = (): WorkTask[] => this.map(t => t.task);

	isDirty = (): boolean => !!this.filter(t => t.isDirty).length;

	clearDirty = (): void => this.forEach(t => t.clearDirty());
}
