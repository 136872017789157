import { Metric } from '../metric.model';
import { TimeframedMetricSubscriber } from './timeframed-metric-subscriber';
import { MetricDeltasRequest, MetricResultsRequest } from '../../domain-service/metrics/request-result.model';
import { TimeframeFilter } from './timeframe-filter.model';
import { Timeframe } from './timeframe.model';
import { MetricScalar } from '../metric-scalar.model';

export class MetricTimeframes {
	get metric() {
		return this._timeframedMetrics[0]?.metric;
	}

	get timeframedMetrics() {
		if (!this._timeframedMetrics) this._timeframedMetrics = [];
		return this._timeframedMetrics;
	}

	constructor(private _timeframedMetrics?: TimeframedMetric[]) {
	}

	toDeltaRequest(): MetricDeltasRequest {
		const moment = require('moment');
		const timeframes = this.timeframedMetrics.map(tfm => {
			const startdate = tfm.timeframeFilter.timeFrame?.startDate ? moment(tfm.timeframeFilter.timeFrame?.startDate).format('YYYY-MM-DD[T]HH:mm:ss') : '';
			const enddate = tfm.timeframeFilter.timeFrame?.endDate ? moment(tfm.timeframeFilter.timeFrame?.endDate).format('YYYY-MM-DD[T]HH:mm:ss') : '';
			const timeframe = {
				rangetype: tfm.timeframeFilter.timeFrame?.definition.boundlessTypeString,
				timeframefield: tfm.timeframeFilter.timeframeField ?? '',
				startdate,
				enddate,
				scalaronly: !(tfm.results?.length > 0)
			};
			if (!timeframe.scalaronly) {
				const outfields = tfm.getOutfields();
				if (!outfields?.length) {
					console.error(`TimeframedMetric has not outfields, therefore it wont return results. ${tfm.metric}`);
					timeframe.scalaronly = true;
				} else timeframe['outfields'] = outfields;
			}
			return timeframe;
		});

		const request = { metricid: this.metric.id, timeframes };

		return request;
	}
}

export class TimeframedMetricError {
	private _error: any;
	set error(m: any) {
		console.log(m);
		this._error = m;
	}
	get error() {
		return this._error;
	}
	private _tryCount = 0;
	get tryCount() {
		return this._tryCount;
	}
	set tryCount(c: number) {
		this._tryCount = c;
	}

	/** in milliseconds */
	fetchDelay = 1000;

	maxNumberOfTries = 2;
}

/** A TimeFramedMetric is a runtime representation of the metric within the range of a given time frame */
export class TimeframedMetric {
	private _error: TimeframedMetricError;
	get error() {
		if (!this._error) this._error = new TimeframedMetricError();
		return this._error;
	}

	updatedRequested: boolean;

	get metric() {
		// readonly - set at construction time
		return this._metric;
	}

	get id() {
		return (
			this.metric.id +
			this.timeframeFilter.timeFrame.text
				.split(' ')
				.join('')
				.toLowerCase()
		);
	}
	private _timeFrameFilter: TimeframeFilter;
	get timeframeFilter() {
		return this._timeFrameFilter;
	}

	private _subscribers: Array<TimeframedMetricSubscriber>;
	get subscribers() {
		if (!this._subscribers) this._subscribers = [];
		return this._subscribers;
	}

	private _results = [];
	get results() {
		if (!this.neededFieldsHaveBeenRequested) {
			this.updatedRequested = true;
			this.onResultsRequested(this);
			this.lastOutfieldsRequested = this.getOutfields();
		}
		return this._results;
	}

	get hasResults() {
		return this._results && this.results.length;
	}

	private _scalar: MetricScalar;
	get scalar() {
		if (this._scalar === undefined) this.onScalarRequested(this);
		return this._scalar;
	}

	private _currentOutfieldsRequested: any[];
	get currentOutfieldsRequested(): any[] {
		if (!this._currentOutfieldsRequested) return [];
		return this._currentOutfieldsRequested;
	}
	set currentOutfieldsRequested(value: any[]) {
		if (this._currentOutfieldsRequested === value) return;
		this._currentOutfieldsRequested = value;
	}

	lastOutfieldsRequested: string[] = [];
	get neededFieldsHaveBeenRequested() {
		const outfields = this.getOutfields();
		if (this.lastOutfieldsRequested.length < outfields.length) return false;
		for (let i = 0; i < outfields.length; i++) {
			if (!this.lastOutfieldsRequested.includes(outfields[i])) return false;
		}
		return true;
	}

	constructor(private _metric: Metric, timeFrameFilter?: TimeframeFilter) {
		// each TimeframedMetric needs its own filter. It cannot just point to the one is being passed.
		// Otherwise the TimeframedMetric will be affected when the timeframe changes. We do not want
		// that. We want each timeframe to belong to a different TimeframedMetric.
		this._timeFrameFilter = new TimeframeFilter();
		this._timeFrameFilter.timeframeField = timeFrameFilter.timeframeField;
		this._timeFrameFilter.timeFrame = new Timeframe(timeFrameFilter.timeFrame.definition);
	}

	onResultsRequested: (arg: TimeframedMetric) => void;

	onScalarRequested: (arg: TimeframedMetric) => void;

	getOutfields() {
		const outfields = [];
		for (const subsciber of this.subscribers) {
			subsciber.outfields.forEach(o => {
				if (outfields.includes(o)) return;
				outfields.push(o);
			});
		}
		if (outfields.includes('*')) return ['*'];
		return outfields;
	}

	subscribe(subscriber: TimeframedMetricSubscriber) {
		this.subscribers.push(subscriber);
		subscriber.timeframedMetric = this;
	}

	unsubscribe(subscriber: TimeframedMetricSubscriber) {
		const i = this.subscribers.indexOf(subscriber);
		if (i < 0) return;
		this.subscribers.splice(i, 1);
	}

	update(newValues: { newScalar?: MetricScalar; newResults?: any[] }) {
		if (newValues.newScalar !== null && newValues.newScalar !== undefined) this._error = null;
		if (newValues.newScalar !== undefined) this._scalar = newValues.newScalar;
		if (newValues.newResults) this._results = newValues.newResults;
		this.updatedRequested = false;
		this.subscribers.forEach(s => s.onMetricUpdated());
	}

	toDeltaRequest() {
		const metricTimeframe = new MetricTimeframes([this]);
		return metricTimeframe.toDeltaRequest();
	}

	toGetResultsRequest(): MetricResultsRequest {
		const moment = require('moment');
		const startdate = this.timeframeFilter.timeFrame?.startDate ? moment(this.timeframeFilter.timeFrame.startDate).format('YYYY-MM-DD[T]HH:mm:ss') : '';
		const enddate = this.timeframeFilter.timeFrame?.endDate ? moment(this.timeframeFilter?.timeFrame.endDate).format('YYYY-MM-DD[T]HH:mm:ss') : '';
		const timeframefield = this.timeframeFilter.timeframeField ?? '';
		const outfields = this.getOutfields();
		this.currentOutfieldsRequested = outfields;
		const metricid = this.metric.id;
		const rangetype = this.timeframeFilter.timeFrameDefinition?.boundlessTypeString;
		return { startdate, enddate, timeframefield, outfields, metricid, rangetype };
	}

	onFetchScalarError(errorMessage: string, type?: string) {
		this.error.tryCount++;
		this.error.error = errorMessage;
		if (this.error.tryCount >= this.error.maxNumberOfTries) {
			this.update({ newScalar: null });
			return;
		}
		setTimeout(() => {
			this.onScalarRequested(this);
		}, this.error.fetchDelay);
	}
}
