<div class="map-layers">
	<app-three-component-navs [tabHeader1]="'legend'" [tabHeader2]="'layer visibility'" [tabHeader3]="'basemap'" [activeTab]="'legend'">
		
		<app-map-legend class="component1" [selectedCanvas]="canvas"></app-map-legend>
		
		<app-layer-visibility class="component2" [selectedCanvas]="canvas" (visiblityLayerChange)="visiblityLayerHandler()" (visiblitySubLayerChange)="visiblitySubLayerHandler()">
		</app-layer-visibility>
		
		<app-base-map class="component3" [selectedCanvas]="canvas" (updateBasemap)="baseMapChangeHandler($event)"></app-base-map>
	
	</app-three-component-navs>
</div>
