import { ManagerBase } from 'domain-service/manager-base';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { AppComponent } from 'app/app.component';
import { MainContentComponent } from 'app/main-content/main-content.component';
import { PhotoSwipeComponent } from 'app/ui-components/photo-swipe/photo-swipe.component';
import { MatSidenav } from '@angular/material/sidenav';
import { CalendarWidgetComponent } from 'app/ui-components/calendar-widget/calendar-widget.component';
import { ContextMenuComponent } from 'app/ui-components/context-menu/context-menu.component';
import { CanvasComponent } from 'app/canvas-container/canvas/canvas.component';
import { TabPanelComponent } from 'app/tab-panel/tab-panel.component';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';

export class UIManager extends ManagerBase {
	constructor(interopService: OmniInteropService) {
		super(interopService);
	}

	private _appComponent: AppComponent;
	public get appComponent(): AppComponent {
		return this._appComponent;
	}

	initialize(appComponent: AppComponent) {
		this._appComponent = appComponent;
	}

	public get openTab(): MainContentComponent {
		const mainComponent = this._appComponent.layoutComponent.activeTabContent;

		return mainComponent;
	}

	public get photoViewer(): PhotoSwipeComponent {
		return this._appComponent.layoutComponent.photoViewer;
	}

	public get tabPanel(): TabPanelComponent {
		return this._appComponent.layoutComponent.tabPanelComponent;
	}

	public get dateTimePicker(): CalendarWidgetComponent {
		return this._appComponent.layoutComponent.dateTimePicker;
	}

	public get contextMenu(): ContextMenuComponent {
		return this._appComponent.layoutComponent.contextMenu;
	}

	public get activeCanvasComponent(): CanvasComponent {
		if (!this.openTab) return null;
		return this.openTab.activeCanvas;
	}

	public get alertDialog(): AlertDialogComponent {
		return this._appComponent.layoutComponent.alertDialog;
	}
}
