import { StandardCustomFieldContract } from '../../contracts/work-order/standard-custom-field-contract';
import { StandardCustomField } from './standard-custom-field.model';

export class StandardCustomFields extends Array<StandardCustomField> {

	static fromContracts(customFieldContacts: StandardCustomFieldContract[]): StandardCustomFields {
		const customFields = new StandardCustomFields();

		if (!customFieldContacts) return customFields;
		for (const customFieldContract of customFieldContacts) {
			const customField = StandardCustomField.fromContract(customFieldContract);
			customFields.push(customField);
		}
		return customFields;
	}

	static fromModels(customFieldModels: StandardCustomField[]): StandardCustomFields {
		const customFields = new StandardCustomFields();
		for (const customField of customFieldModels) customFields.push(customField);
		return customFields;
	}

	getContracts = () => {
		const customFieldContracts = new Array<StandardCustomFieldContract>();
		for (const customField of this) {
			const customFieldContract = customField.getContract();
			customFieldContracts.push(customFieldContract);
		}
		return customFieldContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = () => this.forEach(field => (field.isDirty ? field.clearDirty() : null));

	undoAll = () => this.forEach(field => (field.isDirty ? field.undoAll() : null));

	getFieldsToUpdate = (): StandardCustomFieldContract[] => this.filter(field => field.isDirty).map(field => field.getContract());
}
