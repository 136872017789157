import { WorkComment } from './../models/work-order/work-comment.model';
import { WorkAssets } from './../models/work-order/work-assets.model';
import { Injectable } from '@angular/core';
import { WorkOrderHttpService } from './work-order-http.service';
import { throwError, Observable } from 'rxjs';
import { IHttpResponse } from './http-response.model';
import { catchError, map } from 'rxjs/operators';
import {
	EmployeeContract,
	EquipmentContract,
	TaskContract,
	MaterialContract,
	WorkOrderTemplateContract,
	InspectionContract,
	CustomFieldContract,
	ContractResponse,
	WorkTaskContract,
	ContractResponseBase,
	WorkAssetContract,
	WorkOrderMetaDataContract,
	WorkOrderRawContract,
	EmployeeCostContract,
	EquipmentCostContract,
	MaterialCostContract,
	WorkOrderFilterContract,
	AttachmentContract,
	WorkCommentContract,
	AssetContract,
	AssetSpecContract,
	WorkOrderCapabilitiesContract
} from 'contracts/work-order';
import { ZipReader } from 'sedaru-util/zip-reader';
import { AdvancedWorkOrder, EmployeeCost, EquipmentCost, MaterialCost, WorkTask, WorkAsset } from 'models/work-order';

/**
 * The work order service is used for operations upon Advanced WorkOrders
 */
@Injectable({
	providedIn: 'root'
})
export class WorkOrderService {
	/**
	 * This customer code is to be initialized on load
	 */
	customerCode = '';

	/**
	 * Allows the HttpService and dependencies to be injected into the class
	 * @param {WorkOrderHttpService} workOrderHttpService - HttpService for the work order.
	 */
	constructor(private workOrderHttpService: WorkOrderHttpService, private zipReader: ZipReader) {}

	/**
	 * get all initial data for work order
	 */
	getInitialData() {
		const fileName = 'DataSources.json';
		return this.workOrderHttpService.postService(`/api/WorkOrder/GetAllInitialData?customercode=${this.customerCode}`, {}, { responseType: 'arraybuffer' }).pipe(
			map(async zipContent => {
				const dataJSON = await this.zipReader.unzip(zipContent as any, fileName);
				const workOrderMetaDataContract = JSON.parse(dataJSON);
				const response = new ContractResponse<WorkOrderMetaDataContract>();
				response.Success = true;
				response.Result = workOrderMetaDataContract;
				return response;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getCapabilities(): Observable<WorkOrderCapabilitiesContract> {
		return this.workOrderHttpService.getService(`/api/WorkOrder/${this.customerCode}/GetCapabilities`).pipe(
			map((response: WorkOrderCapabilitiesContract) => {
				return response;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * get all employees
	 */
	getEmployees() {
		return this.workOrderHttpService.getService(`/api/Employee/${this.customerCode}/GetAllEmployees/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const employeeContracts: EmployeeContract[] = response.Result;
				const result = new ContractResponse<EmployeeContract[]>();
				result.Success = response.Success;
				result.Result = employeeContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * get all Equipments
	 */
	getEquipments() {
		return this.workOrderHttpService.getService(`/api/Equipment/${this.customerCode}/GetAllEquipments/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const equipmentContracts: EquipmentContract[] = response.Result;
				const result = new ContractResponse<EquipmentContract[]>();
				result.Success = response.Success;
				result.Result = equipmentContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * get all materials
	 */
	getMaterials() {
		return this.workOrderHttpService.getService(`/api/Material/${this.customerCode}/GetAllMaterials/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const materialContracts: MaterialContract[] = response.Result;
				const result = new ContractResponse<MaterialContract[]>();
				result.Success = response.Success;
				result.Result = materialContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * get all tasks
	 */
	getAllTasks() {
		return this.workOrderHttpService.getService(`/api/Tasks/${this.customerCode}/GetAllTasks/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const taskContracts: TaskContract[] = response.Result;
				const result = new ContractResponse<TaskContract[]>();
				result.Success = response.Success;
				result.Result = taskContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}
	/**
	 * get all custom field codes
	 */
	getAllCustomFieldCodes() {
		return this.workOrderHttpService.getService(`/api/WoTemplate/${this.customerCode}/GetAllCustomfieldCodes/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const customFieldContracts: CustomFieldContract[] = response.Result;
				const result = new ContractResponse<CustomFieldContract[]>();
				result.Success = response.Success;
				result.Result = customFieldContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}
	/**
	 *  get all inspections
	 */
	getAllInspections() {
		return this.workOrderHttpService.getService(`/api/Inspection/${this.customerCode}/GetAllInspections/`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const inspectionContracts: InspectionContract[] = response.Result;
				const result = new ContractResponse<InspectionContract[]>();
				result.Success = response.Success;
				result.Result = inspectionContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * get all workOrder templates
	 */
	getWorkOrderTemplates() {
		return this.workOrderHttpService.getService(`/api/WoTemplate/${this.customerCode}/getall`).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const workOrderTemplateContracts: WorkOrderTemplateContract[] = response.Result;
				const result = new ContractResponse<WorkOrderTemplateContract[]>();
				result.Success = response.Success;
				result.Result = workOrderTemplateContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getWorkOrdersByAsset(assetType: string, assetKey: string) {
		const postData = { assetkey: assetKey, assettype: assetType };
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetWorkOrdersByAsset`, postData).pipe(
			map((httpResponse: IHttpResponse) => {
				if (!httpResponse.Success) {
					throwError(httpResponse.ErrorMessage);
				}

				const workOrdersRaw: WorkOrderRawContract[] = httpResponse.Result.WorkOrders;
				const contractResponse = new ContractResponse<WorkOrderRawContract[]>();
				contractResponse.Success = httpResponse.Success;
				if (workOrdersRaw) {
					contractResponse.Result = workOrdersRaw;
				} else {
					contractResponse.Result = [];
				}

				return contractResponse;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getAttachments(referenceKey: string, referenceValue: string) {
		const postData = { ReferenceKey: referenceKey, ReferenceId: referenceValue };
		return this.workOrderHttpService.postService(`/api/Attachments/${this.customerCode}/GetAttachments`, postData).pipe(
			map((httpResponse: IHttpResponse) => {
				if (!httpResponse.Success) {
					throwError(httpResponse.ErrorMessage);
				}

				const attachmentContracts: AttachmentContract[] = httpResponse.Result;
				const contractResponse = new ContractResponse<AttachmentContract[]>();
				contractResponse.Success = httpResponse.Success;
				if (attachmentContracts) contractResponse.Result = attachmentContracts;
				else contractResponse.Result = [];

				return contractResponse;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * Get work order keys by AssetLocation.locationId
	 * @param {string} locationIds - comma delimited string of AssetLocation.locationId
	 */
	getWorkOrderKeysByLocation(locationIds: string, locationOnly = false): Observable<ContractResponse<object>> {
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetKeysByLocation`, { locationIds, locationOnly }).pipe(
			map((response: IHttpResponse) => {
				const result = new ContractResponse<object>();
				result.Success = response.Success;
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				result.Result = response.Result;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	generteWorkOrderKeys(keyCount: number) {
		return this.workOrderHttpService.woKeypostService(`/api/UniqueKey/GetUniqueWorkorderKey`, {
			CustomerCode: this.customerCode,
			KeyCount: keyCount
		  }).pipe(
			map((response: IHttpResponse) => {
				const result = new ContractResponse<object>();
				result.Success = response.Success;
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				result.Result = response.Result;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * Get work order stubs using work order keys
	 * @param {Array<string>} workOrderKeys - array of AdvancedWorkOrder.workOrderKey to fetch work order stub
	 */
	getWorkOrderStubsByWorkOrderKeys(workOrderKeys: string[]) {
		const postData = {
			workorderkey: workOrderKeys.join(',')
		};

		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetWorkOrders2`, postData).pipe(
			map((response: IHttpResponse) => {
				const workOrderStubsResponse = new ContractResponse<any[]>();
				workOrderStubsResponse.Success = response.Success;
				if (!response.Success) throwError(response.ErrorMessage);
				workOrderStubsResponse.Result = response.Result;
				return workOrderStubsResponse;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create work task
	 */
	postWorkTask(workTask: WorkTask) {
		const postData = {
			WorkTaskList: [workTask.getContract()]
		};
		console.log(postData.WorkTaskList);
		return this.workOrderHttpService.postService(`/api/Tasks/${this.customerCode}/PostWorkTasks/`, postData).pipe(
			map((response: IHttpResponse) => {
				console.log(response);
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				// for now we save single, but can adjust in the future for a list of employeecost
				workTask.id = response.Result[0];
				const result = new ContractResponse<WorkTaskContract[]>();
				result.Success = response.Success;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update work task
	 */
	updateWorkTask(workTask: WorkTask) {
		const postData = {
			WorkTaskList: [workTask.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Tasks/${this.customerCode}/UpdateWorkTasks/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * delete work task
	 */
	deleteWorkTask(workTask: WorkTask) {
		const postData = {
			WorkTaskList: [workTask.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Tasks/${this.customerCode}/DeleteWorkTasks/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create work asset
	 */
	postWorkAsset(workAssets: WorkAssets) {
		const postData = {
			WorkAssetList: workAssets.getContracts()
		};
		console.log(postData);
		return this.workOrderHttpService.postService(`/api/WorkAsset/${this.customerCode}/PostWorkAssets/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				const { Result } = response;
				const result = new ContractResponse<WorkAssetContract[]>();
				if (!Result) return result;
				console.log('work asset result', Result);
				Result.forEach((id, index) => (workAssets[index].objectId = id));
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update work asset
	 */
	updateWorkAsset(workAssets: WorkTaskContract[]) {
		const postData = {
			WorkAssetList: workAssets
		};
		return this.workOrderHttpService.postService(`/api/WorkAsset/${this.customerCode}/UpdateWorkAssets/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * delete work asset
	 */
	deleteWorkAsset(workAsset: WorkAsset) {
		const postData = {
			WorkAssetList: [workAsset.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/WorkAsset/${this.customerCode}/DeleteWorkAssets/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}
	/**
	 * get all workorders
	 */
	getWorkOrders(workOrderFilterContract: WorkOrderFilterContract) {
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetWorkOrders/`, workOrderFilterContract).pipe(
			map((httpResponse: IHttpResponse) => {
				if (!httpResponse.Success) {
					throwError(httpResponse.ErrorMessage);
				}

				const workOrdersRaw: WorkOrderRawContract[] = httpResponse.Result.WorkOrders;
				const contractResponse = new ContractResponse<WorkOrderRawContract[]>();
				contractResponse.Success = httpResponse.Success;
				contractResponse.Result = workOrdersRaw;

				return contractResponse;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * save the workorder
	 */
	postWorkOrder(workOrder: AdvancedWorkOrder) {
		const postData = {
			WorkOrder: workOrder.getContractWithoutNestedFields()
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/PostWorkOrder/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const responseResult = response.Result;
				workOrder.workOrderKey = responseResult['WorkorderKey'] ? responseResult['WorkorderKey'] : null;
				workOrder.isNew = false;
				const result = new ContractResponse<AdvancedWorkOrder>();
				result.Success = response.Success;
				result.Result = workOrder;
				workOrder.passWorkOrderKeyToChild();
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update work order
	 */
	updateWorkOrder(workOrder: AdvancedWorkOrder) {
		const postData = {
			WorkOrder: workOrder.getContractWithoutNestedFields()
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/UpdateWorkorder/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<AdvancedWorkOrder>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	deleteWorkOrders(workOrderKeys: string[]) {
		const postData = {
			workorderkeys: workOrderKeys
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/DeleteWorkorderByWorkOrderKeys`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				console.log(response);
				const result = new ContractResponse<AdvancedWorkOrder>();
				result.Success = response.Success;
				result.Message = response.Message;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * save custom field
	 */
	postCustomFields(customFields: CustomFieldContract[]) {
		const postData = {
			CustomFields: customFields
		};
		return this.workOrderHttpService.postService(`/api/WoTemplate/${this.customerCode}/SaveCustomFieldsAsync/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				console.log(response);
				const result = new ContractResponse<CustomFieldContract[]>();
				result.Success = response.Success;
				result.Message = response.Message;
				result.Result = customFields;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create employees cost
	 */
	postEmployeeCost(employeeCost: EmployeeCost) {
		const postData = {
			EmployeeCosts: [employeeCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Employee/${this.customerCode}/PostEmployeeCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				console.log(response);
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				// for now we save single, but can adjust in the future for a list of employeecost
				employeeCost.id = response.Result[0];
				const result = new ContractResponse<EmployeeCostContract[]>();
				result.Success = response.Success;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update employees cost
	 */
	updateEmployeeCost(employeeCost: EmployeeCost) {
		const postData = {
			EmployeeCosts: [employeeCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Employee/${this.customerCode}/UpdateEmployeeCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * delete employees cost
	 */
	deleteEmployeeCost(employeeCost: EmployeeCost) {
		const postData = {
			EmployeeCosts: [{ objectid: employeeCost.id }]
		};
		return this.workOrderHttpService.postService(`/api/Employee/${this.customerCode}/DeleteEmployeeCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create equipment cost
	 */
	postEquipmentCost(equipmentCost: EquipmentCost) {
		const postData = {
			EquipmentCosts: [equipmentCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Equipment/${this.customerCode}/PostEquipmentCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				// for now we save single, but can adjust in the future for a list of employeecost
				equipmentCost.id = response.Result[0];
				const result = new ContractResponse<EquipmentCostContract[]>();
				result.Success = response.Success;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update equipment cost
	 */
	updateEquipmentCost(equipmentCost: EquipmentCost) {
		const postData = {
			EquipmentCosts: [equipmentCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Equipment/${this.customerCode}/UpdateEquipmentCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * delete equipment cost
	 */
	deleteEquipmentCost(equipmentCost: EquipmentCost) {
		const postData = {
			EquipmentCosts: [{ objectId: equipmentCost.id }]
		};
		return this.workOrderHttpService.postService(`/api/Equipment/${this.customerCode}/DeleteEquipmentCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create material cost
	 */
	postMaterialCost(materialCost: MaterialCost) {
		const postData = {
			MaterialCosts: [materialCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Material/${this.customerCode}/PostMaterialCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				materialCost.id = response.Result[0];
				const result = new ContractResponse<MaterialCostContract[]>();
				result.Success = response.Success;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * update material cost
	 */
	updateMaterialCost(materialCost: MaterialCost) {
		const postData = {
			MaterialCosts: [materialCost.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/Material/${this.customerCode}/UpdateMaterialCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * delete material cost
	 */
	deleteMaterialCost(materialCost: MaterialCost) {
		const postData = {
			MaterialCosts: [{ objectId: materialCost.id }]
		};
		return this.workOrderHttpService.postService(`/api/Material/${this.customerCode}/DeleteMaterialCosts/`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	/**
	 * create material cost
	 */
	postAttachments(attachments: AttachmentContract[]) {
		const postData = attachments;
		return this.workOrderHttpService.postService(`/api/attachments/${this.customerCode}/PostAttachments`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<number[]>();
				result.Success = response.Success;
				result.Result = response.Result;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	postWorkComment(workComment: WorkComment) {
		workComment.createdDate = new Date().toLocaleString();
		const postData = {
			WorkComments: [workComment.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/PostWorkComments`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				const { Result } = response;
				console.log('work comment result', Result);
				workComment.id = Result ? Result[0] : '0';
				const result = new ContractResponse<WorkCommentContract[]>();
				result.Success = response.Success;
				result.Result = Result;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	updateWorkComment(workComment: WorkComment) {
		const postData = {
			WorkComments: [workComment.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/UpdateWorkComments`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				const { Result } = response;
				console.log(Result);
				const result = new ContractResponse<WorkCommentContract>();
				result.Success = response.Success;
				result.Result = Result;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	deleteWorkComment(workComment: WorkComment) {
		const postData = {
			WorkComments: [workComment.getContract()]
		};
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/DeleteWorkComments`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				console.log(response);
				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	deleteAttachment(attachments: AttachmentContract[]) {
		const postData = attachments;
		return this.workOrderHttpService.postService(`/api/attachments/${this.customerCode}/DeleteAttachments`, postData).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}
				console.log(response);
				const result = new ContractResponse<ContractResponseBase>();
				result.Success = response.Success;
				result.Message = response.Message;

				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getAsset(locationId?: string, activeAsset?: string): Observable<ContractResponse<AssetContract[]>> {
		return this.workOrderHttpService.postService(`/api/Asset/${this.customerCode}/GetAsset`, { 'LocationId': locationId, 'AssetId': activeAsset }).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<AssetContract[]>();
				result.Success = response.Success;
				const assetResults = response.Result.Assets as Array<any>;
				const assetContracts = new Array<AssetContract>();

				for (const assetResult of assetResults) {
					const asset = assetResult.Asset as AssetContract;
					asset.AssetSpecs = assetResult.AssetSpecs as AssetSpecContract[];
					assetContracts.push(asset);
				}

				result.Result = assetContracts;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getWorkOrderCountsByAssets(assets: [string, string][]): Observable<ContractResponse<Map<string, string>[]>> {
		const dataObj = {};
		const dataArr = [];
		for (const asset of assets) {
			const assetObj = {};
			assetObj['assettype'] = asset[0];
			assetObj['assetkey'] = asset[1];
			dataArr.push(assetObj);
		}

		dataObj['assets'] = dataArr;
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetWorkOrderCountsByAssets`, dataObj).pipe(
			map((response: IHttpResponse) => {
				if (!response.Success) {
					throwError(response.ErrorMessage);
				}

				const result = new ContractResponse<Map<string, string>[]>();
				result.Success = response.Success;
				if (!result.Success) {
					result.Message = response.ErrorMessage;
					return result;
				}

				const assetList = response.Result.assets as Array<any>;
				if (!assetList || !assetList.length) return result;

				const assetResults = new Array<Map<string, string>>();
				for (const asset of assetList) {
					const assetType = asset.assettype as string;
					const assetKey = asset.assetkey as string;
					const workOrderCount = asset.workordercount as number;
					if (!assetType || !assetKey) continue;

					const assetDict = new Map<string, string>();
					assetDict['assetType'] = assetType;
					assetDict['assetKey'] = assetKey;
					assetDict['workOrderCount'] = workOrderCount.toString();
					assetResults.push(assetDict);
				}

				result.Result = assetResults;
				return result;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}

	getArcGISFields(): any[] {
		return [
			{
				name: 'workorderkey',
				alias: 'Work Order Unique Identfier',
				columnName: 'workorderkey',
				type: 'string'
			}, {
				name: 'worktype',
				alias: 'Work Type',
				columnName: 'worktype',
				type: 'string'
			}, {
				name: 'status',
				alias: 'Status',
				columnName: 'status',
				type: 'string'
			}, {
				name: 'createddate',
				alias: 'Created Date',
				columnName: 'createddate',
				type: 'DateTime'
			}, {
				name: 'createdby',
				alias: 'Creator',
				columnName: 'createdby',
				type: 'string'
			}, {
				name: 'startdate',
				alias: 'Start Date',
				columnName: 'startdate',
				type: 'DateTime'
			}, {
				name: 'completeddate',
				alias: 'Completed Date',
				columnName: 'completeddate',
				type: 'DateTime'
			}, {
				name: 'completedby',
				alias: 'Completed By',
				columnName: 'completedby',
				type: 'DateTime'
			}, {
				name: 'assignedto',
				alias: 'Assigned To',
				columnName: 'assignedto',
				type: 'string'
			}, {
				name: 'location',
				alias: 'Location',
				columnName: 'location',
				type: 'string'
			}, {
				name: 'customername',
				alias: 'Customer Name',
				columnName: 'customername',
				type: 'string'
			}, {
				name: 'contactinfo',
				alias: 'Contact Information',
				columnName: 'contactinfo',
				type: 'string'
			}, {
				name: 'description',
				alias: 'Description',
				columnName: 'description',
				type: 'string'
			}, {
				name: 'comments',
				alias: 'Comments',
				columnName: 'comments',
				type: 'string'
			}, {
				name: 'budgetid',
				alias: 'Budget ID',
				columnName: 'budgetid',
				type: 'string'
			}, {
				name: 'systemid',
				alias: 'System ID',
				columnName: 'systemid',
				type: 'string'
			}, {
				name: 'accountnumber',
				alias: 'Account Number',
				columnName: 'accountnumber',
				type: 'string'
			}, {
				name: 'parentworkorderkey',
				alias: 'Parent Key',
				columnName: 'parentworkorderkey',
				type: 'string'
			}, {
				name: 'worktypeid',
				alias: 'Work Type ID',
				columnName: 'worktypeid',
				type: 'string'
			}, {
				name: 'xcoordinate',
				alias: 'X Coordinate',
				columnName: 'xcoordinate',
				type: 'double'
			}, {
				name: 'ycoordinate',
				alias: 'Y Coordinate',
				columnName: 'ycoordinate',
				type: 'double'
			}, {
				name: 'isrequest',
				alias: 'Is Service Request',
				columnName: 'isrequest',
				type: 'boolean'
			}, {
				name: 'supervisor',
				alias: 'Supervisor',
				columnName: 'supervisor',
				type: 'string'
			}, {
				name: 'teamid',
				alias: 'Team ID',
				columnName: 'teamid',
				type: 'string'
			}, {
				name: 'changedate',
				alias: 'Date Changed',
				columnName: 'changedate',
				type: 'DateTime'
			}, {
				name: 'changeby',
				alias: 'Change By',
				columnName: 'changeby',
				type: 'string'
			}, {
				name: 'iscorrective',
				alias: 'Is Corrective',
				columnName: 'iscorrective',
				type: 'boolean'
			}, {
				name: 'priority',
				alias: 'Priority',
				columnName: 'priority',
				type: 'string'
			}
		]
	}

	getAdvancedWorkOrderSearchData(postData) {
		return this.workOrderHttpService.postService(`/api/WorkOrder/${this.customerCode}/GetWorkOrders2`, postData).pipe(
			map((response: IHttpResponse) => {
				const workOrderStubsResponse = new ContractResponse<any>();
				workOrderStubsResponse.Success = response.Success;
				if (!response.Success) throwError(response.ErrorMessage);
				const result = {
					list: response.Result,
					totalNumberOfRecords: response['TotalNumberOfRecords']
				}
				workOrderStubsResponse.Result = result;
				return workOrderStubsResponse;
			}),
			catchError(err => this.workOrderHttpService.handlerError(err))
		);
	}
}
