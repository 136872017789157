import { LayoutComponent } from 'app/layout/layout.component';
import { INavigation, Pages } from './inavigation';
import { RouterComponent } from './router/router.component';
import { MainContentComponent } from 'app/main-content/main-content.component';
import { GuiConfig } from 'omni-model/gui-config.model';
import { AppComponent } from 'app/app.component';
import { RouterRegistry } from './router/router.registry';
import * as Sedaru from 'sedaru-util';
import { Mutex } from 'async-mutex';

export { Pages } from './inavigation';

class NavigationPanelGroup {
	tabId: string;
	get config(): GuiConfig {
		return this.mainContent.config;
	}
	get router(): RouterComponent {
		return this.mainContent.menuPanelComponent.navigationRouter;
	}
	get teamViewerRouter(): RouterComponent {
		return this.mainContent.teamViewerComponent.navigationRouter;
	}
	mainContent: MainContentComponent;
}

export class NavigationService {
	private static _semaphore: Mutex;
	private static get semaphore(): Mutex {
		if (!NavigationService._semaphore) NavigationService._semaphore = new Mutex();

		return NavigationService._semaphore;
	}

	private static _navigationService: NavigationService;
	static get current(): NavigationService {
		if (!this._navigationService) {
			this._navigationService = new NavigationService();
		}

		return this._navigationService;
	}

	private _resolver: INavigation;
	private _appComponent: AppComponent;
	private _appLayoutComponent: LayoutComponent;

	/** the query parameters on load */
	initialLoadParams = new Map();

	private _sidePanelCollection: Map<string, NavigationPanelGroup>;

	/** sends a navigation request to the router component */
	static navigateTo(page: Pages, parameter?: any, tabId?: string, routerName?: RouterRegistry): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			const release = await NavigationService.semaphore.acquire();
			const group = NavigationService.getCurrentRouter(tabId);
			if (!group) {
				resolve(false);
				release();
				return;
			}
			let router = group.router;
			if (routerName === RouterRegistry.temViewerRouter) router = group.teamViewerRouter;
			const navigationResult = await router.navigateTo(page, parameter);
			if (navigationResult) {
				NavigationService.current._appComponent.updateBackButtonStatus();
				group.mainContent.onMenuPanelNavigated(router.currentPage);
			}
			resolve(navigationResult);
			release();
			return;
		});
	}

	/** sends a navigation request to the router component */
	static navigateBackTo(page?: Pages, parameter?: any, tabId?: string, routerName?: RouterRegistry): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			const release = await NavigationService.semaphore.acquire();
			const group = NavigationService.getCurrentRouter(tabId);
			if (!group) {
				resolve(false);
				release();
				return;
			}

			let result = false;
			let router = group.router;
			if (routerName === RouterRegistry.temViewerRouter) router = group.teamViewerRouter;
			result = await router.navigateBack(page, parameter);
			if (result) {
				NavigationService.current._appComponent.updateBackButtonStatus();
				group.mainContent.onMenuPanelNavigated(router.currentPage);
			}
			resolve(result);
			release();
		});
	}

	static removePageFromBackStack(page: Pages): boolean {
		if (!NavigationService.current || !NavigationService.current.activeSidePanel) return false;
		return NavigationService.current.activeSidePanel.removePageFromBackStack(page);
	}

	private static getCurrentRouter(tabId?: string): NavigationPanelGroup {
		const layoutComponent: LayoutComponent = NavigationService.current._appLayoutComponent;
		if (!layoutComponent) return;
		const selectedMainComponent: MainContentComponent = layoutComponent.activeTabContent;

		if (!selectedMainComponent) {
			return;
		}

		let group = NavigationService.current.sidePanelCollection.get(selectedMainComponent.id);
		if (!group) {
			group = new NavigationPanelGroup();
			group.tabId = selectedMainComponent.id;
			group.mainContent = selectedMainComponent;
			group.router.resolver = NavigationService.current._resolver;
			group.teamViewerRouter.resolver = NavigationService.current._resolver;
			NavigationService.current.sidePanelCollection.set(group.tabId, group);
		}

		return group;
	}

	static destroyNavigationGroup(currentTabComponent: MainContentComponent) {
		NavigationService.current.sidePanelCollection.delete(currentTabComponent.id);
	}

	/** parses the url into the initial parameters */
	static parseUrl() {
		const locationQuery = window.location.href.split('?');
		if (locationQuery.length === 2) {
			const queryParams = locationQuery[1].split('&');
			queryParams.forEach(param => {
				const splittedParam = param.split('=');
				NavigationService.current.initialLoadParams.set(splittedParam[0], splittedParam[1]);
			});

			if (NavigationService.current.initialLoadParams.has('tab')) {
				const tabId = NavigationService.current.initialLoadParams.get('tab');
				const requestedConfig = NavigationService.current._appLayoutComponent.availableConfigs[tabId];
				if (requestedConfig) NavigationService.current._appLayoutComponent.tabSelected.emit(requestedConfig);
			}
			if (NavigationService.current.initialLoadParams.has('page')) {
				setTimeout(() => {
					// allow some time for the active tab to be served down to the components
					NavigationService.navigateTo(NavigationService.current.initialLoadParams.get('page'));
				}, 400);
			}
		}
		// if we need to remove the url after load
		// location.replaceState('');
	}

	private get sidePanelCollection(): Map<string, NavigationPanelGroup> {
		if (!this._sidePanelCollection) this._sidePanelCollection = new Map<string, NavigationPanelGroup>();

		return this._sidePanelCollection;
	}

	public get activeSidePanel(): RouterComponent {
		const activeTab = this.activeTab;
		if (!activeTab) return undefined;

		return activeTab.router;
	}

	public get activeTab(): NavigationPanelGroup {
		return NavigationService.getCurrentRouter();
	}

	private constructor() {}

	/** passes the layout component in memory */
	initializeAppLayout(appComponent: AppComponent, resolver: INavigation) {
		this._appComponent = appComponent;
		this._appLayoutComponent = appComponent.layoutComponent;
		this._resolver = resolver;
		this.registerGuiConfig();
	}

	private registerGuiConfig() {
		if (!this._appComponent.guiConfigService) return;

		this._appComponent.guiConfigService.onTabActivated.subscribe((service, activatedConfig: GuiConfig) => {
			const group = NavigationService.getCurrentRouter(activatedConfig.id);

			if (!group) {
				return;
			}

			group.mainContent.onActivated();
			this._appComponent.updateBackButtonStatus();
		});
		this._appComponent.guiConfigService.onTabDeactivated.subscribe((service, deactivatedConfig) => {
			const group = NavigationService.getCurrentRouter(deactivatedConfig.id);

			if (!group) {
				return;
			}

			group.mainContent.onDeactivated();
		});
	}
}
