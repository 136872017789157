import { Injectable } from '@angular/core';
import { WorkorderStep } from './workorder-step.model';
import { UserService } from 'app/user/user.service';
import { DataChannelService } from 'domain-service/data-channel.service';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Injectable({ providedIn: 'root' })
export class WorkorderOutlineService {
	constructor(private userService: UserService, private dataChannelService: DataChannelService, private workOrderFactory: WorkOrderFactory) {}

	getWorkorderSteps(currentWorkorder: any, advancedMode: boolean, isReadOnly: boolean) {
		const workorderSteps: Array<WorkorderStep> = [];
		let stepNames = [];
		let worktype;
		if (!advancedMode) {
			worktype = currentWorkorder.workType;
			stepNames = worktype ? ['summary', worktype, 'time', 'materials', 'equipment'] : ['summary', 'time', 'materials', 'equipment'];
		} else {
			stepNames = ['summary', 'tasklist', 'time', 'materials', 'equipment', 'vendor', 'attachments', 'failure report'];
		}

		stepNames.forEach(item => {
			let name = '',
				status = 'open',
				state = 'open',
				isHistoryRecord = false,
				isRecurringWorkOrder = false,
				disabled = false;
			switch (item) {
				case 'summary':
					isRecurringWorkOrder = currentWorkorder.isRecurring;

					if (!advancedMode) {
						if (!currentWorkorder.status) break;
						status = state = currentWorkorder.status.toLowerCase();
						break;
					}

					const s =
						this.workOrderFactory.workOrderMetaData && this.workOrderFactory.workOrderMetaData.statuses
							? this.workOrderFactory.workOrderMetaData.statuses.find(wo => wo.statusCode?.toLowerCase() === currentWorkorder.status?.toLowerCase())
							: '';
					status = s ? s.description : '';
					const completeCodes = this.dataChannelService.getCompletedWorkOrderValues();
					state = completeCodes.includes(currentWorkorder.status) ? 'complete' : 'open';
					break;
				case 'tasklist':
					const totalTasks = currentWorkorder.workTasks.length;
					const completedTasks = currentWorkorder.workTasks
															.reduce((acc, cur) => {
																const currentStatus = cur.status?.toLowerCase();
																if (currentStatus) {
																	if (currentStatus.includes('comp') || currentStatus.includes('closed')) {
																		return ++acc;
																	}
																}
																return acc;
															}
															 , 0);
					disabled = isReadOnly && totalTasks === 0;
					status = totalTasks === 0 ? 'no tasks' : completedTasks + ' of ' + totalTasks + ' completed';
					if (totalTasks !== 0 && totalTasks === completedTasks) state = 'complete';
					break;
				case 'time':
					if (!currentWorkorder.employeeCosts) break;
					if (currentWorkorder.employeeCosts.length > 0) state = 'complete';
					status = currentWorkorder.employeeCosts.length + ' entries added';
					disabled = isReadOnly && !currentWorkorder.employeeCosts.length;
					break;
				case 'materials':
					if (!currentWorkorder.materialCosts) break;
					if (currentWorkorder.materialCosts.length > 0) state = 'complete';
					status = currentWorkorder.materialCosts.length + ' entries added';
					disabled = isReadOnly && !currentWorkorder.materialCosts.length;
					break;
				case 'equipment':
					if (!currentWorkorder.equipmentCosts) break;
					if (currentWorkorder.equipmentCosts.length > 0) state = 'complete';
					status = currentWorkorder.equipmentCosts.length + ' entries added';
					disabled = isReadOnly && !currentWorkorder.equipmentCosts.length
					break;
				case 'attachments':
					if (!currentWorkorder.attachments) break;
					if (currentWorkorder.attachments.length > 0) state = 'complete';
					status = currentWorkorder.attachments.length + ' entries added';
					disabled = isReadOnly && !currentWorkorder.attachments.length;
					break;
				case 'vendor':
					if (!currentWorkorder.vendorCosts) break;
					if (currentWorkorder.vendorCosts.length > 0) state = 'complete';
					status = currentWorkorder.vendorCosts.length + ' entries added';
					disabled = isReadOnly && !currentWorkorder.vendorCosts.length;
					break;
				case 'failure report':
					if (!currentWorkorder.failureReports) break;
					if (currentWorkorder.failureReports.length > 0) state = 'complete';
					status = `${currentWorkorder.failureReports.length > 0 ? 1 : 0} entries added`;
					disabled = isReadOnly && !currentWorkorder.failureReports.length;
					break;
				case worktype:
					const historyRecord = currentWorkorder.historyRecord;
					name = historyRecord ? historyRecord.workType : '';
					status = historyRecord ? `completed on ${DateUtil.convertLocaleUTCStringFormatted(historyRecord.workDate)}` : 'unavailable';
					state = historyRecord ? 'complete' : 'open';
					disabled = !historyRecord;
					isHistoryRecord = true;
					break;
			}
			const workorderStep = {
				name: name ? name : item,
				status: 'status: ' + status,
				state: state,
				isHistoryRecord: isHistoryRecord,
				isRecurringWorkOrder: isRecurringWorkOrder,
				disabled: disabled
			};
			workorderSteps.push(workorderStep);
		});
		return workorderSteps;
	}

	getStepLeftIcon(step: WorkorderStep) {
		let path;
		const state = step.state;
		switch (step.name) {
			case 'summary':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/workorder-completed.png';
				else path = 'assets/workorder.png';
				break;
			case 'time':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/employee_time_comp.png';
				else path = 'assets/employee_time.png';
				break;
			case 'materials':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/materials_used_comp.png';
				else path = 'assets/materials_used.png';
				break;
			case 'equipment':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/equipment_used_comp.png';
				else path = 'assets/equipment_used.png';
				break;
			case 'attachments':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/attachments_comp.png';
				else path = 'assets/attachments.png';
				break;
			case 'vendor':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/vendors_done.png';
				else path = 'assets/vendors.png';
				break;
			case 'failure report':
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/WO-failure_done.png';
				else path = 'assets/WO-failure.png';
				break;
			case 'tasklist':
			default:
				if (state.toLowerCase().includes('comp') || state.toLowerCase().includes('clos')) path = 'assets/task-list-green.png';
				else path = 'assets/task-list-orange.png';
				break;
		}
		return path;
	}

	getStepRightIcon(step: WorkorderStep): string {
		if (!step || !step.isRecurringWorkOrder) return null;
		return 'assets/recurrence.png';
	}
}
