import { Proxy } from './proxy.model';
import { Credentials } from './credentials.model';
import { DataSourceType } from './data-source-type.enum';
import { DataSourceCategory } from './data-source-category.enum';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';
import { FlatProxy } from './proxy.model';

export interface FlatDataSource {
	id: string;
	legacyId: number;
	name: string;
	url: string;
	msUrl: string;
	credentialsId: string;
	credentialsLegacyId: number;
	proxyId: string;
	proxyLegacyId: number;
	type: number;
	category: number;
}

/**
 * This defines the source layers properties
 */
export class DataSource extends SedaruConfigBase {
	/**
	 * uuid for data source
	 */
	id: string;

	/**
	 * holds the frist generation id which will getting from studio.
	 */
	legacyId: number;

	/**
	 * holds the name of the source layer
	 */
	name: string;

	/**
	 * holds the url of the source
	 */
	url: string;

	/**
	 * holds the map server url of the source as an alternative layer
	 */
	msUrl: string;

	/**
	 * holds the credentials id for the vertex
	 */
	credentialsId?: string;

	/**
	 * holds the credentials legacy Id
	 */
	credentialsLegacyId?: number;

	/**
	 * holds the credentials details for the source layers
	 */
	public credentials: Credentials;
	public get dataSourceCredentials(): Credentials {
		if (this.credentials) {
			return this.credentials;
		}

		if (!this.credentialsLegacyId || this.credentialsLegacyId === 0) return undefined;

		const credentialList = this.globalConfig.credentials.filter(c => {
			if (!c.legacyId) return false;

			return c.legacyId === this.credentialsLegacyId;
		});

		if (!credentialList || credentialList.length === 0) return undefined;

		return credentialList[0];
	}
	public set dataSourceCredentials(value: Credentials) {
		this.credentials = value;
	}

	/**
	 * holds the vertex id of the proxy
	 */
	proxyId?: string;

	/**
	 * holds the proxy legacy Id
	 */
	proxyLegacyId?: number;

	/**
	 * The proxy of the data source
	 */
	proxy?: Proxy;
	/**
	 * Diffenent types of map source
	 */
	type: DataSourceType;

	/**
	 * Diffenent types of source category
	 */
	category: DataSourceCategory;

	static fromFlatDataSource(flatDataSource: FlatDataSource, flatProxy: FlatProxy) {
		const dataSource = new DataSource();
		dataSource.id = flatDataSource.id;
		dataSource.legacyId = flatDataSource.legacyId;
		dataSource.name = flatDataSource.name;
		dataSource.url = flatDataSource.url;
		dataSource.msUrl = flatDataSource.msUrl;
		dataSource.credentialsId = flatDataSource.credentialsId;
		dataSource.credentialsLegacyId = flatDataSource.credentialsLegacyId;
		dataSource.proxyId = flatDataSource.proxyId;
		dataSource.proxyLegacyId = flatDataSource.proxyLegacyId;
		dataSource.type = flatDataSource.type;
		dataSource.category = flatDataSource.category;
		if (flatProxy) dataSource.proxy = Proxy.fromFlatProxy(flatProxy);
		return dataSource;
	}

	/**
	 * This constructor loads and injects dependencies and initializes a default empty value.
	 */
	constructor(globalConfig?: GlobalConfig) {
		super(globalConfig);
	}

	initialize?(dataSourceInput: DataSource): void {
		this.id = dataSourceInput.id;
		this.name = dataSourceInput.name;
		this.url = dataSourceInput.url;
		this.type = dataSourceInput.type;
		this.category = dataSourceInput.category;
		if (dataSourceInput.credentialsId) {
			this.credentialsId = dataSourceInput.credentialsId;
			this.dataSourceCredentials = dataSourceInput.credentials;
		}
		if (dataSourceInput.proxyId) {
			this.proxyId = dataSourceInput.proxyId;
			this.proxy = dataSourceInput.proxy;
		}
	}

	getFlatDataSource(): FlatDataSource {
		return {
			id: this.id,
			legacyId: this.legacyId,
			name: this.name,
			url: this.url,
			msUrl: this.msUrl,
			credentialsId: this.credentialsId,
			credentialsLegacyId: this.credentialsLegacyId,
			proxyId: this.proxyId,
			proxyLegacyId: this.proxyLegacyId,
			type: this.type,
			category: this.category,
		};
	}
}

export class DataSources extends Array<DataSource> {
	getById(id: number): DataSource {
		for (const dataSource of this) {
			if (dataSource.legacyId !== id) {
				continue;
			}

			return dataSource;
		}
	}
}
