<div
	[ngClass]="{ border: resolver?.showDivider(item), hovered: hovered }"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
	class="search-result-item"
	(click)="itemSelected.emit(item)"
>
	<label class="header">{{ header }}</label>
	<div class="result-body">
		<div class="value" [matTooltip]="value" [matTooltipPosition]="'above'" [matTooltipDisabled]="(searchValue.offsetWidth >= searchValue.scrollWidth)">
			<span [innerHTML]="firstChars"></span>
			<span><b [innerHTML]="boldChars"></b></span>
			<span #searchValue class="last-span" [innerHTML]="lastChars"></span>
		</div>
		<div class="close-icon" [class.hidden]="hovered || !resolver?.showIconOnHover(item) ? false : true">
			<img
				*ngIf="resolver?.getRightIconPath"
				[src]="resolver?.getRightIconPath(item)"
				(click)="onRightIconClicked($event)"
			/>
		</div>
	</div>
</div>
