import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NoIconOneLineTemplateResolver } from './np-icon-one-line.resolver';
import { ListTemplates } from '../templates.enum';
import { ListItemComponentBase } from '../list-item.model';

@Component({
	selector: 'app-no-icon-one-line',
	templateUrl: './no-icon-one-line.component.html',
	styleUrls: ['./no-icon-one-line.component.scss']
})
export class NoIconOneLineComponent extends ListItemComponentBase {
	@Input() resolver: NoIconOneLineTemplateResolver;

	@Input() item: any;

	@Input() selected = false;

	@Output() itemSelected = new EventEmitter();

	hovered = false;

	itemComponent = this;

	static createResolver(): NoIconOneLineTemplateResolver {
		const resolver = new NoIconOneLineTemplateResolver();
		resolver.templateType = ListTemplates.noIconOneLine;
		return resolver;
	}
}
