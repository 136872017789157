import { Equipment } from './equipment.model';
import { EquipmentContract } from '../../contracts/work-order';

export class Equipments extends Array<Equipment> {
	static fromContracts(equipmentContracts: EquipmentContract[]) {
		const equipments = new Equipments();
		if (!equipmentContracts) return equipments;

		for (const equipmentContract of equipmentContracts) {
			const equipment = Equipment.fromContract(equipmentContract);
			equipments.push(equipment);
		}
		return equipments;
	}

	getById = (id: string) => this.find(equipment => equipment.id === id);

	getByEquipmentId = (equipmentId: string) => this.find(equipment => parseInt(equipment.equipmentId) === parseInt(equipmentId));
}
