import { Metric, MetricTile } from '../models';
import { TimeframedMetricSubscriber } from '../models/time-frame/timeframed-metric-subscriber';

export class CanvasTab {
	hovered?: boolean;
	active: boolean;
	activeClicked?: boolean;
	tile?: MetricTile;
	isTimeframed?: boolean;
	defaultMetric?: Metric;
	trendDefinition?: any;
	locked: boolean;
	metricSubscription?: TimeframedMetricSubscriber;

	get metric() {
		return this.metricSubscription?.timeframedMetric?.metric;
	}

	hasMetric(metric: Metric): boolean {
		if (this.defaultMetric && this.defaultMetric.id === metric.id) return true;
		if (this.tile && this.tile.metric.id === metric.id) return true;
		return false;
	}
}

export interface CanvasTabOutput {
	active: boolean;
	tileId?: string;
	defaultMetricId?: string;
	locked: boolean;
}
