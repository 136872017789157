import { Component, ElementRef } from '@angular/core';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { Metric } from 'models/metric.model';
import { Canvas } from 'omni-model/canvas.model';
import { MetricRequesterService } from 'app/metric/metric-requester.service';
import { MenuPanelBaseComponent } from '../menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
/**
 * This component will be loaded as a right side panel in the following two cases,
 * 1. When we add a new trend into an empty canvas.
 * 2. When we click on the settings icon on an existing trend.
 * It provides options to set the default metric and the trend style.
 */
@Component({
	selector: 'app-table-settings',
	templateUrl: './table-settings.component.html',
	styleUrls: ['./table-settings.component.scss']
})
export class TableSettingsComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the default metric of the canvas on which the trend is loaded.
	 */
	defaultMetric: Metric;

	/**
	 * Holds the canvas on which the trend will be loaded.
	 */
	canvas: Canvas;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {FlashMessageService} flashMessageService Used for displaying error messages in the UI from flash message service.
	 */
	constructor(private flashMessageService: FlashMessageService, private metricRequesterService: MetricRequesterService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * On init, the currently selected canvas on which the trend will be loaded.
	 * It calls the method to initialize the trend settings.
	 * The current route is also emitted.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'table settings' });
		this.canvas = this.config.selectedCanvas;
		if (!this.canvas.tabs) this.canvas.tabs = [];
		const defaultMetricTab = this.canvas.tabs.find(tab => !this.isNullOrUndefined(tab.defaultMetric));
		this.defaultMetric = defaultMetricTab ? defaultMetricTab.defaultMetric : null;
	}

	onPageReload(args: NavigationArgs) {
        this.ngOnInit();
    }

	private isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	}

	/**
	 * Called when the icon on the default metric field is clicked.
	 * It will navigate the user to the list of available metrics.
	 */
	onGotoDefaultMetric() {
		this.metricRequesterService.setRequester('Canvas', this.canvas);
		NavigationService.navigateTo(Pages.metrics, {currentlySelectedMetric: this.defaultMetric, showRemoveOption: !!this.defaultMetric, disableRemoveOption: !!this.defaultMetric});
	}

	goTo(address: string) {
		switch (address) {
			case 'defaultMetric':
				this.onGotoDefaultMetric();
				break;
		}
	}

}
