import { WorkOrderChannelAttributes } from './work-order-channel-attributes.model';
import { DataSource } from './data-source.model';
import { ChannelTypes } from './channel-type.enum.model';
import { ChannelAttributes } from './channel-attributes.model';
import { ArcGISAssetChannelAttributes } from './/arc-gis-asset-channel-attributes.model';
import { ArcGISHistoryChannelAttributes } from './arc-gis-history-channel-attributes.model';
import { ArcGISWorkOrderChannelAttributes } from './arc-gis-work-order-channel-attributes.model';
import { GlobalConfig } from './global-config.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { WorkOrderModeType } from './work-order-mode-type.enum';
import { SedaruWorkOrderChannelAttributes } from './sedaru-work-order-channel-attributes.model';

export interface FlatChannel {
	id: string;
	name: string;
	dataSourceId: string;
	dataSourceLegacyId: number;
	channelType: number;
	attributes: any;
}

/**
 * This defines the data channel
 */
export class Channel extends SedaruConfigBase {

	/**
	 * uuid for channel
	 */
	id: string;

	/**
	 * name of channel
	 */
	name: string;

	/**
	 * holds the source id
	 */
	dataSourceId: string;

	/**
	 * The data source's legacy Id
	 */
	dataSourceLegacyId?: number;

	/**
	 * holds the data source
	 */
	dataSource?: DataSource;

	get dataSourceNew(): DataSource {
		if (this.dataSourceLegacyId <= 0) return null;

		return this.globalConfig.dataSources.getById(this.dataSourceLegacyId);
	}

	get isAdvancedWorkOrder(): boolean {
		const attributes = this.attributes as WorkOrderChannelAttributes;
		return attributes.workOrderMode === WorkOrderModeType.Advanced;
	}

	/**
	 * holds the channel types like Asset/History/WorkOrder
	 */
	channelType: ChannelTypes;

	/**
	 * Channel attributes
	 */
	attributes?: ChannelAttributes;

	static fromFlatChannel(flatChannel: FlatChannel) {
		const channel = new Channel();
		channel.id = flatChannel.id;
		channel.name = flatChannel.name;
		channel.dataSourceId = flatChannel.dataSourceId;
		channel.dataSourceLegacyId = flatChannel.dataSourceLegacyId;
		channel.channelType = flatChannel.channelType;
		channel.attributes = flatChannel.attributes;
		return channel;
	}

	/**
	 * Method to create Channel object from a Map
	 * @param channelMaps Channel Map object
	 * @returns Channel object
	 */
	static fromMap(channelMaps: Map<string, any>): Channel {
		const channel = new Channel();
		channel.id = channelMaps.get('id') || '';
		channel.name = channelMaps.get('name') || '';
		channel.dataSourceId = channelMaps.get('dataSourceId') || '';
		channel.dataSourceLegacyId = channelMaps.get('dataSourceLegacyId') || '';
		channel.channelType = channelMaps.get('channelType') || '';
		channel.attributes = channelMaps.get('attributes') || '';
		return channel;
	}

	/**
	 * This constructor loads and injects dependencies and initializes a default empty value.
	 */
	constructor(globalConfig?: GlobalConfig) {
		super(globalConfig);
	}

	initialize?(channel: Channel): void {
		this.id = channel.id;
		this.name = channel.name;
		this.dataSourceId = channel.dataSourceId;
		if (channel.dataSource) {
			this.dataSource = new DataSource(this.globalConfig);
			this.dataSource.initialize(channel.dataSource);
		}
		this.channelType = channel.channelType;

		if (channel.channelType === ChannelTypes.Asset) this.attributes = new ArcGISAssetChannelAttributes();
		if (channel.channelType === ChannelTypes.History) this.attributes = new ArcGISHistoryChannelAttributes();
		if (channel.channelType === ChannelTypes.WorkOrder) {
			const attributes = channel.attributes as WorkOrderChannelAttributes;
			if (attributes.workOrderMode === WorkOrderModeType.Advanced) this.attributes = new SedaruWorkOrderChannelAttributes();
			else this.attributes = new ArcGISWorkOrderChannelAttributes();
		}

		(this.attributes as any).initialize(channel.attributes);
	}

	getFlatChannel(): FlatChannel {
		return {
			id: this.id,
			name: this.name,
			dataSourceId: this.dataSourceId,
			dataSourceLegacyId: this.dataSourceLegacyId,
			channelType: this.channelType,
			attributes: this.attributes,
		};
	}
}

export class Channels extends Array<Channel> {
	getById = (channelId: string): Channel => {
		for (const channel of this) {
			if (channel.id !== channelId) {
				continue;
			}

			return channel;
		}

		return null;
	};

	getHistoryChannelByAssetType = (assetType: string): Channel => {
		for (const channel of this) {
			if (channel.channelType !== ChannelTypes.History) {
				continue;
			}

			const historyAttributes = channel.attributes as ArcGISHistoryChannelAttributes;

			if (!historyAttributes) {
				continue;
			}

			if (historyAttributes.assetType.toLowerCase() !== assetType.toLowerCase()) {
				continue;
			}

			return channel;
		}

		return null;
	};

	getAssetChannelByAssetType = (assetType: string): Channel => {
		for (const channel of this) {
			if (channel.channelType !== ChannelTypes.Asset) {
				continue;
			}

			const assetAttributes = channel.attributes as ArcGISAssetChannelAttributes;

			if (!assetAttributes) {
				continue;
			}

			if (assetAttributes.assetType.toLowerCase() !== assetType.toLowerCase()) {
				continue;
			}

			return channel;
		}

		return null;
	};
}
