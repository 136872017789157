import { TrendStyle, TrendStyleIdentifier } from './trend-style.model';
import { ArcGISField } from './arc-gis-field.model';

export class DefaultTrendSettings {
	/**
	 * The trend's style to show
	 */
	defaultTrendStyle: TrendStyle;

	/**
	 * Field used to trend for x axis
	 */
	xAxisField: ArcGISField;

	/**
	 * Field used to trend for y axis
	 */
	yAxisField: ArcGISField;

	constructor() {
		this.defaultTrendStyle = new TrendStyle();
		this.xAxisField = new ArcGISField();
		this.yAxisField = new ArcGISField();
	}

	initialize?(trendSettings: DefaultTrendSettings) {
		if (!trendSettings) return;
		this.defaultTrendStyle = trendSettings.defaultTrendStyle;
		this.xAxisField.initialize(trendSettings.xAxisField);
		this.yAxisField.initialize(trendSettings.yAxisField);
	}

	clear?(): void {
		this.defaultTrendStyle.identifier = TrendStyleIdentifier.none;
		this.defaultTrendStyle.label = '';
		this.xAxisField = new ArcGISField();
		this.yAxisField = new ArcGISField();
	}
}
