import { Pages } from './inavigation';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';

export class NavigationArgs {
	cancel = false;
	private _parameter: any;
	public get parameter(): any {
		return this._parameter;
	}

	private _isNavigatingBack = false;
	public get isNavigatingBack(): boolean {
		return this._isNavigatingBack;
	}

	private _previousPage: MenuPanelBaseComponent;
	public get previousPage(): MenuPanelBaseComponent {
		return this._previousPage;
	}

	get fromPage(): Pages {
		if (!this.previousPage) return Pages.none;

		return this.previousPage.pageIdentifier;
	}

	private _requestedTargetPage: Pages;
	public get requestedTargetPage(): Pages {
		return this._requestedTargetPage;
	}

	private _uiState: any;
	public get uiState(): any {
		return this._uiState;
	}

	private _searchTerm: any;
	public get searchTerm(): any {
		return this._searchTerm;
	}
	constructor(isNavigatingBack: boolean, previousPage: MenuPanelBaseComponent, requestedPage: Pages, parameter?: any, searchTerm?: any) {
		this._isNavigatingBack = isNavigatingBack;
		this._previousPage = previousPage;
		this._requestedTargetPage = requestedPage;
		this._parameter = parameter;
		this._searchTerm = searchTerm;
	}
}
