<div class="scrolling-content">
	<mat-grid-list cols="4" rowHeight="1:1" *ngIf="icons.length > 0">
		<mat-grid-tile *ngFor="let icon of icons" [ngClass]="{'selected-icon': icon.name === selectedIcon.name}" >
			<button
				(click)="onIconSelected(icon)"
				mat-icon-button>
				<app-asset-icon id="{{icon.name}}" [icon]="icon"></app-asset-icon>
			</button>
		</mat-grid-tile>
	</mat-grid-list>

	<div class="short-item-wrapper">
		<mat-list-item class="dark-gray-background omni-list-item short">
			<div class="relative-position">
				<span class="control-title">icon color</span>
				<input placeholder="not set" [value]="foregroundRGBCode" readonly/>
				<button mat-icon-button class="omni-icon-button">
					<mcc-color-picker
						hideUsedColors="true"
						usedSizeColors="0"
						class="mcc-cp-small"
						[selectedColor]="foregroundColor"
						(change)="onForegroundColorChange($event)">
						<mcc-color-picker-collection
							class="color-picker-collection-bg"
							[colors]="sedaruColors" size="8" label="sedaru colors">
						</mcc-color-picker-collection>
					</mcc-color-picker>
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="dark-gray-background omni-list-item short">
			<div class="relative-position">
				<span class="control-title">background color</span>
				<input placeholder="not set" [value]="backgroundRGBCode" readonly/>
				<button mat-icon-button class="omni-icon-button">
					<mcc-color-picker
						hideUsedColors="true"
						usedSizeColors="0"
						class="mcc-cp-small"
						[selectedColor]="backgroundColor"
						(change)="onBackgroundColorChange($event)">
						<mcc-color-picker-collection
							class="color-picker-collection-bg"
							[colors]="sedaruColors" size="8" label="sedaru colors">
						</mcc-color-picker-collection>
					</mcc-color-picker>
				</button>
			</div>
		</mat-list-item>
	</div>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font">
			add custom
			<img class="omni-sm-img" src="assets/add_custom.png" />
		</button>
	</mat-card-actions>
</mat-card-footer>
