import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChange } from '@angular/core';
import { Canvas, CanvasMode } from 'omni-model/canvas.model';
import { CanvasTabsComponent } from '../canvas-tabs/canvas-tabs.component';
import { TileService } from 'app/tiles/tile.service';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { CanvasService } from '../canvas.service';
import { Subscription } from 'rxjs';
import { Metric, ChannelTypes, MetricTile, Color } from 'models';
import { GuiConfig } from 'omni-model/gui-config.model';
import { AppEnvironmentService, EnvironmentType } from 'domain-service/app-environment.service';
import { ModifierKeys } from 'domain-service/ui';
import { CanvasMapComponent } from '../canvas-map/canvas-map.component';
import { CanvasTrendComponent } from '../canvas-trend/canvas-trend.component';
import { CanvasSubViewBaseComponent } from './canvas-sub-view-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { TrendDefinition } from '../canvas-trend/trend-defintion.model';
import { CanvasTableComponent } from '../canvas-table/canvas-table.component';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { TrendStyle } from 'app/menu-panel/trend-settings/trend-style/trend-style.model';
import { PNGManager } from 'app/ui-components/png-manager';
import { FlashMessageService } from 'app/flash-message/flash-message.service';

@Component({
	selector: 'app-canvas',
	templateUrl: './canvas.component.html',
	styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit, OnDestroy, OnChanges {
	/**
	 * Allows access to the canvas tabs component
	 */
	@ViewChild(CanvasTabsComponent, { static: true })
	canvasTabsComponent: CanvasTabsComponent;
	@ViewChild(CanvasMapComponent)
	canvasMapComponent: CanvasMapComponent;

	@ViewChild(CanvasTrendComponent)
	canvasTrendComponent: CanvasTrendComponent;

	@ViewChild(CanvasTableComponent)
	canvasTableComponent: CanvasTableComponent;

	private get subViews(): Array<CanvasSubViewBaseComponent> {
		return [this.canvasMapComponent, this.canvasTrendComponent, this.canvasTableComponent];
	}

	/** Phone, tablet or large screen */
	@Input() displayMode: string;
	/**
	 * the canvas object pass from the canvas container
	 */
	@Input() canvas: Canvas;
	/**
	 * input to watch the mode of the canvas
	 */
	private _mode: CanvasMode;
	@Input() set mode(mode: CanvasMode) {
		if (!mode) return;
		this._mode = mode;
		if (!this.viewsLoaded.includes(mode)) this.viewsLoaded.push(mode);
		this.subViews.forEach(sv => sv?.onCanvasActivated());
	}
	get mode() {
		return this._mode;
	}
	/**
	 * Holds the given config
	 */
	@Input() config: GuiConfig;
	/**
	 * the output event triggered when the map icon is clicked on an empty canvas
	 */
	@Output() goToMapList = new EventEmitter<Canvas>();

	@Output() activateTrend = new EventEmitter<Canvas>();
	/**
	 * the output event triggered when the trend icon is clicked on an empty canvas
	 */
	@Output() goToTrendList = new EventEmitter<Canvas>();
	/**
	 * the output event triggered when the table icon is clicked on an empty canvas
	 */
	@Output() goToTable = new EventEmitter<Canvas>();
	/**
	 * the property being watched by the canvas children to know when a canvas tab is selected
	 */
	canvasTabSelected: CanvasTab;
	/**
	 * the property being watched by the canvas children to know when a canvas tab has been added
	 */
	canvasTabAdded: CanvasTab;
	/**
	 * the property being watched by the canvas children to know when a canvas tab has been closed
	 */
	canvasTabClosed: CanvasTab;
	/**
	 * property being watched by the canvas children to know when a metric has been removed
	 */
	metricRemovedId: any;
	/**
	 * the property being watched by the canvas children to know when canvas tabs are being initialized
	 */
	canvasTabsInitialized: CanvasTab[];

	viewsLoaded = [];

	defaultMetricSubscription: Subscription;

	removeDefaultMetricSubscription: Subscription;

	isDevelopmentMode = false;

	get isActive() {
		return this.config.selectedCanvas.id === this.canvas.id;
	}

	onCanvasTabSelected: (canvasTab: CanvasTab, defaultMetricRemoved?: boolean) => void;

	constructor(
		private tileService: TileService,
		private canvasService: CanvasService,
		private appEnvironment: AppEnvironmentService,
		private flashService: FlashMessageService
	) {}

	ngOnInit() {
		this.defaultMetricSubscription = this.canvasService.defaultMetricAdded$.subscribe(args => {
			if (args.selectedCanvas.position === this.canvas.position && args.selectedCanvas.id === this.canvas.id) {
				this.onDefaultMetricAdded(args.defaultMetric);
			}
		});

		this.removeDefaultMetricSubscription = this.canvasService.removeDefaultMetric$.subscribe(() => {
			if (this.config.isSelected && this.config.selectedCanvas.id === this.canvas.id) {
				this.onDefaultMetricRemoved();
			}
		});

		if (this.appEnvironment.isLocalhost || this.appEnvironment.currentEnvironment === EnvironmentType.testing) {
			this.isDevelopmentMode = true;
		}
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		for (const propertyName in changes) {
			if (propertyName === 'mode') {
				const currentMode = changes['mode'].currentValue;
				if (currentMode === 'empty') return;
			}
		}
	}

	ngOnDestroy() {
		this.defaultMetricSubscription.unsubscribe();
		this.removeDefaultMetricSubscription.unsubscribe();
	}

	/**
	 * Indicator if to show the work order cursor or not
	 */
	get showWorkorderCursor(): boolean {
		if (!this.canvas || !this.canvas.map) return false;
		return this.canvas.map['dropWorkOrderCone'];
	}

	/**
	 * toggle the work order cursor.
	 */
	toggleWorkOrderCursor() {
		this.canvas.map['dropWorkOrderCone'] = !this.canvas.map['dropWorkOrderCone'];
	}

	/**
	 * Prevents the browser from handling the drag over event
	 * @param event the dragover event
	 */
	allowDrop(event) {
		event.preventDefault();
	}

	/**
	 * Run when a tile has beend dragged and dropped into a canvas
	 * @param event - the mouse drop event
	 * @param canvas - the canvas which the tile was dropped into
	 */
	onTileDropped(event, canvas: Canvas) {
		if (canvas.id !== this.canvas.id) return;
		this.config.selectedCanvas = this.canvas;
		event.preventDefault();
		if (
			canvas.mode === 'trend' &&
			this.tileService.tileDragged.tile.metric.definition.source.type !== ChannelTypes.History &&
			this.tileService.tileDragged.tile.metric.definition.source.type !== ChannelTypes.WorkOrder
		) {
			return;
		}
		const existingTab = this.canvas.tabs.find(tab => {
			if (!tab.tile) return;
			return tab.tile.id === this.tileService.tileDragged.tile.id;
		});
		if (existingTab) {
			existingTab.locked = true;
			this.canvasTabsComponent.setCanvasTabActive(existingTab);
			return;
		}
		if (!this.canvasTabsComponent.canTileBeDragged(this.tileService.tileDragged)) return;
		const newTab = new CanvasTab();
		newTab.active = true;
		newTab.tile = this.tileService.tileDragged.tile;
		newTab.locked = true;
		this.canvasTabsComponent.addCanvasTab(newTab);
	}

	onTileClickedWhenActive(tileComponent: TileComponent) {
		const tabFound = this.canvas.tabs.find(tab => tab.tile === tileComponent.tile);
		if (!tabFound) return;
		if (this.canvasMapComponent) {
			this.canvasMapComponent.moveToTileExtent(tabFound);
		}
	}

	/**
	 * triggered when a canvas tab has been closed
	 * @param tab the tab closed
	 */
	onTabClosed(tab: CanvasTab) {
		if (!tab) return;
		this.canvasTabClosed = tab;
		this.subViews.forEach(sv => {
			// we don't want an exception in one view to preventing an action in another view.
			try {
				sv?.onTabClosed(tab)
			} catch {

			}
		});
	}

	/** when a tile gets removed from the app */
	onTabRemoved(tab: CanvasTab) {
		this.subViews.forEach(sv => sv?.onTabRemoved(tab));
	}

	/**
	 * triggered when a canvas tab has been selected
	 * @param selectedCanvasTab the canvas tab selected
	 */
	onTabSelected(event) {
		const selectedCanvasTab: CanvasTab = event.tab;
		this.canvasTabSelected = selectedCanvasTab;
		this.onCanvasTabSelected(selectedCanvasTab, event.defaultMetricRemoved); // let the parent know
		this.subViews.forEach(sv => sv?.onTabSelected(selectedCanvasTab)); // let the children know
	}

	/**
	 * triggered when a canvas tab has been added
	 * @param tab the tab that has been added
	 */
	onTabAdded(tab: CanvasTab) {
		this.canvasTabAdded = tab;
		this.subViews.forEach(sv => sv?.onTabAdded(tab));
	}

	onTabUpdated(attrs: { canvasTab: CanvasTab; oldId: string }) {
		this.subViews.forEach(sv => sv?.onTabUpdated(attrs.canvasTab, attrs.oldId));
	}

	onTabMetricSubsctiptionChanged(tab: CanvasTab) {}

	/**
	 * triggered when a user selects a tile
	 * @param tile the tile selected by the user
	 */
	onTileSelected(tileComponent: TileComponent) {
		// const tileContainsTheDefaultMetric = this.canvas.tabs.find(tab => tab.defaultMetric && tab.defaultMetric.id === tileComponent.tile.metric.id);

		// const tabExists = tileContainsTheDefaultMetric ? tileContainsTheDefaultMetric : this.canvas.tabs.find(tab => tab.tile && tab.tile.id === tileComponent.tile.id);

		const tabExists = this.canvas.tabs.find(tab => tab.tile && tab.tile.id === tileComponent.tile.id);
		if (tabExists) {
			this.canvasTabsComponent.setCanvasTabActive(tabExists);
			return;
		}
		if (this.canvas.tabs.length >= 5) {
			this.flashService.popMessage('maximum allowable canvas limit reached');
			return;
		}
		if (this.canvas.mode === 'trend' && tileComponent.tile.metric.definition.source.type !== 2 && tileComponent.tile.metric.definition.source.type !== 3) return;
		const newTab = new CanvasTab();
		(newTab.active = true), (newTab.tile = tileComponent.tile), (newTab.locked = false);
		this.canvasTabsComponent.addCanvasTab(newTab);
	}

	/**
	 * sets this canvas as the selected canvas for the current config
	 */
	selectCanvas() {
		if (this.config.selectedCanvas === this.canvas) return;
		this.config.selectedCanvas = this.canvas;
	}

	/**
	 * returns true if this canvas is the selected canvas and if there are more than one canvas
	 */
	highlightCanvas(): boolean {
		if (this.config.canvasList.length <= 1) return false;
		if (!this.config.selectedCanvas) return false;

		return this.config.selectedCanvas.id === this.canvas.id;
		return true;
	}

	/**
	 * triggered when a default metric is added to a canvas
	 */
	private onDefaultMetricAdded(defaultMetric: Metric) {
		if (this.config.selectedCanvas.id !== this.canvas.id) {
			return;
		}
		const defaultMetricTabIndex = this.canvas.tabs.findIndex(tab => !this.isNullOrUndefined(tab.defaultMetric));
		if (defaultMetricTabIndex >= 0) {
			this.canvasTabsComponent.closeCanvasTab(defaultMetricTabIndex);
		}
		const newTab = new CanvasTab();
		newTab.active = true;
		newTab.locked = true;
		newTab.defaultMetric = defaultMetric;
		this.canvasTabsComponent.addCanvasTab(newTab);
	}

	/** triggered when none is selected as the default metric */
	private onDefaultMetricRemoved() {
		if (this.config.selectedCanvas.id !== this.canvas.id) {
			return;
		}
		const defaultMetricTabIndex = this.canvas.tabs.findIndex(tab => !this.isNullOrUndefined(tab.defaultMetric));
		if (defaultMetricTabIndex >= 0) {
			this.canvasTabsComponent.closeCanvasTab(defaultMetricTabIndex, null, true);
		}
	}

	private isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	}

	onModifierKeysChanged(modifierKeys: ModifierKeys) {
		// this.canvasMapComponent.onModifierKeysChanged(modifierKeys);
	}

	metricUpdated(canvasTab: CanvasTab) {
		this.subViews.forEach(sv => {
			if (!sv) return;
			sv.metricUpdated(canvasTab);
		});
	}

	onTileTimeframeChanged(tileComponent: TileComponent) {
		const tab = this.canvas.tabs.find(t => t.tile === tileComponent.tile);
		if (!tab) return;
		this.canvasTabsComponent.updateTabMetricSubscription(tab);
	}

	onMetricDefinitionChanged(metricId: string) {
		this.canvasTabsComponent.onMetricDefinitionChanged(metricId);
	}

	onHistoryFieldTrendIconClicked(trendDefinition: TrendDefinition) {
		const tabExists = this.canvas.tabs.find(tab => tab.trendDefinition && tab.trendDefinition.trendLabel === trendDefinition.trendLabel);
		if (tabExists) {
			this.canvasTabsComponent.setCanvasTabActive(tabExists);
		} else {
			const newTab = new CanvasTab();
			(newTab.active = true), (newTab.trendDefinition = trendDefinition), (newTab.locked = false);
			this.canvasTabsComponent.addCanvasTab(newTab);
		}
	}

	closeTab(canvasTab: CanvasTab) {
		const i = this.canvas.tabs.indexOf(canvasTab);
		this.canvasTabsComponent.closeCanvasTab(i);
	}

	async onTileBackgroundColorChanged(tile: MetricTile) {
		const metricName = tile.metric.definition.name;
		const color = tile.backgroundColor.toString();
		if (this.canvasTrendComponent) this.canvasTrendComponent.updateTrendBackgroundColor(metricName, color);
		if (this.canvasTableComponent) {
			const activeTab = this.canvas.tabs.find(t => t.active);
			if (activeTab.tile === tile) this.canvasTableComponent.updateTableBGColor(color);
		}
		if (this.canvasMapComponent) {
			const tab = this.canvas.tabs.find(t => t.tile === tile);
			if (!tab) return;
			const layerId = this.canvasMapComponent.getTabLayerId(tab);
			const backgroundColor = Color.fromHex(tile.backgroundColor);
			const imageUrl = await PNGManager.editImage(tile.metric.definition.iconUrl, { backgroundColor });
			this.canvasMapComponent.updateLayerIcons(layerId, imageUrl);
		}
	}

	onTrendStyleChanged(style: TrendStyle) {
		this.canvasTrendComponent.onTrendStyleChanged(style);
	}

	onTrendIconClicked() {
		if (this.canvasTrendComponent) this.canvasTrendComponent.activateTrend();
	}
}
