import { Component, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { LeftIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { DataChannelService } from 'domain-service/data-channel.service';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { Metric } from 'models';
import { WorkOrderWrapper } from 'models/work-order';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
export interface SampleRelatedWorkorder {
	topLabel: string;
	bottomLeftLabel: string;
	iconUrl: string;
}

@Component({
	selector: 'app-workorder-related-workorders',
	templateUrl: './workorder-related-workorders.component.html',
	styleUrls: ['./workorder-related-workorders.component.scss']
})
export class WorkorderRelatedWorkordersComponent extends MenuPanelBaseComponent {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;
	relatedWorkorders: Array<WorkOrderWrapper>;
	currentWorkorder: WorkOrderWrapper;
	selectedItem: WorkOrderWrapper;
	advancedMode: boolean;
	metric: Metric;
	private resolvers = new Map();

	constructor(
		private workOrderFactory: WorkOrderFactory,
		private dataChannelService: DataChannelService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;

		this.relatedWorkorders = args.parameter.relatedWorkorders;
		this.currentWorkorder = args.parameter.currentWorkOrder;
		this.advancedMode = args.parameter.advancedMode;
		this.metric = args.parameter.metric;
	}

	ngOnInit() {
		this.menuPanelComponent.updateView({ title: this.currentWorkorder.workOrderKey, backgroundClass: 'orange-background' });

		this.listComponent.getResolver = item => {
			if (!this.resolvers.has(item.workOrderKey)) this.resolvers.set(item.workOrderKey, this.getListResolver());
			const resolver = this.resolvers.get(item.workOrderKey);
			return resolver;
		};
		this.selectedItem = this.relatedWorkorders.find(workorder => {
			if (workorder.workOrderKey === this.currentWorkorder.workOrderKey) return workorder;
		});
	}

	private getListResolver() {
		const relatedWorkorderResolver = LeftIconTwoLinesComponent.createResolver();
		relatedWorkorderResolver.getTopLabel = item => {
			return (item as WorkOrderWrapper).workType;
		};
		relatedWorkorderResolver.getBottomLeftLabel = item => {
			if (!item.parentWorkOrderKey) return (item as WorkOrderWrapper).workOrderKey + ' (parent)';
			return (item as WorkOrderWrapper).workOrderKey;
		};
		relatedWorkorderResolver.getIconPath = item => {
			return new Promise(solve => solve(this.getWorkOrderIcon(item)));
		};
		relatedWorkorderResolver.getBottomRightLabel = item => {
			return '';
		};
		return relatedWorkorderResolver;
	}

	getWorkOrderIcon(workOrder: WorkOrderWrapper): string {
		const completeCodes = this.dataChannelService.getCompletedWorkOrderValues();
		return completeCodes.includes(workOrder.status) ? 'assets/workorder-completed.png' : 'assets/workorder.png';
	}

	/**
	 * This function will set selected workOrder
	 * @param {AdvancedWorkOrder} workOrder - The selected workOrder
	 */
	workorderSelected(workOrder: WorkOrderWrapper) {
		NavigationService.navigateTo(Pages.workorderOutline, { workOrder: workOrder, advancedMode: this.advancedMode, metric: this.metric });
	}
}
