import { Theme } from './theme.model';
import { MetricTile } from '../models/metric-tile.model';
import { Canvas } from './canvas.model';
import { TabScopeType } from './tab-scope.enum';

export class GuiConfigBase {
	/**
	 * A unique uuid identifier of the tab.
	 */
	id: string;
	/**
	 * User defined name for a tab which is optional.
	 */
	name?: string;
	/**
	 * Tab's content. Currently takes values like: dashboard, modeling, outage, ops and cip.
	 */
	content: string;
	/**
	 * A string which defines the tile layout and the canvas layout used by the tab.
	 */
	get layout() {
		switch (this.canvasLayout) {
			case 1:
				return this.tilesLayout + ' tiles, ' + this.canvasLayout + ' canvas';
			case 2:
			case 3:
			case 4:
				return this.tilesLayout + ' tiles, ' + this.canvasLayout + ' canvases';

			case 5:
				return this.tilesLayout + ' tiles, 2 canvases';

			case 6:
				return this.tilesLayout + ' tiles, 3 canvases';
		}
	}
	/**
	 * the tabs visibility scope
	 */
	scope?: string;

	/**
	 * Team id of the tab (only applicable if scope is team)
	 */
	teamId?: string;
	teamName?: string;

	/**
	 * User name of the team owner/creator (only applicable if scope is team)
	 */
	teamOwner?: string;

	/**
	 * List of team members (only applicable if scope is team)
	 */
	teamMembers?: string[];
	/**
	 * Tab's visibility settings. Currently takes the values: just me, team and all users.
	 */
	visibility: string;
	/**
	 * The OMNI background theme object. This defines the unique display name of the theme and the url of the background image
	 * used by the theme.
	 */
	theme: Theme;
	/**
	 * A boolean variable that will be used to check whether the tab is selected or not.
	 */
	isSelected: boolean;
	/**
	 * The layout used for the canvas within the tab. There are 6 different layout options currently available and thus, it
	 * can take values from 1 to 6 which represents each of these layouts.
	 */
	canvasLayout: number;
	/**
	 * The layout used for the tiles within the tab. There are 3 options available for tile layout and thus takes 3 different values:
	 * 0 - No tiles
	 * 6 - 6 tiles displayed in a single row
	 * 12 - 2 rows of 6 tiles in each row.
	 */
	tilesLayout: number;
	/**
	 * The currently selected tile.
	 */
	selectedTile?: MetricTile;
	/**
	 * The currently selected canvas. It will be a number from 1 to 4. Since a tab at a time can hold upto a maximum of
	 * 4 canvases.
	 */
	selectedCanvas?: Canvas;
	/**
	 * The last url path visited
	 */
	lastPathVisited: string;

	createdBy?: string;

	/**
	 * Any profileGroup name associated with the tab. Null if no PG support for the customer
	 */
	profileGroupName?: string;

	isEnabled?: boolean;

	constructor(canvasLayout: number, tilesLayout: number) {
		this.canvasLayout = canvasLayout;
		this.tilesLayout = tilesLayout;
	}
}

/**
 * This defines models for OMNI tab.
 */
export class GuiConfig extends GuiConfigBase {
	/**
	 * An array of OMNI tiles defined within the given tab.
	 */
	tileList?: { [key: string]: MetricTile };

	/**
	 * An array of available canvases
	 */
	canvasList?: Canvas[];

	hasPermission?: boolean;

	constructor(canvasLayout: number, tilesLayout: number) {
		super(canvasLayout, tilesLayout);
		this.tileList = {};
		this.canvasList = [];
	}

	/**
	 * The method creates a new tab. This method is used to create the default tab to be displayed initially when the
	 * project is loaded.
	 */
	static generateNewConfig(profileGroupName?: string): Partial<GuiConfig> {
		const newConfigObject: GuiConfig = new GuiConfig(1, 6);
		newConfigObject.content = 'dashboard';
		newConfigObject.scope = TabScopeType[TabScopeType.Myself];
		newConfigObject.theme = new Theme('OMNI default');
		newConfigObject.isSelected = true;
		newConfigObject.isEnabled = true;
		newConfigObject.canvasList = [];
		newConfigObject.tileList = {};
		newConfigObject.profileGroupName = profileGroupName;
		for (let i = 0; i < newConfigObject.tilesLayout; i++) {
			const tile = new MetricTile();
			tile.tilePosition = i;
			newConfigObject.tileList[tile.tilePosition] = tile;
		}
		newConfigObject.selectedTile = newConfigObject.tileList[Object.keys(newConfigObject.tileList)[0]]; // Select the first tile by default
		newConfigObject.selectedCanvas = new Canvas(1, 1);
		newConfigObject.canvasList.push(newConfigObject.selectedCanvas);
		return newConfigObject;
	}

	/**
	 * This method is used to update the tab's name.
	 * @param {string} name - User defined name given to a tab through GUI.
	 */
	setName?(name: string): void {
		this.name = name;
	}
}
