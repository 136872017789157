<div class="scrolling-content">
    <app-menu-panel-field
        [header]="'display name & icon'"
        [value]="user.displayName"
        [disabled]="true"
        [backgroundColor]="lightGrayColor"
        [thumbnailEnabled]="true"
        [thumbnailIconPath]="'data:image/png;base64,' + thumbnail"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'full name'"
        [value]="user.fullName"
        [disabled]="true"
        [backgroundColor]="darkGrayColor"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'email address'"
        [(value)]="email"
        [backgroundColor]="lightGrayColor"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'username'"
        [value]="user.userName"
        [disabled]="true"
        [backgroundColor]="darkGrayColor"
    ></app-menu-panel-field>
    <app-menu-panel-field
        [header]="'password'"
        [value]="'********'"
        [disabled]="true"
        [backgroundColor]="lightGrayColor"
        [buttonEnabled]="!user.isSSO"
        (goToButtonClick)="changePassword()"
    ></app-menu-panel-field>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions *ngIf="!user.isSSO" align="end">
		<button mat-button class="omni-md-font" [class.disabled]="!isSaveEnabled()" (click)="isSaveEnabled() && onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>