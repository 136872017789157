import { AssetLocationWithAssets } from './asset-location-with-assets.model';
import { AssetLocationWithAssetsContract } from '../../contracts/work-order/asset-location-with-assets-contract';

export class AssetLocationsWithAssets extends Array<AssetLocationWithAssets> {
	private _assetLocationByActiveAssets: any = {};
	private _assetLocationByGisId: any = {};

	static fromContracts(assetLocationsWithAssetsContract: AssetLocationWithAssetsContract[]) {
		const assetLocationsCollection = new AssetLocationsWithAssets();
		if (!assetLocationsWithAssetsContract) return assetLocationsCollection;

		for (const assetLocationWithAssetsContract of assetLocationsWithAssetsContract) {
			const assetLocation = AssetLocationWithAssets.fromContract(assetLocationWithAssetsContract);

			if (assetLocation.gisId && !assetLocationsCollection._assetLocationByGisId[assetLocation.gisId.toLowerCase()]) {
				assetLocationsCollection._assetLocationByGisId[assetLocation.gisId.toLowerCase()] = assetLocation;
			}

			if (assetLocation.activeAsset && !assetLocationsCollection._assetLocationByActiveAssets[assetLocation.activeAsset.toLowerCase()]) {
				assetLocationsCollection._assetLocationByActiveAssets[assetLocation.activeAsset.toLowerCase()] = assetLocation;
			}

			assetLocationsCollection.push(assetLocation);
		}
		return assetLocationsCollection;
	}

	getAssetLocationByActiveAsset = (activeAsset: string) => {
		if (!activeAsset) return undefined;
		// this.find(assetLocation => assetLocation.activeAsset === activeAsset);
		return this._assetLocationByActiveAssets[activeAsset.toLowerCase()];
	}

	getAssetLocationByGisId = (assetkey: string) => {
		if (!assetkey) return undefined;
		// this.find(assetLocation => assetLocation.gisId === assetkey);
		return this._assetLocationByGisId[assetkey.toString().toLowerCase()];
	}
}
