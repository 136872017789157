import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Canvas, CanvasMode } from 'omni-model/canvas.model';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { CanvasService } from '../canvas.service';
import { Subscription } from 'rxjs';
import { MetricRequesterService } from 'app/metric/metric-requester.service';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { AppEnvironmentService, EnvironmentType } from 'domain-service/app-environment.service';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-canvas-tabs',
	templateUrl: './canvas-tabs.component.html',
	styleUrls: ['./canvas-tabs.component.scss']
})
export class CanvasTabsComponent implements OnInit, OnDestroy {
	/**
	 * The canvas that holds these tabs
	 */
	@Input() canvas: Canvas;
	/**
	 * Holds the given config
	 */
	@Input() config: GuiConfig;
	/** if true, it shows instructions to click the map to add a workorder */
	@Input() showWorkorderInstructionText: boolean;

	@Output() tabSelected = new EventEmitter<{tab: CanvasTab, defaultMetricRemoved: boolean}>();

	@Output() tabAdded = new EventEmitter<CanvasTab>();

	@Output() tabClosed = new EventEmitter<CanvasTab>();

	@Output() tabRemoved = new EventEmitter<CanvasTab>();

	@Output() tabsInitialized = new EventEmitter<CanvasTab[]>();

	@Output() navigateToMapList = new EventEmitter();

	@Output() trendActivated = new EventEmitter();

	@Output() metricUpdated = new EventEmitter<CanvasTab>();

	/** emits the tab that was updated and the old id of the timeframedMetric */
	@Output() tabUpdated = new EventEmitter<{ canvasTab: CanvasTab; oldId?: string }>();

	@ViewChildren('tabElement') tabElements: QueryList<ElementRef>;

	onTileRemovedSubscription: Subscription;

	onMetricRemovedSubscription: Subscription;

	isDevelopmentMode = false;

	constructor(
		private scrollingService: ScrollToViewService,
		private canvasService: CanvasService,
		private metricRequesterService: MetricRequesterService,
		private appEnvironment: AppEnvironmentService,
		private interopService: OmniInteropService
	) {}

	ngOnInit() {
		if (this.canvas.tabs && this.canvas.tabs.length) {
			const activeTab = this.canvas.tabs.find(tab => tab.active);
			if (!activeTab) this.canvas.tabs[0].active = true;
			this.canvas.tabs.forEach(tab => this.setTabMetricSubscription(tab));
			this.tabsInitialized.emit(this.canvas.tabs);
		}

		this.onTileRemovedSubscription = this.canvasService.removeTileFromCanvas$.subscribe(canvasIdAndTabIndex => {
			if (!canvasIdAndTabIndex) return;
			const { canvasId, tabIndex, metricId, metricName, tileId } = canvasIdAndTabIndex;
			if (canvasId !== this.canvas.id) return;

			this.removeCanvasTab(tabIndex);
		});

		this.onMetricRemovedSubscription = this.canvasService.removeMetricFromCanvas$.subscribe(metricIdAndCanvas => {
			if (!metricIdAndCanvas) return;
			const { metricId, metricName, canvas } = metricIdAndCanvas;
			if (canvas.id !== this.canvas.id) return;

			this.onMetricRemoved(metricId, metricName);
		});

		if (this.appEnvironment.isLocalhost || this.appEnvironment.currentEnvironment === EnvironmentType.testing) {
			this.isDevelopmentMode = true;
		}
	}

	ngAfterViewInit(): void {
		this.tabElements.changes.subscribe((list: QueryList<ElementRef>) => {
			list.last?.nativeElement.scrollIntoView();
		});
	}

	setTabMetricSubscription(tab: CanvasTab) {
		const timeframeFilter = tab.tile?.uiTimeframeFilter ?? tab.defaultMetric?.definition?.timeFrameFilter;
		const metric = tab.tile?.metric ?? tab.defaultMetric;
		if (!metric) return;
		const metricSubscriber = this.interopService.metricManager.getMetricSubscriber(metric, timeframeFilter);
		if (tab.metricSubscription) {
			tab.metricSubscription.unsubscribe();
			this.interopService.metricManager.removeTimeframedMetricsNotInUse();
		}
		tab.metricSubscription = metricSubscriber;
		tab.metricSubscription.onMetricUpdated = () => {
			this.metricUpdated.emit(tab);
			this.tabUpdated.emit({ canvasTab: tab });
		};
	}

	ngOnDestroy() {
		this.canvas.tabs.forEach(t => t.metricSubscription.unsubscribe());
		this.interopService.metricManager.removeTimeframedMetricsNotInUse();
		if (this.onTileRemovedSubscription) this.onTileRemovedSubscription.unsubscribe();
		if (this.onMetricRemovedSubscription) this.onMetricRemovedSubscription.unsubscribe();
	}

	setCanvasTabActive(tab: CanvasTab, defaultMetricRemoved?: boolean) {
		if (tab.active) tab.activeClicked = true;
		else tab.activeClicked = false;
		this.canvas.tabs.forEach(t => (t.active = false));
		tab.active = true;
		this.tabSelected.emit({tab, defaultMetricRemoved});
	}

	closeCanvasTab(tabIndex: number, event?: MouseEvent, defaultMetricRemoved?: boolean) {
		if (event) event.stopPropagation();
		if (this.canvas.tabs[tabIndex].active) {
			if (tabIndex > 0) {
				const tab = this.canvas.tabs[tabIndex - 1];
				if (tab.defaultMetric) this.toCloseCanvasTab(tabIndex);
				this.setCanvasTabActive(this.canvas.tabs[tabIndex - 1], defaultMetricRemoved);
			} else if (this.canvas.tabs.length > 1) {
				this.setCanvasTabActive(this.canvas.tabs[tabIndex + 1], defaultMetricRemoved);
			}
		}
		this.toCloseCanvasTab(tabIndex);
	}

	toCloseCanvasTab(tabIndex) {
		const removedTabs = this.canvas.tabs.splice(tabIndex, 1);
		this.tabClosed.emit(removedTabs[0]);
		removedTabs[0].metricSubscription?.unsubscribe();
		this.removeUnusedMetricSubscriptions();
		this.updatedCanvasTabs();
	}

	removeCanvasTab(tabIndex) {
		let tabRemoved, isRemovedTabActive = false;
		if (!Array.isArray(tabIndex)) {
			tabIndex = [tabIndex];
		}
		tabIndex.forEach((key) => {
			tabRemoved = this.canvas.tabs[key];
			if (tabRemoved.active) isRemovedTabActive = true;
			this.tabRemoved.emit(tabRemoved);
		});
		const indexSet = new Set(tabIndex);
		const activeCanvasTabs = this.canvas.tabs.filter((value, i) => !indexSet.has(i));
		this.canvas.tabs = activeCanvasTabs;
		if (isRemovedTabActive && this.canvas.tabs.length) {
			this.setCanvasTabActive(this.canvas.tabs[0]);
		}
		this.updatedCanvasTabs();
	}

	addCanvasTab(tab: CanvasTab) {
		this.canvas.tabs.forEach((existingTab, index) => {
			existingTab.active = false;
			if (!existingTab.locked && !tab.locked) {
				// close unlocked tabs only if the new tab is not dragged
				this.closeCanvasTab(index);
			}
		});
		tab.active = true;
		this.setTabMetricSubscription(tab);
		const isTabTimeframe = this.canvas.tabs.find(canvasTab => canvasTab.metricSubscription.timeframedMetric.id === tab.metricSubscription.timeframedMetric.id);
		if (isTabTimeframe) tab.isTimeframed = true;
		this.canvas.tabs.push(tab);
		this.tabAdded.emit(tab);
		if (tab.defaultMetric) setTimeout(() => this.updatedCanvasTabs(), 100);
		if (tab.trendDefinition) return;
	}

	updateTabMetricSubscription(canvasTab: CanvasTab) {
		const oldId = (canvasTab.defaultMetric) ? 'default-metric-graphic-layer' : canvasTab.tile.id;
		this.setTabMetricSubscription(canvasTab);
		this.tabUpdated.emit({ canvasTab, oldId });
	}

	updatedCanvasTabs() {
		this.canvasService.saveOmniCanvas(this.config, this.canvas).subscribe(() => {
			console.log('canvas updated!');
		});
	}

	canTileBeDragged(tileComponent: TileComponent): boolean {
		if (this.canvas.tabs.length >= 5) return false;
		return true;
	}

	/**
	 * Navigate to the the map settings via the router.
	 */
	goToSettings(event: MouseEvent) {
		if (event) event.stopPropagation();
		this.config.selectedCanvas = this.canvas;
		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		if (this.canvas.mode === CanvasMode.map) {
			NavigationService.navigateTo(Pages.mapSettings);
		} else if (this.canvas.mode === CanvasMode.trend) {
			NavigationService.navigateTo(Pages.trendSettings);
		} else if (this.canvas.mode === 'table') {
			NavigationService.navigateTo(Pages.tableSettings);
		}
	}

	loadTrend() {
		if (this.canvas.mode === CanvasMode.trend) return;
		this.canvas.mode = CanvasMode.trend;
		this.canvasService.saveOmniCanvas(this.config, this.canvas).subscribe();
		this.trendActivated.emit();
	}

	private onMetricRemoved(metricId: string, metricName: string) {
		const tabIndices = [];
		this.canvas.tabs.forEach((tab, index) => {
			if (tab.defaultMetric && tab.defaultMetric.id === metricId) {
				return tabIndices.push(index);
			} else if (tab.tile && tab.tile.metric.id === metricId) {
				return tabIndices.push(index);
			}
		});

		this.removeCanvasTab(tabIndices);
	}

	loadMap() {
		if (this.canvas.mode === CanvasMode.map) return;

		if (!this.canvas.map) {
			this.navigateToMapList.emit();
			return;
		}

		this.canvas.mode = CanvasMode.map;
		this.canvasService.saveOmniCanvas(this.config, this.canvas).subscribe();
	}

	loadTable() {
		if (this.canvas.mode === CanvasMode.table) return;
		if (!this.canvas.table) this.canvas.table = {};
		this.canvas.mode = CanvasMode.table;
	}

	activateThisCanvas() {
		this.config.selectedCanvas = this.canvas;
	}

	onMetricDefinitionChanged(metricId: string) {
		this.canvas.tabs.forEach(t => {
			if (t.metric.id !== metricId) return;
			this.updateTabMetricSubscription(t);
		});
	}

	private removeUnusedMetricSubscriptions() {
		setTimeout(() => {
			// allow time for child components to kill the timeframedMetric dependencies before removing it from memory
			this.interopService.metricManager.removeTimeframedMetricsNotInUse();
		}, 1000);
	}
}
