import { AssociatedAssetContract } from '../../contracts/recurrence/associated-asset-contract';
import { AssociatedAsset } from './associated-asset.model';

export class AssociatedAssets extends Array<AssociatedAsset> {
    static fromContracts(assetContracts: AssociatedAssetContract[]): AssociatedAssets {
        const associatedAssets = new AssociatedAssets();
        for (const asset of assetContracts) associatedAssets.push(AssociatedAsset.fromContract(asset));
        return associatedAssets;
    }

    getContracts = (): AssociatedAssetContract[] => this.map(aa => aa.getContract());
}
