import { Injectable } from '@angular/core';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { WorkOrderRawContract } from 'contracts/work-order';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ChannelTypes, Metric } from 'models';
import { AssetRecord } from 'models/records/asset-record.model';
import { HistoryRecord } from 'models/records/history-record.model';
import { MetricRecord } from 'models/records/metric-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { AdvancedWorkOrder, StandardWorkOrder } from 'models/work-order';
import { Observable, ReplaySubject } from 'rxjs';
import { Feature } from 'sedaru-util/esri-core';
import { ArcGisFeature } from './arc-gis-feature.model';
import { MetricViewEventArg, TableActionType } from './metric-view-event-arg.model';


@Injectable({
	providedIn: 'root'
})
export class MetricViewService {

	private metricEvent: ReplaySubject<MetricViewEventArg>;
	private nextId: number;

	constructor(private interopService: OmniInteropService) {
		this.metricEvent = new ReplaySubject<MetricViewEventArg>(1);
		this.nextId = 1;
	}


	getMetricEventStream(): Observable<MetricViewEventArg> {
		return this.metricEvent;
	}


	async triggerMetricEvent(metric: Metric, record: MetricRecord | any, eventArg: MetricViewEventArg, pacifierUpdater?: (status: boolean) => void): Promise<void> {

		const isMetricRecordInstance = record instanceof MetricRecord;

		const tableRowSelect = (eventArg.tableAction == TableActionType.Highlight);
		const featureOrWorkOrder = this.getWorkOrderOrFeature(metric, record, tableRowSelect);

		const tempEvent = (<any>eventArg);
		tempEvent.id = this.nextId;
		this.nextId++;

		const metricRecord = await this.createMetricRecord(metric, featureOrWorkOrder);
		switch (metric.definition.source.type) {
			case ChannelTypes.History:
			case ChannelTypes.Asset:
				await this.loadAssetAndHistoryLayers(metric, metricRecord);
				break;
		}
		if (isMetricRecordInstance) {
			metricRecord.resolver = record.resolver;
		} else if (metricRecord.contextGroup) {
			metricRecord.resolver = this.getResolver(metricRecord.contextGroup);
		}

		eventArg.metricRecord = metricRecord;
		this.metricEvent.next(eventArg);

		if (eventArg.showInSidePanel) {

			switch (metric.definition.source.type) {
				case ChannelTypes.History:
					if (!metricRecord?.contextGroup?.historyRecord) {
						this.interopService?.omniDomain?.snackBarService?.popMessage(`Associated history record could not be found`, SnackbarMessageTypes.WARNING);
						break;
					}

					const assetType = metric.definition.source.assetType;
					const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
					const formType = metricRecord.contextGroup.historyRecord.workType?.toLowerCase();
					const historyFormDefinition = assetDefinition.forms.find(form => form.workType.toLowerCase() === formType);
					if (historyFormDefinition) {
						NavigationService.navigateTo(Pages.historyRecordsForm,
							{
								context: metricRecord.contextGroup,
								metric: metric
							});
					} else {
						console.error('No form definition found for ', formType);
						NavigationService.navigateTo(Pages.mapAssetAttributes, { feature: metricRecord.contextGroup.historyRecord.feature });
					}
					break;

				case ChannelTypes.Asset:
					NavigationService.navigateTo(Pages.assetInformation,
						{
							recordContext: metricRecord.contextGroup,
							metric: metric
						});
					break;

				case ChannelTypes.WorkOrder:
					NavigationService.navigateTo(Pages.workorderOutline,
						{
							workOrder: metricRecord.contextGroup.workOrderRecord,
							advancedMode: metric.definition.workOrderChannel.isAdvancedWorkOrder,
							metric: metric
						});
					break;
			}
			if (pacifierUpdater) {
				pacifierUpdater(false);
			}
		} else {
			if (pacifierUpdater) {
				pacifierUpdater(false);
			}
		}


	}

	/**
	 * Get a feature or work order from the provided record.
	 * @param metric The metric for which record is required.
	 * @returns
	 */
	private getWorkOrderOrFeature(metric: Metric, record: any, tableRowSelect?: boolean): Feature | StandardWorkOrder | AdvancedWorkOrder {

		const isMetricRecordInstance = record instanceof MetricRecord;
		let featureOrWorkOrder: Feature | StandardWorkOrder | AdvancedWorkOrder = null;

		switch (metric.definition.source.type) {
			case ChannelTypes.WorkOrder:
				if (!isMetricRecordInstance) {
					const contract = <any>{ WorkOrder: record };
					const isAwo = metric.definition.workOrderChannel.isAdvancedWorkOrder;
					if (isAwo) {
						if (contract instanceof WorkOrderRawContract && !tableRowSelect) {
							featureOrWorkOrder = AdvancedWorkOrder.fromContract(contract, null, null);
						} else {
							featureOrWorkOrder = AdvancedWorkOrder.fromHmsContract(record);
						}
					} else {
						featureOrWorkOrder = StandardWorkOrder.fromContract(this.convertToLowerCase(record));
					}
				} else {
					featureOrWorkOrder = record.contextGroup.workOrderRecord;
				}
				break;

			default:
				if (!isMetricRecordInstance) {
					featureOrWorkOrder = Feature.fromEsriFeature({ attributes: record });
				} else {
					featureOrWorkOrder = record.feature;
				}
				break;
		}

		return featureOrWorkOrder;
	}

	convertToLowerCase(record): any {
		const lowerCaseRecord = {};
		for (const key in record) {
			if (key) {
				const value = record[key];
				lowerCaseRecord[key.toLowerCase()] = record[key];
			}
		}
		return lowerCaseRecord;
	}


	/**
	 * Create a metric record from partial information
	 * @param metric The metric for which we are building the record for.
	 * @returns
	 */
	private async createMetricRecord(metric: Metric, record: Feature | StandardWorkOrder | AdvancedWorkOrder): Promise<MetricRecord> {
		let metricRecord: MetricRecord = null;

		if (record instanceof Feature) {
			metricRecord = await this.createMetricFromFeature(metric, record);
		} else {
			metricRecord = this.createMetricRecordFromWorkOrder(metric, record);
		}

		return metricRecord
	}


	/**
	 * Create a metric record from a feature.
	 * @param metric
	 * @param feature
	 * @returns
	 */
	private async createMetricFromFeature(metric: Metric, feature: Feature): Promise<MetricRecord> {
		const metricRecord = new MetricRecord();
		metricRecord.metric = metric;
		const context = new RecordContextGroup();
		metricRecord.contextGroup = context;

		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(metric.definition.source.assetType);

		context.assetRecord = AssetRecord.create(assetDefinition, feature);
		return metricRecord;
	}

	/**
	 * Get the resolver associated with the context group.
	 * @param contextGroup
	 * @returns
	 */
	private getResolver(contextGroup: RecordContextGroup) {
		const templateResolver = this.interopService.templateManager.getTemplateResolver(contextGroup);
		templateResolver.showDivider = item => true;
		templateResolver.onIsDisabled = item => false;
		return templateResolver;
	}

	/**
	 * This will load the asset layer and history layer for the metric record.
	 * @param metric
	 * @param metricRecord
	 */
	private async loadAssetAndHistoryLayers(metric: Metric, metricRecord: MetricRecord): Promise<void> {
		const feature = metricRecord.feature;
		const { assetQuery, historyQuery } = this.getLayerQueries(metric, feature);
		const assetLayer = this.interopService.arcGISManager.getMapAssetLayer(metric.definition.source.assetType);
		let assetFeature = null;
		if (assetLayer) {
			const assetFeatureJSON = await assetLayer.query(assetQuery, '*', true);
			if (assetFeatureJSON?.length > 0) {
				assetFeature = Feature.fromEsriFeature(assetFeatureJSON[0]);
				assetFeature.geometry = feature.geometry;
			} else {
				assetFeature = feature;
			}
		}
		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(metric.definition.source.assetType);
		metricRecord.contextGroup.assetRecord = AssetRecord.create(assetDefinition, assetFeature);

		if (metric.definition.source.type == ChannelTypes.History) {
			if (historyQuery) {
				const historyLayer = this.interopService.arcGISManager.getHistoryLayer(metric.definition.source.assetType);
				if (historyLayer) {
					const historyFeatureJSON = await historyLayer.query(historyQuery, '*', true);
					if (historyFeatureJSON?.length > 0) {
						const historyFeature = this.getHistoryFeature(metric, historyFeatureJSON, feature.attributes.objectid);
						metricRecord.contextGroup.historyRecord = HistoryRecord.create(assetDefinition, historyFeature);
					}
				}
			}
		}
	}


	/**
	 * Create a metric record.
	 * @param metric
	 * @param record
	 * @returns
	 */
	private createMetricRecordFromWorkOrder(metric: Metric, record: any): MetricRecord {
		const metricRecord = new MetricRecord();
		metricRecord.metric = metric;
		const context = new RecordContextGroup();
		metricRecord.contextGroup = context;

		const isAwo = metric.definition.workOrderChannel.isAdvancedWorkOrder;
		const newWo = isAwo ? new AdvancedWorkOrder() : new StandardWorkOrder();
		newWo.workOrderKey = record.workOrderKey || record.WorkorderKey;
		newWo.workType = record.workType || record.WorkType;
		newWo.startDate = record.startDate || record.StartDate;
		newWo.completedDate = record.completedDate || record.CompletedDate;
		if (record.geometry) {
			newWo.xCoordinate = record.geometry.x ?? 0;
			newWo.yCoordinate = record.geometry.y ?? 0;
		}
		newWo.isNew = false;
		context.workOrderRecord = newWo;
		return metricRecord;
	}

	/**
	 * Gets the queries to perform to retrieve the asset and history layers.
	 * @param metric
	 * @param featureSelected
	 * @returns
	 */
	private getLayerQueries(metric: Metric, featureSelected: ArcGisFeature): { assetQuery: string; historyQuery: string } {
		let assetQuery, historyQuery;
		const assetFieldName = metric.definition.assetChannel.attributes['uniqueFieldName'];
		let id = metric.getRecordIdField(featureSelected);
		if (metric.definition.source.type === ChannelTypes.History) {
			const historyFieldName = metric.definition.historyChannel.attributes['assetIdFieldName'];
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLocaleLowerCase().includes(historyFieldName.toLowerCase()));
			id = featureSelected.attributes[normalizedFieldName];
			historyQuery = historyFieldName + ' = ' + "'" + id + "'";
		} else {
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLowerCase().includes(assetFieldName.toLowerCase()));
			id = featureSelected.attributes[normalizedFieldName];
		}
		assetQuery = assetFieldName + ' = ' + "'" + id + "'";
		return { assetQuery, historyQuery };
	}

	private getHistoryFeature(metric: Metric, historyFeatureJSON, objectid) {
		const historyLayer = this.interopService.arcGISManager.getHistoryLayer(metric.definition.source.assetType);
		const objectIdField = historyLayer.objectIdField;
		const historyFeature = historyFeatureJSON.find(feature => feature.attributes[objectIdField] === objectid);
		return Feature.fromEsriFeature(historyFeature);
	}

}
