import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html'
})
export class DeleteDialogComponent {
	config: any;
	confirmMessage;
	cancelMessage = 'Cancel';
  	/**
	 * Inject dialog reference as dependency
	 * @param {MatDialogRef<DeleteDialogComponent>} dialogRef - A reference to the dialog instance.
	 */
	constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>) {}

	/**
	 * This method will close the dialog and trigger an event.
	 * @param {boolean} isConfirmed - Boolean value that indicates if user confirmed to delete tile
	 */
	onClose(isConfirmed: boolean) {
		this.dialogRef.close(isConfirmed);
	}
}
