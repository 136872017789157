<div class="scrolling-content">
	<mat-action-list>
		<div class="omni-list-item-wrapper" *ngFor="let trendStyle of trendStyles | keyvalue">
			<mat-list-item class="asset-list-item" (click)="onTrendStyleSelect(trendStyle)">
				<span style="flex:1">{{ trendStyle.value }}</span>
			</mat-list-item>
			<mat-divider class="divider-item"></mat-divider>
		</div>
	</mat-action-list>
</div>
