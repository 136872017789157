<div class="scrolling-content" #customFieldFilterWrapper>
	<app-drop-down
		*ngIf="true"
		mode="selection"
		[headerPlaceHolder]="'select a field'"
		[headerColor]="defaultSedaruGrey"
		[opened]="!selectedCustomField"
		[itemList]="fieldList"
		[selectedItem]="selectedFieldName"
		(itemSelected)="onFieldSelected($event)"
		[maxHeight]="maxHeightOfField"
	></app-drop-down>
	<ng-container *ngFor="let condition of customFieldFilter.conditions; index as i">
		<app-drop-down
			*ngIf="selectedCustomField"
			mode="selection"
			headerPlaceHolder="select an operator"
			[headerColor]="getBackgroundColor(i, 'operator')"
			[opened]="!condition.operator"
			[itemList]="operatorList"
			[selectedItem]="condition.operator"
			(itemSelected)="onOperatorSelected($event, condition)"
			[maxHeight]="maxHeightOfField"
		></app-drop-down>
		<app-drop-down
			*ngIf="!!condition.operator && valueEditingMode !== 'freeText' && !operatorIsNullOrBlank(condition.operator)"
			[mode]="valueEditingMode"
			[headerPlaceHolder]="headerForValuePanel(condition)"
			[headerColor]="getBackgroundColor(i, 'value')"
			[opened]="openValuePanel(condition)"
			[itemList]="valueList"
			[selectedItem]="condition.value"
			(itemSelected)="onValueChanged($event, condition)"
			[expansionDisabled]="disableExpansion"
			[maxHeight]="maxHeightOfField"
			[htmlInputCriteria]="getInputType()"
		></app-drop-down>
		<div
			*ngIf="!!condition.operator && valueEditingMode === 'freeText' && !operatorIsNullOrBlank(condition.operator)"
			class="input-box"
			[ngStyle]="{ 'background-color': getBackgroundColor(i, 'value') }"
		>
			<input [value]="condition.value?.code" (input)="onValueChanged($event.target.value, condition)" [placeholder]="headerForValuePanel(condition)" />
		</div>
		<app-drop-down
			*ngIf="!!condition.value?.code || operatorIsNullOrBlank(condition.operator)"
			mode="selection"
			headerPlaceHolder="select an item"
			[headerColor]="getBackgroundColor(i, 'join')"
			[opened]="!condition.join || i == customFieldFilter.conditions.length - 1"
			[itemList]="joinOperationList"
			[selectedItem]="condition.join"
			(itemSelected)="onJoinOperatorSelected($event, condition)"
			[maxHeight]="maxHeightOfField"
		></app-drop-down>
	</ng-container>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button mat-button class="omni-md-font" (click)="onDelete()" *ngIf="!isNewField"><img class="omni-sm-img" src="assets/delete.png" /> remove</button>
	</mat-card-actions>
</mat-card-footer>
