import { StandardWorkAssetContract, WorkAssetContract } from '../../contracts/work-order';
import { StandardWorkAsset } from './standard-work-asset.model';
import { WorkAssetWrapper } from './work-asset-wrapper.model';
import { WorkAsset } from './work-asset.model';

export class WorkAssetWrappers extends Array<WorkAssetWrapper> {
	static fromContracts(contracts: WorkAssetContract[] | StandardWorkAssetContract[]): WorkAssetWrappers {
		let model: WorkAsset | StandardWorkAsset;
		return WorkAssetWrappers.fromModels(
			contracts.map((c: WorkAssetContract | StandardWorkAssetContract) => {
				if (c instanceof WorkAssetContract) model = WorkAsset.fromContract(c, null, null);
				else if (c instanceof StandardWorkAssetContract) model = StandardWorkAsset.fromContract(c);
				return model;
			})
		);
	}

	static fromModels(models: (WorkAsset | StandardWorkAsset)[]): WorkAssetWrappers {
		const wrappersList = new WorkAssetWrappers();
		for (const model of models) wrappersList.push(new WorkAssetWrapper(model));
		return wrappersList;
	}

	getContracts = (): WorkAssetContract[] | StandardWorkAssetContract[] => this.map(wa => wa.getContract());

	getModels = (): (WorkAsset | StandardWorkAsset)[] => this.map(wa => wa.workAsset);

	isDirty = (): boolean => !!this.filter(wa => wa.isDirty).length;

	clearDirty = (): void => this.forEach(wa => wa.clearDirty());
}
