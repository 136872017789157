import { ListItemResolver } from '../list-item.resolver';
import { ListTemplates } from '../templates.enum';

export class LeftIconTwoLinesItemResolver extends ListItemResolver {
	getIconPath: (item: any) => Promise<string>;
	private _iconPath: string;
	private _iconPathRequested = false;

	getTopLabel: (item: any) => string;
	private _topLabel: string;
	private _topLabelRequested = false;

	getBottomLeftLabel: (item: any) => string;
	private _bottomLeftLabel: string;
	private _bottomLeftLabelRequested = false;

	getBottomRightLabel: (item: any) => string;
	private _bottomRightLabel: string;
	private _bottomRightLabelRequested = false;

	templateType = ListTemplates.leftIconTwoLines;

	_getIconPath(item: any) {
		if (!this._iconPath && !this._iconPathRequested) {
			this.getIconPath(item).then(url => {
				this._iconPath = url;
			});
			this._iconPathRequested = true;
		}
		return this._iconPath || 'assets/task-to-do.png';
	}

	_getTopLabel(item: any) {
		if (!this._topLabel && !this._topLabelRequested) {
			this._topLabel = this.getTopLabel(item);
			this._topLabelRequested = true;
		}
		return this._topLabel;
	}

	_getBottomLeftLabel(item: any) {
		if (!this._bottomLeftLabel && !this._bottomLeftLabelRequested) {
			this._bottomLeftLabel = this.getBottomLeftLabel(item);
			this._bottomLeftLabelRequested = true;
		}
		return this._bottomLeftLabel;
	}

	_getBottomRightLabel(item: any) {
		if (!this._bottomRightLabel && !this._bottomRightLabelRequested) {
			this._bottomRightLabel = this.getBottomRightLabel(item);
			this._bottomRightLabelRequested = true;
		}
		return this._bottomRightLabel;
	}

	resetFlags(): void {
		this._iconPath = this._topLabel = this._bottomLeftLabel = this._bottomRightLabel = null;
		this._iconPathRequested = this._topLabelRequested = this._bottomLeftLabelRequested = this._bottomRightLabelRequested = false;
	}
}
