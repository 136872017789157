import { SummaryFieldInputType } from '../../models/work-order/standard-custom-field.model';
import { ContractBase } from '../contract-base';
export class StandardCustomFieldContract extends ContractBase {
	public ObjectId: number;
	public fieldValue: any;
	public fieldName: string;
	public fieldDescription: string;
	public inputType: SummaryFieldInputType;
	public isVisible: boolean;
	public isRequired: boolean;
}
