import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { LayerResultModel } from '../layer-result.model';
import { SearchService } from '../search.service';
import { Subscription } from 'rxjs';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { Feature } from 'sedaru-util/esri-core';
import { UserService } from 'app/user/user.service';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { GeometryService } from 'app/canvas-container/canvas-map/geometry.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { MapGeomertyType } from 'app/canvas-container/canvas-map/geometry-type.model';
import { AssetRecord } from 'models/records/asset-record.model';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { HierarchyMenuPanelComponent } from 'app/workorder/hierarchy-menu-panel/hierarchy-menu-panel.component';
import { RecordContextGroup } from 'models/records/record-context-group';

import { AdvancedWorkOrder, StandardWorkOrder } from 'models/work-order';
import { Color } from 'models';
import { PNGManager } from 'app/ui-components/png-manager';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AWOModel } from 'models/work-order/awo-model';
import { Canvas } from 'omni-model/canvas.model';

@Component({
	selector: 'app-search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent extends MenuPanelBaseComponent implements OnInit {

	@ViewChild('scrollingContainer', { static: false }) scrollingContainer: ElementRef;

	searchQuery: string;

	layerResults: Array<any> = [];

	searchComplete: boolean;

	initialScroll;

	searchMessage: string;

	searchModeIdentifier: string;

	workOrderSearchResults: Array<AWOModel | StandardWorkOrder>;

	private searchUpdatedHandler: Subscription;

	private _viewLoaded = false;

	nextPage: number;

	get displayedRecordCount() {
		return this.searchService.displayedRecordCount;
	}

	scrollPosition: number;

	private initialRecordCount = 50;

	get totalNumberOfRecords() {
		return this.searchService.totalNumberOfRecords;
	}

	get isAdvanced() {
		return this.workOrderFactory.isAdvancedMode;
	}

	constructor(
		private searchService: SearchService,
		view: ElementRef<HTMLElement>,
		private userService: UserService,
		private mapService: CanvasMapService,
		private graphicsService: OmniMapGraphicsService,
		private interop: OmniInteropService,
		private esriSdkService: EsriSdkService,
		private workOrderFactory: WorkOrderFactory
	) {
		super(view);
		this.layerResults = [];
	}

	ngOnInit() {
		// if (this.config?.canvasList[0].mode === 'map') this.removePreviousWOLayer();
		this.config?.canvasList.forEach((canvas: Canvas) => {
			if (canvas.mode === 'map') {
				this.removePreviousWOLayer(canvas);
			}
		});
		/** initial values */
		this.menuPanelComponent.updateView({});
		this.menuPanelComponent.title  = 'searching';
		this.menuPanelComponent.updatePacifier(true);
		this.searchService.displayedRecordCount = this.initialRecordCount;
		this.workOrderSearchResults = this.searchService.workOrderSearchResults ||  [];
		this.searchModeIdentifier = this.searchService.selectedMode?.identifier;
		if (this.searchService.searchResults) this.layerResults = this.searchService.searchResults;
		else this.layerResults = [];
		this.searchComplete = this.searchService.searchComplete;
		this.menuPanelComponent.updateView({
			title: 'search results',
			subtitle: this.addSubTitle(),
			showPacifier: !this.searchComplete });
		this.searchMessage = this.getSearchMessage();
		/** watch for changes */
		this.searchUpdatedHandler = this.searchService.searchUpdated$.subscribe(() => {
			this.searchModeIdentifier = this.searchService.selectedMode?.identifier;
			this.searchQuery = this.searchService.searchQuery;
			this.layerResults = this.searchService.searchResults;
			this.workOrderSearchResults = this.workOrderFactory.isAdvancedMode
											? JSON.parse(JSON.stringify(this.searchService.workOrderSearchResults))
											: this.searchService.workOrderSearchResults;
			this.searchComplete = this.searchService.searchComplete;
			this.searchMessage = this.getSearchMessage();
			this.menuPanelComponent.updateView({ subtitle: this.addSubTitle(), showPacifier: !this.searchComplete });
		});

		this.initialScroll = this.searchService.scrollPosition;
		this.scrollToInitialScroll();
	}

	private addSubTitle() {
		if (this.searchModeIdentifier === 'address') return 'street addresses';
		if (this.searchModeIdentifier === 'workorder') return 'work orders';
		return undefined;
	}

	private removePreviousWOLayer(canvas: Canvas) {
		this.graphicsService.removeLayerFromMap(canvas, 'wo-search-result');
		this.interop.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip.close();
	}

	ngAfterViewInit(): void {
		this._viewLoaded = true;
	}

	ngOnDestroy() {
		if (this.searchUpdatedHandler) this.searchUpdatedHandler.unsubscribe();
	}

	async goToAssetAttributes(assetData, layer) {
		const asset = assetData.asset.feature;
		asset.layerId = assetData.asset.layerId;
		const assetType = assetData.assetType;

		const attributes = asset.attributes;
		const assetGeometry = asset.geometry;

		const canvas = this.config.selectedCanvas;
		let geo = this.graphicsService.convertSP(canvas, assetGeometry);
		if (!geo) geo = this.graphicsService.convertSP(canvas, asset?.esriFeature?.geometry);
		const graphic = this.mapService.generateGraphicByGeometry(geo, asset.objectId);
		this.mapService.removeAllGraphicFromGraphicLayer(canvas, 'highlight-assets');
		const highlightLayer: __esri.GraphicsLayer = this.mapService.getLayerByLayerId('highlight-assets', canvas.mapView.map);
		highlightLayer.opacity = 0.5;
		this.mapService.addGraphicsToGraphicLayer(highlightLayer, [graphic]);

		if (geo.type == MapGeomertyType.POINT) {
			await canvas.mapView.goTo({center: [geo.longitude, geo.latitude], scale: 1000}, { animate: true, duration: 1 });
		} else {
			await canvas.mapView.goTo(geo, { animate: true, duration: 1 });
		}
		const f = Feature.fromEsriFeature({ attributes, geometry: assetGeometry });
		const assetDefinition = this.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		const assetRecord = AssetRecord.create(assetDefinition, f);
		if (!assetDefinition) {
			NavigationService.navigateTo(Pages.mapAssetAttributes, { mode: 'asset selection', feature: f, assetType: assetRecord?.assetType });
			return;
		}

		let page = Pages.assetInformation;
		if (assetDefinition.useHierarchy) page = Pages.hierarchy;
		NavigationService.navigateTo(page, { recordContext: new RecordContextGroup({ assetRecord: assetRecord }) } );
		const context = new RecordContextGroup({ assetRecord: assetRecord });
		this.graphicsService.showMapToolTip([graphic][0], context)
	}

	getSearchMessage() {
		let msg = '';
		if (this.searchComplete) msg = this.searchService.searchQuery ? 'no results for ' + this.searchService.searchQuery : 'no results found';
		else msg = 'searching for ' + this.searchService.searchQuery;
		return msg;
	}

	private scrollToInitialScroll() {
		const waitForView = setInterval(() => {
			if (!this._viewLoaded) return;
			clearInterval(waitForView);
			this.scrollingContainer.nativeElement.scroll(0, this.initialScroll);
		}, 500);
	}

	onScroll() {
		this.searchService.scrollPosition = this.scrollingContainer.nativeElement.scrollTop;
		this.scrollPosition = this.searchService.scrollPosition;
	}

	handleClick(event: any): void {
		if (!event || !event.result || !event.result.geometry) return;
		const zoomFactor = this.interop?.configurationManager?.customerCodeConfiguration?.map?.zoomFactor;
		this.config?.selectedCanvas?.mapView?.goTo({ target: event.result.geometry, scale: zoomFactor ? zoomFactor : 1000 });
	}

	async workOrderClick(workOrderModel: AWOModel | StandardWorkOrder) {
		const isAWO = this.workOrderFactory.isAdvancedMode;
		let workOrder;
		if (isAWO) {
			workOrder = AdvancedWorkOrder.fromStub(workOrderModel);
		} else {
			// e9d55fbb-2020-487f-b30e-8a202a42c23b - SWO key
			const standardWO = workOrderModel as StandardWorkOrder;
			const detailedWorkorder = await this.workOrderFactory.getWorkOrders([standardWO.workOrderKey], false);
			workOrder = detailedWorkorder[0].workOrder;
		}

		const result = [workOrder];
		const canvas = this.config.selectedCanvas;
		const mockedIconUrl = ' assets/workorder-white.png'
		const backgroundColor: Color = Color.createFromRGBA('rgb(236, 137, 56)');
		const iconUrl = await PNGManager.editImage(mockedIconUrl, { backgroundColor });
		const layerId = 'wo-search-result';
		const outFields = ['workOrderKey', 'workType', 'startDate', 'completedDate', 'geometry', 'xCoordinate', 'yCoordinate', 'systemId', 'status', 'objectId', 'assignedTo', 'recurrenceTemplateId'];

		NavigationService.navigateTo(Pages.workorderOutline, { workOrder });

		if (canvas.mapView && isAWO) {
			this.plotAdvancedWorkOrderOnMap(canvas, layerId, result, iconUrl, outFields);
		}
		if (canvas.mapView && !isAWO) {
			this.plotStandardWorkOrderOnMap(canvas, layerId, result, iconUrl, outFields);
		}
	}

	async plotAdvancedWorkOrderOnMap(canvas, layerId, result, iconUrl, outFields) {
		await this.graphicsService.plotMetricResultsToMap(canvas, layerId, result, iconUrl, true, true, outFields);

		this.config.selectedCanvas.map.pacifier.show = true;
		this.zoomAndHighlightWorkOrder(result, layerId, canvas);
	}

	async plotStandardWorkOrderOnMap(canvas, layerId, result, iconUrl, outFields) {
		const features = [];
		const attributes = {};
		outFields.forEach(outfield => {
			if (outfield === 'geometry' || outfield === 'yCoordinate' || outfield === 'xCoordinate') return;
			attributes['key'] = result[0].workOrderKey;
			attributes['metricType'] = 'workorder';
			attributes[outfield] = result[0][outfield]
		})
		let geometry;
		if (result[0].xCoordinate !== 0 && result[0].yCoordinate !== 0) {
			geometry = await this.graphicsService.getGeomertyFromWO(result[0].xCoordinate, result[0].yCoordinate);
		} else {
			geometry = null;
		}
		features.push({ geometry, attributes });

		await this.graphicsService.addClientFeatureLayer(false, true, canvas, features, layerId, iconUrl);

		this.config.selectedCanvas.map.pacifier.show = true;
		this.zoomAndHighlightWorkOrder(result, layerId, canvas);
	}

	zoomAndHighlightWorkOrder(result, layerId, canvas) {
		const workOrder = result[0];
		const WorkOrderKey = result[0].workOrderKey;

		setTimeout(() => {
			this.graphicsService.highlightWorkOrder(WorkOrderKey, layerId, canvas).then(graphic => {
				if (!graphic) return;
				const context = new RecordContextGroup({ workOrderRecord: workOrder });
				canvas.mapView.goTo({ target: graphic, scale: 1000 }, { duration: 1000, easing: 'ease-out' });
				this.config.selectedCanvas.map.pacifier.show = false;
				this.showToolTip(graphic, context);
			});
		}, 500);
	}

	private showToolTip(graphic, context) {
		if (!graphic) return;
		if (!this.interop.uiManager.activeCanvasComponent.canvasMapComponent) return;

		const mapComponent = this.interop.uiManager.activeCanvasComponent.canvasMapComponent;
		mapComponent.mapTooltip.location = this.esriSdkService.esriMapSdk.Projection.project(graphic.geometry, this.config.selectedCanvas.mapView.spatialReference);
		mapComponent.mapTooltip.location = graphic.geometry;
		mapComponent.mapTooltip.resolver = this.interop.templateManager.getTemplateResolver(context);
		mapComponent.mapTooltip.open();
	}

	onScrollStepReached() {
		this.searchService.displayedRecordCount += this.initialRecordCount;
	}

	async onMoreClicked() {
		if (!this.isAdvanced) return;
		this.menuPanelComponent.updatePacifier(true);
		if (!this.searchService.currentPage) this.searchService.currentPage = 1;
		this.nextPage = this.searchService.currentPage + 1;
		this.initialScroll = this.searchService.scrollPosition;

		await this.searchService.onSearchWorkorder(this.searchQuery, this.nextPage, true);

		this.searchService.currentPage = this.nextPage;
		this.menuPanelComponent.updatePacifier(false);
		this.scrollToInitialScroll();
	}
}
