import { PageGroup, PageGroupName } from 'app/navigation/page-group';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { WorkOrderFilterFields, WorkOrderFilterField, CustomFieldFilter, CustomFieldFilters, WorkOrderMetaData } from 'models/work-order';
import { AdvancedWorkOrderFilterTemplate, WorkOrderFilterFieldKey, StandardWorkOrderFilterTemplate } from './workorder-filter-templates';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { Metric, MetricTypes } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { CalendarWidgetService } from 'app/ui-components/calendar-widget/calendar-widget.service';
import { CalendarTypes } from 'app/ui-components/calendar-widget/calendar-types.enum';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { AWOQuery, TaskFilter, WorkOrderFilter } from 'models/awo-query.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-workorder-filter',
	templateUrl: './workorder-filter.component.html',
	styleUrls: ['./workorder-filter.component.scss']
})
export class WorkOrderFilterComponent extends MenuPanelBaseComponent {
	@ViewChild('scrollingContainer', { static: true }) scrollingContainer: ElementRef;

	shouldShowMultiWorkTypesWarning = false;

	filterTemplate: Array<WorkOrderFilterField>;

	isAdvancedWorkOrder: boolean;

	metric: Metric;

	query: AWOQuery;

	currentFilter: WorkOrderFilter | TaskFilter;

	filterType: WorkOrderSourceType;

	private _scrollPosition: number;

	datesUpdatedSubscription: Subscription;

	filterFields: Array<{ name: string; value: any; key: string }>;

	pageGroup = PageGroup.get(PageGroupName.workorderFilter);

	constructor(private workOrderFactory: WorkOrderFactory, private flashMessageService: FlashMessageService, private calendarService: CalendarWidgetService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	get pageIdentifier() {
		return Pages.workOrderFilter;
	}

	get enablePriority(): boolean {
		if (this.metric.definition.workOrderChannel.isAdvancedWorkOrder && this.workOrderFactory.workOrderMetaData.priorities.length) return true;
		return false;
	}

	get enableCustomField(): boolean {
		if (this.filterType === WorkOrderSourceType.task) {
			const taskWorkType = (this.currentFilter as TaskFilter).taskkey;
			if (!taskWorkType) {
				this.shouldShowMultiWorkTypesWarning = false;
				return true;
			}
			if (taskWorkType.split(',').length > 1) {
				if (this.currentFilter.customFields.length) {
					this.currentFilter.customFields = new CustomFieldFilters();
					this.ngOnInit();
				}
				this.shouldShowMultiWorkTypesWarning = true;
				return false;
			}
			this.shouldShowMultiWorkTypesWarning = false;
			return true;
		}
		this.shouldShowMultiWorkTypesWarning = false;
		if (this.filterType === WorkOrderSourceType.workOrders) return true;
	}

	get customFieldsFilter() {
		return this.currentFilter.customFields;
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.query = args.parameter.query;
		this.metric = args.parameter.metric;
		this.filterType = args.parameter.filterType;
		this.currentFilter = args.parameter.currentFilter;
		if (!args.parameter.currentFilter) {
			switch (this.filterType) {
				case WorkOrderSourceType.task:
					this.currentFilter = new TaskFilter(this.query?.taskFilter);
					break;
				case WorkOrderSourceType.workOrders:
				default:
					this.currentFilter = new WorkOrderFilter(this.query?.workOrderFilter);
					break;
			}
		}
	}

	onPageNavigatingFrom(args: NavigationArgs) {
		return new Promise<boolean>(resolve => {
			args.parameter['query'] = this.query;
			resolve(true);
		});
	}

	isDirty(args: NavigationArgs) {
		return new Promise<boolean>(resolve => {
			if (args.isNavigatingBack) resolve(this.currentFilter.isDirty);
			else resolve(false);
		});
	}

	dirtyMessageTitle() {
		return 'WARNING';
	}

	dirtyMessageBody() {
		return 'Your changes have not been saved and will be lost if you proceed. Are you sure you want to continue?';
	}

	onPageReload() {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
	}

	ngOnInit() {
		this.filterFields = [];
		this.isAdvancedWorkOrder = this.metric.definition.workOrderChannel.isAdvancedWorkOrder;
		this.filterTemplate = AdvancedWorkOrderFilterTemplate.get(this.filterType);

		this.filterTemplate.forEach(field => {
			this.filterFields.push({ name: field.name, value: this.getFieldValues(field.key) || '', key: field.key });
		});

		if (this.currentFilter.customFields && this.currentFilter.customFields.length) {
			this.currentFilter.customFields.forEach(field => {
				this.filterFields.push({ name: field.name, value: this.getFilterCondition(field), key: field.customFieldId });
			});
		}
		this.menuPanelComponent.updateView({ title: this.getTitle(this.filterType) });
		if (this.uiState) this._scrollPosition = this.uiState.scrollPosition;
	}

	onScroll() {
		if (!this.uiState) this.uiState = {};
		this.uiState.scrollPosition = this.scrollingContainer.nativeElement.scrollTop;
	}

	ngAfterViewInit() {
		if (this._scrollPosition) this.scrollingContainer.nativeElement.scroll(0, this._scrollPosition);
	}

	getTitle(workOrderSourceType: WorkOrderSourceType): string {
		const prefix = 'filter';
		if (!workOrderSourceType) {
			return prefix;
		}
		switch (workOrderSourceType) {
			case WorkOrderSourceType.workOrders:
				return `${prefix} - work order`;
			case WorkOrderSourceType.workOrderAssets:
				return `${prefix} - assets`;
			case WorkOrderSourceType.task:
				return `${prefix} - tasks`;
			case WorkOrderSourceType.employees:
				return `${prefix} - employees`;
			case WorkOrderSourceType.equipment:
				return `${prefix} - equipment`;
			case WorkOrderSourceType.materials:
				return `${prefix} - material`;
			case WorkOrderSourceType.vendors:
				return `${prefix} - vendors`;
		}
	}

	createFilterTemplate() {
		const template = AdvancedWorkOrderFilterTemplate.get(this.filterType);
		return template;
	}

	onSave() {
		this.currentFilter.isDirty = false;
		switch (this.filterType) {
			case WorkOrderSourceType.task:
				this.query.taskFilter = new TaskFilter(this.currentFilter);
				break;

			case WorkOrderSourceType.workOrders:
				if (this.isWOWorkTypeChanged()) {
					this.query.taskFilter.taskkey = '';
				}
				this.query.workOrderFilter = new WorkOrderFilter(this.currentFilter);
				break;
			default:
				this.query.workOrderFilter = new WorkOrderFilter(this.currentFilter);
		}
		this.query.isDirty = true;
		this.menuPanelComponent.goBack();
	}

	/** Method to check whether the WO workType changed or not */
	private isWOWorkTypeChanged(): boolean {
		return this.query.workOrderFilter.worktypeid
			&& this.query.workOrderFilter.worktypeid !== (this.currentFilter as WorkOrderFilter).worktypeid
	}

	addCustomFieldFilter() {
		if (this.shouldShowMultiWorkTypesWarning) this.flashMessageService.popMessage(`Custom fields do not support multiple task types. Please select a single task type to configure custom fields.`);
		if (!this.enableCustomField) return;

		NavigationService.navigateTo(Pages.workOrderFilterCustomField, { metric: this.metric, currentFilter: this.currentFilter, workOrderFilter: this.filterFields });
	}

	getFilterCondition(customFieldFilter: CustomFieldFilter) {
		return customFieldFilter.name + ' ' + customFieldFilter.conditions.map(condition => condition.operator.name + ' ' + condition.value.name);
	}

	goTo(selectedFilterField) {
		const isCustomField = this.getCustomFilterByField(selectedFilterField);
		if (isCustomField) return this.goToCustomFieldFilter(isCustomField);
		return NavigationService.navigateTo(Pages.workOrderFilterSelection, { metric: this.metric, selectedFilterField, currentFilter: this.currentFilter, query: this.query });
	}

	private getCustomFilterByField(filterField: { name: string; key: string; value: any }) {
		if (!this.currentFilter.customFields?.length) return null;
		return this.currentFilter.customFields.find(customField => {
			return customField.customFieldId === filterField.key;
		});
	}

	goToCustomFieldFilter(customFieldFilter: CustomFieldFilter) {
		NavigationService.navigateBackTo(Pages.workOrderFilterCustomField, { metric: this.metric, customFieldFilter, currentFilter: this.currentFilter, workOrderFilter: this.filterFields });
	}

	getFieldValues(fieldKey) {
		if (!this.currentFilter[fieldKey]) return '';
		const splitted = this.currentFilter[fieldKey].split(',');
		switch (fieldKey) {
			case WorkOrderFilterFieldKey.WorkType:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.WorkTypeID:
				return this.workOrderFactory.workOrderMetaData.woTemplates
					.filter(woTemplate => splitted.includes(woTemplate.woTemplateId))
					.map(woTemplate => woTemplate.woTemplateName)
					.join(',');
			case WorkOrderFilterFieldKey.Status:
			case WorkOrderFilterFieldKey.TaskStatus:
				return this.workOrderFactory.workOrderMetaData.statuses
					.filter(status => splitted.includes(status.statusCode))
					.map(status => status.description)
					.join(',');
			case WorkOrderFilterFieldKey.AssignedTo:
			case WorkOrderFilterFieldKey.Owner:
				return this.workOrderFactory.workOrderMetaData.employees
					.filter(employee => splitted.includes(employee.employeeId))
					.map(employee => employee.name)
					.join(',');
			case WorkOrderFilterFieldKey.Priority:
				return this.workOrderFactory.workOrderMetaData.priorities
					.filter(priority => splitted.includes(priority.priorityCode))
					.map(priority => priority.description)
					.join(',');
			case WorkOrderFilterFieldKey.Team:
				return this.workOrderFactory.workOrderMetaData.workorderTeams
					.filter(team => splitted.includes(team.teamId))
					.map(team => team.teamName)
					.join(',');
			case WorkOrderFilterFieldKey.Task:
				return this.displayTaskValue();
			case WorkOrderFilterFieldKey.AssetType:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.FromDate:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.ToDate:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.StartDate:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.CompletedDate:
				return this.currentFilter[fieldKey];
			case WorkOrderFilterFieldKey.CostCategory:
				return this.currentFilter[fieldKey];
		}
	}

	private displayTaskValue() {
		const taskField = this.currentFilter[WorkOrderFilterFieldKey.Task];
		if (!taskField) return '';
		const ids = taskField.split(',') as any;
		const set = new Set<string>();
		for (const id of ids) {
			const task = this.workOrderFactory.workOrderMetaData.tasks.getByTaskId(id);
			if (!task?.description  || set.has(task.description)) continue;
			set.add(task.description);
		}
		return [...set.values()].join(',');
	}

	getBackgroundColor(index: number): string {
		if (index % 2) return 'dark-gray-background';
		return 'light-gray-background';
	}

	validateCustomFieldFilter(workOrderFilter: WorkOrderFilterFields, customFieldsFilter: CustomFieldFilters) {
		if (!customFieldsFilter.length) return;
		const workTypeFilter = workOrderFilter.find(field => field.key === WorkOrderFilterFieldKey.WorkTypeID);
		const taskWorkTypeFilter = workOrderFilter.find(field => field.key === WorkOrderFilterFieldKey.Task);
		if (workTypeFilter) {
			this.handleWorkOrderCustomField(workTypeFilter, customFieldsFilter);
		} else if (taskWorkTypeFilter) {
			this.handleTaskCustomField(taskWorkTypeFilter, customFieldsFilter);
		}
	}

	private handleTaskCustomField(taskWorkTypeFilter: WorkOrderFilterField, customFieldsFilter: CustomFieldFilters) {
		const { taskDefaultCustomFields } = this.workOrderFactory.workOrderMetaData;
		const splitted = taskWorkTypeFilter.value.split(',');
		if (!taskWorkTypeFilter.value || !splitted.length) {
			const map = new Map(taskDefaultCustomFields.map(field => [field.customFieldId, field]));
			const condition = (customFieldId: string) => !map.has(customFieldId);
			this.removeFilter(customFieldsFilter, condition);
		} else if (splitted.length > 1) {
			customFieldsFilter.length = 0;
		}
	}

	private handleWorkOrderCustomField(workTypeFilter: WorkOrderFilterField, customFieldsFilter: CustomFieldFilters) {
		const workTypeValues = workTypeFilter.value.split(',');
		const { templateDefaultCustomFields, woTemplates } = this.workOrderFactory.workOrderMetaData;
		let condition: (customFieldId: string) => boolean;
		if (!workTypeFilter.value || workTypeValues.length > 1) {
			const map = new Map(templateDefaultCustomFields.map(field => [field.customFieldId, field]));
			condition = (customFieldId: string) => !map.has(customFieldId);
		} else if (workTypeValues.length == 1) {
			const [workTypeId] = workTypeValues;
			const template = woTemplates.getByTemplateId(workTypeId);
			condition = (customFieldId: string) => !template.customFields.getByCustomFieldId(customFieldId);
		} else {
			return;
		}
		this.removeFilter(customFieldsFilter, condition);
	}

	private removeFilter(customFieldsFilter: CustomFieldFilters, condition: (customFieldId: string) => boolean) {
		for (let i = 0; i < customFieldsFilter.length; i++) {
			const customFieldFilter = customFieldsFilter[i];
			if (condition(customFieldFilter.customFieldId)) {
				customFieldsFilter.splice(i--, 1);
			}
		}
	}
}
