import { EquipmentCost } from './equipment-cost.model';
import { EquipmentCostContract, StandardEquipmentCostContract } from '../../contracts/work-order';
import { StandardEquipmentCost } from './standard-equipment-cost.model';

export class StandardEquipmentCosts extends Array<StandardEquipmentCost> {
	static fromContracts(equipmentCostContracts: StandardEquipmentCostContract[]) {
		const equipmentCosts = new StandardEquipmentCosts();
		for (const equipmentCostContract of equipmentCostContracts) {
			const equipmentCost = StandardEquipmentCost.fromContract(equipmentCostContract);
			equipmentCosts.push(equipmentCost);
		}
		return equipmentCosts;
	}

	static fromModels(equipmentCostModels: StandardEquipmentCost[]): StandardEquipmentCosts {
		const equipmentCosts = new StandardEquipmentCosts();
		for (const equipmentCost of equipmentCostModels) equipmentCosts.push(equipmentCost);
		return equipmentCosts;
	}

	getContracts = () => {
		const equipmentCostContracts = new Array<StandardEquipmentCostContract>();
		for (const equipmentCost of this) {
			const equipmentCostContract = equipmentCost.getContract();
			equipmentCostContracts.push(equipmentCostContract);
		}
		return equipmentCostContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;

	clearDirty = (): void => this.forEach(e => e.clearDirty());
}
