import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import { SearchTransform } from './search-transform';
import { ProxiesTransform } from './proxies-transform';
import { CredentialsTransform } from './credentials-transform';
import { ServicesTransform } from './services-transform';
import { AssetLayersTransform } from './asset-layers-transform';
import { WorkOrderChannelTransform } from './work-order-channel-transform';
import { StylesTransform } from './styles-transform';
import { ColorsTransform } from './colors-transform';
import { ResourcesTransform } from './resources-transform';
import { GlobalConfig } from '../../global-config.model';
import { TemplateTransform } from './template-transform';
import { MapTransform } from './map-transform';
import { FormatPropertiesTransform } from './format-properties-transform';

export class Transform extends TransformBase {
	private _globalConfiguration: GlobalConfig;
	get globalConfig(): GlobalConfig {
		return this._globalConfiguration;
	}

	constructor(customerCode: string, fieldforceConfig: any) {
		const globalConfiguration = GlobalConfig.create();
		globalConfiguration.customerCode = customerCode;
		super(fieldforceConfig, globalConfiguration);
		this._globalConfiguration = globalConfiguration;
	}

	onTransform(): TransformationResult {
		if (this.fieldforceConfig.Credentials) {
			const credentialsTransform = new CredentialsTransform(this.fieldforceConfig, this.globalConfig);
			credentialsTransform.transform();
		}

		if (this.fieldforceConfig.Proxies) {
			const proxyTransform = new ProxiesTransform(this.fieldforceConfig, this.globalConfig);
			proxyTransform.transform();
		}

		if (this.fieldforceConfig.Services) {
			const servicesTransform = new ServicesTransform(this.fieldforceConfig, this.globalConfig);
			servicesTransform.transform();
		}

		if (this.fieldforceConfig.Design && this.fieldforceConfig.Design.Templates) {
			const templatesTransform = new TemplateTransform(this.fieldforceConfig, this.globalConfig);
			templatesTransform.transform();
		}

		if (this.fieldforceConfig.AssetLayers) {
			const assetLayersTransform = new AssetLayersTransform(this.fieldforceConfig, this.globalConfig);
			assetLayersTransform.transform();
		}

		if (this.fieldforceConfig.Search) {
			const searchTransform = new SearchTransform(this.fieldforceConfig, this.globalConfig);
			searchTransform.transform();
		}

		if (this.fieldforceConfig.DataProviders && this.fieldforceConfig.DataProviders.WorkOrderProviders && this.fieldforceConfig.DataProviders.WorkOrderProviders.length > 0) {
			const workOrderTransform = new WorkOrderChannelTransform(this.fieldforceConfig, this.globalConfig);
			workOrderTransform.transform();
		}

		if (this.fieldforceConfig.Design && this.fieldforceConfig.Design.DesignProperties && this.fieldforceConfig.Design.DesignProperties.Styles) {
			const stylesTransform = new StylesTransform(this.fieldforceConfig, this.globalConfig);
			stylesTransform.transform();
		}

		if (this.fieldforceConfig.Design && this.fieldforceConfig.Design.DesignProperties && this.fieldforceConfig.Design.DesignProperties.Colors) {
			const colorsTransform = new ColorsTransform(this.fieldforceConfig, this.globalConfig);
			colorsTransform.transform();
		}

		if (this.fieldforceConfig.Design && this.fieldforceConfig.Design.DesignProperties && this.fieldforceConfig.Design.DesignProperties.FormatProperties) {
			const formatPropertiesTransform = new FormatPropertiesTransform(this.fieldforceConfig, this.globalConfig);
			formatPropertiesTransform.transform();
		}

		if (this.fieldforceConfig.Design && this.fieldforceConfig.Design.Resources) {
			const resourcesTransform = new ResourcesTransform(this.fieldforceConfig, this.globalConfig);
			resourcesTransform.transform();
		}

		if (this.fieldforceConfig.Map && this.fieldforceConfig.Map.DefaultExtent) {
			const mapTransform = new MapTransform(this.fieldforceConfig, this.globalConfig);
			mapTransform.transform();
		}

		return this.getResult(true);
	}
}
