export enum ValueFormatType {
	Unknown = 0,
	MakeLowerCase = 1,
	MakeUpperCase = 2,
	Currency = 3,
	WholeNumber = 4,
	FloatingPoint = 5,
	DateFormat = 6,
	TextFormat = 7,
}
