<div mat-dialog-container>
	<h1 mat-dialog-title>
		<b>{{ config.title }}</b>
	</h1>
	<div mat-dialog-content>
		{{ config.content }}
	</div>
	<div mat-dialog-actions>
		<span style="flex:1"></span>
		<button mat-raised-button color="warn" (click)="onClose(true)">
			{{ confirmMessage || 'Delete' }}
		</button>
		<button mat-raised-button (click)="onClose(false)">
			{{ cancelMessage }}
		</button>
	</div>
</div>
