export class WorkOrderFilterContract {
	assignedto?: string;
	workorderkey?: string;
	status?: string;
	worktypeid?: string;
	priority?: string;
	teamid?: string;
	fromdate?: string;
	todate?: string;
	basicdata?: boolean;
	iscontains?: boolean;
	includeRelatedWO?: boolean;
}
