<mat-nav-list class="omni-tab-panel">
	<app-bot-tab></app-bot-tab>
	<mat-divider class="tab-divider"></mat-divider>
	<div #tabListWrapper cdkDropList class="tab-wrapper" (cdkDropListDropped)="drop($event)" [cdkDropListDisabled] = "isWaiting">
		<div *ngFor="let tab of availableConfigs" cdkDrag cdkDragLockAxis="y">
			
			<div class="tab-panel-tab" matRipple [matRippleDisabled]="!tab.isEnabled" [ngClass]="{ 'selected-omni-tab': tab.isSelected, 'disabled-omni-tab': !tab.isEnabled }" (click)="onTabSelected(tab)" [@newTabAnimation]>
				<!-- <span class="omni-tab-text">{{ tab.name || 'new tab' }}</span> -->
				<div class="omni-tab-text">
					<app-text-in-max-lines [text]="tab.name || 'new tab'" [fontSize]="'16px'" [lineHeight]="'18px'" [maxLines]="2"></app-text-in-max-lines>
				</div>
				<div class="omni-tab-gear-icon" *ngIf="tab.isSelected && tab.hasPermission && tab.isEnabled">
					<a mat-icon-button (click)="goToTabSettings($event, tab.id)">
						<img class="tab-icon" src="assets/settings1.png" />
					</a>
				</div>
			</div>
			<mat-divider class="tab-divider"></mat-divider>
		</div>
		<div class="add-tab-wrapper">
			<button *ngIf="!addingNewTab" [@fadeInAndOutOnHover] mat-icon-button (click)="onNewTabButtonClick()" title="Create New OMNI Tab">
				<img src="assets/plus.png" />
			</button>
			<mat-spinner *ngIf="addingNewTab" diameter="40"></mat-spinner>
		</div>
	</div>
	<span class="omni-xs-font copyright"> (C) {{ year }} Sedaru, Inc. </span>
</mat-nav-list>
