import { Component, ElementRef, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { CalendarTypes } from './calendar-types.enum';
import { CalendarWidgetService } from './calendar-widget.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { PositionOffset } from './position-offset.model';
import { MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';

@Component({
	selector: 'app-calendar-widget',
	templateUrl: './calendar-widget.component.html',
	styleUrls: ['./calendar-widget.component.scss']
})
export class CalendarWidgetComponent implements OnInit, OnDestroy {
	calendarType: CalendarTypes = CalendarTypes.DatePicker;
	boundToElement: ElementRef;
	showCalendar = false;
	selected;
	positionOffset: PositionOffset;
	onDateSelected: (date: any) => void;
	private openCalendarSubscription: Subscription;

	@Output() dateSelected = new EventEmitter<any>();

	@ViewChild(MatStartDate, { static: false }) startInput: MatStartDate<Date>;
	@ViewChild(MatEndDate, { static: false }) endInput: MatEndDate<Date>;

	constructor(private calendarService: CalendarWidgetService) {}

	ngOnInit(): void {
		this.openCalendarSubscription = this.calendarService.openCalendarObservable.subscribe(() => {
			this.openCalendar();
		});
	}

	openCalendar() {
		this.selected = this.calendarService.currentRange ? this.calendarService.currentRange : { startDate: moment(), endDate: moment() };
		this.calendarType = this.calendarService.currentCalendarType;
		this.boundToElement = this.calendarService.currentBoundToElement;
		this.showCalendar = true;
		this.positionOffset = this.calendarService.positioOffset;
		if (this.calendarService.currentRange && this.calendarType !== CalendarTypes.DatePicker) {
			this.selected = Object.values(this.calendarService.currentRange).length ? this.calendarService.currentRange : { startDate: moment(), endDate: moment() };
		}
	}

	ngOnDestroy() {
		if (this.openCalendarSubscription) this.openCalendarSubscription.unsubscribe();
	}

	getPosition() {
		let x: number;
		let y: number;
		const rect = this.boundToElement.nativeElement.getBoundingClientRect();
		const windowHeight = window.innerHeight;
		y = rect.top;
		if (this.positionOffset && this.positionOffset.left) y += this.positionOffset.top;
		x = rect.left;
		if (this.positionOffset && this.positionOffset.top) x += this.positionOffset.left;
		if (windowHeight / 2 <= rect.top) {
			y = windowHeight - y;
			return { position: 'absolute', bottom: y + 'px', left: x + 'px' };
		}

		return { position: 'absolute', top: y + 'px', left: x + 'px' };
	}

	closeCalendar() {
		this.showCalendar = false;
	}

	onApply(selectedDateRange) {
		this.calendarService.oncalendarDatesUpdated(selectedDateRange);
		this.closeCalendar();
	}

	datePickerSelectedDate(date) {
		let selectedDate;
		if (this.calendarType === CalendarTypes.DateTimePicker)	selectedDate = date.startDate === null ? '' : moment(date.startDate).format('M/D/YYYY hh:mm A');
		else selectedDate = date;
		this.dateSelected.emit(selectedDate);
		if (this.onDateSelected) this.onDateSelected(selectedDate);
	}

	onDateRangePickerClosed() {
		if (!this.startInput?.value || !this.endInput?.value) return;
		this.datePickerSelectedDate({startDate: this.startInput.value, endDate: this.endInput.value});
	}
}
