import { SearchableItemBase, SearchableItem } from './searchable-item';

export abstract class SearchControllerBase {
	/**
	 * The array of items on which search is to be performed converted to SearchableItemBase type.
	 */
	private _fullList: Array<SearchableItemBase>;
	protected get fullList(): Array<SearchableItemBase> {
		if (!this._fullList) this._fullList = new Array<SearchableItemBase>();
		return this._fullList;
	}

	/**
	 * The function which holds the logic to match the search text with the array of search items.
	 */
	private _searchFunction: (item: SearchableItemBase, searchText: string) => boolean;

	constructor(searchFunction: (item: SearchableItemBase, searchText: string) => boolean) {
		this._searchFunction = searchFunction;
	}

	/**
	 * This methods loops through the provided array of items and returns a subarray of items that matched
	 * the searchText.
	 * @param {string} searchText - the text item which is to be matched against the provided array of
	 * items.
	 */
	search(searchText: string): Array<SearchableItemBase> {
		if (!searchText) return this.fullList;
		const results = new Array<SearchableItemBase>();
		this.fullList.forEach(item => {
			if (!this._searchFunction(item, searchText)) return;
			results.push(item);
		});
		return results;
	}
}

export class SearchController<T> extends SearchControllerBase {
	/**
	 * The array obtained from the GUI on which the search is to be performed.
	 */
	private _originalItems: Array<T>;
	public get originalItems(): Array<T> {
		if (!this._originalItems) {
			this._originalItems = [];
		}
		return this._originalItems;
	}

	constructor(fullList: Array<T>, searchFunction: (T, string) => boolean) {
		super((item, searchText) => {
			return searchFunction((item as SearchableItem<T>).originalItem, searchText);
		});

		this.reloadList(fullList);
	}

	reloadList(fullList: Array<T>) {
		this._originalItems = [];
		this.fullList.length = 0;

		this._originalItems = fullList;
		this._originalItems.forEach(item => {
			this.fullList.push(new SearchableItem<T>(item));
		});
	}

	/**
	 * This method converts an array of item of type SearchableItemBase to the type T.
	 * @param {Array<SearchableItemBase>} searchableItems - An array of elements of type
	 * SearchableItemBase.
	 */
	getOriginalItems(searchableItems: Array<SearchableItemBase>): Array<T> {
		const originalItems = new Array<T>();
		searchableItems.forEach(searchableItem => {
			originalItems.push((searchableItem as SearchableItem<T>).originalItem);
		});

		return originalItems;
	}
}
