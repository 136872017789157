import { Component, Input, OnInit, Output } from '@angular/core';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { EventEmitter } from '@angular/core';
import { Pattern, RecurrenceDefinition } from 'models/recurrence';

@Component({
  selector: 'app-monthly-pattern',
  templateUrl: './monthly-pattern.component.html',
  styleUrls: ['./monthly-pattern.component.scss']
})
export class MonthlyPatternComponent implements OnInit {
  fieldFullSize = MenuPanelFieldSize.full;
  fieldHalfSize = MenuPanelFieldSize.half;

  @Input() isReadOnly: boolean;

  @Input() recurrencePattern: RecurrenceDefinition;

  get pattern(): Pattern {
    return this.recurrencePattern?.pattern;
  }

  get monthName(): string {
    return this.recurrencePattern?.pattern?.yearlyPattern?.monthString;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
