import { ChannelAttributes } from './channel-attributes.model';

export class AssetChannelAttributes extends ChannelAttributes {
	/**
	 * Type of asset
	 */
	assetType: string;

	constructor() {
		super();
	}

	getIdFieldName(): string {
		return '';
	}
}
