import { MetricIcon } from 'models';
import { Component, Input, OnInit } from '@angular/core';

/**
 * This component wraps an SVG so that a generic SVG can be imported and a circle can be added as well as forground and
 * background colors.
 */
@Component({
	selector: 'app-metric-icon',
	templateUrl: './metric-icon.component.html'
})
export class MetricIconComponent implements OnInit {
	/**
	 * The SVG icon to be displayed
	 */
	@Input() icon: MetricIcon;

	/**
	 * Width of the icon  Set as a string since the unit can be included.  IE: "40px"
	 */
	@Input() width = '40px';

	/**
	 * Height of the icon.  Set as a string since the unit can be included.  IE: "40px"
	 */
	@Input() height = '40px';

	/**
	 * If this icon is selected, then it will listen for changes to it's colors
	 */
	@Input() isSelected = false;

	/**
	 *  The constructor doesn't do anything aside from loading and injecting dependencies.
	 */
	constructor() {}

	ngOnInit() {}
}
