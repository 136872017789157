import { WorkTaskContract } from '../../contracts/work-order';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { WorkTask } from './work-task.model';

export class TaskWrapper {
	private _task: WorkTask;
	get task(): WorkTask {
		return this._task;
	}

	constructor(task: WorkTask) {
		this._task = task;
	}

	get id(): string {
		return this._task.id;
	}
	set id(value: string) {
		this._task.id = value;
	}

	get assignedTo(): string {
		return this._task.assignedTo;
	}
	set assignedTo(value: string) {
		this._task.assignedTo = value;
	}

	get sequence(): number {
		return this._task.sequence;
	}
	set sequence(value: number) {
		this._task.sequence = value;
	}

	get description(): string {
		return this._task.description;
	}
	set description(value: string) {
		this._task.description = value;
	}

	get completedDate(): string {
		return this._task.completedDate;
	}
	set completedDate(value: string) {
		this._task.completedDate = value;
	}

	get status(): string {
		return this._task.status;
	}
	set status(value: string) {
		this._task.status = value;
	}

	get comments(): string {
		return this._task.comments;
	}
	set comments(value: string) {
		this._task.comments = value;
	}

	get taskKey(): string {
		return this._task.taskKey;
	}
	set taskKey(value: string) {
		this._task.taskKey = value;
	}

	get workOrderKey(): string {
		return this._task.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._task.workOrderKey = value;
	}

	get workTaskId(): string {
		return this._task.workTaskId;
	}
	set workTaskId(value: string) {
		this._task.workTaskId = value;
	}

	get changeStatus(): string {
		return this._task.changeStatus;
	}
	set changeStatus(value: string) {
		this._task.changeStatus = value;
	}

	get changeBy(): string {
		return this._task.changeBy;
	}
	set changeBy(value: string) {
		this._task.changeBy = value;
	}

	get systemId(): string {
		return this._task.systemId;
	}
	set systemId(value: string) {
		this._task.systemId = value;
	}

	get teamId(): string {
		return this._task.teamId;
	}
	set teamId(value: string) {
		this._task.teamId = value;
	}

	get extraFields(): any {
		return this._task.extraFields;
	}
	set extraFields(value: any) {
		this._task.extraFields = value;
	}

	get customFields(): CustomFieldWrappers {
		return this._task.customFields;
	}
	set customFields(value: CustomFieldWrappers) {
		this._task.customFields = value;
	}

	get isDirty(): boolean {
		if (this._task instanceof WorkTask) {
			return this._task.isDirty;
		}
		return;
	}

	clearDirty = (): void => {
		if (this._task instanceof WorkTask) this._task.clearDirty();
	}

	undoAll = (): boolean => {
		if (this._task instanceof WorkTask) return this._task.undoAll();
	}

	onUpdateInformation = (copyModel): boolean => {
		if (this._task instanceof WorkTask) return this._task.onUpdateInformation(copyModel);
	}

	updateInformation = (copyModel): boolean => {
		if (this._task instanceof WorkTask) return this._task.updateInformation(copyModel);
	}

	getContract = (): WorkTaskContract => {
		if (this._task instanceof WorkTask) return this._task.getContract();
	}
}
