<div class="scrolling-content" #scrollingContainer (scroll)="onScroll()" infiniteScroll (scrolled)="onScrollStepReached()" [scrollWindow]="false">
	<ng-container *ngFor="let item of displayItems | slice: 0:displayedRecordCount; let first = first;">
		<app-list-item
			[resolver]="getResolver(item.value)"
			[item]="item.value"
			(rightIconClicked)="rightIconClicked.emit($event)"
			(leftIconClicked)="leftIconClicked.emit($event)"
			(itemRightClicked)="itemRightClicked.emit($event)"
			(itemClicked)="onItemSelected($event)"
			(mouseenter)="itemHovered.emit(item.value)"
			(mouseleave)="mouseLeft.emit(item.value)"
			[isCurrentlySelected]="item.selected"
			[connector]="first ? false : connector"
			[disable]="item.disabled"
		>
		</app-list-item>
	</ng-container>
</div>
