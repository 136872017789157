export enum TimeframeType {
	/**
	 * Predefined timeframe types.
	 */
	Simple,

	/**
	 * Fixed period. Ex. Last Quarter.
	 */
	Period,

	/**
	 * Fixed period intervals. Ex. Last n Quarter.
	 */
	PeriodInterval,

	/**
	 * Timeframes where the interested time period is based
	 * on a single date. Ex. Before March 1 2022.
	 */
	SingleDate,

	/**
	 * Timesframes between a range. Should be inclusive???
	 */
	Range
}

