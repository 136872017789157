<mat-card class="omni-menu-panel" [class.phone]="mode === 'phone'" #menupanel>
	<!-- <app-header-footer #header></app-header-footer> -->

	<mat-card-title
		class="omni-menu-panel-title"
		[ngClass]="titleBackground"
		[ngStyle]="{
			'padding-top': calculateTitlePadding(),
			'padding-bottom': calculateTitlePadding(),
			background: getBackgroundColor()
		}"
	>
		<button mat-icon-button class="icon-button" matTooltip="back" (click)="goBack()" *ngIf="canGoBack()" [disabled]="disableBackButton">
			<img class="omni-md-img" src="assets/previous-circled.png" />
		</button>
		<div>{{ title }}</div>
		<div class="loading" *ngIf="loading">
			<span class="loading-1">&#8226;</span>
			<span class="loading-2">&#8226;</span>
			<span class="loading-3">&#8226;</span>
		</div>
		<div class="sub-title">{{ subtitle }}</div>
		<button
			matTooltip="{{ rightIcon.toolTip }}"
			[class.disabled]="disableRightButton"
			mat-icon-button
			class="right-button"
			(click)="!disableRightButton && rightIcon.callBackFunction()"
			*ngIf="rightIcon && rightIcon.url"
		>
			<img class="omni-md-img" [src]="rightIcon.url" />
		</button>
		<div class="badge" *ngIf="badgeNumber">{{ badgeNumber }}</div>
	</mat-card-title>

	<div class="omni-menu-panel-content">
		<app-router-outlet #navigationRouter (navigationHappening)="onNavigationHappening($event)" [config]="config" (navigationComplete)="onNavigationComplete($event)"></app-router-outlet>
		<div class="omni-logo" *ngIf="showOmniLogo()">
			<img src="assets/OMNIbot.png" />
			<label>Welcome to OMNI</label>
		</div>
		<div *ngIf="loading" class="disable-panel"></div>
		<app-toast #toast (toastDismissed)="toastDismissed()"></app-toast>
	</div>

	<!-- <app-header-footer #footer></app-header-footer> -->
</mat-card>
