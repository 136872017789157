/**
 * The configuration information that will be used when running the application in the staging environment.
 */
export const environment = {
	/**
	 * Name
	 */
	name: 'preview',

	/**
	 * Boolean value to check whether the environment is production or not
	 */
	production: true,

	/**
	 * OAuth url
	 */
	oauthUrl: 'https://auth.sedaru.com/v1.0/api/auth',

	/**
	 * The service endpoint for Preview eg- membership service
	 */
	infraApiUrl: 'https://servicesinfra2.sedaru.com/v4.3.4/',

	/**
	 * The URL for Preview OMNI API Server
	 */
	omniApiServer: 'https://proxy.sedaru.com/v3/proxy.ashx?https://omni-preview-api.sedaru.com:3000',

	/**
	 * The Proxy URL for Preview OMNI API Server
	 */
	omniProxyUrl: 'https://proxyinfra2.sedaru.com/v4/proxy.ashx?',
	/**
	 *  Advanced Work Order api base url
	 */
	awoBaseUrl: 'https://services.sedaru.com/smartconnect/v3.6.1',

	/**
	 *  Advanced Work Order api token
	 */
	token: 'x',
	/**
	 *  Smart uploader base url
	 */
	 smartUploaderBaseUrl: 'https://services.sedaru.com/smartuploader/v3.4',
	/**
	 *  Smart uploader url
	 */
	smartUploaderUrl: 'https://services.sedaru.com/smartuploader/v3.1/api/SmartUploader',

	/**
	 * Notifications/Subscriptions url
	 */
	subscriptionsUrl: 'https://notifications.sedaru.com/',

	/**
	 * Url to redirect to when invalid token is found
	 */
	loginPortalUrl: 'https://preview.sedaru.com',

	headlessMetricServiceHeaderHost: 'https://i387u5n9c7.execute-api.us-east-2.amazonaws.com/preview',

	alternateMetricServer: 'https://preview-sedaru-metrics.sedaru.com',

	headlessMetricToken: '2brlpH1BUcH-SwUk1',

	unifiedOmniApiServer: 'https://api-preview.sedaru.com',

	cookieSettings: {
		domain: '.sedaru.com',
		sameSite: ''
	}
};
