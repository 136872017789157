import { Injectable, ElementRef } from '@angular/core';

/**
 * This is used for mobile experience. When a user hits a tile we need to automatically scroll to the menu panel view.
 * When the user is in the menu panel and changes something of the map we need to scroll to the map view, when the user adds
 * a metric to the tile we need to scroll to the tile view, and so on.
 * The problem is that each component is isolated from the other components, so, if I tell the menu panel component to scroll
 * to the tile view, it will not know what to do because tile view is not part of menu panel
 * therefore, we need a service that registers what the tile view is, what the menu panel view is, etc.
 */
@Injectable({
	providedIn: 'root'
})
export class ScrollToViewService {
	/**
	 * This will hold the menu panel component.
	 */
	public menuPanel: ElementRef;
	/**
	 * This will hold the tile list component.
	 */
	public tileList: ElementRef;
	/**
	 * This will hold the canvas container component.
	 */
	public canvasContainer: ElementRef;
}
