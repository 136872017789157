import { Component, Input, OnInit } from '@angular/core';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { MetricTile, ValueFormatEnum, Color, TypeOfValue, TypeOfValueEnum } from 'models';
import { TimeframedMetricSubscriber } from 'models/time-frame/timeframed-metric-subscriber';
import { TimeframedMetric } from 'models/time-frame/timeframed-metric.model';
import { FingersDrummingResolver } from 'app/ui-components/pacifiers/fingers-drumming/fingers-drumming.resolver';
import { MetricScalar, MetricScalarStatus } from 'models/metric-scalar.model';

@Component({
	selector: 'app-tile',
	templateUrl: './tile.component.html',
	styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {
	@Input() tile: MetricTile;
	@Input() settingsEnabled: boolean;
	/** If the tile is added after load time, then it should be initialized */
	@Input() shouldInitialize: boolean;

	private lastChangedDate: Date;

	private _disabled = false;

	thisComponent = this;

	get timeframedMetric() {
		return this.metricSubscriber?.timeframedMetric;
	}

	hovered = false;

	showIndicator = false;

	private metricSubscriber: TimeframedMetricSubscriber;

	scalar: MetricScalar;

	get textOverride() {
		if (!this.scalar || this.scalar.scalarStatus !== MetricScalarStatus.TextOverride) return null;
		return this.scalar.scalarText
	}

	loading = true;

	pacifierResolver = new FingersDrummingResolver();

	private _displayValue = '--';
	get displayValue() {
		return this._displayValue;
	}

	private _showWarning = '';
	set showWarning(message: string) {
		if (message) {
			this._showWarning = message;
			setTimeout(() => {
				this._showWarning = '';
			}, 2000);
		}
	}
	get showWarning() {
		return this._showWarning;
	}

	get active() {
		return this.tile.isSelected;
	}

	get isTimeframeFieldSet() {
		return !!this.tile?.uiTimeframeFilter?.timeframeField;
	}

	onTileClick: (tileComponent: TileComponent) => Promise<boolean>;

	onTileClickWhenActive: (tileComponent: TileComponent) => void;

	onSettingsClick: (tileComponent: TileComponent) => void;

	onDateFrameClick: (tileComponent: TileComponent) => void;

	onTileDrag: (tileComponent: TileComponent) => void;

	onTileDrop: (tileComponent: TileComponent) => void;

	metricSubscriberChanged: (tileComponent: TileComponent) => void;

	constructor(private interop: OmniInteropService) { }

	ngOnInit(): void {
		this._disabled = false;
		this.metricSubscriber = this.interop.metricManager.getMetricSubscriber(this.tile.metric, this.tile.uiTimeframeFilter);
		this.metricSubscriber.onMetricUpdated = () => {
			this.onMetricUpdated();
		};
		if (this.metricSubscriber.timeframedMetric.scalar !== undefined) this.onMetricUpdated();
	}

	onSelect() {
		if (this._disabled) return;
		this._disabled = true;
		this.showIndicator = false;
		if (this.active) {
			this._disabled = false;
			return this.onTileClickWhenActive(this);
		}
		return this.onTileClick(this).then(() => {
			this._disabled = false;
		});
	}

	getTileTimeFrameFilter() {
		return this.tile.uiTimeframeFilter;
	}

	onTimeframeChanged() {
		this.loading = true;
		this.metricSubscriber.unsubscribe();
		this.interop.metricManager.removeTimeframedMetricsNotInUse();
		this.metricSubscriber = this.interop.metricManager.getMetricSubscriber(this.tile.metric, this.tile.uiTimeframeFilter);
		this.metricSubscriber.onMetricUpdated = () => {
			this.onMetricUpdated();
		};
		if (this.metricSubscriber.timeframedMetric.scalar) this.onMetricUpdated();
		this.metricSubscriberChanged(this);
	}

	onMetricDefinitionChanged(metricId: string) {
		if (this.timeframedMetric.metric.id !== metricId) return;
		this.onTimeframeChanged();
	}

	getTileTimeFrameText() {
		return this.getTileTimeFrameFilter()?.timeFrame?.text ?? '';
	}

	updateDisplayValue() {
		if (this.scalar?.scalarStatus !== MetricScalarStatus.Success) return;
		if (this.tile.metric.definition.displayValueSettings.typeOfValue.enumValue === TypeOfValueEnum.percetangeOfTotal) {
			this.formatThousandSeparatorAndDecimal(2);
			this._displayValue = this._displayValue + '%';
			return;
		}
		switch (this.tile.metric.definition.displayValueSettings.valueFormat.enumValue) {
			case ValueFormatEnum.decimalTenths:
				this.formatThousandSeparatorAndDecimal(1);
				break;
			case ValueFormatEnum.decimalHundredths:
				this.formatThousandSeparatorAndDecimal(2);
				break;
			case ValueFormatEnum.currency:
				this.formatThousandSeparatorAndDecimal(2, true);
				break;
			case ValueFormatEnum.none:
				if (Number.isInteger(this.scalar.scalarValue)) {
					this._displayValue = this.scalar.scalarValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				} else {
					this.formatThousandSeparatorAndDecimal(3);
				}
				break;
			case ValueFormatEnum.wholeNumber:
				const value = Math.round(this.scalar.scalarValue);
				this._displayValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				break;
			default:
				// default to no formatting but need to turn number into string
				this.formatThousandSeparatorAndDecimal(0);
				break;
		}
	}

	private formatThousandSeparatorAndDecimal(decimalPlaces: number, isCurrency: boolean = false): void {
		if (decimalPlaces) {
			const decimalized = this.scalar.scalarValue.toFixed(decimalPlaces);
			const splitByDecimal = decimalized.split('.');
			const withThousandSeparator = Number(splitByDecimal[0]).toLocaleString();
			this._displayValue = `${withThousandSeparator}.${splitByDecimal[1]}`;
			if (isCurrency) {
				this._displayValue = `$${this._displayValue}`;
			}
		} else {
			this._displayValue = this.scalar.scalarValue.toString().split('.')[0];
			this._displayValue = this._displayValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	}

	private onMetricUpdated() {
		if (this.lastChangedDate !== undefined && this.lastChangedDate !== this.tile.metric.lastTimeChanged) this.showIndicator = true;
		this.lastChangedDate = this.tile.metric.lastTimeChanged;
		this.scalar = this.metricSubscriber.timeframedMetric.scalar;
		this.tile.metric.lastTimeScalarUpdated = new Date();
		this.loading = false;
		this.updateDisplayValue();
	}
}
