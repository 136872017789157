import { FormControlDefinitions } from './form-control-definitions.model';
import { FormControlGroup } from './form-control-group-definition.model';
import { FormDefinition } from './form-definition.model';

export class FormPageDefinition {
	legacyId: number;
	index: number;

	constructor(parent: FormDefinition) {
		this._parent = parent;
	}

	private _parent: FormDefinition;
	public get parent(): FormDefinition {
		return this._parent;
	}

	private _footer: FormControlDefinitions;
	get footer(): FormControlDefinitions {
		if (!this._footer) {
			this._footer = new FormControlDefinitions();
		}

		return this._footer;
	}
	set footer(value: FormControlDefinitions) {
		this._footer = value;
	}

	private _controlGroups: Array<FormControlGroup>;
	get controlGroups(): Array<FormControlGroup> {
		if (!this._controlGroups) {
			this._controlGroups = new Array<FormControlGroup>();
		}

		return this._controlGroups;
	}
}
