<div class="omni-toggle-container">
	<mat-slide-toggle
		class="omni-toggle-button"
		[checked]="checked"
		(change)="toggled.emit($event)"
		[disabled]="disabled"
	></mat-slide-toggle>
	<div class="toggle-labels" (click)="toggle($event)">
		<span class="off">{{ textWhenUnchecked }}</span
		><span class="on">{{ textWhenChecked }}</span>
	</div>
</div>
