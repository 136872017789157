import { SedaruWorkOrderChannelAttributes } from './../../../../models/sedaru-work-order-channel-attributes.model';
import { WorkOrderSystem } from './../../../../models/sedaru-config/work-order-system.enum';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { WorkAsset, WorkAssetWrappers, WorkOrderSummary, WorkOrderWrapper, WorkOrderWrappers } from 'models/work-order';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { InfoFormComponent } from 'app/dynamic-forms/info-form/info-form.component';
import { FormResolver } from 'app/dynamic-forms/form-resolvers/dynamic-form.resolver';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { DataChannelService } from 'domain-service/data-channel.service';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NoIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-two-lines/no-icon-two-lines.component';
import * as SedaruModels from 'models';
import { UserService } from 'app/user/user.service';
import { AssetRecord } from 'models/records/asset-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { HistoryRecord } from 'models/records/history-record.model';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { Canvas } from 'omni-model/canvas.model';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { ThreeComponentNavsComponent } from 'app/ui-components/three-component-navs/three-component-navs.component';
import { WorkOrderOutlineComponent } from 'app/workorder/workorder-outline/workorder-outline.component';
import { HistoryRecordsFormComponent } from 'app/dynamic-forms/history-records-form/history-records-form.component';
import { MapAssetAttributesComponent } from '../map-asset-attributes/map-asset-attributes.component';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { Metric, ChannelTypes } from 'models';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { ArcGisFeature } from 'app/metric/arc-gis-feature.model';
import { Feature } from 'sedaru-util/esri-core/feature';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';

@Component({
	selector: 'app-asset-information',
	templateUrl: './asset-information.component.html',
	styleUrls: ['./asset-information.component.scss']
})
export class AssetInformationComponent extends MenuPanelBaseComponent {
	assetRecord: AssetRecord;
	hierarchyAssetRecord: HierarchyAssetRecord;
	formResolver: FormResolver;
	tabHeader2badge: string;
	tabHeader3badge: string;
	workOrders: Array<WorkOrderWrapper> = [];
	historyRecords: Array<any> = [];
	completedWorkOrders: Array<WorkOrderWrapper> = [];
	highlightParams: { objectid: number; canvas: Canvas; layerId: string; assetAttribute: string; historyAttribute: string };
	activeTab = 'information';
	context: RecordContextGroup;
	trendableRecordCount = 0;
	timer: NodeJS.Timer;
	isHierarchyRecord;
	isWorkAssetAdd: boolean;
	currentMetric: Metric;
	workOrder: WorkOrderWrapper;

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;


	_infoFormComponent: InfoFormComponent;

	@ViewChild(InfoFormComponent)
	set infoFormComponent(value: InfoFormComponent) {
		this._infoFormComponent = value;
		if (value) {
			this.setupTrendOnClick();
		}
	}
	get infoFormComponent() {
		return this._infoFormComponent;
	}
	@ViewChild(ThreeComponentNavsComponent) threeNavsComponent: ThreeComponentNavsComponent;

	get workOrderType(): string {
		if (this.userService.globalConfig.workOrderMode === SedaruModels.WorkOrderModeType.Advanced) {
			return 'advanced';
		} else if (this.userService.globalConfig.workOrderMode === SedaruModels.WorkOrderModeType.Standard) {
			return 'standard';
		}

		return 'none';
	}
	get currentAssetKey(): string {
		if (!this.assetRecord || !this.assetRecord.assetId) return '';

		let assetKey = String(this.assetRecord.assetId);

		if (!assetKey) return '';

		if (this.dataChannelService.getWorkOrderSystem() === WorkOrderSystem.Maximo) {
			const assetLocation = this.workOrderFactory.workOrderMetaData.assetLocationsWithAssets.getAssetLocationByGisId(assetKey);
			if (assetLocation) assetKey = assetLocation.activeAsset;
		}

		return assetKey;
	}

	get isAdvancedWorkOrder() {
		return this.workOrderFactory.isAdvancedMode;
	}

	constructor(
		view: ElementRef<HTMLElement>,
		private dataChannelService: DataChannelService,
		private userService: UserService,
		private workOrderFactory: WorkOrderFactory,
		private mapService: CanvasMapService,
		private interop: OmniInteropService,
		private graphicsService: OmniMapGraphicsService,
		private flashMessageService: FlashMessageService
	) {
		super(view);
	}

	ngAfterViewInit() {

	}

	async onPageNavigatedTo(args: NavigationArgs) {
		this.loadArgs(args);
	}

	onPageReload(args: NavigationArgs) {
		this.loadArgs(args);
	}


	private setupTrendOnClick() {
		if (!this.infoFormComponent) {
			return;
		}
		this.infoFormComponent.onTrendIconClick = trendDefinition => {
			this.onInfoFormTrendIconClicked(trendDefinition);
		};
	}

	private async loadArgs(args: NavigationArgs) {
		if (!args || !args.parameter) return;

		this.currentMetric = args.parameter.metric ?? this.graphicsService?.currentlySelectedMetric;
		if (args.parameter.incompleteRecord) {
			this.updateTitleView(args.parameter.incompleteRecord.assetId,
				args.parameter.incompleteRecord.assetDefinition.style.layout.background.color);
			}
		this.context = args.parameter.recordContext ?? (await this.getContext(args.parameter.incompleteRecord));
		this.highlightParams = args.parameter.highlightParameters;
		this.workOrder = args.parameter.workOrder;
		this.assetRecord = this.context?.assetRecord;
		this.isHierarchyRecord = this.context?.isHierarchyRecord;
		this.hierarchyAssetRecord = this.context?.hierarchyRecord;

		if (!this.workOrder) this.workOrder = this.workOrderFactory?.currentWorkOrder;

		const currentConfig = this.interop?.configurationManager?.guiConfig?.find(config => config.isSelected);
		if (currentConfig && currentConfig.selectedCanvas?.map) this.isWorkAssetAdd = currentConfig.selectedCanvas.map.eventHandlers['workAssetSelectionHandler'];
		const assetDefinition = this.isHierarchyRecord ? this.hierarchyAssetRecord.assetDefinition : this.assetRecord.assetDefinition;
		this. updateTitleView(this.isHierarchyRecord ? this.hierarchyAssetRecord?.assetId : (this.assetRecord?.assetId as string) ||
							  this.assetRecord?.objectId?.toString(), assetDefinition.style.layout.background.color);
		this.setActiveTab(args.previousPage);

		if (this.infoFormComponent) {
			this.infoFormComponent.updateResolver();
		}

		await this.fetchAndLoadWorkOrderForCurrentAsset();
		this.setHistoryTab();
	}

	private  updateTitleView(assetId, color) {
		this.menuPanelComponent.updateView({
			title: assetId,
			rightIcon: { url: 'assets/workorder-white.png', toolTip: '', callBackFunction: this.getCallBackFunction.bind(this) },
			backgroundColor: color,
			disableRightButton: this.isWorkAssetAdd
		});
	}

	private async getContext(incompleteRecord: any) {
		const context = new RecordContextGroup();
		const { assetQuery, historyQuery } = this.getLayerQueries(incompleteRecord.feature);
		const assetLayer = this.interop.arcGISManager.getMapAssetLayer(this.currentMetric.definition.source.assetType);
		const geometry = incompleteRecord.feature.geometry;
		const historyLayer = this.interop.arcGISManager.getHistoryLayer(this.currentMetric.definition.source.assetType);
		const assetFeatureJSON = assetLayer ? await assetLayer.query(assetQuery, '*', true) : null;
		const historyFeatureJSON = historyQuery ? await historyLayer.query(historyQuery, '*', true) : null;
		const assetDefinition = this.interop.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(this.currentMetric.definition.source.assetType);
		const assetFeature = assetFeatureJSON && assetFeatureJSON.length ? Feature.fromEsriFeature(assetFeatureJSON[0]) : null;
		if (assetFeature) assetFeature.geometry = geometry;
		const historyFeature = historyFeatureJSON && historyFeatureJSON.length ? Feature.fromEsriFeature(historyFeatureJSON[0]) : null;
		if (this.currentMetric.definition.source.type === ChannelTypes.History) context.historyRecord = HistoryRecord.create(assetDefinition, historyFeature);
		context.assetRecord = AssetRecord.create(assetDefinition, assetFeature);
		return context;
	}

	private getLayerQueries(featureSelected: ArcGisFeature): { assetQuery: string; historyQuery: string } {
		let assetQuery, historyQuery, id;
		const assetFieldName = this.currentMetric.definition.assetChannel.attributes['uniqueFieldName'];
		if (this.currentMetric.definition.source.type === ChannelTypes.History) {
			const historyFieldName = this.currentMetric.definition.historyChannel.attributes['assetIdFieldName'];
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLocaleLowerCase().includes(historyFieldName.toLowerCase()));
			id = featureSelected.attributes[normalizedFieldName];
			historyQuery = historyFieldName + ' = ' + "'" + id + "'";
		} else {
			const normalizedFieldName = Object.keys(featureSelected.attributes).find(k => k.toLowerCase().includes(assetFieldName));
			id = featureSelected.attributes[normalizedFieldName];
		}
		assetQuery = assetFieldName + ' = ' + "'" + id + "'";
		return { assetQuery, historyQuery };
	}

	private setActiveTab(previousPage: MenuPanelBaseComponent): void {
		if (!previousPage) return;

		let previousTab = 'information';
		switch (previousPage.pageIdentifier) {
			case Pages.workorderOutline:
				const outlinePage = previousPage as WorkOrderOutlineComponent;
				if (!outlinePage || !outlinePage.tab) break;
				previousTab = outlinePage.tab;
				break;
			case Pages.historyRecordsForm:
				const historyPage = previousPage as HistoryRecordsFormComponent;
				if (!historyPage || !historyPage.tab) break;
				previousTab = historyPage.tab;
				break;
			case Pages.mapAssetAttributes:
				const attributesPage = previousPage as MapAssetAttributesComponent;
				if (!attributesPage || !attributesPage.tab) break;
				previousTab = attributesPage.tab;
				break;
			default:
				break;
		}

		this.activeTab = previousTab;
	}

	private highlightAsset() {
		this.mapService.removeAllGraphicFromGraphicLayer(this.config.selectedCanvas, 'highlight-assets');
		this.mapService.highlightAssets(this.config.selectedCanvas, [this.assetRecord.feature]);
	}

	ngOnDestroy() {
		clearTimeout(this.timer);
	}

	async fetchAndLoadWorkOrderForCurrentAsset() {
		if (!this.isHierarchyRecord && (!this.currentAssetKey || !this.assetRecord)) return;
		if (this.isHierarchyRecord && (!this.hierarchyAssetRecord || !this.hierarchyAssetRecord.asset)) return;

		const assetType = this.isHierarchyRecord ? this.hierarchyAssetRecord.assetType : this.assetRecord.assetType;
		const assetKey = this.isHierarchyRecord ? this.hierarchyAssetRecord.assetId : this.currentAssetKey;

		this.workOrders = [];
		this.completedWorkOrders = [];

		const allWorkOrders = await this.workOrderFactory.getWorkOrdersByAsset(assetType, assetKey);
		if (!allWorkOrders || allWorkOrders.length === 0) {
			return;
		}

		this.filterWorkOrderForCurrentAsset(allWorkOrders);
	}

	async filterWorkOrderForCurrentAsset(workOrders: WorkOrderWrappers) {
		const workOrderChannel = this.dataChannelService.getWorkOrderChannel();
		if (!workOrderChannel) return;
		const { completedWorkOrderValues } = workOrderChannel.attributes as SedaruWorkOrderChannelAttributes;
		const activeWorkOrder = [];
		const completedWorkOrder = [];
		for (const workorder of workOrders) {
			if (completedWorkOrderValues.includes(workorder.status)) completedWorkOrder.push(workorder);
			else activeWorkOrder.push(workorder);
		}

		this.workOrders = activeWorkOrder;
		this.completedWorkOrders = completedWorkOrder;
	}

	async setHistoryTab() {
		switch (this.workOrderType) {
			case 'advanced':
				await this.setHistoryTabForAdvancedWorkOrder(this.isHierarchyRecord ? this.hierarchyAssetRecord.assetId : (this.assetRecord.assetId as string));
				break;
			case 'standard':
				await this.setHistoryTabForStandarWorkOrder(this.assetRecord.assetId as string);
				break;
			case 'none':
				break;
		}
	}

	async setHistoryTabForAdvancedWorkOrder(facilityid: string) {
		this.tabHeader3badge = this.completedWorkOrders && this.completedWorkOrders.length > 0 ? this.completedWorkOrders.length.toString() : '';
		this.trendableRecordCount = this.completedWorkOrders ? this.completedWorkOrders.length : 0;
	}
	/**
	 * This function will set selected workOrder
	 * @param {AdvancedWorkOrder} workOrder - The selected workOrder
	 */
	workorderSelected(workOrder: WorkOrderWrapper) {
		if (this.isWorkAssetAdd || !workOrder) return;
		NavigationService.navigateTo(Pages.workorderOutline, { workOrder: workOrder, advancedMode: this.isAdvancedWorkOrder, tab: this.threeNavsComponent.currentTabHeader });
	}

	async setHistoryTabForStandarWorkOrder(facilityid: string) {
		this.menuPanelComponent.updatePacifier(true);
		this.historyRecords = await this.interop.arcGISManager.getHistoryRecords(this.assetRecord);

		this.tabHeader3badge = this.historyRecords.length ? this.historyRecords.length.toString() : '';
	    this.trendableRecordCount = this.historyRecords.length ? this.historyRecords.length : 0;
		const resolver = NoIconTwoLinesComponent.createResolver();
		resolver.getTopLabel = item => {
			return DateUtil.convertLocaleUTCString(item.workDate);
		};
		resolver.getBottomLeftLabel = item => {
			return (item as HistoryRecord).workType;
		};
		resolver.getBottomRightLabel = item => {
			return (item as HistoryRecord).workBy;
		};
		this.listComponent.getResolver = item => {
			return resolver;
		};
		this.menuPanelComponent.updatePacifier(false);
	}

	getCallBackFunction = async () => {
		let newWorkOrder: WorkOrderWrapper;
		this.menuPanelComponent.updatePacifier(true);
		try {
			const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey();
			if (!workOrderKeys) {
				throw new Error();
			}
			if (!this.isHierarchyRecord) newWorkOrder = this.workOrderFactory.createWorkOrderModel(workOrderKeys[0], this.assetRecord);
			else newWorkOrder = this.workOrderFactory.createWorkOrderModel(workOrderKeys[0], this.hierarchyAssetRecord);
			this.graphicsService.startWorkOrderCreation(this.config.selectedCanvas, this.assetRecord);
			NavigationService.navigateTo(Pages.workorderSummary, { workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.CreateSingleWO, [newWorkOrder]) });
		} catch (e) {
			this.flashMessageService.popMessage('Cannot create workorder. No valid workorder key. Please try again.');
		} finally {
			this.menuPanelComponent.updatePacifier(false);
		}
	};

	historyRecordSelected(historyRecord: HistoryRecord) {
		if (this.isWorkAssetAdd) return;
		const forms = historyRecord?.assetDefinition?.forms ?? this.assetRecord.assetDefinition?.forms;
		const historyFormDefinition = forms.find(form => {
			return form.workType.toLowerCase() === historyRecord.workType.toLowerCase() || form.title.toLowerCase() === historyRecord.workType.toLowerCase();
		});
		if (historyFormDefinition) {
			this.context.historyRecord = historyRecord;
			NavigationService.navigateTo(Pages.historyRecordsForm, {
				context: this.context,
				historyFormDefinition,
				trendableRecordCount: this.trendableRecordCount,
				tab: this.threeNavsComponent.currentTabHeader
			});
		} else {
			this.mapService.selectedIdentifyAsset = this.assetRecord.feature;
			NavigationService.navigateTo(Pages.mapAssetAttributes, { feature: this.assetRecord.feature, tab: this.threeNavsComponent.currentTabHeader });
		}
	}

	/** Method to revise the work assets after a save attempt */
	private reviseUnSavedWorkAssets(workorder: WorkOrderWrapper): WorkAssetWrappers {
		const indexToRemove = [];
		const validAssets = new WorkAssetWrappers();
		workorder.workAssets.forEach((workAsset, index) => {
			if (workAsset.id !== -1) {
				validAssets.push(workAsset);
				return;
			}
			indexToRemove.push(index);
		});

		if (indexToRemove.length > 0) {
			indexToRemove.forEach((removableIndex, i) => {
				workorder.workAssets.splice(removableIndex - i, 1);
			});
			// notify to user
			this.flashMessageService.popMessage('Not all work assets saved, try again', SnackbarMessageTypes.WARNING);
		}
		this.workOrder.workAssets = validAssets;
		return validAssets;
	}

	async apply(): Promise<void> {
		try {
			if (!this.workOrder) return;

			this.menuPanelComponent.updatePacifier(true);
			const assetDefinition = this.context?.assetDefinition;
			if (!assetDefinition) return;
			this.isHierarchyRecord ? this.workOrderFactory.createWorkAssetFromHierarchyRecordModel(this.workOrder, this.context?.hierarchyRecord)
				: this.workOrderFactory.createWorkAssetModel(this.workOrder, this.context?.assetRecord);
			await this.workOrderFactory.updateWorkAsset(this.workOrder, null);
			const savedWorkAssets = this.reviseUnSavedWorkAssets(this.workOrder);
			const newAssetGraphics = this.mapService.getWorkAssetPointGraphic(savedWorkAssets, true);
			newAssetGraphics.forEach(graphic => this.workOrder.workAssetGraphics.push(graphic));
			if (this.currentMetric) {
				const workOrderGraphicLayer = this.mapService.getLayerByLayerId(this.currentMetric.id + '_work_asset', this.config.selectedCanvas.mapView.map);
				workOrderGraphicLayer.addMany(newAssetGraphics);
				this.graphicsService.updateWorkOrderGraphic(this.currentMetric, this.workOrder, this.config.selectedCanvas);
			}

			const eventHandlers = this.config?.selectedCanvas?.map?.eventHandlers;
			if (!eventHandlers) return;
			if (eventHandlers['workAssetSelectionHandler']) {
				eventHandlers['workAssetSelectionHandler'].remove();
				delete eventHandlers['workAssetSelectionHandler'];
			}

			this.graphicsService.identifyTaskOnMapView(this.config.selectedCanvas);
		} finally {
			this.menuPanelComponent.updatePacifier(false);
			NavigationService.navigateBackTo(Pages.workorderAssets, { workOrder: this.workOrder, advancedMode: true });
		}
	}
}
