import * as Subscriptions from '../../../sedaru-util/sedaru-subscriptions-library';
import * as SedaruUtils from '../../../sedaru-util';

export class TabChangedResult extends Subscriptions.MethodHandlerResult<TabChangedHandler> {
	tabId: string;
	changeType: 'scope' | 'name' | 'canvas-layout' | 'tile-layout' | 'theme' | 'content' | 'delete';
	newValue: string;
	constructor(handler: TabChangedHandler) {
		super(handler);
	}
}

export class TabChangedHandler extends Subscriptions.SedaruClientMethodHandlerBase {
	private _onTabChanged: SedaruUtils.InvokableEvent;
	public get onTabChanged(): SedaruUtils.InvokableEvent {
		if (!this._onTabChanged) this._onTabChanged = new SedaruUtils.InvokableEvent();

		return this._onTabChanged;
	}

	shouldHandleMethod(methodIdentifier: string): boolean {
		return methodIdentifier.toLowerCase() === 'omni.tabchanged';
	}

	onHandleMethod(packet: Subscriptions.NotificationPacket, args: Subscriptions.MethodHandlerArgs): Promise<Subscriptions.MethodHandlerResultBase> {
		return new Promise<Subscriptions.MethodHandlerResultBase>(resolve => {
			const data = JSON.parse(packet.data);
			const result = new TabChangedResult(this);
			result.success = true;
			result.message = 'tab has changed';
			result.tabId = data.tabId;
			result.changeType = data.changeType;
			result.newValue = data.newValue;
			args.isHandled = true;
			this.onTabChanged.invoke(this, result);
			resolve(result);
			return;
		});
	}

	onGetUniqueId(): string {
		return 'TabChangedHandler'; // do not user Class.name because it doesn't work with minified code.
	}

	sendTabNotification(tabId: string, changeType: 'scope' | 'name' | 'canvas-layout' | 'tile-layout' | 'theme' | 'content' | 'delete' | string[], newValue: any) {
		const payload = JSON.stringify({ tabId, changeType, newValue });
		this.hub.sendNotification('omni.tabchanged', payload, this.hub.clientConnection.customerCode);
	}
}
