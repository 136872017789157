import { LinkedList } from './linkedlist';

export abstract class UndoableBase {
	abstract get hasUndoHistory(): boolean;
	abstract get isDirty(): boolean;
	abstract get count(): number;
	abstract undo(): boolean;
	abstract undoAll(): boolean;
	abstract clearUndoHistory();
}

export class Undoable<T> extends UndoableBase {
	private _linkedList = new LinkedList<T>();

	private _initialValue: T;
	get initialValue(): T {
		return this._initialValue;
	}

	static empty<U>(): Undoable<U> {
		return new Undoable<U>();
	}

	constructor(value?: T) {
		super();
		this._initialValue = value;
	}

	get value(): T {
		if (this.hasUndoHistory) return this._linkedList.last.value;

		return this.initialValue;
	}
	set value(val: T) {
		this.set(val);
	}

	set(value: T): boolean {
		if (this.hasUndoHistory && this._linkedList.last.value === value) return false;

		this._linkedList.append(value);
		return true;
	}

	get hasUndoHistory(): boolean {
		return this._linkedList.count > 0;
	}
	get isDirty(): boolean {
		if (this.value === this.initialValue) return false;

		return true;
	}
	get count(): number {
		return this._linkedList.count;
	}
	undo(): boolean {
		if (this._linkedList.count === 0) return false;

		this._linkedList.removeLast();
		return true;
	}
	undoAll(): boolean {
		let wasUndone = false;
		while (this.isDirty) {
			wasUndone = this.undo() || wasUndone;
		}
		this.clearUndoHistory();
		return wasUndone;
	}
	clearUndoHistory() {
		if (!this.isDirty) return;

		if (this._linkedList.last != null) this._initialValue = this._linkedList.last.value;
		this._linkedList.clear();
	}
}
