import { Component, ElementRef } from '@angular/core';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { GuiConfig } from '../../../omni-model/gui-config.model';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { CanvasMap } from '../../../omni-model/canvas-map.model';
import { Metric } from 'models';
import { Canvas } from 'omni-model/canvas.model';
import { MetricRequesterService } from 'app/metric/metric-requester.service';
import { MenuPanelBaseComponent } from '../menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';

/**
 * This component is designed to be placed in the right side panel.
 * The component is loaded when a map is selected fromt the map list panel.
 * The component allows you to associate a default metric for the selected map, change the map type which could be selected from
 * a list of available types (eg., water map, sewer map etc. ) and to set the base map type for the selected map from the list
 * of available ESRI base maps (eg., imagery, streets, topographic etc.)
 */
@Component({
	selector: 'app-map-settings',
	templateUrl: './map-settings.component.html',
	styleUrls: ['./map-settings.component.scss']
})
export class MapSettingsComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the ESRI base map type of the currently selected map.
	 */
	baseMapLabel: String;
	/**
	 * Holds the omni map object.
	 */
	canvasMap: CanvasMap;

	canvas: Canvas;

	defaultMetric: Metric;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {CanvasMapService} mapService - Provides services to manage changes in the ESRI map added  to the canvas.
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(private mapService: CanvasMapService, private flashMessageService: FlashMessageService, private metricRequesterService: MetricRequesterService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * On init, the currently selected canvas on which the map will be loaded is initialized along with the map type and
	 * the base map type. The current route is also emitted.
	 */
	ngOnInit() {
		this.setMapSettings(this.config);
		this.menuPanelComponent.updateView({ title: 'map settings' });
	}

	onPageReload(args: NavigationArgs) {
		this.setMapSettings(this.config);
	}
	/**
	 * Sets the properties of the currently selected map.
	 * @param {GuiConfig} selectedConfig Config of the currently selected tab.
	 */
	setMapSettings(selectedConfig: GuiConfig) {
		this.canvas = selectedConfig.selectedCanvas;
		if (!this.canvas) return;

		if (!this.canvas.tabs) this.canvas.tabs = [];
		const defaultMetricTab = this.canvas.tabs.find(tab => tab.defaultMetric !== null && tab.defaultMetric !== undefined);
		this.defaultMetric = defaultMetricTab ? defaultMetricTab.defaultMetric : null;
		this.canvasMap = selectedConfig.selectedCanvas && selectedConfig.selectedCanvas.map ? selectedConfig.selectedCanvas.map : null;
		if (this.canvasMap) {
			const baseMapItem = this.mapService.baseMapESRIList().find(baseMap => {
				return baseMap.baseMapIdentifier === this.canvasMap.baseMapId;
			});
			this.baseMapLabel = baseMapItem ? baseMapItem.baseMapLabel : '';
		}
	}

	private isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	}

	/**
	 * The method will be called when clicking the next button on default metric field.
	 */
	onGotoDefaultMetric() {
		if (this.canvasMap) {
			this.metricRequesterService.setRequester('Canvas', this.canvas);
			NavigationService.navigateTo(Pages.metrics, {showRemoveOption: !!this.defaultMetric, disableRemoveOption: !!this.defaultMetric, currentlySelectedMetric: this.defaultMetric});
		} else {
			this.flashMessageService.popMessage('Please select a map to proceed.');
		}
	}

	/**
	 * The method will be called when clicking the next button on basemap field.
	 */
	onGotoBaseMap() {
		if (this.canvasMap) {
			NavigationService.navigateTo(Pages.baseMapList);
		} else {
			this.flashMessageService.popMessage('Please select a map to proceed.');
		}
	}

	goTo(address: string) {
		switch (address) {
			case 'mapList':
				NavigationService.navigateTo(Pages.mapList, {selectedItem: this.canvasMap.name});
				break;
			case 'defaultMetric':
				this.onGotoDefaultMetric();
				break;
			case 'baseMapList':
				this.onGotoBaseMap();
				break;
		}
	}
}
