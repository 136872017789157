<div #tiles>
	<mat-grid-list [cols]="cols" [rowHeight]="rowHeight" [gutterSize]="gutterSize" [ngStyle]="{ margin: tileListMargin }">
		<mat-grid-tile
			*ngFor="let tile of tileList | keyvalue: sort"
			[class.omni-black-border]="tile.value.metric"
			(mouseenter)="tile.value.isHovered = true"
			(mouseleave)="tile.value.isHovered = false"
			(click)="tile.value.isHovered = true"
			class="grid-tile"
		>
			<div [ngStyle]="getTileBorder(tile.value)" class="omni-tile-wrapper">
				<!-- If it is an empty tile-->
				<button
					class="add-button"
					mat-icon-button
					*ngIf="!tile.value.metric && tile.value.isHovered && activeGuiConfig.hasPermission"
					[@fadeInAndOutOnHover]
					(click)="onGoToMetricList(tile.value)"
				>
					<img src="assets/plus.png" />
				</button>
				<!-- if it is a tile with content-->
				<ng-container *ngIf="tile.value.metric">
					<app-tile [shouldInitialize]="initialized" [tile]="tile.value" [settingsEnabled]="activeGuiConfig.hasPermission"></app-tile>
				</ng-container>
			</div>
		</mat-grid-tile>
	</mat-grid-list>
</div>
