import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { RecurrenceDefinition } from './recurrence-definition.model';
import { AssociatedAssets } from './associated-assets.model';
import { WorkAssetWrapper, WorkAssetWrappers, WorkOrderSummary } from '../work-order';
import { AssociatedAsset } from './associated-asset.model';
import { StatusType } from './status-type.enum';

export class RecurrenceTemplate extends OmniModel<RecurrenceTemplate, Contracts.RecurrenceTemplateContract> {
    private _id: SedaruUtils.Undoable<string>;
    get id(): string {
        if (!this._id) this._id = new SedaruUtils.Undoable<string>();
        return this._id.value;
    }
    set id(value: string) {
        if (!this._id) {
            this._id = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._id.value = value;
    }

    private _name: SedaruUtils.Undoable<string>;
    get name(): string {
        if (!this._name) this._name = new SedaruUtils.Undoable<string>();
        return this._name.value;
    }
    set name(value: string) {
        if (!this._name) {
            this._name = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._name.value = value;
    }

    private _workType: SedaruUtils.Undoable<string>;
    get workType(): string {
        if (!this._workType) this._workType = new SedaruUtils.Undoable<string>();
        return this._workType.value;
    }
    set workType(value: string) {
        if (!this._workType) {
            this._workType = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._workType.value = value;
    }

    private _status: SedaruUtils.Undoable<string>;
    get status(): string {
        if (!this._status) this._status = new SedaruUtils.Undoable<string>();
        return this._status.value;
    }
    set status(value: string) {
        if (!this._status) {
            this._status = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._status.value = value;
    }

    private _assignedTo: SedaruUtils.Undoable<string>;
    get assignedTo(): string {
        if (!this._assignedTo) this._assignedTo = new SedaruUtils.Undoable<string>();
        return this._assignedTo.value;
    }
    set assignedTo(value: string) {
        if (!this._assignedTo) {
            this._assignedTo = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._assignedTo.value = value;
    }

    private _team: SedaruUtils.Undoable<string>;
    get team(): string {
        if (!this._team) this._team = new SedaruUtils.Undoable<string>();
        return this._team.value;
    }
    set team(value: string) {
        if (!this._team) {
            this._team = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._team.value = value;
    }

    private _owner: SedaruUtils.Undoable<string>;
    get owner(): string {
        if (!this._owner) this._owner = new SedaruUtils.Undoable<string>();
        return this._owner.value;
    }
    set owner(value: string) {
        if (!this._owner) {
            this._owner = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._owner.value = value;
    }

    private _priority: SedaruUtils.Undoable<number | string>;
    get priority(): number | string {
        if (!this._priority) this._priority = new SedaruUtils.Undoable<number | string>();
        return this._priority.value;
    }
    set priority(value: number | string) {
        if (!this._priority) {
            this._priority = new SedaruUtils.Undoable<number | string>(value);
            return;
        }
        this._priority.value = value;
    }

    private _recurrencePattern: RecurrenceDefinition;
    get recurrencePattern(): RecurrenceDefinition {
        return this._recurrencePattern ? this._recurrencePattern : (this._recurrencePattern = new RecurrenceDefinition());
    }
    set recurrencePattern(value: RecurrenceDefinition) {
        if (this._recurrencePattern === value) return;
        this._recurrencePattern = value;
    }

    private _associatedAssets: AssociatedAssets;
    get associatedAssets(): AssociatedAssets {
        return this._associatedAssets ? this._associatedAssets : (this._associatedAssets = new AssociatedAssets());
    }
    set associatedAssets(value: AssociatedAssets) {
        if (this._associatedAssets === value) return;
        this._associatedAssets = value;
    }

    private _workAssets: WorkAssetWrappers;
    get workAssets(): WorkAssetWrappers {
        return this._workAssets ? this._workAssets : (this._workAssets = new WorkAssetWrappers());
    }
    set workAssets(value: WorkAssetWrappers) {
        if (this._workAssets === value) return;
        this._workAssets = value;
    }

    get assetType(): string {
        return this.associatedAssets[0]?.assetType;
    }

    get isReadOnly(): boolean {
        return this.recurrencePattern?.status === StatusType.Disabled || this.recurrencePattern?.status === StatusType.Finished;
    }

    static fromContract(contract: Contracts.RecurrenceTemplateContract): RecurrenceTemplate {
        const model = new RecurrenceTemplate();
        model.id = contract.id;
        model.name = contract.name;
        model.workType = contract.workType;
        model.status = contract.status;
        model.assignedTo = contract.assignedTo;
        model.team = contract.team;
        model.owner = contract.owner;
        model.priority = contract.priority;
        if (contract.recurrencePattern) model.recurrencePattern = RecurrenceDefinition.fromContract(contract.recurrencePattern);
        if (contract.associatedAssets) model.associatedAssets = AssociatedAssets.fromContracts(contract.associatedAssets);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.RecurrenceTemplateContract {
        const contract = new Contracts.RecurrenceTemplateContract();
        contract.id = this.id ? this.id : '';
        contract.name = this.name;
        contract.workType = this.workType;
        contract.status = this.status;
        contract.assignedTo = this.assignedTo ? this.assignedTo : '';
        contract.team = this.team ? this.team : 'sedaru';
        contract.owner = this.owner ? this.owner : 'sedaru';
        contract.priority = this.priority ? this.priority : 1;
        contract.recurrencePattern = this.recurrencePattern.getContract();
        contract.associatedAssets = this.associatedAssets.getContracts();
        return contract;
    }

    onUpdateInformation(copyModel: RecurrenceTemplate): boolean {
        let wasChanged = false;
        wasChanged =
            this.updateIfNotDirty<string>(this._id, () => {
                this.id = copyModel.id;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._name, () => {
                this.name = copyModel.name;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._workType, () => {
                this.workType = copyModel.workType;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._status, () => {
                this.status = copyModel.status;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._assignedTo, () => {
                this.assignedTo = copyModel.assignedTo;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._team, () => {
                this.team = copyModel.team;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<string>(this._owner, () => {
                this.owner = copyModel.owner;
            }) || wasChanged;
        wasChanged =
            this.updateIfNotDirty<number | string>(this._priority, () => {
                this.priority = copyModel.priority;
            }) || wasChanged;

        wasChanged = this.recurrencePattern.updateInformation(copyModel.recurrencePattern) || wasChanged;
        wasChanged = this.refreshSubFields(this.associatedAssets, copyModel.associatedAssets) || wasChanged;

        return wasChanged;
    }

    setWorkOrderTemplateValues(workOrderSummary: WorkOrderSummary): void {
        this.workType = workOrderSummary.workType;
        this.status = workOrderSummary.status;
        this.assignedTo = workOrderSummary.assignedTo;
        this.team = workOrderSummary.teamId;
        this.owner = workOrderSummary.supervisor;
        this.priority = workOrderSummary.priority;
        this.setAssociatedAssets(workOrderSummary.workAssets);
    }

    private setAssociatedAssets(workAssets: WorkAssetWrapper[]): void {
        this.associatedAssets = new AssociatedAssets();
        if (!workAssets || !workAssets.length) return;

        for (const workAsset of workAssets) {
            const associatedAsset = new AssociatedAsset();
            associatedAsset.assetID = workAsset.assetKey;
            associatedAsset.assetType = workAsset.assetType;
            this.associatedAssets.push(associatedAsset);
        }
    }

    private refreshSubFields(currentFields: any[], newFields: any[]): boolean {
        let wasChanged = false;
        for (let i = 0; i < currentFields.length; i++) {
            // loop through old fields, find out if current field still exist in the new fields
            const stillExist = newFields.find(field => field.id === currentFields[i].id);
            // If exist, then we leave it along, no need to remove
            if (stillExist) continue;
            // else remove.
            const indexToRemove = currentFields.findIndex(field => field.id === currentFields[i].id);
            if (indexToRemove > -1) {
                currentFields.splice(indexToRemove, 1);
                i--;
                wasChanged = true;
            }
        }

        for (const newField of newFields) {
            // loop through new fields, find out if any of the old field need to be updated
            const currentFieldToUpdate = currentFields.find(field => field.id === newField.id);

            // If found, then old field need to be updated.
            if (currentFieldToUpdate) {
                wasChanged = currentFieldToUpdate.updateInformation(newField) || wasChanged;
            } else {
                // else, new field doesn't exist in the current state, so we push in the new field.
                currentFields.push(newField);
                wasChanged = true;
            }
        }

        return wasChanged;
    }
}
