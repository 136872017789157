import { FailureCode } from './failure-code.model';
import { FailureCodeContract } from '../../contracts/work-order';

export class FailureCodes extends Array<FailureCode> {
    static fromContracts(failureCodeContracts: FailureCodeContract[]): FailureCodes {
        const failureCodes = new FailureCodes();
        if (!failureCodeContracts) return failureCodes;

        for (const failureCodeContract of failureCodeContracts) failureCodes.push(FailureCode.fromContract(failureCodeContract));
        return failureCodes;
    }

    findByCodedValue = (codedValue: string): FailureCode => this.find(fc => fc.value === codedValue);

    filterByType = (type: string): FailureCode[] => this.filter(fc => fc.type === type);

    filterByParentId = (parentId: string): FailureCode[] => this.filter(fc => fc.parentId === parentId);

    filterByTypeAndParentId = (type: string, parentId: string): FailureCode[] => this.filter(fc => fc.type === type && fc.parentId === parentId);
}
