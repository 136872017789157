import { ContractBase } from '../contract-base';
import { CustomFieldContract } from './custom-field-contract';
import { WorkAssetContract } from './work-asset-contract';
import { EmployeeCostContract } from './employee-cost-contract';
import { MaterialCostContract } from './material-cost-contract';
import { EquipmentCostContract } from './equipment-cost-contract';
import { WorkTaskContract } from './work-task-contract';
import { WorkInspectionContract } from './work-inspection-contract';
import { AttachmentContract } from './attachment-contract';
import { FailureReportContract } from './failure-report-contract';
import { StandardMaterialCostContract } from './standard-material-cost-contract';
import { StandardEmployeeCostContract } from './standard-employee-cost-contract';
import { StandardEquipmentCostContract } from './standard-equipment-cost-contract';
import { StandardWorkAssetContract } from './standard-work-asset-contract';
import { StandardCustomFieldContract } from './standard-custom-field-contract';

export class ContractResponseBase {
	public Success: boolean;
	public Message: string;
}

export class ContractResponse<T> extends ContractResponseBase {
	public Result: T;
}

export class StandardWorkOrderContractWithoutNestedFields extends ContractBase {
	public objectid: number;
	public workorderkey: string;
	public worktype: string;
	public status: string;
	public createddate: string;
	public createdby: string;
	public startdate: string;
	public completeddate: string;
	public completedby: string;
	public assignedto: string;
	public priority: string;
	public location: string;
	public customername: string;
	public contactinfo: string;
	public description: string;
	public comments: string;
	public iscorrective: number;
	public change_status: string;
	public budgetid: string;
	public systemid: string;
	public accountnumber: string;
	public parentworkorderkey: string;
	public recurrencetemplateid: string;
	public changeBy: string;
	public changeDate: string;
	public xCoordinate: number;
	public yCoordinate: number;
}

export class StandardWorkOrderContract extends StandardWorkOrderContractWithoutNestedFields {
	private _workAssets: StandardWorkAssetContract[];
	public get workAssets(): StandardWorkAssetContract[] {
		return this._workAssets ? this._workAssets : (this._workAssets = new Array<StandardWorkAssetContract>());
	}
	public set workAssets(value: StandardWorkAssetContract[]) {
		if (this._workAssets === value) return;
		this._workAssets = value;
	}

	private _employeecosts: StandardEmployeeCostContract[];
	public get employeecosts(): StandardEmployeeCostContract[] {
		return this._employeecosts ? this._employeecosts : (this._employeecosts = new Array<StandardEmployeeCostContract>());
	}
	public set employeecosts(value: StandardEmployeeCostContract[]) {
		if (this._employeecosts === value) return;
		this._employeecosts = value;
	}

	private _equipmentcosts: StandardEquipmentCostContract[];
	public get equipmentcosts(): StandardEquipmentCostContract[] {
		return this._equipmentcosts ? this._equipmentcosts : (this._equipmentcosts = new Array<StandardEquipmentCostContract>());
	}
	public set equipmentcosts(value: StandardEquipmentCostContract[]) {
		if (this._equipmentcosts === value) return;
		this._equipmentcosts = value;
	}

	private _materialcosts: StandardMaterialCostContract[];
	public get materialcosts(): StandardMaterialCostContract[] {
		return this._materialcosts ? this._materialcosts : (this._materialcosts = new Array<StandardMaterialCostContract>());
	}
	public set materialcosts(value: StandardMaterialCostContract[]) {
		if (this._materialcosts === value) return;
		this._materialcosts = value;
	}

	private _customFields: StandardCustomFieldContract[];
	public get customFields(): StandardCustomFieldContract[] {
		return this._customFields ? this._customFields : (this._customFields = new Array<StandardCustomFieldContract>());
	}

	public set customFields(value: StandardCustomFieldContract[]) {
		if (this._customFields === value) return;
		this.customFields = value;
	}
}

export class AdvancedWorkOrderContractWithoutNestedFields extends ContractBase {
	public WorkorderKey: string;
	public WorkType: string;
	public WorkTypeId: string;
	public Status: string;
	public CreatedDate: string;
	public CreatedBy: string;
	public ChangeBy: string;
	public StartDate?: string;
	public CompletedDate?: string;
	public CompletedBy: string;
	public AssignedTo: string;
	public Supervisor: string;
	public ChangeDate: string;
	public Priority: string;
	public Location: string;
	public CustomerName: string;
	public TeamID: string;
	public ContactInfo: string;
	public Description: string;
	public ChangeStatus: string;
	public Comments: string;
	public IsCorrective: number;
	public BudgetId: string;
	public SystemId: string;
	public AccountNumber: string;
	public ParentWorkorderKey: string;
	public IsRequest: number;
	public Ycoordinate: number;
	public Xcoordinate: number;
	public RecurrenceTemplateID: string;
	public CustomFields: CustomFieldContract[];
}

export class AdvancedWorkOrderContractMaximo extends AdvancedWorkOrderContractWithoutNestedFields {
	public WorkAssets: WorkAssetContract[];
	public CustomFields: CustomFieldContract[];
}

export class WorkOrderContract extends AdvancedWorkOrderContractWithoutNestedFields {
	// private _customFields: Array<CustomFieldContract>;
	// get CustomFields(): Array<CustomFieldContract> {
	// 	return this._customFields ? this._customFields : (this._customFields = new Array<CustomFieldContract>());
	// }
	// set CustomFields(value) {
	// 	if (this._customFields === value) return;
	// 	this._customFields = value;
	// }

	private _workAssets: Array<WorkAssetContract>;
	get WorkAssets(): Array<WorkAssetContract> {
		return this._workAssets ? this._workAssets : (this._workAssets = new Array<WorkAssetContract>());
	}
	set WorkAssets(value) {
		if (this._workAssets === value) return;
		this._workAssets = value;
	}

	private _employeeCosts: Array<EmployeeCostContract>;
	get EmployeeCosts(): Array<EmployeeCostContract> {
		return this._employeeCosts ? this._employeeCosts : (this._employeeCosts = new Array<EmployeeCostContract>());
	}
	set EmployeeCosts(value) {
		if (this._employeeCosts === value) return;
		this._employeeCosts = value;
	}

	private _materialCosts: Array<MaterialCostContract>;
	get MaterialCosts(): Array<MaterialCostContract> {
		return this._materialCosts ? this._materialCosts : (this._materialCosts = new Array<MaterialCostContract>());
	}
	set MaterialCosts(value) {
		if (this._materialCosts === value) return;
		this._materialCosts = value;
	}

	private _equipmentCosts: Array<EquipmentCostContract>;
	get EquipmentCosts(): Array<EquipmentCostContract> {
		return this._equipmentCosts ? this._equipmentCosts : (this._equipmentCosts = new Array<EquipmentCostContract>());
	}
	set EquipmentCosts(value) {
		if (this._equipmentCosts === value) return;
		this._equipmentCosts = value;
	}

	private _workTasks: Array<WorkTaskContract>;
	get WorkTasks(): Array<WorkTaskContract> {
		return this._workTasks ? this._workTasks : (this._workTasks = new Array<WorkTaskContract>());
	}
	set WorkTasks(value) {
		if (this._workTasks === value) return;
		this._workTasks = value;
	}

	private _workInspections: Array<WorkInspectionContract>;
	get WorkInspections(): Array<WorkInspectionContract> {
		return this._workInspections ? this._workInspections : (this._workInspections = new Array<WorkInspectionContract>());
	}
	set WorkInspections(value) {
		if (this._workInspections === value) return;
		this._workInspections = value;
	}

	private _attachments: Array<AttachmentContract>;
	get Attachments(): Array<AttachmentContract> {
		return this._attachments ? this._attachments : (this._attachments = new Array<AttachmentContract>());
	}
	set Attachments(value) {
		if (this._attachments === value) return;
		this._attachments = value;
	}

	private _failureResults: Array<FailureReportContract>;
	get FailureResults(): Array<FailureReportContract> {
		return this._failureResults ? this._failureResults : (this._failureResults = new Array<FailureReportContract>());
	}
	set FailureResults(value: Array<FailureReportContract>) {
		if (this._failureResults === value) return;
		this._failureResults = value;
	}
}
