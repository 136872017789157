import { FormDefinition } from './form-definition.model';

export class FormDefinitions extends Array<FormDefinition> {
	getById = (id: number): FormDefinition => {
		for (const formDefinition of this) {
			if (formDefinition.legacyId !== id) {
				continue;
			}

			return formDefinition;
		}
	};
}
