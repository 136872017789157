import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';
import { GlobalConfig } from '../../../models/global-config.model';

export class HistoryProvidersTransform extends TransformBase {
	private _assetLayerConfig: any;
	private _assetDefinition: SedaruModels.AssetDefinition;

	constructor(fieldforceConfig: any, globalConfiguration: GlobalConfig, assetLayerConfig: any, assetDefinition: SedaruModels.AssetDefinition) {
		super(fieldforceConfig, globalConfiguration);
		this._assetDefinition = assetDefinition;
		this._assetLayerConfig = assetLayerConfig;
	}

	onTransform(): TransformationResult {
		if (!this._assetLayerConfig.DataHistoryProvider) {
			return this.getResult(true, `no history channel for asset ${this._assetDefinition.assetType}`);
		}

		const historyChannel = this.getHistoryChannel(this._assetLayerConfig.DataHistoryProvider);
		if (!historyChannel) {
			return this.getResult(false, `invalid history provider for asset type ${this._assetDefinition.assetType}`);
		}

		this._assetDefinition.historyChannelId = historyChannel.id;
		this._assetDefinition.historyChannel = historyChannel;
		this.globalConfig.channels.push(historyChannel);

		return this.getResult(true);
	}

	getHistoryChannel(dataHistoryProvider: any): SedaruModels.Channel {
		if (!dataHistoryProvider) {
			return null;
		}

		if (!dataHistoryProvider.AssetType || dataHistoryProvider.AssetType.toLowerCase() !== this._assetLayerConfig.AssetType.toLowerCase()) {
			return null;
		}

		if (dataHistoryProvider.HistoryProviderID === 0) {
			return;
		}

		const provider = this.getHistoryProvider(dataHistoryProvider.HistoryProviderID);
		if (!provider) {
			return null;
		}

		const attributes = this.getHistoryChannelAttributes(dataHistoryProvider);
		if (!attributes) {
			console.log(`Unable to generate history channel`);
			return null;
		}

		const historyChannel = new SedaruModels.Channel(this.globalConfig);
		historyChannel.name = `${this._assetLayerConfig.AssetType.toLowerCase()} history channel`;
		historyChannel.channelType = SedaruModels.ChannelTypes.History;
		const dataSource = this.getServiceConfig(provider.ServiceID);
		historyChannel.dataSourceLegacyId = dataSource.ID;
		historyChannel.attributes = attributes;

		return historyChannel;
	}

	getHistoryProvider(id: number): any {
		const hasHistoryProviders =
			this.fieldforceConfig && this.fieldforceConfig.DataProviders && this.fieldforceConfig.DataProviders.HistoryProviders && this.fieldforceConfig.DataProviders.HistoryProviders.length > 0;
		if (!hasHistoryProviders) {
			return;
		}

		for (const historyProvider of this.fieldforceConfig.DataProviders.HistoryProviders) {
			if (historyProvider.ID !== id) {
				continue;
			}

			return historyProvider;
		}

		return null;
	}

	getHistoryChannelAttributes(dataHistoryProvider: any): SedaruModels.ArcGISHistoryChannelAttributes {
		if (!dataHistoryProvider) {
			return null;
		}

		const attributes = new SedaruModels.ArcGISHistoryChannelAttributes();
		attributes.layerIndex = dataHistoryProvider.LayerIndex;
		attributes.assetType = dataHistoryProvider.AssetType;
		attributes.allAssetsHistoryDays = dataHistoryProvider.HistoryDays;

		if (!attributes.allAssetsHistoryDays || attributes.allAssetsHistoryDays <= 0) {
			attributes.allAssetsHistoryDays = 7;
		}

		attributes.assetHistoryDays = dataHistoryProvider.AssetHistoryDays;

		if (!attributes.assetHistoryDays || attributes.assetHistoryDays <= 0) {
			attributes.assetHistoryDays = 30;
		}

		if (!dataHistoryProvider.ProviderFields) {
			return attributes;
		}

		if (dataHistoryProvider.ProviderFields.AssetIDField) {
			attributes.assetIdFieldName = dataHistoryProvider.ProviderFields.AssetIDField;
		}

		if (dataHistoryProvider.ProviderFields.AssignedToField) {
			attributes.assignedToFieldName = dataHistoryProvider.ProviderFields.AssignedToField;
		}

		if (dataHistoryProvider.ProviderFields.WorkCompletedDateField) {
			attributes.completedDateFieldName = dataHistoryProvider.ProviderFields.WorkCompletedDateField;
		}

		if (dataHistoryProvider.ProviderFields.WorkTypeField) {
			attributes.workTypeFieldName = dataHistoryProvider.ProviderFields.WorkTypeField;
		}

		return attributes;
	}
}
