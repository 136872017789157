import { SedaruNotificationConnection } from './sedaru-notification-connection';

export class PacketRecipients {
	from: SedaruNotificationConnection;
	to: SedaruNotificationConnection;

	static fromAny(jObject: any): PacketRecipients {
		if (!jObject) {
			return null;
		}

		const recipients = new PacketRecipients();
		recipients.to = SedaruNotificationConnection.fromAny(jObject.To);
		recipients.from = SedaruNotificationConnection.fromAny(jObject.From);

		return recipients;
	}

	toAny(): any {
		return {
			To: this.to.toAny(),
			From: this.from.toAny()
		};
	}
}
