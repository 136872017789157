import { Pages } from './../../../navigation/inavigation';
import {
	CustomField,
	CustomFieldFilter,
	CustomFieldFilterCondition,
	CustomFields,
	Tasks,
	WorkOrderFilterFields,
	WorkOrderFilterField,
	WorkOrderMetaData,
	WorkOrderTemplate,
	WorkOrderTemplates
} from 'models/work-order';
import { ElementRef, Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Metric } from 'models/metric.model';
import { WorkOrderFilterFieldKey } from '../workorder-filter-templates';
import { Operator, QueryOperator } from 'models/query-operator.enum';
import { QueryFieldValueEditingMode } from 'app/metric/list-metric/edit-metric/metric-query/query-field/query-field-value-editing-mode.enum';
import { DeprecatedMetricTimeFrame } from 'models/metric-timeframe.model';
import { CustomFieldWrappers } from 'models/work-order/custom-field-wrappers.model';
import { CustomFieldWrapper } from 'models/work-order/custom-field-wrapper.model';
import { TaskFilter, WorkOrderFilter, AWOQuery } from 'models/awo-query.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { DropdownComponent } from 'app/ui-components/drop-down/drop-down.component';

@Component({
	selector: 'app-workorder-filter-custom-field',
	templateUrl: './workorder-filter-custom-field.component.html',
	styleUrls: ['./workorder-filter-custom-field.component.scss']
})
export class WorkOrderFilterCustomFieldComponent extends MenuPanelBaseComponent {
	@ViewChild('customFieldFilterWrapper', { static: true }) customFieldFilterWrapper: ElementRef;

	private _dropDowns: QueryList<DropdownComponent>;
	@ViewChildren(DropdownComponent) set dropDowns(componentList: QueryList<DropdownComponent>) {
		if (!componentList || !componentList.length) return;
		this._dropDowns = componentList;
		this.maxHeightOfField = this.customFieldFilterWrapper.nativeElement.offsetHeight - (componentList.length - 1) * 68;
	}
	get dropDowns() {
		return this._dropDowns;
	}
	/**
	 * The sedaru grey color code
	 */
	readonly sedaruGrey = 'rgba(84, 84, 84, 0.6)';

	/**
	 * The default sedaru grey color code
	 */
	readonly defaultSedaruGrey = 'rgba(128, 128, 128, 0.6)';

	maxHeightOfField: number;

	originalCustomFieldFilter: CustomFieldFilter;

	customFieldFilter: CustomFieldFilter;

	selectedCustomField: CustomFieldWrapper;

	selectedFieldName: { name: string; customFieldId: string };

	workTypeField: WorkOrderFilterField;

	metric: Metric;

	currentFilter: WorkOrderFilter | TaskFilter;

	isNewField: boolean;

	customFields: CustomFieldWrapper[] = [];

	fieldList = [];

	operatorList = [];

	valueList = [];

	valueEditingMode = QueryFieldValueEditingMode.SELECTION;

	joinOperationList: Operator[] = Object.values(QueryOperator.forJoin);

	htmlInputCriteria;

	workOrderFilter: WorkOrderFilterFields;

	constructor(view: ElementRef<HTMLElement>, private workOrderFactory: WorkOrderFactory) {
		super(view);
	}

	/**
	 * Show default custom fields only
	 */
	get showDefaultOnly(): boolean {
		return !!this.workTypeField;
	}

	get disableExpansion(): boolean {
		return this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT;
	}

	get pageIdentifier() {
		return Pages.workOrderFilterCustomField;
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
		this.currentFilter = args.parameter.currentFilter;
		this.isNewField = !args.parameter.customFieldFilter;
		this.originalCustomFieldFilter = args.parameter.customFieldFilter;
		this.workOrderFilter = args.parameter.workOrderFilter;
		this.customFieldFilter = new CustomFieldFilter(this.currentFilter.customFields.length);
		if (!this.isNewField) this.customFieldFilter.deepCopy(this.originalCustomFieldFilter);
		this.workTypeField = this.getWorkTypeField(this.workOrderFilter);
	}

	onPageNavigatingFrom(args: NavigationArgs) {
		return new Promise<boolean>(resolve => {
			args.parameter['currentFilter'] = this.currentFilter;
			resolve(true);
		});
	}

	ngOnInit() {
		this.initializeState(this.customFieldFilter);
	}

	ngOnDestroy() {
		this.removeInvalidCondition(this.customFieldFilter);
	}

	onDelete() {
		const index = this.currentFilter.customFields.findIndex(f => f.customFieldId === this.originalCustomFieldFilter.customFieldId);
		if (index < 0) return;
		this.currentFilter.customFields.splice(index, 1);
		this.currentFilter.isDirty = true;
		this.menuPanelComponent.goBack();
	}

	initializeState(customFieldFilter: CustomFieldFilter) {
		const { workOrderMetaData } = this.workOrderFactory;
		this.customFields = this.getCustomFieldList(workOrderMetaData, this.workTypeField);
		this.fieldList = this.customFields.map(({ customFieldName, customFieldId }) => ({ name: customFieldName, customFieldId }));
		if (customFieldFilter.customFieldId) {
			this.selectedCustomField = this.customFields.find(customField => customField.customFieldId === this.customFieldFilter.customFieldId);
			this.selectedFieldName = { name: this.selectedCustomField.customFieldName, customFieldId: this.selectedCustomField.customFieldId };
			this.operatorList = this.getOperatorBaseOnField(this.selectedCustomField);
			this.valueEditingMode = this.getEditingMode(this.selectedCustomField);
			this.valueList = this.getValueList(this.selectedCustomField);
		}
	}

	removeInvalidCondition(customFieldFilter: CustomFieldFilter) {
		if (!customFieldFilter || !customFieldFilter.conditions) return;
		for (let i = 0; i < customFieldFilter.conditions.length; i++) {
			const condition = customFieldFilter.conditions[i];
			if (!condition.join || !condition.join.value) {
				customFieldFilter.conditions.splice(i--, 1);
			}
		}
		if (customFieldFilter.conditions.length) {
			const last = customFieldFilter.conditions[customFieldFilter.conditions.length - 1];
			last.join = QueryOperator.forJoin.doneOperator;
		}
	}

	getBackgroundColor(index: number, type: string) {
		switch (type) {
			case 'operator':
				if (index % 2) return this.defaultSedaruGrey;
				else return this.sedaruGrey;
			case 'value':
				if (index % 2) return this.sedaruGrey;
				else return this.defaultSedaruGrey;
			case 'join':
				if (index % 2) return this.defaultSedaruGrey;
				else return this.sedaruGrey;
		}
	}

	getInputType() {
		if (!this.selectedCustomField || !this.selectedCustomField.customFieldType) {
			return { type: '' };
		}
		if (this.selectedCustomField.customFieldType.toLowerCase() === 'numeric') {
			return { type: 'number' };
		}
		return { type: '' };
	}

	headerForValuePanel(condition: CustomFieldFilterCondition): string {
		if (!condition || !condition.operator) return 'enter a value';
		if (this.operatorIsNullOrBlank(condition.operator)) return '-';
		if (this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT) return 'enter a value';
		else if (this.valueEditingMode === QueryFieldValueEditingMode.SELECTION) return 'select a value';
		else return 'select or enter a value';
	}

	getWorkTypeField(filter: WorkOrderFilterFields) {
		return filter.find(field => field.key === WorkOrderFilterFieldKey.WorkTypeID) || filter.find(field => field.key === WorkOrderFilterFieldKey.Task);
	}

	possiblePickList(customField: CustomFieldWrapper) {
		if (!customField || !customField.customFieldType) return false;
		const { customFieldType } = customField;
		if (customFieldType.toLowerCase() === 'varchar') return true;
		if (customFieldType.toLowerCase() === 'boolean') return true;
		return false;
	}

	hasPickList(customField: CustomFieldWrapper) {
		if (this.possiblePickList(customField) && customField.codeType) return true;
		return false;
	}

	openValuePanel(condition: CustomFieldFilterCondition) {
		if (condition.value) return false;
		if (this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT) return false;
		return true;
	}

	onFieldSelected(field: { name: string; customFieldId: string }) {
		this.selectedCustomField = this.customFields.find(customField => customField.customFieldId === field.customFieldId);
		this.customFieldFilter.customFieldId = this.selectedCustomField.customFieldId;
		this.customFieldFilter.name = this.selectedCustomField.customFieldName;
		this.customFieldFilter.conditions = [new CustomFieldFilterCondition(0, this.selectedCustomField.customFieldType)];
		this.operatorList = this.getOperatorBaseOnField(this.selectedCustomField);
		this.valueEditingMode = this.getEditingMode(this.selectedCustomField);
	}

	getValueList(customField: CustomFieldWrapper) {
		const customFieldCodes = this.workOrderFactory.workOrderMetaData.customFieldCodes.filter(code => code.codeType === customField.codeType);
		return customFieldCodes.map(({ code, description }) => ({ name: description, code }));
	}

	onValueChanged(value: any, condition: CustomFieldFilterCondition) {
		condition.value = typeof value === 'string' || typeof value === 'number' ? { name: value, code: value } : value;
	}

	onJoinOperatorSelected(operator: Operator, condition: CustomFieldFilterCondition) {
		condition.join = { name: operator.name, value: operator.value };
		if (condition.join.value === QueryOperator.forJoin.doneOperator.value) {
			if (this.isNewField) {
				this.currentFilter.customFields.push(this.customFieldFilter);
			} else {
				this.originalCustomFieldFilter.deepCopy(this.customFieldFilter);
			}
			this.currentFilter.isDirty = true;
			this.menuPanelComponent.goBack();
		} else {
			const { length } = this.customFieldFilter.conditions;
			this.customFieldFilter.conditions.push(new CustomFieldFilterCondition(length, this.selectedCustomField.customFieldType));
		}
	}

	getEditingMode(selectedCustomField: CustomFieldWrapper) {
		if (this.hasPickList(selectedCustomField)) return QueryFieldValueEditingMode.SELECTION;
		return QueryFieldValueEditingMode.FREETEXT;
	}

	onOperatorSelected(operator: Operator, condition: CustomFieldFilterCondition) {
		condition.operator = { name: operator.name, value: operator.value };
		if (condition.value || this.operatorIsNullOrBlank(operator)) {
			condition.value = { name: '', code: '' };
		}
		this.valueList = this.getValueList(this.selectedCustomField);
		this.getEditModeBaseOnOperator(operator);
		this.getMetricTimeFrameIfApply(operator);
	}

	getEditModeBaseOnOperator(selectedOperator: Operator) {
		this.valueEditingMode = this.getEditingMode(this.selectedCustomField);
		if (this.operatorIsNullOrBlank(selectedOperator)) {
			this.valueEditingMode = QueryFieldValueEditingMode.DISABLED;
		}
	}

	operatorIsNullOrBlank(operator: Operator) {
		const operatorEqualsNull: boolean = operator && operator.name.toLowerCase().includes('null');
		const operatorEqualsBlank: boolean = operator && operator.name.toLowerCase().includes('blank');
		return operatorEqualsNull || operatorEqualsBlank;
	}

	getMetricTimeFrameIfApply(operator: Operator) {
		if (operator.name !== QueryOperator.forDate.within.name) return;
		this.valueList = DeprecatedMetricTimeFrame.getTimeFrameSelection();
		this.valueEditingMode = QueryFieldValueEditingMode.SELECTION;
	}

	getCustomFieldList(metaData: WorkOrderMetaData, workTypeField: WorkOrderFilterField) {
		return this.currentFilter.filterType === 'workOrderFilter' ? this.getWOTemplateCustomFields(metaData, workTypeField) : this.getTaskCustomField(metaData, workTypeField);
	}

	private getTaskCustomField(metaData: WorkOrderMetaData, workTypeField: WorkOrderFilterField) {
		const fieldValue = this.currentFilter[workTypeField.key];
		if (fieldValue && fieldValue.split(',').length == 1) {
			const [id] = fieldValue.split(',');
			const task = metaData.tasks.getByTaskId(id);
			if (!id || !task) return metaData.taskDefaultCustomFields.slice(0, 10);
			return this.dedupCustomFields(task.taskCustomFields);
		}

		// only show 10 default custom fields, if user want to see more, they need to pick a task in the filter
		return metaData.taskDefaultCustomFields.slice(0, 10);
	}

	private getWOTemplateCustomFields(metaData: WorkOrderMetaData, workTypeField: WorkOrderFilterField) {
		const fieldValue = this.currentFilter[workTypeField.key];
		if (fieldValue && fieldValue.split(',').length == 1) {
			const [workTypeId] = fieldValue.split(',');
			const template = metaData.woTemplates.getByTemplateId(workTypeId);
			if (!workTypeId || !template) return metaData.templateDefaultCustomFields.slice(0, 10);
			return this.dedupCustomFields(template.customFields);
		}

		return metaData.templateDefaultCustomFields;
	}

	getOperatorBaseOnField(field: CustomFieldWrapper) {
		switch (field.customFieldType.toLowerCase()) {
			case 'datetime':
				return Object.values(QueryOperator.forDate);
			case 'numeric':
				return Object.values(QueryOperator.forNumeric);
			default:
				return Object.values(QueryOperator.forString);
		}
	}

	dedupCustomFields(customFields: CustomFieldWrappers) {
		const exist = new Set<string>();
		const cf: CustomFieldWrapper[] = [];
		for (const field of customFields) {
			if (exist.has(field.customFieldId)) continue;
			cf.push(field);
			exist.add(field.customFieldId);
		}
		return cf;
	}
}
