import { ContractBase } from '../contract-base';

export class EquipmentContract extends ContractBase {
	public ObjectId: number;
	public EquipmentId: string;
	public Description: string;
	public HourlyRate: number;
	public SerialNumber: string;
	public EquipmentType: string;
}
