import {Apollo, gql} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import { Role } from './../models/role.enum';
import { MetricService } from './metric.service';
import { EventEmitter, Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';
import { GuiConfig } from '../omni-model/gui-config.model';
import { Theme } from '../omni-model/theme.model';



import { map, catchError } from 'rxjs/operators';
import { UserService } from 'app/user/user.service';
import { MetricTile } from '../models';
import { GuiConfigServerData } from 'omni-model/gui-config-server-data.model';
import { CanvasTab } from 'omni-model/canvas-tab.model';
import { CanvasMap } from 'omni-model/canvas-map.model';
import { AnalyticsHub } from 'sedaru-util/analytics-hub';
import * as SedaruCore from 'sedaru-util';
import { Canvas } from 'omni-model/canvas.model';
import { TabScopeType } from 'omni-model/tab-scope.enum';

/**
 * Provides the services required to manipulate the OMNI tabs.
 */
@Injectable({
	providedIn: 'root'
})
export class GuiConfigService {
	/**
	 * The configuration details of a selected tab.
	 */
	// selectedConfig: GuiConfig;
	/**
	 * Emits an event when tab selection is made.
	 */
	selectedConfigChanged = new EventEmitter<GuiConfig>();

	private _onTabActivated: SedaruCore.InvokableEvent;
	public get onTabActivated(): SedaruCore.InvokableEvent {
		if (!this._onTabActivated) {
			this._onTabActivated = new SedaruCore.InvokableEvent();
		}

		return this._onTabActivated;
	}

	private _onTabDeactivated: SedaruCore.InvokableEvent;
	public get onTabDeactivated(): SedaruCore.InvokableEvent {
		if (!this._onTabDeactivated) {
			this._onTabDeactivated = new SedaruCore.InvokableEvent();
		}

		return this._onTabDeactivated;
	}

	/**
	 * An array of configuration of all the available tabs for the given login.
	 */
	private _availableConfigurations: Array<GuiConfig>;
	public get availableConfigurations(): Array<GuiConfig> {
		if (!this._availableConfigurations) this._availableConfigurations = new Array<GuiConfig>();

		return this._availableConfigurations;
	}

	/** triggered when configurations have been loaded from the server */
	private configurationsLoaded = new Subject<Array<GuiConfig>>();
	configurationsLoaded$ = this.configurationsLoaded.asObservable();

	private tileFeaturesUpdateSubject = new Subject<any>();
	tileFeaturesUpdate = this.tileFeaturesUpdateSubject.asObservable();

	/**
	 * The constructor updates the list of available configurations when new tabs are created.
	 */
	constructor(private apollo: Apollo, private userService: UserService, private metricService: MetricService) {
		// On start, we want to generate a empty new config so the app won't look broken.
		// In the future, we should replace this with a loading page since calling 'this.generateNewConfig()'
		// would actually create a config vertex in the database in the future.
		// The config with id 0 gets replaced by a real, saved config after the user logs in.
		this.addConfigToAvailableConfigs('0', GuiConfig.generateNewConfig());
	}

	setSelectedConfig(tabId: string) {
		const currentSelcted = this.availableConfigurations.find(c => c.isSelected);
		currentSelcted.isSelected = false;
		this.onTabDeactivated.invoke(this, currentSelcted);
		const config = this.availableConfigurations.find(c => c.id === tabId);
		config.isSelected = true;
		this.onTabActivated.invoke(this, config);
	}

	/**
	 * This function will reset the 'selectedConfig' in the available configurations
	 */
	private resetIsSelectedConfigList(): void {
		for (const existingConfig of this.availableConfigurations) {
			if (!existingConfig.isSelected) continue;

			existingConfig.isSelected = false;
			this.onTabDeactivated.invoke(this, existingConfig);
		}
	}

	updateMenuPanelContent() {
		this.tileFeaturesUpdateSubject.next(null);
	}

	/**
	 * Adds a new configuration to the list of available configurations.
	 * @param id - the unique id fetched from server to be assigned to the new config
	 * @param config - the new configuration to be added to the list of available configurations
	 */
	addConfigToAvailableConfigs(id: string, config: Partial<GuiConfig>) {
		const newConfig: GuiConfig = new GuiConfig(1, 6);
		newConfig.id = id;
		Object.keys(config).forEach(key => {
			newConfig[key] = config[key];
		});
		newConfig.createdBy = this.userService.currentUser.userName;
		newConfig.hasPermission = true;
		this.availableConfigurations.push(newConfig);
		this.setSelectedConfig(newConfig.id);
		return newConfig;
	}

	/**
	 * Hits the createGuiConfig api to add a new configuration to the DB and fetch the new ID
	 * @param {Partial<GuiConfig>} config - the configuration to be added to the DB. It is a
	 * Partial GuiConfig because ID is missing. Will be fetched from server.
	 */
	addConfigToDb(config: Partial<GuiConfig>): Observable<GuiConfig> {
		if (!this.userService.currentUser.customerCode) {
			return;
		}
		const createGuiConfig = gql`
			mutation createGuiConfig($guiConfigInput: CreateGuiConfigInput, $customerCode: String!) {
				createGuiConfig(guiConfigInput: $guiConfigInput, customerCode: $customerCode) {
					id
				}
			}
		`;
		const newConfig = {
			name: config.name,
			content: config.content,
			canvasLayout: config.canvasLayout,
			tilesLayout: config.tilesLayout,
			theme: config.theme,
			lastPathVisited: config.lastPathVisited,
			isSelected: config.isSelected,
			createdBy: this.userService.currentUser.userName,
			profileGroupName: config.profileGroupName
		};

		return this.apollo
			.mutate({
				mutation: createGuiConfig,
				variables: {
					guiConfigInput: newConfig,
					customerCode: this.userService.currentUser.customerCode
				}
			})
			.pipe(
				map((result: ApolloQueryResult<{ createGuiConfig: GuiConfig }>) => {
					this.updateTabScope(result.data.createGuiConfig.id, config.scope).subscribe();
					return result.data.createGuiConfig;
				}),
				catchError(error => throwError('Unable to save configuration to DB', error))
			);
	}

	assignMetricReferenceToMetricTile(guiConfig: GuiConfig) {
		for (const metricTile of Object.values(guiConfig.tileList)) {
			metricTile.metric = metricTile.metric ? this.metricService.availableMetrics.find(metric => metric.id === metricTile.metricId) : undefined;
			metricTile.metricId = metricTile.metric ? metricTile.metric.id : undefined;
		}
	}

	setTabPermission(guiConfig: GuiConfig) {
		if (guiConfig.scope == TabScopeType[TabScopeType.Myself]) return true;

		const { role } = this.userService.currentUser;

		if (!role) return false;

		if (guiConfig.scope == TabScopeType[TabScopeType.Public] && role.toLowerCase() == Role.User) return false;

		return true;
	}

	/**
	 * Method to check whether the given Tab belongs to the current active Profile Group or not, for a PG enabled customer
	 * @param {GuiConfig} tab - Tab to check
	 */
	 private isGuiConfigBelongsToCurrentPG(gConfig: GuiConfigServerData): boolean {
		return this.userService.currentUser.isProfileGroupEnabled
			? this.userService.getCurrentProfileGroup() === gConfig.profileGroupName : true;
	}

	initializeGuiConfig(guiConfigData: GuiConfigServerData): GuiConfig {
		const newGuiConfig = new GuiConfig(1, 6);
		newGuiConfig.id = guiConfigData.id;
		newGuiConfig.name = guiConfigData.name ? guiConfigData.name : '';
		newGuiConfig.content = guiConfigData.content ? guiConfigData.content : '';
		newGuiConfig.isSelected = guiConfigData.isSelected ? true : false;
		newGuiConfig.canvasLayout = guiConfigData.canvasLayout ? guiConfigData.canvasLayout : null;
		newGuiConfig.tilesLayout = guiConfigData.tilesLayout >= 0 ? guiConfigData.tilesLayout : null;
		newGuiConfig.lastPathVisited = guiConfigData.lastPathVisited ? guiConfigData.lastPathVisited : '';
		newGuiConfig.theme = guiConfigData.theme ? new Theme(guiConfigData.theme.displayName, guiConfigData.theme.backgroundURL) : null;
		newGuiConfig.scope = guiConfigData.scope;
		newGuiConfig.teamId = guiConfigData.teamId;
		newGuiConfig.teamName = guiConfigData.teamName;
		newGuiConfig.teamOwner = guiConfigData.teamOwner;
		newGuiConfig.teamMembers = guiConfigData.teamMembers;
		newGuiConfig.createdBy = guiConfigData.createdBy;
		newGuiConfig.profileGroupName = guiConfigData.profileGroupName;
		newGuiConfig.isEnabled = this.isGuiConfigBelongsToCurrentPG(guiConfigData)
		newGuiConfig.hasPermission = this.setTabPermission(newGuiConfig);
		if (guiConfigData.tileList) {
			for (let i = 0; i < newGuiConfig.tilesLayout; i++) {
				const tileAtCurrentPosition = Object.values(guiConfigData.tileList).find(tile => tile.tilePosition === i);
				newGuiConfig.tileList[i] = new MetricTile();

				tileAtCurrentPosition ? newGuiConfig.tileList[i].initialize(tileAtCurrentPosition) : (newGuiConfig.tileList[i].tilePosition = i);
			}
		}

		for (let i = 0; i < guiConfigData.canvasList.length; i++) {
			const tabs: CanvasTab[] = guiConfigData.canvasList[i].tabs
				? guiConfigData.canvasList[i].tabs
						.filter(tab => tab.defaultMetricId)
						.map(tab => {
							const defaultMetric = this.metricService.availableMetrics.find(metric => metric.id === tab.defaultMetricId);
							if (!defaultMetric) return;
							const newTab = new CanvasTab();
							newTab.active = tab.active;
							newTab.defaultMetric = defaultMetric;
							newTab.locked = tab.locked;
							return newTab;
						})
				: [];
			const canvasFromServer = guiConfigData.canvasList[i];
			const currentCanvas = new Canvas(canvasFromServer.size, canvasFromServer.position);
			for (const property in canvasFromServer) {
				if (Object.prototype.hasOwnProperty.call(canvasFromServer, property)) {
					currentCanvas[property] = canvasFromServer[property];
				}
			}

			currentCanvas.tabs = tabs.filter(Boolean);
			const canvasMap = guiConfigData.canvasList[i].map;
			const canvasTrend = guiConfigData.canvasList[i].trend;
			if (canvasMap) {
				const canvasMapModel = new CanvasMap();
				canvasMapModel.initialize(canvasMap);
				currentCanvas.map = canvasMapModel;
			}

			if (canvasTrend) {
				currentCanvas.trendStyle = canvasTrend.style;
			}

			newGuiConfig.canvasList.push(currentCanvas);
		}

		newGuiConfig.canvasList.sort((firstCanvas, secondCanvas) => firstCanvas.position - secondCanvas.position);

		guiConfigData.selectedTile ? (newGuiConfig.selectedTile = guiConfigData.selectedTile) : (newGuiConfig.selectedTile = undefined);

		guiConfigData.selectedCanvas ? (newGuiConfig.selectedCanvas = guiConfigData.selectedCanvas) : (newGuiConfig.selectedCanvas = undefined);

		console.log('newGuiConfig', newGuiConfig);
		return newGuiConfig;
	}

	/**
	 * Loads the configurations returned from the server into availableConfigurations
	 * @param {string} customerCode - the customer code from which the configurations will be fetched
	 * @param {GuiConfigServerData[]} guiConfigServerDataList - The list of gui config data from our graph datebase
	 */
	async loadConfigurations(customerCode?: string, guiConfigServerDataList?: GuiConfigServerData[]) {
		if (!customerCode) {
			// when user logs out
			this._availableConfigurations = [];
			this.resetIsSelectedConfigList();
			return;
		}

		if (guiConfigServerDataList.length) {
			this._availableConfigurations = [];
			for (const config of guiConfigServerDataList) {
				// Making a new omniGui configuration
				const newGuiConfig: GuiConfig = this.initializeGuiConfig(config);

				// 1. Need to link reference of metric to tile

				// 3. Need to link reference of metric to canvasTabs

				this.assignMetricReferenceToMetricTile(newGuiConfig);

				// Assigning the initialzed omniGui configuration into the available configurations
				this.availableConfigurations.push(newGuiConfig);
			}

			let selectedConfig;
			let firstEnabledConfigIndex = -1;
			this.availableConfigurations.forEach((config, index) => {
				if (!config.isEnabled) {
					config.isSelected = false;
				}
				if (this.isUndefined(selectedConfig) && config.isSelected) selectedConfig = config;
				else config.isSelected = false;
				if (firstEnabledConfigIndex < 0 && config.isEnabled) firstEnabledConfigIndex = index;
			});

			if (this.isUndefined(selectedConfig)) {
				if (firstEnabledConfigIndex < 0) {
					// no valid tabs found so create one
					const emptyConfig = GuiConfig.generateNewConfig(this.userService.getCurrentProfileGroup());
					emptyConfig.hasPermission = true;
					const newConfig = this.addConfigToAvailableConfigs('0', emptyConfig);
					await this.createNewGuiConfig(newConfig);
					await this.updateTabOrder().toPromise();
				} else {
					this.availableConfigurations[firstEnabledConfigIndex].isSelected = true;
				}
			}
			this.configurationsLoaded.next(this.availableConfigurations);
		} else {
			const emptyConfig = this.availableConfigurations[0];
			emptyConfig.profileGroupName = this.userService.getCurrentProfileGroup();
			await this.createNewGuiConfig(emptyConfig);
			this._availableConfigurations = [];
			this.addConfigToAvailableConfigs(emptyConfig.id, emptyConfig);
			this.configurationsLoaded.next(this.availableConfigurations);
		}
	}

	/** Method to create new GuiConfig from partial */
	private async createNewGuiConfig(emptyConfig: Partial<GuiConfig>) {
		emptyConfig.isSelected = true;
		emptyConfig.isEnabled = true;
		const newConfig: GuiConfig = await this.addConfigToDb(emptyConfig).toPromise();
		emptyConfig.id = newConfig.id;
	}

	updateTabScope(tabId: string, scope: string, userNamesToAdd = [], userNamesToRemove = []) {
		const { customerCode, userName } = this.userService.currentUser;
		const updateTabScope = gql`
			mutation updateTabScope($tabId: String!, $customerCode: String!, $userName: String!, $scope: String!, $userNamesToAdd: [String], $userNamesToRemove: [String]) {
				updateTabScope(tabId: $tabId, customerCode: $customerCode, userName: $userName, scope: $scope, userNamesToAdd: $userNamesToAdd, userNamesToRemove: $userNamesToRemove)
			}
		`;

		return this.apollo
			.mutate({
				mutation: updateTabScope,
				variables: {
					tabId,
					customerCode,
					userName,
					scope,
					userNamesToAdd,
					userNamesToRemove
				}
			})
			.pipe(map(result => console.log(result)));
	}

	updateTabOrder() {
		const tabOrder = this.availableConfigurations.map(config => config.id);
		const { customerCode, userName } = this.userService.currentUser;
		const updateTabOrder = gql`
			mutation updateTabOrder($customerCode: String!, $userName: String!, $tabOrder: [String]) {
				updateTabOrder(customerCode: $customerCode, userName: $userName, tabOrder: $tabOrder)
			}
		`;

		return this.apollo
			.mutate({
				mutation: updateTabOrder,
				variables: {
					customerCode,
					userName,
					tabOrder
				}
			})
			.pipe(map(result => result));
	}

	private isUndefined(value: any) {
		return value === undefined;
	}

	/**
	 * This function sets the 'selectedConfig' by the config ID
	 * @param {string} id - config index
	 */
	getSelectedConfigurationFromURL(id: string): void {
		// const configID = id === 'default' ? Object.keys(this.availableConfigurations)[0] : id;
		// this.setSelectedConfigById(configID);
	}

	/**
	 * Update the config property value with argument 'newValue' and if the config is the selectedConfig, it will
	 * broadcast the selected config to the components
	 * @param {string} id - config id
	 * @param {string} fieldToUpdate - property to update
	 * @param {any} newValue - the new value of the field to update
	 */
	updateConfigurationProperty(config: GuiConfig, fieldToUpdate: string, newValue: any): Promise<any> {
		if (!this.userService.currentUser.customerCode) {
			return;
		}
		// update config in DB
		const newConfig = {};
		newConfig['id'] = config.id;
		newConfig[fieldToUpdate] = newValue;

		const updateGuiConfig = gql`
			mutation updateGuiConfig($guiConfigInput: UpdateGuiConfigInput) {
				updateGuiConfig(guiConfigInput: $guiConfigInput) {
					id
				}
			}
		`;

		return this.apollo
			.mutate({
				mutation: updateGuiConfig,
				variables: {
					guiConfigInput: newConfig
				}
			})
			.toPromise();
	}

	deleteGuiConfiguration(guiConfigId: string) {
		const deleteGuiConfig = gql`
			mutation deleteGuiConfig($guiConfigId: String!) {
				deleteGuiConfig(guiConfigId: $guiConfigId)
			}
		`;

		return this.apollo
			.mutate({
				mutation: deleteGuiConfig,
				variables: {
					guiConfigId
				}
			})
			.pipe(map(result => console.log(result)));
	}

	private setSelectedConfigInDb(guiConfigId: string) {
		if (!this.userService.currentUser.customerCode) {
			return;
		}
		const setSelectedConfig = gql`
			mutation setSelectedConfig($customerCode: String!, $guiConfigId: String!) {
				setSelectedConfig(customerCode: $customerCode, guiConfigId: $guiConfigId)
			}
		`;

		this.apollo
			.mutate({
				mutation: setSelectedConfig,
				variables: {
					customerCode: this.userService.currentUser.customerCode,
					guiConfigId
				}
			})
			.subscribe((result: ApolloQueryResult<{ setSelectedConfig: string }>) => console.log('selected config set for:', guiConfigId));
	}

	/**
	 * Error handling for graphql api request
	 */
	handlerError(error) {
		console.error(error);
		let errorMessage = '';

		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		AnalyticsHub.current.trackError(error);

		return throwError(new Error(errorMessage));
	}
}
