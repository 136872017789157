import { PacketRecipients } from './packet-recipients';
import { ClientHubBase } from './client-hub-base';
import { NotificationPacket } from './notification-packet';

import * as SedaruUtils from '../index';
import { ServerInfo } from './server-info';
import { SedaruSignalRVersions } from './sedaru-signalr-versions';
import { SedaruClientMethodHandlerManager } from './sedaru-client-method-handler-manager';
import { MethodHandlerArgs } from './method-handler-args';
import { SedaruNotificationConnection } from './sedaru-notification-connection';
import { ConnectionPingHandler } from './connection-ping-handler';

export class SedaruClientHubArgs {
	methodIdentifier: string;
	message: PacketRecipients;
	jsonData: string;
}

export class SedaruClientHub extends ClientHubBase {
	public static get sedaruConnectAppName(): string {
		return 'SmartConnect';
	}

	private _clientConnection: SedaruNotificationConnection;
	public get clientConnection(): SedaruNotificationConnection {
		return this._clientConnection;
	}

	private _onNotificationReceived: SedaruUtils.InvokableEvent;
	public get notificationReceived(): SedaruUtils.InvokableEvent {
		if (!this._onNotificationReceived) this._onNotificationReceived = new SedaruUtils.InvokableEvent();

		return this._onNotificationReceived;
	}

	private _onConnectionWasPinged: SedaruUtils.InvokableEvent;
	public get onConnectionWasPinged(): SedaruUtils.InvokableEvent {
		if (!this._onConnectionWasPinged) this._onConnectionWasPinged = new SedaruUtils.InvokableEvent();

		return this._onConnectionWasPinged;
	}

	private _serverInfo: ServerInfo;
	public get serverInfo(): ServerInfo {
		return this._serverInfo;
	}

	private _methodHandlers: SedaruClientMethodHandlerManager;
	public get methodHandlers(): SedaruClientMethodHandlerManager {
		if (!this._methodHandlers) {
			this._methodHandlers = new SedaruClientMethodHandlerManager(this);
		}

		return this._methodHandlers;
	}

	public get ping(): ConnectionPingHandler {
		return this.methodHandlers.getHandler('ConnectionPingHandler') as ConnectionPingHandler;
	}

	constructor(url: string, private customerCode: string, private username: string, private app: string, private agent: string) {
		super(url, `customerCode=${customerCode}&username=${username}&app=${app}&agent=${agent}&sprotocol=1.1`, 'SedaruHub');
		this.addDefaultHandlers();
	}

	private addDefaultHandlers() {
		this.methodHandlers.add(new ConnectionPingHandler());
	}

	onHubProxyCreation(hubProxy) {
		hubProxy.on('OnNotificationReceived', (transportMessageJson, data) => {
			this.onNotificationReceived_private(transportMessageJson, data);
		});
		hubProxy.on('OnServerInfoReceived', serverInfo => {
			this.serverInfoReceived(serverInfo);
		});
	}

	private async onNotificationReceived_private(serverMessage: string, data: string) {
		if (!serverMessage) {
			return;
		}

		const packet = NotificationPacket.fromAny(JSON.parse(serverMessage));

		if (!packet.metadata.serverInformation && !this.serverInfo) {
			this._serverInfo = packet.metadata.serverInformation;
		}

		let msg: PacketRecipients;
		if (packet.metadata.protocolVersion === SedaruSignalRVersions.v_1_1_text) {
			msg = packet.recipients;
		}

		const methodArgs = new MethodHandlerArgs();
		await this.methodHandlers.handle(packet, methodArgs);

		if (methodArgs.isHandled) {
			if (!methodArgs.continuePropagation) {
				return;
			}
		}

		const args = new SedaruClientHubArgs();
		args.jsonData = packet.data;
		args.methodIdentifier = packet.methodIdentifier;
		args.message = packet.recipients;

		this.onNotificationReceived(packet.methodIdentifier, packet.recipients, packet.data);
		this.notificationReceived.invoke(this, args);
	}

	private serverInfoReceived(serverInfo: string) { }

	protected onNotificationReceived(methodIdentifier: string, transportMessage: PacketRecipients, jsonData: string) { }

	protected onConnectionEstablishedMethod() {
		this._clientConnection = new SedaruNotificationConnection(this.customerCode, this.username, this.app, this.agent, this.connectionId);
	}

	invokeOnConnectionWasPinged(incomingMsg: PacketRecipients) {
		this.onConnectionWasPinged.invoke(this, incomingMsg);
	}

	public isSedaruConnectAvailable(customerCode: string, timeoutSeconds: number): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			let result = await this.ping.pingConnections(customerCode, '', SedaruClientHub.sedaruConnectAppName, '', timeoutSeconds, 1);
			if (result.length === 1) {
				resolve(true);
				return;
			}

			result = await this.ping.pingConnections(customerCode, '', 'smartconnect', '', timeoutSeconds, 1);
			resolve(result.length === 1);
		});
	}

	public sendNotification(methodIdentifier: string, jsonData: string, toCustomerCode?: string, toUsername?: string, toApp?: string, toConnectionID?: string): Promise<number> {
		return new Promise<number>(async (resolve, reject) => {
			if (!this.isConnected) {
				const reconncted = await super.reconnect();

				if (!reconncted) {
					console.log(`Unable to send real-time message ${methodIdentifier}. Not connected`);
					resolve(-4);
					return;
				}
			}

			let packet: NotificationPacket;
			try {
				if (!methodIdentifier) {
					resolve(-4);
					return;
				}

				const to = new SedaruNotificationConnection(toCustomerCode, toUsername, toApp, '', toConnectionID, '');
				const from = new SedaruNotificationConnection(this.customerCode, this.username, this.app, this.agent, this.connectionId, SedaruSignalRVersions.currentVersion);
				const recipients = new PacketRecipients();
				recipients.from = from;
				recipients.to = to;

				packet = new NotificationPacket(methodIdentifier);
				packet.data = jsonData;
				packet.recipients = recipients;
			} catch {
				resolve(-4);
				return;
			}

			const result = await super.invoke('SendNotification', packet.jsonString(), jsonData);
			resolve(result);
			return;
		});
	}
}
