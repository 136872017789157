import { FailureReportContract } from '../../contracts/work-order';
import { FailureReport } from './failure-report.model';

export class FailureReportWrapper {
	private _failureReport: FailureReport;
	get failureReport(): FailureReport {
		return this._failureReport;
	}

	constructor(failureReport: FailureReport) {
		this._failureReport = failureReport;
	}

	get id(): string {
		return this._failureReport.id;
	}
	set id(value: string) {
		this._failureReport.id = value;
	}

	get codeType(): string {
		return this._failureReport.codeType;
	}
	set codeType(value: string) {
		this._failureReport.codeType = value;
	}

	get codeValue(): string {
		return this._failureReport.codeValue;
	}
	set codeValue(value: string) {
		this._failureReport.codeValue = value;
	}

	get systemId(): string {
		return this._failureReport.systemId;
	}
	set systemId(value: string) {
		this._failureReport.systemId = value;
	}

	get workOrderKey(): string {
		return this._failureReport.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._failureReport.workOrderKey = value;
	}

	get createdDate(): string {
		return this._failureReport.createdDate;
	}
	set createdDate(value: string) {
		this._failureReport.createdDate = value;
	}

	get createdBy(): string {
		return this._failureReport.createdBy;
	}
	set createdBy(value: string) {
		this._failureReport.createdBy = value;
	}

	get isDirty(): boolean {
		return this._failureReport.isDirty;
	}

	clearDirty = (): void => this._failureReport.clearDirty();

	undoAll = (): boolean => this._failureReport.undoAll();

	onUpdateInformation = (copyModel): boolean => this._failureReport.onUpdateInformation(copyModel);

	updateInformation = (copyModel): boolean => this._failureReport.updateInformation(copyModel);

	getContract = (): FailureReportContract => this._failureReport.getContract();
}
