import { Component, Input } from '@angular/core';
import { FormControlDefinition } from 'models';
import { BaseFormResolver } from '../form-resolvers/base-form.resolver';

@Component({
	selector: 'app-form-footer-field',
	templateUrl: './form-footer-field.component.html',
	styleUrls: ['./form-footer-field.component.scss']
})
export class FormFooterFieldComponent {
	@Input() control: FormControlDefinition;
	@Input() formResolver: BaseFormResolver;

	getBackgroundColor() {
		const color = this.formResolver.getFooterFieldBackground(this.control);
		if (color) return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.alpha / 100})`;
		return '';
	}

	getTextColor() {
		const color = this.formResolver.getFooterTextColor(this.control);
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.alpha / 100})`;
	}
}
