import { ListItem } from './list-item.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';

export class List extends SedaruConfigBase {
    private _items;
    get items(): ListItem[] {
        return this._items ?? (this._items = new Array<ListItem>());
    }
    set items(value: ListItem[]) {
        if (this._items == value) return;
        this._items = value;
    }
}
