import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';

export class FormatPropertiesTransform extends TransformBase {
	protected onTransform(): TransformationResult {
		if (!this.fieldforceConfig.Design || !this.fieldforceConfig.Design.DesignProperties || !this.fieldforceConfig.Design.DesignProperties.FormatProperties) {
			return this.getResult(true);
		}

		for (const format of this.fieldforceConfig.Design.DesignProperties.FormatProperties) {
			const formatProperties = new SedaruModels.FormatProperty();
			formatProperties.id = format.ID;
			formatProperties.type = formatProperties.getFormatType(format.Type);
			formatProperties.decimals = format.Decimals;
			formatProperties.format = format.Format;
			this.globalConfig.formatProperties.push(formatProperties);
		}

		return this.getResult(true);
	}
}
