import { Component, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { GuiConfigService } from '../../../../domain-service/gui-config.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';
import {
	Metric,
	ChannelTypes,
	QueryBuilder,
	QueryMode,
	AssetDefinition,
	Channel,
	ArcGISAssetChannelAttributes,
	ArcGISHistoryChannelAttributes,
	QueryStatementList,
	MetricTypes
} from '../../../../models';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { TileService } from 'app/tiles/tile.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { WorkOrderFilterFieldKey } from 'app/workorder/workorder-filter/workorder-filter-templates';
import { UserService } from 'app/user/user.service';
import { DeleteDialogComponent } from 'app/ui-components/delete-dialog/delete-dialog.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { MetricService } from 'domain-service/metric.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { AGSQuery } from 'models/ags-query.model';
import { SWOQuery } from 'models/swo-query.model';
import { AssetQuery } from 'models/asset-query.model';
import { HistoryQuery } from 'models/history-query.model';
import { AWOQuery } from 'models/awo-query.model';
import { CustomQuery } from 'models/custom-query.model';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';

/**
 * This component is designed to be loaded when the user tries to edit a selected metric from the available list of metrices.
 */
@Component({
	selector: 'app-edit-metric',
	templateUrl: './edit-metric.component.html',
	styleUrls: ['./edit-metric.component.scss']
})
export class EditMetricComponent extends MenuPanelBaseComponent {
	@ViewChild('scrollingContainer') scrollingContainer: ElementRef;

	/**
	 * This is an omni metric object.
	 */
	metric: Metric;
	/**
	 * The display name for the metric source
	 */
	metricSourceDisplayName: string;

	/**
	 * The current dialog refrence
	 */
	dialogRef: MatDialogRef<any, any>;

	private iconRequested = false;

	private query: string;

	get assetDefinition(): AssetDefinition {
		if (this.dataChannel.channelType === ChannelTypes.WorkOrder) return null;
		const assetType = this.metric.definition.source.assetType;
		return this.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
	}

	get dataChannel(): Channel {
		const channelType = this.metric.definition.source.type;
		switch (channelType) {
			case ChannelTypes.Asset:
				return this.metric.definition.assetChannel;
			case ChannelTypes.History:
				return this.metric.definition.historyChannel;
			case ChannelTypes.WorkOrder:
				return this.metric.definition.workOrderChannel;
			default:
				return null;
		}
	}

	get queryError() {
		if (!this.dataChannel) return true;
		return false;
	}

	private _canSave = true;
	get canSave() {
		if (!this.metric.definition.name) return false;
		return this._canSave;
	}

	private _timeFrameFilter: TimeframeFilter;
	get timeFrameFilter() {
		if (!this._timeFrameFilter) return this.metric.definition.timeFrameFilter;
		return this._timeFrameFilter;
	}

	private _scrollingPosition: number;

	constructor(
		private cdr: ChangeDetectorRef,
		private metricService: MetricService,
		private flashMessageService: FlashMessageService,
		private guiConfigService: GuiConfigService,
		private canvasService: CanvasService,
		private tileService: TileService,
		private dialog: MatDialog,
		private userService: UserService,
		private interopService: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	async onMetricUpdated() {
		this.cdr.detectChanges();
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter?.metric;
		this._timeFrameFilter = args.parameter?.timeFrameFilter;
	}

	onPageReload() {
		if (this.uiState && this.uiState.scrollPosition) this.uiState.scrollPosition = 0;
	}

	ngOnInit() {
		if (this.metric.isNew) this.menuPanelComponent.updateView({ title: 'add metric' });
		else this.menuPanelComponent.updateView({ title: 'edit metric' });
		this.displayMetricSourceName();
		if (this.metric.isNew) delete this.metric.definition.symbolIconUrl;
		if (this.uiState) this._scrollingPosition = this.uiState.scrollingPosition;
	}

	onScroll() {
		if (!this.uiState) this.uiState = {};
		this.uiState.scrollingPosition = this.scrollingContainer.nativeElement.scrollTop;
	}

	ngAfterViewInit() {
		if (this._scrollingPosition) this.scrollingContainer.nativeElement.scroll(0, this._scrollingPosition);
	}

	/**
	 * This function will assign the proper display value to the metric source display name
	 */
	displayMetricSourceName(): void {
		const { source } = this.metric.definition;
		if (source.type === ChannelTypes.WorkOrder) {
			this.metricSourceDisplayName = 'work orders';
		} else if (source.type === ChannelTypes.History && source.assetType) {
			this.metricSourceDisplayName = `History - ${source.assetType}`;
		} else if (source.type === ChannelTypes.Asset && source.assetType) {
			this.metricSourceDisplayName = `Asset - ${source.assetType}`;
		} else {
			this.metricSourceDisplayName = '';
		}
	}

	// getWorkOrderSourceDisplayName(): string {
	// 	const { workOrderSourceType } = this.metric.definition.source;
	// 	const { name } = this.metricSourceService.workOrderSourceTypes.find(type => type.value === workOrderSourceType);
	// 	return name;
	// }

	/**
	 * This method is called when user try to visit the query builder page
	 */
	onGoToQuery() {
		const { type } = this.metric.definition.source;
		if (!type) {
			return this.flashMessageService.popMessage('No source selected for this metric.');
		}
		if (type === ChannelTypes.WorkOrder && this.metric.definition.workOrderChannel.isAdvancedWorkOrder) return NavigationService.navigateTo(Pages.workOrderSourceTypes, { metric: this.metric });
		if (type === ChannelTypes.WorkOrder) this.metric.definition.source.workOrderSourceType = WorkOrderSourceType.workOrders;
		if (this.metric.definition.query.mode === QueryMode.manualQuery) return NavigationService.navigateTo(Pages.manualQuery, { metric: this.metric });
		const queryStatementList = QueryStatementList.from((this.metric.definition.query as AGSQuery).queryStatementList);
		return NavigationService.navigateTo(Pages.queryBuilder, { metric: this.metric, queryStatementList });
	}

	/**
	 * go to display settings for metric
	 */
	onGoToDisplayValueSettings() {
		const { workOrderChannel, assetChannel, historyChannel } = this.metric.definition;
		if (!workOrderChannel && !assetChannel && !historyChannel) return this.flashMessageService.popMessage('Please select a metric source.');

		if (workOrderChannel) {
			if (workOrderChannel.isAdvancedWorkOrder) {
				return this.flashMessageService.popMessage('Not for advanced work order.');
			} else {
				const { workOrderSourceType } = this.metric.definition.source;
				const costCategoryField = (this.metric.definition.query as SWOQuery).workOrderFilter.workOrderFields.find(field => field.key === WorkOrderFilterFieldKey.CostCategory);
				if (workOrderSourceType === WorkOrderSourceType.workOrderCosts && (!costCategoryField || !costCategoryField.value)) {
					return this.flashMessageService.popMessage('Need select cost category from query');
				}
				return NavigationService.navigateTo(Pages.displayValueSettings, { metric: this.metric });
			}
		}
		NavigationService.navigateTo(Pages.displayValueSettings, { metric: this.metric });
	}

	/**
	 * go to menu panel settings for metric
	 */
	onGoToMenuPanelSettings() {
		const { workOrderChannel, assetChannel, historyChannel } = this.metric.definition;
		if (!assetChannel && !workOrderChannel && !historyChannel) return this.flashMessageService.popMessage('Please select a metric source.');
		if (workOrderChannel) {
			if (workOrderChannel.isAdvancedWorkOrder) {
				return this.flashMessageService.popMessage('Not for advanced work order.');
			} else {
				const { workOrderSourceType } = this.metric.definition.source;
				const costCategoryField = (this.metric.definition.query as SWOQuery).workOrderFilter.workOrderFields.find(field => field.key === WorkOrderFilterFieldKey.CostCategory);
				if (workOrderSourceType === WorkOrderSourceType.workOrderCosts && (!costCategoryField || !costCategoryField.value)) {
					return this.flashMessageService.popMessage('Need select cost category from query');
				}
				return NavigationService.navigateTo(Pages.menupanelsettings, { metric: this.metric });
			}
		}
		NavigationService.navigateTo(Pages.menupanelsettings, { metric: this.metric });
	}

	/**
	 * go to default trend settings for metric
	 */
	onGoToDefaultTrendSettings() {
		const { workOrderChannel, historyChannel } = this.metric.definition;
		if (historyChannel) NavigationService.navigateTo(Pages.defaulttrendsettings, { metric: this.metric });

		if (workOrderChannel) {
			const { workOrderSourceType } = this.metric.definition.source;
			const costCategoryField = (this.metric.definition.query as SWOQuery).workOrderFilter.workOrderFields.find(field => field.key === WorkOrderFilterFieldKey.CostCategory);
			if (workOrderSourceType === WorkOrderSourceType.workOrderCosts && (!costCategoryField || !costCategoryField.value)) {
				return this.flashMessageService.popMessage('Need select cost category from query');
			} else if (workOrderSourceType === WorkOrderSourceType.employees || workOrderSourceType === WorkOrderSourceType.equipment || workOrderSourceType === WorkOrderSourceType.materials) {
				return this.flashMessageService.popMessage('Not for labor, equipment, or material');
			}
			return NavigationService.navigateTo(Pages.defaulttrendsettings, { metric: this.metric });
		}

		return this.flashMessageService.popMessage(`Asset metric can't be trended.`);
	}

	/**
	 * On click the save button, the selectedMetric configurations will be preserved in the Database, and saved to gui config object.
	 */
	async onSave() {
		const settingNewMetric = this.metric.isNew;
		if (!this.canSave) return;
		// validate metric' configuration
		this._canSave = false;
		if (this._timeFrameFilter) this.metric.definition.timeFrameFilter = this._timeFrameFilter;
		this.menuPanelComponent.updateView({ showPacifier: true });
		if (settingNewMetric) await this.setDisplayField();
		const errorMessage = this.metricService.validateMetricOnSave(this.metric);
		if (errorMessage) {
			this._canSave = true;
			this.menuPanelComponent.updateView({ showPacifier: false });
			return this.flashMessageService.popMessage(errorMessage);
		}

		this.metric.profileGroup = this.userService.currentUser?.profileGroup ? this.userService.currentUser?.profileGroup : '';
		this.metric.result.hasBeenQueried = false;
		this.metricService.saveMetric(this.metric).subscribe(
			() => {
				this._canSave = true;
				if (!settingNewMetric) this.interopService.uiManager.openTab.onMetricDefinitionChanged(this.metric.id);
				this.menuPanelComponent.updateView({ showPacifier: false });
				NavigationService.navigateTo(Pages.metrics, { selectedMetric: this.metric });
			},
			error => this.flashMessageService.popMessage(error.message, SnackbarMessageTypes.ERROR)
		);
	}

	/**
	 * This method will open up a dialog to ask user to confirm upon deleting a metric
	 */
	openDeletePrompt(): void {
		this.dialogRef = this.dialog.open(DeleteDialogComponent, { width: '480px' });
		const dialogConfig = {
			title: 'Delete Metric',
			content: 'Deleting this metric will remove it from tile, map, trend, and table views and cannot be undone.'
		};
		this.dialogRef.componentInstance.config = dialogConfig;
		this.dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
			if (isConfirmed) {
				this.deleteMetric();
			}
		});
	}

	/**
	 * On click of the delete button, the given metric will be deleted,
	 * and delete that same metric from metricService.availableMetrics object.
	 * If there is a dedicated graphic layer, delete that from all avaiable map views.
	 * Also, loopthrought all the tiles in the guiconfig to delete the matching metric.
	 * Lastly, need to delete that same metric from the databse.
	 */
	deleteMetric(): void {
		this.menuPanelComponent.updatePacifier(true);

		this.metricService.deleteMetric(this.metric).subscribe(
			async (metricId: string) => {
				// first remove the canvas.tabs
				Object.values(this.guiConfigService.availableConfigurations).forEach(config => {
					config.canvasList.forEach(canvas => {
						const metricName = this.metricService.getMetricById(metricId).definition.name;
						this.canvasService.unlinkMetricFromCanvas(metricId, metricName, canvas);
					});
				});
				// then remove from the tile list
				this.tileService.deleteMetricFromAllTiles(metricId);
				// then remove the metric from the available metrics
				const indexToRemove = this.metricService.availableMetrics.findIndex(metric => metric.id === metricId);
				this.metricService.availableMetrics.splice(indexToRemove, 1);
				NavigationService.navigateBackTo(Pages.metrics, { selectedMetric: null });
			},
			error => {
				this.menuPanelComponent.updatePacifier(false);
				this.flashMessageService.popMessage('Error while deleting metric from server', SnackbarMessageTypes.ERROR);
				console.error(error);
			}
		);
	}

	goGoToTimeFrameSetting() {
		NavigationService.navigateTo(Pages.timeframefilter, { timeFrameType: 'metric', metric: this.metric, timeframeFilter: this.timeFrameFilter });
	}

	goTo(address: string) {
		switch (address) {
			case 'source':
				NavigationService.navigateTo(Pages.metricSourceTypes, { metric: this.metric });
				break;
			case 'icon':
				NavigationService.navigateTo(Pages.metricIconList, { metric: this.metric });
				break;
			case 'query':
				this.onGoToQuery();
				break;
			case 'timeframe':
				if (this.disableTimeFrame()) return;
				this.goGoToTimeFrameSetting();
				break;
			case 'displayValueSettings':
				this.onGoToDisplayValueSettings();
				break;
			case 'menupanelsettings':
				this.onGoToMenuPanelSettings();
				break;
			case 'defaulttrendsettings':
				this.onGoToDefaultTrendSettings();
				break;
		}
	}

	getQuery() {
		if (this.query) return this.query;
		if (!this.metric) return null;
		if (!this.dataChannel) return 'error: no data channel found';
		if (this.dataChannel.channelType === ChannelTypes.WorkOrder && this.dataChannel.isAdvancedWorkOrder) return this.metric.definition.query ? this.metric.definition.query.mode : null;
		if (this.metric.definition.query && this.metric.definition.query.mode === QueryMode.manualQuery) {
			if ((this.metric.definition.query as CustomQuery).workOrderQueryString) return this.query = (this.metric.definition.query as CustomQuery).workOrderQueryString;
			if ((this.metric.definition.query as CustomQuery).relatedTableQueryString) return this.query = (this.metric.definition.query as CustomQuery).relatedTableQueryString;
			if ((this.metric.definition.query as CustomQuery).mapLayerQueryString) return this.query = (this.metric.definition.query as CustomQuery).mapLayerQueryString;
		}
		if (!this.metric.definition.query) {
			switch (this.metric.definition.source.type) {
				case ChannelTypes.Asset:
					this.metric.definition.query = new AssetQuery();
					break;
				case ChannelTypes.History:
					this.metric.definition.query = new HistoryQuery();
					break;
				case ChannelTypes.WorkOrder:
					if (this.dataChannel.isAdvancedWorkOrder) {
						this.metric.definition.query = new AWOQuery();
						break;
					}
					this.metric.definition.query = new SWOQuery();
			}
		}
		const queryBuilder = new QueryBuilder((this.metric.definition.query as AGSQuery).queryStatementList);
		const { assetQuery, historyQuery, standardWorkOrderQuery } = queryBuilder.generateQueries();
		return (this.query = assetQuery + historyQuery + standardWorkOrderQuery);
	}

	showDisplayValueSettings(): boolean {
		if (
			this.metric.definition.source.type === ChannelTypes.WorkOrder &&
			(this.metric.definition.source.workOrderSourceType === WorkOrderSourceType.employees ||
				this.metric.definition.source.workOrderSourceType === WorkOrderSourceType.equipment ||
				this.metric.definition.source.workOrderSourceType === WorkOrderSourceType.materials ||
				this.metric.definition.source.workOrderSourceType === WorkOrderSourceType.vendors)
		) {
			return true;
		}
		if (!this.metric.isNew) return true;
		if (!this.dataChannel) return false;
		if (!this.getQuery()) return false;
		if (this.dataChannel.channelType === ChannelTypes.WorkOrder) {
			if (!this.dataChannel.isAdvancedWorkOrder) return true;
			return false;
		}
		return true;
	}

	showTimeFrame() {
		return this.showDescriptionField();
	}

	disableTimeFrame() {
		return false;
	}

	showDescriptionField(): boolean {
		let showDescription = false;
		if (this.showDisplayValueSettings() === false && this.metric.definition.query && this.metric.definition.query.mode && this.dataChannel && this.dataChannel.isAdvancedWorkOrder) {
			showDescription = true;
		}
		if (this.showDisplayValueSettings() === true) showDescription = true;

		if (showDescription && !this.iconRequested && !this.metric.definition.symbolIconUrl) {
			this.metricService.setIcon(this.metric);
			this.iconRequested = true;
		}

		return showDescription;
	}

	setDisplayField(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!this.metric.definition.menuPanelSettings.displayField) return resolve(false);
			if (this.metric.definition.menuPanelSettings.displayField.name) return resolve(false);
			if (this.dataChannel.channelType === ChannelTypes.WorkOrder && this.dataChannel.isAdvancedWorkOrder) {
				return resolve(false);
			}

			let uniqueFieldName, attributes;
			if (this.dataChannel.channelType === ChannelTypes.Asset) {
				attributes = this.dataChannel.attributes as ArcGISAssetChannelAttributes;
				uniqueFieldName = attributes.uniqueFieldName;
			} else if (this.dataChannel.channelType === ChannelTypes.History) {
				attributes = this.dataChannel.attributes as ArcGISHistoryChannelAttributes;
				uniqueFieldName = attributes.assetIdFieldName;
			} else {
				uniqueFieldName = 'workorderkey';
			}
			const fields = this.interopService.arcGISManager.getArcGisFields(this.metric);
			this.metric.definition.menuPanelSettings.displayField = fields.find(field => field.name.toLowerCase() === uniqueFieldName.toLowerCase());
			return resolve(true);
		});
	}

	backgroundColorForDescriptionControl() {
		if (!this.showDisplayValueSettings()) return 'dark-gray-background';
		return 'light-gray-background';
	}

	getIcon() {
		if (!this.metric.definition.symbolIconUrl) return '';
		return this.metric.definition.symbolIconUrl;
	}

	getMetricDisplayFieldName() {
		if (!this.metric.definition.menuPanelSettings) return '';
		if (!this.metric.definition.menuPanelSettings.displayField) return '';
		return this.metric.definition.menuPanelSettings.defaultSortField.omniName;
	}
}
