export class MapSelectionMode {
	name: string;
	displayText: string;
	icon: string;
}

export enum MapSelectionModes {
	'pan' = 'pan',
	'metrics' = 'metrics',
	'assets' = 'assets'
}
