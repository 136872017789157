import { EquipmentCost } from './equipment-cost.model';
import { EquipmentCostContract } from '../../contracts/work-order';

export class EquipmentCosts extends Array<EquipmentCost> {
	static fromContracts(equipmentCostContracts: EquipmentCostContract[]) {
		const equipmentCosts = new EquipmentCosts();
		for (const equipmentCostContract of equipmentCostContracts) {
			const equipmentCost = EquipmentCost.fromContract(equipmentCostContract);
			equipmentCosts.push(equipmentCost);
		}
		return equipmentCosts;
	}

	static fromModels(equipmentCostModels: EquipmentCost[]): EquipmentCosts {
		const equipmentCosts = new EquipmentCosts();
		for (const equipmentCost of equipmentCostModels) equipmentCosts.push(equipmentCost);
		return equipmentCosts;
	}

	getContracts = () => {
		const equipmentCostContracts = new Array<EquipmentCostContract>();
		for (const equipmentCost of this) {
			const equipmentCostContract = equipmentCost.getContract();
			equipmentCostContracts.push(equipmentCostContract);
		}
		return equipmentCostContracts;
	};

	isDirty = () => !!this.filter(field => field.isDirty).length;
}
