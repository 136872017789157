import { MetricIcon } from './metric-icon.model';
import { Channel } from './channel.model';
import { MetricSource } from './metric-source.model';
import { MenuPanelSettings } from './menu-panel-settings.model';
import { DisplayValueSettings } from './display-value-settings.model';
import { DefaultTrendSettings } from './default-trend-settings.model';
import { ChannelTypes } from './channel-type.enum.model';
import { Color } from './sedaru-config/style.model';
import { MetricQuery, MetricTypes, QueryField, QueryMode } from './metric-query.model';
import { HistoryQuery } from './history-query.model';
import { AssetQuery } from './asset-query.model';
import { AWOQuery } from './awo-query.model';
import { SWOQuery } from './swo-query.model';
import { CustomQuery } from './custom-query.model';
import { WorkOrderChannelAttributes } from './work-order-channel-attributes.model';
import { WorkOrderModeType } from './work-order-mode-type.enum';
import { TimeframeFilter } from './time-frame/timeframe-filter.model';
import { TimeframeDefinitionContract } from './time-frame/timeframe-definition-contract';
import { TimeframeFilterContract } from './time-frame/timeframe-filter-contract';
import { MetricDependencies } from './metric-dependencies.model';
import { AssetChannelAttributes } from './asset-channel-attributes.model';
import { ArcGISHistoryChannelAttributes } from './arc-gis-history-channel-attributes.model';
import { Credentials } from './credentials.model';
import { DataSource } from './data-source.model';

/**
 * The model of an OMNI metric.
 */
export class MetricDefinition {
	get isWorkOrderMetric(): boolean {
		return this.source.type === ChannelTypes.WorkOrder;
	}

	get isAssetMetric(): boolean {
		return this.source.type === ChannelTypes.Asset;
	}
	/**
	 * holds metric name
	 */
	public name: string;

	/**
	 * holds metric data source
	 */
	public source: MetricSource;

	/**
	 * deprecated
	 */
	public icon?: MetricIcon;

	/** the icon without border or background */
	public iconUrl: string;

	/** the icon with border and background */
	public symbolIconUrl?: string;

	public backgroundColor?: Color;

	/**
	 * the asset channel Id
	 */
	public assetChannelId?: string;

	/**
	 * holds the metric asset channel values
	 */
	public assetChannel?: Channel;

	/**
	 * the history channel Id
	 */
	public historyChannelId?: string;

	/**
	 * holds the metric history channel values
	 */
	public historyChannel?: Channel;

	/**
	 * the work order channel Id
	 */
	public workOrderChannelId?: string;

	/**
	 * holds the metric workOrder channel values
	 */
	public workOrderChannel?: Channel;

	/**
	 * avaialble channels
	 */
	public availableAssetChannels?: Channel[];

	/**
	 * query model that contains all the statement component for the asset source
	 */
	public query?: MetricQuery;

	/** awo, swo, history or asset */
	public type: MetricTypes;

	private _timeFrameFilter;
	public get timeFrameFilter(): TimeframeFilter {
		if (!this._timeFrameFilter) this._timeFrameFilter = new TimeframeFilter();
		return this._timeFrameFilter;
	}
	public set timeFrameFilter(value: TimeframeFilter) {
		this._timeFrameFilter = value;
	}

	/**
	 * Holds the object for sort settings of the tile content menu panel. eg., the field to be displayed in the list,
	 * (table display field), the sort order for the list (default sort order) etc.
	 */
	public menuPanelSettings?: MenuPanelSettings;

	/**
	 * Holds the object for default settings of the display value after the metric is loaded in it.
	 */
	public displayValueSettings?: DisplayValueSettings;

	/**
	 * The default setting for the trend view
	 */
	public defaultTrendSettings?: DefaultTrendSettings;

	/**
	 * The refresh frequency of the metric
	 */
	public refreshFrequency?: number;

	/**
	 * The data that the metric was created
	 */
	public createdDate?: string;

	constructor() {
		this.name = '';
		this.source = new MetricSource();
		this.menuPanelSettings = new MenuPanelSettings();
		this.displayValueSettings = new DisplayValueSettings();
		this.defaultTrendSettings = new DefaultTrendSettings();
		this.refreshFrequency = 0;
	}

	static getAssetChannels(dependencies: MetricDependencies): Channel[] {
		const allAssetChannels = new Array<Channel>();
		for (const flatChannel of dependencies.assetChannels) {
			const assetChannel = Channel.fromFlatChannel(flatChannel);
			const flatDataSource = dependencies.dataSources.find(ds => ds.legacyId === assetChannel.dataSourceLegacyId);
			if (!flatDataSource) continue;

			const flatProxy = dependencies.proxies.find(p => p.legacyId === flatDataSource.proxyLegacyId);
			assetChannel.dataSource = DataSource.fromFlatDataSource(flatDataSource, flatProxy);
			if (assetChannel.dataSource.proxy) {
				assetChannel.dataSource.proxy.credentials = dependencies.credentials.find(cr => cr.legacyId === assetChannel.dataSource.proxy.credentialsLegacyId) as Credentials;
			}
			assetChannel.dataSource.credentials = dependencies.credentials.find(cr => cr.legacyId === assetChannel.dataSource.credentialsLegacyId) as Credentials;

			allAssetChannels.push(assetChannel);
		}

		return allAssetChannels;
	}

	initialize?(metricDefinition: MetricDefinition) {
		if (metricDefinition.assetChannel || metricDefinition.assetChannelId) {
			this.assetChannel = metricDefinition?.assetChannel;
			this.assetChannelId = metricDefinition?.assetChannel?.id;
			this.type = MetricTypes.asset;
		}

		if (metricDefinition.historyChannel || metricDefinition.historyChannelId) {
			this.historyChannel = metricDefinition?.historyChannel;
			this.historyChannelId = metricDefinition?.historyChannel?.id;
			this.type = MetricTypes.history;
		}

		if (metricDefinition.workOrderChannel || metricDefinition.workOrderChannelId) {
			this.workOrderChannel = metricDefinition?.workOrderChannel;
			this.workOrderChannelId = metricDefinition?.workOrderChannel?.id;
			if ((metricDefinition?.workOrderChannel?.attributes as WorkOrderChannelAttributes)?.workOrderMode === WorkOrderModeType.Advanced) this.type = MetricTypes.awo;
			else this.type = MetricTypes.swo;
		}

		if (this.type === MetricTypes.asset && !this.assetChannel) {
			throw new Error('Asset channel reference not found');
		} else if (this.type === MetricTypes.history && (!this.historyChannel || !this.assetChannel)) {
			throw new Error('History channel reference not found');
		} else if ((this.type === MetricTypes.swo || this.type === MetricTypes.awo) && !this.workOrderChannel) {
			throw new Error('Workorder channel refrence not found');
		}

		if (metricDefinition.createdDate) {
			this.createdDate = metricDefinition.createdDate;
		}
		this.name = metricDefinition ? metricDefinition.name : '';
		this.source.initialize(metricDefinition.source);
		this.icon = metricDefinition.icon;
		this.initializeQuery(metricDefinition.query, this.source);
		this.menuPanelSettings.initialize(metricDefinition.menuPanelSettings);
		this.displayValueSettings.initialize(metricDefinition.displayValueSettings);
		this.defaultTrendSettings.initialize(metricDefinition.defaultTrendSettings);
		const contractObject: any = metricDefinition.timeFrameFilter;
		this.initializeTimeFrame(contractObject);
		this.refreshFrequency = metricDefinition.refreshFrequency ? metricDefinition.refreshFrequency : 0;
		if (metricDefinition.backgroundColor) this.backgroundColor = metricDefinition.backgroundColor; // TO DO: check why metric from server comes with no background
		if (metricDefinition.availableAssetChannels) this.availableAssetChannels = metricDefinition.availableAssetChannels;
	}

	loadWithMetricDependencies(dependencies: MetricDependencies, metricDefinition: MetricDefinition) {
		this.source.initialize(metricDefinition.source);
		switch (this.source.type) {
			case ChannelTypes.Asset:
				this.type = MetricTypes.asset;
				if (!this.source.assetType) throw new Error('Metric definition of type asset is missing asset type.');
				this.setAssetChannel(dependencies);
				break;
			case ChannelTypes.History:
				this.type = MetricTypes.history;
				if (!this.source.assetType) throw new Error('Metric definition of type history is missing asset type.');
				this.setAssetChannel(dependencies);
				this.setHistoryChannel(dependencies);
				break;
			case ChannelTypes.WorkOrder:
				this.setWorkOrderChannel(dependencies);
		}

		if (metricDefinition.createdDate) this.createdDate = metricDefinition.createdDate;
		this.name = metricDefinition ? metricDefinition.name : '';
		this.icon = metricDefinition.icon;
		this.initializeQuery(metricDefinition.query, this.source);
		this.menuPanelSettings.initialize(metricDefinition.menuPanelSettings);
		this.displayValueSettings.initialize(metricDefinition.displayValueSettings);
		this.defaultTrendSettings.initialize(metricDefinition.defaultTrendSettings);
		const contractObject: any = metricDefinition.timeFrameFilter;
		this.initializeTimeFrame(contractObject);
		this.refreshFrequency = metricDefinition.refreshFrequency ? metricDefinition.refreshFrequency : 0;
		if (metricDefinition.backgroundColor) this.backgroundColor = metricDefinition.backgroundColor; // TO DO: check why metric from server comes with no background
		if (metricDefinition.availableAssetChannels) this.availableAssetChannels = metricDefinition.availableAssetChannels;
	}

	initializeTimeFrame(timeframeFilterContract: TimeframeFilterContract) {
		if (!timeframeFilterContract || !timeframeFilterContract.timeFrameDefinition) return;

		const timeFrameFilter = TimeframeFilter.fromContract(timeframeFilterContract);
		this._timeFrameFilter = timeFrameFilter;
	}

	update?(metricDefinition: MetricDefinition) {
		this.name = metricDefinition ? metricDefinition.name : '';
		this.source = new MetricSource();
		this.source.initialize(metricDefinition.source);
		this.icon = metricDefinition.icon;
		this.initializeQuery(metricDefinition.query, this.source);
		this.menuPanelSettings = new MenuPanelSettings();
		this.displayValueSettings = new DisplayValueSettings();
		this.defaultTrendSettings = new DefaultTrendSettings();
		this.menuPanelSettings.initialize(metricDefinition.menuPanelSettings);
		this.displayValueSettings.initialize(metricDefinition.displayValueSettings);
		this.defaultTrendSettings.initialize(metricDefinition.defaultTrendSettings);
		this.refreshFrequency = metricDefinition.refreshFrequency ? metricDefinition.refreshFrequency : 0;
		if (metricDefinition.backgroundColor) this.backgroundColor = metricDefinition.backgroundColor; // TO DO: check why metric from server comes with no background
	}

	/**
	 * Get the channel associated with the source.
	 * If source is not provided, the channel type of the metric source is used.
	 */
	public getChannel(source?: ChannelTypes): Channel {
		let channel: Channel = null;
		switch (source ?? this.source.type) {
			case ChannelTypes.History:
				channel = this.historyChannel;
				break;
			case ChannelTypes.WorkOrder:
				channel = this.workOrderChannel;
				break;
			default:
				channel = this.assetChannel;
				break;
		}
		return channel;
	}

	private setAssetChannel(dependencies: MetricDependencies) {
		const flatChannel = dependencies.assetChannels.find(ch => (ch.attributes as AssetChannelAttributes)?.assetType?.toLowerCase() === this.source.assetType?.toLowerCase());
		this.assetChannel = Channel.fromFlatChannel(flatChannel);
		const flatDataSource = dependencies.dataSources.find(ds => ds.legacyId === this.assetChannel.dataSourceLegacyId);
		const flatProxy = dependencies.proxies.find(p => p.legacyId === flatDataSource.proxyLegacyId);
		this.assetChannel.dataSource = DataSource.fromFlatDataSource(flatDataSource, flatProxy);
		if (this.assetChannel.dataSource.proxy) {
			this.assetChannel.dataSource.proxy.credentials = dependencies.credentials.find(cr => cr.legacyId === this.assetChannel.dataSource.proxy.credentialsLegacyId) as Credentials;
		}
		this.assetChannel.dataSource.credentials = dependencies.credentials.find(cr => cr.legacyId === this.assetChannel.dataSource.credentialsLegacyId) as Credentials;
	}

	private setHistoryChannel(dependencies: MetricDependencies) {
		const historyFlatChannel = dependencies.historyChannels.find(ch => (ch.attributes as ArcGISHistoryChannelAttributes)?.assetType?.toLowerCase() === this.source.assetType?.toLowerCase());
		this.historyChannel = Channel.fromFlatChannel(historyFlatChannel);
		const historyFlatDataSource = dependencies.dataSources.find(ds => ds.legacyId === this.historyChannel.dataSourceLegacyId);
		const flatProxy = dependencies.proxies.find(p => p.legacyId === historyFlatDataSource.proxyLegacyId);
		this.historyChannel.dataSource = DataSource.fromFlatDataSource(historyFlatDataSource, flatProxy);
		if (this.historyChannel.dataSource.proxy) {
			this.historyChannel.dataSource.proxy.credentials = dependencies.credentials.find(cr => cr.legacyId === this.historyChannel.dataSource.proxy.credentialsLegacyId) as Credentials;
		}
		this.historyChannel.dataSource.credentials = dependencies.credentials.find(cr => cr.legacyId === this.historyChannel.dataSource.credentialsLegacyId) as Credentials;
	}

	private setWorkOrderChannel(dependencies: MetricDependencies) {
		this.workOrderChannel = Channel.fromFlatChannel(dependencies.workOrderChannel);
		if ((this.workOrderChannel.attributes as WorkOrderChannelAttributes).workOrderMode === WorkOrderModeType.Advanced) this.type = MetricTypes.awo;
		else this.type = MetricTypes.swo;
		const flatDataSource = dependencies.dataSources.find(ds => ds.legacyId === this.workOrderChannel.dataSourceLegacyId);
		const flatProxy = dependencies.proxies.find(p => p.legacyId === flatDataSource.proxyLegacyId);
		this.workOrderChannel.dataSource = DataSource.fromFlatDataSource(flatDataSource, flatProxy);
		if (this.workOrderChannel.dataSource.proxy) {
			this.workOrderChannel.dataSource.proxy.credentials = dependencies.credentials.find(cr => cr.legacyId === this.workOrderChannel.dataSource.proxy.credentialsLegacyId) as Credentials;
		}
		this.workOrderChannel.dataSource.credentials = dependencies.credentials.find(cr => cr.legacyId === this.workOrderChannel.dataSource.credentialsLegacyId) as Credentials;
	}

	private initializeQuery(queryContract: MetricQuery, source?: MetricSource) {
		if (!queryContract) return;
		if (queryContract.mode === QueryMode.manualQuery) return (this.query = new CustomQuery(queryContract as CustomQuery));
		switch (this.type) {
			case MetricTypes.asset:
				this.query = new AssetQuery(queryContract as AssetQuery);
				break;
			case MetricTypes.history:
				this.query = new HistoryQuery(queryContract as HistoryQuery);
				break;
			case MetricTypes.swo:
				this.query = new SWOQuery(queryContract as SWOQuery);
				break;
			case MetricTypes.awo:
				this.query = new AWOQuery(queryContract as AWOQuery, source);
				break;
		}
	}

}

export class MetricDefinitionInput {
	public id?: string;
	/**
	 * holds metric name
	 */
	public name: string;
	/**
	 * holds metric data source
	 */
	public source: MetricSource;
	/**
	 * deprecated!
	 */
	public icon?: MetricIcon;
	/**
	 * holds the metric asset channel's Id
	 */
	public assetChannelId?: string;
	/**
	 * holds the metric history channel's Id
	 */
	public historyChannelId?: string;
	/**
	 * holds the metric work order channel's Id
	 */
	public workOrderChannelId?: string;
	/**
	 * query model that contains all the statement component for the asset source
	 */
	public query: MetricQuery;

	public type: MetricTypes;

	public timeFrameSettings?: QueryField;

	public timeFrameDefinition?: TimeframeDefinitionContract;

	public timeFrameFilter?: TimeframeFilterContract;
	/**
	 * Holds the object for sort settings of the tile content menu panel. eg., the field to be displayed in the list,
	 * (table display field), the sort order for the list (default sort order) etc.
	 */
	public menuPanelSettings: MenuPanelSettings;
	/**
	 * Holds the object for default settings of the display value after the metric is loaded in it.
	 */
	public displayValueSettings: DisplayValueSettings;
	/**
	 * The default setting for the trend view
	 */
	public defaultTrendSettings: DefaultTrendSettings;
	/**
	 * The refresh frequency of the metric
	 */
	public refreshFrequency?: number;

	/**
	 * The date that the metric was created
	 */
	public createdDate?: string;

	/**
	 * The profile group of the customer
	 */
	public profileGroup?: string;
}
