import { TokenPair } from './token-pair';
import { OAuthService } from './oauth-service';
import * as SedaruUtils from 'sedaru-util';

export class IdentityController {
	private _oauthService: OAuthService;
	private _sedaruOAuthToken: TokenPair;
	public get sedaruOAuthToken(): TokenPair {
		return this._sedaruOAuthToken;
	}

	private _onTokenRefreshed: SedaruUtils.InvokableEvent;
	public get onTokenRefreshed(): SedaruUtils.InvokableEvent {
		if (!this._onTokenRefreshed) this._onTokenRefreshed = new SedaruUtils.InvokableEvent();

		return this._onTokenRefreshed;
	}

	constructor(oauthService: OAuthService, initialToken: TokenPair) {
		this._oauthService = oauthService;
		this._sedaruOAuthToken = initialToken;
	}

	async handleExpiredTokenAsync(): Promise<boolean> {
		const response = await this._oauthService.refreshTokenAsync(this.sedaruOAuthToken);

		if (!response.success) {
			return false;
		}

		this._sedaruOAuthToken = response.result;
		this.onTokenRefreshed.invoke(this, this.sedaruOAuthToken);
		return true;
	}
}
