import { ValueFormatType } from './value-format-type.enum';

export class FormatProperty {
	id: number;
	type: ValueFormatType;
	decimals: number;
	format: string;

	getFormatType(type) {
		let formatType = ValueFormatType.TextFormat;
		switch (type)  {
			case 'MakeLowerCase':
				formatType = ValueFormatType.MakeLowerCase;
				break;
			case 'MakeUpperCase':
				formatType = ValueFormatType.MakeUpperCase;
				break;
			case 'Currency':
				formatType = ValueFormatType.Currency;
				break;
			case 'WholeNumber':
				formatType = ValueFormatType.WholeNumber;
				break;
			case 'FloatingPoint':
				formatType = ValueFormatType.FloatingPoint;
				break;
			case 'DateFormat':
				formatType = ValueFormatType.DateFormat;
				break;
			case 'TextFormat':
			default: {
				formatType = ValueFormatType.TextFormat;
				break;
			}
		}

		return formatType;
	}
}
