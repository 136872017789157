import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CanvasMapService } from './canvas-map.service';
import { Canvas, CanvasMode } from '../../../omni-model/canvas.model';
import { MapViewInput } from './map-view-input.model';
import { EsriSdkService } from './esri-sdk.service';
import esri = __esri; // Esri TypeScript Types
import { OmniMapGraphicsService } from './omni-map-graphics.service';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { ArcGISAssetChannelAttributes, Metric, MetricTile, Color, ChannelTypes, Channel } from 'models';
import { MapVisibleLayer } from 'omni-model/map-visible-layer.model';
import { UserService } from 'app/user/user.service';
import { CanvasSubViewBaseComponent } from '../canvas/canvas-sub-view-base.component';
import { LoadingSpinnerComponent } from 'app/ui-components/loading-spinner/loading-spinner.component';
import { GlobalConfig } from 'models/global-config.model';
import { MapTooltipComponent } from './map-tooltip/map-tooltip.component';
import { MapSelectionModes } from 'app/ui-components/map-selection-toolbar/map-selection-mode.model';
import { MapSelectionToolbarComponent } from 'app/ui-components/map-selection-toolbar/map-selection-toolbar.component';
import { interval, Subscription } from 'rxjs';
import { PNGManager } from 'app/ui-components/png-manager';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { MapActionType, MetricViewEventArg } from 'app/metric/metric-view-event-arg.model';
import { MetricViewService } from 'app/metric/metric-view.service';
import { filter, take } from 'rxjs/operators';

/**
 * The OMNI Map Component displays the map in a canvas.
 */
@Component({
	selector: 'app-canvas-map',
	templateUrl: './canvas-map.component.html',
	styleUrls: ['./canvas-map.component.scss']
})
export class CanvasMapComponent extends CanvasSubViewBaseComponent implements OnInit, OnDestroy {

	@Input() canvasTabAdded: CanvasTab;

	@Input() canvasTabClosed: CanvasTab;

	@Input() canvasTabSelected: CanvasTab;

	@Input() metricRemoved: any;

	/** when the user clicks the workorder icon */
	@Output() requestToAddWorkorderToMap = new EventEmitter();

	/**
	 * We are wrapping the #mapViewNode native element into the ElementRef class that
	 * is just a wrapper class for native elements.
	 */
	@ViewChild('mapViewNode', { static: true }) mapViewElm: ElementRef;

	@ViewChild('loadingPacifier', { static: true }) loadingPacifier: LoadingSpinnerComponent;

	@ViewChild(MapTooltipComponent, { static: true }) mapTooltip: MapTooltipComponent;

	@ViewChild(MapSelectionToolbarComponent, { static: true }) selectionToolBar: MapSelectionToolbarComponent;


	/**
	 * ESRI Map SDK is a custom "Namespace".  It holds the different ESRI classes that require loading.
	 * Once load is complete the classes can be utilized to modify and visualize the map.
	 */
	EsriMapSdk;

	/**
	 * advanced drawing capabilities for creating temporary graphics with different geometries
	 */
	sketchViewModel: __esri.SketchViewModel;
	/**
	 * The time Id for the extent timer
	 */
	extentTimerId;
	/**
	 * Needed to allow us to loop through the map layers only after the map view has loaded
	 */
	mapViewLoaded = false;

	mapLayersButtonHovered = false;

	workorderButtonHovered = false;

	mapServiceSubscription: Subscription;
	esriSdkSubscription: Subscription;
	private subscription: Subscription;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {CanvasMapService} mapService - for creating and updating the omni esri map instances.
	 */
	constructor(
		private mapService: CanvasMapService,
		private esriSdkService: EsriSdkService,
		private graphicsService: OmniMapGraphicsService,
		private userService: UserService,
		private interopService: OmniInteropService,
		private workorderFactory: WorkOrderFactory,
		private metricViewService: MetricViewService
	) {
		super();
	}


	/**
	 * Set up a listener to update the map when things change. It also loads the map when the service is ready.
	 */
	ngOnInit() {
		this.loadingPacifier.show = true;
		if (this.esriSdkService.esriMapSdk) {
			this.init();
		} else {
			this.esriSdkSubscription = this.esriSdkService.esriSdkReadyEvent.subscribe(() => this.init());
		}
	}

	public init() {
		this.loadMap(this.canvas).then(() => {
			this.canvas.mapView.map.allLayers.on('change', event => {
				if (this.canvas.tabs.length) this.reorderMapLayers();
			});
			this.enablePolygon();
			this.loadingPacifier.show = false;
			this.canvas.tabs.forEach(t => this.plotMetricResultsForCanvasTab(t));
		});
		this.EsriMapSdk = this.esriSdkService.esriMapSdk;

		const tabs = this.canvas?.tabs;
		const layerId = this.getTabLayerId();
		// due to the way the plot method is written, there is no way to
		// confirm whether the plotting operation finished. We are getting
		// around by triggering an event every 1 second (or 10 seconds max)
		// until the tab layer has items. At this point we subscribe to the
		// observable which receives the last event.
		interval(1000).pipe(
						filter((v, idx) => {
							const match = 	(!(tabs?.length > 0))
											|| this.graphicsService.layerHasGraphicItems(this.canvas, layerId)
											|| (idx == 10);
							return match;
						})
						, take(1)
					)
					.subscribe(t => {
						this.subscription = this.metricViewService
												.getMetricEventStream()
												.pipe(
													filter((e, i) => (this.isCanvasActiveWithMode()) && (e.metric == this.activeTabMetric))
												)
												.subscribe(e => this.onMetricEvent(e));
					});


	}

	ngOnDestroy() {
		// Clears the timer
		clearTimeout(this.extentTimerId);
		if (this.esriSdkSubscription) this.esriSdkSubscription.unsubscribe();
		if (this.mapServiceSubscription) this.mapServiceSubscription.unsubscribe();
		this.esriSdkSubscription = undefined;
		this.mapServiceSubscription = undefined;

		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	private onMetricEvent(eventArg: MetricViewEventArg): void {
		try {

			switch (eventArg.mapAction) {
				case MapActionType.ClearHighlight:

					this.handleClearItem(eventArg);
					break;

				case MapActionType.ZoomOnItem:
					this.handleZoomOnItem(eventArg);
					break;

				case MapActionType.Highlight:
					this.handleHighlightItem(eventArg);
					break;

				case MapActionType.PanToItem:
					// TODO: intentionally left blank until feature is required.
					break;
				default:
					break;
			}

		} catch (ex) {
			console.error(ex);
		}

	}

	private handleClearItem(eventArg: MetricViewEventArg) {
		this.mapTooltip.close();
		this.graphicsService.clearHighlightedFeature(this.canvas);
	}

	private handleHighlightItem(eventArg: MetricViewEventArg) {
		this.mapTooltip.close();
		const layerId = this.getTabLayerId();
		const channel: Channel = this.activeTabMetric.definition.getChannel();
		const idField = eventArg.metric.getIdFieldName();
		const idValue = eventArg.metric.getRecordId(eventArg.feature);
		this.graphicsService
			.highlightFeatureByAttribute(idValue, idField, layerId, this.canvas, true)
			.then(graphic => {
				if (graphic) {
					this.handleShowToolTip(eventArg, graphic);
				}
			});
	}

	private handleShowToolTip(eventArg: MetricViewEventArg, graphicFeature?: esri.Graphic) {
		if (eventArg.metricRecord.resolver) {
			if (!graphicFeature) {
				graphicFeature = this.GetGraphicInfoFromEvent(eventArg)?.graphicFeature;
			}

			if (graphicFeature?.geometry) {
				const point = this.interopService.omniDomain.esriSdkService.esriMapSdk.Projection.project(graphicFeature.geometry, this.canvas.mapView.spatialReference);
				eventArg.feature.geometry = point;
				this.mapTooltip.location = point;
				const cloneDeep = require('clone');
				const resolver = cloneDeep(eventArg.metricRecord.resolver);
				this.mapTooltip.resolver = resolver;
				this.mapTooltip.resolver.showDivider = item => false;
				this.mapTooltip.resolver.onIsDisabled = item => true;
				this.mapTooltip.open();
			}
		}
	}

	private GetGraphicInfoFromEvent(eventArg: MetricViewEventArg) {
		const layerId = this.getTabLayerId();
		const channel: Channel = this.activeTabMetric.definition.getChannel();
		const idField = eventArg.metric.getIdFieldName();
		const idValue = eventArg.metric.getRecordId(eventArg.feature);
		const graphicFeature = this.graphicsService.getGraphicFeature(idValue, idField, layerId, this.canvas);
		return graphicFeature;
	}

	private handleZoomOnItem(eventArg: MetricViewEventArg) {
		let geometry = eventArg?.feature?.geometry;
		if (!(geometry?.cache)) {
			geometry = null;
		}

		let graphicInfo: { graphicFeature: esri.Graphic, layerView: esri.FeatureLayerView} = null;
		if (!geometry) {
		    graphicInfo = this.GetGraphicInfoFromEvent(eventArg);
			geometry = graphicInfo?.graphicFeature?.geometry;
		}

		if (geometry) {
			const mapTarget = {
				target: geometry,
				scale: 1000
			};
			const mapViewOptions: esri.GoToOptions2D = {
				duration: 1000,
				easing: 'ease-out'
			};

			this.canvas?.mapView?.goTo(mapTarget, mapViewOptions);

			if (graphicInfo?.graphicFeature) {
				this.handleShowToolTip(eventArg, graphicInfo.graphicFeature);
				if (graphicInfo.layerView) {
					this.graphicsService.highlightFeature(this.canvas, graphicInfo.graphicFeature, graphicInfo.layerView);
				}
			}
		}
	}

	/**
	 * Updates the map by creating a new MapView when the view is ready.
	 */
	private loadMap(canvas: Canvas) {
		return new Promise(async resolve => {
			const mapViewInputProperties: MapViewInput = {
				mapElement: this.mapViewElm.nativeElement,
				baseMapType: canvas.map.baseMapId,
				mapName: canvas.map.name,
				canvasId: canvas.id,
				baseVisibleLayers: canvas.map.baseVisibleLayers && canvas.map.baseVisibleLayers.length ? canvas.map.baseVisibleLayers : this.mapService.getGlobalConfigBaseLayers()
			};

			if (canvas.map.visibleLayers && canvas.map.visibleLayers.length) {
				mapViewInputProperties['visibleLayers'] = canvas.map.visibleLayers;
			}

			this.canvas.mapView = await this.mapService.loadMapIntoView(mapViewInputProperties);

			if (!canvas.map.baseMapId) {
				// @ts-ignore
				this.canvas.mapView.map.basemap = '';
			}

			this.canvas.mapView.navigation.browserTouchPanEnabled = false;
			this.canvas.mapView.when(mapViewLoaded => {
				this.mapViewLoaded = mapViewLoaded.ready;

				mapViewLoaded.on('click', event => {
					this.mapTooltip.close();
				});

				this.canvas.map.legends = this.mapService.initLegends(this.canvas);
				this.canvas.map.pacifier = this.loadingPacifier;

				this.EsriMapSdk.WatchUtils.whenTrue(this.canvas.mapView, 'stationary', () => {
					this.extentChangeEvent(this.canvas.mapView);
				});

				resolve(true);
			});

			this.graphicsService.identifyTaskOnMapView(this.canvas);
		});
	}

	getMapServiceLayerIndex() {
		const visibileLayers = [];
		const config: GlobalConfig = this.userService.globalConfig;

		if (!(config && config.assetDefinitions)) return [];

		for (const assetDefinition of config.assetDefinitions) {
			if (!assetDefinition.assetChannel || !assetDefinition.assetChannel.attributes) continue;
			const assetAttr = assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
			const layerIndex = assetAttr.mapServiceLayerIndex;
			const subLayer: MapVisibleLayer = new MapVisibleLayer();
			subLayer.id = layerIndex.toString();
			subLayer.title = assetAttr.assetType;
			visibileLayers.push(subLayer);
		}

		return visibileLayers;
	}

	private getLayerByLayerId(layerId: string) {
		const canvasMap = this.canvas.mapView ? this.canvas.mapView.map : null;
		if (!canvasMap) return;

		if (!layerId) return;

		let graphicsLayer = canvasMap.findLayerById(layerId) as esri.GraphicsLayer;

		if (graphicsLayer) {
			graphicsLayer.visible = true;
			return graphicsLayer;
		}

		graphicsLayer = new this.EsriMapSdk.GraphicsLayer();
		graphicsLayer.set('id', layerId);
		graphicsLayer.set('title', layerId);
		graphicsLayer.set('visible', true);
		canvasMap.add(graphicsLayer);

		return graphicsLayer;
	}

	onTouchMove(event: MouseEvent) {
		event.stopPropagation();
	}

	private makeGraphicLayersOpaque() {
		if (!this.canvas.mapView) return;
		this.canvas.mapView.map.layers.forEach(layer => {
			if (layer.type === 'feature' && layer.visible) {
				layer.opacity = this.graphicsService.opacityForUnfocusedLayers;
			}
		});
	}

	private async reorderMapLayers() {
		const tab = this.canvas.tabs.filter(t => t.active)[0];
		if (!tab) return;

		const layerId = this.getTabLayerId(tab);
		const indexOfLastLayer = this.canvas.mapView.map.layers.length - 1;
		const selectedLayer = this.canvas.mapView.map.findLayerById(layerId) as esri.GraphicsLayer;
		if (selectedLayer) {
			this.makeGraphicLayersOpaque();
			selectedLayer.opacity = 1;
			this.canvas.mapView.map.layers.reorder(selectedLayer, indexOfLastLayer);
		}
	}

	async plotMetricResultsForCanvasTab(canvasTab: CanvasTab) {
		if (!canvasTab.metricSubscription) return;
		const metric = canvasTab.metricSubscription.timeframedMetric.metric;
		const outfields = this.interopService.templateManager.getTemplateOutFields(metric);
		outfields.push('geometry');
		outfields.forEach(o => {
			if (!canvasTab.metricSubscription.outfields.includes(o)) canvasTab.metricSubscription.outfields.push(o);
		});
		if (!metric?.definition?.iconUrl) return; // if we don't have an icon, we don't plot (TO DO: add a default icon)
		const timeframedMetric = canvasTab.metricSubscription.timeframedMetric;
		if (!timeframedMetric.results?.length) return;
		const assetDefinition = this.userService.globalConfig.assetDefinitions.getByAssetType(metric.definition.source.assetType);
		const backgroundColor = canvasTab.tile
			? Color.fromHex(canvasTab.tile.backgroundColor)
			: assetDefinition
				? assetDefinition.style.layout.background.color
				: Color.createFromRGBA('rgb(236, 137, 56)');
		const iconUrl = await PNGManager.editImage(metric.definition.iconUrl, { backgroundColor });
		const isAWO = metric.definition.source.type === ChannelTypes.WorkOrder && this.workorderFactory.isAdvancedMode;
		const layerId = this.getTabLayerId(canvasTab);
		this.graphicsService.plotMetricResultsToMap(this.canvas, layerId, timeframedMetric.results, iconUrl, isAWO, false, outfields);
	}

	getTabLayerId(tab?: CanvasTab) {
		tab = tab ?? this.canvas.tabs?.find(t => t.active);
		if (!tab) {
			return null;
		}
		return (tab.defaultMetric) ? 'default-metric-graphic-layer' : tab.tile.id;
	}

	onTabSelected(tab: CanvasTab) {
		const indexOfLastLayer = this.canvas.mapView.map.layers.length - 1;
		const layerId = this.getTabLayerId(tab);
		const selectedLayer = this.canvas.mapView.map.findLayerById(layerId) as esri.GraphicsLayer;
		this.makeGraphicLayersOpaque();

		if (selectedLayer) {
			selectedLayer.opacity = 1;
			this.canvas.mapView.map.layers.reorder(selectedLayer, indexOfLastLayer);
		}
		this.mapTooltip.close();

		if (tab.activeClicked) {
			this.moveToTileExtent(tab);
		}
	}

	onTabAdded(canvasTab: CanvasTab) {
		this.makeGraphicLayersOpaque();
		this.mapTooltip?.close();
		this.plotMetricResultsForCanvasTab(canvasTab);
	}

	onTabClosed(tab: CanvasTab) {
		this.graphicsService.clearHighlightedFeature(this.canvas);
		this.graphicsService.removeLayerFromMap(this.canvas, this.getTabLayerId(tab));
		this.mapTooltip.close();
	}

	onTabRemoved(tab: CanvasTab) {
		this.onTabClosed(tab);
	}

	listLayers() {
		this.config.selectedCanvas = this.canvas;
		NavigationService.navigateTo(Pages.mapLayers, { canvas: this.canvas });
	}

	private extentChangeEvent(view) {
		if (view.extent) {
			this.mapService.mapExtentChange$.next({ extent: view.extent });
		}
	}

	addingWorkorderToMap = () => {
		this.canvas.map['dropWorkOrderCone'] = !this.canvas.map['dropWorkOrderCone'];
		if (this.canvas.map.eventHandlers['workOrderAssetSelectionHandler']) {
			this.graphicsService.startIdentifyTask(this.canvas);
		} else if (this.canvas.map.eventHandlers['identifyTaskHandler']) {
			this.graphicsService.startWorkOrderAssetSelection(this.canvas);
		}
	};

	protected onMetricUpdated(canvasTab: CanvasTab) {
		this.graphicsService.removeLayerFromMap(this.canvas, this.getTabLayerId(canvasTab));
		this.plotMetricResultsForCanvasTab(canvasTab);
	}

	onTabUpdated(canvasTab: CanvasTab, oldId: string) {
		if (!oldId) return;
		this.graphicsService.removeLayerFromMap(this.canvas, oldId);
	}

	private activatePolygon() {
		this.mapService.removeAllGraphicFromGraphicLayer(this.canvas, 'highlight-assets');
		this.mapService.removeAllGraphicFromGraphicLayer(this.canvas, 'polygonGraphicsLayer');
		this.canvas.isDrawPolygonActive = true;
		this.sketchViewModel.create('polygon');
	}

	private deactivatePolyigonDraw() {
		this.mapService.removeAllGraphicFromGraphicLayer(this.canvas, 'polygonGraphicsLayer');
		this.canvas.isDrawPolygonActive = false;
		this.sketchViewModel.cancel();
	}

	private enablePolygon() {
		const polygonHighlightLayer: esri.GraphicsLayer = this.getLayerByLayerId('polygonGraphicsLayer');
		if (!this.EsriMapSdk) return;

		// create a new instance of draw
		this.sketchViewModel = new this.EsriMapSdk.SketchViewModel({
			view: this.canvas.mapView,
			layer: polygonHighlightLayer,
			polygonSymbol: {
				type: 'simple-fill'
			}
		});
		// this is needed for the lasso.
		this.sketchViewModel.defaultCreateOptions.mode = 'hybrid';

		this.sketchViewModel.on('create', async event => {
			if (event.state === 'complete') {
				this.canvas.isDrawPolygonActive = false;
				this.graphicsService.clearHighlightedFeature(this.canvas);
				if (event.graphic) {
					this.loadingPacifier.show = true;
					if (this.selectionToolBar.activeMode.name === MapSelectionModes.assets) this.graphicsService.onMapAssetClicked(this.canvas, event.graphic.geometry);
					if (this.selectionToolBar.activeMode.name === MapSelectionModes.metrics) {
						const activeTab = this.canvas.tabs.find(t => t.active);
						if (!activeTab) {
							this.loadingPacifier.show = false;
							this.selectionToolBar.selectMode(MapSelectionModes.pan);
							return;
						}
						const params = {
							metric: activeTab.metric,
							extent: event.graphic.geometry
						};
						NavigationService.navigateTo(Pages.metricResults, params);
					}
					this.selectionToolBar.selectMode(MapSelectionModes.pan);
				}
			}
			if (event.state === 'cancel') {
				this.deactivatePolyigonDraw();
			}
		});
	}

	private handleAssetSelectionMode() {
		this.activatePolygon();
	}

	onMapSelectionModeChanged(mode: MapSelectionModes) {
		switch (mode) {
			case 'pan':
				this.deactivatePolyigonDraw();
				break;
			case 'assets':
				this.handleAssetSelectionMode();
				break;
			case 'metrics':
				this.activatePolygon();
				break;
		}
	}

	moveToTileExtent(tab: CanvasTab) {
		this.graphicsService.moveToLayerExtent(this.canvas, this.getTabLayerId(tab));
	}

	moveToExtent(extent) {
		this.canvas.mapView.goTo(extent, { animate: true, duration: 1000 });
	}

	async updateLayerIcons(layerId: string, imageUrl: string) {
		this.mapService.updateSymbols(layerId, imageUrl);
	}
}
