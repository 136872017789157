import { Employee } from './employee.model';
import { EmployeeContract } from '../../contracts/work-order';

export class Employees extends Array<Employee> {
	static fromContracts(employeeContracts: EmployeeContract[]) {
		const employees = new Employees();
		if (!employeeContracts) return employees;

		for (const employeeContract of employeeContracts) {
			const employee = Employee.fromContract(employeeContract);
			employees.push(employee);
		}
		return employees;
	}

	constructor() {
		super();
	}

	getById = (id: string) => this.find(employee => employee.id === id);

	getByEmployeeId = (employeeId: string) => this.find(employee => employee.employeeId.toLowerCase() === (employeeId ? employeeId.toLowerCase() : ''));

	getByEmployeeUsername = (username: string) => this.find(employee => employee.username.toLowerCase() === (username ? username.toLowerCase() : ''));
}
