<mat-card
	class="canvas-card"
	[ngClass]="{
		'cyan-border': highlightCanvas(),
		'dashed-border': canvas.mode === 'empty',
		'workorder-cursor': showWorkorderCursor
	}"
	(dragover)="allowDrop($event)"
	(drop)="onTileDropped($event, canvas)"
	(click)="selectCanvas()"
>
	<!-- canvas tab bar -->
	<app-canvas-tabs
		[config]="config"
		[canvas]="canvas"
		[showWorkorderInstructionText]="showWorkorderCursor"
		(trendActivated)="activateTrend.emit($event)"
		(tabClosed)="onTabClosed($event)"
		(tabSelected)="onTabSelected($event)"
		(tabAdded)="onTabAdded($event)"
		(tabRemoved)="onTabRemoved($event)"
		(navigateToMapList)="goToMapList.emit(canvas)"
		(metricUpdated)="metricUpdated($event)"
		(tabUpdated)="onTabUpdated($event)"
	></app-canvas-tabs>

	<!-- empty view-->
	<mat-grid-list cols="3" rowHeight="30px" class="omni-sm-font" *ngIf="canvas.mode === 'empty' && config.hasPermission">
		<mat-grid-tile matRipple (click)="goToMapList.emit(canvas)"><img src="assets/canvas-icons/map-location.png" class="omni-md-img" /> </mat-grid-tile>
		<mat-grid-tile matRipple (click)="goToTrendList.emit(canvas)"><img src="assets/canvas-icons/trend.png" class="omni-md-img"/></mat-grid-tile>
		<mat-grid-tile matRipple (click)="goToTable.emit(canvas)"
			><img src="assets/canvas-icons/grid.png" class="omni-md-img"
		/></mat-grid-tile>
		<mat-grid-tile>map</mat-grid-tile>
		<mat-grid-tile class="middle-borders">trend</mat-grid-tile>
		<mat-grid-tile>table</mat-grid-tile>
	</mat-grid-list>

	<!-- map view -->
	<app-canvas-map
		[config]="config"
		*ngIf="viewsLoaded.includes('map')"
		[attr.data-canvas-id]="canvas.position"
		[canvas]="canvas"
		[canvasTabAdded]="canvasTabAdded"
		[canvasTabClosed]="canvasTabClosed"
		[canvasTabSelected]="canvasTabSelected"
		[metricRemoved]="metricRemovedId"
		class="map-view"
		[class.hidden]="canvas.mode !== 'map'"
		(requestToAddWorkorderToMap)="toggleWorkOrderCursor()"
	>
	</app-canvas-map>

	<!-- trend view -->
	<app-canvas-trend
		[config]="config"
		*ngIf="viewsLoaded.includes('trend')"
		[attr.data-canvas-id]="canvas.position"
		[canvas]="canvas"
		[canvasTabAdded]="canvasTabAdded"
		[canvasTabClosed]="canvasTabClosed"
		[canvasTabsInitialized]="canvasTabsInitialized"
		[canvasTabSelected]="canvasTabSelected"
		[metricRemoved]="metricRemovedId"
		[class.hidden]="canvas.mode !== 'trend'"
		[displayMode]="displayMode"
		(tabClosed)="closeTab($event)"
		class="trend-view"
	>
	</app-canvas-trend>

	<!-- table view -->
	<app-canvas-table
		[config]="config"
		*ngIf="viewsLoaded.includes('table')"
		[attr.data-canvas-id]="canvas.position"
		[canvas]="canvas"
		[canvasTabAdded]="canvasTabAdded"
		[canvasTabClosed]="canvasTabClosed"
		[canvasTabsInitialized]="canvasTabsInitialized"
		[canvasTabSelected]="canvasTabSelected"
		[metricRemoved]="metricRemovedId"
		[class.hidden]="canvas.mode !== 'table'"
		class="table-view"
	>
	</app-canvas-table>
</mat-card>
