<div class="lem-menu-panel">
	<app-workorder-new-lem
		[lemMenuPanelHeader]="lemMenuPanelHeader"
		[searchFieldHeader]="searchFieldHeader"
		[field1Header]="field1Header"
		[field1Value]="field1Value"
		[field2Header]="field2Header"
		[field2Value]="field2Value"
		[taskEnabled]="taskEnabled"
		[lemList]="lemList"
		[itemKey]="itemKey"
		[taskName]="workTaskDescription"
		(itemSelected)="itemSelected($event)"
		(field2ValueChanged)="valueChanged($event)"
		[goToTask]="goToTask"
		[onSave]="onSave"
		[onDelete]="onDelete"
	></app-workorder-new-lem>
</div>
