<label class="asset-list-header">{{ addingAsset ? addWorkOrderAssetTitle : workOrderAssetTitle }}</label>
<mat-divider class="asset-list-divider"></mat-divider>
<div class="list-wrapper">
	<div class="add-asset-message" *ngIf="addingAsset">select an asset from the map</div>
	<app-omni-list [omniListItems]="assets" (itemRightClicked)="showContextMenu($event)" (rightIconClicked)="goTo($event)" (itemClicked)="assetSelected($event)"></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<label class="omni-md-font">items: {{ assets ? assets.length : 0 }} &nbsp;</label>
	</mat-card-actions>
</mat-card-footer>
