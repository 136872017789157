import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, ElementRef } from '@angular/core';
import { LEMService } from '../workorder-lem-list/lem.service';
import { LEMType, Material, WorkOrderFieldNames, Equipment, WorkOrderWrapper, EmployeeCostWrapper, EquipmentCostWrapper, MaterialCostWrapper } from 'models/work-order';
import { WorkOrderFieldProvider } from '../workorder-filter/workorder-field.provider';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';

@Component({
	selector: 'app-workorder-new-lem-menu-panel',
	templateUrl: './workorder-new-lem-menu-panel.component.html',
	styleUrls: ['./workorder-new-lem-menu-panel.component.scss']
})
export class WorkOrderNewLEMMenuPanelComponent extends MenuPanelBaseComponent {
	workOrder: WorkOrderWrapper;

	employeeCost: EmployeeCostWrapper;

	materialCost: MaterialCostWrapper;

	equipmentCost: EquipmentCostWrapper;

	lemMenuPanelHeader: string;

	searchFieldHeader: string;

	field1Header: string;

	field1Value: string;

	field2Header: string;

	field2Value: number;

	taskEnabled: boolean;

	itemKey: string;

	workTaskDescription: string;

	lemList = [];

	get alertDialog(): AlertDialogComponent {
		return this.interop.uiManager.alertDialog;
	}
	constructor(
		view: ElementRef<HTMLElement>,
		private lemService: LEMService,
		private workOrderFactory: WorkOrderFactory,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		private interop: OmniInteropService
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs): void {
		if (!args || !args.parameter) return;

		this.workOrder = args.parameter.workOrder;
		this.employeeCost = args.parameter.employeeCost;
		this.equipmentCost = args.parameter.equipmentCost;
		this.materialCost = args.parameter.materialCost;

		this.initializeCostFields();
	}

	/**
	 * initialize all the fields
	 */
	initializeCostFields() {
		this.taskEnabled = !!this.workOrder.workTasks.length;
		switch (this.lemService.lemType) {
			case LEMType.labor:
				this.searchFieldHeader = 'employee name';
				this.lemMenuPanelHeader = 'add employee time';
				break;
			case LEMType.equipment:
				return this.initializeEquipmentCost();
			case LEMType.material:
				return this.initializeMaterialCost();
		}
	}

	/**
	 * initialize material cost
	 */
	initializeMaterialCost() {
		const { workOrderMetaData } = this.workOrderFactory;
		this.searchFieldHeader = 'material';
		this.lemMenuPanelHeader = 'add material used';
		this.field1Header = 'material #';
		this.field2Header = 'units';
		this.lemList = workOrderMetaData.materials;
		this.itemKey = 'description';
		this.field1Value = this.materialCost.materialId;
		this.field2Value = this.materialCost.units;
		const workTask = this.workOrder.workTasks.getByWorkTaskId(this.materialCost.workTaskId);
		this.workTaskDescription = workTask ? workTask.description : '';
	}

	/**
	 * initialize equipment cost
	 */
	initializeEquipmentCost() {
		const { workOrderMetaData } = this.workOrderFactory;
		this.searchFieldHeader = 'equipment';
		this.lemMenuPanelHeader = 'add equipment used';
		this.field1Header = 'equipment #';
		this.field2Header = 'hours';
		this.lemList = workOrderMetaData.equipments;
		this.itemKey = 'description';
		this.field1Value = this.equipmentCost.equipmentId;
		this.field2Value = this.equipmentCost.hours;
		const workTask = this.workOrder.workTasks.getByWorkTaskId(this.equipmentCost.workTaskId);
		this.workTaskDescription = workTask ? workTask.description : '';
	}

	/**
	 * When Item is selected
	 * @param {any} item -selected item
	 */
	itemSelected(item: any) {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				break;
			case LEMType.equipment:
				this.equipmentCost.equipmentId = (item as Equipment).equipmentId;
				this.equipmentCost.unitCost = (item as Equipment).hourlyRate;
				this.equipmentCost.description = (item as Equipment).description;
				this.field1Value = this.equipmentCost.equipmentId;
				break;
			case LEMType.material:
				this.materialCost.materialId = (item as Material).materialId;
				this.materialCost.unitCost = (item as Material).unitCost;
				this.field1Value = this.materialCost.materialId;
				break;
		}
	}

	/**
	 * When unit number changes
	 * @param {string} count - the unit number for the cost
	 */
	valueChanged(count: string) {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				break;
			case LEMType.equipment:
				this.equipmentCost.hours = parseInt(count);
				break;
			case LEMType.material:
				this.materialCost.units = parseInt(count);
				break;
		}
	}

	/**
	 * Push to workorder field component
	 */
	goToTask = () => {
		this.workOrderFieldProvider.selectedWorkOrderFieldName = WorkOrderFieldNames.AddLEMTask;
		NavigationService.navigateTo(Pages.workorderField, {
			selectedItems: [this.workTaskDescription],
			selectedItemKey: 'description',
			workOrder: this.workOrder
		});
	};

	/**
	 * On save of the cost
	 */
	onSave = async () => {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				return;
			case LEMType.equipment:
				return await this.editEquipmentCost();
			case LEMType.material:
				return await this.editMaterialCost();
		}
	};

	/**
	 * Delete standard LEM
	 */
	onDelete = async () => {
		switch (this.lemService.lemType) {
			case LEMType.labor:
				break;
			case LEMType.equipment:
				await this.workOrderFactory.deleteEquipmentCost(this.workOrder, this.equipmentCost);
				break;
			case LEMType.material:
				await this.workOrderFactory.deleteMaterialCost(this.workOrder, this.materialCost);
				break;
		}
		this.menuPanelComponent.goBack();
	};

	/**
	 * Edit equipment cost
	 */
	async editEquipmentCost() {
		if (!this.equipmentCost.isDirty) {
			this.alertDialog.mainMessage = { text: 'Need to fill out the form' };
			this.alertDialog.open = true;
			return;
		}
		if (!this.equipmentCost.equipmentId) {
			this.alertDialog.mainMessage = { text: 'Need to select equipment' };
			this.alertDialog.open = true;
			return;
		}
		if (!this.equipmentCost.hours) {
			this.alertDialog.mainMessage = { text: 'Need hours' };
			this.alertDialog.open = true;
			return;
		}
		await this.workOrderFactory.updateEquipmentCost(this.workOrder, this.equipmentCost);
		this.menuPanelComponent.goBack();
	}

	/**
	 * Edit material cost
	 */
	async editMaterialCost() {
		if (!this.materialCost.isDirty) {
			this.alertDialog.mainMessage = { text: 'Need to fill out the form' };
			this.alertDialog.open = true;
			return;
		}
		if (!this.materialCost.materialId) {
			this.alertDialog.mainMessage = { text: 'Need to select material' };
			this.alertDialog.open = true;
			return
		}
		if (!this.materialCost.units) {
			this.alertDialog.mainMessage = { text: 'Need units' };
			this.alertDialog.open = true;
			return;
		}
		await this.workOrderFactory.updateMaterialCost(this.workOrder, this.materialCost);
		this.menuPanelComponent.goBack();
	}
}
