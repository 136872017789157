import { Component, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { GuiConfig } from 'omni-model/gui-config.model';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Pages } from 'app/navigation/inavigation';

/**
 * This is a child component to the tab settings component. This is designed to be placed in the left side panel.
 * The component is loaded when one tries to change a tab's canvas layout and tile count by selecting the 'layout' field from the
 * tab settings side panel.
 * The component displays the three different tile layouts and the 6 different canvas layouts.
 */
@Component({
	selector: 'app-layout-configuration',
	templateUrl: './layout-configuration.component.html',
	styleUrls: ['./layout-configuration.component.scss']
})
export class LayoutConfigurationComponent extends MenuPanelBaseComponent {
	/**
	 * The instance of the RemoveCanvasAndTilesDialogComponent.
	 */
	removeCanvasDialog: MatDialogRef<any, any>;

	configChanges: GuiConfig;

	public get pageIdentifier(): Pages {
		return Pages.layout;
	}

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {GuiConfigService} guiConfigService - Provides all the services to manipulate the OMNI tab.
	 * load appropriate component with the change in the current route.
	 */
	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args.parameter.configChanges) this.configChanges = args.parameter.configChanges;
	}

	/**
	 * On init, the canvas layout and the tile layout of the current selected tab is initialized and the current
	 * route is emitted.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'tiles and canvas layout', backgroundClass: 'cyan-background' });
	}

	/**
	 * This method is invoked when the different tile layouts are selected from the layouts side panel.
	 * @param {number} count - It will be either of the following values,
	 * 0 - when the tab is set to have no tiles
	 * 6 - when the tab is set to have 6 tiles
	 * 12 - when the tab is set to have 12 tiles.
	 */
	setTileCount(count: number) {
		this.configChanges.tilesLayout = count;
		setTimeout(() => {
			this.menuPanelComponent.navigationRouter.navigateBack(Pages.tabSettings, { configChanges: this.configChanges });
		}, 400);
	}

	/**
	 * This method is invoked when the different canvas layouts are selected from the layouts side panel.
	 * @param {number} layout - It will be a value from 1 to 6 ,
	 * which represents the 6 different layouts which are currently available.
	 */
	setCanvasLayout(layout: number) {
		this.configChanges.canvasLayout = layout;
		setTimeout(() => {
			this.menuPanelComponent.navigationRouter.navigateBack(Pages.tabSettings, { configChanges: this.configChanges });
		}, 400);
	}
}
