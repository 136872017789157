import { FormPageDefinition } from './form-page-definition.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';
import { AssetDefinition } from './asset-definition.model';

export class FormDefinition extends SedaruConfigBase {
	legacyId: number;
	workType: string;
	title: string;
	private _pages: Array<FormPageDefinition>;
	get pages(): Array<FormPageDefinition> {
		if (!this._pages) {
			this._pages = new Array<FormPageDefinition>();
		}

		return this._pages;
	}

	private _parent: AssetDefinition;
	public get parent(): AssetDefinition {
		return this._parent;
	}

	constructor(globalConfig: GlobalConfig, assetDefinition: AssetDefinition) {
		super(globalConfig);
		this._parent = assetDefinition;
	}
}
