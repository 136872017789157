import { WorkOrderTeam } from './work-order-team.model';
import { WorkOrderTeamContract } from '../../contracts/work-order';
export class WorkOrderTeams extends Array<WorkOrderTeam> {
	static fromContracts(teamContracts: WorkOrderTeamContract[]) {
		const workOrderTeams = new WorkOrderTeams();
		if (!teamContracts) return workOrderTeams;

		for (const teamContract of teamContracts) {
			const workOrderTeam = WorkOrderTeam.fromContract(teamContract);
			workOrderTeams.push(workOrderTeam);
		}
		return workOrderTeams;
	}

	getByTeamId = (teamId: string) => this.find(team => team.teamId === teamId);
}
