import { AssetDefinition } from 'models/asset-definition.model';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { Asset } from 'models/work-order/asset.model';

export class HierarchyAssetRecord {
    public assetLocation: AssetLocationWithAssets;
    public assetDefinition: AssetDefinition;
    public asset: Asset;

    constructor(asset: Asset, assetLocation: AssetLocationWithAssets, assetDefinition?: AssetDefinition) {
        this.assetLocation = assetLocation;
        this.asset = asset;
        this.assetDefinition = assetDefinition;
    }

    public get assetId(): string {
        return this.asset?.assetId;
    }

    public get assetType(): string {
        return this.asset?.assetType;
    }
}
