import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CanvasService } from '../canvas.service';
import { Canvas } from '../../../omni-model/canvas.model';
import { TileService } from 'app/tiles/tile.service';
import { MetricTile } from 'models';

@Component({
	selector: 'app-remove-canvas-and-tiles-dialog',
	templateUrl: './remove-canvas-and-tiles-dialog.component.html'
})
export class RemoveCanvasAndTilesDialogComponent implements OnInit {
	/**
	 * holds the IDs of the canvases to be removed.
	 */
	canvasToRemove: Canvas[] = [];

	tilesToRemove: MetricTile[] = [];
	/**
	 * Inject dialog reference as dependency
	 * @param {MatDialogRef<RemoveCanvasAndTilesDialogComponent>} dialogRef - A reference to the dialog instance.
	 */
	constructor(
		public dialogRef: MatDialogRef<RemoveCanvasAndTilesDialogComponent>,
		private canvasService: CanvasService,
		private tileService: TileService
	) {}

	/**
	 * This method will close the dialog and trigger an event.
	 * @param {boolean} isConfirmed - Boolean value that indicates if the user confirmed to remove the canvases
	 */
	onClose(isConfirmed: boolean) {
		this.dialogRef.close(isConfirmed);
	}

	ngOnInit() {
		this.canvasToRemove = this.canvasService.canvasesAtRisk;
		this.tilesToRemove = this.tileService.tilesAtRisk;
	}
}
