import { BaseFormResolver } from './base-form.resolver';
import { AssetDefinition, Color, AlternatingPattern, FormDefinition, FormControlDefinition, ContextType } from 'models';
import { FormGridPattern } from './form-alternating-pattern';

export class FormResolver extends BaseFormResolver {
	private _defaultColor: Color;
	get defaultColor() {
		if (!this._defaultColor) {
			this._defaultColor = new Color();
			this._defaultColor.name = 'DefaultTabGray';
			this._defaultColor.r = 84;
			this._defaultColor.g = 84;
			this._defaultColor.r = 84;
			this._defaultColor.alpha = 60;
		}
		return this._defaultColor;
	}

	private _defaultAltColor: Color;
	get defaultAltColor() {
		if (!this._defaultAltColor) {
			this._defaultAltColor = new Color();
			this._defaultAltColor.name = 'DefaultLightGray';
			this._defaultAltColor.r = 102;
			this._defaultAltColor.g = 102;
			this._defaultAltColor.r = 102;
			this._defaultAltColor.alpha = 60;
		}
		return this._defaultAltColor;
	}

	private altPattern: AlternatingPattern;
	private mainBgColor: Color;
	private mainTxtColor: Color;
	private altBgColor: Color;
	private altTxtColor: Color;

	constructor(assetDefinition: AssetDefinition) {
		super();
		this.altPattern = assetDefinition.style.alternatingPattern;
		this.mainBgColor = assetDefinition.style.layout.background.color;
		this.mainTxtColor = assetDefinition.style.layout.foreground.color;
		this.altBgColor = assetDefinition.style.layout.background.alternateColor;
		this.altTxtColor = assetDefinition.style.layout.foreground.alternateColor;
	}

	getControlBackground = (control: FormControlDefinition, grid: FormGridPattern) => {
		if (control.styleId && control.style?.layout?.background?.color) return control.style.layout.background.color;
		const { rowNumber, colNumber } = grid.getControlCoordinates(control);
		if (!this.mainBgColor) return this.defaultColor;
		if (this.altPattern === AlternatingPattern.None) return this.mainBgColor;
		if (this.altPattern === AlternatingPattern.Column) {
			if (colNumber === 0 && rowNumber % 2 === 0) return this.mainBgColor;
			if (colNumber === 1 && rowNumber % 2 !== 0) return this.mainBgColor;
			if (!this.altBgColor) return this.defaultAltColor;
			return this.altBgColor;
		}
		if (rowNumber % 2 === 0) return this.mainBgColor;
		if (!this.altBgColor) return this.defaultAltColor;
		return this.altBgColor;
	};
	getControlTextColor = (control: FormControlDefinition, grid: FormGridPattern) => {
		if (control.styleId && control.style?.layout?.foreground?.color) return control.style.layout.foreground.color;
		if (this.mainTxtColor) return this.mainTxtColor;
		const white = new Color();
		white.fromRGBA('rgb(255,255,255)');
		return white;
	};
	showTrendIcon = (control: FormControlDefinition) => {
		if (!control.value || !control.value.context) return false;
		if (control.value.context.type === ContextType.History) return true;
		return false;
	};
	getMarginLeft = (control: FormControlDefinition, grid: FormGridPattern) => {
		const { colNumber } = grid.getControlCoordinates(control);
		if (colNumber) return '4px';
	};
}
