import { DataChannelService } from '../../../../../../domain-service/data-channel.service';
import { MetricService } from '../../../../../../domain-service/metric.service';
import { MetricSourceService } from '../metric-source.service';
import { Component, ElementRef } from '@angular/core';
import { MetricSourceType } from './metric-source-type.model';
import { KeyValue } from '@angular/common';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MetricSource, ChannelTypes, Metric, MetricQueryLegacy } from 'models';
import { NavigationArgs } from 'app/navigation/navigation-args';

/**
 * This component is desined to be placed in the right side panel. It will display a list of data channel types
 * (the name, icon and its description).
 * This component will be loaded when we try to add a new data channel.
 */
@Component({
	selector: 'app-metric-source-types',
	templateUrl: './metric-source-types.component.html',
	styleUrls: ['./metric-source-types.component.scss']
})
export class MetricSourceTypesComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the static list of data channel types available.
	 */
	metricSourceTypes: { [index: string]: MetricSourceType };

	metric: Metric;

	/**
	 * The constructor creates the static list of data channel types. It also injects dependencies.
	 * @param {MetricService} metricService - Provides services to create and update the selected metric.
	 * @param {MetricSourceService} metricSourceService - Provides a list of types of data channels.
	 */
	constructor(private metricService: MetricService, private metricSourceService: MetricSourceService, private dataChannelService: DataChannelService, view: ElementRef<HTMLElement>) {
		super(view);
		this.metricSourceTypes = this.metricSourceService.types;
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	/**
	 * On init, the current path is emitted so that the navigation service can keep track of the last visited path.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'add source' });
	}

	/**
	 * The method is called when one of the data channel type is selected from the list. It will create a new data
	 * channel and set its type to the type which we selected.
	 * @param {MetricSourceType} sourceType - The data channel type which was clicked.
	 */
	addMetricSource(sourceType: MetricSourceType) {
		let newMetricSource = new MetricSource();
		switch (sourceType.fullName) {
			case 'WorkOrder':
				newMetricSource = {
					type: ChannelTypes[sourceType.fullName]
				};
				this.onWorkOrderSourceSelected(newMetricSource);
				break;
			case 'History':
			case 'Asset':
				newMetricSource = {
					type: ChannelTypes[sourceType.fullName]
				};
				NavigationService.navigateTo(Pages.assetTypes, { metric: this.metric, metricSource: newMetricSource });
				break;
		}
	}

	onWorkOrderSourceSelected(metricSource: MetricSource) {
		this.clearMetricUponChangeOfSource();
		this.metric.definition.source = metricSource;
		this.dataChannelService.assignDataChannelToMetric(this.metric);
		this.menuPanelComponent.goBack();
	}

	clearMetricUponChangeOfSource() {
		this.metric.definition.query = null;
		this.metric.definition.displayValueSettings.clear();
		this.metric.definition.menuPanelSettings.clear();
		this.metric.definition.defaultTrendSettings.clear();
	}

	/**
	 * Sorts the list of contents in ascending order based on the order provided before displaying it.
	 */
	sort = (a: KeyValue<string, MetricSourceType>, b: KeyValue<string, MetricSourceType>): number => {
		return a.value.order > b.value.order ? 1 : b.value.order > a.value.order ? -1 : 0;
	};
}
