<mat-card
	class="omni-templated-list-item-no-icon"
	[class.selected]="selected"
	(click)="!resolver.isDisabled(item) && (selected = !selected);itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
	[title]="resolver._getLabel(item)"
>
	<mat-card-title class="omni-templated-list-item-no-icon-title s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
		{{ resolver._getLabel(item) }}
	</mat-card-title>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-no-icon-divider"></mat-divider>
