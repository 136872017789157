import { CredentialsType } from './credentials-type.enum';
import { GlobalConfig } from './global-config.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';

export interface FlatCredentials {
	id: string;
	legacyId: number;
	name: string;
	username: string;
	password: string;
	token: string;
	expires: number;
	type: number;
	clientId: string;
}

/**
 * This defines the authentication credentials for source layers
 */
export class Credentials extends SedaruConfigBase {
	/**
	 * uuid for credentials
	 */
	id?: string;

	/**
	 * holds the frist generation id which will getting from studio.
	 */
	legacyId?: number;

	/**
	 * Holds the name givent to credentials
	 */
	name?: string;

	/**
	 * Holds the unique name of the source layers
	 */
	username?: string;

	/**
	 * holds the password for the source layers
	 */
	password?: string;

	/**
	 * holds the authentication token for the source layers
	 */
	token?: string;

	/**
	 * holds the expires time of the token
	 */
	expires?: number;

	/**
	 * Diffenent types of authentication for source layers
	 */
	type?: CredentialsType;

	/**
	 * client Id for portal credential
	 */
	clientId?: string;

	/**
	 * This constructor loads and injects dependencies and initializes a default empty value.
	 */
	constructor(globalConfig: GlobalConfig) {
		super(globalConfig);
	}

	getFlatCredentials(): FlatCredentials {
		return {
			id: this.id,
			legacyId: this.legacyId,
			name: this.name,
			username: this.username,
			password: this.password,
			token: this.token,
			expires: this.expires,
			type: this.type,
			clientId: this.clientId,
		};
	}
}

export class CredentialsList extends Array<Credentials> {
	getByLegacyId = (id: number): Credentials => {
		for (let index = 0; index < this.length; index++) {
			const credentials = this[index];

			if (credentials.legacyId !== id) {
				continue;
			}

			return credentials;
		}

		return null;
	};
}
