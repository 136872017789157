import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssetDefinition, ControlType, FormDefinition, ContextType, ArcGISHistoryChannelAttributes, FormControlDefinition, FormControlValue, ValueContext, ArcGISAssetChannelAttributes } from 'models';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { FormGridPattern } from '../form-resolvers/form-alternating-pattern';
import { IdentifyTaskResult } from 'app/canvas-container/canvas-map/identify-task-result.model';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { Pages } from 'app/navigation/inavigation';
import { NavigationService } from 'app/navigation/navigation.service';
import { FormResolver } from '../form-resolvers/dynamic-form.resolver';
import { RecordContextGroup } from 'models/records/record-context-group';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Feature } from 'sedaru-util/esri-core';
import { FieldType } from 'sedaru-util/esri-core';
import { TrendDefinition } from 'app/canvas-container/canvas-trend/trend-defintion.model';
import * as moment from 'moment';
import { ControlGroupHeaderIcon } from '../control-group/control-group-header-icon.model';
import { URIParser } from 'sedaru-util';
import { MenuPanelFieldValueType } from 'app/ui-components/menu-panel-field/menu-panel-field-value-type.enum';

@Component({
	selector: 'app-info-form',
	templateUrl: './info-form.component.html',
	styleUrls: ['./info-form.component.scss']
})
export class InfoFormComponent implements OnInit, OnChanges {
	private _formResolver: FormResolver;
	get formResolver(): FormResolver {
		if (!this._formResolver) {
			this._formResolver = this.createInfoFormResolver();
		}

		return this._formResolver;
	}
	@Input() contextGroup: RecordContextGroup;
	@Input() assetDefinition: AssetDefinition;
	@Input() formDefinition: FormDefinition;
	@Input() asset: Feature;
	attachments: any[] = [];
	infoFormHeight: string;
	@Input() trendableRecordCount: number;

	onTrendIconClick: (trendDefinition: TrendDefinition) => void;

	constructor(private omniInterop: OmniInteropService, private mapService: CanvasMapService) {}


	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contextGroup'] || changes['assetDefinition'] || changes['formDefinition'] || changes['asset']) {
			this.ngOnInit();
		}
	}

	ngOnInit() {
		let countOfRows = 0;
		let countOfHeaders = 1;
		const firstPage = this.formDefinition.pages[0];
		for (let i = 0; i < firstPage.controlGroups.length; i++) {
			const grid = new FormGridPattern(firstPage.controlGroups[i].controls);
			if (firstPage.controlGroups[i].title) countOfHeaders++;
			countOfRows += grid.rows.length;
		}
		this.infoFormHeight = countOfHeaders * 20 + countOfRows * 55 + 20 + 'px';

		this.getAttachments();
	}

	updateResolver() {
		this._formResolver = undefined;
	}

	private createInfoFormResolver(): FormResolver {
		const formResolver = new FormResolver(this.assetDefinition);
		const historyChannelAttributes = this.assetDefinition && this.assetDefinition.historyChannel ? (this.assetDefinition.historyChannel.attributes as ArcGISHistoryChannelAttributes) : undefined;
		const historyLayer = this.omniInterop.arcGISManager.getHistoryLayer(this.assetDefinition.assetType);
		const arcgisServer = this.omniInterop.arcGISManager.getArcGISService(this.assetDefinition.assetChannel.dataSourceLegacyId);
		const assetAttr = this.assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
		const layerIndex = assetAttr.featureServiceLayerIndex;
		const layer = arcgisServer.layers.getById(layerIndex);
		const icon = new ControlGroupHeaderIcon();
		icon.url = 'assets/specifications.png';
		icon.action = asset => this.showAssetDetails(asset);
		icon.asset = this.asset;

		formResolver.showFormTitle = () => {
			return false;
		};
		formResolver.getControlGroupHeader = group => {
			return group.title;
		};
		formResolver.getControlHeader = control => {
			return control.title;
		};
		formResolver.getControlGroupHeaderIcon = () => {
			return icon;
		};
		formResolver.getControlPlaceholder = control => {
			return '';
		};
		formResolver.getControlValue = control => {
			if (control.value.text) {
				return control.value.text;
			}

			if (!control.value.context) return '';

			let returnValue = '';
			if (control.value.context.type === ContextType.Asset) {
				if (!this.contextGroup || !this.contextGroup.assetRecord) return;

				const field = layer.fields.find(layerField => layerField.name.toLowerCase() === control.value.context.fieldname.toLowerCase());
				if (!field) {
					return '';
				}

				if (!field.domain) {
					const value = this.contextGroup.assetRecord.feature.getAttribute(control.value.context.fieldname);
					if (typeof value === 'string' && value.toLowerCase() === 'null') returnValue = '';
					else returnValue = value;
				} else {
					const codedValue = this.contextGroup.assetRecord.feature.getAttribute(control.value.context.fieldname);
					// @ts-ignore
					const fieldValue = field.domain.codedValues.find(cv => cv.code === codedValue);
					returnValue = fieldValue ? fieldValue.name : codedValue;
				}
				if (field.type === FieldType.DateTime || field.type === FieldType.esriFieldTypeDate) {
					returnValue = new Date(returnValue).toLocaleDateString('en-US');
				}
			} else if (control.value.context.type === ContextType.History) {
				if (this.contextGroup.historyRecord) {
					returnValue = this.contextGroup.historyRecord.feature.getAttribute(control.value.context.fieldname);
				}
			}

			if (!returnValue) return '';

			return returnValue;
		};
		formResolver.getControlWidth = control => {
			if (control.controlType === ControlType.Control1x1) return MenuPanelFieldSize.half;
			return MenuPanelFieldSize.full;
		};
		formResolver.getControlHeight = () => {
			return MenuPanelFieldSize.half;
		};
		formResolver.getControlValueType = (control) => {
			const value = formResolver.getControlValue(control);
			if (value) {
				const linkType = URIParser.getLinkType(value);
				if (linkType === URIParser.LinkType.HTTP || linkType === URIParser.LinkType.HTTPS) {
					return MenuPanelFieldValueType.HYPERLINK;
				}
			}
			return MenuPanelFieldValueType.STRING;
		}
		formResolver.disableFormControls = () => {
			return true;
		};
		formResolver.showTrendIcon = control => {
			if (!control.value.context || control.value.context.type !== ContextType.History) return false;

			const field = historyLayer.fields.find(f => f.name.toLowerCase() === control.value.context.fieldname.toLowerCase());
			if (!field) return false;
			if (field.type === FieldType.esriFieldTypeInteger || field.type === FieldType.esriFieldTypeDouble || field.type === FieldType.esriFieldTypeSingle) {
				return true;
			}
			return false;
		};
		formResolver.enableTrendIcon = control => {
			if (formResolver.showTrendIcon(control) && this.trendableRecordCount > 2) return true;
			return false;
		};
		formResolver.trendIconSelected = control => {
			if (!historyChannelAttributes) return;
			const trendDefinition = new TrendDefinition();
			trendDefinition.assetId = this.contextGroup.historyRecord ? this.contextGroup.historyRecord.assetId : this.contextGroup.assetRecord.assetId;
			trendDefinition.assetIdFieldName = historyChannelAttributes.assetIdFieldName;
			trendDefinition.dateFieldName = historyChannelAttributes.completedDateFieldName;
			trendDefinition.historyLayer = historyLayer;
			trendDefinition.defaultFieldName = control.value.context.fieldname;
			trendDefinition.dateStartValue = moment()
				.startOf('day')
				.subtract(historyChannelAttributes.assetHistoryDays, 'days')
				.format('MM/DD/YYYY HH:mm:ss');
			trendDefinition.dateEndValue = moment()
				.endOf('day')
				.format('MM/DD/YYYY HH:mm:ss');
			trendDefinition.trendLabel = this.assetDefinition.title + trendDefinition.assetId + ' ' + control.title + ' (' + trendDefinition.dateStartValue + ' through today)';
			trendDefinition.trendBackgroundColor = control.style.layout.background.color.toHex();

			this.onTrendIconClick(trendDefinition);
			return;
		};

		return formResolver;
	}

	showAssetDetails(asset: IdentifyTaskResult) {
		this.mapService.selectedIdentifyAsset = asset;
		const f = Feature.fromEsriFeature({ attributes: this.asset.attributes, geometry: this.asset.geometry });
		NavigationService.navigateTo(Pages.mapAssetAttributes, { feature: f, assetType: this.assetDefinition.assetType });
	}

	async getAttachments() {
		const assetLayer = this.omniInterop.arcGISManager.getMapAssetLayer(this.assetDefinition.assetType);
		const attachmentList: any = assetLayer ? await assetLayer.getAttachmentList(this.asset.objectId) : null;
		this.attachments = (attachmentList ?? [] ).map(attachment => {
			return {
				thumbnailPath: attachment.url,
				uploadTimeStamp: null,
				attachmentName: attachment.name,
				imagePath: attachment.url,
				fileIndexId: attachment.id,
			}
		})
	}
}
