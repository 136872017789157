import { HttpCommonResponse } from './http-common-response';

export abstract class HttpCommon {
	private static _current: HttpCommon;
	private static get current(): HttpCommon {
		return this._current;
	}

	protected static initialize(instance: HttpCommon) {
		this._current = instance;
	}

	static get(url: string, headers?: any): Promise<HttpCommonResponse> {
		return HttpCommon.current.onGet(url, headers).then<HttpCommonResponse>(response => HttpCommon.getCommonResponse(response));
	}

	static post(url: string, body: any, headers?: any): Promise<HttpCommonResponse> {
		return HttpCommon.current.onPost(url, body, headers).then<HttpCommonResponse>(response => HttpCommon.getCommonResponse(response));
	}

	private static getCommonResponse(response: any) {
		const r = new HttpCommonResponse();

		r.data = response.data;
		if (!r.data) r.data = response.body;
		r.headers = response.headers;
		r.status = response.status;
		r.statusText = response.statusText;
		return r;
	}

	protected abstract onGet(url: string, headers?: any): Promise<any>;
	protected abstract onPost(url: string, body: any, headers?: any): Promise<any>;

	protected constructor() {}
}
