import { TimeframedMetricSubscriberBase } from './timeframed-metric-subscriber-base';
import { TimeframedMetric } from './timeframed-metric.model';

export class TimeframedMetricSubscriber extends TimeframedMetricSubscriberBase {
	timeframedMetric: TimeframedMetric;
	outfields: string[] = [];
	unsubscribe() {
		this.timeframedMetric.unsubscribe(this);
	}
}
