import { Component, ElementRef, ViewChild } from '@angular/core';
import { ContextualSearch, ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { UserTimeframeOption } from 'models/time-frame/user-timeframe-option.model';
import { Metric, MetricTile } from 'models';

@Component({
	selector: 'app-tile-timeframe-form',
	templateUrl: './tile-timeframe-form.component.html',
	styleUrls: ['./tile-timeframe-form.component.scss']
})
export class TileTimeframeFormComponent extends MenuPanelBaseComponent {
	selectedItem = [];
	timeframeFilter: TimeframeFilter;
	timeFrameType: string;
	currentMetric: Metric;
	currentTile: MetricTile;
	itemsKey = 'text';

	public get tileTimeFrameList(): Array<any> {
		return this.contextualSearch.getSearch<any>().results;
	}

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	constructor(view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args.parameter) return;
		this.timeFrameType = args.parameter.timeFrameType ? args.parameter.timeFrameType : 'tile';
		this.timeframeFilter = args.parameter.timeframeFilter;
		this.currentMetric = args.parameter.metric;
		this.currentTile = args.parameter.tile;

		if (!this.timeframeFilter || !this.timeframeFilter.timeFrame) return;
		this.selectedItem.push(this.tileTimeFrameList.find(tileTimeframe => tileTimeframe.text === this.timeframeFilter.timeFrame.definition.text.toLowerCase()));
	}
	onPageReload() {
		this.ngOnInit();
	}

	ngOnInit(): void {
		this.menuPanelComponent.updateView({ title: 'timeframe filter' });
		this.listComponent.getResolver = item => {
			return this.getOneLineResolver(item);
		};
	}

	private getOneLineResolver(item) {
		const tileTimeframeListResolver = NoIconOneLineComponent.createResolver();
		tileTimeframeListResolver.getLabel = i => i['text'];
		tileTimeframeListResolver.getAlignment = i => {
			return 'center';
		};
		return tileTimeframeListResolver;
	}

  onValueSelected(item) {
		const timeframeDefinition = UserTimeframeOption.getTimeFrameDefinition(item.type);
		const newfilter = new TimeframeFilter();
		newfilter.timeFrame = new Timeframe(timeframeDefinition);
		newfilter.timeframeField = this.timeframeFilter.timeframeField;
		NavigationService.navigateBackTo(Pages.timeframefilter, {
			timeFrameType: this.timeFrameType,
			timeframeFilter: newfilter,
			tile: this.currentTile,
			metric: this.currentMetric
		});
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (tileTimeFrame: any, searchText) => {
			if (tileTimeFrame.text.toLowerCase().includes(searchText.toLowerCase())) return true;

			return false;
		};
		const tileTimeFrameList = UserTimeframeOption.userOptions;
		const contextualSearch = new ContextualSearch(tileTimeFrameList, searchFunction);
		contextualSearch.title = 'search timeframe';
		return contextualSearch;
	}
}
