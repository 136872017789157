import { SearchableItemBase } from './searchable-item';
import { SearchControllerBase, SearchController } from './search-controller';
import { DelayedAction } from 'sedaru-util/delayed-action';

export abstract class SearchBase {
	delayTriggerEnabled = true;

	private _delayedAction: DelayedAction;
	private get delayedAction(): DelayedAction {
		if (!this._delayedAction) {
			this._delayedAction = new DelayedAction(400);
			this._delayedAction.onDelayedActionTriggered.subscribe((s, a) => {
				this.triggerSearch(s, a);
			});
		}

		return this._delayedAction;
	}

	/**
	 * The search text that will be entered by the user in the UI.
	 */
	private _searchText = '';
	public get searchText(): string {
		return this._searchText;
	}
	public set searchText(value: string) {
		if (this._searchText === value) return;

		this._searchText = value;

		if (this.delayTriggerEnabled) {
			this.delayedAction.restart();
		} else {
			this.triggerSearch(undefined, undefined);
		}
	}

	/**
	 * The array of filtered search results that match the search text.
	 */
	private _originalResults: Array<SearchableItemBase>;
	protected get originalResults(): Array<SearchableItemBase> {
		if (!this._originalResults) {
			this._originalResults = new Array<SearchableItemBase>();
		}
		return this._originalResults;
	}

	protected get controllerBase(): SearchControllerBase {
		return this._baseController;
	}

	constructor(private _baseController: SearchControllerBase) { }

	private triggerSearch(sender: any, args: any) {
		this.onSearchTriggered(this._searchText);
		this._originalResults = this._baseController.search(this._searchText);
	}

	protected onSearchTriggered(searchText: string) {
	}

	clear() {
		this._searchText = '';
		this._originalResults = [];
	}
}

export class Search<T> extends SearchBase {
	private _castedResults: T[];

	constructor(private _searchController: SearchController<T>) {
		super(_searchController);
	}

	public get controller(): SearchController<T> {
		return this.controllerBase as SearchController<T>;
	}

	onSearchTriggered(text: string) {
		this._castedResults = undefined;
	}

	public get results(): Array<T> {
		if (this.searchText === '') {
			return this._searchController.originalItems;
		}

		if (!this._castedResults) {
			this._castedResults = this._searchController.getOriginalItems(this.originalResults);
		}

		return this._castedResults;
	}
}
