import { ArcGISField } from './arc-gis-field.model';
import { TypeOfValue } from './type-of-value.model';
import { ValueFormat } from './value-format.model';

/**
 * This defines the display value setttings for the metric
 */
export class DisplayValueSettings {
	/**
	 * Holds the type of tile value. eg., record count, sum of a field, average value etc.
	 */
	public typeOfValue: TypeOfValue;
	/**
	 * Holds the field for valueTypeField. Field namd includes gallonsflushed, cl2_residual, percentage etc.
	 */
	public valueTypeField: ArcGISField;
	/**
	 * Holds the value format. eg., #,###, #.## ppm, ##.#% etc.
	 */
	public valueFormat: ValueFormat;

	/**
	 * The constructor initilze empty values to this class
	 */
	constructor() {
		this.typeOfValue = new TypeOfValue();
		this.valueTypeField = new ArcGISField();
		this.valueFormat = new ValueFormat();
	}

	/**
	 * This function used to initial this object
	 * @param {DisplayValueSettings} displayValueSettings - input used to initialize the object
	 */
	initialize?(displayValueSettings: DisplayValueSettings): void {
		if (!displayValueSettings) return;
		this.typeOfValue = displayValueSettings.typeOfValue;
		this.valueTypeField.initialize(displayValueSettings.valueTypeField);
		this.valueFormat = displayValueSettings.valueFormat;
	}

	/**
	 * This function reset this object with default properties
	 */
	clear?(): void {
		this.typeOfValue = new TypeOfValue();
		this.valueTypeField = new ArcGISField();
		this.valueFormat.name = 'none';
		this.valueFormat.enumValue = 0;
	}
}
