import { animate, AnimationTriggerMetadata, state, style, transition, trigger, group, animateChild, query } from '@angular/animations';

/**
 * This function is used to add animation when a child panel is being loaded from the parent panel.
 * This will slide the child panel from right to left.
 */
export function slideToChild() {
	return [
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: '0px',
				bottom: 0,
				left: 0,
				width: '100%'
			})
		]),
		query(':enter', [style({ left: '100%' })]),
		query(':leave', animateChild()),
		group([query(':leave', [animate('300ms ease-out', style({ left: '-100%' }))]), query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])]),
		query(':enter', animateChild())
	];
}

/**
 * This function is used to add animation when a parent panel is being loaded from the child panel.
 * This will slide the child panel from right to left.
 */
export function slideToParent() {
	return [
		query(':enter, :leave', [
			style({
				position: 'absolute',
				top: '0px',
				bottom: 0,
				left: 0,
				width: '100%'
			})
		]),
		query(':enter', [style({ left: '-100%' })]),
		query(':leave', animateChild()),
		group([query(':leave', [animate('300ms ease-out', style({ left: '100%' }))]), query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])]),
		query(':enter', animateChild())
	];
}

/**
 * This method defines the different set of transitions to be used when navigation is made between components.
 * To use this, we will provide the method name as the 'animation' property within the @component decorator.
 * This method when triggered matches the navigation to the appropriate transition using the rank defined for
 * each route.
 */
export const slideInLeftAnimation: AnimationTriggerMetadata = trigger('routeAnimation', [
	state(
		'*',
		style({
			opacity: 1,
			transform: 'translateX(0)'
		})
	),
	transition('0 => 1', slideToParent()),
	transition('1 => 0', slideToParent()),
	transition('2 => 1', slideToParent()),
	transition('3 => 1', slideToParent()),
	transition('3 => 2', slideToParent()),
	transition('4 => 3', slideToParent()),
	transition('5 => 4', slideToParent()),
	transition('6 => 5', slideToParent()),

	transition('1 => 2', slideToChild()),
	transition('2 => 3', slideToChild()),
	transition('3 => 4', slideToChild()),
	transition('4 => 5', slideToChild()),
	transition('5 => 6', slideToChild()),

	transition('10 <=> 20', slideToParent()),
	transition('20 <=> 30', slideToParent()),
	transition('30 <=> 10', slideToParent()),
	transition('40 <=> 10', slideToParent()),
	transition('40 <=> 20', slideToParent()),
	transition('40 <=> 30', slideToParent())
]);

/**
 * This method defines the different set of transitions to be used when navigation is made between components.
 * To use this, we will provide the method name as the 'animation' property within the @component decorator.
 * This method when triggered matches the navigation to the appropriate transition using the rank defined for
 * each route.
 */
export const slideInDownAnimation: AnimationTriggerMetadata = trigger('routeAnimation', [
	state(
		'*',
		style({
			opacity: 1,
			transform: 'translateY(0)'
		})
	),
	transition(':enter', [
		group([
			query('@*', animateChild(), { optional: true }),
			style({
				opacity: 0,
				transform: 'translateY(-100%)'
			}),
			animate('0.3s ease-in')
		])
	]),
	transition(':leave', [
		group([
			query('@*', animateChild(), { optional: true }),
			animate(
				'0.5s ease-out',
				style({
					opacity: 0,
					transform: 'translateY(100%)'
				})
			)
		])
	])
]);

/**
 * This method defines the different set of transitions to be used when navigation is made between components.
 * To use this, we will provide the method name as the 'animation' property within the @component decorator.
 * This method when triggered matches the navigation to the appropriate transition using the rank defined for
 * each route.
 */
export const fadeInAndOutOnHover: AnimationTriggerMetadata = trigger('fadeInAndOutOnHover', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(400))]);

export const fadeIn = trigger('fadeIn', [state('in', style({ opacity: 1 })), transition('void => *', [style({ opacity: 0 }), animate(400)])]);

/**
 * This method defines the different set of transitions to be used when navigation is made between components.
 * To use this, we will provide the method name as the 'animation' property within the @component decorator.
 * This method when triggered matches the navigation to the appropriate transition using the rank defined for
 * each route.
 */
export const newTabAnimation: AnimationTriggerMetadata = trigger('newTabAnimation', [
	transition(':enter', [style({ transform: 'translateX(100%)', opacity: 0 }), animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))])
]);

export const expandInShrinkOut: AnimationTriggerMetadata = trigger('expandInShrinkOut', [
	transition(':enter', [style({ transform: 'scale(0.1)' }), animate('200ms', style({ transform: 'scale(1)' }))]),
	transition(':leave', [animate('100ms', style({ transform: 'scale(0.1)', opacity: 0 }))])
]);

export const collapseOnLeave: AnimationTriggerMetadata = trigger('collapseOnLeave', [
	transition(':leave', [style({ opacity: 0 }), animate('400ms', style({ height: 0, 'line-height': 0, overflow: 'hidden', opacity: 0 }))])
]);

export const expandOnEnter: AnimationTriggerMetadata = trigger('expandOnEnter', [transition(':enter', [style({ height: 0, opacity: 0 }), animate('400ms')])]);
