<div class="scrolling-content" #scrollingContainer (scroll)="onScroll()">
	<mat-action-list class="edit-metric">
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div>
				<span class="control-title">source</span>
				<input
					class="control-value omni-restrict-overflow"
					disabled
					[value]="metricSourceDisplayName"
					placeholder="not set"
					[matTooltip]="metric.definition.source.type"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#metricSourceTooltip="toggleTooltip"
					[matTooltipDisabled]="metricSourceTooltip.isTooltipDisabled"
				/>
				<button *ngIf="metric.isNew" (click)="goTo('source')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>

		<mat-list-item class="dark-gray-background menu-panel-input-field" *ngIf="!metric.isNew || dataChannel">
			<div
				[matTooltip]="metric.definition.query"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#metricQueryTooltip="toggleTooltip"
				[matTooltipDisabled]="metricQueryTooltip.isTooltipDisabled"
			>
				<span class="control-title">query</span>
				<input class="control-value omni-restrict-overflow" [ngStyle]="{ color: queryError ? 'red' : 'white' }" disabled [value]="getQuery()" placeholder="not set" />
				<button (click)="goTo('query')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>

		<!-- <mat-list-item class="light-gray-background menu-panel-input-field" *ngIf="showTimeFrame()">
			<div [matTooltip]="" matTooltipClass="tooltip" matTooltipPosition="above" [appTooltip] #timeFrame="toggleTooltip" [matTooltipDisabled]="timeFrame.isTooltipDisabled">
				<span class="control-title">default time frame</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="getTimeFrame()" placeholder="not set" />
				<button (click)="goTo('timeframe')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item> -->
		<mat-list-item class="light-gray-background menu-panel-input-field" *ngIf="showTimeFrame()">
			<div [matTooltip]="" matTooltipClass="tooltip" matTooltipPosition="above" [appTooltip] #timeFrame="toggleTooltip" [matTooltipDisabled]="timeFrame.isTooltipDisabled">
				<span class="control-title">default time frame</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="timeFrameFilter?.timeFrameDefinition?.text?.toLowerCase()" placeholder="not set" />
				<button (click)="goTo('timeframe')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>

		<mat-list-item class="dark-gray-background menu-panel-input-field" *ngIf="showDisplayValueSettings()">
			<div
				[matTooltip]="metric.definition.displayValueSettings.typeOfValue"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#displayValueSettingsTooltip="toggleTooltip"
				[matTooltipDisabled]="displayValueSettingsTooltip.isTooltipDisabled"
			>
				<span class="control-title">display value settings</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="metric.definition.displayValueSettings.typeOfValue.name" placeholder="not set" />
				<button (click)="goTo('displayValueSettings')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>

		<mat-list-item class="menu-panel-input-field" [ngClass]="backgroundColorForDescriptionControl()" *ngIf="showDescriptionField()">
			<div>
				<span class="control-title">description & icon</span>
				<input
					class="omni-restrict-overflow"
					name="metricTitle"
					id="metricTitle"
					[(ngModel)]="metric.definition.name"
					type="text"
					placeholder="enter a description"
					autocomplete="off"
					[matTooltip]="metric.definition.name"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#metricTitleTooltip="toggleTooltip"
					[matTooltipDisabled]="metricTitleTooltip.isTooltipDisabled"
					maxlength="50"
				/>
				<button mat-icon-button class="metric-icon" disabled>
					<img [src]="getIcon()" />
				</button>
			</div>
		</mat-list-item>

		<!-- <mat-list-item class="dark-gray-background menu-panel-input-field" *ngIf="!metric.isNew && metric.definition.menuPanelSettings.displayField">
			<div
				[matTooltip]="metric.definition.menuPanelSettings.displayField.omniName"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#menuPanelSettings="toggleTooltip"
				[matTooltipDisabled]="menuPanelSettings.isTooltipDisabled"
			>
				<span class="control-title">default menu panel settings</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="getMetricDisplayFieldName()" placeholder="not set" />
				<button (click)="goTo('menupanelsettings')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item> -->
		<!-- <mat-list-item class="light-gray-background menu-panel-input-field" *ngIf="!metric.isNew">
			<div>
				<span class="control-title">default trend settings</span>
				<input class="control-value omni-restrict-overflow" disabled [value]="metric.definition.defaultTrendSettings.defaultTrendStyle.label" placeholder="not set" />
				<button (click)="goTo('defaulttrendsettings')" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item> -->
		<!-- <mat-list-item class="dark-gray-background menu-panel-input-field" *ngIf="!metric.isNew">
			<div>
				<span class="control-title">default table settings</span>
				<input class="control-value" disabled placeholder="not set" />
				<button mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item> -->
		<!-- <mat-list-item class="light-gray-background menu-panel-input-field" *ngIf="!metric.isNew">
			<div>
				<span class="control-title">refresh frequency</span>
				<input class="control-value" type="number" [(ngModel)]="metric.definition.refreshFrequency" placeholder="not set" />
			</div>
		</mat-list-item> -->
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button mat-button class="omni-md-font" (click)="openDeletePrompt()" *ngIf="!metric.isNew"><img class="omni-sm-img" src="assets/delete.png" /> delete</button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" [class.disabled]="!canSave" (click)="onSave()">save <img class="omni-sm-img" src="assets/save.png" /></button>
	</mat-card-actions>
</mat-card-footer>
