import { CustomFields } from './custom-fields.model';
import { Vendor } from './vendor.model';
import { Employees } from './employees.model';
import { Equipments } from './equipments.model';
import { Materials } from './materials.model';
import { Tasks } from './tasks.model';
import { Inspections } from './inspections.model';
import { WorkOrderTemplates } from './work-order-templates.model';
import { CustomFieldCodes } from './custom-field-codes.model';
import { WorkOrderMetaDataContract } from '../../contracts/work-order';
import { Priorities } from './priorities.model';
import { Statuses } from './statuses.model';
import { EmployeeRates } from './employee-rates.model';
import { EmployeeTrades } from './employee-trades.model';
import { WorkOrderBudgets } from './work-order-budgets.model';
import { Vendors } from './vendors.model';
import { AssetLocationsWithAssets } from './asset-locations-with-assets.model';
import { WorkOrderTeams } from './work-order-teams.model';
import { FailureCodes } from './failure-codes.model';
import { CustomField } from './custom-field.model';
import { WorkOrderTemplate } from './work-order-template.model';
import { Task } from './task.model';
import { CustomFieldWrappers } from './custom-field-wrappers.model';
import { CustomFieldWrapper } from './custom-field-wrapper.model';

export class WorkOrderMetaData {
	private _employees: Employees;
	set employees(employees: Employees) {
		this._employees = employees;
	}
	get employees(): Employees {
		return this._employees;
	}

	private _equipments: Equipments;
	set equipments(equipments: Equipments) {
		this._equipments = equipments;
	}
	get equipments() {
		return this._equipments;
	}

	private _materials: Materials;
	set materials(materials: Materials) {
		this._materials = materials;
	}
	get materials() {
		return this._materials;
	}

	private _vendors: Vendors;
	set vendors(vendors: Vendors) {
		this._vendors = vendors;
	}
	get vendors() {
		return this._vendors;
	}

	private _tasks: Tasks;
	set tasks(tasks: Tasks) {
		this._tasks = tasks;
	}
	get tasks() {
		return this._tasks;
	}

	private _inspections: Inspections;
	set inspections(inspections: Inspections) {
		this._inspections = inspections;
	}
	get inspections() {
		return this._inspections;
	}

	private _woTemplates: WorkOrderTemplates;
	set woTemplates(woTemplates: WorkOrderTemplates) {
		this._woTemplates = woTemplates;
	}
	get woTemplates() {
		return this._woTemplates;
	}

	private _templateDefaultCustomFields: CustomFieldWrappers;
	set templateDefaultCustomFields(fields: CustomFieldWrappers) {
		this._templateDefaultCustomFields = fields;
	}
	get templateDefaultCustomFields() {
		return this._templateDefaultCustomFields;
	}

	private _taskDefaultCustomFields: CustomFieldWrappers;
	set taskDefaultCustomFields(fields: CustomFieldWrappers) {
		this._taskDefaultCustomFields = fields;
	}
	get taskDefaultCustomFields() {
		return this._taskDefaultCustomFields;
	}

	private _customFieldCodes: CustomFieldCodes;
	set customFieldCodes(customFieldCodes: CustomFieldCodes) {
		this._customFieldCodes = customFieldCodes;
	}
	get customFieldCodes() {
		return this._customFieldCodes;
	}

	private _priorities: Priorities;
	set priorities(priorities: Priorities) {
		this._priorities = priorities;
	}
	get priorities() {
		return this._priorities;
	}

	private _statuses: Statuses;
	set statuses(statuses: Statuses) {
		this._statuses = statuses;
	}
	get statuses() {
		return this._statuses;
	}

	private _employeeRates: EmployeeRates;
	set employeeRates(employeeRates: EmployeeRates) {
		this._employeeRates = employeeRates;
	}
	get employeeRates() {
		return this._employeeRates;
	}

	private _employeeTrades: EmployeeTrades;
	set employeeTrades(employeeTrades: EmployeeTrades) {
		this._employeeTrades = employeeTrades;
	}
	get employeeTrades() {
		return this._employeeTrades;
	}

	private _workOrderBudgets: WorkOrderBudgets;
	set workOrderBudgets(workOrderBudgets: WorkOrderBudgets) {
		this._workOrderBudgets = workOrderBudgets;
	}
	get workOrderBudgets() {
		return this._workOrderBudgets;
	}

	private _assetLocationsWithAssets: AssetLocationsWithAssets;
	set assetLocationsWithAssets(assetLocationsWithAssets: AssetLocationsWithAssets) {
		this._assetLocationsWithAssets = assetLocationsWithAssets;
	}
	get assetLocationsWithAssets() {
		return this._assetLocationsWithAssets;
	}

	private _workorderTeams: WorkOrderTeams;
	set workorderTeams(workorderTeams: WorkOrderTeams) {
		this._workorderTeams = workorderTeams;
	}
	get workorderTeams() {
		return this._workorderTeams;
	}

	private _failureCodes: FailureCodes;
	set failureCodes(failureCodes: FailureCodes) {
		this._failureCodes = failureCodes;
	}
	get failureCodes() {
		return this._failureCodes;
	}

	static fromContract(contract: WorkOrderMetaDataContract) {
		const metaData = new WorkOrderMetaData();
		metaData.employees = Employees.fromContracts(contract.Employees);
		metaData.equipments = Equipments.fromContracts(contract.Equipments);
		metaData.materials = Materials.fromContracts(contract.Materials);
		metaData.tasks = Tasks.fromContracts(contract.Tasks);
		metaData.taskDefaultCustomFields = WorkOrderMetaData.getDefaultCustomFields(
			metaData.tasks,
			(task: Task) => task.taskCustomFields,
			(cf: CustomFieldWrapper) => cf.isTaskDefault
		);
		metaData.inspections = Inspections.fromContracts(contract.Inspections);
		metaData.woTemplates = WorkOrderTemplates.fromContracts(contract.WOTemplates);
		metaData.templateDefaultCustomFields = WorkOrderMetaData.getDefaultCustomFields(
			metaData.woTemplates,
			(woTempalte: WorkOrderTemplate) => woTempalte.customFields,
			(cf: CustomFieldWrapper) => cf.isDefault
		);
		metaData.customFieldCodes = CustomFieldCodes.fromContracts(contract.CustomFieldCodes);
		metaData.priorities = Priorities.fromContracts(contract.WorkOrderPriority);
		metaData.statuses = Statuses.fromContracts(contract.WorkOrderStatus);
		metaData.employeeRates = EmployeeRates.fromContracts(contract.EmployeeRates);
		metaData.employeeTrades = EmployeeTrades.fromContracts(contract.EmployeeTrades);
		metaData.workOrderBudgets = WorkOrderBudgets.fromContracts(contract.WorkOrderBudget);
		metaData.vendors = Vendors.fromContracts(contract.Vendors);
		metaData.assetLocationsWithAssets = AssetLocationsWithAssets.fromContracts(contract.AssetLocationsWithAssets);
		metaData.workorderTeams = WorkOrderTeams.fromContracts(contract.WorkOrderTeam);
		metaData.failureCodes = FailureCodes.fromContracts(contract.FailureCodes);
		return metaData;
	}

	private static getDefaultCustomFields(
		templates: WorkOrderTemplates | Tasks,
		from: (template: WorkOrderTemplate|Task) => CustomFieldWrappers,
		isDefault: (cf: CustomFieldWrapper) => boolean
	) {
		const defaultCustomFields = new CustomFieldWrappers();
		const exist = new Set<string>();
		for (const template of templates) {
			for (const customField of from(template)) {
				if (!isDefault(customField) || exist.has(customField.customFieldId)) continue;
				defaultCustomFields.push(customField);
				exist.add(customField.customFieldId);
			}
		}
		return defaultCustomFields;
	}
}
