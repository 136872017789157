import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AnalyticsHub, AnalyticsError } from 'sedaru-util/analytics-hub';

/**
 * This component is designed to be placed at the beginning of the left side tab panel of the OMNI app.
 * It displays the OMNI icon and the notification count.
 */
@Component({
	selector: 'app-bot-tab',
	templateUrl: './bot-tab.component.html',
	styleUrls: ['./bot-tab.component.scss'],
	animations: [
		trigger('cyanBackgroundOnHovered', [
			// state transparent sets the background of the icon to transparent
			state(
				'transparent',
				style({
					backgroundColor: 'transparent'
				})
			),
			// state cyan sets the background of the icon to cyan
			state(
				'cyan',
				style({
					backgroundColor: 'rgba(0,254,254,0.5)'
				})
			),
			// transition from transparent to cyan animates slowly
			transition('transparent <=> cyan', animate('400ms 10ms ease-out'))
		])
	]
})
export class BotTabComponent {
	/**
	 * Keeps the state of the background color
	 */
	backgroundState = 'transparent';
	/**
	 * Keeps the number of bot active notifications
	 */
	notificationCount = 0;

	/**
	 * This method will update the notification count displayed on top of the bot icon
	 */
	// to be replaced with logic to update the notificationc count
	incrementNotificationCount() {
		this.notificationCount++;
	}
}
