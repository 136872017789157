import { LEMType } from '../../../models/work-order';
import { Injectable } from '@angular/core';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';

@Injectable({
	providedIn: 'root'
})
export class LEMService {
	/** the resolver to be used by the LEM list component */
	lemResolver: ListItemResolver;
	/** the list of items to be used by the LEM list component */
	lemList: Array<any>;
	/** the text to be shown as header of the LEM list component */
	lemHeader: string;
	/** the text to be shown as footer of the LEM list component */
	lemFooter: string;
	/** the current type of LEM, exampleL labor,equipment,material */
	lemType: LEMType;
	constructor() {}
}
