import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchResultItemResolver } from './search-result-item.resolver';
import { ListItemComponentBase } from '../omni-list/list-item/templates/list-item.model';

@Component({
	selector: 'app-search-result-item',
	templateUrl: './search-result-item.component.html',
	styleUrls: ['./search-result-item.component.scss']
})
export class SearchResultItemComponent extends ListItemComponentBase {
	@Input() resolver: SearchResultItemResolver;
	@Input() item: any;
	header: string;
	value: string;
	matchText: string;

	@Output() itemSelected = new EventEmitter<any>();
	@Output() rightIconClicked = new EventEmitter<any>();

	/** the first part of the string returned */
	firstChars: string;
	/** the part of the string that will be returned in bold */
	boldChars: string;
	/** the last part of the string returned */
	lastChars: string;

	ngOnInit() {
		if (this.resolver) {
			this.header = this.resolver._getHeader(this.item);
			this.value = this.resolver._getValue(this.item);
			this.matchText = this.resolver._getMatchText(this.item);
		}
		if (!this.header || !this.value || !this.matchText) {
			return;
		}
		const indexOfMatchText = this.value.toLowerCase().indexOf(this.matchText.toLowerCase());
		const endIndexOfMatchText = indexOfMatchText + this.matchText.length;
		this.firstChars = indexOfMatchText >= 0 ? this.transformLeadingAndTrailingSpaces(this.value.substring(0, indexOfMatchText)) : '';
		this.boldChars = indexOfMatchText >= 0 ? this.transformLeadingAndTrailingSpaces(this.value.substring(indexOfMatchText, endIndexOfMatchText)) : '';
		this.lastChars = indexOfMatchText >= 0 ? this.transformLeadingAndTrailingSpaces(this.value.substring(endIndexOfMatchText, this.value.length)) : this.value;
	}

	/** transforms leaidng and trailing spaces into &nbsp; */
	transformLeadingAndTrailingSpaces(stringToTransform: string): string {
		const firstTransformation = stringToTransform.replace(/^\s|\s$/, '&nbsp;');
		return firstTransformation.replace(/^\s|\s$/, '&nbsp;');
	}

	onRightIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.rightIconClicked.emit(this.item);
	}
}
