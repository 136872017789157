import { AssetLocationAttachmentUpload, WorkOrderAttachmentUpload } from 'app/workorder/attachment/uploader.service';
import { BulkUpdateJob } from 'domain-service/jobs/bulk-update-job/bulk-update-job';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ArcGISField, Metric, WorkOrderField } from 'models';
import { AssetRecord } from 'models/records/asset-record.model';
import { HierarchyAssetRecord } from 'models/records/hierarchy-asset-record.model';
import { Timeframe } from 'models/time-frame/timeframe.model';
import { WorkOrderWrapper, WorkAssetWrapper, WorkAssetWrappers, TaskWrapper, TaskWrappers, EmployeeCostWrapper, EmployeeCostWrappers, EquipmentCostWrapper, EquipmentCostWrappers, MaterialCostWrapper, MaterialCostWrappers, WorkCommentWrapper, WorkCommentWrappers, FailureReportWrapper, FailureReportWrappers, WorkOrderWrappers, WorkOrderMetaData, HierarchyNode, WorkOrderCapabilities } from 'models/work-order';
import { AssetLocationWithAssets } from 'models/work-order/asset-location-with-assets.model';
import { Asset } from 'models/work-order/asset.model';
import { FeatureLayer } from 'sedaru-util/esri-core';

export abstract class WorkOrderProviderBase {
	public interopService: OmniInteropService;

	public get workOrderLayer(): FeatureLayer {
		return null;
	}

	public initialize(customerCode?: string): void {}

	public generateWorkOrderKey(workOrderWrapper: WorkOrderWrapper): string {
		return null;
	}

	public generateWorkOrderKeys(keyCount: number) {
		return null;
	}

    public getInitialData(): Promise<WorkOrderMetaData> {
        return new Promise<WorkOrderMetaData>((resolve, reject) => {
            resolve(new WorkOrderMetaData());
        });
    }

	public getCapabilities(): Promise<WorkOrderCapabilities> {
		return new Promise<WorkOrderCapabilities>((resolve, reject) => {
			resolve(new WorkOrderCapabilities());
		});
	}

	//#region Work Orders CRUD
	public createWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return new Promise<WorkOrderResult>((resolve, reject) => {
			resolve(new WorkOrderResult());
		});
	}

	public createWorkOrderWithValidation(workOrderWrapper: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		return new Promise<WorkOrderResult>((resolve, reject) => {
			resolve(new WorkOrderResult());
		});
	}

	public getWorkOrders(workOrderKeys: string[], stubsOnly: boolean): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>((resolve, reject) => {
			resolve(new WorkOrderWrappers());
		});
	}

	public getMatchingWorkOrders(query: string, isContains: boolean): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>((resolve, reject) => {
			resolve(new WorkOrderWrappers());
		});
	}

	public updateWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return new Promise<WorkOrderResult>((resolve, reject) => {
			resolve(new WorkOrderResult());
		});
	}

	public updateWorkOrderWithValidation(workOrderWrapper: WorkOrderWrapper, timeoutDelegate?: () => void): Promise<WorkOrderResult> {
		return new Promise<WorkOrderResult>((resolve, reject) => {
			resolve(new WorkOrderResult());
		});
	}

	public deleteWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return new Promise<WorkOrderResult>((resolve, reject) => {
			resolve(new WorkOrderResult());
		});
	}
	//#endregion

	//#region Work Orders Bulk Create/Update
	public bulkCreate(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		return null;
	}

	public bulkEdit(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		return null;
	}

	public bulkDelete(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		return null;
	}
	//#endregion

	//#region Work Asset CRUD
	public createWorkAsset(workOrderWrapper: WorkOrderWrapper, workAssetWrapper: WorkAssetWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getWorkAssets(workOrderKey: string): Promise<WorkAssetWrappers> {
		return new Promise<WorkAssetWrappers>((resolve, reject) => {
			resolve(new WorkAssetWrappers());
		});
	}

	public updateWorkAsset(workOrderWrapper: WorkOrderWrapper, workAssetWrapper: WorkAssetWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteWorkAsset(workOrderWrapper: WorkOrderWrapper, workAssetWrapper: WorkAssetWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Work Tasks CRUD
	public createWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getWorkTasks(workOrderKey: string): Promise<TaskWrappers> {
		return new Promise<TaskWrappers>((resolve, reject) => {
			resolve(new TaskWrappers());
		});
	}

	public updateWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteWorkTask(workOrderWrapper: WorkOrderWrapper, workTaskWrapper: TaskWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Employee Costs CRUD
	public createEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getEmployeeCosts(workOrderKey: string): Promise<EmployeeCostWrappers> {
		return new Promise<EmployeeCostWrappers>((resolve, reject) => {
			resolve(new EmployeeCostWrappers());
		});
	}

	public updateEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteEmployeeCost(workOrderWrapper: WorkOrderWrapper, employeeCostWrapper: EmployeeCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Equipment Costs CRUD
	public createEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getEquipmentCosts(workOrderKey: string): Promise<EquipmentCostWrappers> {
		return new Promise<EquipmentCostWrappers>((resolve, reject) => {
			resolve(new EquipmentCostWrappers());
		});
	}

	public updateEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteEquipmentCost(workOrderWrapper: WorkOrderWrapper, equipmentCostWrapper: EquipmentCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Material Costs CRUD
	public createMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getMaterialCosts(workOrderKey: string): Promise<MaterialCostWrappers> {
		return new Promise<MaterialCostWrappers>((resolve, reject) => {
			resolve(new MaterialCostWrappers());
		});
	}

	public updateMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteMaterialCost(workOrderWrapper: WorkOrderWrapper, materialCostWrapper: MaterialCostWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Work Comments CRUD
	public createWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getWorkComments(workOrderKey: string): Promise<WorkCommentWrappers> {
		return new Promise<WorkCommentWrappers>((resolve, reject) => {
			resolve(new WorkCommentWrappers());
		});
	}

	public updateWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteWorkComment(workOrderWrapper: WorkOrderWrapper, workCommentWrapper: WorkCommentWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Failure Reports CRUD
	public createFailureReport(workOrderWrapper: WorkOrderWrapper, failureReportWrapper: FailureReportWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public getFailureReports(workOrderKey: string): Promise<FailureReportWrappers> {
		return new Promise<FailureReportWrappers>((resolve, reject) => {
			resolve(new FailureReportWrappers());
		});
	}

	public updateFailureReport(workOrderWrapper: WorkOrderWrapper, failureReportWrapper: FailureReportWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}

	public deleteFailureReport(workOrderWrapper: WorkOrderWrapper, failureReportWrapper: FailureReportWrapper): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(false);
		});
	}
	//#endregion

	//#region Creating new models
	createWorkOrderModel(workOrderKey: string, assetRecord?: AssetRecord | HierarchyAssetRecord): WorkOrderWrapper {
		return null;
	}

	public createWorkAssetModel(workOrderWrapper: WorkOrderWrapper, assetRecord: AssetRecord): WorkAssetWrapper {
		return null;
	}

	public createWorkAssetFromHierarchyRecordModel(workOrderWrapper: WorkOrderWrapper, hierarchyAssetRecord: HierarchyAssetRecord): WorkAssetWrapper {
		return null;
	}

	public createWorkTaskModel(workOrderWrapper: WorkOrderWrapper): TaskWrapper {
		return null;
	}

	public createEmployeeCostModel(workOrderWrapper: WorkOrderWrapper): EmployeeCostWrapper {
		return null;
	}

	public createMaterialCostModel(workOrderWrapper: WorkOrderWrapper): MaterialCostWrapper {
		return null;
	}

	public createEquipmentCostModel(workOrderWrapper: WorkOrderWrapper): EquipmentCostWrapper {
		return null;
	}

	public createWorkCommentModel(workOrderWrapper: WorkOrderWrapper): WorkCommentWrapper {
		return null;
	}
	//#endregion

	//#region Misc. methods
	public cancelRealTimeSaveOperation(): void { }

	public getRelatedWorkOrders(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>((resolve, reject) => {
			resolve(new WorkOrderWrappers());
		});
	}

	public async getWorkOrdersByAsset(assetType: string, assetKey: string): Promise<WorkOrderWrappers> {
		return new Promise<WorkOrderWrappers>((resolve, reject) => {
			resolve(new WorkOrderWrappers());
		});
	}

	public getWorkOrdersXY(workOrders: WorkOrderWrapper[]): void {
		return;
	}

	public getWorkOrderByFilter(metric: Metric, timeFrame?: Timeframe): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			resolve();
		});
	}

	public getArcGISFields(): ArcGISField[] {
		return [];
	}

	public getDefaultSummaryFields(recurrenceOnly: boolean): WorkOrderField[] {
		return [];
	}
	//#endregion

	//#region Hierarchy
	public getHierarchy(assetLocation: AssetLocationWithAssets): Promise<HierarchyNode<AssetLocationWithAssets>> {
		return new Promise<HierarchyNode<AssetLocationWithAssets>>((resolve, reject) => {
			resolve(null);
		});
	}

	public refreshHierarchyNodes(node: HierarchyNode<AssetLocationWithAssets>,
		updateAttachments: boolean = true, updateWorkOrderCount: boolean = true): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			resolve();
		});
	}

	public getAsset(locationId?: string, activeAsset?: string): Promise<Asset> {
		return new Promise<Asset>((resolve, reject) => {
			resolve(null);
		});
	}
	//#endregion

	//#region Attachments
	public getWorkOrderAttachmentUpload(file: File, workOrderWrapper: WorkOrderWrapper): WorkOrderAttachmentUpload {
		return null;
	}

	public getAssetLocationAttachmentUpload(file: File, assetLocation: AssetLocationWithAssets): AssetLocationAttachmentUpload {
		return null;
	}
	//#endregion
}

export class WorkOrderResult {
	public isSuccess: boolean;
	public errorMessage?: string;
}
