<div class="scrolling-content">
	<mat-action-list class="tile-settings">
		<mat-list-item class="light-gray-background menu-panel-input-field omni-list-item">
			<div
				[matTooltip]="
					currentTile.metric && currentTile.metric.definition.name
						? currentTile.metric.definition.name
						: 'not set'
				"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#tileMetricTooltip="toggleTooltip"
				[matTooltipDisabled]="tileMetricTooltip.isTooltipDisabled"
			>
				<span class="control-title">metric</span>
				<input
					class="omni-restrict-overflow"
					disabled
					[value]="currentTile.metric ? currentTile.metric.definition.name : 'not set'"
				/>
				<button mat-icon-button class="omni-icon-button">
					<img (click)="onGoToMetricList()" src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>

		<div class="short-item-wrapper">
			<mat-list-item class="dark-gray-background menu-panel-input-field omni-list-item short">
				<div>
					<span class="control-title">tile color</span>
					<input placeholder="not set" [value]="backgroundRGBCode" readonly />
					<button mat-icon-button class="omni-icon-button">
						<mcc-color-picker
							hideUsedColors="true"
							usedSizeColors="0"
							class="mcc-cp-small"
							[selectedColor]="tileBackgroundColor"
							(selected)="onBackgroundColorChange($event)"
						>
							<mcc-color-picker-collection
								class="color-picker-collection-bg"
								[colors]="sedaruColors"
								size="8"
								label="sedaru colors"
							>
							</mcc-color-picker-collection>
						</mcc-color-picker>
					</button>
				</div>
			</mat-list-item>

			<mat-list-item class="dark-gray-background menu-panel-input-field omni-list-item short">
				<div>
					<span class="control-title">text color</span>
					<input placeholder="not set" [value]="foregroundRGBCode" readonly />
					<button mat-icon-button class="omni-icon-button">
						<mcc-color-picker
							hideUsedColors="true"
							usedSizeColors="0"
							class="mcc-cp-small"
							[selectedColor]="tileForegroundColor"
							(selected)="onForegroundColorChange($event)"
						>
							<mcc-color-picker-collection
								class="color-picker-collection-bg"
								[colors]="sedaruColors"
								size="8"
								label="sedaru colors"
							>
							</mcc-color-picker-collection>
						</mcc-color-picker>
					</button>
				</div>
			</mat-list-item>
		</div>

		<mat-list-item class="light-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">selected time frame</span>
				<input
					class="omni-restrict-overflow"
					disabled
					placeholder="not set" [value]="currentTile.uiTimeframeFilter.timeFrame?.text?.toLowerCase()"
					readonly
				/>
				<button mat-icon-button class="omni-icon-button">
					<img (click)="GoToTileTimeframeFilter()" src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<button mat-button class="omni-md-font" (click)="openDeletePrompt()">
			<img class="omni-sm-img" src="assets/delete.png" /> remove
		</button>
	</mat-card-actions>
</mat-card-footer>
