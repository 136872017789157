import { Injectable } from '@angular/core';
import { TrendPoint } from 'interfaces/trend-point.interface';
import { Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { OmniInteropService } from '../../../domain-service/omni-interop.service';
import { ChannelTypes } from '../../../models/channel-type.enum.model';
import { TimeframeFilter } from '../../../models/time-frame/timeframe-filter.model';
import { TimeframeUnit } from '../../../models/time-frame/timeframe-unit.enum';
import { Timeframe } from '../../../models/time-frame/timeframe.model';
import { TimeframedMetricSubscriber } from '../../../models/time-frame/timeframed-metric-subscriber';
import { UserTimeframeOptionType } from '../../../models/time-frame/user-timeframe-option.enum';
import { UserTimeframeOption } from '../../../models/time-frame/user-timeframe-option.model';
import { TypeOfValueEnum } from '../../../models/type-of-value.model';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { CanvasTrend } from '../../../omni-model/canvas-trend.model';
import { TrendData } from './trend-data';
import { TrendDefinition } from './trend-defintion.model';

const moment = require('moment');

enum DataQueryMode {
	allTime,
	timeFrame
}

enum DataGroupingType {
	none,
	date,
	hour
}

enum AggregationMode {
	sum,
	average,
	last
}

interface DataSubsubscriptionInfo {
	canvasTrend: CanvasTrend,
	subject: ReplaySubject<TrendData>,
	metricSubscriber: TimeframedMetricSubscriber,
	data: TrendPoint[]
}

@Injectable()
export class CanvasTrendDataService {

	private dataQueryMode: DataQueryMode;

	/**
	 * Get the start date of the data timeframe.
	 */
	private dataTimeframeStartDate: Date;

	/**
	 * Get the end date of the data timeframe.
	 */
	private dataTimeframeEndDate: Date;

	/**
	 * The default maximum number of days when the trend period is longer than 90 days.
	 * Ex. if a uesr trends a metric that has 120 days, the query will only return the 90 days
	 *     when the timeframe is not AllTime.
	 */
	defaultMaximumDaysOfData = 90;

	subscriptionList: DataSubsubscriptionInfo[];

	constructor(private interopService: OmniInteropService) {
		this.subscriptionList = [];
	}


	/**
	 * An observable that returns new data as the timeframe changes.
	 * @param canvasTrend
	 */
	getDataStream(canvasTrend: CanvasTrend): Observable<TrendData> {

		// the purpose of this method is to set the query mode and timeframe,
		// the data request is performed on subscription.
		let subscriptionInfo = this.findDataSubscriptionInfo(canvasTrend);
		if (!subscriptionInfo) {
			// using ReplaySubject instead of Subject because the metric
			// result could be available before the subscription is returned,
			// this will cause the data notification to be missed.
			const subject = new ReplaySubject<TrendData>(1);
			subscriptionInfo = <any>{ canvasTrend, subject };
			if (!this.dataTimeframeStartDate || !this.dataTimeframeEndDate) {
				this.setDataQueryAndDateRange(subscriptionInfo);
			}
			this.subscriptionList.push(subscriptionInfo);
		}

		const obs = new Observable((subscriber: Subscriber<TrendData>) => {
			this.triggerDataRequest(subscriptionInfo);

			return subscriptionInfo.subject
				.subscribe(n => subscriber.next(n)
					, e => subscriber.error(e)
					, () => subscriber.complete()
				);

		});


		return obs;
	}


	/**
	 * Change the data time frame, this will cause all existing trend data to be
	 * updated.
	 * NOTE: If the data query mode is not already AllTime, this will cause all existing
	 *       data to be reloaded.
	 * @param startDate
	 * @param endDate
	 */
	setDataTimeframe(startDate: Date, endDate: Date) {
		// only need to adjust the timeframe if valid dates are provided.
		if (startDate && endDate) {
			if ((this.dataTimeframeStartDate != startDate) || (this.dataTimeframeEndDate != endDate)) {
				this.dataTimeframeStartDate = moment(startDate).startOf('day').toDate();
				this.dataTimeframeEndDate = moment(endDate).endOf('day').toDate();

				if (this.dataQueryMode != DataQueryMode.allTime) {
					this.dataQueryMode = DataQueryMode.allTime;
					// data needs to be refreshed to the required timeframe range
					for (let i = 0; i < this.subscriptionList.length; i++) {
						const subscriptionInfo = this.subscriptionList[i];
						this.triggerDataRequest(subscriptionInfo);
					}
				} else {
					for (let i = 0; i < this.subscriptionList.length; i++) {
						const subscriptionInfo = this.subscriptionList[i];
						this.triggerNewTimeframeTrendData(subscriptionInfo);
					}
				}
			}
		}
	}

	setDataQueryAndDateRange(subscriptionInfo: DataSubsubscriptionInfo) {
		// NOTE: this should only called for the first trend.
		let startDate;
		let endDate;
		if (subscriptionInfo.canvasTrend.isHistoryTrend) {
			const trendDef = subscriptionInfo.canvasTrend.canvasTab.trendDefinition;
			if (trendDef.dateStartValue && trendDef.dateEndValue) {
				startDate = new Date(trendDef.dateStartValue);
				endDate = new Date(trendDef.dateEndValue);
			} else if (trendDef.dateEndValue) {
				endDate = new Date(trendDef.dateEndValue);
			}

		} else {
			const canvasTab: CanvasTab = subscriptionInfo.canvasTrend.canvasTab;
			let timeframeFilter = null;
			const tile = canvasTab.tile;
			if (tile) {
				timeframeFilter = tile.timeFrameFilter ?? tile.metricsTimeframeFilter;
			}
			if (!(canvasTab.defaultMetric || !timeframeFilter)) {
				const timeFrame = timeframeFilter.timeFrame;
				if ((timeFrame.startDate) && (timeFrame.endDate)) {
					endDate = timeFrame.endDate;
					startDate = timeFrame.startDate;
				}
			}

		}

		let interval = null;
		if (startDate && endDate) {
			interval = moment(endDate).diff(startDate, 'days');
		} else {
			endDate = new Date();
		}

		if ((!startDate) || (interval > this.defaultMaximumDaysOfData)) {
			startDate = moment(endDate).subtract(this.defaultMaximumDaysOfData, 'days').toDate();
		}

		const today = moment().startOf('day');
		const mStartDate = moment(startDate);
		if (mStartDate.isAfter(today) && ((!interval) || (interval > this.defaultMaximumDaysOfData))) {
			startDate = today;
			endDate = moment(today).add(this.defaultMaximumDaysOfData, 'days');
		}

		this.dataTimeframeStartDate = moment(startDate).startOf('day').toDate();
		this.dataTimeframeEndDate = moment(endDate).endOf('day').toDate();
		this.dataQueryMode = DataQueryMode.timeFrame;

	}



	/**
	 * Remove a trend from the data service, this will complete the observable
	 * and will not trigger new data.
	 * @param canvasTrend
	 */
	remove(canvasTrend: CanvasTrend) {
		const subscriptionInfo = this.findDataSubscriptionInfo(canvasTrend);
		if (subscriptionInfo) {
			const idx = this.subscriptionList.indexOf(subscriptionInfo);
			if (idx >= 0) {
				this.subscriptionList.splice(idx, 1);
				subscriptionInfo.subject.complete();
				subscriptionInfo.metricSubscriber.unsubscribe();
			}

			if (this.subscriptionList.length == 0) {
				this.dataQueryMode = null;
				this.dataTimeframeEndDate = null;
				this.dataTimeframeStartDate = null;
			}
		}
	}


	/**
	 * Find an existing trend for the canvasTab.
	 * @param canvasTab
	 */
	findTrend(canvasTab: CanvasTab): CanvasTrend {
		return this.subscriptionList.find(t => t.canvasTrend.canvasTab === canvasTab)?.canvasTrend;
	}

	/**
	 * Set the metric subscriber for the subscription.
	 * @param subscriptionInfo
	 */
	private setMetricSubscriber(subscriptionInfo: DataSubsubscriptionInfo, timeframeFilter: TimeframeFilter): TimeframedMetricSubscriber {

		if (subscriptionInfo.metricSubscriber) {
			subscriptionInfo.metricSubscriber.unsubscribe();
		}
		const canvasTab: CanvasTab = subscriptionInfo.canvasTrend.canvasTab;
		const metric = canvasTab.metric;
		const metricSubscriber = this.interopService.metricManager.getMetricSubscriber(metric, timeframeFilter);
		subscriptionInfo.metricSubscriber = metricSubscriber;
		metricSubscriber.onMetricUpdated = () => {
			this.onNewMetricData(subscriptionInfo);
		};

		return metricSubscriber;
	}


	/**
	 * Trigger a data request.
	 * @param dataSubscriptionInfo
	 */
	private triggerDataRequest(dataSubscriptionInfo: DataSubsubscriptionInfo) {
		if (dataSubscriptionInfo.canvasTrend.isHistoryTrend) {
			this.getHistoryFieldTrendData(dataSubscriptionInfo);
		} else {
			const timeframeFilter = this.getQueryTimeFrameFilter(dataSubscriptionInfo.canvasTrend.canvasTab);
			const metricSubscriber = this.setMetricSubscriber(dataSubscriptionInfo, timeframeFilter);
			const canvasTrend = dataSubscriptionInfo.canvasTrend;
			if (!metricSubscriber.outfields.includes(canvasTrend.dateField)) {
				metricSubscriber.outfields.push(canvasTrend.dateField);
			}
			if (!metricSubscriber.outfields.includes(canvasTrend.valueTypeField)) {
				metricSubscriber.outfields.push(canvasTrend.valueTypeField);
			}
			const isResultValid = metricSubscriber.timeframedMetric.neededFieldsHaveBeenRequested;
			// if the outfields are changed, then this will force a request to the
			// server, otherwise it will return the current data.
			const results = metricSubscriber.timeframedMetric.results;
			if (isResultValid) {
				console.log('CanvasTrendDataService:triggerDataRequest -> Valid Result', results);
				// this will use the current data to trigger the new trend event.
				this.onNewMetricData(dataSubscriptionInfo);
			}
		}
	}


	/**
	 * Executed when new meteric data received.
	 * @param dataSubscriptionInfo
	 */
	private onNewMetricData(dataSubscriptionInfo: DataSubsubscriptionInfo) {
		console.log('CanvasTrendDataService:onNewMetricData -> New Data', dataSubscriptionInfo);
		const metricSubscriber = dataSubscriptionInfo.metricSubscriber;
		const canvasTrend = dataSubscriptionInfo.canvasTrend;
		const timeframedMetric = metricSubscriber.timeframedMetric;
		const metricType = timeframedMetric.metric.definition.source.type;
		const typeOfValue = timeframedMetric.metric.definition.displayValueSettings.typeOfValue.enumValue;
		const plotPoints = this.getFormattedMetricPlotPoints(canvasTrend, timeframedMetric.results, metricType, typeOfValue);
		dataSubscriptionInfo.data = plotPoints;
		this.triggerNewTimeframeTrendData(dataSubscriptionInfo);
	}


	/**
	 * Raises a new trend data event for the given timeframe.
	 * @param dataSubscriptionInfo
	 */
	private triggerNewTimeframeTrendData(dataSubscriptionInfo: DataSubsubscriptionInfo) {
		const startDate = moment(this.dataTimeframeStartDate);
		const endDate = moment(this.dataTimeframeEndDate);
		const intervalDays = endDate.diff(startDate, 'days');

		let plotPoints = dataSubscriptionInfo.data
											.filter(t => this.isDateWithinDataTimeFrame(t.date));

		const canvasTrend = dataSubscriptionInfo.canvasTrend;
		const canvasTab = canvasTrend.canvasTab;

		const dataGrouping = this.getDataGrouping(canvasTrend, intervalDays);
		const aggregationMode = this.getAggregationMode(canvasTrend);
		plotPoints = this.groupAndSortedData(plotPoints, dataGrouping, aggregationMode);

		const newTrendData: TrendData = {
			timeframeStartDate: this.dataTimeframeStartDate,
			timeframeEndDate: this.dataTimeframeEndDate,
			timeframeIntervalDays: intervalDays,
			points: plotPoints,
			canvasTrend: dataSubscriptionInfo.canvasTrend
		};
		dataSubscriptionInfo.subject.next(newTrendData);
	}


	/**
	 * Group the trend points.
	 * @param trendPoints
	 * @param groupingType
	 */
	private groupAndSortedData(trendPoints: TrendPoint[], groupingType: DataGroupingType, aggregationMode: AggregationMode) {
		let startOf = null;
		switch (groupingType) {
			case DataGroupingType.hour:
				startOf = 'hour';
				break;

			case DataGroupingType.date:
				startOf = 'day';
				break;
		}
		if (startOf) {

			const reducedDateList = trendPoints.reduce((acc, curr) => {
				const date = moment(curr.date).startOf(startOf);
				const x = date.format('YYYY-MM-DD hh:mm:ss a');
				if (aggregationMode == AggregationMode.last) {
					if (acc[x]) {
						if (curr.date.isSameOrAfter(acc[x].date)) {
							acc[x].date = curr.date;
							acc[x].y = curr.y;
						}
					} else {
						acc[x] = <TrendPoint>{ x: x, y: curr.y, date: curr.date, recordCount: 1 };
					}
				} else {
					if (acc[x]) {
						acc[x].y += curr.y;
						acc[x].recordCount++;
					} else {
						acc[x] = <TrendPoint>{ x: x, y: curr.y, date: date, recordCount: 1 };
					}
				}

				return acc;
			}, {});

			trendPoints = [];
			for (const [key, value] of Object.entries(reducedDateList)) {
				const point = <TrendPoint>value;
				if (aggregationMode == AggregationMode.average) {
					point.y = point.y / point.recordCount;
				}
				trendPoints.push(point);
			}
		}

		trendPoints.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));
		return trendPoints;
	}


	/**
	 * The grouping type to use when trend points are grouped.
	 * @param canvasTrend
	 * @param intervalDays
	 * @param typeOfValue
	 * @returns
	 */
	private getDataGrouping(canvasTrend: CanvasTrend, intervalDays: any): DataGroupingType {
		let dataGrouping = DataGroupingType.none;
		const metric = canvasTrend.canvasTab?.metric;
		let typeOfValue = null;
		if (metric) {
			typeOfValue = metric.definition.displayValueSettings.typeOfValue.enumValue;
		}

		// if the trend is history type of value will be null
		if (typeOfValue != TypeOfValueEnum.none) {
			if (intervalDays > 0) {
				dataGrouping = DataGroupingType.date;
			} else {
				dataGrouping = DataGroupingType.hour;
			}
		}

		return dataGrouping;
	}

	/**
	 * Get the final value type when the data grouped.
	 * @param canvasTrend
	 * @returns
	 */
	private getAggregationMode(canvasTrend: CanvasTrend) {
		const metric = canvasTrend.canvasTab?.metric;
		let typeOfValue = null;
		if (metric) {
			typeOfValue = metric.definition.displayValueSettings.typeOfValue.enumValue;
		}

		let mode: AggregationMode = null;
		switch (typeOfValue) {
			default:
				mode = AggregationMode.sum;
				break;

			case TypeOfValueEnum.averageValue:
				mode = AggregationMode.average;
				break;

			case TypeOfValueEnum.mostRecentValue:
				mode = AggregationMode.last;
				break;

		}

		return mode;
	}

	private findDataSubscriptionInfo(canvasTrend: CanvasTrend): DataSubsubscriptionInfo {
		if (this.subscriptionList) {
			return this.subscriptionList.find(t => t.canvasTrend === canvasTrend);
		}
		return null;
	}



	/**
	 * Get the data query time filter based on the query mode.
	 * @param canvasTab
	 */
	private getQueryTimeFrameFilter(canvasTab: CanvasTab) {
		const timeframeFilter = new TimeframeFilter();
		if (this.dataQueryMode == DataQueryMode.allTime) {
			timeframeFilter.timeFrame = this.getAllTimeframe();
		} else {
			timeframeFilter.timeFrame = this.getRangeTimeframe(this.dataTimeframeStartDate, this.dataTimeframeEndDate);
		}

		timeframeFilter.timeframeField = canvasTab.metricSubscription.timeframedMetric.timeframeFilter?.timeframeField;
		return timeframeFilter;
	}


	private getAllTimeframe(): Timeframe {
		const timeframeDefinition = UserTimeframeOption.getTimeFrameDefinition(UserTimeframeOptionType.AllDates
			, TimeframeUnit.Days)

		const timeframe = new Timeframe(timeframeDefinition);
		return timeframe;
	}

	private getRangeTimeframe(startDate: Date, endDate: Date): Timeframe {
		// diff finds the interval betwee the two dates, it does not include the end date.
		const interval = moment(endDate).diff(startDate, 'days') + 1;
		const timeframeDefinition = UserTimeframeOption.getTimeFrameDefinition(UserTimeframeOptionType.DateRange
			, TimeframeUnit.Days
			, interval
			, true
			, startDate);

		const timeframe = new Timeframe(timeframeDefinition);
		return timeframe;
	}


	/**
	 * Format the metric results
	 * @param canvasTrend
	 * @param metricResults
	 * @param metricType
	 * @param typeOfValue
	 */
	private getFormattedMetricPlotPoints(canvasTrend: CanvasTrend, metricResults: any, metricType: ChannelTypes, typeOfValue: TypeOfValueEnum): TrendPoint[] {

		const trendData: TrendPoint[] = [];

		// go through the metric data and transform it to
		metricResults.forEach(result => {
			let attributes;
			attributes = this.getAttributesFromMetricData(metricType, result);
			if (!attributes) {
				return;
			}

			const dateProperty = this.getProperty(attributes, canvasTrend.dateField);
			let dateField = dateProperty?.property;
			let dateValue = dateProperty?.value;

			if ((!dateField) || (!dateValue) || (dateValue === '0001-01-01T00:00:00')) {
				return;
			} else if (metricType === ChannelTypes.WorkOrder) {
				if (dateField[0] === '_') {
					dateField = dateField.slice(1);
					dateValue = attributes[dateField];
				}
			}

			let { value: value } = this.getProperty(attributes, canvasTrend.valueTypeField);
			const date = moment(dateValue);

			switch (typeOfValue) {
				case TypeOfValueEnum.recordCount:
				case TypeOfValueEnum.percetangeOfTotal:
					value = 1;
					break;
			}
			const trendDateTime = date.utc()
									.format('YYYY-MM-DD hh:mm:ss a');

			trendData.push({
				y: value,
				x: trendDateTime,
				date: date
			});
		});
		return trendData;
	}


	/**
	 * Check if the given date is within the timeframe.
	 * @param date
	 */
	private isDateWithinDataTimeFrame(date: any) {
		return date.isSameOrAfter(this.dataTimeframeStartDate) && (date.isSameOrBefore(this.dataTimeframeEndDate));
	}


	/**
	 * Get a property and it's value.
	 * @param attributes
	 * @param fieldName
	 */
	private getProperty(attributes: any, fieldName: string) {
		fieldName = fieldName.toLowerCase();
		for (const prop in attributes) {
			if (prop.toLowerCase().includes(fieldName)) {
				return {
					property: prop,
					value: attributes[prop]
				}
			}
		}
	}


	/**
	 * Get the attributes model for the given metric.
	 * @param metricType
	 * @param result
	 */
	private getAttributesFromMetricData(metricType: ChannelTypes, result: any) {
		let attributes: any = null;
		switch (metricType) {
			case ChannelTypes.History:
			case ChannelTypes.WorkOrder:
				if (result.attributes) {
					attributes = (typeof result.attributes === 'string') ? JSON.parse(result.attributes) : result.attributes;
				}

				if ((!attributes) && (metricType == ChannelTypes.WorkOrder)) {
					attributes = result;
				}
				break;

			default:
				// should not get here
				break;
		}
		return attributes;
	}


	getHistoryFieldTrendData(dataSubscriptionInfo: DataSubsubscriptionInfo) {
		const canvasTrend: CanvasTrend = dataSubscriptionInfo.canvasTrend;
		const trendData: TrendPoint[] = [];
		let historyQuery;

		const trendDefinition: TrendDefinition = canvasTrend.canvasTab.trendDefinition;

		const format = 'YYYY-MM-DD HH:mm:ss';
		const startDate = moment(this.dataTimeframeStartDate).startOf('day').format(format);
		const endDate = moment(this.dataTimeframeEndDate).endOf('day').format(format);

		if (startDate && endDate) {
			historyQuery = `${trendDefinition.assetIdFieldName} = '${trendDefinition.assetId}'
				AND ${trendDefinition.dateFieldName} >= TIMESTAMP '${startDate}'
				AND ${trendDefinition.dateFieldName} <= TIMESTAMP '${endDate}'`;
		} else {
			historyQuery = `${trendDefinition.assetIdFieldName} = '${trendDefinition.assetId}'
				AND ${trendDefinition.dateFieldName} >= '${trendDefinition.dateStartValue}'
				AND ${trendDefinition.dateFieldName} <= '${trendDefinition.dateEndValue}'`;
		}

		trendDefinition.historyLayer
			.query(historyQuery, `${trendDefinition.dateFieldName},+${trendDefinition.defaultFieldName}`)
			.then(features => {
				if (features?.length > 0) {
					for (let i = 0; i < features.length; i++) {
						const currentFeature = features[i];
						const dateValueStr = currentFeature.attributes[trendDefinition.dateFieldName];
						const dateValue = moment(dateValueStr);
						const trendDate = dateValue.utc()
							.format('YYYY-MM-DD hh:mm:ss a');
						const trendValue = currentFeature.attributes[trendDefinition.defaultFieldName];
						if (trendDate && trendValue) {
							trendData.push({
								x: trendDate,
								y: trendValue,
								date: dateValue
							});
						}
					}

					dataSubscriptionInfo.data = trendData;
					this.triggerNewTimeframeTrendData(dataSubscriptionInfo);
				}
			});

	}

}
