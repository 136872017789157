<div class="dynamic-form">
	<div class="pager" *ngIf="formDefinition.pages.length > 1">
		<img src="assets/bullet.png" *ngFor="let bullet of formDefinition.pages; index as i" [class.active]="getPageVisibility(i) === 'active'" (click)="setActivePage(i)" />
	</div>
	<div class="header" *ngIf="formResolver.showFormTitle(formDefinition)">
		{{ formResolver.getFormTitle(formDefinition) }}
		<mat-divider></mat-divider>
	</div>
	<div class="page-wrapper">
		<ng-container *ngFor="let page of formDefinition.pages; index as i">
			<app-form-page [pageVisibility]="getPageVisibility(i)" [formResolver]="formResolver" [formPage]="page"></app-form-page>
		</ng-container>
	</div>
</div>
