import { ArcGISHistoryChannelAttributes } from './../../../../../../models/arc-gis-history-channel-attributes.model';
import { Component, Input, ElementRef, QueryList, ViewChildren } from '@angular/core';
import {
	ArcGISField,
	QuerySelectionType,
	QueryOperator,
	Operator,
	QueryField,
	QuerySource,
	ChannelTypes,
	Metric,
	ArcGISWorkOrderChannelAttributes,
	ArcGISAssetChannelAttributes
} from 'models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { DeprecatedMetricTimeFrame } from 'models/metric-timeframe.model';
import { QueryFieldValueEditingMode } from './query-field-value-editing-mode.enum';
import { UserService } from 'app/user/user.service';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { FeatureLayer } from 'sedaru-util/esri-core/feature-layer';
import { AssetChannelAttributes } from 'models/asset-channel-attributes.model';
import { DropdownComponent } from 'app/ui-components/drop-down/drop-down.component';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { FieldType } from 'sedaru-util/esri-core';

@Component({
	selector: 'app-query-field',
	templateUrl: './query-field.component.html',
	styleUrls: ['./query-field.component.scss']
})
export class QueryFieldComponent extends MenuPanelBaseComponent {
	private _dropdowns: QueryList<DropdownComponent>;
	@ViewChildren(DropdownComponent) set dropdowns(dds: QueryList<DropdownComponent>) {
		if (!dds) return;
		this._dropdowns = dds;
		this.updateMaxHeight();
	}

	/**
	 * The sedaru grey color code
	 */
	readonly sedaruGrey = 'rgba(84, 84, 84, 0.6)';

	/**
	 * The default sedaru grey color code
	 */
	readonly defaultSedaruGrey = 'rgba(128, 128, 128, 0.6)';

	/**
	 * Item to edit at each sequence
	 */
	itemToEdit: QuerySelectionType = QuerySelectionType.field;

	/**
	 * A list of opertor to select from
	 */
	operatorList: Operator[] = [];

	/**
	 * A list join operators
	 */
	joinOperationList: Operator[] = Object.values(QueryOperator.forJoin);

	/**
	 * The value list holds all the coded values
	 */
	valueList: { name: string; code: string }[];

	/**
	 * The editing mode when editing a value
	 */
	valueEditingMode = 'selection';

	/**
	 * The html input criteria for the input tag
	 */
	htmlInputCriteria: any = { type: '' };

	/**
	 * list of avilable arcGISFields
	 */
	filtedArcGISFieldList: ArcGISField[] = [];

	/**
	 * a indicator to loading selecteable value for string fields when operator equals 'Is' and 'IsNot'
	 */
	loadingDataBaseValue: boolean;

	private topFiftyValueDict: Map<string, Array<{ name: string; code: string }>>;

	/**
	 * The available arcGISField recieved from parent component.
	 */
	@Input() arcGISFieldList: ArcGISField[] = [];

	/**
	 * Input recieved from parent component indicate the index for the field
	 */
	@Input() fieldIndex: number;

	/**
	 * Input received from parent component the query field
	 */
	@Input() queryField: QueryField;

	/**
	 * Input received from parent component the query source
	 */
	@Input() querySource: QuerySource;

	/**
	 * Input in the queryField component list to manipulate the last query field component in the list
	 */
	@Input() queryFieldComponentList: QueryList<QueryFieldComponent>;

	@Input() metric: Metric;

	private waitForDropDowns;
	private _initialHeight: number;
	@Input() set initialHeight(h: number) {
		if (!h) return;
		this._initialHeight = h;
		if (!this._dropdowns) {
			// wait until dropdowns are defined
			this.waitForDropDowns = setInterval(() => {
				if (this._dropdowns) {
					clearInterval(this.waitForDropDowns);
					this.updateMaxHeight();
				}
			}, 100);
		} else this.updateMaxHeight();
	}

	maxHeightOfField: number;

	/**
	 * This function pass the field selected to parent
	 */
	@Input() passQueryFieldToParent: (queryField: QueryField) => any;

	constructor(private userService: UserService, private interopService: OmniInteropService, private workOrderFactory: WorkOrderFactory, view: ElementRef<HTMLElement>) {
		super(view);
	}

	/**
	 * should show field component in the editing sequence
	 */
	get showFieldComponent(): boolean {
		return this.itemToEdit > 1 || this.itemToEdit === 0;
	}

	/**
	 * should show operator component in the editing sequence
	 */
	get showOperatorComponent(): boolean {
		return this.itemToEdit > 2 || this.itemToEdit === 0;
	}

	/**
	 * should show value component in the editing sequence
	 */
	get showValueComponent(): boolean {
		return this.itemToEdit > 3 || this.itemToEdit === 0;
	}

	/**
	 * should show join component in the editing sequence
	 */
	get showJoinComponent(): boolean {
		return this.itemToEdit > 4 || this.itemToEdit === 0;
	}

	/**
	 * indicate if the expansion panel should open
	 */
	get expansionShouldOpen(): boolean {
		const operatorIsNotNull: boolean = this.queryField.operator && !this.queryField.operator.name.toLowerCase().includes('null');
		const operatorIsNotBlank: boolean = this.queryField.operator && !this.queryField.operator.name.toLowerCase().includes('blank');
		const openValueExpansionPanel: boolean = this.itemToEdit === 4 && operatorIsNotNull && operatorIsNotBlank;
		return openValueExpansionPanel || !this.queryField.value;
	}

	/**
	 * get the header for the value selection
	 */
	get headerForValuePanel(): string {
		if (this.loadingDataBaseValue) return 'loading...';

		const operatorIsNull: boolean = this.queryField.operator && this.queryField.operator.name.toLowerCase().includes('null');

		const operatorIsBlank: boolean = this.queryField.operator && this.queryField.operator.name.toLowerCase().includes('blank');

		if (operatorIsNull || operatorIsBlank) return '-';

		if (this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT) return 'enter a value';
		else if (this.valueEditingMode === QueryFieldValueEditingMode.SELECTION) return 'select a value';
		else return 'select or enter a value';
	}

	/**
	 * indicate if expansion panel should disable
	 */
	get disableExpansionPanel(): boolean {
		const isFreeText: boolean = this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT;

		const operatorIsNull: boolean = this.queryField.operator && this.queryField.operator.name.toLowerCase().includes('null');

		const operatorIsBlank: boolean = this.queryField.operator && this.queryField.operator.name.toLowerCase().includes('blank');

		return isFreeText || operatorIsNull || operatorIsBlank;
	}

	/**
	 * On init, we get the arcGISField list, and then set the sequence for editing.
	 * Also, assign the index to the query field object
	 */
	ngOnInit() {
		this.queryField.index = this.fieldIndex;
		if (this.queryField && this.queryField.field) {
			this.setSquence(QuerySelectionType.none);
		}
		this.setUpQueryField();
	}

	private updateMaxHeight() {
		if (!this._initialHeight || !this._dropdowns) return;
		const numberOfFields = this._dropdowns.length - 1;
		setTimeout(() => {
			// inside a timeout to change this property outside the angular check cycle
			this.maxHeightOfField = this._initialHeight - numberOfFields * 56;
		}, 0);
	}

	async setUpQueryField() {
		if (this.querySource) this.filterFieldListBaseOnSource();
		if (!this.queryField || !this.queryField.field) return;
		const matchingArcGISFIeld = this.filtedArcGISFieldList.find(field => field.name === this.queryField.field.name);
		this.queryField.field = matchingArcGISFIeld;
		this.getInputType(this.queryField.field);
		this.getOperatorListBaseOnArcGISField(this.queryField.field);
		this.getEditModeBaseOnArcGISField(this.queryField.field);
		this.getEditModeBaseOnOperator(this.queryField.operator);
		this.getMetricTimeFrameIfApply(this.queryField.operator);
	}

	/**
	 * filter the arcGISFieldList by source, only show appropriate field
	 */
	filterFieldListBaseOnSource = () => {
		this.filtedArcGISFieldList = this.arcGISFieldList.filter(field => {
			if (this.querySource.value === ChannelTypes.History) {
				return field.omniName.includes(ChannelTypes[ChannelTypes.History]);
			}
			return !field.omniName.includes(ChannelTypes[ChannelTypes.History]);
		});
	};

	/**
	 * On field selected from the expansion pannel
	 * @param {ArcGISField} selectedField - The selected field
	 */
	onFieldSelected(selectedField: ArcGISField) {
		this.fieldIsDirty();
		this.setSquence(QuerySelectionType.field);
		this.getOperatorListBaseOnArcGISField(selectedField);
		this.getEditModeBaseOnArcGISField(selectedField);
		this.getInputType(selectedField);
		this.queryField.field = selectedField;
		setTimeout(() => this.setSquence(QuerySelectionType.operator));
	}

	/**
	 * On operator selected from the expansion pannel
	 * @param {Operator} selectedOperator - The selected operator
	 */
	async onOperatorSelected(selectedOperator: Operator) {
		this.operatorIsDirty();
		this.setSquence(QuerySelectionType.operator);
		this.queryField.operator = selectedOperator;
		this.getEditModeBaseOnArcGISField(this.queryField.field);
		this.getEditModeBaseOnOperator(this.queryField.operator);
		this.getMetricTimeFrameIfApply(this.queryField.operator);
		const operatorIsNull = this.queryField.operator.name.toLowerCase().includes('null');
		const operatorIsBlank = this.queryField.operator.name.toLowerCase().includes('blank');

		if (this.queryField.join) {
			return setTimeout(() => this.setSquence(QuerySelectionType.none));
		}

		if (!operatorIsNull && !operatorIsBlank) {
			return setTimeout(() => this.setSquence(QuerySelectionType.value));
		}

		this.valueEditingMode = QueryFieldValueEditingMode.DISABLED;
		this.valueList = [];
		this.queryField.value = { name: '', code: '' };
		setTimeout(() => this.setSquence(QuerySelectionType.join));
	}

	/**
	 * get time frame for the date type arcGISField
	 */
	getMetricTimeFrameIfApply(operator: Operator) {
		if (operator.name !== QueryOperator.forDate.within.name) return;
		this.valueList = DeprecatedMetricTimeFrame.getTimeFrameSelection();
		this.valueEditingMode = QueryFieldValueEditingMode.SELECTION;
	}

	/**
	 * On value typed from the expansion pannel
	 * @param {any} value - The selected value
	 */
	onValueChanged(value: any) {
		this.queryField.value = typeof value === 'string' || typeof value === 'number' ? { name: value, code: value } : JSON.parse(JSON.stringify(value));

		if (!this.queryField.join) {
			return this.setSquence(QuerySelectionType.join);
		}
		this.setSquence(QuerySelectionType.none);
		this.openLastQueryFieldJoinOperator();
	}

	openLastQueryFieldJoinOperator() {
		if (this.queryFieldComponentList && this.queryFieldComponentList.last) {
			this.queryFieldComponentList.last.setSquence(QuerySelectionType.join);
		}
	}

	/**
	 * On join operator selected
	 * @param {Operator} selectedJoinStatement - The join operator
	 */
	onJoinOperatorSelected(selectedJoinStatement: Operator) {
		this.queryField.join = selectedJoinStatement;
		this.passQueryFieldToParent(this.queryField);
	}

	/**
	 * get the editing mode from the domain of the arcGISField if apply
	 * @param {ArcGISField} selectedField - selected ArcGISField
	 */
	getEditModeBaseOnArcGISField(selectedField: ArcGISField) {
		const { domain } = selectedField;
		this.valueEditingMode = QueryFieldValueEditingMode.SELECTION;
		this.valueList = [];
		if (!domain || domain.range) {
			this.valueEditingMode = QueryFieldValueEditingMode.FREETEXT;
		} else if (domain.codedValues) {
			const sortedValueList = domain.codedValues;
			if (sortedValueList.length > 0) sortedValueList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
			this.valueList = sortedValueList;
		}
	}

	/**
	 * This function gets the edit mode base on if the operator is Null or Blank
	 * @param {Operator} selectedOperator - the selected operator
	 */
	getEditModeBaseOnOperator(selectedOperator: Operator) {
		const operatorEqualsIsOrIsNot: boolean = selectedOperator && (selectedOperator.name.toLowerCase() === 'is' || selectedOperator.name.toLowerCase() === 'is not');

		const operatorEqualsNull: boolean = selectedOperator && selectedOperator.name.toLowerCase().includes('null');

		const operatorEqualsBlank: boolean = selectedOperator && selectedOperator.name.toLowerCase().includes('blank');

		const isString = this.queryField.field.type === FieldType.esriFieldTypeString;

		const noCodedValues = this.queryField.field && (!this.queryField.field.domain || !this.queryField.field.domain.codedValues);

		if (noCodedValues && isString && operatorEqualsIsOrIsNot) {
			this.valueEditingMode = QueryFieldValueEditingMode.FREETEXT_AND_SELECTION;
			// purposely not awaiting 'getValuesForStringField'
			this.getValuesForStringField(this.metric);
		} else if (operatorEqualsNull || operatorEqualsBlank) {
			this.valueEditingMode = QueryFieldValueEditingMode.DISABLED;
		}
	}

	async getValuesForStringField(metric: Metric) {
		const fieldToWatchFor =
			this.queryField.field.name.toLowerCase().includes('worktype') ||
			this.queryField.field.name.toLowerCase().includes('status') ||
			this.queryField.field.name.toLowerCase().includes('assignedto');

		if (metric.definition.isWorkOrderMetric && fieldToWatchFor) {
			const { workOrderChannel } = metric.definition;
			const attributes = workOrderChannel.attributes as ArcGISWorkOrderChannelAttributes;
			if (this.queryField.field.name.toLowerCase().includes('worktype')) {
				this.valueList = this.userService.globalConfig.sedaruWorkTypes.map((value: string) => ({ name: value, code: value }));
				this.valueList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
			} else if (this.queryField.field.name.toLowerCase().includes('status')) {
				this.valueList = attributes.activeWorkOrderValues.concat(attributes.completedWorkOrderValues).map((value: string) => ({ name: value, code: value }));
			} else {
				this.valueList = await this.getQueryBuilderValue(metric, this.queryField.field, WorkOrderSourceType.employees);
			}
		} else {
			this.valueList = await this.getQueryBuilderValue(metric, this.queryField.field, metric.definition.source.workOrderSourceType);
		}
	}

	async getQueryBuilderValue(metric: Metric, arcGISField: ArcGISField, workOrderSourceType: WorkOrderSourceType) {
		if (this.topFiftyValueDict == null) this.topFiftyValueDict = new Map();
		if (this.topFiftyValueDict.has(arcGISField.omniName)) return this.topFiftyValueDict.get(arcGISField.omniName);
		this.loadingDataBaseValue = true;
		const valueList = await this.generateQueryBuilderValue(metric, arcGISField, workOrderSourceType);
		this.topFiftyValueDict.set(arcGISField.omniName, valueList);
		this.loadingDataBaseValue = false;
		valueList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
		return valueList;
	}

	private async generateQueryBuilderValue(metric: Metric, arcGISField: ArcGISField, workOrderSourceType: WorkOrderSourceType) {
		let layer: FeatureLayer;
		let attributes: AssetChannelAttributes;
		if (workOrderSourceType === WorkOrderSourceType.employees) {
			const employees = this.workOrderFactory.workOrderMetaData.employees;
			return employees.map(employee => ({ name: employee.name, code: employee.username }));
		}
		const channelType: ChannelTypes = this.querySource.value;
		switch (channelType) {
			case ChannelTypes.Asset:
				attributes = metric.definition.assetChannel.attributes as ArcGISAssetChannelAttributes;
				layer = this.interopService.arcGISManager.getAssetLayer(attributes.assetType);
				break;
			case ChannelTypes.History:
				attributes = metric.definition.historyChannel.attributes as ArcGISHistoryChannelAttributes;
				layer = this.interopService.arcGISManager.getHistoryLayer(attributes.assetType);
				break;
			case ChannelTypes.WorkOrder:
				layer = this.workOrderFactory.workOrderLayer;
				break;
			default:
				break;
		}
		const features = await layer.query('1=1', arcGISField.name, false, 50, true, [{ fieldName: arcGISField.name, sortType: 'ASC' }]);
		const tempArray = [];
		for (const feature of features) {
			if (!feature.attributes) continue;
			let fieldValue = feature.attributes[arcGISField.name];
			if (fieldValue === null || fieldValue == undefined) continue;
			if (arcGISField.type === FieldType.esriFieldTypeString) fieldValue = fieldValue.trim();
			if (fieldValue === '') continue;
			// if (tempArray.length > 50) break;
			tempArray.push({ name: fieldValue, code: fieldValue });
		}
		return tempArray;
	}

	/**
	 * get the list of avaiable operators base on arcGISField selected
	 * @param {ArcGISField} selectedField - selected ArcGISField
	 */
	getOperatorListBaseOnArcGISField(selectedField: ArcGISField) {
		if (selectedField.type === FieldType.esriFieldTypeString) {
			this.operatorList = Object.values(QueryOperator.forString);
		} else if (selectedField.type === FieldType.esriFieldTypeDate) {
			this.operatorList = Object.values(QueryOperator.forDate);
		} else {
			this.operatorList = Object.values(QueryOperator.forNumeric);
		}
	}

	/**
	 * when fieldSelection opened, check to see if proper sequence is set or if the field list is empty
	 */
	onFieldSelectionOpened = () => {
		if (!this.queryField.operator) {
			this.setSquence(QuerySelectionType.field);
		}
	};

	/**
	 * when operator selection opened, check if proper sequnce is set
	 */
	onOperatorSelectionOpened = () => {
		if (!this.queryField.value) {
			this.setSquence(QuerySelectionType.operator);
		}
	};

	/**
	 * when value selection opened, check if proper sequnce is set
	 */
	onValueSelectionOpened = () => {
		if (this.valueEditingMode === QueryFieldValueEditingMode.SELECTION && !this.queryField.value && !this.queryField.join) {
			this.setSquence(QuerySelectionType.value);
		}
	};

	/**
	 * set the sequence of the selection
	 * @param {QuerySelectionType} type - The selection type on the current sequence
	 */
	setSquence(type: QuerySelectionType) {
		this.itemToEdit = type;
	}

	/**
	 * Get the input type for the html input tag if apply
	 * @param {ArcGISField} selectedField - the selected arcGISField
	 */
	getInputType(selectedField: ArcGISField) {
		const { type, domain } = selectedField;
		const { esriFieldTypeString, esriFieldTypeDate } = FieldType;
		this.htmlInputCriteria = { type: '' };
		if (type !== esriFieldTypeString && type !== esriFieldTypeDate) {
			this.htmlInputCriteria = { type: 'number' };
		}
		if (domain && domain.range) {
			this.htmlInputCriteria.min = domain.range[0];
			this.htmlInputCriteria.max = domain.range[1];
		}
	}

	/**
	 * When field selection component is dirty, delete the rest of the property
	 */
	fieldIsDirty() {
		delete this.queryField.operator;
		delete this.queryField.value;
	}

	/**
	 * When the operator selection component is dirty, delete the rest of the property
	 */
	operatorIsDirty() {
		if (!this.queryField.value) return;
		this.queryField.value.name = '';
		this.queryField.value.code = '';
	}

	ngOnDestroy() {
		if (this.waitForDropDowns) clearInterval(this.waitForDropDowns);
	}
}
