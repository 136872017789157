import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { TwoIconsTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/two-icons-two-lines/two-icons-two-lines.component';
import AssetIcon from 'models/asset-icon.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { RecordContextGroup } from 'models/records/record-context-group';
import { AssetRecord } from 'models/records/asset-record.model';
import { Channel, ChannelAttributes, ChannelTypes, Metric } from 'models';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Feature } from 'sedaru-util/esri-core';

@Component({
  selector: 'app-history-record-asset-list',
  templateUrl: './history-record-asset-list.component.html',
  styleUrls: ['./history-record-asset-list.component.scss']
})
export class HistoryRecordAssetListComponent extends MenuPanelBaseComponent implements OnInit {
  @ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;
  private resolvers = new Map<string, ListItemResolver>();

  assets = [];
  context: any;
  metric: Metric;

  constructor(view: ElementRef<HTMLElement>, private interopService: OmniInteropService) {
    super(view);
  }

  ngOnInit(): void {
	this.listComponent.getResolver = (item: any) => {
		if (!item) {
			return null;
		}
		const assetId = item.assetId;
		if (this.resolvers.has(assetId)) {
			return this.resolvers.get(assetId);
		}
		return this.getItemResolver(assetId);
	};
}

  onPageNavigatedTo(args: NavigationArgs) {
    this.context = args.parameter.context;
    this.metric = args.parameter.metric;
    this.assets.push(this.context.assetRecord);
    console.log('this.context: ', this.context);
    this.menuPanelComponent.updateView({
      title: this.metric?.definition?.name
    });
  }

  private getItemResolver(key: string): ListItemResolver {
		const listResolver = TwoIconsTwoLinesComponent.createResolver();
		listResolver.getLeftIconPath = (item: any) => AssetIcon.getAssetIconUrl(item.assetType);
		listResolver.getTopLabel = (item: any) => item.assetId;
		listResolver.getBottomLeftLabel = (item: any) => item.assetType;
		listResolver.getBottomRightLabel = item => '';
		listResolver.getRightIconPath = item => 'assets/info.png';
		listResolver.isDisabled = item => {
			return false;
		};

		this.resolvers.set(key, listResolver);
		return listResolver;
	}

  async goTo(assetRecord: AssetRecord) {
    const feature = assetRecord.feature;
		const assetQuery = this.getLayerQueries(feature);
		let assetType = null;
		if (this.metric) {
			assetType = this.metric.definition.source.assetType;
		} else {
			assetType = this.context.assetRecord.assetDefinition.assetType;
		}

		let assetLayer
		if (assetType) {
			assetLayer = this.interopService.arcGISManager.getMapAssetLayer(assetType);
		}

		let assetFeature = null;
		if (assetLayer && assetQuery) {
			const assetFeatureJSON = await assetLayer.query(assetQuery, '*', true);
			if (assetFeatureJSON?.length > 0) {
				assetFeature = Feature.fromEsriFeature(assetFeatureJSON[0]);
				assetFeature.geometry = feature.geometry;
			} else {
				assetFeature = feature;
			}
		} else {
			assetFeature = feature;
		}
		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		assetRecord = AssetRecord.create(assetDefinition, assetFeature);
    	NavigationService.navigateTo(Pages.assetInformation, {recordContext: new RecordContextGroup({assetRecord: assetRecord})});
  }


  private getLayerQueries(featureSelected) {
		let assetQuery = null;
		let channelAttribute: ChannelAttributes = null;
		if (this.metric) {
			channelAttribute = this.metric.definition.getChannel(ChannelTypes.Asset)?.attributes;
		} else if (this.context?.assetRecord) {
			channelAttribute = this.context.assetRecord.assetChannelAttributes;
		}

		if (channelAttribute) {
			const { fieldName, value } = channelAttribute.getRecordIdFieldAndValue(featureSelected);
			assetQuery = fieldName + ' = ' + "'" + value + "'";
		}

		return assetQuery;
	}

}
