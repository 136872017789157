import { PriortyContract } from './../../contracts/work-order/priority-contract';
import { Priority } from './priority.model';
export class Priorities extends Array<Priority> {
	static fromContracts(priorityContracts: PriortyContract[]) {
		const priorities = new Priorities();
		if (!priorityContracts) return priorities;

		for (const priortyContract of priorityContracts) {
			const priority = Priority.fromContract(priortyContract);
			priorities.push(priority);
		}
		return priorities;
	}

	getContracts = () => {
		const priortyContracts = new Array<PriortyContract>();
		for (const priority of this) {
			const priorityContract = priority.getContract();
			priortyContracts.push(priorityContract);
		}
		return priortyContracts;
	};

	getById = (id: string) => this.find(priority => priority.id === id);

	getByPriorityCode = (priorityCode: string) => this.find(priority => priority.priorityCode === priorityCode);
}
