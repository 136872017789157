import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';
import { OmniModel } from '../omni-model';
import { EmployeeRates } from './employee-rates.model';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';

/**
 * This defines the employee cost details
 */
export class EmployeeCost extends OmniModel<EmployeeCost, Contracts.EmployeeCostContract> {
	/**
	 * id for employee cost
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the employee hourly rate
	 */
	private _hourlyRate: SedaruUtils.Undoable<number>;
	get hourlyRate(): number {
		if (!this._hourlyRate) this._hourlyRate = new SedaruUtils.Undoable<number>();
		return this._hourlyRate.value;
	}
	set hourlyRate(value: number) {
		if (!this._hourlyRate) {
			this._hourlyRate = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._hourlyRate.value = value;
	}

	/**
	 * holds the employee rate type
	 */
	private _rateType: SedaruUtils.Undoable<string>;
	get rateType(): string {
		if (!this._rateType) this._rateType = new SedaruUtils.Undoable<string>();
		return this._rateType.value;
	}
	set rateType(value: string) {
		if (!this._rateType) {
			this._rateType = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._rateType.value = value;
	}

	/**
	 * holds the employee total hours
	 */
	private _hours: SedaruUtils.Undoable<number>;
	get hours(): number {
		if (!this._hours) this._hours = new SedaruUtils.Undoable<number>();
		return this._hours.value;
	}
	set hours(value: number) {
		if (!this._hours) {
			this._hours = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._hours.value = value;
	}

	/**
	 * holds the employee total cost
	 */
	private _totalCost: SedaruUtils.Undoable<number>;
	get totalCost(): number {
		if (!this._totalCost) this._totalCost = new SedaruUtils.Undoable<number>();
		return this._totalCost.value;
	}
	set totalCost(value: number) {
		if (!this._totalCost) {
			this._totalCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._totalCost.value = value;
	}

	/**
	 * holds the employee id
	 */
	private _employeeId: SedaruUtils.Undoable<string>;
	get employeeId(): string {
		if (!this._employeeId) this._employeeId = new SedaruUtils.Undoable<string>();
		return this._employeeId.value;
	}
	set employeeId(value: string) {
		if (!this._employeeId) {
			this._employeeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._employeeId.value = value;
	}

	private _dateStamp: SedaruUtils.Undoable<string>;
	get dateStamp(): string {
		if (!this._dateStamp) this._dateStamp = new SedaruUtils.Undoable<string>();
		return this._dateStamp.value;
	}
	set dateStamp(value: string) {
		if (!this._dateStamp) {
			this._dateStamp = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._dateStamp.value = value;
	}

	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	private _changeStatus: SedaruUtils.Undoable<string>;
	get changeStatus(): string {
		if (!this._changeStatus) this._changeStatus = new SedaruUtils.Undoable<string>();
		return this._changeStatus.value;
	}
	set changeStatus(value: string) {
		if (!this._changeStatus) {
			this._changeStatus = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._changeStatus.value = value;
	}

	private _workTaskId: SedaruUtils.Undoable<string>;
	get workTaskId(): string {
		if (!this._workTaskId) this._workTaskId = new SedaruUtils.Undoable<string>();
		return this._workTaskId.value;
	}
	set workTaskId(value: string) {
		if (!this._workTaskId) {
			this._workTaskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTaskId.value = value;
	}

	private _tradeId: SedaruUtils.Undoable<string>;
	get tradeId(): string {
		if (!this._tradeId) this._tradeId = new SedaruUtils.Undoable<string>();
		return this._tradeId.value;
	}
	set tradeId(value: string) {
		if (!this._tradeId) {
			this._tradeId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._tradeId.value = value;
	}

	private _budgetId: SedaruUtils.Undoable<string>;
	get budgetId(): string {
		if (!this._budgetId) this._budgetId = new SedaruUtils.Undoable<string>();
		return this._budgetId.value;
	}
	set budgetId(value: string) {
		if (!this._budgetId) {
			this._budgetId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._budgetId.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	static fromContract(contract: Contracts.EmployeeCostContract): EmployeeCost {
		const model = new EmployeeCost();
		model.id = contract.objectid.toString();
		model.hourlyRate = contract.HourlyRate;
		model.rateType = contract.RateType;
		model.hours = contract.Hours;
		model.totalCost = contract.TotalCost;
		model.employeeId = contract.EmployeeID;
		model.dateStamp = DateUtil.isValidDate(contract.DateStamp) ? contract.DateStamp : '';
		model.workOrderKey = contract.WorkOrderKey;
		model.changeStatus = contract.ChangeStatus;
		model.workTaskId = contract.WorkTaskId;
		model.tradeId = contract.TradeId;
		model.budgetId = contract.BudgetId;
		model.createdBy = contract.CreatedBy;
		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.EmployeeCostContract => {
		const contract = new Contracts.EmployeeCostContract();
		contract.objectid = +this.id;
		contract.HourlyRate = +this.hourlyRate;
		contract.RateType = this.rateType;
		contract.Hours = +this.hours;
		contract.TotalCost = +this.totalCost;
		contract.EmployeeID = this.employeeId;
		contract.DateStamp = this.dateStamp;
		contract.WorkOrderKey = this.workOrderKey;
		contract.ChangeStatus = this.changeStatus;
		contract.WorkTaskId = this.workTaskId;
		contract.TradeId = this.tradeId;
		contract.BudgetId = this.budgetId;
		contract.CreatedBy = this.createdBy;
		return contract;
	};

	calculateTotalCost = (employeeRates: EmployeeRates) => {
		const { multiplier } = employeeRates.find(rate => rate.rateCode === this.rateType);
		this.totalCost = this.hourlyRate * this.hours * multiplier;
	};

	createTimeStamp() {
		this.dateStamp = new Date().toLocaleString();
	}

	onUpdateInformation(copyModel: EmployeeCost): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._hourlyRate, () => {
				this.hourlyRate = copyModel.hourlyRate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._rateType, () => {
				this.rateType = copyModel.rateType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._hours, () => {
				this.hours = copyModel.hours;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._totalCost, () => {
				this.totalCost = copyModel.totalCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._employeeId, () => {
				this.employeeId = copyModel.employeeId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._dateStamp, () => {
				this.dateStamp = copyModel.dateStamp;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._changeStatus, () => {
				this.changeStatus = copyModel.changeStatus;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTaskId, () => {
				this.workTaskId = copyModel.workTaskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._tradeId, () => {
				this.tradeId = copyModel.tradeId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._budgetId, () => {
				this.budgetId = copyModel.budgetId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		return wasChanged;
	}

	isNew = () => this.id === '0';
}
