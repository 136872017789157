import { NoIconTwoLinesComponent } from './../../../ui-components/omni-list/list-item/templates/no-icon-two-lines/no-icon-two-lines.component';
import { Pages } from './../../../navigation/inavigation';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { WorkOrderTemplate, Employee, Priority, Status, Task } from 'models/work-order';
import { WorkOrderFilterFieldKey } from '../workorder-filter-templates';
import { WorkOrderCostCategory } from 'models/work-order/workorder-cost-category.enum';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { Subscription } from 'rxjs';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { AssetDefinition, Metric } from 'models';
import { ContextualSearch } from 'app/menu-panel/menu-panel-base/contextual-search';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { Field } from 'sedaru-util/esri-core/field';
import { WorkOrderTeam } from 'models/work-order/work-order-team.model';
import { AWOQuery, TaskFilter, WorkOrderFilter } from 'models/awo-query.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

@Component({
	selector: 'app-workorder-filter-selection',
	templateUrl: './workorder-filter-selection.component.html',
	styleUrls: ['./workorder-filter-selection.component.scss']
})
export class WorkOrderFilterSelectionComponent extends MenuPanelBaseComponent {
	get selectionList(): any[] {
		return this.getContextualSearchItems<any>();
	}
	set selectionList(value: any[]) {
		value.sort((a, b) => (a[this.nameKey] > b[this.nameKey] ? 1 : b[this.nameKey] > a[this.nameKey] ? -1 : 0));
		(this.contextualSearch as ContextualSearch<any>).reloadRecords(value);
	}

	nameKey = '';

	previouslySelectedCodes: string[];

	arcGISFieldSubsription: Subscription;

	queryBuilderValueSubscription: Subscription;

	metric: Metric;

	selectedItems = [];

	selectedFilterField;

	query: AWOQuery;

	currentFilter: WorkOrderFilter | TaskFilter;

	itemsKey = 'id';

	private resolvers = new Map();

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	get pageIdentifier() {
		return Pages.workOrderFilterSelection;
	}

	constructor(private workOrderFactory: WorkOrderFactory, view: ElementRef<HTMLElement>, private interopService: OmniInteropService) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
		this.selectedFilterField = args.parameter.selectedFilterField;
		this.currentFilter = args.parameter.currentFilter;
		this.query = args.parameter.query;

		if (args.searchTerm) {
			this.contextualSearch.navigateBack = args.isNavigatingBack;
			this.contextualSearch.search.searchText = args.searchTerm;
		}
	}

	onPageNavigatingFrom(args: NavigationArgs) {
		return new Promise<boolean>(resolve => {
			args.parameter['currentFilter'] = this.currentFilter;
			resolve(true);
		});
	}

	ngOnInit() {
		this.previouslySelectedCodes = this.currentFilter[this.selectedFilterField.key] ? this.currentFilter[this.selectedFilterField.key].split(',') : [];

		this.getListOfOptions();

		this.listComponent.getResolver = item => {
			if (this.resolvers.has(item[this.nameKey])) return this.resolvers.get(item[this.nameKey]);
			if (this.selectedFilterField.key === WorkOrderFilterFieldKey.Task) {
				return this.getTwoLinesResolver(item);
			}
			return this.getOneLineResolver(item);
		};
	}

	private getTwoLinesResolver(item) {
		const workorderPickListResolver = NoIconTwoLinesComponent.createResolver();
		workorderPickListResolver.getTopLabel = i => i[this.nameKey];
		workorderPickListResolver.getBottomLeftLabel = i => {
			const template = this.workOrderFactory.workOrderMetaData.woTemplates.getByTemplateId(i.woTemplateId);
			return template ? template.woTemplateName : '';
		};
		workorderPickListResolver.getBottomRightLabel = i => '';
		this.resolvers.set(item[this.nameKey], workorderPickListResolver);
		return workorderPickListResolver;
	}

	private getOneLineResolver(item) {
		const workorderPickListResolver = NoIconOneLineComponent.createResolver();
		workorderPickListResolver.getLabel = i => i[this.nameKey];
		this.resolvers.set(item[this.nameKey], workorderPickListResolver);
		return workorderPickListResolver;
	}
	ngOnDestroy() {
		if (this.arcGISFieldSubsription) this.arcGISFieldSubsription.unsubscribe();
		if (this.queryBuilderValueSubscription) this.queryBuilderValueSubscription.unsubscribe();
	}

	async getListOfOptions() {
		const { workOrderMetaData } = this.workOrderFactory;
		const { isAdvancedWorkOrder } = this.metric.definition.workOrderChannel;
		const { workOrderSourceType } = this.metric.definition.source;

		this.nameKey = 'name';
		switch (this.selectedFilterField.key) {
			case WorkOrderFilterFieldKey.WorkType:
				this.getValues(WorkOrderFilterFieldKey.WorkType, WorkOrderSourceType.workOrders);
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems = this.selectionList.find(item => item.name === code);
				});
				break;
			case WorkOrderFilterFieldKey.WorkTypeID:
				this.nameKey = 'woTemplateName';
				this.selectionList = workOrderMetaData.woTemplates;
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(template => template.woTemplateId === code));
				});
				break;
			case WorkOrderFilterFieldKey.Status:
			case WorkOrderFilterFieldKey.TaskStatus:
				if (!isAdvancedWorkOrder) return this.getValues(WorkOrderFilterFieldKey.Status, WorkOrderSourceType.workOrders);
				this.nameKey = workOrderMetaData.statuses.length ? 'description' : 'statusCode';
				this.selectionList =
					this.currentFilter && this.currentFilter.filterType === 'taskFilter'
						? workOrderMetaData.statuses.filter(status => status.taskVisible)
						: workOrderMetaData.statuses.length
						? workOrderMetaData.statuses.filter(status => status.workOrderVisible)
						: [{ statusCode: 'Open' }, { statusCode: 'Close' }];
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => item.statusCode === code));
				});
				break;
			case WorkOrderFilterFieldKey.Task:
				this.nameKey = 'description';
				let selectionList: Task[];
				const workTypeIds = this.query?.workOrderFilter?.worktypeid?.split(',');
				if (!workTypeIds || !workTypeIds.length) selectionList = workOrderMetaData.tasks;
				else selectionList = workOrderMetaData.tasks.filter(t => workTypeIds.includes(t.woTemplateId));

				this.selectionList = selectionList;
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => item.taskId === code));
				});
				break;
			case WorkOrderFilterFieldKey.AssignedTo:
			case WorkOrderFilterFieldKey.Owner:
				if (!isAdvancedWorkOrder) return this.getValues(null, WorkOrderSourceType.employees);
				this.selectionList = workOrderMetaData.employees;
				this.previouslySelectedCodes.forEach(employeeId => {
					this.selectedItems.push(this.selectionList.find(item => item.employeeId === employeeId));
				});
				break;
			case WorkOrderFilterFieldKey.Priority:
				this.nameKey = 'description';
				this.selectionList = workOrderMetaData.priorities.length ? workOrderMetaData.priorities : [];
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => item.priorityCode === code));
				});
				break;
			case WorkOrderFilterFieldKey.AssetType:
				this.nameKey = 'assetType';
				this.selectionList = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.map(({ assetType }, index) => ({ id: index, assetType }));
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => item.assetType === code));
				});
				break;
			case WorkOrderFilterFieldKey.CostCategory:
				this.selectionList = this.generateCostCategorySelections();
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => String(item.value) === code));
				});
				break;
			case WorkOrderFilterFieldKey.Team:
				this.nameKey = 'teamName';
				this.selectionList = workOrderMetaData.workorderTeams;
				this.previouslySelectedCodes.forEach(code => {
					this.selectedItems.push(this.selectionList.find(item => item.teamId === code));
				});
				break;
		}
	}

	private async getValues(filterField: WorkOrderFilterFieldKey, workOrderSourceType: WorkOrderSourceType) {
		let field: Field;
		this.menuPanelComponent.updateView({ showPacifier: true });
		const layer = this.workOrderFactory.workOrderLayer;
		switch (filterField) {
			case WorkOrderFilterFieldKey.WorkType:
				field = layer.fields.find(f => f.name.toLowerCase().includes(WorkOrderFilterFieldKey.WorkType));
				break;
			case WorkOrderFilterFieldKey.Status:
				field = layer.fields.find(f => f.name.toLowerCase().includes(WorkOrderFilterFieldKey.Status));
				break;
			case WorkOrderFilterFieldKey.AssignedTo:
				const employees = await this.workOrderFactory.workOrderMetaData.employees;
				this.selectionList = employees.map(employee => ({ name: employee.name }));
				return;
			default:
				return;
		}
		const features = await layer.query('1=1', field.name, false, 0, true);
		this.selectionList = features.map(value => ({ name: value }));
		this.menuPanelComponent.updateView({ showPacifier: false });
	}

	onItemSelected(item: any) {
		let value = '';
		switch (this.selectedFilterField.key) {
			case WorkOrderFilterFieldKey.WorkType:
				value = item.name;
				break;
			case WorkOrderFilterFieldKey.WorkTypeID:
				value = (item as WorkOrderTemplate).woTemplateId;
				break;
			case WorkOrderFilterFieldKey.Status:
			case WorkOrderFilterFieldKey.TaskStatus:
				value = (item as Status).statusCode;
				break;
			case WorkOrderFilterFieldKey.Task:
				value = (item as Task).taskId;
				break;
			case WorkOrderFilterFieldKey.AssignedTo:
			case WorkOrderFilterFieldKey.Owner:
				value = (item as Employee).employeeId;
				break;
			case WorkOrderFilterFieldKey.Priority:
				value = (item as Priority).priorityCode;
				break;
			case WorkOrderFilterFieldKey.AssetType:
				value = (item as AssetDefinition).assetType;
				break;
			case WorkOrderFilterFieldKey.CostCategory:
				value = String((item as { name: string; value: WorkOrderCostCategory }).value);
				break;
			case WorkOrderFilterFieldKey.Team:
				value = (item as WorkOrderTeam).teamId;
				break;
		}

		if (!this.currentFilter[this.selectedFilterField.key]) this.currentFilter[this.selectedFilterField.key] = `${value}`;
		else this.currentFilter[this.selectedFilterField.key] += `,${value}`;
	}

	onSave() {
		this.currentFilter[this.selectedFilterField.key] = '';
		this.listComponent.selectedItems.forEach(item => this.onItemSelected(item));
		this.currentFilter.isDirty = true;
		this.menuPanelComponent.goBack();
	}

	onClear() {
		this.listComponent.clearSelection();
	}

	generateCostCategorySelections() {
		const { employee, material, equipment, vendor } = WorkOrderCostCategory;
		const selectionList = [
			{ name: this.capitalize(employee), value: employee },
			{ name: this.capitalize(material), value: material },
			{ name: this.capitalize(equipment), value: equipment },
			{ name: this.capitalize(vendor), value: vendor }
		];
		return selectionList;
	}

	private capitalize = (word: string) => word.charAt(0).toUpperCase() + word.substring(1);

	onCreateContextualSearch() {
		const contextualSearch = new ContextualSearch<any>([], (item: any, text: string) => {
			const template = this.workOrderFactory.workOrderMetaData.woTemplates.getByTemplateId(item.woTemplateId);
			const templateName = template
				? template.woTemplateName
						.toString()
						.toLowerCase()
						.includes(text.toLowerCase())
				: '';

			if (!this.nameKey) return false;

			if (!item[this.nameKey]) return false;

			const description = item[this.nameKey]
				.toString()
				.toLowerCase()
				.includes(text.toLowerCase());

			if (description || templateName) {
				return true;
			}

			return false;
		});

		return contextualSearch;
	}
}
