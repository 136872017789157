import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { TrendStyle } from './trend-style.model';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { NavigationService, Pages } from 'app/navigation/navigation.service';

@Component({
	selector: 'app-trend-style',
	templateUrl: './trend-style.component.html',
	styleUrls: ['./trend-style.component.scss']
})
export class TrendStyleComponent extends MenuPanelBaseComponent {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;
	trendStyles: Array<TrendStyle>;
	currentlySelectedStyle: TrendStyle;

	constructor(private canvasService: CanvasService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	ngOnInit() {
		this.trendStyles = [new TrendStyle('bar'), new TrendStyle('line')];
		this.menuPanelComponent.updateView({ title: 'select style' });

		this.listComponent.getResolver = item => {
			return this.getItemResolver(item);
		};
	}

	private getItemResolver(item) {
		const listResolver = NoIconOneLineComponent.createResolver();
		listResolver.getAlignment = i => {
			return 'center';
		};
		listResolver.getLabel = i => {
			return i.label;
		};
		return listResolver;
	}

	onTrendStyleSelect(selectedStyle: TrendStyle) {
		this.config.selectedCanvas.trendStyle = selectedStyle;
		this.canvasService.saveOmniCanvas(this.config, this.config.selectedCanvas, false).subscribe();
		this.trendStyleChanged(selectedStyle);
		NavigationService.navigateBackTo(Pages.trendSettings);
	}
}
