import { ArcGISField, CodedValue } from './arc-gis-field.model';
import { Operator, QueryOperator } from './query-operator.enum';
import { WorkOrderFilterFields } from './work-order/work-order-filter.model';
import { CustomFieldFilter, CustomFieldFilters, WorkOrderFilterField, WorkOrderFilterJSON, WorkOrderQueryJSON } from './work-order';
import { ChannelTypes } from './channel-type.enum.model';
import { WorkOrderSourceType } from './work-order-source-type.enum';
import { FieldType } from '../sedaru-util/esri-core';

/**
 * A const variable represent a space, for easy readabliltiy
 */
const space = ' ';

/**
 * The QuerySource model
 */
export class QuerySource {
	/**
	 * The query source name
	 */
	name: string;

	/**
	 * The metric source value. Ex: Asset, History, Workorder
	 */
	value: ChannelTypes;
}

/**
 * The QueryField model
 */
export class QueryField {
	index: number;
	field: ArcGISField;
	operator: Operator;
	value: CodedValue;
	join: Operator;
	initialize?(queryField: QueryField) {
		if (!queryField) return;
		if (queryField.index !== undefined) {
			this.index = queryField.index;
		}
		this.field = new ArcGISField();
		if (queryField.field) {
			this.field.initialize(queryField.field);
		}
		this.operator = new Operator();
		if (queryField.operator) {
			this.operator.name = queryField.operator.name;
			this.operator.value = queryField.operator.value;
		}
		this.value = new CodedValue();
		if (queryField.value) {
			this.value.code = queryField.value.code;
			this.value.name = queryField.value.name;
		}
		if (queryField.join) {
			this.join = new Operator();
			this.join.name = queryField.join.name;
			this.join.value = queryField.join.value;
		}
		return this;
	}
}

/**
 * The QueryFieldList model
 */
export class QueryFieldList extends Array<QueryField> { }

/**
 * The QueryStatement
 */
export class QueryStatement {
	/**
	 * The Query Source
	 */
	private _source: QuerySource;

	/**
	 * The Query field list
	 */
	private _queryFieldList: QueryFieldList;

	/**
	 * If this query statement is newly generated
	 */
	isNew?: boolean;

	/**
	 * Index of the position of this query statement in the list
	 */
	index: number;

	/**
	 * Join oeprator, ex: and, or , done
	 */
	join: Operator;

	/**
	 * if the query statement only have asset as source, then source selection is disabled
	 */
	disableSource?: boolean;

	/**
	 * If the this query statement is using a differnet source then the previous one, join is disabled
	 */
	disableJoin?: boolean;

	constructor() {
		this.isNew = true;
	}

	// Getter method for source
	get source(): QuerySource {
		if (!this._source) {
			this._source = new QuerySource();
		}
		return this._source;
	}

	// Setter method for source
	set source(source: QuerySource) {
		this._source = source;
	}

	/**
	 * Getter method for query field list
	 */
	get queryFieldList(): QueryFieldList {
		if (!this._queryFieldList) {
			this._queryFieldList = new QueryFieldList();
		}

		return this._queryFieldList;
	}

	static from(statementList: QueryStatementList, i: number) {
		const queryStatement = new QueryStatement();

		const current = statementList[i];

		queryStatement.index = current.index;

		if (current.index === 0) {
			queryStatement.disableJoin = true;
		}

		if (current.join) {
			queryStatement.join = current.join;
		}

		queryStatement.source = current.source || current._source;

		const queryFieldList = current.queryFieldList || current._queryFieldList;

		for (const queryField of queryFieldList) {
			const newQueryField = new QueryField();
			newQueryField.initialize(queryField);
			queryStatement.queryFieldList.push(newQueryField);
		}

		queryStatement.isNew = false;

		const previous = statementList[i - 1];

		if (!current.source && current._source) {
			current.source = current._source;
		}
		if (previous && !previous.source && previous._source) {
			previous.source = previous._source;
		}
		if (previous && current.source.name !== previous.source.name) {
			queryStatement.disableJoin = true;
		}

		queryStatement.disableSource = true;

		return queryStatement;
	}

	/**
	 * This function will clear query field list
	 */
	clearQueryFieldList() {
		this._queryFieldList = [];
	}
}

/**
 * A list of query statement
 */
export class QueryStatementList extends Array<QueryStatement> {
	static from(statementList: QueryStatementList) {
		const queryStatementList = new QueryStatementList();
		for (let i = 0; i < statementList.length; i++) {
			const queryStatement = QueryStatement.from(statementList, i);
			queryStatementList.push(queryStatement);
		}
		return queryStatementList;
	}
}

/**
 * Query builder
 */
export class QueryBuilder {
	/**
	 * The query statement list holds a list of query statement
	 */
	private queryStatementList: QueryStatementList;

	/**
	 * take in query statementlist and assign it
	 * @param {QueryStatementList} queryStatementList - queryStatement input
	 */
	constructor(queryStatementList: QueryStatementList) {
		this.queryStatementList = queryStatementList;
	}

	/**
	 * This function will convert a single query statement to string
	 * @param {QueryStatement} queryStatement - A single query statement
	 */
	generateQueryStatement(queryStatement: QueryStatement): string {
		const { doneOperator } = QueryOperator.forJoin;

		let queryStatementString = '(';

		for (const queryField of queryStatement.queryFieldList) {
			const isSWOAssignedTo = queryField.field.name.toLowerCase().includes('assignedto');

			const fieldValueIsString = queryField.field.type === FieldType.esriFieldTypeString;

			const dataValueNeedQuotes = queryField.field.type === FieldType.esriFieldTypeDate && queryField.operator.name !== QueryOperator.forDate.within.name;

			const putQuoteAroundValue = fieldValueIsString || dataValueNeedQuotes;

			const fieldValue = putQuoteAroundValue ? this.getFormattedFieldValueString(queryField.value.code.trim()) : `${queryField.value.code}`;

			const operatorHasNull = queryField.operator.name.toLowerCase().includes('null');

			const operatorHasLike = queryField.operator.name === QueryOperator.forString.contains.name;
			const operatorHasNotLike = queryField.operator.name === QueryOperator.forString.doesNotContain.name;

			const stringIs = queryField.operator.value === QueryOperator.forString.is.value;

			const stringIsNot = queryField.operator.value === QueryOperator.forString.isNot.value;

			if (operatorHasNull) {
				queryStatementString += `${queryField.field.name}${space}${queryField.operator.value}`;
			} else if ((isSWOAssignedTo && (stringIs || stringIsNot)) || operatorHasLike || operatorHasNotLike) {
				if (stringIs || operatorHasLike) {
					const likeFullName = `LOWER(${queryField.field.name})${space}LIKE${space}'%${queryField.value.name.toLowerCase()}%'`;
					const likeUserName = `LOWER(${queryField.field.name})${space}LIKE${space}'%${queryField.value.code.toLowerCase()}%'`;
					queryStatementString += '(' + likeFullName + ' OR ' + likeUserName + ')';
				} else if (stringIsNot || operatorHasNotLike) {
					const notLikeFullName = `LOWER(${queryField.field.name})${space}NOT${space}LIKE${space}'%${queryField.value.name.toLowerCase()}%'`;
					const notLikeUserName = `LOWER(${queryField.field.name})${space}NOT${space}LIKE${space}'%${queryField.value.code.toLowerCase()}%'`;
					queryStatementString += '(' + notLikeFullName + ' OR ' + notLikeUserName + ')';
				}
			} else {
				if (queryField.field.type === FieldType.esriFieldTypeString) {
					queryStatementString += `LOWER(${queryField.field.name})${space}${queryField.operator.value}${space}${fieldValue.toLowerCase()}`;
				} else {
					queryStatementString += `${queryField.field.name}${space}${queryField.operator.value}${space}${fieldValue}`;
				}
			}

			const notDone = queryField.join.value !== doneOperator.value;

			if (notDone) {
				queryStatementString += `${space}${queryField.join.value}${space}`;
			}
		}

		queryStatementString += ')';

		return queryStatementString;
	}

	getFormattedFieldValueString(fieldValue: string) {
		fieldValue = fieldValue.replace(/'/g, '\'\'');
		return `'${fieldValue}'`;
	}

	/**
	 * This function will convert all statements to a single asset and history query
	 */
	generateQueries(): { assetQuery: string; historyQuery: string; standardWorkOrderQuery: string } {
		let assetQuery = '',
			historyQuery = '',
			standardWorkOrderQuery = '';

		// check query here

		for (const queryStatement of this.queryStatementList) {
			if (this.invalidQueryField(queryStatement)) break;

			const queryStatementString = this.generateQueryStatement(queryStatement);

			const isHistoryQuery = queryStatement.source.value === ChannelTypes.History;
			const isAssetQuery = queryStatement.source.value === ChannelTypes.Asset;

			const joinedQueryStatementString = this.getQueryStatementStringWithJoin(queryStatement.join, queryStatementString);

			if (isAssetQuery) {
				assetQuery += `${joinedQueryStatementString}${space}`;
				continue;
			} else if (isHistoryQuery) {
				historyQuery += `${joinedQueryStatementString}${space}`;
				continue;
			} else {
				standardWorkOrderQuery += `${joinedQueryStatementString}${space}`;
			}
		}

		return { assetQuery, historyQuery, standardWorkOrderQuery };
	}

	private invalidQueryField(queryStatement: QueryStatement) {
		for (const queryField of queryStatement.queryFieldList) {
			if (!queryField.join) return true;
		}
		return false;
	}

	/**
	 * This function will join the query statement string togther
	 * @param {Operator} join - The join operator
	 * @param {string} queryStatementString - The single query statement string
	 */
	getQueryStatementStringWithJoin(join: Operator, queryStatementString: string): string {
		const { doneOperator } = QueryOperator.forJoin;
		let joinedQueryStatementString: string;
		if (!join) {
			joinedQueryStatementString = `${queryStatementString}`;
		} else if (join.value === doneOperator.value) {
			joinedQueryStatementString = `${space}${queryStatementString}`;
		} else {
			joinedQueryStatementString = `${join.value}${space}${queryStatementString}${space}`;
		}
		return joinedQueryStatementString;
	}
}

/**
 * Indicate the query mode such as using builder mode or manual mode
 */
export enum QueryMode {
	none = '',
	builderQuery = 'builder query',
	manualQuery = 'manual query',
	workOrderFilter = 'work order filter'
}

/**
 * Indicate the query statement mode such as view mode or edit mode
 */
export enum QueryStatementMode {
	view = 'view',
	edit = 'edit'
}

/**
 * The metric query model
 */
export class MetricQueryLegacy {
	mode: string = QueryMode.none;

	hmsWorkorderFilterJson: WorkOrderQueryJSON;
	// Need to implement getter and set for this
	// custom?: CustomQuery;

	private _workOrderFilter: WorkOrderFilterFields;
	get workOrderFilter() {
		if (!this._workOrderFilter) {
			this._workOrderFilter = new WorkOrderFilterFields();
		}
		return this._workOrderFilter;
	}

	private _customFieldsFilter: CustomFieldFilters;
	get customFieldsFilter() {
		if (!this._customFieldsFilter) {
			this._customFieldsFilter = new CustomFieldFilters();
		}
		return this._customFieldsFilter;
	}

	private _queryStatementList?: QueryStatementList;
	get queryStatementList() {
		if (!this._queryStatementList) {
			this._queryStatementList = new QueryStatementList();
		}
		return this._queryStatementList;
	}

	getWorkOrderFilterJSON = (woSourceType: WorkOrderSourceType): WorkOrderQueryJSON => {
		const json = new WorkOrderFilterJSON();
		json.filters = this.workOrderFilter.getJSON();
		json.customFields = this.customFieldsFilter.getJSON();
		switch (woSourceType) {
			case WorkOrderSourceType.workOrders:
				return { workOrder: json };
			case WorkOrderSourceType.task:
				return { tasks: json };
			default:
				return {};
		}
	};

	fromPojo(metricQueryPojo: any) {
		this._workOrderFilter = new WorkOrderFilterFields();
		if (metricQueryPojo._workOrderFilter) {
			this._workOrderFilter.fromPojo(metricQueryPojo._workOrderFilter);
		}
		this._customFieldsFilter = new CustomFieldFilters();
		if (metricQueryPojo._customFieldsFilter) {
			this._customFieldsFilter.fromPojo(metricQueryPojo._customFieldsFilter);
		}
	}

	/**
	 * This function will initialize the metric query model
	 * @param {MetricQueryLegacy} query - The query data fetched from server
	 */
	initialize?(query: MetricQueryLegacy) {
		if (!query) return;

		this.mode = query.mode;

		// if (query.custom) {
		// 	this.custom = query.custom;
		// }

		const statementListData = query.queryStatementList;

		if (statementListData) {
			for (let i = 0; i < statementListData.length; i++) {
				const queryStatement = QueryStatement.from(statementListData, i);
				this.queryStatementList.push(queryStatement);
			}
		}

		if (query.workOrderFilter) {
			for (const filterField of query.workOrderFilter) {
				this.workOrderFilter.push(WorkOrderFilterField.fromContract(filterField));
			}
		}

		if (query.customFieldsFilter) {
			for (const filter of query.customFieldsFilter) {
				this.customFieldsFilter.push(CustomFieldFilter.fromContract(filter));
			}
		}
	}
}

export enum MetricTypes {
	'swo',
	'awo',
	'history',
	'asset'
}

export abstract class MetricQuery {
	mode: string = QueryMode.none;
}
