import { HMSRefreshOperation } from './hms-refresh-operation.enum';

export enum WorkOrderMetricType {
	SWO = 'SWO',
	AWO = 'AWO',
}

/**
 * This defines the model for the OMNI hms to call refresh workorder metric.
 */
export class RefreshWorkOrderMetric {
	/**
	 * Metric type
	 */
	metricType: WorkOrderMetricType;
	/**
	 * workOrderKey of the metric
	 */
	workOrderKey: string;
	/**
	 * HMS operation for add, move and update
	 */
	operation: HMSRefreshOperation;
}
