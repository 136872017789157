import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { OmniModel } from '../omni-model';

/**
 * This defines the work order attachment
 */
export class Attachment extends OmniModel<Attachment, Contracts.AttachmentContract> {
	/**
	 * id for attachment
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}
	/**
	 * holds the work order attachment id
	 */
	private _attachmentId: SedaruUtils.Undoable<number>;
	get attachmentId(): number {
		if (!this._attachmentId) this._attachmentId = new SedaruUtils.Undoable<number>();
		return this._attachmentId.value;
	}
	set attachmentId(value: number) {
		if (!this._attachmentId) {
			this._attachmentId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._attachmentId.value = value;
	}

	/**
	 * holds the attachment file index id
	 */
	private _fileIndexId: SedaruUtils.Undoable<number>;
	get fileIndexId(): number {
		if (!this._fileIndexId) this._fileIndexId = new SedaruUtils.Undoable<number>();
		return this._fileIndexId.value;
	}
	set fileIndexId(value: number) {
		if (!this._fileIndexId) {
			this._fileIndexId = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._fileIndexId.value = value;
	}

	/**
	 * holds the attachment reference key
	 */
	private _referenceKey: SedaruUtils.Undoable<string>;
	get referenceKey(): string {
		if (!this._referenceKey) this._referenceKey = new SedaruUtils.Undoable<string>();
		return this._referenceKey.value;
	}
	set referenceKey(value: string) {
		if (!this._referenceKey) {
			this._referenceKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._referenceKey.value = value;
	}

	/**
	 * holds the attachment reference value
	 */
	private _referenceValue: SedaruUtils.Undoable<string>;
	get referenceValue(): string {
		if (!this._referenceValue) this._referenceValue = new SedaruUtils.Undoable<string>();
		return this._referenceValue.value;
	}
	set referenceValue(value: string) {
		if (!this._referenceValue) {
			this._referenceValue = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._referenceValue.value = value;
	}

	/**
	 * holds the attachment url
	 */
	private _url: SedaruUtils.Undoable<string>;
	get url(): string {
		if (!this._url) this._url = new SedaruUtils.Undoable<string>();
		return this._url.value;
	}
	set url(value: string) {
		if (!this._url) {
			this._url = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._url.value = value;
	}

	/**
	 * holds the attachment system id
	 */
	private _systemId: SedaruUtils.Undoable<string>;
	get systemId(): string {
		if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
		return this._systemId.value;
	}
	set systemId(value: string) {
		if (!this._systemId) {
			this._systemId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._systemId.value = value;
	}

	/**
	 * holds the attachment user
	 */
	private _attachedBy: SedaruUtils.Undoable<string>;
	get attachedBy(): string {
		if (!this._attachedBy) this._attachedBy = new SedaruUtils.Undoable<string>();
		return this._attachedBy.value;
	}
	set attachedBy(value: string) {
		if (!this._attachedBy) {
			this._attachedBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._attachedBy.value = value;
	}

	/**
	 * holds the attachment date
	 */
	private _attachedDate: SedaruUtils.Undoable<Date>;
	get attachedDate(): Date {
		if (!this._attachedDate) this._attachedDate = new SedaruUtils.Undoable<Date>();
		return this._attachedDate.value;
	}
	set attachedDate(value: Date) {
		if (!this._attachedDate) {
			this._attachedDate = new SedaruUtils.Undoable<Date>(value);
			return;
		}
		this._attachedDate.value = value;
	}

	/**
	 * holds the attachment comments
	 */
	private _comments: SedaruUtils.Undoable<string>;
	get comments(): string {
		if (!this._comments) this._comments = new SedaruUtils.Undoable<string>();
		return this._comments.value;
	}
	set comments(value: string) {
		if (!this._comments) {
			this._comments = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._comments.value = value;
	}

	private _thumbnailUrl: SedaruUtils.Undoable<string>;
	get thumbnailUrl(): string {
		if (!this._thumbnailUrl) this._thumbnailUrl = new SedaruUtils.Undoable<string>();
		return this._thumbnailUrl.value;
	}
	set thumbnailUrl(value: string) {
		if (!this._thumbnailUrl) {
			this._thumbnailUrl = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._thumbnailUrl.value = value;
	}

	static fromContract(contract: Contracts.AttachmentContract): Attachment {
		const model = new Attachment();
		model.id = contract.ObjectId.toString();
		model.attachmentId = contract.AttachmentId;
		// model.fileIndexId = contract.FileIndexId;
		model.fileIndexId = contract['FileindexId'];
		model.referenceKey = contract.ReferenceKey;
		model.referenceValue = contract.ReferenceValue;
		model.url = contract.Url;
		model.systemId = contract.SystemId;
		model.attachedBy = contract.AttachedBy;
		model.attachedDate = new Date(contract.AttachedDate);
		model.comments = contract.Comments;
		model.clearDirty();
		return model;
	}
	getContract = (): Contracts.AttachmentContract => {
		const contract = new Contracts.AttachmentContract();
		contract.ObjectId = +this.id;
		contract.AttachmentId = this.attachmentId;
		contract.FileindexId = this.fileIndexId;
		contract.ReferenceKey = this.referenceKey;
		contract.ReferenceValue = this.referenceValue;
		contract.Url = this.url;
		contract.SystemId = this.systemId;
		contract.AttachedBy = this.attachedBy;
		contract.AttachedDate = this.attachedDate.toLocaleString();
		contract.Comments = this.comments;
		return contract;
	};
	onUpdateInformation(copyModel: Attachment): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._attachmentId, () => {
				this.attachmentId = copyModel.attachmentId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._fileIndexId, () => {
				this.fileIndexId = copyModel.fileIndexId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._referenceKey, () => {
				this.referenceKey = copyModel.referenceKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._referenceValue, () => {
				this.referenceValue = copyModel.referenceValue;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._url, () => {
				this.url = copyModel.url;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._attachedBy, () => {
				this.attachedBy = copyModel.attachedBy;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<Date>(this._attachedDate, () => {
				this.attachedDate = copyModel.attachedDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._comments, () => {
				this.comments = copyModel.comments;
			}) || wasChanged;
		return wasChanged;
	}
}
