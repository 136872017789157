import { ContractBase } from '../contract-base';
import { InspectionResultContract } from './inspection-result-contract';

export class WorkInspectionContract extends ContractBase {
	public ObjectId: number;
	public InspectionTypeID: number;
	public AssetType: string;
	public AssetKey: string;
	public AssetOID: number;
	public WorkOrderKey: string;
	public InspectionKey: string;
	public SystemID: string;
	public InspectedBy: string;
	public InspectionDate: Date;
	public Status: string;

	private _attributes: Array<InspectionResultContract>;

	get Attributes(): Array<InspectionResultContract> {
		return this._attributes ? this._attributes : (this._attributes = new Array<InspectionResultContract>());
	}
	set Attributes(value) {
		this._attributes = value;
	}
}
