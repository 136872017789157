import { WorkOrderTeam } from './../../../models/work-order/work-order-team.model';
import { LEMService } from '../workorder-lem-list/lem.service';
import { Employee, WorkOrderTemplate, Priority, WorkOrderFieldNames, CustomFieldCode, Status, EmployeeRate, EmployeeTrade, WorkOrderBudget, LEMType, WorkOrderSummary, WorkOrderWrapper, EquipmentCostWrapper, EmployeeCostWrapper, MaterialCostWrapper, TaskWrapper, CustomFieldWrapper } from './../../../models/work-order';
import { WorkOrderFieldProvider } from './../workorder-filter/workorder-field.provider';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { NoIconOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/no-icon-one-line/no-icon-one-line.component';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { ContextualSearch } from 'app/menu-panel/menu-panel-base/contextual-search';
import { DataChannelService } from 'domain-service/data-channel.service';
import { DateUtil } from 'sedaru-util/date-utility/date-util';
import { Pages } from 'app/navigation/inavigation';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { FormDefinition } from '../../../models/form-definition.model';
import { FieldConfigInput } from '../../../models/field-config-input.model';

@Component({
	selector: 'app-workorder-field',
	templateUrl: './workorder-field.component.html',
	styleUrls: ['./workorder-field.component.scss']
})
export class WorkOrderFieldComponent extends MenuPanelBaseComponent {
	static customFieldResolver: () => CustomFieldWrapper;

	get selectionList(): any[] {
		return this.getContextualSearchItems<any>();
	}
	set selectionList(value: any[]) {
		value.sort((a, b) => (a[this.nameKey] > b[this.nameKey] ? 1 : b[this.nameKey] > a[this.nameKey] ? -1 : 0));
		(this.contextualSearch as ContextualSearch<any>).reloadRecords(value);
	}

	nameKey = '';

	showClearButton: boolean;

	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;

	selectedItems = [];
	selectedItemKey: string;
	workTypeFilterEnabled: boolean;
	advancedMode: boolean;
	isMultipleSelection: boolean;
	workOrderSummary: WorkOrderSummary;
	input: FieldConfigInput;
	private workOrder: WorkOrderWrapper;
	private equipmentCost: EquipmentCostWrapper;
	private employeeCost: EmployeeCostWrapper;
	private materialCost: MaterialCostWrapper;
	private workTask: TaskWrapper;

	private resolvers = new Map();

	public get pageIdentifier(): Pages {
		return Pages.workorderField;
	}

	constructor(
		private dataChannelService: DataChannelService,
		private workOrderFieldProvider: WorkOrderFieldProvider,
		private workOrderFactory: WorkOrderFactory,
		private interop: OmniInteropService,
		view: ElementRef<HTMLElement>,
		private lemSerivce: LEMService
	) {
		super(view);
	}

	private getItemResolver(item) {
		const workorderPickListResolver = NoIconOneLineComponent.createResolver();
		workorderPickListResolver.getLabel = i => i[this.nameKey];
		this.resolvers.set(item[this.nameKey], workorderPickListResolver);
		return workorderPickListResolver;
	}

	ngOnDestroy() {
		WorkOrderFieldComponent.customFieldResolver = null;
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (!args || !args.parameter) return;
		this.input = args.parameter.input;
		this.selectedItemKey = args.parameter.selectedItemKey;
		const selectedItems = args.parameter.selectedItems;
		this.advancedMode = args.parameter.advancedMode;
		this.workTypeFilterEnabled = args.parameter.workTypeFilterEnabled || false;
		this.workOrderSummary = args.parameter.workOrderSummary;
		this.workOrder = args.parameter.workOrder ? args.parameter.workOrder : this.workOrderSummary?.workOrderWrapper;
		this.workTask = args.parameter.workTask;
		this.employeeCost = args.parameter.employeeCost;
		this.equipmentCost = args.parameter.equipmentCost;
		this.materialCost = args.parameter.materialCost;
		this.isMultipleSelection = args.parameter.isMultipleSelection;

		this.getSelections();

		this.listComponent.getResolver = item => {
			if (this.resolvers.has(item[this.nameKey])) return this.resolvers.get(item[this.nameKey]);
			return this.getItemResolver(item);
		};

		const { selectedWorkOrderFieldName } = this.workOrderFieldProvider;
		if (selectedWorkOrderFieldName === WorkOrderFieldNames.WorkOrderCustomFields || selectedWorkOrderFieldName === WorkOrderFieldNames.TaskCustomFields) this.showClearButton = false;
		if (selectedItems) this.selectedItems.push(...selectedItems);
	}

	getSelections() {
		if (this.input) {
			this.nameKey = 'text';
			this.selectionList = this.input?.config?.list?.items;
			return;
		}

		const { selectedWorkOrderFieldName } = this.workOrderFieldProvider;
		const { workOrderMetaData } = this.workOrderFactory;
		switch (selectedWorkOrderFieldName) {
			case WorkOrderFieldNames.WorkType:
				this.selectionList = workOrderMetaData.woTemplates;
				this.nameKey = 'woTemplateName';
				if (this.workTypeFilterEnabled) {
					this.selectionList = this.advancedMode ? this.workTypeFilter(workOrderMetaData.woTemplates, this.workOrderSummary.workOrderWrapper)
						: this.standardWorkTypeFilter(this.workOrderSummary.workOrderWrapper);
				}
				break;
			case WorkOrderFieldNames.WorkOrderAssignTo:
			case WorkOrderFieldNames.WorkTaskAssignTo:
			case WorkOrderFieldNames.Supervisor:
			case WorkOrderFieldNames.Employee:
				this.nameKey = 'name';
				this.selectionList = this.getEmployees();
				break;
			case WorkOrderFieldNames.WorkOrderStatus:
				const workOrderStatuses = this.getStatuses(true);
				this.nameKey = workOrderStatuses.length ? 'description' : 'statusCode';
				this.selectionList = workOrderStatuses.length ? workOrderStatuses : [{ statusCode: 'Open' }, { statusCode: 'Close' }];
				break;
			case WorkOrderFieldNames.WorkTaskStatus:
				const taskStatuses = this.getStatuses(false);
				this.nameKey = taskStatuses.length ? 'description' : 'statusCode';
				this.selectionList = taskStatuses.length ? taskStatuses : [{ statusCode: 'Open' }, { statusCode: 'Close' }];
				break;
			case WorkOrderFieldNames.Priority:
				this.nameKey = 'description';
				this.selectionList = workOrderMetaData.priorities.length ? workOrderMetaData.priorities : [];
				break;
			case WorkOrderFieldNames.WorkOrderCustomFields:
				const workOrderCustomField = WorkOrderFieldComponent.customFieldResolver();
				const woTemplate = workOrderMetaData.woTemplates.find(template => template.woTemplateId === this.workOrderSummary.workTypeId);
				const workOrderCustomFieldFromTemplate = woTemplate.customFields.find(customField => customField.customFieldId === workOrderCustomField.customFieldId);
				this.nameKey = 'description';
				this.selectionList = workOrderMetaData.customFieldCodes.filter(code => code.codeType === workOrderCustomFieldFromTemplate.codeType);
				break;
			case WorkOrderFieldNames.TaskCustomFields:
				const taskCustomField = WorkOrderFieldComponent.customFieldResolver();
				const taskTemplate = workOrderMetaData.tasks.find(task => task.taskId === this.workTask.taskKey);
				const taskCustomFieldFromTemplate = taskTemplate.taskCustomFields.find(customField => customField.customFieldId === taskCustomField.customFieldId);
				this.nameKey = 'description';
				this.selectionList = workOrderMetaData.customFieldCodes.filter(code => code.codeType === taskCustomFieldFromTemplate.codeType);
				break;
			case WorkOrderFieldNames.RateType:
				this.nameKey = 'description';
				this.selectionList = workOrderMetaData.employeeRates;
				break;
			case WorkOrderFieldNames.AddLEMTask:
				this.nameKey = 'description';
				this.selectionList = this.workOrder.workTasks;
				break;
			case WorkOrderFieldNames.EmployeeTrade:
				this.nameKey = 'name';
				this.selectionList = workOrderMetaData.employeeTrades;
				break;
			case WorkOrderFieldNames.WorkOrderBudget:
				this.nameKey = 'name';
				this.selectionList = workOrderMetaData.workOrderBudgets;
				break;
			case WorkOrderFieldNames.WorkOrderTeam:
			case WorkOrderFieldNames.WorkTaskTeam:
				this.selectionList = workOrderMetaData.workorderTeams;
				this.nameKey = 'teamName';
				break;
		}
	}

	onItemSelected(item: any) {
		if (this.input) {
			this.input.value = item?.value;
			this.menuPanelComponent.goBack();
			return;
		}

		if (this.isMultipleSelection) return;
		const { selectedWorkOrderFieldName } = this.workOrderFieldProvider;
		switch (selectedWorkOrderFieldName) {
			case WorkOrderFieldNames.WorkType:
				if (!this.advancedMode) {
					this.workOrderSummary.workType = item.workType;
					break;
				}

				this.workOrderSummary.workType = (item as WorkOrderTemplate).woTemplateName;
				this.workOrderSummary.workTypeId = (item as WorkOrderTemplate).woTemplateId;
				this.workOrderSummary.customFields = (item as WorkOrderTemplate).customFields.getDefaultFields();
				break;
			case WorkOrderFieldNames.WorkOrderAssignTo:
				this.workOrderSummary.assignedTo = (item as Employee).employeeId;
				break;
			case WorkOrderFieldNames.Supervisor:
				this.workOrderSummary.supervisor = (item as Employee).employeeId;
				break;
			case WorkOrderFieldNames.WorkOrderStatus:
				this.workOrderSummary.status = (item as Status).statusCode;
				break;
			case WorkOrderFieldNames.Priority:
				this.workOrderSummary.priority = (item as Priority).priorityCode;
				break;
			case WorkOrderFieldNames.Location:
				return;
			case WorkOrderFieldNames.WorkOrderCustomFields:
			case WorkOrderFieldNames.TaskCustomFields:
				const customField = WorkOrderFieldComponent.customFieldResolver();
				customField.customFieldValue = (item as CustomFieldCode).code;
				break;
			case WorkOrderFieldNames.Employee:
				this.employeeCost.employeeId = (item as Employee).employeeId;
				break;
			case WorkOrderFieldNames.RateType:
				this.employeeCost.rateType = (item as EmployeeRate).rateCode;
				break;
			case WorkOrderFieldNames.AddLEMTask:
				this.addWorkTaskIdToCost(item);
				break;
			case WorkOrderFieldNames.EmployeeTrade:
				this.employeeCost.tradeId = (item as EmployeeTrade).tradeId;
				this.employeeCost.hourlyRate = (item as EmployeeTrade).hourlyRate;
				break;
			case WorkOrderFieldNames.WorkOrderBudget:
				this.employeeCost.budgetId = (item as WorkOrderBudget).budgetId;
				break;
			case WorkOrderFieldNames.WorkTaskAssignTo:
				this.workTask.assignedTo = (item as Employee).employeeId;
				break;
			case WorkOrderFieldNames.WorkTaskStatus:
				this.workTask.status = (item as Status).statusCode;
				const completeCodes = this.dataChannelService.getCompletedWorkOrderValues().join(',');
				if (completeCodes.includes(this.workTask.status)) this.workTask.completedDate = DateUtil.getTimeZoneAgnosticString(new Date());
				else this.workTask.completedDate = '';
				break;
			case WorkOrderFieldNames.WorkOrderTeam:
				this.workOrderSummary.teamId = (item as WorkOrderTeam).teamId;
				break;
			case WorkOrderFieldNames.WorkTaskTeam:
				this.workTask.teamId = (item as WorkOrderTeam).teamId;
		}

		this.menuPanelComponent.goBack();
	}

	onClear(): void {
		try {
			if (this.isMultipleSelection) {
				this.setEmptySelection();
				return;
			}

			// This button is only for custom field
			const selectedCustomField = WorkOrderFieldComponent.customFieldResolver();
			selectedCustomField.customFieldValue = '';
		} finally {
			this.menuPanelComponent.goBack();
		}
	}

	onSave(): void {
		const { selectedWorkOrderFieldName } = this.workOrderFieldProvider;
		if (!selectedWorkOrderFieldName) return;

		switch (selectedWorkOrderFieldName) {
			case WorkOrderFieldNames.WorkOrderAssignTo:
				this.saveAssignedTo();
				break;
		}

		this.menuPanelComponent.goBack();
	}

	private saveAssignedTo(): void {
		let value = '';
		const employeeIds = (this.selectedItems as Employee[])?.map(e => e.employeeId);
		if (employeeIds) value = employeeIds.join(',');
		this.workOrderSummary.assignedTo = value;
	}

	private setEmptySelection(): void {
		const { selectedWorkOrderFieldName } = this.workOrderFieldProvider;
		if (!selectedWorkOrderFieldName) return;

		switch (selectedWorkOrderFieldName) {
			case WorkOrderFieldNames.WorkOrderAssignTo:
				this.workOrderSummary.assignedTo = '';
				break;
		}
	}

	addWorkTaskIdToCost(item: TaskWrapper) {
		switch (this.lemSerivce.lemType) {
			case LEMType.labor:
				this.employeeCost.workTaskId = item.workTaskId;
				break;
			case LEMType.equipment:
				this.equipmentCost.workTaskId = item.workTaskId;
				break;
			case LEMType.material:
				this.materialCost.workTaskId = item.workTaskId;
				break;
		}
	}

	onCreateContextualSearch() {
		const contextualSearch = new ContextualSearch<any>([], (item: any, text: string) => {
			if (!this.nameKey) return false;

			if (!item[this.nameKey]) return false;

			if (
				item[this.nameKey]
					.toString()
					.toLowerCase()
					.includes(text.toLowerCase())
			) {
				return true;
			}

			return false;
		});

		return contextualSearch;
	}

	/**
	 * Show all work types that have a null asset type and Show all work types that match the asset type of the work order.
	 * @param woTempaltes
	 * @param currentWorkOrder
	 */
	workTypeFilter(woTempaltes, currentWorkOrder) {
		let selectionList = [];
		const workTaskAssetTypes = currentWorkOrder.workAssets.map(workAsset => workAsset.assetType).filter((x, i, a) => a.indexOf(x) == i);
		selectionList = woTempaltes.filter(item => {
			return item.assetType === null || workTaskAssetTypes.includes(item.assetType);
		});

		// @ts-ignore
		selectionList.sort((a, b) => (a.assetType === null) - (b.assetType === null) || +(a.assetType > b.assetType) || -(a.assetType < b.assetType));

		return selectionList;
	}

	standardWorkTypeFilter(workOrderWrapper: WorkOrderWrapper): any[] {
		this.nameKey = 'workType';
		const woAssetTypes = [...new Set(workOrderWrapper.workAssets.map(wa => wa.assetType))];
		const matchingAssetDefinitions = this.interop?.configurationManager?.customerCodeConfiguration?.assetDefinitions?.filter(ad => woAssetTypes.includes(ad.assetType));
		if (!matchingAssetDefinitions) return [];

		const formsList = new Array<FormDefinition>();
		for (const assetDefinition of matchingAssetDefinitions) formsList.push(...assetDefinition.forms);
		return formsList;
	}

	private getEmployees(): Employee[] {
		return this.workOrderFactory.workOrderMetaData.employees;
	}

	private getStatuses(isWorkOrder: boolean, activeOnly = false): Status[] {
		let property = 'workOrderVisible';
		if (!isWorkOrder) property = 'taskVisible';
		const statuses = this.workOrderFactory?.workOrderMetaData?.statuses;
		if (!statuses || !statuses.length) return [];

		return this.workOrderFactory.workOrderMetaData.statuses.filter(s => s[property] && (activeOnly ? this.isActiveStatus(s) : true));
	}

	private isActiveStatus(status: Status): boolean {
		if (!status || !status.statusCode) return false;
		return this.workOrderFactory?.activeWorkOrderValues?.includes(status.statusCode);
	}
}
