import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { AppComponent } from './app.component';
import { TabPanelComponent } from './tab-panel/tab-panel.component';
import { CanvasContainerComponent } from './canvas-container/canvas-container.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { TileListComponent } from './tiles/tile-list.component';
import { OmniUserComponent } from './user/omni-user.component';
import { LoginDialogComponent } from './user/login-dialog/login-dialog.component';
import { TabContentPanelComponent } from './menu-panel/tab-settings/tab-content-panel/tab-content-panel.component';
import { LayoutConfigurationComponent } from './menu-panel/tab-settings/layout-configuration/layout-configuration.component';
import { TileSettingsComponent } from './menu-panel/tile-settings/tile-settings.component';
import { EditMetricComponent } from 'app/metric/list-metric/edit-metric/edit-metric.component';
import { HeaderComponent } from './header/header.component';
import { MetricIconListComponent } from 'app/metric/list-metric/edit-metric/metric-icon/metric-icon-list.component';
import { ListMetricComponent } from 'app/metric/list-metric/list-metric.component';
import { TabVisibilityComponent } from './menu-panel/tab-settings/tab-visibility/tab-visibility.component';
import { BotTabComponent } from './tab-panel/bot-tab/bot-tab.component';
import { MapListComponent } from './menu-panel/map-list/map-list.component';
import { MapSettingsComponent } from './menu-panel/map-settings/map-settings.component';
import { DisplayValueSettingsComponent } from 'app/metric/list-metric/edit-metric/display-value-settings/display-value-settings.component';
import { OmniValueListComponent } from './omni-value-list/omni-value-list.component';
import { ThemeComponent } from './menu-panel/tab-settings/theme/theme.component';
import { CanvasMapComponent } from './canvas-container/canvas-map/canvas-map.component';
import { QueryBuilderComponent } from './metric/list-metric/edit-metric/metric-query/query-builder/query-builder.component';
import { QueryValidatorComponent } from './metric/list-metric/edit-metric/metric-query/query-validator/query-validator.component';
import { TabSettingsMainMenuComponent } from './menu-panel/tab-settings/tab-settings-main-menu/tab-settings-main-menu.component';
import { MetricSourceTypesComponent } from './metric/list-metric/edit-metric/metric-source/types/metric-source-types.component';
import { MenuPanelSettingsComponent } from './metric/list-metric/edit-metric/menu-panel-settings/menu-panel-settings.component';
import { DataSourceService } from '../domain-service/data-source.service';
import { EsriSdkService } from './canvas-container/canvas-map/esri-sdk.service';
import { TooltipDirective } from './app-directives/tooltip-directive/tooltip.directive';
import { RemoveCanvasAndTilesDialogComponent } from './canvas-container/remove-canvas-and-tiles-dialog/remove-canvas-and-tiles-dialog.component';
import { AssetIconComponent } from './metric/list-metric/edit-metric/metric-icon/asset-icon.component';
import { TrendSettingsComponent } from './menu-panel/trend-settings/trend-settings.component';
import { CanvasTabsComponent } from './canvas-container/canvas-tabs/canvas-tabs.component';
import { CanvasComponent } from './canvas-container/canvas/canvas.component';
import { TrendStyleComponent } from './menu-panel/trend-settings/trend-style/trend-style.component';
import { MapAssetListComponent } from './menu-panel/map-assets/map-asset-list/map-asset-list.component';
import { MapAssetAttributesComponent } from './menu-panel/map-assets/map-asset-attributes/map-asset-attributes.component';
import { AssetTypesComponent } from './metric/list-metric/edit-metric/metric-source/asset-types/metric-source-asset-types.component';
import { RootDataService } from '../domain-service/root-data.service';
import { MetricService } from '../domain-service/metric.service';
import { GuiConfigService } from '../domain-service/gui-config.service';
import { DefaultTrendSettingsComponent } from './metric/list-metric/edit-metric/default-trend-settings/default-trend-settings.component';
import { DefaultTrendStyleComponent } from './metric/list-metric/edit-metric/default-trend-settings/default-trend-style/default-trend-style.component';
import { EditQueryStatementComponent } from './metric/list-metric/edit-metric/metric-query/query-statement/edit-query-statement/edit-query-statement.component';
import { QueryFieldComponent } from './metric/list-metric/edit-metric/metric-query/query-field/query-field.component';
import { QuerySourceComponent } from './metric/list-metric/edit-metric/metric-query/query-source/query-source.component';
import { MapLayersComponent } from './menu-panel/map-layers/map-layers.component';
import { SedaruSubscriptionsService } from '../domain-service/subscriptions/sedaru-subscriptions.service';
import { ViewQueryStatementComponent } from './metric/list-metric/edit-metric/metric-query/query-statement/view-query-statement/view-query-statement.component';
import { QueryStatementComponent } from './metric/list-metric/edit-metric/metric-query/query-statement/query-statement.component';
import { WorkorderModule } from './workorder/workorder.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { CanvasTrendComponent } from './canvas-container/canvas-trend/canvas-trend.component';
import { GraphQLModule } from './graphql/graphql.module';
import { LegendInfoComponent } from './menu-panel/map-layers/map-legend/legend-info/legend-info.component';
import { MapLegendComponent } from './menu-panel/map-layers/map-legend/map-legend.component';
import { LayerVisibilityComponent } from './menu-panel/map-layers/layer-visibility/layer-visibility.component';
import { BaseMapComponent } from './menu-panel/map-layers/base-map/base-map.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationModule } from './navigation/navigation.module';
import { MainContentComponent } from './main-content/main-content.component';
import { WorkOrderSourceTypesComponent } from './metric/list-metric/edit-metric/metric-source/work-order-source-types/work-order-source-types.component';
import { SearchModule } from './search/search.module';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsService } from 'domain-service/analytics/analytics.service';
import { ErrorHandlerCluster } from 'domain-service/analytics/error-handler-cluster';
import { DynamicFormsModule } from './dynamic-forms/dynamic-forms.module';
import { OmniBootstrapService } from 'domain-service/omni-bootstrap.service';
import { AssetInformationComponent } from './menu-panel/map-assets/asset-information/asset-information.component';
import { TeamViewerModule } from './team-viewer/team-viewer.module';
import { CanvasTableComponent } from './canvas-container/canvas-table/canvas-table.component';
import { ArcGISDataSourceService } from 'domain-service/data-sources/arc-gis-data-source.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { TimeFrameSettingsComponent } from './metric/list-metric/edit-metric/time-frame-settings/time-frame-settings.component';
import { MapTooltipComponent } from './canvas-container/canvas-map/map-tooltip/map-tooltip.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TabChangedWarningDialogComponent } from './menu-panel/tab-settings/tab-settings-main-menu/tab-changed-warning-dialog/tab-changed-warning-dialog.component';
import { MetricResultsComponent } from './metric/metric-results/metric-results.component';
import { TileComponent } from './tiles/tile/tile.component';
import { HierarchyAssetFormComponent } from './menu-panel/map-assets/hierarchy-asset-form/hierarchy-asset-form.component';
import { UserContextMenuComponent } from './user/user-context-menu/user-context-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { TableSettingsComponent } from './menu-panel/table-settings/table-settings.component';
import { TimeframeFilterComponent } from './menu-panel/timeframe/timeframe-filter/timeframe-filter.component';
import { TileTimeframeFormComponent } from './menu-panel/tile-settings/tile-timeframe-form/tile-timeframe-form.component';
import { SingleAssetTypeListComponent } from './menu-panel/map-assets/map-asset-list/single-asset-type-list/single-asset-type-list.component';
import { DateFieldSelectionComponent } from './menu-panel/timeframe/date-field-selection/date-field-selection.component';
import { OmniSnackBarComponent } from './flash-message/omni-snack-bar/omni-snack-bar.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { TimeframeSelectionComponent } from './menu-panel/timeframe/timeframe-selection/timeframe-selection.component';
import { TimeframeBuilderComponent } from './menu-panel/timeframe/timeframe-builder/timeframe-builder.component';
import { TimeframeFieldComponent } from './menu-panel/timeframe/timeframe-field/timeframe-field.component';

@NgModule({
	entryComponents: [OmniUserComponent, LoginDialogComponent, RemoveCanvasAndTilesDialogComponent],
	declarations: [
		AppComponent,
		CanvasContainerComponent,
		MenuPanelComponent,
		TabPanelComponent,
		TileListComponent,
		TileSettingsComponent,
		TabContentPanelComponent,
		LayoutConfigurationComponent,
		EditMetricComponent,
		OmniUserComponent,
		LoginDialogComponent,
		HeaderComponent,
		AssetIconComponent,
		MetricIconListComponent,
		ListMetricComponent,
		BotTabComponent,
		TabVisibilityComponent,
		MapListComponent,
		MapSettingsComponent,
		DisplayValueSettingsComponent,
		OmniValueListComponent,
		ThemeComponent,
		CanvasMapComponent,
		TabSettingsMainMenuComponent,
		CanvasMapComponent,
		QueryBuilderComponent,
		QueryValidatorComponent,
		MetricSourceTypesComponent,
		WorkOrderSourceTypesComponent,
		MenuPanelSettingsComponent,
		TooltipDirective,
		RemoveCanvasAndTilesDialogComponent,
		CanvasTrendComponent,
		TrendSettingsComponent,
		CanvasTabsComponent,
		CanvasComponent,
		TrendStyleComponent,
		MapAssetListComponent,
		MapAssetAttributesComponent,
		AssetInformationComponent,
		AssetTypesComponent,
		DefaultTrendSettingsComponent,
		DefaultTrendStyleComponent,
		QueryStatementComponent,
		EditQueryStatementComponent,
		ViewQueryStatementComponent,
		QueryFieldComponent,
		QuerySourceComponent,
		MapLayersComponent,
		LegendInfoComponent,
		MapLegendComponent,
		LayerVisibilityComponent,
		BaseMapComponent,
		LayoutComponent,
		MainContentComponent,
		AssetInformationComponent,
		CanvasTableComponent,
		TimeFrameSettingsComponent,
		MapTooltipComponent,
		TabChangedWarningDialogComponent,
		MetricResultsComponent,
		TileComponent,
		HierarchyAssetFormComponent,
		UserContextMenuComponent,
		TileTimeframeFormComponent,
		TableSettingsComponent,
		SingleAssetTypeListComponent,
		TimeframeFilterComponent,
		DateFieldSelectionComponent,
		OmniSnackBarComponent,
		UserProfileComponent,
		ChangePasswordComponent,
		TimeframeSelectionComponent,
		TimeframeBuilderComponent,
		TimeframeFieldComponent,
	],
	imports: [
		GraphQLModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		NavigationModule,
		MccColorPickerModule.forRoot({
			empty_color: 'transparent',
			used_colors: ['#000000', '#FFF555']
		}),
		UiComponentsModule,
		WorkorderModule,
		SearchModule,
		DynamicFormsModule,
		TeamViewerModule,
		DragDropModule,
		HttpClientModule
	],
	providers: [
		DatePipe,
		OmniBootstrapService,
		OmniInteropService,
		AnalyticsService,
		RootDataService,
		MetricService,
		GuiConfigService,
		DataSourceService,
		EsriSdkService,
		SedaruSubscriptionsService,
		ArcGISDataSourceService,
		CookieService,
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerCluster
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
