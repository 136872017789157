<div class="lists-container">
    <div class="ancestor-list">
        <app-omni-list #ancestorsList
            [omniListItems]="ancestors"
            (leftIconClicked)="leftIconClicked.emit($event)"
            (rightIconClicked)="rightIconClicked.emit($event)"
            (itemClicked)="itemClicked.emit($event)">
        </app-omni-list>
    </div>
    <div class="children-list">
        <app-omni-list #childrenList
            [omniListItems]="children"
            (leftIconClicked)="leftIconClicked.emit($event)"
            (rightIconClicked)="rightIconClicked.emit($event)"
            (itemClicked)="itemClicked.emit($event)">
        </app-omni-list>
    </div>
</div>
