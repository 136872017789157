<label class="asset-list-header">history record assets</label>
<mat-divider class="asset-list-divider"></mat-divider>
<div class="list-wrapper">
	<app-omni-list [omniListItems]="assets" (rightIconClicked)="goTo($event)"></app-omni-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<label class="omni-md-font">items: {{ assets ? assets.length : 0 }} &nbsp;</label>
	</mat-card-actions>
</mat-card-footer>
