import { EmployeeCostContract, StandardEmployeeCostContract } from '../../contracts/work-order';
import { EmployeeCost } from './employee-cost.model';
import { EmployeeRates } from './employee-rates.model';
import { StandardEmployeeCost } from './standard-employee-cost.model';

export class EmployeeCostWrapper {
	private _employeeCost: EmployeeCost | StandardEmployeeCost;
	get employeeCost(): EmployeeCost | StandardEmployeeCost {
		return this._employeeCost;
	}

	constructor(employeeCost: EmployeeCost | StandardEmployeeCost) {
		if (employeeCost instanceof EmployeeCost) this._employeeCost = employeeCost as EmployeeCost;
		else this._employeeCost = employeeCost as StandardEmployeeCost;
	}

	get id(): string {
		return this._employeeCost.id;
	}
	set id(value: string) {
		this._employeeCost.id = value;
	}

	get hourlyRate(): number {
		return this._employeeCost.hourlyRate;
	}
	set hourlyRate(value: number) {
		this._employeeCost.hourlyRate = value;
	}

	get rateType(): string {
		return this._employeeCost.rateType;
	}
	set rateType(value: string) {
		this._employeeCost.rateType = value;
	}

	get hours(): number {
		return this._employeeCost.hours;
	}
	set hours(value: number) {
		this._employeeCost.hours = value;
	}

	get totalCost(): number {
		return this._employeeCost.totalCost;
	}
	set totalCost(value: number) {
		this._employeeCost.totalCost = value;
	}

	get employeeId(): string {
		return this._employeeCost.employeeId;
	}
	set employeeId(value: string) {
		this._employeeCost.employeeId = value;
	}

	get dateStamp(): string {
		return this._employeeCost.dateStamp;
	}
	set dateStamp(value: string) {
		this._employeeCost.dateStamp = value;
	}

	get workOrderKey(): string {
		return this._employeeCost.workOrderKey;
	}
	set workOrderKey(value: string) {
		this._employeeCost.workOrderKey = value;
	}

	get changeStatus(): string {
		return this._employeeCost.changeStatus;
	}
	set changeStatus(value: string) {
		this._employeeCost.changeStatus = value;
	}

	get workTaskId(): string {
		return this._employeeCost.workTaskId;
	}
	set workTaskId(value: string) {
		this._employeeCost.workTaskId = value;
	}

	get tradeId(): string {
		return this._employeeCost.tradeId;
	}
	set tradeId(value: string) {
		this._employeeCost.tradeId = value;
	}

	get budgetId(): string {
		return this._employeeCost.budgetId;
	}
	set budgetId(value: string) {
		this._employeeCost.budgetId = value;
	}

	get createdBy(): string {
		return this._employeeCost.createdBy;
	}
	set createdBy(value: string) {
		this._employeeCost.createdBy = value;
	}

	get isDirty(): boolean {
		return this._employeeCost.isDirty;
	}

	isNew = (): boolean => this._employeeCost.isNew();

	calculateTotalCost = (employeeRates: EmployeeRates): void => this._employeeCost.calculateTotalCost(employeeRates);

	createTimeStamp = (): void => this._employeeCost.createTimeStamp();

	clearDirty = (): void => this._employeeCost.clearDirty();

	undoAll = (): boolean => this._employeeCost.undoAll();

	onUpdateInformation = (copyModel): boolean => this._employeeCost.onUpdateInformation(copyModel.employeeCost);

	updateInformation = (copyModel): boolean => this._employeeCost.updateInformation(copyModel.employeeCost);

	getContract = (): EmployeeCostContract | StandardEmployeeCostContract => this._employeeCost.getContract();
}
