import { AnalyticsEvent } from './analytics-event';
import { IAnalyticsChannel } from './ianalytics-channel';
import { AnalyticsError } from './analytics-error';

/**
 * This singleton class can stand alone and will report analytics to the registered channels
 */
export class AnalyticsHub {
	private static _current: AnalyticsHub;
	private _analyticsChannels: { [index: string]: IAnalyticsChannel };
	private get analyticsChannels(): { [index: string]: IAnalyticsChannel } {
		return this._analyticsChannels ? this._analyticsChannels : (this._analyticsChannels = {});
	}

	get channelCount(): number {
		return Object.values(this.analyticsChannels).length;
	}
	static get current(): AnalyticsHub {
		return this._current ? this._current : (this._current = new AnalyticsHub());
	}

	private _userIdentity: string;
	get userIdentity(): string {
		return this._userIdentity;
	}
	set userIdentity(value) {
		this._userIdentity = value;
		for (const c of Object.values(this.analyticsChannels)) {
			c.userIdentifier = this._userIdentity;
		}
	}

	private constructor() {
		AnalyticsEvent.trackEvent = (event: AnalyticsEvent) => {
			this.trackEvent(event);
		};
		AnalyticsError.trackError = (error: AnalyticsError) => {
			this.trackError(error);
		};
	}

	public clear() {
		this._analyticsChannels = null;
	}

	public isChannelRegistered(channelIdentifier: string): boolean {
		return this.analyticsChannels.hasOwnProperty(channelIdentifier);
	}

	public getChannel(channelIdentifier: string): IAnalyticsChannel {
		if (!this.isChannelRegistered(channelIdentifier)) return;
		return this.analyticsChannels[channelIdentifier];
	}

	public removeChannel(channelIdentifier: string): void {
		if (!this.isChannelRegistered(channelIdentifier)) return;
		delete this.analyticsChannels[channelIdentifier];
	}

	public registerChannel(channel: IAnalyticsChannel): void {
		if (channel === null) return;
		channel.userIdentifier = this._userIdentity;
		this.analyticsChannels[channel.constructor.name] = channel;
	}

	public trackEvent(name: string, payload?: any, description?: string): void;
	public trackEvent(analyticsEvent: AnalyticsEvent): void;
	public trackEvent(param1, param2?, param3?) {
		if (param1 instanceof AnalyticsEvent) {
			this.trackAnalyticsEvent(param1 as AnalyticsEvent);
			return;
		}

		const analyticsEvent = new AnalyticsEvent(param1.toString());
		if (param2) analyticsEvent.payload = param2;
		if (param3) analyticsEvent.description = param3.toString();
		this.trackAnalyticsEvent(analyticsEvent);
	}
	private trackAnalyticsEvent(analyticsEvent: AnalyticsEvent): void {
		if (!analyticsEvent) return;

		const channels = this.analyticsChannels;

		for (const channel of Object.values(channels)) {
			if (!channel.isEnabled) continue;

			channel.trackEvent(analyticsEvent);
		}
	}

	public trackError(analyticsError: AnalyticsError): void {
		if (analyticsError.exception == null) return;

		const channels = this.analyticsChannels;

		for (const channel of Object.values(channels)) {
			if (!channel.isEnabled) continue;
			channel.trackError(analyticsError);
		}
	}
}
