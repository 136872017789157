import { Component, ElementRef } from '@angular/core';
import { DefaultTrendSettings, ArcGISField, Metric, ArcGISAssetChannelAttributes, ChannelTypes, ArcGISHistoryChannelAttributes } from '../../../../../models';
import { OmniValueListService } from '../../../../omni-value-list/omni-value-list.service';
import { FlashMessageService } from '../../../../../app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from '../../../../../app/flash-message/snackbar-message-types.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { FieldType } from 'sedaru-util/esri-core';

@Component({
	selector: 'app-default-trend-settings',
	templateUrl: './default-trend-settings.component.html',
	styleUrls: ['./default-trend-settings.component.scss']
})
export class DefaultTrendSettingsComponent extends MenuPanelBaseComponent {
	settings: DefaultTrendSettings;
	metric: Metric;

	/**
	 * @param {MetricService} metricService - The metric service that will provide the selected metric
	 * @param {OmniValueListService} listService - The list service hold the selectable values in the menu panel
	 * @param {FlashMessageService} flashMessageService - The service used to display proper error message
	 */
	constructor(
		private interopService: OmniInteropService,
		private listService: OmniValueListService,
		private flashMessageService: FlashMessageService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'trend settings' });
		this.settings = this.metric.definition.defaultTrendSettings;
	}

	/**
	 * The function pushes current route to defaulttrendstyle route
	 */
	onGoToDefaultTrendStyle(): void {
		NavigationService.navigateTo(Pages.defaulttrendstyle, { metric: this.metric });
	}

	/**
	 * The function will push to the value list route to show selectable x-axis fields
	 */
	onGoToXAxisField(): void {
		if (!this.metric.definition.source.assetType) {
			return this.flashMessageService.popMessage('Please select a metric source');
		}
		this.listService.list = [];

		let fields = this.interopService.arcGISManager.getArcGisFields(this.metric);

		// Only return the x-axis field that are date type from history source
		fields = fields.filter(xAxisField => xAxisField.type === FieldType.esriFieldTypeDate && xAxisField.omniName.toLowerCase().includes('history'));

		const { defaultTrendSettings } = this.metric.definition;

		if (defaultTrendSettings.yAxisField.name) {
			// If y-axis field is chosen, make sure to filter it out from the selection for x-axis field,
			// We want no duplicates.
			fields = fields.filter(xAxisField => xAxisField.name !== defaultTrendSettings.yAxisField.name);
		}

		for (const xAxisField of fields) {
			this.listService.list.push({
				name: xAxisField.omniName,
				callBackFunction: () => (defaultTrendSettings.xAxisField = xAxisField),
				shouldGoBack: true
			});
		}
		this.listService.selectedItem = defaultTrendSettings.xAxisField.omniName;
		this.listService.header = 'select x-axis field';
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.xAxisField.omniName }, selectedItemKey: 'name' });
	}

	/**
	 * The function will push to the value list route to show selectable y-axis fields
	 */
	onGoToYAxisField(): void {
		this.listService.list = [];

		let fields = this.interopService.arcGISManager.getArcGisFields(this.metric);

		// For the y-axis, only return the fields that are number type.
		fields = fields.filter(
			yAxisField =>
				yAxisField.type === FieldType.esriFieldTypeDouble || yAxisField.type === FieldType.esriFieldTypeInteger || yAxisField.type === FieldType.esriFieldTypeSmallInteger
		);

		const { defaultTrendSettings } = this.metric.definition;

		if (defaultTrendSettings.xAxisField.name) {
			// If x-axis field is chosen, make sure to filter it out from the selection for y-axis field,
			// We want no duplicates.
			fields = fields.filter(yAxisField => yAxisField.name !== defaultTrendSettings.xAxisField.name);
		}

		for (const yAxisField of fields) {
			this.listService.list.push({
				name: yAxisField.omniName,
				callBackFunction: () => (defaultTrendSettings.yAxisField = yAxisField),
				shouldGoBack: true
			});
		}
		this.listService.selectedItem = defaultTrendSettings.yAxisField.omniName;
		this.listService.header = 'select y-axis field';
		NavigationService.navigateTo(Pages.valueList, { selectedItem: { name: this.settings.yAxisField.name }, selectedItemKey: 'name' });
	}
}
