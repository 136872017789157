import { FormControlInputListItem } from './form-control-input-list-item.model';

export class FormControlInputList {
	dataSourceId: number;
	layerIndex: number;
	fieldName: string;

	private _listItems: Array<FormControlInputListItem>;
	get listItems(): Array<FormControlInputListItem> {
		if (!this._listItems) {
			this._listItems = new Array<FormControlInputListItem>();
		}

		return this._listItems;
	}
}
