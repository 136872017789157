<svg xmlns="http://www.w3.org/2000/svg"
	height="40px"
	width="40px"
	viewBox="0 0 192 192"
	[attr.id]="icon.name"
	*ngIf="icon && icon.pathList"
	>
	<circle cx="96" cy="96" r="92" [attr.stroke]="icon.foregroundColor" stroke-width="4"
		[attr.fill]="icon.backgroundColor" />
	<path *ngFor="let path of icon.pathList"
		[attr.fill]="path.isForeground ? icon.foregroundColor : icon.backgroundColor"
		[attr.d]="path.path"
		transform="scale(0.5), translate(64, 64)">
	</path>
</svg>
