// import { gql, Apollo } from 'apollo-angular';
// import { ApolloQueryResult } from '@apollo/client/core';
import {gql, Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import { DataSource } from '../../models/data-source.model';
import { CredentialsType } from '../../models/credentials-type.enum';
import esri = __esri; // Esri TypeScript Types

import { Injectable } from '@angular/core';


import { GetToken } from './arcgis-token.model';
import { UserService } from 'app/user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { InvokableEvent } from 'sedaru-util';
import { DataSourceType } from 'models';
import { TokenManager } from 'sedaru-util/esri-core/token-manager';
import { Credentials } from 'sedaru-util/esri-core';
// import { waitForAsync } from '@angular/core/testing';
import { EsriSdkService } from 'app/canvas-container/canvas-map/esri-sdk.service';

@Injectable({
	providedIn: 'root'
})
export class IdentityManagerService {
	private get esrIdentityManager(): esri.IdentityManager {
		return this.esriSDKService.esriMapSdk.IdentityManager;
	}

	private get urlUtils(): esri.urlUtils {
		return this.esriSDKService.esriMapSdk.urlUtils;
	}

	private serverHitsCount = 0;

	private _onPortalAccessTokenChanged: InvokableEvent;
	public get onPortalAccessTokenChanged(): InvokableEvent {
		if (!this._onPortalAccessTokenChanged) {
			this._onPortalAccessTokenChanged = new InvokableEvent();
		}
		return this._onPortalAccessTokenChanged;
	}

	portalObj: { portalToken: string; refreshToken: string };

	private portalUrls: { portalUrl: string; portalServerUrl: string };

	constructor(private apollo: Apollo, private esriSDKService: EsriSdkService, private userService: UserService, private http: HttpClient) {
		this.portalObj = userService.isPortalEnabled();
		this.portalUrls = { portalUrl: '', portalServerUrl: '' };
	}

	/**
	 * Registers a token to the identity manager to use whenever a server url is hit
	 * @param {DataSource} dataSource - The data source need to be authenticated
	 * @param {string} customerCode - The customer code
	 */
	registerToken(dataSource: DataSource, customerCode: string): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			if (dataSource.proxy && dataSource.url && dataSource.proxy.url) {
				this.urlUtils.addProxyRule({
					urlPrefix: dataSource.url,
					proxyUrl: dataSource.proxy.url + '?SedaruToken=' + this.userService.getToken()
				});
			}

			if (!dataSource.dataSourceCredentials) {
				resolve(false);
				return;
			}

			const server = dataSource.url;

			if (dataSource.dataSourceCredentials.type === CredentialsType.Password) {
				const { token, expires } = dataSource.dataSourceCredentials;
				this.addTokenToEsriIdentityManager(server, token, expires);
				await this.setTimeoutForTokenRenewal(dataSource, expires, customerCode);
			} else if (dataSource.dataSourceCredentials.type === CredentialsType.ArcGISPortalSSOToken) {
				if (this.portalObj && this.portalObj.portalToken) {
					let res: { success: boolean; token: string; expires: number };
					res = await this.checkSSOstatus(dataSource, this.portalObj.portalToken, this.portalObj.refreshToken);

					if (!res.success) {
						this.userService.logout();
					}
					await this.setTimeoutForSSOTokenRenewal(dataSource, res.expires);
				}
			}

			resolve(true);
		});
	}


	/**
	 * Add a token to the esri identity manager.
	 * This will enforce a single token per resource.
	 * @param server
	 * @param token
	 * @param expires
	 */
	private addTokenToEsriIdentityManager(server: string, token: string, expires: number) {
		const identityManager = (<any>this.esrIdentityManager);
		const credentials = identityManager.credentials;
		identityManager.credentials = [];

		this.esrIdentityManager.registerToken({
			server,
			token,
			expires
		});

		if (credentials?.length > 0) {
			// Assumes only one resource per token.
			const credentialToAdd: any[] = [];

			credentials.forEach(credential => {
				const resources: string[] = credential.resources;
				const resourcerIndex = resources.indexOf(server);
				if (resourcerIndex < 0) {
					credentialToAdd.push(credential);
				}
			});
			identityManager.credentials.splice(0, 0, ... credentialToAdd);
		}

	}

	private checkSSOstatus(dataSource: DataSource, portalToken: string, portalRefreshToken: string, refreshTokenInfo?: { access_token: string; expires_in: number }): Promise<any> {
		return new Promise<any>(async resolve => {
			const token = refreshTokenInfo ? refreshTokenInfo.access_token : portalToken;
			const now = new Date().getTime();
			const expiresIn = refreshTokenInfo ? refreshTokenInfo.expires_in : now + 1800 * 1000;

			this.esrIdentityManager.registerToken({
				server: dataSource.url,
				token: token
			});

			this.esrIdentityManager.on('credential-create', async created => {
				const credList: any = this.esrIdentityManager;

				for (let i = 0; i < credList.credentials.length; i++) {
					if (credList.credentials[i].server !== created.credential.server) {
						credList.credentials[i].token = created.credential.token;
						credList.credentials[i].expires = created.credential.expires;
					}
				}

				await this.setTimeoutForSSOTokenRenewal(dataSource, created.credential.expires);
			});

			try {
				const c = await this.esrIdentityManager.checkSignInStatus(dataSource.url);
				this.onPortalAccessTokenChanged.invoke(this, { dataSourceLegacyId: dataSource.legacyId, newAccessToken: c.token });
				resolve({ success: true, newPortalToken: c.token, expires: c.expires });
				return;
			} catch {
				const res = await this.refreshSSOToken(dataSource.url, portalRefreshToken);

				if (res && res.access_token) {
					this.onPortalAccessTokenChanged.invoke(this, { dataSourceLegacyId: dataSource.legacyId, newAccessToken: res.access_token });
					this.setTimeoutForSSOTokenRenewal(dataSource, res.expires_in);
					const r = await this.checkSSOstatus(dataSource, '', '', res);
					resolve(r);
					return;
				} else {
					resolve({ success: false });
					return;
				}
			}
		});
	}

	/**
	 * Gets a new token for the specified data source
	 * @param {DataSource} dataSource the data source to renew the token for
	 * @param {string} customerCode - The customer code
	 */
	private getToken(dataSource: DataSource, customerCode: string): Promise<ApolloQueryResult<GetToken>> {
		const referer = window.location.origin;
		const gqlQuery = gql`
		{
			getToken(dataSourceId: "${dataSource.id}", referer: "${referer}"){
				token
				expires
			}
		}
	`;
		if (customerCode) {
			return this.apollo
				.query<GetToken>({
					query: gqlQuery,
					fetchPolicy: 'no-cache',
					errorPolicy: 'all'
				})
				.toPromise();
		}
	}

	private async getPortalInfo() {
		const infra = environment.infraApiUrl;
		const getPortalInfoUrl = infra + 'MembershipProviderService.svc/' + this.userService.currentUser.customerCode + '/portalsettings';
		const portalInfoRes = await this.http.get<any>(getPortalInfoUrl).toPromise();

		if (!portalInfoRes.Success) {
			return { success: false };
		}

		return { success: true, results: portalInfoRes.Result };
	}

	private async refreshSSOToken(tokenUrl: string, portalRefreshToken: string) {
		const portalInfoRes = await this.getPortalInfo();

		if (!portalInfoRes.success) {
			return { success: false };
		}

		const portalAppId = portalInfoRes.results.WebAppID;
		const portalUrl = portalInfoRes.results.PortalUrl;

		const refreshUrl = portalUrl + '/sharing/rest/oauth2/token?client_id=' + portalAppId + '&refresh_token=' + portalRefreshToken + '&grant_type=refresh_token';
		const newTokenReq = await this.http.post<any>(refreshUrl, null).toPromise();

		if (newTokenReq && newTokenReq.access_token) {
			this.userService.updatePortalToken(newTokenReq.access_token);

			return newTokenReq;
		} else {
			return { success: false };
		}
	}

	private async setTimeoutForSSOTokenRenewal(dataSource: DataSource, tokenExpires: number) {
		/*if (this.serverHitsCount > 2) {
			throw new Error('Token expiration returned less than 5 minutes for:' + dataSourceURL);
		}*/

		const now = new Date().getTime();
		const expirationTimeStamp = tokenExpires;
		const checkInterval = 300000; // 5 mins
		const countDown = expirationTimeStamp ? expirationTimeStamp - now - checkInterval : 0;

		if (countDown > 0) {
			setTimeout(async () => {
				await this.updateSSOTokenInIdentityManager(dataSource);
				console.log('success renew in set time out');
				this.serverHitsCount = 0;
			}, countDown);
		} else {
			this.serverHitsCount++;
			await this.updateSSOTokenInIdentityManager(dataSource);
			console.log('success renew imediate');
		}
	}

	private updateSSOTokenInIdentityManager(dataSource: DataSource): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			try {
				const dataSourceURL = dataSource.url;

				const result = await this.refreshSSOToken(dataSourceURL, this.portalObj.refreshToken);
				const now = new Date().getTime();
				const expirationTimeStamp = now + result.expires_in * 1000;
				const credential = this.esrIdentityManager.findCredential(dataSourceURL);
				credential.token = result.access_token;
				credential.expires = result.expires_in;

				this.onPortalAccessTokenChanged.invoke(this, { dataSourceLegacyId: dataSource.legacyId, newAccessToken: result.access_token });

				this.setTimeoutForSSOTokenRenewal(dataSource, expirationTimeStamp);
				resolve(true);
			} catch (error) {
				console.error(error.message);
				resolve(false);
			}
		});
	}

	/**
	 * Sets a timeOut to renew the token befire it expires
	 * @param {DataSource} dataSource the data source to renew the token for
	 * @param {number} tokenExpires the expiration date of the active token
	 * @param {string} customerCode the customer code
	 */
	async setTimeoutForTokenRenewal(dataSource: DataSource, tokenExpires: number, customerCode: string) {
		/* if (this.serverHitsCount > 2) {
			throw new Error('Token expiration returned less than 5 minutes for:' + dataSource.name);
		}*/

		const now = new Date().getTime();
		const checkInterval = 300000; // 5 mins
		const countDown = tokenExpires ? tokenExpires - now - checkInterval : 0;

		if (countDown > 0) {
			setTimeout(async () => {
				await this.updateTokenInIdentityManager(dataSource, customerCode);
				console.log('success renew in set time out');
				this.serverHitsCount = 0;
			}, countDown);
		} else {
			this.serverHitsCount++;
			await this.updateTokenInIdentityManager(dataSource, customerCode);
			console.log('success renew imediate');
		}
	}

	/**
	 * This function updates the credential token and expires inside the identity manager
	 * @param {DataSource} dataSource - the data source
	 * @param {string} customerCode - the customer code
	 */
	private async updateTokenInIdentityManager(dataSource: DataSource, customerCode: string) {
		try {
			if (!dataSource.dataSourceCredentials) {
				return;
			}

			const credentials = new Credentials(dataSource.dataSourceCredentials.username, dataSource.dataSourceCredentials.password);
			const token = await TokenManager.current.authenticateCredentials(dataSource.url, credentials);
			const credential = this.esrIdentityManager.findCredential(dataSource.url);
			credential.token = credentials.token;
			if (credentials.expiration) {
				credential.expires = credentials.expiration.getTime();
			}

			this.setTimeoutForTokenRenewal(dataSource, credential.expires, customerCode);
		} catch (error) {
			console.log(error);
		}
	}
}
