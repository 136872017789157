import { MapVisibleLayer } from './map-visible-layer.model';

/**
 * This defines the model for the OMNI map layers loaded on the canvas.
 */
export class BasemapVisibleLayer {
	legacyId?: number;
	/**
	 * Name of the map
	 */
	name?: string;

	/**
	 * check whether visible layer are enabled or not.
	 */
	visible: boolean;

	subLayers?: MapVisibleLayer[];

	url: string;
	transparency: number;
	layerType: string;

	constructor() {
		this.visible = true;
	}
}
