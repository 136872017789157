import { MapVisibleLayer } from './map-visible-layer.model';
import { LoadingSpinnerBase } from '../app/ui-components/loading-spinner/loading-spinner.base';
import { BasemapVisibleLayer } from './basemap-visible-layer.model';

/**
 * This defines the model for the OMNI map which will be loaded on the canvas.
 */
export class CanvasMap {
	id?: string;
	/**
	 * Name of the map
	 */
	name: string;
	/**
	 * The base map type selected from the list of base maps made available from ESRI. eg., street, topography etc.
	 */
	baseMapId: string;
	/**
	 * The initial extent for the omni map
	 */
	initialExtent?: string;
	/**
	 * The initial zoom for the omni map
	 */
	initialZoom?: number;
	/**
	 * The layers loaded for the omni map which are visible
	 */
	visibleLayers?: __esri.Sublayer[];
	/**
	 * holds the esri map legend properties
	 */
	legends?: any;
	/**
	 * holds the json string for the sublayers of map
	 */
	subLayers?: string;
	/**
	 * holds the json string for the sublayers of map
	 */
	baseLayers?: string;

	/**
	 * Holds all the event handlers that when creating an "click" on the map
	 */
	eventHandlers?: any = {};

	pacifier?: LoadingSpinnerBase;

	layerViews?: Array<__esri.FeatureLayerView>;

	baseVisibleLayers?: BasemapVisibleLayer[];

	constructor() {
		this.name = '';
		this.baseMapId = 'topo-vector';
		this.layerViews = [];
	}

	initialize?(omniMap: CanvasMap) {
		this.id = omniMap.id;
		this.name = omniMap.name ? omniMap.name : '';
		this.baseMapId = omniMap.baseMapId || omniMap.baseMapId === '' ? omniMap.baseMapId : 'topo-vector';
		if (omniMap.initialExtent && isFinite(omniMap.initialZoom)) {
			this.initialExtent = omniMap.initialExtent;
			this.initialZoom = omniMap.initialZoom;
		}
		this.visibleLayers = omniMap.subLayers ? JSON.parse(omniMap.subLayers) : [];
		this.baseVisibleLayers = omniMap.baseLayers ? JSON.parse(omniMap.baseLayers) : [];
	}

	subLayersDTO?() {
		this.subLayers = this.visibleLayers && this.visibleLayers.length > 0 ? JSON.stringify(this.visibleLayers) : '';
		return this.subLayers;
	}

	baseLayerDTO?() {
		this.baseLayers = this.baseVisibleLayers && this.baseVisibleLayers.length > 0 ? JSON.stringify(this.baseVisibleLayers) : '';
		return this.baseLayers;
	}
}

export interface LayerLegend {
	id: string;
	title: string;
	legendElements: __esri.LegendElement[];
}
