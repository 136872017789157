import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LeftIconOneLineItemResolver } from './left-icon-one-line.resolver';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-left-icon-one-line',
	templateUrl: './left-icon-one-line.component.html',
	styleUrls: ['./left-icon-one-line.component.scss']
})
export class LeftIconOneLineComponent {
	@Input() resolver: LeftIconOneLineItemResolver;
	@Input() item: any;
	@Output() leftIconClicked = new EventEmitter();
	@Output() itemSelected = new EventEmitter();

	@Input() selected = false;

	hovered = false;

	itemComponent = this;

	static createResolver(): LeftIconOneLineItemResolver {
		const resolver = new LeftIconOneLineItemResolver();
		resolver.templateType = ListTemplates.leftIconOneLine;
		return resolver;
	}

	onLeftIconClicked(event: MouseEvent) {
		event.stopPropagation();
		if (this.resolver.isDisabled(this.item)) return;

		this.leftIconClicked.emit(this.item);
	}
}
