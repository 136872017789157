import { Component, ElementRef } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { AlertButton } from './alert-dialog/alert-dialog.component';

/** Used to display ui components that are being developed.
 * Type ?page=test as the url parameter and this page will be rendered.
 * Insert your UI component in the inline template secion.
 * Any method and/or property in this class is just for testing purposes.
 */
@Component({
	selector: 'app-ui-test',
	template: `
		<button mat-button (click)="open()">Open Alert Dialog</button>
	`,
	styles: []
})
export class UITestComponent extends MenuPanelBaseComponent {
	constructor(
		private interopService: OmniInteropService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}
	open() {
		const alertButton: AlertButton[] = [
			{
				text: 'cancel',
				icon: 'assets/x-close.png',
				onClick: this.cancelButtonClick.bind(this)
			},
			{
				text: 'yes',
				icon: 'assets/tick-tranparent.png',
				onClick: this.yesButtonClick.bind(this)
			}
		];
		const alertDialog = this.interopService.uiManager.alertDialog;
		alertDialog.title = 'this is the title';
		alertDialog.mainMessage = { text: 'This is main message' };
		alertDialog.secondaryMessage =  { text: 'This is warning message' };
		alertDialog.alertButtons = alertButton;
		alertDialog.onDismiss = () => {
			alert('alert dialog dismissed');
		}
		alertDialog.open = true;
	}

	cancelButtonClick() {
		alert('cancel button clicked');
	}

	yesButtonClick() {
		alert('yes button clicked');
	}
}
