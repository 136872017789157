import { MetricQuery, QueryStatementList, QueryMode } from './metric-query.model';

export abstract class AGSQuery extends MetricQuery {
	queryStatementList: QueryStatementList;
	mode = QueryMode.builderQuery;
	constructor(initialData?: AGSQuery) {
		super();
		this.queryStatementList = new QueryStatementList();
		if (!initialData) return;
		if (initialData.queryStatementList) {
			this.queryStatementList = QueryStatementList.from(initialData.queryStatementList);
		}
		if ((initialData as any)._queryStatementList) {
			this.queryStatementList = QueryStatementList.from((initialData as any)._queryStatementList);
		}
	}
}
