import { IdentifyTaskResult } from 'app/canvas-container/canvas-map/identify-task-result.model';
import { Feature } from 'sedaru-util/esri-core';

export class ControlGroupHeaderIcon {
	url: string;
	action: (IdentifyTaskResult) => void;
	tooltip: string;
	size: string;
	asset: Feature;
}
