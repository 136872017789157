<mat-card
	class="omni-templated-list-item"
	[class.selected]="selected"
	(click)="!resolver.isDisabled(item) && (selected = !selected) && itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
>
	<div class="content">
		<img
			*ngIf="getTypeOfIcon(resolver.getLeftIcon(item)) === 'string'"
			mat-card-avatar
			[src]="resolver.getLeftIcon(item)"
			(click)="onLeftIconClicked($event)"
			class="left-icon"
		/>
		<app-metric-icon
			*ngIf="getTypeOfIcon(resolver.getLeftIcon(item)) !== 'string'"
			(click)="onLeftIconClicked($event)"
			[icon]="resolver.getLeftIcon(item)"
			[width]="'30px'"
			[height]="'30px'"
			class="left-icon"
		></app-metric-icon>
		<mat-card-title class="omni-templated-list-item-title omni-restrict-overflow s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
			{{ resolver.getLabel(item) }}
		</mat-card-title>
		<img
			mat-card-avatar
			[src]="resolver.getRightIcon(item)"
			(click)="onRightIconClicked($event)"
			[class.invisible]="hovered || !resolver.showRightIconOnHover(item) ? false : true"
			class="right-icon"
		/>
	</div>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-divider"></mat-divider>
