export enum UserTimeframeOptionType {
    Today = 'Today',
    Yesterday = 'Yesterday',
    Tomorrow = 'Tomorrow',
    Last7Days = 'Last7Days',
    Next7Days = 'Next7Days',
    Last30Days = 'Last30Days',
    Next30Days = 'Next30Days',
    Last90Days = 'Last90Days',
    Next90Days = 'Next90Days',
    Last365Days = 'Last365Days',
    Next365Days = 'Next365Days',
    LastWeek = 'LastWeek',
    ThisWeek = 'ThisWeek',
    NextWeek = 'NextWeek',
    WeekToDate = 'WeekToDate',
    RestOfWeek = 'RestOfWeek',
    LastMonth = 'LastMonth',
    ThisMonth = 'ThisMonth',
    NextMonth = 'NextMonth',
    MonthToDate = 'MonthToDate',
    RestOfMonth = 'RestOfMonth',
    LastQuarter = 'LastQuarter',
    ThisQuarter = 'ThisQuarter',
    NextQuarter = 'NextQuarter',
    QuarterToDate = 'QuarterToDate',
    RestOfQuarter = 'RestOfQuarter',
    LastYear = 'LastYear',
    ThisYear = 'ThisYear',
    NextYear = 'NextYear',
    Last2Years = 'Last2Years',
    Next2Years = 'Next2Years',
    Last3Years = 'Last3Years',
    Next3Years = 'Next3Years',
    YearToDate = 'YearToDate',
    RestOfYear = 'RestOfYear',
    LastHour = 'LastHour',
    ThisHour = 'ThisHour',
    NextHour = 'NextHour',
    HourToNow = 'HourToNow',
    RestOfHour = 'RestOfHour',
    MorningToNow = 'MorningToNow',
    RestOfDay = 'RestOfDay',
    Last12Hours = 'Last12Hours',
    Next12Hours = 'Next12Hours',
    Last24Hours = 'Last24Hours',
    Next24Hours = 'Next24Hours',
    Next = 'Next',
    Last = 'Last',
    EarlierThanToday = 'EarlierThanToday',
    TodayAndEarlier = 'TodayAndEarlier',
    TodayAndLater = 'TodayAndLater',
    LaterThanToday = 'LaterThanToday',
    BeforeDate = 'BeforeDate',
    ExactDate = 'ExactDate',
    AfterDate = 'AfterDate',
    DateRange = 'DateRange',
    AllDates = 'AllDates',
    NoDate = 'NoDate',
    ThisX = 'ThisX',
    RestOfX = 'RestOfX',
    XToNow = 'XToNow',
}
