import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ListItemComponentBase } from '../list-item.model';
import { NoIconTwoLinesItemResolver } from './no-icon-two-lines.resolver';
import { ListTemplates } from '../templates.enum';

@Component({
	selector: 'app-no-icon-two-lines',
	templateUrl: './no-icon-two-lines.component.html',
	styleUrls: ['./no-icon-two-lines.component.scss']
})
export class NoIconTwoLinesComponent extends ListItemComponentBase {
	@Input() resolver: NoIconTwoLinesItemResolver;

	@Input() item: any;

	@Output() itemSelected = new EventEmitter();

	@Input() selected = false;

	hovered = false;

	itemComponent = this;

	static createResolver(): NoIconTwoLinesItemResolver {
		const resolver = new NoIconTwoLinesItemResolver();
		resolver.templateType = ListTemplates.noIconTwoLines;
		return resolver;
	}
}
