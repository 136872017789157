<div class="attachments-collection">
	<div class="attachment-cell" *ngFor="let attachment of attachments">
    <app-attachment
      [thumbnailPath]="attachment.thumbnailPath"
      [uploadTimeStamp]="attachment.uploadTimeStamp"
      [attachmentName]="attachment.attachmentName"
      [imagePath]="attachment.imagePath"
      [fileIndexId]="attachment.fileIndexId"
      [isReadOnly]="isReadOnly"
      (removeItem)="itemToRemove.emit($event)"
    ></app-attachment>
	</div>
</div>
