import { Component, OnInit, Input, ContentChild } from '@angular/core';

@Component({
	selector: 'app-three-component-navs',
	templateUrl: './three-component-navs.component.html',
	styleUrls: ['./three-component-navs.component.scss']
})
export class ThreeComponentNavsComponent implements OnInit {
	@ContentChild('component1') component1: Component;
	@Input() tabHeader1: string;
	@ContentChild('component2') component2: Component;
	@Input() tabHeader2: string;
	@Input() tabHeader2badge: string;
	@ContentChild('component3') component3: Component;
	@Input() tabHeader3: string;
	@Input() tabHeader3badge: string;
	@Input() activeTab: string;

	get currentTabHeader(): string {
		let currentTab = this.tab1;
		if (this.tab2.active) currentTab = this.tab2;
		else if (this.tab3.active) currentTab = this.tab3;
		return currentTab.header;
	}

	tab1: { header: string; active: boolean };
	tab2: { header: string; active: boolean };
	tab3: { header: string; active: boolean };
	loaded = false;

	constructor() {}

	/** sets the first component as the actvie one by default */
	ngOnInit() {
		this.tab1 = { header: this.tabHeader1, active: this.tabHeader1 === this.activeTab };
		this.tab2 = { header: this.tabHeader2, active: this.tabHeader2 === this.activeTab };
		this.tab3 = { header: this.tabHeader3, active: this.tabHeader3 === this.activeTab };
		setTimeout(() => {
			this.loaded = true;
		}, 400);
	}

	/** sets the given tab to active */
	navigateToTab(tabNumber: number) {
		switch (tabNumber) {
			case 1:
				this.tab1.active = true;
				this.tab2.active = false;
				this.tab3.active = false;
				break;
			case 2:
				this.tab1.active = false;
				this.tab2.active = true;
				this.tab3.active = false;
				break;
			default:
				this.tab1.active = false;
				this.tab2.active = false;
				this.tab3.active = true;
				break;
		}
	}

	resetChildren() {
		if ((this.component1 as any).ngOnInit) (this.component1 as any).ngOnInit();
		if ((this.component2 as any).ngOnInit) (this.component2 as any).ngOnInit();
		if ((this.component3 as any).ngOnInit) (this.component3 as any).ngOnInit();
	}
}
