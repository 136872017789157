export class MetricIconPath {
	path: string;
	isForeground?: boolean;
}

/**
 * This defines the svg asset icons for the metric
 */
export class MetricIcon {
	/**
	 * uuid for each metric icon.
	 */
	id?: string;

	/**
	 * Holds the name of asset icon.  It should match the SVG file name.
	 */
	name: string;

	/**
	 * holds the background color of metric icon in Hexidecimal
	 */
	backgroundColor?: string;

	/**
	 * holds the foreground color of metric icon in Hexidecimal
	 */
	foregroundColor?: string;

	/**
	 * List of paths that will be drawn via SVG.  "isForeground" variable can be used to determine if the path should
	 * have a forground or background color.
	 */
	pathList?: MetricIconPath[];

	/**
	 * URL used by ESRI ArcGIS Maps to display the icon in a graphics layer.
	 */
	url?: string;

	/**
	 * Image binary generated from SVG and used to obtain a valid URL for use in an ESRI ArcGIS Map.
	 */
	blob?: Blob;

	/**
	 * Image which converted as Base64 string which used for preserving on db
	 */
	base64?: string;

	/**
	 * This constructor loads and injects dependencies and initializes a default empty value for metric icon.
	 */
	constructor(metricIcon?: MetricIcon) {
		if (metricIcon) {
			this.name = metricIcon.name;
			this.backgroundColor = metricIcon.backgroundColor;
			this.foregroundColor = metricIcon.foregroundColor || '';
			this.pathList = metricIcon.pathList || null;
			this.url = metricIcon.url || '';
		}
	}

	/**
	 * Initializes a default values for metric icon from the GUI config and generate base64 string to it.
	 * @param {MetricIcon} merticIcon - Icon which generated from SVG to have the blob and URL attached to.
	 */
	initialize?(merticIcon: MetricIcon): void {
		this.id = merticIcon.id;
		this.name = merticIcon.name;
		this.backgroundColor = merticIcon.backgroundColor;
		this.foregroundColor = merticIcon.foregroundColor;
		this.pathList = merticIcon.pathList;
		this.base64 = merticIcon.base64;
		if (this.base64) {
			this.generateIconBlobUrlFromBase64();
		}
		if (!this.url) this.url = merticIcon.url;
	}

	/**
	 * Convert the base64 encode string to Blob to show upon on the map
	 * @param {string} base64Data - Icon to have the blob and URL attached to.
	 * @param {string} contentType - content type to convert, deafult it will be svg string
	 * @param {number} sliceSize - array buffer size for convertion of base64 data.
	 */
	convertBase64toBlob?(base64Data, contentType = 'image/svg+xml', sliceSize = 512): Blob {
		const byteCharacters = atob(base64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });

		return blob;
	}

	/**
	 * Create the icon URL and blob from base64 string
	 */
	generateIconBlobUrlFromBase64?(): void {
		this.blob = this.convertBase64toBlob(this.base64);
		this.url = URL.createObjectURL(this.blob);
	}

	/**
	 * Create the icon URL, blob and base64 string
	 * @param {string} svg - The Icon SVG that will be used to generate the blob and URL
	 */
	generateUrlWithBase64?(svg: string): void {
		if (this.url) {
			URL.revokeObjectURL(this.url);
		}
		this.blob = new Blob([svg], { type: 'image/svg+xml' });
		this.url = URL.createObjectURL(this.blob);
		if (this.blob) {
			const reader = new FileReader();
			reader.readAsDataURL(this.blob);
			reader.onloadend = () => {
				const base64String = reader.result;
				this.base64 = (<string>base64String).split(',')[1];
			};
		}
	}
}
