import { InvokableEvent } from 'sedaru-util';
import { SearchController, SearchControllerBase } from 'sedaru-util/generic-search/search-controller';
import { SearchBase, Search } from 'sedaru-util/generic-search/search';

export class ContextualSearchBase {
	private _onEnabledChanged: InvokableEvent;
	public get onEnabledChanged(): InvokableEvent {
		if (!this._onEnabledChanged) this._onEnabledChanged = new InvokableEvent();
		return this._onEnabledChanged;
	}

	public get isEnabled(): boolean {
		return this._isEnabled;
	}
	public set isEnabled(value: boolean) {
		if (this._isEnabled === value) return;
		this._isEnabled = value;

		this.onEnabledChanged.invoke(this, this._isEnabled);
	}

	private _title = '';
	public get title(): string {
		if (!this._title) {
			return 'contextual search';
		}

		return this._title;
	}
	public set title(value: string) {
		if (!value) {
			this._title = '';
			return;
		}

		this._title = value;
	}

	private _navigateBack = false;
	public get navigateBack(): boolean {
		return this._navigateBack;
	}
	public set navigateBack(value: boolean) {
		if (this._navigateBack === value) return;

		this._navigateBack = value;
	}

	public get search(): SearchBase {
		return this._search;
	}

	public getSearch<T>(): Search<T> {
		return this.search as Search<T>;
	}

	constructor(private _search: SearchBase, private _isEnabled: boolean = true) {}
}

export class ContextualSearch<T> extends ContextualSearchBase {
	constructor(fullList: Array<T>, searchFunction: (T, string) => boolean, isEnabled: boolean = true) {
		const controller = new SearchController<T>(fullList, searchFunction);
		const search = new Search<T>(controller);
		super(search, isEnabled);
	}

	get results(): Array<T> {
		const search = this.search as Search<T>;
		if (!search) return new Array<T>();

		return search.results;
	}

	reloadRecords(fullList: Array<T>) {
		const search = this.search as Search<T>;
		search.controller.reloadList(fullList);
	}
}
