import { Component, ElementRef, ViewChild } from '@angular/core';
import { CanvasMapService } from 'app/canvas-container/canvas-map/canvas-map.service';
import { OmniMapGraphicsService } from 'app/canvas-container/canvas-map/omni-map-graphics.service';
import { ContextualSearch } from 'app/menu-panel/menu-panel-base/contextual-search';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ChannelTypes, Metric, MetricTile } from 'models';
import { AssetRecord } from 'models/records/asset-record.model';
import { HistoryRecord } from 'models/records/history-record.model';
import { MetricRecord } from 'models/records/metric-record.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { AdvancedWorkOrder, AdvancedWorkOrders, WorkOrderSummary, WorkOrderWrapper } from 'models/work-order';
import { StandardWorkOrder } from 'models/work-order/standard-work-order.model';
import { Subscription } from 'rxjs';
import { Feature } from 'sedaru-util/esri-core';
import { ArcGisFeature } from '../arc-gis-feature.model';
import { CanvasMode } from 'omni-model/canvas.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { TimeframedMetric } from 'models/time-frame/timeframed-metric.model';
import { TimeframedMetricSubscriber } from 'models/time-frame/timeframed-metric-subscriber';
import { TimeframeFilter } from 'models/time-frame/timeframe-filter.model';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { PNGManager } from 'app/ui-components/png-manager';
import { MapActionType, MetricViewEventArg, TableActionType } from '../metric-view-event-arg.model';
import { MetricViewService } from '../metric-view.service';
import { AlertDialogComponent } from 'app/ui-components/alert-dialog/alert-dialog.component';
import { FlashMessageService } from 'app/flash-message/flash-message.service';

@Component({
	selector: 'app-metric-results',
	templateUrl: './metric-results.component.html',
	styleUrls: ['./metric-results.component.scss']
})
export class MetricResultsComponent extends MenuPanelBaseComponent {
	@ViewChild('scrollingContainer', { static: true }) scrollingContainer: ElementRef;
	timeframedMetric: TimeframedMetric;
	timeframedMetricSubscriber: TimeframedMetricSubscriber;
	timeframeFilter: TimeframeFilter;
	displayedRecordCount: number;
	private initialRecordCount = 50;
	layerHighlightHandler;
	metric: Metric;
	activeTile: MetricTile;
	highlightPromise: Promise<any>;
	mode: 'selected-from-map' | 'from-tile' = 'selected-from-map';
	activeExtent: __esri.Extent;
	get isAWO() {
		if (!this.metric) return false;
		if (this.metric.definition.source.type !== ChannelTypes.WorkOrder) return false;
		return this.workOrderFactory.isAdvancedMode;
	}
	needsToScroll = false;
	scrollTimer;
	mapExtentChangeSubscription: Subscription;
	private _allMetricResults;
	private _filterByExtentActive = false;
	private selectedFeature: any;
	get metricResults(): Array<any> {
		return this.getContextualSearchItems<any>();
	}
	get alertDialog(): AlertDialogComponent {
		return this.interopService.uiManager.alertDialog;
	}

	constructor(
		private graphicsService: OmniMapGraphicsService,
		private interopService: OmniInteropService,
		private workOrderFactory: WorkOrderFactory,
		private mapService: CanvasMapService,
		private metricViewService: MetricViewService,
		private flashMessageService: FlashMessageService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	ngOnInit(): void {
		if (this.uiState && this.uiState.scrollPosition) this.needsToScroll = true;
		if (this.selectedFeature) return this.setSelectedRecord(this.selectedFeature);
		this.displayedRecordCount = this.initialRecordCount;
		const headerParams = {
			title: this.metric.definition.name,
			rightIcon: { url: 'assets/map-extent.png', toolTip: '', callBackFunction: this.onFilterByExtentButtonClicked.bind(this) },
			disableRightButton: true
		};
		if (this.metric.definition.source.type === ChannelTypes.WorkOrder) {
			headerParams['backgroundClass'] = 'orange-background';
		}
		this.menuPanelComponent.updateView(headerParams);
		this.setMetricSubscriber();
		if (this.activeExtent) {
			this.getMetricResultsFromExtent(this.activeExtent);
			return;
		}
		this.getMetricResultsForTile();
		if (this.uiState?.activeExtent) this.moveToPreviousExtent();
	}

	setMetricSubscriber() {
		if (this.timeframedMetricSubscriber) {
			this.timeframedMetricSubscriber.unsubscribe();
			this.interopService.metricManager.removeTimeframedMetricsNotInUse();
		}
		this.timeframedMetricSubscriber = this.interopService.metricManager.getMetricSubscriber(this.metric, this.timeframeFilter);
		this.timeframedMetricSubscriber.outfields = this.interopService.templateManager.getTemplateOutFields(this.metric);
		this.timeframedMetricSubscriber.onMetricUpdated = () => {
			this.getMetricResultsForTile();
		};
		this.timeframedMetric = this.timeframedMetricSubscriber.timeframedMetric;
	}

	ngOnDestroy() {
		if (this.layerHighlightHandler) this.layerHighlightHandler.remove();
		this.closePopUp();
		if (this.scrollTimer) clearInterval(this.scrollTimer);
		if (this.mapExtentChangeSubscription) this.mapExtentChangeSubscription.unsubscribe();
		if (this.timeframedMetricSubscriber) this.timeframedMetricSubscriber.unsubscribe();
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.commonOnPageNavigated(args);
	}
	onPageReload(args: NavigationArgs) {
		if (this.activeTile  && args?.parameter?.tile === this.activeTile) return;
		(this.contextualSearch as ContextualSearch<any>).reloadRecords([]);
		this.timeframedMetricSubscriber?.unsubscribe();
		this.ngOnDestroy();
		this.commonOnPageNavigated(args);
		this.uiState = undefined;
		this.ngOnInit();
	}
	/** common logic when page is navigated to or reloaded */
	private commonOnPageNavigated(args: NavigationArgs) {
		this.metric = undefined;
		this.activeExtent = undefined;
		this.selectedFeature = undefined;
		this._allMetricResults = [];

		if (args.parameter.mode) {
			this.mode = args.parameter.mode;
		}
		if (args.parameter.extent) {
			this.activeExtent = args.parameter.extent;
		}

		this.activeTile = args.parameter.tile;

		this.metric = args.parameter?.metric ?? this.activeTile?.metric;

		this.timeframeFilter = this.activeTile?.uiTimeframeFilter ?? this.metric.definition.timeFrameFilter;

		if (args.parameter.selectedFeature && !args.isNavigatingBack) this.selectedFeature = args.parameter.selectedFeature;

		if (!args.isNavigatingBack) this.contextualSearch.search.clear();
		this.contextualSearch.navigateBack = args.isNavigatingBack;

		if (args.searchTerm) {
			this.contextualSearch.search.searchText = args.searchTerm;
		}
	}

	onScrollStepReached() {
		this.displayedRecordCount += this.initialRecordCount;
	}
	onScroll() {
		if (!this.uiState) this.uiState = { scrollPosition: 0 };
		this.uiState.scrollPosition = this.scrollingContainer.nativeElement.scrollTop;
	}
	scrollToPosition(position: number) {
		this.scrollTimer = setInterval(() => {
			this.scrollingContainer.nativeElement.scrollTo(0, position);
			if (this.scrollingContainer.nativeElement.scrollTop >= position) {
				clearInterval(this.scrollTimer);
				this.scrollTimer = undefined;
			}
		}, 400);
	}

	/** when coming back from another page */
	moveToPreviousExtent() {
		const extent = this.uiState.activeExtent;
		this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.moveToExtent(extent);
		this.onFilterByExtentButtonClicked();
	}

	onFilterByExtentButtonClicked() {
		this._filterByExtentActive = !this._filterByExtentActive;
		if (this._filterByExtentActive) {
			if (this.layerHighlightHandler) this.layerHighlightHandler.remove();
			this.menuPanelComponent.updateSubTitle('current map view');
			this.menuPanelComponent.updateRightIcon({url: 'assets/map-extent_sel.png' });
			this.getMetricResultsFromExtent(this.config.selectedCanvas.mapView.extent);
			this.mapExtentChangeSubscription = this.mapService.mapExtentChangeObservable$.subscribe((mapViewExtent: any) => {
				this.getMetricResultsFromExtent(mapViewExtent.extent);
			});
		} else {
			if (this.uiState ) this.uiState.activeExtent = undefined;
			this.menuPanelComponent.updateSubTitle('');
			this.menuPanelComponent.updateRightIcon({ url: 'assets/map-extent.png' });
			this.mapExtentChangeSubscription.unsubscribe();
			this.getMetricResultsForTile();
		}
	}

	/** populates the list of metric results filtered by an extent */
	getMetricResultsFromExtent(extent: __esri.Extent) {
		if (this._filterByExtentActive) {
			if (!this.uiState) this.uiState = { activeExtent: null };
			this.uiState.activeExtent = extent;
			this.menuPanelComponent.updateSubTitle('current map view');
		}

		this.menuPanelComponent.disableRightButton = true;
		this.menuPanelComponent.updatePacifier(true);
		this._allMetricResults = [];
		const metricType = this.metric.definition.source.type;
		const assetType = this.metric.definition.source.assetType;
		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		const layerId = this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.getTabLayerId()
		const layer = this.config.selectedCanvas.mapView.map.layers.find(l => l.id === layerId) as __esri.FeatureLayer;
		const query = layer.createQuery();
		query.geometry = extent;
		query.where = '1=1';
		query.outFields = ['*'];
		layer.queryFeatures(query).then(result => {
			if (!result) {
				this.removePacifiers();
				return;
			}

			if (!result.features || !result.features.length) {
				(this.contextualSearch as ContextualSearch<any>).reloadRecords([]);
				this.menuPanelComponent.disableRightButton = false;
				this.removePacifiers();
				return;
			}
			if (!this._filterByExtentActive) this.highlightSelectedFeatures(result.features, layerId);

			let features = [];
			if (this.isAWO) {
				features = AdvancedWorkOrders.fromBasicDataContracts(result.features.map(f => f.attributes));
			} else {
				features = result.features;
				if (metricType === ChannelTypes.WorkOrder) {
					features = result.features.map(f => (f.attributes ? f.attributes : f));
				}
			}
			features.forEach(f => {
				const metricRecord = new MetricRecord();
				const context = new RecordContextGroup();
				if (metricType === ChannelTypes.Asset) {
					const feature = Feature.fromEsriFeature(f);
					const assetRecord = AssetRecord.create(assetDefinition, feature);
					context.assetRecord = assetRecord;
				}
				if (metricType === ChannelTypes.History) {
					const feature = Feature.fromEsriFeature(f);
					const assetRecord = AssetRecord.create(assetDefinition, feature); // using the same feature because this feature comes with attributes from history and asset
					const historyRecord = HistoryRecord.create(assetDefinition, feature);
					context.historyRecord = historyRecord;
					context.assetRecord = assetRecord;
				}
				if (metricType === ChannelTypes.WorkOrder) {
					context.workOrderRecord = f;
				}
				metricRecord.contextGroup = context;
				metricRecord.resolver = this.interopService.templateManager.getTemplateResolver(context, this.metric?.id);
				metricRecord.resolver.showDivider = item => true;
				metricRecord.resolver.onIsDisabled = item => false;

				this._allMetricResults.push(metricRecord);
			});
			(this.contextualSearch as ContextualSearch<any>).reloadRecords(this._allMetricResults);
			this.removePacifiers();
			this.menuPanelComponent.disableRightButton = false;
		});
	}

	setSelectedRecord(_feature: __esri.Graphic) {
		const metricType = this.metric.definition.source.type;
		const assetType = this.metric.definition.source.assetType;
		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		const selectedRecord = new MetricRecord();
		const context = new RecordContextGroup();
		if (metricType === ChannelTypes.Asset) {
			const feature = Feature.fromEsriFeature(_feature);
			const assetRecord = AssetRecord.create(assetDefinition, feature);
			context.assetRecord = assetRecord;
		}
		if (metricType === ChannelTypes.History) {
			const feature = Feature.fromEsriFeature(_feature);
			const assetRecord = AssetRecord.create(assetDefinition, feature); // using the same feature because this feature comes with attributes from history and asset
			const historyRecord = HistoryRecord.create(assetDefinition, feature);
			context.historyRecord = historyRecord;
			context.assetRecord = assetRecord;
		}
		if (metricType === ChannelTypes.WorkOrder) {
			context.workOrderRecord = _feature.attributes;
			context.workOrderRecord['geometry'] = _feature.geometry ? _feature.geometry : null;
		}
		selectedRecord.contextGroup = context;
		selectedRecord.resolver = this.interopService.templateManager.getTemplateResolver(context, this.metric?.id);
		selectedRecord.resolver.showDivider = item => true;
		selectedRecord.resolver.onIsDisabled = item => false;
		this.selectMetricRecord(selectedRecord, false);
	}

	async getMetricResultsForTile() {
		const features = this.timeframedMetric.results;
		if (features === undefined || this.timeframedMetric.updatedRequested) {
			this.menuPanelComponent.updatePacifier(true);
			return;
		}

		const metricType = this.metric.definition.source.type;

		this._allMetricResults = [];
		(this.contextualSearch as ContextualSearch<any>).reloadRecords(this._allMetricResults);
		if (!features || !features.length) {
			this.removePacifiers();
			return;
		}

		const assetType = this.metric.definition.source.assetType;
		const assetDefinition = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		await this.preCacheIconResource(metricType, assetDefinition);
		const metricRecords = features.map(f => {
			const metricRecord = new MetricRecord();
			const context = new RecordContextGroup();
			if (metricType === ChannelTypes.Asset) {
				const feature = Feature.fromEsriFeature(f);
				const assetRecord = AssetRecord.create(assetDefinition, feature);
				context.assetRecord = assetRecord;
			}
			if (metricType === ChannelTypes.History) {
				const feature = Feature.fromEsriFeature(f);
				const assetRecord = AssetRecord.create(assetDefinition, feature); // using the same feature because this feature comes with attributes from history and asset
				const historyRecord = HistoryRecord.create(assetDefinition, feature);
				context.historyRecord = historyRecord;
				context.assetRecord = assetRecord;
			}
			if (metricType === ChannelTypes.WorkOrder) {
				if (this.isAWO) {
					context.workOrderRecord = f;
				} else {
					context.workOrderRecord = f.attributes;
					context.workOrderRecord['geometry'] = f.geometry;
				}
			}
			metricRecord.resolver = this.interopService.templateManager.getTemplateResolver(context, this.metric?.id);
			metricRecord.resolver.showDivider = item => true;
			metricRecord.resolver.onIsDisabled = item => false;
			metricRecord.contextGroup = context;
			return metricRecord;
		});
		/** TEMPORARY
		 * this code hard codes the sort order of work orders
		 */
		if (metricType === ChannelTypes.WorkOrder) {
			metricRecords.sort((mrA, mrB) => {
				const createdDateA = mrA.contextGroup.workOrderRecord.completedDate ?? mrA.contextGroup.workOrderRecord.startDate ?? 0;
				const createdDateB = mrB.contextGroup.workOrderRecord.completedDate ?? mrB.contextGroup.workOrderRecord.startDate ?? 0;
				return createdDateB - createdDateA;
			});
		} else if (metricType === ChannelTypes.History) {
			// sorting for histroy records with workdate desc
			const completedDateField =  assetDefinition.historyChannel.attributes['completedDateFieldName'];
			if (completedDateField) {
				metricRecords.sort((mrA, mrB) => {
					const createdDateA: any = mrA.feature.attributes[completedDateField];
					const createdDateB: any = mrB.feature.attributes[completedDateField];
					return createdDateB - createdDateA;
				});
			}
		}

		this._allMetricResults.push(...metricRecords);

		(this.contextualSearch as ContextualSearch<any>).reloadRecords(this._allMetricResults);
		if (this.needsToScroll) this.scrollToPosition(this.uiState.scrollPosition);
		this.removePacifiers();
		this.menuPanelComponent.disableRightButton = false;

		const tableComponent = this.interopService.uiManager.activeCanvasComponent.canvasTableComponent;
		if (tableComponent) {
			tableComponent.clearHighlights();
			tableComponent.clearHighlightRecord();
		}
	}

	async preCacheIconResource(metricType, assetDefinition) {
		if (metricType === ChannelTypes.Asset || metricType === ChannelTypes.History) {
			await PNGManager.editImage(assetDefinition.iconResource.url, {
				width: 25,
				height: 25,
				backgroundColor: assetDefinition.style.layout.background.color
			});
		}
	}

	highlightSelectedFeatures(features: __esri.Graphic[], layerId: string) {
		if (this.layerHighlightHandler) this.layerHighlightHandler.remove();
		const layerView = this.config.selectedCanvas.map.layerViews.find(lv => {
			if (lv.layer) return lv.layer.id === layerId;
		});
		this.layerHighlightHandler = layerView.highlight(features);
	}

	private removePacifiers() {
		this.menuPanelComponent.updatePacifier(false);
		this.menuPanelComponent.updateRightIcon({disable: false});
		if (this.interopService.uiManager.activeCanvasComponent.canvasMapComponent) this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.loadingPacifier.show = false;
	}

	showPopUpOnFeature(metricRecord: MetricRecord) {
		const mapComponent = this.interopService.uiManager.activeCanvasComponent.canvasMapComponent;
		if (!mapComponent) return;
		const feature = metricRecord.contextGroup.workOrderRecord ? metricRecord.contextGroup.workOrderRecord : metricRecord.feature;
		mapComponent.mapTooltip.location = feature.geometry;
		const cloneDeep = require('clone');
		const resolver = cloneDeep(metricRecord.resolver);
		mapComponent.mapTooltip.resolver = resolver;
		mapComponent.mapTooltip.resolver.showDivider = item => false;
		mapComponent.mapTooltip.resolver.onIsDisabled = item => true;
		mapComponent.mapTooltip.open();
	}
	closePopUp() {
		// TODO: If a record is selected, we don't close the tooltip,
		// so what is the purpose of this method?
		if (this.selectedFeature) return;
		if (this.config.selectedCanvas.mode !== CanvasMode.map) return;
		if (this.interopService.uiManager.activeCanvasComponent && this.interopService.uiManager.activeCanvasComponent.canvasMapComponent) {
			// this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip.close();
		}
		const tableComponent = this.interopService.uiManager.activeCanvasComponent.canvasTableComponent;
		if (tableComponent) tableComponent.clearHighlights();
	}

	async highlightFeatureOnHover(metricRecord: MetricRecord) {
		const isHighlightLayer = !this.layerHighlightHandler;
		const metricEventArg: MetricViewEventArg = new MetricViewEventArg();
		metricEventArg.source = 'MetricResult-Hover';
		metricEventArg.tableAction = TableActionType.Highlight;
		if (isHighlightLayer) {
			metricEventArg.mapAction = MapActionType.Highlight;
		}

		await this.metricViewService
				  .triggerMetricEvent(this.metric, metricRecord, metricEventArg);

		return;
	}

	async selectMetricRecord(incompleteRecord: MetricRecord, zoomIn = true) {

		this.menuPanelComponent.updatePacifier(true);
		const metricEventArg: MetricViewEventArg = new MetricViewEventArg();
		metricEventArg.source = 'MetricResult-Select';
		metricEventArg.showInSidePanel = true;
		metricEventArg.tableAction = TableActionType.Highlight;
		if (zoomIn) {
			metricEventArg.mapAction = MapActionType.ZoomOnItem;
		} else {
			metricEventArg.mapAction = MapActionType.Highlight;
		}

		await this.metricViewService
				.triggerMetricEvent(this.metric
							 , incompleteRecord
							 , metricEventArg
							 , t => this.menuPanelComponent.updatePacifier(t));
		this.selectedFeature = incompleteRecord;
		return;
	}

	goToBulkEdit() {
		let parameter: WorkOrderWrapper[];
		const tableFields = this.workOrderFactory?.getArcGISFields();
		if (!this.isAWO) parameter = this.metricResults.map(mr => new WorkOrderWrapper(StandardWorkOrder.fromFeature(mr.feature, tableFields)));
		else parameter = this.metricResults.map(mr => new WorkOrderWrapper(mr.feature as AdvancedWorkOrder));

		if (!parameter) return;
		NavigationService.navigateTo(Pages.workorderSummary, {
			workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.EditMultipleWO, parameter)
		});
	}

	async goToBulkCreate() {
		if (this.workOrderFactory.isAdvancedMode) {
			this.alertDialog.mainMessage = { text: 'Feature coming soon!' };
			this.alertDialog.open = true;
			return;
		}

		const metricUniqueFieldName = this.metric.getIdFieldName();
		const assetRecords = this.metricResults.map(mr => mr?.contextGroup?.assetRecord);
		if (!assetRecords || !assetRecords.length) return;

		const distinctAssetRecords: AssetRecord[] = [];
		assetRecords.forEach(record => {
			const indexOfRecord = distinctAssetRecords.findIndex(r => r.feature.attributes[metricUniqueFieldName] === record.feature.attributes[metricUniqueFieldName]);
			if (indexOfRecord < 0) {
				distinctAssetRecords.push(record);
			}
		});

		const workOrderWrappers = new Array<WorkOrderWrapper>();
		this.menuPanelComponent.updatePacifier(true);
		try {
			const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey(distinctAssetRecords.length);
			if (!workOrderKeys || workOrderKeys.length != distinctAssetRecords.length) {
				throw new Error();
			}
			for (let i = 0; i < distinctAssetRecords.length; i++) {
				if (!distinctAssetRecords[i]) continue;
				workOrderWrappers.push(this.workOrderFactory.createWorkOrderModel(workOrderKeys[i], distinctAssetRecords[i]));
			}

			NavigationService.navigateTo(Pages.workorderSummary, {
				workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.CreateMultipleWO, workOrderWrappers, distinctAssetRecords)
			});
		} catch (e) {
			this.flashMessageService.popMessage('Cannot create workorder. No valid workorder key. Please try again.');
		} finally {
			this.menuPanelComponent.updatePacifier(false);
		}
	}

	onCreateContextualSearch() {
		let contextualSearch;

		contextualSearch = new ContextualSearch<any>(this._allMetricResults, (metricResult: MetricRecord, text: string) => {
			const fields = this.interopService.templateManager.getTemplateSearchFields(this.metric, metricResult.feature);
			for (let index = 0; index < fields.length; index++) {
				const field = fields[index];
				const actualValue = metricResult.feature.attributes ? metricResult.feature.attributes[field] : metricResult.feature[field];
				if (!actualValue) continue;
				if (typeof actualValue === 'string' && actualValue.toLowerCase().includes(text.toLowerCase())) return true;
				if (
					actualValue
						.toString()
						.toLowerCase()
						.includes(text.toLowerCase())
				) {
					return true;
				}
			}

			return false;
		});

		return contextualSearch;
	}

	onTileTimeframeChanged(tileComponent: TileComponent) {
		this.metric = tileComponent.tile.metric;
		this.timeframeFilter = tileComponent.getTileTimeFrameFilter();
		this.setMetricSubscriber();
		this.getMetricResultsForTile();
	}

	onMetricUpdated(metricId: string) {
		if (metricId === this.metric.id) return;
		this.setMetricSubscriber();
		return this.getMetricResultsForTile();
	}
}
