import { Operator, UnitOperator } from 'models';
import { TimeframeDefinition } from './timeframe-definition.model';
import { TimeframeQueryValue } from './timeframe-query-value';

/**
 * The TimeframeField model
 */
export class TimeframeField {
	timeframeOption;
	isNew: boolean;
	index: number;
	operator: UnitOperator;
	value: TimeframeQueryValue;
	join: Operator;
	timeframeDefinition: TimeframeDefinition;
	initialize?(timeframeField: TimeframeField) {
		if (!timeframeField) return;
		if (timeframeField.index !== undefined) {
			this.index = timeframeField.index;
		}
		if (timeframeField.timeframeDefinition) {
			this.timeframeDefinition = timeframeField.timeframeDefinition;
		}
		if (timeframeField.timeframeOption) {
			this.timeframeOption = timeframeField.timeframeOption;
		}
		this.operator = new UnitOperator();
		if (timeframeField.operator) {
			this.operator.name = timeframeField.operator.name;
			this.operator.value = timeframeField.operator.value;
		}
		this.value = new TimeframeQueryValue();
		if (timeframeField.value) {
			this.value.code = timeframeField.value.code;
			this.value.name = timeframeField.value.name;
		}
		if (timeframeField.join) {
			this.join = new Operator();
			this.join.name = timeframeField.join.name;
			this.join.value = timeframeField.join.value;
		}
		return this;
	}
}
