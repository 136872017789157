import { Color } from 'models';

export class OmniSnackBarActionButton {
	label: string;
	textColor: Color;
	backgroundColor: Color;
	onClick: () => void;
	constructor() {
		this.textColor = Color.createFromRGBA('rgba(255,255,255,0.7)');
		this.backgroundColor = Color.createFromRGBA('rgba(255,255,255,0)');
	}
}
