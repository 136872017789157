import { PacifierResolver } from '../pacifier-resolver.base';
import { PacifierType } from '../pacifier-types.enum';
import { Color } from 'models';

export class FingersDrummingResolver extends PacifierResolver {
	type = PacifierType['fingers-drumming'];
	scale = 1;
	color: Color;
	animationDuration = 2;
	constructor() {
		super();
		this.color = Color.fromHex('#ffffff');
	}
}
