import { AnalyticsItem } from './analytics-item';

export class AnalyticsEvent extends AnalyticsItem {
	static trackEvent: (event: AnalyticsEvent) => void;

	public name: string;
	public description: string;

	constructor(name: string) {
		super();
		if (!name) throw new Error(name + ' cannot be empty.');
		this.name = name;
	}

	public static create(name, payload: any = null, description = ''): AnalyticsEvent {
		const analyticsEvent = new AnalyticsEvent(name);
		analyticsEvent.description = description;
		analyticsEvent.payload = payload;

		return analyticsEvent;
	}

	protected onSubmit(): void {
		if (!AnalyticsEvent.trackEvent) return;

		AnalyticsEvent.trackEvent(this);
	}
}
