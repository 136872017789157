import { ListTemplates } from './templates.enum';

export abstract class ListItemResolver {
	templateType: ListTemplates;
	onIsDisabled: (item: any) => boolean;
	onGetAlignment: (item: any) => string;
	isDisabled(item: any): boolean {
		if (!this.onIsDisabled) return false;
		return this.onIsDisabled(item);
	}
	getAlignment(item: any): string {
		if (!this.onGetAlignment) return 'left';
		return this.onGetAlignment(item);
	}
	showDivider(item: any) {
		return true;
	}
	resetFlags(): void { }
}
