declare var $: any;

export class ClientHubBase {
	private _connection: any;
	private _hubConnection: any;

	private stateConversion = { 0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected' };

	private _url: string;
	get url() {
		return this._url;
	}

	private _queryString: string;
	private _hubName: string;

	protected get connectionId(): string {
		if (!this._connection || !this._connection.hub) return undefined;

		return this._connection.hub.id;
	}

	public get isConnected(): boolean {
		if (!this._hubConnection) return false;

		return this._connection.state === 1;
	}

	constructor(url: string, queryString: string, hubName: string) {
		this._url = url;
		this._queryString = queryString;
		this._hubName = hubName;
	}

	private initialize() {
		this._connection = $.hubConnection(this._url);
		this._connection.error((error) => {
			this.connectionError(error);
		});
		this._connection.stateChanged((state) => {
			this.connectionStateChanged(state);
		});
		this._connection.qs = this._queryString;
		this._hubConnection = this._connection.createHubProxy(this._hubName);
		this.onHubProxyCreation(this._hubConnection);
	}

	start(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			const connected = await this.reconnect();
			if (connected) {
				this.onConnectionEstablishedMethod();
			}

			resolve(connected);
		});
	}

	protected reconnect() {
		return new Promise<boolean>((resolve, reject) => {
			if (!this._connection) {
				this.initialize();
			}

			this._connection
				.start()
				.done((data: any) => {
					this.onConnectionEstablishedMethod();
					resolve(true);
				})
				.catch((error: any) => {
					resolve(false);
				});
		});
	}

	protected connectionStateChanged(state) {
		console.log('Notification state changed from: ' + this.stateConversion[state.oldState]
			+ ' to: ' + this.stateConversion[state.newState]);

		if (state.newState !== 4) return;

		this.reconnect();
	};

	private connectionError(error) {
		console.log('SignalR Error: ' + error);
		this.onError(error);
		setTimeout(() => {
			this.reconnect();
		}, 5000);
	}

	protected onError(error) {

	}

	protected onConnectionEstablishedMethod() {

	}

	protected onHubProxyCreation(hubProxy: any) { }

	protected invoke(method: string, packet: string, jsonData: string) {
		return this._hubConnection.invoke(method, packet, jsonData);
	}
}
