export class DeprecatedMetricTimeFrame {
	private constructor() {}

	static getAWOTimeFrameFields() {
		return [
			{ name: 'startdate', code: `StartDate` },
			{ name: 'createddate', code: `CreatedDate` },
			{ name: 'completeddate', code: `CompletedDate` }
		];
	}

	static getTimeFrameSelection() {
		return [
			{ name: 'today', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('today') },
			{ name: '12 hours', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('12 hours') },
			{ name: '24 hours', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('24 hours') },
			{ name: '48 hours', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('48 hours') },
			{ name: '30 days', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('30 days') },
			{ name: '90 days', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('90 days') },
			{ name: '365 days', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('365 days') },
			{ name: '2 years', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('2 years') },
			{ name: '3 years', code: DeprecatedMetricTimeFrame.getTimeFrameQuery('3 years') }
		];
	}

	static getTimeFrameQuery(defaultTimeFrame: string) {
		let queryString;
		const now = new Date();
		const thisHour = now.getHours();
		const thisDay = now.getDate();
		const thisYear = now.getFullYear();
		switch (defaultTimeFrame) {
			case 'today':
				queryString = 'current_timestamp';
				break;
			case '12 hours':
				queryString = `current_timestamp - interval '12' hour`
				break;
			case '24 hours':
				queryString = `current_timestamp - interval '24' hour`
				break;
			case '48 hours':
				queryString = `current_timestamp - interval '48' hour`
				break;
			case 'current week':
				// dayOfTheWeek could be: 'Sunday' = 0,'Monday' = 1, 'Tuesday' = 2
				const dayOfTheWeek = now.getDay();
				// If today is 'Monday', then we back track 0 days to get to 'Monday' since Monday is the first day of the week,
				// If today is 'Sunday', then we back track 6 days to get to 'Monday'
				// NOTE: If we consider 'Sunday' to be first day of the week then the logic needs to change
				const mapIndexToNumberOfDaysTobeSubtracted = [6, 0, 1, 2, 3, 4, 5];
				now.setDate(now.getDate() - mapIndexToNumberOfDaysTobeSubtracted[dayOfTheWeek]);
				// Clear the times to 00:00:00 to be considered start of the current week
				queryString = DeprecatedMetricTimeFrame.getQueryStringFromSettingMonthDateHour(now);
				break;
			case 'current month':
				// Clear the date to the 1st of the month, and clear the times to 00:00:00
				queryString = DeprecatedMetricTimeFrame.getQueryStringFromSettingMonthDateHour(now, 1);
				break;
			case '30 days':
				queryString = `current_timestamp - interval '30' day`
				break;
			case 'current quarter':
				const currentMonth = now.getMonth();
				// Note: January = 0, April = 3, July = 6, October = 9
				const mapIndexToQuarterStartsOn = [0, 0, 0, 3, 3, 3, 6, 6, 6, 9, 9, 9];
				const monthThatQuarterStartsOn = mapIndexToQuarterStartsOn[currentMonth];
				// Clear month to the first month of that quarter,clear the date to the 1st of the month, and clear the times to 00:00:00
				queryString = DeprecatedMetricTimeFrame.getQueryStringFromSettingMonthDateHour(now, 1, monthThatQuarterStartsOn);
				break;
			case '90 days':
				queryString = `current_timestamp - interval '90' day`
				break;
			case 'current year':
				// Clear month to the January of the year, clear the date to the 1st of the month, and clear the times to 00:00:00
				queryString = DeprecatedMetricTimeFrame.getQueryStringFromSettingMonthDateHour(now, 1, 0);
				break;
			case '365 days':
				queryString = `current_timestamp - interval '365' day`
				break;
			case '2 years':
				queryString = `current_timestamp - interval '730' day`
				break;
			case '3 years':
				queryString = `current_timestamp - interval '1095' day`
				break;
		}
		if (!queryString) queryString = now;
		return queryString;
	}

	/**
	 * This method generate the query string by setting the given date and month
	 * @param {string} timeFrameFieldName - the arcGis field with a datetime value eg., event_time
	 * @param {Date} currentDate - new Date object that represents now
	 * @param {string} date - date of the month to set it to
	 * @param {string} month - month of the year to set it to
	 * @return {string} returns the constructed query string
	 */
	private static getQueryStringFromSettingMonthDateHour(currentDate: Date, date?: number, month?: number) {
		// Clear month to the that month of the year
		if (month !== undefined) currentDate.setMonth(month);

		// Clear the date to the that day of the month
		if (date !== undefined) currentDate.setDate(date);

		// // Clear the times to 00:00:00
		currentDate.setHours(0, 0, 0, 0);
		return currentDate;
	}
}
