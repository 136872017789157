import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';
import { DateUtil } from '../../sedaru-util/date-utility/date-util';

/**
 * This defines the equipment cost details
 */
export class EquipmentCost extends OmniModel<EquipmentCost, Contracts.EquipmentCostContract> {
	/**
	 * uuid for equipment cost
	 */
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>('0');
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	/**
	 * holds the equipment id
	 */
	private _equipmentId: SedaruUtils.Undoable<string>;
	get equipmentId(): string {
		if (!this._equipmentId) this._equipmentId = new SedaruUtils.Undoable<string>();
		return this._equipmentId.value;
	}
	set equipmentId(value: string) {
		if (!this._equipmentId) {
			this._equipmentId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._equipmentId.value = value;
	}

	/**
	 * holds the equipment units
	 */
	private _units: SedaruUtils.Undoable<number>;
	get units(): number {
		if (!this._units) this._units = new SedaruUtils.Undoable<number>(1);
		return this._units.value;
	}
	set units(value: number) {
		if (!this._units) {
			this._units = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._units.value = value;
	}

	/**
	 * holds the equipment unit cost
	 */
	private _unitCost: SedaruUtils.Undoable<number>;
	get unitCost(): number {
		if (!this._unitCost) this._unitCost = new SedaruUtils.Undoable<number>();
		return this._unitCost.value;
	}
	set unitCost(value: number) {
		if (!this._unitCost) {
			this._unitCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._unitCost.value = value;
	}

	/**
	 * holds the equipment date
	 */
	private _datestamp: SedaruUtils.Undoable<string>;
	get datestamp(): string {
		if (!this._datestamp) this._datestamp = new SedaruUtils.Undoable<string>();
		return this._datestamp.value;
	}
	set datestamp(value: string) {
		if (!this._datestamp) {
			this._datestamp = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._datestamp.value = value;
	}

	/**
	 * holds the equipment work order key
	 */
	private _workOrderKey: SedaruUtils.Undoable<string>;
	get workOrderKey(): string {
		if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
		return this._workOrderKey.value;
	}
	set workOrderKey(value: string) {
		if (!this._workOrderKey) {
			this._workOrderKey = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workOrderKey.value = value;
	}

	/**
	 * holds the equipment total cost
	 */
	private _totalCost: SedaruUtils.Undoable<number>;
	get totalCost(): number {
		if (!this._totalCost) this._totalCost = new SedaruUtils.Undoable<number>();
		return this._totalCost.value;
	}
	set totalCost(value: number) {
		if (!this._totalCost) {
			this._totalCost = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._totalCost.value = value;
	}

	/**
	 * holds the equipment hours
	 */
	private _hours: SedaruUtils.Undoable<number>;
	get hours(): number {
		if (!this._hours) this._hours = new SedaruUtils.Undoable<number>();
		return this._hours.value;
	}
	set hours(value: number) {
		if (!this._hours) {
			this._hours = new SedaruUtils.Undoable<number>(value);
			return;
		}
		this._hours.value = value;
	}

	private _workTaskId: SedaruUtils.Undoable<string>;
	get workTaskId(): string {
		if (!this._workTaskId) this._workTaskId = new SedaruUtils.Undoable<string>();
		return this._workTaskId.value;
	}
	set workTaskId(value: string) {
		if (!this._workTaskId) {
			this._workTaskId = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._workTaskId.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	private _createdBy: SedaruUtils.Undoable<string>;
	get createdBy(): string {
		if (!this._createdBy) this._createdBy = new SedaruUtils.Undoable<string>();
		return this._createdBy.value;
	}
	set createdBy(value: string) {
		if (!this._createdBy) {
			this._createdBy = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._createdBy.value = value;
	}

	static fromContract(contract: Contracts.EquipmentCostContract): EquipmentCost {
		const model = new EquipmentCost();
		model.id = contract.objectid.toString();
		model.equipmentId = contract.EquipmentId ? contract.EquipmentId : contract['equipmentid'];
		model.units = contract.Units ? contract.Units : contract['units'];
		model.unitCost = contract.UnitCost ? contract.UnitCost : contract['unitcost'];
		const datestamp = contract.Datestamp ? contract.Datestamp : contract['datestamp'];
		model.datestamp = DateUtil.isValidDate(datestamp) ? datestamp : '';
		model.workOrderKey = contract.WorkOrderKey ? contract.WorkOrderKey : contract['workorderkey'];
		model.totalCost = contract.TotalCost ? contract.TotalCost : contract['totalcost'];
		model.hours = contract.Hours ? contract.Hours : contract['hours'];
		model.workTaskId = contract.WorkTaskId ? contract.WorkTaskId : contract['worktaskid'];
		model.description = contract.Description ? contract.Description : contract['description'];
		model.createdBy = contract.CreatedBy ? contract.CreatedBy : contract['createdby'];

		model.clearDirty();
		return model;
	}

	getContract = (): Contracts.EquipmentCostContract => {
		const contract = new Contracts.EquipmentCostContract();
		contract.objectid = +this.id;
		contract.EquipmentId = this.equipmentId.toString();
		contract.Units = this.units;
		contract.UnitCost = this.unitCost;
		contract.Datestamp = this.datestamp;
		contract.TotalCost = this.totalCost;
		contract.Hours = this.hours;
		contract.WorkOrderKey = this.workOrderKey;
		contract.Description = this.description;
		contract.CreatedBy = this.createdBy;
		contract.WorkTaskId = this.workTaskId;
		return contract;
	};

	calculateTotalCost() {
		this.totalCost = this.hours * this.unitCost;
	}

	createTimeStamp() {
		this.datestamp = new Date().toLocaleString();
	}

	onUpdateInformation(copyModel: EquipmentCost): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._equipmentId, () => {
				this.equipmentId = copyModel.equipmentId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._units, () => {
				this.units = copyModel.units;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._unitCost, () => {
				this.unitCost = copyModel.unitCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._datestamp, () => {
				this.datestamp = copyModel.datestamp;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._totalCost, () => {
				this.totalCost = copyModel.totalCost;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._hours, () => {
				this.hours = copyModel.hours;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workTaskId, () => {
				this.workTaskId = copyModel.workTaskId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._createdBy, () => {
				this.createdBy = copyModel.createdBy;
			}) || wasChanged;
		return wasChanged;
	}

	isNew = () => this.id === '0';
}
