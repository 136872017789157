import { NoIconOneLineTemplateResolver } from './no-icon-one-line/np-icon-one-line.resolver';
import { Template, TemplateType } from 'models/sedaru-config/template.model';
import { RecordContextGroup } from 'models/records/record-context-group';
import { ChannelTypes, ContextType, Metric, ValueFormatType } from 'models';
import { BindingPlaceholders } from 'models/sedaru-config/binding.model';
import { LeftIconTwoLinesItemResolver } from './left-icon-two-lines/left-icon-two-lines.resolver';
import { PNGManager } from 'app/ui-components/png-manager';
import { ListItemResolver } from './list-item.resolver';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { ManagerBase } from 'domain-service/manager-base';
import { WorkOrderWrapper } from 'models/work-order';
import { DatePipe } from '@angular/common';
import { AssetRecord } from 'models/records/asset-record.model';
import { HistoryRecord } from 'models/records/history-record.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { FieldType } from 'sedaru-util/esri-core';
import { DateUtil } from 'sedaru-util';

export class TemplateManager extends ManagerBase {
	constructor(interopService: OmniInteropService) {
		super(interopService);
	}

	private get getWOArcGISFields() {
		return this.workOrderFactory.getArcGISFields();
	}

	private get workOrderFactory(): WorkOrderFactory {
		return this.interopService?.omniDomain?.workOrderFactory;
	}

	private get globalTemplates(): Template[] {
		return this.interopService?.configurationManager?.customerCodeConfiguration?.templates;
	}

	private getDefaultAssetTemplateResolver(assetRecord: AssetRecord) {
		const defaultResolver = new NoIconOneLineTemplateResolver();
		defaultResolver.getAlignment = item => {
			return 'center';
		};
		defaultResolver.getLabel = item => {
			const displayFieldName = assetRecord.feature.displayFieldName;
			if (!displayFieldName) return assetRecord.assetId;
			return assetRecord.feature.getAttribute(displayFieldName);
		};
		defaultResolver.onIsDisabled = item => true;
		defaultResolver.showDivider = item => false;
		return defaultResolver;
	}

	private getAssetSingleLineTemplateResolver(template: Template, assetRecord: AssetRecord) {
		const singleTextResolver = new NoIconOneLineTemplateResolver();
		const bindingContext = template.bindings[0].value.context;
		singleTextResolver.getAlignment = item => {
			return 'center';
		};
		singleTextResolver.getLabel = item => {
			if (bindingContext.type === ContextType.Asset) return assetRecord.feature.getAttribute(bindingContext.fieldname);
			return '';
		};
		singleTextResolver.onIsDisabled = item => true;
		singleTextResolver.showDivider = item => false;
		return singleTextResolver;
	}

	private getAssetThreeLineTemplateResolver(template: Template, assetRecord: AssetRecord) {
		const threeFieldResolver = new LeftIconTwoLinesItemResolver();
		threeFieldResolver.getAlignment = item => 'left';
		threeFieldResolver.getTopLabel = item => {
			const placeHolder = template.bindings.find(b => b.placeholder === BindingPlaceholders.Header);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.Asset) return '';

			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Header).value.textFormatId;
			return formatId
				? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
				: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
		};

		threeFieldResolver.getBottomLeftLabel = item => {
			const placeHolder = template.bindings.find(b => b.placeholder === BindingPlaceholders.Details);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.Asset) return '';

			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Details).value.textFormatId;
			return formatId
				? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
				: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
		};
		threeFieldResolver.getBottomRightLabel = item => {
			const placeHolder = template.bindings.find(b => b.placeholder === BindingPlaceholders.Extra);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.Asset) return '';

			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Extra).value.textFormatId;
			return formatId
				? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
				: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
		};
		threeFieldResolver.getIconPath = item => {
			return PNGManager.editImage(assetRecord.assetDefinition.iconResource.url, {
				width: 25,
				height: 25,
				backgroundColor: assetRecord.assetDefinition.style.layout.background.color
			});
		};
		threeFieldResolver.onIsDisabled = item => true;
		threeFieldResolver.showDivider = item => false;
		return threeFieldResolver;
	}

	private getAssetTemplateResolver(contextGroup: RecordContextGroup, metricId?: string) {
		if (!contextGroup || !contextGroup.assetRecord) return;
		const assetRecord = contextGroup.assetRecord;
		// if there is no template
		if (!assetRecord.assetDefinition || !assetRecord.assetDefinition.assetMetricTemplate) return this.getDefaultAssetTemplateResolver(assetRecord);

		let template = this.globalTemplates?.find(t => t?.metricId === metricId);
		if (!template) template = assetRecord.assetDefinition.assetMetricTemplate;
		if (!template) return this.getDefaultAssetTemplateResolver(assetRecord);

		const isValidTemplate = template.validateTemplate();
		if (!isValidTemplate.isValid) {
			console.error(isValidTemplate.reason);
			return this.getDefaultAssetTemplateResolver(assetRecord);
		}
		// if template is single text
		if (template.type === TemplateType.SingleTextTemplate) {
			return this.getAssetSingleLineTemplateResolver(template, assetRecord);
		}

		// if template is three field
		return this.getAssetThreeLineTemplateResolver(template, assetRecord);
	}

	private getDefaultHistoryTemplateResolver(assetRecord: AssetRecord, historyRecord: HistoryRecord) {
		const defaultResolver = new NoIconOneLineTemplateResolver();
		defaultResolver.getAlignment = item => {
			return 'center';
		};
		defaultResolver.getLabel = item => {
			const displayFieldName = historyRecord.feature.displayFieldName;
			return assetRecord.feature.getAttribute(displayFieldName);
		};
		defaultResolver.onIsDisabled = item => true;
		defaultResolver.showDivider = item => false;
		return defaultResolver;
	}

	private getHistorySingleLineTemplateResolver(template: Template, assetRecord: AssetRecord, historyRecord: HistoryRecord) {
		const singleTextResolver = new NoIconOneLineTemplateResolver();
		const bindingContext = template.bindings[0].value.context;
		singleTextResolver.getAlignment = item => {
			return 'center';
		};
		singleTextResolver.getLabel = item => {
			if (bindingContext.type === ContextType.Asset) return assetRecord.feature.getAttribute(bindingContext.fieldname);
			if (bindingContext.type === ContextType.History) return historyRecord.feature.getAttribute(bindingContext.fieldname);
			return '';
		};
		singleTextResolver.onIsDisabled = item => true;
		singleTextResolver.showDivider = item => false;
		return singleTextResolver;
	}

	private getHistoryThreeLineTemplateResolver(template: Template, assetRecord: AssetRecord, historyRecord: HistoryRecord) {
		const threeFieldResolver = new LeftIconTwoLinesItemResolver();
		threeFieldResolver.getAlignment = item => 'left';
		threeFieldResolver.getTopLabel = item => {
			const templateContext = template.bindings.find(b => b.placeholder === BindingPlaceholders.Header).value.context;
			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Header).value.textFormatId;
			if (templateContext.type === ContextType.Asset) {
				return formatId
					? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
			}
			if (templateContext.type === ContextType.History) {
				return formatId
					? this.formatReturnValue(historyRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, historyRecord.feature.getAttribute(templateContext.fieldname));
			}
			return '';
		};
		threeFieldResolver.getBottomLeftLabel = item => {
			const templateContext = template.bindings.find(b => b.placeholder === BindingPlaceholders.Details).value.context;
			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Details).value.textFormatId;
			if (templateContext.type === ContextType.Asset) {
				return formatId
					? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
			}
			if (templateContext.type === ContextType.History) {
				return formatId
					? this.formatReturnValue(historyRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, historyRecord.feature.getAttribute(templateContext.fieldname));
			}
			return '';
		};
		threeFieldResolver.getBottomRightLabel = item => {
			const templateContext = template.bindings.find(b => b.placeholder === BindingPlaceholders.Extra).value.context;
			const formatId = template.bindings.find(b => b.placeholder === BindingPlaceholders.Extra).value.textFormatId;
			if (templateContext.type === ContextType.Asset) {
				return formatId
					? this.formatReturnValue(assetRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, assetRecord.feature.getAttribute(templateContext.fieldname));
			}
			if (templateContext.type === ContextType.History) {
				return formatId
					? this.formatReturnValue(historyRecord.feature.getAttribute(templateContext.fieldname), formatId)
					: this.formatCheckValue(templateContext.fieldname, historyRecord.feature.getAttribute(templateContext.fieldname));
			}
			return '';
		};
		threeFieldResolver.getIconPath = item => {
			return PNGManager.editImage(assetRecord.assetDefinition.iconResource.url, {
				width: 25,
				height: 25,
				backgroundColor: assetRecord.assetDefinition.style.layout.background.color
			});
		};
		threeFieldResolver.onIsDisabled = item => true;
		threeFieldResolver.showDivider = item => false;
		return threeFieldResolver;
	}

	private getHistoryTemplateResolver(contextGroup: RecordContextGroup, metricId?: string) {
		if (!contextGroup || !contextGroup.assetRecord || !contextGroup.historyRecord) return;
		const historyRecord = contextGroup.historyRecord;
		const assetRecord = contextGroup.assetRecord;

		// if no template
		if (!historyRecord.assetDefinition || !historyRecord.assetDefinition.historyMetricTemplate) return this.getDefaultHistoryTemplateResolver(assetRecord, historyRecord);
		let template = this.globalTemplates?.find(t => t?.metricId === metricId);
		if (!template) template = historyRecord.assetDefinition.historyMetricTemplate;
		if (!template) return this.getDefaultHistoryTemplateResolver(assetRecord, historyRecord);

		const isValidTemplate = template.validateTemplate();
		if (!isValidTemplate.isValid) {
			console.error(isValidTemplate.reason);
			return this.getDefaultHistoryTemplateResolver(assetRecord, historyRecord);
		}
		// if template is single text
		if (template.type === TemplateType.SingleTextTemplate) return this.getHistorySingleLineTemplateResolver(template, assetRecord, historyRecord);

		// if template is three field
		return this.getHistoryThreeLineTemplateResolver(template, assetRecord, historyRecord);
	}

	private getDefaulltWOTemplateResolver(workOrderRecord: WorkOrderWrapper) {
		const defaultResolver = new LeftIconTwoLinesItemResolver();
		defaultResolver.getAlignment = item => 'left';
		defaultResolver.onIsDisabled = item => true;
		defaultResolver.showDivider = item => false;

		defaultResolver.getTopLabel = item => {
			return workOrderRecord.workType;
		};
		defaultResolver.getBottomLeftLabel = item => {
			if (!workOrderRecord.startDate) return '';
			const options: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };
			const date = workOrderRecord.startDate ? workOrderRecord.startDate : 0;
			const newDate = DateUtil.parseDate(date, 'MM/dd/yyyy');
			return 'start: ' + newDate.toLocaleDateString('en-US', options);
		};
		defaultResolver.getBottomRightLabel = item => {
			if (!workOrderRecord.completedDate) return '';
			const options: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };
			const date = workOrderRecord.completedDate ? workOrderRecord.completedDate : 0;
			const newDate = DateUtil.parseDate(date, 'MM/dd/yyyy');
			return 'complete: ' +  newDate.toLocaleDateString('en-US', options);
		};
		defaultResolver.getIconPath = item => {
			return new Promise(solve => solve('assets/workorder.png'));
		};
		return defaultResolver;
	}

	private getWOSingleLineTemplateResolver(woTemplate: Template, workOrderRecord: WorkOrderWrapper) {
		const singleTextResolver = new NoIconOneLineTemplateResolver();
		const bindingContext = woTemplate.bindings[0].value.context;
		singleTextResolver.getAlignment = item => {
			return 'center';
		};
		singleTextResolver.getLabel = item => {
			if (bindingContext.type === ContextType.WorkOrder) {
				const normalizedKey = Object.keys(workOrderRecord).find(k => k && k.toLowerCase().includes(bindingContext.fieldname.toLowerCase()));
				if (!normalizedKey) return 'invalid configuration';
				return workOrderRecord[normalizedKey];
			}
			return '';
		};
		singleTextResolver.onIsDisabled = item => true;
		singleTextResolver.showDivider = item => false;
		return singleTextResolver;
	}

	private getWOThreeLineTemplateResolver(woTemplate: Template, workOrderRecord: WorkOrderWrapper) {
		const threeFieldResolver = new LeftIconTwoLinesItemResolver();
		threeFieldResolver.getAlignment = item => 'left';
		threeFieldResolver.getTopLabel = item => {
			const placeHolder = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Header);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.WorkOrder) return '';
			const normalizedKey = WorkOrderWrapper.normalizePropertyName(templateContext.fieldname);
			if (!normalizedKey) return 'invalid configuration';
			const formatId = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Header).value.textFormatId;
			let returnValue = workOrderRecord[normalizedKey];
			returnValue = formatId ? this.formatReturnValue(returnValue, formatId) : this.formatWithArcGISField(templateContext.fieldname.toLowerCase(), returnValue);
			return returnValue;
		};
		threeFieldResolver.getBottomLeftLabel = item => {
			const placeHolder = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Details);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.WorkOrder) return '';
			const normalizedKey = WorkOrderWrapper.normalizePropertyName(templateContext.fieldname);
			if (!normalizedKey) return 'invalid configuration';
			const formatId = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Details).value.textFormatId;
			let returnValue = workOrderRecord[normalizedKey];
			returnValue = formatId ? this.formatReturnValue(returnValue, formatId) : this.formatWithArcGISField(templateContext.fieldname.toLowerCase(), returnValue);
			return returnValue;
		};
		threeFieldResolver.getBottomRightLabel = item => {
			const placeHolder = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Extra);
			if (!placeHolder) return '';

			const templateContext = placeHolder.value.context;
			if (templateContext.type !== ContextType.WorkOrder) return '';
			const normalizedKey = WorkOrderWrapper.normalizePropertyName(templateContext.fieldname);
			let returnValue = workOrderRecord[normalizedKey];
			const formatId = woTemplate.bindings.find(b => b.placeholder === BindingPlaceholders.Extra).value.textFormatId;
			returnValue = formatId ? this.formatReturnValue(returnValue, formatId) : this.formatWithArcGISField(templateContext.fieldname.toLowerCase(), returnValue);
			return returnValue;
		};
		threeFieldResolver.getIconPath = item => {
			return new Promise(solve => solve('assets/workorder.png'));
		};
		threeFieldResolver.onIsDisabled = item => true;
		threeFieldResolver.showDivider = item => false;
		return threeFieldResolver;
	}

	private getWorkOrderTemplateResolver(contextGroup: RecordContextGroup, metricId?: string) {
		let woTemplate = this.globalTemplates?.find(t => t?.metricId === metricId);
		if (!woTemplate) woTemplate = this.workOrderFactory.activeWorkOrderTemplate ? this.workOrderFactory.activeWorkOrderTemplate : this.workOrderFactory.completedWorkOrderTemplate;
		if (!woTemplate) return this.getDefaulltWOTemplateResolver(contextGroup.workOrderRecord);

		const templateValidation = woTemplate.validateTemplate();
		if (!templateValidation.isValid) {
			console.error(templateValidation.reason);
			return this.getDefaulltWOTemplateResolver(contextGroup.workOrderRecord);
		}

		if (woTemplate.type === TemplateType.SingleTextTemplate) return this.getWOSingleLineTemplateResolver(woTemplate, contextGroup.workOrderRecord);
		return this.getWOThreeLineTemplateResolver(woTemplate, contextGroup.workOrderRecord);
	}

	getTemplateResolver(context: RecordContextGroup, metricId?: string): ListItemResolver {
		if (!context.assetRecord && !context.workOrderRecord) return;

		if (context.workOrderRecord) return this.getWorkOrderTemplateResolver(context, metricId);
		else if (context.historyRecord) return this.getHistoryTemplateResolver(context, metricId);

		return this.getAssetTemplateResolver(context, metricId);
	}

	getTemplateOutFields(metric: Metric, onlyTemplateItem = false): Array<string> {
		const type = metric.definition.source.type;

		if (type === ChannelTypes.WorkOrder) {
			const WOFields = ['workOrderKey', 'workType', 'startDate', 'completedDate', 'xCoordinate', 'yCoordinate', 'systemId', 'status', 'objectId', 'assignedTo', 'recurrenceTemplateId'];
			let woTemplate = this.globalTemplates?.find(t => t?.metricId === metric?.id);
			if (!woTemplate) woTemplate = this.workOrderFactory.activeWorkOrderTemplate ? this.workOrderFactory.activeWorkOrderTemplate : this.workOrderFactory.completedWorkOrderTemplate;
			if (!woTemplate) return WOFields;
			const templateValidation = woTemplate.validateTemplate();
			if (!templateValidation.isValid) {
				console.error(templateValidation.reason);
				return WOFields;
			}

			if (woTemplate.type === TemplateType.SingleTextTemplate) {
				const fieldName = woTemplate.bindings[0].value.context.fieldname;
				const normalizedKey = Object.keys(WorkOrderWrapper.prototype).find(k => k.toLowerCase().includes(fieldName.toLowerCase()));
				if (normalizedKey) WOFields.push(normalizedKey);
				if (fieldName?.toLowerCase() === 'assetkey' || fieldName?.toLowerCase() === 'assettype') WOFields.push(fieldName);
			}
			if (woTemplate.type === TemplateType.ListThreeFieldTemplate) {
				woTemplate.bindings.forEach(b => {
					const fieldName = b.value.context.fieldname;
					const normalizedKey = WorkOrderWrapper.normalizePropertyName(fieldName)
					if (normalizedKey) WOFields.push(normalizedKey);
					if (fieldName?.toLowerCase() === 'assetkey' || fieldName?.toLowerCase() === 'assettype') WOFields.push(fieldName);
				});
			}

			return WOFields.filter(e => !!e);
		}

		return this.getTemplateAssetFields(metric, onlyTemplateItem);
	}

	getTemplateSearchFields(metric: Metric, workOrderRecord?: WorkOrderWrapper): Array<string> {
		const type = metric.definition.source.type;

		if (type === ChannelTypes.WorkOrder) {
			const WOFields = [];
			let woTemplate = this.globalTemplates?.find(t => t?.metricId === metric?.id);
			if (!woTemplate) woTemplate = this.workOrderFactory.activeWorkOrderTemplate ? this.workOrderFactory.activeWorkOrderTemplate : this.workOrderFactory.completedWorkOrderTemplate;
			if (!woTemplate) return this.getDefaulltWOTemplateFeilds(workOrderRecord);
			if (woTemplate.type === TemplateType.SingleTextTemplate) {
				const fieldName = woTemplate.bindings[0].value.context.fieldname;
				const normalizedKey = Object.keys(WorkOrderWrapper.prototype).find(k => k.toLowerCase().includes(fieldName.toLowerCase()));
				if (normalizedKey) WOFields.push(normalizedKey);
				if (fieldName?.toLowerCase() === 'assetkey' || fieldName?.toLowerCase() === 'assettype') WOFields.push(fieldName);
			}
			if (woTemplate.type === TemplateType.ListThreeFieldTemplate) {
				const workOrderKeys = WorkOrderWrapper.getClassProperties(workOrderRecord);
				woTemplate.bindings.forEach(b => {
					const fieldName = b.value.context.fieldname;
					console.log('==> Requesting: ', b);
					const normalizedKey = workOrderKeys.find(k => k.toLowerCase() == fieldName.toLowerCase());
					if (normalizedKey) WOFields.push(normalizedKey);
					if (fieldName?.toLowerCase() === 'assetkey' || fieldName?.toLowerCase() === 'assettype') WOFields.push(fieldName);
				});
			}

			return WOFields;
		}

		return this.getTemplateAssetFields(metric);
	}

	private getTemplateAssetFields(metric: Metric, onlyTemplateItem?): Array<string> {
		const type = metric.definition.source.type;
		let template: Template;
		const assetType = metric.definition.source.assetType;
		const assetDef = this.interopService.omniDomain.userService.globalConfig.assetDefinitions.getByAssetType(assetType);
		template = this.globalTemplates?.find(t => t?.metricId === metric?.id);
		if (!template) {
			if (type === ChannelTypes.History) template = assetDef.historyMetricTemplate;
			else template = assetDef.assetMetricTemplate;
		}

		const fields = [];
		if (!onlyTemplateItem) {
			const uniqueField = assetDef.assetChannel?.attributes['uniqueFieldName'];
			fields.push(uniqueField);
			fields.push('objectid');

			if (type === ChannelTypes.History) {
				const assetIdField = assetDef.historyChannel?.attributes['assetIdFieldName'];
				const valueTypeField = metric.definition.displayValueSettings.valueTypeField.name;
				fields.push(assetIdField);
				fields.push(valueTypeField);
			}
		}

		if (!template || template.type === TemplateType.None) {
			fields.push('displayFieldName');
			return fields.filter(e => !!e);
		}

		if (template.type === TemplateType.SingleTextTemplate) {
			fields.push(template.bindings[0].value.context.fieldname);
			return fields.filter(e => !!e);
		}

		template.bindings.forEach(b => {
			fields.push(b.value.context.fieldname);
		});

		return fields.filter(e => !!e);
	}

	formatReturnValue(value, formatId) {
		const formatProperties = this.interopService.omniDomain.userService.globalConfig.formatProperties;
		const format = formatProperties.getById(formatId);
		let returnValue = value;
		switch (format.type) {
			case ValueFormatType.MakeLowerCase:
				returnValue.toLowerCase();
				break;
			case ValueFormatType.MakeUpperCase:
				returnValue.toUpperCase();
				break;
			case ValueFormatType.Currency:
				break;
			case ValueFormatType.WholeNumber:
				break;
			case ValueFormatType.FloatingPoint:
				break;
			case ValueFormatType.DateFormat: {
				returnValue = DateUtil.convertLocaleUTCStringFormatted(returnValue);
				break;
			}
			case ValueFormatType.TextFormat:
			default: {
				break;
			}
		}

		return returnValue;
	}

	formatWithArcGISField(templateFieldName, templateValue) {
		if (!templateValue) return;
		const arcField = this.getWOArcGISFields.find(f => f.name == templateFieldName);
		let returnValue = templateValue;
		if (!arcField) return returnValue;
		switch (arcField.type) {
			case FieldType.esriFieldTypeDouble:
			case FieldType.esriFieldTypeInteger:
				break;
			case FieldType.DateTime:
			case FieldType.esriFieldTypeDate:
				const options: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };
				returnValue = templateValue > 0 ? new Date(templateValue).toLocaleDateString('en-US', options) : '';
				break;
			default:
				break;
		}
		return returnValue;
	}

	private formatCheckValue(field, value) {
		if (field.toLowerCase().includes('date') || field.toLowerCase().includes('time')) {
			return DateUtil.convertLocaleUTCStringFormatted(value);
		}

		return value;
	}

	private getDefaulltWOTemplateFeilds(workOrderRecord: WorkOrderWrapper) {
		const defaultFeilds = ['workType', 'workOrderKey'];
		const dateFeild = this.workOrderFactory.completedWorkOrderValues.includes(workOrderRecord.status) ? 'completedDate' : 'startDate';
		defaultFeilds.push(dateFeild);

		return defaultFeilds;
	}
}
