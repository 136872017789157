import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { LeftIconTwoLinesComponent } from 'app/ui-components/omni-list/list-item/templates/left-icon-two-lines/left-icon-two-lines.component';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { TemplateManager } from 'app/ui-components/omni-list/list-item/templates/template-manager';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { RecordContextGroup } from 'models/records/record-context-group';
import { WorkOrderWrapper } from 'models/work-order';
import { DateUtil } from 'sedaru-util/date-utility/date-util';

@Component({
  selector: 'app-hierarchy-workorder-list',
  templateUrl: './hierarchy-workorder-list.component.html',
  styleUrls: ['./hierarchy-workorder-list.component.scss']
})
export class HierarchyWorkorderListComponent extends MenuPanelBaseComponent {
  workOrders: Array<WorkOrderWrapper> = [];
  locationId: string;
  resolvers = new Map<any, any>();

  @ViewChild('workOrdersList', { static: true }) workOrdersList: OmniListComponent;

  constructor(
    private workOrderFactory: WorkOrderFactory,
    private interopService: OmniInteropService,
    view: ElementRef<HTMLElement>
  ) {
    super(view);
  }

  ngOnInit(): void {
    this.workOrdersList.getResolver = (item: WorkOrderWrapper) => {
      const key = item?.workOrderKey;
      if (this.resolvers.has(key)) return this.resolvers.get(key);
      return this.getResolver(item);
    };
  }

  async onPageNavigatedTo(args: NavigationArgs): Promise<void> {
    if (!args || !args.parameter) return;

    const workOrderKeys = args.parameter['workOrderKeys'];
    this.locationId = args.parameter['locationId'];

    this.menuPanelComponent.updateView({
      title: 'work orders',
      subtitle: this.locationId,
      backgroundClass: 'orange-background',
      showPacifier: true
    });

    if (!workOrderKeys) return;
    this.workOrders = await this.workOrderFactory.getWorkOrders(workOrderKeys, true);
    this.menuPanelComponent.updatePacifier(false);
  }

  handleClick(event: WorkOrderWrapper): void {
    if (!event) return;

    NavigationService.navigateTo(Pages.workorderOutline, { workOrder: event, advancedMode: true });
  }

  getResolver(resolverItem: WorkOrderWrapper): ListItemResolver {
    const context = new RecordContextGroup({ workOrderRecord: resolverItem });
    const resolver = this.interopService.templateManager.getTemplateResolver(context);
    resolver.onIsDisabled = (item: WorkOrderWrapper) => false;
    resolver.showDivider = (item: any) => true;
    this.resolvers.set(resolverItem.workOrderKey, resolver);
    return resolver;
  }
}
