import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, Input } from '@angular/core';

@Component({
	selector: 'app-sedaru-wall',
	templateUrl: './sedaru-wall.component.html',
	styleUrls: ['./sedaru-wall.component.scss']
})
export class SedaruWallComponent implements OnInit {
	@Input() mode: string;
	@ViewChild('wall', { static: false }) wall: ElementRef;
	private _wrapperElement: ElementRef;
	@ViewChild('wrapper', { static: false }) set wrapperElement(e: ElementRef) {
		if (!e) return;
		this._wrapperElement = e;
		this.init();
	}
	get wrapperElement() {
		return this._wrapperElement;
	}
	@ViewChildren('value') values: QueryList<ElementRef>;

	gridItems = [];
	colTemplate: string;
	rowTemplate: string;
	intervalHandler;
	intervalSpeed = 1500;
	visibleValues = [];
	active = true;
	error = false;
	errorMessage: string;
	windowHeight: string;
	windowWidth: string;
	get imageWidth() {
		if (window.innerWidth > window.innerHeight) return 'auto';
		return window.innerWidth - 50 + 'px';
	}
	get imageHeight() {
		if (window.innerWidth > window.innerHeight) return '100%';
		return 'auto';
	}

	constructor() {}

	ngOnInit() {
		this.windowHeight = window.innerHeight - 20 + 'px';
		this.windowWidth = window.innerWidth - 20 + 'px';
	}

	init() {
		let numberOfCols = Math.floor(this.wrapperElement.nativeElement.offsetWidth / 200);
		if (!numberOfCols) numberOfCols = 1;
		let numberOfRows = Math.floor(this.wrapperElement.nativeElement.offsetHeight / 200);
		if (!numberOfRows) numberOfRows = 1;
		for (let i = 0; i < numberOfRows * numberOfCols; i++) {
			const delay = this.getDelay();
			this.gridItems.push({ delay });
		}
		let ctemplate = 'auto';
		for (let i = 1; i < numberOfCols; i++) {
			ctemplate += ' auto';
		}
		this.rowTemplate = this.wrapperElement.nativeElement.offsetHeight / numberOfRows + 'px';
		for (let i = 1; i < numberOfRows; i++) {
			this.rowTemplate += ' ' + this.wrapperElement.nativeElement.offsetHeight / numberOfRows + 'px';
		}
		this.colTemplate = ctemplate;
		this.showValues();
	}

	ngOnDestroy(): void {
		if (this.intervalHandler) clearInterval(this.intervalHandler);
	}

	showValues() {
		this.intervalHandler = setInterval(() => {
			let randomIndex;
			do {
				randomIndex = Math.floor(Math.random() * this.values.length);
			} while (this.visibleValues.includes(randomIndex));
			this.values.find((item, index) => index === randomIndex).nativeElement.style.opacity = 0.7;
			this.visibleValues.push(randomIndex);
			if (this.visibleValues.length === this.values.length) clearInterval(this.intervalHandler);
		}, this.intervalSpeed);
	}

	getDelay() {
		return Math.floor(Math.random() * 4000);
	}

	close() {
		if (!this.wall) return;
		if (this.intervalHandler) clearInterval(this.intervalHandler);
		this.wall.nativeElement.style.opacity = 0;
		this.wrapperElement.nativeElement.style.backgroundColor = 'rgb(30, 34, 34)';
		setTimeout(() => {
			this.active = false;
		}, 800);
	}
}
