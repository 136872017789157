import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';

export class AssociatedAsset extends OmniModel<AssociatedAsset, Contracts.AssociatedAssetContract> {
    private _assetType: SedaruUtils.Undoable<string>;
    get assetType(): string {
        if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
        return this._assetType.value;
    }
    set assetType(value: string) {
        if (!this._assetType) {
            this._assetType = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._assetType.value = value;
    }

    private _assetID: SedaruUtils.Undoable<string>;
    get assetID(): string {
        if (!this._assetID) this._assetID = new SedaruUtils.Undoable<string>();
        return this._assetID.value;
    }
    set assetID(value: string) {
        if (!this._assetID) {
            this._assetID = new SedaruUtils.Undoable<string>(value);
            return;
        }
        this._assetID.value = value;
    }

    static fromContract(contract: Contracts.AssociatedAssetContract): AssociatedAsset {
        const model = new AssociatedAsset();
        model.assetType = contract.assetType;
        model.assetID = contract.assetId;
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.AssociatedAssetContract {
        const contract = new Contracts.AssociatedAssetContract();
        contract.assetType = this.assetType;
        contract.assetId = this.assetID;
        return contract;
    }

    onUpdateInformation(copyModel: AssociatedAsset): boolean {
        let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
        wasChanged =
			this.updateIfNotDirty<string>(this._assetID, () => {
				this.assetID = copyModel.assetID;
			}) || wasChanged;

        return wasChanged;
    }
}
