import { Component, Input, OnInit } from '@angular/core';
import { MenuPanelFieldSize } from 'app/ui-components/menu-panel-field/menu-panel-field-size.enum';
import { DailyPatternType, Pattern, RecurrenceDefinition } from 'models/recurrence';

@Component({
  selector: 'app-daily-pattern',
  templateUrl: './daily-pattern.component.html',
  styleUrls: ['./daily-pattern.component.scss']
})
export class DailyPatternComponent implements OnInit {
  fieldFullSize = MenuPanelFieldSize.full;
  fieldHalfSize = MenuPanelFieldSize.half;

  @Input() isReadOnly: boolean;

  @Input() recurrencePattern: RecurrenceDefinition;

  get pattern(): Pattern {
    return this.recurrencePattern?.pattern;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
