import { Metric } from '../../../models/metric.model';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { fadeInAndOutOnHover } from 'app/app.animations';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { CanvasService } from 'app/canvas-container/canvas.service';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SnackbarMessageTypes } from 'app/flash-message/snackbar-message-types.model';
import { MetricRequesterService, MetricRequester } from '../metric-requester.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { TileService } from 'app/tiles/tile.service';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { TwoIconsOneLineComponent } from 'app/ui-components/omni-list/list-item/templates/two-icons-one-line/two-icons-one-line.component';
import { ContextualSearch, ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { MetricTile } from 'models';
import { MetricService } from 'domain-service/metric.service';
import { ChannelTypes } from 'models';
import { Canvas, CanvasMode } from 'omni-model/canvas.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { MapSettingsComponent } from 'app/menu-panel/map-settings/map-settings.component';
import { TrendSettingsComponent } from 'app/menu-panel/trend-settings/trend-settings.component';
import { TableSettingsComponent } from 'app/menu-panel/table-settings/table-settings.component';


/**
 * This component is designed to be placed in the left side menu panel.
 * It lists all the available metrics and the 'add metric' button at the footer of the panel for adding new
 * metrics.
 */
@Component({
	selector: 'app-list-metric',
	templateUrl: './list-metric.component.html',
	styleUrls: ['./list-metric.component.scss'],
	animations: [fadeInAndOutOnHover]
})
export class ListMetricComponent extends MenuPanelBaseComponent {
	@ViewChild(OmniListComponent, { static: true }) listComponent: OmniListComponent;
	/**
	 * Holds the list of available metrics
	 */
	public get metrics(): Array<Metric> {
		return this.contextualSearch.getSearch<Metric>().results;
	}

	currentlySelectedMetric: Metric;

	metricRequester: MetricRequester;

	showRemoveOption = false;
	disableRemoveOption = false;
	private canvasMode;
	initialScrollPosition = 0;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {ScrollToViewService} scrollService - This registers the view which is to be scrolled in when
	 * a user hits something, say a tile, on a mobile device.
	 * @param {MetricService} metricService - Provides all the services required to manipulate a metric
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(
		private scrollService: ScrollToViewService,
		private metricRequesterService: MetricRequesterService,
		private tileService: TileService,
		private flashMessageService: FlashMessageService,
		private canvasService: CanvasService,
		private workOrderFactory: WorkOrderFactory,
		private metricService: MetricService,
		view: ElementRef<HTMLElement>
	) {
		super(view);
	}

	/**
	 * On init, a list of metrics are initialized.
	 * Tab ID of the currently selected tab is initialized.
	 */
	ngOnInit() {
		if (this.uiState?.scrollPosition) this.initialScrollPosition = this.uiState.scrollPosition;
		this.metricRequester = this.metricRequesterService.getRequester();
		this.menuPanelComponent.updateView({ title: 'select metric' });
		const metricListRessolver = TwoIconsOneLineComponent.createResolver();
		metricListRessolver.getLabel = item => {
			return (item as Metric).definition.name;
		};
		metricListRessolver.getLeftIcon = item => {
			return (item as Metric).definition.symbolIconUrl;
		};
		metricListRessolver.getRightIcon = item => {
			return 'assets/edit.png';
		};
		metricListRessolver.getAlignment = item => {
			return 'center';
		};
		metricListRessolver.showRightIconOnHover = item => {
			return true;
		};
		this.listComponent.getResolver = item => {
			return metricListRessolver;
		};
	}

	onScroll(position: number) {
		if (!this.uiState) this.uiState = {};
		this.uiState['scrollPosition'] = position;
	}

	onPageReload(args: NavigationArgs) {
		this.onPageNavigatedTo(args);
		this.ngOnInit();
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args.searchTerm) {
			this.contextualSearch.navigateBack = args.isNavigatingBack;
			this.contextualSearch.search.searchText = args.searchTerm;
		}
		if (!args || !args.parameter) return;
		// if metric has been selected, go back to tile settings or canvas settings
		const selectedMetric = args.parameter['selectedMetric'];
		this.showRemoveOption = args.parameter['showRemoveOption'] || false;
		this.disableRemoveOption = args.parameter['disableRemoveOption'] || false;

		if (selectedMetric) {
			this.onMetricSelected(selectedMetric);
		}

		this.currentlySelectedMetric = args.parameter.currentlySelectedMetric;
	}


	/**
	 * Sorts the list of metrics by its name and groups by its asset type.
	 * @param {Array<Metric>} metrics list of available metrics.
	 */
	sortAndGroupMetrics(metrics: Array<Metric>) {
		metrics.sort((a, b) => (a.definition.name.toLowerCase() > b.definition.name.toLowerCase() ? 1 : b.definition.name.toLowerCase() > a.definition.name.toLowerCase() ? -1 : 0));
		const groups = [];
		const groupedMetrics = [];
		for (let i = 0; i < metrics.length; i++) {
			if (metrics[i].definition.source.type === ChannelTypes.WorkOrder) {
				const sourceType = WorkOrderSourceType[metrics[i].definition.source.workOrderSourceType];
				if (!groups[sourceType]) {
					groups[sourceType] = [];
				}
				groups[sourceType].push(metrics[i]);
			} else {
				if (!groups[metrics[i].definition.source.assetType]) {
					groups[metrics[i].definition.source.assetType] = [];
				}
				groups[metrics[i].definition.source.assetType].push(metrics[i]);
			}
		}
		for (const group of Object.values(groups)) {
			groupedMetrics.push(...group);
		}
		return groupedMetrics;
	}

	/**
	 * This method is invoked when the edit icon (pencil icon) corresponding to a metric is clicked.
	 * @param {Metric} metric - The metric selected for editing
	 */
	onEditMetricItem(metricItem: Metric): void {
		NavigationService.navigateTo(Pages.editMetric, { metric: metricItem });
	}

	/**
	 * This method is invoked when the 'Add Metric' is clicked on the select metric side panel.
	 */
	onAddMetricItem(): void {
		NavigationService.navigateTo(Pages.editMetric, { metric: new Metric() });
	}

	onNoneClick() {
		this.disableRemoveOption = false;
		this.canvasService.defaultMetricRemoved();
		NavigationService.navigateBackTo();
	}
	/**
	 * This method is invoked when an item from the metric list is selected. The user is then navigated to the tile settings panel.
	 * The metric is added to the tile.
	 * @param {Metric} metricItem  - The metric selected for editing
	 */
	async onMetricSelected(metricItem: Metric): Promise<void> {
		this.menuPanelComponent.updateView({ showPacifier: true });
		const metricRequester = this.metricRequesterService.getRequester();
		/**
		 * This case is if metric is set as default metric to a canvas
		 */
		if (metricRequester.type === 'Canvas') {
			if (metricItem === null) this.canvasService.defaultMetricRemoved();
			else {
				if (this.config.selectedCanvas.mode === CanvasMode.trend && !metricItem.definition.historyChannel && !metricItem.definition.workOrderChannel) {
					this.flashMessageService.popMessage('This metric is not trendable.');
					this.menuPanelComponent.updateView({ showPacifier: false });
					/**
					 * deselect the selected metrics from this.listComponent.selectedItems
					 * ie; to remove the cyan selection
					 */
					const indexInItemsSelected = this.indexInItemsSelected(metricItem);
					if (indexInItemsSelected >= 0) this.listComponent.selectedItems.splice(indexInItemsSelected, 1);
					return;
				}

				if (!metricItem.result || !metricItem.result.hasBeenQueried) {
					metricItem.result.hasBeenQueried = true;
					if (metricItem.definition.source.type === ChannelTypes.WorkOrder && metricItem.definition.workOrderChannel.isAdvancedWorkOrder) {
						this.workOrderFactory.getWorkOrderByFilter(metricItem);
					} // else this.interopService.metricManager.getMetricResults(metricItem);
				}

				this.canvasService.defaultMetricSelected(metricItem, this.config.selectedCanvas);
			}

			/**
			 * Scrolls the HTML element passed to 'tileList' into the portview
			 */
			this.scrollService.tileList.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});

			this.menuPanelComponent.updateView({ showPacifier: false });

			if (window.innerWidth <= 768) {
				/**
				 * Android devices don't scroll up to the tile list unless we set
				 * the navigation inside a timeout. Using 768px as the cut off point
				 */
				setTimeout(() => {
					if (metricRequester.value.mode === 'map') {
						NavigationService.navigateTo(Pages.mapSettings);
					} else if (metricRequester.value.mode === 'trend') {
						NavigationService.navigateTo(Pages.trendSettings);
					}
				}, 400);
			} else {
				if (metricRequester.value.mode === 'map') {
					NavigationService.navigateTo(Pages.mapSettings);
				} else if (metricRequester.value.mode === 'trend') {
					NavigationService.navigateTo(Pages.trendSettings);
				} else if (metricRequester.value.mode === 'table') {
					NavigationService.navigateTo(Pages.tableSettings);
				}
			}
		}

		/**
		 *  This case is if metric is added to the tile.
		 */

		if (this.metricRequesterService.getRequester().type === 'MetricTile') {
			const tile = this.metricRequesterService.getRequester().value as MetricTile;
			tile.metric = metricItem;

			try {
				this.tileService.assignMetricToTile(metricItem, tile, this.config).subscribe(() => {
					this.menuPanelComponent.updateView({ showPacifier: false });
					/**
					 * Scrolls the HTML element passed to 'tileList' into the portview
					 */
					this.scrollService.tileList.nativeElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
					if (window.innerWidth <= 768) {
						/**
						 * Android devices don't scroll up to the tile list unless we set
						 * the navigation inside a timeout.Using 768px as the cut off point
						 */
						setTimeout(() => {
							NavigationService.navigateTo(Pages.tileSettings, { currentTile: tile });
						}, 400);
					} else {
						NavigationService.navigateTo(Pages.tileSettings, { currentTile: tile });
					}
				});
			} catch (error) {
				this.menuPanelComponent.updateView({ showPacifier: false });
				this.flashMessageService.popMessage(error.message, SnackbarMessageTypes.ERROR);
			}
		}
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (metric: Metric, searchText) => {
			if (metric.definition.name.toLowerCase().includes(searchText.toLowerCase())) return true;

			return false;
		};
		const metrics = this.sortAndGroupMetrics(this.metricService.getMetricListByProfileGroup());
		const contextualSearch = new ContextualSearch(metrics, searchFunction);
		contextualSearch.title = 'search metrics';
		return contextualSearch;
	}

	/**
	 * Function used to find the index of selected metric from list of metrics using metric id
	 */
	private indexInItemsSelected(itemInTheList): number {
		if (!this.listComponent.selectedItems || !this.listComponent.selectedItems.length) return -1;

		let index = -1;
		for (let i = 0; i < this.listComponent.selectedItems.length; i++) {
			const selectedItem = this.listComponent.selectedItems[i];
			if (!selectedItem) continue;
			if (selectedItem.id === itemInTheList.id) {
				index = i;
				break;
			}
		}
		return index;
	}
}
