import { OnInit, OnDestroy, AfterViewInit, OnChanges, SimpleChanges, ElementRef, Component } from '@angular/core';
import { MenuPanelComponent } from '../menu-panel.component';
import { GuiConfig } from 'omni-model/gui-config.model';
import { ContextualSearch, ContextualSearchBase } from './contextual-search';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Pages } from 'app/navigation/inavigation';
import { BackNavigationArgs } from 'app/navigation/back-navigation-args';
import { Metric, MetricTile } from 'models';
import * as MenuPanelNavigation from 'app/navigation/menu-panel-navigation';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { TrendDefinition } from 'app/canvas-container/canvas-trend/trend-defintion.model';
import { TrendStyle } from '../trend-settings/trend-style/trend-style.model';

@Component({
	selector: 'app-menu-panel-base',
	template: ''
})
export abstract class MenuPanelBaseComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
	/** the instance of the current menu panel component */
	menuPanelComponent: MenuPanelComponent;
	/** the current gui config */
	config: GuiConfig;

	// ANTHONY
	/* Not sure what these are, but we should use the property menuPanelNavigationEntry*/
	get header() {
		return this.menuPanelComponent.headerComponent;
	}

	get footer() {
		return this.menuPanelComponent.footerComponent;
	}

	uiState: any;

	slideOnPageReload = false;

	/** returns the template of the current panel */
	get panelTemplate(): HTMLCollection {
		return this.template.nativeElement.children;
	}

	public get pageIdentifier(): Pages {
		return Pages.none;
	}

	private _menuPanelNavigationEntry: MenuPanelNavigation.MenuPanelNavigationEntry;
	public get menuPanelNavigationEntry(): MenuPanelNavigation.MenuPanelNavigationEntry {
		if (!this._menuPanelNavigationEntry) {
			this._menuPanelNavigationEntry = this.createMenuPanelNavigationEntry();
		}

		return this._menuPanelNavigationEntry;
	}

	assetSearchQuery: string;


	private _contextualSearch: ContextualSearchBase;
	public get contextualSearch(): ContextualSearchBase {
		if (!this._contextualSearch) {
			this._contextualSearch = this.onCreateContextualSearch();

			if (!this._contextualSearch) {
				this._contextualSearch = new ContextualSearchBase(undefined, false);
			}
		}

		return this._contextualSearch;
	}

	private _goBackAction: () => void;

	private createMenuPanelNavigationEntry(): MenuPanelNavigation.MenuPanelNavigationEntry {
		const entry = MenuPanelNavigation.MenuPanelNavigationEntry.createDefault();
		entry.header.leftButton.selectedAction = this.goBackAction;
		this.onMenuPanelEntryConstruction(entry);
		entry.isLoaded = true;
		return entry;
	}

	protected onMenuPanelEntryConstruction(entry: MenuPanelNavigation.MenuPanelNavigationEntry) {}

	public getContextualSearch<T>(): ContextualSearch<T> {
		return this.contextualSearch as ContextualSearch<T>;
	}

	public getContextualSearchItems<T>() {
		const cs = this.getContextualSearch<T>();
		if (!cs) return new Array<T>();

		return cs.results;
	}

	// abstract onGetPageIdentifier(): Pages;
	constructor(private template: ElementRef<HTMLElement>) {}

	ngOnChanges(changes: SimpleChanges) {
		// we do not do anything on the base class,
		// classes extending this base class can override
		// without declaring or importing OnChanges
	}

	ngOnInit() {
		// we do not do anything on the base class,
		// classes extending this base class can override
		// without declaring or importing OnInit
	}

	ngAfterViewInit() {
		// we do not do anything on the base class,
		// classes extending this base class can override
		// without declaring or importing AfterViewInit
	}

	ngOnDestroy() {
		// we do not do anything on the base class,
		// classes extending this base class can override
		// without declaring or importing OnDestroy
	}

	protected onCreateContextualSearch(): ContextualSearchBase {
		return null;
	}

	onPageNavigatingFrom(navigationArgs: NavigationArgs): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			resolve(true);
		});
	}

	onPageNavigatedTo(navigationArgs: NavigationArgs) {}

	onPageReload(navigationArgs: NavigationArgs) {}

	isDirty(navigationArgs: NavigationArgs): Promise<boolean> {
		return new Promise<boolean>(resolve => resolve(false));
	}

	dirtyMessageTitle(): string {
		return 'Losing Changes';
	}

	dirtyMessageBody(): string {
		return 'You will lose your changes and cannot be undone.';
	}

	onBackPressed(args: BackNavigationArgs): Promise<void> {
		return new Promise<void>(resolve => resolve());
	}

	onTabActivated() {}

	onTabDeactivated() {}

	onTileTimeframeChanged(tileComponent: TileComponent) {}

	async metricUpdated(metricId: string) {
		await this.onMetricUpdated(metricId);
		console.log('metric updated in menu panel');
	}

	protected async onMetricUpdated(metricId: string) {}

	protected get goBackAction(): () => void {
		if (!this._goBackAction) {
			this._goBackAction = () => this.menuPanelComponent.navigationRouter.navigateBack();
		}
		return this._goBackAction;
	}

	onInfoFormTrendIconClicked(trendDefinition: TrendDefinition) {
		this.menuPanelComponent.onInfoFormTrendIconClicked(trendDefinition);
	}

	onTileBackgroundColorChanged(tile: MetricTile) {
		this.menuPanelComponent.onTileBackgroundColorChanged(tile);
	}

	trendStyleChanged(style: TrendStyle) {
		this.menuPanelComponent.onTrendStyleChanged(style);
	}
}
