<mat-card
	class="omni-templated-list-item"
	[class.selected]="selected || resolver._onIsForceSelected(item)"
	(click)="!resolver.isDisabled(item) && (selected = !selected) && itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
	[ngStyle]="{
		'border-left': resolver._onShowLeftBorder(item) ? resolver._getLeftBorderStyle(item) : 'none',
		'border-bottom': resolver._onShowBottomBorder(item) ? resolver._getBottomBorderStyle(item) : 'none'
	}"
>
	<img
		mat-card-avatar
		[ngStyle]="{ border: resolver._onShowLeftImageBorder(item) ? resolver._getLeftImageBorderStyle(item) : 'none' }"
		*ngIf="getTypeOfIcon(resolver._getLeftIconPath(item)) === 'string'"
		[src]="resolver._getLeftIconPath(item)"
		(click)="onLeftIconClicked($event)"
		class="img-left"
	/>
	<app-metric-icon
		*ngIf="getTypeOfIcon(resolver._getLeftIconPath(item)) !== 'string'"
		[icon]="resolver._getLeftIconPath(item)"
		(click)="onLeftIconClicked($event)"
		[width]="'30px'"
		[height]="'30px'"
		class="img-left"
	></app-metric-icon>
	<div class="omni-templated-list-item-content">
		<div class="text-and-right-icon-wrapper">
			<div class="text-wrapper">
				<mat-card-title class="omni-templated-list-item-title s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
					{{ resolver._getTopLabel(item) }}
				</mat-card-title>
				<mat-card-subtitle class="omni-templated-list-item-subtitle xs-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
					<span class="omni-templated-list-item-subtitle-1" style="flex:1">{{ resolver._getBottomLeftLabel(item) }}</span>
					<span class="omni-templated-list-item-subtitle-2">
						{{ resolver._getBottomRightLabel(item) }}
					</span>
				</mat-card-subtitle>
			</div>
			<img
				mat-card-avatar
				*ngIf="resolver._getRightIconPath(item)"
				[src]="resolver._getRightIconPath(item)"
				(click)="onRightIconClicked($event)"
				[class.invisible]="hovered || !resolver._onShowRightIconOnHover(item) ? false : true"
			/>
			<div (click)="onRightIconClicked($event)" class="badge" *ngIf="resolver._getBadgeLabel(item)" [ngStyle]="{ 'background-color': resolver._getBadgeBackgroundColor(item) }">
				<label>{{ resolver._getBadgeLabel(item) }}</label>
			</div>
		</div>
		<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-divider"></mat-divider>
	</div>
</mat-card>
