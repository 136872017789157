export class SpatialReference {
	wkid: number;
	latestWkid: number;
	wkText = '';

	get isEmpty(): boolean {
		return this.wkid === 0 && this.latestWkid === 0 && !this.wkText;
	}

	static get wgs84(): number {
		return 4326;
	}

	static get webMercator(): number {
		return 3857;
	}
}
