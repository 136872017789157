import { WorkCommentContract } from '../../contracts/work-order';
import { WorkCommentWrapper } from './work-comment-wrapper.model';
import { WorkComment } from './work-comment.model';

export class WorkCommentWrappers extends Array<WorkCommentWrapper> {
	static fromContracts(contracts: WorkCommentContract[]): WorkCommentWrappers {
		return WorkCommentWrappers.fromModels(contracts.map(c => WorkComment.fromContract(c)));
	}

	static fromModels(models: WorkComment[]): WorkCommentWrappers {
		const wrappersList = new WorkCommentWrappers();
		for (const model of models) wrappersList.push(new WorkCommentWrapper(model));
		return wrappersList;
	}

	getContracts = (): WorkCommentContract[] => this.map(wc => wc.getContract());

	getModels = (): WorkComment[] => this.map(wc => wc.workComment);

	isDirty = (): boolean => !!this.filter(wc => wc.isDirty).length;

	clearDirty = (): void => this.forEach(wc => wc.clearDirty());
}
