import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class Priority extends OmniModel<Priority, Contracts.PriortyContract> {
	private _id: SedaruUtils.Undoable<string>;
	get id(): string {
		if (!this._id) this._id = new SedaruUtils.Undoable<string>();
		return this._id.value;
	}
	set id(value: string) {
		if (!this._id) {
			this._id = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._id.value = value;
	}

	private _priorityCode: SedaruUtils.Undoable<string>;
	get priorityCode(): string {
		if (!this._priorityCode) this._priorityCode = new SedaruUtils.Undoable<string>();
		return this._priorityCode.value;
	}
	set priorityCode(value: string) {
		if (!this._priorityCode) {
			this._priorityCode = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._priorityCode.value = value;
	}

	private _description: SedaruUtils.Undoable<string>;
	get description(): string {
		if (!this._description) this._description = new SedaruUtils.Undoable<string>();
		return this._description.value;
	}
	set description(value: string) {
		if (!this._description) {
			this._description = new SedaruUtils.Undoable<string>(value);
			return;
		}
		this._description.value = value;
	}

	static fromContract(contract: Contracts.PriortyContract): Priority {
		const model = new Priority();
		model.id = contract.ObjectId.toString();
		model.priorityCode = contract.PriorityCode;
		model.description = contract.Description;
		model.clearDirty();
		return model;
	}

	getContract(): Contracts.PriortyContract {
		const contract = new Contracts.PriortyContract();
		contract.ObjectId = +this.id;
		contract.PriorityCode = this.priorityCode;
		contract.Description = this.description;
		return contract;
	}

	onUpdateInformation(copyModel: Priority): boolean {
		let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._priorityCode, () => {
				this.priorityCode = copyModel.priorityCode;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;

		return wasChanged;
	}
}
