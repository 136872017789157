import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchService } from 'app/search/search.service';
import { ListItemResolver } from 'app/ui-components/omni-list/list-item/templates/list-item.resolver';
import { OmniListComponent } from 'app/ui-components/omni-list/omni-list.component';
import { SearchResultItemResolver } from 'app/ui-components/search-result-item/search-result-item.resolver';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { StandardWorkOrder } from 'models/work-order';
import { AWOModel } from 'models/work-order/awo-model';


@Component({
  selector: 'app-workorder-search-results',
  templateUrl: './workorder-search-results.component.html',
  styleUrls: ['./workorder-search-results.component.scss']
})
export class WorkorderSearchResultsComponent implements OnInit {

  @Input() workorderResults;

  @Input() displayedRecordCount: number;

  @Output() workorderClicked = new EventEmitter<AWOModel | StandardWorkOrder>();

  @ViewChild('workorderList', { static: true }) listComponent: OmniListComponent;

  private cachedListResolvers = new Map<any, ListItemResolver>();

  constructor(
    private searchService: SearchService,
    private workorderFactory: WorkOrderFactory
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listComponent.getResolver = item => {
      if (this.cachedListResolvers.has(item)) return this.cachedListResolvers.get(item);
      return this.getSearchItemResolver(item);
    };
  }

  private getSearchItemResolver(item) {
		const resolver = new SearchResultItemResolver();
		resolver.getHeader = i => 'WorkOrderKey';
		resolver.getMatchText = i => this.searchService.searchQuery;
		resolver.getValue = i => this.workorderFactory.isAdvancedMode ? (i as AWOModel).WorkOrderKey : (i as StandardWorkOrder).workOrderKey;
		this.cachedListResolvers.set(item, resolver);
		return this.cachedListResolvers.get(item);
	}

  handleWorkOrderClick(dataOnClick) {
    this.workorderClicked.emit(dataOnClick);
  }
}
