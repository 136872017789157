<div class="scrolling-content">
	<mat-action-list class="omni-list-base">
		<mat-list-item class="light-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">tab name</span>
				<input
					class="omni-restrict-overflow"
					name="tab.name"
					id="tab.name"
					[(ngModel)]="tab.name"
					type="text"
					placeholder="enter the tab name"
					(input)="changeTabNameAction()"
					autocomplete="off"
					[matTooltip]="tab.name"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#tabNameTooltip="toggleTooltip"
					[matTooltipDisabled]="tabNameTooltip.isTooltipDisabled"
				/>
			</div>
		</mat-list-item>
		<mat-list-item class="dark-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">content</span>
				<input
					class="control-value omni-restrict-overflow"
					disabled
					[(ngModel)]="tab.content"
					[matTooltip]="tab.content"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#tabContentTooltip="toggleTooltip"
					[matTooltipDisabled]="tabContentTooltip.isTooltipDisabled"
				/>
				<a class="omni-icon-button" mat-icon-button (click)="goTo('content')">
					<img src="assets/next-circled.png" />
				</a>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">layout</span>
				<input
					class="control-value omni-restrict-overflow"
					disabled
					[ngModel]="layout"
					[matTooltip]="layout"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#tabLayoutTooltip="toggleTooltip"
					[matTooltipDisabled]="tabLayoutTooltip.isTooltipDisabled"
				/>
				<a class="omni-icon-button" mat-icon-button (click)="goTo('layout')">
					<img src="assets/next-circled.png" />
				</a>
			</div>
		</mat-list-item>
		<mat-list-item class="dark-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">visibility</span>
				<input
					class="control-value omni-restrict-overflow"
					disabled
					[ngModel]="visibility"
					[matTooltip]="visibility"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#tabVisibilityTooltip="toggleTooltip"
					[matTooltipDisabled]="tabVisibilityTooltip.isTooltipDisabled"
				/>
				<a class="omni-icon-button" mat-icon-button (click)="goTo('visibility')">
					<img src="assets/next-circled.png" />
				</a>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field omni-list-item">
			<div>
				<span class="control-title">theme</span>
				<input
					class="control-value omni-restrict-overflow"
					disabled
					[(ngModel)]="tab.theme.displayName"
					[matTooltip]="tab.theme.displayName"
					matTooltipClass="tooltip"
					matTooltipPosition="above"
					[appTooltip]
					#tabThemeTooltip="toggleTooltip"
					[matTooltipDisabled]="tabThemeTooltip.isTooltipDisabled"
				/>
				<button class="omni-icon-button" mat-icon-button (click)="goTo('theme')">
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions class="tab-settings-footer">
		<button mat-button *ngIf="!isSettingDirty && hasDeletePermission" class="omni-md-font" (click)="openDeletePrompt()"><img class="omni-sm-img" src="assets/delete.png" /> remove tab</button>
		<button mat-button *ngIf="isSettingDirty" class="omni-md-font" [class.disabled]="isSaving" (click)="!isSaving && cancel()"><img class="omni-sm-img" src="assets/x-close.png" /> cancel</button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" [class.disabled]="!isSettingDirty || isSaving" (click)="isSettingDirty && !isSaving && save()" [ngClass]="{ disabled: !isSettingDirty }">
			<img class="omni-sm-img" src="assets/save.png" /> save
		</button>
	</mat-card-actions>
</mat-card-footer>
