import { OmniModel } from '../../models/omni-model';
import { StandardWorkAssetContract } from '../../contracts/work-order/index';
import * as SedaruUtils from '../../sedaru-util/index';

export class StandardWorkAsset extends OmniModel<StandardWorkAsset, StandardWorkAssetContract> {
    private _objectId: SedaruUtils.Undoable<number>;
    get objectId(): number {
        if (!this._objectId) this._objectId = new SedaruUtils.Undoable<number>(0);
        return this._objectId.value;
    }
    set objectId(value: number) {
        if (!this._objectId) this._objectId = new SedaruUtils.Undoable<number>();
        this._objectId.value = value;
    }

    private _assetKey: SedaruUtils.Undoable<string>;
    get assetKey(): string {
        if (!this._assetKey) this._assetKey = new SedaruUtils.Undoable<string>();
        return this._assetKey.value;
    }
    set assetKey(value: string) {
        if (!this._assetKey) this._assetKey = new SedaruUtils.Undoable<string>();
        this._assetKey.value = value;
    }

    private _assetType: SedaruUtils.Undoable<string>;
    get assetType(): string {
        if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
        return this._assetType.value;
    }
    set assetType(value: string) {
        if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();
        this._assetType.value = value;
    }

    private _workOrderKey: SedaruUtils.Undoable<string>;
    get workOrderKey(): string {
        if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
        return this._workOrderKey.value;
    }
    set workOrderKey(value: string) {
        if (!this._workOrderKey) this._workOrderKey = new SedaruUtils.Undoable<string>();
        this._workOrderKey.value = value;
    }

    private _isCompleted: SedaruUtils.Undoable<number>;
    get isCompleted(): number {
        if (!this._isCompleted) this._isCompleted = new SedaruUtils.Undoable<number>(0);
        return this._isCompleted.value;
    }
    set isCompleted(value: number) {
        if (!this._isCompleted) this._isCompleted = new SedaruUtils.Undoable<number>();
        this._isCompleted.value = value;
    }

    private _workInspectionKey: SedaruUtils.Undoable<string>;
    get workInspectionKey(): string {
        if (!this._workInspectionKey) this._workInspectionKey = new SedaruUtils.Undoable<string>();
        return this._workInspectionKey.value;
    }
    set workInspectionKey(value: string) {
        if (!this._workInspectionKey) this._workInspectionKey = new SedaruUtils.Undoable<string>();
        this._workInspectionKey.value = value;
    }

    private _systemId: SedaruUtils.Undoable<string>;
    get systemId(): string {
        if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
        return this._systemId.value;
    }
    set systemId(value: string) {
        if (!this._systemId) this._systemId = new SedaruUtils.Undoable<string>();
        this._systemId.value = value;
    }

    private _assetOID: SedaruUtils.Undoable<number>;
    get assetOID(): number {
        if (!this._assetOID) this._assetOID = new SedaruUtils.Undoable<number>(0);
        return this._assetOID.value;
    }
    set assetOID(value: number) {
        if (!this._assetOID) this._assetOID = new SedaruUtils.Undoable<number>();
        this._assetOID.value = value;
    }

    static fromContract(contract: StandardWorkAssetContract): StandardWorkAsset {
        const model = new StandardWorkAsset();
        model.objectId = contract.ObjectId;
        model.assetKey = contract.AssetKey;
        model.assetType = contract.AssetType;
        model.workOrderKey = contract.WorkOrderKey;
        model.isCompleted = contract.IsCompleted;
        model.workInspectionKey = contract.WorkInspectionkey;
        model.systemId = contract.SystemId;
        model.assetOID = contract.AssetOID;
        return model;
    }

    getContract(): StandardWorkAssetContract {
        const contract = new StandardWorkAssetContract();
        contract.ObjectId = this.objectId;
        contract.AssetKey = this.assetKey;
        contract.AssetType = this.assetType;
        contract.WorkOrderKey = this.workOrderKey;
        contract.IsCompleted = this.isCompleted;
        contract.WorkInspectionkey = this.workInspectionKey;
        contract.SystemId = this.systemId;
        contract.AssetOID = this.assetOID;
        return contract;
    }

    getDirtyFieldsFeature(): any {
		const obj = {};
		for (const propName of Object.keys(this)) {
			if (!propName || !(this[propName] instanceof SedaruUtils.UndoableBase)) continue;

			const value = this[propName] as SedaruUtils.Undoable<any>;
			if ((!value || !value.isDirty)) continue;

			obj[propName.substring(1).toLowerCase()] = value.value;
		}

		if (!obj['objectid'] && this.objectId) obj['objectid'] = this.objectId;

		return obj;
	}

    onUpdateInformation(copyModel: StandardWorkAsset): boolean {
        let wasChanged = false;
		wasChanged =
			this.updateIfNotDirty<number>(this._objectId, () => {
				this.objectId = copyModel.objectId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetKey, () => {
				this.assetKey = copyModel.assetKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._workOrderKey, () => {
				this.workOrderKey = copyModel.workOrderKey;
            }) || wasChanged;
        wasChanged =
			this.updateIfNotDirty<number>(this._isCompleted, () => {
				this.isCompleted = copyModel.isCompleted;
            }) || wasChanged;
        wasChanged =
			this.updateIfNotDirty<string>(this._workInspectionKey, () => {
				this.workInspectionKey = copyModel.workInspectionKey;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._systemId, () => {
				this.systemId = copyModel.systemId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<number>(this._assetOID, () => {
				this.assetOID = copyModel.assetOID;
			}) || wasChanged;
		return wasChanged;
    }

    isNew = () => this.objectId === 0;
}
