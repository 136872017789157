import { ListItemResolver } from '../list-item.resolver';
import { Metric, MetricIcon } from 'models';
import { ListTemplates } from '../templates.enum';

export class TwoIconsTwoLinesItemResolver extends ListItemResolver {
	templateType = ListTemplates.twoIconsTwoLines;

	getTopLabel: (item: any) => string;
	private _topLabel: string;
	private _topLabelRequested = false;

	getBottomLeftLabel: (item: any) => string;
	private _bottomLeftLabel: string;
	private _bottomLeftLabelRequested = false;

	getBottomRightLabel: (item: any) => string;
	private _bottomRightLabel: string;
	private _bottomRightLabelRequested = false;

	getLeftIconPath: (item: any) => string | MetricIcon;
	private _leftIconPath: string | MetricIcon;
	private _leftIconPathRequested = false;

	getRightIconPath: (item: any) => string;
	private _rightIconPath: string;
	private _rightIconPathRequested = false;

	getLeftBorderStyle: (item: any) => string;
	private _leftBorderStyle: string;
	private _leftBorderStyleRequested = false;

	getBottomBorderStyle: (item: any) => string;
	private _bottomBorderStyle: string;
	private _bottomBorderStyleRequested = false;

	getBadgeLabel: (item: any) => string;
	private _badgeLabel: string;
	private _badgeLabelRequested = false;

	getBadgeBackgroundColor: (item: any) => string;
	private _badgeBackgroundColor: string;
	private _badgeBackgroundColorRequested = false;

	getLeftImageBorderStyle: (item: any) => string;
	private _leftImageBorderStyle: string;
	private _leftImageBorderStyleRequested = false;

	onShowRightIconOnHover: (item: any) => boolean;
	private _showRightIconOnHover: boolean;
	private _showRightIconOnHoverRequested = false;

	onShowLeftBorder: (item: any) => boolean;
	private _showLeftBorder: boolean;
	private _showLeftBorderRequested = false;

	onShowBottomBorder: (item: any) => boolean;
	private _showBottomBorder: boolean;
	private _showBottomBorderRequested = false;

	onShowLeftImageBorder: (item: any) => boolean;
	private _showLeftImageBorder: boolean;
	private _showLeftImageBorderRequested = true;

	onIsForceSelected: (item: any) => boolean;
	private _isForceSelected: boolean;
	private _isForceSelectedRequested = false;

	_getTopLabel(item: any): string {
		if (!this._topLabel && !this._topLabelRequested) {
			this._topLabel = this.getTopLabel ? this.getTopLabel(item) : '';
			this._topLabelRequested = true;
		}
		return this._topLabel;
	}

	_getBottomLeftLabel(item: any): string {
		if (!this._bottomLeftLabel && !this._bottomLeftLabelRequested) {
			this._bottomLeftLabel = this.getBottomLeftLabel ? this.getBottomLeftLabel(item) : '';
			this._bottomLeftLabelRequested = true;
		}
		return this._bottomLeftLabel;
	}

	_getBottomRightLabel(item: any): string {
		if (!this._bottomRightLabel && !this._bottomRightLabelRequested) {
			this._bottomRightLabel = this.getBottomRightLabel ? this.getBottomRightLabel(item) : '';
			this._bottomRightLabelRequested = true;
		}
		return this._bottomRightLabel;
	}

	_getLeftIconPath(item: any): string | MetricIcon {
		if (!this._leftIconPath && !this._leftIconPathRequested) {
			this._leftIconPath = this.getLeftIconPath ? this.getLeftIconPath(item) : '';
			this._leftIconPathRequested = true;
		}
		return this._leftIconPath;
	}

	_getRightIconPath(item: any): string {
		if (!this._rightIconPath && !this._rightIconPathRequested) {
			this._rightIconPath = this.getRightIconPath ? this.getRightIconPath(item) : '';
			this._rightIconPathRequested = true;
		}
		return this._rightIconPath;
	}

	_getLeftBorderStyle(item: any): string {
		if (!this._leftBorderStyle && !this._leftBorderStyleRequested) {
			this._leftBorderStyle = this.getLeftBorderStyle ? this.getLeftBorderStyle(item) : '';
			this._leftBorderStyleRequested = true;
		}
		return this._leftBorderStyle;
	}

	_getBottomBorderStyle(item: any): string  {
		if (!this._bottomBorderStyle && !this._bottomBorderStyleRequested) {
			this._bottomBorderStyle = this.getBottomBorderStyle ? this.getBottomBorderStyle(item) : '';
			this._bottomBorderStyleRequested = true;
		}
		return this._bottomBorderStyle;
	}

	_getBadgeLabel(item: any): string {
		if (!this._badgeLabel && !this._badgeLabelRequested) {
			this._badgeLabel = this.getBadgeLabel ? this.getBadgeLabel(item) : '';
			this._badgeLabelRequested = true;
		}
		return this._badgeLabel;
	}

	_getBadgeBackgroundColor(item: any): string {
		if (!this._badgeBackgroundColor && !this._badgeBackgroundColorRequested) {
			this._badgeBackgroundColor = this.getBadgeBackgroundColor ? this.getBadgeBackgroundColor(item) : '#00b0f0';
			this._badgeBackgroundColorRequested = true;
		}
		return this._badgeBackgroundColor;
	}

	_getLeftImageBorderStyle(item: any): string {
		if (!this._leftImageBorderStyle && !this._leftImageBorderStyleRequested) {
			this._leftImageBorderStyle = this.getLeftImageBorderStyle ? this.getLeftImageBorderStyle(item) : '';
			this._leftImageBorderStyleRequested = true;
		}
		return this._leftImageBorderStyle;
	}

	_onShowRightIconOnHover(item: any): boolean {
		if (!this._showRightIconOnHover && !this._showRightIconOnHoverRequested) {
			this._showRightIconOnHover = this.onShowRightIconOnHover ? this.onShowRightIconOnHover(item) : false;
			this._showRightIconOnHoverRequested = true;
		}
		return this._showRightIconOnHover;
	}

	_onShowLeftBorder(item: any): boolean {
		if (!this._showLeftBorder && !this._showLeftBorderRequested) {
			this._showLeftBorder = this.onShowLeftBorder ? this.onShowLeftBorder(item) : false;
			this._showLeftBorderRequested = true;
		}
		return this._showLeftBorder;
	}

	_onShowBottomBorder(item: any): boolean {
		if (!this._showBottomBorder && !this._showBottomBorderRequested) {
			this._showBottomBorder = this.onShowBottomBorder ? this.onShowBottomBorder(item) : false;
			this._showBottomBorderRequested = true;
		}
		return this._showBottomBorder;
	}

	_onShowLeftImageBorder(item: any): boolean {
		if (!this._showLeftImageBorder && !this._showLeftImageBorderRequested) {
			this._showLeftImageBorder = this.onShowLeftImageBorder ? this.onShowLeftImageBorder(item) : false;
			this._showLeftImageBorderRequested = true;
		}
		return this._showLeftImageBorder;
	}

	_onIsForceSelected(item: any): boolean {
		if (!this._isForceSelected && !this._isForceSelectedRequested) {
			this._isForceSelected = this.onIsForceSelected ? this.onIsForceSelected(item) : false;
			this._isForceSelectedRequested = true;
		}
		return this._isForceSelected;
	}

	resetFlags(): void {
		this._topLabel = this._bottomLeftLabel = this._bottomRightLabel = this._leftIconPath = this._rightIconPath
			= this._leftBorderStyle = this._bottomBorderStyle = this._badgeLabel = this._badgeBackgroundColor = this._showRightIconOnHover
			= this._showLeftBorder = this._showBottomBorder = this._isForceSelected = this._showLeftImageBorder = this._leftImageBorderStyle = null;

		this._topLabelRequested = this._bottomLeftLabelRequested = this._bottomRightLabelRequested = this._leftIconPathRequested = this._rightIconPathRequested
			= this._leftBorderStyleRequested = this._bottomBorderStyleRequested = this._badgeLabelRequested = this._badgeBackgroundColorRequested = this._showRightIconOnHoverRequested
			= this._showLeftBorderRequested = this._showBottomBorderRequested = this._isForceSelectedRequested = this._showLeftImageBorderRequested = this._leftImageBorderStyleRequested = false;
	}
}
