import { Metric } from '../../../models/metric.model';
import { Injectable } from '@angular/core';
import esri = __esri; // Esri TypeScript Types
import { EsriSdkService } from './esri-sdk.service';
import { CanvasMapService } from './canvas-map.service';
import { Canvas } from 'omni-model/canvas.model';
import { CanvasTab } from '../../../omni-model/canvas-tab.model';
import { GuiConfigService } from 'domain-service/gui-config.service';
import { ChannelTypes, Color, MetricTile } from 'models';
import { WorkOrderSourceType } from 'models/work-order-source-type.enum';
import { GeometryService } from './geometry.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { Pages } from 'app/navigation/inavigation';
import { UserService } from 'app/user/user.service';
import { GuiConfig } from 'omni-model/gui-config.model';
import { WorkOrderSummary, WorkOrderWrapper, AdvancedWorkOrder } from 'models/work-order';
import { OmniInteropService } from 'domain-service/omni-interop.service';
import { IdentifyTask } from 'sedaru-util/esri-core/identify-task';
import { FindResultItem } from 'sedaru-util/esri-core/find-result';
import { AssetRecord } from 'models/records/asset-record.model';
import { PNGManager } from 'app/ui-components/png-manager';
import { RecordContextGroup } from 'models/records/record-context-group';
import { Feature } from 'sedaru-util/esri-core';
import { HistoryRecord } from 'models/records/history-record.model';
import { WorkOrderFactory } from 'domain-service/work-order-factory';
import { WorkOrderSummaryActions } from 'models/work-order/work-order-summary-actions.enum';
import { ArcGisFeature } from 'app/metric/arc-gis-feature.model';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { MetricViewService } from 'app/metric/metric-view.service';
import { MapActionType, MetricViewEventArg, TableActionType } from 'app/metric/metric-view-event-arg.model';

/**
 * The OmniMapGraphicsService extends the OmniMapService. It hosts the functions specific to graphics layers.
 */
 @Injectable({
	providedIn: 'root'
})
export class OmniMapGraphicsService {
	/**
	 * Holds the Esri SDK functions
	 */
	EsriMapSdk;
	/**
	 * The opacity of the graphic layers that are not focused
	 */
	opacityForUnfocusedLayers = 0.5;

	highlightedFeature;

	currentlySelectedMetric: Metric;

	/**
	 * Initializes the EsriMapSdk once the EsriSdkService is ready.
	 */
	constructor(
		private esriSdkService: EsriSdkService,
		private mapService: CanvasMapService,
		private guiConfigService: GuiConfigService,
		private geometryService: GeometryService,
		private userService: UserService,
		private workOrderFactory: WorkOrderFactory,
		private interopService: OmniInteropService,
		private flashMessageService: FlashMessageService,
		private metricViewService: MetricViewService
	) {
		if (this.esriSdkService.esriMapSdk) {
			this.EsriMapSdk = this.esriSdkService.esriMapSdk;
		} else {
			this.esriSdkService.esriSdkReadyEvent.subscribe(() => {
				this.EsriMapSdk = this.esriSdkService.esriMapSdk;
			});
		}
	}

	convertSP(canvas, geometry) {
		if (!canvas.mapView) return;
		return this.EsriMapSdk.Projection.project(geometry, canvas.mapView.spatialReference);
	}

	getGeomertyFromWO(xCoordinate, yCoordinate) {
		let spatialReference;
		if (this.interopService.arcGISManager.defaultAssetWkText) {
			spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkt: this.interopService.arcGISManager.defaultAssetWkText });
		} else spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkid: this.interopService.arcGISManager.defaultAssetWkid });
		const geometry = new this.esriSdkService.esriMapSdk.Point({ x: xCoordinate, y: yCoordinate, spatialReference });
		return geometry;
	}

	async convertWorkOrdersToFeatures(metric: Metric) {
		const features = [];
		if (metric.definition.isWorkOrderMetric && this.workOrderFactory.isAdvancedMode) {
			const workorders = await metric.result.getWorkorders();
			for (const workorder of workorders) {
				if (!workorder.xCoordinate || !workorder.yCoordinate) continue;
				const fieldNames = this.interopService.templateManager.getTemplateOutFields(metric);
				const attributes = {};
				fieldNames.forEach(fieldName => {
					if (fieldName !== 'geometry' && fieldName !== 'yCoordinate' && fieldName !== 'xCoordinate') {
						attributes[fieldName] = workorder[fieldName];
					}
				});
				attributes['key'] = workorder.workOrderKey;
				attributes['metricType'] = 'workorder';
				let spatialReference;
				if (this.interopService.arcGISManager.defaultAssetWkText) {
					spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkt: this.interopService.arcGISManager.defaultAssetWkText });
				} else spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkid: this.interopService.arcGISManager.defaultAssetWkid });
				const geometry = new this.esriSdkService.esriMapSdk.Point({ x: workorder.xCoordinate, y: workorder.yCoordinate, spatialReference });
				features.push({ geometry, attributes });
			}
		} else {
			const assetFeatures = await metric.result.getFeatures();
			for (const feature of assetFeatures) {
				if (!feature.geometry) continue;
				const geometry = typeof feature.geometry === 'string' ? JSON.parse(feature.geometry) : feature.geometry;
				const attributes = typeof feature.attributes === 'string' ? JSON.parse(feature.attributes) : feature.attributes;
				for (const attributeKey in attributes) {
					if (attributeKey === '') {
						delete attributes[attributeKey];
					}
				}
				const { x, y } = this.geometryService.getPointGeometry(geometry);
				const point = new this.esriSdkService.esriMapSdk.Point({ longitude: x, latitude: y });
				if (metric.definition.isWorkOrderMetric) {
					attributes['metricType'] = 'workOrder';
					attributes['key'] = attributes.workorderkey;
				} else {
					attributes['metricType'] = 'metric';
				}

				features.push({ geometry: point, attributes });
			}
		}
		return features;
	}

	goToGraphicsExtent(canvas: Canvas, graphics: esri.Graphic[]) {
		if (canvas.mapView.spatialReference !== graphics[0].geometry.spatialReference) {
			const inGeo = graphics.map(grpahicGeo => grpahicGeo.geometry);
			graphics = this.convertSP(canvas, inGeo);
		}
		return canvas.mapView.goTo(graphics, { animate: true, duration: 1 });
	}

	moveToLayerExtent(canvas: Canvas, layerId: string) {
		const layer = canvas.mapView.map.findLayerById(layerId) as __esri.FeatureLayer;
		if (!layer) return;
		layer.queryExtent().then(response => {
			if (!response.extent) return;
			const projectedExtent = this.esriSdkService.esriMapSdk.Projection.project(response.extent, canvas.mapView.spatialReference);
			canvas.mapView.goTo(projectedExtent, { animate: true, duration: 1000 });
		});
	}

	/**
	 * Given a metric id and a canvas id, hides all other metric graphic layers and
	 * shows only the one provied (if no metric was provided, all graphics will be hidden).
	 * @param {Canvas} canvas - the canvas the holds the graphic layers
	 * @param {string} layerId - The id of the layer that will be set to visible.
	 */
	toggleGraphicLayerVisibility(canvas: Canvas, layerId?: string) {
		if (!canvas.mapView) {
			return;
		}
		canvas.mapView.map.layers.forEach(layer => {
			if ((layerId && layer.id === layerId) || layer.id === 'default-metric-graphic-layer') {
				layer.set('visible', true);
			} else if (layer.type === 'graphics') {
				layer.set('visible', false);
			}
		});
	}

	/**
	 * Removes graphic layer from map (when tile is assigned to a different map
	 * or the default metric changes)
	 * @param {string} canvas - the canvas that holds the layer to be removed.
	 * @param {string} layerId - if the layer to be removed is the default metric, the id
	 * is 'default-metric-graphic-layer'. If the layer is the representation of a metric,
	 * the layerId is the metric's id.
	 */
	removeLayerFromMap(canvas: Canvas, layerId: string) {
		if (!layerId) return;
		const layerToBeRemoved = canvas?.mapView?.map?.findLayerById(layerId);
		if (layerToBeRemoved) {
			canvas.mapView.map.remove(layerToBeRemoved);
		}
	}

	/**
	 * It finds all graphic layers that are linked to a given metric
	 * @param {Metric} metric - the id of the metric to look for
	 */
	removeAllLayersLinkedToMetric(metric: Metric) {
		Object.values(this.guiConfigService.availableConfigurations).forEach(config => {
			config.canvasList.forEach(canvas => {
				if (canvas.mapView) {
					canvas.mapView.map.layers.forEach(layer => {
						if (layer && layer.title === metric.definition.name) {
							this.removeLayerFromMap(canvas, layer.id);
						}
					});
				}
			});
		});
	}

	/**
	 * Moves the map to the extent of its graphic layers
	 * @param canvas - the canvas that holds the map that will move to extent
	 */
	async moveToGraphicsExtent(canvas: Canvas) {
		const visibleGraphicLayers = [];
		canvas.mapView.map.layers.forEach(layer => {
			if ((layer.type === 'graphics' || layer.type === 'feature') && layer.visible) {
				const graphicLayer = layer as esri.GraphicsLayer;
				visibleGraphicLayers.push(graphicLayer.graphics);
			}
		});
		await canvas.mapView.goTo(visibleGraphicLayers, { animate: true, duration: 1 });
	}

	getGraphicFeature(attributeValue: any, attributeName: string, layerId: string, canvas: Canvas) {
		let graphicFeature: esri.Graphic = null;
		let layerView: esri.FeatureLayerView = null;
		if (canvas?.map) {
			layerView = canvas.map.layerViews.find(lv => {
				if (lv.layer) return lv.layer.id === layerId;
			});
			if (!layerView) {
				layerView = canvas.map.layerViews.find(dm => {
					if (dm.layer) return dm.layer.id === 'default-metric-graphic-layer';
				});
			}
			if (layerView) {
				graphicFeature = layerView.layer.source.find(graphic => {
					const match = (graphic?.attributes && graphic.attributes.hasOwnProperty(attributeName))
								  && (graphic.attributes[attributeName] === attributeValue);
					return match;
				});
			}
		}
		return {graphicFeature, layerView};
	}

	/**
	 * Check if the given canvas/layer has any graphic items.
	 * @param canvas
	 * @param layerId
	 * @returns
	 */
	layerHasGraphicItems(canvas: Canvas, layerId: string): boolean {
		let layerView: esri.FeatureLayerView = null;
		if (canvas?.map) {
			layerView = canvas.map.layerViews.find(lv => {
				if (lv.layer) return lv.layer.id === layerId;
			});
			if (!layerView) {
				layerView = canvas.map.layerViews.find(dm => {
					if (dm.layer) return dm.layer.id === 'default-metric-graphic-layer';
				});
			}
			if (layerView) {
				return (layerView?.layer?.source?.length > 0);
			}
		}
		return false;
	}

	highlightFeatureByAttribute(attributeValue: any, attributeName: string, layerId: string, canvas: Canvas, isHighlightLayer?: boolean): Promise<esri.Graphic> {
		return new Promise(solve => {
			const {graphicFeature, layerView} = this.getGraphicFeature(attributeValue, attributeName, layerId, canvas);
			if (!graphicFeature) {
				this.clearHighlightedFeature(canvas);
				solve(null);
				return;
			}
			if (isHighlightLayer) {
				// @ts-ignore
				this.moveFeatureToFront(graphicFeature, layerView.layer).then(newObjectId => {
					if (this.highlightedFeature) this.clearHighlightedFeature(canvas);
					this.highlightedFeature = layerView.highlight(newObjectId);
					solve(graphicFeature);
				});
			} else {
				solve(graphicFeature);
			}
		});
	}


	/**
	 * highlight an object on the map.
	 * @param canvas
	 * @param graphicFeature
	 * @param layerView
	 * @returns
	 */
	highlightFeature(canvas: Canvas, graphicFeature: esri.Graphic, layerView: esri.FeatureLayerView): Promise<esri.Graphic> {
		return new Promise(solve => {
			if (!graphicFeature) {
				this.clearHighlightedFeature(canvas);
				solve(null);
				return;
			}

			// @ts-ignore
			this.moveFeatureToFront(graphicFeature, layerView.layer).then(newObjectId => {
				if (this.highlightedFeature) {
					this.clearHighlightedFeature(canvas);
				}
				this.highlightedFeature = layerView.highlight(newObjectId);
				solve(graphicFeature);
			});

		});
	}

	highlightWorkOrder(workorderKey: string, layerId: string, canvas: Canvas): Promise<esri.Graphic> {
		return new Promise((resolve, reject) => {
			if (!canvas.map) resolve(null);

			const layerView = canvas.map.layerViews.find(lv => {
				if (lv.layer) return lv.layer.id === layerId;
			});
			if (!layerView) resolve(null);
			const woGraphic = layerView.layer.source.find(graphic => graphic.attributes['key'] === workorderKey);
			if (!woGraphic) resolve(null);
			this.moveFeatureToFront(woGraphic, layerView.layer).then(newObjectId => {
				if (this.highlightedFeature) this.clearHighlightedFeature(canvas);
				this.highlightedFeature = layerView.highlight(newObjectId);
				resolve(woGraphic);
			});
		});
	}

	moveFeatureToFront(feature: esri.Graphic, layer: esri.FeatureLayer): Promise<number> {
		return new Promise((solve, reject) => {
			layer.applyEdits({ deleteFeatures: [feature] }).then(deleteEdit => {
				if (!deleteEdit.deleteFeatureResults.length) reject('could not delete feature ' + feature);

				layer.applyEdits({ addFeatures: [feature] }).then(addEdit => {
					if (!addEdit.addFeatureResults.length) reject('could not add feature ' + feature);
					layer.queryFeatures({ objectIds: [addEdit.addFeatureResults[0].objectId] }).then(queryResult => {
						if (!queryResult || !queryResult.features) reject();
						const objectid = queryResult.features[0]?.getObjectId();
						// @ts-ignore
						solve(objectid);
					});
				});
			});
		});
	}

	zoomToWorkorder(workorderKey: string, layerId: string, canvas: Canvas, zoomFactor?: number) {
		if (!canvas.map) return;

		const layerView = canvas.map.layerViews.find(lv => {
			if (lv.layer) return lv.layer.id === layerId;
		});
		if (!layerView) return;
		const woGraphic = layerView.layer.source.find(graphic => graphic.attributes['key'] === workorderKey);
		if (!woGraphic) return;
		// @ts-ignore
		canvas.mapView.goTo({ center: [woGraphic.geometry.x, woGraphic.geometry.y], scale: zoomFactor ? zoomFactor : 1000 });
	}

	clearHighlightedFeature(canvas: Canvas) {
		this.mapService.removeAllGraphicFromGraphicLayer(canvas, 'highlight-assets');
		this.clearWorkorderConeIcon(canvas);
		if (!this.highlightedFeature) return;
		this.highlightedFeature.remove();
	}

	clearWorkorderConeIcon(canvas: Canvas) {
		this.mapService.removeAllGraphicFromGraphicLayer(canvas, 'workorder-cone');
	}

	/**
	 * This function updates a single workorder icon's x and y on the map upon changes in its work asset
	 * @param metric
	 * @param workOrder
	 * @param canvas
	 */
	async updateWorkOrderGraphic(metric: Metric, workOrder: WorkOrderWrapper, canvas: Canvas) {
		// update workorder's x and y coordinates
		this.workOrderFactory.getWorkOrdersXY([workOrder]);

		const layer = canvas.mapView.map.findLayerById(metric.id) as __esri.FeatureLayer;
		if (!layer) return;
		// find the graphic object, they overlaps by workorder key
		const graphicFound = layer.source.find(g => g.attributes.key == workOrder.workOrderKey);
		// create the attributes and geometries
		const workOrderAttributes = { key: workOrder.workOrderKey, metricType: 'workOrder' };
		const geometry = { type: 'point', longitude: workOrder.xCoordinate, latitude: workOrder.yCoordinate };

		// create the new workorder graphic icon
		const newGraphic: esri.Graphic = new this.EsriMapSdk.Graphic({
			geometry,
			attributes: workOrderAttributes,
			spatialReference: canvas.mapView.spatialReference
		});

		// must use apply edits on feature layer, can't mutate directly
		layer.applyEdits({ addFeatures: [newGraphic], deleteFeatures: [graphicFound] });
	}

	/** Method to check whether the geometry can be plotted or not */
	private canPlotGeometryPoint(geometry) {
		return ((geometry.latitude && geometry.longitude) || (geometry.x && geometry.y));
	}

	async addClientFeatureLayer(moveToExtend: boolean, visible: boolean, canvas: Canvas, features, layerId, iconUrl, color?: Color) {
		const graphics = [];
		const fields = [{ name: 'graphicId', type: 'oid' }];

		if (features.length) {
			const attrs = features[0].attributes;
			for (const attrName in attrs) {
				if (attrs.hasOwnProperty(attrName)) {
					let esriValueType = 'string';
					if (typeof attrs[attrName] === 'number') {
						const value = attrs[attrName];
						esriValueType = (value > Math.pow(2, 32) || Math.floor(value) !== value) ? 'double' : 'integer';
					}
					fields.push({ name: attrName, type: esriValueType });
				}
			}
			for (const feature of features) {
				if (!feature.geometry || !this.canPlotGeometryPoint(feature.geometry)) continue;
				const graphic: esri.Graphic = new this.EsriMapSdk.Graphic(feature);
				if (graphic.geometry.spatialReference !== canvas.mapView.spatialReference) {
					const geometry = this.convertSP(canvas, graphic.geometry);
					graphic.geometry = geometry;
				}
				graphics.push(graphic);
			}
		}

		if (color) {
			iconUrl = await PNGManager.editImage(iconUrl, { backgroundColor: color });
		}

		const layerProps = {
			id: layerId,
			title: 'metric-results',
			source: graphics,
			objectIdField: 'graphicId',
			fields,
			visible,
			geometryType: 'point',
			renderer: {
				type: 'simple',
				symbol: { type: 'picture-marker', url: iconUrl, width: '30px', height: '30px' }
			}
		};

		this.mapService.featureLayerClient['f_' + layerProps.id] = new this.esriSdkService.esriMapSdk.FeatureLayer(layerProps);
		const featurLayerGraphics = this.mapService.featureLayerClient['f_' + layerProps.id];

		canvas.mapView.map.add(featurLayerGraphics);
		canvas.mapView.whenLayerView(featurLayerGraphics).then(layerView => {
			// @ts-ignore
			canvas.map.layerViews.push(layerView);
			canvas.map.pacifier.show = false;
		});
		await featurLayerGraphics.load();
		if (moveToExtend) await this.goToGraphicsExtent(canvas, graphics);
	}

	startIdentifyTask(canvas: Canvas) {
		if (canvas.map.eventHandlers['workOrderAssetSelectionHandler']) {
			canvas.map.eventHandlers['workOrderAssetSelectionHandler'].remove();
			delete canvas.map.eventHandlers['workOrderAssetSelectionHandler'];
		}

		this.identifyTaskOnMapView(canvas);
	}

	/**
	 * Adding click event listener to map-view on the selected canvas
	 * @param {string} canvas  - the selected canvas
	 * @param {string} mapName - unique map name for the data source
	 */
	identifyTaskOnMapView(canvas: Canvas) {
		const mapView: esri.MapView = canvas.mapView;
		if (!mapView) {
			return;
		}
		canvas.map.eventHandlers['identifyTaskHandler'] = mapView.on('click', async mapEvent => {
			if (canvas.isDrawPolygonActive) {
				console.log('polygon active');
				return;
			}
			canvas.map.pacifier.left = mapEvent.x;
			canvas.map.pacifier.top = mapEvent.y;
			canvas.map.pacifier.show = true;
			this.clearHighlightedFeature(canvas);

			canvas.mapView.hitTest(mapEvent).then(async hitTestResult => {
				if (!hitTestResult || !hitTestResult.results || !hitTestResult.results.length) return this.onMapAssetClicked(canvas, mapEvent.mapPoint);

				const graphicResults = hitTestResult.results.filter(result => {
					return result.graphic.layer
						? result.graphic.layer.type === 'feature' && result.graphic.layer.opacity === 1 && this.mapService.featureLayerClient['f_' + result.graphic.layer.id]
						: '';
				});

				if (graphicResults && graphicResults.length) return this.onMetricIconClicked(canvas, graphicResults[0].graphic);
				return this.onMapAssetClicked(canvas, mapEvent.mapPoint);
			});
		});
	}

	async onMetricIconClicked(canvas: Canvas, graphicObj: esri.Graphic) {
		const graphicFeatureLayer = this.mapService.featureLayerClient['f_' + graphicObj.layer.id];
		if (!graphicFeatureLayer) return;

		const objectId = graphicObj.attributes.graphicId;
		const queryFeaturesResults = await graphicFeatureLayer.queryFeatures({
			objectIds: [objectId],
			returnGeometry: true,
			outFields: ['*']
		});

		if (!queryFeaturesResults?.features?.length) {
			canvas.map.pacifier.show = false;
			return;
		}

		const _feature = queryFeaturesResults.features[0];
		const activeTab = canvas.tabs.find(tab => tab.active);
		const metric = activeTab.tile ? activeTab.tile.metric : activeTab.defaultMetric;

		const metricEventArg: MetricViewEventArg = new MetricViewEventArg();
		metricEventArg.showInSidePanel = true;
		metricEventArg.tableAction = TableActionType.Highlight;
		metricEventArg.mapAction = MapActionType.ZoomOnItem;

		await this.metricViewService
				.triggerMetricEvent(metric, _feature.attributes, metricEventArg);

		canvas.map.pacifier.show = false;
	}

	highlightTableRow(metric: Metric, feature: any) {
		const tableComponent = this.interopService.uiManager.activeCanvasComponent?.canvasTableComponent;
		if (tableComponent) {
			tableComponent.clearHighlights();
			let uniqueRowItem = null,
				isAdvancedWO = false;
			let uniqueFieldName, uniqueKeyValue;
			switch (metric.definition.source.type) {
				case ChannelTypes.History:
					uniqueFieldName = metric.definition.historyChannel.attributes['assetIdFieldName'];
					uniqueKeyValue = feature.attributes[uniqueFieldName];
					break;
				case ChannelTypes.WorkOrder:
					uniqueFieldName = 'workOrderKey';
					uniqueKeyValue = feature.attributes[uniqueFieldName];
					isAdvancedWO = true;
					break;
				default:
					uniqueFieldName = metric.definition.assetChannel.attributes['uniqueFieldName'];
					uniqueKeyValue = feature.attributes[uniqueFieldName];
					break;
			}
			uniqueRowItem = { key: uniqueFieldName, value: uniqueKeyValue, isAdvancedWO };
			tableComponent.highlightRecord(uniqueRowItem);
		}
	}

	async onMapAssetClicked(canvas: Canvas, geomerty: esri.Geometry) {
		const obtainedResults = await this.getIdentifyTaskResult(canvas, geomerty, true);
		if (!obtainedResults.length) {
			canvas.map.pacifier.show = false;
			return;
		}

		const features = obtainedResults.map(result => result.feature);

		if (features.length === 1) {
			const assetDefinition = this.userService.globalConfig.getAssetDefinition(this.mapService.activeDataSource.legacyId, obtainedResults[0].layerId);
			const assetRecord = AssetRecord.create(assetDefinition, features[0]);
			const context = new RecordContextGroup({ assetRecord: assetRecord });

			this.mapService.highlightAssets(canvas, features).then(graphics => {
				const currentLOD = canvas.mapView.zoom;
				canvas.mapView.goTo({ target: graphics, zoom: currentLOD }, { duration: 1000 });
				this.showMapToolTip(graphics[0], context);
				canvas.map.pacifier.show = false;
			});
			if (!assetDefinition) {
				NavigationService.navigateTo(Pages.mapAssetAttributes, { feature: features[0], assetType: assetRecord?.assetType });
				return;
			}

			let page = Pages.assetInformation;
			if (assetDefinition.useHierarchy) page = Pages.hierarchy;
			NavigationService.navigateTo(page, { recordContext: context });
		} else {
			const newObtainedResult = obtainedResults.map((result) => {
				const assetDefinition = this.userService.globalConfig.getAssetDefinition(this.mapService.activeDataSource.legacyId, result.layerId);
				if (assetDefinition) result.layerName = assetDefinition.assetType;
				return result;
			});
			NavigationService.navigateTo(Pages.mapAssetsList, { mode: 'asset selection', identifyResults: newObtainedResult });
		}
	}

	showMapToolTip(graphic, context: RecordContextGroup) {
		const mapToolTip = this.interopService.uiManager.activeCanvasComponent.canvasMapComponent.mapTooltip;
		const point = graphic.geometry.extent
			? graphic.geometry.extent.center
			: new this.esriSdkService.esriMapSdk.Point({ latitude: graphic.geometry.latitude, longitude: graphic.geometry.longitude });

		mapToolTip.location = point;
		mapToolTip.resolver = this.interopService.templateManager.getTemplateResolver(context);
		mapToolTip.open();
	}

	closeMapToolTip() {
		this.interopService.uiManager.activeCanvasComponent?.canvasMapComponent?.mapTooltip.close();
	}

	public async getIdentifyTaskResult(canvas: Canvas, geomerty: esri.Geometry, showOnlyVisibleLayer: boolean): Promise<FindResultItem[]> {
		this.mapService.activeDataSource = this.mapService.availableDataSources[canvas.map.name];
		const mapView: esri.MapView = canvas.mapView;
		const dataSourceUrl = this.mapService.activeDataSource.url;
		// Set the parameters for the Identify
		const identifyTask = new IdentifyTask();
		identifyTask.tolerance = 10;
		identifyTask.mapWidth = mapView.width;
		identifyTask.mapHeight = mapView.height;
		identifyTask.geometry = geomerty;
		if (geomerty.type == 'polygon' || geomerty.type == 'polyline') {
			identifyTask.geometryType = 'esriGeometryPolygon';
		} else {
			identifyTask.geometryType = 'esriGeometryPoint';
		}
		identifyTask.mapExtent = mapView.extent;
		const esriServer = this.interopService.arcGISManager.getArcGISService(this.mapService.activeDataSource.legacyId);
		const response = await esriServer.identify(identifyTask);
		return response.results;
	}

	async startWorkOrderCreation(canvas: Canvas, identifiedItem: AssetRecord) {
		const color = new Color();
		color.fromRGBA('rgb(236, 137, 56)');
		const graphics = await this.mapService.getPointGraphics([identifiedItem.feature], 'assets/workorder-white.png', color);
		graphics[0].geometry.spatialReference = identifiedItem.feature.geometry.spatialReference;
		const workorderConeGrapicLayer = this.mapService.getLayerByLayerId('workorder-cone', canvas.mapView.map);
		if (workorderConeGrapicLayer.graphics.length) workorderConeGrapicLayer.graphics.removeAll();
		canvas.mapView.map.layers.reorder(workorderConeGrapicLayer, canvas.mapView.map.layers.length - 1);
		workorderConeGrapicLayer.addMany(graphics);
		canvas.mapView.goTo(graphics, { animate: true, duration: 1 });
		this.startIdentifyTask(canvas);
		const workOrderKeys = await this.workOrderFactory.generateWorkOrderKey();
		if (!workOrderKeys) {
			this.flashMessageService.popMessage('Cannot create workorder. Could not generate a valid workorder key.');
			return;
		}
		const workOrder = this.workOrderFactory.createWorkOrderModel(workOrderKeys[0]);
		this.workOrderFactory.createWorkAssetModel(workOrder, identifiedItem);
		// this.workOrderFactory.generateWorkOrderKey(workOrder);
		NavigationService.navigateTo(Pages.workorderSummary, { workOrderSummary: new WorkOrderSummary(WorkOrderSummaryActions.CreateSingleWO, [workOrder], [identifiedItem]) });
		this.mapService.removeAllGraphicFromGraphicLayer(canvas, 'highlight-assets');
	}

	startAssetSelectionForWorkAsset(canvas: Canvas, metric: Metric, workOrder?: WorkOrderWrapper) {
		if (!canvas || !canvas.mapView) return;
		this.currentlySelectedMetric = metric;
		if (canvas.map.eventHandlers['workAssetSelectionHandler']) return;
		canvas.map.eventHandlers['workAssetSelectionHandler'] = canvas.mapView.on('click', async mapEvent => {
			console.log('clicked in work asset selection');
			const results = await this.getIdentifyTaskResult(canvas, mapEvent.mapPoint, true);
			const newObtainedResult = results.map((result) => {
				const assetDefinition = this.userService.globalConfig.getAssetDefinition(this.mapService.activeDataSource.legacyId, result.layerId);
				if (assetDefinition) {
					result.layerName = assetDefinition.assetType;
					return result;
				}
			}).filter(element => element !== undefined);
			if (!results.length) return;
			NavigationService.navigateTo(Pages.mapAssetsList, {
				mode: 'add workasset',
				identifyResults: newObtainedResult,
				workOrder
			});
		});
	}

	/**
	 * This function starts the asset selection process.
	 * @param {Canvas} canvas - The selected canvas
	 * @param {() => void} cancelRequest - The cancel request function
	 */
	startAssetSelectionForWorkOrder(canvas: Canvas) {
		if (!canvas || !canvas.mapView) return;
		canvas.map.eventHandlers['workOrderAssetSelectionHandler'] = canvas.mapView.on('click', async mapEvent => {
			console.log('clicked in work order asset selection');
			canvas.map.pacifier.left = mapEvent.x;
			canvas.map.pacifier.top = mapEvent.y;
			canvas.map.pacifier.show = true;
			this.clearHighlightedFeature(canvas);
			canvas.map['dropWorkOrderCone'] = false;
			const results = await this.getIdentifyTaskResult(canvas, mapEvent.mapPoint, true);
			this.mapService.removeAllGraphicFromGraphicLayer(canvas, 'highlight-assets');
			const features = results.map(r => r.feature);
			this.mapService.highlightAssets(canvas, features);
			canvas.map.pacifier.show = false;
			if (!results.length || results.length > 1) {
				return NavigationService.navigateTo(Pages.mapAssetsList, { mode: 'create workorder', identifyResults: results });
			}
			const definition = this.userService.globalConfig.getAssetDefinition(this.mapService.activeDataSource.legacyId, results[0].layerId);
			const assetRecord = AssetRecord.create(definition, results[0].feature);
			this.startWorkOrderCreation(canvas, assetRecord);
		});
	}

	startWorkOrderAssetSelection(canvas: Canvas) {
		if (canvas.map.eventHandlers['identifyTaskHandler']) {
			canvas.map.eventHandlers['identifyTaskHandler'].remove();
			delete canvas.map.eventHandlers['identifyTaskHandler'];
		}

		this.startAssetSelectionForWorkOrder(canvas);
	}

	/**
	 *
	 * @param id - usually, the id of the metric that will become the identifier of the layer.
	 * If it is a default metric, use 'default-metric-graphic-layer' as the id.
	 * @param title - the 'friendly name' that the graphic layer will hold. Usually, the metric's name.
	 * @param graphics - the graphic points to be plotted in the layer.
	 */
	private createGraphicLayer(id: string, title: string, graphics: esri.Graphic[]): esri.GraphicsLayer {
		const graphicLayer: esri.GraphicsLayer = new this.EsriMapSdk.GraphicsLayer();
		graphicLayer.set('id', id);
		graphicLayer.set('title', title);
		graphicLayer.set('visible', true);
		graphicLayer.addMany(graphics);
		return graphicLayer;
	}

	plotMetricResultsToMap(canvas: Canvas, layerId: string, metricResults: ArcGisFeature[] | AdvancedWorkOrder[], iconUrl: string, isAWO = false, moveToExtend = false, outfields: string[] = []) {
		if (!canvas.mapView) return;

		this.clearWorkorderConeIcon(canvas);
		const features = this.parseGeometryForMetricResults(metricResults, isAWO, outfields);

		canvas.map.pacifier.top = undefined;
		canvas.map.pacifier.left = undefined;
		canvas.map.pacifier.show = true;

		return this.addClientFeatureLayer(moveToExtend, true, canvas, features, layerId, iconUrl);
	}

	parseGeometryForMetricResults(metricResults: ArcGisFeature[] | AdvancedWorkOrder[], isAWO: boolean, outfields: string[]) {
		const features = [];
		if (isAWO) {
			(metricResults as AdvancedWorkOrder[]).forEach(result => {
				const attributes = {};
				attributes['key'] = result.workOrderKey;
				attributes['metricType'] = 'workorder';
				outfields.forEach(outfield => {
					if (outfield === 'geometry' || outfield === 'yCoordinate' || outfield === 'xCoordinate') return;
					attributes[outfield] = result[outfield];
				});
				let spatialReference, geometry;
				if (this.interopService.arcGISManager.defaultAssetWkText) {
					spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkt: this.interopService.arcGISManager.defaultAssetWkText });
				} else spatialReference = new this.esriSdkService.esriMapSdk.MapGeometrySpatialReference({ wkid: this.interopService.arcGISManager.defaultAssetWkid });
				geometry = (result.xCoordinate !== 0 && result.yCoordinate !== 0)
					? new this.esriSdkService.esriMapSdk.Point({ x: result.xCoordinate, y: result.yCoordinate, spatialReference })
					: null;
				features.push({ geometry, attributes });
			});
		} else {
			(metricResults as ArcGisFeature[]).forEach(feature => {
				if (!feature.geometry) return;
				const geometry = typeof feature.geometry === 'string' ? JSON.parse(feature.geometry) : feature.geometry;
				const attributes = typeof feature.attributes === 'string' ? JSON.parse(feature.attributes) : feature.attributes;
				for (const attributeKey in attributes) {
					if (attributeKey === '') {
						delete attributes[attributeKey];
					}
				}
				const { x, y } = this.geometryService.getPointGeometry(geometry);
				const point = new this.esriSdkService.esriMapSdk.Point({ longitude: x, latitude: y });
				if (attributes.workorderkey) {
					attributes['metricType'] = 'workOrder';
					attributes['key'] = attributes.workorderkey;
				} else {
					attributes['metricType'] = 'metric';
				}

				features.push({ geometry: point, attributes });
			});
		}
		return features;
	}
}
