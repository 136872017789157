<div class="scrolling-content time-frame" #timeFrameFieldWrapper>
	<app-drop-down
		*ngIf="showFieldComponent"
		mode="selection"
		[headerPlaceHolder]="'select a field'"
		[headerColor]="defaultSedaruGrey"
		[opened]="itemToEdit === 2"
		[itemList]="fieldList"
		[selectedItem]="timeFrameSetting.field"
		(itemSelected)="onFieldSelected($event)"
		[maxHeight]="maxHeightOfField"
	></app-drop-down>
	<app-drop-down
		*ngIf="showOperatorComponent"
		mode="selection"
		headerPlaceHolder="select an operator"
		[headerColor]="sedaruGrey"
		[opened]="itemToEdit === 3"
		[itemList]="operatorList"
		[selectedItem]="timeFrameSetting.operator"
		(itemSelected)="onOperatorSelected($event)"
		[maxHeight]="maxHeightOfField"
	></app-drop-down>
	<app-drop-down
		*ngIf="showValueComponent"
		[mode]="valueEditingMode"
		[headerPlaceHolder]="headerForValuePanel"
		[headerColor]="defaultSedaruGrey"
		[opened]="expansionShouldOpen"
		[itemList]="valueList"
		[selectedItem]="timeFrameSetting.value"
		(itemSelected)="onValueChanged($event)"
		[expansionDisabled]="isFreeText || isDisabled"
		[maxHeight]="maxHeightOfField"
	></app-drop-down>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="end">
		<button mat-button class="omni-md-font" (click)="onDelete()" *ngIf="showDelete"><img class="omni-sm-img" src="assets/delete.png" /> remove</button>
		<span style="flex:1"></span>
		<button mat-button class="omni-md-font" (click)="save()">done <img class="omni-sm-img" src="assets/done.png" /></button>
	</mat-card-actions>
</mat-card-footer>
