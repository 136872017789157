import { Employee, Material, Equipment, StandardWorkAssets, WorkOrderWrapper, WorkOrderMetaData, WorkAssetWrappers, WorkOrderWrappers, StandardWorkAsset, WorkAssetWrapper, WorkOrderCapabilities } from '../models/work-order';
import { ArcGISAssetChannelAttributes, ArcGISField, ArcGISWorkOrderChannelAttributes, FieldConfigInput, OmniModel, WorkOrderField, List, ListItem } from '../models';
import { ApplyEditsTransaction, Feature, FeatureLayer, LayerApplyEditsOperation, Server } from '../sedaru-util/esri-core';
import { StandardWorkOrderService } from '../domain-service/standard-work-order.service';
import { StandardWorkOrder } from '../models/work-order/standard-work-order.model';
import { StandardWorkOrders } from '../models/work-order/standard-work-orders.model';
import { StandardCustomFieldContract, StandardEquipmentCostContract, StandardMaterialCostContract, StandardWorkOrderContract, StandardWorkOrderContractWithoutNestedFields, StatusContract } from '../contracts/work-order';
import { StandardEmployeeCostContract } from '../contracts/work-order/standard-employee-cost-contract';
import { StandardEmployeeCosts } from '../models/work-order/standard-employee-costs.model';
import { StandardEquipmentCosts } from '../models/work-order/standard-equipment-costs.model';
import { StandardMaterialCosts } from '../models/work-order/standard-material-costs.model';
import { HistoryRecord } from '../models/records/history-record.model';
import { WorkOrderProviderBase, WorkOrderResult } from './work-order-provider-base';
import { AssetRecord } from '../models/records/asset-record.model';
import { Batch, BulkJobMode, BulkUpdateJob } from 'domain-service/jobs/bulk-update-job/bulk-update-job';
import { BulkUpdateChangedResult } from 'domain-service/subscriptions/handlers/bulk-update-changed-handler';
import { HierarchyAssetRecord } from '../models/records/hierarchy-asset-record.model';
import { take } from 'rxjs/operators';
import { HMSRefreshOperation } from 'domain-service/metrics/hms-refresh-operation.enum';
import { WorkOrderService } from 'domain-service/work-order.service';
import { SummaryFieldInputType } from 'models/work-order/standard-custom-field.model';

export class StandardWorkOrderProvider extends WorkOrderProviderBase {
	private _currentEmployeeList: Employee[] = [];
	private _currentMaterialList: Material[] = [];
	private _currentEquipmentList: Equipment[] = [];
	private _service: StandardWorkOrderService;
	private _workOrderChannelAttributes: ArcGISWorkOrderChannelAttributes;

	constructor(private workorderService: WorkOrderService) {
		super();
	}

	initialize(customerCode?: string): void {
		this.workorderService.customerCode = customerCode;
		const workOrderChannel = this.interopService?.configurationManager?.customerCodeConfiguration?.workOrderChannel;
		if (!workOrderChannel) return;

		const workOrderAttributes = workOrderChannel.attributes as ArcGISWorkOrderChannelAttributes;
		if (!workOrderAttributes) return;

		const server = this.interopService?.arcGISManager?.getArcGISService(workOrderChannel.dataSourceLegacyId);
		if (!server) return;

		this._workOrderChannelAttributes = workOrderAttributes;
		this._service = new StandardWorkOrderService(server, workOrderAttributes);
	}

	get workOrderLayer(): FeatureLayer {
		return this._service?.workOrderLayer;
	}

	get workAssetLayer(): FeatureLayer {
		return this._service?.workAssetLayer;
	}

	get activeWorkOrderValues(): string[] {
		if (!this._workOrderChannelAttributes) return [];

		return this._workOrderChannelAttributes.activeWorkOrderValues;
	}

	get completedWorkOrderValues(): string[] {
		if (!this._workOrderChannelAttributes) return [];

		return this._workOrderChannelAttributes.completedWorkOrderValues;
	}

	private getStatuses(): StatusContract[] {
		const statusArray = new Array<StatusContract>();
		const allStatuses = new Array<string>(...this.activeWorkOrderValues, ...this.completedWorkOrderValues);
		for (const status of allStatuses) {
			const newStatus = new StatusContract();
			newStatus.ObjectId = 0;
			newStatus.Description = status;
			newStatus.StatusCode = status;
			newStatus.WoVisible = true;
			newStatus.TaskVisible = true;
			statusArray.push(newStatus);
		}

		return statusArray;
	}

	async getInitialData(): Promise<WorkOrderMetaData> {
		if (!this._service) return null;

		const initialData = await this._service.getInitialData();
		initialData.WorkOrderStatus = this.getStatuses();
		console.log(initialData);
		return WorkOrderMetaData.fromContract(initialData);
	}

	getCapabilities(): Promise<WorkOrderCapabilities> {
		return new Promise<WorkOrderCapabilities>((resolve, reject) => {
			const capabilities = new WorkOrderCapabilities();
			const fields = this.getArcGISFields();
			let supportsRecurrence = false;
			for (const f of fields) {
				if (f?.name?.toLowerCase() === 'recurrencetemplateid') {
					supportsRecurrence = true;
					break;
				}
			}

			capabilities.supportsWorkOrderRecurrence = supportsRecurrence;
			resolve(capabilities);
		})
	}

	private async fetchEmployees(): Promise<void> {
		if (!this._service) return;
		const employees = await this._service.fetchEmployees();
		if (!employees || !employees.length) return;

		this._currentEmployeeList = employees.map(e => Employee.fromContract(e));
	}

	async getEmployees(): Promise<Employee[]> {
		if (!this._currentEmployeeList || !this._currentEmployeeList.length) await this.fetchEmployees();
		return this._currentEmployeeList;
	}

	getEmployeeById(employeeId: string): Employee {
		if (!this._currentEmployeeList) return null;
		return this._currentEmployeeList.find(e => e.employeeId === employeeId);
	}

	private async fetchEquipment(): Promise<void> {
		if (!this._service) return;
		const equipment = await this._service.fetchEquipment();
		if (!equipment || !equipment.length) return;

		this._currentEquipmentList = equipment.map(e => Equipment.fromContract(e));
	}

	async getEquipment(): Promise<Equipment[]> {
		if (!this._currentEquipmentList || !this._currentEquipmentList.length) await this.fetchEquipment();
		return this._currentEquipmentList;
	}

	getEquipmentById(equipmentId: string): Equipment {
		if (!this._currentEquipmentList) return null;
		return this._currentEquipmentList.find(e => e.equipmentId === equipmentId);
	}

	private async fetchMaterials(): Promise<void> {
		if (!this._service) return;
		const materials = await this._service.fetchMaterials();
		if (!materials || !materials.length) return;

		this._currentMaterialList = materials.map(m => Material.fromContract(m));
	}

	async getMaterials(): Promise<Material[]> {
		if (!this._currentMaterialList || !this._currentMaterialList.length) await this.fetchMaterials();
		return this._currentMaterialList;
	}

	getMaterialById(materialId: string): Material {
		if (!this._currentMaterialList) return null;
		return this._currentMaterialList.find(m => m.materialId === materialId);
	}

	async getWorkAssets(workOrderKey: string): Promise<WorkAssetWrappers> {
		const workAssets = await this._service.fetchWorkAssets([workOrderKey]);
		if (!workAssets || !workAssets.length) return new WorkAssetWrappers();

		return WorkAssetWrappers.fromContracts(workAssets);
	}

	async createWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return await this.createWorkOrders([workOrderWrapper]);
	}

	async createWorkOrders(workOrderWrappers: WorkOrderWrapper[]): Promise<WorkOrderResult> {
		const workOrders = workOrderWrappers?.map(wo => wo?.workOrder as StandardWorkOrder);
		if (!workOrders) return new WorkOrderResult();

		const allWorkAssets = new Array<StandardWorkAsset>();
		for (const wo of workOrders) allWorkAssets.push(...wo.workAssets);

		const transaction = new ApplyEditsTransaction();
		transaction.addOperation(new LayerApplyEditsOperation(this._service.workOrderLayer, this.getFeatures(workOrders)));
		if (allWorkAssets && allWorkAssets.length) transaction.addOperation(new LayerApplyEditsOperation(this._service.workAssetLayer, this.getFeatures(allWorkAssets)));

		return await this._service.applyEdits(transaction);
	}

	async getWorkOrders(workOrderKeys: string[], stubsOnly: boolean): Promise<WorkOrderWrappers> {
		const allWorkOrders = await this._service.fetchWorkOrder(workOrderKeys);
		if (!allWorkOrders || !allWorkOrders.length) return new WorkOrderWrappers();
		this.setCustomFields(allWorkOrders);

		const workOrderModels = StandardWorkOrders.fromContracts(allWorkOrders);
		await this.hydrateWorkAssets(workOrderModels);

		if (stubsOnly) return WorkOrderWrappers.fromModels(workOrderModels);

		await this.hydrateLEM(workOrderModels);
		await this.hydrateHistoryRecords(workOrderModels);
		return WorkOrderWrappers.fromModels(workOrderModels);
	}

	async getMatchingWorkOrders(query: string, isContains: boolean): Promise<WorkOrderWrappers> {
		const allWorkOrders = await this._service.getMatchingWorkOrder(query, isContains);
		if (!allWorkOrders || !allWorkOrders.length) return new WorkOrderWrappers();
		const workOrderModels = StandardWorkOrders.fromContracts(allWorkOrders);
		return WorkOrderWrappers.fromModels(workOrderModels);
	}

	async updateWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return await this.updateWorkOrders([workOrderWrapper]);
	}

	private async updateWorkOrders(workOrderWrappers: WorkOrderWrapper[]): Promise<WorkOrderResult> {
		const workOrders = workOrderWrappers?.map(wo => wo?.workOrder as StandardWorkOrder);
		if (!workOrders) return new WorkOrderResult();

		const transaction = new ApplyEditsTransaction();
		transaction.addOperation(new LayerApplyEditsOperation(this._service.workOrderLayer, null, this.getFeatures(workOrders)));
		return await this._service.applyEdits(transaction);
	}

	async deleteWorkOrder(workOrderWrapper: WorkOrderWrapper): Promise<WorkOrderResult> {
		return await this.deleteWorkOrders([workOrderWrapper]);
	}

	private async deleteWorkOrders(workOrderWrappers: WorkOrderWrapper[]): Promise<WorkOrderResult> {
		const transaction = new ApplyEditsTransaction();
		transaction.addOperation(new LayerApplyEditsOperation(this._service.workOrderLayer, null, null, workOrderWrappers.map(wo => wo.objectId)));
		const allWorkAssetObjectIds = workOrderWrappers.map(wo => wo.workAssets).reduce((accumulator, value) => accumulator.concat(value.map(wa => wa.id)), []);
		transaction.addOperation(new LayerApplyEditsOperation(this._service.workAssetLayer, null, null, allWorkAssetObjectIds));
		const allEmployeeCostObjectIds = workOrderWrappers.map(wo => wo.employeeCosts).reduce((accumulator, value) => accumulator.concat(value.map(ec => ec.id)), []);
		transaction.addOperation(new LayerApplyEditsOperation(this._service.employeeCostLayer, null, null, allEmployeeCostObjectIds));
		const allMaterialCostObjectIds = workOrderWrappers.map(wo => wo.materialCosts).reduce((accumulator, value) => accumulator.concat(value.map(mc => mc.id)), []);
		transaction.addOperation(new LayerApplyEditsOperation(this._service.materialCostLayer, null, null, allMaterialCostObjectIds));
		const allEquipmentCostObjectIds = workOrderWrappers.map(wo => wo.equipmentCosts).reduce((accumulator, value) => accumulator.concat(value.map(mc => mc.id)), []);
		transaction.addOperation(new LayerApplyEditsOperation(this._service.equipmentCostLayer, null, null, allEquipmentCostObjectIds));

		const layers = new Map<string, FeatureLayer>();
		for (const historyRecord of workOrderWrappers.filter(wo => wo.historyRecord).map(wo => wo.historyRecord)) {
			if (!historyRecord || !historyRecord.assetDefinition || !historyRecord.assetType) continue;
			if (layers.has(historyRecord.assetType)) continue;

			const featureLayer = await this.interopService?.arcGISManager?.getHistoryLayer(historyRecord.assetType);
			if (!featureLayer) continue;

			layers.set(historyRecord.assetType, featureLayer);
		}

		for (const assetType of layers.keys()) {
			const layer = layers.get(assetType);
			if (!layer) continue;

			transaction.addOperation(new LayerApplyEditsOperation(layer, null, null,
				workOrderWrappers.filter(wo => wo.historyRecord && wo.historyRecord.assetType?.toLowerCase() === assetType?.toLowerCase()).map(wo => wo.historyRecord.objectId)));
		}

		return await this._service.applyEdits(transaction);
	}

	bulkCreate(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		const job = new BulkUpdateJob(workOrderWrappers, this.runBatchDelegateCreation.bind(this), BulkJobMode.Create, false);
		job.jobFinished?.pipe(take(1)).subscribe((processedWorkOrderKeys: string[]) => {
			this.interopService?.metricManager?.refreshWorkOrderMetrics(processedWorkOrderKeys, HMSRefreshOperation.Add);
		});

		return job;
	}

	bulkEdit(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		const job = new BulkUpdateJob(workOrderWrappers, this.runBatchDelegateEdit.bind(this), BulkJobMode.Update, false);
		job.jobFinished?.pipe(take(1)).subscribe((processedWorkOrderKeys: string[]) => {
			this.interopService?.metricManager?.refreshWorkOrderMetrics(processedWorkOrderKeys, HMSRefreshOperation.Update);
		});

		return job;
	}

	bulkDelete(workOrderWrappers: WorkOrderWrapper[]): BulkUpdateJob {
		const job = new BulkUpdateJob(workOrderWrappers, this.runBatchDelegateDelete.bind(this), BulkJobMode.Delete, false);
		job.jobFinished?.pipe(take(1)).subscribe((processedWorkOrderKeys: string[]) => {
			this.interopService?.metricManager?.refreshWorkOrderMetrics(processedWorkOrderKeys, HMSRefreshOperation.Remove);
		});

		return job;
	}

	private getFeatures<TModel, TContract>(omniModels: OmniModel<TModel, TContract>[]): Feature[] {
		if (!omniModels || !omniModels.length) return [];

		const features = new Array<Feature>();
		for (const model of omniModels) {
			const newFeature = new Feature();
			newFeature.attributes = model.getDirtyFieldsFeature();
			features.push(newFeature);
		}

		return features;
	}

	private getWorkOrderAssetTypes(workOrders: StandardWorkOrder[]): string[] {
		const dict = new Map<string, boolean>();
		if (!workOrders) return [];


		for (const workAsset of workOrders.filter(wo => wo.workAssets && wo.workAssets.length).map(wo => wo.workAssets[0])) {
			if (!workAsset || !workAsset.assetType) continue;
			if (dict.has(workAsset.assetType)) continue;

			dict[workAsset.assetType] = true;
		}

		return Object.keys(dict);
	}

	private async runBatchDelegateEdit(batch: Batch): Promise<boolean> {
		const bulkResult = new BulkUpdateChangedResult(null);
		const response = await this.updateWorkOrders(batch.workOrders);
		if (response && response.isSuccess) bulkResult.workOrderKeysWithSuccess.push(...batch.workOrders.map(wo => wo.workOrderKey));
		batch.result = bulkResult;
		return response?.isSuccess;
	}

	private async runBatchDelegateCreation(batch: Batch): Promise<boolean> {
		const bulkResult = new BulkUpdateChangedResult(null);
		const response = await this.createWorkOrders(batch.workOrders);
		if (response && response.isSuccess) bulkResult.workOrderKeysWithSuccess.push(...batch.workOrders.map(wo => wo.workOrderKey));
		batch.result = bulkResult;
		return response?.isSuccess;
	}

	private async runBatchDelegateDelete(batch: Batch): Promise<boolean> {
		const bulkResult = new BulkUpdateChangedResult(null);
		await this.hydrateRelatedChildren(batch.workOrders);
		const response = await this.deleteWorkOrders(batch.workOrders);
		if (response && response.isSuccess) bulkResult.workOrderKeysWithSuccess.push(...batch.workOrders.map(wo => wo.workOrderKey));
		batch.result = bulkResult;
		return response?.isSuccess;
	}

	private async hydrateHistoryRecords(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		const assetTypes = this.getWorkOrderAssetTypes(workOrders);
		if (!assetTypes || !assetTypes.length) return;

		const historyRecords = new Array<HistoryRecord>();
		for (const assetType of assetTypes) {
			const assetDefinition = this.interopService?.configurationManager?.customerCodeConfiguration?.assetDefinitions?.getByAssetType(assetType);
			if (!assetDefinition) continue;

			const foundRecords = await this.interopService?.arcGISManager?.getHistoryRecordsByWorkOrderKeys(workOrders.map(wo => wo.workOrderKey), assetType, idOnly);
			if (!foundRecords || !foundRecords.length) continue;

			historyRecords.push(...foundRecords);
		}

		if (!historyRecords || !historyRecords.length) return;

		for (const wo of workOrders) {
			const foundHistoryRecord = historyRecords.find(hr => hr.workOrderKey === wo.workOrderKey || hr.workOrderId === wo.workOrderKey);
			if (!foundHistoryRecord) continue;

			wo.historyRecord = foundHistoryRecord;
		}
	}

	private async hydrateEmployeeCost(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		if (!workOrders) return;

		const allEmployeeCost: StandardEmployeeCostContract[] = await this._service.fetchEmployeeCost(workOrders.map(wo => wo.workOrderKey));
		if (!allEmployeeCost || !allEmployeeCost.length) return;

		for (const wo of workOrders) {
			wo.employeeCosts = StandardEmployeeCosts.fromContracts(allEmployeeCost.filter(empCost =>
				empCost.WorkOrderKey === wo.workOrderKey));
		}
	}

	private async hydrateEquipmentCost(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		if (!workOrders) return;

		const allEquipmentCost: StandardEquipmentCostContract[] = await this._service.fetchEquipmentCost(workOrders.map(wo => wo.workOrderKey), idOnly);
		if (!allEquipmentCost || !allEquipmentCost.length) return;

		for (const wo of workOrders) {
			wo.equipmentCosts = StandardEquipmentCosts.fromContracts(allEquipmentCost.filter(equiCost =>
				equiCost.WorkOrderKey === wo.workOrderKey));
		}
	}

	private async hydrateMaterialCost(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		if (!workOrders) return;

		const allMaterialCost: StandardMaterialCostContract[] = await this._service.fetchMaterialCost(workOrders.map(wo => wo.workOrderKey), idOnly);
		if (!allMaterialCost || !allMaterialCost.length) return;

		for (const wo of workOrders) {
			wo.materialCosts = StandardMaterialCosts.fromContracts(allMaterialCost.filter(matCost =>
				matCost.WorkOrderKey === wo.workOrderKey));
		}
	}

	private async hydrateLEM(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		if (!workOrders) return;

		await this.hydrateEmployeeCost(workOrders, idOnly);
		await this.hydrateEquipmentCost(workOrders, idOnly);
		await this.hydrateMaterialCost(workOrders, idOnly);
	}

	private async hydrateWorkAssets(workOrders: StandardWorkOrder[], idOnly = false): Promise<void> {
		if (!workOrders) return;

		const allWorkAssets = await this._service.fetchWorkAssets(workOrders.map(wo => wo.workOrderKey));
		if (!allWorkAssets || !allWorkAssets.length) return;

		for (const wo of workOrders) {
			wo.workAssets = StandardWorkAssets.fromContracts(allWorkAssets.filter(wa =>
				wa.WorkOrderKey === wo.workOrderKey));
		}
	}

	private async hydrateRelatedChildren(workOrderWrappers: WorkOrderWrapper[]): Promise<void> {
		// hydrate stubbed batch of work orders with related children i.e. work assets, LEM, history record
		await this.hydrateWorkAssets(workOrderWrappers.map(wo => wo.workOrder as StandardWorkOrder), true);
		await this.hydrateLEM(workOrderWrappers.map(wo => wo.workOrder as StandardWorkOrder), true);
		await this.hydrateHistoryRecords(workOrderWrappers.map(wo => wo.workOrder as StandardWorkOrder), true);
	}

	generateWorkOrderKeys(keyCount: number) {
		return this.workorderService.generteWorkOrderKeys(keyCount).toPromise();
	}

	createWorkOrderModel(workOrderKey: string, assetRecord?: AssetRecord | HierarchyAssetRecord): WorkOrderWrapper {
		const woContract = new StandardWorkOrderContract();
		this.setCustomFields([woContract]);
		const workOrderWrapper = new WorkOrderWrapper(StandardWorkOrder.fromContract(woContract));
		if (assetRecord instanceof AssetRecord) this.createWorkAssetModel(workOrderWrapper, assetRecord);
		workOrderWrapper.workOrderKey = workOrderKey;
		for (const workAsset of workOrderWrapper.workAssets) workAsset.workOrderKey = workOrderKey;
		return workOrderWrapper;
	}

	createWorkAssetModel(workOrderWrapper: WorkOrderWrapper, asset: AssetRecord): WorkAssetWrapper {
		const workOrder = workOrderWrapper?.workOrder as StandardWorkOrder;

		const workAsset = new StandardWorkAsset();
		const { assetType } = asset.assetDefinition.assetChannel.attributes as ArcGISAssetChannelAttributes;
		workAsset.assetType = assetType;
		workAsset.assetKey = asset.assetId?.toString();
		workAsset.assetOID = asset.feature.objectId;
		workAsset.workOrderKey = workOrder ? workOrder.workOrderKey : '';

		if (workOrder) workOrder.workAssets.push(workAsset);
		return new WorkAssetWrapper(workAsset);
	}

	async getWorkOrdersByAsset(assetType: string, assetKey: string): Promise<WorkOrderWrappers> {
		const workOrderKeys = await this._service.fetchWorkOrderKeysByAsset(assetType, assetKey);
		if (!workOrderKeys || !workOrderKeys.length) return new WorkOrderWrappers();

		return await this.getWorkOrders(workOrderKeys, true);
	}

	getArcGISFields(): ArcGISField[] {
		if (!this.workOrderLayer) return [];
		return this.workOrderLayer.getOmniFields('WorkOrder');
	}

	getDefaultSummaryFields(recurrenceOnly: boolean): WorkOrderField[] {
		const fields = new Array<WorkOrderField>();
		fields.push(this.createSummaryField('worktype', 'work type', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('status', 'status', true, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('assignedto', 'assigned to', false, false, SummaryFieldInputType.LIST));
		fields.push(this.createSummaryField('priority', 'priority', false, false, SummaryFieldInputType.KEYPAD));
		if (recurrenceOnly) return fields;
		fields.push(this.createSummaryField('comments', 'comments', false, false, SummaryFieldInputType.TEXT));
		fields.push(this.createSummaryField('startdate', 'start date', false, false, SummaryFieldInputType.DATETIME_PICKER));
		fields.push(this.createSummaryField('completeddate', 'completed date', false, true, SummaryFieldInputType.DATETIME_PICKER));
		return fields;
	}

	private setCustomFields(workOrders: StandardWorkOrderContract[]): void {
		let summaryFields = (this.interopService?.configurationManager?.customerCodeConfiguration?.workOrderChannel?.attributes as ArcGISWorkOrderChannelAttributes)?.summaryFields;
		if (!summaryFields || !summaryFields.length) summaryFields = this.getDefaultSummaryFields(false);
		if (!summaryFields || !summaryFields.length) return;

		const woTableFields = this.getArcGISFields();
		const keys = Object.getOwnPropertyNames(StandardWorkOrder.prototype);
		for (const wo of workOrders) {
			for (const tableField of woTableFields) {
				if (keys.some(k => k?.toLowerCase() === tableField?.name?.toLowerCase() ||
					wo.customFields.some(cf => cf.fieldName?.toLowerCase() === tableField?.name?.toLowerCase()))) continue;

				const customField = new StandardCustomFieldContract();
				customField.fieldName = tableField.name;
				wo.customFields.push(customField);
			}

			let i = 0;
			for (const cf of wo.customFields) {
				cf.ObjectId = i;
				i++;
				const foundField = summaryFields?.find(sf => sf.name?.toLowerCase() === cf.fieldName?.toLowerCase());
				if (!foundField) {
					cf.isVisible = false;
					cf.isRequired = false;
					continue;
				}

				cf.inputType = foundField.inputType;
				cf.fieldDescription = foundField.text;
				cf.isRequired = foundField.isRequired;
				cf.isVisible = foundField.isVisible;
			}
		}
	}

	private createSummaryField(name: string, text: string, isRequired: boolean, isReadOnly: boolean, inputType: SummaryFieldInputType): WorkOrderField {
		return new WorkOrderField(null, {
			isReadOnly: isReadOnly,
			isRequired: isRequired,
			inputType: inputType,
			isVisible: true,
			name: name,
			text: text
		});
	}
}
