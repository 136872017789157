/**
 * The model for the trend style property.
 */
export class TrendStyle {
	/**
	 * Unique identiifier for the trend style.
	 */
	identifier?: string;

	/**
	 * The label for the trend style which will be displayed in the UI.
	 */
	label?: string;

	/**
	 * The path URL for the style's icon.
	 */
	iconURL?: string;

	constructor(type?: string) {
		if (!type || type === 'line') {
			this.identifier = 'line';
			this.label = 'line chart';
			this.iconURL = 'assets/trend-line.png';
		} else if (type === 'bar') {
			this.identifier = 'bar';
			this.label = 'bar chart';
			this.iconURL = 'assets/trend-bar.png';
		}
	}
}
