import { OmniModel } from '../omni-model';
import * as SedaruUtils from '../../sedaru-util/index';
import * as Contracts from '../../contracts/recurrence/index';
import { VariableDayType } from './variable-day-type.enum';

export class VariableDay extends OmniModel<VariableDay, Contracts.VariableDayContract> {
    private _type: SedaruUtils.Undoable<VariableDayType>;
    get type(): VariableDayType {
        if (!this._type) this._type = new SedaruUtils.Undoable<VariableDayType>();
        return this._type.value;
    }
    set type(value: VariableDayType) {
        if (!this._type) {
            this._type = new SedaruUtils.Undoable<VariableDayType>(value);
            return;
        }
        this._type.value = value;
    }

    private _dayOfWeek: SedaruUtils.Undoable<number>;
    get dayOfWeek(): number {
        if (!this._dayOfWeek) this._dayOfWeek = new SedaruUtils.Undoable<number>();
        return this._dayOfWeek.value;
    }
    set dayOfWeek(value: number) {
        if (!this._dayOfWeek) {
            this._dayOfWeek = new SedaruUtils.Undoable<number>(value);
            return;
        }
        this._dayOfWeek.value = value;
    }

    static fromContract(contract: Contracts.VariableDayContract): VariableDay {
        const model = new VariableDay();
        model.type = contract.type as VariableDayType;
        model.dayOfWeek = contract.dayOfWeek;
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.VariableDayContract {
        const contract = new Contracts.VariableDayContract();
        contract.type = this.type;
        contract.dayOfWeek = this.dayOfWeek;
        return contract;
    }

    onUpdateInformation(copyModel: VariableDay): boolean {
        let wasChanged = false;
        wasChanged =
			this.updateIfNotDirty<VariableDayType>(this._type, () => {
				this.type = copyModel.type;
			}) || wasChanged;
        wasChanged =
			this.updateIfNotDirty<number>(this._dayOfWeek, () => {
				this.dayOfWeek = copyModel.dayOfWeek;
			}) || wasChanged;

        return wasChanged;
    }
}
