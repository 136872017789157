import { OmniModel } from '../omni-model';
import * as Contracts from '../../contracts/work-order';
import * as SedaruUtils from '../../sedaru-util';
import { AssetSpecs } from './asset-specs.model';

export class Asset extends OmniModel<Asset, Contracts.AssetContract> {
    private _id: SedaruUtils.Undoable<string>;
    get id(): string {
        if (!this._id) this._id = new SedaruUtils.Undoable<string>();

        return this._id.value;
    }
    set id(value: string) {
        if (!this._id) {
            this._id = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._id.value = value;
    }

    private _assetId: SedaruUtils.Undoable<string>;
    get assetId(): string {
        if (!this._assetId) this._assetId = new SedaruUtils.Undoable<string>();

        return this._assetId.value;
    }
    set assetId(value: string) {
        if (!this._assetId) {
            this._assetId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._assetId.value = value;
    }

    private _locationId: SedaruUtils.Undoable<string>;
    get locationId(): string {
        if (!this._locationId) this._locationId = new SedaruUtils.Undoable<string>();

        return this._locationId.value;
    }
    set locationId(value: string) {
        if (!this._locationId) {
            this._locationId = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._locationId.value = value;
    }

    private _description: SedaruUtils.Undoable<string>;
    get description(): string {
        if (!this._description) this._description = new SedaruUtils.Undoable<string>()

        return this._description.value;
    }
    set description(value: string) {
        if (!this._description) {
            this._description = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._description.value = value;
    }

    private _status: SedaruUtils.Undoable<string>;
    get status(): string {
        if (!this._status) this._status = new SedaruUtils.Undoable<string>();

        return this._status.value;
    }
    set status(value: string) {
        if (!this._status) {
            this._status = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._status.value = value;
    }

    private _statusDate: SedaruUtils.Undoable<string>;
    get statusDate(): string {
        if (!this._statusDate) this._statusDate = new SedaruUtils.Undoable<string>();

        return this._statusDate.value;
    }
    set statusDate(value: string) {
        if (!this._statusDate) {
            this._statusDate = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._statusDate.value = value;
    }

    private _inService: SedaruUtils.Undoable<boolean>;
    get inService(): boolean {
        if (!this._inService) this._inService = new SedaruUtils.Undoable<boolean>();

        return this._inService.value;
    }
    set inService(value: boolean) {
        if (!this._inService) {
            this._inService = new SedaruUtils.Undoable<boolean>(value);
            return;
        }

        this._inService.value = value;
    }

    get inServiceString(): string {
        return this.inService ? 'YES' : 'NO';
    }

    private _assetType: SedaruUtils.Undoable<string>;
    get assetType(): string {
        if (!this._assetType) this._assetType = new SedaruUtils.Undoable<string>();

        return this._assetType.value;
    }
    set assetType(value: string) {
        if (!this._assetType) {
            this._assetType = new SedaruUtils.Undoable<string>(value);
            return;
        }

        this._assetType.value = value;
    }

    private _assetSpecs: AssetSpecs;
    get assetSpecs(): AssetSpecs {
        return this._assetSpecs ? this._assetSpecs : (this._assetSpecs = new AssetSpecs());
    }
    set assetSpecs(value: AssetSpecs) {
        if (this._assetSpecs === value) return;

        this._assetSpecs = value;
    }

    static fromContract(contract: Contracts.AssetContract): Asset {
        const model = new Asset();
        model.id = contract.ObjectId.toString();
        model.assetId = contract.AssetId;
        model.locationId = contract.LocationId;
        model.description = contract.Description;
        model.status = contract.Status;
        model.statusDate = contract.StatusDate;
        model.inService = contract.InService == 1;
        model.assetType = contract.AssetType;
        if (contract.AssetSpecs) model.assetSpecs = AssetSpecs.fromContracts(contract.AssetSpecs);
        model.clearDirty();
        return model;
    }

    getContract(): Contracts.AssetContract {
        const contract = new Contracts.AssetContract();
        contract.ObjectId = +this.id;
        contract.AssetId = this.assetId;
        contract.LocationId = this.locationId;
        contract.Description = this.description;
        contract.Status = this.status;
        contract.StatusDate = this.statusDate;
        contract.InService = this.inService ? 1 : 0;
        contract.AssetType = this.assetType;
        contract.AssetSpecs = this.assetSpecs.getContracts();
        return contract;
    }
    onUpdateInformation(copyModel: Asset): boolean {
        let wasChanged = false;
        wasChanged =
			this.updateIfNotDirty<string>(this._id, () => {
				this.id = copyModel.id;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetId, () => {
				this.assetId = copyModel.assetId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._locationId, () => {
				this.locationId = copyModel.locationId;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._description, () => {
				this.description = copyModel.description;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._status, () => {
				this.status = copyModel.status;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._statusDate, () => {
				this.statusDate = copyModel.statusDate;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<boolean>(this._inService, () => {
				this.inService = copyModel.inService;
			}) || wasChanged;
		wasChanged =
			this.updateIfNotDirty<string>(this._assetType, () => {
				this.assetType = copyModel.assetType;
            }) || wasChanged;

        return wasChanged;
    }
}
