import { Injectable } from '@angular/core';
import { MetricTile } from 'models';
import { Canvas } from 'omni-model/canvas.model';

@Injectable({
	providedIn: 'root'
})
export class MetricRequesterService {
	private _requester: MetricRequester;

	setRequester(type: string, value: Canvas | MetricTile) {
		this._requester = { value, type };
	}

	getRequester(): MetricRequester {
		return this._requester;
	}
}

export interface MetricRequester {
	value: any;
	type: string;
}
