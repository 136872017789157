export default class AssetIcon {
	public static getAssetIconUrl(assetType: string) {
		if (!assetType) return 'assets/assets/pipes.png';
		switch (true) {
			case assetType.toLowerCase().includes('hydrant'):
				return 'assets/assets/hydrants.png';
			case assetType.toLowerCase().includes('valve'):
				return 'assets/assets/valves.png';
			case assetType.toLowerCase().includes('customer'):
				return 'assets/assets/customers.png';
			case assetType.toLowerCase().includes('pump'):
				return 'assets/assets/pumps.png';
			case assetType.toLowerCase().includes('tank'):
				return 'assets/assets/tanks.png';
			case assetType.toLowerCase().includes('pipe'):
			default:
				return 'assets/assets/pipes.png';
		}
	}
}
