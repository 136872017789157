export enum ListTemplates {
	noIconOneLine = 'noIconOneLine',
	searchResult = 'searchResult',
	rightIconOneLine = 'rightIconOneLine',
	noIconTwoLines = 'noIconTwoLines',
	leftIconOneLine = 'leftIconOneLine',
	leftIconTwoLines = 'leftIconTwoLines',
	twoIconsTwoLines = 'twoIconsTwoLines',
	twoIconsOneLine = 'twoIconsOneLine'
}
