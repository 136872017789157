import { ContractBase } from '../contract-base';

export class StandardEquipmentCostContract extends ContractBase {
	public objectid: number;
	public EquipmentId: string;
	public Units: number;
	public Hours: number;
	public UnitCost: number;
	public Datestamp: string;
	public WorkOrderKey: string;
	public TotalCost: number;
	public Description: string;
	public WorkTaskId: string;
	public CreatedBy: string;
	public RateType: string;
}
