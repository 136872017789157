import { TransformBase } from './transform-base';
import { TransformationResult } from './transformation-result';
import * as SedaruModels from '../../index';

export class StylesTransform extends TransformBase {
	protected onTransform(): TransformationResult {
		for (const sourceStyle of this.fieldforceConfig.Design.DesignProperties.Styles) {
			const style = new SedaruModels.Style();
			style.id = sourceStyle.ID;
			if (sourceStyle.AlternatingPattern.toLowerCase() === 'column') {
				style.alternatingPattern = SedaruModels.AlternatingPattern.Column;
			} else if (sourceStyle.AlternatingPattern.toLowerCase() === 'row') {
				style.alternatingPattern = SedaruModels.AlternatingPattern.Row;
			} else {
				style.alternatingPattern = SedaruModels.AlternatingPattern.None;
			}
			style.layout = this.getLayout(sourceStyle.Layout);
			this.globalConfig.styles.push(style);
		}

		return this.getResult(true);
	}

	private getLayout(sourceLayout: any): SedaruModels.Layout {
		const layout = new SedaruModels.Layout();

		layout.background = new SedaruModels.ColorGroup(this.globalConfig);
		layout.background.colorId = sourceLayout.Background.ColorIDs[0];
		layout.background.errorColorId = sourceLayout.Background.ErrorColorIDs[0];
		layout.background.warningColorId = sourceLayout.Background.WarningColorIDs[0];
		layout.background.alternateColorId = sourceLayout.Background.AlternateColorIDs[0];
		layout.foreground = new SedaruModels.ColorGroup(this.globalConfig);
		layout.foreground.colorId = sourceLayout.Foreground.ColorID;
		layout.foreground.alternateColorId = sourceLayout.Foreground.ColorID;
		layout.foreground.warningColorId = sourceLayout.Foreground.WarningColorID;
		layout.foreground.errorColorId = sourceLayout.Foreground.ErrorColorID;

		return layout;
	}
}
