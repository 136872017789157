<div class="omni-bot">
	<a
		(mouseenter)="backgroundState = 'cyan'"
		(mouseleave)="backgroundState = 'transparent'"
		matBadge="{{ notificationCount }}"
		matBadgeColor="warn"
		matBadgeOverlap="true"
		[matBadgeHidden]="!notificationCount"
		(click)="incrementNotificationCount()"
	>
		<img src="assets/omni-bot.png" class="omni-bot-icon" [@cyanBackgroundOnHovered]="backgroundState" />
	</a>
	<h1>OMNI</h1>
	<h4>operations management <br />& network intelligence</h4>
</div>
