<div class="scrolling-content">
	<mat-action-list class="default-trend-settings">
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.defaultTrendStyle"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#defaultTrendStyleTooltip="toggleTooltip"
				[matTooltipDisabled]="defaultTrendStyleTooltip.isTooltipDisabled"
			>
				<span class="control-title">default trend style</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="not set" [value]="settings.defaultTrendStyle" />
				<button (click)="onGoToDefaultTrendStyle()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="dark-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.xAxisField.name"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#xAxisFieldTooltip="toggleTooltip"
				[matTooltipDisabled]="xAxisFieldTooltip.isTooltipDisabled"
			>
				<span class="control-title">trend x-axis</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="not set" [value]="settings.xAxisField.omniName" />
				<button (click)="onGoToXAxisField()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.yAxisField.name"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#yAxisFieldTooltip="toggleTooltip"
				[matTooltipDisabled]="yAxisFieldTooltip.isTooltipDisabled"
			>
				<span class="control-title">trend y-axis</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="not set" [value]="settings.yAxisField.omniName" />
				<button (click)="onGoToYAxisField()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
