import { ContractBase } from '../contract-base';

export class CustomFieldContract extends ContractBase {
	public ObjectId: number;
	public CustomFieldId: string;
	public CustomFieldValue: string;
	public WorkorderKey: string;
	public CustomFieldType: string;
	public CustomFieldName: string;
	public CodeType: string;
	public IsRequired: boolean;
	public DefaultValue: string;
	public CustomeFieldGroup: any;
	public IsDefault: boolean;
	public IsTaskDefault: boolean;
	public CustomFieldGroup: string;
	public Sequence: number;
}
