// import { PortalCredentials } from './portal-credentials';

export enum CredentialsType {
	Basic,
	ArcGISOnline,
	Token,
	Portal
}

export class Credentials {
	username: string;
	password: string;
	token: string;
	type: CredentialsType;
	expiration: Date;
	ssl: boolean;

	public get isExpired(): boolean {
		if (!this.expiration) return false;

		// TODO Oscar
		// const difference = this.expiration - new Date().getUTCDate();

		// if (difference.TotalMinutes > 0) return false;

		return true;
	}

	private _isAuthenticated: boolean;
	get isAuthenticated(): boolean {
		return this._isAuthenticated;
	}
	set isAuthenticated(value: boolean) {
		this._isAuthenticated = value;
	}

	constructor(param1: string, param2?: string) {
		if (!param2) {
			this.type = CredentialsType.Token;
			this.token = param1;
			return;
		}

		this.username = param1;
		this.password = param2;
		this.type = CredentialsType.Basic;
	}

	public static fromJson(jsonData: any): Credentials {
		let credentials: Credentials;
		if (jsonData.token) {
			credentials = new Credentials(jsonData.token);
		} else if (jsonData.access_token) {
			credentials = undefined; // new PortalCredentials(jsonData.access_token, jsonData.refresh_token);
		} else {
			credentials = new Credentials('', '');
			credentials.type = CredentialsType.Basic;
		}

		let expires = 0;
		if (jsonData.expires) {
			expires = jsonData.expires;
			if (expires > 0) {
				credentials.expiration = new Date(expires);
			}
		} else if (jsonData.expires_in) {
			expires = jsonData.expires_in;
			const ds = new Date(new Date().getTime() + expires); // .getUTCDate();
			// ds.setSeconds(ds.getSeconds() + expires);
			credentials.expiration = ds;
		}

		credentials.username = jsonData.username;
		credentials.ssl = jsonData.ssl;

		return credentials;
	}

	refreshCredentials(jsonData: any) {
		const creds = Credentials.fromJson(jsonData);
		this.updateCredentials(creds);
	}
	private updateCredentials(credentials: Credentials) {
		this.token = credentials.token;
		this.expiration = credentials.expiration;
		this.ssl = credentials.ssl;
	}
}
