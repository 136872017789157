import { ContractBase } from '../contract-base';

export class AttachmentContract extends ContractBase {
	public ObjectId: number;
	public AttachmentId: number;
	public FileindexId: number;
	public ReferenceKey: string;
	public ReferenceValue: string;
	public Url: string;
	public SystemId: string;
	public AttachedBy: string;
	public AttachedDate: string;
	public Comments: string;
}
