import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { SearchMode } from './search-mode.model';
import { FlashMessageService } from 'app/flash-message/flash-message.service';
import { SearchService } from '../search.service';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { Subject } from 'rxjs';
import { LayerResultModel } from '../layer-result.model';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { MapAssetListComponent } from 'app/menu-panel/map-assets/map-asset-list/map-asset-list.component';
import { WorkOrderFactory } from 'domain-service/work-order-factory';

/**
 * The component is designed to be placed at the top of the right panel.
 * It includes an input text box to type in the search keyword and a menu to select the
 * category of the search being made.
 */
@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent implements OnInit {
	@ViewChild('searchbar', { static: true }) searchbar: ElementRef;
	@ViewChild('searchQuery', { static: true }) searchQuery: ElementRef;

	searchTerm$ = new Subject<string>();
	assetSearchResults: LayerResultModel[];
	lastOnKeyUpSearch: string = null;

	/**
	 * An list of available search modes.
	 */
	availableSearchModes: SearchMode[];

	get title(): string {
		if (this.selectedMode.identifier === 'contextual') {
			if (this._currentContextualSearch) {
				return this._currentContextualSearch.title;
			}

			return 'contextual search';
		}

		return this.selectedMode.title;
	}

	/**
	 * Currently selected search mode.
	 */
	selectedMode: SearchMode = new SearchMode();
	/**
	 * The mode user was at before switching to contextual mode.
	 */
	previousMode: SearchMode = new SearchMode();

	private _currentContextualSearch: ContextualSearchBase;

	private searchtimer;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {SearchService} searchService - Provides methods to perform search.
	 * @param {FlashMessageService} flashMessageService - Used for displaying error messages in the UI from flash message service.
	 */
	constructor(
		private searchService: SearchService,
		private flashMessageService: FlashMessageService,
		private workorderFactory: WorkOrderFactory
	) {}

	/**
	 * On init the availableSearchModes is initialized with the available list of modes.
	 */
	ngOnInit() {
		this.availableSearchModes = this.searchService.getSearchModes().filter(mode => mode.identifier !== 'contextual');
		this.searchService.selectedMode = this.selectedMode;
		this.searchService.onSearchAssets$(this.searchTerm$).subscribe((assetResults: LayerResultModel[]) => {
			console.log('assetResults', assetResults);
			this.assetSearchResults = assetResults;
		});
	}

	getSearchBarWidth() {
		return { 'width.px': this.searchbar.nativeElement.offsetWidth };
	}

	isAWO() {
		return this.workorderFactory.isAdvancedMode;
	}

	/**
	 * This method is called when an item from the search mode list is clicked.
	 * It will set the currently selected mode.
	 * @param {SearchMode} mode the mode which was selected through click.
	 */
	onSearchModeSelected(mode: SearchMode) {
		if (mode.identifier === 'workorder') this.searchService.initialize();
		if (mode.identifier === 'address') this.searchService.initialize();
		this.selectedMode = this.previousMode = this.searchService.selectedMode = mode;
	}

	onKeyup(searchQuery: string, event) {
		if (this.selectedMode.identifier === 'workorder') return;
		if (this.selectedMode.identifier === 'address') return;
		if (event.keyCode === 16 || event.keyCode === 17 || event.keyCode === 18) return;
		if (this.lastOnKeyUpSearch != searchQuery) {
			this.lastOnKeyUpSearch = searchQuery;
			this.onSearch(searchQuery);
		}
	}

	onSearchAsset(searchQuery: string) {
		this.onSearch(searchQuery)
	}

	/**
	 * This method is called when the search button on the search bar is clicked.
	 * @param {string} searchQuery - The value typed in the search component's text box.
	 */
	onSearch(searchQuery: string) {
		// Empty query must be processed for contextual search
		if (!searchQuery && this.selectedMode.identifier !== 'contextual') return;
		if (this.searchtimer) {
			clearTimeout(this.searchtimer);
			this.searchtimer = null;
		}
		this.searchtimer = setTimeout(() => {
			const searchQueryTrimed = searchQuery.trim();
			switch (this.selectedMode.identifier) {
				case 'assets':
					if (searchQueryTrimed) {
						NavigationService.current.activeSidePanel.updateSearchTerm('');
						this.searchTerm$.next(searchQueryTrimed);
						NavigationService.navigateTo(Pages.mapAssetsList, { mode: 'search-results' });
					}
					break;
				case 'address':
					this.searchService.onSearchAddress(searchQueryTrimed);
					if (NavigationService.current.activeSidePanel.currentPageIdentifier !== Pages.searchResults) {
						NavigationService.navigateTo(Pages.searchResults);
					}
					break;
				case 'workorder':
					if (this.workorderFactory.isAdvancedMode) {
						this.searchService.onSearchWorkorder(searchQueryTrimed, 1, true);
					} else {
						this.searchService.onSearchWorkorder(searchQueryTrimed, 1, false).then(() => {
							this.searchService.onSearchWorkorder(searchQueryTrimed, 2, true);
						});
					}
					if (NavigationService.current.activeSidePanel.currentPageIdentifier !== Pages.searchResults) {
						NavigationService.navigateTo(Pages.searchResults);
					}
					break;
				case 'contextual':
					if (this._currentContextualSearch && this._currentContextualSearch.isEnabled) {
						NavigationService.current.activeSidePanel.updateSearchTerm(searchQueryTrimed);
						this._currentContextualSearch.search.searchText = searchQueryTrimed;
						// this.searchQuery.nativeElement.value = searchQueryTrimed;
					}
					break;
				default:
					this.flashMessageService.popMessage('Invalid search mode.');
					break;
			}
		}, 400);
	}

	onMenuPanelNavigated(menuPanelPage: MenuPanelBaseComponent) {
		console.log('onMenuPanelNavigated', menuPanelPage);
		const currentPageIdentifier = NavigationService.current.activeSidePanel.currentPageIdentifier;
		switch (currentPageIdentifier) {
			case Pages.searchResults:
				if (this.searchService.searchQuery) this.searchQuery.nativeElement.value = this.searchService.searchQuery;
				break;
			case Pages.mapAssetsList:
				if (menuPanelPage.assetSearchQuery) this.searchQuery.nativeElement.value = menuPanelPage.assetSearchQuery;
				break;
			case Pages.singleAssetList:
				break;
			default:
				(this.searchQuery.nativeElement as HTMLInputElement).value = '';
				break;
		}

		if (!menuPanelPage) {
			this._currentContextualSearch = undefined;
			return;
		}

		this._currentContextualSearch = menuPanelPage.contextualSearch;

		if (this._currentContextualSearch && this._currentContextualSearch.isEnabled) {
			if (this.selectedMode.identifier !== 'contextual') {
				this.previousMode = this.selectedMode;
			}
			this.availableSearchModes = this.searchService.getSearchModes();
			this.selectedMode = this.availableSearchModes.find(mode => mode.identifier === 'contextual');
			if (this._currentContextualSearch.navigateBack) {
				const query = this._currentContextualSearch.search.searchText;
				if (query) {
					this.searchQuery.nativeElement.value = query;
				}
			} else {
				this.searchQuery.nativeElement.value = '';
			}
		} else {
			this.selectedMode = this.previousMode;
			this.availableSearchModes = this.searchService.getSearchModes().filter(mode => mode.identifier !== 'contextual');
		}
	}
}
