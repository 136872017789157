export enum WorkOrderSourceType {
	workOrders = 1,
	workOrderAssets = 2,
	workOrderCosts = 3,
	employees = 4,
	materials = 5,
	equipment = 6,
	vendors = 7,
	task = 8
}
export const WorkOrderSource: Readonly<Array<Readonly<{ name: string; type: WorkOrderSourceType }>>> = [
	{ name: 'work order', type: WorkOrderSourceType.workOrders },
	{ name: 'assets', type: WorkOrderSourceType.workOrderAssets },
	{ name: 'tasks', type: WorkOrderSourceType.task },
	{ name: 'employees', type: WorkOrderSourceType.employees },
	{ name: 'material', type: WorkOrderSourceType.materials },
	{ name: 'equipment', type: WorkOrderSourceType.equipment },
	{ name: 'vendors', type: WorkOrderSourceType.vendors }
];
