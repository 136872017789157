import { WorkOrderBudgetContract } from './../../contracts/work-order';
import { WorkOrderBudget } from './work-order-budget.model';
export class WorkOrderBudgets extends Array<WorkOrderBudget> {
	static fromContracts(workOrderBudgetContracts: WorkOrderBudgetContract[]) {
		const workOrderBudgets = new WorkOrderBudgets();
		if (!workOrderBudgetContracts) return workOrderBudgets;

		for (const workOrderBudgetContract of workOrderBudgetContracts) {
			const workOrderBudget = WorkOrderBudget.fromContract(workOrderBudgetContract);
			workOrderBudgets.push(workOrderBudget);
		}
		return workOrderBudgets;
	}

	getByBudgetId = (budgetId: string) => this.find(budget => budget.budgetId === budgetId);
}
