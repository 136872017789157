import { DataChannelService } from '../../../../../../domain-service/data-channel.service';
import { Component, ElementRef } from '@angular/core';
import { TrendStyleIdentifier, MetricQueryLegacy, Metric, MetricSource, MetricTypes } from 'models';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { UserService } from 'app/user/user.service';
import * as SedaruModels from 'models';
import { SearchController } from 'sedaru-util/generic-search/search-controller';
import { Search } from 'sedaru-util/generic-search/search';
import { ContextualSearchBase } from 'app/menu-panel/menu-panel-base/contextual-search';
import { NavigationArgs } from 'app/navigation/navigation-args';

@Component({
	selector: 'app-asset-types',
	templateUrl: './metric-source-asset-types.component.html',
	styleUrls: ['./metric-source-asset-types.component.scss']
})
export class AssetTypesComponent extends MenuPanelBaseComponent {
	metric: Metric;
	metricSource: MetricSource;

	constructor(private userSessionService: UserService, private dataChannelService: DataChannelService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
		this.metricSource = args.parameter.metricSource;
	}

	public get assetDefinitions(): SedaruModels.AssetDefinition[] {
		const search = this.contextualSearch.getSearch<SedaruModels.AssetDefinition>();
		return search.results;
	}

	getAssetIcon(assetDef: SedaruModels.AssetDefinition): string {
		if (!assetDef) return '';

		const icon = assetDef.iconResource;

		if (!icon) return '';

		return icon.url;
	}

	selectAssetType(assetDefinition: SedaruModels.AssetDefinition) {
		this.clearMetricUponChangeOfSource();
		this.metric.definition.source = this.metricSource;
		this.metric.definition.source.assetType = assetDefinition.assetType;
		this.dataChannelService.assignDataChannelToMetric(this.metric);
		const { historyChannel, defaultTrendSettings } = this.metric.definition;

		if (historyChannel) {
			defaultTrendSettings.defaultTrendStyle = {
				identifier: TrendStyleIdentifier.line,
				label: 'line chart'
			};
		}

		NavigationService.navigateBackTo(Pages.editMetric);
	}

	clearMetricUponChangeOfSource() {
		this.metric.definition.query = null;
		this.metric.definition.displayValueSettings.clear();
		this.metric.definition.menuPanelSettings.clear();
		this.metric.definition.defaultTrendSettings.clear();
	}

	onCreateContextualSearch(): ContextualSearchBase {
		const searchFunction = (assetDefinition: SedaruModels.AssetDefinition, searchText) => {
			if (assetDefinition.title.toLowerCase().includes(searchText.toLowerCase())) return true;

			if (assetDefinition.assetType.toLowerCase().includes(searchText.toLowerCase())) return true;

			return false;
		};
		const searchController = new SearchController<SedaruModels.AssetDefinition>(this.userSessionService.globalConfig.assetDefinitions, searchFunction);
		const contextualSearch = new ContextualSearchBase(new Search<SedaruModels.AssetDefinition>(searchController), true);
		contextualSearch.title = 'search asset types';
		return contextualSearch;
	}
}
