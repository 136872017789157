import { QueryField } from './../../../../../models/metric-query.model';
import { QueryOperator, Operator } from './../../../../../models/query-operator.enum';
import { OmniInteropService } from './../../../../../domain-service/omni-interop.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Metric } from 'models/metric.model';
import { DeprecatedMetricTimeFrame } from 'models/metric-timeframe.model';
import { QueryFieldValueEditingMode } from '../metric-query/query-field/query-field-value-editing-mode.enum';
import { QuerySelectionType } from 'models/query-selection-type.enum';
import { ArcGISField } from 'models/arc-gis-field.model';
import { FieldType } from 'sedaru-util/esri-core';

@Component({
	selector: 'app-time-frame-settings',
	templateUrl: './time-frame-settings.component.html',
	styleUrls: ['./time-frame-settings.component.scss']
})
export class TimeFrameSettingsComponent extends MenuPanelBaseComponent {
	@ViewChild('timeFrameFieldWrapper', { static: false }) set timeFrameFieldWrapper(wrapper: ElementRef) {
		if (!wrapper) return;
		setTimeout(() => (this.maxHeightOfField = wrapper.nativeElement.offsetHeight / 3), 0);
	}
	metric: Metric;

	timeFrameSetting: QueryField;
	/**
	 * The sedaru grey color code
	 */
	readonly sedaruGrey = 'rgba(84, 84, 84, 0.6)';

	/**
	 * The default sedaru grey color code
	 */
	readonly defaultSedaruGrey = 'rgba(128, 128, 128, 0.6)';

	fieldList: any = [];

	operatorList: any = [];

	valueList: { name: string; code: string }[] = DeprecatedMetricTimeFrame.getTimeFrameSelection();

	valueEditingMode = QueryFieldValueEditingMode.SELECTION;

	itemToEdit: QuerySelectionType = QuerySelectionType.field;

	headerForValuePanel = '';

	maxHeightOfField = 240;

	get showFieldComponent(): boolean {
		return this.itemToEdit > 1 || this.itemToEdit === 0;
	}

	get showOperatorComponent(): boolean {
		return this.itemToEdit > 2 || this.itemToEdit === 0;
	}

	get showValueComponent(): boolean {
		return this.itemToEdit > 3 || this.itemToEdit === 0;
	}

	get isFreeText(): boolean {
		return this.valueEditingMode === QueryFieldValueEditingMode.FREETEXT;
	}

	get isDisabled(): boolean {
		return this.valueEditingMode === QueryFieldValueEditingMode.DISABLED;
	}

	get showDelete(): boolean {
		return !!(this.timeFrameSetting && this.timeFrameSetting.field && this.timeFrameSetting.field.name)
	}

	get expansionShouldOpen(): boolean {
		const operatorIsNotNull: boolean = this.timeFrameSetting.operator && !this.timeFrameSetting.operator.name.toLowerCase().includes('null');
		const operatorIsNotBlank: boolean = this.timeFrameSetting.operator && !this.timeFrameSetting.operator.name.toLowerCase().includes('blank');
		const openValueExpansionPanel: boolean = this.itemToEdit === QuerySelectionType.value && operatorIsNotNull && operatorIsNotBlank;
		return openValueExpansionPanel || !this.timeFrameSetting.value;
	}

	constructor(view: ElementRef<HTMLElement>, private interopService: OmniInteropService) {
		super(view);
	}

	ngOnInit() {
		this.getCopy();
		this.getState();
		this.getFields();
		this.getOperator();
	}

	onPageNavigatedTo(args: NavigationArgs) {
		this.metric = args.parameter.metric;
	}

	getCopy() {
		this.timeFrameSetting = new QueryField();
	}

	getFields() {
		const { isWorkOrderMetric, workOrderChannel } = this.metric.definition;
		if (isWorkOrderMetric && workOrderChannel.isAdvancedWorkOrder) {
			this.fieldList = DeprecatedMetricTimeFrame.getAWOTimeFrameFields();
		} else {
			const fields = this.interopService.arcGISManager.getArcGisFields(this.metric, true);
			this.fieldList = fields.filter(field => field.type === FieldType.esriFieldTypeDate);
		}
	}

	getOperator() {
		this.operatorList = Object.values(QueryOperator.forDate).filter(operator => operator.name !== 'is null' && operator.name !== 'is not null');
	}

	getState() {
		if (this.timeFrameSetting.operator && this.timeFrameSetting.operator.name) {
			this.itemToEdit = QuerySelectionType.none;
			this.setSelection(this.timeFrameSetting.operator);
			this.getValuePanelHeader();
		}
	}

	setSelection(operator: Operator) {
		const operatorEqualsNull: boolean = operator && operator.name.toLowerCase().includes('null');
		const operatorEqualsBlank: boolean = operator && operator.name.toLowerCase().includes('blank');
		if (operatorEqualsNull || operatorEqualsBlank) {
			this.setEditMode(QueryFieldValueEditingMode.DISABLED);
		} else if (operator.name !== QueryOperator.forDate.within.name) {
			this.setEditMode(QueryFieldValueEditingMode.FREETEXT);
		} else {
			this.valueList = DeprecatedMetricTimeFrame.getTimeFrameSelection();
			this.setEditMode(QueryFieldValueEditingMode.SELECTION);
		}
	}

	setEditMode(queryOperator: QueryFieldValueEditingMode) {
		this.valueEditingMode = queryOperator;
	}

	onFieldSelected(item: ArcGISField) {
		this.timeFrameSetting.field.initialize(item);
		this.setSequence(QuerySelectionType.operator);
	}

	onOperatorSelected(operator: Operator) {
		this.timeFrameSetting.operator.name = operator.name;
		this.timeFrameSetting.operator.value = operator.value;
		this.timeFrameSetting.value = { code: '', name: '' };
		this.setSelection(operator);
		this.getValuePanelHeader();
		this.setSequence(QuerySelectionType.value);
	}

	onValueChanged(value: any) {
		this.timeFrameSetting.value = typeof value === 'string' || typeof value === 'number' ? { name: value, code: value } : value;
	}

	getValuePanelHeader() {
		if (this.valueEditingMode === QueryFieldValueEditingMode.DISABLED) {
			this.headerForValuePanel = '-';
		} else if (this.valueEditingMode === QueryFieldValueEditingMode.SELECTION) {
			this.headerForValuePanel = 'select a value';
		} else {
			this.headerForValuePanel = 'enter a value';
		}
	}

	setSequence(type: QuerySelectionType) {
		this.itemToEdit = type;
	}

	save() {
		this.menuPanelComponent.goBack();
	}

	onDelete() {
		this.menuPanelComponent.goBack();
	}
}
