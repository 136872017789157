<mat-card
	class="omni-templated-list-item-no-icon"
	[class.selected]="selected"
	(click)="!resolver.isDisabled(item) && (selected = !selected); itemSelected.emit(item)"
	[class.hovered]="hovered"
	(mouseenter)="!resolver.isDisabled(item) && (hovered = true)"
	(mouseleave)="hovered = false"
>
	<mat-card-title class="omni-templated-list-item-no-icon-title s-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
		{{ resolver.getTopLabel(item) }}
	</mat-card-title>
	<mat-card-subtitle class="omni-templated-list-item-no-icon-subtitle xs-font-size" [ngStyle]="{ 'text-align': resolver.getAlignment(item) }">
		<span class="omni-templated-list-item-no-icon-subtitle-1" style="flex:1">{{ resolver.getBottomLeftLabel(item) }}</span>
		<span class="omni-templated-list-item-no-icon-subtitle-2">
			{{ resolver.getBottomRightLabel(item) }}
		</span>
	</mat-card-subtitle>
</mat-card>
<mat-divider *ngIf="resolver.showDivider(item)" class="omni-templated-list-item-no-icon-divider"></mat-divider>
