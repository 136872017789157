import { Component, ElementRef } from '@angular/core';
import { GuiConfigService } from 'domain-service/gui-config.service';
import { GuiConfig } from '../../../../omni-model/gui-config.model';
import { Theme } from '../../../../omni-model/theme.model';
import { MenuPanelBaseComponent } from 'app/menu-panel/menu-panel-base/menu-panel-base.component';
import { NavigationArgs } from 'app/navigation/navigation-args';
import { Pages } from 'app/navigation/inavigation';

/**
 * This is a child component to the tab settings component. This is designed to be placed in the left side panel.
 * The component is loaded when one tries to change a tab's theme by slecting the 'theme' field form the tab settings panel.
 * The component lists the currently available themes and also an option to add custom themes.
 */
@Component({
	selector: 'app-theme',
	templateUrl: './theme.component.html',
	styleUrls: ['./theme.component.scss']
})
export class ThemeComponent extends MenuPanelBaseComponent {
	/**
	 * Holds the currently selected tab.
	 */
	configChanges: GuiConfig;

	public get pageIdentifier(): Pages {
		return Pages.tabTheme;
	}

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {GuiConfigService} guiConfigService - Provides all the services to manipulate the OMNI tab.
	 */
	constructor(private guiConfigService: GuiConfigService, view: ElementRef<HTMLElement>) {
		super(view);
	}

	onPageNavigatedTo(args: NavigationArgs) {
		if (args.parameter.configChanges) this.configChanges = args.parameter.configChanges;
	}

	/**
	 * On init, the currently selected tab is initialized, a subscription is made to the event thrown when a tab is selected
	 * and the current route is emitted.
	 */
	ngOnInit() {
		this.menuPanelComponent.updateView({ title: 'tab settings', backgroundClass: 'cyan-background' });
	}

	/**
	 * This method is invoked when one of the theme is selected from the theme side panel. It sets the selected theme
	 * to the OMNI app.
	 * @param {string} themeName - The unique name used to identify different themes.
	 */
	updateTheme(themeName: string) {
		const newTheme = new Theme(themeName);
		this.configChanges.theme = newTheme;
		/**
		 * when user selects an option we delay 400ms before redirecting back to tab settings
		 * that way we give the user time to notice the ripple animation and the
		 * newly highlighted item
		 */
		setTimeout(() => {
			this.menuPanelComponent.navigationRouter.navigateBack(Pages.tabSettings, { configChanges: this.configChanges });
		}, 400);
	}
}
