import { Component, Input, OnInit } from '@angular/core';
import { fadeIn } from '../../../app.animations';

@Component({
	selector: 'app-node',
	templateUrl: './node.component.html',
	styleUrls: ['./node.component.scss'],
	animations: [fadeIn]
})
export class NodeComponent implements OnInit {
	@Input() widthNumber: number;

	@Input() position: { top: number; left: number };

	@Input() color = 'white';

	top: string;
	left: string;
	width: string;

	constructor() {}

	ngOnInit() {
		this.top = this.position.top + 'px';
		this.left = this.position.left + 'px';
		this.width = this.widthNumber + 'px';
	}
}
