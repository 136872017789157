export enum Pages {
	none = 'none',
	tabSettings = 'tabSettings',
	tabContentType = 'tabContentType',
	tabVisibility = 'tabVisibility',
	tabTheme = 'tabTheme',
	layout = 'layout',
	metrics = 'metrics',
	tileSettings = 'tileSettings',
	mapList = 'mapList',
	mapSettings = 'mapSettings',
	baseMapList = 'baseMapList',
	trendSettings = 'trendSettings',
	trendStyle = 'trendStyle',
	mapLayers = 'mapLayers',
	mapAssetsList = 'mapAssetsList',
	singleAssetList = 'singleAssetList',
	mapAssetAttributes = 'mapAssetAttributes',
	editMetric = 'editMetric',
	metricSourceTypes = 'metricSourceTypes',
	metricIconList = 'metricIconList',
	queryBuilder = 'queryBuilder',
	timeframeBuilder = 'timeframeBuilder',
	manualQuery = 'manualQuery',
	displayValueSettings = 'displayValueSettings',
	menupanelsettings = 'menupanelsettings',
	timeFrameSettings = 'timeFrameSettings',
	defaulttrendsettings = 'defaulttrendsettings',
	assetTypes = 'assetTypes',
	valueList = 'valueList',
	defaulttrendstyle = 'defaulttrendstyle',
	searchResults = 'searchResults',
	workorderOutline = 'workorderOutline',
	workorderSummary = 'workorderSummary',
	workorderTaskList = 'workorderTaskList',
	workorderTask = 'workorderTask',
	workorderNewTask = 'workorderNewTask',
	workorderAssets = 'workorderAssets',
	workorderLEMCostView = 'workorderLEMCostView',
	workorderField = 'workorderField',
	workOrderSourceTypes = 'workOrderSourceTypes',
	workorderLemList = 'workorderLemList',
	workOrderFilter = 'workOrderFilter',
	workOrderFilterSelection = 'workOrderFilterSelection',
	workOrderFilterDatePicker = 'workOrderFilterDatePicker',
	workOrderFilterCustomField = 'workOrderFilterCustomField',
	workOrderAttachments = 'workOrderAttachments',
	workOrderCommentList = 'workOrderCommentList',
	workOrderComment = 'workOrderComment',
	workorderTime = 'workorderTime',
	relatedWorkorders = 'relatedWorkorders',
	newLEMMenuPanel = 'newLEMMenuPanel',
	assetInformation = 'assetInformation',
	historyRecordsForm = 'historyRecordsForm',
	teamView = 'teamView',
	test = 'test',
	workOrderFailureReport = 'workOrderFailureReport',
	bulkEditErrorsList = 'bulkEditErrorsList',
	metricResults = 'metricResults',
	hierarchy = 'hierarchy',
	hierarchyWorkOrderList = 'hierarchyWorkOrderList',
	tileTimeframe = 'tileTimeframe',
	tableSettings = 'tableSettings',
	timeframefilter = 'timeframefilter',
	historyRecordAssetList = 'historyRecordAssetList',
	dateFieldSelection = 'dateFieldSelection',
	workOrderRecurrence = 'workOrderRecurrence',
	recurrenceFieldSelection = 'recurrenceFieldSelection',
	userProfile = 'userProfile',
	changePassword = 'changePassword'
}

export interface INavigation {
	resolve(page: Pages): any;
}
