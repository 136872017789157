<div class="scrolling-content" #advancedLaborContainer>
	<span class="advanced-labor-header">employee time</span>
	<mat-divider class="divider-item"></mat-divider>
	<app-menu-panel-field
		[header]="'employee name'"
		[cssClass]="'orange-background'"
		[type]="'text'"
		[buttonEnabled]="!readOnly"
		[value]="employeeName"
		[goTo]="goToEmployee"
		[disabled]="true"
	></app-menu-panel-field>
	<app-menu-panel-field
		[header]="'type'"
		[cssClass]="'light-gray-background'"
		[type]="'text'"
		[buttonEnabled]="!readOnly"
		[value]="rateTypeDescription"
		[goTo]="goToRateType"
		[disabled]="true"
	></app-menu-panel-field>
	<app-menu-panel-field
		[header]="'hours'"
		[(value)]="employeeCost.hours"
		[cssClass]="'orange-background'"
		[type]="'number'"
		[maxNumber]="1000"
		[minNumber]="-1000"
		[disabled]="readOnly"
	></app-menu-panel-field>
	<app-menu-panel-field
		[header]="'start date'"
		[cssClass]="'light-gray-background'"
		[type]="'date'"
		[buttonEnabled]="!readOnly"
		[value]="dateStamp"
		[goTo]="goToStartDate"
		[disabled]="true"
	></app-menu-panel-field
	><app-menu-panel-field
		[header]="'budget'"
		[cssClass]="'orange-background'"
		[type]="'text'"
		[buttonEnabled]="!readOnly"
		[value]="budgetName"
		[disabled]="true"
		[goTo]="goToWorkOrderBudget"
	></app-menu-panel-field>
	<app-menu-panel-field
		[header]="'trade'"
		[cssClass]="'light-gray-background'"
		[type]="'text'"
		[buttonEnabled]="!readOnly"
		[value]="employeeTradeName"
		[disabled]="true"
		[goTo]="goToEmployeeTrade"
	></app-menu-panel-field>
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
</mat-card-footer>
