<div class="scrolling-content">
	<mat-action-list class="display-value-settings">
		<mat-list-item class="dark-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.typeOfValue"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#typeOfValueTooltip="toggleTooltip"
				[matTooltipDisabled]="typeOfValueTooltip.isTooltipDisabled"
			>
				<span class="control-title">type of value</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="not set" [value]="settings.typeOfValue.name" />
				<button (click)="onGotoTypeOfValue()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="light-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.valueTypeField.omniName"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#valueTypeFieldTooltip="toggleTooltip"
				[matTooltipDisabled]="valueTypeFieldTooltip.isTooltipDisabled"
			>
				<span class="control-title">value type field</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="n/a" [value]="settings.valueTypeField.name ? settings.valueTypeField.omniName : ''" />
				<button (click)="onGotoValueTypeField()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
		<mat-list-item class="dark-gray-background menu-panel-input-field">
			<div
				[matTooltip]="settings.valueFormat"
				matTooltipClass="tooltip"
				matTooltipPosition="above"
				[appTooltip]
				#valueFormatTooltip="toggleTooltip"
				[matTooltipDisabled]="valueFormatTooltip.isTooltipDisabled"
			>
				<span class="control-title">value format</span>
				<input class="control-value omni-restrict-overflow" disabled placeholder="n/a" [value]="settings.valueFormat.name" />
				<button (click)="onGotoValueFormat()" mat-icon-button>
					<img src="assets/next-circled.png" />
				</button>
			</div>
		</mat-list-item>
	</mat-action-list>
</div>
