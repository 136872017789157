import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, Input, OnChanges, SimpleChange } from '@angular/core';
import { Canvas, CanvasLayoutTypes, CanvasMode } from '../../omni-model/canvas.model';
import { ScrollToViewService } from 'app/scroll-to-view/scroll-to-view.service';
import { UserService } from 'app/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuid } from 'uuid';
import { CanvasComponent } from './canvas/canvas.component';
import { CanvasTrend } from '../../omni-model/canvas-trend.model';
import { GuiConfig } from '../../omni-model/gui-config.model';
import { MetricTile, Metric } from 'models';
import { NavigationService, Pages } from 'app/navigation/navigation.service';
import { CanvasService } from './canvas.service';
import { CanvasTrendService } from './canvas-trend/canvas-trend.service';
import { ModifierKeys } from 'domain-service/ui';
import { TrendDefinition } from './canvas-trend/trend-defintion.model';
import { TileComponent } from 'app/tiles/tile/tile.component';
import { CanvasTab } from 'omni-model/canvas-tab.model';
import { TrendStyle } from 'app/menu-panel/trend-settings/trend-style/trend-style.model';

/**
 * This component is designed to cover the entire space between the left side tab panel and the right side menu panel.
 * The component will load the OMNI map, trend charts and the tables.
 */
@Component({
	selector: 'app-canvas-container',
	templateUrl: './canvas-container.component.html',
	styleUrls: ['./canvas-container.component.scss']
})
export class CanvasContainerComponent implements OnInit, OnChanges {
	@Input() tilesLayout: number;
	/**
	 * This will be a number from 1 to 6 which will represent the layout of the currently displayed canvas.
	 */
	@Input() canvasLayout: CanvasLayoutTypes;
	/**
	 * Holds the array of canvases within a tab. It can range from a single canvas to 4 canvases.
	 */
	@Input() canvases: Array<Canvas>;
	/**
	 * Holds the given config
	 */
	@Input() config: GuiConfig;
	/** Phone, tablet or large screen */
	@Input() displayMode: string;
	/**
	 * We are wrapping the #canvascontainer native element into the ElementRef class that
	 * is just a wrapper class for native elements.
	 */
	@ViewChild('canvascontainer', { read: ElementRef, static: true }) public canvasContainerElement: ElementRef;

	@ViewChildren(CanvasComponent) canvasComponents: QueryList<CanvasComponent>;
	/**
	 * Height of the canvas defined as a column-width:row-height ratio.
	 */
	canvasHeight: string;
	/**
	 * Holds the height of the tile component. It varies based on the tile layout chosen.
	 */
	gridHeight: {};

	/**
	 * Holds the gutter size of the canvas container. This varies based on whether the component is loaded on a mobile device
	 * or desktop.
	 */
	gutterSize = '3px';

	selectedTrend: CanvasTrend;

	onTabSelected: (canvasTab: CanvasTab, defaultMetricRemoved?: boolean) => Promise<Boolean>;

	/**
	 * The constructor doesn't do anything aside from loading and injecting dependencies.
	 * @param {GuiConfigService} guiConfigService - Provides the services required to update the canvas
	 * details like canvas layout and tab layout of the containing tab.
	 * @param {Router} router - Provides services to enable navigation between components.
	 * @param {CanvasMapService} mapService - Provides services to manage changes in the ESRI map added
	 * to the canvas.
	 * @param {BreakpointObserver} breakpointObserver - lets us observe when the portview size corresponds to a phone size
	 * @param {ScrollToViewService} scrollingService - This registers the view which is to be scrolled in when
	 * a user hits something, say a tile, on a mobile device.
	 * @param {UserService} userService - Used to fetching user data
	 * @param {MatSnackBar} snackBar - The Angular Material snack bar component.  Used for login error notifications.
	 */
	constructor(
		private scrollingService: ScrollToViewService,
		private userService: UserService,
		private snackBar: MatSnackBar,
		private canvasService: CanvasService,
	) {}

	/**
	 * On init, subscriptions are made to the events emitted when tabs are changed and also when the canvas map is changed.
	 */
	ngOnInit() {
		if (!this.config.selectedCanvas) {
			this.config.selectedCanvas = this.canvases[0];
		}

		this.scrollingService.canvasContainer = this.canvasContainerElement;
	}

	ngAfterViewInit() {
		this.bubbleOnTabSelectedHandler();
	}

	private bubbleOnTabSelectedHandler() {
		if (!this.canvasComponents) return;
		this.canvasComponents.forEach(canvasComponent => {
			canvasComponent.onCanvasTabSelected = (canvasTab, defaultMetricRemoved) => {
				console.log('canvascontainer  bubbleontabselected handler   ng afterviewinit', defaultMetricRemoved)
				return this.onTabSelected(canvasTab, defaultMetricRemoved);
			};
		});
	}

	ngOnChanges(changes: { [key: string]: SimpleChange }) {
		for (const propertyName in changes) {
			if (propertyName === 'canvasLayout') {
				this.changeCanvasLayout(this.canvasLayout);
				this.gridHeight = this.getGridHeight();
				setTimeout(() => this.bubbleOnTabSelectedHandler(), 50);
			} else if (propertyName === 'displayMode') {
				if (this.displayMode === 'phone') {
					this.gutterSize = '5px';
					this.gridHeight = this.getGridHeight();
				} else {
					this.gutterSize = '3px';
					this.gridHeight = this.getGridHeight();
				}
			}
		}
	}

	/**
	 * The method accepts the canvas layout type and the available canvas map list  and returns an array of canvas object
	 * The number of canvases in each array depends on the layout selected (from 1 to 6).
	 * @param {CanvasLayoutTypes} canvasLayout - The canvas layout that has been selected for the current tab (a number from 1 to 6)
	 */
	changeCanvasLayout(canvasLayout: CanvasLayoutTypes) {
		// if new layout is less than previous layout, warn the user

		if (canvasLayout < this.canvasLayout) {
			// warn the user
			// to be taken up in ticket 10899
		}

		const canvasArray = [];
		switch (canvasLayout) {
			case 1:
			default: {
				let canvas: Canvas;
				const currentCanvas = this.canvases[Object.keys(this.canvases)[0]];
				if (this.canvases && currentCanvas) {
					canvas = currentCanvas;
					canvas.size = 1;
					canvas.colspan = 2;
				} else {
					canvas = new Canvas(1, 1);
					// For now, we will implement canvas id using uuid. In the near future, right after creating a new canvas,
					// we should save that canvas object into the database, and on success, we will assign the generated vertex id
					// from our neptune database and assign it to the cavase.id at the next line of code
					canvas.id = uuid();
				}
				canvasArray.push(canvas);
				this.canvasHeight = '1:1';
				break;
			}
			case 2: {
				for (let i = 0; i < 2; i++) {
					const currentCanvas = this.canvases.find(canvas => canvas.position === i + 1);
					if (this.canvases && currentCanvas) {
						currentCanvas.size = 2;
						currentCanvas.colspan = 2;
						canvasArray.push(currentCanvas);
					} else {
						const canvas = new Canvas(2, i + 1);
						canvas.id = uuid();
						canvasArray.push(canvas);
					}
				}
				this.canvasHeight = '2:1';
				break;
			}
			case 3: {
				for (let i = 0; i < 3; i++) {
					const size = i > 1 ? 2 : 4; // size 4 is a square canvas, size 2 is a long horizontal canvas.
					const colspan = i > 1 ? 2 : 1;
					const currentCanvas = this.canvases.find(canvas => canvas.position === i + 1);
					if (this.canvases && currentCanvas) {
						const canvas = currentCanvas;
						canvas.size = size;
						canvas.colspan = colspan;
						canvasArray.push(currentCanvas);
					} else {
						const canvas = new Canvas(size, i + 1);
						canvas.id = uuid();
						canvasArray.push(canvas);
					}
				}
				this.canvasHeight = '2:1';
				break;
			}
			case 4: {
				for (let i = 0; i < 4; i++) {
					const currentCanvas = this.canvases.find(canvas => canvas.position === i + 1);
					if (this.canvases && currentCanvas) {
						const canvas = currentCanvas;
						canvas.size = 4;
						canvas.colspan = 1;
						canvasArray.push(currentCanvas);
					} else {
						const canvas = new Canvas(4, i + 1);
						canvas.id = uuid();
						canvasArray.push(canvas);
					}
				}
				this.canvasHeight = '2:1';
				break;
			}
			case 5: {
				for (let i = 0; i < 2; i++) {
					const currentCanvas = this.canvases.find(canvas => canvas.position === i + 1);
					if (this.canvases && currentCanvas) {
						const canvas = currentCanvas;
						canvas.size = 3;
						canvas.colspan = 1;
						canvasArray.push(currentCanvas);
					} else {
						const canvas = new Canvas(3, i + 1);
						canvas.id = uuid();
						canvasArray.push(canvas);
					}
				}
				this.canvasHeight = '1:1';
				break;
			}
			case 6: {
				for (let i = 0; i < 3; i++) {
					const currentCanvas = this.canvases.find(canvas => canvas.position === i + 1);
					const size = i > 0 ? 4 : 2; // size 4 is a square canvas, size 2 is a long horizontal canvas.
					const colspan = i > 0 ? 1 : 2;
					if (this.canvases && currentCanvas) {
						const canvas = currentCanvas;
						canvas.size = size;
						canvas.colspan = colspan;
						canvasArray.push(currentCanvas);
					} else {
						const canvas = new Canvas(size, i + 1);
						canvas.id = uuid();
						canvasArray.push(canvas);
					}
				}
				this.canvasHeight = '2:1';
				break;
			}
		}

		this.canvases = canvasArray;
		this.config.canvasList = this.canvases;
	}

	/**
	 * This method accepts the tile layout selected for the tab and sets the height of the tile grid based on the
	 * tile layout selected and based on whether it is a mobile device.
	 * @param {number} tiles - holds the tile layout for the currently selected tab. It will be a number from 0, 6 or 12.
	 */
	getGridHeight() {
		let height = {};
		if (this.displayMode === 'phone') {
			const px = this.canvases.length * 300;
			height = { height: px + 'px' };
		} else height = { height: '100%' };
		return height;
	}

	/**
	 * This method is called when the map icon within the canvas is clicked.
	 * The method navigated the user to the map list side panel.
	 * @param {Canvas} canvas - The unique id of the canvas on which the map is trying to be loaded.
	 */
	onGoToMapList(canvas: Canvas) {
		try {
			this.userService.getCurrentCustomerCode();
		} catch (err) {
			this.snackBar.open(err, 'OK', {
				verticalPosition: 'bottom',
				horizontalPosition: 'right'
			});
			return;
		}

		this.scrollingService.menuPanel.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		this.config.selectedCanvas = canvas;
		NavigationService.navigateTo(Pages.mapList);
	}

	/**
	 * This method is called when the trend icon within the canvas is clicked.
	 * The method navigated the user to the trend data list side panel.
	 * @param {Canvas} canvas - The unique id of the canvas on which the trend will be loaded.
	 */
	onGoToTrendList(canvas: Canvas) {
		this.config.selectedCanvas = canvas;
		const { selectedCanvas } = this.config;
		const isNewCanvas = selectedCanvas.mode === CanvasMode.empty;
		selectedCanvas.mode = CanvasMode.trend;

		this.canvasService.saveOmniCanvas(this.config, selectedCanvas, isNewCanvas).subscribe((savedCanvas: Canvas) => {
			this.config.selectedCanvas.id = selectedCanvas.id;
			this.config.selectedCanvas.mode = CanvasMode.trend;
			NavigationService.navigateTo(Pages.trendSettings);
			this.scrollingService.canvasContainer.nativeElement.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}

	activateTrend(canvas: Canvas) {
		this.canvasComponents.forEach(canvasComponent => {
			if (canvasComponent.canvas.id === this.config.selectedCanvas.id) {
				canvasComponent.onTrendIconClicked();
			}
		});
	}

	onGoToTable(canvas: Canvas) {
		this.config.selectedCanvas = canvas;
		if (!this.config.selectedCanvas.table) {
			this.config.selectedCanvas.table = {};
		}
		const { selectedCanvas } = this.config;
		const isNewCanvas = selectedCanvas.mode === CanvasMode.empty;
		selectedCanvas.mode = CanvasMode.table;
		NavigationService.navigateTo(Pages.tableSettings);
		this.scrollingService.canvasContainer.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		this.canvasService.saveOmniCanvas(this.config, canvas, isNewCanvas).subscribe();
	}

	onTileSelected(tileComponent: TileComponent) {
		this.canvasComponents.forEach(canvasComponent => {
			if (canvasComponent.canvas.id === this.config.selectedCanvas.id) {
				canvasComponent.onTileSelected(tileComponent);
			}
		});
	}

	onModifierKeysChanged(modifierKeys: ModifierKeys) {
		// this.canvasComponents.forEach(canvas => {
		// 	canvas.onModifierKeysChanged(modifierKeys);
		// });
	}

	onMetricDefinitionChanged(metricId: string) {
		this.canvasComponents.forEach(c => c.onMetricDefinitionChanged(metricId));
	}

	onCanvasTabSelected(canvasTab: CanvasTab, defaultMetricRemoved: boolean) {
		console.log('canvas-container oncanvastabsselected   defaultmetric', defaultMetricRemoved)
		this.onTabSelected(canvasTab, defaultMetricRemoved);
	}

	onInfoFormTrendIconClicked(trendDefinition: TrendDefinition) {
		this.canvasComponents.forEach(canvasComponent => {
			if (canvasComponent.canvas.id === this.config.selectedCanvas.id) {
				this.onGoToTrendList(canvasComponent.canvas);
				setTimeout(() => {
					canvasComponent.onHistoryFieldTrendIconClicked(trendDefinition);
				}, 2000);
			}
		});
	}

	onTileBackgroundColorChanged(tile: MetricTile) {
		this.canvasComponents.forEach(canvasComponent => canvasComponent.onTileBackgroundColorChanged(tile));
	}

	onTrendStyleChanged(style: TrendStyle) {
		this.canvasComponents.forEach(canvasComponent => {
			if (canvasComponent.canvas.id === this.config.selectedCanvas.id) {
				canvasComponent.onTrendStyleChanged(style);
			}
		});
	}
}
