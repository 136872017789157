import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Sedaru from '../sedaru-util';

export class HttpAngular extends Sedaru.Http.HttpCommon {
	private static _httpClient: HttpClient;
	static initializeHttp(httpClient: HttpClient) {
		HttpAngular._httpClient = httpClient;
		const instance = new HttpAngular();
		Sedaru.Http.HttpCommon.initialize(instance);
	}

	protected onGet(url: string, headers?: any): Promise<any> {
		return HttpAngular._httpClient.get(url, this.getOptions(headers)).toPromise();
	}
	protected onPost(url: string, body: any, headers?: any): Promise<any> {
		return HttpAngular._httpClient.post(url, body, this.getOptions(headers)).toPromise();
	}
	private getOptions(headers?: any): any {
		const options: any =  { observe: 'response' };

		if (headers) {
			options.headers = new HttpHeaders(headers);
		}

		return options;
	}
}
