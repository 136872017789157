<div class="scrolling-content">
	<app-attachments-collection [attachments]="attachments" [isReadOnly]="isReadOnly" (itemToRemove)="deleteAttachment($event)"></app-attachments-collection>
	<input #fileInput type="file" (change)="fileChange($event)" style="display:none;" />
</div>
<mat-card-footer class="omni-menu-panel-footer">
	<mat-divider class="footer-divider"></mat-divider>
	<mat-card-actions align="start">
		<span style="flex:1"></span>
		<p class="omni-md-font">attachments : {{ attachments.length }}</p>
	</mat-card-actions>
</mat-card-footer>
