import { ControlType } from './form-control-type.enum';
import { FormControlInput } from './form-control-input.model';
import { FormControlValue } from './form-control-value.model';
import { Style } from './sedaru-config/style.model';
import { SedaruConfigBase } from './sedaru-config/sedaru-config-base.model';
import { GlobalConfig } from './global-config.model';
import { FormControlGroup } from './form-control-group-definition.model';
import { FormPageDefinition } from './form-page-definition.model';

export class FormControlDefinition extends SedaruConfigBase {
	constructor(globalConfig: GlobalConfig, parent: FormControlGroup | FormPageDefinition) {
		super(globalConfig);

		this._parentGroup = parent as FormControlGroup;
		this._parentPage = parent as FormPageDefinition;
	}

	legacyId: number;
	index: number;
	defaultFieldName: string;
	controlType: ControlType;
	readOnly: boolean;
	required: boolean;
	skipOnEdit: boolean;
	title: string;
	description: string;
	input: FormControlInput;
	value: FormControlValue;
	styleId: number;

	private _parentGroup: FormControlGroup;
	public get parentGroup(): FormControlGroup {
		return this._parentGroup;
	}

	private _parentPage: FormPageDefinition;
	public get parentPage(): FormPageDefinition {
		return this._parentPage;
	}
	public get style(): Style {
		if (this.styleId > 0) {
			return this.globalConfig.styles.getById(this.styleId);
		}

		let page: FormPageDefinition;
		if (this.controlType === ControlType.Footer) {
			page = this.parentPage;
		} else if (this.parentGroup) {
			page = this.parentGroup.parent;
		}

		if (!page || !page.parent) return null;

		const assetDefinition = page.parent.parent;
		if (!assetDefinition) {
			return null;
		}

		return assetDefinition.style;
	}
}
