import { SedaruClientHub } from './sedaru-client-hub';
import { NotificationPacket } from './notification-packet';
import { MethodHandlerArgs } from './method-handler-args';
import { MethodHandlerResultBase } from './method-handler-result';
import { MethodHandlerListener } from './method-handler-listener';

export abstract class SedaruClientMethodHandlerBase {
	private _listeners = new Array<MethodHandlerListener>();

	get id(): string {
		return this.onGetUniqueId();
	}

	private _hub: SedaruClientHub;
	get hub(): SedaruClientHub {
		return this._hub;
	}

	protected abstract onGetUniqueId(): string;
	abstract shouldHandleMethod(methodIdentifier: string): boolean;

	async handleMethod(packet: NotificationPacket, args: MethodHandlerArgs): Promise<void> {
		let result = await this.onHandleMethod(packet, args);
		if (this._listeners.length === 0) return;

		if (!result) {
			result = new MethodHandlerResultBase();
		}

		const listenersToRemove = new Array<MethodHandlerListener>();

		for (const listener of this._listeners) {
			if (listener.hasFinished) {
				listenersToRemove.push(listener);
				continue;
			}

			const resolveResult = await listener.resolve(result);
			if (!resolveResult) continue;

			listenersToRemove.push(listener);
		}

		listenersToRemove.forEach(l => {
			const index = this._listeners.indexOf(l, 0);
			if (index >= 0) {
				this._listeners.splice(index, 1);
			}
		});
	}
	protected abstract onHandleMethod(packet: NotificationPacket, args: MethodHandlerArgs): Promise<MethodHandlerResultBase>;

	attach(hub: SedaruClientHub, id: string) {
		this._hub = hub;
		// this._id = id;

		this.onAttached(hub, id);
	}
	detach() {
		// this._id = '';
		this._hub = null;
		this.onDetach();
	}

	onAttached(hub: SedaruClientHub, id: string) { }
	onDetach() { }

	public createListener = (): MethodHandlerListener => {
		const listener = new MethodHandlerListener(this);
		this._listeners.push(listener);
		return listener;
	};
}

export class SedaruClientMethodHandlerManager {
	private _methodHandlers: Map<string, SedaruClientMethodHandlerBase>;
	private get methodHandlers(): Map<string, SedaruClientMethodHandlerBase> {
		if (!this._methodHandlers) {
			this._methodHandlers = new Map<string, SedaruClientMethodHandlerBase>();
		}

		return this._methodHandlers;
	}

	private _parentHub: SedaruClientHub;
	public get parentHub(): SedaruClientHub {
		return this._parentHub;
	}

	constructor(hub: SedaruClientHub) {
		this._parentHub = hub;
	}

	add(methodHandler: SedaruClientMethodHandlerBase): string {
		this.methodHandlers.set(methodHandler.id, methodHandler);
		methodHandler.attach(this.parentHub, methodHandler.id);
		return methodHandler.id;
	}

	remove(id: string): boolean {
		const handler = this.methodHandlers.get(id);
		if (!handler) return false;

		this.methodHandlers.delete(id);
		handler.detach();
	}

	containsMethodHandler(id: string): boolean {
		const handler = this.methodHandlers.get(id);
		if (!handler) return false;

		return true;
	}

	getHandler(id: string): SedaruClientMethodHandlerBase {
		return this.methodHandlers.get(id);
	}

	getHandlers(methodIdentifier: string = '') {
		const handlers = new Array<SedaruClientMethodHandlerBase>();

		for (const key of this.methodHandlers.keys()) {
			if (!methodIdentifier) {
				handlers.push(this.methodHandlers.get(key));
			}

			if (this.methodHandlers.get(key).shouldHandleMethod(methodIdentifier)) {
				handlers.push(this.methodHandlers.get(key));
				continue;
			}
		}

		return handlers;
	}

	handle(packet: NotificationPacket, args: MethodHandlerArgs): Promise<void> {
		return new Promise<void>(async (resolver, reject) => {
			const handlers = this.getHandlers(packet.methodIdentifier);

			for (const handler of handlers) {
				if (!handler.shouldHandleMethod(packet.methodIdentifier)) continue;

				await handler.handleMethod(packet, args);

				if (!args.isHandled) continue;

				if (!args.continuePropagation) {
					resolver();
					return;
				}
			}

			resolver();
		});
	}
}
