import { EsriSdkService } from './esri-sdk.service';
import { Injectable } from '@angular/core';
import { GeometryType } from './geometry-type.model';

@Injectable({
	providedIn: 'root'
})
export class GeometryService {
	constructor(private esriSdkService: EsriSdkService) {}
	/**
	 * This method will take in a feature object, and convert it to a x and y coordinate to plot into the map as icons.
	 * @param {any} geometry - The arcGisFeature recieved from querying agains ESRI api.
	 */
	getPointGeometry(geometry: any): { x: number; y: number } {
		const { esriMapSdk } = this.esriSdkService as any;
		const type: string = geometry.type || geometry.geometryType;
		let x: number, y: number;

		if (type.toLowerCase().includes('point')) {
			x = geometry.x;
			y = geometry.y;
		}
		if (type.toLowerCase().includes('line')) {
		 	const midPoint = this.getPointWithinPolyLine(<number[][]>geometry.paths[0])
			x = midPoint.x;
			y = midPoint.y;
		}

		if (type.toLowerCase().includes('polygon')) {
			// We will need actual assets that are polygon to be in our data base to test this
			const polygon: __esri.Polygon = new esriMapSdk.Polygon({
				rings: geometry.rings,
				spatialReference: geometry.spatialReference
			});
			x = polygon.centroid.x;
			y = polygon.centroid.y;
		}

		return { x, y };
	}


	/**
	* This function will return the point with in the polyline that
    * that matches the ratio. Example if the ratio is 0.5, then the
	* returned value is the midpoint.
	* @param points - The point of the polyline.
	* @param ratio - An optional parameter used to determine the interested point reltive to p0.
	* @returns An object of type {x: number, y: number}
	*/
	private getPointWithinPolyLine(points: number[][], ratio?: number): { x: number; y: number } {
		let totalLength = 0;
		const segments = [];
		for (let i = 0; i < points.length - 1; i++) {
			const length = this.getDistance(points[i], points[i + 1]);
			totalLength += length;
			segments.push({
				point: points[i],
				distance: length,
				nextPoint: points[i + 1],
			});
		}
		if ((ratio == null) || (ratio == undefined)) {
			ratio = 0.5;
		} else if ((ratio < 0) || (ratio > 1.0)) {
			throw new Error('Ratio has to be between 0 and 1.0');
		}

		const expectedDistance = totalLength * (ratio ?? ratio);
		const result = this.findPointWithinLine(segments, expectedDistance);
		return result;
	}



	/**
	 * Calculate the euclidean distance between two points.
	 * @param point1 - The reference point.
	 * @param point2 - The second point.
	 * @returns The distance between point 1 and point 2.
	 */
	private getDistance(point1: number[], point2: number[]): number {
		return Math.pow(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2), 0.5);
	}


	/**
	* This function will find the point at distance from p0 (first point).
	* @param segments - The points within the polyline.
	* @param distance - The distance from p0.
	* @returns An object of type {x: number, y: number}
	*/
	private findPointWithinLine(segments: {point: number[], nextPoint: number[], distance: number}[], distance: number): { x: number; y: number } {
		let remainingLength = distance;
		let result: {x: number, y: number} = null;
		for (let i = 0; i < segments.length; i++ ) {
			if (remainingLength <= segments[i].distance) {
				// the point we are interested in is in the current segment.
				const distanceRatio = remainingLength / segments[i].distance;
				const nextPoint = segments[i].nextPoint;
				const currentPoint = segments[i].point;
				const xOffSet = (nextPoint[0] - currentPoint[0]) * distanceRatio;
				const yOffset = (nextPoint[1] - currentPoint[1]) * distanceRatio;
				result = {x: currentPoint[0] + xOffSet, y: currentPoint[1] + yOffset};
				break;
			} else {
				remainingLength -= segments[i].distance;
			}
		}
		return result;
	}
}
